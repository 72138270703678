// actions types is object with the
// key: GET_DATA_REQUEST -> value: describes the action
import {toast} from "react-toastify";
import {Translation} from "react-i18next";
import React from "react";

export const Types = {

    RESET_ALL_DATA: 'reset_all_data',

    GET_LOGIN_REAL_USER_REQUEST: 'get_login_real_user_request',
    GET_LOGIN_REAL_USER_SUCCESS: 'get_login_real_user_success',
    GET_LOGIN_REAL_USER_FAILURE: 'get_login_real_user_failure',
    GET_LOGIN_REAL_USER_ERROR: 'get_login_real_user_error',

    GET_CHECK_TOKEN_REQUEST: 'get_check_token_request',
    GET_CHECK_TOKEN_SUCCESS: 'get_check_token_success',
    GET_CHECK_TOKEN_FAILURE: 'get_check_token_failure',
    GET_CHECK_TOKEN_ERROR: 'get_check_token_error',

    POST_LOGOUT_REQUEST: 'post_logout_request',
    POST_LOGOUT_SUCCESS: 'post_logout_success',
    POST_LOGOUT_FAILURE: 'post_logout_failure',
    POST_LOGOUT_ERROR: 'post_logout_error',

    POST_LOST_PASSWORD_REQUEST: 'post_lost_password_request',
    POST_LOST_PASSWORD_SUCCESS: 'post_lost_password_success',
    POST_LOST_PASSWORD_FAILURE: 'post_lost_password_failure',
    POST_LOST_PASSWORD_ERROR: 'post_lost_password_error',

    POST_NEW_PASSWORD_REQUEST: 'post_new_password_request',
    POST_NEW_PASSWORD_SUCCESS: 'post_new_password_success',
    POST_NEW_PASSWORD_FAILURE: 'post_new_password_failure',
    POST_NEW_PASSWORD_ERROR: 'post_new_password_error',

    POST_REGISTER_REQUEST: 'post_register_request',
    POST_REGISTER_SUCCESS: 'post_register_success',
    POST_REGISTER_FAILURE: 'post_register_failure',
    POST_REGISTER_ERROR: 'post_register_error',

    POST_EMAIL_CONFIG_REQUEST: 'post_email_config_request',
    POST_EMAIL_CONFIG_SUCCESS: 'POST_EMAIL_CONFIG_SUCCESS',
    POST_EMAIL_CONFIG_FAILURE: 'POST_EMAIL_CONFIG_FAILURE',
    POST_EMAIL_CONFIG_ERROR: 'POST_EMAIL_CONFIG_ERROR',

    POST_DEMO_REQUEST: 'post_demo_request',
    POST_DEMO_SUCCESS: 'post_demo_success',
    POST_DEMO_FAILURE: 'post_demo_failure',
    POST_DEMO_ERROR: 'post_demo_error',


    GET_DEMO_REQUEST: 'get_demo_request',
    GET_DEMO_SUCCESS: 'get_demo_success',
    GET_DEMO_FAILURE: 'get_demo_failure',
    GET_DEMO_ERROR: 'get_demo_error',

    GET_USER_REQUEST: 'get_user_request',
    GET_USER_SUCCESS: 'get_user_success',
    GET_USER_FAILURE: 'get_user_failure',
    GET_USER_ERROR: 'get_user_error',

    GET_PROFILS_REQUEST: 'get_profils_request',
    GET_PROFILS_SUCCESS: 'get_profils_success',
    GET_PROFILS_FAILURE: 'get_profils_failure',
    GET_PROFILS_ERROR: 'get_profils_error',

    GET_ENVIRONMENTS_REQUEST: 'get_environements_request',
    GET_ENVIRONMENTS_SUCCESS: 'get_environements_success',
    GET_ENVIRONMENTS_FAILURE: 'get_environements_failure',
    GET_ENVIRONMENTS_ERROR: 'get_environements_error',

    PUT_USER_REQUEST: 'put_user_request',
    PUT_USER_SUCCESS: 'put_user_success',
    PUT_USER_FAILURE: 'put_user_failure',
    PUT_USER_ERROR: 'put_user_error',

    POST_CREATE_ENVIRONMENT_REQUEST: 'post_create_environment_request',
    POST_CREATE_ENVIRONMENT_SUCCESS: 'post_create_environment_success',
    POST_CREATE_ENVIRONMENT_FAILURE: 'post_create_environment_failure',
    POST_CREATE_ENVIRONMENT_ERROR: 'post_create_environment_error',

    GET_USER_QUOTA_REQUEST: 'get_user_quota_request',
    GET_USER_QUOTA_SUCCESS: 'get_user_quota_success',
    GET_USER_QUOTA_FAILURE: 'get_user_quota_failure',
    GET_USER_QUOTA_ERROR: 'get_user_quota_error',

    CLEAN_LIST_TO_ACCEPT: 'clean_list_to_accept',

    GET_USER_CONFIG_REQUEST: 'get_user_config_request',
    GET_USER_CONFIG_SUCCESS: 'get_user_config_success',
    GET_USER_CONFIG_FAILURE: 'get_user_config_failure',
    GET_USER_CONFIG_ERROR: 'get_user_config_error',

    POST_USER_CONFIG_REQUEST: 'post_user_config_request',
    POST_USER_CONFIG_SUCCESS: 'post_user_config_success',
    POST_USER_CONFIG_FAILURE: 'post_user_config_failure',
    POST_USER_CONFIG_ERROR: 'post_user_config_error',

    PUT_USER_CONFIG_REQUEST: 'put_user_config_request',
    PUT_USER_CONFIG_SUCCESS: 'put_user_config_success',
    PUT_USER_CONFIG_FAILURE: 'put_user_config_failure',
    PUT_USER_CONFIG_ERROR: 'put_user_config_error',


};

/*
    Login
 */

// function that returns an object literal
export const getloginRealUserRequest = (username,password) => ({
    type: Types.GET_LOGIN_REAL_USER_REQUEST,
    username : username,
    password : password
});

// function that returns an object literal
export const getloginRealUserSuccess = (data) => ({
    type: Types.GET_LOGIN_REAL_USER_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getloginRealUserFailure = () => ({
    type: Types.GET_LOGIN_REAL_USER_FAILURE,
});

// function that returns an object literal
export const getloginRealUserError = () => ({
    type: Types.GET_LOGIN_REAL_USER_FAILURE,
});


// function that returns an object literal
export const postRegisterRequest = (payload) => ({
    type: Types.POST_REGISTER_REQUEST,
    payload : payload,
});

// function that returns an object literal
export const postRegisterSuccess = () => ({
    type: Types.POST_REGISTER_SUCCESS,
});

// function that returns an object literal
export const postRegisterFailure = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_REGISTER_FAILURE,
        });
        toast.error(<ErrorToastMessage message='common:global.error' />)
    };
};

// function that returns an object literal
export const postRegisterError = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_REGISTER_ERROR,
        });
        toast.error(<ErrorToastMessage message='common:global.error' />)
    };
};


// function that returns an object literal
export const postDemoRequest = (payload) => ({
    type: Types.POST_DEMO_REQUEST,
    payload : payload
});

// function that returns an object literal
export const postDemoSuccess = () => ({
    type: Types.POST_DEMO_SUCCESS,
});

// function that returns an object literal
export const postDemoFailure = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_DEMO_FAILURE,
        });
        toast.error(<ErrorToastMessage message='common:global.error' />)
    };
};

// function that returns an object literal
export const postDemoError = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_DEMO_ERROR,
        });
        toast.error(<ErrorToastMessage message='common:global.error' />)
    };
};


export const getDemoRequest = (hash) => ({
    type: Types.GET_DEMO_REQUEST,
    hash : hash,
});

// function that returns an object literal
export const getDemoSuccess = (data) => ({
    type: Types.GET_DEMO_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getDemoFailure = () => ({
    type: Types.GET_DEMO_FAILURE,
});

// function that returns an object literal
export const getDemoError = () => ({
    type: Types.GET_DEMO_ERROR,
});


// function that returns an object literal
export const postCreateEnvironmentRequest = (payload, userhash) => ({
    type: Types.POST_CREATE_ENVIRONMENT_REQUEST,
    payload : payload,
    userhash : userhash
});

// function that returns an object literal
export const postCreateEnvironmentSuccess = () => ({
    type: Types.POST_CREATE_ENVIRONMENT_SUCCESS,
});

// function that returns an object literal
export const postCreateEnvironmentFailure = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_CREATE_ENVIRONMENT_FAILURE,
        });
        toast.error(<ErrorToastMessage message='common:global.error' />)
    };
};

// function that returns an object literal
export const postCreateEnvironmentError = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_CREATE_ENVIRONMENT_ERROR,
        });
        toast.error(<ErrorToastMessage message='common:global.error' />)
    };
};


/*
Token
 */
// function that returns an object literal
export const getCheckTokenRequest = (userhash, token) => ({
    type: Types.GET_CHECK_TOKEN_REQUEST,
    userhash : userhash,
    token : token
});

// function that returns an object literal
export const getCheckTokenSuccess = (data) => ({
    type: Types.GET_CHECK_TOKEN_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getCheckTokenFailure = ({data}) => ({
    type: Types.GET_CHECK_TOKEN_FAILURE,
    payload: { data }
});

// function that returns an object literal
export const getCheckTokenError = () => ({
    type: Types.GET_CHECK_TOKEN_ERROR,
});

/*
Logout
 */
// function that returns an object literal
export const postLogoutRequest = (username) => ({
    type: Types.POST_LOGOUT_REQUEST,
    username : username
});

// function that returns an object literal
export const postLogoutSuccess = () => ({
    type: Types.POST_LOGOUT_SUCCESS,
});

// function that returns an object literal
export const postLogoutFailure = () => ({
    type: Types.POST_LOGOUT_FAILURE,
});

// function that returns an object literal
export const postLogoutError = () => ({
    type: Types.POST_LOGOUT_ERROR,
});


/*
Lost Password
 */
// function that returns an object literal
export const postLostPasswordRequest = (email, language) => ({
    type: Types.POST_LOST_PASSWORD_REQUEST,
    email : email,
    language : language
});

// function that returns an object literal
export const postLostPasswordSuccess = () => ({
    type: Types.POST_LOST_PASSWORD_SUCCESS,
});

// function that returns an object literal
export const postLostPasswordFailure = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_LOST_PASSWORD_FAILURE,
        });
        toast.error(<ErrorToastMessage message='common:global.error' />)
    };
};

// function that returns an object literal
export const postLostPasswordError = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_LOST_PASSWORD_ERROR,
        });
        toast.error(<ErrorToastMessage message='common:global.error' />)
    };
};


/*
Lost Password
 */
// function that returns an object literal
export const postNewPasswordRequest = (email, code, password) => ({
    type: Types.POST_NEW_PASSWORD_REQUEST,
    payload : {email : email, code_sms : code, password_1 : password, password_2 : password},
});

// function that returns an object literal
export const postNewPasswordSuccess = () => ({
    type: Types.POST_NEW_PASSWORD_SUCCESS,
});

// function that returns an object literal
export const postNewPasswordFailure = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_NEW_PASSWORD_FAILURE,
        });
        toast.error(<ErrorToastMessage message='common:global.error' />)
    };
};

// function that returns an object literal
export const postNewPasswordError = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_NEW_PASSWORD_ERROR,
        });
        toast.error(<ErrorToastMessage message='common:global.error' />)
    };
};


// function that returns an object literal
export const getUserRequest = (userhash) => ({
    type: Types.GET_USER_REQUEST,
    userhash : userhash,
});

// function that returns an object literal
export const getUserSuccess = (data) => ({
    type: Types.GET_USER_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getUserFailure = () => ({
    type: Types.GET_USER_FAILURE,
});

// function that returns an object literal
export const getUserError = () => ({
    type: Types.GET_USER_ERROR,
});

// function that returns an object literal
export const getProfilsRequest = (userhash) => ({
    type: Types.GET_PROFILS_REQUEST,
    userhash : userhash,
});

// function that returns an object literal
export const getProfilsSuccess = (data) => ({
    type: Types.GET_PROFILS_SUCCESS,
    payload: data
});

// function that returns an object literal
export const getProfilsFailure = () => ({
    type: Types.GET_PROFILS_FAILURE,
});

// function that returns an object literal
export const getProfilsError = () => ({
    type: Types.GET_PROFILS_ERROR,
});

// function that returns an object literal
export const getUserQuotaRequest = (userhash) => ({
    type: Types.GET_USER_QUOTA_REQUEST,
    userhash : userhash,
});

// function that returns an object literal
export const getUserQuotaSuccess = (data) => ({
    type: Types.GET_USER_QUOTA_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getUserQuotaFailure = () => ({
    type: Types.GET_USER_QUOTA_FAILURE,
});

// function that returns an object literal
export const getUserQuotaError = () => ({
    type: Types.GET_USER_QUOTA_ERROR,
});





// function that returns an object literal
export const getEnvironmentsRequest = (userhash) => ({
    type: Types.GET_ENVIRONMENTS_REQUEST,
    userhash : userhash,
});

// function that returns an object literal
export const getEnvironmentsSuccess = (data) => ({
    type: Types.GET_ENVIRONMENTS_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getEnvironmentsFailure = () => ({
    type: Types.GET_ENVIRONMENTS_FAILURE,
});

// function that returns an object literal
export const getEnvironmentsError = () => ({
    type: Types.GET_ENVIRONMENTS_ERROR,
});


// function that returns an object literal
export const putUserRequest = (payload,userhash) => ({
    type: Types.PUT_USER_REQUEST,
    payload : payload,
    userhash : userhash
});

// function that returns an object literal

export const putUserSuccess = (data) => {
    return function(dispatch) {
        dispatch({
            type: Types.PUT_USER_SUCCESS,
            payload: { data }
        });
        toast.success(<ErrorToastMessage message='common:global.success' />)
    };
};

// function that returns an object literal
export const putUserFailure = () => {
    return function(dispatch) {
        dispatch({
            type: Types.PUT_USER_FAILURE,
        });
        toast.error(<ErrorToastMessage message='common:global.error' />)
    };
};

// function that returns an object literal
export const putUserError = () => {
    return function(dispatch) {
        dispatch({
            type: Types.PUT_USER_ERROR,
        });
        toast.error(<ErrorToastMessage message='common:global.error' />)
    };
};


// function that returns an object literal
export const postUserConfigRequest = (payload) => ({
    type: Types.POST_USER_CONFIG_REQUEST,
    payload : payload
});

// function that returns an object literal
export const postUserConfigSuccess = () => ({
    type: Types.POST_USER_CONFIG_SUCCESS,
});

// function that returns an object literal
export const postUserConfigFailure = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_USER_CONFIG_FAILURE,
        });
        toast.error(<ErrorToastMessage message='common:global.error' />)
    };
};

// function that returns an object literal
export const postUserConfigError = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_USER_CONFIG_ERROR,
        });
        toast.error(<ErrorToastMessage message='common:global.error' />)
    };
};


// function that returns an object literal
export const getUserConfigRequest = (payload) => ({
    type: Types.GET_USER_CONFIG_REQUEST,
    payload : payload
});

// function that returns an object literal
export const getUserConfigSuccess = (data) => ({
    type: Types.GET_USER_CONFIG_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getUserConfigFailure = () => {
    return function(dispatch) {
        dispatch({
            type: Types.GET_USER_CONFIG_FAILURE,
        });
        toast.error(<ErrorToastMessage message='common:global.error' />)
    };
};

// function that returns an object literal
export const getUserConfigError = () => {
    return function(dispatch) {
        dispatch({
            type: Types.GET_USER_CONFIG_ERROR,
        });
        toast.error(<ErrorToastMessage message='common:global.error' />)
    };
};


// function that returns an object literal
export const postEmailConfigRequest = (payload) => ({
    type: Types.POST_EMAIL_CONFIG_REQUEST,
    payload : payload,
});

// function that returns an object literal
export const postEmailConfigSuccess = () => ({
    type: Types.POST_EMAIL_CONFIG_SUCCESS,
});

// function that returns an object literal
export const postEmailConfigFailure = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_EMAIL_CONFIG_FAILURE,
        });
        toast.error(<ErrorToastMessage message='common:global.error' />)
    };
};

// function that returns an object literal
export const postEmailConfigError = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_EMAIL_CONFIG_ERROR,
        });
        toast.error(<ErrorToastMessage message='common:global.error' />)
    };
};


export const cleanListToAccept = (data) => ({
    type: Types.CLEAN_LIST_TO_ACCEPT,
    payload: data
});

export const resetAllData = () => ({
    type: Types.RESET_ALL_DATA,
});

const ErrorToastMessage = ({ message }) => (
    <div>
        <Translation ns="common">
            {
                (t, { i18n }) => <h6>{t(message)}</h6>
            }
        </Translation>
    </div>

)
