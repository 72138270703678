import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {connect} from "react-redux";
import {getCurrentEnvironnement} from "../../../utils/Common";
import '@contentful/forma-36-react-components/dist/styles.css';
import {postComboExtDataRequest, postExtDataRequest, postExtDataReset} from "../../../actions/extdata";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {convertersAlphaToNumber, convertersNumberToAlpha} from "../../../utils/utils";
import Tooltip from "@material-ui/core/Tooltip";
import AsyncSelect from 'react-select/async';

import colors from "../../../assets/themes/Colors";

import {apiV2} from "../../../api/indexV2";
import {isNull} from "lodash";
import Select from "react-select";
import {createEmptyRow} from "../../../utils/Fields";
import {Checkbox} from "@material-ui/core";


function getModalStyle() {
    const top = 5;

    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        //padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '90%',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
        //border: '3px solid pink',
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    marginLeft:{
        marginLeft : '10px'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    tableRoot: {
        width: "100%", //it was 100%
        maxHeight : "50vh",
        marginTop: theme.spacing(1),
        overflowY: "scroll",
        //marginRight: 'auto',
    },
    scrollBar: {
        overflow: 'auto',
        overflowX: 'auto',
        overflowY: 'auto',
        scrollbarWidth: 'thin', //without buttons on firefox, change to 'auto' if buttons wanted
        scrollbarColor: '#9B9B9B #E3E3E3', //first color to scrollbutton and the second to the bar
        "&::-webkit-scrollbar": {
            display: "block",
            overflow: 'auto',
            overflowX: 'auto',
            overflowY: 'auto',
            height: '10px',
            background: '#E3E3E3',
        },
        "&::-webkit-scrollbar-thumb": {
            background: '#9B9B9B',
        },
    },
    popupScrollBar: {
        padding: '5px',
        overflow: 'auto',
        overflowX: 'auto',
        scrollbarWidth: 'thin', //without buttons on firefox, change to 'auto' if buttons wanted
        scrollbarColor: '#9B9B9B #E3E3E3', //first color to scrollbutton and the second to the bar
        "&::-webkit-scrollbar": {
            display: "block",
            overflowX: 'auto',
            height: '10px',
            background: '#E3E3E3',
        },
        "&::-webkit-scrollbar-thumb": {
            background: '#9B9B9B',
        },
        //if buttons wanted
        /*  "&::-webkit-scrollbar-button:single-button": {
             backgroundColor: '#bbbbbb',
       display: 'block',
       height: '13px',
       width: '16px',
         }, */

    },
    sousHeader:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    titleInfo:{
        color: colors.grey,
        fontSize: 18,
        fontWeight: 'bold',
    },
    tooltipStyle:{
        backgroundColor: colors.main,
        color: colors.white,
        fontSize: 10,
        textAlign: 'center',
    },
    importBtn: {
        borderRadius: 4,
        alignSelf: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        backgroundColor: colors.mainButton,
        color: 'white',
        padding: '5px 16px',
        fontSize: 18,
        cursor: 'pointer',
    }
}));

const selectStyles = {
    menu: base => ({
        ...base,
        zIndex: 100
    })
};
const AsyncSelectComponent = (props) => {

    const [items, setItems] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [options, setOptions] = useState([]);

    const [selectedValue, setSelectedValue] = useState(null);

    // handle selection
    const handleChange = value => {
        if(typeof value !== "undefined"){
            console.log(value);
            setSelectedValue(value);
            props.refColumns[props.index] = props.filter.viewColumn;
            props.refValues[props.index] = isNull(value) ? "" : value.value;
            props.setRefColumns(props.refColumns);
            props.setRefValues(props.refValues);
            props.handleChangeSelect();
        }
    }

    useEffect(() => {
        if(props.refresh){
            fetch();
        }
    }, [props.refresh])

    useEffect(() => {
        if(props.open){
            fetchInit();
        }
    }, [props.open])

    const fetchInit = () => {
        let actualIndex = props.index;
        let refValues = [];
        let refColumns = [];

        let resolve = new Promise(async (resolve, reject) => {

            if (props.refValues.length === 0) {
                await props.filters.map((elm, idx) => {
                    refValues.push("");
                })
            }else{
                await props.refValues.map(async (elm, idx) => {
                    if (actualIndex >= 0) {
                        if(elm !== ""){
                            if(props.filters[(idx)].viewColumn !== props.filter.viewColumn){
                                refColumns.push(props.filters[(idx)].viewColumn);
                            }else{
                                refValues.push("");
                            }
                            refValues.push(elm);
                        }
                    }
                })
            }

            resolve(true);

        })

        if(resolve){
            let payload = {
                "query": "DISTINCT",
                "refcolumns": refColumns,
                "refvalue": refValues,
                "viewcolumns": [
                    props.filter.viewColumn
                ],
                "value": "",
                "userhash": null,
                "file": props.refFile,
                "ownerId": props.ownerId,
                "header": false,
                "mode": 7,
                "all": false
            }
            apiV2.post('/Formats/Table/ComboListFillIn.php', payload).then(result => {
                const res = result.data.data;
                let options = [];
                if(Array.isArray(res)){
                    res.map((value) => {
                        options.push({ value: value, label: value })
                    });
                }

                let objectInRow = Object.values(props.row);
                let valueInRow = objectInRow[(Number(props.filter.refColumn) - 1)];
                console.log(valueInRow);
                if(typeof valueInRow !== "undefined"){
                    let option = options.find(elm => elm.value === valueInRow);
                    console.log(option);
                    handleChange(option);
                }

                setOptions(options);

            });
        }
    }

    const fetch = () => {
        let actualIndex = props.index;
        let refValues = [];
        let refColumns = [];

        let resolve = new Promise(async (resolve, reject) => {

            if (props.refValues.length === 0) {
                await props.filters.map((elm, idx) => {
                    refValues.push("");
                })
            }else{
                await props.refValues.map(async (elm, idx) => {
                    if (actualIndex >= 0) {
                        if(elm !== ""){
                            if(props.filters[(idx)].viewColumn !== props.filter.viewColumn){
                                refColumns.push(props.filters[(idx)].viewColumn);
                            }else{
                                refValues.push("");
                            }
                            refValues.push(elm);
                        }
                    }
                })
            }

            resolve(true);

        })





        if(resolve){
            let payload = {
                "query": "DISTINCT",
                "refcolumns": refColumns,
                "refvalue": refValues,
                "viewcolumns": [
                    props.filter.viewColumn
                ],
                "value": "",
                "userhash": null,
                "file": props.refFile,
                "ownerId": props.ownerId,
                "header": false,
                "mode": 7,
                "all": false
            }
            apiV2.post('/Formats/Table/ComboListFillIn.php', payload).then(result => {
                const res = result.data.data;
                let options = [];
                if(Array.isArray(res)){
                    res.map((value) => {
                        options.push({ value: value, label: value })
                    });
                }
                setOptions(options);
            });
        }
    }


    return (
        <Select
            isClearable={true}
            isMulti={props.filter.multiple}
            styles={selectStyles}
            key={props.index}
            cacheOptions
            defaultOptions
            value={selectedValue}
            getOptionLabel={e => e.value}
            getOptionValue={e => e.index}
            options={options}
            onChange={handleChange}
        />
    )
}

const ModalListComboFillExtDataComponent = (props) => {

    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [t, i18n] = useTranslation('common');

    const [key, setKey] = React.useState(0);
    const [hash, setHash] = React.useState(props.owner);
    const [refFile, setRefFile] = React.useState(props?.options?.ref || '');
    const [filters, setFilters] = React.useState(props?.options?.filters || []);
    const [value, setValue] = React.useState(null);
    const [refRefreshAllDatas, setRefreshAllDatas] = React.useState(false);

    const [refColumns, setRefColumns] = React.useState([]);
    const [refValues, setRefValues] = React.useState([]);


    const [rows, setRows] = useState([])

    const handleCloseModal = () => {
        props.close();
    };

    const handleConfirmSelection = () => {
        props.handleSelectDataMultiCombo(rows, props.currentColumn, props.currentRow);
        props.close();
    };

    const handleSelectData = (event, item) => {
        event.preventDefault();
        event.stopPropagation();
        if (!rows.includes(item)) {
            setRows([...rows, item]);
        } else {
            let tmpRows = rows.filter((row) => row !== item);
            setRows(tmpRows);
        }
    }

    const handleSelectAllData = () => {
        if (props.list.length > 0)
        {
            if(rows.length < props.list?.length) {
                setRows(props.list);
            }
            else {
                setRows([]);
            }
        }
    }

    const handleChangeSelect = () => {

        if(refColumns.length > 0 && refValues.length > 0){
            setRefreshAllDatas(true);
        }
    }

    const fetchDatas = () => {

        let payload = {
            "query": "",
            "refcolumns": refColumns,
            "refvalue": refValues,
            "viewcolumns": [],
            "userhash": null,
            "value": "",
            "file": refFile,
            "ownerId": props.ownerId,
            "header": true,
            "mode" : 7,
            "all" : true
        }

        props.attemptGetComboDataFromAnotherBaseByClient(payload);


    };


    useEffect(() => {
        if(refRefreshAllDatas){
            if(refValues.every(element => element === "")){
                props.attemptGetDataFromAnotherBaseByClientReset();
            }else{
                fetchDatas();
            }
            setRefreshAllDatas(false);
        }
    }, [refRefreshAllDatas]);


    let colId = props?.options?.columns?.split(';')

    let colHeadTitle = []

    return (
        <Modal
            key={key}
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div style={modalStyle} className={classes.popup}>
                <div className = "popup_wrapper flexSpacebetween" >
                    <div className="popup_wrapper_content">
                        <div className={classes.sousHeader}>
                            <div>
                                <Tooltip  title={props.titleFormat} aria-label="add" placement="right" classes={{tooltip: classes.tooltipStyle}}>
                                <span id="icon-more" className={`material-icons md-30`}>
                                    info
                                </span>
                                </Tooltip>
                            </div>

                            <div  className={classes.titleInfo}>{props.titlePerso} : {props.value}</div>

                            <div onClick={handleCloseModal}>
                                {
                                    rows.length > 0 &&
                                    <span id="icon-close" className={classes.importBtn} onClick={handleConfirmSelection}>
                                        {t('global.import')}
                                    </span>
                                }
                                    <span id="icon-close" className="material-icons md-50 lightgrey">
                                    close
                                    </span>
                            </div>
                        </div>
                        <span> {props.list.length} result(s) </span>

                        <div>
                            {filters.map((filter, index) => {
                                return (
                                    <AsyncSelectComponent
                                        {...props}
                                        key={index}
                                        ownerId={props.ownerId}
                                        refFile={refFile}
                                        filter={filter}
                                        index={index}
                                        handleChangeSelect={handleChangeSelect}
                                        setRefColumns={setRefColumns}
                                        refColumns={refColumns}
                                        setRefValues={setRefValues}
                                        refValues={refValues}
                                        filters={filters}
                                        refresh={refRefreshAllDatas}
                                        row={props.row}
                                    />
                                )
                            })}
                        </div>

                            {props.listHeader.length > 0 &&
                            <TableContainer component={Paper} className={classes.tableRoot} classes={{root: classes.scrollBar}}>
                                <Table className={classes.table} aria-label="simple table" stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center" style={{fontWeight: 'bold'}}>
                                                <div className={classes.checkboxCtn}>
                                                    {t('ext-table.select-all')}
                                                    <Checkbox
                                                        value={rows.length === props.list.length}
                                                        style={{color: colors.main, width: '30px', marginRight: '5px'}}
                                                        checked={rows.length === props.list.length}
                                                        onClick={handleSelectAllData}
                                                    />
                                                </div>
                                            </TableCell>
                                            {
                                                colId.map((col, index) => {
                                                let realcolLetter = col.replace(/[^A-Za-z]/g, '');
                                                props.listHeader.map((header, index) => {
                                                    let numberIndex = (index + 1);
                                                    if (realcolLetter === convertersNumberToAlpha(numberIndex)) {
                                                        let alphaIndexFound = props.options.columns.includes(convertersNumberToAlpha(numberIndex));
                                                        if(alphaIndexFound){
                                                            colHeadTitle.push(header.COLUMN_NAME)
                                                        }
                                                        else return null;
                                                    } else return null
                                                })
                                            })
                                            }
                                            {
                                                colId.map((col, index) => {
                                                    let realcolLetter = col.replace(/[^A-Za-z]/g, '');
                                                    return <TableCell align="center" key={index} style={{fontWeight: 'bold'}}>{colHeadTitle[index]} ( {realcolLetter} )</TableCell>
                                                })
                                            }
                                        </TableRow>
                                    </TableHead>
                                    {
                                        props.list.length > 0 && colHeadTitle.length > 0 &&
                                        <TableBody>
                                        {
                                            props.list.map((item, index) => {
                                                return (
                                                    <TableRow key={index} className="cursor">
                                                        <TableCell  align="center" key={index}>
                                                            <div className={classes.visibleBox}>
                                                                <Checkbox
                                                                    value={item}
                                                                    style={{color: colors.main, width: '30px', marginRight: '5px'}}
                                                                    checked={rows.includes(item)}
                                                                    onChange={(event) => {handleSelectData(event,item)}}
                                                                />
                                                            </div>
                                                        </TableCell>
                                                        {
                                                            colHeadTitle.map((col, rindex) => {
                                                                return (
                                                                    <TableCell align="center" key={rindex}>{item[col]}</TableCell>
                                                                )
                                                            })
                                                        }
                                                    </TableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                    }
                            </Table>
                        </TableContainer>
                        }
                    </div>
                </div>
            </div>
        </Modal>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        list : state?.extdata?.list || [],
        listHeader : state?.extdata?.listHeader || [],
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptGetDataFromAnotherBaseByClientReset: () => dispatch(postExtDataReset()),
        attemptGetDataFromAnotherBaseByClient: (payload) => dispatch(postExtDataRequest(payload)),
        attemptGetComboDataFromAnotherBaseByClient: (payload) => dispatch(postComboExtDataRequest(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalListComboFillExtDataComponent)
