import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {
    acceptShareModelShareRequest,
    delModelUniqueRequest,
    delShareModelShareRequest,
    getModelSharedUniqueRequest,
    getModelsLightCountablePlugnoteRequest,
    getModelsLightRequest,
    getModelsPaginateRequest,
    getModelsRequest,
    getModelUniqueRequest,
    getModelUniqueReset,
    postModelDuplicateRequest,
    postModelZapierRequest
} from '../../actions/model'
import {confirmAlert} from 'react-confirm-alert'; // Import
import Box from '@material-ui/core/Box';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

import {Content, Header, } from "rsuite";
import {
    delAnnexes,
    deleteHistoryAction,
    delModel,
    delPlugnote, delTypeOfPlugnote,
    getCurrentEnvironnement,
    getFolderTopRow,
    getLanguage,
    getModel, getModelView, getToken,
    setAnnexes,
    setCurrentEnvironnement,
    setFolderTopRow,
    setLoadModel,
    setModel,
    setModelView
} from "../../utils/Common";
import Select from "@material-ui/core/Select";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getFieldsRequest} from "../../actions/field";
import {ListAlt, ViewList, Storage, Help} from "@material-ui/icons";
import ModalModelShareComponent from "../../components/modals/ModalModelShareComponent";
import {postModelGenerateMultiplePlugcodeRequest, reduxGeneratePlugformcodeReset} from "../../actions/plugcode";
import {trackPromise, usePromiseTracker} from "react-promise-tracker";
import {reduxGenerateShareReset} from "../../actions/share";
import {delAllSearchParamsRequest} from "../../actions/search";
import ModalCreateUniversComponent from "../../components/modals/ModalCreateUniversComponent";
import ListItemText from "@material-ui/core/ListItemText";
import {
    reduxDelAllDataFilterRequest,
    reduxDelGlobalApiDataFilterRequest,
    reduxGenerateDataPasswordReset
} from "../../actions/data";
import MenuItem from "@material-ui/core/MenuItem";
import * as moment from "moment";
import Popover from "@material-ui/core/Popover";
import MenuLeftSearchComponent from "../../components/searchs/MenuLeftSearchComponent";
import HeaderSearch from "../../components/searchs/HeaderSearch";
import Images from "../../assets/themes/Images";
import {camelCase} from "lodash";
import Tooltip from "@material-ui/core/Tooltip";
import {GridOverlay, useGridApiRef, XGrid} from "@material-ui/x-grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import i18n from "i18next";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ModalBiblioTemplateComponent from "../../components/modals/ModalBiblioTemplateComponent";
import ModalInviteComponent from "../../components/modals/ModalInviteComponent";
import SubModelMenuPortaComponent from "../../components/specials/portal/SubModelMenuPortalComponent";
import ModalModelPlugcodeComponentV2 from "../../components/modals/ModalModelPlugcodeComponentV2";
import {getBool} from "../../utils/utils";
import {reduxGenerateAnnexeReset} from "../../actions/annexe";
import { styled } from '@material-ui/core';
import colors from '../../assets/themes/Colors';
import { DesignedGrid } from '../../components/custom/CustomComponents';
import axios from "axios";
import ApplicationConfig from "../../config/ApplicationConfig";
import ModalHelpComponent from "../../components/modals/ModalHelpComponent";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor : 'white',
    },
    fieldImage: {
        height: 24,
        width: 24
    },
    black:{
        color : 'black'
    },
    iconRefresh:{
        float : 'right'
    },
    grid:{
        minHeight : '40vh',
        height : '80vh',
        maxHeight : '80vh'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        padding: '15px 0px 0px 30px'
    },
    paperAnchorDockedLeft : {
        boxShadow: '0px 0px 6px 4px rgba(136, 136, 136, 0.12)',
        border : 0,
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: '57px'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    openClose: {
        bottom: 0,
        position: 'absolute',
        width : '100%'
    },
    openCloseRelative: {
        bottom: 0,
        position: 'relative',
        width : '100%'
    },
    openCloseButton : {
        float : 'right',
        marginRight :'5px'
    },
    openedCloseButton : {
        float : 'right',
        marginRight :'20px'
    },
    content: {
        width : '100%',
        padding: '64px 20px',
        backgroundColor: 'white',
    },
    contentModelGrid : {
        display: 'flex',
        backgroundColor : 'white',
        flexWrap : 'wrap',
        justifyContent: 'space-evenly',
        gridColumnGap: '20px',
        columnGap: '20px',
        padding: '0 20px',
        maxHeight : 'calc(100vh - 150px)',
    },
    contentModelList : {
        backgroundColor : 'white',
    },
    contentEmpty : {
      height : '85vh',
      overflow : 'scroll',
    },
    icon:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : '#DADADA'
    },
    iconSearch:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : '#DADADA'
    },
    iconSearchBis:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : '#000000'
    },
    iconBlack:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : '#000000'
    },
    iconActive:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : '#58C3C1'
    },
    iconSearchActive:{
        lineHeight: '56px',
        textAlign: 'center',
        color : '#58C3C1'
    },
    gridHeader : {
        padding: '10px 20px !important',
        width: '100%',
    },
    selectEnvironnement : {
    },
    iconSelect : {
        color : '#58C3C1 !important'
    },
    input: {
        padding: '0 !important'
    },
    marginLeftAuto :{
        marginLeft : 'auto'
    },
    pointer:{
        cursor: 'pointer'
    },
    plugcodeCtn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

const listItemIconStyles = makeStyles((theme) => ({
    icon: {
        minWidth: '40px',
        color : '#58C3C1'
    }
}));

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const getCodePFCode = (model) => {
    if(typeof model.creationdate.date === "undefined"){
        return model.codepfcode != null ? 'PF-' + moment(model.creationdate).format('YYMMDD') + '-' + model.codepfcode : ''
    }else{
        return model.codepfcode != null ? 'PF-' + moment(model.creationdate.date).format('YYMMDD') + '-' + model.codepfcode : ''
    }
}

function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    );
}


const ViewModels = ({models, listView, handleSelectModel,handleCreateModel,handleDeleteModel,handleSetStatusZapier,handleModalModelShareOpen,handleModalModelShareClose,handleModalModelPlugcodeOpen,handleModalModelPlugcodeClose,handleGeneratePlugcodeForAllPlugnoteModel,handleFlip, flipped, history,
                        attemptGetModelsPaginate,hash,limitPage,startPage,language,refresh, setRefresh, isZapier, attemptRemoveSharedForModel,handleSelectSharedModel,
                        noSharedModels , yesSharedModels,listOwnerSharedFilter,countYesSharedModels,handleDuplicateModel,handleSelectModelEdit, handleTranslate, handleLayout, attemptGetModelsLightCountablePlugnote
                    ,attemptResetAnnexe,attemptResetDataPassword,attemptGetModelUniqueRequest,attemptGetModelSharedUniqueRequest,invite,handleSelectReadModel}) => {

    const classes = useStyles();
    const environment = getCurrentEnvironnement();

    const { t, i18n } = useTranslation('common');

    const initialRows = [];
    let initColumns = [];
    const apiRef = useGridApiRef();

    const qrcodeAction = (object) => {

/*        if(object.row.shared){
            return (
                <div onClick={(event) => { handleSelectSharedModel(object.row.model) }} style={{ cursor: "pointer", width : "100%" }}>
                    <span>
                        {object.row.plugcode}
                    </span>
                </div>
            )
        }else{
            return (
                <div className="float_left" onClick={(event) => {
                    setModel(object.row.model);
                    handleModalModelPlugcodeOpen();
                }} style={{ cursor: "pointer", width : "100%" }}>
                <span className={`material-icons md-small ${object.row.model.actifpfcode ? 'green' : ''}`}>
                    qr_code
                </span>
                    {
                        getCodePFCode(object.row.model)
                    }
                </div>
            )
        }*/

        if(object.row.shared){
            return (
                <div onClick={(event) => { handleSelectSharedModel(object.row.model) }} style={{ cursor: "pointer", width : "100%" }}>
                    {object.row.plugcode.length > 0 &&
                        <span className={classes.plugcodeCtn}>
                        {object.row.plugcode}
                            {
                                object.row.model?.pfcode_countopen > 0 && <span className="references_8"> ({object.row.model?.pfcode_countopen}) </span>
                            }
                    </span>
                    }
                </div>
            )
        }
        else if(object.row.read){
            return (
                <div onClick={(event) => { handleSelectReadModel(object.row.model) }} style={{ cursor: "pointer", width : "100%" }}>
                    {object.row.plugcode.length > 0 &&
                        <span className={classes.plugcodeCtn}>
                        {object.row.plugcode}
                            {
                                object.row.model?.pfcode_countopen > 0 && <span className="references_8"> ({object.row.model?.pfcode_countopen}) </span>
                            }
                    </span>
                    }
                </div>
            )
        }
        else if(object.row.model.actifpfcode){
            return (
                <div className="float_left" onClick={(event) => {
                    setModel(object.row.model);
                    handleModalModelPlugcodeOpen();
                }} style={{ cursor: "pointer", width : "100%", display: 'flex', alignItems: 'center', flexDirection: 'row', }}>

                    <span className={classes.plugcodeCtn}>
                        <span>
                            <span className='material-icons md-small green'>
                                qr_code
                            </span>
                        {
                            getCodePFCode(object.row.model)
                        }
                        </span>
                    {
                        object.row.model?.pfcode_countopen > 0 && <span className="references_8"> ({object.row.model?.pfcode_countopen}) </span>
                    }
                    </span>
                </div>
            )
        }
        else{
            return (
                <div className="float_left generatePfCode" onClick={(event) => {
                    setModel(object.row.model);
                    handleModalModelPlugcodeOpen();
                }} style={{ cursor: "pointer", width : "100%" }}>
                <span className={'material-icons md-small'}>
                    qr_code
                </span>
                    Generate Plugcode
                </div>
            )
        }
    };

    const [visibleOwner, setVisibleOwner] = useState(countYesSharedModels === 0);
    const [visibleSubMenuEdit , setVisibleSubMenuEdit] = useState(null);

    const viewAction = (object) => {
        return (
            <div onClick={(event) => {
                handleSelectModel(object.row.model);
            }} style={{ cursor: "pointer", width : "100%" }}>
                <span className="material-icons md-small">
                    visibility
                </span>
            </div>
        )
    };

    const viewValueAction = (object) => {

        return (
            <div onClick={(event) => { object.row.shared ? handleSelectSharedModel(object.row.model) : object.row?.read ? handleSelectReadModel(object.row.model) :  handleSelectModel(object.row.model)}} style={{ cursor: "pointer", width : "100%" }}>
                {
                    object.value !== "" ?
                        <span>

                    {object.value}

                </span>
                        :
                        <span>&nbsp;</span>
                }

            </div>
        )
    }

    const viewOwnerAction = (object) => {

        return (
            <div onClick={(event) => { object.row.shared ? handleSelectSharedModel(object.row.model) : object.row?.read ? handleSelectReadModel(object.row.model) :  handleSelectModel(object.row.model)}} style={{ cursor: "pointer", width : "100%" }}>
                {
                    object.value !== "" ?
                        <span className={object.row.shared === 'true' || object.row.shared === 'true' ? 'blue' : 'green'}>
                            {object.value}
                        </span>
                        :
                        <span>&nbsp;</span>
                }

            </div>
        )
    }

    const folderValueAction = (object) => {

        return (
            <div onClick={(event) => { object.row.shared ? handleSelectSharedModel(object.row.model) : object.row?.read ? handleSelectReadModel(object.row.model) :  handleSelectModel(object.row.model)}} style={{ cursor: "pointer", width : "100%" }}>
                <span className={`material-icons md-30 ${object.row.shared || object.row?.read ? 'blue'  : 'green'}`}>
                    list_alt
                </span>
                <span>&nbsp;&nbsp;</span>
                <span>
                    {object.value}
                </span>
            </div>
        )
    }

    const viewLabelValueAction = (object) => {
        let column = object.column.key;
        let value = object.row[column];

        return (
            <div onClick={(event) => {
                handleSelectModel(object.row.model);
            }} style={{ cursor: "pointer", width : "100%" }}>
                        <span >
                            {value}
                        </span>
            </div>
        )
    }

    const addAction = (object) => {
        if (object.row.shared) {
            if(object.row.model.ms_add_fiche > 0){
                return (
                    <div className="d-flex justify-content-between align-items-center grey" style={{ cursor: "pointer" }}>
                        <div>
                            <span className="material-icons md-medium"  onClick={(event) => {
                                attemptResetAnnexe();
                                attemptResetDataPassword();
                                delPlugnote();
                                delTypeOfPlugnote();
                                delAnnexes();
                                setAnnexes([]);
                                setModel(object.row.model);
                                attemptGetModelSharedUniqueRequest(object.row.model.hash,environment.userhash, environment.userhash)
                                history.push('/create-plugnote-shared');
                                //attemptGetModelUniqueRequest(object.row.model.hash,environment.userhash, environment.userhash)
                            }}>
                                add
                            </span>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div onClick={(event) => { handleSelectSharedModel(object.row.model)}} style={{ cursor: "pointer", width : "100%" }}>
                        <span>&nbsp;</span>
                    </div>
                )
            }
        }else if(object.row.read){
            return (
                <div onClick={(event) => { handleSelectReadModel(object.row.model)}} style={{ cursor: "pointer", width : "100%" }}>
                    <span>&nbsp;</span>
                </div>
            )
        }
        else{
            return (
                <div onClick={() => {
                    attemptResetAnnexe();
                    attemptResetDataPassword();
                    delPlugnote();
                    delTypeOfPlugnote();
                    delAnnexes();
                    setAnnexes([]);
                    setModel(object.row.model);
                    attemptGetModelUniqueRequest(object.row.model.hash, environment.userhash, environment.userhash)
                    history.push('/create-plugnote');
                    console.log('row: ', object.row)
                }} style={{ cursor: "pointer", width : "100%" }}>
                    <span className="material-icons md-medium grey">
                        add
                    </span>
                </div>
            )
        }
    };

    const partageAction = (object) => {

        if(object.row.shared){
            return (
                <div onClick={(event) => { handleSelectSharedModel(object.row.model)}} style={{ cursor: "pointer", width : "100%" }}>
                    <span>&nbsp;</span>
                </div>
            )
        }
        if(object.row.read){
            return (
                <div onClick={(event) => { handleSelectReadModel(object.row.model)}} style={{ cursor: "pointer", width : "100%" }}>
                    <span>&nbsp;</span>
                </div>
            )
        }
        else{
            return (
                <div onClick={(event) => {
                    setModel(object.row.model);
                    handleModalModelShareOpen();
                }} style={{ cursor: "pointer", width : "100%" }}>
                <span className={`material-icons md-small ${object.row.model.numshared ? 'green' : ''}`}>
                    group_add
                </span>
                    { object.row.model.numshared > 0 &&
                    <span className="font_size_10 green"> ({object.row.model.numshared})</span>
                    }
                </div>
            )
        }
    };

    const allActions = (object) => {

        if(object.row.shared){
            return (
                <div className="d-flex justify-content-between align-items-center grey" style={{ cursor: "pointer" }}>
                    <div>
                        <Tooltip title={i18n.t('common:actions.delete-shared')} aria-label="add" placement="top-start">

                        <span className="material-icons md-medium" onClick={(event) => { _handleSubmitModelShare(object)
                        }}>
                            delete
                        </span>
                        </Tooltip>
                    </div>
                </div>
            )
        }
        else if(object.row.read){
            return (
                <div className="d-flex justify-content-between align-items-center grey" style={{ cursor: "pointer" }}>
                    <div>
                        <span>&nbsp;</span>
                    </div>
                </div>
            )
        }
        else{

            if(!invite){
                return (
                    <div className="display_flex float_right grey">
                        <PopupState variant="popover" popupId={object.row.model.hash}>
                            {(popupState) => (
                                <div>
                            <span aria-describedby={object.row.model.hash} className="material-icons md-medium" {...bindTrigger(popupState)}>
                                more_horiz
                            </span>
                                    <Popover
                                        {...bindPopover(popupState)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                    >
                                        <Box p={2}>
                                            <div className="flex_display_direction_column">
                                                <SubModelMenuPortaComponent
                                                    object={object}
                                                    history={history}
                                                    handleSelectModelEdit={handleSelectModelEdit}
                                                />
                                                <Tooltip title={t('common:models.translate')} aria-label="add" placement="top">
                                                    <span className="p3 cursor" onClick={(event) => handleTranslate(object.row.model)}>
                                                        <span className="material-icons md-25 black padding_0_20" >translate</span>
                                                        <span>{t('common:models.translate')}</span>
                                                    </span>
                                                </Tooltip>
                                                <Tooltip title={t('common:models.layout')} aria-label="add" placement="top">
                                                    <span className="p3 cursor" onClick={(event) => handleLayout(object.row.model)}>
                                                        <span className="material-icons md-25 black padding_0_20" >preview</span>
                                                        <span>{t('common:models.layout')}</span>
                                                    </span>
                                                </Tooltip>
                                                <Tooltip title={t('common:models.delete')} aria-label="add" placement="top">
                                                    <span className="p3 cursor" onClick={(event) => handleDeleteModel(object.row.model)}>
                                                        <span className="material-icons md-25 black padding_0_20" >delete</span>
                                                        <span>{t('common:models.delete')}</span>
                                                    </span>
                                                </Tooltip>

                                                <Tooltip title={t('common:models.duplicate')} aria-label="add" placement="top">
                                                    <span className="p3 cursor" onClick={(event) => handleDuplicateModel(object.row.model)}>
                                                        <span className="material-icons md-25 black padding_0_20" >control_point_duplicate</span>
                                                        <span>{t('common:models.duplicate')}</span>
                                                    </span>
                                                </Tooltip>
                                                <Tooltip title={t('common:models.manage-plugcodes-multiple')} aria-label="add" placement="top">
                                                    <span className="p3 cursor" onClick={(event) => {handleGeneratePlugcodeForAllPlugnoteModel(object.row.model)}}>
                                                        <span className="md-25 black padding_0_20">
                                                            <img
                                                                className={classes.fieldImage} src ={Images[camelCase("gridIconSvg")]}
                                                                alt={t('common:models.manage-plugcodes-multiple')}
                                                                style={{width : '25px'}}
                                                            />
                                                        </span>
                                                        <span>{t('common:models.manage-plugcodes-multiple')}</span>
                                                    </span>
                                                </Tooltip>
                                                {
                                                    isZapier &&
                                                    <Tooltip title={ object.row.model.isZapier > 0  ? t('common:models.remove_zapier_link') : t('common:models.active_zapier_link')} aria-label="add" placement="top">
                                                <span className="p3 cursor" onClick={(event) => handleSetStatusZapier(object.row.model)}>
                                                    { object.row.model.isZapier > 0 ?
                                                        <span className="material-icons md-25 green padding_0_20" >api</span>
                                                        :
                                                        <span className="material-icons md-25 red padding_0_20" >api</span>
                                                    }
                                                    { object.row.model.isZapier > 0 ?
                                                        <span>{t('common:models.remove_zapier_link')}</span>
                                                        :
                                                        <span>{t('common:models.active_zapier_link')}</span>
                                                    }
                                                </span>
                                                    </Tooltip>
                                                }
                                            </div>
                                        </Box>
                                    </Popover>
                                </div>
                            )}
                        </PopupState>
                    </div>
                )
            }else return null;
        }
    }

    const handleCreateModelClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        handleCreateModel();
    }

    const headerView = (object) => {
        return (
            <div
                className=""
            >
                <span>{object.colDef.headerName}</span>
                <div className="float_right" onClick={(event) => handleCreateModelClick(event)}>
                    <span className="material-icons md-25 md-small">
                    add
                    </span>
                    <span className="md-small">{i18n.t('common:global.new-form')}</span>
                </div>
            </div>
        );
    }

    const headerShareColumn = (object) => {
        return (
            <span >
                {t('models.header-share')}
            </span>
        )
    }

    const headerTitleViewCountable = (object) => {
        return (
            <span>
                {object.colDef.headerName}
                <span className={`material-icons md-small`} onClick={(event) => handleRefreshNumberOfPlugnotes(event)}>
                    refresh
            </span>
            </span>
        )
    }

    const handleRefreshNumberOfPlugnotes = (event) => {
        event.preventDefault();
        event.stopPropagation();
        attemptGetModelsLightCountablePlugnote(hash);
    }

    if(models.length > 0 ){
        initColumns.push(
            // { key: 'view', name:  '', resizable: true, sortable : false , width: '5%', formatter : viewAction },
            { field: 'username', key: 'username', headerName: t('models.header-owner'), resizable: true, sortable : true,
                disableClickEventBubbling: true,
                renderCell: viewOwnerAction,
                width: 200, headerAlign: 'center', align : "center",
                hide : visibleOwner,
            },
            { field: 'label', key: 'label', headerName:  t('common:models.models'), resizable: true,  sortable : true , renderCell : folderValueAction , width: 450 , headerAlign: 'center'},
            { field: 'plugnote', key: 'plugnote', headerName: t('models.header-number'), resizable: true, sortable : true, width: 100 , headerAlign: 'center', align : "center"},
            { field: 'plugnote-add', key: 'plugnote-add', headerName: t('models.header-add'), resizable: true, sortable : false, renderCell : addAction , width: 120, headerAlign: 'center' , align : "center"},
            { field: 'clef', key: 'clef', headerName: t('models.header-key'),
                resizable: true,
                sortable : true,
                draggable: true,
                renderCell : viewValueAction,
                width: 100, headerAlign: 'center', align : "center"
            },
            { field: 'partage', key: 'partage', headerName:  t('models.header-share'), resizable: true, sortable : false,
                renderCell : partageAction,
                renderHeader: headerShareColumn,
                width: 100, headerAlign: 'center', align : "center"},
            { field: 'qrcode', key: 'qrcode', headerName:  t('models.header-pfcode'), resizable: true, sortable : false, renderCell : qrcodeAction, width: 200, headerAlign: 'center', align : "center"},
            { field: 'actions', key: 'actions', headerName:  'Actions', resizable: true, sortable : false , renderCell : allActions, width: 120, headerAlign: 'center', align : "center"}
        );
    }

    if(models.length > 0 ){
        models.map((model, index) => {

            let finalObject = {};

            finalObject['id'] = model['id'];
            finalObject['clef'] = getBool(model.shared) === true ? '[' + 'P' + '-' + model['clef'] + ']' :  '[' + environment.envIndex + '-' + model['clef'] + ']';
            finalObject['username'] = getBool(model.shared) === true ? model['username'] : t('common:models.my-models');
            finalObject['label'] = model['label'];
            finalObject['model'] =  model;
            finalObject['plugcode'] = getCodePFCode(model);
            //finalObject['plugnote'] =  isNaN(model.nbFichesNotArchived + model.nbFichesArchived) ? "" : model.nbFichesNotArchived + model.nbFichesArchived;
            finalObject['plugnote'] =  model.numdatas;
            finalObject['shared'] =  getBool(model.shared) === true ;
            finalObject['read'] =  getBool(model.read) === true ;

            if(noSharedModels === true && yesSharedModels === true){
                if(listOwnerSharedFilter.length > 0 && getBool(model.shared) === true  && listOwnerSharedFilter.filter(e =>  e.username.localeCompare(model['username']) === 0).length > 0){
                    initialRows.push(finalObject);
                }else if(listOwnerSharedFilter.length  === 0){
                    initialRows.push(finalObject);
                }
            }
            else if(noSharedModels === true && yesSharedModels === false && getBool(model.shared) === false){
                initialRows.push(finalObject);
            }
            else if(noSharedModels === false && yesSharedModels === true && getBool(model.shared) === true){
                if(listOwnerSharedFilter.length > 0 && listOwnerSharedFilter.filter(e =>  e.username.localeCompare(model['username']) === 0).length > 0){
                    initialRows.push(finalObject);
                }else if(listOwnerSharedFilter.length  === 0){
                    initialRows.push(finalObject);
                }
            }
        });
    }

    const [rows, setRows] = useState(initialRows);
    const [columns, setColumns] = useState(initColumns);
    const [sortDirection, setSortDirection] = useState("NONE");
    const [sortColumn, setSortColumn] = useState("clef");
    const [page, setPage] = useState(typeof startPage === 'undefined' ? 1 : startPage);
    const [end, setEnd] = useState(typeof limitPage === 'undefined' ? 1 : limitPage);

    const [hasMore, setHasMore] = useState(startPage < limitPage);

    const sortRows = (initialRows, sortColumn, sortDirection) => rows => {

        const comparer = (a, b) => {
            if (sortDirection === "ASC") {
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } else if (sortDirection === "DESC") {
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            }
        };

        return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
    };

    const [rowCount, setRowCount] = useState(rows.length);

    const [editRowsModel, setEditRowsModel] = useState({});
    const [loading, setLoading] = useState(false);
    const mounted = React.useRef(true);

    useEffect(() => {
        if(refresh){
            setPage(1);
            setRefresh(false);
        }
    }, [refresh]);

    useEffect(() => {
        setLoading(false);
        setRows(initialRows);
        setRowCount(initialRows.length);
        setVisibleOwner(countYesSharedModels === 0);
    }, [models, noSharedModels , yesSharedModels, listOwnerSharedFilter,countYesSharedModels]);

    useEffect(() => {
        setPage(startPage);
        setEnd(limitPage);
        if(startPage < limitPage){
            setHasMore(true);
        }
    }, [startPage, limitPage]);

    const loadServerRows = () => {
        setLoading(true);
        let nextPage = page + 1;
        setPage(nextPage);
        if(nextPage < limitPage){
            setHasMore(true);
        }else{
            setHasMore(false);
        }
        attemptGetModelsPaginate(hash,language, nextPage);
    };

    // const fetchDatas = () => {
    // };

    const storeClickedRow = (params, e) => {
        let position = apiRef.current.getScrollPosition()
        let rowId = params.id
        let row = {
            folder: hash,
            position: position,
            rowId: rowId,
        }
        setFolderTopRow(row)
    }
    const scrollGrid = () => {
        let topRow = getFolderTopRow()
        if(topRow !== null && apiRef?.current !== null) {
            if(topRow.folder === hash) {
                apiRef.current.scroll(topRow.position)
                apiRef.current.selectRow(topRow.rowId, true, false)
            }
        }
    }

    useEffect(() => {
    //restore previous position
        if(apiRef?.current !== null) setTimeout(() => {
            scrollGrid()
        }, 1200)
    }, [])

    const _handleSubmitModelShare = (object) => {

        // Alert.alert(I18n.t('confirm'), I18n.t('model-share-confirm-delete'), [
        //     {text: I18n.t('cancel'), style: 'cancel'},
        //     {text: I18n.t('ok'), onPress: () => {
        //             this.props.attemptRemoveSharedForModel(this.props.modelHash,this.props.modelShareForUser.hashModelShared)
        //         }}
        // ])

        console.log('_handleSubmitModelShare',object);

        confirmAlert({
            title: i18n.t('common:global.plugnotes'),
            message: i18n.t('common:models.model-share-confirm-delete'),
            buttons: [
                {
                    label: i18n.t('common:global.ok'),
                    onClick: () => attemptRemoveSharedForModel(object.row.model.ms_hash,object.row.model.hash)
                },
                {
                    label: i18n.t('common:global.cancel')
                },
            ]
        });
    }

    const _handleSubmitDeleteModelShare = (object) => {

        // Alert.alert(I18n.t('confirm'), I18n.t('model-share-confirm-delete'), [
        //     {text: I18n.t('cancel'), style: 'cancel'},
        //     {text: I18n.t('ok'), onPress: () => {
        //             this.props.attemptRemoveSharedForModel(this.props.modelHash,this.props.modelShareForUser.hashModelShared)
        //         }}
        // ])

        confirmAlert({
            title: i18n.t('common:global.plugnotes'),
            message: i18n.t('common:models.model-share-confirm-delete'),
            buttons: [
                {
                    label: i18n.t('common:global.ok'),
                    onClick: () => attemptRemoveSharedForModel(object.hashModelShared,object.hash)
                },
                {
                    label: i18n.t('common:global.cancel')
                },
            ]
        });
    }

    return (
        <div className="grid-container">
            <DesignedGrid
                {...rows}
                apiRef={apiRef}
                //autoHeight={true}
                className={classes.grid}
                columns={initColumns}
                disableExtendRowFullWidth={false}
                editRowsModel={editRowsModel}
                hideFooter={true}
                hideFooterPagination={true}
                loading={false}
                onCellClick={(params, e)=> storeClickedRow(params, e)}
                //onEditCellChange={handleEditCellChange}
                //onRowsScrollEnd={hasMore ? loadServerRows : null}
                rows={rows}
                rowHeight={50}
                showCellRightBorder={true}
            />
        </div>
    )
}


function ModelListLight(props) {
    const classes = useStyles();
    const classesIcons = listItemIconStyles();
    const theme = useTheme();

    const { t, i18n } = useTranslation(['common', 'models', 'global']);

    const { promiseInProgress } = usePromiseTracker();

    const [open, setOpen] = useState(false);
    const [isOpenModalModelPlugcode, setIsOpenModalModelPlugcode] = useState(false);
    const [isOpenModalModelShare, setIsOpenModalModelShare] = useState(false);
    const [isOpenModaCreateUnivers, setIsOpenModaCreateUnivers] = useState(false);

    const [countable, setCountable] = useState(0);


    const user = props.user;
    const environment = getCurrentEnvironnement();
    const [hash, setHash] = useState(!!environment ? environment.userhash : null);
    const sortedListEnvironnement = props.sortedListEnvironnement;

    const token = props.token;

    const [models, setModels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [listView, setListView] = useState(getModelView() !== 'false');
    const [activeReset, setActiveReset] = useState(false);
    const [flipped, setFlipped] = useState(-1);
    const [refresh , setRefresh] = useState(false);
    const [language , setLanguage] = useState(getLanguage());

    const [noSharedModels, setNoSharedModels] = useState(true);
    const [yesSharedModels, setYesSharedModels] = useState(true);

    const [countNoSharedModels, setCountNoSharedModels] = useState(0);
    const [countYesSharedModels, setCountYesSharedModels] = useState(0);

    const [listOwnerSharedFilter, setListOwnerSharedFilter] = useState([]);

    const [openModalBiblioTemplate, setOpenModalBiblioTemplate] = useState(false);

    const [openModalInvite, setOpenModalInvite] = useState(false);
    const [openModalHelp, setOpenModalHelp] = useState(false);

    const [openRightWorkSpace, setOpenRightWorkSpace] = useState(false);

    const [openLeftWorkSpace, setOpenLeftWorkSpace] = useState(false);


    const handleLeftWorkSpaceClose = () => {
        setOpenLeftWorkSpace(false);
    };

    const handleLeftWorkSpaceOpen = () => {
        setOpenLeftWorkSpace(true);
    };

    const handleRightWorkSpaceClose = () => {
        setOpenRightWorkSpace(false);
    };

    const handleRightWorkSpaceOpen = () => {
        setOpenRightWorkSpace(true);
    };

    const handleModalBiblioTemplateClose = () => {
        setOpenModalBiblioTemplate(false);
    }

    const handleModalBiblioTemplateOpen = () => {
        setCountable(countable + 1);
        setOpenModalBiblioTemplate(true);
    }

    const handleModalInviteOpen = () => {
        setOpenModalInvite(true);
    }

    const handleModalInviteClose = () => {
        setOpenModalInvite(false);
    }

    const [mainHash, setMainHash] = useState(!!props.profil.hash ? props.profil.hash : null)

    useEffect(() => {
        deleteHistoryAction();
        props.attemptGetModelsLight(hash);
        props.attemptGetFields();
        props.attemptDelAllDataFilter();
        props.attemptDelGlobalApiDataFilter();
    }, [hash]);

    useEffect(() => {
        //setLoadModel(false);
        setHash(!!environment ? environment.userhash : null);
    }, [getCurrentEnvironnement()]);


    useEffect(() => {
        if(language !== null){
            i18n.changeLanguage(language);
        }
    }, [language]);

    useEffect(()=> {
        setCountNoSharedModels(props.models.filter((model, index) => {
            return getBool(model.shared) === false;
        }).length)
        setCountYesSharedModels(props.models.filter((model, index) => {
            return getBool(model.shared) === true;
        }).length)
    },[props.models])

    const getOwnerFolderList = () => {
        return props.models.filter((model, index) => {
            return model.ms_read_fiche !== "0"
        })
    }

    const getOwnerFolder = () => {
        let list = getOwnerFolderList();
        let valueInfos = [];
        list.map((item, index) => {
            if(valueInfos.filter(function(e) { return e.username === item.username; }).length === 0){
                valueInfos.push({ title: item.username , username: item.username })
            }
        })


        return valueInfos;
    }

    const handleRefreshView = () => {
        let language = i18n.language.charAt(0).toUpperCase() + i18n.language.charAt(1).toUpperCase();
        props.attemptGetModels(hash, language);
        props.attemptGetFields();
        props.attemptDelAllDataFilter();
        props.attemptDelGlobalApiDataFilter();
        setLoadModel(false);
        setRefresh(true);
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleModalModelPlugcodeOpen = () => {
        props.attemptResetModelPlugcode();
        setIsOpenModalModelPlugcode(true);
    };

    const handleModalModelPlugcodeClose = () => {
        props.attemptResetModelPlugcode();
        setIsOpenModalModelPlugcode(false);

    };

    const handleModalModelShareOpen = () => {
        props.attemptResetModelShare();
        setIsOpenModalModelShare(true);
    };

    const handleModalModelShareClose = () => {
        props.attemptResetModelShare();
        setIsOpenModalModelShare(false);
    };

    const handleModalCreateUniversClose = () => {
        setIsOpenModaCreateUnivers(false);
    }

    const handleModalCreateUniversOpen = () => {
        setIsOpenModaCreateUnivers(true);
    }

    const handleDeleteModel = (model) => {

        //const isDeletable = !model.nbFichesArchived && !model.nbFichesNotArchived;
        const isDeletable = (model.numdatas == "0"  || model.numdatas == 0);

        if(isDeletable){
            confirmAlert({
                title: t('global.confirm'),
                message: t('models.model-confirm-delete'),
                buttons: [
                    {
                        label: t('global.yes'),
                        onClick: () => props.attemptRemoveModel(model.hash, hash)
                    },
                    {
                        label: t('global.cancel'),
                    }
                ]
            });
        }else{
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('common:models.not-empty-folder'),
                buttons: [
                    {
                        label: t('global.ok'),
                    }
                ]
            });
        }
    }

    const handleDuplicateModel = (model) => {
        confirmAlert({
            title: t('global.confirm'),
            message: t('models.model-confirm-duplicate'),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () => props.attemptDuplicateModel(model, hash)
                },
                {
                    label: t('global.cancel'),
                }
            ]
        });
    }

    const handleSetStatusZapier = (model) => {

        let payload = model.isZapier === 1 ? 0 : 1;

        confirmAlert({
            title: t('global.confirm'),
            message: t('models.model-confirm-change-status-zapier'),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () => props.attemptSetStatusZapier(model.hash, hash, payload)
                },
                {
                    label: t('global.cancel'),
                }
            ]
        });
    }

    const handleGeneratePlugcodeForAllPlugnoteModel = (model) => {

        confirmAlert({
            title: t('global.plugnotes'),
            message: t('plugcode.model-confirm-multiple-generation'),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () => props.attemptGeneratePlugcodeForAllPlugnoteModel(model.hash, hash)
                },
                {
                    label: t('global.cancel'),
                }
            ]
        });

    }

    const handleSelectModel = (model) => {
        props.attemptGetModelUniqueReset();
        delModel();
        setModel(model);
        props.history.push('/pluglist');
    }

    const handleSelectReadModel = (model) => {
        props.attemptGetModelUniqueReset();
        delModel();
        setModel(model);
        props.history.push('/pluglist-read');
    }

    const handleSelectModelEdit = (model) => {
        delModel();
        setModel(model);
        props.history.push('/pluglist-editable');
    }

    const handleTranslate = (model) => {
        delModel();
        setModel(model);
        props.history.push('/translate-model');
    }

    const handleLayout = (model) => {
        delModel();
        setModel(model);
        props.history.push('/layout-model');
    }



    const handleSelectSharedModel = (model) => {
        props.attemptGetModelUniqueReset();
        delModel();
        setModel(model);
        props.history.push('/pluglist-shared');
    }

    const handleCreateModel = () => {
        props.history.push('/create-model');
    }

    const handleGenerateOrDeleteTable = () => {
        //props.history.push('/create-model');

        if(props.userid !== null){
            const payload = {
                uid: props.userid,
                hash: getToken()
            }

            let bodyFormData = new FormData();
            bodyFormData.append('uid', props.userid);
            bodyFormData.append('hash',  getToken());

            confirmAlert({
                title: i18n.t('common:global.plugnotes'),
                message: i18n.t('common:tables.automatisation'),
                buttons: [
                    {
                        label: i18n.t('common:global.ok'),
                        onClick: () => {
                            axios({
                                method: "post",
                                url: ApplicationConfig.backop + 'process_customer_tables.php',
                                data: bodyFormData,
                                headers: {
                                    "Content-Type": "multipart/form-data" ,
                                    "Access-Control-Allow-Origin": "*"
                                },
                            })
                                .then(function (response) {
                                    //handle success
                                    console.log(response);
                                })
                                .catch(function (response) {
                                    //handle error
                                    console.log(response);
                                });
                        }
                    },
                    {
                        label: i18n.t('common:global.cancel')
                    },
                ]
            });
        }
        else{
            confirmAlert({
                title: i18n.t('common:global.plugnotes'),
                message: i18n.t('common:global.administrator-error'),
                buttons: [
                    {
                        label: i18n.t('common:global.ok'),
                    }
                ]
            });
        }
    }

    const handleImportFromFtp = () => {
        //props.history.push('/create-model');

        if(props.userhash !== null){

            const payload = {
                uh: props.userhash,
            }


            confirmAlert({
                title: i18n.t('common:global.plugnotes'),
                message: i18n.t('common:import.automatisation'),
                buttons: [
                    {
                        label: i18n.t('common:global.ok'),
                        onClick: () => {
                            axios.post(ApplicationConfig.apiv2Url + `User/ImportFromFtp.php`, payload
                                , {
                                    crossdomain: true,
                                    'headers': {
                                        'X-AUTH-TOKEN': getToken(),
                                        "Access-Control-Allow-Origin": "*"
                                    }
                                })
                                .then(function (response) {
                                    //handle success
                                    console.log(response);
                                })
                                .catch(function (response) {
                                    //handle error
                                    console.log(response);
                                });
                        }
                    },
                    {
                        label: i18n.t('common:global.cancel')
                    },
                ]
            });
        }
        else{
            confirmAlert({
                title: i18n.t('common:global.plugnotes'),
                message: i18n.t('common:global.administrator-error'),
                buttons: [
                    {
                        label: i18n.t('common:global.ok'),
                    }
                ]
            });
        }
    }

    const handleOpenHelp = () => {
        setOpenModalHelp(true);
    }

    const handleModalHelpClose = () => {
        setOpenModalHelp(false);
    }

    const handleChangeView = () => {
        setListView(!listView);
        setModelView(!listView);
    }

    const handleFlip = (value, event) => {
        event.preventDefault();
        event.stopPropagation();
        if(flipped === value){
            setFlipped(-1);
        }else{
            setFlipped(value);
        }
    }

    const handleChangeEnvironnement = (event) => {

        if(event.target.value === 'create-account'){
            handleModalCreateUniversOpen();
        }else{
            const value = event.target.value;
            let newEnvironnement = sortedListEnvironnement.find((element, index) => {
                return element.userhash === value;
            })
            setCurrentEnvironnement(newEnvironnement);
            //setLoadModel(true);
            setCountNoSharedModels(0);
            setCountYesSharedModels(0);
            setHash(newEnvironnement.userhash);
            setModels([]);
            setLoading(true);
        }

    };

    const _handleAcceptSharedModel = (element, answer) => {

        let language = i18n.language.charAt(0).toUpperCase() + i18n.language.charAt(1).toUpperCase();

        if(answer !== null){
            props.attemptAcceptSharedForModel(element.modelsshared_hash, answer ? "1" : "2",environment.userhash,props.profil.hash,language);
        }
    }

    const handleResetAllSearch = (event) => {
        props.attemptResetSearch();
        setActiveReset(true);
        setTimeout(() => {
            setActiveReset(false);
        },10)
    };

    const handleOwnerSelect = (value) => {
        setListOwnerSharedFilter(value)
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx(classes.paperAnchorDockedLeft,{
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}/>
                <List>
                    <div className={classes.openCloseRelative}>
                        <IconButton className={!open ? classes.openCloseButton : classes.openedCloseButton} onClick={open ? handleDrawerClose : handleDrawerOpen}>
                            {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </div>
                    <Tooltip title={t('models.environnementName')} aria-label="add" placement="top">

                    <ListItem button className="choix-univers">
                        <ListItemIcon className="green" onClick={handleLeftWorkSpaceOpen}><ViewList/></ListItemIcon>
                        <Select
                            disableUnderline
                            value={hash}
                            open={openLeftWorkSpace}
                            onClose={handleLeftWorkSpaceClose}
                            onOpen={handleLeftWorkSpaceOpen}
                            onChange={handleChangeEnvironnement}
                            label={t('models.environnementName')}
                            inputProps={{
                                name: 'age',
                                id: 'outlined-age-native-simple',
                                classes: {
                                    icon: classes.iconSelect,
                                },
                            }}
                            className="select"
                            IconComponent={props => (
                                <i {...props} className={`material-icons ${props.className}`}>
                                    keyboard_arrow_down
                                </i>
                            )}
                        >
{/*                            <MenuItem key={-1} value={"create-account"}>
                                <ListItemIcon className="black"><Add/></ListItemIcon>
                                <ListItemText primary={t('univers.add-univers')} />
                            </MenuItem>*/}
                            {
                                sortedListEnvironnement.map((value, index) => {
                                    return <MenuItem key={index} value={value.userhash}>{value.envIndex} -  {value.name}</MenuItem>
                                })
                            }
                        </Select>
                    </ListItem>
                    </Tooltip>

{/*                    <Tooltip title={i18n.t('common:univers.add-univers')} aria-label="add" placement="top">
                        <ListItem disableGutters={false} button onClick={(event) => handleModalCreateUniversOpen()}>
                            <ListItemIcon className="black"><Add/></ListItemIcon>
                            <ListItemText primary={i18n.t('common:univers.add-univers')} />
                        </ListItem>
                    </Tooltip>*/}

                    {
                        !props.invite &&
                        <Tooltip title={i18n.t('common:global.new-form')} aria-label="add" placement="top">
                            <ListItem disableGutters={false} button onClick={(event) => handleCreateModel()}>
                                <ListItemIcon className="green"><ListAlt/></ListItemIcon>
                                <ListItemText primary={i18n.t('common:global.new-form')} />
                            </ListItem>
                        </Tooltip>
                    }
                    <ListItem disableGutters={true}>
                        {open ?
                        <MenuLeftSearchComponent placeholder={t('models.search-model')} reset={activeReset} open={open} handleResetAllSearch={handleResetAllSearch} isSearchActive={props.isSearchActive}/>
                        :
                        <div>
                            <span id="icon-listForm" className={`material-icons md-25 ${props.isSearchActive ? classes.iconSearchActive : classes.iconSearchBis}`} onClick={(event) => setOpen(true)}>
                                search
                            </span>
                            {props.isSearchActive &&
                            <span id="icon-listForm" className={`material-icons md-25`} onClick={(event) => handleResetAllSearch(event)} >
                                close
                            </span>
                            }
                        </div>
                        }
                    </ListItem>
                    {
                        !props.invite && props.authapi &&
                        <ListItem disableGutters={true}>

                            <Tooltip title={i18n.t('common:global.update-table')} aria-label="add" placement="top">
                                <ListItem disableGutters={false} button
                                          onClick={(event) => handleGenerateOrDeleteTable()}>
                                    <ListItemIcon className="green"><Storage/></ListItemIcon>
                                    <ListItemText primary={i18n.t('common:global.update-table')} />
                                </ListItem>
                            </Tooltip>
                        </ListItem>
                    }

                    {
                        !props.invite && props.authapi && props.authapiws.length > 0 && props.authapiws.includes('ImportFromFtp') &&
                        <ListItem disableGutters={true}>

                            <Tooltip title={i18n.t('common:import.ftp-a32')} aria-label="add" placement="top">
                                <ListItem disableGutters={false} button
                                          onClick={(event) => handleImportFromFtp()}>
                                    <ListItemIcon className="green">
                                        <img
                                            className={classes.fieldImage}
                                            src ={Images['documentIconGreen']}
                                         alt={i18n.t('common:import.ftp-a32')}/>
                                    </ListItemIcon>
                                    <ListItemText primary={i18n.t('common:import.ftp-a32')} />
                                </ListItem>
                            </Tooltip>
                        </ListItem>
                    }
                    <ListItem disableGutters={true}>

                        <Tooltip title={i18n.t('common:global.help-manuels')} aria-label="add" placement="top">
                            <ListItem disableGutters={false} button
                                      onClick={(event) => handleOpenHelp()}>
                                <ListItemIcon className="green"><Help/></ListItemIcon>
                                <ListItemText primary={i18n.t('common:global.help-manuels')} />
                            </ListItem>
                        </Tooltip>
                    </ListItem>
                </List>

                <div className={classes.openClose}>
                    <IconButton className={!open ? classes.openCloseButton : classes.openedCloseButton} onClick={open ? handleDrawerClose : handleDrawerOpen}>
                        {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
            </Drawer>
            <main className={classes.content}>
                {props.isSearchActive &&
                <HeaderSearch />
                }
                <Header style={{maxHeight : 50}}>
                    <div id="btnContainer">
                        <div className="flex_display_direction_column_centered">
                            {props.type !== 'demo' &&
                            <div className="grey" style={{textTransform: 'none'}}>
                                <div style={{flexDirection : 'row'}}>
                                    <span style={{marginRight : '15px'}}><ViewList className="material-icons md-30"/></span>
                                    <span className="grey">{environment.envIndex} - {environment.name}</span>
                                </div>
                            </div>
                            }
                        </div>
                        { environment.envIndex === 1 &&
                            <div style={{flex : 1}}>
                                <div className="grey display_flex display_flex_right float_right" style={{
                                    paddingTop: '8px',
                                    paddingBottom: '8px',
                                    alignItems: 'baseline'
                                }}>
                                    {!props.invite &&
                                    <div className="padding_left" style={{paddingTop: '8px', paddingBottom: '8px'}}>
                                        <input className="checkbox" type="checkbox" checked={noSharedModels}
                                                onChange={(event) => setNoSharedModels(!noSharedModels)}/>
                                        {t('common:models.model-no-shared')} ({countNoSharedModels})
                                    </div>
                                    }
                                    <div className="padding_left" style={{paddingTop: '8px', paddingBottom: '8px'}}>
                                        <input
                                            checked={yesSharedModels}
                                            className="checkbox" type="checkbox"
                                            onChange={(event) => setYesSharedModels(!yesSharedModels)}/>
                                        {t('common:models.model-shared')} ({countYesSharedModels})
                                    </div>
                                </div>
                            </div>
                        }

                    </div>
                </Header>
                <Content className={`scrollbar-hidden-spec  ${!listView ? classes.contentModelGrid : classes.contentModelList}`}>
                    {props.listToAccept.length > 0 && props.listToAccept.map((element, index) => {
                        const modelShared = element;
                        const modelSharedName = !!modelShared.firstname && !!modelShared.lastname ? modelShared.firstname + ' ' + modelShared.lastname : modelShared.username ;
                        const modelSharedEnv = !!modelShared.profilename ? modelShared.profilename : t('common:global.new') ;

                        return(
                        <div className="flexSpacebetween margin-bottom-top margin-top" key={index}>
                            <div className="trenteWidth">
                                <span>{t('common:share.alert-model-shared-confirm-title', {name : modelSharedName})}</span>
                            </div>
                            <div className="cinquanteWidth">
                                <span>{t('common:share.alert-model-shared-confirm-message', { modelName: modelShared.label, profileName : modelSharedEnv })}</span>
                            </div>
                            <div className="dixWidth float_right cursor" onClick={(event) => _handleAcceptSharedModel(element, false)}>
                                <span className="button">{t('common:global.no')}</span>
                            </div>
                            <div className="dixWidth float_right cursor" onClick={(event) => _handleAcceptSharedModel(element, true)}>
                                <span className="button-exit">{t('common:global.yes')}</span>
                            </div>
                        </div>)
                    })}
                    {props.models.length > 0 &&
                    <ViewModels
                        models={props.models}
                        listView={listView}
                        handleSelectReadModel={handleSelectReadModel}
                        handleSelectModel={handleSelectModel}
                        handleSelectModelEdit={handleSelectModelEdit}
                        handleCreateModel={handleCreateModel}
                        handleDeleteModel={handleDeleteModel}
                        handleDuplicateModel={handleDuplicateModel}
                        handleSetStatusZapier={handleSetStatusZapier}
                        handleModalModelShareOpen={handleModalModelShareOpen}
                        handleModalModelShareClose={handleModalModelShareClose}
                        handleModalModelPlugcodeOpen={handleModalModelPlugcodeOpen}
                        handleModalModelPlugcodeClose={handleModalModelPlugcodeClose}
                        handleGeneratePlugcodeForAllPlugnoteModel={handleGeneratePlugcodeForAllPlugnoteModel}
                        handleFlip={handleFlip}
                        handleTranslate={handleTranslate}
                        handleLayout={handleLayout}
                        flipped={flipped}
                        history={props.history}
                        attemptGetModelsPaginate={props.attemptGetModelsPaginate}
                        hash={hash}
                        limitPage={props.end}
                        startPage={props.start}
                        language={i18n.language.charAt(0).toUpperCase() + i18n.language.charAt(1).toUpperCase()}
                        refresh={refresh}
                        setRefresh={setRefresh}
                        isZapier={props.isZapier}
                        attemptRemoveSharedForModel={props.attemptRemoveSharedForModel}
                        handleSelectSharedModel={handleSelectSharedModel}
                        noSharedModels={noSharedModels}
                        yesSharedModels={yesSharedModels}
                        listOwnerSharedFilter={listOwnerSharedFilter}
                        countYesSharedModels={countYesSharedModels}
                        attemptGetModelsLightCountablePlugnote={props.attemptGetModelsLightCountablePlugnote}
                        attemptResetDataPassword={props.attemptResetDataPassword}
                        attemptResetAnnexe={props.attemptResetAnnexe}
                        attemptGetModelUniqueRequest={props.attemptGetModelUniqueRequest}
                        attemptGetModelSharedUniqueRequest={props.attemptGetModelSharedUniqueRequest}
                        invite={props.invite}
                    />
                    }

                    { props.models.length > 0 && !props.invite &&
                    <span className="dot_fixed_model green" onClick={handleCreateModel.bind(this)}>
                        <span id="icon-qrcode" className="material-icons icon-add_style flex_display_direction_column_dot_centered">
                            <span className="md-small-x line_height_10">{i18n.t('common:global.new-form-short')}</span>
                            <ListAlt/>
                            <span className="md-small-x line_height_10">{i18n.t('common:models.folder')}</span>
                        </span>
                    </span>
                    }

                    { props.models.length === 0 && !promiseInProgress && !props.isSearchActive && !props.invite &&
                    <div className={`flex_display centre grey`}>
                        <div style={{width : '50%'}}>
                            <div style={{marginLeft: 'auto', marginRight: 'auto', textAlign: 'left', width: '90%'}}>
                                <div>
                                    <span className="titres_vide_3">{t('welcome.model-one')}</span><br/>
                                    <span className="titres_vide_4">{t('welcome.model-two')}</span><br/>
                                    <span className="titres_vide_4">{t('welcome.model-tree')}</span>
                                </div>
                                <hr/>
                                <div className="titres_vide_2">
                                    {t('welcome.model-four')} <span><a href="mailto:support@plugnotes.com"> support@plugnotes.com </a></span>
                                </div>
                            </div>

                        </div>
                        <div style={{width : '50%'}}>
                            <div className="flexbox-item-welcome p1 m2" style={{backgroundColor: 'white', color:'black', border:'none', cursor:'pointer'}} onClick={handleCreateModel.bind(this)}>
                                <div style={{width : '10%', display: 'contents'}}>
                                    <div className="">
                                        <span className="material-icons md-80 green">
                                            list_alt
                                        </span>
                                    </div>
                                </div>
                                <div style={{width : '90%'}} className="text_align_left text_align_centered margin-left-10">
                                    <div>
                                        <span className="titres_vide" style={{margin:'auto', fontSize: '20px'}}>
                                        {t('welcome.create-form-title')}
                                        </span><br/>
                                        <span className="titres_vide_2" style={{margin:'auto', fontSize: '15px'}}>
                                        {t('welcome.create-form-subtitle')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="flexbox-item-welcome p1 m2" style={{backgroundColor: 'white', color:'black', border:'none', cursor:'pointer'}} onClick={handleModalBiblioTemplateOpen.bind(this)}>
                                <div style={{width : '10%', display: 'contents'}}>
                                    <div className="">
                                        <span className="material-icons md-80 green">
                                            qr_code
                                        </span>
                                    </div>
                                </div>
                                <div style={{width : '90%'}} className="text_align_left text_align_centered margin-left-10">
                                    <div>
                                        <span className="titres_vide" style={{margin:'auto', fontSize: '20px'}}>
                                        {t('welcome.open-library-title')}
                                        </span><br/>
                                        <span className="titres_vide_2" style={{margin:'auto', fontSize: '15px'}}>
                                        {t('welcome.open-library-subtitle')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="flexbox-item-welcome p1 m2" style={{backgroundColor: 'white', color:'black', border:'none', cursor:'pointer'}} onClick={handleModalInviteOpen.bind(this)}>
                                <div style={{width : '10%', display: 'contents'}}>
                                    <div className="">
                                        <span className="material-icons md-80 green">
                                            groups
                                        </span>
                                    </div>
                                </div>
                                <div style={{width : '90%'}} className="text_align_left text_align_centered margin-left-10">
                                    <div>
                                        <span className="titres_vide" style={{margin:'auto', fontSize: '20px'}}>
                                        {t('welcome.invite-col-title')}
                                        </span><br/>
                                        <span className="titres_vide_2" style={{margin:'auto', fontSize: '15px'}}>
                                        {t('welcome.invite-col-subtitle')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="flexbox-item-welcome p1 m2" style={{backgroundColor: 'white', color:'black', border:'none', cursor:'pointer'}} onClick={(event) => window.open('https://intercom.help/plugnotes/en/articles/4797898-demo-plugnotes-web-app', "_blank")}>
                                <div style={{width : '10%', display: 'contents'}}>
                                    <div className="">
                                        <span className="material-icons md-80 green">
                                            school
                                        </span>
                                    </div>
                                </div>
                                <div style={{width : '90%'}} className="text_align_left text_align_centered margin-left-10">
                                    <div>
                                        <span className="titres_vide" style={{margin:'auto', fontSize: '20px'}}>
                                        {t('welcome.how-to-title')}
                                        </span><br/>
                                        <span className="titres_vide_2" style={{margin:'auto', fontSize: '15px'}}>
                                        {t('welcome.how-to-subtitle')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }

                    {/*                    { props.models.length === 0 && !promiseInProgress && !props.isSearchActive &&
                    <div className="centre dossier_vide grey">
                        <div className="titres_vide">
                            {t('models.welcome-one')}<br/>
                            {t('models.welcome-two')}
                        </div>
                        <div className="flexbox-item dossierPlugnote p1 m2"
                             style={{backgroundColor: '#58C3C1', color:'white', border:'none', cursor:'pointer'}} onClick={handleCreateModel.bind(this)}>
                        <span className="titres_vide" style={{margin:'auto', fontSsize: '24px'}}>
                            {t('models.new-folder')}
                        </span>
                        </div>
                        <div className="titres_vide P1 cliquables">
                            {t('global.how-to-do')}
                        </div>
                    </div>
                    }*/}
                </Content>
            </main>
            { getModel() && isOpenModalModelPlugcode ?
                <ModalModelPlugcodeComponentV2 open={isOpenModalModelPlugcode} handleModalModelPlugcodeClose={handleModalModelPlugcodeClose} close={handleModalModelPlugcodeClose}/>
                :
                null
            }
            { getModel()  && isOpenModalModelShare ?
                <ModalModelShareComponent open={isOpenModalModelShare} handleModalModelShareClose={handleModalModelShareClose} close={handleModalModelShareClose}/>
                :
                null
            }

            {
                isOpenModaCreateUnivers ?
                    <ModalCreateUniversComponent open={isOpenModaCreateUnivers}  close={handleModalCreateUniversClose} />
                    :

                    null
            }

            { openModalBiblioTemplate ?

                <ModalBiblioTemplateComponent
                    open={openModalBiblioTemplate}
                    close={handleModalBiblioTemplateClose}
                    countable={countable}
                />
                :
                null

            }

            { openModalInvite ?

                <ModalInviteComponent
                    open={openModalInvite}
                    close={handleModalInviteClose}
                />
                :
                null

            }

            {
                openModalHelp  ?
                    <ModalHelpComponent open={openModalHelp} close={handleModalHelpClose} from="plugforms"/>
                    :
                    null
            }
        </div>
    );
}

// redux providing state takeover
const mapStateToProps = (state) => {
    // console.log(state);

    const listEnvironment = state.user.user.profil.filter((value, index) => {
        if(!value.modelsshared){
            return value;
        }
    });
    const sortedListEnvironnement = listEnvironment.sort((left, right) => {
        if(left.profilid === ''){
            return -1
        }else{
            return left.profilid < right.profilid ? -1 : 1;
        }
    });

    let models = [];

    if(state.search.isSearchActive && state.search.isSearchResult && state.model.list.length > 0){
        state.model.list.map((item, index) => {
            let find = false;
            state.search.searchResult.map((search, index) => {
                if(item.hash === search.modhash){
                    find = true;
                }
            })
            if(find){
                models.push(item);
            }
        });
    }

    if(!state.search.isSearchActive && !state.search.isSearchResult){
        models = !! state.model.list ? state.model.list : [];
    }

    // console.log(state?.user?.user);
    return {
        end : state.model.end,
        start : state.model.start,
        sortedListEnvironnement : sortedListEnvironnement,
        listEnvironment : listEnvironment,
        user : state.user,
        isZapier : !!state.user.user.isZapier ? state.user.user.isZapier === true : false,
        token : state.user.token,
        data: state.data,
        models: models.sort((a, b) => a.label.localeCompare(b.label)),
        fetching : state.model.fetching,
        isSearchActive: state.search.isSearchActive,
        type : !!state.user && !!state.user.user  ? state.user.user.type : null,
        listToAccept : !!state.user && !!state.user.user ?  state.user.listToAccept : [],
        profil : state.user.profil,
        invite : state?.user?.user?.type == 'invite' || false,
        authapi : state?.user?.user?.auth || false,
        authapiws : state?.user?.user?.authServices || [],
        userid : state?.user?.user?._value?.id || null,
        userhash : state?.user?.user?._value?.hash || null,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptGetModels: (userhash, language) => dispatch(getModelsRequest(userhash,language)),
        attemptGetModelsLight: (userhash) => dispatch(getModelsLightRequest(userhash)),
        attemptGetModelsLightCountablePlugnote: (userhash) => dispatch(getModelsLightCountablePlugnoteRequest(userhash)),
        attemptGetModelsPaginate: (userhash, language, page) => dispatch(getModelsPaginateRequest(userhash,language,page)),
        attemptDelAllDataFilter: () => dispatch(reduxDelAllDataFilterRequest()),
        attemptDelGlobalApiDataFilter: () => dispatch(reduxDelGlobalApiDataFilterRequest()),
        attemptGetFields : () =>  dispatch(getFieldsRequest()),
        attemptResetModelPlugcode: () => dispatch(reduxGeneratePlugformcodeReset()),
        attemptResetModelShare: () => dispatch(reduxGenerateShareReset()),
        attemptRemoveModel :(modelhash,userhash) => dispatch(delModelUniqueRequest(modelhash, userhash)),
        attemptDuplicateModel :(model,userhash) => dispatch(postModelDuplicateRequest(model, userhash)),
        attemptSetStatusZapier :(modelhash,userhash,payload) => dispatch(postModelZapierRequest(modelhash,userhash,payload)),
        attemptGeneratePlugcodeForAllPlugnoteModel :(modelhash,userhash) => dispatch(postModelGenerateMultiplePlugcodeRequest(modelhash, userhash)),
        attemptResetSearch : () => dispatch(delAllSearchParamsRequest()),
        attemptRemoveSharedForModel: (modelsharedhash,model) => dispatch(delShareModelShareRequest(modelsharedhash,model)),
        attemptAcceptSharedForModel : (modelsharedhash, accept,userhash,usermainhash,language) => dispatch(acceptShareModelShareRequest(modelsharedhash, accept,userhash,usermainhash,language)),
        attemptGetModelUniqueReset: () => dispatch(getModelUniqueReset()),
        attemptResetAnnexe: () => dispatch(reduxGenerateAnnexeReset()),
        attemptResetDataPassword: () => dispatch(reduxGenerateDataPasswordReset()),
        attemptGetModelUniqueRequest: (modelhash,userhash,usermainhash) => dispatch(getModelUniqueRequest(modelhash,userhash,usermainhash)),
        attemptGetModelSharedUniqueRequest: (modelhash,userhash,usermainhash) => dispatch(getModelSharedUniqueRequest(modelhash,userhash,usermainhash)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelListLight)
