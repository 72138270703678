import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import FormatTableMultipleConstructorComponent from './FormatTableMultipleConstructorComponent';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from "@material-ui/core";
import colors from '../../assets/themes/Colors';
import { useTranslation } from 'react-i18next';
import FormatExtDataConstructorComponent from './FormatExtDataConstructorComponent';
import { getColumnLabel, RenderMaskList } from './FormatSMailValidMultiConstructorComponent';
import { isDefined } from '../../utils/utils';

const useStyles = makeStyles(() => ({
  mainField : {
      minWidth: '100%',
      display: 'flex',
      flexDirection : 'column'
  },
  subfield : {
      scrollMarginTop: '100px',
      width : '100%',
  },
  //Table
    tableCtn: {
      marginTop: -12,
  },
  table: {
      '@media(max-width: 1024px)':{
          '& .MuiTableCell-root': {
              padding: 8
          }
      }
  },
  tableHeader: {
      '& .MuiTableCell-root': {
          padding: '4px 30px 4px !important',
      },
  },
  headerTxt: {
      fontSize: 18,
  },
  secondColCell: {
      '& .MuiTableCell-root': {
          borderLeft: '1px solid #cccccc',
      },
      borderLeft: '1px solid #cccccc',
  },
  // Tabs
  tabsWrapper: {
      display: 'flex',
      flexDirection: 'row',
  },
  tabsCtn: {
      '& .MuiTab-root': {
          textTransform: 'none !important',
      },
      '& .MuiTabs-indicator': {
          top: 0,
          backgroundColor: colors.main,
      },
      '& .MuiTabTextColorInherit.MuiSelected': {
          color: colors.main,
          fontWeight: 700,
      },
      '& div.MuiTabs-scroller.MuiTabs-fixed': {
          overflow: 'auto !important',
      }
  },
  tab: {
      // paddingTop: -20,
  },
  tabIcon: {
    height: 24,
    width: 24,
  },
  unselectedTab: {
      backgroundColor: colors.greyLight,
  },
  voidSpace: {
      flex: 1,
      background: `linear-gradient(90deg, ${colors.greyLight}, transparent)`,
      opacity: 0.7,
  },
}))

const FormatTableMultiplenConstructorComponent = (props) => {
  const classes = useStyles();
  const [dataRows, setDataRows] = useState(props.field.formatoptions.lines);

  const voidAction = () => null

  const ExtdataTabComponent = (
    <div className={classes.tabCtn} >
      <div className={classes.subfield}>
        <FormatExtDataConstructorComponent
          field={props.field}
          handleEditLabel={props.handleEditLabel}
          index={props.index}
          setModel={props.setModel}
          model={props.model}
          update={props.update}
        />
        <TableContainer component={Paper} className={classes.tableCtn} style={{ width: '100%' }}>
          <Table className={classes.table}>
            <TableHead className={classes.tableHeader}  >
              <TableRow>
                <TableCell align="left" className={classes.headerTxt} colSpan={dataRows.length > 5 ? 9 : 4}>Field List</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                dataRows.length > 0 &&
                <React.Fragment>
                  {
                    dataRows
                      .slice(...(dataRows.length > 5 ? [0, Math.ceil(dataRows.length / 2)] : []))
                      .map((row, index) => {
                        let index2 = Math.ceil(index + (dataRows.length / 2))
                        return (
                          <React.Fragment key={index}>
                            <TableRow >
                              <TableCell align="center">{index + 1}</TableCell>
                              <TableCell align="center">
                                <span>{row.label}</span>
                              </TableCell>
                              <TableCell align="center">
                                <span>{row.formatLabel}</span>
                              </TableCell>
                              <TableCell className={classes.configCell}>
                                <RenderMaskList {...props}
                                  open={null}
                                  openRow={null}
                                  row={dataRows[index]}
                                  index={index}
                                  rows={[]}
                                  isPreview
                                  handleUpdateMask={voidAction}
                                  handleUpdateType={voidAction}
                                  handleToggleSubConfig={voidAction}
                                />
                              </TableCell>
                              {
                                dataRows.length > 5 &&
                                <TableCell colSpan={1} />
                              }
                              {dataRows.length > 5 &&
                                (isDefined(dataRows[index2]) ?
                                  <React.Fragment>
                                    <TableCell align="center" className={classes.secondColCell} >{index2 + 1}</TableCell>
                                    <TableCell align="center">
                                      <span>{getColumnLabel(dataRows[index2].label)}</span>
                                    </TableCell>
                                    <TableCell align="center">
                                      <span>{dataRows[index2].formatLabel}</span>
                                    </TableCell>
                                    <TableCell className={classes.configCell}>
                                      <RenderMaskList {...props}
                                        open={null}
                                        openRow={null}
                                        row={dataRows[index2]}
                                        index={index2}
                                        rows={[]}
                                        isPreview
                                        handleUpdateMask={voidAction}
                                        handleUpdateType={voidAction}
                                        handleToggleSubConfig={voidAction}
                                      />
                                    </TableCell>
                                  </React.Fragment>
                                  :
                                  <TableCell colSpan={5} className={classes.secondColCell} />)
                              }
                            </TableRow>
                          </React.Fragment>
                        );
                      })}
                </React.Fragment>
              }
            </TableBody >

          </Table>
        </TableContainer>
      </div>
    </div>
  )

  return (
    <div className={classes.mainField}>
      <FormatTableMultipleConstructorComponent
        extdataTab={ExtdataTabComponent}
        field={props.field}
        folderName={props.folderName}
        handleEditLabel={props.handleEditLabel}
        index={props.index}
        model={props.model}
        onChangeHandlerDrop={props.onChangeHandlerDrop}
        plugnoteCount={props.plugnoteCount}
        setModel={props.setModel}
        setDataRows={setDataRows}
        update={props.update}
      />
    </div>
  );
};

export default FormatTableMultiplenConstructorComponent;
