import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import { isDefined } from "../../utils/utils";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    fieldRight:{
       flexGrow: 1,
       overflow: 'auto',
       overflowX: 'hidden',
       whiteSpace: 'nowrap',
    },
    urlContainer:{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 15,

    },
    urlButton:{
        /* border: '1px red solid', */
        display: 'inline-block',
    color: "white",
    backgroundColor: "#58c3c1",
    padding: "3px 15px",
    borderRadius: "3px",
    cursor: "pointer",
    },
}));

const FormatUrlComponent = (props) => {
    const classes = useStyles()

    let initialValue = isDefined(props.field?.options?.setup?.defaultValue) ? props.field?.options?.setup.defaultValue : ''
    if (isDefined(props.field.value) && props.field.value !== '') { initialValue = props.field.value }

    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [url, setUrl] = useState(initialValue)

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
        if(typeof props.handleEditValue !== "undefined"){
            props.handleEditValue(initialValue, props.field, props.index);
        }
    },[ props.disabled]);

    useEffect(() => {
        if(!!props.field.value){
            if (props.field.value.indexOf("http://") === 0 || props.field.value.indexOf("https://") === 0) {
                setUrl(props.field.value);
            }else{
                setUrl("https://"+props.field.value);
                props.handleEditValue("https://"+props.field.value, props.field, props.index)
            }
        }else {
            setUrl("https://")
        }

    },[props.field.value])


    const handleEditValue = (value, field, index) => {
        setUrl(value);
        props.handleEditValue(value, field, index)
    }

    return (
        <div className={classes.urlContainer} >
            <TextField
                onChange={(event) => handleEditValue(event.target.value, props.field, props.index)}
                id={'url-input-' + props.field.id}
                style={{ margin: 0 }}
                type="text"
                helperText=""
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                className={classes.fieldRight}
                value={url}
                disabled={disabled}
                InputProps={{ disableUnderline: true }}
                />
            { typeof url !== 'undefined' &&  url !== '' &&
            <div>
                <a href={url.toString()} target="_blank" className={classes.urlButton} rel="noreferrer"><span className="material-icons md-small white">link</span></a>
            </div>
            }
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatUrlComponent);





