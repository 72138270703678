import { Input, InputLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import React, { useContext, useEffect, useMemo, useState } from "react";
import colors from "../../assets/themes/Colors";
import {convertersNumberToAlpha, devLog, isDefined} from "../../utils/utils";
import { DrawerContext } from "../../App";

const useStyles = makeStyles((theme) => ({
    sideMenu: {
        position: 'fixed',
        left: 0,
        top: 0,
        paddingTop: '10px',
        paddingLeft: '10px',
        height: 'calc(100vh + 60px)',
        overflowY: 'scroll',
        width: 370,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        zIndex: 2000,
        backgroundColor: colors.white,
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
    },
    sideHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        '& .MuiSvgIcon-root': {
            color: `${colors.main}!important`
        },
        alignItems: 'center'
    },
    inputField: {
        width: 60,
    },
    sideInstructions: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding:'0 10px',
        gap: 10,
    },
    sideInput: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: 25
    },
    subtitle: {
        fontWeight: 'bold'
    },
    instructionsFont: {
        fontSize: 12,
        display: 'flex',
        alignItems: 'center',
    },
    margin:{
        marginTop : '15px',
        marginBottom : '5px',
    },
    RadioGroupCell: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 20,
    },
    marginbuttonsBox : {
        margin : 5,
        cursor : 'pointer',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        padding: 5,
        gap: 5
    },
    itemContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    itemGroupContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    valueButton: {
        height:'20px',
        width: '20px',
        color: colors.white,
        alignItems: 'center',
        backgroundColor: colors.main,
        borderRadius: 4,
        fontSize: "18px",
        fontWeight: '900',
    },
    checkboxContainer: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    labelButton: {
        color: colors.mainButton,
        fontWeight: '900',
    },
    tableContainer : {
        width : 'auto',
        marginLeft: -10,
    },
    tableRoot: {
        width: "100%", //it was 100%
        marginTop: 10,
        overflowX: "auto",
    },
    columnContainer: {
        '& .MuiTableCell-root': {
            padding: '8px 4px !important'
        },
        display: 'flex',
        flexDirection: 'row'
    },
    rowContainer: {
        '& .MuiTableCell-root': {
            padding: '8px 4px !important'
        },
    },
    tableFields: {
        color: colors.mainButton
    },
    errorMsg: {
        display: 'flex',
        color: colors.titleGrey,
        // marginTop: 25,
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold'
    },
}))

const excludes = [
    'hash',
    'field1format','field2format','field3format','field4format','field5format',
    'field6format','field7format','field8format','field9format','field10format',
    'field11format','field12format','field13format','field14format','field15format',
    'field1','field2','field3','field4','field5',
    'field6','field7','field8','field9','field10',
    'field11','field12','field13','field14','field15',
];

export function ExtDataSideMenu (props) {
    // devLog('ExtDataSideMenu got props', props)
    const classes = useStyles();
    const { t } = useTranslation(['common', 'models']);
    const extTable = props.extTable
    const preview = props.preview
    const record = props.record
    const [showFields, setShowFields] = useState(false)
    const { openDrawer, handleToggleDrawer } = useContext(DrawerContext)
    const drawerWasOpen = useMemo(() => openDrawer, [])

    useEffect(() => {
        if (preview === null) { props.handlePreview() }
        if (!openDrawer) { handleToggleDrawer() }
        const element = document.getElementById("extdata-side-menu")
        if (isDefined(element)) {
            devLog('element', { element })
            devLog('document', { document })
        }
        return () => { if (openDrawer && !drawerWasOpen) { handleToggleDrawer() }}
    }, [])

    useEffect(() => {
        setTimeout(() => props.handlePreview(), 1000)
    }, [props.extTable])

    // devLog("Ext menu props", props);
    const handleClose = () => {
        if (openDrawer && !drawerWasOpen) { handleToggleDrawer() }
        props.handleClose()
    }

    return (
        <div className={`${classes.sideMenu} scrollbar-hidden`} style={showFields? {width: 450} : null} id="extdata-side-menu">
            <div className={classes.sideHeader}>
                    <InputLabel className={classes.subtitle} htmlFor={"body-"+props.field.key}>{t('common:ext-table.menu-title')}{props.extTable !== '' && ' : ' + extTable}</InputLabel>
                    <span id="icon-close" className="material-icons md-50 lightgrey" onClick={handleClose}>
                        close
                    </span>
                </div>
            {
                preview !== undefined && preview !== null && preview !== false && !Array.isArray(preview) ?
                <div>
                    <InputLabel className={classes.subtitle}>{t('common:ext-table.for-field')} {props.fieldId}: {props.fieldLabel}</InputLabel>
                    <div className={classes.sideInput}>
                        <InputLabel>{t('common:ext-table.search-row')}</InputLabel>
                        <div className="flex_display_direction_row margin-10">
                            <Input
                                endAdornment={
                                    <span className="margin-left-auto cursor" onClick={(event) => props.handlePreview()}>
                                        <span id="icon-close" className="material-icons md-25 black">
                                            {preview !== null? "refresh" : "search"}
                                        </span>
                                    </span>}
                                id="standard-full-width-clef"
                                label="search-db"
                                placeholder={t('common:ext-table.row')}
                                fullWidth={true}
                                margin="none"
                                inputlabelprops={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    fieldkey: "record-" + props.field.key,
                                    className: classes.inputField,
                                    maxLength: 40
                                }}
                                onChange={(event) => props.setRecord(event.target.value)}
                                defaultValue={record}
                            />
                        </div>
                    </div>
                    <div className={classes.checkboxContainer} onClick={() => setShowFields(!showFields)}>
                        {showFields?
                        <span
                            id="icon-more"
                            className={`material-icons md-small`}
                            style={{ margin: 0, width: '20px', height: '20px', marginRight : '5px', color: colors.selectedMain,}}>
                            check_box
                        </span>
                        :
                        <span
                            id="icon-more"
                            className={`material-icons md-small`}
                            style={{ margin: 0, width: '20px', height: '20px', marginRight : '5px', color: colors.selectedMain,}}>
                            check_box_outline_blank
                        </span>
                        }
                        <InputLabel>{t('common:ext-table.show-names')}</InputLabel>
                    </div>
                </div>
                :
                <div className={classes.errorMsg}>
                    <span
                        id="icon-more"
                        className={`material-icons md-small`}
                        style={{ margin: 0, width: '20px', height: '20px', marginRight : '5px', color: colors.red, }}>
                        warning
                    </span>
                    <span>
                        {t('common:ext-table.invalid-name')}
                    </span>
                </div>
            }
            {
                preview !== null &&
                <div className={classes.tableContainer}>
                    <div className={classes.columnContainer}>
                        {preview !== undefined && preview !== null && preview !== false && !Array.isArray(preview) ?
                        <TableContainer component={Paper} className={classes.tableRoot} classes={{root: classes.scrollBar}}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" className={classes.rowContainer}>Colonnes</TableCell>
                                        <TableCell align="center">Données</TableCell>
                                        {showFields && <TableCell align="center">Nom des champs</TableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(preview).map((col, rindex) => {
                                        if(!excludes.includes(col)){
                                            return (
                                                <TableRow key={rindex}>
                                                    <TableCell align="center">{convertersNumberToAlpha((rindex+1))}</TableCell>
                                                    <TableCell align="center">{preview[col]}</TableCell>
                                                    {showFields && <TableCell className={classes.tableFields} align="center">{col}</TableCell>}
                                                </TableRow>
                                            );
                                        }else return null})}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        :
                        null}
                    </div>
                </div>
            }
        </div>
    )
}
