
import { PDFDocument } from "pdf-lib"
import {
  Font,
  Image,
  Link,
  Text,
  View
} from '@react-pdf/renderer'
import { devLog, isDefined } from "./utils"
import ApplicationConfig from "../config/ApplicationConfig"
import Axios from "axios"
import { trackPromise } from "react-promise-tracker"
import { getToken } from "./Common"

export const fetchAnnexeB64 = annexe => {
  return trackPromise(
    Axios
      .get(ApplicationConfig.apiUrl + 'v2/annexe.json?annexehash=' + annexe.hash + '&version=' + annexe.lastversion, {
        crossdomain: true,
        'headers': {
          'X-AUTH-TOKEN': getToken(),
        }
      })
      .then((response) => {
        if (response.data.result) {
          let fileBase64 = response.data.base64 //Image Base64 Goes here
          // devLog('fetch response', response)
          return fileBase64
        }
      })
      .catch((response) => {
        console.error("Could not Download the Excel report from the backend.", response);
      })
  )
}

function base64ToBufferAsync(base64) {
  var dataUrl = "data:application/octet-binary;base64," + base64
  return fetch(dataUrl)
    .then(res => res.arrayBuffer())
    // .then(buffer => {
    //   console.log("base64 to buffer: " + new Uint8Array(buffer))
    //   return new Uint8Array(buffer)
    // })
}

export const handleMergeMultiplePDF = async (url, files) => {
  // console.log('files', files)
  // I chose to handle the main pdf first, then the attached pdf, because they are from two different sources and of different types
  const pdfDoc = await PDFDocument.create()
  let promise = new Promise(async resolve => {
    await fetch(url)
      .then(res => {
        res.arrayBuffer()
          .then(async result => {
            let docPart = await PDFDocument.load(result)
            let pages = []
            let docLength = docPart.getPageCount()
            pages = Array.from({ length: docLength }, (v, i) => i)

            pdfDoc.copyPages(docPart, pages)
              .then(res => {
                for (const page of res) {
                  pdfDoc.addPage(page)
                }
              })
              .catch(e => { console.error(`❌ copyPages main ${e}`) })
            // const docPages = docPart.getPages()
            if (files?.length > 0) {
              files
                .slice()
                .sort((a, b) => a?.position - b?.position)
                .forEach(async (file, index) => {
                  await fetchAnnexeB64(file)
                    .then(res => {
                      // devLog('fetch then res', res)
                      base64ToBufferAsync(res)
                      // res.arrayBuffer()
                        .then(async result => {
                          const docPart = await PDFDocument.load(result)
                          let docLength = docPart.getPageCount()
                          pages = Array.from({ length: docLength }, (v, i) => i)
                          pdfDoc.copyPages(docPart, pages)
                          .then(res => {
                            for (const page of res) {
                              pdfDoc.addPage(page)
                            }
                            if (index === files.length - 1) {
                              resolve(true)
                            }
                          })
                          .catch(e => { console.error(`❌ copyPages att #${index} ${e} `) })
                        })
                    })
                })
            } else { resolve(true) }
          })
      })
      // .catch(e => {
      //   console.error(`Error fetching url ${url} \n ${e}`)
      //   resolve(false)
      // })
  })
  if (await promise) {
    return await pdfDoc.save()
  }
}

export const getLabelFontSize = (setup, column) => {
  let defaultSize = isDefined(setup?.layout?.defaultLabelSize) ? setup.layout.defaultLabelSize : 8
  if (isDefined(column.headerFontSize) && column.headerFontSize > 0) { return column.headerFontSize * 0.7 }
  else { return defaultSize }
}
export const getDataFontSize = (setup, column) => {
  let defaultSize = isDefined(setup?.layout?.defaultFontSize) ? setup.layout.defaultFontSize : 10
  if (isDefined(column.fontSize) && column.fontSize > 0) { return column.fontSize * 0.7 }
  else { return defaultSize }
}

export const convertHtmlToNative = (html) => {

  // temporary return sans style
  return (
    <View>
      {
        html.split(/(?:<br>)/g)
          .map((entry, index) => (
          <View key={index} style={{ paddingVertical: 4 }}>
              <Text style={{ fontSize: 12 }} >{decodeHtml(entry)}</Text>
          </View>)
        )
      }
    </View>
  )
  // return (
  //   convertHtmlTags(html)
  // )
}

export const decodeHtml = (input) => {
  var doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
}

export const encodeHtml = (input) => {
  return input
    // special chars
    .replace('&', '&amp;')
    .replace('°', '&deg;')
    .replaceAll('à', '&agrave;')
    .replaceAll('è', '&egrave;')
    .replaceAll('ì', '&igrave;')
    .replaceAll('ò', '&ograve;')
    .replaceAll('ù', '&ugrave;')
    .replaceAll('ä', '&auml;')
    .replaceAll('ë', '&euml;')
    .replaceAll('ï', '&iuml;')
    .replaceAll('ö', '&ouml;')
    .replaceAll('ü', '&uuml;')
    .replaceAll('â', '&acirc;')
    .replaceAll('ê', '&ecirc;')
    .replaceAll('î', '&icirc;')
    .replaceAll('ô', '&ocirc;')
    .replaceAll('û', '&ucirc;')
    .replaceAll('é', '&eacute;')
    .replaceAll('œ', '&oelig;')
    .replaceAll('ç', '&ccedil;')
    // Majuscules
    .replaceAll('À', '&Agrave;')
    .replaceAll('È', '&Egrave;')
    .replaceAll('Ì', '&Igrave;')
    .replaceAll('Ò', '&Ograve;')
    .replaceAll('Ù', '&Ugrave;')
    .replaceAll('Ä', '&Auml;')
    .replaceAll('Ë', '&Euml;')
    .replaceAll('Ï', '&Iuml;')
    .replaceAll('Ö', '&Ouml;')
    .replaceAll('Ü', '&Uuml;')
    .replaceAll('Â', '&Acirc;')
    .replaceAll('Ê', '&Ecirc;')
    .replaceAll('Î', '&Icirc;')
    .replaceAll('Ô', '&Ocirc;')
    .replaceAll('Û', '&Ucirc;')
    .replaceAll('É', '&Eacute;')
    .replaceAll('Œ', '&OElig;')
    .replaceAll('Ç', '&Ccedil;')
    //! do not replace "<" & ">" in html
    // .replace('<', '&lt;')
    // .replace('>', '&rt;')
    
}

const convertHtmlTags = (html) => {
  const htmlTags = ['a', 'address', 'em', 'p', 'span', 'strong']
  const containerTags = ['p', 'span']
  const textTags = ['a', 'address', 'em', 'span', 'strong']
  const linkTags = ['a href=']
  const styledTags = ['p style=', 'span style=']
    // Convert <address>
    // Convert <strong>
    // Convert <a>
    // Convert <em>
    let array
    if (Array.isArray(html)) array = html;
    else array = html.split(/(?:<|>)/g)
    //devLog('Array', array)

    return (
      <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', }}>
        {
          array.map((entry, index) => {
            if (containerTags.includes(entry)) {
              let lastIndex = array.lastIndexOf('/' + entry)
              let newArray = array.slice(index + 1, lastIndex)
              return (
                <View >{convertHtmlTags(newArray)}</View>
              )
            } else if (entry === 'em') {
              let lastIndex = array.lastIndexOf('/' + entry)
              let newArray = array.slice(index + 1, lastIndex)
              return (
                <Text style={{ fontWeight: 'bold', }}>{decodeHtml(array[index + 1])}</Text>
              )
            } else return <Text>{decodeHtml(entry)}</Text>
          })
        }
      </View>
    )

}

