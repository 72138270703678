import React, {useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import {useTranslation} from "react-i18next";
import TextField from "@material-ui/core/TextField";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    MuiInputBaseInput:{
        padding : 0
    },
    mainField: {
        display: 'flex',
        flexDirection: 'column',
        padding: '0 10px 10px 40px',
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    inputType:{
        marginTop: '15px',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    field : {
        display: 'flex',
    },
    inputField:{
        padding : '0px 0px 7px',
    },
    mainInputField:{
        display: 'flex',
    }
}));


const FormatPlugcodeInternalConstructorComponent = (props) => {
    const classes = useStyles();
    const { t } = useTranslation(['common', 'models']);
    const [newDataClef, setDataClef] = useState(props.field.options.clef !== ""  ? props.field.options.clef  : "");
    const [newDataComment, setDataComment] = useState(props.field.options.comment !== ""  ? props.field.options.comment  : "");
    const [fieldstoCopyIntoComment, setFieldstoCopyIntoComment] = useState(props.field.options?.copyfieldstocomment !== ""  ? props.field.options.copyfieldstocomment  : "");
    const [fieldstoCopyIntoFields, setFieldstoCopyIntoFields] = useState(props.field.options?.copyfieldstofields !== ""  ? props.field.options.copyfieldstofields  : "");

    const handleDataClef = (clef) => {
        setDataClef(clef);
        props.field.options.clef = clef;
        let model = [...props.model];
        model[props.index].options.clef = clef;
        props.setModel(model);
    }

    const handleDataComment = (comment) => {
        setDataComment(comment);

        props.field.options.comment = comment;
        let model = [...props.model];
        model[props.index].options.comment = comment;
        props.setModel(model);
    }

    const handleDataCopieComment = (fields) => {
        setFieldstoCopyIntoComment(fields);

        props.field.options.copyfieldstocomment = fields;
        let model = [...props.model];
        model[props.index].options.copyfieldstocomment = fields;
        props.setModel(model);

    }

    const handleDataCopieFields = (fields) => {
        setFieldstoCopyIntoFields(fields);
        props.field.options.copyfieldstofields = fields;
        let model = [...props.model];
        model[props.index].options.copyfieldstofields = fields;
        props.setModel(model);
    }

    return (
        <div className={classes.mainField}>
            {/* <div className={classes.field}>
                <Input
                    id="standard-full-width-plugform"
                    label="Label"
                    placeholder={t('fields.format-choice-placeholder-'+props.field.format.toLowerCase())}
                    fullWidth={true}
                    margin="none"
                    inputlabelprops={{
                        shrink: true,
                    }}
                    inputProps={
                        {
                            fieldkey: props.field.key,
                            className: classes.inputField,
                            maxLength: 40
                        }
                    }
                    className="text-line"
                    onChange={(event) => props.handleEditLabel(event.target.value, props.field, props.index)}
                    defaultValue={props.field.label}
                />
            </div> */}
            <div className={classes.mainInputField}>
                <Input
                    id="standard-full-width-clef"
                    label="Label"
                    placeholder={t('fields.format-choice-placeholder-'+props.field.format.toLowerCase()+'-clef')}
                    fullWidth={true}
                    margin="none"
                    inputlabelprops={{
                        shrink: true,
                    }}
                    inputProps={
                        {
                            fieldkey: props.field.key,
                            className: classes.inputField,
                            maxLength: 40
                        }
                    }
                    className="text-line"
                    onChange={(event) => handleDataClef(event.target.value)}
                    defaultValue={newDataClef}
                />
            </div>
            <div className={classes.inputType}>
                <div className={classes.mainInputField}>
                    <Input
                        id="standard-full-width-clef"
                        label="Label"
                        placeholder={t('fields.format-choice-placeholder-'+props.field.format.toLowerCase()+'-comment')}
                        fullWidth={true}
                        margin="none"
                        inputlabelprops={{
                            shrink: true,
                        }}
                        inputProps={
                            {
                                fieldkey: props.field.key,
                                className: classes.inputField,
                                maxLength: 255
                            }
                        }
                        className="text-line"
                        onChange={(event) => handleDataComment(event.target.value)}
                        defaultValue={newDataComment}
                    />
                </div>
            </div>

            <div className={classes.inputType}>
                <div className={classes.mainInputField}>
                    <Input
                        id="standard-full-width-clef"
                        label="Label"
                        placeholder={t('fields.format-choice-placeholder-'+props.field.format.toLowerCase()+'-copyfieldstocomment')}
                        fullWidth={true}
                        margin="none"
                        inputlabelprops={{
                            shrink: true,
                        }}
                        inputProps={
                            {
                                fieldkey: props.field.key,
                                className: classes.inputField,
                                maxLength: 255
                            }
                        }
                        className="text-line"
                        onChange={(event) => handleDataCopieComment(event.target.value)}
                        defaultValue={fieldstoCopyIntoComment}
                    />
                </div>
            </div>

            <div className={classes.inputType}>
                <div className={classes.mainInputField}>
                    <Input
                        id="standard-full-width-clef"
                        label="Label"
                        placeholder={t('fields.format-choice-placeholder-'+props.field.format.toLowerCase()+'-copyfieldstofields')}
                        fullWidth={true}
                        margin="none"
                        inputlabelprops={{
                            shrink: true,
                        }}
                        inputProps={
                            {
                                fieldkey: props.field.key,
                                className: classes.inputField,
                                maxLength: 255
                            }
                        }
                        className="text-line"
                        onChange={(event) => handleDataCopieFields(event.target.value)}
                        defaultValue={fieldstoCopyIntoFields}
                    />
                </div>
            </div>

        </div>
    )
}

// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatPlugcodeInternalConstructorComponent);





