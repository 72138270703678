import { Tabs, makeStyles } from '@material-ui/core'
import React, { useContext } from 'react'
import { isDefined } from '../../../utils/utils'
import colors from '../../../assets/themes/Colors'
import { DrawerContext } from '../../../App'

const useStyles = makeStyles({
  // Tabs
  tabsWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  tabsCtn: {
    '& .MuiTab-root': {
      textTransform: 'none !important',
    },
    '& .MuiTabs-indicator': {
      top: 0,
      backgroundColor: colors.main,
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      fontWeight: 'lighter',
    },
    '& div.MuiTabs-scroller.MuiTabs-fixed': {
      overflow: 'auto !important',
    },
    '& div.MuiTabs-scrollable': {
      maxWidth: props => props.maxWidth,
    },
  },
  voidSpace: {
    flex: 1,
    opacity: 0.7,
    position: 'relative',
  },
  selected: {
    '& .MuiTab-textColorInherit.Mui-selected': {
      color: props => props.color,
      boxShadow: '5px -20px 10px 9px rgb(0 0 0 / 15%), 10px -20px 20px 0px rgb(0 0 0 / 10%)',
    },
    '& .MuiTab-root': {
      '@media(min-width: 600px)': {
        maxWidth: props => props.tabWidth,
        minWidth: 'auto !important',
      },
    },
    '& div.MuiTabs-scrollable': {
      maxWidth: props => props.maxWidth,
    },
  },
  tabWidth: {
    maxWidth: props => props.maxWidth,
    // '& div.MuiTabs-root': {
    //   maxWidth: props => props.maxWidth,
    // },
    // '& div.MuiTabs-scrollable': {
    //   maxWidth: props => props.maxWidth,
    // },
  },
  tabColor: {
    '& .MuiTab-root': {
      backgroundColor: props => props.backgroundColor,
    },
  }
})

const PlugTabs = (props) => {
  const { openDrawer } = useContext(DrawerContext)
  const tabWidth = (props.orientation === 'vertical' ? 120 : 200) + 'px !important'
  const maxWidth = 'calc(100vw - ' + (openDrawer ? 400 : 220) + 'px) !important'
  const prop = { maxWidth: maxWidth, tabWidth: tabWidth, backgroundColor: (props.backgroundColor || 'white'), color: (props.color || colors.main) }
  const classes = useStyles(prop)
  
  return (
    <div className={`${classes.tabsWrapper} ${classes.tabWidth}`} style={{ maxWidth: maxWidth }}>
      <div className={`${classes.tabsCtn} ${classes.selected} ${classes.tabColor} `}>
        <Tabs
          className={classes.tabWidth}
          onChange={props.onChange}
          orientation={props.orientation || 'horizontal'}
          scrollButtons="auto"
          style={{ maxWidth: maxWidth }}
          textColor="inherit"
          value={props.value}
          variant={props.orientation === 'vertical' ? "standard" : "scrollable"}
        >
          {props.children}
        </Tabs>
      </div>
      <span className={classes.voidSpace + " flex_display_align_items_justify_content_center"}>
        {
          isDefined(props.extra) &&
          props.extra
        }
      </span>
    </div>
  )
}

export default PlugTabs
