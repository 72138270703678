import { IconContext } from "react-icons"
import { AiOutlineRotateRight } from "react-icons/ai"
import { BiErrorAlt } from "react-icons/bi"
import { BsEraserFill, BsLink45Deg, BsUiChecksGrid } from "react-icons/bs"
import { FaAsterisk, FaInfoCircle } from "react-icons/fa"
import { GiResize } from "react-icons/gi"
import { GrDocumentConfig } from "react-icons/gr"
import { MdEdit, MdOutlineDomainVerification, MdOutlineMarkEmailRead, MdOutlineSendTimeExtension, MdSettings, MdVisibilityOff } from "react-icons/md"
import { RiAddFill, RiEdit2Fill } from "react-icons/ri"
import { RxInput } from "react-icons/rx"
import { TbFileText, TbNumbers, TbTools } from "react-icons/tb"
import { TfiLayoutListThumbAlt } from "react-icons/tfi"
import colors from "../../../assets/themes/Colors"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { makeStyles } from "@material-ui/core/styles"
import { faSlash } from "@fortawesome/free-solid-svg-icons"
import { isDefined } from "../../../utils/utils"
import { ReactComponent as Extdata } from '../../../assets/images/format-icon/svg/extdata.svg'
import { ReactComponent as ResizeBottomRight } from '../../../assets/images/svg/resize-bottom-right.svg'
import { ReactComponent as MissingDocument } from '../../../assets/images/svg/missing-document.svg'

const mainIcon = {
  position: 'relative'
}
const useStyles = makeStyles(() => ({
  fontStyle: {
      fontSize: 12,
      fontWeight: 'bold',
  },
  fontError: {
      fontSize: 12,
      color: colors.red,
      fontWeight: 'bold',
      paddingLeft: '5px!important',
      width: '-webkit-fill-available',
      display: 'flex',
      justifyContent: 'flex-end',
  },
  delButton: {
      justifyContent: 'center',
      alignItems: 'center',
      color: colors.main,
      position: 'absolute',
      right: 0
  },
  indicatorCtn: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      gap: 4,
      height: 15,
      cursor: 'default',
      justifyContent: 'center',
  },
  indicator: {
      fontSize: 8,
      width: 12,
      height: 10,
      position: 'relative',
  },
  indicatorFalse: {
      fontWeight: "bolder",
      color: colors.bloodOrange,
      fontSize: 7,
      position: 'absolute',
      top: -2,
      left: 0,
      right: 0,
  },
  slashIcon: {
      fontSize: 8,
      color: colors.bloodOrange,
      position: 'absolute',
      top: 6,
      left: 0,
      right: 0,
  },
  indicatorBtn: {
    fontSize: 12,
    color: colors.main,
    position: 'relative',
    fontWeight: 'bold',
    cursor: 'pointer',
  }
}))
const secondIcon = (size) => {
  return {
    position: 'absolute',
    right: -10,
    bottom: size / (-2),
    transform: 'scale(0.7)'
  }
}
export const BackupTable = (props) => {
  const iconStyle = {
    color: props.color || colors.main,
    fontSize: props.size || 24 
  }
  if (props.disabled) {
    iconStyle.cursor = 'not-allowed'
  }
  return (
    <span className="material-icons" style={iconStyle}>
      backup_table
    </span>
  )
}

export const InputIcon = (props) => {
  return (
    <IconContext.Provider value={{ color: props.color || colors.main, size: props.size || 24 }}>
      <RxInput />
    </IconContext.Provider>
  )
}
export const AddInfoIcon = (props) => {
  return (
    <IconContext.Provider value={{ color: props.color || colors.main, size: props.size || 24 }}>
      <div style={mainIcon}>
        <FaInfoCircle style={isDefined(props.style) ? props.style : undefined}/>
        <div style={secondIcon(props.size || 24)}>
          <RiAddFill color={isDefined(props.secondaryColor) ? props.secondaryColor : props.color || "red" }/>
        </div>
      </div>
    </IconContext.Provider>
  )
}
export const EditInfoIcon = (props) => {
  return (
    <IconContext.Provider value={{ color: props.color || colors.main, size: props.size || 24 }}>
      <div style={mainIcon}>
        <FaInfoCircle style={isDefined(props.style) ? props.style : undefined}/>
        <div style={secondIcon(props.size || 24)}>
          <MdEdit color={isDefined(props.secondaryColor) ? props.secondaryColor : props.color || "red" } />
        </div>
      </div>
    </IconContext.Provider>
  )
}
export const InfoIcon = (props) => {
  return (
    <IconContext.Provider value={{ color: props.color || colors.main, size: props.size || 24 }}>
      <FaInfoCircle style={isDefined(props.style) ? props.style : undefined} />
    </IconContext.Provider>
  )
}
export const EditValueIcon = (props) => {
  return (
    <IconContext.Provider value={{ color: props.color || colors.main, size: props.size || 24 }}>
      <RiEdit2Fill style={isDefined(props.style) ? props.style : undefined} />
    </IconContext.Provider>
  )
}

export const SettingsIcon = (props) => {
  return (
    <IconContext.Provider value={{ color: props.color || colors.main, size: props.size || 24 }}>
      <MdSettings />
    </IconContext.Provider>
  )
}

export const GallerySelect = (props) => {
  return (
    <IconContext.Provider value={{ color: props.color || colors.main, size: props.size || 24 }}>
      <BsUiChecksGrid />
    </IconContext.Provider>
  )
}

export const Asterisk = (props) => {
  return (
    <FaAsterisk
      color={isDefined(props.color) ? props.color : colors.main}
      size={isDefined(props.size) ? props.size : 24}
      stroke={isDefined(props.color) ? props.color : colors.main}
      strokeWidth={8}
    />
  )
}
export const AsteriskOutline = (props) => {
  return (
    <FaAsterisk
      stroke={isDefined(props.color) ? props.color : colors.main}
      strokeWidth={8}
      size={isDefined(props.size) ? props.size : 24} color="white"/>
  )
}
export const ToolsIcon = (props) => {
  return (
    <TbTools
      stroke={isDefined(props.color) ? props.color : colors.main}
      strokeWidth={isDefined(props.strokeWidth) ? props.strokeWidth : 2}
      size={isDefined(props.size) ? props.size : 24} color="white"
    />
  )
}
export const CheckScreenIcon = (props) => {
  return (
    <MdOutlineDomainVerification
      color={isDefined(props.color) ? props.color : colors.main}
      size={isDefined(props.size) ? props.size : 24}
    />
  )
}

export const SendOptionsIcon = (props) => {
  return (
    <MdOutlineSendTimeExtension
      color={isDefined(props.color) ? props.color : colors.main}
      size={isDefined(props.size) ? props.size : 24}
    />
  )
}

export const SentEmailIcon = (props) => {
  return (
    <MdOutlineMarkEmailRead
      color={isDefined(props.color) ? props.color : colors.main}
      size={isDefined(props.size) ? props.size : 24}
    />
  )
}

export const AttLayout = (props) => {
  return (
    <IconContext.Provider value={{ color: props.color || colors.main, size: props.size || 24 }}>
      <GiResize />
    </IconContext.Provider>
  )
}

export const FieldVisibility = (props) => {
  return (
    <IconContext.Provider value={{ color: props.color || colors.main, size: props.size || 24 }}>
      <div style={mainIcon}>
        <TfiLayoutListThumbAlt />
        <div style={secondIcon(props.size || 24)}>
          <MdVisibilityOff color="red"/>
        </div>
      </div>
    </IconContext.Provider>
  )
}

export const RelatedPlugnote = (props) => {
  return (
    <IconContext.Provider value={{ color: props.color || colors.main, size: props.size || 24 }}>
      <div style={mainIcon}>
        <TbFileText />
        <div style={secondIcon(props.size || 24)}>
          <BsLink45Deg color="red"/>
        </div>
      </div>
    </IconContext.Provider>
  )
}

const indicatorStyle = (props) => {
  // console.log('style props', props);
  return {
    fontSize: isDefined(props.fontSize) ? `${props.fontSize}px` : '7px',
    position: props.relative ? "relative" : "absolute",
    top: props.relative ? "auto" : "-2px",
  }
}

const slashStyle = (props) => {
  return {
    top: props.relative ? "-2px" : 6,
    fontSize: isDefined(props.fontSize) ? `${props.fontSize}px` : '7px',
  }
}

export const InvisiblePDF = (props) => {
  const classes = useStyles();

  return (
    <span className={classes.indicator} >
      {
        !props.visible &&
        <FontAwesomeIcon icon={faSlash} className={classes.slashIcon} style={slashStyle(props)} />
      }
      <span className={classes.indicatorFalse} style={{ ...indicatorStyle(props) }}>PDF </span>
    </span>
  )
}

export const InvisiblePF = (props) => {
  const classes = useStyles();

  return (
    <span className={classes.indicator} >
      {
        !props.visible &&
        <FontAwesomeIcon icon={faSlash} className={classes.slashIcon} style={slashStyle(props)} />
      }
      <span className={classes.indicatorFalse} style={indicatorStyle(props)}>PF- </span>
    </span>
  )
}

export const InvisiblePN = (props) => {
  const classes = useStyles();

  return (
    <span className={classes.indicator} >
      {
        !props.visible &&
        <FontAwesomeIcon icon={faSlash} className={classes.slashIcon} style={slashStyle(props)} />
      }
      <span className={classes.indicatorFalse} style={indicatorStyle(props)}>PN- </span>
    </span>
  )
}

export const VisibilityIndicator = (props) => {
  const classes = useStyles()
  const containerStyle = {
    ...props.containerStyle,
    position: 'relative',
  }
  const textStyle = {
    fontSize: props.large ? 16 : 12,
  }

  const slashStyle = props.large
    ? { fontSize: 16, top: -3, left: 0 }
    : { fontSize: 12, top: 1, left: props.text === 'Plugnote' ? '40%' : 0 }

  return (
    <span className={classes.indicatorBtn} style={containerStyle}>
      {
        !props.visible &&
        <FontAwesomeIcon icon={faSlash} className={classes.slashIcon} style={slashStyle} />
      }
      <span className={classes.indicator} style={ !props.visible ? { ...textStyle, color: colors.bloodOrange } : textStyle }>{props.text}</span>
    </span>
  )
}

export const OpenFieldIcon = (props) => {
  const iconStyle = {
    color: props.color || colors.main,
    padding: '4px 8px',
    ...props.style,
  }
  return (
  <span className="material-icons" style={iconStyle}>
      {
        props.open ? 'keyboard_arrow_up' : 'keyboard_arrow_down'
      }
    </span>
  )
}
export const LockedIcon = (props) => {
  const lockStyle = {
    color: props.locked ? props.color || colors.main : colors.coal,
    fontSize: props.fontSize || 16,
  }
  return (
    <span className="material-icons" style={lockStyle}>
      {
        props.locked ? 'lock' : 'lock_open'
      }
    </span>
  )
}

export const ClearFormattingIcon = props => {
  const eraserStyle = {
    transform: 'scaleY(-0.9) scaleX(0.9)',
    size: props.size - 4 || 12,
  }

  const eraserCtn = {
    position: 'absolute',
    right: -5,
    bottom: -8,
  }

  return (
    <span style={{ position: 'relative', marginTop: -3, cursor: 'pointer' }} >
      <span className="material-icons" style={{ fontSize: props.size || 20 }} >
          text_format
      </span>
      <span style={eraserCtn}>
        <BsEraserFill style={eraserStyle}/>
      </span>
    </span>
  )
}

export const TableOrientationIcon = props => {

  return (
    <AiOutlineRotateRight fill={props.color || colors.main} size={props.size || 24}
      height={props.size || 24} width={props.size || 24}
    />
  )
}

export const ErrorIcon = props => {

  return (
    <BiErrorAlt fill={props.color || colors.main} size={props.size || 24}
      height={props.size || 24} width={props.size || 24}
    />
  )
}

export const DocumentConfigIcon = props => {

  return (
    <GrDocumentConfig fill={props.color || colors.main} size={props.size || 24}
      height={props.size || 24} width={props.size || 24}
    />
  )
}
export const MissingDocumentIcon = props => {

  return (
    <MissingDocument fill={props.color || colors.main} size={props.size || 24}
      height={props.size || 24} width={props.size || 24}
      stroke={props.stroke || colors.unselected}
    />
  )
}
export const NumberIcon = props => {

  return (
    <TbNumbers fill={props.color || colors.main} size={props.size || 24}
      height={props.size || 24} width={props.size || 24}
    />
  )
}

export const ExtDataIcon = props => {
  return <Extdata height={props.size || 16} width={props.size || 16}/>
}

export const ResizeBottomRightIcon = props => {
  return <ResizeBottomRight />
}

