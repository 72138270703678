import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { devLog, handlePreventDefault, isDefined } from '../../../utils/utils';
import { Box, Collapse, IconButton, Input, ListSubheader, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, makeStyles } from '@material-ui/core';
import { ColorCheckbox, PlugTooltip } from '../../custom/CustomComponents';
import colors from '../../../assets/themes/Colors';
import { Asterisk, AsteriskOutline, SettingsIcon, VisibilityIndicator } from '../../custom/icons/CustomIcons';
import { ArrowDropDown, ArrowDropUp, KeyboardArrowDown, KeyboardArrowUp, Settings } from '@material-ui/icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';
import { ReactComponent as MultiSelect } from '../../../assets/images/format-icon/svg/multi-select.svg'
import { ReactComponent as MultiSelectAdd } from '../../../assets/images/format-icon/svg/free-multi-select.svg'
import { ReactComponent as SingleSelect } from '../../../assets/images/format-icon/svg/single-select.svg'
import { ReactComponent as SingleSelectAdd } from '../../../assets/images/format-icon/svg/free-single-select.svg'
import FormatTMExtDataConstructorComponent from '../FormatTMExtDataConstructorComponent';
import FormatIntDataMajConstructorComponent from '../FormatIntDataMajConstructorComponent';
import FormatTMReferenceConstructorComponent from '../FormatTMReferenceConstructorComponent';
import FormatTMFormulaConstructorComponent from '../FormatTMFormulaConstructorComponent';
import { RenderPreselList } from '../../custom/TableRenderPresel';
import * as XLSX from "xlsx";
import FormatTMCombofillExtDataConstructorComponent from "../FormatTMCombofillExtDataConstructorComponent";
import { Autocomplete } from '@material-ui/lab';
// import EditTableOrientationComponent from '../../custom/buttons/EditTableOrientationComponent';

const hasConfigurator = ['extdata', 'formula', 'intdata', 'numeric-ref', 'presel', 's33data', 'intdatamaj', 'combofillextdata'];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 680,
  },
  tableRoot: {
    marginRight: '20px',
  },
  headerCtn: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    borderColor: colors.palePlugGreen,
    boxShadow: '3px 0px 6px 4px rgba(136, 136, 136, 0.12)',
    '& .MuiTableCell-root': {
      border: '0px solid !important',
    },
  },
  headers: {
    '& .MuiTableCell-root': {
      padding: '4px !important',
      textAlign: 'center',
      position: 'sticky',
    },
    backgroundColor: colors.palePlugGreen,
  },
  flatHeader: {
    display: 'none',
  },
  appBar: {
    backgroundColor: 'white',
    borderBottom: '3px solid grey',
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: 'black',
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    width: '10%',
  },
  field: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  inputField: {
    padding: '0px 0px 7px',
  },
  headerInputCtn: { fontSize: 13, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' },
  inputCenter: { textAlign: 'center', width: '95%', maxWidth: 250 },
  tableBody: {
    maxHeight: 'calc(60vh)',
  },
  mainField: {
    minWidth: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  selectType: {
    //paddingRight: '24px',
    marginTop: '15px',
    marginLeft: '10px',
  },
  inputType: {
    marginTop: '30px',
    marginLeft: '0px',
    marginRight: '20px',
  },
  inputListType: {
    marginTop: '15px',
    marginLeft: '10px',
  },
  configCell: {
    height: 60,
  },
  labelCell: {
    minWidth: 120,
    maxWidth: 280,
    width: '20%',
  },
  formatCell: {
    width: 160,
  },
  mandatoryCell: {
    width: 60,
  },
  smPaddingCell: {
    paddingLeft: 5,
    paddingRight: 5
  },
  formatSelectCtn: {
    width: 'auto',
    maxWidth: 160,
  },
  smColumn: {
    width: '20px !important',
    paddingLeft: 5,
    paddingRight: 5
  },
  preselConfig: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
    paddingBottom: 5,
    justifyContent: 'space-between',
  },
  switchCtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  configRow: {
    // verticalAlign: 'bottom',
    height: '60px !important',
  },
  openClose: {
    flex: '0 0 auto',
    color: 'rgba(0, 0, 0, 0.54)',
    cursor: 'pointer',
    overflow: 'visible',
    fontSize: '1.5rem',
    textAlign: 'center',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '50%',
    scrollMarginTop: '30px',
  },
  tableRow: {
    borderRadius: 4,
    color: colors.main,
    padding: '0px 5px',
    gap: 5,
    // display: 'flex',
    // alignItems: 'center'
  },
  rowNb: {
    fontSize: 14,
    marginRight: 4,
    border: '1px solid',
    padding: '0px 4px',
    borderRadius: '50%',
    verticalAlign: 'bottom',
  },
  rowContainer: {
    '& .MuiTableCell-root': {
      padding: '0px!important'
    },
  },
  selectedMask: {
    cursor: 'pointer',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: "6px",
    backgroundColor: colors.selectedAlpha,
    color: colors.white,
    padding: "3px 8px",
    minHeight: 24,
    gap: 5,
    fontSize: 13,
  },
  unselectedMask: {
    cursor: 'pointer',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 24,
    borderRadius: "6px",
    backgroundColor: colors.unselectedAlpha,
    color: colors.white,
    padding: "3px 8px",
    gap: 5,
    fontSize: 13,
  },
  masksContainer: {
    width: 'inherit',
    minHeight: 24,
    cursor: 'pointer',
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: "6px",
    alignItems: 'flex-start',
    gap: 5,
    paddingTop: 4,
    paddingBottom: 4,
  },
  subOpenClose: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: colors.main,
    cursor: 'pointer',
    '&:hover': {
      borderRadius: 50,
      background: colors.frost
    }
  },
  fieldIcon: {
    // stroke: colors.white,
    fill: colors.white,
    color: colors.white,
    width: 20,
    height: 'auto',
    verticalAlign: 'sub',
    cursor: 'pointer'
  },
  visibilityCtn: { display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 5, alignItems: 'center' },
  switch: {
    '& .MuiSwitch-root': { height: '43px !important' }, height: '43px !important',
    '& .MuiSwitch-track': { backgroundColor: colors.unselectedAlpha, opacity: 1, },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { backgroundColor: colors.unselectedAlpha, opacity: 1 }
  },
  switchIcon: { fontSize: 24, color: 'white', backgroundColor: colors.main, borderRadius: 12, padding: 2, },
  clickableText: {
    cursor: 'pointer',
  },
  warnToast: {
    backgroundColor: colors.unselected,
  },
  disabledIcon: {
    cursor: 'not-allowed',
    color: colors.greyLight,
  },
}))

const getTypeValue = (value) => {
  let multiselect;
  let editable;
  if (value === null || value === '') {
    return 'T01';
  } else {
    multiselect = value.multiselect
    editable = value.editable
    if (multiselect === false && editable === false) {
      return 'T01';
    } else if (multiselect === false && editable === true) {
      return 'T02';
    } else if (multiselect === true && editable === false) {
      return 'T03';
    } else if (multiselect === true && editable === true) {
      return 'T04';
    }
  }
}

const RenderFormatList = (props) => {
  // devLog('FormatList', props)
  const { t } = useTranslation('common')

  const formats = [...props.rows]
  formats.splice(0, 0, { category: 'Input' })
  formats.splice(7, 0, { category: 'Date & Time' })
  formats.splice(11, 0, { category: 'Selects' })
  formats.splice(15, 0, { category: 'Database' })
  formats.splice(20, 0, { category: 'Other' })

  const [newData, setNewData] = useState(props.row.format)

  const handleFormatChange = (e) => {
    if (isDefined(e.target.value)) {
      setNewData(e.target.value.toString())
      props.handleUpdateFormat(e.target.value)
    } else { handlePreventDefault(e) }
  }

  useEffect(() => {
    setNewData(props.row.format)
  }, [props.row])

  const disabled = props.row.id === 1000 ||props.row.id === 1001 || props.row.id === 1002 || props.row.id === 1003 ||
    props.row.format === "file-source" || props.row.format === "file-refid" || props.row.format === "file-uri"
  const  disabledFormats = ['file-source', 'file-uri', 'file-refid']

  const subheaderStyle = {
    marginLeft: 'auto',
    width: 'max-content',
    lineHeight: '20px',
    borderBottom: '1px solid ' + colors.paleGrey,
  }

  return (
    <Select
      fullWidth
      disabled={disabled}
      displayEmpty
      label="Grouping"
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={newData}
      onChange={(event) => {
        handleFormatChange(event)
      }}
    >
      <PlugTooltip title={t('common:global.help-manuels')}>
        <div className="cursor text_align" onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          props.handleModalHelpOpen(event)
        }}>
          <span id="icon-more" className="font_size_15 text-span-round" >
            ?
          </span>
          <span>{t('common:global.help-manuels')}</span>
        </div>
      </PlugTooltip>
      {
        formats
          .map((col, index) => {
            if (isDefined(col.category)) {
              return (
                <ListSubheader key={index} style={subheaderStyle}>{col.category}</ListSubheader>
              )
            }
            return (
              <MenuItem disabled={disabledFormats.includes(col.format)} key={index} value={col.format}>{col.formatLabel}</MenuItem>
            )
          }
        )
      }
    </Select>
  )
}

export const RenderInputLabelEdit = (props) => {
  const classes = useStyles();
  const [newData, setNewData] = useState(props.row.label);
  const missingLabel = props.missingLabel === props.index
  const handleInputChange = (event) => {
    setNewData(event.target.value);
    props.handleUpdateLabel(props.index, event.target.value);
  }

  useEffect(() => {
    setNewData(props.row.label);
  }, [props.row]);

  useEffect(() => {
    if (missingLabel) {
      document.getElementById("subfield-label-input-" + props.index).scrollIntoView({ behavior: "smooth" })
    }
  }, [missingLabel]);

  return (
    <Input
      autoComplete="off"
      autoFocus={missingLabel}
      id={"subfield-label-input-" + props.index}
      label="Label"
      error={missingLabel}
      placeholder={missingLabel ? 'Missing Label' : 'Label'}
      classes={{ input: classes.inputCenter }}
      fullWidth={false}
      margin="none"
      inputlabelprops={{ shrink: true }}
      style={{  }}
      value={newData}
      onChange={(event) => handleInputChange(event)}
    />
  )
}

const RenderMaskList = (props) => {
  const { t } = useTranslation('common')
  const row = props.row
  // devLog('Masks row', row)
  const [masks, setMasks] = useState(props.rows.findIndex((row => row.format === props.row.format)) !== -1 ? [props.rows[props.rows.findIndex((row => row.format === props.row.format))].mask] : []);
  const [mask, setMask] = useState(props.row.mask !== null && !Array.isArray(props.row.mask) ? props.row.mask : '');
  const [source, setSource] = useState(Array.isArray(props.row.mask) ? props.row.mask : ['PLUGNOTE'])
  const classes = useStyles()

  useEffect(() => {
    setMask(props.row.mask !== null && !Array.isArray(props.row.mask) ? props.row.mask : '');
    setMasks(props.rows[props.rows.findIndex((row => row.format === props.row.format))]?.mask || []);
  }, [props.row.format])

  const handleMaskChange = (value) => {
    let regex = null;
    let _value
    if (typeof value === 'string') _value = value;
    else if (Array.isArray(value) && value.length > 0) _value = value[0]
    else _value = value.toString()
    setMask(_value);
    switch (props.row.format) {
      case "duration":
      case "time":
      case "numeric":
      case "date":
      case "tel":
        let indexRow = props.rows.findIndex((row => row.mask.includes(_value)));
        if (indexRow !== -1) {
          let row = props.rows[indexRow];
          let indexMask = row.mask.findIndex((mask => mask === _value));
          regex = row.regex[indexMask];
        }
        break;
      default:
        break;
    }
    props.handleUpdateMask(props.index, _value, regex);
  }

  const handleToggleSource = entry => {
    setSource(current => {
      const newMask = current?.includes(entry) ? current.filter(value => value !== entry) : [...current, entry]
      props.handleUpdateMask(props.index, newMask)
      return newMask
    })
  }

  // useEffect(() => {
  //   if (masks.length > 0 && mask === '' && masks[0] !== undefined) {
  //     handleMaskChange(masks[0])
  //   }
  // }, [masks])

  if (masks.length === 0) {
    if (row.format === 'file-source') {
      // return <span>FILE SOURCE</span>
      setMasks(['PLUGNOTE', 'URL', 'FTP'])
    } else return null
  }
  if (row.format === 'file-source') {
    return (
      <div className={classes.masksContainer} onClick={props.handleOpenSourceTab}  style={{ width: 'max-content' }}>
        <div style={{ color: colors.main, fontWeight: 'bold', }}>{t('helper.more-options')}</div>
        <span id='icon-create' className='material-icons md-small' style={{ color: colors.main }}>
          open_in_new
        </span>
        {/* {masks.map((entry) => (
          <span key={entry} onClick={() => handleToggleSource(entry)} style={{ marginRight: 8, cursor: 'pointer' }}>
            <ColorCheckbox checked={source?.includes(entry)} />
            {entry}
          </span>
        ))} */}
      </div>
    )
  }

  return (
    <div className={classes.masksContainer} key="uniquevalue">
      {
        masks
          .filter(mask => props.row?.id === 1000 ? (mask === '40') : true )
          .map((row, index) => {
          return (
            <div className={row === mask ? classes.selectedMask : classes.unselectedMask}
              key={index + "-mask"} onClick={() => handleMaskChange(row)}>{row}</div>
          )
        })
      }
    </div>
  );
}

const RenderFormulaComponent = (props) => {
  return (
    <div>
      <FormatTMFormulaConstructorComponent options={props.row.configuration} />
    </div>
  )
}

const RenderExtdataComponent = (props) => {
  // const classes = useStyles();
  // const { t, i18n } = useTranslation(['common', 'models']);
  const field = {
    label: props.row.label || '',
    key: props.field.key,
    index: props.index,
    format: 'table',
    type: props.row.format || '',
  }
  return <FormatTMExtDataConstructorComponent options={props.row.configuration} field={field} />
}

const RenderComboFillExtdataComponent = (props) => {
  // const classes = useStyles();
  // const { t, i18n } = useTranslation(['common', 'models']);
  const field = {
    label: props.row.label || '',
    key: props.field.key,
    index: props.index,
    format: 'table',
    type: props.row.format || '',
  }
  return <FormatTMCombofillExtDataConstructorComponent options={props.row.configuration} field={field} />
}

const RenderIntDataMajComponent = (props) => {
  const field = {
    options: props.row.configuration,
    format: 'intdatamaj',
    hidden: true
  }
  return <FormatIntDataMajConstructorComponent field={field} />
}

const RenderReferenceComponent = (props) => {
  const field = {
    label: props.row.label || '',
    key: props.field.key,
    index: props.index,
    format: 'table',
    type: props.row.format || '',
    complex: props.complex,
  }
  return <FormatTMReferenceConstructorComponent options={props.row.configuration} field={field} />
}

const RenderTypeList = (props) => {
  const { t } = useTranslation(['common', 'models']);
  const selectTypes = ['T01', 'T02', 'T03', 'T04']
  const classes = useStyles()
  const [newData, setNewData] = useState(getTypeValue(props.row.mask));

  useEffect(() => {
    setNewData(getTypeValue(props.row.mask));
  }, [props.row])

  const handleTypeChange = (value) => {
    setNewData(value.toString());
    props.handleUpdateType(props.index, value);
  }

  function rowRender(value) {
    let icon = ""
    let tooltip = ""
    let label = ""
    switch (value) {
      case 'T01':
        icon = <SingleSelect alt={t('fields.format-choice-' + props.field.format.toLowerCase())}
          fill='white !important' className={classes.fieldIcon} />
        tooltip = t("common:formats.preselection-type-unique-two")
        label = t("selects.single")
        break;
      case 'T02':
        icon = <SingleSelectAdd alt={t('fields.format-choice-' + props.field.format.toLowerCase())}
          fill='white !important' className={classes.fieldIcon} />
        tooltip = t("common:formats.preselection-type-unique-and-free-two")
        label = t("selects.single-add")
        break;
      case 'T03':
        icon = <MultiSelect alt={t('fields.format-choice-' + props.field.format.toLowerCase())}
          fill='white !important' className={classes.fieldIcon} />
        tooltip = t("common:formats.preselection-type-multiple-two")
        label = t("selects.multiple")
        break;
      case 'T04':
        icon = <MultiSelectAdd alt={t('fields.format-choice-' + props.field.format.toLowerCase())}
          fill='white !important' className={classes.fieldIcon} />
        tooltip = t("common:formats.preselection-type-multiple-and-free-two")
        label = t("selects.multiple-add")
        break;
      default:
        break;
    }
    let result = { icon: icon, label: label, tooltip: tooltip }
    // console.log('result', result);
    return result
  }

  return (
    <div className={classes.masksContainer} key="uniquevalue">
      {selectTypes.map((row, index) => {
        return (
          <PlugTooltip title={rowRender(row).tooltip} key={"presel-" + index}>
            <div className={row === newData ? classes.selectedMask : classes.unselectedMask}
              key={index + "-select"} onClick={() => handleTypeChange(row)}>
              <div className={classes.fieldIcon} style={{ width: '20px', fill: 'white !important' }}>
                {rowRender(row).icon}
              </div>
              {rowRender(row).label}
            </div>
          </PlugTooltip>
        )
      })}
    </div>
  );
}

const RenderEditTableComponent = (props) => {
  // devLog('RenderEditTable got props', props)
  const { t } = useTranslation('common')
  const classes = useStyles()
  // Variables
  const dataRows = props.dataRows
  const defaultRows = props.field?.options?.setup?.defaultRows || []

  // UseStates
  const [openEdit, setOpenEdit] = useState(false)
  const [openRow, setOpenRow] = useState(null)


  // Methods
  function handleToggleHidden(row, index) {
    let _rows = [...dataRows]
    _rows[index] = {
      ...row,
      hidden: isDefined(row.hidden) ? !row.hidden : true
    }
    props.setDataRows(_rows)
  }

  function handleTogglePDFVisibility(row, index) {
    let _row = { ...row };
    if (isDefined(row.showOnPdf)) {
      _row.showOnPdf = !row.showOnPdf;
    } else { _row.showOnPdf = false; }
    let _rows = [...dataRows];
    _rows[index] = _row;
    props.setDataRows(_rows);
  }

  function handleTogglePFVisibility(row, index) {
    let _row = { ...row };
    if (isDefined(row.encodable)) {
      _row.encodable = !row.encodable;
    } else { _row.encodable = false; }
    let _rows = [...dataRows];
    _rows[index] = _row;
    props.setDataRows(_rows);
  }

  function handleTogglePNVisibility(row, index) {
    let _row = { ...row }
    if (isDefined(row.visible)) {
      _row.visible = !row.visible
    } else { _row.visible = false }
    let _rows = [...dataRows]
    _rows[index] = _row
    props.setDataRows(_rows)
  }

  const handleToggleMandatory = (row, index) => {
    let _row = { ...row }
    if (isDefined(row.mandatory)) {
      _row.mandatory = !row.mandatory
    } else { _row.mandatory = true }
    let _rows = [...dataRows]
    _rows[index] = _row
    props.setDataRows(_rows)
  }

  // DragNDrop
  const onDragRow = (result) => {
    if (props.plugnoteCount > 0) {
      toast.info(t('tablemulti.folder-not-empty'),
        {
          autoClose: 5000,
          hideProgressBar: true,
          bodyClassName: classes.warnToast,
          className: classes.warnToast,
          toastClassName: classes.warnToast,
        })
    } else {
      // dropped outside the list
      // devLog('DragDrop result', result, dataRows)
      if (!result.destination) {
        return
      }
      if (result.destination.index === result.source.index) {
        return
      }
      if (props.field.format === 'document' || props.field.format === 'smailvalidmulti') { // colonnes fixes
        if (result.destination.index === 0 || result.destination.index === dataRows.length - 1) { return }
        if (props.field.format === 'document' && result.destination.index === 1) { return }
      }
      const sourceIndex = result.source.index
      const destinationIndex = result.destination.index

      let _dataRows = handleReorderRows(sourceIndex, destinationIndex)
      props.setDataRows(_dataRows)
    }
  }

  const handleToggleEdit = (index) => {
    if (openRow === index) {
      setOpenEdit(false)
      setOpenRow(null)
    } else {
      setOpenEdit(true)
      setOpenRow(index)
    }
  }

  const handleReorderRows = (src, dest) => {
    let _rows = [...dataRows]
    let _defaultRows = [...defaultRows]
    let idArray = dataRows.map(col => col.id)
    let newArray = dataRows.map((_, index) => index + 1)

    let tempId = idArray.slice(0, src).concat(idArray.slice(src + 1))
    tempId.splice(dest, 0, dataRows[src].id)
    let tempArray = newArray.slice(0, src).concat(newArray.slice(src + 1))
    let tempRows = _rows.slice(0, src).concat(_rows.slice(src + 1))
    tempArray.splice(dest, 0, src + 1)
    tempRows.splice(dest, 0, _rows[src])

    tempRows.forEach((row, rindex) => {
      row.id = idArray[rindex]
    })

    // defaultRows
    if (_defaultRows.length > 0) {
      let tempDefault = []
      _defaultRows.forEach(row => {
        let _row = {}
        _row.locked = row.locked
        idArray.forEach((id, index) => {
          _row[id] = row[tempId[index]]
        })
        tempDefault.push(_row)
      })
      props.handleChangeDefaultRows(tempDefault)
    }
    return tempRows
  }

  const handleDropPresel = (acceptedFiles, _, index) => {
      let files = acceptedFiles, f = files[0];
      let reader = new FileReader()
      reader.onload = function(e) {
          let bstr = new Uint8Array(e.target.result)
          let workbook = XLSX.read(bstr, {type: 'array'})
          /* DO SOMETHING WITH workbook HERE */
          /* Get first worksheet */
          const wsName = workbook.SheetNames[0]
          const ws = workbook.Sheets[wsName]
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_csv(ws, { header: 1 })
          handleAddPresels(data, index)
      }
      reader.readAsArrayBuffer(f)
  }

  const handleAddPresels = (list, index) => {
      let presels = dataRows[index].presel
      let _list = list.split(/\r\n|\n/)
      for (const element of _list) {
          const row = element.split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
          for (let j = 0; j < 1; j++) {
              let item = row[j]?.trim()

              if (item?.length > 0 && !presels.includes(item)) {
                  presels.push(item)
              }
          }
      }
      props.handleUpdatePresel(index, presels)
  }

  const getListStyle = dragging => ({
    background: dragging ? "lightblue" : "white",
    //padding: grid,
    //width: 250
  })

  // Render Methods

  const renderFieldConfigurator = (row, index) => {
    let template;
    switch (row.format) {
      case 'presel':
        template = (
          <RenderPreselList
            {...props}
            getTypeValue={getTypeValue}
            handleClose={() => { setOpenEdit(false); setOpenRow(null) }}
            handleUpdatePresel={props.handleUpdatePresel}
            handleUpdatePreselOrder={(value) => props.handleUpdatePreselOrder(index, value)}
            index={index}
            onChangeHandlerDrop={handleDropPresel}
            row={dataRows[index]}
            rows={props.rows}
          />
        );
        break;
      case 'formula':
        template = (
          <RenderFormulaComponent
            {...props}
            row={dataRows[index]}
            rows={props.rows}
            index={index}
          />
        );
        break;
      case 'extdata':
      case 'intdata':

        template = (
          <RenderExtdataComponent
            {...props}
            row={dataRows[index]}
            rows={props.rows}
            index={index}
          />
        );
        break;
      case 'combofillextdata':
        template = (
            <RenderComboFillExtdataComponent
                {...props}
                row={dataRows[index]}
                rows={props.rows}
                index={index}
            />
        );
        break;
      case 'intdatamaj':
        template = (
          <RenderIntDataMajComponent
            {...props}
            row={dataRows[index]}
            rows={props.rows}
            index={index}
          />
        )
        break;
      case 'intdata-': // in case format intdata S03 evolves
        template = (
          <RenderExtdataComponent
            row={dataRows[index]}
            rows={props.rows}
            index={index}
            format='S03'
          />
        )
        break;
      case 'numeric-ref':
        template = (
          <RenderReferenceComponent
            {...props}
            row={dataRows[index]}
            rows={props.rows}
            index={index}
            complex={false}
          />
        );
        break;
      case 's33data':
        template = (
          <RenderReferenceComponent
            {...props}
            row={dataRows[index]}
            rows={props.rows}
            index={index}
            complex={true}
          />
        );
        break;
      default:
        break;
    }
    return template;
  }

  const isMandatoryColumn = (row) => {
    return (
      row.id < 2000 ||
      row.format === 'file-uri' || row.format === 'file-source' || row.format === 'file-refid'
    )
  }

  const isDraggableRow = (row) => {
    return (
      row.format !== 'file-uri' && row.format !== 'file-source' && row.format !== 'file-refid' &&
      row.id !== 1000
    )
  }

  return (
    <div className={classes.field}>
      <TableContainer style={{ maxHeight: '60vh' }}>
        <Table className={classes.table}>
          <TableHead className={classes.headerCtn} >
            <TableRow className={classes.headers}>
              <TableCell align="center" className={classes.smColumn}>
                <span style={{ display: "inline-block" }}>
                  Col.
                </span>
              </TableCell>
              <PlugTooltip title={<span style={{ fontSize: 15, whiteSpace: 'pre-line' }}>{t('tablemulti.infos-chars')}</span>} >
                <TableCell className={classes.labelCell} >
                  <span>Libellé</span>
                  <span className="material-icons" style={{ fontSize: 20, color: colors.main }}>
                    info
                  </span>
                </TableCell>
              </PlugTooltip>

              <TableCell align="center" style={{ maxWidth: 160 }} className={classes.formatCell} >
                <span>Format</span>
              </TableCell>
              <TableCell align="center">Configuration</TableCell>

              <TableCell align="right" className={classes.smColumn}>
                <div style={{ display: "flex", alignItems: "center", flexDirection: "column", fontSize: 15 }}>
                  Total
                </div>
              </TableCell>

              <TableCell align="center" style={{ display: 'flex', marginLeft: 12 }} colSpan={4}>
                <span
                  style={{
                    padding: '4px 10px',
                    borderRadius: 5,
                    backgroundColor: colors.main,
                    color: colors.white,
                    cursor: "pointer",
                    textAlign: "center",
                    alignSelf: 'flex-end',
                    minWidth: 172,
                    marginLeft: 'auto',
                  }}
                  onClick={props.handleAddRow}>
                  {t('tablemulti.add-column')}
                </span>
              </TableCell>
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={onDragRow}>
            <Droppable droppableId="droppable" direction="vertical">
              {(droppableProvided, snapshot) => (
                <TableBody
                  ref={droppableProvided.innerRef}
                  {...droppableProvided.droppableProps}
                  style={getListStyle(snapshot.isDraggingOver)}
                  className={classes.tableBody}
                >
                  {
                    dataRows.map((row, index) => {
                      const dragId = row.dragId || row.label
                      return (
                        <Draggable
                          key={dragId}
                          draggableId={dragId}
                          index={index}
                          isDragDisabled={!isDraggableRow(row)}
                        >
                          {(draggableProvided, snapshot) => {
                            const isMandatory = isMandatoryColumn(row)
                            const isDraggable = isDraggableRow(row)
                            return (
                              <React.Fragment>
                                <TableRow
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                  style={{
                                    ...draggableProvided.draggableProps.style,
                                    background: snapshot.isDragging
                                      ? "rgba(245,245,245, 0.75)"
                                      : "none"
                                  }}
                                >
                                  <TableCell align="center" className={`${classes.configCell} ${classes.smColumn}`} >
                                    {index + 1}
                                  </TableCell>

                                  <TableCell align="center" className={`${classes.configCell} ${classes.labelCell}`}>
                                    <RenderInputLabelEdit {...props} row={dataRows[index]} index={index} handleUpdateLabel={props.handleUpdateLabel} missingLabel={props.missingLabel} />
                                  </TableCell>

                                  <TableCell align="center" className={`${classes.configCell} ${classes.formatCell}`}>
                                    <div className={classes.formatSelectCtn}>
                                      <RenderFormatList
                                        handleModalHelpOpen={props.handleOpenModalColonneHelp}
                                        handleUpdateFormat={(value) => props.handleUpdateFormat(index, value)} 
                                        row={dataRows[index]} index={index}
                                        rows={props.rows}
                                      />
                                    </div>
                                  </TableCell>

                                  <TableCell className={`${classes.configCell} ${classes.smPaddingCell}`}>
                                    {
                                      row.format === 'presel' ?
                                        <div className={classes.preselConfig}>
                                          <RenderTypeList {...props}
                                            row={dataRows[index]}
                                            index={index} rows={props.rows}
                                            handleUpdateType={props.handleUpdateType}
                                          />

                                          <div aria-label="expand row" size="small"
                                            style={{ margin: '0 -4px 0 4px' }}
                                            onClick={() => handleToggleEdit(index)}
                                          >
                                            {openEdit && openRow === index ?
                                              <div className={classes.subOpenClose}>
                                                <Settings /><KeyboardArrowUp /></div> :
                                              <div className={classes.subOpenClose}>
                                                <Settings /><KeyboardArrowDown /></div>}
                                            ({row.presel.length})
                                          </div>
                                        </div>
                                        : <RenderMaskList {...props} row={dataRows[index]}
                                          index={index} rows={props.rows}
                                          handleOpenSourceTab={props.handleOpenSourceTab}
                                          handleUpdateMask={props.handleUpdateMask} />
                                    }
                                    {(row.format === 'formula' || row.format === "extdata" || row.format === "intdata" || row.format === "numeric-ref" || row.format === "s33data" || row.format === "intdatamaj" || row.format === "combofillextdata") &&
                                      <IconButton
                                        aria-label="expand row"
                                        onClick={() => handleToggleEdit(index)}
                                        size="small"
                                      >
                                        {
                                          !openEdit && openRow !== index ?
                                            <div className={classes.openClose}>
                                              <SettingsIcon /><ArrowDropDown />
                                            </div>
                                            :
                                            <div className={classes.openClose}>
                                              <SettingsIcon /><ArrowDropUp />
                                            </div>
                                        }
                                      </IconButton>
                                    }
                                  </TableCell>

                                  <TableCell align="center" className={classes.smColumn}>
                                    {
                                      (row.format === "numeric" || row.format === "formula") &&
                                      <PlugTooltip title="Total" key={"recap-" + index} placement="top">
                                        <span
                                          onClick={() => { props.handleEnableRecapSpecificRow(index, row?.recap !== true) }}
                                        >
                                          <ColorCheckbox
                                            style={{
                                              color: colors.main,
                                              width: '20px',
                                              marginLeft: '10px',
                                            }}
                                            checked={row?.recap === true || false}
                                          />
                                        </span>
                                      </PlugTooltip>
                                    }
                                  </TableCell>

                                  <TableCell align='center' colSpan={4}>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', }}>
                                      <PlugTooltip
                                        title={t('tablemulti.move-column')}
                                        aria-label="add"
                                        placement="top-start">
                                        <div
                                          {...draggableProvided.dragHandleProps}
                                        >
                                          <span
                                            style={{ cursor: isDraggable ? 'grab' : 'no-drop' }}
                                            className={`material-icons ${!isDraggable ? classes.disabledIcon : undefined}`}
                                          >drag_handle</span>
                                        </div>
                                      </PlugTooltip>
                                      <PlugTooltip
                                        title={row.mandatory ? t('helper.mandatory-column') : t('helper.not-mandatory')}
                                        aria-label="add"
                                        placement="top-start">
                                        <span onClick={() => handleToggleMandatory(row, index)} >
                                          {
                                            row.mandatory
                                              ? <Asterisk color="black" size={18} />
                                              : <AsteriskOutline color="black" size={18} />
                                          }
                                        </span>
                                      </PlugTooltip>
                                      <PlugTooltip
                                        title={t('tablemulti.delete-column')}
                                        aria-label="add"
                                        placement="top-start">
                                        <span
                                          id="icon-add-playlist" className={`material-icons md-small ${isMandatory ? classes.disabledIcon : undefined}`}
                                          onClick={() => { if (!isMandatory) props.handleRemoveSpecificRow(index) }}
                                        >
                                          delete
                                        </span>
                                      </PlugTooltip>
                                    </div>
                                    <div className={classes.visibilityCtn}  >
                                      <span className={`material-icons`} style={{ fontSize: 20 }}>
                                        visibility
                                      </span>
                                      <PlugTooltip title={t('tablemulti.column-' + (row.hidden ? 'hidden' : 'visible') )} placement="bottom">
                                        <span onClick={() => handleToggleHidden(row, index)}>
                                          <VisibilityIndicator text="Plugnote" visible={isDefined(row.hidden) ? !row.hidden : true} />
                                        </span>
                                      </PlugTooltip>
                                      <PlugTooltip title={t('helper.toggle-visibility-col')} placement="bottom">
                                        <div style={{ display: 'inline-flex', gap: 5 }}>
                                          <span onClick={() => handleTogglePDFVisibility(row, index)} >
                                            <VisibilityIndicator text="PDF" visible={!isDefined(row.showOnPdf) || row.showOnPdf} />
                                          </span>
                                          <span onClick={() => handleTogglePNVisibility(row, index)} >
                                            <VisibilityIndicator text="PN-" visible={!isDefined(row.visible) || row.visible} />
                                          </span>
                                          <span onClick={() => handleTogglePFVisibility(row, index)} >
                                            <VisibilityIndicator text="PF-" visible={!isDefined(row.encodable) || row.encodable} />
                                          </span>
                                        </div>
                                      </PlugTooltip>
                                    </div>
                                  </TableCell>
                                </TableRow>
                                {
                                  hasConfigurator.includes(row.format) &&
                                  <TableRow key={"row-" + index}>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }}
                                      colSpan={10}>
                                      <Collapse
                                        in={openEdit && openRow === index}
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <Box margin={0}>
                                          <Typography variant="h6" gutterBottom
                                            component="div">
                                            Configuration
                                          </Typography>
                                          <section>
                                            {renderFieldConfigurator(row, index)}
                                          </section>
                                        </Box>
                                      </Collapse>
                                    </TableCell>
                                  </TableRow>
                                }
                              </React.Fragment>
                            )
                          }}
                        </Draggable>
                      )
                    })
                  }
                  {droppableProvided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
    </div>
  )
}

export default RenderEditTableComponent
