import React, {useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import InputMask from "react-input-mask";
import {devLog, isDefined} from "../../utils/utils";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: 'white',
        borderBottom: '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color: 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo: {
        width: '10%',
    },
    margin_5: {
        margin: '5px',
        width: '100%'
    },
    input_add_item: {
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    fieldRight: {
        flexGrow: 1,
    },
    mailContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    mailButton:{
        /* border: '1px red solid', */
        display: 'inline-block',
        color: "white",
        backgroundColor: "#58c3c1",
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
    }
}));

const FormatTextComponent = (props) => {
    //devLog("FormatText props : ", props)
    const countryCodes = {
        AD: 24, AE: 23, AL: 28, AO: 25, AT: 20, AX: 18, AZ: 28, BA: 20, BE: 16, BF: 28, BG: 22, BH: 22, BI: 27, BJ: 28, BL: 27, BR: 29, BY: 28, CF: 27, CG: 27, CH: 21, CI: 28, CM: 27, CR: 21, CV: 25, CY: 28, CZ: 24, DE: 22, DJ: 27, DK: 18, DO: 28, DZ: 26, EA: 24, EE: 20, EG: 29, ES: 24, FI: 18, FO: 18, FR: 27, GA: 27, GB: 22, GE: 22, GF: 27, GG: 22, GI: 23, GL: 18, GP: 27, GQ: 27, GR: 27, GT: 28, GW: 25, HN: 28, HR: 21, HU: 28, IC: 24, IE: 22, IL: 23, IM: 22, IQ: 23, IR: 26, IS: 26, IT: 27, JE: 22, JO: 30, KW: 30, KZ: 20, LB: 28, LC: 32, LI: 21, LT: 20, LU: 20, LV: 21, LY: 25, MA: 28, MC: 27, MD: 24, ME: 22, MF: 27, MG: 27, MK: 19, ML: 28, MN: 20, MQ: 27, MR: 27, MT: 31, MU: 30, MZ: 25, NC : 27, NE: 28, NI: 32, NL: 18, NO: 15, PF: 27, PK: 24, PL: 28, PM: 27, PS: 29, PT: 25, QA: 29, RE: 27, RO: 24, RS: 22, RU: 33, SA: 24, SC: 31, SD: 18, SE: 24, SI: 19, SK: 24, SM: 27, SN: 28, SO: 23, ST: 25, SV: 28, TD: 27, TF: 27, TG: 28, TL: 23, TN: 24, TR: 26, UA: 29, VA: 22, VG: 24, WF: 27, XK: 20, YT: 27
    };

    const classes= useStyles();
    const { t } = useTranslation('common')
    const field = props.field
    const [value, setValue] = useState(field.value || "")

    let maskProp = field.options?.setup?.mask || ""
    let inputMaskToApply
    if(maskProp === "IBAN" && field?.value?.length > 0) {
        let twoFirstLetters = value?.substring(0,2)
        inputMaskToApply = generateInputMask(twoFirstLetters)
    }
    const [inputMask, setInputMask] = useState(inputMaskToApply || 'aa') //'aa99 9999 9999 9999 9999 9999 9999 9999 9999'
    const [isInvalid, setIsInvalid] = useState(false)

    let placeholder
    let maskChar
    getMaskDetails(maskProp)

    function getMaskDetails(maskProp) {
        switch (maskProp) {
            case 'BBAN':
                maskChar = '_'
                placeholder= 'xxx xxxxxxx xx' //inputMask = '999 9999999 99'
                break
            case 'IBAN':
                maskChar = '_'
                placeholder= 'BExx xxxx xxxx xxxx' //inputMask = 'aa99 9999 9999 9999'
                break
            case 'TVA':
                maskChar = '_'
                placeholder= 'BE xxxx xxx xxx' //inputMask = 'aa 9999 999 999'
                break
            default:
                break
        }
    }

    function generateInputMask(countryCode) {
        const ibanLength = countryCodes[countryCode.toUpperCase()];
        if (!ibanLength) {
            return undefined //if not valid countryCode
        }
        let inputMask = '';
        for (let i = 0; i < ibanLength; i++) {
            if (i === 4 || i === 8 || i === 12 || i === 16 || i === 20 || i === 24 || i === 28) {
                inputMask += ' ';
            }
            inputMask += i < 2 ? 'a' : '*';
        } //console.log("inputMask : ", inputMask)
        return inputMask;
    }

    const handleInputMaskChange = (value) => {
        //console.log("VALUE", value)
        //console.log("value.length", value?.length)
        if(maskProp === "IBAN")
        {
            let tmpValue = value.replaceAll('_', '')
            tmpValue = tmpValue.replaceAll(' ', '')
            //console.log("tmpValue", tmpValue)
            let newMask
            if(tmpValue?.length === 2)
            {
                newMask = generateInputMask(tmpValue)
                if(isDefined(newMask)) setInputMask(newMask)
            }
            if(value !== 'BE' && value !== '' && !value?.includes('_')) {handleCheckIsValid(value)}
        }

        props.handleEditValue(value?.toUpperCase())
        setValue(value?.toUpperCase())
    }

    function handleCheckIsValid(value) {
        let isValidAccount
        if (maskProp === "BBAN") {
            if(value?.length === 14) {
                let tmpValue = value.replaceAll(" ", "")
                let tenFirstNum = tmpValue.substring(0,10);
                let twoLastNum = tmpValue.substring(10,13);
                if(tenFirstNum % 97 == twoLastNum) {isValidAccount = true} //console.log('Valid BBAN')
                else if (tenFirstNum % 97 == 0 && twoLastNum == 97) {isValidAccount = true} //console.log('Valid BBAN')
                else {isValidAccount = false} //console.log('Invalid BBAN')
            }
        } else if (maskProp === "IBAN") {isValidAccount = checkIBAN(value)} //console.log("isValidAccount ? : ", isValidAccount)
        else {} //console.log("maskProp undefined ? : ", maskProp )}
        setIsInvalid(!isValidAccount)
    }

    function modulo(aNumStr, aDiv) {
        let tmp = "";
        let  i, r;
        for ( i=0; i<aNumStr.length ; i++){
            tmp += aNumStr.charAt(i);
            r = tmp % aDiv;
            tmp = r.toString();
        }
        return tmp / 1;
    }

    function checkIBAN(iban) {
        //Move front 4 digits to the end
        let rearrange =
            iban.substring(4, iban?.length)
            + iban.substring(0, 4);
        let alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split('');
        let alphaMap = {};
        let number = [];
        alphabet.forEach(function(value, index) {
            alphaMap[value] = index + 10;
        });
        rearrange.split('').forEach(function(value, index) {
            number[index] = alphaMap[value] || value;
        });
        return modulo(number.join('').toString(), 97) === 1;
    }

    //To TEST function checkIBAN(iban):
    //let iban // http://randomiban.com/
    //iban = 'FR76 3000 6000 0112 3456 7890 123'; //INVALID IBAN
    // VALID IBAN:
    //iban = 'NL41RABO6319269278';
    //iban = 'FR5414508000408936357463D44'
    //iban = 'LT755344969669762660'
    //iban = 'BE91557954512776' // BBAN = 377019659311
    //iban = 'UA903052992990004149123456789'
    //iban = 'LC14BOSL123456789012345678901234'
    //iban = 'RE4220041010050500013M02606'
    //let isValidIBAN = checkIBAN(iban);
    //console.log("isValidIBAN : ", isValidIBAN);

    return (
        <div>
            {props.field.options?.setup?.mask?.length> 0 ?
                <InputMask
                    disabled={props.disabled || props.field.disabled || field?.options?.setup?.editable === false}
                    maskChar={maskChar}
                    //mask={maskProp === "BBAN" ? "999 9999999 99" : inputMask} //inputMask = 'aa 9999 999 999'
                    mask={maskProp === "IBAN" ? inputMask : maskProp === "BBAN" ? "999 9999999 99" : 'aa 9999 999 999'} //TVA inputMask = 'aa 9999 999 999'

                    value={value}
                    onChange={(event) => {handleInputMaskChange(event.target.value)}}
                    onBlur={(e) => e.target.placeholder = placeholder}
                    onFocus={(e) => {
                        if(value === "") {handleInputMaskChange('BE')}
                        e.target.placeholder = ''
                    }}
                >
                    {(inputProps) => (
                        <TextField
                            {...inputProps}
                            style={{ margin: 0 }}
                            placeholder={placeholder}
                            helperText={isInvalid ? <span style={{fontSize: 'small', color: "red"}}>Invalid</span> : ""}
                            //FormHelperTextProps={{className: classes.helperText}}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            inputProps={{ maxLength: 40 }}
                            InputProps={{ disableUnderline: true }}
                            //type={"text"}
                            disabled={props.disabled || field.disabled || field?.options?.setup?.editable === false}
                        />
                    )}
                </InputMask>
                :
                <TextField
                    onChange={(event) => handleInputMaskChange(event.target.value)}
                    style={{ margin: 0 }}
                    placeholder={t("fields.format-choice-placeholder-" + field.visuelFormat.toLowerCase())}
                    onBlur={(e) => e.target.placeholder = t("fields.format-choice-placeholder-" + field.visuelFormat.toLowerCase())}
                    onFocus={(e) => e.target.placeholder = ''}
                    helperText=""
                    fullWidth
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    inputProps={{ maxLength: 40 }}
                    value={value}
                    InputProps={{
                        disableUnderline: true,
                    }}
                    disabled={props.disabled || field.disabled || field?.options?.setup?.editable === false}
                />
            }
        </div>
    )
}

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {}
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FormatTextComponent);