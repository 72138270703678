import React, {useEffect, useState} from "react"
import { SetupSelects } from "./SetupSelects";
import { devLog } from "../../utils/utils";

export function RenderPreselList (props) {
    //devLog('Presel List got props', props)
    // const { t, i18n } = useTranslation(['common', 'models']);
    const [presel, setPresel] = useState('');
    const [dataSelect, setDataSelect] = useState(props.row.presel !== "" && props.row.presel !== "[]"  ? props.row.presel  : []);

    const handleAddPresel = (value, field, index) => {
        if (value !== "") {
            let find = props.row.presel.find((item) => item === value );
            if(!find){
                props.row.presel.push(value);
                setDataSelect(props.row.presel);
                setPresel('');
                props.handleUpdatePresel(props.index, props.row.presel);
            }
        }
    }

    const handleChangeSortOrder = order => {
        props.handleUpdatePreselOrder(order)
    }

    const handleDeletePresel = (value) => {
        props.row.presel = props.row.presel.filter(item => item !== value)
        setDataSelect(list => list.filter(item => item !== value));
        props.handleUpdatePresel(props.index, props.row.presel);
    }

    useEffect(() => {
        if(props.row.presel !== "" && props.row.presel !== "[]" && Array.isArray(props.row.presel)){
            setDataSelect(props.row.presel);
        }
        else {
            setDataSelect([]);
        }
    }, [props.row.presel]);

    // console.log('render presel props', props);

    return (
        <SetupSelects
            field={props.row}
            format={props.getTypeValue(props.row.mask)}
            handleAddPresel={handleAddPresel}
            handleChangeSortOrder={handleChangeSortOrder}
            handleChangeVisibility={null}
            handleClose={props.handleClose}
            handleDeletePresel={handleDeletePresel}
            index={props.index}
            isTablemulti
            newData={presel}
            newDataSelect={props.row.presel}
            onChangeHandlerDrop={props.onChangeHandlerDrop}
            setNewData={setPresel}
            visibleByDefault={null}
        />
    )
}
