import React, { useState } from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Input } from "@material-ui/core";
import { confirmAlert } from "react-confirm-alert";

function getModalStyle() {
    const top = 20;
    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        width : '60vw',
        backgroundColor: 'white',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
        '@media (max-width: 800px)': {
            width: '90vw'
        }
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    modalTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'grey'
    },
    marginLeft:{
        marginLeft : '10px'
    },
    bottomBtnCtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        margin: '5px 35px 20px',
    },
    textAreaField:{
        marginLeft: 'auto',
        marginRight: 'auto',
        fontWeight: '500',
        borderRadius: '5px',
        minHeight: 100,
        lineHeight: '28px',
        paddingLeft: '10px',
        backgroundColor: '#F1F1F1',
        fontSize: 18,
        border: 'none',
        width: '100%',
        MozBorderRadius : '5px',
        WebkitBorderRadius: '5px',
    },
}));

const ModalEditInfobulleComponent = (props) => {
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle);
    const [t, ] = useTranslation('common');
    const [tooltip, setTooltip] = useState(props.tooltip);

    const handleCloseModal = () => {
        props.close();
    };

    const handleSaveFieldTooltip = () => {
        props.handleSaveFieldTooltip(props.field.id, tooltip);
        props.close();
    };

    const handleDeleteTooltip = () => {
        confirmAlert({
            title: t('global.plugnotes'),
                message: t('helper.infobulles-delete'),
                buttons: [
                    {
                        label: t('global.ok'),
                        onClick: () => {
                            props.handleDeleteFieldTooltip(props.field.id)
                            props.close()
                        }
                    },
                    {
                        label: t('global.cancel'),
                    }
                ],
            })
    }
   
    return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div style={modalStyle} className={classes.popup}>
                <div className = "scrollbar-hidden popup_wrapper flexSpacebetween" >
                    <div className="popup_wrapper_content">
                        <div className="sous-header_modal_content">
                            <div>
                                <span className="titres">
                                    <span
                                        id="standard-full-width"
                                        className={classes.modalTitle}
                                    >
                                        {t('helper.infobulle')} - {t('helper.field')} N°{props.field.id}: {props.field.label}
                                    </span>
                                     <br/>
                                </span>
                            </div>
                            <div onClick={handleCloseModal}>
                                <span id="icon-close" className="material-icons md-50 lightgrey">
                                    close
                                </span>
                            </div>

                        </div>
                        <div className="sous-header_content lightgrey">
                            <div className="line flex_style">
                            </div>
                        </div>
                    </div>
                    <div className="popup_wrapper_content">
                        <Input
                            autoFocus
                            label="Label"
                            placeholder={t('helper.infobulles-placeholder')}
                            fullWidth={true}
                            // margin="none"
                            multiline={true}
                            disableUnderline={true}
                            inputlabelprops={{
                                shrink: true,
                            }}
                            inputProps={
                                {
                                    // className: classes.textAreaField,
                                }
                            }
                            disabled={false}
                            className={classes.textAreaField}
                            onChange={(e) => setTooltip(e.target.value)}
                            value={tooltip}
                        />
                    </div>
                    <div className={classes.bottomBtnCtn}>
                        {
                            tooltip.length > 0 ?
                            <button className="button-delete" onClick={handleDeleteTooltip} style={{fontWeight: "bold"}}>{t('global.delete')}</button>
                            :
                            <button className="button" onClick={props.close} style={{fontWeight: "bold"}}>{t('global.cancel')}</button>
                        }
                        <button className="button" onClick={handleSaveFieldTooltip} disabled={tooltip.length === 0} style={{fontWeight: "bold"}}>{t('multiple.save')}</button>
                    </div>
                    
                </div>
            </div>
        </Modal>
    )
};

export default ModalEditInfobulleComponent