import React, {useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import { IconButton, Tooltip} from "@material-ui/core";
import colors from "../../assets/themes/Colors";
import { SetupSelects } from "../custom/SetupSelects";
import { Settings } from "@material-ui/icons";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import {ReactComponent as MultiSelect} from '../../assets/images/format-icon/svg/multi-select.svg'
import {ReactComponent as MultiSelectAdd} from '../../assets/images/format-icon/svg/free-multi-select.svg'
import {ReactComponent as SingleSelect} from '../../assets/images/format-icon/svg/single-select.svg'
import {ReactComponent as SingleSelectAdd} from '../../assets/images/format-icon/svg/free-single-select.svg'
import { devLog, isDefined } from "../../utils/utils";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    field : {
        width : '100%',
        display: 'flex',
    },
    inputField:{
        padding : '0px 0px 7px',
    },
    mainField : {
        display: 'flex',
        flexDirection : 'column',
    },
    selectType:{
        //paddingRight: '24px',
        marginTop: '15px',
        marginLeft: '10px',
    },
    inputType:{
        marginTop: '15px',
    },
    inputListType:{
        marginTop: '15px',
        marginLeft: '10px',
    },
    visibleBox:{
        height:'40px',
        margin:'5px 0',
        justifyContent: 'left',
        textAlign: 'start',
        alignItems: 'center',
        display: 'flex',
    },
    visibleText:{
        verticalAlign: 'text-bottom',
        color: 'black',
        fontStyle: 'italic',
        fontSize: 'small',
    },
    selectedMask: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "6px",
        backgroundColor: colors.selectedAlpha,
        color: colors.white,
        padding: "3px 8px",
        minHeight: 24,
        gap: 5,
    },
    unselectedMask: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 24,
        borderRadius: "6px",
        backgroundColor: colors.unselectedAlpha,
        color: colors.white,
        padding: "3px 8px",
        gap: 5,
    },
    masksContainer: {
        width: 'inherit',
        minHeight: 24,
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        flexWrap: 'wrap',
        borderRadius: "6px",
        alignItems: 'flex-start',
        gap: 5,
    },
    subOpenClose: {
        display: 'flex',
        flexDirection: 'column',
        // '&:hover': {
        //     borderRadius: 50,
        //     background: colors.frost
        // }
    },
    fieldIcon: {
        // stroke: colors.white,
        fill: colors.white,
        color: colors.white,
        width: 22,
        height: 'auto',
        verticalAlign: 'sub',
        cursor: 'pointer'
    },
    preselConfig: {
        display: 'flex',
        flexDirection: 'row',
        padding: 5,
        paddingLeft: 40,
    },
}));

const getTypeValue = (value) => {
    let multiselect;
    let editable;
    if (value === null || value === undefined || value === '') {
        return 'F11';
    }
    else {
        multiselect = value.multiselect
        editable = value.editable
        if(multiselect === false && editable === false){
            return 'F11';
        }
        else if(multiselect === false && editable === true){
            return 'F12';
        }
        else if(multiselect === true && editable === false){
            return 'F13';
        }
        else if(multiselect === true && editable === true){
            return 'F14';
        }
    }
}

const RenderTypeList = (props) => {
    const { t, } = useTranslation(['common', 'models']);
    const selectTypes = ['F11', 'F12', 'F13', 'F14']
    const classes = useStyles()
    const [newData, setNewData] = useState(getTypeValue(props.formatoptions));

    useEffect(() => {
        setNewData(getTypeValue(props.formatoptions));
    }, [props.formatoptions]);

    const  handleTypeChange = (value, index) =>{
        setNewData(value.toString());
        props.handleUpdateType(value, index);
    }
    function rowRender (value)
        {
        let icon = ""
        let tooltip = ""
        let label = ""
        switch(value) {
            case 'F11':
                icon = <SingleSelect alt={t('fields.format-choice-'+ props.field.format.toLowerCase())} fill='white !important' className={classes.fieldIcon}/>
                tooltip = t("common:formats.preselection-type-unique-two")
                label = t("selects.single")
                break;
            case'F12':
                icon = <SingleSelectAdd alt={t('fields.format-choice-'+ props.field.format.toLowerCase())} fill='white !important' className={classes.fieldIcon}/>
                tooltip = t("common:formats.preselection-type-unique-and-free-two")
                label = t("selects.single-add")
                break;
            case'F13':
                icon = <MultiSelect alt={t('fields.format-choice-'+ props.field.format.toLowerCase())} fill='white !important' className={classes.fieldIcon}/>
                tooltip = t("common:formats.preselection-type-multiple-two")
                label = t("selects.multiple")
                break;
            case'F14':
                icon = <MultiSelectAdd alt={t('fields.format-choice-'+ props.field.format.toLowerCase())} fill='white !important' className={classes.fieldIcon}/>
                tooltip = t("common:formats.preselection-type-multiple-and-free-two")
                label = t("selects.multiple-add")
                break;
            default:
                break;
        }
        let result = {icon: icon, label: label, tooltip: tooltip}
        // console.log('result', result);
        return result
    }
    // console.log('presel type props', props);
// freeSingleSelectIconSvg
    return (
        <div className={classes.masksContainer} key="uniquevalue">
            {selectTypes.map((row, index) => {
            return(
            <Tooltip title={rowRender(row).tooltip} key={"presel-" + index}>
                <div className={row === newData? classes.selectedMask : classes.unselectedMask} key={index + "-select"} onClick={() => {console.log('changing...',row, props.index); handleTypeChange(row, props.index)}}>
                    <div className={classes.fieldIcon} style={{width : '20px', fill: 'white !important'}}>
                        {rowRender(row).icon}
                    </div>
                    {rowRender(row).label}
                </div>
            </Tooltip>
            )
            })}
        </div>
    );
}

const FormatSelectConstructorComponent = (props) => {
    const classes = useStyles();
    const { t } = useTranslation(['common', 'models']);
    const [newDataSelect, setNewDataSelect] = useState(props.field.options.presel !== "" && props.field.options.presel !== "[]"  ? props.field.options.presel  : []);
    const [newData, setNewData] = useState('');
    let dataType = 'F11';
    let visibleDefault = false;
    if (typeof props.field.options !== 'undefined') {
        let multiselect = props.field.options.multiselect;
        let editable = props.field.options.editable;
        visibleDefault = props.field.options?.visibledefault || false;
        if (!multiselect && !editable) {
            dataType = 'F11';
        } else if (!multiselect && editable) {
            dataType = 'F12';

        } else if (multiselect && !editable) {
            dataType = 'F13';

        } else if (multiselect && editable) {
            dataType = 'F14';
        }
    }
    const options = props.field.options
    const [newDataType, setNewDataType] = useState(dataType);
    const [open, setOpen] = useState(false)
    const [visibleByDefault, setVisibleByDefault] = useState(visibleDefault); // variable intermédiaire ; visibledefault reçoit la valeur attendue

    const handleChangeTypeSelect = (value, index) => {
        let field = { ...props.field }
        let multiselect = false
        let editable = false
        switch (value) {
            case 'F11':
                multiselect = false;
                editable = false;
                break;
            case 'F12':
                multiselect = false;
                editable = true;
                break;
            case 'F13':
                multiselect = true;
                editable = false;
                break;
            case 'F14':
                multiselect = true;
                editable = true;
                break;
            default:
                break;
        }
        setNewDataType(value);
        field.options.editable = editable;
        field.options.multiselect = multiselect;
        let model = [...props.model];
        model[index].options.editable = editable;
        model[index].options.multiselect = multiselect;
        props.setModel(model)
    }

    const handleChangeVisibility = (value, field, index) => {
        setVisibleByDefault(value);
        field.options.visibledefault = value;
        let model = [...props.model];
        model[index].options.visibledefault = value;
        props.setModel(model);
    }

    const handleAddPresel = (value, field, index) => {
        if(value.trim() !== ''){
            let find = field.options.presel.find((item) => item === value );
            if(!find){
                field.options.presel.push(value);
                setNewDataSelect(field.options.presel);
                setNewData('');
            }
        }
    }

    const handleDeletePresel = (value) => {
        let field = { ...props.field }
        const newPresel = field.options.presel.filter(item => item !== value)
        field.options.presel = newPresel
        let model = [...props.model]
        model[props.index] = field
        props.setModel(model)
        setNewDataSelect(newPresel);
    }

    const handleChangeSortOrder = (order) => {
        let field = { ...props.field }
        field.options.setup = {
            ...field.options?.setup,
            sortOrder: order,
        }
        let model = [...props.model];
        model[props.index].options.setup = {
            ...field.options?.setup,
            sortOrder: order,
        }
        props.setModel(model)
    }

    const handleChangeDefaultValue = (value) => {
        if (isDefined(value) && value.length > 0) {
            let field = { ...props.field }
            let model = [...props.model]
            if (options.multiselect) {
                let _default = field.options.setup?.defaultValue || []
                if (_default.includes(value)) {
                    field.options.setup = {
                        ...field.options?.setup,
                        defaultValue: _default.filter(entry => entry?.length > 0 && entry !== value),
                    }
                } else {
                    field.options.setup = {
                        ...field.options?.setup,
                        defaultValue: [..._default, value],
                    }
                }
            } else {
                if (options.setup?.defaultValue?.includes(value)) {
                    field.options.setup = {
                        ...field.options?.setup,
                        defaultValue: [],
                    }
                } else {
                    field.options.setup = {
                        ...field.options?.setup,
                        defaultValue: [value],
                    }
                }
            }
            model[props.index] = field
            props.setModel(model)
        }
    }

    const handleToggleEditable = () => {
        let field = { ...props.field }
        field.options.setup = {
            ...field.options?.setup,
            editable: !isDefined(options.setup?.editable) || options.setup?.editable !== false
                ? false
                : { editablePf: true, editablePw: true },
        }
        let model = [...props.model];
        model[props.index] = field
        props.setModel(model)
    }

    const handleToggleEditablePf = () => {
        let field = { ...props.field }
        field.options.setup = {
            ...field.options?.setup,
            editable: {
                ...field.options?.setup?.editable,
                editablePf: isDefined(field.options?.setup?.editable?.editablePf) ? !field.options?.setup?.editable?.editablePf : false,
            },
        }
        let model = [...props.model];
        model[props.index] = field
        props.setModel(model)
    }

    const handleToggleEditablePw = () => {
        let field = { ...props.field }
        field.options.setup = {
            ...field.options?.setup,
            editable: {
                ...field.options?.setup?.editable,
                editablePw: isDefined(field.options?.setup?.editable?.editablePw) ? !field.options?.setup?.editable?.editablePw : false,
            },
        }
        let model = [...props.model];
        model[props.index] = field
        props.setModel(model)
    }

    useEffect(() => {
        if (props.field.options.presel !== "" && props.field.options.presel !== "[]" && Array.isArray(props.field.options.presel)) {
            setNewDataSelect(props.field.options.presel);
        } else {
            setNewDataSelect([]);
        }
    }, [props.field.options.presel]);

    return (
        <div className={classes.mainField}>
            <div className={classes.preselConfig}>
                <RenderTypeList {...props} formatoptions={props.field.formatoptions} index={props.index}  handleUpdateType={(value, index) => handleChangeTypeSelect(value, index)}/>
                <IconButton aria-label="expand row" size="small" style={{alignItems: 'flex-start'}} onClick={() => {setOpen(!open)}}
                >
                    {open ? <div className={classes.subOpenClose}><Settings/><KeyboardArrowUpIcon/></div> : <div className={classes.subOpenClose}><Settings/><KeyboardArrowDownIcon/></div>}
                    ({props.field.options.presel.length})
                </IconButton>
            </div>
            {
                open &&
                <SetupSelects
                    field={props.field}
                    format={newDataType}
                    handleAddPresel={handleAddPresel}
                    handleChangeDefaultValue={handleChangeDefaultValue}
                    handleChangeSortOrder={handleChangeSortOrder}
                    handleChangeVisibility={handleChangeVisibility}
                    handleClose={() => setOpen(false)}
                    handleDeletePresel={handleDeletePresel}
                    handleToggleEditable={handleToggleEditable}
                    handleToggleEditablePf={handleToggleEditablePf}
                    handleToggleEditablePw={handleToggleEditablePw}
                    index={props.index}
                    newData={newData}
                    newDataSelect={newDataSelect}
                    onChangeHandlerDrop={props.onChangeHandlerDrop}
                    presels={props.field.presel}
                    setNewData={setNewData}
                    visibleByDefault={visibleByDefault}
                />
            }
        </div>
    );
};

// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatSelectConstructorComponent);





