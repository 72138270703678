// actions types is object with the
// key: GET_DATA_REQUEST -> value: describes the action
import {toast} from "react-toastify";

export const Types = {

    POST_EXPORT_DATA_FROM_MODEL_CSV_REQUEST: 'post_export_data_from_model_csv_request',
    POST_EXPORT_DATA_FROM_MODEL_CSV_SUCCESS: 'post_export_data_from_model_csv_success',
    POST_EXPORT_DATA_FROM_MODEL_CSV_FAILURE: 'post_export_data_from_model_csv_failure',
    POST_EXPORT_DATA_FROM_MODEL_CSV_ERROR: 'post_export_data_from_model_csv_error',

    POST_EXPORT_DATA_TABLE_MULTI_FROM_MODEL_CSV_REQUEST: 'post_export_data_table_multi_from_model_csv_request',
    POST_EXPORT_DATA_TABLE_MULTI_FROM_MODEL_CSV_SUCCESS: 'post_export_data_table_multi_from_model_csv_success',
    POST_EXPORT_DATA_TABLE_MULTI_FROM_MODEL_CSV_FAILURE: 'post_export_data_table_multi_from_model_csv_failure',
    POST_EXPORT_DATA_TABLE_MULTI_FROM_MODEL_CSV_ERROR: 'post_export_data_table_multi_from_model_csv_error',

    POST_EXPORT_TEMPLATE_CSV_REQUEST: 'post_export_template_csv_request',
    POST_EXPORT_TEMPLATE_CSV_SUCCESS: 'post_export_template_csv_success',
    POST_EXPORT_TEMPLATE_CSV_FAILURE: 'post_export_template_csv_failure',
    POST_EXPORT_TEMPLATE_CSV_ERROR: 'post_export_template_csv_error',

    POST_EXPORT_DATA_TEMPLATE_CSV_REQUEST: 'post_export_data_template_csv_request',
    POST_EXPORT_DATA_TEMPLATE_CSV_SUCCESS: 'post_export_data_template_csv_success',
    POST_EXPORT_DATA_TEMPLATE_CSV_FAILURE: 'post_export_data_template_csv_failure',
    POST_EXPORT_DATA_TEMPLATE_CSV_ERROR: 'post_export_data_template_csv_error',


    POST_IMPORT_TEMPLATE_CSV_REQUEST: 'post_import_template_csv_request',
    POST_IMPORT_TEMPLATE_CSV_SUCCESS: 'post_import_template_csv_success',
    POST_IMPORT_TEMPLATE_CSV_FAILURE: 'post_import_template_csv_failure',
    POST_IMPORT_TEMPLATE_CSV_ERROR: 'post_import_template_csv_error',
    POST_IMPORT_TEMPLATE_CSV_PROGRESS: 'post_import_template_csv_progress',

    POST_IMPORT_TEMPLATE_DATA_CSV_REQUEST: 'post_import_template_data_csv_request',
    POST_IMPORT_TEMPLATE_DATA_CSV_SUCCESS: 'post_import_template_data_csv_success',
    POST_IMPORT_TEMPLATE_DATA_CSV_FAILURE: 'post_import_template_data_csv_failure',
    POST_IMPORT_TEMPLATE_DATA_CSV_ERROR: 'post_import_template_data_csv_error',
    POST_IMPORT_TEMPLATE_DATA_CSV_PROGRESS: 'post_import_template_data_csv_progress',


    POST_IMPORT_TEMPLATE_DATA_TABLE_CSV_REQUEST: 'post_import_template_data_table_csv_request',
    POST_IMPORT_TEMPLATE_DATA_TABLE_CSV_SUCCESS: 'post_import_template_data_table_csv_success',
    POST_IMPORT_TEMPLATE_DATA_TABLE_CSV_FAILURE: 'post_import_template_data_table_csv_failure',
    POST_IMPORT_TEMPLATE_DATA_TABLE_CSV_ERROR: 'post_import_template_data_table_csv_error',
    POST_IMPORT_TEMPLATE_DATA_TABLE_CSV_PROGRESS: 'post_import_template_data_table_csv_progress',

    POST_IMPORT_TEMPLATE_PLUGLINK_CSV_REQUEST: 'post_import_template_pluglink_csv_request',
    POST_IMPORT_TEMPLATE_PLUGLINK_CSV_SUCCESS: 'post_import_template_pluglink_csv_success',
    POST_IMPORT_TEMPLATE_PLUGLINK_CSV_FAILURE: 'post_import_template_pluglink_csv_failure',
    POST_IMPORT_TEMPLATE_PLUGLINK_CSV_ERROR: 'post_import_template_pluglink_csv_error',
    POST_IMPORT_TEMPLATE_PLUGLINK_CSV_PROGRESS: 'post_import_template_pluglink_csv_progress',


    POST_EXPORT_TEMPLATE_PLUGLINK_CSV_REQUEST: 'post_export_template_pluglink_csv_request',
    POST_EXPORT_TEMPLATE_PLUGLINK_CSV_SUCCESS: 'post_export_template_pluglink_csv_success',
    POST_EXPORT_TEMPLATE_PLUGLINK_CSV_FAILURE: 'post_export_template_pluglink_csv_failure',
    POST_EXPORT_TEMPLATE_PLUGLINK_CSV_ERROR: 'post_export_template_pluglink_csv_error',
    POST_EXPORT_TEMPLATE_PLUGLINK_CSV_PROGRESS: 'post_export_template_pluglink_csv_progress',

    RESET_ALL_DATA: 'reset_all_data',
};

/*
Token
 */
// function that returns an object literal
export const resetAllData = () => ({
    type: Types.RESET_ALL_DATA,
});


// function that returns an object literal
export const postExportDataFromModelCsvRequest = (payload, shared, userhash) => ({
    type: Types.POST_EXPORT_DATA_FROM_MODEL_CSV_REQUEST,
    payload : payload,
    shared : shared,
    userhash : userhash
});

// function that returns an object literal
export const postExportDataFromModelCsvSuccess = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_EXPORT_DATA_FROM_MODEL_CSV_SUCCESS,
        });
        toast.success("");
    };
};

// function that returns an object literal
export const postExportDataFromModelCsvFailure = () => ({
    type: Types.POST_EXPORT_DATA_FROM_MODEL_CSV_FAILURE,
});

// function that returns an object literal
export const postExportDataFromModelCsvError = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_EXPORT_DATA_FROM_MODEL_CSV_ERROR,
        });
        toast.error("ERROR");
    };
};

// function that returns an object literal
export const postExportDataTableMultiFromModelCsvRequest = (payload, shared, userhash) => ({
    type: Types.POST_EXPORT_DATA_TABLE_MULTI_FROM_MODEL_CSV_REQUEST,
    payload : payload,
    shared : shared,
    userhash : userhash
});

// function that returns an object literal
export const postExportDataTableMultiFromModelCsvSuccess = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_EXPORT_DATA_TABLE_MULTI_FROM_MODEL_CSV_SUCCESS,
        });
        toast.success("");
    };
};

// function that returns an object literal
export const postExportDataTableMultiFromModelCsvFailure = () => ({
    type: Types.POST_EXPORT_DATA_TABLE_MULTI_FROM_MODEL_CSV_FAILURE,
});

// function that returns an object literal
export const postExportDataTableMultiFromModelCsvError = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_EXPORT_DATA_TABLE_MULTI_FROM_MODEL_CSV_ERROR,
        });
        toast.error("ERROR");
    };
};

// function that returns an object literal
export const postExportTemplateCsvRequest = (payload, shared, userhash) => ({
    type: Types.POST_EXPORT_TEMPLATE_CSV_REQUEST,
    payload : payload,
    shared : shared,
    userhash : userhash
});

// function that returns an object literal
export const postExportTemplateCsvSuccess = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_EXPORT_TEMPLATE_CSV_SUCCESS,
        });
        toast.success("");
    };
};

// function that returns an object literal
export const postExportTemplateCsvFailure = () => ({
    type: Types.POST_EXPORT_TEMPLATE_CSV_FAILURE,
});

// function that returns an object literal
export const postExportTemplateCsvError = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_EXPORT_TEMPLATE_CSV_ERROR,
        });
        toast.error("ERROR");
    };
};

// function that returns an object literal
export const postExportDataTemplateCsvRequest = (payload, shared, userhash) => ({
    type: Types.POST_EXPORT_DATA_TEMPLATE_CSV_REQUEST,
    payload : payload,
    shared : shared,
    userhash : userhash
});

// function that returns an object literal
export const postExportDataTemplateCsvSuccess = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_EXPORT_DATA_TEMPLATE_CSV_SUCCESS,
        });
        toast.success("");
    };
};

// function that returns an object literal
export const postExportDataTemplateCsvFailure = () => ({
    type: Types.POST_EXPORT_DATA_TEMPLATE_CSV_FAILURE,
});

// function that returns an object literal
export const postExportDataTemplateCsvError = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_EXPORT_DATA_TEMPLATE_CSV_ERROR,
        });
        toast.error("ERROR");
    };
};

// function that returns an object literal
export const postImportTemplateCsvRequest = (annexe, modelhash, modelclef, modellabel,shared, userhash) => ({
    type: Types.POST_IMPORT_TEMPLATE_CSV_REQUEST,
    annexe: annexe,
    modelhash: modelhash ,
    modelclef: modelclef,
    modellabel: modellabel,
    shared: shared,
    userhash: userhash
});

// function that returns an object literal
export const postImportTemplateCsvSuccess = (data) => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_IMPORT_TEMPLATE_CSV_SUCCESS,
            payload: { data }
        });
        toast.success("");
    };
};

export const postImportTemplateCsvProgress = (file: File, progress: number) => ({
    type: Types.POST_IMPORT_TEMPLATE_CSV_PROGRESS,
    progress: progress,
    meta: { file },
});


// function that returns an object literal
export const postImportTemplateCsvFailure = () => ({
    type: Types.POST_IMPORT_TEMPLATE_CSV_FAILURE,
});

// function that returns an object literal
export const postImportTemplateCsvError = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_IMPORT_TEMPLATE_CSV_ERROR,
        });
        toast.error("ERROR");
    };
};

// function that returns an object literal
export const postImportTemplateDataCsvRequest = (annexe, modelhash, modelclef, modellabel,shared, userhash) => ({
    type: Types.POST_IMPORT_TEMPLATE_DATA_CSV_REQUEST,
    annexe: annexe,
    modelhash: modelhash ,
    modelclef: modelclef,
    modellabel: modellabel,
    shared: shared,
    userhash: userhash
});

// function that returns an object literal
export const postImportTemplateDataCsvSuccess = (data) => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_IMPORT_TEMPLATE_DATA_CSV_SUCCESS,
            payload: { data }
        });
        toast.success("");
    };
};

// function that returns an object literal
export const postImportTemplateDataCsvFailure = () => ({
    type: Types.POST_IMPORT_TEMPLATE_DATA_CSV_FAILURE,
});

export const postImportTemplateDataCsvProgress = (file: File, progress: number) => ({
    type: Types.POST_IMPORT_TEMPLATE_DATA_CSV_PROGRESS,
    progress: progress,
    meta: { file },
});

// function that returns an object literal
export const postImportTemplateDataCsvError = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_IMPORT_TEMPLATE_DATA_CSV_ERROR,
        });
        toast.error("ERROR");
    };
};


// function that returns an object literal
export const postImportTemplateDataTableCsvRequest = (annexe, modelhash, modelclef, modellabel,shared, userhash) => ({
    type: Types.POST_IMPORT_TEMPLATE_DATA_TABLE_CSV_REQUEST,
    annexe: annexe,
    modelhash: modelhash ,
    modelclef: modelclef,
    modellabel: modellabel,
    shared: shared,
    userhash: userhash
});

// function that returns an object literal
export const postImportTemplateDataTableCsvSuccess = (data) => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_IMPORT_TEMPLATE_DATA_TABLE_CSV_SUCCESS,
            payload: { data }
        });
        toast.success("");
    };
};

// function that returns an object literal
export const postImportTemplateDataTableCsvFailure = () => ({
    type: Types.POST_IMPORT_TEMPLATE_DATA_TABLE_CSV_FAILURE,
});

export const postImportTemplateDataTableCsvProgress = (file: File, progress: number) => ({
    type: Types.POST_IMPORT_TEMPLATE_DATA_TABLE_CSV_PROGRESS,
    progress: progress,
    meta: { file },
});

// function that returns an object literal
/*export const postImportTemplateDataTableCsvError = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_IMPORT_TEMPLATE_DATA_TABLE_CSV_ERROR,
        });
        toast.error("ERROR");
    };
};*/

export const postImportTemplateDataTableCsvError = () => ({
    type: Types.POST_IMPORT_TEMPLATE_DATA_TABLE_CSV_ERROR
});

// function that returns an object literal
export const postImportTemplatePluglinkCsvRequest = (annexe, modelhash, modelclef, modellabel,shared, userhash) => ({
    type: Types.POST_IMPORT_TEMPLATE_PLUGLINK_CSV_REQUEST,
    annexe: annexe,
    modelhash: modelhash ,
    modelclef: modelclef,
    modellabel: modellabel,
    shared: shared,
    userhash: userhash
});

// function that returns an object literal
export const postImportTemplatePluglinkCsvSuccess = (data) => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_IMPORT_TEMPLATE_PLUGLINK_CSV_SUCCESS,
            payload: { data }
        });
        toast.success("");
    };
};

// function that returns an object literal
export const postImportTemplatePluglinkCsvFailure = () => ({
    type: Types.POST_IMPORT_TEMPLATE_DATA_CSV_FAILURE,
});

export const postImportTemplatePluglinkCsvProgress = (file: File, progress: number) => ({
    type: Types.POST_IMPORT_TEMPLATE_DATA_CSV_PROGRESS,
    progress: progress,
    meta: { file },
});

// function that returns an object literal
/*export const postImportTemplateDataTableCsvError = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_IMPORT_TEMPLATE_DATA_TABLE_CSV_ERROR,
        });
        toast.error("ERROR");
    };
};*/

export const postImportTemplatePluglinkCsvError = () => ({
    type: Types.POST_IMPORT_TEMPLATE_PLUGLINK_CSV_ERROR
});

export const postExportTemplatePluglinkCsvRequest = (payload, shared, userhash) => ({
    type: Types.POST_EXPORT_TEMPLATE_PLUGLINK_CSV_REQUEST,
    payload : payload,
    shared : shared,
    userhash : userhash
});

// function that returns an object literal
export const postExportTemplatePluglinkCsvSuccess = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_EXPORT_TEMPLATE_PLUGLINK_CSV_SUCCESS,
        });
        toast.success("");
    };
};

// function that returns an object literal
export const postExporTemplatePluglinkCsvFailure = () => ({
    type: Types.POST_EXPORT_TEMPLATE_PLUGLINK_CSV_FAILURE,
});

// function that returns an object literal
export const postExportTemplatePluglinkCsvError = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_EXPORT_TEMPLATE_PLUGLINK_CSV_ERROR,
        });
        toast.error("ERROR");
    };
};
