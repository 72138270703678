import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import colors from "../../assets/themes/Colors";

import {
  FormControl,
  Switch
} from "@material-ui/core";

import { devLog } from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
  mainField: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: 40, 
  },
  optionCtn: {
    margin: 5,
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    border: '1px solid ' + colors.main,
    borderRadius: 4,
    position: 'relative',
    height: 50,
  },
  optionLabel: {
    position: 'absolute',
    top: -24,
    left: -4,
    fontSize: 12,
    whiteSpace: 'nowrap',
    color: colors.darkSilver,
    fontWeight: 'bold',
  },
  selected: {
    fontSize: 'xx-large',
    textAlign: 'center',
    verticalAlign: 'middle',
    marginTop: '0.6rem',
    color: colors.mainButton,
    cursor: 'pointer',
  },
  unselected: {
    fontSize: 'xx-large',
    textAlign: 'center',
    verticalAlign: 'middle',
    marginTop: '0.6rem',
    color: colors.darkSilver,
    cursor: 'pointer',
  },
  selectedMask: {
    cursor: 'pointer',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: "6px",
    backgroundColor: colors.selectedAlpha,
    color: colors.white,
    padding: "2px 6px",
    minHeight: 18,
    gap: 5,
    fontSize: 12,
  },
  unselectedMask: {
    cursor: 'pointer',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 18,
    borderRadius: "6px",
    backgroundColor: colors.unselectedAlpha,
    color: colors.white,
    padding: "2px 6px",
    gap: 5,
    fontSize: 12,
  },
  masksContainer: {
    width: 'inherit',
    minHeight: 24,
    cursor: 'pointer',
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: "6px",
    alignItems: 'flex-start',
    gap: 5,
  },
}));

const SwitchDateSeparator = ({ dateSeparator, handleChangeSwitchDateSeparator }) => {
  const classes = useStyles()

  return (
    <FormControl style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}
    >
      <div style={{ lineHeight: '35px' }}>
        <span className={dateSeparator === "-" ? classes.selected : classes.unselected} onClick={() => handleChangeSwitchDateSeparator({ target: { checked: false } })}>
          -
        </span>
      </div>
      <Switch
        color="default"
        checked={dateSeparator === "/"}
        onChange={handleChangeSwitchDateSeparator}
      />
      <div style={{ lineHeight: '35px' }} onClick={() => handleChangeSwitchDateSeparator({ target: { checked: true } })}>
        <span className={dateSeparator === "/" ? classes.selected : classes.unselected}>
          /
        </span>
      </div>
    </FormControl>
  )
}

const FormatDateConstructorComponentV2 = (props) => {
  // devLog('FormatDateConstructor got props ', props)
  const classes = useStyles()
  const { t } = useTranslation('common')
  const [selectedDateFormatMask, setSelectedDateFormatMask] = useState(props.field.options?.setup?.dateFormatOption?.toUpperCase()?.replaceAll("/", "-") || 'YYYY-MM-DD');
  const [dateSeparator, setDateSeparator] = useState(props.field.options?.setup?.dateFormatOption?.includes('-') ? "-" : "/");

  let optionsDateFormat = [
    { labelHyphen: 'dd-MM-yyyy', labelSlash: 'dd/MM/yyyy', value: 'DD-MM-YYYY' },
    { labelHyphen: 'dd-MM-yy', labelSlash: 'dd/MM/yy', value: 'DD-MM-YY' },
    { labelHyphen: 'dd-MM', labelSlash: 'dd/MM', value: 'DD-MM' },
    { labelHyphen: 'MM-yy', labelSlash: 'MM/yy', value: 'MM-YY' },
    { labelHyphen: 'MM-yyyy', labelSlash: 'MM/yyyy', value: 'MM-YYYY' },
    { labelHyphen: 'yyyy-MM-dd', labelSlash: 'yyyy/MM/dd', value: 'YYYY-MM-DD' },
  ]

  const handleChangeSwitchDateSeparator = (event) => {
    const newDateSeparator = event.target.checked ? "/" : "-"
    setDateSeparator(newDateSeparator);
    const newSelectedDateFormatMask = optionsDateFormat.find((entry) => {
      return entry.value === selectedDateFormatMask;
    });

    let dateFormatOptionValue;

    if (newDateSeparator === '-') {
      dateFormatOptionValue = newSelectedDateFormatMask.labelHyphen;
    }
    else {
      dateFormatOptionValue = newSelectedDateFormatMask.labelSlash;
    }
    let model = [...props.model];
    model[props.index].options.setup.dateFormatOption = dateFormatOptionValue;
    props.setModel(model)
  }

  const handleChangeSelectedDateFormatMask = (value) => {
    setSelectedDateFormatMask(value)
    const newSelectedDateFormatMaskValue = optionsDateFormat.find(entry => entry.value === value)
    let model = [...props.model];
    model[props.index].options.setup.dateFormatOption = dateSeparator === '-' ? newSelectedDateFormatMaskValue.labelHyphen : newSelectedDateFormatMaskValue.labelSlash;
    props.setModel(model)
    setSelectedDateFormatMask(value);
  }

  return (
    <div className={classes.mainField}>
        <div className={classes.optionCtn}>
          <span className={classes.optionLabel} >{t('setup.date-separator')}</span>
          <SwitchDateSeparator
            dateSeparator={dateSeparator}
            handleChangeSwitchDateSeparator={handleChangeSwitchDateSeparator}
          />
        </div>

        <div className={classes.optionCtn}>
          <span className={classes.optionLabel} >{t('setup.mask')}</span>
          {
            optionsDateFormat
              .map(option => (
                  <span
                    className={(selectedDateFormatMask === option.value) ? classes.selectedMask : classes.unselectedMask}
                    key={option.value}
                    onClick={() => handleChangeSelectedDateFormatMask(option.value)}
                  >
                    {(dateSeparator === "-" ? option.labelHyphen : option.labelSlash).toUpperCase()}
                  </span>
                ))
          }
        </div>
    </div>
  );
}
export default FormatDateConstructorComponentV2