import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import colors from '../../../assets/themes/Colors'
import { Input, makeStyles, Table, TableCell, TableHead, TableRow } from "@material-ui/core"
import { PlugTooltip } from '../../custom/CustomComponents'
import { ToolsIcon, VisibilityIndicator } from '../../custom/icons/CustomIcons'
import { devLog, isDefined } from '../../../utils/utils'
import EditAlignementComponent from '../../custom/buttons/EditAlignementComponent'
import { RenderInputLabelFontsizeEdit, RenderInputRowFontsizeEdit } from './RenderEditLayoutComponent'
import UpAndDownButton from '../../custom/buttons/UpAndDownButton'
import { DrawerContext } from '../../../App'

const useStyles = makeStyles(() => ({
  scrollBar: {
    overflowX: 'auto',
    "&::-webkit-scrollbar": {
      display: "block",
      overflowX: 'auto',
      background: colors.greyLight,
      width: 5,
    },
    "&::-webkit-scrollbar-thumb": {
      background: colors.grey,
    },
  },
  modelPreviewCtn: {
    display: 'flex',
    flexDirection: 'column',
    width: '-webkit-fill-available',
    overflowY: 'auto',
    marginBottom: 12,
    borderRadius: 4,
    '& .MuiTableCell-root': {
      borderRight: `1px solid ${colors.unselected}`,
      padding: '3px !important'
    },
    '& .MuiTable-root': {
      width: '99%',
    },
  },
  openDrawerCtn: {
    maxWidth: 'calc(90vw - 320px)',
    '@media (max-width: 1000px)': {
      maxWidth: 'calc(90vw - 200px)',
    },
  },
  closedDrawerCtn: {
    maxWidth: 'calc(90vw - 60px)',
  },
  noBorder: {
    '&.MuiTableCell-root': {
      borderRight: 'none',
    }
  },
  stickyColumn: {
    position: 'sticky',
    left: 0,
    zIndex: 5,
  },
  tableHeader: {
    '& .MuiTableCell': {
      backgroundColor: colors.palePlugGreen
    },
    borderRadius: 5,
  },
  tableHeaderEdit: {
    borderRadius: 5,
    backgroundColor: colors.mainPale,
  },
  optionTitleCtn: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: 8,
    fontSize: 18,
    color: colors.labelGrey,
    paddingTop: 2,
  },
  columnHeader: {
    fontSize: '14px',
    // maxWidth: 36,
  },
  rowNumber: {
    fontSize: 10,
    border: '1px solid ' + colors.unselected,
    borderRadius: '50%',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    height: 20,
    width: 20,
    '&:hover': {
      boxShadow: '0px 0px 10px 1px',
      fontWeight: 'bold',
      backgroundColor: colors.mainButton,
      borderColor: colors.unselected,
      color: 'white',
    },
  },
  fontSizeSettingCtn: {
    // position: 'absolute', 
    height: '100%',
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    fontSize: 10
  },
  fontSizeSetting: {
    fontSize: 16,
  },
  headerInputCtn: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  labelEdit: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'center',
  },
  inputCenter: { textAlign: 'center' },
}))

const RenderEditPerColumnSettingsComponent = (props) => {
  const classes = useStyles()
  const { t } = useTranslation(['common'])
  const { openDrawer } = useContext(DrawerContext)
  
  return (
    <div
      className={`${classes.modelPreviewCtn} ${classes.scrollBar} disable-text-selection ${openDrawer ? classes.openDrawerCtn : classes.closedDrawerCtn}`}
      key={'table-' + props.field.label}
    >
      <Table style={{ maxWidth: '99%', position: 'relative' }} >
        <TableHead>
          <TableRow
            style={{ position: 'relative', height: 40, marginLeft: -20, }}
          >
            <TableCell
              className={`${classes.stickyColumn} ${classes.noBorder}`}
              colSpan={3}
            >
              <div className={classes.optionTitleCtn}
                style={{ marginLeft: -5 }}
              >
                <ToolsIcon color={colors.labelGrey} size={24} />
                <span>{t('tablemulti.edit-per-column')} — {t('tablemulti.labels-row')}</span>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableHead className={classes.tableHeader} sx={{ display: "table-header-group" }} aria-label="simple table">
          <TableRow className={classes.tableHeaderEdit} >
            <TableCell align="center"
              className={`${classes.stickyColumn}`}
              style={{ width: 150, backgroundColor: colors.mainLight, zIndex: 5, borderRight: `1px solid ${colors.unselected}` }}>
              <span>{t('tablemulti.labels-row')}</span>
              <div className={classes.labelEdit} >
                <PlugTooltip title={t('tablemulti.default-header')}>
                  <div className={classes.headerInputCtn}>
                    <span className="material-icons" style={{ fontSize: 20 }} >
                      format_size
                    </span>
                    <Input
                      autoComplete="off"
                      classes={{ input: classes.inputCenter }}
                      id="standard-full-width"
                      inputMode="numeric"
                      police="police"
                      inputlabelprops={{ shrink: true }}
                      onChange={props.handleChangeHeadersFontSize}
                      style={{ width: 60 }}
                      type="number"
                      value={props.defaultLabelSize}
                    />
                  </div>
                </PlugTooltip>
                <UpAndDownButton
                  handleClickDown={props.handleDecreaseHeadersFontSize}
                  handleClickUp={props.handleIncreaseHeadersFontSize}
                  tooltipDown={t('tablemulti.fontsize-decrease-default')}
                  tooltipUp={t('tablemulti.fontsize-increase-default')}
                />
              </div>
              <div>{t('tablemulti.alignment')}</div>
              {/* <br /> */}
              <div style={{ paddingTop: 8 }}>{t('helper.visibility')} Col.</div>
            </TableCell>
            {
              props.colonnes.map((entry, id) => {
                return (
                  <TableCell
                    id={'table-header-' + props.field.label + '-' + id}
                    key={'table-header-' + props.field.label + '-' + id}
                    style={{ minWidth: 160 }}
                    align="center"
                  >
                    <span>{entry.label}</span>
                    <div className="horizontalCenter" style={{ marginTop: -4 }}>
                      <RenderInputLabelFontsizeEdit
                        row={entry}
                        default={props.field?.options?.setup?.layout?.defaultLabelSize || 11}
                        handleUpdatePolice={props.handleChangeLabelFontSize}
                      />
                      <UpAndDownButton
                        handleClickDown={() => props.handleDecreaseLabelFontSize(entry.id)}
                        handleClickUp={() => props.handleIncreaseLabelFontSize(entry.id)}
                        tooltipDown={t('tablemulti.font-label-decrease')}
                        tooltipUp={t('tablemulti.font-label-increase')}
                      />
                    </div>
                    <EditAlignementComponent
                      handleChangeAlignment={(align) => props.handleChangeLabelAlignment(align, entry.id)}
                      value={entry.labelAlignment || 'center'}
                    />
                    {/* <br /> */}
                    <PlugTooltip title={t('helper.toggle-visibility-col')} placement="top">
                      <div style={{ justifyContent: 'center', display: 'flex', gap: 5, marginTop: 4 }}>
                        <span onClick={() => props.handleTogglePDFVisibility(entry)} >
                          <VisibilityIndicator large text="PDF" visible={!isDefined(entry.showOnPdf) || entry.showOnPdf} />
                        </span>
                        <span onClick={() => props.handleTogglePNVisibility(entry)} >
                          <VisibilityIndicator large text="PN-" visible={!isDefined(entry.visible) || entry.visible} />
                        </span>
                        <span onClick={() => props.handleTogglePFVisibility(entry)} >
                          <VisibilityIndicator large text="PF-" visible={!isDefined(entry.encodable) || entry.encodable} />
                        </span>
                      </div>
                    </PlugTooltip>
                  </TableCell>
                )
              })
            }
          </TableRow>
        </TableHead>
        <TableHead>
          <TableRow
            style={{ position: 'relative', height: 40, marginLeft: -20, }}
          >
            <TableCell
              className={`${classes.stickyColumn} ${classes.noBorder}`}
              colSpan={3}
            >
              <div className={classes.optionTitleCtn}
                style={{ marginLeft: -5 }}
              >
                <ToolsIcon color={colors.labelGrey} size={24} />
                <span>{t('tablemulti.edit-per-column')} — {t('tablemulti.rows-row')}</span>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableHead className={classes.tableBodyCtn}>
          <TableRow className={classes.tableHeaderEdit}>
            <TableCell align="center"
              className={`${classes.stickyColumn}`}
              style={{ width: 150, backgroundColor: colors.mainLight, zIndex: 5 }}
            >
              {/* <span>{t('tablemulti.rows-row')}</span> */}
              <div className={classes.labelEdit}>
                <PlugTooltip title={t('tablemulti.default-row')}>
                  <div className={classes.headerInputCtn} >
                    <span className="material-icons" style={{ fontSize: 20 }} >
                      format_size
                    </span>
                    <Input
                      autoComplete="off"
                      classes={{ input: classes.inputCenter }}
                      id="standard-full-width"
                      inputMode="numeric"
                      police="police"
                      inputlabelprops={{ shrink: true }}
                      onChange={props.handleChangeRowsFontSize}
                      style={{ width: 60 }}
                      type="number"
                      value={props.defaultFontSize}
                    />
                  </div>
                </PlugTooltip>
                <UpAndDownButton
                  handleClickDown={props.handleDecreaseRowsFontSize}
                  handleClickUp={props.handleIncreaseRowsFontSize}
                  tooltipDown={t('tablemulti.fontsize-decrease-default')}
                  tooltipUp={t('tablemulti.fontsize-increase-default')}
                />
              </div>
              <div>{t('tablemulti.alignment')}</div>
            </TableCell>
            {
              props.colonnes.map((entry, id) => {
                if ((props.mode === 'plugcode-complete' && entry.encodable === false) || (props.mode === 'plugcode-consult' && entry.visible === false)) { return null }
                if (props.mode === 'pdf' && entry.showOnPdf === false) { return null }

                return (
                  <TableCell
                    id={'table-header-' + props.field.label + '-' + id}
                    key={'table-header-' + props.field.label + '-' + id}
                    style={{ minWidth: 160, borderLeft: `1px solid ${colors.unselected}` }}
                    align="center"
                  >
                    {/* <span>{entry.label}</span> */}
                    <div className="horizontalCenter" style={{ marginTop: -4 }}>
                      <RenderInputRowFontsizeEdit
                        row={entry}
                        default={props.field?.options?.setup?.layout?.defaultFontSize || 11}
                        handleUpdatePolice={props.handleChangeRowFontSize}
                      />
                      <UpAndDownButton
                        handleClickDown={() => props.handleDecreaseRowFontSize(entry.id)}
                        handleClickUp={() => props.handleIncreaseRowFontSize(entry.id)}
                        tooltipDown={t('tablemulti.font-row-decrease')}
                        tooltipUp={t('tablemulti.font-row-increase')}
                      />
                    </div>
                    <EditAlignementComponent
                      handleChangeAlignment={(align) => props.handleChangeRowAlignment(align, entry.id)}
                      value={entry.rowAlignment || 'center'}
                    />
                  </TableCell>
                )
              })
            }
          </TableRow>
        </TableHead>
      </Table>
    </div>
  )
}

export default RenderEditPerColumnSettingsComponent
