import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {postRegisterRequest} from '../../actions/user';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";
import logo from '../../assets/images/logos/plugnotes_logo_color.png';
import background from '../../assets/images/register_background.png';
import clientLogos from '../../assets/images/register_logo_clients.png';
import computerImg from '../../assets/images/register_ordi_smartphone.png';
import computerImgInt from '../../assets/images/register_ordi_smartphone_en.png';
import MuiPhoneNumber from 'material-ui-phone-number';
import {Checkbox, TextField} from '@material-ui/core';
import colors from '../../assets/themes/Colors';
import qs from "querystringify";
import {setLanguage} from "../../utils/Common";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    bodyCtn: {
        display: 'flex',
        padding: '0px 10vw',
        position: 'relative',
        justifyContent: 'space-between',
        '@media (max-width: 1024px)': {
            flexDirection: 'column',
            gap: 20,
        }
    },
    // registerBck: {
    //     position: 'absolute',
    //     scale: 2.2,
    //     objectFit: 'contain',
    //     objectPosition: 'left',
    //     maxHeight: 600,
    // },
    infosCtn: {
        width: '55%',
        paddingRight: 40,
        fontSize: 16,
        color: colors.unselected,
        fontFamily: 'Poppins, sans-serif',
        '@media (max-width: 1024px)': {
            width: 'auto',
            order: 1
        }
    },
    registerCtn: {
        width: '100vw',
        height: '100vh',
        position: 'absolute',
        zIndex: 4,
        display: 'flex',
        flexDirection:' column',
        alignItems: 'center',
        overflow: 'auto',
        background: `url('${background}')`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'left -100px',
        backgroundSize: 'auto 150vh',
        
    },
    fullWidth : {
      width : '100%'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    submitBtn: {
        backgroundColor: colors.main,
        borderRadius: 50,
        color: 'white',
        fontFamily: 'Poppins, sans-serif',
        fontSize: 16,
        padding: '12px 44px',
        width: '100%',
    },
    headerCtn: {
        padding: '1.5rem 20px',
    },
    logoContainer:{
        display: 'inline-block',
        float: 'left',
    },
    registerLogo: {
        width: 250,
    },
    logo:{
        width: '35%',
    },
    subHeaderCtn: {
        paddingBottom: 20,
        textAlign: 'center',
    },
    footerCtn: {
        height: 120,
        flex: '0 0 120px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 10,
        '@media (max-width: 500px)': {
            marginBottom: '100px',
        }
    },
    footerFnt: {
        fontFamily: 'Poppins',
        fontSize: 16,
        textAlign: 'center'
    },
    registerTitle: {
        fontWeight: 'bold',
        fontSize: 44,
        color: colors.mainMedium,
        textAlignLast: 'center',
        fontFamily: 'Poppins, sans-serif',
    },
    subTitle: {
        fontSize: 16,
        textAlign: 'center',
        fontFamily: 'Poppins',
        fontWeight: 500
    },
    infos: {
        fontSize: 16,
        fontWeight: 500,
        fontFamily: 'Poppins',
    },
    imgCtn: {
        maxWidth: 600,
        padding: 30
    },
    inputFieldCtn: {
        backgroundColor: colors.silver,
        borderRadius: 18,
        padding: 45,
        width: '45%',
        maxWidth: 500,
        marginBottom: 50,
        '@media (max-width: 1024px)': {
            width: 'auto',
            alignSelf: 'center',
        }
    },
    input: {
        background: 'white',
        height: 35,
        '& .MuiOutlinedInput-notchedOutline': {
            top: '-15px !important'
        }
    },
    registerLabel: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: 16,
        padding: '6px 0px',
        color: colors.unselected
    },
    inputRowCtn: {
        display: 'flex',
        flexDirection: 'column'
    },
    inputRow1Ctn: {
        display: 'flex',
        flexDirection: 'row',
        gap: 8,
        justifyContent: 'space-between',
        '@media (max-width: 500px)': {
            flexDirection: 'column'
        }
    },
    formContainer: {
        padding: '20px',
        border: 'solid',
        borderRadius: '10px',
        margin: '20px',
        borderColor: 'darkgray',
        boxShadow: '5px 5px 5px grey',
    },
    margin_left_5 : {
        marginLeft : '5px',
        cursor : 'pointer',
    },
    accept:{
        display: 'flex',
        alignItems: 'center'
    }
}));

function RegisterV2(props) {
    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'login']);

    const firstname = useFormInput('');
    const lastname = useFormInput('');
    const email = useFormInput('');
    const company = useFormInput('');
    const [telephone, setTelephone] = useState('');
    const [accept1, setAccept1] = useState(false);
    const [accept2, setAccept2] = useState(false);

    const [errorFirstname, setErrrFirstName] = useState(false);
    const [errorLastname, setErrorLastName] = useState(false);
    const [errorEmail, setErrorMail] = useState(false);
    const [errorCompany, setErrorCompany] = useState(false);
    const [errorTelephone, setErrorTelephone] = useState(false);
    const [errorAccept1, setErrorAccept1] = useState(false);
    const [errorAccept2, setErrorAccept2] = useState(false);
    const [error, setError] = useState(false);
    const [send, setSend] = useState(false);
    const [control, setControl] = useState(false);

    const [lg, setLg] = React.useState(qs.parse(props.location.search, { ignoreQueryPrefix: true })?.lg || 'fr');


    let lang = i18n.language.substring(0, 2)

    /**
     * Ajout d'une vérif pour le type 'email'
     */
    const isEmail = (value) => {
        return /(.)+@(.)+/.test(value)
    }

    const isPhoneNumber = (value) => {
        return true
    }

    // handle button click of login form
    const handleLogin = () => {
        //props.attemptRealLogin(username.value,  password.value);
        props.history.push('/');
    };

    const _handlePressLink = (link) => {
        window.open("https://plugnotes.com/conditions/","_blank");
/*        if (link === 'conditions') {
            window.open("http://api.plugnotes.com/assets/Conditions_d_utilisation.docx", "_blank");
        } else {
            window.open("http://api.plugnotes.com/assets/Politique_de_confidentialite.docx", "_blank");
        }*/
    }

    const handleControlAccount = () => {
        if(firstname.value.length === 0) setErrrFirstName(true); else setErrrFirstName(false);
        if(lastname.value.length === 0) setErrorLastName(true); else setErrorLastName(false);
        if(company.value.length === 0) setErrorCompany(true); else setErrorCompany(false);
        if(email.value.length === 0 || isEmail(email.value) === false) setErrorMail(true); else setErrorMail(false);
        if(telephone.length === 0 || !isPhoneNumber(telephone)) setErrorTelephone(true); else setErrorTelephone(false);
        if(accept1 === false) setErrorAccept1(true); else setErrorAccept1(false);
        if(accept2 === false) setErrorAccept2(true); else setErrorAccept2(false);

        setControl(true);
    }

    useEffect(() => {
        if(control){
            setControl(false);
            if(errorFirstname || errorLastname || errorEmail || errorTelephone || errorAccept1 || errorAccept2){
                setError(true);
                setSend(false);
            }else{
                setError(false);
                setSend(true);
            }
        }
    }, [control])

    useEffect(() => {
        if(!error && send){
            setSend(false);
            let payload =   {
                email : email.value,
                mobile : telephone.replace(/\s/g,''),
                username : email.value,
                firstname : firstname.value,
                lastname : lastname.value,
                company : company.value,
                language : i18n.language.substr(0, 2).toLowerCase() === 'fr' ? 'fr' : 'en',
                hubspotLanguage : i18n.language.toUpperCase(),
                accept: "1",
                accept_2: "1"
            }
            props.attemptRegister(payload)
        }
    }, [send])

    useEffect(() => {
        i18n.changeLanguage(lg.toLowerCase());
        setLanguage(lg.toLowerCase());
    }, [lg])


    const onChangePhoneInput = (value) => {
        setTelephone(value);
    }

    const handleGoogleTagManager = (w, d, s, l, i) => {
        w[l] = w[l] || []
        w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
        })
        let f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l !== 'dataLayer' ? '&l=' + l : ''
        j.async = true
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl
        f.parentNode.insertBefore(j, f)
    }
    handleGoogleTagManager(window, document, 'script', 'dataLayer', 'GTM-NPWD826')

    return (
        <Grid>
            <div className={classes.registerCtn}>
                <div className={classes.headerCtn}>
                    <img className={classes.registerLogo} src={logo} alt="Plugnotes" />
                </div>
                <div className={classes.subHeaderCtn}>
                    <h1 className={classes.registerTitle}>{t('register.discover')}</h1>
                    <span className={classes.subTitle}>{t('register.subheader')}</span>
                </div>

                <div className={classes.bodyCtn}>
                    <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-NPWD826" title='google-tag-mng'
                        height="0" width="0" style={{ display:"none", visibility:"hidden"}} />
                    <div className={classes.infosCtn}>
                        <span className={classes.infos}>{t('register.infos')}</span>
                        <div className={classes.imgCtn}>
                            <img className={classes.computerImg} src={lang === 'fr' ? computerImg : computerImgInt} alt="computer-demo" />
                            <img className={classes.registerBck} src={clientLogos} alt="clients" />
                        </div>
                    </div>

                    <div className={classes.inputFieldCtn}>
                        <div className="popup_wrapper flexSpacebetween">
                            <ul className="inputWrapper">
                                <li className={classes.inputRow1Ctn}>
                                    <span className={classes.inputRowCtn}>
                                        <span className={classes.registerLabel}>
                                            {t('register.firstname')}
                                        </span>
                                        <TextField
                                            {...firstname}
                                            autoComplete="off"
                                            InputProps={{classes: {root: classes.input},}}
                                            variant="outlined"
                                            required
                                            fullWidth
                                            name="firstname"
                                            type="text"
                                            id="firstname"
                                            error={errorFirstname}
                                            helperText={errorFirstname ? <span>{t('login.error-firstname-required')}</span> : ''}
                                        />
                                    </span>
                                    <span className={classes.inputRowCtn}>
                                        <span className={classes.registerLabel}>
                                            {t('register.lastname')}
                                        </span>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            name="lastname"
                                            type="text"
                                            id="lastname"
                                            InputProps={{classes: {root: classes.input},}}
                                            {...lastname}
                                            autoComplete="off"
                                            error={errorLastname}
                                            helperText={errorLastname ? <span>{t('login.error-lastname-required')}</span> : ''}
                                        />
                                    </span>
                                </li>
                                <li className={classes.inputRowCtn}>
                                    <span className={classes.registerLabel}>
                                        {t('register.company')}
                                    </span>
                                    <TextField
                                        {...company}
                                        autoComplete="off"
                                        variant="outlined"
                                        required={true}
                                        fullWidth
                                        name="company"
                                        type="text"
                                        id="company"
                                        InputProps={{classes: {root: classes.input},}}
                                        error={errorCompany}
                                        helperText={errorCompany ? <span>{t('login.error-company-required')}</span> : ''}
                                    />
                                </li>
                                <li className={classes.inputRowCtn}>
                                    <span className={classes.registerLabel}>
                                        {t('register.email')}
                                    </span>
                                    <TextField
                                        variant="outlined"
                                        required
                                        fullWidth
                                        name="email"
                                        type="text"
                                        id="email"
                                        InputProps={{classes: {root: classes.input},}}
                                        {...email}
                                        autoComplete="off"
                                        error={errorEmail}
                                        helperText={errorEmail ? <span>{t('login.error-mail-required')}</span> : ''}
                                    />
                                </li>
                                <li className={classes.inputRowCtn}>
                                    <span className={classes.registerLabel}>
                                        {t('register.phone')}
                                    </span>
                                    <MuiPhoneNumber
                                        defaultCountry={'be'}
                                        onChange={onChangePhoneInput}
                                        variant="outlined"
                                        required
                                        fullWidth
                                        name="telephone"
                                        type="text"
                                        id="telephone"
                                        InputProps={{classes: {root: classes.input},}}
                                        error={errorTelephone}
                                        helperText={errorTelephone ? <span>{t('login.error-mobile-required')}</span> : ''}
                                    />
                                </li>
                                <li className={classes.accept}>
                                    <Checkbox id="accept1" name="accept1" color="default" className="checkbox" type="checkbox" disabled={false} required={true} value={accept1 && accept2} onClick={(event) => {setAccept1(!accept1); setAccept2(!accept2)}} />
                                    <label className={`blue ${classes.margin_left_5} ${classes.footerFnt}`} htmlFor="accept1" >{t('register.agree-policy')}<a href='policy' onClick={_handlePressLink.bind(this,"conditions")} style={{color : colors.main, fontFamily: 'Poppins', fontWeight: 500}}> {t('register.policy')}</a></label>
                                </li>
                                {errorAccept1 ?
                                <li className={classes.accept}>
                                        <div><p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required"></p></div>
                                </li>
                                    : ''
                                }
                                {errorAccept2 ?
                                <li className={classes.accept}>
                                    <div>
                                        <p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required">
                                            <span style={{color : 'red'}}>{t('login.error-accept-two')}</span>
                                        </p>
                                    </div>
                                </li>
                                : ''
                                }
                            </ul>

                            <div className="align-items-center">
                                <div className="confirmation_pop-up">
                                    <button
                                        className={classes.submitBtn}
                                        onClick={handleControlAccount}
                                        disabled={props.fetching}
                                        >
                                        {props.fetching ? t('global.loading') : t('register.submit')}
                                    </button>
                                </div>
                                {/* <div className="confirmation_pop-up greya" onClick={handleLogin}>
                                    <span className="cliquables grey">{t('login.already-account')}</span>
                                </div> */}
                            </div>
                        </div>

                    </div>
                </div>
                <div className={classes.footerCtn}>
                    <span className={classes.footerFnt}>{t('register.required-field')}</span>
                    <span className={classes.footerFnt}>{t('register.copyright')}</span>
                </div>
            </div>
        </Grid>

    );

}

const useFormInput = initialValue => {
    const [value, setValue] = useState(initialValue);

    const handleChange = e => {
        setValue(e.target.value);
    }
    return {
        value,
        onChange: handleChange
    }
}


// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptRegister: (payload) => dispatch(postRegisterRequest(payload)),

});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterV2)
