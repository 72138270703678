import React, {useEffect,  useState} from 'react';
import * as moment from "moment";
import {
    delAnnexes,
    deleteHistoryPlugAction,
    delModel,
    delPlugnote,
    delSpecifiFieldsSelected, delSpecifiFieldsSize, delTypeOfPlugnote,
    getCurrentEnvironnement,
    getModel,
    getPlugnote,
    getToken,
    getUser,
    setAnnexes,
    setFieldsSelected, setFieldsSize,
    setModel,
    setModelToUpdate,
    setPlugnote,
    setPlugnoteView
} from '../../utils/Common';
import {makeStyles} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";
import {Content, Header } from "rsuite";
import {connect} from "react-redux";
import {
    delDeleteDataRequest,
    delDeleteDatasRequest, getDatasLightRequest, getDatasPaginateLightRequest,
    getDatasPaginateRequest,
    getDatasRequest, getDecryptPasswordRequest,
    putUpdateDataArchiveRequest,
    putUpdateDataFavorisRequest, putUpdateDataFieldRequest,
    putUpdateDataLockRequest,
    putUpdateDatasArchiveRequest, putUpdateDatasUnArchiveRequest,
    reduxDelAllDataFilterRequest,
    reduxDelDataFilterRequest,
    reduxDelGlobalDataFilterRequest, reduxGenerateDataPasswordReset,
    reduxSetDataFilterRequest
} from "../../actions/data";
import {usePromiseTracker} from "react-promise-tracker";
import ModalModelPlugcodeComponent from "../../components/modals/ModalModelPlugcodeComponent";
import ModalModelShareComponent from "../../components/modals/ModalModelShareComponent";
import {confirmAlert} from "react-confirm-alert";
import {useTranslation} from "react-i18next";
import {
    postModelGenerateMultiplePlugcodeRequest,
    reduxGeneratePlugformcodeReset,
    reduxGeneratePlugnotecodeReset
} from "../../actions/plugcode";
import {reduxGenerateShareReset} from "../../actions/share";
import {
    delModelUniqueRequest,
    postExportFullFileDatasRequest,
    putReplaceModelRequest
} from "../../actions/model";
import ModalSimpleExportCsvComponent from "../../components/modals/ModalSimpleExportCsvComponent";
import ModalTemplateImportExportCsvComponent from "../../components/modals/ModalTemplateImportExportCsvComponent";
import ModalImportExportCsvComponent from "../../components/modals/ModalImportExportCsvComponent";
import ModalPlugnotePlugcodeComponent from "../../components/modals/ModalPlugnotePlugcodeComponent";
import ModalCopyOrMovePlugnoteComponent from "../../components/modals/ModalCopyOrMovePlugnoteComponent";
import {
    isJsonString,
    isJsonStringObject,
    isJsonStringObjectAndCount,
    isJsonStringObjectAndCountSubElement,
    isJsonStringObjectAndGetElement,
    validateEmail,
    validateNumber,
    validateText,
    validateTextarea,
    validateUrl
} from "../../utils/utils";
import {reduxGenerateAnnexeReset} from "../../actions/annexe";
import { SelectCellFormatter } from 'react-data-grid';
import {getTemplateFormatText, isCompatibleSpecial} from "../../utils/Compatibility";
import PopupState, {bindPopover, bindTrigger} from "material-ui-popup-state";
import DraggableHeaderRenderer from "../../components/renderers/DraggableHeaderRenderer";
import HeaderSearch from "../../components/searchs/HeaderSearch";
import Tooltip from "@material-ui/core/Tooltip";
import Images from "../../assets/themes/Images";
import {
    GridOverlay,
    useGridApiRef,
    XGrid,
    GRID_COLUMN_HEADER_DRAG_END,
    GRID_COLUMN_HEADER_DRAG_START,
} from "@material-ui/x-grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import PhoneInput from "react-phone-number-input";
import {KeyboardDatePicker, KeyboardDateTimePicker, KeyboardTimePicker} from "@material-ui/pickers";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Input,
    MenuItem,
    Popover,
    Select,
    TextField
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import PlacesAutocomplete, {geocodeByAddress} from "react-places-autocomplete";
import {history} from "../../hooks/history";

import {
    GridToolbarContainer,
    GridToolbarExport
} from "@material-ui/x-grid";
import HeaderInfos from "../../components/infos/HeaderInfos";
import colors from '../../assets/themes/Colors';


const drawerWidth = 400;
const subDrawerWidth = 320;

//const specialFields = ['plugform','textarea', 'list', 'single-select', 'free-single-select', 'multi-select', 'free-multi-select','single-table', 'free-single-table', 'multi-table', 'free-multi-table', 'qrcode', 'localis'];
const specialFields = ['textarea', 'text', 'numeric', 'phone', 'mail', 'url', 'date', 'boolean', 'select', 'agenda', 'localis', 'password', 'time', 'list', 'birthday', 'qrcode', 'glocalis'];
const editableDirectFields = ['text', 'numeric', 'phone', 'mail', 'url', 'date', 'boolean', 'select'];
const optionsFields = ['select', 'table'];


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor : 'white',
        '& .super-app.red': {
            backgroundColor: 'red', // you need to configure the background colors to the colorKey
            color: '#1a3e72',
        },
        '& .super-app.blue': {
            backgroundColor: 'blue',
            color: '#1a3e72',
        },
        '& .super-app.orange': {
            backgroundColor: 'orange',
            color: '#1a3e72',
        },
    },
    buttonSimple:{
        backgroundColor: '#58C3C1',
        color: 'white',
        "&:hover":{
            backgroundColor: '#58C3C1'
        },
        "&:disabled":{
            backgroundColor: '#58C3C1'
        }
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F1F1F1',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    fieldImageSvg : {
        width: '20px',
        marginLeft : '10px'
    },
    grid:{
        minHeight : '40vh',
        height : '75vh',
        maxHeight : '75vh',
        '& .MuiDataGrid-cellEditing': {
            backgroundColor: 'rgb(255,215,115, 0.19)',
            color: '#1a3e72',
        },
        '& .Mui-error': {
            backgroundColor: `rgb(126,10,15, 0.1})`,
            color: '#750f0f',
        },
    },
    margin_left_5:{
        marginLeft : '5px',
    },

    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        padding: '15px 0px 0px 30px'
    },
    subDrawer: {
        width: subDrawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    paperAnchorDockedLeft : {
        boxShadow: '0px 0px 6px 4px rgba(136, 136, 136, 0.12)',
        border : 0,
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    iconSearchActive:{
        lineHeight: '56px',
        textAlign: 'center',
        color : '#58C3C1'
    },
    iconSearch:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : '#DADADA'
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: '70px'
    },
    subDrawerOpen: {
        width: subDrawerWidth,
        // transition: theme.transitions.create('width', {
        //     easing: theme.transitions.easing.sharp,
        //     duration: theme.transitions.duration.enteringScreen,
        // }),
        marginLeft : '70px'
    },
    subDrawerClose: {
        // transition: theme.transitions.create('width', {
        //     easing: theme.transitions.easing.sharp,
        //     duration: theme.transitions.duration.leavingScreen,
        // }),
        overflowX: 'hidden',
        width: '0px'
    },
    formControl: {
        margin: theme.spacing(1),
        width : '100%'
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    openClose: {
        bottom: 0,
        // position: 'absolute',
        width : '100%'
    },
    openCloseButton : {
        float : 'right',
        marginRight :'10px'
    },
    openedCloseButton : {
        float : 'right',
        marginRight :'20px'
    },
    content: {
        width : '100%',
        backgroundColor : 'white',
        padding: '0px 0px',
    },
    contentSub:{
        width : '100%',
        backgroundColor : 'white',
        padding: '64px 0px',
        marginLeft : '-70px'
    },
    contentModelGrid : {
        //flexGrow: 1,
        //padding: theme.spacing(3),
        display: 'flex',
        backgroundColor : 'white',
        flexWrap : 'wrap',
        justifyContent: 'space-evenly',
        padding: '0px 20px',
        //columnGap: '20px',
        maxHeight : 'calc(85vh - 80px)',
    },
    contentModelList : {
        //flexGrow: 1,
        //padding: theme.spacing(3),
        marginTop : '0px',
        backgroundColor : 'white',
        flexWrap : 'wrap',
        justifyContent: 'space-evenly',
        columnGap: '20px',
        padding: '0px 20px',
        maxHeight : 'calc(85vh - 80px)',
    },
    contentModelListOpenFilter : {
        //flexGrow: 1,
        //padding: theme.spacing(3),
        marginTop : '150px',
        backgroundColor : 'white',
        flexWrap : 'wrap',
        justifyContent: 'space-evenly',
        columnGap: '20px',
    },
    openCloseRelative: {
        bottom: 0,
        position: 'relative',
        width : '100%'
    },
    icon:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : '#DADADA'
    },
    iconActive:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : '#58C3C1'
    },
    gridHeader : {
        padding: '10px 20px !important',
        width: '100%',
    },
    selectEnvironnement : {
    },
    iconSelect : {
        color : '#58C3C1 !important'
    },
    pointer:{
        cursor: 'pointer'
    },
    listHeight:{
        height: '100%'
    },
    phone: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: 16,
    },
    editSelect: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: 16,
        width: '100%'
    },
    selectedActionsContainer: {
        position: 'absolute',
        top: -10,
        left: 10,
        backgroundColor: colors.whiteAlpha,
        borderRadius: 4,
        padding: '3px 10px',
        zIndex: 3,
    },
}));
const gridStyles = makeStyles((theme) => ({
    root: {
        '& .super-app-theme--cell': {
            backgroundColor: 'rgba(224, 183, 60, 0.55)',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.positive': {
            backgroundColor: 'rgba(157, 255, 118, 0.49)',
            color: '#1a3e72',
            fontWeight: '600',
        },
        '& .super-app.negative': {
            backgroundColor: 'red',
            color: 'white',
            fontWeight: '600',
        },
        '& .super-app.deleted': {
            backgroundColor: '#868686',
            color: 'white',
            fontWeight: '600',
            textDecoration: 'line-through'
        }
    },
}));

const defaultColumnProperties = {
    resizable: true,
    frozen: true,
    sortable : true,
    width: 120
};

const getCodePnPpCodeTemplate = (plug) => {

    const {hash, idOffline, userindex, creationdate, codepncode, publicpncode, modname,modclef} = plug
    //file-download

    let codePn= 'PN';

    switch (publicpncode) {
        case 0:
            codePn = 'PP';
            break;
        case 1:
            codePn = 'PN';
            break;
        case 2:
            codePn = 'PE';
            break;
        case 3:
            codePn = 'PS';
            break;
    }

    let pncode = plug.codepncode ? `${codePn}-${moment(plug.creationdate).format('YYMMDD')}-${plug.codepncode}` : null

    if(plug.pncode_countopen > 0 && pncode !== null){
        pncode = pncode + ' (' + plug.pncode_countopen + ') '
    }else if(pncode !== null){
        pncode = pncode
    }else{
        pncode = "";
    }

    return pncode
};

const parseJson = (code) => {
    try {
        return JSON.parse(code);
    } catch (e) {
        return code;
    }
};

function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    );
}

function renderPhoneEditInputCell (params) {
    return <PhoneEditInputCell {...params} />;
}

function renderDateEditInputCell (params) {
    return <DateEditInputCell {...params} />;
}

function renderSelectEditInputCell(params){
    return <SelectEditInputCell {...params} />
}

function PhoneEditInputCell (props) {
    const { id, value, api, field } = props;
    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'models', 'global']);

    const handleChange = React.useCallback(
        (value) => {
            const editProps = {
                value: value,
                format: 'phone'
            };
            api.setEditCellProps({id, field,  props: editProps });
            //api.setCellMode(id, field, 'view');
        },
        [api, field, id],
    );

    return (
        <div className={classes.phone}>
            <PhoneInput
                defaultCountry="BE"
                value={typeof value !== 'undefined' ? value.toString() : ''}
                placeholder={t('fields.format-choice-placeholder-phone')}
                onChange={(event) =>  handleChange(event)}
                //disabled={disabled}
            />
        </div>
    );
}

function DateEditInputCell (props) {
    const { id, value, api, field } = props;
    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'models', 'global']);

    const handleChange = React.useCallback(
        (value) => {
            const editProps = {
                value: value,
            };
            api.setEditCellProps({id, field,  props: editProps });
        },
        [api, field, id],
    );

    return (
        <div className={classes.phone}>
            <KeyboardDatePicker
                className={classes.fullWidth}
                variant="inline"
                format="yyyy-MM-dd"
                fullWidth={true}
                id={'date-picker-inline-'+id+field}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                value={typeof value !== 'undefined' ? value.toString() : ''}
                onChange={(date, value) =>  handleChange(value)}
            />
        </div>
    );
}

function SelectEditInputCell(props){

    const { id, value, api, field } = props;
    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'models', 'global']);


    const handleChange = React.useCallback(
        (event) => {
            const editProps = {
                value: event.target.value,
                type : 'single-select',
                format : 'select',
                arrayValue : [event.target.value],
                arrayStringValue : JSON.stringify([event.target.value])
            };
            api.setEditCellProps({id, field,  props: editProps });
            event.stopPropagation();
            //api.setCellMode(id, field, 'view');
        },
        [api, field, id],
    );

    const handleChangeMultiple = React.useCallback(
        (event) => {
            const editProps = {
                value: event.target.value,
                type : 'multiple-select',
                format : 'select',
                arrayValue : event.target.value,
                arrayStringValue : JSON.stringify(event.target.value)
            };
            api.setEditCellProps({id, field,  props: editProps });
            event.stopPropagation();
            //api.setCellMode(id, field, 'view');
        },
        [api, field, id],
    );

    let valueSimple = "";
    let valueMultiple = [];

    let options = null

    if(typeof props.colDef.options !== "undefined" && isJsonStringObject(props.colDef.options)){
        options = JSON.parse(props.colDef.options)
    }else if(typeof props.colDef.options !== "undefined" && !isJsonStringObject(props.colDef.options)){
        options = props.colDef.options

    }


    let multiselect = false;
    let presel = [];

    if(options !== null){
        multiselect = options.multiselect;
        presel = Array.isArray(options.presel) &&  options.presel.length  === 0 ?  [] : options.presel.split(";")
    }



    if(typeof props.value !== "undefined"){

        if(typeof props.row.plugnote[field] !== "undefined"){
            if(options !== null && !options.multiselect && isJsonString(props.row.plugnote[field])){
                valueSimple = JSON.parse(props.row.plugnote[field])[0];
                if(options.editable){
                    if(!presel.includes(valueSimple)){
                        presel.push(valueSimple);
                    }
                }
            }
            else if(options !== null && !options.multiselect && props.row.plugnote[field].length > 0){
                valueSimple = props.row.plugnote[field];
                if(options.editable){
                    if(!presel.includes(valueSimple)){
                        presel.push(valueSimple);
                    }
                }
            }
            else if(options !== null && options.multiselect && isJsonString(props.row.plugnote[field])){
                valueMultiple = JSON.parse(props.row.plugnote[field]);
                if(options.editable){
                    valueMultiple.map((item, index) => {
                        if(!presel.includes(item)){
                            presel.push(item);
                        }
                    })
                }
            }
        }
    }


    const [selected, setSelected] = useState(valueSimple);
    const [selectedMultiple, setSelectedMultiple] = useState(valueMultiple);


    if(options !== null && typeof props.row.plugnote[field] !== "undefined"){
        if(options !== null && !options.multiselect && isJsonString(props.row.plugnote[field])){
            valueSimple = JSON.parse(props.row.plugnote[field])[0];
            if(options.editable){
                if(!presel.includes(valueSimple)){
                    presel.push(valueSimple);
                }
            }
        }
        else if(options !== null && !options.multiselect && props.row.plugnote[field].length > 0){
            valueSimple = props.row.plugnote[field];
            if(options.editable){
                if(!presel.includes(valueSimple)){
                    presel.push(valueSimple);
                }
            }
        }
        else if(options !== null && options.multiselect && isJsonString(props.row.plugnote[field])){
            valueMultiple = JSON.parse(props.row.plugnote[field]);
            if(options.editable){
                valueMultiple.map((item, index) => {
                    if(!presel.includes(item)){
                        presel.push(item);
                    }
                })
            }
        }


        if(multiselect){

            return (
                <div className={classes.editSelect}>
                    <FormControl className={classes.formControl}>
                        <Select
                            multiple={true}
                            value={selectedMultiple || []}
                            fullWidth={true}
                            id={id}
                            onChange={(event) => {
                                setSelectedMultiple(event.target.value);
                                handleChangeMultiple(event);
                            }}
                        >
                            {
                                presel.map((value, index) => {
                                    return  <MenuItem key={index} value={value}>{value}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
            );
        }
        else{
            return (
                <div className={classes.editSelect}>
                    <FormControl className={classes.formControl}>

                        <Select
                            multiple={false}
                            value={selected || ""}
                            fullWidth={true}
                            id={id}
                            onChange={(event) => {
                                setSelected(event.target.value);
                                handleChange(event);
                            }}
                        >
                            {
                                presel.map((value, index) => {
                                    return  <MenuItem key={index} value={value}>{value}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
            );
        }
    }
    else if(options !== null && typeof props.row.plugnote[field] === "undefined"){

        if(multiselect){

            return (
                <div className={classes.editSelect}>
                    <FormControl className={classes.formControl}>
                        <Select
                            multiple={true}
                            value={selectedMultiple || []}
                            fullWidth={true}
                            id={id}
                            onChange={(event) => {
                                setSelectedMultiple(event.target.value);
                                handleChangeMultiple(event);
                            }}
                        >
                            {
                                presel.map((value, index) => {
                                    return  <MenuItem key={index} value={value}>{value}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
            );
        }
        else{
            return (
                <div className={classes.editSelect}>
                    <FormControl className={classes.formControl}>

                        <Select
                            multiple={false}
                            value={selected || ""}
                            fullWidth={true}
                            id={id}
                            onChange={(event) => {
                                setSelected(event.target.value);
                                handleChange(event);
                            }}
                        >
                            {
                                presel.map((value, index) => {
                                    return  <MenuItem key={index} value={value}>{value}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
            );
        }
    }
    else return null;

}

function SimpleMemoDialog(props) {
    const classes = useStyles();
    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    const [content, setContent] = useState(value || '');

    const handleClose = () => {
        onClose(infos, content,infos.colDef.format);
    };

    const handleUpdateClose = () => {
        onUpdateClose(infos, content,infos.colDef.format);
    }

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={(event) => handleClose()} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>
                <TextField
                    multiline
                    minRows={10}
                    rowsMax={512}
                    inputProps={{ maxLength: 512 }}
                    defaultValue={content}
                    variant="outlined"
                    fullWidth={true}
                    onChange={(event) => setContent(event.target.value)}
                />
                <span style={{float : "right", fontSize : '10px'}}>{content.length +  ' / ' + '512'}</span>
            </DialogContent>
            <DialogActions>
                <Button onClick={(event) => handleClose()} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={(event) => handleUpdateClose()} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimpleAgendaDialog(props) {
    const classes = useStyles();

    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    const [selectedDate, handleDateChange] = useState(value !== null && value.length > 0 ? value : null);
    const [selectedDateValue, handleDateChangeValue] = useState();


    const handleClose = () => {
        onClose(infos, selectedDateValue,infos.colDef.format);
    };

    const handleUpdateClose = () => {
        onUpdateClose(infos, selectedDateValue,infos.colDef.format);
    }

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={(event) => handleClose()} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>
                <KeyboardDateTimePicker
                    variant="inline"
                    ampm={false}
                    fullWidth={true}
                    id={'date-picker-inline'}

                    //label={props.field.title}
                    //value={selectedDate}
                    //onChange={handleDateChange}
                    format="yyyy-MM-dd HH:mm"
                    value={selectedDate}
                    onChange={(date, value) => {
                        handleDateChange(date);
                        handleDateChangeValue(value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={(event) => handleClose()} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={(event) => handleUpdateClose()} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimpleLocalisDialog(props) {
    const classes = useStyles();

    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    const [address, setAddress] = useState(value.length > 0 ? value : "");

    const [fullAddress, setFullAddress] = useState("http://maps.google.com/maps?daddr="+address);

    const handleSelect = address => {
        geocodeByAddress(address)
            .then(results => {
                setAddress(results[0].formatted_address);
            })
            .catch(error => console.error('Error', error));
    };

    const  handleChange = address => {
        setAddress(address);
    };

    const handleClose = () => {
        onClose(infos, address,infos.colDef.format);
    };

    const handleUpdateClose = () => {
        onUpdateClose(infos, address,infos.colDef.format);
    }

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>
                <div style={{width: '100%' , marginRight: '5px', display: 'flex'}}>
                    <PlacesAutocomplete
                        value={address}
                        onChange={handleChange}
                        onSelect={handleSelect}
                        key={props.index}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div style={{width : '100%'}}>
                                <input
                                    {...getInputProps({
                                        placeholder: 'Search Places ...',
                                        className: 'location-search-input',
                                    })}
                                />
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, index) => {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                                key={index}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                    { typeof address !== 'undefined' &&  address !== '' &&
                    <a href={fullAddress} target="_blank"><span className="material-icons md-medium green">room</span></a>
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={handleUpdateClose} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimplePasswordDialog(props) {
    const classes = useStyles();
    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    const handleClose = () => {
        props.props.attemptResetDataPassword();
        onClose(infos, decryptPassword,infos.colDef.format);
    };

    const handleUpdateClose = () => {
        props.props.attemptResetDataPassword();
        onUpdateClose(infos, decryptPassword,infos.colDef.format);
    }

    const [password, setPassword] = useState(infos.value);
    const [decryptPassword, setDecryptPassword] = useState("");
    const [view, setView] = useState(false);
    const [edit, setEdit] = useState(false);
    const [fieldId, setFieldId] = useState(infos.field.replace("field", ""));

    const environment = getCurrentEnvironnement();
    const plugnote =  infos.row.plugnote;

    const handleViewCryptedPassword = (event) => {
        if(!edit){
            props.props.attemptGetPasswordField(plugnote.hash,fieldId,environment.userhash);
        }
        setView(true);
        setEdit(true);
    }

    const handleHidePassword = (event) => {
        setView(false);
        props.props.attemptResetDataPassword();
        setEdit(false);
    }

    const { t, i18n } = useTranslation(['common', 'login']);


    useEffect(() => {
        if(!!props.props.password && props.props.password !== null){
            if(typeof props.props.password[infos.field] !== "undefined"){
                setDecryptPassword(props.props.password[infos.field]);
            }
        }
    }, [props.props.password, view === true]);

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={(event) => handleClose()} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>
                <div className={classes.margin_5}>
                    {
                        view ?
                            <Input
                                autoComplete='new-password'
                                id={fieldId + '-' + 'password' + '-visible'}
                                placeholder="Password"
                                fullWidth={true}
                                style={{ margin: 0 }}
                                type="text"
                                margin="none"
                                disableUnderline={true}
                                className={`text-line ${classes.textField} input`}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <div onClick={(event) => handleHidePassword(event)}>
                                    <span className="material-icons">
                                        visibility_off
                                    </span>
                                        </div>
                                    </InputAdornment>}
                                onChange={(event) => {
                                    setEdit(true);
                                    setPassword(event.target.value);
                                    setDecryptPassword(event.target.value);
                                    //props.handleChangePassword(event.target.value, props.field, props.index)
                                }}
                                value={props.props.fetchingPassword ? t('global.loading') :  decryptPassword }
                            />
                            :

                            <Input
                                disableUnderline={true}
                                autoComplete='new-password'
                                id={fieldId + '-' + 'password' + '-invisible'}
                                placeholder="password"
                                fullWidth={true}
                                style={{ margin: 0 }}
                                margin="none"
                                type="password"
                                className={`text-line ${classes.textField} input`}
                                endAdornment={ password.length > 0 &&
                                <InputAdornment position="end">
                                    <div onClick={(event) => handleViewCryptedPassword(event)}>
                                    <span className="material-icons">
                                        visibility
                                    </span>
                                    </div>
                                </InputAdornment>}
                                onChange={(event) => {
                                    setEdit(true);
                                    setPassword(event.target.value);
                                    setDecryptPassword(event.target.value);
                                    //props.handleChangePassword(event.target.value, props.field, props.index)
                                }}
                                value={password.length > 0 ?  password : ""}
                            />
                    }

                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={(event) => handleClose()} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={(event) => handleUpdateClose()} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimpleTimeDialog(props) {
    const classes = useStyles();
    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    let currentValue = null;
    if(value !== null && value.length > 0){
        let split = value.split(':');
        currentValue = new Date(null, null, null, split[0], split[1]);
    }

    const [selectedDate, handleDateChange] = useState(currentValue);
    const [selectedDateValue, handleDateChangeValue] = useState();


    const handleClose = () => {
        onClose(infos, selectedDateValue,infos.colDef.format);
    };

    const handleUpdateClose = () => {
        onUpdateClose(infos, selectedDateValue,infos.colDef.format);
    }

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>
                <KeyboardTimePicker
                    fullWidth={true}
                    className={classes.fullWidth}
                    format="HH:mm"
                    margin="normal"
                    id="time-picker"
                    //label={props.field.title}
                    ampm={false}
                    //value={selectedDate}
                    //onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change time',
                    }}
                    value={selectedDate}
                    onChange={(date, value) => {
                        handleDateChange(date);
                        handleDateChangeValue(value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={handleUpdateClose} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimpleListDialog(props) {
    const classes = useStyles();

    const { t, i18n } = useTranslation(['common', 'models', 'global']);

    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    let tmpValue = [];

    if(typeof infos.row.plugnote[infos.field] === 'undefined'){
        tmpValue = [];
    }else if( infos.row.plugnote[infos.field].length > 0){

        let keywordList = JSON.parse(infos.row.plugnote[infos.field]);
        tmpValue = [];
        keywordList.map((item, index) => {
            tmpValue.push(item.text);
        })
    }


    const [selected, setSelected] = useState(tmpValue);
    const [newValue, setNewValue] = useState("");

    const handleAddValue = (value) =>
    {
        if(!selected.includes(value)){
            setSelected([...selected, value]);
        }
        setNewValue('');
    }

    const handleRemoveValue = (value) =>
    {
        if(selected.includes(value)){
            setSelected(selected.filter(item => item !== value));
        }
    }

    const handleClose = () => {
        onClose(infos, "",infos.colDef.format);
    };

    const handleUpdateClose = () => {
        let stringValue = selected.join(', ');
        let stringValueFormated = "";
        if (selected.length > 0) {
            let correctValue = [];
            selected.map((item, index) => {
                correctValue.push({"text" : item})
            });
            stringValueFormated = JSON.stringify(correctValue);
        }

        onUpdateClose(infos, stringValue,infos.colDef.format,stringValueFormated);
    }


    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>
                <div className={classes.margin_5}>
                    <div className={classes.input_add_item}>
                        <Input
                            autoComplete='off'
                            id="standard-full-width"
                            //label="Label"
                            style={{ margin: 0 }}
                            placeholder={t('common:multiple.add-item')}
                            //helpertext="Full width!"
                            fullWidth={true}
                            margin="none"
                            inputlabelprops={{
                                shrink: true,
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <div onClick={(event) => handleAddValue(newValue)}>
                                    <span className="material-icons">
                                        add
                                    </span>
                                    </div>
                                </InputAdornment>}
                            className={`text-line ${classes.textField}  ${classes.fieldRight} input`}
                            value={newValue}
                            disableUnderline={true}
                            onKeyPress={(e) => { if (e.key === 'Enter') { handleAddValue(newValue)}}}
                            onChange={(event) => setNewValue(event.target.value)}

                        />
                    </div>
                    {
                        selected.map((value,indexation) => {
                            return (
                                <div key={indexation} className={classes.input_view_item}>
                                    <Input
                                        autoComplete='off'
                                        readOnly={true}
                                        id="standard-full-width"
                                        //label="Label"
                                        style={{ margin: 0 }}
                                        placeholder={t('common:multiple.add-item')}
                                        //helpertext="Full width!"
                                        fullWidth={true}
                                        margin="none"
                                        inputlabelprops={{
                                            shrink: true,
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <div onClick={(event) => handleRemoveValue(value)}>
                                                    <span className="material-icons">
                                                        delete
                                                    </span>
                                                </div>
                                            </InputAdornment>
                                        }
                                        className={`text-line ${classes.fieldRight} input`}
                                        value={value}
                                        disableUnderline={true}
                                        onChange={(event) => setNewValue(event.target.value)}

                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={handleUpdateClose} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimpleBirthdayDialog(props) {
    const classes = useStyles();

    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    let currentValue = null;
    if(value !== null && value.length > 0){
        let currentTime = new Date();
        let split = value.split('-');
        currentValue = new Date(currentTime.getFullYear(), split[1] - 1, split[0], null, null);
    }

    const [selectedDate, handleDateChange] = useState(currentValue);
    const [selectedDateValue, handleDateChangeValue] = useState();


    const handleClose = () => {
        onClose(infos, selectedDateValue,infos.colDef.format);
    };

    const handleUpdateClose = () => {
        onUpdateClose(infos, selectedDateValue,infos.colDef.format);
    }

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>
                <KeyboardDatePicker
                    className={classes.fullWidth}
                    variant="inline"
                    format="dd-MM"
                    fullWidth={true}
                    id={'date-picker-inline'}
                    //label={props.field.title}
                    //value={selectedDate}
                    //onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    value={selectedDate}
                    onChange={(date, value) => {
                        handleDateChange(date);
                        handleDateChangeValue(value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={handleUpdateClose} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimpleQrcodeDialog(props) {
    const classes = useStyles();
    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    let plugcode = typeof value !== 'undefined' ? infos.row.plugnote[infos.field].split(';') : [];

    let initCode = "";
    let initComment = "";

    if(plugcode.length === 2){
        initCode =  (plugcode[0] !== 'undefined' ? plugcode[0] : "");
        initComment =(typeof plugcode[1] !== 'undefined' ? plugcode[1] : "");

    }else if(plugcode.length === 1){
        let constainsCode = plugcode[0].startsWith('PN') || plugcode[0].startsWith('PP') || plugcode[0].startsWith('PF') || plugcode[0].startsWith('PE');
        if(constainsCode){
            initCode= (plugcode[0]);
            initComment =("");
        }else{
            initCode = ("");
            initComment = (plugcode[0]);
        }
    }

    const [code, setCode] = useState(initCode);
    const [comment, setComment] = useState(initComment);

    const handleClose = () => {
        onClose(infos, "(" + code +") "+ comment,infos.colDef.format, code+';'+comment);
    };

    const handleUpdateClose = () => {
        onUpdateClose(infos, "(" + code +") "+ comment,infos.colDef.format, code+';'+comment);
    }

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>
                <div className={classes.margin_5}>
                    <Input
                        autoComplete='off'
                        style={{ margin: 0 }}
                        id="standard-full-width"
                        placeholder="code"
                        fullWidth={true}
                        margin="none"
                        className="text-line"
                        disableUnderline={true}
                        onChange={(event) => {
                            setCode(event.target.value);
                        }}
                        defaultValue={code}
                    />
                    <div className="height_12">
                        <div className="line lightgrey flex_style">
                        </div>
                    </div>
                    <Input
                        disableUnderline={true}
                        style={{ margin: 0 }}
                        autoComplete='off'
                        id="standard-full-width"
                        placeholder="comment"
                        fullWidth={true}
                        multiline={true}
                        margin="none"
                        className="text-line"
                        onChange={(event) => {
                            setComment(event.target.value);
                        }}
                        defaultValue={comment}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={handleUpdateClose} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimpleMultipleTableDialog(props) {
    const classes = useStyles();
    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    const [content, setContent] = useState(value || '');


    const handleClose = () => {
        onClose(infos, content,infos.colDef.format);
    };

    const handleUpdateClose = () => {
        onUpdateClose(infos, content,infos.colDef.format);
    }

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={handleUpdateClose} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimpleSimpleTableDialog(props) {
    const classes = useStyles();
    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    const [content, setContent] = useState(value || '');


    const handleClose = () => {
        onClose(infos, content,infos.colDef.format);
    };

    const handleUpdateClose = () => {
        onUpdateClose(infos, content,infos.colDef.format);
    }

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={handleUpdateClose} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}


function SimplePlugformDialog(props) {
    const classes = useStyles();
    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    const [content, setContent] = useState(value || '');


    const handleClose = () => {
        onClose(infos, content,infos.colDef.format);
    };

    const handleUpdateClose = () => {
        onUpdateClose(infos, content,infos.colDef.format);
    }

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={handleUpdateClose} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const CustomSelect = (props) => {

    let format = null;
    let options = null;
    let multiselect = null;
    let editable = null;

    switch (props.modelCopy['field' + props.item + 'format']) {

        case 'select':
            options = props.modelCopy['field' + props.item + 'formatoptions'];
            multiselect = options.multiselect;
            editable = options.editable;

            if(!multiselect && !editable){
                format = 'select-11';
            }else if(!multiselect && editable){
                format = 'select-12';
            }else if(multiselect && !editable){
                format = 'select-13';
            }else if(multiselect && editable){
                format = 'select-14';
            }
            break;
        case 'table':
            options = props.modelCopy['field' + props.item + 'formatoptions'];
            multiselect = options.multiselect;
            editable = options.editable;

            if(!multiselect && !editable){
                format = 'table-23';
            }else if(!multiselect && editable){
                format = 'table-24';
            }else if(multiselect && !editable){
                format = 'table-25';
            }else if(multiselect && editable){
                format = 'table-26';
            }
            break;
        default:
            format = props.modelCopy['field' + props.item + 'format'];
            break;

    }

    const [value, setValue] = useState(format);

    return (
            <select
                className="input-select-text"
                    onFocus={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                    }}
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                    }}
                    onChange={(event) => {
                        setValue(event.target.value);
                        props.handleChanteTypeFormatAndValidate(event, 'field' + props.item + 'format');
                    }}
                value={value}
            >
                {
                    props.fields.map((item, index) => {
                        return (
                            <option key={index} value={item.selectedFormat}>{item.label}</option>
                        )
                    })
                }
            </select>
        )
}

const ViewPlugnotes = ({plugnotes, listView, actif, archived, favoris,
                           handleSelectPlugnote,handleCreatePlugnote, handleFlip, flipped, handleDeletePlugnote,
                           handleArchivePlugnote, handleLockPlugnote, handleFavorisPlugnote,handleModalPlugnotePlugcodeOpen,
                           handleModalPlugnoteClassifyOpen,selectedFields,
                           attemptUpdatePlugnoteField,
                           attemptGetPlugnotesPaginateByModelHashAndUserHash,hash,modelhash,limitPage, startPage,
                           setSelectedFields, setFilters,setSubOpen,fields, filters, attemptDelAllDataFilter,attemptDelDataFilter,
                           selectedRows, setSelectedRows,defaultSelectedFields, setDefaultSelectedFields,
                           sizeFields, setSizeFields, defaultSizeFields, setDefaultSizeFields,props

                       }) => {
    const classes = useStyles();
    const gridClasses = gridStyles();

    const { t, i18n } = useTranslation(['common', 'models', 'global']);

    const generateCorrectFieldsInfos = () => {
        let loadActive = props.fields.filter((item, index) => {
            if(item.active){
                return item
            }
        }).filter(Boolean);

        let finalFields = [];

        loadActive.map((item, index) => {
            if(!optionsFields.includes(item.format)){
                let copyItem = {...item};
                let position = copyItem.position <= 9 ? '0'+copyItem.position : '' + copyItem.position;
                copyItem.selectedFormat = copyItem.format;
                copyItem.label = 'F' + position + ' - ' + t('fields.format-choice-'+copyItem.format.toLowerCase())
                finalFields.push(copyItem);
            }else{

                switch (item.format) {
                    case 'select':
                        let copyItem1 = {...item};
                        let label1 = t("common:formats.preselection-type-unique");
                        let options1 = { editable : false, multiselect : false };

                        copyItem1.label = label1;
                        copyItem1.options = options1;
                        copyItem1.selectedFormat = copyItem1.format+'-11';
                        copyItem1.position = 11;
                        finalFields.push(copyItem1);

                        let copyItem2 = {...item};

                        let label2 = t("common:formats.preselection-type-unique-and-free");
                        let options2 = { editable : true, multiselect : false };

                        copyItem2.label = label2;
                        copyItem2.options = options2;
                        copyItem2.selectedFormat = copyItem2.format+'-12';
                        copyItem2.position = 12;
                        finalFields.push(copyItem2);

                        let copyItem3 = {...item};

                        let label3 = t("common:formats.preselection-type-multiple");
                        let options3 = { editable : false, multiselect : true };

                        copyItem3.label = label3;
                        copyItem3.options = options3;
                        copyItem3.selectedFormat = copyItem3.format+'-13';
                        copyItem3.position = 13;
                        finalFields.push(copyItem3);

                        let copyItem4 = {...item};
                        let label4 = t("common:formats.preselection-type-multiple-and-free");
                        let options4 = { editable : true, multiselect : true };

                        copyItem4.label = label4;
                        copyItem4.options = options4;
                        copyItem4.selectedFormat = copyItem4.format+'-14';
                        copyItem4.position = 14;
                        finalFields.push(copyItem4);

                        break;
                    case 'table':

                        let copyTItem1 = {...item};
                        let labelT1 = t("common:formats.preselection-table-type-unique");
                        let optionsT1 = { editable : false, multiselect : false };

                        copyTItem1.label = labelT1;
                        copyTItem1.options = optionsT1;
                        copyTItem1.selectedFormat = copyTItem1.format+'-23';
                        copyTItem1.position = 23;

                        finalFields.push(copyTItem1);

                        let copyTItem2 = {...item};

                        let labelT2 = t("common:formats.preselection-table-type-unique-and-free");
                        let optionsT2 = { editable : true, multiselect : false };

                        copyTItem2.label = labelT2;
                        copyTItem2.options = optionsT2;
                        copyTItem2.selectedFormat = copyTItem2.format+'-24';
                        copyTItem2.position = 24;

                        finalFields.push(copyTItem2);

                        let copyTItem3 = {...item};

                        let labelT3 = t("common:formats.preselection-table-type-multiple");
                        let optionsT3 = { editable : false, multiselect : true };

                        copyTItem3.label = labelT3;
                        copyTItem3.options = optionsT3;
                        copyTItem3.selectedFormat = copyTItem3.format+'-25';
                        copyTItem3.position = 25;

                        finalFields.push(copyTItem3);

                        let copyTItem4 = {...item};
                        let labelT4 = t("common:formats.preselection-table-type-multiple-and-free");
                        let optionsT4 = { editable : true, multiselect : true };

                        copyTItem4.label = labelT4;
                        copyTItem4.options = optionsT4;
                        copyTItem4.selectedFormat = copyTItem4.format+'-26';
                        copyTItem4.position = 26;
                        finalFields.push(copyTItem4);
                        break;
                    default:
                        break;
                }
            }
        })

        return finalFields.sort(function(a, b){return a.position - b.position});

    }

    const apiRef = useGridApiRef();

    const [openMemo, setOpenMemo] = useState(false);
    const [openAgenda, setOpenAgenda] = useState(false);
    const [openLocalis, setOpenLocalis] = useState(false);
    const [openPassword, setOpenPassword] = useState(false);
    const [openTime, setOpenTime] = useState(false);
    const [openList, setOpenList] = useState(false);
    const [openBirthday, setOpenBirthday] = useState(false);
    const [openQrcode, setOpenQrcode] = useState(false);
    const [openMultipleTable, setOpenMultipleTable] = useState(false);
    const [openSimpleTable, setOpenSimpleTable] = useState(false);
    const [openPlugform, setOpenPlugform] = useState(false);
    const [modelOriginal, setModelOriginal] = useState(props.model);
    const [modelCopy, setModelCopy] = useState(props.model);
    const [fieldFormats, setFieldsFormat] = useState(generateCorrectFieldsInfos());
    const [infoCurrentModel, setInfoCurrentModel] = useState(props.model);
    const [infos, setInfos] = useState(null);

    const environment = getCurrentEnvironnement();

    const initialRows = [];
    let initColumns = [];
    let initCopyColums = [];

    const viewAction = (object) => {
        return (
            <div onClick={(event) => {
                handleSelectPlugnote(object.row.plugnote);
            }} style={{ cursor: "pointer", width : "100%" }}>
                <span className="material-icons md-small">
                    zoom_out_map
                </span>
            </div>
        )
    };

    const headerView = (object) => {
        return (
            <div className="flex_display_direction_column" >
                <span className="material-icons md-small">
                        zoom_out_map
                </span>
                <div>
                    <span>&nbsp;&nbsp;</span>
                </div>
            </div>
        )
    };

    /*    const headerView = (object) => {
            return (
                <DraggableHeaderRenderer onColumnsReorder={handleColumnsReorder} column={object.column}>
                    <div
                        style={{
                            color : '#58C3C1'
                        }}
                        className="flex_display_direction_column"
                    >
                        <span className="material-icons md-25 md-small " onClick={(event) => handleRemoveColumnVisibility(event, object.column.item)}>
                            {selectedFields.includes(object.column.item) ? 'check_box' : 'check_box_outline_blank'}
                        </span>
                        <span>{object.column.name}</span>
                    </div>
                </DraggableHeaderRenderer>
            );
        }*/

    const handleRemoveColumnVisibility = (event, indice) => {
        event.preventDefault();
        event.stopPropagation();
        setSelectedFields(selectedFields.filter((e)=>(e !== indice)))
    }

    const handleActiveFilterSearch = (event, item) => {
        event.preventDefault();
        event.stopPropagation();
        setSubOpen(true);

        let result = fields.find((field, indexField) => field.id === item);

        if(typeof result !== "undefined"){
            setFilters(oldArray => [...oldArray, {position : oldArray.length + 1, field : result, value : null, query : null}])
        }
    }

    const handleUnActiveFilterSearch = (event, item, findIndex, result) => {

        event.preventDefault();
        event.stopPropagation();
        setFilters(filters.filter((item, index) => {
            return findIndex !== index;
        }));

        attemptDelDataFilter(result.field.key);
    };

    const handleUnActiveAllFiltersSearch = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setSubOpen(false);
        setFilters([]);
        attemptDelAllDataFilter();
    };

    const headerIdView = (object) => {
        return (
            <DraggableHeaderRenderer onColumnsReorder={handleColumnsReorder} column={object.column}>
                <div
                    style={{
                        color : '#58C3C1'
                    }}
                    className="flex_display_direction_column"
                >
                    <span className="material-icons md-25 md-small " onClick={(event) => handleRemoveColumnVisibility(event, object.column.item)}>
                        {selectedFields.includes(object.column.item) ? 'check_box' : 'check_box_outline_blank'}
                    </span>
                    <span>
                        {object.column.name}
                    </span>
                </div>
            </DraggableHeaderRenderer>
        );
    }

    const oldHeaderTitleView = (object) => {

        let item = object.colDef.item;

        let result = filters.find((element, indexField) => {
            if(typeof element.field !== "undefined" && element.field !== null){
                return  element.field.id === item
            }
        });

        let findIndex = filters.findIndex((element, indexField) => {
            if(typeof element.field !== "undefined" && element.field !== null){
                return  element.field.id === item
            }
        });

        let template = getTemplateFormatText(props.model['field'+item+'format'],props.model['field'+item+'formatoptions']);

        let span =  ' ( ' + item + ' - ' + template +' )';
        return (
            <DraggableHeaderRenderer onColumnsReorder={handleColumnsReorder} column={object.column}>
                <div className="flex_display_direction_column">
                    <span className="material-icons md-25 md-small " onClick={(event) => handleRemoveColumnVisibility(event, item)}>
                            {selectedFields.includes(item) ? 'check_box' : 'check_box_outline_blank'}
                    </span>

                    {typeof result === 'undefined' ?
                        <span className="material-icons md-25 md-small pluglist-search-button" onClick={(event) => handleActiveFilterSearch(event, item)}>
                            search
                        </span>
                        :
                        <span className="material-icons md-25 md-small pluglist-search-button" onClick={(event) => handleUnActiveFilterSearch(event, item, findIndex, result)}>
                            close
                        </span>
                    }

                    <span>{object.colDef.name}</span>
                    <span style={{fontSize : '10px'}}>{span}</span>
                </div>
            </DraggableHeaderRenderer>
        );
    }

    const headerTitleView = (object) => {
        let item = object.colDef.item;
        let editable = object.colDef.editable;
        let deleted = typeof object.colDef.deleted !== "undefined" ? object.colDef.deleted : false;
        let complex = typeof object.colDef.complex !== "undefined" ? true : false;

        let result = filters.find((element, indexField) => {
            if(typeof element.field !== "undefined" && element.field !== null){
                return  element.field.id === item
            }
        });

        let findIndex = filters.findIndex((element, indexField) => {
            if(typeof element.field !== "undefined" && element.field !== null){
                return  element.field.id === item
            }
        });

        let template = getTemplateFormatText(props.model['field'+item+'format'],props.model['field'+item+'formatoptions']);

        let leftSpan =  ' ( ' + item + ' )';
        let rightSpan =  '( ' + template +' )';
        return (
            <div className="flex_display_direction_column" style={{width : "100%", textAlign : "center"}}>
                <CustomSelect key={item} item={item} index={item} fields={fieldFormats} modelCopy={modelCopy} handleChanteTypeFormatAndValidate={handleChanteTypeFormatAndValidate}/>
                <span className="bold">{object.colDef.headerNameCustom}</span>
                <div className="text_align" style={{width : "100%"}}>
                    { complex ?
                        <div className="flex_display_direction_row_center" style={{width : "100%"}}>
                            <div className="headerPlugLlist4"><span style={{fontSize : '10px'}}>{leftSpan} </span></div>
                            <div className="headerPlugLlist4">
                                <Tooltip  title={t('common:helper.edit-possible-via-modal')} aria-label="add" placement="top-start">
                                    <span className={`material-icons md-15 orange`}> create </span>
                                </Tooltip>
                            </div>
                            <div className="headerPlugLlist4">
                                <span style={{fontSize : '10px'}}>{rightSpan}</span>
                            </div>
                            <div className="headerPlugLlist4">
                                <span className="p4 cursor" onClick={(event) => deleted === false ?  handleDeleteTypeFormatAndValidate(event,item) : handleActiveTypeFormatAndValidate(event,item)}>
                                    <span className="material-icons md-20 black" >{deleted === false ? 'delete' : 'add'}</span>
                                </span>
                            </div>
                        </div>
                        :
                        <div className="flex_display_direction_row" style={{width : "100%"}}>
                            <div className="headerPlugLlist4"><span style={{fontSize : '10px'}}>{leftSpan} </span></div>
                            <div className="headerPlugLlist4">
                                <Tooltip  title={editable ? t('common:helper.edit-possible') : t('common:helper.edit-not-possible')} aria-label="add" placement="top-start">
                                    <span className={`material-icons md-15 ${editable ? 'green' : 'red'}`}> create </span>
                                </Tooltip>
                            </div>
                            <div className="headerPlugLlist4">
                                <span style={{fontSize : '10px'}}>{rightSpan}</span>
                            </div>
                            <div className="headerPlugLlist4">
                                <span className="p4 cursor" onClick={(event) => deleted === false ?  handleDeleteTypeFormatAndValidate(event,item) : handleActiveTypeFormatAndValidate(event,item)}>
                                    <span className="material-icons md-20 black" >{deleted === false ? 'delete' : 'add'}</span>
                                </span>
                            </div>
                        </div>
                    }
                </div>
            </div>
        );
    }

    const headerSpecialTitleView = (object) => {

        return (
            <div className="flex_display_direction_column" style={{width : "100%", textAlign : "center"}}>
                <span className="green bold">{object.colDef.headerName}</span>
                <div className="text_align" style={{width : "100%"}}>
                    <div className="flex_display_direction_row" style={{width : "100%"}}>
                        <div className="headerPlugLlist"><span style={{fontSize : '10px'}}>&nbsp;</span></div>
                        <div className="headerPlugLlist"><span className={`material-icons md-15`}> zoom_out_map </span></div>
                        <div className="headerPlugLlist"><span style={{fontSize : '10px'}}>&nbsp;</span></div>
                    </div>
                </div>
            </div>
        );
    }

    const headerSpecialQrCodeTitleView = (object) => {

        return (
            <div className="flex_display_direction_column" style={{width : "100%", textAlign : "center"}}>
                <span className="green bold">{object.colDef.headerName}</span>
                <div className="text_align" style={{width : "100%"}}>
                    <div className="flex_display_direction_row" style={{width : "100%"}}>
                        <div className="headerPlugLlist"><span style={{fontSize : '10px'}}>&nbsp;</span></div>
                        <div className="headerPlugLlist"><span className={`material-icons md-15`}> qr_code </span></div>
                        <div className="headerPlugLlist"><span style={{fontSize : '10px'}}>&nbsp;</span></div>
                    </div>
                </div>
            </div>
        );
    }

    const headerActionsTitleView = (object) => {

        return (
            <div className="flex_display_direction_column" style={{width : "100%", textAlign : "center"}}>
                <span className="">{object.colDef.headerName}</span>
                <div>
                    <span>&nbsp;&nbsp;</span>
                </div>
            </div>
        );
    }


    const headerSpecialTitleViewAnnexe = (object) => {
        return (
            <div className="flex_display_direction_column cursor" style={{width : "100%", textAlign : "center"}} onClick={(event) => {onRowClick(object)}}>
                <span className={`material-icons md-small green bold fa-rotate-90`}>
                    attachment
                </span>
                <div className="text_align" style={{width : "100%"}}>
                    <div className="flex_display_direction_row" style={{width : "100%"}}>
                        <div className="headerPlugLlist"><span style={{fontSize : '10px'}}>&nbsp;</span></div>
                        <div className="headerPlugLlist"><span className={`material-icons md-15`}> zoom_out_map </span></div>
                        <div className="headerPlugLlist"><span style={{fontSize : '10px'}}>&nbsp;</span></div>
                    </div>
                </div>
            </div>
        )
    }

    const onRowClick = (object) => {
        handleSelectPlugnote(object.row.plugnote);
    }

    const onRowPlugcodeClick = (object) => {
        handleClickQrCode(object.row.plugnote)
    }

    const specialAction = (object) => {
        switch (object.colDef.format) {
            case 'tablemulti':
                return (
                    <div>
                        <span>
                            {
                                isJsonStringObjectAndCount(object.value).toString() + " " + t('common:global.total')
                            }
                        </span>
                    </div>
                );
            case 'document':
            case 'tablemultin':
                return (
                    <div>
                        <span>
                            {isJsonStringObjectAndGetElement(object?.value, 'value').toString() !== "" &&
                            <span>{isJsonStringObjectAndGetElement(object?.value, 'value').toString()}  <span> - </span> </span>
                            }
                            {
                                isJsonStringObjectAndCountSubElement(object?.value, 'list').toString() + " " + t('common:global.total')
                            }
                        </span>
                    </div>
                );
                case 'smailvalidmulti':
                return (
                    <div>
                        <span>
                            {isJsonStringObjectAndGetElement(object?.value, 'value').toString() !== "" &&
                            <span>{isJsonStringObjectAndGetElement(object?.value, 'value').toString()}  <span> - </span> </span>
                            }
                            {
                                isJsonStringObjectAndCountSubElement(object?.value, 'list').toString() + " " + t('common:global.total')
                            }
                        </span>
                    </div>
                );
            case 'phone':
                return (
                    <div>
                        <span>
                            <a href={`tel:${object.value}`}>{object.value}</a>
                        </span>
                    </div>
                );

            case 'mail':
                return (
                    <div>
                        <span>
                            <a href={`mailto:${object.value}`}>{object.value}</a>
                        </span>
                    </div>
                );

            case 'url':
                return (
                    <div>
                        <span>
                            <a href={object.value} target="_blank">{object.value}</a>
                        </span>
                    </div>
                );


            default :

                if(specialFields.includes(object.colDef.format)){
                    return(
                        <div>
                            <span>
                                {object.value}
                            </span>
                        </div>
                    )
                }
                else{
                    switch (object.field) {
                        case "annexe":
                            return (
                                <div onClick={(event) => {onRowClick(object)}}  style={{width : "100%", textAlign : "center", cursor: "pointer"}}>
                                    { object.value === 0 ?
                                        <span>
                                        &nbsp;
                                    </span>
                                        :
                                        <span>
                                        {object.value}
                                    </span>
                                    }
                                </div>
                            )
                            break;
                        default:
                            return (
                                <div onClick={(event) => {onRowClick(object)}}  style={{width : "100%", textAlign : "center", cursor: "pointer"}} >
                                    { typeof object.value !== "undefined" && object.value.length > 0 ?
                                        <span>
                                {object.value}
                            </span>
                                        :
                                        <span>
                            &nbsp;
                            </span>
                                    }
                                </div>
                            )
                            break;
                    }
                }
        }
    };

    const dateAction = (object) => {
        return(
            <div className="cursor" onClick={(event) => {onRowClick(object)}} style={{ cursor: "pointer", width : "100%" }}>
                            <span>
                                {object.value}
                            </span>
            </div>
        )
    };

    const handleClickQrCode = (object) => {
        setPlugnote(object);
        handleModalPlugnotePlugcodeOpen(object);
    };

    const allActions = (object) => {

        return (
            <div className="display_flex float_right grey">
                <PopupState variant="popover" popupId={object.row.plugnote.hash}>
                    {(popupState) => (
                        <div>
                            <span aria-describedby={object.row.plugnote.hash} className="material-icons md-medium" {...bindTrigger(popupState)}>
                                more_horiz
                            </span>
                            <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Box p={2}>
                                    <div className="flex_display_direction_column">
                                        <span className="p3 cursor"  onClick={(event) => handleFavorisPlugnote(object.row.plugnote)}>
                                            <span className={"material-icons md-25 padding_0_10 " + (object.row.plugnote.favorite ? 'green' : 'black')}>star</span>
                                            {t('common:plugnote.favorite')}
                                        </span>
                                        <span className="p3 cursor"  onClick={(event) => handleArchivePlugnote(object.row.plugnote)}>
                                            {
                                                object.row.plugnote.isarchived ?
                                                    <span id="icon-archive" className="material-icons md-25 padding_0_10">
                                                        archive
                                                    </span>
                                                    :
                                                    <span id="icon-archive" className="material-icons md-25 padding_0_10">
                                                        unarchive
                                                    </span>
                                            }
                                            { object.row.plugnote.isarchived ? t('common:plugnote.unarchive') : t('common:plugnote.archive')}
                                        </span>
                                        <span className="p3 cursor"  onClick={(event) => handleLockPlugnote(object.row.plugnote)}>
                                            {
                                                object.row.plugnote.locked === true ?
                                                    <span id="icon-verouiller" className="material-icons md-25 black padding_0_10">
                                                        lock
                                                    </span>
                                                    :
                                                    <span id="icon-verouiller" className="material-icons md-25 black padding_0_10">
                                                        lock_open
                                                    </span>
                                            }
                                            { object.row.plugnote.locked ? t('common:plugnote.unlock') : t('common:plugnote.lock')}
                                        </span>
                                        <span className="p3 cursor" onClick={(event) => {handleClickQrCode(object.row.plugnote)}}>
                                            <span className="material-icons md-25 black padding_0_10" >qr_code</span>
                                            {t('common:plugnote.manage-plugcode')}
                                        </span>
                                        <span className="p3 cursor" onClick={(event) => {handleModalPlugnoteClassifyOpen(object.row.plugnote)}}>
                                            <span className="material-icons md-25 black padding_0_10" >sync_alt</span>
                                            {t('common:plugnote.move-plugnote')}
                                        </span>
                                        <span className="p3 cursor" onClick={(event) => handleDeletePlugnote(object.row.plugnote)}>
                                            <span className="material-icons md-25 black padding_0_10" >delete</span>
                                            {t('common:plugnote.delete')}
                                        </span>
                                    </div>
                                </Box>
                            </Popover>
                        </div>
                    )}
                </PopupState>
            </div>
        )
    }

    const plugcodeAction = (object) => {

        return (
            <div onClick={(event) => {onRowPlugcodeClick(object)}} style={{ cursor: "pointer", width : "100%" }}>
                {
                    object.value.length > 0 ?

                        <span>
                            {object.value}
                        </span>
                        :
                        <span>
                    &nbsp;
                    </span>
                }
            </div>
        )
    }

    const selectAction = (object) => {

        let plugnote = object.row;

        if(plugnote.isarchived){
            return null;
        }else{
            return (
                <SelectCellFormatter
                    aria-label="Select"
                    tabIndex={-1}
                    isCellSelected={object.isCellSelected}
                    value={object.isRowSelected}
                    //onClick={event.stopPropagation}
                    onChange={object.onRowSelectionChange}
                />
            );
        }
    }

    let preSizeFields = [];
    let checkExistingSizeFields = JSON.parse(localStorage.getItem('sizeFields'));

    if(checkExistingSizeFields){
        let findIndex = checkExistingSizeFields.findIndex(item  => item.hash === props.model.hash);
        if(findIndex !== -1){
            preSizeFields =checkExistingSizeFields[findIndex].fields;
        }
    }

    if(plugnotes.length > 0 && modelOriginal){

        // columns.push({ key: 'view', name:  '', resizable: true, sortable : false , width: 25, formatter : viewAction });
        initColumns.push({ field: 'view',  key: 'view', headerName:  ' ', resizable: false, sortable : false, order : false, renderHeader: headerView, renderCell : viewAction, width: 65, align : 'center'});

        selectedFields.map((item, index) => {
            let find = preSizeFields.findIndex(elm => elm.field === item);
            let size = find !== -1 ? preSizeFields[find].width : 150;
            if(item === 0){
                size = find !== -1 ? preSizeFields[find].width : 100;
            }else if(item === 20){
                size = find !== -1 ? preSizeFields[find].width : 90;
            }
            if(item !== 0 && item !== 16 && item !== 17 && item !== 18 && item !== 19 && item !== 20 ){
                if(modelOriginal['field'+item+'label'] !== null){
                    let template = getTemplateFormatText(modelOriginal['field'+item+'format'],modelOriginal['field'+item+'formatoptions']);
                    switch (modelOriginal['field'+item+'format']) {
                        case 'textarea':
                        case "agenda":
                        case "localis":
                        case "password":
                        case "time":
                        case "list":
                        case "birthday":
                        case "qrcode":
                            initColumns.push({
                                field: 'field' + item,
                                key: 'field' + item,
                                headerName:  modelOriginal['field'+item+'label'] + ' ('+ item +')' + ' ' + '('+  template +')',
                                headerNameCustom:  modelOriginal['field'+item+'label'],
                                resizable: true,
                                sortable : true,
                                item : item,
                                renderHeader: headerTitleView,
                                renderCell : specialAction,
                                headerAlign: 'left',
                                format : modelOriginal['field'+item+'format'],
                                hide : false,
                                special : false,
                                width: size,
                                editable: specialFields.includes(modelOriginal['field'+item+'format']),
                                complex : true,
                                compatible : isCompatibleSpecial(modelCopy['field'+item+'format'], modelOriginal['field'+item+'format']),
                                deleted : typeof modelOriginal['field'+item+'deleted'] !== undefined ? modelOriginal['field'+item+'deleted'] : false,
                                cellClassName: (params) =>
                                    clsx('super-app', {
                                        negative: isCompatibleSpecial(modelCopy['field'+item+'format'], modelOriginal['field'+item+'format']) === false,
                                        deleted : typeof modelOriginal['field'+item+'deleted'] !== undefined && modelOriginal['field'+item+'deleted'] === true
                                    })
                            });
                            break;
                        /*
                                                case "table":
                                                    initColumns.push({
                                                        field: 'field' + item,
                                                        key: 'field' + item,
                                                        headerName:  modelOriginal['field'+item+'label'] + ' ('+ item +')' + ' ' + '('+  template +')',
                                                        headerNameCustom:  modelOriginal['field'+item+'label'],
                                                        resizable: true,
                                                        sortable : true,
                                                        item : item,
                                                        renderHeader: headerTitleView,
                                                        renderCell : specialAction,
                                                        headerAlign: 'left',
                                                        format : modelOriginal['field'+item+'format'],
                                                        hide : false,
                                                        special : false,
                                                        width: size,
                                                        editable: specialFields.includes(modelOriginal['field'+item+'format']),
                                                        complex : true,
                                                    });
                                                    break;
                        */
                        case 'phone':
                            initColumns.push({
                                field: 'field' + item,
                                key: 'field' + item,
                                headerName:  modelOriginal['field'+item+'label'] + ' ('+ item +')' + ' ' + '('+  template +')',
                                headerNameCustom:  modelOriginal['field'+item+'label'],
                                resizable: true,
                                hide : false,
                                sortable : true,
                                item : item,
                                renderHeader: headerTitleView,
                                renderCell : specialAction,
                                renderEditCell: renderPhoneEditInputCell,
                                format : modelOriginal['field'+item+'format'],
                                special : false,
                                headerAlign: 'left',
                                width: size,
                                editable: specialFields.includes(modelOriginal['field'+item+'format']),
                                compatible : isCompatibleSpecial(modelCopy['field'+item+'format'], modelOriginal['field'+item+'format']),
                                deleted : typeof modelOriginal['field'+item+'deleted'] !== undefined ? modelOriginal['field'+item+'deleted'] : false,
                                cellClassName: (params) =>
                                    clsx('super-app', {
                                        negative: isCompatibleSpecial(modelCopy['field'+item+'format'], modelOriginal['field'+item+'format']) === false,
                                        deleted : typeof modelOriginal['field'+item+'deleted'] !== undefined && modelOriginal['field'+item+'deleted'] === true
                                    })
                                });

                            break;
                        case 'numeric':
                            initColumns.push({
                                field: 'field' + item,
                                key: 'field' + item,
                                headerName:  modelOriginal['field'+item+'label'] + ' ('+ item +')' + ' ' + '('+  template +')',
                                headerNameCustom:  modelOriginal['field'+item+'label'],
                                resizable: true,
                                sortable : true,
                                item : item,
                                type : 'number',
                                hide : false,
                                renderHeader: headerTitleView,
                                renderCell : specialAction,
                                headerAlign: 'left',
                                format : modelOriginal['field'+item+'format'],
                                special : false,
                                width: size,
                                editable: specialFields.includes(modelOriginal['field'+item+'format']),
                                compatible : isCompatibleSpecial(modelCopy['field'+item+'format'], modelOriginal['field'+item+'format']),
                                deleted : typeof modelOriginal['field'+item+'deleted'] !== undefined ? modelOriginal['field'+item+'deleted'] : false,
                                cellClassName: (params) =>
                                    clsx('super-app', {
                                        negative: isCompatibleSpecial(modelCopy['field'+item+'format'], modelOriginal['field'+item+'format']) === false,
                                        deleted : typeof modelOriginal['field'+item+'deleted'] !== undefined && modelOriginal['field'+item+'deleted'] === true
                                    })
                            });
                            break;
                        case 'date':
                            initColumns.push({
                                field: 'field' + item,
                                key: 'field' + item,
                                headerName:  modelOriginal['field'+item+'label'] + ' ('+ item +')' + ' ' + '('+  template +')',
                                headerNameCustom:  modelOriginal['field'+item+'label'],
                                resizable: true,
                                sortable : true,
                                item : item,
                                type : 'date',
                                renderHeader: headerTitleView,
                                renderCell : specialAction,
                                hide : false,
                                headerAlign: 'left',
                                //renderEditCell: renderDateEditInputCell,
                                format : modelOriginal['field'+item+'format'],
                                special : false,
                                width: size,
                                editable: specialFields.includes(modelOriginal['field'+item+'format']),
                                compatible : isCompatibleSpecial(modelCopy['field'+item+'format'], modelOriginal['field'+item+'format']),
                                deleted : typeof modelOriginal['field'+item+'deleted'] !== undefined ? modelOriginal['field'+item+'deleted'] : false,
                                cellClassName: (params) =>
                                    clsx('super-app', {
                                        negative: isCompatibleSpecial(modelCopy['field'+item+'format'], modelOriginal['field'+item+'format']) === false,
                                        deleted : typeof modelOriginal['field'+item+'deleted'] !== undefined && modelOriginal['field'+item+'deleted'] === true
                                    })
                            });
                            break;
                        case 'boolean':
                            initColumns.push({
                                field: 'field' + item,
                                key: 'field' + item,
                                headerName:  modelOriginal['field'+item+'label'] + ' ('+ item +')' + ' ' + '('+  template +')',
                                headerNameCustom:  modelOriginal['field'+item+'label'],
                                resizable: true,
                                sortable : true,
                                item : item,
                                type : 'boolean',
                                renderHeader: headerTitleView,
                                format : modelOriginal['field'+item+'format'],
                                special : false,
                                headerAlign: 'left',
                                hide : false,
                                width: size,
                                valueFormatter: ({ value }) => value === '1',
                                editable: specialFields.includes(modelOriginal['field'+item+'format']),
                                compatible : isCompatibleSpecial(modelCopy['field'+item+'format'], modelOriginal['field'+item+'format']),
                                deleted : typeof modelOriginal['field'+item+'deleted'] !== undefined ? modelOriginal['field'+item+'deleted'] : false,
                                cellClassName: (params) =>
                                    clsx('super-app', {
                                        negative: isCompatibleSpecial(modelCopy['field'+item+'format'], modelOriginal['field'+item+'format']) === false,
                                        deleted : typeof modelOriginal['field'+item+'deleted'] !== undefined && modelOriginal['field'+item+'deleted'] === true
                                    })
                            });
                            break;
                        case 'select':
                            initColumns.push({
                                field: 'field' + item,
                                key: 'field' + item,
                                headerName:  modelOriginal['field'+item+'label'] + ' ('+ item +')' + ' ' + '('+  template +')',
                                headerNameCustom:  modelOriginal['field'+item+'label'],
                                resizable: true,
                                sortable : true,
                                item : item,
                                renderHeader: headerTitleView,
                                format : modelOriginal['field'+item+'format'],
                                special : false,
                                headerAlign: 'left',
                                hide : false,
                                width: size,
                                renderEditCell: renderSelectEditInputCell,
                                editable: specialFields.includes(modelOriginal['field'+item+'format']),
                                options : modelOriginal['field'+item+'formatoptions'],
                                compatible : isCompatibleSpecial(modelCopy['field'+item+'format'], modelOriginal['field'+item+'format']),
                                deleted : typeof modelOriginal['field'+item+'deleted'] !== undefined ? modelOriginal['field'+item+'deleted'] : false,
                                cellClassName: (params) =>
                                    clsx('super-app', {
                                        negative: isCompatibleSpecial(modelCopy['field'+item+'format'], modelOriginal['field'+item+'format']) === false,
                                        deleted : typeof modelOriginal['field'+item+'deleted'] !== undefined && modelOriginal['field'+item+'deleted'] === true
                                    })
                            });
                            break;
                        default:
                            initColumns.push({
                                field: 'field' + item,
                                key: 'field' + item,
                                headerName:  modelOriginal['field'+item+'label'] + ' ('+ item +')' + ' ' + '('+  template +')',
                                headerNameCustom:  modelOriginal['field'+item+'label'],
                                resizable: true,
                                sortable : true,
                                item : item,
                                renderHeader: headerTitleView,
                                renderCell : specialAction,
                                headerAlign: 'left',
                                format : modelOriginal['field'+item+'format'],
                                hide : false,
                                special : false,
                                width: size,
                                editable: specialFields.includes(modelOriginal['field'+item+'format']),
                                compatible : isCompatibleSpecial(modelCopy['field'+item+'format'], modelOriginal['field'+item+'format']),
                                deleted : typeof modelOriginal['field'+item+'deleted'] !== undefined ? modelOriginal['field'+item+'deleted'] : false,
                                cellClassName: (params) =>
                                    clsx('super-app', {
                                        negative: isCompatibleSpecial(modelCopy['field'+item+'format'], modelOriginal['field'+item+'format']) === false,
                                        deleted : typeof modelOriginal['field'+item+'deleted'] !== undefined && modelOriginal['field'+item+'deleted'] === true
                                    })
                            });
                            break;

                    }
                    initCopyColums.push({field: 'view',  key: 'view', headerName:  modelOriginal['field'+item+'label'] + ' ('+ item +')' + ' ' + '('+  template +')', format : modelOriginal['field'+item+'format'], size :  size});
                }
            }
            else{

                if(item === 0){
                    initColumns.push({
                        field: 'userindex',
                        key: 'userindex',
                        headerName: "N°",
                        resizable: true,
                        sortable : true,
                        item : 0,
                        headerAlign: 'left',
                        renderHeader: headerSpecialTitleView,
                        renderCell : specialAction,
                        align : 'center',
                        special : true,
                        hide: false,
                        width: size,
                    });
                }

                if(item === 20){
                    initColumns.push({
                        field: 'annexe',
                        key: 'annexe',
                        headerName: "Nbr d'Annexes",
                        resizable: true,
                        sortable : true,
                        item : 20,
                        headerAlign: 'left',
                        hide: false,
                        renderHeader: headerSpecialTitleViewAnnexe,
                        align : 'center',
                        renderCell : specialAction,
                        special : true,
                        width: size,
                    });
                }

                if(item === 16){
                    initColumns.push({
                        field: 'plugcode',
                        key: 'plugcode',
                        headerName: "PN- Code",
                        resizable: true,
                        sortable : true,
                        item : 16,
                        headerAlign: 'left',
                        renderHeader: headerSpecialQrCodeTitleView,
                        hide: false,
                        renderCell : plugcodeAction,
                        special : true,
                        width: size,
                    });
                }

                if(item === 17){
                    initColumns.push({
                        field: 'tiers',
                        key: 'tiers',
                        headerName: "Created by",
                        resizable: true,
                        sortable : true,
                        item : 17,
                        headerAlign: 'left',
                        renderHeader: headerSpecialTitleView,
                        hide: false,
                        renderCell : specialAction,
                        special : true,
                        width: size,
                    });
                }

                if(item === 18){
                    initColumns.push({
                        field: 'creation',
                        key: 'creation',
                        headerName: "Créé le",
                        resizable: true,
                        sortable : true,
                        item :18,
                        hide: false,
                        headerAlign: 'left',
                        renderHeader: headerSpecialTitleView,
                        renderCell : dateAction,
                        align : 'center',
                        special : true,
                        width: size,
                    });
                }

                if(item === 19){
                    initColumns.push({
                        field: 'modification',
                        key: 'modification',
                        headerName: "Modifié le",
                        resizable: true,
                        sortable : true,
                        headerAlign: 'left',
                        item : 19,
                        hide: false,
                        renderHeader: headerSpecialTitleView,
                        renderCell : dateAction,
                        special : true,
                        align : 'center',
                        width: size,
                    });
                }

                initCopyColums.push({field: 'view',  key: 'view', headerName:  '', format : '', size :  size});
            }
        })

        defaultSelectedFields.map((item, index) => {
            let find = preSizeFields.findIndex(elm => elm.field === item);
            let size = find !== -1 ? preSizeFields[find].width : 150;
            if(item === 0){
                size = find !== -1 ? preSizeFields[find].width : 100;
            }else if(item === 20){
                size = find !== -1 ? preSizeFields[find].width : 90;
            }
            if(!selectedFields.includes(item)){
                if(item !== 0 && item !== 16 && item !== 17 && item !== 18 && item !== 19 && item !== 20 ){
                    let template = getTemplateFormatText(modelOriginal['field'+item+'format'],modelOriginal['field'+item+'formatoptions']);
                    if(modelOriginal['field'+item+'label'] !== null){
                        switch (modelOriginal['field'+item+'format']) {
                            case 'textarea':
                            case "agenda":
                            case "localis":
                            case "password":
                            case "time":
                            case "list":
                            case "birthday":
                            case "qrcode":
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  modelOriginal['field'+item+'label'] + ' ('+ item +')' + ' ' + '('+  template +')',
                                    headerNameCustom:  modelOriginal['field'+item+'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    headerAlign: 'left',
                                    format : modelOriginal['field'+item+'format'],
                                    hide : false,
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(modelOriginal['field'+item+'format']),
                                    complex : true,
                                    compatible : isCompatibleSpecial(modelCopy['field'+item+'format'], modelOriginal['field'+item+'format']),
                                    deleted : typeof modelOriginal['field'+item+'deleted'] !== undefined ? modelOriginal['field'+item+'deleted'] : false,
                                    cellClassName: (params) =>
                                        clsx('super-app', {
                                            negative: isCompatibleSpecial(modelCopy['field'+item+'format'], modelOriginal['field'+item+'format']) === false,
                                            deleted : typeof modelOriginal['field'+item+'deleted'] !== undefined && modelOriginal['field'+item+'deleted'] === true
                                        })
                                });
                                break;
                            case 'phone':
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  modelOriginal['field'+item+'label'] + ' ('+ item +')' + ' ' + '('+  template +')',
                                    headerNameCustom:  modelOriginal['field'+item+'label'],
                                    resizable: true,
                                    hide: true,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    renderEditCell: renderPhoneEditInputCell,
                                    format : modelOriginal['field'+item+'format'],
                                    special : false,
                                    headerAlign: 'left',
                                    width: size,
                                    editable: specialFields.includes(modelOriginal['field'+item+'format']),
                                    compatible : isCompatibleSpecial(modelCopy['field'+item+'format'], modelOriginal['field'+item+'format']),
                                    deleted : typeof modelOriginal['field'+item+'deleted'] !== undefined ? modelOriginal['field'+item+'deleted'] : false,
                                    cellClassName: (params) =>
                                        clsx('super-app', {
                                            negative: isCompatibleSpecial(modelCopy['field'+item+'format'], modelOriginal['field'+item+'format']) === false,
                                            deleted : typeof modelOriginal['field'+item+'deleted'] !== undefined && modelOriginal['field'+item+'deleted'] === true
                                        })
                                });
                                break;
                            case 'numeric':
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  modelOriginal['field'+item+'label'] + ' ('+ item +')' + ' ' + '('+  template +')',
                                    headerNameCustom:  modelOriginal['field'+item+'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    type : 'number',
                                    hide: true,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    headerAlign: 'left',
                                    format : modelOriginal['field'+item+'format'],
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(modelOriginal['field'+item+'format']),
                                    compatible : isCompatibleSpecial(modelCopy['field'+item+'format'], modelOriginal['field'+item+'format']),
                                    deleted : typeof modelOriginal['field'+item+'deleted'] !== undefined ? modelOriginal['field'+item+'deleted'] : false,
                                    cellClassName: (params) =>
                                        clsx('super-app', {
                                            negative: isCompatibleSpecial(modelCopy['field'+item+'format'], modelOriginal['field'+item+'format']) === false,
                                            deleted : typeof modelOriginal['field'+item+'deleted'] !== undefined && modelOriginal['field'+item+'deleted'] === true
                                        })
                                });
                                break;
                            case 'date':
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  modelOriginal['field'+item+'label'] + ' ('+ item +')' + ' ' + '('+  template +')',
                                    headerNameCustom:  modelOriginal['field'+item+'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    type : 'date',
                                    headerAlign: 'left',
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    hide: true,
                                    //renderEditCell: renderDateEditInputCell,
                                    format : modelOriginal['field'+item+'format'],
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(modelOriginal['field'+item+'format']),
                                    compatible : isCompatibleSpecial(modelCopy['field'+item+'format'], modelOriginal['field'+item+'format']),
                                    deleted : typeof modelOriginal['field'+item+'deleted'] !== undefined ? modelOriginal['field'+item+'deleted'] : false,
                                    cellClassName: (params) =>
                                        clsx('super-app', {
                                            negative: isCompatibleSpecial(modelCopy['field'+item+'format'], modelOriginal['field'+item+'format']) === false,
                                            deleted : typeof modelOriginal['field'+item+'deleted'] !== undefined && modelOriginal['field'+item+'deleted'] === true
                                        })
                                });
                                break;
                            case 'boolean':
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  modelOriginal['field'+item+'label'] + ' ('+ item +')' + ' ' + '('+  template +')',
                                    headerNameCustom:  modelOriginal['field'+item+'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    type : 'boolean',
                                    headerAlign: 'left',
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    format : modelOriginal['field'+item+'format'],
                                    special : false,
                                    hide: true,
                                    width: size,
                                    valueFormatter: ({ value }) => value === '1',
                                    editable: specialFields.includes(modelOriginal['field'+item+'format']),
                                    compatible : isCompatibleSpecial(modelCopy['field'+item+'format'], modelOriginal['field'+item+'format']),
                                    deleted : typeof modelOriginal['field'+item+'deleted'] !== undefined ? modelOriginal['field'+item+'deleted'] : false,
                                    cellClassName: (params) =>
                                        clsx('super-app', {
                                            negative: isCompatibleSpecial(modelCopy['field'+item+'format'], modelOriginal['field'+item+'format']) === false,
                                            deleted : typeof modelOriginal['field'+item+'deleted'] !== undefined && modelOriginal['field'+item+'deleted'] === true
                                        })
                                });
                                break;
                            default:
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  modelOriginal['field'+item+'label'] + ' ('+ item +')' + ' ' + '('+  template +')',
                                    headerNameCustom:  modelOriginal['field'+item+'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    headerAlign: 'left',
                                    format : modelOriginal['field'+item+'format'],
                                    hide: true,
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(modelOriginal['field'+item+'format']),
                                    compatible : isCompatibleSpecial(modelCopy['field'+item+'format'], modelOriginal['field'+item+'format']),
                                    deleted : typeof modelOriginal['field'+item+'deleted'] !== undefined ? modelOriginal['field'+item+'deleted'] : false,
                                    cellClassName: (params) =>
                                        clsx('super-app', {
                                            negative: isCompatibleSpecial(modelCopy['field'+item+'format'], modelOriginal['field'+item+'format']) === false,
                                            deleted : typeof modelOriginal['field'+item+'deleted'] !== undefined && modelOriginal['field'+item+'deleted'] === true
                                        })
                                });
                                break;

                        }
                    }
                }
                else{

                    if(item === 0){
                        initColumns.push({
                            field: 'userindex',
                            key: 'userindex',
                            headerName: "N°",
                            resizable: true,
                            sortable : true,
                            item : 0,
                            renderHeader: headerSpecialTitleView,
                            renderCell : specialAction,
                            special : true,
                            headerAlign: 'left',
                            align : 'center',
                            hide: true,
                            width: size,
                        });
                    }

                    if(item === 20){
                        initColumns.push({
                            field: 'annexe',
                            key: 'annexe',
                            headerName: "Nbr d'Annexes",
                            resizable: true,
                            sortable : true,
                            item : 20,
                            hide: true,
                            headerAlign: 'left',
                            renderHeader: headerSpecialTitleViewAnnexe,
                            align : 'center',
                            renderCell : specialAction,
                            special : true,
                            width: size,
                        });
                    }

                    if(item === 16){
                        initColumns.push({
                            field: 'plugcode',
                            key: 'plugcode',
                            headerName: "PN- Code",
                            resizable: true,
                            sortable : true,
                            item : 16,
                            headerAlign: 'left',
                            renderHeader: headerSpecialQrCodeTitleView,
                            hide: true,
                            renderCell : plugcodeAction,
                            special : true,
                            width: size,
                        });
                    }

                    if(item === 17){
                        initColumns.push({
                            field: 'tiers',
                            key: 'tiers',
                            headerName: "Created by",
                            resizable: true,
                            sortable : true,
                            item : 17,
                            headerAlign: 'left',
                            renderHeader: headerSpecialTitleView,
                            hide: true,
                            renderCell : specialAction,
                            special : true,
                            width: size,
                        });
                    }

                    if(item === 18){
                        initColumns.push({
                            field: 'creation',
                            key: 'creation',
                            headerName: "Créé le",
                            resizable: true,
                            sortable : true,
                            item :18,
                            headerAlign: 'left',
                            hide: true,
                            renderHeader: headerSpecialTitleView,
                            renderCell : dateAction,
                            align : 'center',
                            special : true,
                            width: size,
                        });
                    }

                    if(item === 19){
                        initColumns.push({
                            field: 'modification',
                            key: 'modification',
                            headerName: "Modifié le",
                            resizable: true,
                            sortable : true,
                            item : 19,
                            headerAlign: 'left',
                            hide: true,
                            renderHeader: headerSpecialTitleView,
                            renderCell : dateAction,
                            align : 'center',
                            special : true,
                            width: size,
                        });
                    }
                }
            }
        })


/*
        initColumns.push({ field: 'actions',  key: 'actions', headerName:  'Actions', resizable: false, sortable : false, order : false, renderHeader: headerActionsTitleView, renderCell : allActions, width: 175, align : 'center'});
*/

        plugnotes.map((plugnote, index) => {

            let finalObject = {};
            finalObject['id'] =  plugnote.id;
            finalObject.userindex = '[' + environment.envIndex + '-' + plugnote.userindex + ']';

            defaultSelectedFields.map((item, index) => {
                if(item !== 0 && item !== 16 && item !== 17 && item !== 18 && item !== 19 && item !== 20 ){
                    if(props.model['field'+item+'label'] !== null){
                        let value = plugnote['field'+item];
                        if(plugnote['field'+item+'format'] === 'select'){
                            try {
                                let values = JSON.parse(plugnote['field'+item]).sort((str1, str2) => {
                                    let num1 = parseInt(str1);
                                    let num2 = parseInt(str2);

                                    if (isNaN(num1) && isNaN(num2)) {
                                        if (str1 > str2)
                                            return 1;
                                        else if (str1 < str2)
                                            return -1;
                                        else
                                            return 0;
                                    }

                                    else if (isNaN(num1))
                                        return 1;
                                    else if (isNaN(num2))
                                        return -1;
                                    else if (num1 > num2)
                                        return 1;
                                    else if (num1 < num2)
                                        return -1;
                                    else
                                        return 0;
                                });
                                value = values.join(', ');
                            } catch (error) {
                                value = plugnote['field'+item];
                            }
                        }
                        else if(plugnote['field'+item+'format'] === 'list'){
                            let plugValue = parseJson(plugnote['field' + item]);
                            if (typeof plugValue === 'string') {
                                if (plugValue) {
                                    value = [value];
                                }
                            } else if (plugValue == null  || typeof plugValue === 'number') {
                                value = [];
                            } else {
                                value = plugValue.reduce((res, obj) => {
                                    return res.concat(obj.text || '');
                                }, []).join(', ');
                            }
                        }
                        else if(plugnote['field'+item+'format'] === 'table'){
                            let plugValue = parseJson(plugnote['field' + item]);
                            if (typeof plugValue === 'string') {
                                if (plugValue) {
                                    value = [value];
                                }
                            } else if (plugValue == null  || typeof plugValue === 'number') {
                                value = [];
                            } else {
                                value = plugValue.reduce((res, obj) => {
                                    return res.concat(obj.text + ' : ' + obj.value || '');
                                }, []).join(', ');
                            }
                        }
                        else if(plugnote['field'+item+'format'] === 'plugform'){
                            let plugValue = parseJson(plugnote['field' + item]);
                            value = typeof plugValue !== "undefined" ? plugValue.value : "";
                        }
                        else if(plugnote['field'+item+'format'] === 'qrcode'){
                            if(typeof value !== 'undefined' && value.length > 0){
                                let qrCode = "";
                                let comment = "";
                                if(value.startsWith(';')){
                                    let fieldValue  = value.substring(1);
                                    qrCode = '';
                                    comment = fieldValue;
                                }else{
                                    let fieldValue = value.split(/([^,]*);(.*)/);
                                    let fieldFilteredValue =  fieldValue.filter( a => a);
                                    qrCode = fieldFilteredValue[0];
                                    comment = fieldFilteredValue[1];
                                }

                                if(qrCode !== undefined && qrCode.length > 0){
                                    if (value.startsWith('PN') || value.startsWith('PP') || value.startsWith('PF') || value.startsWith('pn') || value.startsWith('pp') || value.startsWith('pf')){
                                        value = '(' + qrCode.toUpperCase() + ')';
                                    }
                                }

                                if(comment !== undefined && comment.length > 0){
                                    value = comment;
                                }

                                if(qrCode !== undefined && comment !== undefined && qrCode.length > 0 && comment.length > 0){
                                    value = '(' + qrCode.toUpperCase() + ')' + ' ' + comment;
                                }
                            }

                        }

                        else if (plugnote['field' + item + 'format'] === 'qrcodeint') {

                            if (typeof value !== 'undefined' && value.length > 0) {
                                let qrcodeIntValue = parseJson(plugnote['field' + item]);
                                let modelClef = qrcodeIntValue?.model || "";
                                let dataIndex = qrcodeIntValue?.data || "";
                                let comment = qrcodeIntValue?.comment || "";

                                if(comment !== ""){
                                    value = comment;
                                }else if (modelClef != "" && dataIndex != "") {
                                    value = modelClef + ' - ' + dataIndex;
                                }else {
                                    value = ""
                                }
                            }

                        }

                        else if(plugnote['field'+item+'format'] === 'password'){
                            if(typeof value !== "undefined"  && value.length > 0){
                                value = '*'.repeat(6);
                            }else{
                                value = "";
                            }
                        }
                        else if(plugnote['field'+item+'format'] === 'boolean'){
                            value = (value === "1");
                        }
                        finalObject['field' + item] = value;
                    }
                }
            })

            let sharedInfoUser = "";

            if(plugnote.shared){
                sharedInfoUser =  plugnote.username;
            }

            if(plugnote.external){
                sharedInfoUser =  plugnote.email;
            }

            finalObject['plugnote'] =  plugnote;
            finalObject['hash'] =  plugnote.hash;
            finalObject['isarchived'] =  plugnote.isarchived;
            finalObject['plugcode'] = getCodePnPpCodeTemplate(plugnote);
            finalObject['annexe'] = !!plugnote.Annexes ? plugnote.Annexes.length : 0;
            finalObject['tiers'] = sharedInfoUser;
            finalObject['creation'] = plugnote.creationdate ? moment(plugnote.creationdate).format('YY-MM-DD') : '';
            finalObject['modification'] = plugnote.moddate ? moment(plugnote.moddate).format('YY-MM-DD') : '';

            if(favoris === false){
                if(actif === true && archived === true && (plugnote.isarchived === false || plugnote.isarchived === true)){

                    initialRows.push(finalObject);
                }
                else if(actif === true && archived === false && plugnote.isarchived === false){

                    initialRows.push(finalObject);
                }
                else if(actif === false && archived === true && plugnote.isarchived === true){

                    initialRows.push(finalObject);
                }
            }
            else{
                if(actif === true && archived === true && (plugnote.isarchived === false || plugnote.isarchived === true) && plugnote.favorite === 1){

                    initialRows.push(finalObject);
                }
                else if(actif === true && archived === false && plugnote.isarchived === false  && plugnote.favorite === 1){

                    initialRows.push(finalObject);
                }
                else if(actif === false && archived === true && plugnote.isarchived === true  && plugnote.favorite === 1){

                    initialRows.push(finalObject);
                }
            }
        });
    }

    const [columns, setColumns] = useState(initColumns);
    const [rows, setRows] = useState(initialRows);
    const [page, setPage] = useState(typeof startPage === 'undefined' ? 1 : startPage);
    const [end, setEnd] = useState(typeof limitPage === 'undefined' ? 1 : limitPage);
    const [hasMore, setHasMore] = useState(startPage < limitPage);
    const [sortDirection, setSortDirection] = useState("NONE");
    const [sortColumn, setSortColumn] = useState("userindex");
    const [rowCount, setRowCount] = useState(rows.length);

    const [editRowsModel, setEditRowsModel] = useState({});
    const [loading, setLoading] = useState(false);
    const [onDrag, setOndrag] = useState(null);
    const [sourceColumnIndex, setSourceColumnIndex] = useState(null);
    const [targetColumnIndex, setTargetColumnIndex] = useState(null);

    const sortRows = (initialRows, sortColumn, sortDirection) => rows => {
        const comparer = (a, b) => {
            if (sortDirection === "ASC") {
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } else if (sortDirection === "DESC") {
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            }
        };
        return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
    };

    function handleColumnsReorder(object) {
        setSourceColumnIndex(object.oldIndex);
        setTargetColumnIndex(object.targetIndex);
        setOndrag(false);
    }

    function handleColumnsResize(object) {

        let findIndex = sizeFields.findIndex(item  => item.field === object.colDef.item);
        if(findIndex !== -1){
            sizeFields[findIndex].width = object.width;
            let checkExistingSizeFields = JSON.parse(localStorage.getItem('sizeFields'));

            if(!checkExistingSizeFields){

                if((JSON.stringify(defaultSizeFields) !== JSON.stringify(sizeFields))){
                    let fieldByModel = {
                        'hash' : props.model.hash,
                        'fields' : sizeFields,
                        'environnement' : environment.userhash,
                        'date' : moment().format('YYYY/MM/DD'),
                    };

                    setFieldsSize(fieldByModel);
                }
            }
            else{
                let findIndex = checkExistingSizeFields.findIndex(item  => item.hash === props.model.hash);
                if(findIndex !== -1){
                    if((JSON.stringify(defaultSizeFields) !== JSON.stringify(sizeFields))){
                        let fieldByModel = {
                            'hash' : props.model.hash,
                            'fields' : sizeFields,
                            'environnement' : environment.userhash,
                            'date' : moment().format('YYYY/MM/DD'),
                        };

                        setFieldsSize(fieldByModel);
                    }else{
                        delSpecifiFieldsSize(props.model.hash);
                    }
                }else{
                    if((JSON.stringify(defaultSizeFields) !== JSON.stringify(sizeFields))){
                        let fieldByModel = {
                            'hash' : props.model.hash,
                            'fields' : sizeFields,
                            'environnement' : environment.userhash,
                            'date' : moment().format('YYYY/MM/DD'),
                        };

                        setFieldsSize(fieldByModel);
                    }
                }
            }
        }

    }

    function handleColumnsVisibility (object) {
        if(typeof object.colDef.item !== "undefined" && object.isVisible === false){
            setSelectedFields(selectedFields.filter((e)=>(e !== object.colDef.item)));
        }else if(typeof object.colDef.item !== "undefined" && object.isVisible === true){
            setSelectedFields([...selectedFields, object.colDef.item]);
        }
    }

    const handleClose = (info , value, format, valueFormatted, type) => {

        switch (format) {
            case "textarea":
                setOpenMemo(false);
                break;
            case "agenda":
                setOpenAgenda(false);
                break;
            case "localis":
                setOpenLocalis(false);
                break;
            case "password":
                setOpenPassword(false);
                break;
            case "time":
                setOpenTime(false);
                break;
            case "list":
                setOpenList(false);
                break;
            case "birthday":
                setOpenBirthday(false);
                break;
            case "qrcode":
                setOpenQrcode(false);
                break;
            case "table":
                if(typeof type !== "undefined"){
                    if(type === "simple"){
                        setOpenSimpleTable(false);
                    }else if(type === "multiple"){
                        setOpenMultipleTable(false);
                    }
                }
                break;
            case "plugform":
                setOpenPlugform(false);
                break;
            default:
                break;
        }


        setInfos(null);
    };

    const handleUpdateClose = (infos , value, format, valueFormatted, type) => {
        switch (format) {
            case "textarea":
                setOpenMemo(false);
                break;
            case "agenda":
                setOpenAgenda(false);
                break;
            case "localis":
                setOpenLocalis(false);
                break;
            case "password":
                setOpenPassword(false);
                break;
            case "time":
                setOpenTime(false);
                break;
            case "list":
                setOpenList(false);
                break;
            case "birthday":
                setOpenBirthday(false);
                break;
            case "qrcode":
                setOpenQrcode(false);
                break;
            case "table":
                if(typeof type !== "undefined"){
                    if(type === "simple"){
                        setOpenSimpleTable(false);
                    }else if(type === "multiple"){
                        setOpenMultipleTable(false);
                    }
                }
                break;
            case "plugform":
                setOpenPlugform(false);
                break;
            default:
                break;
        }

        const {id, field} = infos;

        const updatedRows = rows.map((row) => {
            let fieldName = field;
            if (row.hash === id ) {


                if(typeof row.plugnote[fieldName+'format'] === "undefined" || row.plugnote[fieldName+'format']  ===  null){
                    row.plugnote[fieldName+'format'] = format;
                }

                if(row.plugnote[fieldName+'format'] === 'qrcode'){
                    row.plugnote[fieldName]  = valueFormatted;
                }else if(row.plugnote[fieldName+'format'] === 'list'){
                    row.plugnote[fieldName]  = valueFormatted;
                }
                else {
                    row.plugnote[fieldName]  = value;
                }


                if(row.plugnote[fieldName+'format'] !== 'qrcode' && row.plugnote[fieldName+'format'] !== 'list'){
                    attemptUpdatePlugnoteField(props.model.hash, row.plugnote.hash, environment.userhash, {
                        field : fieldName,
                        value : value
                    });
                }else{
                    attemptUpdatePlugnoteField(props.model.hash, row.plugnote.hash, environment.userhash, {
                        field : fieldName,
                        value : valueFormatted
                    });
                }

                if(row.plugnote[fieldName+'format'] !== 'password'){
                    return { ...row,
                        [fieldName]: value,
                        plugnote : row.plugnote
                    };
                }
                else{
                    return {
                        ...row,
                        [fieldName] : value.length > 0 ?  '*'.repeat(6) : "",
                        plugnote : row.plugnote
                    }
                }
            }
            return row;
        });
        setRows(updatedRows);
        setInfos(null);
    };

    const handleChanteTypeFormatAndValidate = (event, id) => {

        const tmpModelCopy = {...modelCopy};
        let formatOptions = null;
        let format;
        let pfcode = "";
        let code = "";
        let fields = [];

        event.preventDefault();
        event.stopPropagation();

        switch (modelOriginal[id]) {
            case 'select':
            case 'table':
            case 'plugform':
            case 'pluglink':
                try {
                    formatOptions = JSON.parse(modelOriginal[id+'options']);
                }catch (e) {
                    formatOptions = modelOriginal[id+'options'];
                }
                break;
            default:
                break;

        }

        let multiselect;
        let editable;

        switch (event.target.value) {
            case 'select-11':
                multiselect = false;
                editable = false;
                format = 'select';
                break;
            case 'select-12':
                multiselect = false;
                editable = true;
                format = 'select';
                break;
            case 'select-13':
                multiselect = true;
                editable = false;
                format = 'select';
                break;
            case 'select-14':
                multiselect = true;
                editable = true;
                format = 'select';
                break;
            case 'table-23':
                multiselect = false;
                editable = false;
                format = 'table';
                break;
            case 'table-24':
                multiselect = false;
                editable = true;
                format = 'table';
                break;
            case 'table-25':
                multiselect = true;
                editable = false;
                format = 'table';
                break;
            case 'table-26':
                multiselect = true;
                editable = true;
                format = 'table';
                break;
            default:
                format = event.target.value;
                break;
        }

        switch (format) {
            case 'select':
            case 'table':
                try {
                    if(formatOptions === null){
                        formatOptions = {multiselect : multiselect, editable : editable, presel : []};
                    }else{
                        formatOptions.multiselect = multiselect;
                        formatOptions.editable = editable;
                    }
                }catch (e) {
                }

                break;
            case 'pluglink':
                try {
                    //{"mandatory":false,"code":"A126","pfcode":"PF-210714-5ND02","fields":[{"from":"2","to":"1","dragid":"item-1"}]}
                    if(formatOptions === null){
                        formatOptions = {code : code, pfcode : pfcode, fields : fields};
                    }
                }catch (e) {
                }
                break;
            case 'plugform':
                try {
                    //{"mandatory":false,"clef":"REP0","field":"1;5;6"}
                    if(formatOptions === null){
                        formatOptions = {clef : "", field : ""};
                    }
                }catch (e) {
                }

                break;
            case 'formula':
                try {
                    //{"mandatory":false,"formula":"=SUM(PN!A1, PN!A2) - 12.23"}
                    if(formatOptions === null){
                        formatOptions = {formula : ""};
                    }
                }catch (e) {
                }
                break;
            default:
                break;
        }

        tmpModelCopy[id] = format;
        tmpModelCopy[id+'options'] = formatOptions;

        setModelCopy(tmpModelCopy);
    }

    const handleDeleteTypeFormatAndValidate = (event, id) => {
        event.preventDefault();
        event.stopPropagation();
        const tmpModelOriginal = {...modelOriginal};
        const tmpModelCopy = {...modelCopy};
        tmpModelOriginal['field'+id+'deleted'] = true;
        tmpModelCopy['field'+id+'deleted'] = true;
        setModel(tmpModelOriginal);
        setModelOriginal(tmpModelOriginal);
        setModelCopy(tmpModelCopy);
    }

    const handleActiveTypeFormatAndValidate = (event, id) => {
        event.preventDefault();
        event.stopPropagation();
        const tmpModelOriginal = {...modelOriginal};
        const tmpModelCopy = {...modelCopy};
        tmpModelOriginal['field'+id+'deleted'] = false;
        tmpModelCopy['field'+id+'deleted'] = false;
        setModel(tmpModelOriginal);
        setModelOriginal(tmpModelOriginal);
        setModelCopy(tmpModelCopy);
    }

    function oldHandleColumnsReorder(sourceKey, targetKey) {

        const sourceColumnIndex = initColumns.findIndex(c => c.key === sourceKey);
        const targetColumnIndex = initColumns.findIndex(c => c.key === targetKey);

        const sourceColumnSelectedFieldsIndex = selectedFields.findIndex(c => c === initColumns[sourceColumnIndex].item);
        const targetColumnSelectedFieldsIndex = selectedFields.findIndex(c => c === initColumns[targetColumnIndex].item);
        const reorderedSelectedFieldsColumns = [...selectedFields];

        reorderedSelectedFieldsColumns.splice(
            targetColumnSelectedFieldsIndex,
            0,
            reorderedSelectedFieldsColumns.splice(sourceColumnSelectedFieldsIndex, 1)[0]
        );


        setSelectedFields(reorderedSelectedFieldsColumns);

    }

    useEffect(() => {
        setRows(initialRows);
        setRowCount(initialRows.length);
    }, [favoris, actif, archived,plugnotes]);


    useEffect(() => {
        setColumns(initColumns);
    }, [modelCopy,modelOriginal])


    useEffect(() => {
        setPage(startPage);
        setEnd(limitPage);
        if(startPage < limitPage){
            setHasMore(true);
        }
    }, [startPage, limitPage]);

    const fetchDatas = () => {

        let nextPage = page + 1;
        setPage(nextPage);
        if(nextPage < limitPage){
            setHasMore(true);
        }else{
            setHasMore(false);
        }

        attemptGetPlugnotesPaginateByModelHashAndUserHash(modelhash, hash, nextPage);

    };

    const loadServerRows = () => {
        setLoading(true);
        let nextPage = page + 1;
        setPage(nextPage);
        if(nextPage < limitPage){
            setHasMore(true);
        }else{
            setHasMore(false);
        }

        attemptGetPlugnotesPaginateByModelHashAndUserHash(modelhash, hash, nextPage);
    };

    const handleEditCellChange = React.useCallback(
        ({ id, field, props }) => {
            let format = infoCurrentModel[field+'format'];
            let fieldName = field;
            let data = props; // Fix eslint value is missing in prop-types for JS files
            let isValid = true;
            let newState = {};

            switch (format) {
                case 'boolean':
                    newState[id] = {
                        ...editRowsModel[id],
                        [fieldName]: { ...props, value : data.value, type : 'boolean', stringValue :  data.value ? "1" : "", format : 'boolean'},
                    };
                    setEditRowsModel((state) => ({ ...state, ...newState }));
                    break;
                case 'mail':
                    isValid = validateEmail(data.value);
                    newState[id] = {
                        ...editRowsModel[id],
                        [fieldName]: { ...props, error: !isValid, format : 'mail' },
                    };
                    setEditRowsModel((state) => ({ ...state, ...newState }));
                    break;
                case 'date':
                    let date = data !==  null && data.value !== null ? moment(new Date(data.value.toString())).format('YYYY-MM-DD') : "";
                    newState[id] = {
                        ...editRowsModel[id],
                        [fieldName]: { ...props, error: false, value : date ,format : 'date'},
                    };
                    setEditRowsModel((state) => ({ ...state, ...newState }));
                    break;
                case 'phone':
                    newState[id] = {
                        ...editRowsModel[id],
                        [fieldName]: { ...props, format : 'phone' }
                    };
                    setEditRowsModel((state) => ({ ...state, ...newState }));
                    break;
                case 'text':
                    isValid = validateText(data.value);
                    newState[id] = {
                        ...editRowsModel[id],
                        [fieldName]: { ...props, error: !isValid, format : 'text' },
                    };
                    setEditRowsModel((state) => ({ ...state, ...newState }));
                    break;
                case 'numeric':
                    isValid = validateNumber(data.value);
                    newState[id] = {
                        ...editRowsModel[id],
                        [fieldName]: { ...props, error: !isValid, format : 'numeric' },

                    };
                    setEditRowsModel((state) => ({ ...state, ...newState }));
                    break;
                case 'url':
                    isValid = validateUrl(data.value);
                    newState[id] = {
                        ...editRowsModel[id],
                        [fieldName]: { ...props, error: !isValid,format : 'url' },

                    };
                    setEditRowsModel((state) => ({ ...state, ...newState }));
                    break;
                case 'select':
                    newState[id] = {
                        ...editRowsModel[id],
                        [fieldName]: { ...props, format : 'select' },

                    };
                    setEditRowsModel((state) => ({ ...state, ...newState }));
                    break;
            default:
                break;
            }
        },
        [editRowsModel],
    );

    const handleEditCellChangeCommitted = React.useCallback(
        ({ id, field, props }) => {
            const updatedRows = rows.map((row) => {
                let fieldName = field;
                const data = props; // Fix eslint value is missing in prop-types for JS files
                if(typeof data !== 'undefined'){
                    if (row.hash === id && typeof data.type === "undefined" && editableDirectFields.includes(data.format)) {

                        row.plugnote[fieldName]  = data.value;

                        attemptUpdatePlugnoteField(infoCurrentModel.hash, row.plugnote.hash, environment.userhash, {
                            field : fieldName,
                            value : data.value
                        });

                        return { ...row,
                            [fieldName]: data.value,
                            plugnote : row.plugnote
                        };
                    }
                    else if (row.hash === id && typeof data.type !== "undefined" && editableDirectFields.includes(data.format)) {

                        switch (data.type) {
                            case 'boolean' :
                                row.plugnote[fieldName] = data.stringValue;

                                attemptUpdatePlugnoteField(infoCurrentModel.hash, row.plugnote.hash, environment.userhash, {
                                    field : fieldName,
                                    value : data.value
                                });

                                return { ...row,
                                    [fieldName]: data.value,
                                    plugnote : row.plugnote
                                };
                                break;

                            default:
                                row.plugnote[fieldName] = data.arrayStringValue;

                                attemptUpdatePlugnoteField(infoCurrentModel.hash, row.plugnote.hash, environment.userhash, {
                                    field : fieldName,
                                    value : data.arrayStringValue
                                });

                                return { ...row,
                                    [fieldName]: data.type === "single-select" ? data.value : data.arrayValue.join(", "),
                                    plugnote : row.plugnote
                                };
                                break;
                        }
                    }
                }
                return row;
            });
            setRows(updatedRows);
        },
        [rows],
    );

    const handleBlur = React.useCallback((params, event) => {
        if(params.cellMode === "edit" && params.colDef.format === "select"){
            event.stopPropagation();
        }
    }, []);


    function handleDoubleClick (object) {
        //agenda,localis, password,time,list,birthday,qrcode,table,plugform,pluglink

        switch (object.colDef.format) {
            case "textarea":
                setOpenMemo(true);
                setInfos(object);
                break;
            case "agenda":
                setOpenAgenda(true);
                setInfos(object);
                break;
            case "localis":
                setOpenLocalis(true);
                setInfos(object);
                break;
            case "password":
                setOpenPassword(true);
                setInfos(object);
                break;
            case "time":
                setOpenTime(true);
                setInfos(object);
                break;
            case "list":
                setOpenList(true);
                setInfos(object);
                break;
            case "birthday":
                setOpenBirthday(true);
                setInfos(object);
                break;
            case "qrcode":
                setOpenQrcode(true);
                setInfos(object);
                break;
            case "table":
                //setOpenTable(true);
                setInfos(object);
                break;
            /*
            case "plugform":
                setOpenPlugform(true);
                setInfos(object);
                break;
            */
            default:
                break;
        }
    }

    useEffect(() => {
        if(apiRef.current !== null && typeof apiRef.current.subscribeEvent !== "undefined"){
            return apiRef.current.subscribeEvent(
                GRID_COLUMN_HEADER_DRAG_END,
                (params, event) => {
                    setOndrag(null);
                },
            );
        }
    }, [apiRef]);

    useEffect(() => {
        if(onDrag === false && sourceColumnIndex !== -1 && targetColumnIndex !== -1){

            const sourceColumnIndexSource = sourceColumnIndex;
            //const sourceColumnIndexSource = sourceColumnIndex - 1;
            const targetColumnIndexSource = targetColumnIndex;
            //const targetColumnIndexSource = targetColumnIndex - 1;


            if(sourceColumnIndexSource !== null && targetColumnIndexSource !== null){
                const sourceColumnSelectedFieldsIndex = selectedFields.findIndex(c => typeof initColumns[sourceColumnIndexSource] !== "undefined" && c === initColumns[sourceColumnIndexSource].item);
                const targetColumnSelectedFieldsIndex = selectedFields.findIndex(c => typeof initColumns[targetColumnIndexSource] !== "undefined" && c === initColumns[targetColumnIndexSource].item);

                if(sourceColumnSelectedFieldsIndex !== -1 && targetColumnSelectedFieldsIndex !== -1){
                    const reorderedSelectedFieldsColumns = [...selectedFields];

                    reorderedSelectedFieldsColumns.splice(
                        targetColumnSelectedFieldsIndex,
                        0,
                        reorderedSelectedFieldsColumns.splice(sourceColumnSelectedFieldsIndex, 1)[0]
                    );

                    setSelectedFields(reorderedSelectedFieldsColumns);
                }

                setSourceColumnIndex(null);
                setTargetColumnIndex(null);
                setOndrag(null);
            }

        }
    }, [onDrag,sourceColumnIndex,targetColumnIndex]);

    useEffect(() => {
        if(apiRef.current !== null && typeof apiRef.current.subscribeEvent !== "undefined"){
            return apiRef.current.subscribeEvent(
                 GRID_COLUMN_HEADER_DRAG_START,
                (params, event) => {
                    setOndrag(true)
                },
            );
        }
    }, [apiRef]);


    const handleSaveReplaceModel = () => {

        let tmpSelectedFields = [...selectedFields];
        let selectedFieldsToRemove = [0,16,17,18,19,20];

        selectedFieldsToRemove.map((item, index) => {
            const toDeleteIndex = tmpSelectedFields.indexOf(item);
            if (toDeleteIndex > -1) {
                tmpSelectedFields.splice(toDeleteIndex, 1);
            }
        })

        modelCopy.orderFields = tmpSelectedFields;

        try {
            delSpecifiFieldsSelected(modelCopy.hash);

        }catch (e) {}


        props.attemptReplaceModel(modelCopy, environment.userhash);
    }

    const CustomToolbar = () => {

        return (
            <div style={{flexDirection : 'row', display : 'column'}}>
                <GridToolbarContainer>
                    <div className="clear div_width_100">
                        <button className="button" style={{float: "right"}} onClick={handleSaveReplaceModel}><span style={{color : 'white'}}>{t("common:multiple.save")}</span></button>
                    </div>
                </GridToolbarContainer>
            </div>
        );
    };

    // if(!listView){
    //     return (
    //         <InfiniteScroll
    //             className="scrollbar-hidden"
    //             dataLength={plugnotes.length} //This is important field to render the next data
    //             next={loadServerRows}
    //             height={'calc(85vh - 80px)'}
    //             scrollThreshold={0.7}
    //             hasMore={hasMore}
    //             loader={<div/>}
    //         >
    //             <div className={!listView ? classes.contentModelGrid : classes.contentModelList}>
    //                 {
    //                     plugnotes.length > 0 && plugnotes.map((value, index) => {
    //
    //                         if(favoris === false){
    //                             if(actif === true && archived === true && (value.isarchived === false || value.isarchived === true)){
    //                                 return (
    //                                     <div className="flexbox-container" key={index} onClick={handleSelectPlugnote.bind(this,value)}>
    //                                         <FlipPlugnoteComponent index={index} value={value}
    //                                                                handleDeletePlugnote={handleDeletePlugnote}
    //                                                                handleArchivePlugnote={handleArchivePlugnote}
    //                                                                handleLockPlugnote={handleLockPlugnote}
    //                                                                handleFavorisPlugnote={handleFavorisPlugnote}
    //                                                                handleModalPlugnotePlugcodeOpen={handleModalPlugnotePlugcodeOpen}
    //                                                                handleModalPlugnoteClassifyOpen={handleModalPlugnoteClassifyOpen}
    //                                         />
    //                                     </div>)
    //                             }
    //                             else if(actif === true && archived === false && value.isarchived === false){
    //                                 return (
    //                                     <div className="flexbox-container" key={index} onClick={handleSelectPlugnote.bind(this,value)}>
    //                                         <FlipPlugnoteComponent index={index} value={value}
    //                                                                handleDeletePlugnote={handleDeletePlugnote}
    //                                                                handleArchivePlugnote={handleArchivePlugnote}
    //                                                                handleLockPlugnote={handleLockPlugnote}
    //                                                                handleFavorisPlugnote={handleFavorisPlugnote}
    //                                                                handleModalPlugnotePlugcodeOpen={handleModalPlugnotePlugcodeOpen}
    //                                                                handleModalPlugnoteClassifyOpen={handleModalPlugnoteClassifyOpen}
    //
    //                                         />
    //                                     </div>)
    //                             }
    //                             else if(actif === false && archived === true && value.isarchived === true){
    //                                 return (
    //                                     <div className="flexbox-container" key={index} onClick={handleSelectPlugnote.bind(this,value)}>
    //                                         <FlipPlugnoteComponent index={index} value={value}
    //                                                                handleDeletePlugnote={handleDeletePlugnote}
    //                                                                handleArchivePlugnote={handleArchivePlugnote}
    //                                                                handleLockPlugnote={handleLockPlugnote}
    //                                                                handleFavorisPlugnote={handleFavorisPlugnote}
    //                                                                handleModalPlugnotePlugcodeOpen={handleModalPlugnotePlugcodeOpen}
    //                                                                handleModalPlugnoteClassifyOpen={handleModalPlugnoteClassifyOpen}
    //                                         />
    //                                     </div>)
    //                             }
    //                         }else{
    //                             if(actif === true && archived === true && (value.isarchived === false || value.isarchived === true) && value.favorite === 1){
    //                                 return (
    //                                     <div className="flexbox-container" key={index} onClick={handleSelectPlugnote.bind(this,value)}>
    //                                         <FlipPlugnoteComponent index={index} value={value}
    //                                                                handleDeletePlugnote={handleDeletePlugnote}
    //                                                                handleArchivePlugnote={handleArchivePlugnote}
    //                                                                handleLockPlugnote={handleLockPlugnote}
    //                                                                handleFavorisPlugnote={handleFavorisPlugnote}
    //                                                                handleModalPlugnotePlugcodeOpen={handleModalPlugnotePlugcodeOpen}
    //                                                                handleModalPlugnoteClassifyOpen={handleModalPlugnoteClassifyOpen}
    //                                         />
    //                                     </div>)
    //                             }
    //                             else if(actif === true && archived === false && value.isarchived === false  && value.favorite === 1){
    //                                 return (
    //                                     <div className="flexbox-container" key={index} onClick={handleSelectPlugnote.bind(this,value)}>
    //                                         <FlipPlugnoteComponent index={index} value={value}
    //                                                                handleDeletePlugnote={handleDeletePlugnote}
    //                                                                handleArchivePlugnote={handleArchivePlugnote}
    //                                                                handleLockPlugnote={handleLockPlugnote}
    //                                                                handleFavorisPlugnote={handleFavorisPlugnote}
    //                                                                handleModalPlugnotePlugcodeOpen={handleModalPlugnotePlugcodeOpen}
    //                                                                handleModalPlugnoteClassifyOpen={handleModalPlugnoteClassifyOpen}
    //
    //                                         />
    //                                     </div>)
    //                             }
    //                             else if(actif === false && archived === true && value.isarchived === true  && value.favorite === 1){
    //                                 return (
    //                                     <div className="flexbox-container" key={index} onClick={handleSelectPlugnote.bind(this,value)}>
    //                                         <FlipPlugnoteComponent index={index} value={value}
    //                                                                handleDeletePlugnote={handleDeletePlugnote}
    //                                                                handleArchivePlugnote={handleArchivePlugnote}
    //                                                                handleLockPlugnote={handleLockPlugnote}
    //                                                                handleFavorisPlugnote={handleFavorisPlugnote}
    //                                                                handleModalPlugnotePlugcodeOpen={handleModalPlugnotePlugcodeOpen}
    //                                                                handleModalPlugnoteClassifyOpen={handleModalPlugnoteClassifyOpen}
    //                                         />
    //                                     </div>)
    //                             }
    //                         }
    //                     })}
    //
    //             </div>
    //         </InfiniteScroll>
    //     );
    // }
    // else{
    //     return (
    //         <div className="grid-container">
    //             <XGrid
    //                 classes={gridClasses}
    //                 components={{
    //                     Toolbar: CustomToolbar
    //                 }}
    //                 apiRef={apiRef}
    //                 getRowId={(row) => row.hash || ''}
    //                 checkboxSelection={true}
    //                 disableSelectionOnClick={true}
    //                 {...rows}
    //                 rows={rows || []}
    //                 rowHeight={50}
    //                 headerHeight={100}
    //                 columns={columns}
    //                 showCellRightBorder={true}
    //                 onColumnVisibilityChange={handleColumnsVisibility}
    //                 onSelectionModelChange={(newSelection) => {
    //                     setSelectedRows(newSelection);
    //                 }}
    //                 selectionModel={selectedRows}
    //                 //onRowSelected={handleSelectRow}
    //                 hideFooter={true}
    //                 editRowsModel={editRowsModel}
    //                 onEditCellChange={handleEditCellChange}
    //                 onEditCellChangeCommitted={handleEditCellChangeCommitted}
    //                 onColumnOrderChange={handleColumnsReorder}
    //                 className={classes.grid}
    //                 loading={false}
    //                 onColumnResizeCommitted={handleColumnsResize}
    //                 hideFooterPagination
    //                 onCellBlur={handleBlur}
    //                 onCellDoubleClick={handleDoubleClick}
    //                 onRowsScrollEnd={hasMore ? loadServerRows : null}
    //                 /*                    components={{
    //                                         LoadingOverlay: CustomLoadingOverlay,
    //                                     }}*/
    //             />
    //             {
    //                 infos !== null && openMemo &&
    //                 <SimpleMemoDialog
    //                     value={infos.value}
    //                     infos={infos}
    //                     open={openMemo}
    //                     onUpdateClose={handleUpdateClose}
    //                     onClose={handleClose}
    //                     maxWidth="lg"
    //                     fullWidth
    //                     style={{ zIndex: 0 }}
    //                     props={props}
    //                 />
    //             }
    //
    //             {
    //                 infos !== null && openLocalis &&
    //                 <SimpleLocalisDialog
    //                     value={infos.value}
    //                     infos={infos}
    //                     open={openLocalis}
    //                     onUpdateClose={handleUpdateClose}
    //                     onClose={handleClose}
    //                     maxWidth="lg"
    //                     fullWidth
    //                     style={{ zIndex: 0 }}
    //                     props={props}
    //                 />
    //             }
    //
    //             {
    //                 infos !== null && openAgenda &&
    //                 <SimpleAgendaDialog
    //                     value={infos.value}
    //                     infos={infos}
    //                     open={openAgenda}
    //                     onUpdateClose={handleUpdateClose}
    //                     onClose={handleClose}
    //                     maxWidth="lg"
    //                     fullWidth
    //                     style={{ zIndex: 0 }}
    //                     props={props}
    //                 />
    //             }
    //
    //             {
    //                 infos !== null && openList &&
    //                 <SimpleListDialog
    //                     value={infos.value}
    //                     infos={infos}
    //                     open={openList}
    //                     onUpdateClose={handleUpdateClose}
    //                     onClose={handleClose}
    //                     maxWidth="lg"
    //                     fullWidth
    //                     style={{ zIndex: 0 }}
    //                     props={props}
    //                 />
    //             }
    //
    //             {
    //                 infos !== null && openPassword &&
    //                 <SimplePasswordDialog
    //                     value={infos.value}
    //                     infos={infos}
    //                     open={openPassword}
    //                     onUpdateClose={handleUpdateClose}
    //                     onClose={handleClose}
    //                     maxWidth="lg"
    //                     fullWidth
    //                     style={{ zIndex: 0 }}
    //                     props={props}
    //                 />
    //             }
    //
    //             {
    //                 infos !== null && openBirthday &&
    //                 <SimpleBirthdayDialog
    //                     value={infos.value}
    //                     infos={infos}
    //                     open={openBirthday}
    //                     onUpdateClose={handleUpdateClose}
    //                     onClose={handleClose}
    //                     maxWidth="lg"
    //                     fullWidth
    //                     style={{ zIndex: 0 }}
    //                     props={props}
    //                 />
    //             }
    //
    //             {
    //                 infos !== null && openQrcode &&
    //                 <SimpleQrcodeDialog
    //                     value={infos.value}
    //                     infos={infos}
    //                     open={openQrcode}
    //                     onUpdateClose={handleUpdateClose}
    //                     onClose={handleClose}
    //                     maxWidth="lg"
    //                     fullWidth
    //                     style={{ zIndex: 0 }}
    //                     props={props}
    //                 />
    //             }
    //
    //             {
    //                 infos !== null && openPlugform &&
    //                 <SimplePlugformDialog
    //                     value={infos.value}
    //                     infos={infos}
    //                     open={openPlugform}
    //                     onUpdateClose={handleUpdateClose}
    //                     onClose={handleClose}
    //                     maxWidth="lg"
    //                     fullWidth
    //                     style={{ zIndex: 0 }}
    //                     props={props}
    //
    //                 />
    //             }
    //
    //             {
    //                 infos !== null && openSimpleTable &&
    //                 <SimpleSimpleTableDialog
    //                     value={infos.value}
    //                     infos={infos}
    //                     open={openSimpleTable}
    //                     onUpdateClose={handleUpdateClose}
    //                     onClose={handleClose}
    //                     maxWidth="lg"
    //                     fullWidth
    //                     style={{ zIndex: 0 }}
    //                     props={props}
    //                 />
    //             }
    //
    //
    //             {
    //                 infos !== null && openMultipleTable &&
    //                 <SimpleMultipleTableDialog
    //                     value={infos.value}
    //                     infos={infos}
    //                     open={openMultipleTable}
    //                     onUpdateClose={handleUpdateClose}
    //                     onClose={handleClose}
    //                     maxWidth="lg"
    //                     fullWidth
    //                     style={{ zIndex: 0 }}
    //                     props={props}
    //                 />
    //             }
    //
    //             {
    //                 infos !== null && openTime &&
    //                 <SimpleTimeDialog
    //                     props={props}
    //                     value={infos.value}
    //                     infos={infos}
    //                     open={openTime}
    //                     onUpdateClose={handleUpdateClose}
    //                     onClose={handleClose}
    //                     maxWidth="lg"
    //                     fullWidth
    //                     style={{ zIndex: 0 }}
    //                 />
    //             }
    //         </div>
    //     );
    // }

    return (
        <div className="grid-container">
            <XGrid
                components={{
                    Toolbar: CustomToolbar
                }}
                classes={gridClasses}
                apiRef={apiRef}
                getRowId={(row) => row.hash || ''}
                checkboxSelection={false}
                disableSelectionOnClick={true}
                {...rows}
                rows={rows || []}
                rowHeight={50}
                headerHeight={100}
                columns={columns}
                showCellRightBorder={true}
                onColumnVisibilityChange={handleColumnsVisibility}
                onSelectionModelChange={(newSelection) => {
                    setSelectedRows(newSelection);
                }}
                selectionModel={selectedRows}
                //onRowSelected={handleSelectRow}
                hideFooter={true}
                editRowsModel={editRowsModel}
                onEditCellChange={handleEditCellChange}
                onEditCellChangeCommitted={handleEditCellChangeCommitted}
                onColumnOrderChange={handleColumnsReorder}
                className={classes.grid}
                loading={false}
                onColumnResizeCommitted={handleColumnsResize}
                hideFooterPagination
                onCellBlur={handleBlur}
                onCellDoubleClick={handleDoubleClick}
                onRowsScrollEnd={hasMore ? loadServerRows : null}
                /*                    components={{
                                        LoadingOverlay: CustomLoadingOverlay,
                                    }}*/
            />
            {
                infos !== null && openMemo &&
                <SimpleMemoDialog
                    value={infos.value}
                    infos={infos}
                    open={openMemo}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}
                />
            }

            {
                infos !== null && openLocalis &&
                <SimpleLocalisDialog
                    value={infos.value}
                    infos={infos}
                    open={openLocalis}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}
                />
            }

            {
                infos !== null && openAgenda &&
                <SimpleAgendaDialog
                    value={infos.value}
                    infos={infos}
                    open={openAgenda}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}
                />
            }

            {
                infos !== null && openList &&
                <SimpleListDialog
                    value={infos.value}
                    infos={infos}
                    open={openList}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}
                />
            }

            {
                infos !== null && openPassword &&
                <SimplePasswordDialog
                    value={infos.value}
                    infos={infos}
                    open={openPassword}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}
                />
            }

            {
                infos !== null && openBirthday &&
                <SimpleBirthdayDialog
                    value={infos.value}
                    infos={infos}
                    open={openBirthday}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}
                />
            }

            {
                infos !== null && openQrcode &&
                <SimpleQrcodeDialog
                    value={infos.value}
                    infos={infos}
                    open={openQrcode}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}
                />
            }

            {
                infos !== null && openPlugform &&
                <SimplePlugformDialog
                    value={infos.value}
                    infos={infos}
                    open={openPlugform}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}

                />
            }

            {
                infos !== null && openSimpleTable &&
                <SimpleSimpleTableDialog
                    value={infos.value}
                    infos={infos}
                    open={openSimpleTable}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}
                />
            }


            {
                infos !== null && openMultipleTable &&
                <SimpleMultipleTableDialog
                    value={infos.value}
                    infos={infos}
                    open={openMultipleTable}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}
                />
            }

            {
                infos !== null && openTime &&
                <SimpleTimeDialog
                    props={props}
                    value={infos.value}
                    infos={infos}
                    open={openTime}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                />
            }
        </div>
    );
};


function PlugListEditComponent(props) {
    const { t, } = useTranslation(['common', 'models', 'global']);

    const classes = useStyles();

    const { promiseInProgress } = usePromiseTracker();

    const [modelBaseInfo, setModelBaseInfo] = useState(null);

    const [open, setOpen] = useState(false);
    const [subOpen, setSubOpen] = useState(false);
    const [archived, setArchived] = useState(false);
    const [actif, setActif] = useState(true);
    const [favoris, setFavoris] = useState(false);

    const [countArchived, setCountArchived] = useState(0);
    const [countFavorites, setCountFavorite] = useState(0);
    const [countActive, setCountActive] = useState(0);

    const [payload, setPayload] = useState(null);

    const [fields, setFields] = useState(() => {
        let currentModel = props.model;

        if(currentModel !== 'undefined'){
            const labels = Object.keys(currentModel).filter((key) => {
                const regex = /field[0-9]+label/gi;
                return regex.test(key);
            });

            let itemFields = labels.filter(Boolean);

            let currentItemFields = itemFields.map((item) => {
                return currentModel[item];
            });

            return currentItemFields.filter(Boolean);
        }
    });

    const [fieldsInfos, setFieldsInfos] = useState(() => {
        let currentModel = props.model;

        if(currentModel !== 'undefined'){
            const labels = Object.keys(currentModel).filter((key) => {
                const regex = /field[0-9]+label/gi;
                return regex.test(key) && currentModel[key] != null;
            });

            let fields = labels.map((label, index) => {
                let formatoptions = currentModel['field' + (index + 1) + 'formatoptions'] || {};
                if (typeof formatoptions !== 'object') {
                    formatoptions = JSON.parse(formatoptions);
                }

                return {
                    key: index + 1,
                    id: index + 1,
                    label: currentModel['field' + (index + 1) + 'label'],
                    format: currentModel['field' + (index + 1) + 'format'],
                    options: {
                        mandatory: !!currentModel['field' + (index + 1) + 'mandatory'],
                        ...formatoptions
                    },
                    formatoptions: currentModel['field' + (index + 1) + 'formatoptions']
                }
            });

            return fields;
        }
    });


    let checkExistingSelection = JSON.parse(localStorage.getItem('fieldsSelected'));
    let checkExistingSizeFields = JSON.parse(localStorage.getItem('sizeFields'));

    let preSelectedFields = [0];
    let preSpecialsSelectedFields = [16, 17, 18, 19, 20];

    let preSizeFields = [{width : 100, field : 0}];
    let preDefaultSizeFields = [{width : 100, field : 0}];
    let preSizeSelectedFields = [
        {width : 150, field : 16},
        {width : 150, field : 17},
        {width : 150, field : 18},
        {width : 150, field : 19},
        {width : 90, field : 20}
    ];
    let preDefaultSizeSelectedFields = [
        {width : 150, field : 16},
        {width : 150, field : 17},
        {width : 150, field : 18},
        {width : 150, field : 19},
        {width : 90, field : 20}
    ];

    fields.map((field, Findex) => {
        if (field !== null) {
            preSelectedFields.push(Findex + 1);
            preSizeFields.push({width : 150, field : Findex + 1});
            preDefaultSizeFields.push({width : 150, field : Findex + 1});
        }
    });

    preSelectedFields = preSelectedFields.concat(preSpecialsSelectedFields);
    preSizeFields = preSizeFields.concat(preSizeSelectedFields);
    preDefaultSizeFields = preDefaultSizeFields.concat(preDefaultSizeSelectedFields);

    const [defaultSelectedFields, setDefaultSelectedFields] = useState(preSelectedFields);
    const [defaultSizeFields, setDefaultSizeFields] = useState(preDefaultSizeFields);

    if(checkExistingSelection){
        let findIndex = checkExistingSelection.findIndex(item  => item.hash === props.model.hash);
        if(findIndex !== -1){
            preSelectedFields = checkExistingSelection[findIndex].fields;
            if(typeof checkExistingSelection[findIndex].specialFields !== 'undefined'){
                preSelectedFields.concat([0, 16, 17, 18, 19, 20]);
            }
        }
    }

    const [selectedFields, setSelectedFields] = useState(preSelectedFields);

    if(checkExistingSizeFields){
        let findIndex = checkExistingSizeFields.findIndex(item  => item.hash === props.model.hash);
        if(findIndex !== -1){
            preSizeFields = checkExistingSizeFields[findIndex].fields;
        }
    }

    const [sizeFields, setSizeFields] = useState(preSizeFields);


    const [isOpenModalModelPlugcode, setIsOpenModalModelPlugcode] = useState(false);
    const [isOpenModalPlugnotePlugcode, setIsOpenModalPlugnotePlugcode] = useState(false);
    const [isOpenModalModelShare, setIsOpenModalModelShare] = useState(false);
    const [isOpenModalImportExportCsv, setIsOpenModalImportExportCsv] = useState(false);
    const [isOpenModalTemplateImportExportCsv, setIsOpenModalTemplateImportExportCsv] = useState(false);
    const [isOpenModalExportSimpleCsv, setIsOpenModalExportSimpleCsv] = useState(false);
    const [isOpenClassify, setIsOpenClassify] = useState(false);
    const [filters, setFilters] = useState([]);
    const [filterFieldsOpen, setFilterFieldsOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState(() => []);

    const user = getUser();
    const environment = getCurrentEnvironnement();
    const [hash, setHash] = useState(environment.userhash);
    const token = getToken();
    const model = props.model;
    const [plugnotes, setPlugnotes] = useState([]);
    const [loading, setLoading] = useState(true);
    //const [listView, setListView] = useState(getPlugnoteView() !== 'false');
    const [listView, setListView] = useState(true);
    const [flipped, setFlipped] = useState(-1);

    const handleListAnnexes = (plugnote) => {
        setPlugnote(plugnote);
        setAnnexes([]);
        if(!!plugnote.Annexes && plugnote.Annexes.length > 0){
            setAnnexes(plugnote.Annexes);
        }
        props.history.push('/annexeslist');
    }

    const handleSelectPlugnote = (plugnote) => {
        props.attemptResetAnnexe();
        props.attemptResetDataPassword();
        setPlugnote(plugnote);
        setAnnexes([]);
        if(!!plugnote.Annexes && plugnote.Annexes.length > 0){
            setAnnexes(plugnote.Annexes);
        }
        props.history.push('/update-plugnote');
    }

    const handleCreatePlugnote = () => {
        props.attemptResetAnnexe();
        props.attemptResetDataPassword();
        delPlugnote();
        delTypeOfPlugnote();
        delAnnexes();
        setAnnexes([]);
        props.history.push('/create-plugnote');
    };

    const handleDeletePlugnote = (item) => {

        confirmAlert({
            title: t('global.confirm'),
            message: t('plugnote.plugnote-confirm-delete'),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () =>  props.attemptDeletePlugote(item.hash,environment.userhash)
                },
                {
                    label: t('global.cancel'),
                }
            ]
        });

    };

    const handleArchivePlugnote = (item) => {
        props.attemptUpdateArchivePlugnote(item.hash, !item.isarchived, item.locked, environment.userhash);
    }

    const handleLockPlugnote = (item) => {
        props.attemptUpdateLockPlugnote(item.hash, item.isarchived, !item.locked, environment.userhash);
    }

    const handleFavorisPlugnote = (item) => {
        props.attemptUpdateFavorisPlugnote(item.hash, !item.favorite, environment.userhash);
    }

    const handleDrawerOpen = () => {
        setSubOpen(false);
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleSubDrawerClose = () => {
        setSubOpen(false);
        setOpen(false);
    };

    const handleResetPlugSearch = () => {
        props.attemptDelAllDataFilter();
        setFilters([]);
        setSubOpen(false);
        setOpen(false);
    };

    const handleAddFiltersLine = () => {
        setFilters(oldArray => [...oldArray, {position : oldArray.length + 1, field : null, value : null, query : null}]);
    };

    const handleDelFiltersLine = (indexFilter) => {
        setFilters(filters.filter((item, index) => {
            return indexFilter !== index;
        }));

    };

    const handleChangeView = () => {
        setFilterFieldsOpen(false);
        setListView(!listView);
        setPlugnoteView(!listView);
    }

    const handleFlip = (value, event) => {
        event.preventDefault();
        event.stopPropagation();
        if(flipped === value){
            setFlipped(-1);
        }else{
            setFlipped(value);
        }
    }

    const handleExitPlugList = () => {

        if(props.back.length > 0){
            props.history.push(props.back);
        }else{
            props.history.push("/models");
            delModel();
        }
    }

    const handleModalPlugnotePlugcodeOpen = () => {
        props.attemptResetPlugnotePlugcode();
        setIsOpenModalPlugnotePlugcode(true);
    }

    const handleModalPlugnotePlugcodeClose = () => {
        props.attemptResetPlugnotePlugcode();
        setIsOpenModalPlugnotePlugcode(false);
    };

    const handleModalPlugnoteClassifyClose = () => {
        delPlugnote();
        setIsOpenClassify(false);
    };

    const handleModalPlugnoteClassifyOpen = (item) => {
        setPlugnote(item);
        setIsOpenClassify(true);
    };

    const handleModalModelPlugcodeOpen = () => {
        props.attemptResetModelPlugcode();
        setIsOpenModalModelPlugcode(true);
    };

    const handleGeneratePlugcodeForAllPlugnoteModel = () => {
        confirmAlert({
            title: t('global.plugnotes'),
            message: t('plugcode.model-confirm-multiple-generation'),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () => props.attemptGeneratePlugcodeForAllPlugnoteModel(model.hash, hash)
                },
                {
                    label: t('global.cancel'),
                }
            ]
        });
    }

    const handleModalModelPlugcodeClose = () => {
        props.attemptResetModelPlugcode();
        setIsOpenModalModelPlugcode(false);

    };

    const handleModalModelShareOpen = () => {
        props.attemptResetModelShare();
        setIsOpenModalModelShare(true);
    };

    const handleOpenSubDrawer = () => {
        setOpen(false);
        setSubOpen(true);
    }

    const handleModalModelShareClose = () => {
        props.attemptResetModelShare();
        setIsOpenModalModelShare(false);
    };

    const handleModalExportCsvClose = () => {
        setPayload(null);
        setIsOpenModalExportSimpleCsv(false);
    }

    const handleModalTemplateImportExportCsvClose = () => {
        setIsOpenModalTemplateImportExportCsv(false);
    }

    const handleModalImportExportCsvClose = () => {
        setIsOpenModalImportExportCsv(false);
    }

    const handleModalTemplateImportExportCsvOpen = () => {
        setIsOpenModalTemplateImportExportCsv(true);
    }

    const handleModalImportExportCsvOpen = () => {
        setIsOpenModalImportExportCsv(true);
    }

    const handleUpdateModel = () => {
        setModelToUpdate(props.model);
        props.history.push({
            pathname: '/update-model',
            state: { back: '/pluglist' }
        });
    }

    const handlePressExportFile = () => {
        let model = props.model;
        props.attemptExportFullFiles(model.hash, props.user.user.userhash );
    };

    const handlePressExportCsv = () => {
        let tmpFieldsChecked = Array.from(selectedFields);

        let filteredPlugList = plugnotes.map(searchData => {
            /*            if(!selectedFields.includes(searchData.userindex)){
                            return searchData.userindex
                        }*/
            if(selectedRows.length > 0 && selectedRows.includes(searchData.hash)){
                //console.log(cleanFilteredPlugList);
                return searchData.userindex
            }
        });

        let cleanFilteredPlugList = filteredPlugList.filter(Boolean);


        let filter = 'all';

        if(!archived && actif){
            filter = 'isnotarchived';
        }else if(archived && !actif){
            filter = 'isarchived';
        }

        let payload = {
            model : model.hash,
            field : tmpFieldsChecked,
            filter : filter,
            plugList : selectedRows.length > 0 ? cleanFilteredPlugList : [],
            plugsSelectAll : selectedRows.length === 0
        };

        setPayload(payload);
        setIsOpenModalExportSimpleCsv(true);
    };

    const handleDeleteModel = () => {
        let model = props.model;
        const isDeletable = !model.nbFichesArchived && !model.nbFichesNotArchived;

        if(isDeletable){
            confirmAlert({
                title: t('global.confirm'),
                message: t('models.model-confirm-delete'),
                buttons: [
                    {
                        label: t('global.yes'),
                        onClick: () => props.attemptRemoveModel(model.hash, hash)
                    },
                    {
                        label: t('global.cancel'),
                    }
                ]
            });
        }else{
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('common:models.not-empty-folder'),
                buttons: [
                    {
                        label: t('global.ok'),
                    }
                ]
            });
        }
    }

    const handleAllArchive = () => {

        let dataToDeleteHash = [...selectedRows];
        let dataToDeleteHashMap = [];
        plugnotes.map((plug, index) => {
            if(dataToDeleteHash.includes(plug.hash) && plug.isarchived === false){
                dataToDeleteHashMap.push(plug.hash);
            }
        });


        confirmAlert({
            title: t('global.confirm'),
            message: t('plugnote.plugnote-confirm-archive-multiple',{count : dataToDeleteHashMap.length}),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () =>  {
                        props.attemptArchivesDatas(dataToDeleteHashMap,environment.userhash)
                        setSelectedRows([])
                    }
                },
                {
                    label: t('global.cancel'),
                }
            ]
        });

    };

    const handleAllUnArchive = () => {

        let dataToDeleteHash = [...selectedRows];
        let dataToDeleteHashMap = [];
        plugnotes.map((plug, index) => {
            if(dataToDeleteHash.includes(plug.hash) && plug.isarchived === true){
                dataToDeleteHashMap.push(plug.hash);
            }
        });


        confirmAlert({
            title: t('global.confirm'),
            message: t('plugnote.plugnote-confirm-unarchive-multiple',{count : dataToDeleteHashMap.length}),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () =>  {
                        props.attemptUnArchivesDatas(dataToDeleteHashMap,environment.userhash)
                        setSelectedRows([])
                    }
                },
                {
                    label: t('global.cancel'),
                }
            ]
        });

    };

    const handleAllDelete = () => {

        let dataToDeleteHashMap = [...selectedRows];


        confirmAlert({
            title: t('global.confirm'),
            message: t('plugnote.plugnote-confirm-delete-multiple',{count : dataToDeleteHashMap.length}),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () =>  {
                        props.attemptDeleteDatas(dataToDeleteHashMap,environment.userhash);
                        setSelectedRows([])
                    }
                },
                {
                    label: t('global.cancel'),
                }
            ]
        });

    };

    const attemptSetDataFilter = (search, key) => {
        props.attemptSetDataFilter(search, key);
    };

    const attemptDelDataFilter = (search, key) => {
        props.attemptDelDataFilter(search, key);
    }

    const handleRemoveAllSelectedFieldsInFilter = () => {
        setSelectedFields([]);
    };

    const handleChangeSelectedFieldsInFilter = (index) => {

        if(selectedFields.includes(index)){
            setSelectedFields(selectedFields.filter((e)=>(e !== index)))
        }else{
            setSelectedFields([...selectedFields, index])
        }


    };

    const handleAddAllFieldsInFilter = () => {

        let allSelect = [];
        allSelect.push(0);
        fields.map((field, Findex) => {
            if (field !== null) {
                allSelect.push(Findex + 1);
            }
        });
        allSelect = allSelect.concat([16,17,18,19,20]);
        setSelectedFields(allSelect);
    };


    const handleModels = () => {
        history.push('models')
    };


    useEffect(() => {
        deleteHistoryPlugAction();
        props.attemptGetPlugnotesByModelHashAndUserHash(model.hash, hash);

    }, [hash]);

    useEffect(() => {
        let dataSource = props.plugnotes;

        if(props.globalDataFilter !== null){

            dataSource = dataSource.filter(data => {
                return fields.reduce((res, field, fieldIndex) => {
                    if (!!field) {
                        const index = fieldIndex + 1 ;
                        let value = data[`field${index}`];
                        if (data[`field${index}format`] === 'select' && data[`field${index}`]) {
                            if(isJsonString(data[`field${index}`])){
                                value = JSON.parse(data[`field${index}`]).join(', ');
                            }else{
                                value = '';
                            }
                        }

                        if (data[`field${index}format`] === 'table' && data[`field${index}`]) {
                            if(isJsonString(data[`field${index}`])){
                                value = JSON.parse(data[`field${index}`]).reduce((res, obj) => {
                                    return res.concat(obj.text + ' : ' + obj.value || '');
                                }, []).join(', ');
                            }else{
                                value = '';
                            }
                        }

                        if (data[`field${index}format`] === 'list' && data[`field${index}`] && (data[`field${index}`] !== '\'\'' && data[`field${index}`] !== '[]' &&  data[`field${index}`] !== 'NULL' && data[`field${index}`] !== '""' )) {
                            if(isJsonString(data[`field${index}`])){
                                value = JSON.parse(data[`field${index}`]).reduce((res, obj) => {
                                    return res.concat(obj.text || '');
                                }, []).join(', ');
                            }else{
                                value = '';
                            }
                        }

                        if (data[`field${index}format`] === 'password' && data[`field${index}`]) {
                            value = '*'.repeat(value.length);
                        }

                        if ((value || '').toLowerCase().includes(props.globalDataFilter.toLowerCase()) || (typeof data !== "undefined" && data.userindex.toString().includes(props.globalDataFilter))) {
                            return true;
                        }

                        return res || false;
                    }
                    return res;
                }, false);
            })

            setCountActive(dataSource.filter((plug, index) => {
                return !plug.isarchived
            }).length)
            setCountFavorite(dataSource.filter((plug, index) => {
                return plug.favorite
            }).length)
            setCountArchived(dataSource.filter((plug, index) => {
                return plug.isarchived
            }).length)

        }
        if(props.dataFilter.length > 0){
            props.dataFilter.map((selectedDataFilter, index) => {
                if(selectedDataFilter.simpleSearch){
                    dataSource = dataSource.filter(data => {
                        return fields.reduce((res, field, fieldIndex) => {
                            if (!!field) {
                                const index = fieldIndex + 1;
                                let value = data[`field${index}`];

                                if(!!selectedDataFilter[`field${index}`]){
                                    if(index === selectedDataFilter[`field${index}`].field.key && data[`field${index}format`] ===  selectedDataFilter[`field${index}`].field.format && data[`field${index}`]){
                                        if (data[`field${index}format`] === 'select' && data[`field${index}`]) {
                                            if(isJsonString(data[`field${index}`])){
                                                value = data[`field${index}`];
                                                //.map(v => v.toLowerCase())
                                            }else{
                                                value = '';
                                            }
                                        }

                                        if (data[`field${index}format`] === 'table' && data[`field${index}`]) {
                                            if(isJsonString(data[`field${index}`])){
                                                value = data[`field${index}`];
                                                //.map(v => v.toLowerCase())
                                            }else{
                                                value = '';
                                            }
                                        }

                                        if (data[`field${index}format`] === 'list' && data[`field${index}`] && (data[`field${index}`] !== '\'\'' && data[`field${index}`] !== '[]' &&  data[`field${index}`] !== 'NULL' && data[`field${index}`] !== '""' )) {
                                            if(isJsonString(data[`field${index}`])){
                                                value = data[`field${index}`];
                                            }else{
                                                value = '';
                                            }
                                        }

                                        if (data[`field${index}format`] === 'password' && data[`field${index}`]) {
                                            value = '*'.repeat(value.length);
                                        }

                                        if(data[`field${index}format`] === 'select' && data[`field${index}`]){

                                            let selectedDateFilterValue = selectedDataFilter[`field${index}`].value.map((v) => {

                                                let found = false;
                                                if(value.includes(v)){
                                                    found = true;
                                                }
                                                return found;
                                            });
                                            return !!selectedDateFilterValue.includes(true);

                                        }
                                        else if(data[`field${index}format`] === 'date' && data[`field${index}`]){
                                            if(!!selectedDataFilter[`field${index}`].query && selectedDataFilter[`field${index}`].query !== null){
                                                switch (selectedDataFilter[`field${index}`].query) {
                                                    case 'from':
                                                        let date1 = new Date(value);
                                                        let date2 = new Date(selectedDataFilter[`field${index}`].value);
                                                        if(date1 >= date2){
                                                            return true;
                                                        }
                                                        break;
                                                    case 'to':
                                                        let date3 = new Date(value);
                                                        let date4 = new Date(selectedDataFilter[`field${index}`].value);
                                                        if(date3 <= date4){
                                                            return true;
                                                        }
                                                        break;
                                                    case 'equal':
                                                        if((value || '').toLowerCase().includes(selectedDataFilter[`field${index}`].value.toLowerCase())){
                                                            return true;
                                                        }
                                                        break;
                                                    default:
                                                        break;
                                                }
                                            }
                                        }
                                        else if(data[`field${index}format`] === 'agenda' && data[`field${index}`]){
                                            if(!!selectedDataFilter[`field${index}`].query && selectedDataFilter[`field${index}`].query !== null){
                                                switch (selectedDataFilter[`field${index}`].query) {
                                                    case 'from':
                                                        //                valueTZ = moment(date,'YYYY-MM-DD').format('YYYY-MM-DD');
                                                        let date1 = moment(value,'YYYY-MM-DD').format('YYYY-MM-DD');
                                                        let date2 = moment(selectedDataFilter[`field${index}`].value,'YYYY-MM-DD').format('YYYY-MM-DD')
                                                        if(date1 >= date2){
                                                            return true;
                                                        }
                                                        break;
                                                    case 'to':
                                                        let date3 = moment(value,'YYYY-MM-DD').format('YYYY-MM-DD');
                                                        let date4 = moment(selectedDataFilter[`field${index}`].value,'YYYY-MM-DD').format('YYYY-MM-DD')
                                                        if(date3 <= date4){
                                                            return true;
                                                        }
                                                        break;
                                                    case 'equal':
                                                        let date5 = moment(value,'YYYY-MM-DD').format('YYYY-MM-DD');
                                                        let date6 = moment(selectedDataFilter[`field${index}`].value,'YYYY-MM-DD').format('YYYY-MM-DD')
                                                        if(date5 == date6){
                                                            return true;
                                                        }
                                                        break;
                                                    default:
                                                        break;
                                                }
                                            }
                                        }
                                        else if(data[`field${index}format`] === 'now' && data[`field${index}`]){
                                            if(!!selectedDataFilter[`field${index}`].query && selectedDataFilter[`field${index}`].query !== null){
                                                switch (selectedDataFilter[`field${index}`].query) {
                                                    case 'from':
                                                        //                valueTZ = moment(date,'YYYY-MM-DD').format('YYYY-MM-DD');
                                                        let date1 = moment(value,'YYYY-MM-DD').format('YYYY-MM-DD');
                                                        let date2 = moment(selectedDataFilter[`field${index}`].value,'YYYY-MM-DD').format('YYYY-MM-DD')
                                                        if(date1 >= date2){
                                                            return true;
                                                        }
                                                        break;
                                                    case 'to':
                                                        let date3 = moment(value,'YYYY-MM-DD').format('YYYY-MM-DD');
                                                        let date4 = moment(selectedDataFilter[`field${index}`].value,'YYYY-MM-DD').format('YYYY-MM-DD')
                                                        if(date3 <= date4){
                                                            return true;
                                                        }
                                                        break;
                                                    case 'equal':
                                                        let date5 = moment(value,'YYYY-MM-DD').format('YYYY-MM-DD');
                                                        let date6 = moment(selectedDataFilter[`field${index}`].value,'YYYY-MM-DD').format('YYYY-MM-DD')
                                                        if(date5 == date6){
                                                            return true;
                                                        }
                                                        break;
                                                    default:
                                                        break;
                                                }
                                            }
                                        }
                                        else if(data[`field${index}format`] === 'numeric' && data[`field${index}`]){
                                            switch (selectedDataFilter[`field${index}`].query) {
                                                case 'from':
                                                    let number1 = Number(value);
                                                    let number2 = Number(selectedDataFilter[`field${index}`].value);
                                                    if(number1 >= number2){
                                                        return true;
                                                    }
                                                    break;
                                                case 'to':
                                                    let number3 = Number(value);
                                                    let number4 = Number(selectedDataFilter[`field${index}`].value);
                                                    if(number3 <= number4){
                                                        return true;
                                                    }
                                                    break;
                                                case 'equal':
                                                    let number5 = Number(value);
                                                    let number6 = Number(selectedDataFilter[`field${index}`].value);
                                                    if(number5 == number6){
                                                        return true;
                                                    }
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }
                                        else if(data[`field${index}format`] === 'list' && data[`field${index}`]){
                                            let selectedDateFilterValue = selectedDataFilter[`field${index}`].value.map((v) => {
                                                let found = false;
                                                if(value.includes(v)){
                                                    found = true;
                                                }
                                                return found;
                                            });
                                            return !!selectedDateFilterValue.includes(true);
                                        }
                                        else if(data[`field${index}format`] === 'table' && data[`field${index}`]){
                                            let selectedDateFilterValue = selectedDataFilter[`field${index}`].value.map((v) => {
                                                let found = false;
                                                if(value.includes(v)){
                                                    found = true;
                                                }
                                                return found;
                                            });
                                            return !!selectedDateFilterValue.includes(true);
                                        }
                                        else{

                                            if((value || '').toLowerCase().includes(selectedDataFilter[`field${index}`].value.toLowerCase())){
                                                return true;
                                            }
                                        }
                                    }

                                }
                                return res || false;
                            }
                            return res;
                        }, false);
                    });
                }

            });


            if(actif){
                setCountActive(dataSource.filter((plug, index) => {
                    return !plug.isarchived
                }).length)
            }

            if(favoris){
                setCountFavorite(dataSource.filter((plug, index) => {
                    return plug.favorite
                }).length)
            }

            if(archived){
                setCountArchived(dataSource.filter((plug, index) => {
                    return plug.isarchived
                }).length)
            }

            if(filters.length === 0){
                props.dataFilter.map((item, index) => {
                    const  fieldKey = Object.keys(item);
                    setFilters(oldArray => [...oldArray, {position : oldArray.length + 1, field : item[fieldKey[0]].field, value: item[fieldKey[0]].value, query: item[fieldKey[0]].query}]);
                });
                setOpen(false);
                setSubOpen(true);
            }
        }
        if(props.globalDataFilter === null && props.dataFilter.length === 0){
            setCountActive(dataSource.filter((plug, index) => {
                return !plug.isarchived
            }).length);
            setCountFavorite(dataSource.filter((plug, index) => {
                return plug.favorite
            }).length);
            setCountArchived(dataSource.filter((plug, index) => {
                return plug.isarchived
            }).length);

            dataSource = dataSource.filter(data => {
                return data;
            });

        }

        setPlugnotes(dataSource);


    }, [props.plugnotes,props.globalDataFilter,props.dataFilter]);

    useEffect(() => {

        let checkExistingSelection = JSON.parse(localStorage.getItem('fieldsSelected'));

        if(!checkExistingSelection){

            if((JSON.stringify(defaultSelectedFields) !== JSON.stringify(selectedFields))){
                let fieldByModel = {
                    'hash' : props.model.hash,
                    'fields' : selectedFields,
                    'environnement' : environment.userhash,
                    'date' : moment().format('YYYY/MM/DD'),
                };

                setFieldsSelected(fieldByModel);
            }
        }
        else{
            let findIndex = checkExistingSelection.findIndex(item  => item.hash === props.model.hash);
            if(findIndex !== -1){
                if((JSON.stringify(defaultSelectedFields) !== JSON.stringify(selectedFields))){
                    let fieldByModel = {
                        'hash' : props.model.hash,
                        'fields' : selectedFields,
                        'environnement' : environment.userhash,
                        'date' : moment().format('YYYY/MM/DD'),
                    };

                    setFieldsSelected(fieldByModel);
                }else{
                    delSpecifiFieldsSelected(props.model.hash);
                }
            }else{
                if((JSON.stringify(defaultSelectedFields) !== JSON.stringify(selectedFields))){
                    let fieldByModel = {
                        'hash' : props.model.hash,
                        'fields' : selectedFields,
                        'environnement' : environment.userhash,
                        'date' : moment().format('YYYY/MM/DD'),
                    };

                    setFieldsSelected(fieldByModel);
                }
            }
        }

    }, [selectedFields]);

    useEffect(() => {
        if (props.modelInfo !== null){
            setModelBaseInfo(props.modelInfo)
        }
    }, [props.modelInfo]);

    useEffect(() => {
        if (props.model !== null){
            let currentModel = props.model;

            if(currentModel !== 'undefined' && currentModel !== null){
                const labels = Object.keys(currentModel).filter((key) => {
                    const regex = /field[0-9]+label/gi;
                    return regex.test(key);
                });

                let itemFields = labels.filter(Boolean);

                let currentItemFields = itemFields.map((item) => {
                    return currentModel[item];
                });

                setFields(currentItemFields.filter(Boolean));
            }

            if(currentModel !== 'undefined' && currentModel !== null){
                const labels = Object.keys(currentModel).filter((key) => {
                    const regex = /field[0-9]+label/gi;
                    return regex.test(key) && currentModel[key] != null;
                });

                let fields = labels.map((label, index) => {
                    let formatoptions = currentModel['field' + (index + 1) + 'formatoptions'] || {};
                    if (typeof formatoptions !== 'object') {
                        formatoptions = JSON.parse(formatoptions);
                    }

                    return {
                        key: index + 1,
                        id: index + 1,
                        label: currentModel['field' + (index + 1) + 'label'],
                        format: currentModel['field' + (index + 1) + 'format'],
                        options: {
                            mandatory: !!currentModel['field' + (index + 1) + 'mandatory'],
                            ...formatoptions
                        },
                        formatoptions: currentModel['field' + (index + 1) + 'formatoptions']
                    }
                });

                setFieldsInfos(fields);
            }
        }
    }, [props.model]);


    return (
        <div className={classes.root}>
            <CssBaseline />
            <main className={subOpen === false ? classes.content : classes.contentSub}>
                {
                    (props.isSearchActive || props.isDataSearchActive ) &&
                    <HeaderSearch />
                }
                {props.model !== null &&
                    <Header>
                    <div className="sous-header-without-height p1 grey">

                        <div className="sous-header_content sous-header_content_center">
                            <div style={{flex: 1}}>
                                <img className="logo" src={Images.logo} alt="logo" onClick={(event) => handleModels()}/>
                                {
                                    props.type !== 'demo' &&
                                    <Button className="grey" style={{textTransform: 'none'}}>
                                        <span className="grey">{environment.envIndex} - {environment.name}</span>
                                    </Button>
                                }

                            </div>
                            <div style={{flex: 1, textAlign: 'center'}}>
                                    <span className="titres">
                                    [{environment.envIndex} - {props.modelInfo.clef}] {props.modelInfo.label}
                                    </span>
                            </div>
                            <div style={{flex: 1}}>
                                <div className="grey display_flex display_flex_right float_right">
                                    <div className="padding_left">
                                        <input className="checkbox" type="checkbox" checked={actif}
                                               onChange={(event) => setActif(!actif)}/>
                                        {t('common:plugnote.plugnote-active')} ({countActive})
                                    </div>
                                    <div className="padding_left">
                                        <input className="checkbox" type="checkbox" checked={archived}
                                               onChange={(event) => setArchived(!archived)}/>
                                        {t('common:plugnote.plugnote-archived')} ({countArchived})
                                    </div>
                                    <div className="padding_left">
                                        <input className="checkbox" type="checkbox" checked={favoris}
                                               onChange={(event) => setFavoris(!favoris)}/>
                                        {t('common:plugnote.plugnote-favorite')} ({countFavorites})
                                    </div>
                                    <div className="padding_left">
                                            <span id="icon-close" className="material-icons md-30"
                                                  onClick={handleExitPlugList}>
                                                close
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="sous-header_content black">
                            <div className="line flex_style">
                            </div>
                        </div>
                    </div>
                </Header>
                }
                {props.model !== null &&
                    <HeaderInfos/>
                }

                { props.model !== null &&
                    <Content
                        className={!listView ? classes.contentModelGrid : !filterFieldsOpen ?  classes.contentModelList : classes.contentModelListOpenFilter}
                    >

                        { plugnotes.length > 0 &&
                        <ViewPlugnotes
                            plugnotes={plugnotes}
                            listView={listView}
                            actif={actif}
                            archived={archived}
                            favoris={favoris}
                            handleSelectPlugnote={handleSelectPlugnote}
                            handleCreatePlugnote={handleCreatePlugnote}
                            handleFlip={handleFlip}
                            flipped={flipped}
                            handleDeletePlugnote={handleDeletePlugnote}
                            handleFavorisPlugnote={handleFavorisPlugnote}
                            handleArchivePlugnote={handleArchivePlugnote}
                            handleLockPlugnote={handleLockPlugnote}
                            handleModalPlugnotePlugcodeOpen={handleModalPlugnotePlugcodeOpen}
                            handleModalPlugnotePlugcodeClose={handleModalPlugnotePlugcodeClose}
                            handleModalPlugnoteClassifyOpen={handleModalPlugnoteClassifyOpen}
                            attemptUpdatePlugnoteField={props.attemptUpdatePlugnoteField}
                            selectedFields={selectedFields}
                            attemptGetPlugnotesPaginateByModelHashAndUserHash={props.attemptGetPlugnotesPaginateByModelHashAndUserHash}
                            hash={hash}
                            modelhash={model.hash}
                            limitPage={props.end}
                            startPage={props.start}
                            setSelectedFields={setSelectedFields}
                            defaultSelectedFields={defaultSelectedFields}
                            setDefaultSelectedFields={setDefaultSelectedFields}
                            setFilters={setFilters}
                            setSubOpen={setSubOpen}
                            fields={fieldsInfos}
                            filters={filters}
                            attemptDelAllDataFilter={props.attemptDelAllDataFilter}
                            attemptDelDataFilter={attemptDelDataFilter}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                            sizeFields = {sizeFields}
                            setSizeFields ={setSizeFields}
                            defaultSizeFields = {defaultSizeFields}
                            setDefaultSizeFields = {setDefaultSizeFields}
                            props={props}
                        />
                        }
                        {/*                    { plugnotes.length > 0 &&
                    <span className="dot_fixed red" onClick={handleCreatePlugnote.bind(this)}>
                            <span id="icon-qrcode" className="material-icons icon-add_style">
                            add
                            </span>
                        </span>
                    }*/}

                        {/*                    { plugnotes.length === 0 && props.dataFilter.length === 0 && !promiseInProgress &&
                    <div className="centre dossier_vide grey">
                        <div className="titres_vide">
                            {t('plugnote.empty-plugnote', {label : model.label})}<br/>
                            {t('plugnote.new-plugnote')}
                        </div>
                        <span className="dot red centre">
                            <span id="icon-qrcode" className="material-icons white" style={{color:'white', fontSize:'60px'}} onClick={handleCreatePlugnote.bind(this)}>
                            add
                            </span>
                            </span>

                        <div className="titres_vide P1 cliquables">
                            {t('global.how-to-do')}
                        </div>
                    </div>
                    }*/}
                    </Content>
                }
            </main>

            { props.model !== null && isOpenModalModelPlugcode ?
                <ModalModelPlugcodeComponent open={isOpenModalModelPlugcode} handleModalModelPlugcodeClose={handleModalModelPlugcodeClose} close={handleModalModelPlugcodeClose}/>
                :
                null
            }
            { props.model !== null  && isOpenModalModelShare ?
                <ModalModelShareComponent open={isOpenModalModelShare} handleModalModelShareClose={handleModalModelShareClose} close={handleModalModelShareClose}/>
                :
                null
            }

            { props.model !== null  && isOpenModalExportSimpleCsv ?
                <ModalSimpleExportCsvComponent open={isOpenModalExportSimpleCsv} close={handleModalExportCsvClose} modelShared={false} payload={payload}/>
                :
                null
            }

            { props.model !== null  && isOpenModalTemplateImportExportCsv ?
                <ModalTemplateImportExportCsvComponent open={isOpenModalTemplateImportExportCsv} close={handleModalTemplateImportExportCsvClose} modelShared={false}/>
                :
                null
            }

            { props.model !== null  && isOpenModalImportExportCsv ?
                <ModalImportExportCsvComponent open={isOpenModalImportExportCsv} close={handleModalImportExportCsvClose} modelShared={false}/>
                :
                null
            }

            { getPlugnote()  && isOpenModalPlugnotePlugcode ?
                <ModalPlugnotePlugcodeComponent open={isOpenModalPlugnotePlugcode} close={handleModalPlugnotePlugcodeClose} modelShared={false}/>
                :
                null
            }

            {
                isOpenClassify &&
                <ModalCopyOrMovePlugnoteComponent open={isOpenClassify} close={handleModalPlugnoteClassifyClose}/>
            }

        </div>
    );
}

function PlugListEmptyComponent(props) {
    const { t, i18n } = useTranslation(['common', 'models', 'global']);

    const classes = useStyles();
    const environment = getCurrentEnvironnement();

    const [hash, setHash] = useState(environment.userhash);
    const [open, setOpen] = useState(false);
    const [subOpen, setSubOpen] = useState(false);

    return (
        <div className={classes.root}>
            <CssBaseline />
            <main className={subOpen === false ? classes.content : classes.contentSub}>
                {props.model !== null &&
                <Header>
                    <div className="sous-header-without-height p1 grey">

                        <div className="sous-header_content sous-header_content_center">
                            <div style={{flex: 1}}>
                                <img className="logo" src={Images.logo} alt="logo"/>
                                {
                                    props.type !== 'demo' &&
                                    <Button className="grey" style={{textTransform: 'none'}}>
                                        <span className="grey">{environment.envIndex} - {environment.name}</span>
                                    </Button>
                                }

                            </div>
                            <div style={{flex: 1, textAlign: 'center'}}>
                                    <span className="titres">
                                    [{environment.envIndex} - {props.modelInfo.clef}] {props.modelInfo.label}
                                    </span>
                            </div>
                            <div style={{flex: 1}}>
                                <div className="grey display_flex display_flex_right float_right">
                                    <div className="padding_left">
                                        <input className="checkbox" type="checkbox" checked={false} readOnly={true} />
                                        {t('common:plugnote.plugnote-active')} (0)
                                    </div>
                                    <div className="padding_left">
                                        <input className="checkbox" type="checkbox" checked={false} readOnly={true}/>
                                        {t('common:plugnote.plugnote-archived')} (0)
                                    </div>
                                    <div className="padding_left">
                                        <input className="checkbox" type="checkbox" checked={false} readOnly={true}/>
                                        {t('common:plugnote.plugnote-favorite')} (0)
                                    </div>
                                    <div className="padding_left">
                                            <span id="icon-close" className="material-icons md-30">
                                                close
                                            </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="sous-header_content black">
                            <div className="line flex_style">
                            </div>
                        </div>
                    </div>
                </Header>
                }
                {props.model !== null &&
                <HeaderInfos/>
                }
            </main>
        </div>
    );
}

function PlugListEditV2(props){

    const environment = getCurrentEnvironnement();

    useEffect(() => {
        deleteHistoryPlugAction();
        props.attemptGetPlugnotesByModelHashAndUserHash(getModel().hash, environment.userhash);
    }, [])

    if (props.model !== null){
        return(
            <PlugListEditComponent modelInfo={getModel()} {...props} />
        )
    }else {
        return(
            <PlugListEmptyComponent modelInfo={getModel()} {...props} />
        );
    }
}

// redux providing state takeover
const mapStateToProps = (state, props) => {

    let back = "";

    if(typeof props.location.state !== "undefined"){
        if(typeof props.location.state.back !== "undefined"){
            back = props.location.state.back;
        }
    }

    let datas = [];

    if(state.search.isSearchActive && state.search.isSearchResult && state.data.list.length > 0){
        state.data.list.map((item, index) => {
            let find = false;
            state.search.searchResult.map((search, index) => {
                if(item.hash === search.datahash){
                    find = true;
                }
            });
            if(find){
                datas.push(item);
            }
        });
    }

    if(!state.search.isSearchActive && !state.search.isSearchResult){
        datas = !! state.data.list ? state.data.list : [];
    }

    let dataFilter = [];
    let globalDataFilter = null;

    if(typeof state.data.dataFilter !== 'undefined' ){
        if(state.data.dataFilter.length > 0){
            dataFilter = state.data.dataFilter;
        }
    }

    if(typeof state.data.dataGlobalFilter !== 'undefined' ){
        if(state.data.dataGlobalFilter !== null){
            globalDataFilter = state.data.dataGlobalFilter;
        }
    }

    let datasSorted = datas.sort((a,b) => {
        return a.userindex - b.userindex;
    })

    return {
        back,
        model : state.model.model,
        fields : state.field.list,
        end : state.data.end,
        start : state.data.start,
        user : state.user,
        token : state.user.token,
        plugnotes : datasSorted.reverse(),
        models : !! state.model.datas ? state.model.datas : [],
        fetching : state.data.fetching,
        dataFilter : dataFilter,
        globalDataFilter : globalDataFilter,
        isSearchActive: state.search.isSearchActive,
        isDataSearchActive : state.data.isDataSearchActive,
        type : !!state.user && !!state.user.user  ? state.user.user.type : null,
        fetchingPassword : state.data.fetchingPassword,
        password : state.data.password
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptSetDataFilter: (dataFilter, key) => dispatch(reduxSetDataFilterRequest(dataFilter, key)),
        attemptDelDataFilter: (dataFilter) => dispatch(reduxDelDataFilterRequest(dataFilter)),
        attemptDelAllDataFilter: () => dispatch(reduxDelAllDataFilterRequest()),
        attemptGetPlugnotesByModelHashAndUserHash: (modelhash,userhash) => dispatch(getDatasLightRequest(modelhash,userhash)),
        attemptGetPlugnotesPaginateByModelHashAndUserHash: (modelhash,userhash,page) => dispatch(getDatasPaginateLightRequest(modelhash,userhash,page)),
        attemptDeletePlugote: (datahash, userhash) => dispatch(delDeleteDataRequest(datahash, userhash)),
        attemptUpdateFavorisPlugnote: (datahash, favorite, userhash) => dispatch(putUpdateDataFavorisRequest(datahash, favorite, userhash)),
        attemptUpdateLockPlugnote: (datahash, isarchived, locked, userhash) => dispatch(putUpdateDataLockRequest(datahash, isarchived, locked, userhash)),
        attemptUpdateArchivePlugnote: (datahash, isarchived, locked, userhash) => dispatch(putUpdateDataArchiveRequest(datahash, isarchived, locked, userhash)),
        attemptUpdatePlugnoteField: (modelhash, datahash, userhash, formData) => dispatch(putUpdateDataFieldRequest(modelhash, datahash, userhash, formData)),
        attemptResetModelPlugcode: () => dispatch(reduxGeneratePlugformcodeReset()),
        attemptResetPlugnotePlugcode: () => dispatch(reduxGeneratePlugnotecodeReset()),
        attemptResetModelShare: () => dispatch(reduxGenerateShareReset()),
        attemptResetAnnexe: () => dispatch(reduxGenerateAnnexeReset()),
        attemptRemoveModel :(modelhash,userhash) => dispatch(delModelUniqueRequest(modelhash, userhash)),
        attemptExportFullFiles:(modelhash, usermainhash, payloadhash) => dispatch(postExportFullFileDatasRequest(modelhash, usermainhash, payloadhash)),
        attemptDeleteDatas: (payloadhash, userhash) => dispatch(delDeleteDatasRequest(payloadhash, userhash)),
        attemptArchivesDatas: (payloadhash, userhash) => dispatch(putUpdateDatasArchiveRequest(payloadhash, userhash)),
        attemptUnArchivesDatas: (payloadhash, userhash) => dispatch(putUpdateDatasUnArchiveRequest(payloadhash, userhash)),
        attemptDelGlobalDataFilter: () => dispatch(reduxDelGlobalDataFilterRequest()),
        attemptGeneratePlugcodeForAllPlugnoteModel :(modelhash,userhash) => dispatch(postModelGenerateMultiplePlugcodeRequest(modelhash, userhash)),
        attemptResetDataPassword: () => dispatch(reduxGenerateDataPasswordReset()),
        attemptGetPasswordField: (datahash, fieldId, userhash) => dispatch(getDecryptPasswordRequest(datahash, fieldId, userhash)),
        attemptReplaceModel: (model,userhash,back) => dispatch(putReplaceModelRequest(model,userhash,back))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlugListEditV2)
