import React from 'react';
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";

import {Document, Image, Link, Page, PDFDownloadLink, StyleSheet, Text, View} from '@react-pdf/renderer';
import Images from "../../assets/themes/Images";

import ApplicationConfig from "../../config/ApplicationConfig";
import {getCurrentEnvironnement, getToken} from "../../utils/Common";
import axios from "axios";
import moment from "moment";
import {formatTextTypes} from "../../utils/Compatibility";
import {isNull} from "lodash";

const environment = getCurrentEnvironnement();


// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#E4E4E4',
        margin : 50
    },
    parent: {
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap'
    },
    child: {
        width: '20%',
        margin: '1%',
        aspectRatio: 1,
        maxHeight : "100px",
        alignItems : 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderColor : 'grey'
    },
    maxChild: {
        width: '69%',
        margin: '1%',
        borderWidth: 1,
        borderColor : 'grey'
    },
    minChild: {
        width: '5%',
        margin: '1%',
        alignItems : 'center',
        justifyContent: 'center',
        borderWidth: 1,
        borderColor : 'grey'
    },
    section: {
        margin: 10,
        flexGrow: 0,
    },
    sectionAnnexes : {
        margin: 10,
    },
    viewer: {
        paddingTop: 58,
        paddingBottom: 58,
    },
    paragraph: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        flexGrow: '1' /* Let it fill the entire space horizontally */
    },
    paragraphText : {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        flexGrow: '1' /* Let it fill the entire space horizontally */,
        marginBottom: 5
    },
    paragraphMain: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        flexGrow: '1', /* Let it fill the entire space horizontally */
        margin : 5
    },
    paragraphPng: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        flexGrow: '1', /* Let it fill the entire space horizontally */
        alignItems : 'flex-start',
        margin : 5
    },
    paragraphImage: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        flexGrow: '1', /* Let it fill the entire space horizontally */
        alignItems : 'center',
        margin : 5
    },
    paragraphTextAnnexes: {
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        flexGrow: '1', /* Let it fill the entire space horizontally */
        alignItems : 'flex-start',
        margin : 5
    },
    viewLeft: {
        display: "flex",
        flexWrap: "wrap",
        flex: '1' /* Let it fill the entire space horizontally */
    },
    viewRight: {
        display: "flex",
        flexWrap: "wrap",
        flex: '1' /* Let it fill the entire space horizontally */
    },
    viewFieldsLeft: {
        display: "flex",
        flexWrap: "wrap",
        flex: '1' /* Let it fill the entire space horizontally */,
        justifyContent: 'flex-start',
        flexDirection: "row",
    },
    viewFieldsRight: {
        display: "flex",
        flexWrap: "wrap",
        flex: '2' /* Let it fill the entire space horizontally */
    },
    viewTextRight: {
        display: "flex",
        flexWrap: "wrap",
        flex: '1' /* Let it fill the entire space horizontally */,
        textAlign: 'right'
    },
    text: {
        width: "auto",
        fontWeight : 500,
        fontSize: 14,
    },
    textInfos: {
        textAlign : 'right',
        width: "auto",
        fontWeight : 100,
        fontSize: 8,
    },
    textUnder: {
        width: "auto",
        fontWeight : 500,
        fontSize: 14,
        textDecoration: 'underline',
    },
    textThin: {
        width: "auto",
        fontWeight : 300,
        fontSize: 12,
    },
    textThinSmall:{
        width: "auto",
        fontWeight : 200,
        fontSize: 10,
    },
    textLink: {
        width: "auto",
        color : "green",
        fontWeight : 300,
        fontSize: 12,
    },
    image: {
        width: "50%",
    },
    icon: {
        width: "30",
        height: "30",
    },
    iconImg: {
        maxWidth: "150",
        maxHeight: "150",
        aspectRatio: 1,
    },
    title:{
        marginBottom : 10
    },
    footer: {
        position: 'absolute',
        width : '100%',
        bottom: 10,
        left: 0,
        right: 0,
        justifyContent: 'center',
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
    },
    footerText: {
        width : '60%',
        justifyContent: 'center',
        color: 'grey',
        textAlign : 'center',
        fontSize: 10,
    },
    footerTextLeft: {
        width : '20%',
        left : 10,
        fontSize: 10,
        justifyContent: 'center',
        textAlign : 'left',
        color: 'grey',
    },
    footerTextRight: {
        right : 10,
        width : '20%',
        fontSize: 10,
        justifyContent: 'center',
        textAlign : 'right',
        color: 'grey',
    },
    header: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
    },
    headerText: {
        textAlign: 'center',
        color: 'grey',
        fontSize: 12,
    },
    logo: {
        width: '186px'
    }
});

const useStyles = makeStyles((theme) => ({
    pointer:{
        cursor: 'pointer'
    }
}));

//http://test.plugnotes.com/uploads/uswE1SjDMGLkg0820759001492675386/daeMEUjoGm8Go0402037001604567020/somename%20(1).pdf

//downloadUrl

const downloadEmployeeData = (annexe) => {

    // Fetch the dynamically generated excel document from the server.
    return axios.get(ApplicationConfig.apiUrl + 'annexe.json?annexehash=' + annexe.hash, {crossdomain: true,
        'headers': {
            'X-AUTH-TOKEN': getToken(),
        }
    }).then((response) => {
        if(response.data.result){
            return response.data.base64; //Image Base64 Goes here
        }else{
            return false;
        }
    }).catch((response) => {
        return false;
    })
};


const getUrlPreviewAnnexe = (annexe, plugnote) => {
    return downloadEmployeeData(annexe);
    //return ApplicationConfig.downloadUrl + environment.userhash + "/" + plugnote.hash + "/" + encodeURIComponent(annexe.name) + "." + annexe.type
}

const getUrlPreviewAnnexeUrl = (annexe, plugnote) => {
    return ApplicationConfig.downloadUrl + environment.userhash + "/" + plugnote.hash + "/" + encodeURIComponent(annexe.name) + "." + annexe.type
}

const renderAnnexePreview = (annexeToPreview, plugnote, index) => {

    index++;

    switch (annexeToPreview.type.toLowerCase()) {
        case 'jpg':
        case 'JPG':
        case 'jpeg':
            return (
                <View style={[styles.parent]} key={index}>
                    <View style={[styles.minChild ]}><Text style={styles.text}>{index}</Text></View>
                    <View style={[styles.child ]}>
                        <Image src={getUrlPreviewAnnexe(annexeToPreview, plugnote)} style={styles.iconImg} />
                    </View>
                    <View style={[styles.maxChild ]}>
                        <View style={styles.paragraphImage}>
                            { typeof annexeToPreview.comment !== "undefined" && annexeToPreview.comment.length > 0 &&
                            <Text style={styles.textThin}>{annexeToPreview.comment}</Text>
                            }
                        </View>
                        <View style={styles.paragraphTextAnnexes}>
                            <Text style={styles.textThinSmall}>Nom du fichier : {annexeToPreview.name}</Text><br/>
                            <Text style={styles.textThinSmall}> ({'Type: ' + annexeToPreview.type + ' | Size: ' + ((annexeToPreview.size) / 1000).toFixed(2) + 'Kb' +  ' | Added: ' + (annexeToPreview.creationdate ? moment(annexeToPreview.creationdate).format('YY-MM-DD') : '') })</Text>
                        </View>
                    </View>
                </View>
            )
            break;
        case 'png':
        case 'PNG':
            return (
                <View style={[styles.parent]} key={index}>
                    <View style={[styles.minChild ]}><Text style={styles.text}>{index}</Text></View>
                    <View style={[styles.child ]}>
                        <Image src={getUrlPreviewAnnexe(annexeToPreview, plugnote)} style={styles.iconImg} />
                    </View>
                    <View style={[styles.maxChild ]}>
                        <View style={styles.paragraphImage}>
                            { typeof annexeToPreview.comment !== "undefined" && annexeToPreview.comment.length > 0 &&
                            <Text style={styles.textThin}>{annexeToPreview.comment}</Text>
                            }
                        </View>
                        <View style={styles.paragraphTextAnnexes}>
                            <Text style={styles.textThinSmall}>Nom du fichier : {annexeToPreview.name}</Text><br/>
                            <Text style={styles.textThinSmall}> ({'Type: ' + annexeToPreview.type + ' | Size: ' + ((annexeToPreview.size) / 1000).toFixed(2) + 'Kb' +  ' | Added: ' + (annexeToPreview.creationdate ? moment(annexeToPreview.creationdate).format('YY-MM-DD') : '') })</Text>
                        </View>
                    </View>
                </View>
            )
            break;
        case 'gif':
            return (
                <View style={[styles.parent]} key={index}>
                    <View style={[styles.minChild ]}><Text style={styles.text}>{index}</Text></View>
                    <View style={[styles.child ]}>
                        <Image src={Images.gifPng} style={styles.icon} />
                    </View>
                    <View style={[styles.maxChild ]}>
                        <View style={styles.paragraphImage}>
                            { typeof annexeToPreview.comment !== "undefined" && annexeToPreview.comment.length > 0 &&
                            <Text style={styles.textThin}>{annexeToPreview.comment}</Text>
                            }
                        </View>
                        <View style={styles.paragraphTextAnnexes}>
                            <Text style={styles.textThinSmall}>Nom du fichier : {annexeToPreview.name}</Text><br/>
                            <Text style={styles.textThinSmall}> ({'Type: ' + annexeToPreview.type + ' | Size: ' + ((annexeToPreview.size) / 1000).toFixed(2) + 'Kb' +  ' | Added: ' + (annexeToPreview.creationdate ? moment(annexeToPreview.creationdate).format('YY-MM-DD') : '') })</Text>
                        </View>
                    </View>
                </View>
            )
            break;

        /*        case 'mp4':
                case 'mkv':
                case 'avi':
                case 'mov':
                    return <Svg width={50}><FontAwesomeIcon icon={faFileVideo} size="lg" color='green' style={{fontSize : '50', marginRight : '10'}}  /></Svg>;
                    break;*/

        case 'pdf':
            return (
                <View style={[styles.parent]} key={index}>
                    <View style={[styles.minChild ]}><Text style={styles.text}>{index}</Text></View>
                    <View style={[styles.child ]}>
                        <Image src={Images.pdfPng} style={styles.icon} />
                    </View>
                    <View style={[styles.maxChild ]}>
                        <View style={styles.paragraphImage}>
                            { typeof annexeToPreview.comment !== "undefined" && annexeToPreview.comment.length > 0 &&
                            <Text style={styles.textThin}>{annexeToPreview.comment}</Text>
                            }
                        </View>
                        <View style={styles.paragraphTextAnnexes}>
                            <Text style={styles.textThinSmall}>Nom du fichier : {annexeToPreview.name}</Text><br/>
                            <Text style={styles.textThinSmall}> ({'Type: ' + annexeToPreview.type + ' | Size: ' + ((annexeToPreview.size) / 1000).toFixed(2) + 'Kb' +  ' | Added: ' + (annexeToPreview.creationdate ? moment(annexeToPreview.creationdate).format('YY-MM-DD') : '') })</Text>
                        </View>
                    </View>
                </View>
            )
            break;

        /*        case 'zip':
                case 'rar':
                    return <Svg width={50}><FontAwesomeIcon icon={faFileArchive} size="lg" color='green' style={{fontSize : '50', marginRight : '10'}}  /></Svg>;
                    break;

                case 'mp3':
                case 'aac':
                    return <Svg width={50}><FontAwesomeIcon icon={faFileAudio} size="lg" color='green' style={{fontSize : '50', marginRight : '10'}}   /></Svg>;
                    break;

                case 'html':
                    return <Svg width={50}><FontAwesomeIcon icon={faFileCode} size="lg" color='green' style={{fontSize : '50', marginRight : '10'}}  /></Svg>;
                    break;*/

        case 'txt':
        case 'sheet':
        case 'doc':
        case 'docx':
        case 'pages':
            return (
                <View style={[styles.parent]} key={index}>
                    <View style={[styles.minChild ]}><Text style={styles.text}>{index}</Text></View>
                    <View style={[styles.child ]}>
                        <Image src={Images.docPng} style={styles.icon} />
                    </View>
                    <View style={[styles.maxChild ]}>
                        <View style={styles.paragraphImage}>
                            { typeof annexeToPreview.comment !== "undefined" && annexeToPreview.comment.length > 0 &&
                            <Text style={styles.textThin}>{annexeToPreview.comment}</Text>
                            }
                        </View>
                        <View style={styles.paragraphTextAnnexes}>
                            <Text style={styles.textThinSmall}>Nom du fichier : {annexeToPreview.name}</Text><br/>
                            <Text style={styles.textThinSmall}> ({'Type: ' + annexeToPreview.type + ' | Size: ' + ((annexeToPreview.size) / 1000).toFixed(2) + 'Kb' +  ' | Added: ' + (annexeToPreview.creationdate ? moment(annexeToPreview.creationdate).format('YY-MM-DD') : '') })</Text>
                        </View>
                    </View>
                </View>
            )
            break;

        /*        case 'xls':
                case 'xlsx':
                case 'csv':
                    return <Svg width={50}><FontAwesomeIcon icon={faFileExcel} size="lg" color='green' style={{fontSize : '50', marginRight : '10'}}  /></Svg>;
                    break;*/

        case 'note':
            return (
                <View style={[styles.parent]} key={index}>
                    <View style={[styles.minChild ]}><Text style={styles.text}>{index}</Text></View>
                    <View style={[styles.child ]}>
                        <Image src={Images.notePng} style={styles.icon} />
                    </View>
                    <View style={[styles.maxChild ]}>
                        <View style={styles.paragraphImage}>
                            { typeof annexeToPreview.comment !== "undefined" && annexeToPreview.comment.length > 0 &&
                            <Text style={styles.textThin}>{annexeToPreview.comment}</Text>
                            }
                        </View>
                        <View style={styles.paragraphTextAnnexes}>
                            <Text style={styles.textThinSmall}>Nom du fichier : {annexeToPreview.name}</Text><br/>
                            <Text style={styles.textThinSmall}> ({'Type: ' + annexeToPreview.type +  ' | Created: ' + (annexeToPreview.creationdate ? moment(annexeToPreview.creationdate).format('YY-MM-DD') : '') })</Text>
                        </View>
                    </View>
                </View>
            )
            break;

        case 'pptx':
        case 'ppt':
            return (
                <View style={[styles.parent]} key={index}>
                    <View style={[styles.minChild ]}><Text style={styles.text}>{index}</Text></View>
                    <View style={[styles.child ]}>
                        <Image src={Images.pptPng} style={styles.icon} />
                    </View>
                    <View style={[styles.maxChild ]}>
                        <View style={styles.paragraphImage}>
                            { typeof annexeToPreview.comment !== "undefined" && annexeToPreview.comment.length > 0 &&
                            <Text style={styles.textThin}>{annexeToPreview.comment}</Text>
                            }
                        </View>
                        <View style={styles.paragraphTextAnnexes}>
                            <Text style={styles.textThinSmall}>Nom du fichier : {annexeToPreview.name}</Text><br/>
                            <Text style={styles.textThinSmall}> ({'Type: ' + annexeToPreview.type + ' | Size: ' + ((annexeToPreview.size) / 1000).toFixed(2) + 'Kb' +  ' | Added: ' + (annexeToPreview.creationdate ? moment(annexeToPreview.creationdate).format('YY-MM-DD') : '') })</Text>
                        </View>
                    </View>
                </View>
            )
            break;
        /*        default :
                    return <Svg width={50}><FontAwesomeIcon icon={faFile} size="lg" color='green' style={{fontSize : '50', marginRight : '10'}}/></Svg>;
                    break;*/

        default:
            return (
                <View style={[styles.parent]} key={index}>
                    <View style={[styles.minChild ]}><Text style={styles.text}>{index}</Text></View>
                    <View style={[styles.child ]} />
                    <View style={[styles.maxChild ]}>
                        <View style={styles.paragraphImage}>
                            { typeof annexeToPreview.comment !== "undefined" && annexeToPreview.comment.length > 0 &&
                            <Text style={styles.textThin}>{annexeToPreview.comment}</Text>
                            }
                        </View>
                        <View style={styles.paragraphTextAnnexes}>
                            <Text style={styles.textThinSmall}>Nom du fichier : {annexeToPreview.name}</Text><br/>
                            <Text style={styles.textThinSmall}> ({'Type: ' + annexeToPreview.type + ' | Size: ' + ((annexeToPreview.size) / 1000).toFixed(2) + 'Kb' +  ' | Added: ' + (annexeToPreview.creationdate ? moment(annexeToPreview.creationdate).format('YY-MM-DD') : '') })</Text>
                        </View>
                    </View>
                </View>
            )
            break;
    }
};

const renderFieldPreview = (value, format, options, field, model, mode) => {

    const setupbis = field.options?.setupbis  || null;


    switch (format) {
        case 'intdatamaj':
            if (value !== '') {
                try {
                    if(value.hasOwnProperty('value') && value.hasOwnProperty('updatedDate')){
                        return (
                            <View style={styles.viewFieldsRight}>
                                <Text  style={styles.textThin}>{value.value} {value.updatedDate !== "" ? value.updatedDate : ""}</Text>
                            </View>
                        )
                    }
                    else {
                        return (
                            <View style={styles.viewFieldsRight}>
                                <Text  style={styles.textThin}/>
                            </View>
                        )
                    }
                }catch (e) {
                    return (
                        <View style={styles.viewFieldsRight}>
                            <Text  style={styles.textThin}/>
                        </View>
                    )
                }
            }
            else {
                return (
                    <View style={styles.viewFieldsRight}>
                        <Text  style={styles.textThin}/>
                    </View>
                )
            }
        case 'qrcodeint' :
            let qrcodeintComment = typeof value.comment !==  "undefined" ? value.comment : "";
            let qrcodeintCode = typeof value.code !==  "undefined" ? value.code : "";
            if(qrcodeintComment != "" && qrcodeintCode != ""){
                let url = ApplicationConfig.templateUrl + '?code=' + qrcodeintCode;
                return(
                    <View style={styles.viewFieldsRight}>
                        <Text style={styles.textThin}>{qrcodeintComment}</Text>
                        <Link src={url} target="_blank"><Text style={styles.textThin}>{url}</Text></Link>
                    </View>
                )
            }else if(qrcodeintComment !== ""){
                return(
                    <View style={styles.viewFieldsRight}>
                        <Text style={styles.textThin}>{qrcodeintComment}</Text>
                    </View>
                )
            }else if(qrcodeintCode !== ""){
                let url = ApplicationConfig.templateUrl + '?code=' + qrcodeintCode;
                return(
                    <View style={styles.viewFieldsRight}>
                        <Link src={url} target="_blank"><Text style={styles.textThin}>{url}</Text></Link>
                    </View>
                );
            }else{
                return(
                    <View style={styles.viewFieldsRight}>
                        <Text style={styles.textThin}/>
                    </View>
                )
            }
            break;
        case 'document':
        case 'tablemultin':
            if (value !== '') {
                try {
                    if(value.hasOwnProperty('value') && value.hasOwnProperty('list')){
                        let formatOptions = typeof options !== "undefined" ?  JSON.parse(options) : field.options;
                        let concatValueTableMultiArray = [];
                        value.list.map((item, index) => {
                            let concatValueTableMulti = formatOptions.lines.map((option, indexOptions) => {

                                let visible = true;

                                if(!isNull(setupbis) && (mode.includes('shared') && setupbis[indexOptions].visible === false)){
                                    visible = false;
                                }

                                if(visible){
                                    if(option.id !== ""){
                                        if(Array.isArray(item[option.id]) && item[option.id].length){
                                            if(typeof item[option.id] !== "undefined"){
                                                let tmp = item[option.id].join(',') ;
                                                if(formatOptions.lines.length !== (indexOptions + 1)){
                                                    tmp = tmp.concat(' ~ ');
                                                }
                                                return tmp
                                            }
                                        }
                                        else{
                                            if(typeof item[option.id] !== "undefined"){
                                                let tmp =  item[option.id].toString();

                                                if(option.format == 'file-uri' && formatOptions.lines[0].format == 'file-source' && item[formatOptions.lines[0].id] == 'FTP'){
                                                    tmp = "********"
                                                }

                                                if(tmp !== undefined){
                                                    if(formatOptions.lines.length !== (indexOptions + 1)){
                                                        tmp = tmp.concat(' ~ ');
                                                    }
                                                }
                                                return tmp
                                            }
                                        }
                                    }

                                }

                            });

                            concatValueTableMultiArray.push(concatValueTableMulti);

                        });
                        return (
                            <View style={styles.viewFieldsRight}>
                                <Text  style={styles.textThin}>{value.value}</Text>
                                {concatValueTableMultiArray.map((item, index) => {
                                    return <Text key={index} style={styles.textThin}>{item}</Text>;
                                })}
                            </View>
                        )
                    }
                    else {
                        return (
                            <View style={styles.viewFieldsRight}>
                                <Text  style={styles.textThin}/>
                            </View>
                        )
                    }
                }catch (e) {
                    return (
                        <View style={styles.viewFieldsRight}>
                            <Text  style={styles.textThin}/>
                        </View>
                    )
                }
            } else {
                return (
                    <View style={styles.viewFieldsRight}>
                        <Text  style={styles.textThin}/>
                    </View>
                )
            }
        case 'smailvalidmulti':
            if (value !== '') {
                try {
                    if(value.hasOwnProperty('value') && value.hasOwnProperty('list')){
                        let formatOptions = typeof options !== "undefined" ?  JSON.parse(options) : field.options;
                        let concatValueTableMultiArray = [];
                        value.list.map((item, index) => {
                            let concatValueTableMulti = formatOptions.lines.map((option, indexOptions) => {


                                let visible = true;

                                if(!isNull(setupbis) && (mode.includes('shared') && setupbis[indexOptions].visible === false)){
                                    visible = false;
                                }

                                if(visible){
                                    if(option.id !== ""){
                                        if(Array.isArray(item[option.id]) && item[option.id].length){
                                            if(typeof item[option.id] !== "undefined"){
                                                let tmp = item[option.id].join(',') ;
                                                if(formatOptions.lines.length !== (indexOptions + 1)){
                                                    tmp = tmp.concat(' ~ ');
                                                }
                                                return tmp
                                            }
                                        }else{
                                            if(typeof item[option.id] !== "undefined"){
                                                let tmp =  item[option.id];
                                                if(formatOptions.lines.length !== (indexOptions + 1)){
                                                    tmp = tmp.concat(' ~ ');
                                                }
                                                return tmp
                                            }
                                        }
                                    }

                                }

                            });

                            concatValueTableMultiArray.push(concatValueTableMulti);

                        });
                        return (
                            <View style={styles.viewFieldsRight}>
                                <Text  style={styles.textThin}>{value.value}</Text>
                                {concatValueTableMultiArray.map((item, index) => {
                                    return <Text key={index} style={styles.textThin}>{item}</Text>;
                                })}
                            </View>
                        )
                    }
                    else {
                        return (
                            <View style={styles.viewFieldsRight}>
                                <Text  style={styles.textThin}/>
                            </View>
                        )
                    }
                }catch (e) {
                    return (
                        <View style={styles.viewFieldsRight}>
                            <Text  style={styles.textThin}/>
                        </View>
                    )
                }
            } else {
                return (
                    <View style={styles.viewFieldsRight}>
                        <Text  style={styles.textThin}/>
                    </View>
                )
            }
        case 'tablemulti':
            let formatOptions = typeof options !== "undefined" ?  JSON.parse(options) : field.options;

            let concatValueTableMultiArray = [];

            value.map((item, index) => {
                let optionsFormat = formatOptions?.lines ? formatOptions?.lines : formatOptions;

                let concatValueTableMulti = optionsFormat.map((option, indexOptions) => {

                    let visible = true;

                    if(!isNull(setupbis) && (mode.includes('shared') && setupbis[indexOptions].visible === false)){
                        visible = false;
                    }

                    if(visible){
                        if(option.id !== ""){
                            if(Array.isArray(item[option.id]) && item[option.id].length){
                                return item[option.id].join(',') + ' ~ ';
                            }else{
                                let finalValue = item[option.id];
                                if(finalValue !== undefined){
                                    return finalValue + ' ~ '
                                }
                            }
                        }
                    }
                });

                concatValueTableMultiArray.push(concatValueTableMulti);

            });

            return(
                <View style={styles.viewFieldsRight}>
                    {concatValueTableMultiArray.map((item, index) => {
                        return <Text key={index} style={styles.textThin}>{item}</Text>;
                    })}
                </View>
            );
            break;
        case 'boolean':
            return(
                <View style={styles.viewFieldsRight}>
                    <Text  style={styles.textThin}>{value === "1" ? 'oui/yes' : 'non/no'}</Text>
                </View>
            )
            break;
        case 'single-table':
        case 'free-single-table':
            let concatValueSingleTable = value.map((item, index) => {
                if(typeof item.text !== "undefined"){
                    if(index < (value.length - 1 )){
                        return item.text + ' ~ ' + item.value + ' , '
                    }else{
                        return item.text + ' ~ ' + item.value
                    }
                }
            })
            return(
                <View style={styles.viewFieldsRight}>
                    <Text  style={styles.textThin}>{concatValueSingleTable}</Text>
                </View>
            )
            break;
        case 'multi-table':
        case 'free-multi-table':
            let concatValueMultipleTable = value.map((item,index) => {
                if(typeof item.text !== "undefined"){
                    if(index < (value.length - 1 )){
                        return item.text + ' ~ ' + item.value + ' , '
                    }else{
                        return item.text + ' ~ ' + item.value
                    }
                }
            })
            return(
                <View style={styles.viewFieldsRight}>
                    <Text  style={styles.textThin}>{concatValueMultipleTable}</Text>
                </View>
            )
            break;
        case 'numeric':
            return (
                <View style={styles.viewFieldsRight}>
                    <Text style={styles.textThin}>{isNaN(value) ? "" : value}</Text>
                </View>
                )
        case 'pluglink':
            if(typeof value.code !== 'undefined' &&  value.code !== '' && value.userindex !== 'undefined' &&  value.userindex !== ''){
                let source = ApplicationConfig.plugcodesUrl + value.code.toString() + '/' + value.clef.toString() +'/' + value.userindex.toString()
                if(typeof model !== "undefined" && !isNull(model)){
                    source = ApplicationConfig.plugcodesUrl + value.code.toString() + '/' + value.clef.toString() +'/' + value.userindex.toString() + '/' + model.hash.toString();
                }
                return(
                    <View style={styles.viewFieldsRight}>
                        <Link src={source} target="_blank"><Text style={styles.textThin}>{value.code.toString() + '-' + value.clef.toString() +'-' + value.userindex.toString()}</Text></Link>
                    </View>
                )
            }else{
                return(
                    <View style={styles.viewFieldsRight}>
                        <Text style={styles.textThin}/>
                    </View>
                )
            }
            break;
        case 'plugform' :
            let plugformValue = typeof value.value !==  "undefined" ? value.value : "";
            return(
                <View style={styles.viewFieldsRight}>
                    <Text  style={styles.textThin}>{plugformValue}</Text>
                </View>
            )
            break;
        case 'extdata' :
            let extdataValue = typeof value.value !==  "undefined" ? value.value : "";
            return(
                <View style={styles.viewFieldsRight}>
                    <Text  style={styles.textThin}>{extdataValue}</Text>
                </View>
            )
            break;
        case 'password':
            return(
                <View style={styles.viewFieldsRight}>
                    <Text  style={styles.textThin}>********</Text>
                </View>
            )
            break;
        case 'url':
            return(
                <View style={styles.viewFieldsRight}>
                    <Text  style={styles.textThin}>{value}</Text>
                    {/*
                        <Link src={value}><Text  style={styles.textThin}>Cliquer ici</Text></Link>
                        */}
                </View>
            )
        default:
            return(
                <View style={styles.viewFieldsRight}>
                    <Text  style={styles.textThin}>{value}</Text>
                </View>
            )
            break;

    }


}
const imagesListType = ['jpg', 'jpeg', 'png'];


const MyDoc = (props) => {

    let properties = props.props;
    let environnement = getCurrentEnvironnement();
    let user = typeof properties.user.profil !== "undefined" ? properties.user.profil : null;
    let currentPlugnote = properties !==null ? properties.plugnote : undefined;
    let userIndex = typeof currentPlugnote !== "undefined" ?  currentPlugnote.userindex : null;
    let envIndex = typeof currentPlugnote !== "undefined" ? environnement.envIndex : null;
    let lastUpdate = typeof currentPlugnote !== "undefined" && typeof currentPlugnote.moddate !== "undefined" ? moment(currentPlugnote.moddate).format('YY-MM-DD') : null;

    let model = properties.model;
    let modelName = model.label;
    let modelClef = model.clef;
    let creator = typeof currentPlugnote !== "undefined" && currentPlugnote.externalowner !== null ? currentPlugnote.username : null

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    today = mm + '/' + dd + '/' + yyyy;

    return (
        <Document>
            <Page size="A4" style={styles.viewer} wrap>
                <View style={styles.header} fixed>
                    <Image style={styles.logo} src={Images.logo}/>
                </View>

                <View style={styles.section}>
                    <View  style={styles.paragraph}>
                        <View style={styles.viewTextRight}>
                            { user !== null && <Text  style={styles.textThin}>{user.lastname} {user.firstname} - {today}</Text>}
                            { user === null && <Text  style={styles.textThin}>{today}</Text>}
                        </View>
                    </View>
                </View>
                <View style={styles.section}>
                    <View  style={styles.paragraph}>
                        <View style={styles.viewFieldsLeft}>
                            <Text  style={styles.text}>Plugnotes N°:</Text>
                        </View>
                        <View style={styles.viewFieldsRight}>
                            { userIndex !== null && envIndex !== null  &&
                            <Text  style={styles.textThin}>{envIndex} - {userIndex}</Text>
                            }
                        </View>
                    </View>
                    <View  style={styles.paragraph}>
                        <View style={styles.viewFieldsLeft}>
                            <Text  style={styles.text}>Folder N°:</Text>
                        </View>
                        <View style={styles.viewFieldsRight}>
                            <Text  style={styles.textThin}>[{modelClef}] {modelName}</Text>
                        </View>
                    </View>
                    {
                        creator !== null &&
                        <View  style={styles.paragraph}>
                            <View style={styles.viewFieldsLeft}>
                                <Text  style={styles.text}>De / From :</Text>
                            </View>
                            <View style={styles.viewFieldsRight}>
                                <Text  style={styles.textThin}>{creator}</Text>
                            </View>
                        </View>
                    }
                    {
                        lastUpdate !== null &&
                        <View  style={styles.paragraph}>
                            <View style={styles.viewFieldsLeft}>
                                <Text  style={styles.text}>Date dernière MAJ:</Text>
                            </View>
                            <View style={styles.viewFieldsRight}>
                                <Text  style={styles.textThin}>{lastUpdate}</Text>
                            </View>
                        </View>
                    }

                </View>
                <View style={styles.section}>
                    <View style={styles.title}>
                        <Text  style={styles.textUnder}>Contenu de la Plugnote:</Text>
                    </View>
                    {
                        properties.fields.map((field, index) => {
                            let tmpValue = typeof field.value !== 'undefined' ? field.value.toString() : '';
                            let length = field.format === 'numeric' && tmpValue === 'NaN' ? 0 : tmpValue.length;
                            if(typeof tmpValue === 'string' && Object(tmpValue) && tmpValue !== null && field.format === 'plugform'){
                                let tmp = field.value;
                                if(tmp.hasOwnProperty('value')){
                                    length = tmp.value.length;
                                }else{
                                    length = 0;
                                }
                            }

                            let visible = true;
                            let setup = field.options?.setup;

                            if(!isNull(setup) && typeof setup !== "undefined"){
                                if(setup.hasOwnProperty('visible')){
                                    visible = setup.visible;

                                }
                            }

                            if(length !== 0 && visible){
                                return(
                                    <View  style={styles.paragraphText} key={index}>
                                        <View style={styles.viewFieldsLeft}>
                                            <View style={{flex : 0.6}}>
                                                <Text  style={styles.text}>{field.title}</Text>
                                            </View>
                                            <View style={{flex : 0.3}}>
                                                <Text style={styles.textInfos}>({(index + 1)}-{formatTextTypes[field.format.toUpperCase()]}) <Text style={styles.text}> : </Text> </Text>
                                            </View>
                                            <View style={{flex : 0.1}}/>
                                        </View>
                                        {
                                            renderFieldPreview(field.value, field.format, model['field'+(index+1)+'formatoptions'], field, model, properties.mode)
                                        }
                                    </View>

                                )
                            }else{
                                return null;
                            }
                        })
                    }
                </View>
                {
                    properties.annexes.length > 0 &&
                    <View style={styles.sectionAnnexes}>
                        <View style={styles.title}>
                            <Text  style={styles.textUnder}>Annexes:</Text>
                        </View>
                        {
                            properties.annexes.map((annexe, index) => {
                                return (renderAnnexePreview(annexe, properties.plugnote, index))
                            })
                        }
                    </View>
                }

                <View style={styles.footer} fixed>
                    <Text style={styles.footerTextLeft}>www.plugnotes.com</Text>
{/*                    <Text style={styles.footerText} render={({ pageNumber, totalPages }) => (
                        `${pageNumber} / ${totalPages}`
                    )}/>*/}
                    <Text style={styles.footerTextRight}>Layout : 1006</Text>
                </View>
            </Page>
        </Document>
    )
}


function Data1006Printable(props) {

    const { t, i18n } = useTranslation(['common', 'models']);
    const classes = useStyles();

    const handleDownloadHtmlContent = () => {

    }

    /*    const [instance, updateInstance] = usePDF({ document: MyDoc });

        if (instance.loading) return <div style={{display : 'flex'}}> <span className="material-icons md-25 "> sync_alt </span><div className={classes.pointer}>{t('common:global.print-sync') }</div></div>;

        if (instance.error) return <div>Something went wrong: {instance.error}</div>;

        console.log(instance.url);

        return (
            <a href={instance.url} download="test.pdf" style={{textDecoration: 'none', color : 'black'}}>
                <div style={{display : 'flex'}}> <span className="material-icons md-25 "> picture_as_pdf </span><div className={classes.pointer}>{t('common:global.print') }</div></div>
            </a>
        );*/

    return (
        <PDFDownloadLink document={<MyDoc props={props}/>} className={classes.pointer} fileName={props.filename+'.pdf'} style={{textDecoration : 'none', color : 'black'}}>
            {({ blob, url, loading, error }) =>
                loading ? <div style={{display : 'flex'}}> <span className="material-icons md-25 "> sync_alt </span><div className={classes.pointer}>{t('common:global.print-sync') }</div></div> :  <div style={{display : 'flex'}}> <span className="material-icons md-25 "> picture_as_pdf </span><div className={classes.pointer}>{t('common:global.print') }</div></div>
            }
        </PDFDownloadLink>
    );
}

export default Data1006Printable;
