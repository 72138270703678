import { useEffect, useState } from "react"
import { trackPromise } from "react-promise-tracker"
import ApplicationConfig from "../config/ApplicationConfig"
import axios from "axios"
import { getToken } from "../utils/Common"
import { devLog } from "../utils/utils"

const useFetchExtdata = (db, userhash) => {
  const [isReady, setReady] = useState(false)
  const [extdata, setExtdata] = useState(null)

  const fetchPreview = () => {
    trackPromise(
      axios.post(ApplicationConfig.apiUrl + 'extdata/uniques/datas.json', {
        file: db,
        userhash: userhash,
        record: '1',
        limit: '5'
      }, {
          crossdomain: true,
          'headers': {
              'X-AUTH-TOKEN': getToken(),
          }
      }).then((response) => {
        setExtdata(response.data.data);
        setReady(true)
      }).catch((response) => {
          console.error(
              "Could not download the Extdata from the backend.",
              response
          );
      }))
  }

  useEffect(() => {
    if (db?.length > 0) fetchPreview()
    // devLog('db name', db)
    const aborter = new AbortController()

    return () => aborter.abort()
  }, [db])

  return { extdata, isReady, db }
}

export default useFetchExtdata