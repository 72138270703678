import React, {useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import {useTranslation} from "react-i18next";
import {Close, Search} from "@material-ui/icons";
import colors from "../../assets/themes/Colors";
import {isIterableArray} from "../../utils/utils";
import {isNull} from "lodash";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%',
    },
    margin_left_5:{
        marginLeft : '5px',
    },
    input_add_item:{
        flexDirection: 'row',
        display: 'flex',
        border: "solid #E3E3E3 1px",
        borderRadius: "6px",
        height:42,
        padding: "2px 15px",
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    textField: {
        fontWeight: '500',
        borderRadius: '5px',
        padding: '0 10px',
        backgroundColor: colors.greyLighter,
        fontSize: '14px',
        flexGrow: 1,
        border: 'none',
    },
    multipleAdd: {
        width : '35%',
        display: 'flex',
        alignItems: 'center',
        gap: 5,
    },
    multipleAddButton: {
        width : 20,
        height : 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid',
        borderColor: colors.main,
        color: colors.main,
        borderRadius: 50,
        fontSize: 16,
    },
    multipleItemContainer: {
        flexDirection: 'row',
        // width: '100%',
        display: 'flex',
        // border: '1px solid #E3E3E3',
        borderRadius: "6px",
        // padding: "10px 15px",
        //marginTop: 10,
        flexWrap: 'wrap',
        gap: 5,
        alignItems: 'flex-start',
    },
    multipleItem: {
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "6px",
        backgroundColor: colors.unselectedAlpha,
        color: colors.white,
        // fontWeight: '900px',
        padding: "5px 10px 5px",
        gap: 5,
    },
    multipleInput: {
        // border: '1px solid red',
        textAlign: 'center',
        // padding: 5,
        margin: 0,
        color: colors.white,
        cursor: 'pointer',
    },
    multipleDelButton: {
        width : 20,
        height : 18,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 100,
        borderRadius: 50,
        fontSize: '28px',
    },
    multipleOutlinedButton: {
        height: 20,
        width: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid',
        // borderColor: colors.white,
        // color: colors.white,
        borderRadius: 50,
        fontSize: '14px',
    },
    multipleItemSearchContainer: {
        flexDirection: 'row',
        width: '100%',
        display: 'flex',
        // border: '1px solid #E3E3E3',
        borderRadius: "6px",
        // padding: "10px 15px",
        marginTop: 10,
        gap: 5,
        alignItems: 'center',
    },
    multipleItemSearchContainerOpen: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        // border: '1px solid #00F',
        borderRadius: "6px",
        // padding: "10px 15px",
        cursor: 'pointer',
        flexWrap: 'wrap',
        gap: 5,
        alignItems: 'flex-start',
    },
    multiplePresetText: {
        color: colors.grey,
        fontSize: 14,
    },
    multipleItemPresetOpen: {
        display: 'flex',
        gap: 5,
        alignItems: 'flex-start',
        //border: '1px solid green',
        width: '100%',
        padding: '0 10px 0 0'
    },
    multipleSearchButton: {
        color: colors.main,
    },
    multipleCleanButton: {
        width : 20,
        height : 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid',
        borderColor: colors.greyDark,
        color: colors.greyDark,
        borderRadius: 50,
    },
    multipleCheckBoxGroup: {
        //color: colors.main,
        display: 'flex',
        alignItems: 'center',
        gap: 5,

    },
    multipleCheckBox: {
        color: colors.main,
        width: 15,
        height: 15,
    },
    selectedItem: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        borderRadius: "6px",
        backgroundColor: colors.selectedAlpha,
        color: colors.white,
        padding: "5px 10px 5px",
        gap: 5,
        minHeight: 42,
    },
    unselectedItem: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'left',
        borderRadius: "6px",
        minHeight: '42px',
        backgroundColor: colors.unselectedAlpha,
        color: colors.white,
        padding: "5px 10px 5px",
        gap: 5,
        height: "100%",
    },
   endButtonContainer:{
        cursor: 'pointer',
        maxHeight: '31px',
        padding: '3px 14px',
        borderRadius: "3px",
        backgroundColor: colors.main,
        alignItems: 'center',
        display: 'inline-block',
        color: 'white',
    },
    mainContainer:{
        minHeight: '40px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },

}));


const FormatMultipleSelectComponent = (props) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation(['common']);

    const [newSelected, setNewSelected] = useState([]);

    const [selected, setSelected] = useState(props.field.value.length > 0 ? props.field.value : []);
    const [newValue, setNewValue] = useState("");
    const [disabled, setDisabled] = React.useState(typeof props.disabled === 'undefined' ? false: props.disabled);
// const [open, setOpen] = useState(typeof props.expand === 'undefined' ? false : props.expand);
    const [open, setOpen] = useState(props.field.visualDefault ? props.field.visualDefault : false);
    const [value, setValue] = useState('');
    const [setupbis, setSetubis] = useState(props.field?.options?.setupbis);


    const items = props.field?.options?.presel=== undefined || !isIterableArray(props.field?.options?.presel) ? [] : [...props.field.options.presel]
    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    useEffect(() => {
        setSelected(props.field.value.length > 0 ? props.field.value : []);
    },[props.field.value])

    const handleAddValue = (value) => {
        if(!items.includes(value) && value.trim().length !== 0){
            items.push(value)
            setSelected([...selected, value]);
            props.field.options.presel.push(value);
            props.handleChangeRadio(value, props.field.options.presel.length - 1,props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
            setNewSelected(newSelected.filter(item => item !== value));
        }
        setNewValue('');
    }

    const handleRemoveValue = (value) => {
        if(items.includes(value) && !isInPresel(value)){
            let valueIndex = items.indexOf(value)
            items.splice(valueIndex, 1)
            setSelected(selected.filter(item => item !== value));
            let indice = props.field.options.presel.indexOf(value);
            if (indice !== -1) {
                props.field.options.presel.splice(indice, 1);
                props.handleChangeRadio(value, indice, props.field,props.index, props.field.options.multiselect, props.field.options.mandatory);
            }
        }
    }

    function isInPresel(value) {
        if(props.field.originalPresel !== undefined) return props.field.originalPresel.includes(value); else return false
    }
    //méthode qui gère tous les cas de select / unselect
    const handleSelect = (value, indexage) => {
        switch (selected.includes(value)) {
            case true:
                setSelected(selected.filter(item => item !== value));
                break;
            case false:
                setSelected([...selected, value]);
                break;
            default:
                break;
        }

        if(!isInPresel(value)){
            handleRemoveValue(value)
        }else{
            props.handleChangeRadio(value, indexage, props.field,props.index, props.field.options.multiselect, props.field.options.mandatory)
        }

    }

    return (
    <div className={classes.mainContainer}>
        <div className={classes.multipleItemSearchContainerOpen} onClick={() => { setOpen(!open)}}>
            {open && items.length > 10 &&
            <div className={classes.multipleItemPresetOpen} onClick={(e) => {e.stopPropagation(); e.preventDefault() }}>
            {/* filter field */}
                <Input
                    id={`standard-full-width-search-${props.field.id}`}
                    label=""
                    margin="none"
                    fullWidth={true}
                    disableUnderline={true}
                    startAdornment={
                        <InputAdornment position="start">
                            <Search className={classes.multipleSearchButton}/>
                        </InputAdornment>}
                    endAdornment={ value.length > 0 &&
                        <InputAdornment position="end" onClick={(event) => setValue("")} style={{cursor : 'pointer'}}>
                            <Close className={classes.multipleCleanButton} />
                        </InputAdornment>
                    }
                    className={classes.textField}
                    onChange={(event) => setValue(event.target.value)}
                    value={value}
                />
            </div>}

            { open  &&
            <div>
                <div className={classes.multipleItemContainer} >
                    {  /* add item field */
                        props.field.options.editable && open &&
                        <div className={classes.multipleAdd} onClick={(e) => {e.stopPropagation(); e.preventDefault() }}>
                            <div className={classes.input_add_item}>
                                <Input
                                    disabled={disabled}
                                    autoComplete='off'
                                    id={'new-select-input-' + props.field.id}
                                    //label="Label"
                                    style={{ margin: 0 }}
                                    placeholder={t('common:multiple.add-item')}
                                    //helpertext="Full width!"
                                    fullWidth={false}
                                    margin="none"
                                    inputlabelprops={{
                                        shrink: true,
                                    }}
                                    disableUnderline={true}
                                    onBlur={() => {handleAddValue(newValue.trim())}}
                                    onChange={(event) => setNewValue(event.target.value)}
                                    onKeyPress={(e) => { if (e.key === 'Enter' && newValue.trim() !=="") { handleAddValue(newValue.trim())}}}
                                    value={newValue}
                                />
                            </div>
                        </div>
                    }
                    {
                        props.field.format.includes('select') && props.field.format.includes('multi') &&
                        typeof props.field.options.presel !== 'undefined' &&
                        // liste des selects
                        selected.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).filter((item,index) => {
                            let visible = true;

                            if(!isNull(setupbis) && typeof setupbis !== "undefined" && (props?.shared && setupbis[index].visible === false)){
                                visible = false;
                            }

                            if(visible){
                                if (!value) return true
                                if (item.toLowerCase().includes(value.toLowerCase())) {
                                    return true
                                }
                            }

                        }).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).map((item, indexage) => {
                        return(
                        <div className={classes.multipleInput} key={'main' + props.field.id + '-' + indexage} onClick={(e)=>{e.stopPropagation(); e.preventDefault() }}>
                            <div key= {'divsingleitem' + props.field.id + '-' + indexage} className={selected.includes(item)? classes.selectedItem : classes.unselectedItem} onClick={(e) => {e.preventDefault(); e.stopPropagation(); if(!disabled) handleSelect(item)}}>
                                <span style={{fontWeight:'normal'}}>{item}</span>
                            <div/>
                                {
                                    !disabled && (isInPresel(item)?
                                        <span className={`${classes.multipleDelButton} material-icons`} onClick={(e)=> {e.preventDefault(); e.stopPropagation(); handleSelect(item, indexage)}}>
                                            highlight_off
                                        </span>
                                        :
                                        <span className={`${classes.multipleOutlinedButton} material-icons`} onClick={(e)=> {e.preventDefault(); e.stopPropagation(); handleRemoveValue(item, indexage)}}>
                                            delete
                                        </span>)
                                }
                            </div>
                        </div>)})
                    }
                    {
                        //autres items...
                        typeof props.field.options.presel !== 'undefined' && props.field.options.presel.filter((item, index) => {

                            let visible = true;

                            if(!isNull(setupbis) && typeof setupbis !== "undefined" && (props?.shared && setupbis[index].visible === false)){
                                visible = false;
                            }

                            if(visible){
                                if (!value) return true
                                if (item.toLowerCase().includes(value.toLowerCase())) {
                                    return true
                                }
                            }

                        }).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).map((item, indexage) => { if (!selected.includes(item))
                        return(
                        <div className={classes.multipleInput} key={'others' + props.field.id + '-' + indexage} onClick={(e) => {e.preventDefault(); e.stopPropagation(); if(!disabled) handleSelect(item)}}>
                            <div key= {'divsingleitem' + props.field.id + '-' + indexage} className={classes.unselectedItem}>
                                <span style={{fontWeight:'normal'}} >{item}</span>
                            <div/>
                                {
                                    !disabled && (isInPresel(item)?
                                        <span className={`${classes.multipleDelButton} material-icons`} onClick={(e)=> {e.preventDefault(); e.stopPropagation(); handleSelect(item, indexage)}}>
                                            highlight_off
                                        </span>
                                        :
                                        <span className={`${classes.multipleOutlinedButton} material-icons`} onClick={(e)=> {e.preventDefault(); e.stopPropagation(); handleRemoveValue(item, indexage)}}>
                                            delete
                                        </span>)
                                }
                            </div>
                        </div>)})
                    }
                </div>
            </div>
            }
            {selected.length > 0 && !open &&
                <div className={classes.multipleItemContainer} onClick={() => {if(!open) setOpen(true)}}>

                {/*liste des sélections*/
                !open && (selected.length > 0 || newSelected.length > 0) &&
                        selected
                        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                        .map((value, index) => {
                            return (
                                <div key={index} className={classes.selectedItem} onClick={() => {if(!disabled) handleSelect(value)}}>
                                    <div className={classes.multipleInput} style={{fontWeight:'normal'}}>{value}</div>
                                    {
                                        !disabled &&
                                        <div onClick={handleRemoveValue.bind(this,value, props.field, props.index)}>
                                            <span className={isInPresel(value)? `${classes.multipleDelButton} material-icons` : `${classes.multipleOutlinedButton} material-icons`}>
                                                {isInPresel(value)? 'highlight_off' : 'delete'}
                                            </span>
                                        </div>
                                    }
                                </div>
                            )
                        })
                    }

        </div>
        }
        </div>
        <div className={classes.endButtonContainer} onClick={(event) => setOpen(!open)}>
                    <span id="icon-delete-playlist" className={`${classes.singleItemPresetOpen} material-icons md-small`}>
                                    {open? 'expand_less' : props.field.options.editable? 'playlist_add':'playlist_add_check'}
                    </span>
        </div>
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatMultipleSelectComponent);





