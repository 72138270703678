export const existingModes = [
  /**********************************************************
  CREATE / UPDATE
  **********************************************************/
  { group: "CREATE", modes: [
    { mode: "create", description: "", label: "" },
    { mode: "update", description: "", label: "" },
    { mode: "modal-plugnote-create", description: "", label: "" },
  ]},
  /**********************************************************
   SHARED-CREATE/UPDATE/VIEWS
  **********************************************************/
  { group: "SHARED", modes: [
    { mode: "shared-create", description: "", label: "" },
    { mode: "shared-update", description: "", label: "" },
    { mode: "shared-view", description: "", label: "" },
  ]},
  /**********************************************************
   PLUGCODE-CONSULT/COMPLETE/CONSULT-GALLERY/COMPLETE-GALLERY
  **********************************************************/
  { group: "PLUGCODE-COMPLETE", modes: [
    { mode: "plugcode-complete", description: "", label: "" },
    { mode: "plugcode-complete-modal", description: "", label: "" },
    { mode: "plugcode-complete-gallery", description: "", label: "" },
  ]},
  { group: "PLUGCODE-CONSULT", modes: [
    { mode: "plugcode-consult", description: "", label: "" },
    { mode: "plugcode-consult-modal", description: "", label: "" },
    { mode: "plugcode-consult-gallery", description: "", label: "" },
  ]},
  /**********************************************************
   MODAL-SHARED-CREATE/MODAL-SHARED-UPDATE
  **********************************************************/
  { group: "MODAL", modes: [
    { mode: "modal-shared-create", description: "", label: "" },
    { mode: "modal-shared-update", description: "", label: "" },
  ]},
]