import React, {useEffect, useRef, useState} from 'react'
import { Checkbox, Dialog, Input, InputLabel, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { strReplaceContentField } from '../../../utils/Fields'
import { devLog, handlePreventDefault, isDefined, urlify } from '../../../utils/utils'
import {getCurrentEnvironnement, getPlugnote} from '../../../utils/Common'
import { confirmAlert } from 'react-confirm-alert'
import { isNull } from 'lodash'
import { Content } from 'rsuite'
import RichEditor from '../../custom/rich-editor/RichEditor'
import { ColorCheckbox } from '../../custom/CustomComponents'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileAlt, faFilePdf } from '@fortawesome/free-regular-svg-icons'
import Data2001Printable from '../../printable/Data2001Printable'
import colors from '../../../assets/themes/Colors'
import ApplicationConfig from '../../../config/ApplicationConfig'
import Plugform from "../../../classes/Plugform";

const useStyles = makeStyles(() => ({
  modalHeader: {
    // position: 'absolute',
    top: 0,
    width: '100%',
  },
  modalFooter: {
    '@media (min-height:640px)': {
      display: 'none'
    },
    bottom: 0,
    width: '100%',
  },
  labelContainer: {
    width: '15%',
  },
  inputContainer: {
    width: '80%',
  },
  input: {
    color: 'white',
    fontFamily: 'Arial, FontAwesome'
  },
  textField: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: '500',
    borderRadius: '5px',
    height: '38px',
    lineHeight: '38px',
    paddingLeft: '10px',
    backgroundColor: '#FFFFFF',
    fontSize: '14px',
    width: '100%',
    MozBorderRadius: '5px',
    border: '1px solid #ccc',
    WebkitBorderRadius: '5px',
  },
  attachmentTitle: {
    color: "black",
    fontWeight: "600",
  },
  attachmentBox: {
    margin: "0 0 40px 0",
    border: "dashed 2px #51C3C1",
    borderRadius: "5px",
    backgroundColor: "#c5e4e3",
    height: 150,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  attachmentsCtn: {
    display: "flex",
    flexDirection: "column",
    gap: 5
  },
  attItemsContainer: {
    overflow: "hidden",
  },
  attItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  attAnnexName: {
    overflow: "auto",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    height: "30px",
    flex: "none",
    width: "100%",
    fontSize: "14px",
    cursor: "pointer",
  },
  attIconDlInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#D8D8D8",
  },
  attIconType: {
    margin: 10,
    width: "30px",
    height: "30px",
    cursor: "pointer",
  },
  multipleCheckBox: {
    color: colors.main,
    width: 15,
    height: 15,
  },
  faIconSize: {
    fontSize: 20,
    color: "#51C3C1",
  },
}))

const SmailModalComponent = (props) => {
  // devLog('SmailModal got props', props)
  const classes = useStyles()
  const { t } = useTranslation('common')
  const options = props.field.options
  let content = ""
  if (options?.body) {
    content = strReplaceContentField(options?.body, props.fields, props?.model, props?.plugnote, props?.user, props?.pwCode, props?.mode)
  }
  let userIndex = typeof props.plugnote !== "undefined" && props.plugnote !== null ? props.plugnote.userindex : 0
  let dataNumber = userIndex.toString().padStart(6, 0)
  let model = props.model
  let modelClef = model.clef
  const fileName = 'PN-' + dataNumber + '-' + modelClef
  const setup = props.field.options?.setup
  const autoAttachments = isDefined(setup?.autoAttachments) ? setup?.autoAttachments : false
  const autoCopy = isDefined(setup?.autoCopy) ? setup?.autoCopy : true

  const [body, setBody] = useState(content)
  const [sujet, setSujet] = useState(options?.title || "")
  const expediteur = options?.from || "noreply@plugnotes.com"
  const viewNotes = options?.note ? options?.note === 'Y' : false
  const [showCc, setShowCc] = useState(false)
  const [mail, setMail] = useState(props.value || "")
  const [mailCc, setMailCc] = useState(options?.cc || "")
  const [mailCci, setMailCci] = useState(options?.cci || "")
  const [attachments, setAttachments] = useState(autoAttachments ? props.annexes.map(entry => entry.hash) : [])
  const [attachCopy, setAttachCopy] = useState(autoCopy)
  const [extraAttachments, setExtraAttachments] = useState([])
  const [hasRelated, setHasRelated] = useState(false)
  const [printRelated, setPrintRelated] = useState(false)
  const [open, setOpen] = useState(false);

  const environment = getCurrentEnvironnement()
  const editorRef = useRef(null)

  const handlePressValidate = () => {
    if (mail.length > 0) {
      let message = t('global.send')
      confirmAlert({
        title: t('global.plugnotes'),
        message: message,
        buttons: [
          {
            label: t('global.ok'),
            onClick: () => {
              let bodyReplace = body
              // let bodyReplace = body.replace(/(?:\r\n|\r|\n)/g, '<br>')
              // let bodyUrlReplace = urlify(bodyReplace)
              const searchRegExp = /(PN|PF|PW)-\d{6}-[A-Z,0-9]{5}/g
              if (bodyReplace.search(searchRegExp)) {
                const searchedValues = bodyReplace.match(searchRegExp)

                if (!isNull(searchedValues)) {
                  if (searchedValues.length > 0) {
                    searchedValues.forEach((value, index) => {
                      bodyReplace = bodyReplace.replace(value, '<a href="' + ApplicationConfig.templateUrl + '?code=' + value + '">' + value + '</a>');
                    })
                  }
                }
              }

              let payload = {}
              payload.subject = sujet.length > 0 ? sujet : null
              payload.body = bodyReplace.length > 0 ? bodyReplace : null
              payload.from = expediteur.length > 0 ? expediteur : null
              payload.cc = mailCc.length > 0 ? mailCc : null
              payload.cci = mailCci.length > 0 ? mailCci : null
              payload.to = mail.length > 0 ? mail : null
              payload.attachments = attachments.length > 0 ? attachments : null
              payload.attachment = attachCopy ? props.plugnoteCopy : null
              payload.extraAttachments = printRelated && extraAttachments.length > 0 ? extraAttachments : null
              if (props.field.format === 'smail') { props.handleRecordSendDate(mail) }
              props.close()
              props.attemptSendMailSimple(payload, environment?.userhash, props?.ownerhash)
            }
          },
          {
            label: t('global.cancel'),
          }
        ]
      });
    } else {
      confirmAlert({
        title: t('global.plugnotes'),
        message: t('global.missing-email'),
        buttons: [
          {
            label: t('global.ok'),
          },
        ]
      })
    }
  }

  const handleTogglePlugnoteAttachment = (annexe) => {
    if (!attachments.includes(annexe.hash)) {
      setAttachments([...attachments, annexe.hash])
    } else {
      let tmp = attachments.filter(item => item !== annexe.hash)
      setAttachments(tmp);
    }
  }

  const handleTogglePlugnoteCopy = () => {
    setAttachCopy(previous => !previous)
  }

  const handleToggleRelated = () => {
    setPrintRelated(previous => {
      return !previous
    })
  }

  const handleAddRelatedPlugnote = (blob) => {
    if (blob) {
      let reader = new FileReader()
      blob.then(result => {
        try {
          reader.readAsDataURL(result);
          reader.onloadend = function () {
            let base64data = reader.result;
            let _attachments = [...extraAttachments, base64data]
            setExtraAttachments(_attachments)
          }
        } catch (e) {
          console.error(`❌ onLoadBlob ${e}`)
        }
      })
    }
  }

  const handleClickBody = () => {
    if (isDefined(editorRef)) {
      devLog('editor ref ', editorRef.current)
    }
  }

  const handleEditBody = (value) => {
    // devLog('changing body || event', value)
    setBody(value)
  }

  const handleToggleCc = () => { setShowCc(previous => !previous) }


  useEffect(async () => {

    if(typeof props.mode !== "undefined" && !isNull(props.mode)){
      if (props.mode === "create" || props.mode === "update") {
        if (options?.body.includes('***PN-**')) {
          let plugform = new Plugform(props.mode, props.fields);
          await plugform.createOrUpdatePlugnoteGeneratePnCodeAndRefreshBody().then(result => {
            if (result) {
              setBody(strReplaceContentField(options?.body, props.fields, props?.model, getPlugnote(), props?.user, props?.pwCode, props?.mode));
              setOpen(props.open);
            } else {
              setOpen(props.open);
            }
          });
        } else {
          setOpen(props.open);
        }
      } else {
        setOpen(props.open);
      }
    } else {
      setOpen(props.open);
    }
  }, []);




  return (
    <Dialog open={open} onClose={props.close} fullWidth={true} maxWidth="md" onClick={handlePreventDefault}>
      <div className={classes.modalHeader}>
        <div className="popup_wrapper_content justify_content_space">
          <button
            className="button-exit"
            onClick={props.close}
          >
            {t("common:global.cancel")}
          </button>
          <button className="button float_right" onClick={handlePressValidate}>{t('common:global.send')}</button>
        </div>
      </div>
      <Content >
        <div className="popup_wrapper_content">
          <div className="flex_display_direction_row justify_content_space align-items-center">
            <InputLabel style={{ fontWeight: 'bold' }} shrink className={classes.labelContainer}>{t('common:mails.destinatairefrom')}</InputLabel>
            <div className={classes.inputContainer}>
              <Input
                id={'from-input-' + props.field.id}
                label=""
                style={{ margin: 8 }}
                //placeholder={props.placeholder}
                fullWidth={true}
                margin="none"
                disableUnderline={true}
                inputlabelprops={{
                  className: classes.input,
                }}
                className={classes.textField}
                value={expediteur}
                disabled={true}
              />
            </div>
          </div>
          <div className="flex_display_direction_row justify_content_space align-items-center">
            <InputLabel style={{ fontWeight: 'bold', display: 'flex', flexDirection: 'row' }} shrink className={classes.labelContainer}>
              {t('common:mails.destinataire')}
              <InputLabel style={{ fontWeight: 'bold', marginLeft: 10, borderBottom: '1px solid', cursor: 'pointer' }}
                onClick={handleToggleCc}
              >
                {t('common:mails.destinatairecc')} {t('common:mails.destinatairecci')}
              </InputLabel>
            </InputLabel>
            <div className={classes.inputContainer}>
              <Input
                id={'to-input-' + props.field.id}
                label=""
                style={{ margin: 8 }}
                fullWidth={true}
                margin="none"
                disableUnderline={true}
                inputlabelprops={{
                  className: classes.input,
                }}
                className={classes.textField}
                value={mail}
                disabled={false}
                onChange={(event) => setMail(event.target.value)}
              />
            </div>
          </div>
          {
            showCc &&
            <div className="flex_display_direction_row justify_content_space align-items-center">
              <InputLabel style={{ fontWeight: 'bold' }} shrink className={classes.labelContainer}>{t('common:mails.destinatairecc')}</InputLabel>

              <div className={classes.inputContainer}>
                <Input
                  id={'cc-input-' + props.field.id}
                  label=""
                  style={{ margin: 8 }}
                  fullWidth={true}
                  margin="none"
                  disableUnderline={true}
                  inputlabelprops={{
                    className: classes.input,
                  }}
                  className={classes.textField}
                  value={mailCc}
                  disabled={false}
                  onChange={(event) => setMailCc(event.target.value)}
                />
              </div>
            </div>
          }

          {
            showCc &&
            <div className="flex_display_direction_row justify_content_space align-items-center">
              <InputLabel style={{ fontWeight: 'bold' }} shrink className={classes.labelContainer}>{t('common:mails.destinatairecci')}</InputLabel>

              <div className={classes.inputContainer}>
                <Input
                  id={'cci-input-' + props.field.id}
                  label=""
                  style={{ margin: 8 }}
                  fullWidth={true}
                  margin="none"
                  disableUnderline={true}
                  inputlabelprops={{
                    className: classes.input,
                  }}
                  className={classes.textField}
                  value={mailCci}
                  disabled={false}
                  onChange={(event) => setMailCci(event.target.value)}
                />
              </div>
            </div>
          }
          <div className="flex_display_direction_row justify_content_space align-items-center">
            <InputLabel style={{ fontWeight: 'bold' }} shrink className={classes.labelContainer}>{t('common:mails.sujet')}</InputLabel>
            <div className={classes.inputContainer}>
              <Input
                id={'subject-input-' + props.field.id}
                label=""
                style={{ margin: 8 }}
                fullWidth={true}
                margin="none"
                disableUnderline={true}
                inputlabelprops={{
                  className: classes.input,
                }}
                className={classes.textField}
                onChange={(event) => setSujet(event.target.value)}
                value={sujet}
              />
            </div>
          </div>

          <div className="flex_display_direction_row justify_content_space align-items-center">
            <InputLabel style={{ fontWeight: 'bold' }} shrink>{t('common:mails.message')}</InputLabel>
          </div>

          <RichEditor
            init={{
              height: 500,
              menubar: true,
              plugins: [
                'advlist', 'anchor', 'autolink', 'image', 'link', 'lists',
                'searchreplace', 'table', 'wordcount'
              ],
              // toolbar: 'undo redo | blocks | ' +
              //   'bold italic forecolor | alignleft aligncenter ' +
              //   'alignright alignjustify | bullist numlist outdent indent | ' +
              //   'removeformat',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
            onEditorChange={handleEditBody}
            onClickBody={handleClickBody}
            onInit={(evt, editor) => editorRef.current = editor}
            value={body}
          />
        </div>
        {
          (props.field.template !== '' || props.annexes.length > 0 || props.plugnoteCopy !== null) &&
          <div className="popup_wrapper_content">
            <div className="flex_display_direction_row justify_content_space align-items-center">
              <InputLabel style={{ fontWeight: 'bold' }} shrink>{t('common:mails.attachments')}</InputLabel>
              <span style={{ height: '25px' }} />
            </div>
            {
              (props.field.template !== '' || props.annexes.length > 0) &&
              <div className={classes.attachmentsCtn}>
                {
                  props.annexes.length > 0 &&
                  props.annexes.map((annexe, index) => {
                    if ((annexe.type === 'note' && viewNotes) || annexe.type !== 'note') {
                      return (
                        <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }} key={'annexes-' + props.field.index + '-' + index}>
                          <div className={classes.attItemsContainer} onClick={() => handleTogglePlugnoteAttachment(annexe)}>
                            <div className={classes.attItem}>
                              <ColorCheckbox checked={attachments.includes(annexe.hash)} color={colors.main} size={24} />
                              <div className={classes.attIconType}>
                                {
                                  annexe.type === 'note' && viewNotes &&
                                  props.renderAnnexeIconPreview(annexe)
                                }
                                {
                                  annexe.type !== 'note' &&
                                  props.renderAnnexeIconPreview(annexe)
                                }
                              </div>

                              <div className={classes.attAnnexName} style={{ marginRight: 10 }}>
                                {
                                  annexe.type === 'note' && viewNotes &&
                                  <span>{annexe.name}</span>
                                }

                                {
                                  annexe.type !== 'note' &&
                                  <span>{annexe.name}.{annexe.type}</span>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    } else return null
                  })}
                {
                  props.field.options.template === '9002' && props.plugnoteCopy !== null &&
                  <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-start', alignItems: 'flex-start' }} key={'annexes-' + props.field.index + '-' + (props.annexes.length + 1)}>
                    <Checkbox
                      className={classes.multipleCheckBox + ' ' + classes.switchBase}
                      name={t('common:smail.template-9002-content')}
                      checked={attachCopy}
                      onChange={handleTogglePlugnoteCopy}
                    />
                    <div className={classes.attItemsContainer} onClick={handleTogglePlugnoteCopy}>
                      <div className={classes.attItem}>
                        <div className={classes.attIconType}>
                          <FontAwesomeIcon
                            icon={faFilePdf}
                            className={classes.faIconSize}
                          />
                        </div>

                        <div className={classes.attAnnexName} style={{ marginRight: '10px' }}>
                          <span>{t('common:smail.template-9002-content')}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                {
                  props.field.options.template === '2001' &&
                  <div className={classes.attItemsContainer} onClick={handleTogglePlugnoteCopy}>
                    <div className={classes.attItem}>
                      <Data2001Printable
                        {...props}
                        addRelatedPlugnote={handleAddRelatedPlugnote}
                        checked={attachCopy}
                        filename={fileName}
                        isEmailAttachment
                        setHasRelated={setHasRelated}
                        setPdfBlob={props.setPdfBlob}
                        t={t}
                      />
                      <div className={classes.attIconType}>
                        <FontAwesomeIcon
                          icon={faFilePdf}
                          className={classes.faIconSize}
                        />
                      </div>

                      <div className={classes.attAnnexName} style={{ marginRight: '10px' }}>
                        <span>{t('common:smail.template-9002-content')}</span>
                      </div>
                    </div>
                  </div>
                }
                {
                  hasRelated &&
                  <div className={classes.attItemsContainer} onClick={handleToggleRelated}>
                    <div className={classes.attItem}>
                      <ColorCheckbox
                        checked={printRelated}
                        size={24}
                      />
                      <div className={classes.attIconType}>
                        <FontAwesomeIcon
                          icon={faFileAlt}
                          className={classes.faIconSize}
                        />
                      </div>
                      <div className={classes.attAnnexName} style={{ marginRight: '10px' }}>
                        <span>{t('common:print.related-plugnote')}</span>
                      </div>
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        }

        <div className={classes.modalFooter}>
          <div className="popup_wrapper_content justify_content_space">
            <button
              className="button-exit"
              onClick={props.close}
            >
              {t("common:global.cancel")}
            </button>
            <button className="button float_right" onClick={handlePressValidate}>{t('common:global.send')}</button>
          </div>
        </div>
      </Content>
    </Dialog>
  )
}

export default SmailModalComponent
