import React from "react"
import { Text, View, StyleSheet, Link, Font, Image } from "@react-pdf/renderer"
import { devLog, isDefined } from "../../../utils/utils"
import { isNull } from "lodash";
import Images from "../../../assets/themes/Images";

const hyphenationCallback = (word) => {
    devLog('hyphenationCallback', word)
    if (word.length > 0) {
        const array = word.split(/(?=.)/g) // splits the string without removing splitting char
        devLog('hyphenation', array)
        return array
    }
    else return ['']
}

Font.registerHyphenationCallback(hyphenationCallback)

const styles = StyleSheet.create({
    row: {
        display: 'flex',
        // flexGrow: 1,
        flexDirection: "row",
        // flexWrap: 'nowrap',
        // alignItems: "center",
        // width: '100%',
        justifyContent: 'space-between',
        borderBottomWidth: 1,
        minHeight: 15,
    },
    cell: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: 'center',
        alignContent: 'center',
        borderLeftWidth: 1,
        borderRightWidth: 0,
        borderColor: "#e0e0e0",
        // backgroundColor: 'yellow', // pour test
        height: '100%',
        minHeight: 15,
        // wordWrap: 'normal',
        // textOverflow: 'ellipsis',
    },
    flexCell: {
        // flex: 1,
    },
    emptyCell: {
        flexDirection: "column",
        alignItems: "center",
        justifyContent: 'center',
        alignContent: 'center',
        height: 'auto',
        minHeight: 15,
    },
    rowNb: {
        // fontFamily: 'Poppins',
        fontSize: 8,
    },
    value: {
        flexWrap: 'wrap',
        fontWeight: 'light',
        minHeight: 15,
    },
    ellipsisCell: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: 'inline-block',
    },
    iconCtn: {
        width: 20,
        height: 20,
        margin: 2,
    },
});

const RenderTextOrObject = (props) => {
    let value = isDefined(props.row) ? props.row : "";
    if (typeof props.row === "object" && props.row.hasOwnProperty('value') && props.row.hasOwnProperty('updatedDate')) {
        if (props.row.value !== "" && props.row.updatedDate !== "") {
            value = "TO : " + props.row.value + " AT : " + props.row.updatedDate;
        } else if (props.row.value !== "" && props.row.updatedDate === "") {
            value = "TO : " + props.row.value;
        } else {
            value = ""
        }
    }

    return (
        <Text break style={[styles.value, { fontSize: props.fontSize }]} > {value} </Text>
    )
}

const RenderFileUri = props => {
    return (
        <View style={[styles.cell, styles.flexCell, props.widthStyle, { borderColor: props.borderColor, borderRightWidth: props.isLastColumn ? 1 : 0, fontSize: props.fontSize }]} key={props.index}>
            <View style={styles.iconCtn} >
                {/* <Text style={[styles.value, { textOverflow: 'ellipsis', fontSize: props.fontSize }]}> */}
                <Link
                    src={props.value} style={{ textOverflow: 'ellipsis', }}
                >
                    <Image src={Images.downArrow} />
                </Link>
                {/* </Text> */}
            </View>
        </View>
    )
}

const PDFRow = (props) => {
    // devLog('pdf renderer Font', Font)
    const borderColor = props.borderColor !== undefined ? props.borderColor : "#c5c5c5"
    const layout = props.setup?.layout
    const row = props.row
    // devLog('RENDER pdf row ', row)

    return (props.isEmpty === true ?
        (
            <View style={[styles.row, { borderColor: borderColor, borderLeftWidth: 1, borderRightWidth: 1 }]}>
                <View style={[styles.emptyCell, { flex: 1 }]} key={props.id}>
                    <Text style={props.textStyle}>{props.row[0]}</Text>
                </View>
            </View>
        ) : (
            <View style={[styles.row, { borderColor: borderColor }, props.widthStyle]}
                wrap={false}
            // debug
            >
                {
                    layout?.showRowNb !== false && layout?.showRowNb?.showOnPdf !== false &&
                    <View style={[styles.cell, { width: 20, borderColor: borderColor }]}>
                        <Text style={styles.rowNb} key="row-number" wrap={false}>{props.rowNb + 1}</Text>
                    </View>
                }
                {
                    typeof props.row !== "undefined" && (props.row.length > 0 || props.row !== null) &&
                    props.header.map((col, index) => {
                        {/* console.log('row render', col) */ }
                        let visible = true;

                        if (!isNull(props.setupbis) && typeof props.setupbis !== "undefined" && ((props.mode === 'shared-update' || props.mode === 'shared-create' || props.mode === 'shared-view') && props.setupbis[index].visible === false)) {
                            visible = false;
                        }

                        let colId = col.id.toString()
                        let fontSize = isDefined(col.fontSize) ? col.fontSize * 0.7 : 8
                        let widthStyle
                        if (props.flex) {
                            widthStyle = { flex: props.widths[index] }
                        } else {
                            widthStyle = col.colWidth > 30
                                ? { maxWidth: col.colWidth, minWidth: col.colWidth }
                                : { flex: 1 }
                        }

                        if (visible) {
                            // Test if the label and row should be shown
                            if (props.hiddenOpt?.pdf || col.showOnPdf || !isDefined(col.showOnPdf)) {
                                let isLastColumn = false
                                let isUrlOrMail = false
                                if (typeof props.row[colId] === "string" && props.row[colId].length > 0 && (['@', 'http', 'www', 'ftp'].some(v => props.row[colId].includes(v)))) { isUrlOrMail = true; }
                                if (!props.header.some(entry => { return props.header.indexOf(entry) > index && (entry.showOnPdf === true || !isDefined(entry.showOnPdf) || props.hiddenOpt?.pdf) })) { isLastColumn = true }
                                if (col.format === 'file-uri') {
                                    if (row.type === 'note') {
                                        return (
                                            <View style={[styles.cell, styles.flexCell, widthStyle, { borderColor: borderColor, borderRightWidth: isLastColumn ? 1 : 0, fontSize: fontSize }]} key={index}>
                                                <Text style={[styles.value, { textOverflow: 'ellipsis', fontSize: fontSize }]}> - </Text>
                                            </View>
                                        )
                                    }
                                    return (
                                        <RenderFileUri
                                            col={col}
                                            fontSize={fontSize}
                                            index={index}
                                            isLastColumn={isLastColumn}
                                            key={index}
                                            value={props.row[colId] || ''}
                                            widthStyle={widthStyle}
                                        />
                                    )
                                }
                                return (
                                    <View style={[styles.cell, styles.flexCell, widthStyle, { borderColor: borderColor, borderRightWidth: isLastColumn ? 1 : 0, fontSize: fontSize }]} key={index}>
                                        {
                                            isUrlOrMail ?
                                                <View style={styles.ellipsisCell} >
                                                    <Text style={[styles.value, { fontSize: fontSize }]} break>
                                                        <Link src={props.row[colId]} >
                                                            {props.row[colId] || ''}
                                                        </Link>
                                                    </Text>
                                                </View>
                                                :
                                                <RenderTextOrObject row={props.row[colId]} fontSize={fontSize} />
                                        }
                                    </View>
                                )
                            } else { return null; }
                        } else { return null; }
                    })
                }
            </View>
        )
    );
}

export default PDFRow;
