import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import i18n from 'i18next'
import {
  BlobProvider,
  Document,
  Font,
  Image,
  Link,
  Page,
  pdf,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer'
import Images from '../../assets/themes/Images'
import popLig from '../../fonts/Poppins-Light.ttf'
import popLigIt from '../../fonts/Poppins-LightItalic.ttf'
import popBol from '../../fonts/Poppins-Bold.ttf'
import popReg from '../../fonts/Poppins-Medium.ttf'
import ApplicationConfig from '../../config/ApplicationConfig'
import axios from 'axios'
import { getPrintPDFOptions, getToken, setLogo, setPrintPDFOptions } from '../../utils/Common'
import moment from 'moment'
import colors from '../../assets/themes/Colors'
import { ModalTablemultiTableViewComponent } from '../modals/tablemulti/ModalTablemultiTableViewComponent'
import { ColorCheckbox, PlugTooltip } from '../custom/CustomComponents'
import {formatNumberApplyOptions} from '../../utils/utils.js';
import { devLog, isDefined, isJsonString, returnJSON } from '../../utils/utils'
import { isNull } from 'lodash'
import { getPlugnoteFromCode } from '../../utils/Plugnotes'
import { Tab, Tabs } from '@material-ui/core'
import { AttLayout, FieldVisibility, GallerySelect, InvisiblePDF, InvisiblePF, InvisiblePN, RelatedPlugnote } from '../custom/icons/CustomIcons'
import { AttachmentThumbnail } from '../preview/AttachmentThumbnail'
import { isEmptyField } from '../../utils/Fields'
import { convertHtmlToNative, handleMergeMultiplePDF } from '../../utils/pdf'

const hyphenationCallback = (word) => {
  if (word.length > 0) {
    // devLog('hyphenationCallback', word)
    const array = word.split("(?=[a-z])|(?=[A-Z])|(?=[0-9])") // splits the string without removing splitting char
    // const array = word.split(/(?=.)/g) // splits the string without removing splitting char
    if (word.length > 15) console.log('hyphenation', array)
    return array
  }
  else return ['']
}

Font.register({
  family: 'Poppins',
  fonts: [
    { src: popBol, fontWeight: 'bold' },
    { src: popLig, fontWeight: 'light' },
    { src: popReg, fontWeight: 'normal' },
    { src: popLigIt, fontStyle: 'italic', fontWeight: 'light' }
  ]
})

Font.registerHyphenationCallback(hyphenationCallback)

// Create styles
const styles = StyleSheet.create({
  mainCtn: {
    position: 'relative',
    flexGrow: 1,
    height: '100%',
    width: '100%',
    paddingBottom: 50
  },
  // BODY
  bodyCtn: {
    flexDirection: 'column',
  },
  container45: {
    width: '45%',
    flexDirection: 'column',
    flexWrap: 'wrap',
    marginBottom: 12,
  },
  container45H: {
    width: '45%',
    maxWidth: '45%',
    flexDirection: 'row',
    flexWrap: 'no-wrap',
    // backgroundColor: 'yellow',
  },
  attName: {
    fontFamily: 'Poppins',
    fontSize: 10,
    wordWrap: 'break-word'
  },
  attInfos: {
    fontFamily: 'Poppins',
    fontWeight: 'light',
    fontSize: 10,
    fontStyle: 'italic'
  },
  child: {
    width: '20%',
    margin: '1%',
    aspectRatio: '1/1',
    maxHeight: '160px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  thumbnailCtn: {
    // aspectRatio: 1,
    // marginTop: 5,
    maxHeight: '90px',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  maxChild: {
    width: '69%',
    margin: '1%'
  },
  minChild: {
    width: '5%',
    margin: '1%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  section: {
    margin: 10,
    flexGrow: 1,
    flexDirection: 'column'
  },
  sectionAnnexes: {
    margin: 10,
    paddingHorizontal: 40,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 15
  },
  iconCtn: {
    width: 32,
    marginRight: 8,
  },
  icon: {
    paddingTop: 4,
    width: '30',
    // height: '30',
    aspectRatio: '1/1',
  },
  iconImg: {
    width: "auto",
    maxHeight: 90,
    height: 90,
    aspectRatio: '1/1',
  },
  iconTxtCtn: {
    maxWidth: 'calc(100% - 40px)',
    width: 'calc(100% - 40px)',
  },
  page: {
    paddingTop: 40,
    paddingBottom: 50,
    flexGrow: 1,
    // backgroundColor: 'crimson',
  },
  commentTxt: {
    fontSize: 10,
    fontWeight: "light",
    fontFamily: 'Poppins',
  },
  // Layout 10
  descCtn10: {
    maxWidth: '70%',
    flexDirection: 'column',
    flex: 0.6,
    borderLeftWidth: 1,
    borderLeftColor: colors.iconGrey,
    marginLeft: 10,
    paddingLeft: 10,
  },
  containerLayout10: {
    display: 'inline-flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  img10Ctn: {
    height: '250px',
    // width: 130,
    margin: '0 auto auto',
    flex: 0.4,
  },
  containerLayout75: {
    flexDirection: 'row',
    marginBottom: 20,
  },
  // Layout 25
  img25Ctn: {
    height: '250px',
    width: '100%',
  },
  // Layout 50
  container100: {
    width: '100%',
    flexDirection: 'column',
    flexWrap: 'wrap',
    flexGrow: 1,
  },
  img50Ctn: {
    maxHeight: '260px',
    width: '100%',
  },
  img50: {
    aspectRatio: '1/1',
    margin: '0px auto'
    // height: 'auto',
    // width: 'auto',
  },
  // Layout 75
  descCtn75: {
    width: '40%',
    flexDirection: 'column',
    borderLeftWidth: 1,
    borderLeftColor: colors.iconGrey,
    marginLeft: 10,
    paddingLeft: 10,
    marginRight: -20,
  },
  img75Ctn: {
    maxHeight: '260px',
    width: '50%',
  },
  // Layout 100
  img100Ctn: {
    maxHeight: '700px',
    minHeight: '40%',
    width: '100%',
  },
  fullPageCtn: {
    flexGrow: 1,
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  img100: {
    aspectRatio: '1/1',
    margin: '0px auto'
  },
  paragraph: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexGrow: '1' /* Let it fill the entire space horizontally */
  },
  paragraphText: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexGrow: '1' /* Let it fill the entire space horizontally */,
    marginBottom: 5
  },
  paragraphMain: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    flexGrow: '1', /* Let it fill the entire space horizontally */
    margin: 5
  },
  paragraphPng: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexGrow: '1', /* Let it fill the entire space horizontally */
    alignItems: 'flex-start',
    margin: 5
  },
  paragraphImage: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    flexGrow: '1', /* Let it fill the entire space horizontally */
    alignItems: 'center',
    margin: 5,
    minHeight: 30,
  },
  paragraphTextAnnexes: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    flexGrow: '1', /* Let it fill the entire space horizontally */
    alignItems: 'flex-start',
    margin: 5
  },
  viewLeft: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: '1' /* Let it fill the entire space horizontally */
  },
  viewRight: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: '1' /* Let it fill the entire space horizontally */
  },
  viewFieldsLeft: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: '1' /* Let it fill the entire space horizontally */,
    justifyContent: 'flex-start',
    flexDirection: 'row'
  },
  viewFieldsRight: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: '2' /* Let it fill the entire space horizontally */
  },
  viewTextRight: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: '1' /* Let it fill the entire space horizontally */,
    textAlign: 'right'
  },
  text: {
    width: 'auto',
    fontWeight: 500,
    fontSize: 14
  },
  textInfos: {
    textAlign: 'right',
    width: 'auto',
    fontWeight: 100,
    fontSize: 8
  },
  textUnder: {
    width: 'auto',
    fontWeight: 500,
    fontSize: 14,
    textDecoration: 'underline'
  },
  textThin: {
    width: 'auto',
    fontWeight: 300,
    fontSize: 12
  },
  textThinSmall: {
    width: 'auto',
    fontWeight: 200,
    fontSize: 10
  },
  textLink: {
    width: 'auto',
    color: 'green',
    fontWeight: 300,
    fontSize: 12
  },
  title: {
    marginBottom: 10
  },
  // region FOOTER
  footer: {
    position: 'absolute',
    width: '100%',
    height: 40,
    bottom: 0,
    left: 0,
    right: 0,
    paddingTop: 10,
    paddingHorizontal: 30,
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    // backgroundColor: 'yellow',
  },
  footerText: {
    justifyContent: 'flex-start',
    color: 'grey',
    textAlign: 'center',
    fontSize: 10,
    fontFamily: 'Poppins',
    fontStyle: 'italic',
    fontWeight: 'light',
    flex: 0.4,
  },
  pageNbCtn: {
    justifyContent: 'center',
    textAlign: 'center',
    flex: 0.1,
    backgroundColor: colors.mainAlpha, // for dev purpose
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    height: '100%',
  },
  pageNb: {
    fontSize: 10,
    fontWeight: 'bold',
    color: 'white',
    paddingTop: 4,
  },
  footerTextLeft: {
    width: '20%',
    left: 10,
    fontSize: 10,
    justifyContent: 'center',
    textAlign: 'left',
    color: 'grey'
  },
  footerLayout: {
    width: '30%',
    right: 30,
    bottom: 45,
    fontSize: 10,
    justifyContent: 'flex-end',
    textAlign: 'right',
    color: 'grey',
    position: 'absolute'
  },
  plugNbText: {
    letterSpacing: 1,
    color: colors.unselected,
    fontSize: 11,
    fontWeight: 'bold',
    fontFamily: 'Poppins'
  },
  // #endregion
  // #region HEADER
  headerCtn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '40px 50px 0px 50px',
    position: 'relative',
    minHeight: 100,
    // backgroundColor: 'burlywood',
  },
  headerTextCtn: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start'
  },
  headerLogoCtn: {
    flex: 1,
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    // position: 'absolute',
    maxHeight: 100,
    marginTop: -50
  },
  plugTitle: {
    color: colors.unselected,
    fontSize: 17,
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    maxWidth: 'calc(100% - 200px)',
    flexWrap: 'wrap'
  },
  headerText: {
    textAlign: 'center',
    color: 'grey',
    fontSize: 12
  },
  headerLogo: {
    width: '514px',
    objectFit: 'contain',
    top: 0
  },
  // #endregion
  contentCtn: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%'
  },
  // #region Annexes
  attFieldCtn: {
    flexGrow: 1,
    paddingTop: 20,
    // position: 'absolute',
    // top: 40,
    width: '100%'
  },
  noAttFieldCtn: {
    paddingTop: 20,
    position: 'absolute',
    bottom: 40,
    // backgroundColor: 'aquamarine',
  },
  imgLegendCtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 6
  },
  // #endregion
  // #region Related
  subheaderCtn: {
    flexDirection: 'column'
  },
  subheaderTextCtn: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 40
  },
  // #endregion
  titleCtn: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignContent: 'center',
    marginBottom: 12,
    maxWidth: 514,
    paddingRight: 30,
  },
  titleLine: {
    width: 100,
    height: 6,
    backgroundColor: colors.main,
    marginRight: 15
  },
  subtitle: {
    color: colors.unselected,
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    fontSize: 11,
    letterSpacing: 1,
    marginTop: -5,
    maxWidth: 'calc(100% - 100px)',
  },
  fieldsCtn: {
    display: 'flex',
    flexDirection: 'column',
    height: 'auto',
    gap: 4
  },
  mainFieldCtn: {
    flexDirection: 'column',
    position: 'relative',
    // backgroundColor: colors.blueHeader
  },
  fieldCtn: {
    paddingLeft: 35,
    alignContent: 'flex-start',
    flexDirection: 'row',
    marginBottom: 8,
    minHeight: 25,
    // height: 'auto'
  },
  fullWidthFieldCtn: {
    alignContent: 'center',
    flexDirection: 'column',
    marginBottom: 8,
    paddingLeft: 35,
    // height: 'auto',
    // backgroundColor: colors.cloud // pour test
  },
  fieldLabelCtn: {
    width: '25%'
  },
  fullWidthLabelCtn: {
    width: 'auto',
    marginBottom: 10,
    marginRight: 40,
  },
  fieldLabel: {
    fontFamily: 'Poppins',
    color: colors.main,
    fontSize: 11
  },
  fieldValue: {
    fontWeight: 'light',
    fontFamily: 'Poppins',
    fontSize: 11
  },
  emptyField: {
    color: colors.grey,
    fontStyle: 'italic',
  },
  fieldValueCtn: {
    width: '75%',
    paddingLeft: 35,
    paddingRight: 20
  },
  multilineValueCtn: {
    flexDirection: 'column'
  },
  signatureCtn: {
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    maxHeight: '60px',
    width: '300px',
    marginBottom: 15,
    marginTop: -10
    // borderBottomWidth: 1,
    // borderColor: "#c5c5c5",
  },
  signature: {
    width: '300px',
    objectFit: 'contain'
  },
  subfieldsCtn: {
    marginTop: -10,
    paddingHorizontal: 35,
    paddingVertical: 10
  },
  subfieldsBorder: {
    paddingTop: 10,
    marginTop: -10,
    borderWidth: 1,
    borderColor: colors.main
  },
  fieldBtnCtn: {
    width: '75%',
    paddingLeft: 35,
    // display: 'inline-flex',
    // justifyContent: 'center',
    textAlign: 'center'
  },
  fullWidthValueCtn: {
    width: '100%',
    margin: '0px',
    padding: '5px 20px 5px 0px',
    display: 'flex',
    flexGrow: 1,
    // height: 'auto',
    // backgroundColor: colors.darkgrey // pour test
  },
  previewCtn: {
    border: 'none',
    width: '100%',
    minWidth: '400px',
    height: '100%',
    '@media (min-width:1025px)': {
      minWidth: '60vw'
    }
  },
  // Indicator
  indicatorCtn: {
    position: 'absolute',
    left: 10,
    display: 'flex',
    flexDirection: 'column',
  },
  indicatorTxtCtn: {
    position: 'relative',
  },
  indicatorSlash: {
    fontSize: 11,
    fontWeight: 'bold',
    color: colors.bloodOrange,
    position: 'absolute',
    left: '40%',
    top: -2,
    transform: 'rotate(20)'
  },
  indicatorTxt: {
    fontSize: 7,
    color: colors.bloodOrange,
  },
  plugBtnCtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
    paddingLeft: 35
  },
  plugBtn: {
    borderRadius: '50%',
    color: 'white',
    backgroundColor: colors.mainButton,
    paddingHorizontal: 40,
    paddingVertical: 5,
    minWidth: 250,
    alignContent: 'center'
  },
  plugBtnText: {
    color: 'white',
    flexWrap: 'nowrap',
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    alignSelf: 'center'
  },
})

// http://test.plugnotes.com/uploads/uswE1SjDMGLkg0820759001492675386/daeMEUjoGm8Go0402037001604567020/somename%20(1).pdf

const downloadEmployeeData = async (annexe) => {
  // Fetch the dynamically generated excel document from the server.
  return axios.get(ApplicationConfig.apiUrl + 'annexe.json?annexehash=' + annexe.hash, {
    crossdomain: true,
    headers: {
      'X-AUTH-TOKEN': getToken()
    }
  }).then((response) => {
    if (response.data.result) {
      return response.data.base64 // Image Base64 Goes here
    } else {
      return false
    }
  }).catch((response) => {
    return false
  })
}

const getUrlPreviewAnnexe = (annexe) => {
  return downloadEmployeeData(annexe)
}

const renderAttThumbnail = (att, index, printOpt) => {
  const withComment = isDefined(printOpt.printAttComment) ? printOpt.printAttComment : true
  const withInfo = isDefined(printOpt.printAttInfo) ? printOpt.printAttInfo : true
  const merge = isDefined(printOpt.merge) ? printOpt.merge : false
  let iconSrc = getAttIcon(att)
  index++
  switch (att.type.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
    case 'png':
      return (
        <View style={[styles.container45H]} key={index} wrap={false}>
          <View style={[styles.iconCtn]}>
            <Image src={iconSrc} style={styles.icon} />
          </View>
          <View style={styles.iconTxtCtn}>
            <Text style={styles.attName}>{index}. {withInfo ? att.name : ''}</Text>
            {
              withInfo &&
              <Text style={styles.attInfos}>{att.type.toUpperCase()} - {(att.size / 1000).toFixed(2) + ' kb'} - {att.creationdate ? moment(att.creationdate).format('DD/MM/YYYY') : ''}</Text>
            }
            {
              typeof att.comment !== 'undefined' && att.comment.length > 0 && withComment &&
              <View style={styles.paragraphImage}>
                <Text style={[styles.textThin, styles.commentTxt]}>{att.comment}</Text>
              </View>
            }
            <Text style={[styles.attInfos, { marginBottom: 12 }]}>{i18n.t('common:print.cf-full')}</Text>
          </View>
        </View>
      )
    case 'gif':
    case 'pdf':
    case 'txt':
    case 'doc':
    case 'docx':
    case 'pages':
    case 'note':
    case 'csv':
    case 'number':
    case 'sheet':
    case 'xls':
    case 'xlsx':
    case 'pptx':
    case 'ppt':
      return (
        <View style={[styles.container45H, { marginBottom: 12 }]} key={index} wrap={false}>
          <View style={[styles.iconCtn]}>
            <Image src={iconSrc} style={styles.icon} />
          </View>
          <View style={styles.iconTxtCtn}>
            <Text style={styles.attName}>{index}. {withInfo ? att.name : ''}</Text>
            {
              withInfo &&
              <Text style={styles.attInfos}>{att.type.toUpperCase()} - {(att.size / 1000).toFixed(2) + ' kb'} - {att.creationdate ? moment(att.creationdate).format('DD/MM/YYYY') : ''}</Text>
            }
            {
              typeof att.comment !== 'undefined' && att.comment.length > 0 && withComment &&
              <View style={styles.paragraphImage}>
                <Text style={[styles.textThin, styles.commentTxt]}>{att.comment}</Text>
              </View>
            }
            {
              att.type.toLowerCase() === 'pdf' && merge &&
              <Text style={[styles.attInfos, { marginBottom: 12 }]}>{i18n.t('common:print.cf-merge')}</Text>
            }
          </View>
        </View>
      )
    default:
      return (
        <View style={[styles.container45]} key={index}>
          <Text style={styles.attName}>{index}. {withInfo ? att.name : ''}</Text>
          {
              withInfo &&
              <Text style={styles.attInfos}>{att.type.toUpperCase()} - {(att.size / 1000).toFixed(2) + ' kb'} - {att.creationdate ? moment(att.creationdate).format('DD/MM/YYYY') : ''}</Text>
            }
          <View style={[styles.thumbnailCtn]}>
            <Image src={Images.genericPng} style={styles.icon} />
          </View>
          {
            typeof att.comment !== 'undefined' && att.comment.length > 0 && withComment &&
            <View style={styles.paragraphImage}>
              <Text style={[styles.textThin, styles.commentTxt]}>{att.comment}</Text>
            </View>
          }
        </View>
      )
  }
}

const renderAtt100 = (att, index, printOpt) => {
  const withComment = isDefined(printOpt.printAttComment) ? printOpt.printAttComment : true
  const withInfo = isDefined(printOpt.printAttInfo) ? printOpt.printAttInfo : true
  index++
  return (
    <View style={[styles.fullPageCtn]} wrap={false} key={'full-' + index} >
      <View style={styles.imgLegendCtn}>
        <Text style={styles.attName}>{index}. {withInfo ? att.name : ''} - </Text>
        {
              withInfo &&
              <Text style={styles.attInfos}>{att.type.toUpperCase()} - {(att.size / 1000).toFixed(2) + ' kb'} - {att.creationdate ? moment(att.creationdate).format('DD/MM/YYYY') : ''}</Text>
            }
        <Text>{'\n'}</Text>
      </View>
      <View style={[styles.img100Ctn]}>
        <Image src={getUrlPreviewAnnexe(att)} style={styles.img100} />
      </View>
      {
        typeof att.comment !== 'undefined' && att.comment.length > 0 && withComment &&
        <Text style={styles.textThin}>{att.comment}</Text>
      }
    </View>
  )
}

const renderAtt75 = (att, index, printOpt) => {
  const withComment = isDefined(printOpt.printAttComment) ? printOpt.printAttComment : true
  const withInfo = isDefined(printOpt.printAttInfo) ? printOpt.printAttInfo : true
  index++
  switch (att.type.toLowerCase()) {
    case 'jpg':
    case 'JPG':
    case 'jpeg':
    case 'png':
    case 'PNG':
      return (
        <View style={[styles.container100, styles.containerLayout75]} key={index} wrap={false}>
          <View style={[styles.img75Ctn]}>
            <Image src={getUrlPreviewAnnexe(att)}
              style={styles.img50}
              resizeMode="center"
            />
          </View>
          <View style={[styles.descCtn75]}>
            <Text style={styles.attName}>{index}. {withInfo ? att.name : ''}</Text>
            {
              withInfo &&
              <Text style={styles.attInfos}>{att.type.toUpperCase()} - {(att.size / 1000).toFixed(2) + ' kb'} - {att.creationdate ? moment(att.creationdate).format('DD/MM/YYYY') : ''}</Text>
            }
            {
              typeof att.comment !== 'undefined' && att.comment.length > 0 && withComment &&
              <View style={styles.paragraphImage}>
                <Text style={[styles.textThin, styles.commentTxt]}>{att.comment}</Text>
              </View>
            }
          </View>
        </View>
      )
    default:
      return (
        <View style={[styles.container45]} key={index}>
          <Text style={styles.attName}>{index}. {withInfo ? att.name : ''}</Text>
          {
              withInfo &&
              <Text style={styles.attInfos}>{att.type.toUpperCase()} - {(att.size / 1000).toFixed(2) + ' kb'} - {att.creationdate ? moment(att.creationdate).format('DD/MM/YYYY') : ''}</Text>
            }
          <View style={[styles.thumbnailCtn]}>
            <Image src={Images.genericPng} style={styles.icon} />
          </View>
          {
            typeof att.comment !== 'undefined' && att.comment.length > 0 && withComment &&
            <View style={styles.paragraphImage}>
              <Text style={[styles.textThin, styles.commentTxt]}>{att.comment}</Text>
            </View>
          }
        </View>
      )
  }
}

const renderAtt50 = (att, index, printOpt) => {
  const withComment = isDefined(printOpt.printAttComment) ? printOpt.printAttComment : true
  const withInfo = isDefined(printOpt.printAttInfo) ? printOpt.printAttInfo : true
  index++
  switch (att.type.toLowerCase()) {
    case 'jpg':
    case 'JPG':
    case 'jpeg':
    case 'png':
    case 'PNG':
      return (
        <View style={[styles.container100]} key={index} wrap={false}>
          <Text style={styles.attName}>{index}. {withInfo ? att.name : ''}</Text>
          {
              withInfo &&
              <Text style={styles.attInfos}>{att.type.toUpperCase()} - {(att.size / 1000).toFixed(2) + ' kb'} - {att.creationdate ? moment(att.creationdate).format('DD/MM/YYYY') : ''}</Text>
            }
          <View style={[styles.img50Ctn]}>
            <Image src={getUrlPreviewAnnexe(att)}
              style={styles.img50}
              resizeMode="center"
            />
          </View>
          {
            typeof att.comment !== 'undefined' && att.comment.length > 0 && withComment &&
            <View style={styles.paragraphImage}>
              <Text style={[styles.textThin, styles.commentTxt]}>{att.comment}</Text>
            </View>
          }
        </View>
      )
    default:
      return (
        <View style={[styles.container45]} key={index}>
          <Text style={styles.attName}>{index}. {withInfo ? att.name : ''}</Text>
          {
              withInfo &&
              <Text style={styles.attInfos}>{att.type.toUpperCase()} - {(att.size / 1000).toFixed(2) + ' kb'} - {att.creationdate ? moment(att.creationdate).format('DD/MM/YYYY') : ''}</Text>
            }
          <View style={[styles.thumbnailCtn]}>
            <Image src={Images.genericPng} style={styles.icon} />
          </View>
          {
            typeof att.comment !== 'undefined' && att.comment.length > 0 && withComment &&
            <View style={styles.paragraphImage}>
              <Text style={[styles.textThin, styles.commentTxt]}>{att.comment}</Text>
            </View>
          }
        </View>
      )
  }
}

const renderAtt25 = (att, index, printOpt) => {
  const withComment = isDefined(printOpt.printAttComment) ? printOpt.printAttComment : true
  const withInfo = isDefined(printOpt.printAttInfo) ? printOpt.printAttInfo : true
  index++
  switch (att.type.toLowerCase()) {
    case 'jpg':
    case 'JPG':
    case 'jpeg':
    case 'png':
    case 'PNG':
      return (
        <View style={[styles.container45]} key={index} wrap={false}>
          <Text style={styles.attName}>{index}. {withInfo ? att.name : ''}</Text>
          {
              withInfo &&
              <Text style={styles.attInfos}>{att.type.toUpperCase()} - {(att.size / 1000).toFixed(2) + ' kb'} - {att.creationdate ? moment(att.creationdate).format('DD/MM/YYYY') : ''}</Text>
            }
          <View style={[styles.img25Ctn]}>
            <Image src={getUrlPreviewAnnexe(att)}
              style={styles.img50}
              resizeMode="center"
            />
          </View>
          {
            typeof att.comment !== 'undefined' && att.comment.length > 0 && withComment &&
            <View style={styles.paragraphImage}>
              <Text style={[styles.textThin, styles.commentTxt]}>{att.comment}</Text>
            </View>
          }
        </View>
      )
    default:
      return (
        <View style={[styles.container45]} key={index}>
          <Text style={styles.attName}>{index}. {withInfo ? att.name : ''}</Text>
          {
            withInfo &&
              <Text style={styles.attInfos}>{att.type.toUpperCase()} - {(att.size / 1000).toFixed(2) + ' kb'} - {att.creationdate ? moment(att.creationdate).format('DD/MM/YYYY') : ''}</Text>
          }
          <View style={[styles.thumbnailCtn]}>
            <Image src={Images.genericPng} style={styles.icon} />
          </View>
          {
            typeof att.comment !== 'undefined' && att.comment.length > 0 && withComment &&
            <View style={styles.paragraphImage}>
              <Text style={[styles.textThin, styles.commentTxt]}>{att.comment}</Text>
            </View>
          }
        </View>
      )
  }
}

const renderAtt15 = (att, index, printOpt) => {
  const withComment = isDefined(printOpt.printAttComment) ? printOpt.printAttComment : true
  const withInfo = isDefined(printOpt.printAttInfo) ? printOpt.printAttInfo : true
  index++
  switch (att.type.toLowerCase()) {
    case 'jpg':
    case 'JPG':
    case 'jpeg':
    case 'png':
    case 'PNG':
      return (
        <View style={[styles.container45]} key={index} wrap={false}>
          <Text style={styles.attName}>{index}. {withInfo ? att.name : ''}</Text>
          {
              withInfo &&
              <Text style={styles.attInfos}>{att.type.toUpperCase()} - {(att.size / 1000).toFixed(2) + ' kb'} - {att.creationdate ? moment(att.creationdate).format('DD/MM/YYYY') : ''}</Text>
            }
          <View style={[styles.thumbnailCtn]}>
            <Image src={getUrlPreviewAnnexe(att)} style={styles.iconImg} />
          </View>
          {
            typeof att.comment !== 'undefined' && att.comment.length > 0 && withComment &&
            <View style={styles.paragraphImage}>
              <Text style={[styles.textThin, styles.commentTxt]}>{att.comment}</Text>
            </View>
          }
        </View>
      )
    default:
      return (
        <View style={[styles.container45]} key={index}>
          <Text style={styles.attName}>{index}. {withInfo ? att.name : ''}</Text>
          {
              withInfo &&
              <Text style={styles.attInfos}>{att.type.toUpperCase()} - {(att.size / 1000).toFixed(2) + ' kb'} - {att.creationdate ? moment(att.creationdate).format('DD/MM/YYYY') : ''}</Text>
            }
          <View style={[styles.thumbnailCtn]}>
            <Image src={Images.genericPng} style={styles.icon} />
          </View>
          {
            typeof att.comment !== 'undefined' && att.comment.length > 0 && withComment &&
            <View style={styles.paragraphImage}>
              <Text style={[styles.textThin, styles.commentTxt]}>{att.comment}</Text>
            </View>
          }
        </View>
      )
  }
}

const renderAtt10 = (att, index, printOpt) => {
  const withComment = isDefined(printOpt.printAttComment) ? printOpt.printAttComment : true
  const withInfo = isDefined(printOpt.printAttInfo) ? printOpt.printAttInfo : true
  let iconSrc = getAttIcon(att)
  index++
  switch (att.type.toLowerCase()) {
    case 'jpg':
    case 'JPG':
    case 'jpeg':
    case 'png':
    case 'PNG':
      return (
        <View style={[styles.container100, styles.containerLayout10]} key={index} wrap={false}>
          <View style={[styles.thumbnailCtn, styles.img10Ctn]}>
            <Image src={getUrlPreviewAnnexe(att)} style={styles.iconImg} />
          </View>
          <View style={styles.descCtn10}>
            <Text style={styles.attName}>{index}. {withInfo ? att.name : ''}</Text>
            {
              withInfo &&
              <Text style={styles.attInfos}>{att.type.toUpperCase()} - {(att.size / 1000).toFixed(2) + ' kb'} - {att.creationdate ? moment(att.creationdate).format('DD/MM/YYYY') : ''}</Text>
            }
            {
              typeof att.comment !== 'undefined' && att.comment.length > 0 && withComment && withComment &&
              <View style={styles.paragraphImage}>
                <Text style={[styles.textThin, styles.commentTxt]}>{att.comment}</Text>
              </View>
            }
          </View>
        </View>
      )
    default:
      return (
        <View style={[styles.container100, styles.containerLayout10]} key={index} wrap={false}>
          <View style={[styles.thumbnailCtn]}>
            <Image src={iconSrc} style={styles.icon} />
          </View>
          <View style={styles.descCtn10}>
            <Text style={styles.attName}>{index}. {withInfo ? att.name : ''}</Text>
            {
              withInfo &&
              <Text style={styles.attInfos}>{att.type.toUpperCase()} - {(att.size / 1000).toFixed(2) + ' kb'} - {att.creationdate ? moment(att.creationdate).format('DD/MM/YYYY') : ''}</Text>
            }
            {
              typeof att.comment !== 'undefined' && att.comment.length > 0 && withComment && withComment &&
              <View style={styles.paragraphImage}>
                <Text style={[styles.textThin, styles.commentTxt]}>{att.comment}</Text>
              </View>
            }
          </View>
        </View>
      )
  }
}

const renderSignature = (field, index, signature) => {
  const template = (
    <View style={[styles.fieldValueCtn, { width: '100%', alignSelf: 'center', paddingTop: 10 }]}>
      <Text style={styles.fieldValue}>{field.value}</Text>
    </View>
  )

  return (
    <View
      wrap={false}
      style={styles.mainFieldCtn}
      key={'field-signature-' + index}
    >
      <View style={styles.fullWidthLabelCtn}>
        {template}
      </View>
      <View style={styles.signatureCtn}>
        <Image src={signature} style={styles.signature} />
      </View>
    </View>
  )
}

const renderField = (field, options, index, pindex, hiddenOpt, relPlugnote, model, t, mode) => {
  const value = field.value
  const format = field.format
  let showHidden = false
  let isFullWidth = false
  let isEmpty = false
  let template

  if (isEmptyField(field.value)) {
    isEmpty = true
    template = (
      <View style={styles.fieldValueCtn}>
        <Text style={[styles.fieldValue, styles.emptyField]}></Text>
        {/* <Text style={[styles.fieldValue, styles.emptyField]}>{t('global.no-data')}</Text> */}
      </View>
    )
  } else {
    switch (format) {
      case 'intdatamaj':
        if (value !== '') {
          try {
            if (value.hasOwnProperty('value') && value.hasOwnProperty('updatedDate')) {
              template = (
                <View style={styles.fieldValueCtn}>
                  <Text style={styles.fieldValue}>{value.value} {value.updatedDate !== "" ? value.updatedDate : ""}</Text>
                </View>
              )
            }
            else {
              template = (
                <View style={styles.fieldValueCtn}>
                  <Text style={styles.fieldValue} />
                </View>
              )
            }
          } catch (e) {
            template = (
              <View style={styles.fieldValueCtn}>
                <Text style={styles.fieldValue} />
              </View>
            )
          }
        }
        else {
          template = (
            <View style={styles.fieldValueCtn}>
              <Text style={styles.fieldValue} />
            </View>
          )
        }
        break;
      case 'qrcodeint':
        const qrcodeintComment = typeof value.comment !== 'undefined' ? value.comment : ''
        const qrcodeintCode = typeof value.code !== 'undefined' ? value.code : ''
        if (qrcodeintComment !== '' && qrcodeintCode !== '') {
          const url = ApplicationConfig.templateUrl + '?code=' + qrcodeintCode
          template = (
            <View style={styles.fieldValueCtn}>
              <Text style={styles.fieldValue}><Link src={url} target="_blank"><Text style={styles.fieldValue}>{qrcodeintCode}</Text></Link> - {qrcodeintComment}</Text>
            </View>
          )
          // console.log('rendering F22 values', isDefined(relPlugnote), relPlugnote)
        } else if (qrcodeintComment !== '') {
          template = (
            <View style={styles.fieldCtn}>
              <View style={styles.fieldLabelCtn}>
                <Text style={styles.fieldLabel}>{field.title}: </Text>
              </View>
              <View style={styles.fieldValueCtn}>
                <Text style={styles.fieldValue}>{qrcodeintComment}</Text>
              </View>
            </View>
          )
        } else if (qrcodeintCode !== '') {
          const url = ApplicationConfig.templateUrl + '?code=' + qrcodeintCode
          template = (
            <View style={styles.fieldCtn}>
              <View style={styles.fieldLabelCtn}>
                <Text style={styles.fieldLabel}>{field.title}: </Text>
              </View>
              <View style={styles.fieldValueCtn}>
                <Link src={url} target="_blank"><Text style={styles.fieldValue}>{qrcodeintCode}</Text></Link>
              </View>
            </View>
          )
        } else {
          template = (
            <View style={styles.fieldValueCtn}>
              <Text style={styles.fieldValue} />
            </View>
          )
        }
        break
      case 'document':
      case 'tablemultin':
        if (value !== '') {
          try {
            if (value.hasOwnProperty('value') && value.hasOwnProperty('list')) {
              const formatOptions = typeof options !== 'undefined' ? JSON.parse(options) : field.options
              isFullWidth = true
              template = (
                <View style={styles.fullWidthValueCtn}>
                  <ModalTablemultiTableViewComponent
                    colonnes={formatOptions.lines}
                    field={field}
                    hiddenOpt={hiddenOpt}
                    isPreview={false}
                    rows={value.list}
                    toPrint={true}
                  />
                </View>
              )
            } else {
              template = (
                <View style={styles.fieldValueCtn}>
                  <Text style={styles.fieldValue} />
                </View>
              )
            }
          } catch (e) {
            template = (
              <View style={styles.fieldValueCtn}>
                <Text style={styles.fieldValue} />
              </View>
            )
          }
        }
        else {
          template = (
            <View style={styles.fieldValueCtn}>
              <Text style={styles.fieldValue} />
            </View>
          )
        }
        break
      case 'smailvalidmulti':
        if (value !== '') {
          isFullWidth = true
          try {
            if (value.hasOwnProperty('value') && value.hasOwnProperty('list')) {
              const formatOptions = typeof options !== 'undefined' ? returnJSON(options) : field.options

              template = (
                <View style={styles.fullWidthValueCtn}>
                  <ModalTablemultiTableViewComponent
                    colonnes={formatOptions.lines}
                    field={field}
                    hiddenOpt={hiddenOpt}
                    isPreview={false}
                    rows={value.list}
                    toPrint={true}
                  />
                </View>
              )
            } else {
              template = (
                <View style={styles.fieldValueCtn}>
                  <Text style={styles.fieldValue} />
                </View>
              )
            }
          } catch (e) {
            template = (
              <View style={styles.fieldValueCtn}>
                <Text style={styles.fieldValue}>{e}</Text>
              </View>
            )
          }
        } else {
          template = (
            <View style={styles.fieldValueCtn}>
              <Text style={styles.fieldValue} />
            </View>
          )
        }
        break
      case 'tablemulti':
        const formatOptions = typeof options !== 'undefined' ? returnJSON(options) : field.options
        const headers = formatOptions?.lines ? formatOptions.lines : formatOptions
        isFullWidth = true

        template = (
          <View style={styles.fullWidthValueCtn} wrap={false}>
            <ModalTablemultiTableViewComponent
              colonnes={headers}
              field={field}
              hiddenOpt={hiddenOpt}
              isPreview={false}
              rows={Array.isArray(value) ? value : []}
              toPrint={true}
              mode={mode}
            />
          </View>
        )
        break
      case 'boolean':
        template = (
          <View style={styles.fieldValueCtn}>
            <Text style={styles.fieldValue}>{value === '1' ? 'oui/yes' : 'non/no'}</Text>
          </View>
        )
        break
      case 'single-select':
      case 'free-single-select':
      case 'multi-select':
      case 'free-multi-select':
        const concatValue = value.join(', ')
        template = (
          <View style={styles.fieldValueCtn}>
            <Text style={styles.fieldValue}>{concatValue}</Text>
          </View>
        )
        break
      case 'single-table':
      case 'free-single-table':
      case 'multi-table':
      case 'free-multi-table':
        // console.log('select-table', field, value);
        let concatValueMultipleTable = ''
        value.forEach((item, index) => {
          if (typeof item.text !== 'undefined') {
            if (index < (value.length - 1)) {
              concatValueMultipleTable += item.text + ' : ' + item.value + ' ~ '
            } else {
              concatValueMultipleTable += item.text + ' : ' + item.value
            }
          }
        })
        template = (
          <View style={styles.fieldValueCtn}>
            <Text style={styles.fieldValue}>{concatValueMultipleTable}</Text>
          </View>
        )
        break;

      case 'numeric':
        // PREVIOUS CODE:
        /*      case 'numeric':
                template = isNaN(value)
                  ? (
                    <View style={styles.fieldValueCtn}>
                      <Text style={[styles.fieldValue, styles.emptyField]}>{t('global.no-data')}</Text>
                    </View>
                  ) : (
                    <View style={styles.fieldValueCtn}>
                      <Text style={styles.fieldValue}>{value}</Text>
                    </View>
                  )
                break*/

        // ADDED CODE FOR NUMERIC FORMAT OPTIONS
        const formatValueToPrint = () => {
          let tmpValueToFormat = value?.toString();
          let thousandSepToApply = field.options?.setup?.septhousand;
          let decimalSepToApply = field.options?.setup?.sepdecimal;
          let decimalQuantity = field.options?.setup?.numberdecimals;
          //let isActiveThousandSeparator = field.options?.setup?.isActiveThousandSeparator;
          let isActiveThousandSeparator = false
          if(thousandSepToApply !== false) {
            isActiveThousandSeparator = true
          }
          return formatNumberApplyOptions(isActiveThousandSeparator, tmpValueToFormat, thousandSepToApply, decimalSepToApply, decimalQuantity);
        }

        template = isNaN(value)
            ? (
                <View style={styles.fieldValueCtn}>
                  <Text style={[styles.fieldValue, styles.emptyField]}>{t('global.no-data')}</Text>
                </View>
            ) :
            ((field.options?.setup?.hasOwnProperty('isCustomNumericField'))?
                (<View style={styles.fieldValueCtn}>
                  <Text style={styles.fieldValue}>{formatValueToPrint(value)}</Text>
                </View>)
                :
                (<View style={styles.fieldValueCtn}>
                  <Text style={styles.fieldValue}>{value}</Text>
                </View>)
            )
        break;


      case 'date': // ADDED CODE FOR DATE FORMAT OPTIONS

        //let dateValueFromPdfToFormat = value.toString();
        let dateValueFromPdfToFormat = value;
        let dateFormatFromFieldOptions = field.options?.setup?.dateFormatOption;

        const regexpSlash = /['/']/gi;
        const regexpHyphen = /['\-']/gi;

        const checkSeparatorCharacter = (stringToCheck, characterToCheck) => {
          const isCharacterInString = stringToCheck.includes(characterToCheck);
          //console.log("isCharacterInString : ", isCharacterInString);
          //console.log("characterToCheck : ", characterToCheck);
          return isCharacterInString;
        }


        const checkSeparatorQty = (stringToCheck, separatorRegexpToCheck) => {
          const matchesReturn = stringToCheck.match(separatorRegexpToCheck);
          //console.log("matchesReturn : ", matchesReturn);
          //console.log("matchesReturn.length : ", matchesReturn.length);
          return matchesReturn.length;
        }


        const dateSplitInParts = (d) => {
        let parts =d.split('-');
        // default format from DB is 'yyyy-mm-dd' -> "2023-11-31"
        let yearPart = parts[0];
        let monthPart = parts[1];
        let dayPart = parts[2];
        //console.log('yearPart : ', yearPart);
        //console.log('monthPart : ', monthPart);
        //console.log('dayPart : ', dayPart);

        const dateSplitedParts = {yearPart, monthPart, dayPart}
        //to put all parts (only digits remaining) in one string if needed
          // -> let dateString = Number(parts[2] + parts[1] + parts[0]);

          //console.log('dateSplitedParts : ', dateSplitedParts);
          // with "2023-11-31"
          // {
          //     "yearPart": "2023",
          //     "monthPart": "11",
          //     "dayPart": "31"
          // }
        return dateSplitedParts;
      }


        const switchOnDateFormatsHyphenCases = (dateFormat, separatorQty, hyphenSep, dateToFormat) =>{
          // console.log(dateFormat);
          // console.log(separatorQty);
          // console.log(hyphenSep);
          // console.log(dateToFormat);

          const datePartsValues = dateSplitInParts(dateToFormat);
          //console.log(datePartsValues);
          let yyyy = datePartsValues.yearPart;
          let yy = datePartsValues.yearPart.substr(2,2);
          let mm = datePartsValues.monthPart;
          let dd = datePartsValues.dayPart;

          if(separatorQty == 1)
          {
            let returnedValue = '';
            let returnedValueFormat = '';

            switch(dateFormat) {
              case 'dd-mm' :
                //console.log(`${dd}`);
                //returnedValueFormat = 'dd-mm';
                //returnedValue =  `${dd}-${mm}`;
                //returnedValue =  `${dd}${hyphenSep}${mm}`;
                returnedValue =  dd + hyphenSep + mm;
                break;

              case 'mm-dd' :
                //returnedValueFormat = 'mm-dd';
                returnedValue = mm + hyphenSep + dd;
                break;

              case 'mm-yyyy' :
                //returnedValueFormat = 'mm-yyyy';
                returnedValue =  mm + hyphenSep + yyyy;
                break;

              case 'mm-yy' :
                //returnedValueFormat = 'mm-yy';
                returnedValue =  mm + hyphenSep + yy;
                break;

              case 'yy-mm' :
                //returnedValueFormat = 'yy-mm';
                returnedValue =  yy + hyphenSep + mm;
                break;

              case 'yyyy-mm' :
                //returnedValueFormat = 'yyyy-mm';
                returnedValue =  yyyy + hyphenSep + mm;
                break;

              default: returnedValue = 'def 1';
            }
            return returnedValue;
          }
          else if(separatorQty == 2)
          {
            let returnedValue = '';
            let returnedValueFormat = '';

            switch(dateFormat) {
              case 'dd-mm-yyyy' :
                //returnedValueFormat = 'dd-mm-yyyy';
                returnedValue = dd + hyphenSep + mm + hyphenSep + yyyy;
                break;

              case 'dd-mm-yy' :
                //returnedValueFormat = 'dd-mm-yy';
                returnedValue = dd + hyphenSep + mm + hyphenSep + yy;
                break;

              case 'yy-mm-dd' :
                //returnedValueFormat = 'yy-mm-dd';
                returnedValue = yy + hyphenSep + mm + hyphenSep + dd;
                break;

              case 'yyyy-mm-dd' :
                //returnedValueFormat = 'yyyy-mm-dd';
                returnedValue = yyyy + hyphenSep + mm + hyphenSep + dd;
                break;

              default: returnedValue = 'def 2';
            }
            return returnedValue;
          }
          else
          {
            console.log('wrong qty');
          }
        }


        const switchOnDateFormatsSlashCases = (dateFormat, separatorQty, slashSep, dateToFormat) =>{
          // console.log(dateFormat);
          // console.log(separatorQty);
          // console.log(hyphenSep);
          // console.log(dateToFormat);

          const datePartsValues = dateSplitInParts(dateToFormat);
          //console.log(datePartsValues);
          let yyyy = datePartsValues.yearPart;
          let yy = datePartsValues.yearPart.substr(2,2);
          let mm = datePartsValues.monthPart;
          let dd = datePartsValues.dayPart;

          if(separatorQty == 1)
          {
            let returnedValue = '';
            let returnedValueFormat = '';

            switch(dateFormat) {
              case 'dd/mm' :
                //returnedValueFormat = 'dd/mm';
                returnedValue =  dd + slashSep + mm;
                break;

              case 'mm/dd' :
                //returnedValueFormat = 'mm/dd';
                returnedValue = mm + slashSep + dd;
                break;

              case 'mm/yyyy' :
                //returnedValueFormat = 'mm/yyyy';
                returnedValue = mm + slashSep + yyyy;
                break;

              case 'mm/yy' :
                //returnedValueFormat = 'mm/yy';
                returnedValue = mm + slashSep + yy;
                break;

              case 'yy/mm' :
                //returnedValueFormat = 'yy/mm';
                returnedValue = yy + slashSep + mm;
                break;

              case 'yyyy/mm' :
                //returnedValueFormat = 'yyyy/mm';
                returnedValue = yyyy + slashSep + mm;
                break;

              default: returnedValue = 'def 1';
            }
            return returnedValue;
          }
          else if(separatorQty == 2)
          {
            let returnedValue = '';
            let returnedValueFormat = '';

            switch(dateFormat) {
              case 'dd/mm/yyyy' :
                //returnedValueFormat = 'dd/mm/yyyy';
                returnedValue = dd + slashSep + mm + slashSep + yyyy;
                break;

              case 'dd/mm/yy' :
                //returnedValueFormat = 'dd/mm/yy';
                returnedValue = dd + slashSep + mm + slashSep + yy;
                break;

              case 'yy/mm/dd' :
                //returnedValueFormat = 'yy/mm/dd';
                returnedValue = yy + slashSep + mm + slashSep + dd;
                break;

              case 'yyyy/mm/dd' :
                //returnedValueFormat = 'yyyy/mm/dd';
                returnedValue = yyyy + slashSep + mm + slashSep + dd;
                break;

              default: returnedValue = 'def 2';
            }
            return returnedValue;
          }
          else
          {
            console.log('wrong qty');
          }
        }


        const formatDateToPdf = (dF, dateToFormat) => {
          //console.log("dateFormat : ", dF);
          //console.log("dateToFormat : ", dateToFormat);

          // remove uppercase in dF from field.options?.setup?.dateFormatOption before switching on format cases
          dF = dF.toLowerCase();

          let slashSep = '/';
          let hyphenSep = '-';

          let hasSlash = checkSeparatorCharacter(dF, slashSep);
          let hasHyphen = checkSeparatorCharacter(dF, hyphenSep);
          //console.log("hasSlash : ", hasSlash);
          //console.log("hasHyphen : ", hasHyphen);

          if(hasHyphen)
          {
            let hyphenQty = checkSeparatorQty(dF, regexpHyphen);
            //console.log('hyphenQty :', hyphenQty);

            const returnedValueFromHyphenCases = switchOnDateFormatsHyphenCases(dF, hyphenQty, hyphenSep, dateToFormat);

            //console.log("returnedValueFromHyphenCases : ", returnedValueFromHyphenCases);
            return returnedValueFromHyphenCases;
          }
          else
          {
            //console.log("no hypen ?!");
          }

          if(hasSlash)
          {
            let slashQty = checkSeparatorQty(dF, regexpSlash);
            //console.log('slashQty :', slashQty);

            const returnedValueFromSlashCases = switchOnDateFormatsSlashCases(dF, slashQty, slashSep, dateToFormat);

            //console.log("returnedValueFromSlashCases : ", returnedValueFromSlashCases);
            return returnedValueFromSlashCases;
          }
          else
          {
            //console.log("no slash ?!");
          }
          //return returnedValue;
        }

        template = (value === '')
            ? (
                <View style={styles.fieldValueCtn}>
                  <Text style={[styles.fieldValue, styles.emptyField]}>{t('global.no-data')}</Text>
                </View>
            ) :
            ((field.options?.setup?.hasOwnProperty('dateFormatOption'))?
                    (<View style={styles.fieldValueCtn}>
                      {/*<Text style={styles.fieldValue}>CUSTOM FORMATTED</Text>*/}
                      <Text style={styles.fieldValue}>{formatDateToPdf(dateFormatFromFieldOptions, dateValueFromPdfToFormat)}</Text>
                    </View>)
                    :
                    (<View style={styles.fieldValueCtn}>
                      <Text style={styles.fieldValue}>{value}</Text>
                    </View>)
            )
        break;


/*        template = isNaN(value)
            ? (
                <View style={styles.fieldValueCtn}>
                  <Text style={[styles.fieldValue, styles.emptyField]}>{t('global.no-data')}</Text>
                </View>
            ) :
            ((field.options?.setup?.hasOwnProperty('dateFormatOption'))?
                    (<View style={styles.fieldValueCtn}>
                      <Text style={styles.fieldValue}>CUSTOM FORMATTED</Text>
                      {/!*<Text style={styles.fieldValue}>{myFunctionTest(value)}</Text>*!/}
                    </View>)
                    :
                    (<View style={styles.fieldValueCtn}>
                      <Text style={styles.fieldValue}>{value}</Text>
                    </View>)
            )
            break;*/

      case 'pluglink':
        if (typeof value.code !== 'undefined' && value.code !== '' && value.userindex !== 'undefined' && value.userindex !== '') {
          let source = ApplicationConfig.plugcodesUrl + value.code.toString() + '/' + value.clef.toString() + '/' + value.userindex.toString()
          if (typeof model !== 'undefined' && !isNull(model)) {
            source = ApplicationConfig.plugcodesUrl + value.code.toString() + '/' + value.clef.toString() + '/' + value.userindex.toString() + '/' + model.hash.toString()
          }
          template = (
            <View style={styles.fieldBtnCtn}>
              <View style={styles.plugBtnCtn}>
                <View style={styles.plugBtn}>
                  <Text maxLines={1} style={styles.textThin}>
                    <Link rel="noopener noreferrer" src={source} style={styles.plugBtnText} target="_blank">{t('common:pluglink.fill-form-1') + ' [' + value.clef.toString() + ']'}</Link>
                  </Text>
                  <Text style={[styles.textThin]}>
                    <Link rel="noopener noreferrer" src={source} style={styles.plugBtnText} target="_blank">{value.code.toString()}</Link>
                  </Text>
                </View>
              </View>
            </View>
          )
        } else {
          template = (
            <View style={styles.fieldValueCtn}>
              <Text style={styles.textThin} />
            </View>
          )
        }
        break
      case 'plugform':
        let plugformValue = typeof value.value !== 'undefined' ? value.value : ''
        plugformValue = plugformValue.split('~')
        plugformValue = plugformValue.map(str => str.trim())
        template = (
          <View style={styles.fieldValueCtn}>
            <Text style={styles.fieldValue}>{plugformValue.join('\n')}</Text>
          </View>
        )
        break
      case 'password':
        template = (
          <View style={styles.fieldValueCtn}>
            <Text style={styles.fieldValue}>********</Text>
          </View>
        )
        break
      case 'mail':
        template = (
          <View style={styles.fieldValueCtn}>
            <Text style={styles.fieldValue}><Link>{value}</Link></Text>
          </View>
        )
        break
      case 'url':
        template = (
          <View style={styles.fieldValueCtn}>
            <Text style={styles.fieldValue}><Link src={value}>{value}</Link></Text>
          </View>
        )
        break
      case 'glocalis':
        let jsonValue = value
        if (isJsonString(value)) {
          jsonValue = JSON.parse(value)
        }
        template = (
          <View style={styles.fieldValueCtn}>
            <View style={styles.multilineValueCtn}>
              <Text style={styles.fieldValue}>{jsonValue.address}</Text>
              {jsonValue.latitude !== '' && jsonValue.longitude !== '' &&
                <Text style={styles.fieldValue}>Latitude: {jsonValue.latitude} Longitude: {jsonValue.longitude}</Text>}
              <Text style={styles.fieldValue}>(
                <Link src={'http://maps.google.com/maps?daddr=' + encodeURI(jsonValue.address)}>
                  <Text>{t('common:print.open-maps')}</Text>
                </Link>)
              </Text>
            </View>
          </View>
        )
        break
      case 'localis':
        template = (
          <View style={styles.fieldValueCtn}>
            <Text style={styles.fieldValue}>{value}</Text>
            <Text style={styles.fieldValue}>(
              <Link src={'http://maps.google.com/maps?daddr=' + encodeURI(value)}>
                <Text>{t('common:print.open-maps')}</Text>
              </Link>)
            </Text>

          </View>
        )
        break
      default:
        template = (
          <View style={styles.fieldValueCtn}>
            <Text style={styles.fieldValue}>{value}</Text>
          </View>
        )
        break
    }
  }
  if (typeof options !== 'undefined' && options.hidden === true && !showHidden) return null
  else {
    let key = pindex !== undefined ? 'plug-' + pindex + '-field-' + index : 'field-' + index
    if (isDefined(relPlugnote)) { key = 'subfield-' + pindex + '-' + index }
    return (
      <View
        key={key}
        style={styles.mainFieldCtn}
      >
        {
          hiddenOpt.indicator &&
          <View style={styles.indicatorCtn}>
            {
              !field.encodable &&
              <View style={styles.indicatorTxtCtn}>
                <Text style={styles.indicatorTxt} >PF-</Text>
                <Text style={styles.indicatorSlash} >/</Text>
              </View>
            }
            {
              !field.visible &&
              <View style={styles.indicatorTxtCtn}>
                <Text style={styles.indicatorTxt} >PN-</Text>
                <Text style={styles.indicatorSlash} >/</Text>
              </View>
            }
            {
              !field?.options?.setup?.printable &&
              <View style={styles.indicatorTxtCtn}>
                <Text style={styles.indicatorTxt} >PDF</Text>
                <Text style={styles.indicatorSlash} >/</Text>
              </View>
            }
          </View>
        }
        <View style={(isFullWidth || isEmpty) ? styles.fullWidthFieldCtn : styles.fieldCtn} >
          <View style={(isFullWidth || isEmpty) ? styles.fullWidthLabelCtn : styles.fieldLabelCtn}>
            <Text style={[styles.fieldLabel, isDefined(relPlugnote) ? { paddingLeft: 20, color: colors.unselectedAlpha } : null]}>{field.title}: </Text>
          </View>
          <View>
            {template}
          </View>
        </View>
      </View>
    )
  }
}

const renderFieldWithSubField = (field, index, pindex, showHidden, relPlugnote, model, t, mode) => {
  const value = field.value
  const format = field.format
  let template = null
  let subfields = null
  let url = null
  switch (format) {
    case 'qrcodeint':
      const qrcodeintComment = typeof value.comment !== 'undefined' ? value.comment : ''
      const qrcodeintCode = typeof value.code !== 'undefined' ? value.code : ''
      subfields = (
        <View style={styles.subfieldsCtn}>
          <View style={styles.subfieldsBorder}>
            {
              isDefined(relPlugnote) && relPlugnote.fields
                .map((field, subindex) => {
                  return renderField(field, field.options, subindex, undefined, showHidden, model, t, mode)
                })
            }
          </View>
        </View>
      )
      if (qrcodeintComment !== '' && qrcodeintCode !== '') {
        url = ApplicationConfig.templateUrl + '?code=' + qrcodeintCode
        template = (
          <View style={styles.fieldValueCtn}>
            <Text style={styles.fieldValue}><Link src={url} target="_blank"><Text style={styles.fieldValue}>{qrcodeintCode}</Text></Link> - {qrcodeintComment}</Text>
          </View>
        )
        // console.log('rendering F22 values', isDefined(relPlugnote), relPlugnote)
      } else if (qrcodeintComment !== '') {
        template = (
          <View style={styles.fieldCtn}>
            <View style={styles.fieldLabelCtn}>
              <Text style={styles.fieldLabel}>{field.title}: </Text>
            </View>
            <View style={styles.fieldValueCtn}>
              <Text style={styles.fieldValue}>{qrcodeintComment}</Text>
            </View>
          </View>
        )
      } else if (qrcodeintCode !== '') {
        url = ApplicationConfig.templateUrl + '?code=' + qrcodeintCode
        template = (
          <View style={styles.fieldCtn}>
            <View style={styles.fieldLabelCtn}>
              <Text style={styles.fieldLabel}>{field.title}: </Text>
            </View>
            <View style={styles.fieldValueCtn}>
              <Link src={url} target="_blank"><Text style={styles.fieldValue}>{qrcodeintCode}</Text></Link>
            </View>
          </View>
        )
      } else {
        template = (
          <View style={styles.fieldValueCtn}>
            <Text style={styles.fieldValue} />
          </View>
        )
      }
      break
    case 'qrcode':
    default:
      break;
  }
  return (
    <View
      key={'subfield-' + pindex + '-' + index}
      style={styles.mainFieldCtn}
    >
      <View style={styles.fieldCtn} >
        <View style={styles.fieldLabelCtn}>
          <Text style={[styles.fieldLabel, { paddingLeft: 20, color: colors.unselectedAlpha }]}>{field.title}: </Text>
        </View>
        <View>
          {template}
        </View>
      </View>
      {
        subfields !== null &&
        <View>
          {subfields}
        </View>
      }
    </View>
  )
}

const imagesListType = ['jpg', 'jpeg', 'png']
// const formatsWithRelated = ['qrcode', 'qrcodeint'] // F19 et F22

const PageFooter = (props) => {
  const t= props.t
  return (
    <View style={styles.footer} fixed>
      <Text style={[styles.footerText, { textAlign: 'left' }]}>{props.footerLeft}</Text>
      <Text style={[styles.footerText, { color: 'grey', fontSize: 9 }]}>{t('common:print.printed-on')}{props.date}</Text>
      <View  style={styles.pageNbCtn}>
        <Text style={styles.pageNb} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} />
      </View>
      {
        props.footerRight &&
        <Text style={[styles.footerText, { textAlign: 'right' }]}>{props.footerRight}</Text>
      }
    </View>
  )
}

const renderAttachmentList = (field, annexes, hasLinks) => {
  return (
    <View style={[styles.fullWidthValueCtn, { paddingLeft: 20 }]} >
      <ModalTablemultiTableViewComponent
        colonnes={field.options.colonnes.filter(entry => hasLinks ? true : entry.id !== 904)}
        field={field}
        isAttachment
        isPreview={false}
        rows={annexes}
        toPrint
      />
    </View>
  )
}

const renderNoteContent = (annexe, index) => {
  
  return (
    <View style={[styles.container100, styles.containerLayout75]} key={index} wrap={false}>
      <Text style={{ color: colors.unselected, fontSize: 11, fontFamily: 'Poppins' }}>{index + 1}. Note : {annexe.name}</Text>
      <Text style={{ paddingLeft: 16 }}>{convertHtmlToNative(annexe.Webdoc.content)}</Text>
    </View>
  )
}

export const AttachmentTableDoc = (props) => {
  // console.log('AttDoc props', props)
  const t = props.t
  const logo = sessionStorage.getItem('logo')
  const logoSrc = isDefined(logo) ? logo : Images.logoPng
  const footerLeft = t('print.footer-att')

  return (
    <Document title={props.filename}>
      <Page size="A4" style={styles.page}>
        <View style={[styles.headerCtn, { height: 160 }]}>
          <View style={styles.headerLogoCtn}>
            <Image style={styles.headerLogo} src={logoSrc} resizeMode="center" />
          </View>
          <View style={styles.headerTextCtn}>
            <Text style={styles.subtitle}>{props.plugnote.modname}</Text>
            {
              props.plugcode !== '' &&
              <Text style={styles.plugNbText}>{props.plugcode}</Text>
            }
            <Text style={{ color: colors.unselected, fontSize: 9, fontFamily: 'Poppins' }}>{props.filename}</Text>
          </View>
        </View>

        <View style={styles.bodyCtn}>
          <View style={styles.titleCtn}>
            <View style={styles.titleLine} />
            <Text style={styles.subtitle}>
              {t('multiple.current-attachments')} ({props.annexes?.length})
            </Text>
          </View>
          {renderAttachmentList(props.field, props.annexes, props.hasLinks)}
        </View>
        
        {
          (props.hasThumbnails || props.hasNote) &&
            <View style={styles.attFieldCtn}
              // wrap={false}
              break
            >
              <View style={styles.titleCtn}>
                <View style={styles.titleLine} />
                <Text style={styles.subtitle}>{t('common:print.att-overview')}</Text>
              </View>
              <View style={styles.sectionAnnexes}>
                {
                  props.annexes
                    .map((annexe, index) => {
                      const printOpt = {
                        attLayout: props.attLayout,
                        printAttComment : true,
                        printAttInfo: true,
                      }
                      if (props.hasThumbnails && imagesListType.includes(annexe.type?.toLowerCase())) {
                        return renderAtt100(annexe, index, printOpt)
                      } else if (annexe.type === 'note' && props.hasNote) {
                        return renderNoteContent(annexe, index)
                      } else {
                        return null
                        switch (printOpt.attLayout) {
                          case 75:
                          case 10:
                            return renderAtt10(annexe, index, printOpt)
                          default:
                            return renderAttThumbnail(annexe, index, printOpt)
                        }
                      }
                    })
                }
              </View>
            </View>
        }
        <PageFooter fixed
          footerLeft={footerLeft} isAttachment
          footerRight={props.filename}
          t={t}
        />
      </Page>
    </Document>
  )
}

export const Doc2001 = (props) => {
  // devLog('MyDoc got props', props)
  const t = props.t
  const printOpt = props.printOptions
  let user
  if (props.user2 !== undefined) {
    user = props.user2.user
  } else {
    user = props.user
  }
  const hasSignature = props.fields.some(field => (field.format === 'sign' && field.id === props.index)) && isDefined(props.signature)
  const profil = typeof props.user?.profil !== 'undefined' ? props.user.profil : null
  const mainModel = props.model

  let today = new Date()
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
  const yyyy = today.getFullYear()
  today = i18n.language === 'en' ? (mm + '/' + dd + '/' + yyyy) : (dd + '/' + mm + '/' + yyyy)
  const footerLeft = (t('common:print.footer-msg')).toString() + ' Layout 2001'

  const logo = sessionStorage.getItem('logo')
  const logoSrc = isDefined(logo) ? logo : Images.logoPng

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.headerCtn}>
          <View style={styles.headerLogoCtn}>
            <Image style={styles.headerLogo} src={logoSrc} resizeMode="center" />
          </View>
          <View style={styles.headerTextCtn}>
            
            {props.plugcode !== '' && <Text style={styles.plugNbText}>{props.plugcode}</Text>}

            {
              isDefined(user) &&
              <Text style={{ color: colors.unselected, fontSize: 13, fontFamily: 'Poppins' }}>{user.pseudo} {profil !== null && <Text style={{ fontSize: 13, fontFamily: 'Poppins', fontWeight: 'light' }}>{user.lastname} {user.firstname}</Text>}</Text>
            }
          </View>
        </View>

        {/* Fields */}
        <View style={styles.bodyCtn}>
          <View style={styles.titleCtn}>
            <View style={styles.titleLine} />
            <Text style={styles.subtitle}>
              {props.model.label.toUpperCase()} {
                props.isExtra
                  ? ('(' + t('common:print.from-field') + ' ' + props.fromField?.id + ': ' + props.fromField?.title + ')')
                  : props.filename !== '' && '-' + props.filename
                }
            </Text>
          </View>
          {/* <View style={styles.section}> */}
          {
            props.fields.map((field, index) => {


              let visible = true;
              let setup = field.options?.setup;

              if(!isNull(setup) && typeof setup !== "undefined"){
                if(setup.hasOwnProperty('visible')){
                  visible = setup.visible;

                }
              }

              if(visible){
                const tmpValue = typeof field.value !== 'undefined' ? field.value.toString() : ''
                let length = field.format === 'numeric' && tmpValue === 'NaN' ? 0 : tmpValue.length
                if (typeof tmpValue === 'string' && Object(tmpValue) && tmpValue !== null && field.format === 'plugform') {
                  const tmp = field.value
                  if (tmp.hasOwnProperty('value')) {
                    length = tmp.value.length
                  } else {
                    length = 0
                  }
                }
                let relatedPN = null
                if (!!field && !!field.format && field.format.includes('qrcode')) {
                  const code = field.value.code
                  if (isDefined(props.related)) {
                    relatedPN = props.related.find((f) => f.filename === code)
                  }
                  {/* console.log('found related  ', relatedPN) */ }
                }

                if ((length !== 0 || printOpt.hiddenOption?.empty === true) && (field?.options?.setup?.printable !== false || printOpt.hiddenOption?.pdf === true)) {
                  if (isDefined(relatedPN)) {
                    return (
                        renderFieldWithSubField(field, index, undefined, props.printOptions.hiddenOption, relatedPN, mainModel, t, props.mode)
                    )
                  } else return renderField(field, mainModel['field' + (index + 1) + 'formatoptions'], index, undefined, printOpt.hiddenOption, relatedPN, mainModel, t, props.mode)
                } else {
                  return null
                }
              }else return null;
            })
          }
          {
            hasSignature &&
            props.fields.filter(field => field.id === props.index)
              .map((field, index) => renderSignature(field, index, props.signature))
          }
          {
            props.annexes.length > 0 &&
            <View style={styles.attFieldCtn}
              // wrap={false}
              break
            >
              <View style={styles.titleCtn}>
                <View style={styles.titleLine} />
                <Text style={styles.subtitle}>{t('common:print.att-overview')}</Text>
              </View>
              <View style={styles.sectionAnnexes}>
                {
                  props.annexes
                    .map((annexe, index) => {
                      if (imagesListType.includes(annexe.type?.toLowerCase())) {
                        switch (printOpt.attLayout) {
                          case 75:
                            return renderAtt75(annexe, index, printOpt)
                          case 50:
                            return renderAtt50(annexe, index, printOpt)
                          case 25:
                            return renderAtt25(annexe, index, printOpt)
                          case 15:
                            return renderAtt15(annexe, index, printOpt)
                          case 10:
                            return renderAtt10(annexe, index, printOpt)
                          default:
                            return renderAttThumbnail(annexe, index, printOpt)
                        }
                      } else {
                        switch (printOpt.attLayout) {
                          case 75:
                          case 10:
                            return renderAtt10(annexe, index, printOpt)
                          default:
                            return renderAttThumbnail(annexe, index, printOpt)
                        }
                      }
                    })
                }
              </View>
              {
                printOpt?.attLayout === 100 &&
                props.annexes
                  .map((annexe, index) => {
                    if (imagesListType.includes(annexe.type?.toLowerCase())) {
                      return renderAtt100(annexe, index, printOpt)
                    } else { return null }
                  })
              }
            </View>
          }
        </View>

        {/* Related Plugnotes */}
        {
          props.relatedPlugnotes.length > 0 &&
          <View style={styles.bodyCtn}>
            <View style={styles.titleCtn}>
              <View style={styles.titleLine} />
              <Text style={styles.subtitle}>{t('common:print.related-plugnotes')}</Text>
            </View>
            {
              props.relatedPlugnotes.map((plugnote, pindex) => {
                if (isDefined(plugnote)) {
                  return (
                    <View key={'rel-plugnote-' + plugnote.data.hash} style={styles.subheaderCtn}>
                      <View style={styles.subheaderTextCtn}>
                        <Text style={styles.plugNbText}>Plugnote {pindex + 1 + ' : '} {plugnote.filename} - {t('common:print.from-field')} {plugnote.fieldId + ': ' + props.fields[plugnote.fieldId - 1].title}</Text>

                        <Text style={[styles.subtitle, { textAlign: 'center', marginVertical: 4 }]}>{plugnote.model.label.toUpperCase()}</Text>
                        <Text>{'\n'}</Text>
                      </View>
                      {
                        plugnote.fields.map((field, index) => {
                          let hiddenOptions = {
                            indicator: plugnote.isOwned ? printOpt.hiddenOption.indicator : false,
                            pf: plugnote.isOwned ? printOpt.hiddenOption.pf : field.encodable,
                            pn: plugnote.isOwned ? printOpt.hiddenOption.pn : field.visible,
                            pdf: plugnote.isOwned ? printOpt.hiddenOption.pdf : (isDefined(field.printable) && field.printable) || !isDefined(field.printable),
                          }
                          return renderField(field, field.options, index, pindex, hiddenOptions, null, mainModel, t, props.mode)
                        })
                      }
                      {
                        printOpt.printRelatedAtt && plugnote.data.Annexes.length > 0 &&
                        <View style={styles.attFieldCtn}
                        // break
                        >
                          <View style={styles.titleCtn}>
                            <View style={styles.titleLine} />
                            <Text style={styles.subtitle}>{t('common:print.att-overview')} - Plugnote {pindex + 1}</Text>
                          </View>
                          <View style={styles.sectionAnnexes}>
                            {
                              plugnote.data.Annexes
                                .map((annexe, index) => {
                                  if (imagesListType.includes(annexe.type?.toLowerCase())) {
                                    switch (printOpt.attLayout) {
                                      case 50:
                                        return renderAtt50(annexe, index, printOpt)
                                      case 25:
                                        return renderAtt25(annexe, index, printOpt)
                                      case 15:
                                        return renderAtt15(annexe, index, printOpt)
                                      case 10:
                                        return renderAtt10(annexe, index, printOpt)
                                      default:
                                        return renderAttThumbnail(annexe, index, printOpt)
                                    }
                                  } else { return renderAttThumbnail(annexe, index, printOpt) }
                                })
                            }
                          </View>
                          {
                            printOpt?.attLayout === 100 &&
                            plugnote.data.Annexes
                              .map((annexe, index) => {
                                if (imagesListType.includes(annexe.type?.toLowerCase())) {
                                  return renderAtt100(annexe, index, printOpt)
                                } else { return null }
                              })
                          }
                        </View>
                      }
                    </View>
                  )
                } else { return null }
              })
            }

          </View>
        }
        {
          props.showRelatedAttachments && props.annexes.length === 0 &&
          <View style={styles.noAttFieldCtn}>
            <View style={styles.titleCtn}>
              <View style={styles.titleLine} />
              <Text style={styles.subtitle}>{t('common:print.att-empty')}</Text>
            </View>
          </View>
        }
        <PageFooter fixed
          date={today}
          footerLeft={footerLeft}
          t={t}
         />
      </Page>
    </Document>
  )
}

const useStyles = makeStyles(() => ({
  pointer: {
    cursor: 'pointer'
  },
  button: {
    display: 'flex',
    width: 150,
    alignItems: 'center',
    borderRadius: 6,
    fontSize: 30,
    height: 40,
    color: 'white',
    backgroundColor: colors.mainButton,
    margin: 24,
    cursor: 'pointer',
    justifyContent: 'center',
    alignSelf: 'center',
    position: 'absolute',
    right: 25,
    top: 140,
    zIndex: 2,
    '@media (max-width: 900px)': {
      top: -10,
      right: 70,
    },
  },
  optionCtn: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: 40,
  },
  optionTxt: {
    cursor: 'pointer',
    whiteSpace: 'break-spaces',
  },
  optionTitle: {
    fontSize: 18,
    alignSelf: 'center',
    margin: '8px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  // Tabs
  tabsVerticalCtn: {
    boxShadow: '15px 20px 20px 0px rgb(0 0 0 / 14%), 0px 11px 15px rgb(0 0 0 / 20%)',
    width: 60,
    height: 'fit-content',
    borderRadius: 8,
    marginLeft: -50,
    '& .MuiTab-root': {
      '@media (min-width: 600px)': {
        minWidth: 60,
        maxWidth: 60
      },
      paddingRight: 160,
    },
    '& .MuiTabs-indicator': {
      backgroundColor: colors.main,
      width: 3,
    },
  },
  tabsCtn: {
    boxShadow: '0px 20px 20px 3px rgb(0 0 0 / 14%)',
    top: 0,
    position: 'sticky',
    zIndex: 1,
    backgroundColor: 'white',
    height: 'fit-content',
    margin: '0 -40px',
    padding: '0 40px',
    '& .MuiTab-root': {
      textTransform: 'none !important',
    },
    '@media (max-width: 600px)': {
      margin: '0 -50px',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: colors.main,
      width: 3,
    },
  },
  tab: {
    '& .MuiTab-root': {
      textTransform: 'none !important',
    },
  },
  tabScreenCtn: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 4,
  },
  unselectedPreview: {
    backgroundColor: `${colors.hoverBgnd} !important`,
  },
  insetShadowCtn: {
    borderRadius: 8,
    padding: 8,
    boxShadow: 'inset 0px 0px 8px #34b7bb8c',
  },
  // Related Plugnotes preview
  relPreviewCtn: {
    maxWidth: '100% !important',
    width: '100% !important',
    paddingBottom: 4,
    borderBottom: `1px solid ${colors.main}`,
    padding: 4,
    backgroundColor: colors.plugnotesLightAlpha,
    borderRadius: 4,
  },
  relPreviewTxt: {
    maxWidth: '100% !important',
  },
  relDetailsCtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: 10,
    marginLeft: 30,
  },
  // Attachment preview
  attPreviewsCtn: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '0px auto',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    gap: 8,
    padding: 8,
  },
  attPreviewCtn: {
    display: 'flex',
    flexDirection: 'column',
    margin: 4,
    justifyContent: 'center',
    alignItems: 'flex-start',
    cursor: 'pointer',
    maxWidth: 120,
    borderBottom: `1px solid ${colors.main}`,
    padding: 4,
    backgroundColor: colors.plugnotesLightAlpha,
    borderRadius: 4,
  },
  attPreviewTxtCtn: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: "nowrap",
  },
  attPreviewTxt: {
    maxWidth: 100,
    fontSize: 12,
  },
  // Attachment layout
  attOptionsCtn: {
    flexDirection: 'row',
    display: 'flex',
    gap: 10,
  },
  attLayoutsCtn: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    margin: '0px auto',
    justifyContent: 'space-evenly',
  },
  attLayoutCtn: {
    display: 'flex',
    flexDirection: 'column',
    margin: 16,
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 8,
  },
  flexBreak: {
    flexBasis: '100%',
    height: 0,
    '@media (max-width: 640px)': {
      display: 'none',
    }
  },
  smallPage: {
    height: 90,
    width: 63.6,
    border: '1px solid',
    boxShadow: '0px 0px 20px 10px rgb(0 0 0 / 14%), 0px 11px 15px rgb(0 0 0 / 20%)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "flex-start",
    flexWrap: 'wrap',
    gap: 8,
    padding: '4px 4px 4px 7px',
    cursor: 'pointer',
  },
  layoutTxt: {
    whiteSpace: "break-spaces",
    textAlign: 'center',
  },
  selectedLayoutTxt: {
    color: colors.main,
    fontWeight: 700,
    borderBottom: '2px solid',
    borderColor: colors.main,
    whiteSpace: "break-spaces",
    textAlign: 'center',
  },
  selectedLayout: {
    border: '2px solid',
    borderColor: colors.main,
    boxShadow: `0px 0px 20px 10px rgb(88, 195, 193), 0px 11px 15px rgb(88, 195, 193)`,
  },
  preview10: {
    width: '25%',
    height: '12%',
    backgroundColor: colors.greyLight,
  },
  icon10: { color: colors.iconGrey, marginTop: -1, width: '40%', fontSize: 14 },
  preview15: {
    width: '35%',
    height: '20%',
    backgroundColor: colors.greyLight,
  },
  preview25: {
    width: '40%',
    height: '40%',
    backgroundColor: colors.greyLight,
  },
  preview50: {
    width: '90%',
    height: '40%',
    backgroundColor: colors.greyLight,
  },
  preview100: {
    width: '90%',
    height: '90%',
    backgroundColor: colors.greyLight,
  },
  // Navigation
  navBtnCtn: {
    display: 'flex',
    alignSelf: 'center',
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 30,
  },
  navBtn: {
    padding: '4px 16px',
    border: '2px solid ' + colors.main,
    borderRadius: 4,
    color: colors.main,
    cursor: 'pointer',
    width: 160,
    textAlign: 'center',
  },
  // PDF Preview
  pdfCtn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    height: 'calc(95vh - 140px)'
  },
}))

function getAttIcon(att) {
  let iconSrc
  switch (att.type.toLowerCase()) {
    case 'gif':
      iconSrc = Images.gifPng
      break
    case 'pdf':
      iconSrc = Images.pdfPng
      break
    case 'txt':
    case 'doc':
    case 'docx':
    case 'pages':
      iconSrc = Images.docPng
      break
    case 'pptx':
    case 'ppt':
      iconSrc = Images.pptPng
      break
    case 'csv':
    case 'number':
    case 'sheet':
    case 'xls':
    case 'xlsx':
      iconSrc = Images.xlsPng
      break
    case 'note':
      iconSrc = Images.notePng
      break
    case 'jpg':
    case 'jpeg':
    case 'png':
      iconSrc = Images.imgPng
      break
    default:
      iconSrc = Images.genericPng
      break
  }
  return iconSrc
}

export const SelectThumbnailLayoutComponent = ({ attLayout, handleSelectLayout, t }) => {
  const classes = useStyles()

  return (
    <div className={`${classes.attLayoutsCtn} ${classes.insetShadowCtn}`} >
      <div className={classes.attLayoutCtn} onClick={() => handleSelectLayout(100)} >
        <span className={`${classes.smallPage} ${attLayout === 100 ? classes.selectedLayout : undefined}`} >
          <span className={classes.preview100} ></span>
        </span>
        <span className={attLayout === 100 ? classes.selectedLayoutTxt : classes.layoutTxt}>{t('print.att-100')}</span>
      </div>
      <div className={classes.attLayoutCtn} onClick={() => handleSelectLayout(50)} >
        <span className={`${classes.smallPage} ${attLayout === 50 ? classes.selectedLayout : undefined}`} >
          <span className={classes.preview50} ></span>
          <span className={classes.preview50} ></span>
        </span>
        <span className={attLayout === 50 ? classes.selectedLayoutTxt : classes.layoutTxt}>{t('print.att-50')}</span>
      </div>
      <div className={classes.attLayoutCtn} onClick={() => handleSelectLayout(25)} >
        <span className={`${classes.smallPage} ${attLayout === 25 ? classes.selectedLayout : undefined}`} >
          <span className={classes.preview25} ></span>
          <span className={classes.preview25} ></span>
          <span className={classes.preview25} ></span>
          <span className={classes.preview25} ></span>
        </span>
        <span className={attLayout === 25 ? classes.selectedLayoutTxt : classes.layoutTxt}>{t('print.att-25')}</span>
      </div>
      <div className={classes.attLayoutCtn} onClick={() => handleSelectLayout(15)} >
        <span className={`${classes.smallPage} ${attLayout === 15 ? classes.selectedLayout : undefined}`} >
          <span className={classes.preview15} ></span>
          <span className={classes.preview15} ></span>
          <span className={classes.preview15} ></span>
          <span className={classes.preview15} ></span>
          <span className={classes.preview15} ></span>
          <span className={classes.preview15} ></span>
        </span>
        <span className={attLayout === 15 ? classes.selectedLayoutTxt : classes.layoutTxt} >{t('print.att-15')}</span>
      </div>
      <div className={classes.flexBreak} />
      <div className={classes.attLayoutCtn} onClick={() => handleSelectLayout(75)} >
        <span className={`${classes.smallPage} ${attLayout === 75 ? classes.selectedLayout : undefined}`} >
          <span className={classes.preview25} ></span>
          <span className="material-icons" style={{ color: colors.iconGrey, marginLeft: -6, width: '40%' }}>
            align_horizontal_left
          </span>
          <span className={classes.preview25} ></span>
          <span className="material-icons" style={{ color: colors.iconGrey, marginLeft: -6, width: '40%' }}>
            align_horizontal_left
          </span>
        </span>
        <span className={attLayout === 75 ? classes.selectedLayoutTxt : classes.layoutTxt} >{t('print.att-75')}</span>
      </div>
      <div className={classes.attLayoutCtn} onClick={() => handleSelectLayout(10)} >
        <span className={`${classes.smallPage} ${attLayout === 10 ? classes.selectedLayout : undefined}`} >
          <span className={classes.preview10} ></span>
          <span className={`material-icons ${classes.icon10}`}>
            align_horizontal_left
          </span>
          <span className={classes.preview10} ></span>
          <span className={`material-icons ${classes.icon10}`}>
            align_horizontal_left
          </span>
          <span className={classes.preview10} ></span>
          <span className={`material-icons ${classes.icon10}`}>
            align_horizontal_left
          </span>
          <span className={classes.preview10} ></span>
          <span className={`material-icons ${classes.icon10}`}>
            align_horizontal_left
          </span>
        </span>
        <span className={attLayout === 10 ? classes.selectedLayoutTxt : classes.layoutTxt} >{t('print.att-10')}</span>
      </div>
    </div>
  )
}

function Data2001Printable(props) {
  // console.log('Data2001 props', props)
  const classes = useStyles()
  // Printing options
  const [hiddenOption, setHiddenOption] = useState({
    empty: false,
    indicator: false,
    pdf: false, // print PDF-hidden fields
    pf: false,
    pn: false,
  })
  const [printRelatedAtt, setPrintRelatedAtt] = useState(false)
  const [attLayout, setAttLayout] = useState(25)
  const [attInfo, setAttInfo] = useState(true)
  const [attComment, setAttComment] = useState(true)
  const [hasHidden, setHasHidden] = useState(false)
  const [hasRelated, setHasRelated] = useState(false);
  const [loading, setLoading] = useState(true)
  const [related, setRelated] = useState([])
  const [relList, setRelList] = useState(related);

  const [validate, setValidate] = useState(null)

  // Attachment options
  const [attList, setAttList] = useState(props.annexes)
  const [pdfUrl, setPdfUrl] = useState(null)
  const [merge, setMerge] = useState(false)
  const [merging, setMerging] = useState(false)

  const [pdfDocument, setPdfDocument] = useState(null)
  const [mergedPdfDoc, setMergedPdfDoc] = useState(null)

  const t = props.t
  const fields = props.fields

  const fieldsWithRelatedPN = fields.filter(field => field.format?.includes('qrcode') && (field.value?.length > 0 || field.value?.code !== ''))
  const hasAttachment = props.annexes.length > 0 || related.some(entry => entry?.Annexes?.length > 0)

  // Tabs
  const attachmentTab = 0
  const layoutTab = 1
  const hiddenTab = 2
  const relatedTab = 3
  const [visibleTabs, setVisibleTabs] = useState([hasAttachment, attList.length > 0 || printRelatedAtt, hasHidden, hasRelated])
  const [tabNb, setTabNb] = useState(0)

  // For dev purpose
  const [logoSrc, setLogoSrc] = useState('')
  const handleChangeLogoSrc = (e) => { setLogoSrc(e.target.value) }

  const toggleRelatedAttOption = () => {
    setPrintRelatedAtt(!printRelatedAtt)
  }

  const handleToggleMergePDF = () => { setMerge(previous => !previous) }

  const handleIncludeAllHidden = () => {
    setHiddenOption(previous => (
      (!previous.empty || !previous.pdf || !previous.pf || !previous.pn) ? {
        ...previous,
        empty: true,
        pdf: true,
        pf: true,
        pn: true,
      } : {
        ...previous,
        empty: false,
        pdf: false,
        pf: false,
        pn: false,
      }))
  }

  const handleValidate = () => {
    setPrintPDFOptions(printOptions)
    setValidate(true)
  }

  const handleToggleRelated = (plug) => {
    setRelList(previous =>
      previous.includes(plug)
        ? previous.filter(entry => entry !== plug)
        : [...previous, plug])
  }

  const handleChangeTab = (_, nb) => {
    setTabNb(nb)
  }

  const handleSelectAllRelated = () => { setRelList(related) }
  const handleSelectNoRelated = () => { setRelList([]) }
  const handleSelectAllAttachments = () => { setAttList(props.annexes) }
  const handleSelectNoAttachment = () => { setAttList([]) }

  const handleToggleAttachment = (att) => {
    setAttList(previous => {
      let next = previous.includes(att)
        ? previous.filter(entry => entry !== att)
        : [...previous, att]
      return next
    })
  }

  const handleSelectLayout = (layout) => { setAttLayout(layout) }

  useEffect(() => {
    Object.keys(props.model).forEach(key => {
      if (key.includes('visible') && props.model[key] === 0) { setHasHidden(true) }
      if (key.includes('encodable') && props.model[key] === 0) { setHasHidden(true) }
      if (key.includes('formatoptions')) {
        let options = JSON.parse(props.model[key])
        let columns = []
        if (options.lines?.length > 0) { columns = options.lines }
        if (!options.setup?.printable) { setHasHidden(true) }
        if (columns.some(entry => {
          // console.log('hasHidden ? ', !entry.visible || !entry.editable || !entry.showOnPdf)
          return (!entry.visible || !entry.editable || !entry.showOnPdf)
        })) { setHasHidden(true) }
      }
    })
    let temp = getPrintPDFOptions()
    if (isDefined(temp)) {
      setAttLayout(temp.attLayout)
      if (attList.some(att => att.type?.toLowerCase() === 'pdf')) { setMerge(temp.merge) }
      setHiddenOption(temp.hiddenOption)
      setPrintRelatedAtt(temp.printRelatedAtt)
      setAttComment(temp.printAttComment)
      setAttInfo(temp.printAttInfo)
    }
  }, [])

  useEffect(() => {
    if (related.length > 0) {
      related.forEach(plug => {
        if (isDefined(plug)) {
          Object.keys(plug.model).forEach(key => {
            if (key.includes('visible') && plug.model[key] === 0) { setHasHidden(true) }
            if (key.includes('encodable') && props.model[key] === 0) { setHasHidden(true) }
          })
        }
      })
    }
    if (related.length === fieldsWithRelatedPN.length) {
      if (props.isEmailAttachment) {
        const printOptions = {
          attLayout: attLayout,
          merge: merge,
          relatedPlugnotes: relList,
          printRelatedAtt: printRelatedAtt,
          hiddenOption: hiddenOption,
        }
        const blob = pdf(
          <Doc2001
            {...props}
            relatedPlugnotes={related}
            printOptions={printOptions}
            annexes={attList}
          />).toBlob()
        // console.log('printing blob', blob)
        props.setPdfBlob(blob)
        props.setHasRelated(related?.length > 0)
        // console.log('props.printRelated', props.printRelated);
        if (related.length > 0) {
          related.forEach(extra => {
            if (isDefined(extra)) {
              const originField = fieldsWithRelatedPN.filter(field => (field.value.code === extra.filename || field.value.split(';')[0] === extra.filename))[0]
              const extraBlob = pdf(
                <Doc2001
                  {...props}
                  annexes={extra.data.Annexes}
                  fields={extra.fields}
                  fromField={originField}
                  isExtra
                  model={extra.model}
                  plugcode={extra.filename}
                  plugnote={{ ...extra.data }}
                  user={{ profil: props.profil }}
                  relatedPlugnotes={[]} printOptions={printOptions}
                  showRelatedAttachments={false}
                  t={t}
                />
              ).toBlob()
              props.addRelatedPlugnote(extraBlob)
            }
          })
        }
      }
    }
    setHasRelated(related.length > 0 && related.every(plug => isDefined(plug)))
  }, [related, props.printRelated])

  useEffect(() => {
    if (loading) {
      if (fieldsWithRelatedPN.length > 0) {
        const _related = [...related]
        fieldsWithRelatedPN.forEach((plug) => {
          // console.log('plug', plug);
          const pCode = plug.value.code !== undefined ? plug.value.code : plug.value.split(';')[0]
          let plugnote = null
          getPlugnoteFromCode(pCode, props.userhash)
            .then((result) => {
              plugnote = result
              // console.log('Found plugnote', plugnote)
              if (plugnote !== null) {
                plugnote.filename = pCode
                plugnote.fieldId = plug.id
                const fields = []
                for (let index = 0; index < 15; index++) {
                  const field = {
                    format: returnJSON(plugnote.model['field' + (index + 1) + 'format']),
                    options: returnJSON(plugnote.model['field' + (index + 1) + 'formatoptions']),
                    id: index + 1,
                    title: plugnote.model['field' + (index + 1) + 'label'],
                    value: returnJSON(plugnote.data['field' + (index + 1)]),
                    encodable: plugnote.model['field' + (index + 1) + 'encodable'] === 1,
                    visible: plugnote.model['field' + (index + 1) + 'visible'] === 1,
                  }
                  if (!field.visible || !field.encodable || !field.options?.visible || !field.options?.editable || !field.options?.showOnPdf) {
                    setHasHidden(true)
                  }
                  if (!isEmptyField(field.value)) {
                    fields.push(field)
                  }
                }
                plugnote.fields = fields
                plugnote.isOwned = plugnote.data.owner_hash === props.user.profil.hash
                _related.push(plugnote)
                setRelated(_related)
                // console.log('related PN', _related)
                // console.log('loading ?', loading)
              } else {
                _related.push(null)
                setRelated(_related)
              }
            })
            .catch(e => console.log(e))
        })
        setLoading(false)
      }
    }
  }, [printRelatedAtt])

  useEffect(() => {
    let visible = [hasAttachment, attList.length > 0 || printRelatedAtt, hasHidden, hasRelated]
    // console.log('u-e visibleTabs', visible, loading)
    setVisibleTabs(visible)
    if (visible.includes(true)) setTabNb(visible.indexOf(true))
    // else if (!loading && related.length === 0) { setValidate(true) }
  }, [hasAttachment, hasAttachment, hasHidden, hasRelated, loading, printRelatedAtt, attList, related])

  const handleNavPrevious = () => {
    let previousVisible = tabNb - 1
    while (previousVisible > 0 && visibleTabs[previousVisible] === false) {
      previousVisible--
    }
    setTabNb(previousVisible)
  }

  const handleNavNext = () => {
    let previousVisible = visibleTabs.findIndex((entry, index) => entry === true && index > tabNb)
    if (previousVisible !== -1) { setTabNb(previousVisible) }
  }

  const printOptions = {
    attLayout: attLayout,
    printAttComment: attComment,
    printAttInfo: attInfo,
    merge: merge,
    printRelatedAtt: printRelatedAtt,
    hiddenOption: hiddenOption,
  }

  const handleToggleEmptyFields = () => { setHiddenOption(previous => ({ ...previous, empty: !previous.empty })) }

  const handleToggleAttComment = () => { setAttComment(previous => !previous) }

  const handleToggleAttInfo = () => { setAttInfo(previous => !previous) }

  const handleToggleHiddenPF = () => { setHiddenOption(previous => ({ ...previous, pf: !previous.pf })) }

  const handleToggleHiddenPN = () => { setHiddenOption(previous => ({ ...previous, pn: !previous.pn })) }

  const handleToggleHiddenPDF = () => { setHiddenOption(previous => ({ ...previous, pdf: !previous.pdf })) }

  const handleToggleIndicator = () => { setHiddenOption(previous => ({ ...previous, indicator: !previous.indicator })) }

  const handleDownloadPdf = (e) => {
    e.preventDefault()
    e.stopPropagation()
    window.open(mergedPdfDoc, "_blank")
  }

  useEffect(() => {
    let mainPdf = (
      <Doc2001
        {...props}
        annexes={attList}
        printOptions={printOptions}
        relatedPlugnotes={related}
      />
    )
    if (validate && !merging) {
      setPdfDocument(mainPdf)
    }
  }, [validate])

  useEffect(() => {
    const handleMergeAll = async (pdfList) => {
      await handleMergeMultiplePDF(pdfUrl, pdfList)
        .then(res => {
          const bytes  = new Uint8Array( res )
          const blob   = new Blob( [ bytes ], { type: "application/pdf" } )
          const docUrl = URL.createObjectURL( blob )
          setMergedPdfDoc(docUrl)
          setMerging(false)
          setMerge(false)
        })
    }
    if (merging) {
      const pdfList = attList.filter(att => att.type === 'pdf')
      if (isDefined(pdfUrl)) {
        handleMergeAll(pdfList)
      }
    }
  }, [merging, pdfUrl])

  if (props.isEmailAttachment) {
    return (
      <ColorCheckbox checked={props.checked} color={colors.main} size={24} />
    )
  } else if (isDefined(props.signature)) {
    return (
      <BlobProvider document={<Doc2001 {...props} relatedPlugnotes={related} printOptions={printOptions} annexes={attList} />}>
        {({ blob, url, loading, error }) => {
          if (blob !== null) {
            props.close()
            const reader = new FileReader()
            reader.readAsDataURL(blob)
            reader.onloadend = function () {
              const file = new File([blob], props.filename + '.pdf')
              file.base64data = reader.result
              props.onChangeHandlerDrop([file])
            }
            // Do whatever you need with blob here
          }
          return ''
        }}
      </BlobProvider>
    )
  } else if (validate === null) {
    // console.log('visibleTabs', visibleTabs)
    // console.log('2001 props', props)

    return (
      <div className={classes.optionCtn}>
        <div className={classes.button} onClick={handleValidate} style={!visibleTabs.includes(true) ? { position: 'relative' } : { position: 'absolute' }}>
          <span>{t('print.generate-pdf')}</span>
        </div>
        {
          (props.annexes?.length > 0 || fieldsWithRelatedPN.length > 0 || hasHidden) &&
          <span style={{ textDecoration: 'underline', paddingLeft: 40, fontSize: 20, fontWeight: 18, textAlign: 'center', }}>{t('common:print.print-option')}</span>
        }
        {
          visibleTabs.includes(true) &&
          <div className={classes.tabsCtn} >
            <span style={{ position: 'relative' }}>
              <Tabs
                onChange={handleChangeTab}
                // orientation="vertical"
                textColor="inherit"
                value={tabNb}
                variant="scrollable"
                scrollButtons="on"
              >
                <PlugTooltip title={t('print.select-att')} placement="bottom" >
                  <Tab
                    // disableRipple
                    icon={<GallerySelect color={tabNb === attachmentTab ? colors.main : colors.coal} size={20} />}
                    label={t('print.att-select')} className={`${classes.tab} ${tabNb !== attachmentTab && classes.unselectedTab}`}
                    style={!visibleTabs[0] ? { display: 'none' } : undefined}
                  />
                </PlugTooltip>

                <PlugTooltip title={t('print.select-layout')} placement="bottom" >
                  <Tab
                    // disabled={!visibleTabs[1]}
                    // disableRipple
                    icon={<AttLayout color={tabNb === layoutTab ? colors.main : colors.coal} size={20} />}
                    label={t('print.att-layout')}
                    className={`${classes.tab} ${tabNb === layoutTab && classes.unselectedTab}`}
                    style={!visibleTabs[1] ? { display: 'none' } : undefined}
                  />
                </PlugTooltip>

                <PlugTooltip title={t('print.hidden-select')} placement="bottom" >
                  <Tab
                    // disabled={!visibleTabs[2]}
                    // disableRipple
                    icon={<FieldVisibility color={tabNb === hiddenTab ? colors.main : colors.coal} size={20} />}
                    label={t('print.hidden-fields')} className={`${classes.tab} ${tabNb !== 0 && classes.unselectedTab}`}
                    style={!visibleTabs[2] ? { display: 'none' } : undefined}
                  />
                </PlugTooltip>

                <PlugTooltip title={t('print.select-related')} placement="bottom" >
                  <Tab
                    // disabled={!visibleTabs[3]}
                    // disableRipple
                    icon={<RelatedPlugnote color={tabNb === relatedTab ? colors.main : colors.coal} size={20} />}
                    label={t('print.related-plugnote')} className={`${classes.tab} ${tabNb !== relatedTab && classes.unselectedTab}`}
                    style={!visibleTabs[3] ? { display: 'none' } : undefined}
                  />
                </PlugTooltip>
              </Tabs>
            </span>
          </div>
        }
        <br />

        <div className={classes.attOptionsCtn} >
          {
            tabNb === relatedTab &&
            <div className={classes.tabScreenCtn}>
              <span className={classes.optionTitle}>{t('print.select-related')}</span>
              <br />
              <div className={`${classes.attLayoutsCtn}`} >
                <div onClick={handleSelectAllRelated}>
                  <ColorCheckbox
                    checked={relList?.length === related?.length}
                  />
                  <span className={classes.optionTxt}>{t('print.select-all')}</span>
                </div>
                <div onClick={handleSelectNoRelated}>
                  <ColorCheckbox
                    checked={relList?.length === 0}
                  />
                  <span className={classes.optionTxt}>{t('print.select-none')}</span>
                </div>
              </div>
              <br />
              <div className={`${classes.attLayoutsCtn} `}>
                <div className={`${classes.attPreviewsCtn} ${classes.insetShadowCtn}`} >
                  {
                    related.map((plug, index) => {
                      if (isDefined(plug)) {
                        let plugIndex = index + 1
                        let plugTitle = plug.model.label
                        /* console.log('related: ', plug) */
                        return (
                          <div key={plug.data.hash} className={` ${classes.relPreviewCtn} ${classes.attPreviewCtn} ${!relList?.includes(plug) ? classes.unselectedPreview : undefined}`} onClick={() => handleToggleRelated(plug)}>
                            <span className={classes.attPreviewTxtCtn}>
                              <ColorCheckbox
                                checked={relList?.includes(plug)}
                                style={{ marginTop: 2 }}
                              />
                              <span className={`multiline-ellipsis ${classes.attPreviewTxt}  ${classes.relPreviewTxt}`}>{plugIndex + '. ' + plugTitle}</span>
                            </span>
                            <div className={classes.relDetailsCtn}>
                              <span className={` ${classes.attPreviewTxt}  ${classes.relPreviewTxt}`}>{plug.filename}</span>
                              <span style={{ fontSize: 10 }}><span className={"material-icons"} style={{ fontSize: 16 }}>attach_file</span>({plug.data.Annexes.length})</span>
                            </div>
                          </div>
                        )
                      } else { return null }
                    })
                  }
                </div>
              </div>
              <br />
              {
                relList?.length > 0 && relList.some(entry => entry.data.Annexes.length > 0) &&
                <div className={`${classes.attLayoutCtn} `}>
                  <div onClick={toggleRelatedAttOption}>
                    <ColorCheckbox
                      checked={printRelatedAtt}
                    />
                    <span className={classes.optionTxt}>{t('common:print.include-related-att')}</span>
                  </div>
                  <br />
                </div>
              }
            </div>
          }
          {
            tabNb === hiddenTab &&
            <div className={classes.tabScreenCtn}>
              <span className={classes.optionTitle}>{t('print.hidden-select')}</span>
              <br />
              <div className={classes.hiddenOptCtn} >
                <div onClick={handleIncludeAllHidden}>
                  <ColorCheckbox
                    checked={Object.keys(hiddenOption).filter(entry => entry.includes('p')).every(entry => hiddenOption[entry] === true)}
                  />
                  <span className={classes.optionTxt}>{t('common:print.include-hidden')}</span>
                </div>
                <br />
                <div onClick={handleToggleEmptyFields}>
                  <ColorCheckbox
                    checked={hiddenOption.empty}
                  />
                  <span className={classes.optionTxt}>{t('common:print.include-empty')}</span>
                </div>
                <br />
                <div onClick={handleToggleHiddenPF}>
                  <ColorCheckbox
                    checked={hiddenOption.pf}
                  />
                  <span className={classes.optionTxt}>{t('common:print.include-pf')}</span>
                </div>
                <div onClick={handleToggleHiddenPN}>
                  <ColorCheckbox
                    checked={hiddenOption.pn}
                  />
                  <span className={classes.optionTxt}>{t('common:print.include-pn')}</span>
                </div>
                <div onClick={handleToggleHiddenPDF}>
                  <ColorCheckbox
                    checked={hiddenOption.pdf}
                  />
                  <span className={classes.optionTxt}>{t('common:print.include-pdf')}</span>
                </div>
                <br />
                <div onClick={handleToggleIndicator}>
                  <ColorCheckbox
                    checked={hiddenOption.indicator}
                  />
                  <span className={classes.optionTxt}>{t('common:print.hidden-indicator')} (<InvisiblePDF relative fontSize="12" />, <InvisiblePF relative fontSize="12" />, <InvisiblePN relative fontSize={12} />)</span>
                </div>
              </div>
              <br />
            </div>
          }
          {
            tabNb === attachmentTab && visibleTabs.includes(true) &&
            <div className={classes.tabScreenCtn}>
              <span className={classes.optionTitle}>{t('print.select-att')}</span>
              <br />
              <div className={classes.attLayoutsCtn} >
                <div onClick={handleSelectAllAttachments}>
                  <ColorCheckbox
                    checked={attList?.length === props.annexes?.length}
                  />
                  <span className={classes.optionTxt}>{t('print.select-all')}</span>
                </div>
                <div onClick={handleSelectNoAttachment}>
                  <ColorCheckbox
                    checked={attList?.length === 0}
                  />
                  <span className={classes.optionTxt}>{t('print.select-none')}</span>
                </div>
              </div>
              <br />
              <div className={classes.attLayoutsCtn} >
                <div className={`${classes.attPreviewsCtn} ${classes.insetShadowCtn}`} >
                  {
                    props.annexes.map((att, index) => {
                      let attName = att.name
                      let attIndex = index + 1
                      let description = attName
                      if (!attName.toLowerCase().includes('.' + att.type?.toLowerCase())) { description += '.' + att.type?.toLowerCase() }
                      if (att.comment !== "") { description += '\n' + att.comment }
                      description += '\n' + (att.size / 1000).toFixed(2) + ' kb'
                      return (
                        <PlugTooltip key={'att-item-' + attIndex} title={description} placement="top">
                          <div className={`${classes.attPreviewCtn} ${!attList?.includes(att) ? classes.unselectedPreview : undefined}`} onClick={() => handleToggleAttachment(att)}>
                            <span className={classes.attPreviewTxtCtn}>
                              <ColorCheckbox
                                checked={attList?.includes(att)}
                              />
                              <AttachmentThumbnail attachment={att} />
                            </span>
                            <span className={`multiline-ellipsis ${classes.attPreviewTxt}`}>{attIndex + '. ' + attName}</span>
                          </div>
                        </PlugTooltip>
                      )
                    })
                  }
                </div>
              </div>
              <br />
              {
                attList.some(att => att.type?.toLowerCase() === 'pdf') &&
                <div className={classes.attLayoutsCtn} >
                  <div onClick={handleToggleMergePDF}>
                    <ColorCheckbox
                      checked={merge}
                    />
                    <span className={classes.optionTxt}>{t('print.merge-pdf')}</span>
                  </div>
                  <br />
                </div>
              }
            </div>
          }
          {
            tabNb === layoutTab &&
            <div className={classes.tabScreenCtn}>
              <span className={classes.optionTitle}>{t('print.select-layout')}</span>
              <br />
              <SelectThumbnailLayoutComponent attLayout={attLayout} handleSelectLayout={handleSelectLayout} t={t} />
              <br />
              <div className={classes.hiddenOptCtn}
              // style={{ justifyContent: 'center' }}
              >
                <div onClick={handleToggleAttInfo}>
                  <ColorCheckbox
                    checked={attInfo}
                  />
                  <span className={classes.optionTxt}>{t('print.print-info')}</span>
                </div>
                <div onClick={handleToggleAttComment}>
                  <ColorCheckbox
                    checked={attComment}
                  />
                  <span className={classes.optionTxt}>{t('print.print-comment')}</span>
                </div>
                <br />
              </div>
            </div>
          }
        </div>
        <div style={{ width: '100%', flexGrow: 1, minHeight: 10 }} />
        {
          visibleTabs.includes(true) &&
          <div className={classes.navBtnCtn} >
            <div className={classes.navBtn} onClick={handleNavPrevious} style={!visibleTabs.some((entry, index) => entry === true && index < tabNb) ? { zIndex: -1 } : undefined}>
              <span>
                <span className="material-icons" >
                  chevron_left
                </span>
                {t('global.previous')}
              </span>
            </div>
            {!visibleTabs.some((entry, index) => entry === true && index > tabNb) ?
              <div className={classes.navBtn} onClick={handleValidate} style={{ color: 'white', backgroundColor: colors.main, fontWeight: 'bold' }}>
                <span>{t('print.generate-pdf')}</span>
              </div>
              :
              <div className={classes.navBtn} onClick={handleNavNext} >
                <span>{t('global.next')}
                  <span className="material-icons" >
                    chevron_right
                  </span>
                </span>
              </div>
            }
          </div>
        }
        {
          ApplicationConfig.devMode === null &&
          <div>
            <br />
            <div>
              Logo Source: (pour modifier manuellement la source du logo dans le session storage - ex: afPng)
            </div>
            <div>
              <input type="text" value={logoSrc} onChange={handleChangeLogoSrc} />
            </div>
            <div>
              <br />
              <span onClick={() => setLogo(logoSrc)} style={{ backgroundColor: colors.plugformBlue, padding: 8, margin: 12, borderRadius: 8, color: 'white' }}>Validate</span>
            </div>
          </div>
        }
      </div>
    )
  } else {
      return (
        <BlobProvider
          document={pdfDocument}
          fileName={(props.plugcode === '' ? props.filename : props.plugcode) + '.pdf'}
          style={{ textDecoration: 'none', color: 'black', display: 'flex', height: '70vh', width: '100%', justifyContent: 'center', }}
        >
          { ({ blob, url, loading, error }) => {
            if (isDefined(error)) { console.error(`❌ generate ${error}`) }
            if (merge) {
              if (isDefined(blob) && isDefined(url)) {
                setMerging(true)
                {/* let _url = url.startsWith('http:') ? ('https:' + url.split('http:')[0]) : url */}
                setPdfUrl(url)
              }
            }
            return (
              loading
                ? <div style={{ display: 'flex' }}>
                    <br />
                    <span className="material-icons md-25 "> hourglass_top </span>
                    <div className={classes.pointer}>{t('common:global.print-sync')}</div>
                  </div>
                : <div className={classes.pdfCtn}>
                    <iframe title='pdf-preview' src={isDefined(mergedPdfDoc) ? mergedPdfDoc : (url + '#toolbar=1&FitV')}
                      className={classes.previewCtn} height="100%"
                      style={{ width: '100%',
                        // maxWidth: 'calc(80vw - 154px)'
                      }}
                    />
                    <br />
                    <div style={{ display: 'flex' }} onClick={handleDownloadPdf}>
                      <span className="material-icons md-25 "> picture_as_pdf </span>
                      <div className={classes.pointer}>{t('common:global.print')}</div>
                    </div>
                  </div>
            )
          }}
        </BlobProvider>
      )
  }
}

export default Data2001Printable
