import React from "react";
import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";
import colors from "../../../assets/themes/Colors";
import { isDefined } from "../../../utils/utils";
import {isNull} from "lodash";

// const hyphenationCallback = (word) => {
//     if (word.length > 0) {
//         return word.split(/(?=.)/g); // splits the string without removing splitting char
//     }
//     else return ['']
// }

// Font.registerHyphenationCallback(hyphenationCallback);

const styles = StyleSheet.create({
    row: {
        display: 'flex',
        // flexGrow: 1,
        flexDirection: "row",
        alignItems: "center",
        width: '100%',
        // margin: 0,
        padding: 0,
        justifyContent: 'space-between',
        backgroundColor: colors.palePlugGreen,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderTopWidth: 1,
        borderBottomWidth: 1,
        maxHeight: 70,
    },
    cell: {
        display: 'flex',
        flexDirection: "column",
        alignItems: "center",
        alignContent: 'center',
        justifyContent: 'center',
        borderLeftWidth: 1,
        borderColor : "#e0e0e0",
        textAlign: 'center',
        // backgroundColor: 'yellow',
    },
    flexCell: {
        // flexGrow: 1,
        // flexBasis: 'auto'
    },
    topNbCell: {
        fontSize: 10,
        borderTopLeftRadius: 4,
        maxWidth: 20, minWidth: 20, width: 20,
        alignSelf: 'center',
        // backgroundColor: 'orange' // for dev purpose
    },
    headerNb: {
        fontFamily: 'Poppins',
        //fontSize: 9,
        fontWeight: 'light',
    },
    total: {
        color: colors.bloodOrange,
        fontSize: 10,
        // fontFamily: 'Poppins',
        textAlign: 'center',
        alignSelf: 'flex-end',
    },
    value: {
        fontSize: 10,
        // fontFamily: 'Poppins',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        display: 'inline-block',
    },
    indicatorsCtn: { display: 'flex', alignSelf: 'center', justifyContent: 'center', flexDirection: 'row', gap: 2 },
    indicatorCtn: {
        position: 'relative',
    },
    indicator: {
        fontSize: 6,
        color: colors.bloodOrange,
        fontWeight: 'bold',
    },
    slash: {
        fontSize: 10,
        color: colors.bloodOrange,
        left: 2,
        top: -2,
        position: 'absolute',
    },
});

// Handles special chars in column label
export const getPDFColumnLabel = (col, labelStyle) => {
    // const textStyle = { }  // pas de style particulier appliqué au header
    const label = isDefined(col.value) ? col.value : col.label
    if (label.includes('~')) {
        const parts = label.split("~");
        return parts.map((part, i) => {
            let section;
            if (part.includes('#')) {
                section = part.split('#')[0] + '...';
            } else section = part;
            return (<Text key={'label-section-' + section} style={[styles.value, labelStyle]}>{section}</Text>)
        });
    } else if (label.includes('#')) {
        const index = label.indexOf("#");
        return (<Text style={[styles.value, labelStyle]} key={'label-section-' + label}>{label.slice(0, index) + '...'}</Text>);
    } else {
        return (<Text style={[styles.value, labelStyle]} key={'label-section-' + label}>{label}</Text>);
    }
}

const PDFHeaderRow = (props) => {
    // console.log('PDFHeader got props', props)
    const borderColor = props.borderColor !== undefined ? props.borderColor : "#c5c5c5"
    const hasIndicator = props.hiddenOpt?.indicator
    const layout = props.setup?.layout
    return (
        <View
            style={[styles.row, { borderColor: borderColor, borderTopLeftRadius: 4, minWidth: 120 }, props.widthStyle]}
            fixed
            wrap={false}
            // debug // affiche les dimensions de la vue dans le PDF rendu
        >
            {
                layout?.showRowNb !== false && layout?.showRowNb?.showOnPdf !== false &&
                <View style={[styles.cell, styles.topNbCell, { borderColor: borderColor }]} key={"header-nb-cell"}>
                    <Text style={styles.headerNb} key="header-number">N°</Text>
                </View>
            }
            {
                props.items.map((item, id) => {
                    // Test if the Label and row should be shown
                    let visible = true;

                    if( !isNull(props.setupbis) && typeof props.setupbis !== "undefined" && ((props.mode === 'shared-update' || props.mode === 'shared-create' || props.mode === 'shared-view') && props.setupbis[id]?.visible === false)){
                        visible = false;
                    }

                    if (visible && (props.hiddenOpt?.pdf || item.showOnPdf || !isDefined(item.showOnPdf))) {
                        // console.log('rendering header ', item)
                        let widthStyle
                        if (props.flex) {
                            widthStyle = { flex: props.widths[id] }
                        } else {
                            widthStyle = item.colWidth > 30
                                ? { maxWidth: item.colWidth, minWidth: item.colWidth }
                                : {
                                    display: 'flex',
                                    flex: 1,

                                }
                        }
                        let labelStyle = { fontSize: isDefined(item.headerFontSize) ? item.headerFontSize * 0.7 : 8 }
                        let totalStyle = { fontSize: isDefined(item.fontSize) ? item.fontSize * 0.7 : 8 }
                        let colHeader = getPDFColumnLabel(item, labelStyle)
                        let isLastColumn = false
                        if (!props.items.some(entry => { return props.items.indexOf(entry) > id && (entry.showOnPdf === true || !isDefined(entry.showOnPdf) || props.hiddenOpt?.pdf) })) { isLastColumn = true }

                        return (
                            <View
                                key={'table-header-' + item.value}
                                style={[styles.cell, styles.flexCell, widthStyle, { borderColor: borderColor, height: '100%', borderRightWidth: isLastColumn ? 1 : 0, borderTopRightRadius: isLastColumn ? 4 : 0 }]}
                            >
                                <View style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center' }}>
                                    {colHeader}
                                </View>
                                {
                                    isDefined(item.total) &&
                                    <View style={{ display: 'flex', alignSelf: 'center', justifyContent: 'center' }}>
                                        <Text style={{ ...styles.total, ...totalStyle }}>{item.total}</Text>
                                    </View>
                                }
                                {
                                    hasIndicator &&
                                    <View style={styles.indicatorsCtn}>
                                        {
                                            item.editable === false &&
                                            <View style={styles.indicatorCtn}>
                                                <Text style={styles.indicator}>PF-</Text>
                                                <Text style={styles.slash}>/</Text>
                                            </View>
                                        }
                                        {
                                            item.visible === false &&
                                            <View style={styles.indicatorCtn}>
                                                <Text style={styles.indicator}>PN-</Text>
                                                <Text style={styles.slash}>/</Text>
                                            </View>
                                        }
                                        {
                                            item.showOnPdf === false &&
                                            <View style={styles.indicatorCtn}>
                                                <Text style={styles.indicator}>PDF</Text>
                                                <Text style={styles.slash}>/</Text>
                                            </View>
                                        }
                                    </View>
                                }
                            </View>
                        );
                    } else {
                        return null;
                    }
                })
            }
        </View>
    )
};

export default PDFHeaderRow;
