import React, {createContext, useContext, useEffect, useState} from 'react';
import {getCheckTokenRequest, getProfilsRequest, postLogoutRequest} from './actions/user';
import {history} from './hooks/history'
import {delFieldsSelected, getToken, removeDemoHash} from './utils/Common';
import 'rsuite/dist/styles/rsuite-default.css';
// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import 'react-phone-number-input/style.css'

import {Container} from "rsuite";
import {connect} from "react-redux";
import MenuComponent from "./components/menu/MenuComponent";
import Routes from "./routes";
import {ToastContainer} from "react-toastify";
import {ConnectedRouter} from "connected-react-router";
import withClearCache from "./ClearCache";
import {useTranslation} from "react-i18next";
// import Intercom from "./components/intercom/Intercom";
import ApplicationConfig from "./config/ApplicationConfig";
import Maintenance from "./containers/maintenance/Maintenance";
import {useIdleTimer} from 'react-idle-timer'
import * as moment from "moment";


import {LicenseInfo} from '@material-ui/x-grid';
import { useTitle } from './utils/utils';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { useDrawer } from './hooks/useDrawer';

const ClearCacheComponent = withClearCache(MainApp);
const prod = ApplicationConfig.production;
const timeoutControl = prod ? 600000 : 600000;

const MyContext = createContext({
    count: 0
});


LicenseInfo.setLicenseKey(
    '8577b96a5067a40c01905d78d0de755fT1JERVI6MjUwMzUsRVhQSVJZPTE2NTMwNDc1MjkwMDAsS0VZVkVSU0lPTj0x',
);

if (!ApplicationConfig.devMode) {
    Bugsnag.start({
        apiKey: '10d6f0dbd6239d62be2d2fd64d377d4e',
        plugins: [new BugsnagPluginReact()]
    })
}

function callAPI() {
    return new Promise((resolve, reject) => {
        setTimeout(() => resolve(), 1000);
    });
}

function useBackgroundService(someStateFromContext, props) { /* todo notification */
    const [backgroundService, setBackgroundService] = useState(null);

    useEffect(() => {
        const bgService = setInterval(() => {
            if (someStateFromContext !== 0) {
                return;
            }
            callAPI().then(() => {
                delFieldsSelected();
                if(typeof props.userhash !== "undefined" && !!props.userhash){
                    props.attemptGetProfils(props.userhash);
                }
            });
        }, timeoutControl);

        setBackgroundService(bgService);

        return () => clearInterval(bgService);
    }, [someStateFromContext]);

    return backgroundService;
}


function App(props) {

    const [authLoading, setAuthLoading] = useState(true);
    const [logout, setLougout] = useState(false);

    const { t, i18n } = useTranslation(['common', 'login']);

    const myContext = useContext(MyContext);

    useBackgroundService(myContext.count, props);

    const handleOnIdle = event => {
        if(!props.user && !getToken()){
            setLougout(false);
        }else{
            setLougout(true);
        }
    }

    const handleOnActive = event => {
        setLougout(false);
    }

    const handleOnAction = (e) => {
        setLougout(false);
    }

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: props.type === 'demo' ?  1000 * 60 * 2400 : 1000 * 60 * 30,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 500
    })

    useEffect(() => {
        if(logout === true){
            props.attemptLogout(props.username)
        }
    },[logout])

    useEffect(() => {

        if (!props.user && !getToken()) {
            return;
        }

        if(typeof props.userhash !== "undefined" && !!props.userhash){
            props.attemptCheckToken(props.userhash,props.token);
        }

        setAuthLoading(false);
    }, []);

/*    useEffect(() => {
        if( !!props.userhash){
            if(props.type === 'demo'){
                setInterval(() => {
                    let a = moment(props.creation);//now
                    let b = moment(a).add(8, 'hours');
                    let c = moment();
                    let duration = moment.duration(b.diff(c));
                    let hours = duration.hours(); //hours instead of asHours
                    let minutes = duration.minutes(); //minutes instead of asMinutes

                    if(hours <= 0 && minutes <= 0 && !!props.userhash){
                        removeDemoHash();
                        props.attemptLogout(props.username);
                    }

                }, 1000)
            }
        }
    })*/

    if (authLoading && getToken() && !!props.user) {
        return <div className="content">{t('global.loading')}</div>
    }




    return <ClearCacheComponent {...props}/>;
}
export const DrawerContext = createContext(null)

function MainApp(props) {
    const { openDrawer, handleToggleDrawer } = useDrawer()
    //useScript('https://maps.googleapis.com/maps/api/js?key=AIzaSyDhcQZNiwclLgi3Q0uiIrTHyjLXZwnbxCw&libraries=places');

    const [authLoading, setAuthLoading] = useState(true);
    const [maintenance, setMaintenance] = useState(ApplicationConfig.maintenance);
    const [title, setTitle] = useState("")
    const { t, } = useTranslation(['common', 'login']);


    useEffect(() => {
        if (!props.user && !getToken()) {
            return;
        }

        if(typeof props.userhash !== "undefined" && !!props.userhash){
            props.attemptCheckToken(props.userhash,props.token);
        }
        setAuthLoading(false);
    }, []);

    useEffect(() => {
        let docTitle = t('global.plugnotes')
        if(props.username !== null && props.username !== undefined) docTitle += ' - ' + props.username
        setTitle(docTitle)
    }, [props.username])

    useTitle(title)

    if (authLoading && getToken() && !!props.user) {
        return <div className="content">{t('global.loading')}</div>
    }


    if(maintenance){
        return(
            <Maintenance/>
        )
    }

    return (
        <DrawerContext.Provider value={{ openDrawer, handleToggleDrawer }}>
            <ConnectedRouter history={history}>
                <div className="App">
                    <div className="container">
                    <Container>
                        { getToken() &&
                        <MenuComponent/>
                        }
                        <Routes/>
                    </Container>
                    </div>
                    <ToastContainer autoClose={3000} />
                </div>
            </ConnectedRouter>
        </DrawerContext.Provider>
    );
}

// redux providing state takeover
const mapStateToProps = (state) => {
    return {
        user : !!state.user ? state.user : null,
        type : !!state.user && !!state.user.user  ? state.user.user.type : null,
        userhash : !!state.user && !!state.user.user ? state.user.user.userhash : null,
        token : !!state.user ? state.user.token : null,
        username : !!state.user && !!state.user.user ?  state.user.user.username : null,
        creation : !!state.user && !!state.user.user  ? state.user.user.creationdate : null,
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptCheckToken: (userhash, token) => dispatch(getCheckTokenRequest(userhash, token)),
    attemptLogout: (username) => dispatch(postLogoutRequest(username)),
    attemptGetProfils: (userhash) => dispatch(getProfilsRequest(userhash)),
});


export default connect(mapStateToProps, mapDispatchToProps)(App);
