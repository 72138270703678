import {applyMiddleware, createStore} from "redux";
import createSagaMiddleware from "redux-saga";
import {routerMiddleware} from "connected-react-router"
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import thunk from "redux-thunk";
import {persistReducer, persistStore} from "redux-persist";
import {composeWithDevTools} from 'redux-devtools-extension';
import {createLogger} from 'redux-logger';
import createRootReducer from './reducers';
import rootSaga from './sagas';
import {history} from "./hooks/history";
import ApplicationConfig from "./config/ApplicationConfig";
import colors from "./assets/themes/Colors";

const ColorsObject = {
    title: () => colors.unselected,
    prevState: () => '#9E9E9E',
    action: () => '#03A9F4',
    nextState: () => '#4CAF50',
    error: () => '#F20404',
  }

const reactRouterMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();
const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['router'],
}
const middlewares = [
    sagaMiddleware,
    thunk,
    reactRouterMiddleware,
    createLogger({
        predicate: () => process.env.NODE_ENV === 'development' && ApplicationConfig.developer !== 'tech2', // if specified this function will be called before each action is processed with this middleware.
        collapsed: true, // takes a Boolean or optionally a Function that receives `getState` function for accessing current store state and `action` object as parameters. Returns `true` if the log group should be collapsed, `false` otherwise.
        duration: true, // print the duration of each action?
        timestamp: true, // print the timestamp with each action?
        log: 'log' | 'console' | 'warn' | 'error' | 'info', // console's level
        colors: ColorsObject, // colors for title, prev state, action and next state: https://github.com/LogRocket/redux-logger/blob/master/src/defaults.js#L12-L18
        //titleFormatter, // Format the title used when logging actions.
        //stateTransformer, // Transform state before print. Eg. convert Immutable object to plain JSON.
        //actionTransformer, // Transform action before print. Eg. convert Immutable object to plain JSON.
        //errorTransformer, // Transform error before print. Eg. convert Immutable object to plain JSON.
        //console: LoggerObject, // implementation of the `console` API.
        logErrors: true, // should the logger catch, log, and re-throw errors?
        diff: true, // (alpha) show diff between states?
    //diffPredicate // (alpha) filter function for showing states diff, similar to `predicate`
})];

const persistedReducer = persistReducer(persistConfig, createRootReducer(history));

const enhancers = [applyMiddleware(...middlewares)];

//let store = createStore(persistedReducer, applyMiddleware(sagaMiddleware));
//let store = createStore(persistedReducer, applyMiddleware(...middleWares));
//export const store = createStore(persistedReducer,applyMiddleware(...middleWares));
//export const persistor = persistStore(store);

export const store = createStore(
    persistedReducer, composeWithDevTools(...enhancers),
);
export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);