import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import {useTranslation} from "react-i18next";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import {getCurrentEnvironnement} from "../../utils/Common";
import ModalFormatFormulaConstructorComponent from "./modal/ModalFormatFormulaConstructorComponent";
import Modal from "@material-ui/core/Modal";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    MuiInputBaseInput:{
        padding : 0
    },
    mainField : {
        display: 'flex',
        flexDirection : 'column'
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    inputType:{
        marginTop: '15px',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    field : {
        display: 'flex',
    },
    inputField:{
        padding : '0px 0px 7px',
    },
    textAreaField:{
        marginLeft: 20,
        marginRight: 'auto',
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        backgroundColor: '#F1F1F1',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        padding: 8,
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    mainInputField:{
        display: 'flex',
    },
    mainInputFieldTools:{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom : '20px',
        cursor : "pointer"
    }
}));


const FormatTMFormulaConstructorComponent = (props) => {
    const classes = useStyles();
    const { t } = useTranslation(['common', 'models']);
    const [openModal, setOpenModal] = useState(false);
    const [formula, setFormula] = useState(typeof props.options.formula !== "undefined" && props.options.formula !== null  && props.options.formula !== ""  ? props.options.formula  : "");

    const handleChangeFormula = (value) => {
        props.options.formula = value;
        setFormula(value);
    }

    return (
        <div className={classes.mainField}>
            <div className="display_flex flex_display_direction_column div_width_100">
                <Input
                    autoFocus
                    id="standard-full-width-clef"
                    label="Label"
                    placeholder={t('fields.format-choice-placeholder-formula-formula')}
                    margin="none"
                    multiline={true}
                    style={ {textTransform: "uppercase" }}
                    disableUnderline={true}
                    inputlabelprops={{
                        shrink: true,
                    }}
                    inputProps={{
                        className: classes.textAreaField,
                    }}
                    disabled={false}
                    className="text-line"
                    onChange={(event) => handleChangeFormula(event.target.value)}
                    value={formula}
                />
            </div>
            <div className="popup_wrapper_content">
                <ul>{t('setup.formula-examples')}
                    <li>{t('setup.formula-time-difference')}=TEXT(PN!A2-PN!A1, "H:MM") : 12:00, 13:00 → 1:00</li>
                    <li>{t('setup.formula-sum')} =SUM(PN!A2,PN!A1) : 12, 13 → 25</li>
                </ul>
                <span><a href="https://handsontable.github.io/hyperformula/guide/built-in-functions.html#overview" target="_blank" rel="noreferrer">{t('setup.see-documentation')}</a> </span>
            </div>
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatTMFormulaConstructorComponent);





