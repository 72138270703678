import { Checkbox, InputLabel, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import React, { useContext, useMemo, useState } from "react";
import colors from "../../assets/themes/Colors";
import { Subject } from "@material-ui/icons";
import { PlugTooltip } from "./CustomComponents";
import { addZeroBefore, devLog, isDefined } from "../../utils/utils";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { DrawerContext } from "../../App";

const useStyles = makeStyles((theme) => ({
    sideMenu: {
        position: 'fixed',
        left: 0,
        top: 0,
        marginLeft: 15,
        overflowY: 'auto',
        width: 335,
        padding: 10,
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 2000,
        backgroundColor: colors.white,
        boxShadow: '5px 0px 5px rgb(0 0 0 / 10%)'
    },
    sideHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
            color: `${colors.main}!important`
        },
    },
    sideOptionCtn: {
        flexDirection: 'column',
    },
    sideOption: {
        height: 36,
        paddingLeft: 16,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .MuiSvgIcon-root': {
            color: `${colors.main}!important`
        }
    },
    sideInstructions: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding:'0 10px',
        gap: 10,
    },
    instructionsFont: {
        fontSize: 12,
        display: 'flex',
        alignItems: 'center',
    },
    margin:{
        marginTop : '15px',
        marginBottom : '5px',
    },
    subtitle: {
        fontWeight: 'bold'
    },
    RadioGroupCell: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        // paddingLeft: 20,
    },
    marginbuttonsBox : {
        margin : 5,
        cursor : 'pointer',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        padding: 5,
        gap: 5
    },
    itemContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    itemGroupContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    valueButton: {
        height:'20px',
        width: '20px',
        color: colors.white,
        alignItems: 'center',
        backgroundColor: colors.main,
        borderRadius: 4,
        fontSize: "18px",
        fontWeight: '900',
    },
    labelButton: {
        color: colors.mainButton,
        fontWeight: '900',
    },
}))
export function SMailSideMenu (props) {
    // devLog('SMail got props', props)
    const classes = useStyles();
    const { t } = useTranslation('common')
    const { openDrawer, handleToggleDrawer } = useContext(DrawerContext)
    const drawerWasOpen = useMemo(() => openDrawer, [])
    const body = props.body
    const elements = props.elements
    const template = props.template || ''
    const note = isDefined(props.note) ? props.note : true
    const auto = isDefined(props.auto) ? props.auto : true
    const autoAttachments = isDefined(props.autoAttachments) ? props.autoAttachments : false
    const autoCopy = isDefined(props.autoCopy) ? props.autoCopy : false

    // Options N°
    const pwOption = props.hasAuto ? 4 : 3
    const fieldList = props.hasPw ? pwOption + 1 : pwOption
    const filter = createFilterOptions()

    const handleChangeTemplate = (e, newValue) => {
        // devLog('handleChange template e', e)
        // devLog('handleChange template value', newValue)
        if (typeof newValue === 'string') {
            props.handleChangeTemplateSelect(newValue)
        } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            props.handleChangeTemplateSelect(newValue.inputValue, props.field, props.index)
        } else {
            props.handleChangeTemplateSelect(newValue?.value || '', props.field, props.index)
        }
    }

    const handleFilterOptions = (options, params) => {
        const filtered = filter(options, params)
        const { inputValue } = params

        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.title)
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            value: `Add "${inputValue}"`,
          })
        }
        return filtered
    }

    const handleGetOptionLabel = (option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
            return option
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
            return option.inputValue
        }
        // Regular option
        return option.label
    }

    const templates = [
        { "label": t("print.2001"), "value": "2001" },
        { "label": t("print.9002"), "value": "9002" },
        { "label": t("print.9099"), "value": "9099" },
    ]
    const handleClose = () => {
        if (openDrawer && !drawerWasOpen) { handleToggleDrawer() }
        props.handleClose()
    }

    return (
        <div className={classes.sideMenu}>
            <div className={classes.sideHeader}>
                <InputLabel className={`${classes.margin} ${classes.subtitle}`} htmlFor={"body-" + props.field.key}>{t('smail.options').toLocaleUpperCase()}</InputLabel>
                {/* <span id="icon-close" className="material-icons md-50 lightgrey" onClick={handleClose}>
                    close
                </span> */}
            </div>
            <InputLabel className={`${classes.margin} ${classes.subtitle}`} id={props.field.id + "-" + props.field.format.toLowerCase()}>1—{t('smail.label-select-template')}</InputLabel>
            <div className={classes.selectType}>
                <Autocomplete
                    clearOnBlur
                    filterOptions={handleFilterOptions}
                    // freeSolo
                    fullWidth
                    getOptionLabel={handleGetOptionLabel}
                    id={props.field.id + "-" + props.field.format.toLowerCase()}
                    key="smail-template-select"
                    // onDidChange={(event) => props.handleChangeTemplateSelect(event.target.value, props.field, props.index)}
                    onChange={handleChangeTemplate}
                    options={templates}
                    placeholder={t('fields.format-choice-placeholder-' + props.field.format.toLowerCase())}
                    renderInput={(params) => <TextField {...params} label="Template" />}
                    renderOption={(prop, _) => {
                        // devLog('rendering opt, props', _, prop)
                        return <span {...prop}>{prop.value} - {t(prop.label)}</span>}}
                    selectOnFocus
                    value={
                        isDefined(templates.find(entry => entry.value === template)) ?
                        template + ' - ' + templates.find(entry => entry.value === template)?.label
                        : template
                    }
                />
            </div>
            <br/>
            <InputLabel className={`${classes.margin} ${classes.subtitle}`} htmlFor={"note-" + props.field.key}>2—{t('smail.attachments')}</InputLabel>
            <div className={classes.sideOption}>
                {t('smail.attach-copy')}
                <div className={classes.field}>
                    <Checkbox
                        style={{ color: colors.main, width: '30px', marginRight: '5px' }}
                        checked={autoCopy}
                        onChange={() => { props.handleToggleAutoJoinCopy(!autoCopy, props.index) }}
                    />
                </div>
            </div>
            <div className={classes.sideOption}>
                {t('smail.attach-annexes')}
                <div className={classes.field}>
                    <Checkbox
                        style={{ color: colors.main, width: '30px', marginRight: '5px' }}
                        checked={autoAttachments}
                        onChange={() => { props.handleToggleAutoJoinAtt(!autoAttachments, props.index) }}
                    />
                </div>
            </div>
            <div className={classes.sideOption}>
                {t('mails.attachments-notes')}
                <div className={classes.field}>
                    <Checkbox
                        style={{ color: colors.main, width: '30px', marginRight: '5px' }}
                        checked={note === 'Y'}
                        onChange={() => { props.handleChangeTemplateRadio(note === 'Y' ? 'N' : 'Y', props.field, props.index) }}
                    />
                </div>
            </div>
            <br/>
            {
                props.hasAuto &&
                <div className={classes.sideHeader}>
                    <InputLabel className={`${classes.margin} ${classes.subtitle}`} htmlFor={"auto-" + props.field.key}>3—{t('smail.auto-send')}</InputLabel>
                    <div className={classes.field}>
                        <Checkbox
                            style={{ color: colors.main, width: '30px', marginRight: '5px' }}
                            checked={auto === 'Y'}
                            onChange={() => { props.handleChangeAuto(auto === 'Y' ? 'N' : 'Y', props.field, props.index) }}
                        />
                    </div>
                </div>
            }

            {
                props.hasPw &&
                <div className={classes.sideOptionCtn}>
                    <InputLabel className={`${classes.margin} ${classes.subtitle}`} htmlFor={"title-" + props.field.key}>
                        {pwOption}—{t('smail.share-pw')}
                    </InputLabel>
                    <div className={classes.sideOption}>
                        {t('smail.share-allow')}
                        <Checkbox
                            style={{ color: colors.main, width: '30px', marginRight: '5px' }}
                            checked={props.share}
                            onChange={(event) => { props.handleChangeShare(event.target.checked, props.field, props.index) }}
                        />
                    </div>
                </div>
            }

            <div className={classes.itemContainer}>
                <br/>
                <InputLabel className={`${classes.margin} ${classes.subtitle}`} htmlFor={"body-" + props.field.key}>{fieldList}—{t('smail.label-fields')}</InputLabel>
                <InputLabel className={classes.margin}>{t('smail.instructions')}</InputLabel>
                {
                    elements && elements.length > 0 && elements.length <= 15 &&
                    elements.map((el, index) => (
                        <button
                            className={classes.marginbuttonsBox}
                            key={el.keyLabel}
                            onMouseDown={() => navigator.clipboard.writeText(' ***' + el.label.substr(0, 18) + '__' + el.value + '** ')}
                            onClick={() => props.handleChangeBody(body, ' ***' + el.label.substr(0, 18) + '__' + el.value + '** ', props.field, props.index)}
                        >
                            {addZeroBefore(index + 1) + '. ' + el.label}
                            <PlugTooltip title={t('smail.insert-value')} placement="top-start">
                                <Subject className={classes.valueButton} key={el.keyValue}
                                    onClick={() => props.handleChangeBody(body, ' ***' + el.label.substr(0, 18) + '__' + el.value + '** ', props.field, props.index)} />
                            </PlugTooltip>
                        </button>
                    ))
                }
            </div>
            <InputLabel className={classes.margin} htmlFor={"body-" + props.field.key}>{t('smail.value-complementary')}</InputLabel>
            <div className={classes.itemContainer}>
                <div className={classes.itemGroupContainer}>
                    <button
                        className={classes.marginbuttonsBox}
                        onClick={() => props.handleChangeBody(body, ' ***PN** ', props.field, props.index)}
                    >
                        {'PN number'}
                        <Subject className={classes.valueButton} />
                    </button>

                    <button
                        className={classes.marginbuttonsBox}
                        onClick={() => props.handleChangeBody(body, ' ***PN-** ', props.field, props.index)}
                    >
                        {'PN code'}
                        <Subject className={classes.valueButton} />
                    </button>
                </div>
                <div className={classes.itemGroupContainer}>
                    <button
                        className={classes.marginbuttonsBox}
                        onClick={() => props.handleChangeBody(body, ' ***PF** ', props.field, props.index)}
                    >
                        {'PF title'}
                        <Subject className={classes.valueButton} />
                    </button>
                    <button
                        className={classes.marginbuttonsBox}
                        onClick={() => props.handleChangeBody(body, ' ***PF Key** ', props.field, props.index)}
                    >
                        {'PF key'}
                        <Subject className={classes.valueButton} />
                    </button>
                </div>
                {
                    props.hasPw &&
                    <div className={classes.itemGroupContainer}>
                        <button
                            className={classes.marginbuttonsBox}
                            onClick={() => props.handleChangeBody(body, ' ***PW** ', props.field, props.index)}
                        >
                            <Subject className={classes.valueButton} />
                            {'PW'}
                        </button>

                        <button
                            className={classes.marginbuttonsBox}
                            onClick={() => props.handleChangeBody(body, ' ***PW Url** ', props.field, props.index)}
                        >
                            <Subject className={classes.valueButton} />
                            {'PW Url'}
                        </button>
                    </div>
                }

                <button
                    className={classes.marginbuttonsBox}
                    onClick={() => props.handleChangeBody(body, ' ***Date Modif** ', props.field, props.index)}
                >
                    {'Date Modif'}
                    <Subject className={classes.valueButton} />
                </button>
                <div className={classes.itemGroupContainer} style={{ paddingBottom: 20 }}>
                    <PlugTooltip title={t('smail.insert-value')} placement="top-start">
                        <button
                            className={classes.marginbuttonsBox}
                            onClick={() => props.handleChangeBody(body, ' ***User** ', props.field, props.index)}
                        >
                            {'User'}
                            <Subject className={classes.valueButton} />
                        </button>
                    </PlugTooltip>
                    <button
                        className={classes.marginbuttonsBox}
                        onClick={() => props.handleChangeBody(body, ' ***User Gsm** ', props.field, props.index)}
                    >
                        {'User Gsm'}
                        <Subject className={classes.valueButton} />
                    </button>
                </div>
            </div>
        </div>
    )
}
