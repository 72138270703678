import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Editor } from '@tinymce/tinymce-react'

import tinymce from 'tinymce/tinymce'
// DOM model
import 'tinymce/models/dom/model'
// Theme
import 'tinymce/themes/silver'
// Toolbar icons
import 'tinymce/icons/default'
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css'
// if you use a plugin that is not listed here the editor will fail to load
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/autosave'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/code'
import 'tinymce/plugins/codesample'
import 'tinymce/plugins/directionality'
import 'tinymce/plugins/emoticons'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/image'
import 'tinymce/plugins/importcss'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/media'
import 'tinymce/plugins/nonbreaking'
import 'tinymce/plugins/pagebreak'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/quickbars'
import 'tinymce/plugins/save'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/table'
import 'tinymce/plugins/template'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/visualchars'
import 'tinymce/plugins/wordcount'

// importing plugin resources
import 'tinymce/plugins/emoticons/js/emojis'
import { useTranslation } from 'react-i18next'
import { devLog, isDefined } from '../../../utils/utils'

// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
// import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css'
// import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css'

const useStyles = makeStyles(() => ({
  mainCtn: {
    position: 'relative',
  },
  patch: {
    position: 'absolute',
    right: 15,
    top: 10,
    height: 30,
    width: 90,
    backgroundColor: 'white',
    zIndex: 10,
  },
}))

const RichEditor = (props) => {
  const classes = useStyles()
  const { init, ...rest } = props
  const { t } = useTranslation('common')

  const handleSelectionChange = e => {
    if (isDefined(props.onSelectionChange)) props.onSelectionChange(e)
  }

  return (
    <div className={classes.mainCtn} >
      <span className={classes.patch} />
      <Editor
        init={{
          ...init,
          contextmenu: "link | list | spellchecker | inserttable | cell row column deletetable",
          skin: false,
          menubar: true,
          content_css: false,
          content_style: [
            // contentCss, contentUiCss, 
            init.content_style || ''].join('\n'),
          menu: {
            file: { title: 'File', items: 'newdocument restoredraft | preview | print ' },
            edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall | searchreplace' },
            view: { title: 'View', items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen' },
            insert: { title: 'Insert', items: 'image link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime' },
            format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat' },
            tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | code wordcount' },
            table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable' },
          },
          // plugins: 'paste',
          text_patterns: false,
          toolbar: [
            'undo redo | blocks fontsize | ' +
            'bold underline italic forecolor | alignleft aligncenter ' +
            'alignright alignjustify paragraph | ' +
            'removeformat',
            ],
            toolbar_mode: 'floating',
            toolbar_groups: {
            paragraph: {
              icon: 'more-drawer',
              items: 'bullist numlist outdent indent | ',
              tooltip: t('smail.editor-paragraph')
            },
          }
        }}
        onClick={props.onClickBody}
        onSelectionChange={handleSelectionChange}
        {...rest}
      />
    </div>
  )
}

export default RichEditor
