import React from 'react'
import colors from '../../../assets/themes/Colors'

const EditAlignementComponent = (props) => {
  const color = props.color || colors.main
  const containerStyle = {
    display: 'flex',
    flexDirection: 'row',
    gap: 6,
    justifyContent: 'center',
  }
  const selectStyle = {
    border: '2px solid' + color,
    padding: 3,
    borderRadius: 6,
    color: color,
    backgroundColor: 'white',
  }
  const unselectedStyle = {
    border: '1px solid ' + colors.unselected,
    padding: 4,
    borderRadius: 6,
    color: colors.unselected,
    backgroundColor: 'white',
  }

  return (
    <div style={containerStyle}>
      <div onClick={() => props.handleChangeAlignment('left')} style={props.value === 'left' ? selectStyle : unselectedStyle}>
        <span className="material-icons" >
            format_align_left
        </span>
      </div>
      <div onClick={() => props.handleChangeAlignment('center')} style={props.value === 'center' ? selectStyle : unselectedStyle}>
        <span className="material-icons" >
            format_align_center
        </span>
      </div>
      <div onClick={() => props.handleChangeAlignment('right')} style={props.value === 'right' ? selectStyle : unselectedStyle}>
        <span className="material-icons" >
            format_align_right
        </span>
      </div>
    </div>
  )
}

export default EditAlignementComponent
