import i18n from "../translations/i18n"

export const defaultTablemultiSetup = {
  comment: '',
  layout: {
    alignment: 'full',
    showHeader: true,
    showRowNb: true,
  },
  printable: true,
  rowLimit: 0,
}

export const defaultDocumentColumns = [
  {
    configuration: null,
    dragId: "col-0",
    editable: true,
    encodable: true,
    format: 'file-source',
    formatLabel: i18n.t('common:document.docsource'),
    id: 10000,
    label: 'A32 - Source (Mandatory)',
    mask: ['PLUGNOTE'],
    presel: null,
    regex: [],
    visible: null,
  },
  {
    configuration: null,
    dragId: "col-1",
    editable: true,
    encodable: true,
    format: 'file-refid',
    formatLabel: i18n.t('common:document.docrefid'),
    id: 10011,
    label: 'A32 - Reference (Mandatory)',
    mask: '',
    presel: null,
    regex: [],
    visible: null,
  },
  {
    configuration: null,
    dragId: "col-99",
    editable: true,
    encodable: true,
    format: 'file-uri',
    formatLabel: i18n.t('common:document.docstockage'),
    id: 10077,
    label: 'A32 - Access path (Mandatory)',
    mask: '',
    presel: null,
    regex: [],
    visible: null,
  },
]

export const defaultSmailvalidmultiColumns = [
  {
    configuration: null,
    dragId: "col-99",
    editable: true,
    encodable: true,
    format: "mail",
    formatLabel: "Mail",
    id: 1001,
    label: "",
    mask: "@",
    presel: null,
    regex: [],
    visible: null,
  },
  {
    configuration: null,
    dragId: "col-1",
    editable: false,
    format: "radio",
    formatLabel: "Radio",
    id: 1002,
    label: i18n.t('common:smailvalidmulti.validation'),
    mask: "Y/N/NA",
    presel: null,
    regex: [],
    visible: null,
  },
  {
    configuration: null,
    dragId: "col-2",
    editable: false,
    format: "date",
    formatLabel: "Date",
    id: 1003,
    label: i18n.t('common:smailvalidmulti.date-tracking'),
    mask: "DD/MM/YYYY",
    presel: null,
    regex: "99/99/9999",
    visible: null,
  },
]