import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import _ from "lodash";
import {connect} from "react-redux";
import {getCurrentEnvironnement, getModel, getPlugnote} from "../../utils/Common";
import {
    delDeletePlugcodePlugnoteUserRequest,
    deleteGeneratePlugnotecodeRequest,
    getCorporateUsersGroupsRequest,
    postAddPlugcodePlugnoteUserRequest,
    postGeneratePlugnotecodeRequest,
    putGeneratePlugnotecodeRequest,
    putGeneratePsPlugnotecodeRequest
} from "../../actions/plugcode";
import Images from "../../assets/themes/Images";
import QRCode from "qrcode.react"
import ApplicationConfig from "../../config/ApplicationConfig";
import * as moment from "moment";
//import Select from "@material-ui/core/Select";
import html2canvas from 'html2canvas';
import {FormControlLabel, Input, Radio, RadioGroup} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import {Add} from "@material-ui/icons";
import {confirmAlert} from "react-confirm-alert";
import {toast} from "react-toastify";
import Select from 'react-select';
import {isFirefox, isSafari} from "react-device-detect";
import {dateFormat} from "../../utils/utils";
import TagsInputObjectMails from "../specials/input/TagsInputObjectMails";
import ModalSimpleSendMailV2PlugnoteCodeComponent from "./ModalSimpleSendMailV2PlugnoteCodeComponent";
import colors from "../../assets/themes/Colors";

function getModalStyle() {
    const top = 10;
    return {
        top: 'auto',
        margin:'auto'
        //left: `${left}%`,
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const EMAIL_RULES = {
    READ: 'read',
    READECOPY: 'readecopy',
    READEDIT: 'readedit',
    READCOPYEDIT: 'readcopyedit',
    READEDITADDANNEXES: 'readeditaddannexes',
    READADDADDANNEXES: 'readaddannexes'

}

const useStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '820px',
        minHeight: '300px',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    divSharedUser:{
        marginTop : 10,
        height  : '10vh',
        maxHeight : '20vh',
        overflowY :'scroll'
    },
    paddingMargin5:{
        margin : '5px',
        padding : '5px',
    },
    divRadiusShare:{
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        minHeight: '38px',
        minLineHeight: '38px',
        backgroundColor: '#92FCEF',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    divRadiusShareRules:{
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        minHeight: '38px',
        minLineHeight: '38px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    textFieldChips : {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        minHeight: '38px',
        minLineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    divRadiusNotif:{
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        minHeight: '38px',
        minLineHeight: '38px',
        backgroundColor: '#92FCEF',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    popupPrivate: {
        position: 'absolute',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        //padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '820px',
        minHeight: '300px',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
    },
    textField: {
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    RadioGroupCell: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        paddingLeft: 20,
        //border: '1px solid greenyellow',
    },
    showOption: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: 5
    },
    radioIcon: {
        color: colors.mainButton,
        backgroundColor: colors.mainButton
    },
    radioLabel: {
        fontSize: 14,
        cursor: 'pointer'
    },
    '&input[name="show-empty-option"]': {
        backgroundColor: 'blue'
    }
}));

const getPlugcodeLink = (plugnote) => {

    let link = null;
    const pnCode = _.get(plugnote, 'pncode', null);
    const hash = _.get(plugnote, 'hash', null);
    const baseURL = ApplicationConfig.plugUrl;

    if (hash && pnCode) {
        link = `${baseURL}?dh=${hash}&pnc=${pnCode}`
    }

    return link || baseURL
}

const checkComment = (plugnote) => {
    if(_.get(plugnote, 'pncomment', '') !== null){
        return _.get(plugnote, 'pncomment', '');
    }

    return "";
}

const checkCount = (plugnote) => {
    if(_.get(plugnote, 'pncode_countopen', 0) !== null){
        return _.get(plugnote, 'pncode_countopen', 0);
    }

    return 0;
}

const isEmail = (value) => {
    return value.match(new RegExp( /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) != null;
    //return /(.)+@(.)+/.test(value)
}

const ModalPlugnotePlugcodeComponent = (props) => {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [t, i18n] = useTranslation('common');
    const environment = getCurrentEnvironnement();
    const currentChoosePlugnote = getPlugnote();
    const model = getModel();
    const plugnote = props.plugnote === null ? currentChoosePlugnote : props.plugnote;
    const link = plugnote.pncode !== null ? getPlugcodeLink(plugnote) : null;
    const [comment, setComment] = useState(checkComment(plugnote));
    const [countView, setCountView] = useState(checkCount(plugnote));

    const [typePnCode, setTypePnCode] = useState('public');
    const [typePnCodeChange, setTypePnCodeChange] = useState({value : "public" , label : t("common:global.public")});
    const [email, setEmail] = useState("");
    const [emails, setEmails] = useState([]);
    const [showEmpty, setShowEmpty] = useState(plugnote?.showEmpty !== 0)
    const [rules, setRules] = useState(-1);
    const [editMode, setEditMode] = useState(false);
    const [errorMail, setErrorMail] = useState(false);
    const [errorRules, setErrorRules] = useState(false);
    const [isPublic, setIsPublic] = useState(plugnote.publicpncode === 1);
    const [isPrivate, setIsPrivate] = useState(plugnote.publicpncode === 0);
    const [isPublicCorpo, setIsPublicCorpo] = useState(plugnote.publicpncode === 2);
    const [isPrivateCorpo, setIsPrivateCorpo] = useState(plugnote.publicpncode === 3);

    const [currentLanguageLower, setCurrentLanguageLower] = useState(i18n.language.charAt(0).toLowerCase() + i18n.language.charAt(1).toLowerCase());


    const [dataHasChanged, setDataHasChanged] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [addToGallery, setAddTogallery] = useState(false);
    const [isAddNewCorpoRules, setIsAddNewCorpoRules] = useState(false);
    const [selectedOptionGroup, setSelectedOptionGroup] = useState(null);
    const [selectedOptionUsers, setSelectedOptionUser] = useState(null);
    const [optionsGroups, setOptionsGroups] = useState([]);
    const [optionsUsers, setOptionsUsers] = useState([]);
    const [options, setOptions] = useState([
        {value : "read" , label : t('common:plugcode.rule-read')},
        {value : "readecopy" , label : t('common:plugcode.rule-read-copy')}
    ]);

    const [generateOptions, setGenerateOptions] = useState([
        {value : "public" , label : t("common:global.public")},
        {value : "private" , label : t("common:global.private")}
    ]);

    const [generateCorpoOptions, setGenerateCorpoOptions] = useState([
        {value : "public" , label : t("common:global.public")},
        {value : "private" , label : t("common:global.private")},
        {value : "public-corpo" , label : t("common:global.public-corpo")},
        {value : "private-corpo" , label : t("common:global.private-corpo")}
    ]);

    const [listUsersIntable, setListUsersInTable]  = useState([]);

    const [countSelected, setCountSelected] = useState(0);

    const [openSendMail, setOpenSendMail] = useState(false);
    const [confirmOpenSendMail, setConfirmOpenSendMail] = useState(false);

    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");

    let tmpEmailrules = [];
    let tmpCorporules = [];
    let tmpIsGallery = false;

    if (plugnote) {
        tmpEmailrules = plugnote && plugnote.pncodesusers && plugnote.pncodesusers.length > 0 && plugnote.publicpncode === 0 ?
            plugnote.pncodesusers.map(user => {
                return {
                    email: user.email,
                    rules: user.pncodeuser_privilege,
                    object: user
                }
            }) : [];

        tmpCorporules = plugnote && plugnote.pncodesusers && plugnote.pncodesusers.length > 0 && plugnote.publicpncode === 3 ?
            plugnote.pncodesusers.map(user => {
                return {
                    hash: user.hash,
                    email: user.email,
                    user: user.pncodeuser_lastname + ' ' + user.pncodeuser_firstname,
                    rules: user.pncodeuser_privilege,
                    object: user
                }
            }) : [];

        tmpIsGallery = plugnote && plugnote.pncodesusers && plugnote.pncodesusers.length > 0 && plugnote.publicpncode === 2 ? plugnote.pncodesusers[0].pncodeuser_isGallery : false;
    }

    const [emailrules, setEmailrules] = useState(tmpEmailrules);
    const [corporules, setCorporules] = useState(tmpCorporules);
    const [isGallery, setIsGallery] = useState(tmpIsGallery);

    let mailInviteUsers = [];

    if (plugnote && plugnote.mailInviteUsers && plugnote.mailInviteUsers.length > 0){
        //mailInviteUsers.push({"email" : props.email, "read" : null, "date": null, "addannexes" : null ,  "main" : true})
        try {
            let textMailInviteUsersToJson = JSON.parse(plugnote.mailInviteUsers);
            mailInviteUsers = textMailInviteUsersToJson.map(notification => {
                return {
                    email: notification.email,
                    date : notification.date,
                }
            });
        }catch(err){
            let textMailInviteUsersToJson = JSON.stringify(plugnote.mailInviteUsers);
            let textMailInviteUsersFromJson = JSON.parse(textMailInviteUsersToJson);
            mailInviteUsers = textMailInviteUsersFromJson.map(notification => {
                return {
                    email: notification.email,
                    date : notification.date,
                }
            });
        }
    }

    const [modelMailInviteUsers, setModelMailInviteUsers] = useState(mailInviteUsers);
    const [modelMailNewInviteUsers, setModelMailNewInviteUsers] = useState([]);
    const [modelMailDeleteInviteUsers, setModelMailDeleteInviteUsers] = useState([]);
    const [modelMailRefreshInviteUsers, setModelMailRefreshInviteUsers] = useState([]);
    const [errorMailInvite, setErrorEmailInvite] = useState(false);

    const handleGeneratePNCode = () => {
        //props.generatePlugcodePlugform('', currentChooseModel.hash, environment.userhash, currentChooseModel.isPublic, currentChooseModel)

        if (typeof (plugnote.pncode) === 'undefined' || plugnote.pncode === null || plugnote.pncode == "") {
            let payload = {
                comment: comment,
                dh: plugnote.hash
            };

            let arrayFormatNotCopy = ['password', 'list', 'select', 'now', 'indice', 'timeplus', ''];
            if (!arrayFormatNotCopy.includes(plugnote.field1format)) {
                let tmpComment = plugnote.field1format !== 'textarea' ? plugnote.field1 : typeof plugnote.field1 !== "undefined" ?  plugnote.field1.substring(0, 100) : "";
                payload = {
                    comment: tmpComment,
                    dh: plugnote.hash
                }
            } else {
                let i = 2;
                let find = false;
                while (!find) {
                    if (!arrayFormatNotCopy.includes(plugnote['field' + i + 'format'])) {
                        find = true;
                    } else {
                        i++
                    }
                }

                if (find) {
                    let tmpComment = plugnote['field' + i + 'format'] !== 'textarea' ? plugnote['field' + i] : plugnote['field' + i].substring(0, 100);

                    payload = {
                        comment: tmpComment,
                        dh: plugnote.hash
                    }
                }
            }

            let typePnCodeNumber = '1';
            switch (typePnCode) {
                case 'public' :
                    typePnCodeNumber = "1";
                    break;
                case 'private' :
                    typePnCodeNumber = "0";
                    break;
                case 'public-corpo' :
                    typePnCodeNumber = "2";
                    break;
                case 'private-corpo' :
                    typePnCodeNumber = "3";
                    break;
                default:
                    break;
            }

            props.generatePlugcodePlugnote(payload, typePnCodeNumber, environment.userhash, plugnote);
            if (typePnCodeNumber === "3") {
                props.attemptGetCorporateUsersGroups(props.groupId,environment.userhash);
            }
        }
    };

    const handleDeletePnCode = () => {
        props.deletePlugcodePlugnote(plugnote.hash, plugnote.pncode, environment.userhash)
        setIsGallery(false);
    };

    const handleUpdatePnCode = () => {
        setDataHasChanged(false);
        let typePnCodeNumber
        switch (typePnCode) {
            case 'public' :
                typePnCodeNumber = "1";
                break;
            case 'private' :
                typePnCodeNumber = "0";
                break;
            case 'public-corpo' :
                typePnCodeNumber = "2";
                break;
            case 'private-corpo' :
                typePnCodeNumber = "3";
                break;
            default:
                break;
        }

        const payload = {
            dh: plugnote.hash,
            comment: comment,
            pnc: plugnote.pncode,
            uh: environment.userhash,
            public: typePnCodeNumber,
            mail_invite_users : modelMailInviteUsers.length > 0 ? modelMailInviteUsers : null,
            mail_invite_new_users : modelMailNewInviteUsers.length > 0 ? modelMailNewInviteUsers : null,
            mail_invite_refresh_users : modelMailRefreshInviteUsers.length > 0 ? modelMailRefreshInviteUsers : null,
            mail_invite_delete_users : modelMailDeleteInviteUsers.length > 0 ? modelMailDeleteInviteUsers : null,
            showEmpty: showEmpty
        }

        if(isPublicCorpo){
            payload.gallery = isGallery;
        }

        props.updatePlugcodePlugnote(payload, environment.userhash, plugnote)

    }


    const handleEraseView = () => {
        setDataHasChanged(false);
        let typePnCodeNumber
        switch (typePnCode) {
            case 'public' :
                typePnCodeNumber = "1";
                break;
            case 'private' :
                typePnCodeNumber = "0";
                break;
            case 'public-corpo' :
                typePnCodeNumber = "2";
                break;
            case 'private-corpo' :
                typePnCodeNumber = "3";
                break;
            default:
                break;
        }

        const payload = {
            dh: plugnote.hash,
            comment: comment,
            pnc: plugnote.pncode,
            uh: environment.userhash,
            public: typePnCodeNumber,
            mail_invite_users : modelMailInviteUsers.length > 0 ? modelMailInviteUsers : null,
            mail_invite_new_users : modelMailNewInviteUsers.length > 0 ? modelMailNewInviteUsers : null,
            mail_invite_refresh_users : modelMailRefreshInviteUsers.length > 0 ? modelMailRefreshInviteUsers : null,
            mail_invite_delete_users : modelMailDeleteInviteUsers.length > 0 ? modelMailDeleteInviteUsers : null,
            showEmpty: showEmpty,
            pncode_countopen : '0',
        }

        if(isPublicCorpo){
            payload.gallery = isGallery;
        }

        props.updatePlugcodePlugnote(payload, environment.userhash, plugnote);

        setCountView(0);

    }


    const handleUpdatePsCode = () => {

        if(listUsersIntable.length > 0){
            setDataHasChanged(false);
            props.postAddPsPlugcodePlugnoteUser(plugnote.hash, plugnote.pncode, listUsersIntable, environment.userhash, comment, "3", plugnote);
        }

    }

    const copyPfCodeToClipboard = () => {
        if(isFirefox || isSafari){
            navigator.clipboard.writeText(link);
            document.execCommand('copy');
            toast.success(i18n.t('common:global.plugcodes-to-clipboard'));
        }else{
            navigator.permissions.query({name: "clipboard-write"}).then(result => {
                if (result.state == "granted" || result.state == "prompt") {
                    let pnCode = getCodePNCode(plugnote);
                    navigator.clipboard.writeText(pnCode);
                    toast.success(i18n.t('common:global.plugcodes-to-clipboard'));
                }
            });
        }
    };

    const copyLnkPfCodeToClipboard = () => {

        if(isFirefox || isSafari){
            navigator.clipboard.writeText(link);
            document.execCommand('copy');
            toast.success(i18n.t('common:global.link-to-clipboard'));
        } else{
            navigator.permissions.query({name: "clipboard-write"}).then(result => {
                if (result.state == "granted" || result.state == "prompt") {
                    navigator.clipboard.writeText(link);
                    toast.success(i18n.t('common:global.link-to-clipboard'));
                }
            });
        }

    };

    const downloadPfQrCode = () => {
        let tmpId = getCodePNCode(plugnote);
        html2canvas(document.querySelector(`#plugcode-plugnote-${tmpId}`)).then(canvas => {
            //document.body.appendChild(canvas)
            const pngUrl = canvas
                .toDataURL("image/png")
                .replace("image/png", "image/octet-stream");
            let downloadLink = document.createElement("a");
            downloadLink.href = pngUrl;
            downloadLink.download = id + ".png";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        });
    }

    const getCodePNCode = (plugnote) => {
        let code = 'PN';
        switch (plugnote.publicpncode) {
            case 0:
                code = 'PP';
                break;
            case 1:
                code = 'PN';
                break;
            case 2:
                code = 'PE';
                break;
            case 3:
                code = 'PS';
                break;
            default:
                break;
        }
        return (code) + '-' +
            moment(plugnote.creationdate).format('YYMMDD') + '-' +
            plugnote.codepncode
    }

    const _handleChangeComment = (event) => {
        setDataHasChanged(true);
        setComment(event.target.value);
    }


    const handleChangeRules = rules => {
        setDataHasChanged(true);
        setRules(rules)
    }


    const handleChangeCheckRules = (value) => {
        setDataHasChanged(true);
        setRules(value)
    }

    const handleSelectedUser = selectedOptionUser => {
        setSelectedOptionUser(selectedOptionUser);
    }

    const handleSelectedGroup = selectedOptionGroup => {
        setSelectedOptionGroup(selectedOptionGroup);
    }

    const handleChangeTypePnCode = typePnCodeChange => {
        setTypePnCodeChange(typePnCodeChange);
        setTypePnCode(typePnCodeChange.value);
    }

    useEffect(() => {
        generateListUsersInTable();
    }, [selectedOptionGroup, selectedOptionUsers])

    useEffect(() => {
        generateListUsersInTable();
    }, [optionsUsers, optionsGroups])

    useEffect(() => {
        if(isAddNewCorpoRules){
            setIsAddNewCorpoRules(false);
            _handleClickAddPersonToAccessPSCode()
        }
    }, [isAddNewCorpoRules])

    const handleAddRules = () => {

        if (isEmail(email) && getCurrentRules() !== false) {

            setDataHasChanged(true);
            setErrorMail(false);
            setErrorRules(false);
            const payload = {
                dh: plugnote.hash,
                pnc: plugnote.pncode,
                uh: null,
                privilege: getCurrentRules()
            };

            props.postAddPlugcodePlugnoteUser(payload, email, environment.userhash);

            setEmail("");
            setEditMode(false);
            setRules(-1);
        } else {
            if (!isEmail(email)) {
                setErrorMail(true);
            } else {
                setErrorMail(false);
            }

            if (rules === -1) {
                setErrorRules(true);
            } else {
                setErrorRules(false);
            }

        }
    }

    const handleDelRules = (email) => {

        setDataHasChanged(true);

        let pncodesusers = emailrules && emailrules && emailrules.length > 0 ?
            emailrules.find(user => {
                if (user.email === email) {
                    return user;
                }
            }) : null;

        const payload = {
            dh: plugnote.hash,
            pnc: plugnote.pncode,
            uh: pncodesusers.object.pncodeuser_userhash,
            pncush: pncodesusers.object.pncodeuser_id
        }

        props.delDeletePlugcodePlugnoteUser(payload, environment.userhash);

    }

    const handleAddCorpoRules = () => {

        if (isEmail(email) && getCurrentRules() !== false) {

            setDataHasChanged(true);
            setErrorMail(false);
            setErrorRules(false);
            const payload = {
                dh: plugnote.hash,
                pnc: plugnote.pncode,
                uh: null,
                privilege: getCurrentRules()
            };

            props.postAddPlugcodePlugnoteUser(payload, email, environment.userhash);

            setEmail("");
            setEditMode(false);
            setRules(-1);
        }
        else {
            if (!isEmail(email)) {
                setErrorMail(true);
            } else {
                setErrorMail(false);
            }

            if (rules === -1) {
                setErrorRules(true);
            } else {
                setErrorRules(false);
            }

        }
    };

    const handleDelCorpoRules = (email) => {

        setDataHasChanged(true);

        let pncodesusers = corporules && corporules && corporules.length > 0 ?
            corporules.find(user => {
                if (user.email === email) {
                    return user;
                }
            }) : null;


        setListUsersInTable(listUsersIntable.filter((item) => item.email !== email));


        if(pncodesusers){
            const payload = {
                dh: plugnote.hash,
                pnc: plugnote.pncode,
                uh: pncodesusers.object.pncodeuser_userhash,
                pncush: pncodesusers.object.pncodeuser_id
            }

            props.delDeletePlugcodePlugnoteUser(payload, environment.userhash);
        }

    }

    const handleClosePopup = () => {

        if (dataHasChanged) {
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.exit-without-saving'),
                buttons: [
                    {
                        label: t('global.ok'),
                        onClick: () => {
                            props.close();
                        }
                    },
                    {
                        label: t('global.cancel'),
                    },
                ]
            });
        } else {
            props.close();
        }
    }

    const getCurrentRules = () => {
        if (rules.value === 'read') {
            return EMAIL_RULES.READ
        } else if (rules.value  === 'readecopy') {
            return EMAIL_RULES.READECOPY
        } else if (rules.value  === 'readcopyedit') {
            return EMAIL_RULES.READCOPYEDIT
        } else return false;
    };

    const _handleClickAddPersonToAccessPSCode = () => {
        handleAddCorpoRules();
    }

    const id = getCodePNCode(plugnote);

    const getFullSelected = () => {

        let countSelected = 0;

        listUsersIntable.map((item, index) => {
            if(item.select){
                countSelected++;
            }
        })

        if(countSelected === listUsersIntable.length && listUsersIntable.length > 0){
            return true;
        }

        return false

    }

    const generateListUsersInTable = () => {

        let finalArray = [];

        if(props.listusersgroup !== "undefined"){
            props.listusersgroup.map((item, index) => {
                let value = {
                    hash : item.infos.hash,
                    name : item.infos.lastname + ' ' + item.infos.firstname,
                    read : true,
                    copy : false,
                    select : false,
                    email : item.infos.email,
                    group : item.roles.length > 1 ? item.roles[0].name + ' ...' : item.roles.length > 0 ? item.roles[0].name : '',
                    gallery : false,
                    rulesIndex : -1,
                    exist : false
                };

                let result = corporules.filter(obj => {
                    return obj.email === item.infos.email
                });
                let resultSelectedOptionUser = selectedOptionUsers !== null ? selectedOptionUsers.findIndex(obj => obj.email === item.infos.email) !== -1 : false;
                let resultSelectedOptionGroup = false;
                if(selectedOptionGroup !== null){
                    selectedOptionGroup.map((obj, index) => {
                        if(item.roles.findIndex(sub => obj.label === sub.name) !== -1){
                            resultSelectedOptionGroup = true;
                        }
                    })
                }

                if(result.length > 0){
                    let foundIndex = corporules.findIndex(obj => {
                        return obj.email === item.infos.email
                    });

                    if(foundIndex !== -1){
                        let corpo = corporules.find(obj => {
                            return obj.email === item.infos.email
                        })
                        value.select = true;
                        value.copy = corpo.object.pncodeuser_privilege === "readecopy";
                        value.gallery = corpo.object.pncodeuser_isGallery;
                        value.rulesIndex = foundIndex;
                        value.exist = true;
                        finalArray.push(value);
                    }
                }
                else if(resultSelectedOptionUser === true){
                    value.read = (getCurrentRules() === "read" || getCurrentRules() === "readecopy");
                    value.copy = getCurrentRules() === "readecopy";
                    value.select = true;

                    let findUser = listUsersIntable.findIndex((obj, index) =>  obj.email === item.infos.email);
                    if(findUser === -1){
                        finalArray.push(value);
                    }else{
                        finalArray.push(listUsersIntable[findUser]);
                    }


                }
                else if(resultSelectedOptionGroup === true){
                    value.read = (getCurrentRules() === "read" || getCurrentRules() === "readecopy");
                    value.copy = getCurrentRules() === "readecopy";
                    value.select = true;

                    let findUser = listUsersIntable.findIndex((obj, index) =>  obj.email === item.infos.email);
                    if(findUser === -1){
                        finalArray.push(value);
                    }else{
                        finalArray.push(listUsersIntable[findUser]);
                    }
                }

            })
        }

        if(finalArray.length > 0){
            setListUsersInTable(finalArray);
        }else if(finalArray.length === 0 && listUsersIntable.length > 0){
            setListUsersInTable(listUsersIntable);
        }else {
            setListUsersInTable(finalArray);
        }

    }

    const handleChangeFullSelect = (value) => {
        setListUsersInTable(value);
    }

    const sendMail = () => {
        setOpenSendMail(true)
    }

    const closeSendMail = () => {
        setOpenSendMail(false);
    }

    const checkDoublonObject = (list, text) => {
        return list.findIndex((item) => item.email === text) > -1
    };

    const handleAddMailInviteUser = (mail) => {
        if(isEmail(mail)){
            setErrorEmailInvite(false);
            let mailNewInviteUsers = modelMailNewInviteUsers;
            let mailInviteUsers = modelMailInviteUsers;

            if (!checkDoublonObject(modelMailInviteUsers, mail)) {
                const formatYmd = dateFormat (new Date (), "%Y-%m-%d %H:%M:%S", true)
                mailNewInviteUsers = mailNewInviteUsers.concat({"email" : mail, "date" : formatYmd});
                mailInviteUsers = mailInviteUsers.concat({"email" : mail, "date" : formatYmd});
            }

            setModelMailNewInviteUsers(mailNewInviteUsers);
            setModelMailInviteUsers(mailInviteUsers);
            setDataHasChanged(true);
        }else{
            setErrorEmailInvite(true);
        }
    };

    const handleDelMailNewInviteUser = (mail, index) => {
        let mailInviteUsers = modelMailNewInviteUsers.filter(item => item.email !== mail);
        setModelMailNewInviteUsers(mailInviteUsers);
        setDataHasChanged(true);
    }

    const handleDelMailInviteUser = (mail, index) => {
        let mailInviteUsers = modelMailInviteUsers.filter(item => item.email !== mail);
        let mailDeleteInviteUsers = modelMailDeleteInviteUsers;

        if (!checkDoublonObject(modelMailDeleteInviteUsers, mail)) {
            mailDeleteInviteUsers = mailDeleteInviteUsers.concat(modelMailInviteUsers[index]);
            setModelMailDeleteInviteUsers(mailDeleteInviteUsers)
        }

        setModelMailInviteUsers(mailInviteUsers);
        setDataHasChanged(true);
    }

    const handleRefreshMailInviteUser = (mail, index) => {
        //let mailInviteUsers = modelMailInviteUsers.filter(item => item.email !== mail);
        //setModelMailInviteUsers(mailInviteUsers);
        //setDataHasChanged(true);

        const formatYmd = dateFormat (new Date (), "%Y-%m-%d %H:%M:%S", true)

        let mailRefreshInviteUsers = modelMailRefreshInviteUsers;

        if (!checkDoublonObject(modelMailRefreshInviteUsers, mail)) {
            mailRefreshInviteUsers = mailRefreshInviteUsers.concat({"email" : mail, "date" : formatYmd});
        }

        if(checkDoublonObject(modelMailInviteUsers, mail)){
            let mailInviteUsers = modelMailInviteUsers.filter(item => item.email !== mail);
            mailInviteUsers = mailInviteUsers.concat({"email" : mail, "date" : formatYmd});
            setModelMailInviteUsers(mailInviteUsers);
        }

        setModelMailRefreshInviteUsers(mailRefreshInviteUsers);
        setDataHasChanged(true);

    }

    function handleShowEmptyOption(e) {
        setShowEmpty(e.target.value)

    }

    function handleSelecetedTags(items) {
    }

    useEffect(() => {

        setComment(checkComment(plugnote));

        if (plugnote) {

            tmpEmailrules = plugnote && plugnote.pncodesusers && plugnote.pncodesusers.length > 0 && plugnote.publicpncode === 0 ?
                plugnote.pncodesusers.map(user => {
                    return {
                        email: user.email,
                        rules: user.pncodeuser_privilege,
                        object: user
                    }
                }) : []

            tmpCorporules = plugnote && plugnote.pncodesusers && plugnote.pncodesusers.length > 0 && plugnote.publicpncode === 3 ?
                plugnote.pncodesusers.map(user => {
                    return {
                        hash: user.hash,
                        rules: user.pncodeuser_privilege,
                        object: user,
                        email: user.email,
                        user: user.pncodeuser_lastname + ' ' + user.pncodeuser_firstname,
                    }
                }) : [];
        }

        setEmailrules(tmpEmailrules);
        setCorporules(tmpCorporules);
        setIsPublic(plugnote.publicpncode === 1);
        setIsPrivate(plugnote.publicpncode === 0);
        setIsPublicCorpo(plugnote.publicpncode === 2);
        setIsPrivateCorpo(plugnote.publicpncode === 3);

        if(typeof plugnote.pncode !== "undefined" && plugnote.pncode !== null){
            switch (plugnote.publicpncode){
                case 0:
                    setTypePnCode('private');
                    break;
                case 1:
                    setTypePnCode('public');
                    break;
                case 2:
                    setTypePnCode('public-corpo');
                    break;
                case 3:
                    setTypePnCode('private-corpo');
                    break;
                default:
                    setTypePnCode('public');
                    setTypePnCodeChange({value : "public" , label : t("common:global.public")});
                    break;
            }

            if((i18n.language.charAt(0).toLowerCase() + i18n.language.charAt(1).toLowerCase()) === 'fr'){
                setTitle("Accédez à ma Plugnote");
                //. Accédez à la fiche en cliquant sur le lien ci-dessous ou en encodant le Plugcode PN-191230-IK633 dans l'application ou sur www.plugcodes.com.
                setContent(
                    "Bonjour,\n\n"+
                    "Je vous invite à consulter ma fiche "+ environment.envIndex  +"-"+ plugnote.userindex +" via Plugnotes.\n\n" +
                    "Accédez à la fiche en cliquant sur le lien ci-dessous ou en encodant le Plugcode "+ getCodePNCode(plugnote) + " dans l'application ou sur www.plugcodes.com.\n\n" +
                    getPlugcodeLink(plugnote)+"\n\n\n"+
                    "Cordialement,\n\n\n"+
                    props.pseudo
                )
            }
            else{
                setTitle("Access my Plugnote");
                setContent(
                    "Dear,\n\n"+
                    "I invite you to consult my repertory "+ environment.envIndex +"-"+ plugnote.userindex +" via Plugnotes.\n\n" +
                    "Access the form by clicking on the link below or by encoding the Plugcode "+ getCodePNCode(plugnote) + " in the app or on www.plugcodes.com.\n\n" +
                    getPlugcodeLink(plugnote)+"\n\n\n"+
                    "Kind regards,\n\n\n"+
                    props.pseudo
                )
            }
        }

        let mailInviteUsers = [];

        if (plugnote && plugnote.mailInviteUsers && plugnote.mailInviteUsers.length > 0){
            //mailInviteUsers.push({"email" : props.email, "read" : null, "date": null, "addannexes" : null ,  "main" : true})
            try {
                let textMailInviteUsersToJson = JSON.parse(plugnote.mailInviteUsers);
                mailInviteUsers = textMailInviteUsersToJson.map(notification => {
                    return {
                        email: notification.email,
                        date : notification.date,
                    }
                });
                setModelMailInviteUsers(mailInviteUsers);
            }catch(err){
                let textMailInviteUsersToJson = JSON.stringify(plugnote.mailInviteUsers);
                let textMailInviteUsersFromJson = JSON.parse(textMailInviteUsersToJson);
                mailInviteUsers = textMailInviteUsersFromJson.map(notification => {
                    return {
                        email: notification.email,
                        date : notification.date,
                    }
                });
                setModelMailInviteUsers(mailInviteUsers);
            }
        }

        setIsLoaded(true)



    }, [props.plugnote]);

    useEffect(() => {

        let listusergroups = [];
        props.listusersgroup.map((item, index) => {
            let sub = {value : item.id, label : item.infos.lastname + ' ' + item.infos.firstname, email : item.infos.email};
            listusergroups.push(sub);
        })

        setOptionsUsers(listusergroups);

    }, [props.listusersgroup]);

    useEffect(() => {

        let subgroups = [];
        props.subgroup.map((item, index) => {
            let sub = {value : item.id, label : item.name};
            subgroups.push(sub);
        })

        setOptionsGroups(subgroups);

    }, [props.subgroup]);

    useEffect(() => {
        if(isPrivateCorpo && isLoaded){
            props.attemptGetCorporateUsersGroups(props.groupId,environment.userhash);
            setIsLoaded(false);
        }
    }, [isLoaded]);

    return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={props.handleModalModelPlugcodeClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >

            <div style={modalStyle} className={classes.popupPrivate}>
                {(plugnote.pncode == null || plugnote.pncode === "") ?
                    < div className="scrollbar-hidden popup_wrapper_plugcode flexSpacebetween">
                        < div className="popup_wrapper_content">
                            <div className="sous-header_modal_content">
                                <div>
                                <span className="titres">
                                    {t('common:plugnote.send-plugnote')}  <br/>
                                </span>
                                    <span className="grey">
                                {/*[{model.clef}] {model.label}*/}
                                </span>
                                </div>
                                <div onClick={props.close}>
                                <span id="icon-close" className="material-icons md-50 lightgrey">
                                close
                                </span>
                                </div>

                            </div>
                            <div className="sous-header_content lightgrey">
                                <div className="line flex_style">
                                </div>
                            </div>
                            <div className="popup_wrapper_content">
                                <div className="margin-bottom-top">
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        options={props.user.user.corporate ? generateCorpoOptions : generateOptions}
                                        value={typePnCodeChange}
                                        onChange={handleChangeTypePnCode}
                                    />
                                    <button className="button-margin-top button_full_width"
                                            onClick={handleGeneratePNCode}>{t('common:plugnote.generate-plugcode')}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="scrollbar-hidden popup_wrapper_plugcode flexSpacebetween">
                        <div className="popup_wrapper_content">
                            <div className="sous-header_modal_content">
                                <div>
                                    <span className="titres">
                                    {t('common:plugcode.send-plugnote')} <br/>
                                    </span>
                                    <span className="grey">
                                        [{environment.envIndex }-{model.clef}] {model.label} {environment.envIndex }-{plugnote.userindex}
                                    </span>
                                </div>

                                <div>
                                    <div className="display_flex">
                                        <div onClick={copyPfCodeToClipboard} className="cursor grey">
                                        <span className=" md-25 ">
                                            { getCodePNCode(plugnote) }
                                        </span>
                                            <span id="icon-close" className="material-icons md-25 lightgrey">
                                        content_copy
                                    </span>
                                        </div>

                                        <div onClick={isPrivateCorpo ? handleUpdatePsCode : handleUpdatePnCode}  className="cursor">
                                    <span id="icon-close" className="material-icons md-25 lightgrey">
                                        save
                                    </span>
                                        </div>
                                        <div onClick={handleClosePopup}  className="cursor">
                                    <span id="icon-close" className="material-icons md-25 lightgrey">
                                        close
                                    </span>
                                        </div>
                                    </div>

                                    {
                                        countView > 0 &&
                                        <div className="display_flex">
                                            <div><span className="green-blue bold">{ t('common:plugcode.view-count') } : </span><span className="green-blue bold">{countView}</span></div>
                                            <div className="margin-left-20 cursor green-blue bold" onClick={(event) => handleEraseView()}>
                                                <span>{ t('common:plugcode.view-count-erase') }</span>
                                                <span className="material-icons md-15 margin-left-5 margin-top-minder-5">
                                            cleaning_services
                                        </span>
                                            </div>
                                        </div>
                                    }

                                </div>




                            </div>
                            <div className="sous-header_content lightgrey">
                                <div className="line flex_style">
                                </div>
                            </div>
                            {isPrivateCorpo ?
                            <div className="fullWidth">
                                <div className="inputWrapper popup-TextInput">
                                    <div>
                                        <span className="grey">{t('common:plugcode.complete-an-description')}</span>
                                    </div>
                                    <li>
                                        <textarea className="input_area" name="description" placeholder={t('common:plugcode.complete-an-description')} rows="3" value={comment} onChange={_handleChangeComment}/>
                                    </li>
                                </div>
                                <div className="">
                                    <div>
                                        <span className="grey">{t('common:plugcode.choose-options-form')}</span>
                                    </div>
                                    <div className="inputWrapper">
                                        <div>
                                            <div className="flex_display_direction_row_baseline">
                                                <div className="dixWidth">
                                                    <label>{t('share.select-rules-label')} : </label>
                                                </div>
                                                <div className="nonanteWidth">
                                                    <label htmlFor="read">{t('common:plugcode.rule-read')} </label>
                                                    <input disabled={true} type="checkbox" name="read" value="read" id={'read'} checked={true} onChange={(event) => {
                                                        setDataHasChanged(true);
                                                    }}/>
                                                    <span> </span>
                                                    <label htmlFor="readecopy">{t('common:plugcode.rule-copy')} </label>
                                                    <input disabled={false} type="checkbox" name="readecopy" checked={rules.value === "readecopy"} id={'readecopy'} onChange={(event) => {
                                                        setDataHasChanged(true);
                                                        //        {value : "read" , label : t('common:plugcode.rule-read')},
                                                        //         {value : "readecopy" , label : t('common:plugcode.rule-read-copy')}
                                                        if(event.target.checked){
                                                            handleChangeCheckRules({value : "readecopy" , label : t('common:plugcode.rule-read-copy')});
                                                        }else{
                                                            handleChangeCheckRules({value : "read" , label : t('common:plugcode.rule-read')});
                                                        }
                                                    }}/>
                                                </div>
                                            </div>
                                            <div className="flex_display_direction_row_baseline">
                                                <div className="quinzeWidth"><label>{t('global.search-by-groups')} : </label></div>
                                                <div className="trenteWidth">
                                                    <Select
                                                        placeholder=""
                                                        isMulti={true}
                                                        value={selectedOptionGroup}
                                                        onChange={handleSelectedGroup}
                                                        options={optionsGroups}
                                                        style={{width : '100%'}}

                                                    />
                                                </div>
                                                <div className="dixWidth"/>
                                                <div className="quinzeWidth"><label>{t('global.search-by-users')} : </label></div>
                                                <div className="trenteWidth">
                                                    <Select
                                                        placeholder=""
                                                        isMulti={true}
                                                        value={selectedOptionUsers}
                                                        onChange={handleSelectedUser}
                                                        options={optionsUsers}
                                                        style={{width : '100%'}}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <div className="divTable" style={{width: '100%'}}>
                                                <div className="divTableBody">
                                                    <div className="divTableRow">
                                                        <div className="divTableCell" style={{width: '40%'}}>{t('common:global.users')}</div>
                                                        <div className="divTableCell" style={{width: '25%'}}>{t('common:global.groups')}</div>
                                                        <div className="divTableCell" style={{width: '5%'}}>
                                                            <input type="checkbox" name="scales" checked={getFullSelected()} onChange={(event) => {
                                                                let list = listUsersIntable
                                                                list.map((item,index) => {
                                                                    item.select = event.target.checked;
                                                                })
                                                                handleChangeFullSelect(list);
                                                            }}/><label htmlFor="scales"></label>
                                                        </div>
                                                        <div className="divTableCell" style={{width: '5%'}}>{t('common:global.read')}</div>
                                                        <div className="divTableCell" style={{width: '5%'}}>{t('common:global.copy')}</div>
                                                        <div className="divTableCell" style={{width: '15%'}}>{t('common:global.add-to-gallery')}</div>
                                                        <div className="divTableCell" style={{width: '5%'}}>{t('common:global.delete')} </div>
                                                    </div>
                                                    {listUsersIntable.map((item, index) => {
                                                        return(
                                                            <div key={index} className="divTableRow">
                                                                <div className="divTableCell" style={{width: '40%'}}>{item.name}</div>
                                                                <div className="divTableCell" style={{width: '25%'}}>{item.group}</div>
                                                                <div className="divTableCell" style={{width: '5%'}}>
                                                                    <input type="checkbox" value={item.select} id={'select'+item.id} checked={!!item.select} onChange={(event) => {
                                                                        setDataHasChanged(true);
                                                                        let newArr = [...listUsersIntable]; // copying the old datas array
                                                                        newArr[index].select = event.target.checked; // replace e.target.value with whatever you want to change it to
                                                                        setListUsersInTable(newArr)
                                                                    }}/>
                                                                </div>
                                                                <div className="divTableCell" style={{width: '5%'}}>
                                                                    <input type="checkbox" value={item.read} id={'read'+item.id} checked={true} onChange={(event) => {
                                                                        setDataHasChanged(true);
                                                                        let newArr = [...listUsersIntable]; // copying the old datas array
                                                                        newArr[index].read = true; // replace e.target.value with whatever you want to change it to
                                                                        setListUsersInTable(newArr)
                                                                    }}/>
                                                                </div>
                                                                <div className="divTableCell" style={{width: '5%'}}>
                                                                    <input type="checkbox" value={item.copy} id={'copy'+item.id} checked={!!item.copy} onChange={(event) => {
                                                                        setDataHasChanged(true);
                                                                        let newArr = [...listUsersIntable]; // copying the old datas array
                                                                        if(event.target.checked && !newArr[index].read){
                                                                            newArr[index].read = event.target.checked;
                                                                        }
                                                                        newArr[index].copy = event.target.checked; // replace e.target.value with whatever you want to change it to
                                                                        setListUsersInTable(newArr)
                                                                    }}/>
                                                                </div>
                                                                <div className="divTableCell" style={{width: '15%'}}>
                                                                    <input type="checkbox" value={item.gallery} id={'gallery'+item.id} checked={!!item.gallery} onChange={(event) => {
                                                                        setDataHasChanged(true);
                                                                        let newArr = [...listUsersIntable]; // copying the old datas array
                                                                        newArr[index].gallery = event.target.checked; // replace e.target.value with whatever you want to change it to
                                                                        setListUsersInTable(newArr)
                                                                    }}/>
                                                                </div>
                                                                <div className="divTableCell" style={{width: '5%'}}>
                                                                    { item.rulesIndex !== -1 && item.exist &&
                                                                    <div onClick={(event) => handleDelCorpoRules(item.email, item.rulesIndex)} className="fullWidth">
                                                                            <span id="icon-close" className="material-icons md-25 lightgrey float_right">
                                                                                delete
                                                                            </span>
                                                                    </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                <div>
                                    <span className="grey">{t('common:plugcode.choose-options-form')}</span>
                                </div>
                                {isPublicCorpo &&
                                <div className="inputWrapper">
                                    <input id="addtogallery" value={isGallery} type="checkbox" name="addtogallery" checked={!!isGallery} onChange={(event) => {
                                        if(event.target.checked){
                                            setIsGallery(true);
                                            setDataHasChanged(true);
                                        }
                                        else{
                                            setDataHasChanged(true);
                                            setIsGallery(false);
                                        }
                                    }}/><label htmlFor="addtogallery">{t('common:global.add-to-gallery')}</label>
                                </div>
                                }
                                 {/* // Option pour masquer/afficher les champs vides lors de la consultation de plugcode // désactivé pour l'instant
                                <div className={classes.RadioGroupCell} aria-label={"show-empty-option"} name={"show-empty-option"} value={showEmpty}>
                                    <div className={classes.showOption} onClick={() => setShowEmpty(false)}>
                                        <input type='radio' size='small' value={false} checked={showEmpty === false} className={classes.radioIcon}/><span className={classes.radioLabel}>{ t('common:modal-plugcode.hide-empty') }</span>
                                    </div>
                                    <div className={classes.showOption} onClick={() => setShowEmpty(true)}>
                                        <input type='radio' size='small' name={"show-empty-option"} value={true} checked={showEmpty === true} className={classes.radioIcon}/><span className={classes.radioLabel}>{ t('common:modal-plugcode.show-empty') }</span>
                                    </div>
                                </div> */}
                                <div className="inputWrapper">
                                    <div>
                                        <span className="grey">{ t('common:modal-plugcode.invite-users') }</span>
                                    </div>
                                    <div className={classes.textFieldChips}>
                                        <TagsInputObjectMails
                                            handleRemove={handleDelMailNewInviteUser}
                                            handleAdd={handleAddMailInviteUser}
                                            selectedTags={handleSelecetedTags}
                                            fullWidth
                                            tags={modelMailNewInviteUsers}
                                            controls={modelMailInviteUsers}
                                            id="tags"
                                            name="tags-shared"
                                            placeholder=""
                                            label=""

                                        />
                                    </div>

                                    {(isPublic || isPrivate) &&
                                    <div>

                                        <div className={classes.divRadiusShare}>
                                            <div className={classes.paddingMargin5}>
                                                <span className="black">{t('common:plugcode.list-shared-user')}</span>
                                            </div>
                                            <div className="line-white flex_style">
                                            </div>
                                            <div className="scrollbar-hidden inputWrapper retrait-popup-margin">
                                                <div className={`scrollbar-hidden  ${classes.divSharedUser}`}>
                                                    {modelMailInviteUsers.map((mail, index) => {
                                                        return (
                                                            <div key={index} className="flex_display_list_email">
                                                                {mail.email}
                                                                <span className="margin-left-auto cursor" onClick={(event) => handleRefreshMailInviteUser(mail.email, index)}>
                                                                    {mail.date}
                                                                    <span id="icon-close" className="material-icons md-25 black">
                                                                        refresh
                                                                    </span>
                                                                </span>
                                                                <span id="icon-close" className="material-icons md-25 black margin-left-auto cursor" onClick={(event) => handleDelMailInviteUser(mail.email, index)}>
                                                                    delete
                                                                </span>
                                                            </div>)
                                                    })
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            !isPublic &&
                                            <div className="flex_display_direction_row">
                                                <div className="semiWidth">
                                                    <Select
                                                        options={options}
                                                        disableUnderline
                                                        value={rules}
                                                        onChange={handleChangeRules}
                                                        label={t('share.select-rules-label')}
                                                        style={{width : '100%'}}
                                                        placeholder={t('share.select-rules-label')}
                                                    />
                                                </div>
                                                <div className="semiWidth margin-left-10">
                                                    <Input
                                                        disabled={editMode}
                                                        id="standard-full-width"
                                                        label=""
                                                        placeholder={t('common:plugcode.email')}
                                                        fullWidth={true}
                                                        margin="none"
                                                        disableUnderline={true}
                                                        endAdornment={
                                                            <InputAdornment position="end" onClick={email.length > 0 && rules !== -1 && isEmail(email) ? handleAddRules : null}
                                                                            style={{cursor: 'pointer'}}>
                                                                <Add/>
                                                            </InputAdornment>
                                                        }
                                                        inputlabelprops={{
                                                            className: classes.input,
                                                        }}
                                                        value={email}
                                                        className={classes.textField}
                                                        onChange={(event) => setEmail(event.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        }
                                        <div className="flex_display_direction_row">
                                            <div className="div_width_50_centered">{errorRules &&
                                            <span className="red">{t('common:plugcode.error-rules')}&nbsp;</span>}</div>
                                            <div className="div_width_50_centered">{errorMail &&
                                            <span className="red">{t('common:plugcode.error-email')}&nbsp;</span>}</div>
                                        </div>
                                        {
                                            emailrules.length > 0 &&
                                            <div className={classes.divRadiusShareRules}>
                                                <div className={classes.paddingMargin5}>
                                                    {
                                                        emailrules.map((emailRule, index) => {
                                                            return (
                                                                <div key={index} className="flex_display_list_email margin-5x">
                                                                    {emailRule.email}
                                                                    <div onClick={(event) => handleDelRules(emailRule.email, index)}
                                                                            className="fullWidth">
                                                                        <span id="icon-close" className="material-icons md-25 lightgrey float_right">
                                                                            delete
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    }
                                    <div>
                                        <span className="grey">{ t('common:modal-plugcode.comment') }</span>
                                    </div>
                                    <div className="inputWrapper popup-TextInput">
                                        <div>
                                            <textarea className="input_area_small" name="description" rows="3" value={comment} onChange={_handleChangeComment}/>
                                            {/* <span style={{float : "right"}}>{comment.length +  ' / ' + '256'}</span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            <div className="margin-top flex_display_direction_row_center">

                                <div className="flex_display_direction_row_center">
                                    <div className="confirmation_pop-up cursor" onClick={handleDeletePnCode}>
                                        <button className="grey-button"><span className="greyFine">{t('common:plugnote.delete-plugcode')}</span></button>
                                    </div>
                                </div>

                                <div className="order-2 flex_display_direction_row_center">
                                    <div onClick={downloadPfQrCode} className="cursor margin-5">
                                        <button className="grey-button margin-5">
                                        <span className="material-icons md-25 black">
                                            qr_code
                                        </span>
                                            <span className="greyFine">{t('common:plugcode.qr-code')}</span>
                                        </button>
                                    </div>
                                    {
                                        (isPublic || isPrivate) &&
                                        <div onClick={copyLnkPfCodeToClipboard} className="cursor">
                                            <button className="green-button margin-5">
                                        <span className="material-icons md-25 ">
                                            link
                                        </span>
                                                <span className="white">{t('common:plugcode.copy-link')}</span>
                                            </button>
                                        </div>
                                    }
                                    <div  onClick={sendMail} className="cursor">
                                        <button className="base-button">
                                           <span className="material-icons md-25 ">
                                                mail_outline
                                            </span>
                                            <span className="white">{ t('common:modal-plugcode.invite') }</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="popup_wrapper_content_secret">
                            <div id={`plugcode-plugnote-${id}`}>
                                <div style={{
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    display: 'flex',
                                }}>
                                    <div className="subQrCode-media-div">
                                        <span> {comment} </span>
                                    </div>
                                    <div className='qrCode-media'>
                                        <div className='subQrCode-media-color-1'>
                                            <div className='subQrCode-media-color-2'>
                                                <div>
                                                    <QRCode
                                                        id={id}
                                                        value={link}
                                                        size={180}
                                                        bgColor={"#ffffff"}
                                                        fgColor={"#20959D"}
                                                        level={"L"}
                                                        includeMargin={false}
                                                        renderAs={"png"}
                                                        imageSettings={{
                                                            src: Images.iconQrCode,
                                                            x: null,
                                                            y: null,
                                                            height: 40,
                                                            width: 40,
                                                            excavate: false,
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div><span className="white">{ApplicationConfig.url}</span></div>
                                        <div><span className="white">{getCodePNCode(plugnote)}</span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        { (plugnote.pncode !== null && plugnote.pncode !== "") &&
                        <ModalSimpleSendMailV2PlugnoteCodeComponent
                            open={openSendMail}
                            close={closeSendMail}
                            code={getCodePNCode(plugnote)}
                            link={isPublic ? getPlugcodeLink(plugnote) : ""}
                            plugnote={plugnote}
                            title={title}
                            content={content}
                        />
                        }
                    </div>
                }
            </div>

        </Modal>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    let groupId  = 0;
    if(!!state.user && !!state.user.user){
        if(!!state.user.user.corporate_infos && !!state.user.user.corporate){
            if(state.user.user.corporate){
                groupId = state.user.user.corporate_infos.group_id
            }
        }
    }

    return {
        plugnote : state.plugcode.plugnote,
        email: state.user.profil.email,
        user : !!state.user && !!state.user.user ? state.user : null,
        group : !!state.plugcode.group ? state.plugcode.group : null,
        subgroup : !!state.plugcode.subgroup ? state.plugcode.subgroup : [],
        listusersgroup : !!state.plugcode.listusersgroup ? state.plugcode.listusersgroup : [],
        corporate : !!state.plugcode.corporate ? state.plugcode.corporate : [],
        groupId : groupId,
        pseudo : state.user.profil.pseudo

    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        attemptGetCorporateUsersGroups:(group,userhash) => dispatch(getCorporateUsersGroupsRequest(group,userhash)),
        postAddPlugcodePlugnoteUser:(payload,email,userhash) => dispatch(postAddPlugcodePlugnoteUserRequest(payload,email,userhash)),
        delDeletePlugcodePlugnoteUser:(payload,usderhash) => dispatch(delDeletePlugcodePlugnoteUserRequest(payload,usderhash)),
        generatePlugcodePlugnote:(payload,isPublic, userhash, plugnote) => dispatch(postGeneratePlugnotecodeRequest(payload,isPublic, userhash, plugnote)),
        deletePlugcodePlugnote:(datahash, datapnc, userhash) => dispatch(deleteGeneratePlugnotecodeRequest(datahash, datapnc, userhash)),
        updatePlugcodePlugnote: (payload,userhash,plugnote) => dispatch(putGeneratePlugnotecodeRequest(payload,userhash,plugnote)),
        postAddPsPlugcodePlugnoteUser:(datahash,pnhash,listUsers,userhash,comment,type,plugnote) => dispatch(putGeneratePsPlugnotecodeRequest(datahash,pnhash,listUsers,userhash,comment,type,plugnote)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalPlugnotePlugcodeComponent)
