import React, {useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core/styles"
import {connect} from "react-redux"
import Input from "@material-ui/core/Input"
import {useTranslation} from "react-i18next"
import {InputLabel, TextField} from "@material-ui/core"
import { Autocomplete, createFilterOptions } from "@material-ui/lab"
import { devLog, isDefined } from "../../utils/utils"

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    MuiInputBaseInput:{
        padding : 0
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    mainField : {
        display: 'flex',
        flexDirection : 'column',
        paddingLeft: 40,
        paddingRight: 10,
    },
    selectType: {
        marginBottom: 8,
    },
    field : {
        width : '100%',
        display: 'flex',
    },
    inputField:{
        padding : '0px 0px 7px',
    },
}))

const FormatSignConstructorComponent = (props) => {
    const classes = useStyles()
    const { t } = useTranslation(['common', 'models'])
    const [template, setTemplate] = useState(props?.field?.options?.template || '2001')

    const filter = createFilterOptions()

    const handleChangeTemplate = (e, newValue) => {
        // devLog('handleChange template e', e)
        // devLog('handleChange template value', newValue)
        if (typeof newValue === 'string') {
            handleChangeTemplateSelect(newValue)
        } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            handleChangeTemplateSelect(newValue.inputValue)
        } else {
            handleChangeTemplateSelect(newValue?.value || '2001')
        }
    }

    const handleFilterOptions = (options, params) => {
        const filtered = filter(options, params)
        const { inputValue } = params

        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.title)
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            value: `Add "${inputValue}"`,
          })
        }
        return filtered
    }

    const handleGetOptionLabel = (option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
            return option
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
            return option.inputValue
        }
        // Regular option
        return option.label
    }

    const handleChangeTemplateSelect = (value) => {
        setTemplate(value)
        props.field.options.template = value
        let model = [...props.model]
        model[props.index].options.template = value
        props.setModel(model)
    }

const templates = [
    { "label": t("print.2001"), "value": "2001" },
    { "label": t("print.9002"), "value": "9002" },
    { "label": t("print.9099"), "value": "9099" },
]

    useEffect(() => {
        if (typeof props.field.options.template === 'undefined') {
            handleChangeTemplateSelect(template, props.field, props.index);
        }
    }, [template])

    return (
        <div className={classes.mainField}>
            {/* <div className={classes.field}>
                <Input
                    id="standard-full-width"
                    label="Label"
                    placeholder={t('fields.format-choice-placeholder-' + props.field.format.toLowerCase())}
                    fullWidth
                    margin="none"
                    inputlabelprops={{
                        shrink: true,
                    }}
                    inputProps={
                        {
                            fieldkey: props.field.key,
                            className: classes.inputField,
                            maxLength: 40
                        }
                    }
                    className="text-line"
                    onChange={(event) => props.handleEditLabel(event.target.value, props.field, props.index)}
                    defaultValue={props.field.label}
                />
            </div> */}
            <div className={classes.selectType}>
                <InputLabel id={props.field.id + "-" + props.field.format.toLowerCase()}>{t('global.choose-template')}</InputLabel>
                <Autocomplete
                    clearOnBlur
                    filterOptions={handleFilterOptions}
                    // freeSolo
                    fullWidth
                    getOptionLabel={handleGetOptionLabel}
                    id={props.field.id + "-" + props.field.format.toLowerCase()}
                    key="smail-template-select"
                    onChange={handleChangeTemplate}
                    options={templates}
                    placeholder={t('fields.format-choice-placeholder-' + props.field.format.toLowerCase())}
                    renderInput={(params) => <TextField {...params} value={"Texte"} />}
                    renderOption={(prop, _) => <span {...prop}>{prop.value} - {t(prop.label)}</span>}
                    selectOnFocus
                    value={
                        isDefined(templates.find(entry => entry.value === template)) ?
                        template + ' - ' + templates.find(entry => entry.value === template)?.label 
                        : template
                    }
                />
            </div>
        </div>
    )
}

// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({ })

export default connect(mapStateToProps, mapDispatchToProps)(FormatSignConstructorComponent)





