import React, { useState } from 'react'
import { Modal, Typography, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Content, Header } from 'rsuite'
import PlugformSetupPrintable from '../printable/PlugformSetupPrintable'
import colors from '../../assets/themes/Colors'
import { isDefined } from '../../utils/utils'

const useStyles = makeStyles(() => ({
  modalCtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal: {
    position: 'absolute',
    //maxHeight: '95vh',
    overflow: 'auto',
    height: '95vh',
    '@media (max-width: 900px)': {
      minWidth: '95vw',
    },
    backgroundColor: 'white',
    width: '1000px',
    maxWidth: '80vw',
    border: '1px solid #DEDEDE',
    boxSizing: 'border-box',
    boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
    borderRadius: 10,
  },
  modalTitle: {
    fontSize: 28,
    padding: 28,
    color: colors.darkSilver,
    fontWeight: 'bolder',
  },
}))

const ModalPrintSetupComponent = (props) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const modalStyle = {}
  const fields = props.fields.map(field => {
    if (isDefined(props.infobulles?.hasOwnProperty('field' + field.id))) {
      return {
        ...field,
        infobulle: props.infobulles['field' + field.id]
      }
    } else return field
  })

  return (
    <Modal
      keepMounted={false}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modalCtn}
    >
      <div style={modalStyle} className={classes.modal}>
        <Header style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between',}}>
          <div className={classes.modalTitle} >{t('print.setup-print')}</div>
          <div onClick={props.handleClose} style={{ alignSelf: 'center', marginRight: 20 }}>
            <span id="icon-close" className="material-icons md-50 lightgrey">
              close
            </span>
          </div>
        </Header>

        <Content>
          <div></div>
          <PlugformSetupPrintable {...props} fields={fields}/>
        </Content>
      </div>
    </Modal>
  )
}

export default ModalPrintSetupComponent
