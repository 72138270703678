import React, { useState } from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {connect} from "react-redux";
import '@contentful/forma-36-react-components/dist/styles.css';
import Input from "@material-ui/core/Input";

function getModalStyle() {
    const top = 5;

    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '95%',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    marginLeft:{
        marginLeft : '10px'
    },
    MuiInputBaseInput:{
        width : '50%'
    },
    inputField:{
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F1F1F1',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    }
}));

const ModalFormatFormulaConstructorComponent = (props) => {
    const { t } = useTranslation('common')
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = useState(getModalStyle);
    const [key, setKey] = useState(0);
    const [formula, setFormula] = useState(typeof props.field.options.formula !== "undefined" ? props.field.options.formula : "");

    const handleCloseModal = () => {
        props.close();
    };

    const handleChangeFormula = (value) => {
        setFormula(value);
        // setFormula(value.toUpperCase());
        // props.handleDataFormula(value.toUpperCase())
        props.handleDataFormula(value)
    }

    const _handleAddFieldInFormula = (value) => {
        let newFormula = formula.concat(value);
        setFormula(newFormula);
        props.handleDataFormula(value)
    }

    return (
        <Modal
            key={key}
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div style={modalStyle} className={classes.popup}>
                <div className = "scrollbar-hidden popup_wrapper flexSpacebetween" >
                    <div className="popup_wrapper_content">
                        <div className="sous-header_modal_content">
                            <div>
                                <span className="titres">
                                    F29 : {t('fields.format-choice-'+props.field.format.toLowerCase())}
                                    <br/>
                                </span>
                            </div>
                            <div onClick={handleCloseModal}>
                                    <span id="icon-close" className="material-icons md-50 lightgrey">
                                    close
                                    </span>
                            </div>

                        </div>
                        <div className="sous-header_content lightgrey">
                            <div className="line flex_style">
                            </div>
                        </div>

                        <div className="sous-header_content ">
                            <div className="display_flex flex_display_direction_column div_width_100">
                                <Input
                                    autoFocus
                                    id="standard-full-width-clef"
                                    label="Label"
                                    placeholder={t('fields.format-choice-placeholder-'+props.field.format.toLowerCase()+'-formula')}
                                    fullWidth={true}
                                    margin="none"
                                    multiline={true}
                                    // style={ {textTransform: "uppercase" }}
                                    disableUnderline={true}
                                    inputlabelprops={{
                                        shrink: true,
                                    }}
                                    inputProps={
                                        {
                                            fieldkey: props.field.pfcode,
                                            className: classes.inputField,
                                        }
                                    }
                                    disabled={false}
                                    className="text-line"
                                    onChange={(event) => handleChangeFormula(event.target.value)}
                                    value={formula}
                                />
                            </div>
                        </div>
                        <span>{t('setup.formula-fields')}</span>
                        <div className="sous-header_content ">
                            <ul className="display_flex flex_display_direction_column div_width_100">
                                { 
                                    props.fields.map((field, index) => {
                                    if(typeof field !== "undefined" && (field.format === 'numeric' || field.format === 'time')){
                                        return (
                                            <li key={index}>
                                                <span>{field.label}</span> :  <span>PN!A{index + 1}</span>
                                            </li>
                                        )
                                    }
                                    return null;
                                })
                                }
                            </ul>

                        </div>
                    </div>
                    <div className="popup_wrapper_content">
                        <ul>{t('setup.formula-examples')}
                            <li>{t('setup.formula-time-difference')}=TEXT(PN!A2-PN!A1, "H:MM") : 12:00, 13:00 → 1:00</li>
                            <li>{t('setup.formula-sum')} =SUM(PN!A2,PN!A1) : 12, 13 → 25</li>
                        </ul>
                        <span><a href="https://handsontable.github.io/hyperformula/guide/built-in-functions.html#overview" target="_blank" rel="noreferrer">{t('setup.see-documentation')}</a> </span>
                    </div>
                </div>
            </div>
        </Modal>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalFormatFormulaConstructorComponent)
