import * as moment from "moment";
import i18n from './../translations/i18n';
import ApplicationConfig from "../config/ApplicationConfig";
import {devLog, isDefined, zeroPad, addZeroBefore } from "./utils";
import {isNull, isUndefined} from "lodash";

export const getContentValueForField = (field, mode) => {
    let fields
    // devLog('getContentValueForField', field)
    if (field.value !== "") {
        switch (field.format) {
            case 'extdata':
            case 'extdatan':
            case 'text':
            case 'agenda':
            case 'textarea':
            case 'date':
            case 'birthday':
            case 'phone':
            case 'sms':
            case 'time':
            case 'smail':
            case 'mail':
            case 'formula':
            case 'sign':
            case 'url':
            case 'qrcode':
            case 'now':
            case 'localis':
            case 'indice':
                return field.value;
            case 'password':
                return "******"
            case 'list':
            case 'single-select':
            case 'free-single-select':
            case 'multi-select':
            case 'free-multi-select':
                // console.log('field value - ', field.format, ': ', field.value);
                // if (field.value?.length > 0) return field.value.join(' , ');
                // else return ''
                return field.value.join(' , ');
            case 'single-table':
            case 'free-single-table':
            case 'multi-table':
            case 'free-multi-table':
                return field.value.map(item => item.text + ' : ' + item.value).join(' , ')
            case 'tablemulti':
            case 'tablemultin':
            case 'smailvalidmulti':
            case 'document':
                fields = getTablemultiFieldsLabels(field, mode)
                let values = ''
                let lines = isDefined(field.options.lines) ? field.options.lines : field.options

                // devLog('TM Field', field)
                let rows = isDefined(field.value?.list) ? field.value.list : field.value
                // devLog('rows', rows, '_fields', fields)

                if (isDefined(rows) && Array.isArray(rows)) {
                    let jsRows = `<tbody id="body-${field.label}">`
                    rows.forEach((row, rindex) => {
                        let jsRow = row.hasOwnProperty('recap')
                            ? `<tr id={"tr-" + ${rindex}} style="background-color: #ffc4b4;"><td> T </td>`
                            : `<tr><td> ${rindex + 1} </td>`
                        if (Object.values(row).length > 0) {
                            lines.forEach(col  => {
                                let alignment = col.rowAlignment || "center"
                                let cellStyle
                                switch (alignment) {
                                    case 'left': cellStyle = "padding-left: 4px;"
                                    break
                                    case 'right': cellStyle = "margin-left: auto;text-align: right;"
                                    break
                                    default: cellStyle = "margin-left: auto; margin-right: auto; text-align: center;"
                                    break
                                }
                                let key = col.id
                                if ((mode === 'plugcode-complete' && col.encodable === false) || (mode === 'plugcode-consult' && col.visible === false)) {}
                                else if (key !== 'recap' && key !== 'locked') {
                                    let val = row[key]
                                    if (val !== '') {
                                        let _val
                                        if (typeof val === "object") { _val = val.join(', ') }
                                        else { _val = val }
                                        if (!isDefined(_val)) { _val = '' }
                                        jsRow += `<td style="${cellStyle}">` + _val + '</td>'
                                    } else jsRow += '<td> </td>'
                                }
                            })
                            jsRow += '</tr>'
                            jsRows += jsRow
                        }
                    })
                    values += jsRows + '</tbody>'
                }
                if (values !== '') return '\n<table style="border-color: #e0e0e0;" border="1" cellspacing="0" width="100%">' + fields + values + '</table><br/>';
                else return ''
            case 'boolean':
                return field.value == "1" ? "Y" : "N";
            case 'numeric':
                if (!isNaN(field.value)) {
                    return field.value;
                } else return "";
            case 'plugform':
                return field?.value?.value
            case 'glocalis':
                try {
                    let tmp = JSON.parse(field?.value);
                    return '\nAdr: ' + tmp.address + '\nLat: ' + tmp.latitude + '\nLong: ' + tmp.longitude;
                } catch (e) {
                    return "";
                }
            case 'qrcodeint':
                try {
                    let value = field?.value;
                    let qrcodeintComment = typeof value.comment !== "undefined" ? value.comment : "";
                    let qrcodeintCode = typeof value.code !== "undefined" ? value.code : "";
                    if (qrcodeintComment != "" && qrcodeintCode != "") {
                        let url = ApplicationConfig.templateUrl + '?code=' + qrcodeintCode;
                        return '\n' + qrcodeintComment + '\n' + url;
                    } else if (qrcodeintComment != "") {
                        return '\n' + qrcodeintComment;
                    } else if (qrcodeintCode != "") {
                        let url = ApplicationConfig.templateUrl + '?code=' + qrcodeintCode;
                        return '\n' + url;
                    } else return "";

                } catch (e) {
                    return "";
                }

            default: return "";
        }
    } else return "";
};

const getTablemultiFieldsLabels = (field, mode) => {
    // devLog('get tm labels', field, mode)
    let modelFields = field.options.lines === undefined ? field.options : field.options.lines
    if (isDefined(modelFields)) {
        let fieldStr = ''
        modelFields.forEach(col => {
            if ((mode === 'plugcode-complete' && col.encodable === false) || (mode === 'plugcode-consult' && col.visible === false)) { devLog('Hidden') }
            else { fieldStr += (`<th>${col.label}</th>`) }
        })
        return '<thead style="font-weight: 400;padding-top: 5px;padding-bottom: 5px;padding-left: 8px;padding-right: 8px;background-color: #B9E5E4;"><th>N°</th>' + fieldStr + '</thead>'
    } else return null
}


function getCellFormat (cellId, columns) {
    // devLog('cellId et columns', cellId, columns)
    if (cellId) {
        let column = columns.find(obj => {
            return obj.id.toString() === cellId.toString()
        })
        return column
    }
}

export const handleCheckHasRecap = (options) => {
    let hasRecap = false
    if (isDefined(options)) {
        return ((Array.isArray(options?.lines) && options.lines.some(col => col.recap === true)) || (Array.isArray(options) && options.some(col => col.recap === true)))
    }
    return hasRecap
}

export const getTotalForSpecificColumnIndex = (rows, id, columns) => {
// previously : getTotalForSpecificColumnIndex
    if (isDefined(rows)) {
        let total
        let format = getCellFormat(id, columns)
        if (format.format === "time" || format.format === "duration" || (typeof rows[0] !== "undefined" && isNaN(Number(rows[0][id])))) {
            total = ''
        } else {
            total = 0;
            rows.forEach((row, index) => {
                if (row[id] && row?.recap !== true) {
                    total += Number(row[id]);
                }
            });
        }
        return total
    } else return 0
}

export const strReplaceContentField = (content, fields, model, plugnote, user, pwCode, mode) => {
    let _fields = fields.options?.lines !== undefined ? fields.options.lines : fields
    let fieldsCopy = [..._fields]
    let copy = content;
    copy = copy.replaceAll('***', '{{')
    copy = copy.replaceAll('**', '}}')
    copy = copy.replaceAll('\n', '<br/>')

    // devLog('body after', copy)

    if (fieldsCopy.length > 0 && fieldsCopy[0].hasOwnProperty('hash')) {
        fieldsCopy = fieldsCopy.filter((value, index) => index !== 0).filter(Boolean);
    }

    fieldsCopy.forEach((field, index) => {
        try {
            let fieldInsert = "{{" + field.title.substr(0, 18) + '__field' + field.id.toString() + '}}'
            let fieldId = "{{field" + field.id.toString() + "}}" // legacy version
            if (copy.includes(fieldId)) {
                copy = copy.replaceAll(fieldId, getContentValueForField(field, mode))
            }
            if (copy.includes(fieldInsert)) {
                copy = copy.replaceAll(fieldInsert, getContentValueForField(field, mode))
            }

            // let searchRegex = "{{field" + field.id.toString()+"}}"
            // let searchRegexLabelled = "{{" + field.title.substr(0, 18) + '__field' +  field.id.toString() + '}}';

            // let regex = new RegExp( "{{field" + field.id.toString()+"}}",'g');
            // if(copy.search(searchRegex)){
            //     copy = copy.replaceAll(regex, getContentValueForField(field, model));
            // }

            //@gabriel l'erreur vient surement quand tu mets un titre + (ex : quelqueschose).
            // try {
            //     let regexLabelled = new RegExp(searchRegexLabelled,'g');
            //     if(copy.search(regexLabelled)){
            //         copy = copy.replaceAll(searchRegexLabelled, getContentValueForField(field, model));
            //     }
            // }catch (e){
            // console.log(e);
            //     // copy = copy.replaceAll(searchRegexLabelled, getContentValueForField(field, model));
            // }        let fieldInsert = "{{" + field.title.substr(0, 18) + '__field' +  field.id.toString() + '}}'
            //         let fieldId = "{{field" + field.id.toString()+"}}" // legacy version
            //         if(copy.includes(fieldId)) {
            //             copy = copy.replaceAll(fieldId, getContentValueForField(field, mode))
            //         }
            //         if(copy.includes(fieldInsert)) {
            //             copy = copy.replaceAll(fieldInsert, getContentValueForField(field, mode))
            //         }
            //
            //         // let searchRegex = "{{field" + field.id.toString()+"}}"
            //         // let searchRegexLabelled = "{{" + field.title.substr(0, 18) + '__field' +  field.id.toString() + '}}';
            //
            //         // let regex = new RegExp( "{{field" + field.id.toString()+"}}",'g');
            //         // if(copy.search(searchRegex)){
            //         //     copy = copy.replaceAll(regex, getContentValueForField(field, model));
            //         // }
            //
            //         //@gabriel l'erreur vient surement quand tu mets un titre + (ex : quelqueschose).
            //         // try {
            //         //     let regexLabelled = new RegExp(searchRegexLabelled,'g');
            //         //     if(copy.search(regexLabelled)){
            //         //         copy = copy.replaceAll(searchRegexLabelled, getContentValueForField(field, model));
            //         //     }
            //         // }catch (e){
            //             // console.log(e);
            //         //     // copy = copy.replaceAll(searchRegexLabelled, getContentValueForField(field, model));
            //         // }
        }
        catch (e) {
            //console.log(e);
        }
    })
    //! champs modifiés dans le setup après la composition du mail
    // remplacé selon le label ou selon le numéro du champs
    //Si les deux valeurs changent, impossible de garantir la correction
    if (copy.includes("__field")) {
        let inserts = []
        let copyArray = copy.split('{{')
        copyArray.forEach(entry => {
            let insert = entry.split('}}')[0]
            let id = insert.split('__field')[1]
            let label = insert.split('__field')[0]
            if (id !== undefined) inserts.push({ id: id, insert: insert, label: label })
        })
        // console.log('inserts', inserts)
        inserts.forEach((value) => {
            let field = fieldsCopy.find(insert => insert.title.includes(value.label))
            // console.log('original', field)
            if (field !== undefined) {
                copy = copy.replaceAll('{{' + value.insert + '}}', getContentValueForField(field, mode))
            } else {
                let index = parseInt(value.id)
                // console.log('wrong number', fields[index]);
                copy = copy.replaceAll('{{' + value.insert + '}}', getContentValueForField(fieldsCopy.find(field => field.id === index)))
            }
        })
    }

    //  {{PF Key}} {{PF}} {{User}} {{Date Modif}} {{PN}} {{PN-}} {{User Gsm}}

    let searchRegexPFKey = "{{PF Key}}";
    let regexPFKey = /{{PF Key}}/g;
    if (copy.search(searchRegexPFKey)) {
        copy = copy.replaceAll(regexPFKey, model?.clef || "");
    }

    let searchRegexPF = "{{PF}}"
    let regexPF = /{{PF}}/g
    if (copy.search(searchRegexPF)) {
        copy = copy.replaceAll(regexPF, model?.label || "");
    }

    let searchRegexUser = "{{User}}"
    let regexUser = /{{User}}/g
    if (copy.search(searchRegexUser)) {
        copy = copy.replaceAll(regexUser, user?.pseudo || "");
    }

    let searchRegexDateModif = "{{Date Modif}}"
    let regexDateModif = /{{Date Modif}}/g
    if (copy.search(searchRegexDateModif)) {
        if (plugnote !== null) {
            let date = typeof plugnote.moddate !== "undefined" ? moment(plugnote.moddate).format('YY-MM-DD') : ""
            copy = copy.replaceAll(regexDateModif, date);
        } else copy = copy.replaceAll(regexDateModif, "");
    }

    let searchRegexPn = "{{PN}}"
    let regexPn = /{{PN}}/g
    if (copy.search(searchRegexPn)) {
        if (plugnote !== null) {
            copy = copy.replaceAll(regexPn, "1-" + plugnote?.userindex || "");
        } else copy = copy.replaceAll(regexPn, "");
    }

    let searchRegexPw = "{{PW}}"
    let regexPw = /{{PW}}/g
    if (copy.search(searchRegexPw)) {
        if (pwCode !== null && pwCode !== "") {
            copy = copy.replaceAll(regexPw, i18n.t('common:smail.pwcode-info', { pw: pwCode }));
        } else copy = copy.replaceAll(regexPw, "");
    }

    let searchRegexPwUrl = "{{PW Url}}"
    let regexPwUrl = /{{PW Url}}/g
    if (copy.search(searchRegexPwUrl)) {
        if (pwCode !== null && pwCode !== "") {
            copy = copy.replaceAll(regexPwUrl, ApplicationConfig.templateUrl + '?code=' + pwCode);
        } else copy = copy.replaceAll(regexPwUrl, "");
    }

    let searchRegexPn_ = "{{PN-}}"
    let regexPn_ = /{{PN-}}/g
    if (copy.search(searchRegexPn_)) {
        copy = copy.replaceAll(regexPn_, getCodePnPpCodeTemplate(plugnote));
    }

    let sarchRegexUserGsm = "{{User Gsm}}"
    let regexUserGsm = /{{User Gsm}}/g
    if (copy.search(sarchRegexUserGsm)) {
        copy = copy.replaceAll(regexUserGsm, user?.mobile || "");
    }
    return copy.replaceAll('}}', '')
}

export const getCodePnPpCodeTemplate = (plugnote) => {
    // devLog('get pncode', plugnote)
    if (!isDefined(plugnote)) return ""
    const { publicpncode } = plugnote

    let codePn = 'PN';

    switch (publicpncode) {
        case 0:
            codePn = 'PP';
            break;
        case 1:
            codePn = 'PN';
            break;
        case 2:
            codePn = 'PE';
            break;
        case 3:
            codePn = 'PS';
            break;
        default:
            break;
    }

    let pncode = plugnote.codepncode ? `${codePn}-${moment(plugnote.creationdate).format('YYMMDD')}-${plugnote.codepncode}` : null

    if (pncode !== null) {
        return pncode
    } else {
        return ""
    }
};

export const _getCodePFCode = (plugform) => {
    return plugform !== null && plugform.codepfcode != null ? 'PF-' + moment(plugform.creationdate).format('YYMMDD') + '-' + plugform.codepfcode : '';
};

export const parseModel = (model) => {
    let _model = {}
    let _fields = (model)
    Object.keys(_fields).forEach((key) => {
        if (key.includes('label')) {
            let nb = key.charAt(5)
            if (!isNaN(parseInt(key.charAt(6)))) nb = nb + key.charAt(6)
            _model['field' + nb] = _fields[key]
        }
    })
    return _model
};

const parseOperator = (operator) => {
    switch (operator) {
        case 'contains':
            return " .x.. ";
        case 'isNotEmpty':
            return " ≠ Ø ";
        case 'isEmpty':
            return " = Ø ";
        case 'is':
        case 'equals':
        case '=':
        case 'on':
            return " = ";
        case '!=':
            return " ≠ ";
        case '<=':
            return " ≤ ";
        case '<':
        case 'before':
        case "onOrBefore":
            return " < ";
        case '>':
        case 'after':
            return " > ";
        case '>=':
        case 'onOrAfter':
            return " ≥ ";
        case 'startsWith':
            return " x.. ";
        case 'endsWith':
            return " ..x ";
        default:
            return " ? ";
    }
};

export const parseFilterParameters = (fields, options, model) => {
    let entries = []
    if (options !== null && fields !== null && Array.isArray(options)) {
        options.forEach((index) => {
            let parameters = ""
            let numero = 0
            numero = numero + index.columnField.charAt(5)
            if (index.columnField.length >= 5) {
                if (!isNaN(parseInt(index.columnField.charAt(6)))) numero = index.columnField.charAt(5) + index.columnField.charAt(6)
            }
            if (fields[index.columnField] === undefined) {
                parameters = parameters + index.columnField;
                parameters = parameters + parseOperator(index.operatorValue)
            }
            else if (index.columnField !== 'view') {
                parameters = parameters + numero + ':'
                parameters = parameters + parseOperator(index.operatorValue)
            }
            if (index?.value !== undefined) {
                parameters = parameters + index.value
            }
            entries.push(parameters)
            if (options.indexOf(index) !== options.length - 1) {
                parameters = parameters + '; '
            }
        })
    }
    let stringOptions = ''
    entries.sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
        .forEach(option => {
            stringOptions = stringOptions + option
            if (entries.indexOf(option) !== entries.length - 1) {
                stringOptions = stringOptions + '; '
            }
        })
    return stringOptions
}

export const fieldIsVisible = (field, props) => {
    // console.log('empty?', field);
    // console.log('visible?', props);
    let visible = false

    //? permet d'afficher tous les champs si Display empty fields est coché
    //? (dans le setup du plugform ouvert dans la modale)
    if (props.visibleAll && !props.mode?.includes('plugcode-consult')) return true

    switch (field.format) {
        case "agenda":
        case "birthday":
        case "boolean":
        case "date":
        case "extdata":
        case "extdatan":
        case "glocalis":
        case "localis":
        case "mail":
        case "now":
        case "password":
        case "phone":
        case "qrcode":
        case "qrcodeint":
        case "sign":
        case "smail":
        case "textarea":
        case "text":
        case "time":
        case "url":
            return field.value !== "" && field.value !== undefined;
        case "formula":
        case "numeric":
            return field.value !== "" && field.value !== undefined && !isNaN(field.value);
        case "free-multi-select":
        case "free-multi-table":
        case "free-single-select":
        case "free-single-table":
        case "list":
        case "multi-table":
        case "multi-select":
        case "single-select":
        case "single-table":
        case "tablemulti":
            return field.value !== undefined && field.value.length > 0
        case "document":
        case "tablemultin":
            let empty = true
            Object.keys(field.value).forEach(key => {
                if (key === 'value' && field.value.value !== '') empty = false
                if (key === 'list' && field.value.list.length > 0) empty = false
            })
            return !empty
        case "smailvalidmulti":
            let vide = true
            if (field.value === "") return false
            else {
                let smailValue = typeof field.value === 'string' ? JSON.parse(field.value) : field.value
                if (smailValue.hasOwnProperty("value")) vide = smailValue.value.length === 0
                if (smailValue.hasOwnProperty("list") && vide) vide = smailValue.list.length === 0
            }
            return !vide
        default:
            let tmpValue = typeof field.value !== "undefined" && field.value !== null ? field.value.toString() : "";
            let length = field.format === "numeric" && tmpValue === "NaN" ? 0 : tmpValue.length;
            if (typeof tmpValue === "string" && Object(tmpValue) && tmpValue !== null && field.format === "plugform") {
                let tmp = field.value;
                if (tmp.hasOwnProperty("value")) length = tmp.value.length;
                else length = 0;
            }
            visible = ((props.visibleAll === false && length > 0) || props.visibleAll === true)
            return visible
    }
}

export const getNonEmptyRows = (rows) => {
    let isArray = Array.isArray(rows)
    let _rows = isArray ? rows : rows.list
    // console.log('got rows', rows, _rows);
    let nonEmptyRows = []
    if (_rows.length > 0) {
        _rows.forEach((row, rindex) => {
            let valArray = Object.values(row)
            // console.log('valArray', valArray);
            if (Object.keys(row).includes('recap')) { nonEmptyRows.push(row) }
            else if (valArray.some(value => (isDefined(value) && value !== '0' && value !== 0 && value !== '0:00' && value?.length !== 0))) { nonEmptyRows.push(row) }
            // else console.log(`row #${rindex} was empty`);
        })
    }
    if (nonEmptyRows.length > 0) return isArray ? nonEmptyRows : { list: nonEmptyRows, value: rows.value };
    else return isArray ? '' : { list: '', value: rows.value }
}

export const isPrintable = (field, mode) => {
    let print = true
    if (field.showOnPdf === false) { return false }
    if ((field.encodable === false || field.editable === false) && mode.includes('plugcode')) { print = false }
    if (field.visible === false && mode.includes('plugcode')) { print = false }
    return print
}

export const isVisibleInCSV = (field, mode) => {
    let visible = true
    if ((field.encodable === false || field.editable === false) && mode.includes('plugcode-complete')) { visible = false }
    if (field.visible === false && mode.includes('plugcode-consult')) { visible = false }
    return visible
}

export const getLabelWithSpecialChar = (label) => {
    let str = label
    if (label.includes('~')) {
        let array = label.split('~').map(entry => entry?.trim())
        str = array.join(' ')
    }
    if (str.includes('#')) {
        let array = label.split('#').map(entry => entry?.trim())
        str = array.join(' ')
    }
    return str
}

export const isEmptyField = (value) => {
    if (!isDefined(value)) {
        return true;
    }
    if (Array.isArray(value) || typeof value === 'string') {
        // console.log('Array.isArray(value) || typeof value === string', value.length === 0);
        return value.length === 0;
    } else if (typeof value === 'number') {
        return false;
    } else {
        let valArray = Object.values(value);
        // console.log('Object', valArray.some(row => !isEmptyField(row)));
        return !valArray.some(row => !isEmptyField(row));
    }
}

export const getFieldDefaultValue = (format, options, value) => {
    // devLog('get default val params', format, options, value)
    if (isDefined(options?.setup) && isDefined(options?.setup?.defaultValue)) {
        let _default = options.setup.defaultValue
        switch (format) {
            case 'presel':
                return options.multiselect ? _default : _default[0] || ''
            case 'multi-select':
            case 'free-multi-select':
            case 'single-select':
            case 'free-single-select':
                // _default.forEach(entry => devLog(`entry -${entry}- || ${entry.length}`))
                return _default.filter(entry => entry?.length > 0)
            case 'multi-table':
            case 'free-multi-table':
            case 'single-table':
            case 'free-single-table':
                return _default.length > 0 ? _default : ''
            case 'numeric':
            case 'smail':
            case 'text':
            case 'textarea':
            case 'url':
                return _default
            case 'qrcode':
                return _default + ';'
            // case '':
            // case '':
            // break;
            default:
                return value
        }
    } else {
        return value;
    }
}

export function createEmptyRow(columns, useCount, fieldId) {
    // devLog('createEmptyRow columns', columns)
    let data = {}
    columns.forEach(col => {
        if (col.format === 'file-refid') {
            let { countRefId, setCountRefId } = useCount
            let tmpCountRefId = countRefId.count;
            tmpCountRefId = Number(tmpCountRefId) + 1;
            data[col.id] = zeroPad(fieldId, 2) + '-' + zeroPad(tmpCountRefId.toString(), 3);
            setCountRefId(previous =>({ ...previous, count : tmpCountRefId.toString()}));
        } else {
            data[col.id] = ''
        }
    })
    return data
}

export const handleCleanEmptyRows = (rows) => {
    if (isDefined(rows) && rows.length > 0) {
        let cleaned = [...rows]
        let recap = cleaned.find(entry => entry?.recap === true)
        if (recap) { cleaned.pop() }
        let lastRow = cleaned[cleaned.length - 1]
        if (!Object.values(lastRow).some(entry => entry !== '')) {
            cleaned.pop()
        }
        return recap ? [...cleaned, recap] : cleaned
    } else return []
}

export const getAllDefaultRows = (columns, defaultRows) => {
    return defaultRows.map((_, index) => {
        return populateRowWithDefaultValues(columns, defaultRows, index, createEmptyRow(columns))
    })
}

export const isEditableField = (field, mode) => {
    if (isDefined(mode)) {
        return !(mode.includes('plugcode') && field?.encodable === false)
    } else { return true }
}

export const isHiddenColumn = (column, mode) => {
    return (!isDefined(column) || column.hidden || (isDefined(column.encodable) && mode === 'plugcode-complete' && column.encodable === false) || (isDefined(column.visible) && mode === 'plugcode-consult' && column.visible === false))
}

export const isEditableColumnMode = (field, col, mode) => {
    if (isDefined(mode)) {
        return !(mode.includes('plugcode-complete') && col?.encodable === false)
    } else { return true }
}

export const isEditableColumnFormat = (col) => {
    // devLog('isEditableColumn', col)
    if (isDefined(col)) {
        return !(
            col.id === 1000 || col.id === 1003 ||
            col.format === 'formula' ||
            // col.format === 'file-source' ||
            col.format === 'file-name-text' ||
            // col.format === 'file-comment-text' ||
            col.format === 'file-type-text' ||
            col.format === 'file-size-num' ||
            col.format === 'file-create-date' ||
            col.format === 'file-refid' ||
            // col.format === 'file-uri' ||
            // col.format === 'intdatamaj' ||
            col.format === 's33data'
        )
    } else { return false }
}

export const populateRowWithDefaultValues = (columns, defaultRows, index, row) => {
    // devLog('populateRowWithDefaultValues columns, row', columns, row)
    // devLog('populateRowWithDefaultValues defaultRows', defaultRows)
    if (defaultRows?.length > 0) {
        let defaultRow
        if (defaultRows.length > 1) {
            if (index + 1 < defaultRows.length) {
                defaultRow = defaultRows[index]
            } else {
                defaultRow = defaultRows.slice(-1)[0]
            }
        } else { defaultRow = defaultRows[0] }
        Object.keys(defaultRow).forEach(key => {
            let col = columns.find(entry => entry.id.toString() === key)
            if (key === 'locked') {
                if (defaultRow.locked) { row['locked'] = true }
            } else if (isDefined(col) && isEditableColumnFormat(col)) {
                row[key] = defaultRow[key]?.value || ''
            } else if (!isDefined(row[key])) {
                row[key] = ''
            }
        })
        return row
    } else return row
}

export const getFormatDefaultValue = (format) => {
    switch (format) {
        case "select":
            return []
        case "date":
        case "duration":
        case "mail":
        case "numeric":
        case "time":
        case "text":
        default:
            return ""
    }
}

export const isRowLimitReached = (rowLimit, rows) => {
    // devLog('isRowLimit params', rowLimit, rows)
    if (!isDefined(rowLimit) || rowLimit === 0) {
        return false
    }
    if (rows.length > 0) {
        let lastRow = rows[rows.length - 1]
        let tableLength = lastRow.hasOwnProperty('recap') ? rows.length - 1 : rows.length
        return tableLength === rowLimit
    } else { return false }
}

export const isEditableDefaultValue = (field, id, rindex, mode, isPw) => {
    if (id === 1000 || id === 1003) { return false }
    let editable = true
    // devLog('isFieldEditable field', field, id, rindex)
    if (field?.options?.setup?.hasOwnProperty('defaultRows')) {
        let defaultRow = field?.options?.setup?.defaultRows[rindex] || field?.options?.setup?.defaultRows[field?.options?.setup?.defaultRows.length - 1]
        if (isDefined(defaultRow) && typeof defaultRow[id] === "object") {
            // devLog('isFieldEditable defaultRow', defaultRow[id])
            if (defaultRow[id].options.editable === false) { return false }
            else if (mode.includes('plugcode')) {
                if ((isDefined(isPw) && isPw && !defaultRow[id].options.editablePw) || (!defaultRow[id].options.editablePf && !isDefined(isPw))) { return false }
            }
        }
    }
    return editable
}

export const fieldsWithDefaultValue = ApplicationConfig.devMode ? ['qrcode', 'smail', 'text', 'textarea', 'url', 'numeric'] : ['qrcode', 'smail', 'url', 'numeric']

export const formatsWithOptions = [
    'boolean',
    'date',
    'document',
    'extdata', 'extdatan',
    'formula',
    'indice',
    'intdatamaj',
    'mail',
    'numeric',
    'phone', 'tel',
    'sign',
    'smail', 'smailvalidmulti',
    'time',
    'tablemulti', 'tablemultin',
    'plugform', 'pluglink',
    'qrcode', 'qrcodeint',
    'select',
    'table',
    'text', 'textarea',
    'url'
]

// Date & Time
const monthLength = (month, bissextile) => {
    if (month === 2) {
        if (bissextile === true || bissextile === undefined) return 29;
        else return 28
    }
    if (month % 2 === 0 && month < 7) return 30
    if (month > 8 && month % 2 !== 0) return 30
    return 31
}

const isBissextile = (year) => {
    if (year % 4 === 0 && year % 100 !== 0) return true
    if (year % 400 === 0) return true
    return false
}

export const isInvalidDate = (mask, value) => {
    if (isDefined(mask) && isDefined(value)) {
        switch (mask) {
            case 'DD/MM':
                return parseInt(value.slice(0, 2)) > monthLength(parseInt(value.slice(3, 5))) || parseInt(value.slice(3, 5)) >= 13
            case 'DD/MM/YY':
                return parseInt(value.slice(0, 2)) > monthLength(parseInt(value.slice(3, 5))) || parseInt(value.slice(3, 5)) >= 13
            case 'MM/YYYY':
                return parseInt(value.slice(0, 2)) >= 13
            case 'DD/MM/YYYY':
                return parseInt(value.slice(0, 2)) > monthLength(parseInt(value.slice(3, 5)), isBissextile(parseInt(value.slice(6, 8)))) || parseInt(value.slice(3, 5)) >= 13
            default:
                return false
        }
    } else return true
}

export const isInvalidTime = (mask, value) => {
    if (isDefined(mask) && isDefined(value)) {
        switch (mask) {
            case 'HH:MM':
                return parseInt(value.slice(3, 2)) >= 60
            case 'HH H MM':
                return parseInt(value.slice(0, 2)) >= 24 || parseInt(value.slice(5, 7)) >= 60
            case 'HH:MM (24H)':
                return parseInt(value.slice(0, 2)) >= 24 || parseInt(value.slice(3, 5)) >= 60
            case 'HH:MM (15min)':
                return parseInt(value.slice(0, 2)) >= 24 || parseInt(value.slice(3, 5)) >= 60
            case 'HH:MM:SS':
                return parseInt(value.slice(0, 2)) >= 24 || parseInt(value.slice(3, 5)) >= 60 || parseInt(value.slice(6, 8)) >= 60
            case 'MM:SS (60min)':
                return parseInt(value.slice(0, 2)) >= 60 || parseInt(value.slice(3, 5)) >= 60
            case 'MM:SS':
                return parseInt(value.slice(3, 5)) >= 60
            default:
                return false
        }
    } else return true
}

export const getCurrentDateTimeByMask = (mask, column) => {
    if (isDefined(mask)) {
        let presentTime = new Date()
        let nowVal = ''
        let day = presentTime.getDate() < 10 ? '0' + presentTime.getDate() : presentTime.getDate()
        let month = presentTime.getMonth() + 1 < 10 ? '0' + (presentTime.getMonth() + 1) : presentTime.getMonth() + 1
        let year = presentTime.getFullYear().toString()
        let hour = presentTime.getHours() > 9 ? presentTime.getHours() : '0' + presentTime.getHours()
        let minutes = presentTime.getMinutes() > 9 ? presentTime.getMinutes() : '0' + presentTime.getMinutes()
        let seconds = presentTime.getSeconds() > 9 ? presentTime.getSeconds() : '0' + presentTime.getSeconds()

        switch (mask) {
            case 'DD/MM':
                nowVal = day + '/' + month
                break
            case 'DD/MM/YY':
                nowVal = day + '/' + month + '/' + year.slice(2, 4)
                break
            case 'MM/YYYY':
                nowVal = month + '/' + year
                break
            case 'DD/MM/YYYY':
                nowVal = day + '/' + month + '/' + year
                break
            case '99':
                nowVal = column?.format === 'time' ? (hour + ':' + minutes) : (day + '/' + month + '/' + year)
                break
            case 'HH:MM':
            case 'HH:MM (24H)':
            case 'HH:MM (15min)':
            case 'Free Text':
                return nowVal = column?.format === 'time' ? (hour + ':' + minutes) : (day + '/' + month + '/' + year)
            case 'HH H MM':
                return nowVal = hour + ' H ' + minutes
            case 'HH:MM:SS':
                return nowVal = hour + ':' + minutes + ':' + seconds
            case 'MM:SS':
            case 'MM:SS (60min)':
                return nowVal = minutes + ':' + seconds
            default:
                break
        }
        return nowVal
    }
}

export function getTimeStringFromDateTime(date, mask) {
    let timeString = ""
    if(!isNull(date)) {
        let hours = addZeroBefore(date.getHours())
        let minutes = addZeroBefore(date.getMinutes())
        let seconds = addZeroBefore(date.getSeconds())
        switch (mask) {
            case "HH:MM (24H)":
            case "HH:MM (15min)":
            case "Free Text":
                timeString = hours + ":" + minutes
                break
            case "HH:MM:SS":
                timeString = hours + ":" + minutes + ":" + seconds
                break
            case "MM:SS (60min)":
                timeString = minutes + ":" + seconds
                break
            case "HH H MM":
                timeString = hours + " H " + minutes
                break
            default :
                break
        }
        return timeString
    }
}

export const roundQuarter = (timeStringValue) => {
    let tmp = timeStringValue.split(':')
    let hours = tmp[0]
    let minutes = tmp[1]
    let roundedHours = Number(hours)
    let roundedMinutes = minutes
    minutes = Number(minutes)
    if (minutes > 59) roundedMinutes = minutes.toString()

    if (roundedHours <= 23) {
        if (minutes >= 0 && minutes <= 7) {
            roundedMinutes = '00'
        } else if (minutes >= 8 && minutes <= 21) {
            roundedMinutes = '15'
        } else if (minutes >= 22 && minutes <= 37) {
            roundedMinutes = '30'
        } else if (minutes >= 38 && minutes <= 51) {
            roundedMinutes = '45'
        } else if (minutes >= 52 && minutes <= 59) {
            roundedMinutes = '00'
            switch (roundedHours) {
                case 23 :
                    roundedHours = '00'
                    break
                default:
                    roundedHours = roundedHours + 1
                    break
            }
        }
    }
    if (roundedHours < 10) roundedHours = addZeroBefore(roundedHours)
    roundedHours = roundedHours.toString()
    return {roundedHours, roundedMinutes}
}

export function getPickerMaskDetails(mask) {
    const [partStart, partEnd] = mask.split('(')
    let maskFormat = partStart.trim()
    let maskFormatOption = partEnd
    if(maskFormatOption !== undefined) maskFormatOption = maskFormatOption.replace(")","")
    return {maskFormat: maskFormat, maskFormatOption: maskFormatOption}
}

export function getDateTimeFromValue (fieldValue, mask) {
    let dateTimeFromValue
    let splitValue
    let hours
    let minutes
    let seconds
    if(mask === "HH H MM") fieldValue = fieldValue.replace('H', ':')
    if(fieldValue.includes(':')) {
        splitValue = fieldValue.split(':')
        hours = splitValue[0]
        minutes = splitValue[1]
        seconds = splitValue[2]
        if(!isUndefined(mask)) mask = mask.toUpperCase()
    }
    switch (mask) {
        case 'HH:MM (24H)':
        case 'HH H MM':
            dateTimeFromValue = new Date(null, null, null, hours, minutes)
            break
        case 'HH:MM:SS':
            dateTimeFromValue = new Date(null, null, null, hours, minutes, seconds)
            break
        case 'MM:SS (60MIN)':
            minutes = splitValue[0]
            seconds = splitValue[1]
            dateTimeFromValue = new Date(null, null, null, null, minutes, seconds)
            break
        case 'HH:MM (15MIN)':
            if(hours > 23 || minutes > 59 || hours === NaN || minutes === NaN) {
                dateTimeFromValue = null
            } else {dateTimeFromValue = new Date(null, null, null, hours, minutes)}
            break
        case 'FREE TEXT':
            dateTimeFromValue = fieldValue
            break
        default:
            break
    }
    return dateTimeFromValue
}

export const fieldsWithColumns = ['document', 'smailvalidmulti', 'tablemulti', 'tablemultin' ]

export const compareArrays = (a, b) => {
    if (!isDefined(a) || !isDefined(b)) { return false }
    const arrayA = JSON.stringify(a)
    const arrayB = JSON.stringify(b)
    return arrayA === arrayB
}

export const summarizeDefaultValue = (format, defaultValue, t) => {
    switch (format) {
        case 'table':
            return (
                <div>
                    <div>{t('helper.default-value')}<br/></div>
                    <ul>
                        {defaultValue.map(entry => <li key={entry.text} style={{ color: "white" }}><span style={{ fontWeight: 'bold' }}>{entry.text} : </span>{entry.value}</li>)}
                    </ul>
                </div>
            )
        default:
            return (
                <div><div>{t('helper.default-value')}<br/></div>{defaultValue}</div>
            )
    }
}

/**
 * A modifier et activer plus tard , tjs en cours d'analyse
 * @param field
 * @param mode
 * @param header
 * @param width
 * @returns {string}
 */

export const getContentTableValueForFieldTpl23 = (field, mode, header, width) => {
    let fields
    // devLog('getContentValueForField', field)
    if (field.value !== "") {
        switch (field.format) {
            case 'tablemulti':
            case 'tablemultin':
            case 'smailvalidmulti':
            case 'document':
                fields = header ? getTablemultiFieldsLabelsTpl23(field, mode, width) : '';
                let values = ''
                let lines = isDefined(field.options.lines) ? field.options.lines : field.options

                // devLog('TM Field', field)
                let rows = isDefined(field.value?.list) ? field.value.list : field.value
                // devLog('rows', rows, '_fields', fields)

                if (isDefined(rows) && Array.isArray(rows)) {
                    let jsRows = `<tbody id="body-${field.label}">`
                    rows.forEach((row, rindex) => {
                        let jsRow = row.hasOwnProperty('recap')
                            ? `<tr id={"tr-" + ${rindex}} style="background-color: #ffc4b4;"><td> T </td>`
                            : `<tr><td style="border: 1px solid #e0e0e0; border-collapse: collapse;"> ${rindex + 1} </td>`
                        if (Object.values(row).length > 0) {
                            lines.forEach(col  => {
                                let defaultStyle = "border: 1px solid #e0e0e0; border-collapse: collapse;";
                                let alignment = col.rowAlignment || "center"
                                let fontSize = col.fontSize || 11;
                                let colWidth = col.colWidth || null;
                                if(!isNull(colWidth)){
                                    //defaultStyle += "width:"+(colWidth - (colWidth  *  0.10))+"px;"
                                    defaultStyle += "width:"+colWidth+"px;"
                                }
                                let cellStyle
                                switch (alignment) {
                                    case 'left': cellStyle = "padding-left: 4px;"
                                        break
                                    case 'right': cellStyle = "margin-left: auto;text-align: right;"
                                        break
                                    default: cellStyle = "margin-left: auto; margin-right: auto; text-align: center;"
                                        break
                                }
                                let key = col.id
                                if ((mode === 'plugcode-complete' && col.encodable === false) || (mode === 'plugcode-consult' && col.visible === false)) {}
                                else if (key !== 'recap' && key !== 'locked') {
                                    let val = row[key]
                                    if (val !== '') {
                                        let _val
                                        if (typeof val === "object") { _val = val.join(', ') }
                                        else { _val = val }
                                        if (!isDefined(_val)) { _val = '' }
                                        jsRow += `<td style="${defaultStyle + cellStyle  + ' font-size:'+fontSize}">` + _val + '</td>'
                                    } else jsRow += '<td> </td>'
                                }
                            })
                            jsRow += '</tr>'
                            jsRows += jsRow
                        }
                    })
                    values += jsRows + '</tbody>'
                }
                if (values !== '') return '<table style="border: 1px solid #e0e0e0; border-collapse: collapse; width:'+width+'; max-width:'+width+';">' + fields + values + '</table>';
                else return ''
            default: return "";
        }
    } else return "";
};


const getTablemultiFieldsLabelsTpl23 = (field, mode) => {
    // devLog('get tm labels', field, mode)
    let modelFields = field.options.lines === undefined ? field.options : field.options.lines
    if (isDefined(modelFields)) {
        let fieldStr = ''
        modelFields.forEach(col => {
            let alignment = col.labelAlignment || "center"
            let fontSize = col.fontSize || 11;
            let colWidth = col.colWidth || null;
            let defaultStyle = "border: 1px solid #e0e0e0; border-collapse: collapse; font-weight: 400;padding-top: 5px;padding-bottom: 5px;padding-left: 8px;padding-right: 8px;background-color: #B9E5E4;";
            if(!isNull(colWidth)){
                //defaultStyle += "width:"+(colWidth - (colWidth  *  0.10))+"px;"
                defaultStyle += "width:"+(colWidth)+"px;"
            }
            let cellStyle
            switch (alignment) {
                case 'left':  cellStyle = "padding-left: 4px;"
                    break
                case 'right': cellStyle = "margin-left: auto;text-align: right;"
                    break
                default: cellStyle = "margin-left: auto; margin-right: auto; text-align: center;"
                    break
            }
            if ((mode === 'plugcode-complete' && col.encodable === false) || (mode === 'plugcode-consult' && col.visible === false)) { devLog('Hidden') }
            else { fieldStr += (`<th style="${defaultStyle + cellStyle + 'font-size:'+fontSize}">${col.label}</th>`) }
        })
        return '<thead><tr><th style="border: 1px solid #e0e0e0; border-collapse: collapse; font-weight: 400;padding-top: 5px;padding-bottom: 5px;padding-left: 8px;padding-right: 8px;background-color: #B9E5E4;">N°</th>' + fieldStr + '</tr></thead>'
    } else return null
}
