import React, { useEffect, useRef, useState } from 'react'
import { Collapse, Input, Tab, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import colors from '../../../assets/themes/Colors'
import { ColorCheckbox, PlugTooltip } from '../../custom/CustomComponents'
import { AddInfoIcon, Asterisk, AsteriskOutline, EditInfoIcon, EditValueIcon, SettingsIcon, VisibilityIndicator } from '../../custom/icons/CustomIcons'
import { devLog, getFormatCode, isDefined } from '../../../utils/utils'
import Images from '../../../assets/themes/Images'
import { camelCase } from 'lodash'
import { fieldsWithDefaultValue, formatsWithOptions, summarizeDefaultValue } from '../../../utils/Fields'
import RenderEditDefaultValueComponent from './RenderEditDefaultValueComponent'
import FormatPluglinkConstructorComponent from '../FormatPluglinkConstructorComponent'
import FormatPlugcodeInternalConstructorComponent from '../FormatPlugcodeInternalConstructorComponent'
import FormatPlugformConstructorComponent from '../FormatPlugformConstructorComponent'
import FormatFormulaConstructorComponent from '../FormatFormulaConstructorComponent'
import FormatTableConstructorComponent from '../FormatTableConstructorComponent'
import FormatIndiceConstructorComponent from '../FormatIndiceConstructorComponent'
import FormatSelectConstructorComponent from '../FormatSelectConstructorComponent'
import FormatSignConstructorComponent from '../FormatSignConstructorComponent'
import FormatNumericConstructorComponent from '../FormatNumericConstructorComponent'
import FormatSMailConstructorComponent from '../FormatSMailConstructorComponent'
import FormatSMailValidMultiConstructorComponent from '../FormatSMailValidMultiConstructorComponent'
import FormatTableMultiplenConstructorComponent from '../FormatTableMultiplenConstructorComponent'
import FormatTableMultipleConstructorComponent from '../FormatTableMultipleConstructorComponent'
import FormatIntDataMajConstructorComponent from '../FormatIntDataMajConstructorComponent'
import FormatExtDatanConstructorComponent from '../FormatExtDatanConstructorComponent'
import FormatDocumentConstructorComponent from '../FormatDocumentConstructorComponent'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'
import PlugTabs from './PlugTabs'
import FormatDateConstructorComponentV2 from '../FormatDateConstructorComponentV2'
import FormatTextConstructorComponent from "../FormatTextConstructorComponent";
import FormatTimeConstructorComponent from '../FormatTimeConstructorComponent'
import ApplicationConfig from '../../../config/ApplicationConfig'

const notEncodable = ['qrcodeint']
const useStyles = makeStyles(() => ({
  fieldImage: {
    height: 10,
    width: 10
  },
  fieldImageSvg: {
    width: 25,
    height: 25,
  },
  fieldIcon: {
    width: 20,
    height: 20,
    backgroundColor: '#57bbb8',
    borderRadius: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fieldIconSvg: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  fieldCtn: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    padding: 10,
    transition: 'background-color 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundColor: 'white',
    position: 'relative',
  },
  editedField: {
    // transition: 'background-color 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    backgroundColor: colors.plugnotesLightAlpha,
    paddingBottom: 0,
  },
  borderedField: {
    transition: 'border-color 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    border: '2px solid ' + colors.main,
  },
  fieldOptionsCtn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    transition: 'background-color 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  horizontalFlexSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 8,
  },
  optionBtn: {
    height: 'fit-content',
    color: colors.white,
    cursor: 'pointer',
    overflow: 'visible',
    textAlign: 'center',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: 4,
    backgroundColor: colors.mainButton,
    paddingTop: 4,
    margin: 'auto',
    zIndex: 1,
    '&:hover': {
      backgroundColor: colors.main,
      boxShadow: '0px 0px 3px 0px rgb(0 0 0 / 14%), 0px 0px 20px rgb(0 0 0 / 20%)'
    },
    width: 36,
  },
  disabled: {
    backgroundColor: colors.disabled,
    cursor: 'not-allowed',
    '&:hover': {
      boxShadow: 'none',
      backgroundColor: colors.disabled,
    },
  },
  labelInputCtn: {
    width: '-webkit-fill-available',
  },
  tab: {
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      fontSize: 15,
      gap: 4,
    },
    '&.MuiTab-labelIcon': {
      minHeight: 'auto !important',
    },
  },
  selectedTabH: {
    borderTop: '4px solid ' + colors.mainButton,
  },
  selectedTabV: {
    borderRight: '4px solid ' + colors.mainButton,
  },
  unselectedTab: {
      backgroundColor: colors.greyLight,
  },
  optionCtn: {
    width: '5%',
    '@media (max-width: 1000px)': { width: '10%' },
  },
  createOptionCtn: {
    width: '10%',
    '@media (max-width: 1000px)': { width: '15%' },
  },
  visibilityCtn: {
    width: '10%',
    justifyContent: 'center',
    display: 'flex',
    gap: 5,
    '@media(max-width:1000px)': {
      position: 'absolute',
      right: 4,
      bottom: 4,
    },
  },
  actionsCtn: {
    width: '10%',
    justifyContent: 'flex-end',
    display: 'flex',
    gap: 5,
    '@media(max-width:1000px)': {
      width: '15%',
    },
  },
}))

function RenderFieldOptionsComponent(props) {
  const { t } = useTranslation('common')
  const classes = useStyles()
  const plugnoteCount = props.model.numdatas

  // Tabs
  const [tabNb, setTabNb] = useState(props.defaultTab ? 1 : 0)
  const handleChangeTab = (_, nb) => {
    setTabNb(nb);
    if (nb === 0) { // ouvrir le Drawer

    }
  }

  let subitem = null
  let tabIcon = <SettingsIcon color={tabNb === 0 ? colors.main : colors.unselected} />
  let orientation = 'horizontal'
  switch (props.field.format.toLowerCase()) {
    case "text":
      subitem = ApplicationConfig.devMode
      ? (
          <FormatTextConstructorComponent
              field={props.field}
              handleEditLabel={props.handleEditLabel}
              index={props.index}
              setModel={props.setModel}
              model={props.model}
              update={props.update}
              folderName={props.folderName}
          />
      )
      : (
        <RenderEditDefaultValueComponent
          field={props.field}
          handleChangeDefaultValue={props.handleChangeDefaultValue}
          handleChangeDefaultValueOptions={props.handleChangeDefaultValueOptions}
        />
      )
      break
    case "date":
      subitem = (
        <FormatDateConstructorComponentV2
          field={props.field}
          handleEditLabel={props.handleEditLabel}
          index={props.index}
          setModel={props.setModel}
          model={props.model}
          update={props.update}
          folderName={props.folderName}
        />
      )
      break;

    case "indice":
      return (
        <FormatIndiceConstructorComponent
          field={props.field}
          handleEditLabel={props.handleEditLabel}
          index={props.index}
          onChangeHandlerDrop={props.onChangeHandlerDrop}
          setModel={props.setModel}
          model={props.model}
          update={props.update}
        />
      )

    case "numeric":
      subitem = (
        <FormatNumericConstructorComponent
          field={props.field} handleEditLabel={props.handleEditLabel}
          index={props.index}
          setModel={props.setModel}
          model={props.model}
          update={props.update}
          folderName={props.folderName} 
        />
      )
      break;

    case "select":
      return (
        <FormatSelectConstructorComponent
          field={props.field}
          handleEditLabel={props.handleEditLabel}
          index={props.index}
          onChangeHandlerDrop={props.onChangeHandlerDrop}
          setModel={props.setModel}
          model={props.model}
          update={props.update}
        />
      )

    case "sign":
      return (
        <FormatSignConstructorComponent
          field={props.field}
          handleEditLabel={props.handleEditLabel}
          index={props.index}
          setModel={props.setModel}
          model={props.model}
          update={props.update}
        />
      )
    
    case "table":
      return (
        <FormatTableConstructorComponent
          field={props.field}
          handleEditLabel={props.handleEditLabel}
          index={props.index}
          onChangeHandlerDrop={props.onChangeHandlerDrop}
          setModel={props.setModel}
          model={props.model}
          update={props.update}
        />
      )
    
    case "formula":
      return (
        <FormatFormulaConstructorComponent
          field={props.field}
          handleEditLabel={props.handleEditLabel}
          index={props.index}
          setModel={props.setModel}
          model={props.model}
          update={props.update}
        />
      )
    
    case "plugform":
      return (
        <FormatPlugformConstructorComponent
          field={props.field}
          handleEditLabel={props.handleEditLabel}
          index={props.index}
          setModel={props.setModel}
          model={props.model}
          update={props.update}
        />
      )
    
    case "qrcodeint":
      return (
        <FormatPlugcodeInternalConstructorComponent
          field={props.field}
          handleEditLabel={props.handleEditLabel}
          index={props.index}
          setModel={props.setModel}
          model={props.model}
          update={props.update}
        />
      )

    case "pluglink":
      return (
        <FormatPluglinkConstructorComponent
          field={props.field}
          handleEditLabel={props.handleEditLabel}
          index={props.index}
          setModel={props.setModel}
          model={props.model}
          update={props.update}
          folderName={props.folderName}
        />
      )
    
    case "smail":
      orientation = 'horizontal'
      subitem = (
        <FormatSMailConstructorComponent
          field={props.field}
          handleEditLabel={props.handleEditLabel}
          index={props.index}
          setModel={props.setModel}
          model={props.model}
          update={props.update}
          folderName={props.folderName}
        />
      )
      break;

    case "smailvalidmulti":
      return (
        <div className="fullWidth">
          <FormatSMailValidMultiConstructorComponent
            field={props.field}
            handleEditLabel={props.handleEditLabel}
            index={props.index}
            onChangeHandlerDrop={props.onChangeHandlerDrop}
            setModel={props.setModel}
            model={props.model}
            update={props.update}
            setOpenDrawer={props.setOpenDrawer}
            folderName={props.folderName}
            plugnoteCount={plugnoteCount}
          />
        </div>
      )

    case "tablemulti":
      return (
        <FormatTableMultipleConstructorComponent
          field={props.field}
          handleEditLabel={props.handleEditLabel}
          index={props.index}
          onChangeHandlerDrop={props.onChangeHandlerDrop}
          setModel={props.setModel}
          model={props.model}
          update={props.update}
          folderName={props.folderName}
          plugnoteCount={plugnoteCount}
        />
      )

    case "tablemultin":
      return (
        <div className="fullWidth">
          <FormatTableMultiplenConstructorComponent
            field={props.field}
            handleEditLabel={props.handleEditLabel}
            index={props.index}
            onChangeHandlerDrop={props.onChangeHandlerDrop}
            setModel={props.setModel}
            model={props.model}
            update={props.update}
            folderName={props.folderName}
            setOpenDrawer={props.setOpenDrawer}
            plugnoteCount={plugnoteCount}
          />
        </div>
      )

    case "time":
      subitem = (
        <FormatTimeConstructorComponent
          field={props.field}
          index={props.index}
          onChangeHandlerDrop={props.onChangeHandlerDrop}
          setModel={props.setModel}
          model={props.model}
          update={props.update}
          folderName={props.folderName}
          setOpenDrawer={props.setOpenDrawer}
        />
      )
      break

    case "intdatamaj":
      return (
        <FormatIntDataMajConstructorComponent
          field={props.field}
          handleEditLabel={props.handleEditLabel}
          index={props.index}
          setModel={props.setModel}
          model={props.model}
          update={props.update}
        />
      )

    case "extdata":
    case "extdatan":
      return (
        <FormatExtDatanConstructorComponent
          field={props.field} handleEditLabel={props.handleEditLabel}
          index={props.index}
          setModel={props.setModel}
          model={props.model}
          update={props.update}
        />
      )

    case "document":
      return (
        <FormatDocumentConstructorComponent
          field={props.field}
          handleEditLabel={props.handleEditLabel}
          index={props.index}
          setModel={props.setModel}
          model={props.model}
          update={props.update}
          plugnoteCount={plugnoteCount}
        />
      )

    // case 'time':
    //   return (
    //     <FormatTimeConstructorComponent
    //       field={props.field}
    //       handleEditLabel={props.handleEditLabel}
    //       index={props.index}
    //       setModel={props.setModel}
    //       model={props.model}

    //     />
    //   )

    default:
      return (
        <div className={classes.fieldOptionsCtn}>
          <RenderEditDefaultValueComponent
            field={props.field}
            handleChangeDefaultValue={props.handleChangeDefaultValue}
            handleChangeDefaultValueOptions={props.handleChangeDefaultValueOptions}
          />
        </div>
      )
  }
  if (fieldsWithDefaultValue.includes(props.field.format.toLowerCase())) {
    const selectedTab = orientation === 'vertical' ? classes.selectedTabV : classes.selectedTabH
    if (!isDefined(tabNb)) { return null }
    return (
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <div className={classes.tabsWrapper}>
          <div className={classes.tabsCtn} >
            <PlugTabs
              onChange={handleChangeTab}
              textColor="inherit"
              value={tabNb || 0}
            >
              <Tab
                icon={tabIcon}
                label={props.field.format.toLowerCase() === "numeric" || "text" ? 'Options' : t('smailvalidmulti.config-email')}
                className={`${classes.tab} ${tabNb === 0 ? selectedTab : classes.unselectedTab}`} />
              <Tab
                icon={<EditValueIcon color={tabNb === 1 ? colors.main : colors.coal} height={24} width={24} />}
                label={t('helper.default-value')}
                className={`${classes.tab} ${tabNb === 1 ? selectedTab : classes.unselectedTab}`} />
            </PlugTabs>
          </div>
        </div>
        {
          tabNb === 1 &&
          <RenderEditDefaultValueComponent
            field={props.field}
            handleChangeDefaultValue={(value) => props.handleChangeDefaultValue(value)}
            handleChangeDefaultValueOptions={(value) => props.handleChangeDefaultValueOptions(value)}
          />
        }
        {
          tabNb === 0 &&
          subitem
        }
      </div>
    )
  } else {
    return subitem
  }
}

export const GenericFormatConstructorComponent = ({
  field, index, isCreate,
  handleOpenFieldOptions,
  handleEditLabel,
  onChangeHandlerDrop, setModel, model, nameModel,
  missingLabel, openFieldOptions,
  handleToggleVisiblePDF, handleVisibleField, handleEditableField,
  authorizeInfobulle, infobulles, handleEditInfobulle, handleToggleRGPD,
  provided, handleDeleteField,
  handleToggleMandatoryField, handleChangeDefaultValue,
  handleChangeDefaultValueOptions
}) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const dragProvided = isDefined(provided) ? provided : {}
  const labelInputRef = useRef(null)
  const openOptions = openFieldOptions === index
  const formatHasOptions = formatsWithOptions.includes(field.format)
  
  const [defaultTab, setDefaultTab] = useState(false)

  const handleOpenDefaultTab = () => {
    setDefaultTab(true)
    handleOpenFieldOptions()
  }

  const handleOpenOptions = () => {
    if (!formatHasOptions) return null
    handleOpenFieldOptions()
    // window.scrollTo({
    //   top: -fieldRef.current.offsetTop,
    //   behavior: 'smooth',
    // })
  }

  const handleScrollIntoField = () => {
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    let element = document.getElementById('field-' + field.id)
    if (isDefined(element) && openOptions) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' })
      }, 500);
    }
    handleScrollIntoField()
  }, [openOptions])

  return (
    <div className={`champs_remplir flexbox-field-item m2 ${openOptions ? classes.borderedField : undefined}`} id={'field-' + field.id}>
      <div className={`${classes.fieldCtn} ${openOptions ? classes.editedField : undefined}`}>
        <div style={{ width: '5%', textAlign: 'center', display: 'flex', flexDirection: 'column' }}>
          <span className="green">
            {
              field.format.toLowerCase() === 'now' ?
                <div
                  className={classes.fieldIcon}>
                  <img
                    className={classes.fieldImage}
                    src={Images[camelCase(field.format.toLowerCase()) + "Icon"]}
                    alt={t('fields.format-choice-' + field.format.toLowerCase())} />
                </div>
                :
                <div
                  className={classes.fieldIconSvg}>
                  <img
                    className={classes.fieldImageSvg}
                    src={Images[camelCase(field.format.toLowerCase()) + "IconSvg"]}
                    alt={t('fields.format-choice-' + field.format.toLowerCase())} />
                </div>
            }
          </span>
          <span className="references_8 line_height_22">({(index + 1)})</span>
        </div>
        <div style={{ width: '5%', textAlign: 'center' }}>
          <span className="references">
            {getFormatCode(field)}
          </span>
        </div>
        <div style={{ width: '20%', textAlign: 'center', padding: '0 8px' }}>
          {t('fields.format-choice-' + field.format.toLowerCase())}
        </div>
        <div style={{ width: isCreate ? '40%' : '35%' }}
          className={classes.horizontalFlexSpaceBetween}
        >
          <div ref={labelInputRef} className={classes.labelInputCtn} >
            <Input
              id="standard-full-width"
              label="Label"
              placeholder={t('fields.format-choice-placeholder-' + field.format.toLowerCase())}
              fullWidth
              margin="none"
              inputlabelprops={{ shrink: true }}
              inputProps={{
                className: classes.inputField,
                fieldkey: field.key,
                maxLength: 40,
              }}
              style={{ width: '100%' }}
              onChange={(event) => handleEditLabel(event.target.value, field, index)}
              defaultValue={field.label}
            />
          </div>
          {
            missingLabel === index &&
            <PlugTooltip placement="top" title={t('tablemulti.missing-label')}>
              <span className="material-icons" style={{ color: 'red' }}>
                error
              </span>
            </PlugTooltip>
          }
          {
            isDefined(field?.options?.setup?.defaultValue) && field.options.setup.defaultValue !== '' &&
            <PlugTooltip placement="top" title={summarizeDefaultValue(field.format, field.options.setup.defaultValue, t)}>
              <span onClick={handleOpenDefaultTab}>
                <EditValueIcon style={{ cursor: 'pointer', color: colors.bloodOrange }} />
              </span>
            </PlugTooltip>
          }
        </div>
        <span style={{ display: 'flex', justifyContent: 'center' }} className={isCreate ? classes.createOptionCtn : classes.optionCtn}>
          <PlugTooltip title={t('helper.more-options')} placement="top">
            <div className={`${classes.optionBtn} ${!formatHasOptions ? classes.disabled: undefined}`}
              onClick={handleOpenOptions}
            >
              <div className={classes.openClose}>
                <SettingsIcon color="white" />
                <span style={{ marginTop: -8 }} >
                  {
                    openOptions
                      ? <KeyboardArrowUp />
                      : <KeyboardArrowDown />
                  }
                </span>
              </div>
            </div>
          </PlugTooltip>
        </span>
        <PlugTooltip title={t('helper.toggle-visibility')} placement="top">
          <div className={classes.visibilityCtn} >
            <span onClick={() => handleToggleVisiblePDF(field, index)}>
              <VisibilityIndicator text="PDF" visible={field?.options?.setup?.printable === true} />
            </span>
            <span onClick={!notEncodable.includes(field.format) ? handleVisibleField.bind(this, field, index) : null}>
              <VisibilityIndicator text="PN-" visible={field.isVisible === true} />
            </span>
            <span onClick={!notEncodable.includes(field.format) ? handleEditableField.bind(this, field, index) : null}>
              <VisibilityIndicator text="PF-" visible={field.isEncodable === true} />
            </span>
          </div>
        </PlugTooltip>
        {
          authorizeInfobulle &&
          <div style={{ width: '5%', justifyContent: 'center', display: 'flex' }}>
            <PlugTooltip
              title={!isDefined(infobulles) || !infobulles.hasOwnProperty('field' + field.id) ? t('common:helper.infobulles-info') : infobulles['field' + field.id]}
              placement="top">
              <span
                id="icon-more"
                onClick={() => handleEditInfobulle(field)}
                style={{ cursor: 'pointer' }}
              >
                {isDefined(infobulles) && infobulles.hasOwnProperty('field' + field.id)
                  ? <EditInfoIcon style={{ color: colors.bloodOrange }} secondaryColor={colors.bloodOrange} />
                  : <AddInfoIcon style={{ color: colors.grey }} secondaryColor={colors.grey} />}
              </span>
            </PlugTooltip>
          </div>
        }
        <div style={{ width: '5%', justifyContent: 'center', display: 'flex' }}>
          <div className="grey flexSpacebetween">
            <PlugTooltip
              title={t('common:helper.rgpd')}
              aria-label="add"
              placement="top-start">
              <div className="grey flexSpacebetween" onClick={() => handleToggleRGPD(field, index)}>
                <ColorCheckbox
                  checked={field?.options?.rgpd} />
              </div>
            </PlugTooltip>
          </div>
        </div>

        <div // 10 % Actions
          className={classes.actionsCtn} 
        >
          <div
            className="grey flexSpacebetween">
            <PlugTooltip
              title={t('common:helper.move-field')}
              aria-label="add"
              placement="top-start">
              <span
                id="icon-more"
                {...dragProvided.dragHandleProps}
                className="material-icons md-small">
                drag_handle
              </span>
            </PlugTooltip>
            <PlugTooltip
              title={t('common:helper.remove-field')}
              aria-label="add"
              placement="top-start">
              <span
                id="icon-more"
                className="material-icons md-small"
                onClick={handleDeleteField.bind(this, field)}>
                clear
              </span>
            </PlugTooltip>
            {
              field.format !== 'plugform' &&
              <PlugTooltip
                title={field.options.mandatory ? t('helper.mandatory-field') : t('helper.not-mandatory-field')}
                aria-label="add"
                placement="top-start">
                <span onClick={handleToggleMandatoryField.bind(this, field, index)}>
                  {
                    field.options.mandatory
                      ? <Asterisk color="black" size={18} />
                      : <AsteriskOutline color="black" size={18} />
                  }
                </span>
              </PlugTooltip>
            }
          </div>
        </div>
      </div>

      <Collapse in={openOptions} timeout="auto" unmountOnExit className={classes.editedField} >
        <RenderFieldOptionsComponent
          defaultTab={defaultTab}
          field={field}
          handleChangeDefaultValue={handleChangeDefaultValue}
          handleChangeDefaultValueOptions={handleChangeDefaultValueOptions}
          handleEditLabel={handleEditLabel}
          index={index}
          onChangeHandlerDrop={onChangeHandlerDrop}
          setModel={setModel}
          model={model}
          update={true}
          folderName={nameModel}
        />
      </Collapse>
    </div>
  );
}

export default GenericFormatConstructorComponent
