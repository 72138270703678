import {api} from "./index";
import {apiV2} from "./indexV2";

export let
    postExtData,
    postComboExtData,
    postExtDataPreview,
    postExtDataGenerateTable;


postExtData = (payload) => api.post('extdata/v3s/retrieves/datas.json', payload);
postComboExtData = (payload) => apiV2.post('/Formats/Table/ComboListFillIn.php', payload);
postExtDataPreview = (payload) => api.post('extdata/uniques/datas.json', payload);
postExtDataGenerateTable = (payload) => api.post('extdata/generates/datas.json', payload);

