import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import axios from "axios";
import ApplicationConfig from "../../../config/ApplicationConfig";
import i18n from "../../../translations/i18n";
import colors from "../../../assets/themes/Colors";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    Box,
    CircularProgress,
    Collapse,
    Dialog,
    Fade,
    FormControl, FormControlLabel, FormLabel,
    Input, InputAdornment,
    List, ListItem, ListItemText,
    makeStyles,
    MenuItem,
    Modal, Popover,
    Radio, RadioGroup,
    Select,
    Table, TableBody, TableCell, TableHead, TableRow,
    TextField
} from "@material-ui/core"
import { NumericFormat } from 'react-number-format'
import InputMask from 'react-input-mask';
import { CSVDownload } from "react-csv";
import { convertersAlphaToNumber, devLog, getFilenameAndExtension, handlePreventDefault, isDefined } from "../../../utils/utils";
import { HyperFormula } from "hyperformula";
import { ColorCheckbox, DurationInput, FieldVisibilityIndicator, PlugTooltip, RestyledNumericInput } from "../../custom/CustomComponents";
import ModalListExtDataComponent from "../extdata/ModalListExtDataComponent";
import { RenderItemMultipleSelectComponent, RenderItemSingleSelectComponent } from "../../custom/FormatTable";
import { ToastMessage } from "../../../actions/gallery";
import { Content, Header } from "rsuite";
import { faFileCsv } from "@fortawesome/free-solid-svg-icons";
import { ExpandLess, ExpandMore, Search } from "@material-ui/icons";
import { trackPromise, usePromiseTracker } from "react-promise-tracker";
import { faFile, faFileArchive, faFilePdf, faFileWord, faStickyNote } from "@fortawesome/free-regular-svg-icons";
import { getCurrentDateTimeByMask, isEditableColumnFormat, isEditableDefaultValue, isInvalidDate, isInvalidTime, isRowLimitReached, isHiddenColumn, getDateTimeFromValue } from "../../../utils/Fields";
import { getPlugnote, getToken, getTableView } from "../../../utils/Common";
import { confirmAlert } from "react-confirm-alert";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { deleteShareDataWithEmailUser, getShareDataWithEmailUser, postShareDataWithEmailUser, putShareDataWithEmailUser } from "../../../api/shareApi";
import { Data9002PrintableGetURL } from "../../printable/Data9002Printable";
import PDFTablemulti from "../../custom/pdftable/PDFTablemulti";
import ModalListIntDataComponent from "../intdata/ModalListIntDataComponent";
import { PDFDownloadLink } from "@react-pdf/renderer";
import TableMultiPrintable from "../../printable/TableMultiPrintable"
import FormatIntDataMajComponent from "../../formats/FormatIntDataMajComponent";
import _, { isNull } from "lodash";
import { existingModes } from "../../../utils/Modes"
import TablemultiHorizontalViewComponent from "./TablemultiHorizontalViewComponent";
import { LockedIcon, MissingDocumentIcon } from "../../custom/icons/CustomIcons";
import PDFHorizontalTablemulti from "../../custom/pdftable/PDFHorizontalTablemulti";
import SmailModalComponent from "../smail/SmailModalComponent";
import { isMobile } from "react-device-detect";
import { MdDelete, MdDeleteSweep } from "react-icons/md";
import { RiFileCopyLine } from "react-icons/ri";
import PopupState, { bindPopover, bindTrigger } from "material-ui-popup-state";
import { roundQuarter } from "../../../utils/Fields";
import { KeyboardTimePicker } from "@material-ui/pickers";
import Icon from "@material-ui/core/Icon";
import { isUndefined } from "lodash";
import { TimeComponentWithOptions } from "../../custom/CustomComponents";
// import useDebounce from "../../../utils/hooks/useDebounce";
import ModalNotePreviewComponent from "../annexes/ModalNotePreviewComponent";
import ModalListComboFillExtDataComponent from "../combofillextdata/ModalListComboFillExtDataComponent";
import { FileCommentComponent } from "../../../containers/annexes/AttachmentTableView";

function getModalStyle() {
    // const top = 5;
    return {
        // top: `${top}%`,
        margin: 'auto',
        overflow: 'auto',
    };
}
// devLog('isMobile ?', isMobile)
const useStyles = makeStyles((theme) => ({
    iframe: {
        width: '100%',
        height: 800,
        minWidth: '60vw',
        minHeight: '60vh',
    },
    modalFile: {
      border: "none",
      width: "100%",
      height: "100%",
      minWidth: '400px',
      "@media (min-width:1025px)":{
        minWidth: '80vw',
        minHeight: '90vh',
      },
    },
    popup: {
        position: 'absolute',
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '90vw',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
        height: '99vh',
        scrollbarWidth: 'none!important',
        "&::-webkit-scrollbar": {
            display: 'none'
        },
        overflow: 'hidden',
        '@media (max-width: 1024px)': {
            width: '95vw',
            minHeight: '60vh'
        },
        '@media (max-width: 640px)': {
            padding: '8px 5px 30px',
        }
    },
    notRender: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 15,
    },
    notRenderText: {
        fontWeight: 'bold',
        color: '#88949C',
    },
    urlButton: {
        /* border: '1px red solid', */
        display: 'inline-block',
        color: "white",
        backgroundColor: "#58c3c1",
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    closeBtn: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-end',
        marginBottom: 15,
        '@media (max-width: 850px)': {
            marginBottom: 0,
            alignSelf: 'flex-end',
        },
        position: 'relative',
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalHeader: {
        // backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        minHeight: '11vh',
        padding: '20px 10px 0',
        '@media (max-width: 850px)': {
            flexDirection: 'column-reverse',
        }
    },
    radioGroupCtn: {
        '& .MuiFormGroup-root': {
            flexDirection: 'row',
        },
        flexDirection: 'row !important',
    },
    radioLabel: {
        fontSize: 12,
    },
    modalBody: {
        // paddingTop: 80,
        position: 'relative',
        '@media (max-width: 850px)': {
            paddingTop: isMobile ? 12 : 20,
        }
    },
    printPreviewCtn: {
        // paddingTop: 80,
    },
    headerTitle: {
        textAlign: 'center',
        '@media (max-width: 1299px)': {
            fontSize: '12px !important'
        },
        '@media (max-width: 850px)': {
            alignSelf: 'center',
        }
    },
    exportIconCtn: {
        width: 100,
        alignSelf: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        gap: 5,
    },
    searchInputCtn: {
        flex: 1,
        marginRight: 15,
        marginBottom: 15,
        '@media (max-width: 850px)': !isMobile ? {
            position: 'absolute',
            left: 25,
            top: 25
        } : undefined,
    },
    input: {
        color: 'white',
        fontFamily: 'Arial, FontAwesome'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '8px',
        width: '100%',
        minWidth: 140,
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F1F1F1',
        fontSize: 15,
        MozBorderRadius: '8px',
        WebkitBorderRadius: '8px',
    },
    horizontalTable: {
        '& .MuiTable-root .MuiTableCell-root': {
            minWidth: isMobile ? 72 : 30,
            border: '1px solid ' + colors.unselectedAlpha,
        },
    },
    table: {
        '& .MuiTable-root .MuiTableCell-root': {
            // width: 'auto !important',
            minWidth: isMobile ? 72 : 30,
            border: '1px solid ' + colors.unselectedAlpha,
        },
    },
    tableCtn: {
        width: 'auto',
        maxHeight: '80vh',
        height: 'calc(90vh - ' + isMobile ? '140px)' : '30px)',
        overflowY: 'auto',
        paddingRight: 2,
        '& .MuiTableCell-root': {
            padding: '2px !important',
        }
    },
    modelPreviewCtn: {
        display: 'flex',
        // alignItems: 'center',
        flexDirection: 'column',
        width: '-webkit-fill-available',
        // maxHeight: '60vh',
        overflowY: 'auto',
        // marginLeft: -30,
        // marginRight: -5,
        marginBottom: 10,
        borderRadius: 4,
        '& .MuiTableCell-root': {
            borderRight: `1px solid ${colors.paleGrey}`,
            padding: '3px !important'
        },
        '& .MuiTable-root': {
            width: '99%',
        },
    },
    tablePreviewCtn: {
        display: 'flex',
        flexDirection: 'column',
        padding: 8,
        overflowY: 'auto',
        marginLeft: -30,
        marginRight: -5,
        marginBottom: 4,
        borderRadius: 4,
        '& .MuiTableCell-root': {
            borderRight: `1px solid ${colors.unselected}`,
            borderBottom: `1px solid ${colors.unselected}`,
            padding: '3px !important'
        },
        '& .MuiTable-root': {
            width: '99%',
            borderLeft: `1px solid ${colors.unselected}`,
            borderTop: `1px solid ${colors.unselected}`,
        },
    },
    tableHeader: {
        '& .MuiTableCell-stickyHeader': {
            backgroundColor: colors.palePlugGreen
        },
        borderRadius: 5,
    },
    tableHeaderEdit: {
        '& .MuiTableCell-stickyHeader': {
            backgroundColor: colors.bloodAlpha,
        },
        borderRadius: 5,
    },
    tableBodyCtn: {
        maxHeight: '55vh',
        overflow: 'auto',
        backgroundColor: 'white',
    },
    stickyColumn: {
        position: 'sticky',
        left: 0,
        right: 0,
        zIndex: 10,
        // boxShadow: "5px 2px 5px grey",
        // borderRight: "2px solid black",
    },
    fixedColumn: {
        position: 'fixed',
        left: 0,
    },
    scrollBar: {
        overflowX: 'hidden',
        "&::-webkit-scrollbar": {
            display: "block",
            position: 'absolute',
            overflowX: 'auto',
            background: colors.greyLight,
            width: 5,
            right: 0,
        },
        "&::-webkit-scrollbar-thumb": {
            background: colors.grey,
        },
    },
    rowNb: {
        fontSize: 12,
    },
    rowNbColumnHeader: {
        width: 30,
    },
    rowNbCell: {
        '& .MuiTableCell-root': {
            borderLeft: `1px solid ${colors.paleGrey}`,
            padding: '3px !important'
        }
    },
    columnHeader: {
        fontSize: '14px',
        // maxWidth: 36,
    },
    thinColumn: {
        '& .MuiTable-root .MuiTableCell-root': {
            width: '30px !important',
        },
        width: '30px !important',
    },
    actionColumn: {
        '& .MuiTable-root .MuiTableCell-root': {
            minWidth: 30,
            maxWidth: '52px !important',
        },
    },
    verticalDotsCtn: {
        width: 28, maxWidth: '28px !important', display: 'flex',
        // marginRight: -40,
    },
    popoverCtn: {
        padding: '8px 16px',
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        alignItems: 'flex-start',
    },
    popoverItemCtn: {
        display: 'flex',
        alignItems: 'center',
        gap: 6,
        cursor: 'pointer',
    },
    headerIcon: {
        fontSize: '14px',
        fontWeight: 'bold',
        cursor: 'pointer',
    },
    rowNumber: {
        fontSize: 10,
        border: '1px solid',
        borderRadius: '50%',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        height: 20,
        width: 20,
        '&:hover': {
            boxShadow: '0px 0px 10px 1px',
            fontWeight: 'bold',
            backgroundColor: colors.mainButton,
            borderColor: colors.mainButton,
            color: 'white',
        },
    },
    rowNbPreview: {
        fontSize: 14,
        fontWeight: 'bold',
        cursor: 'pointer',
    },
    rowHeader: {
        borderRadius: '50%',
    },
    //selects
    selectsCtn: {
        minHeight: 24,
        gap: 5,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
    selectItem: {
        color: colors.unselectedAlpha,
        borderColor: colors.selectedMain,
        border: '1px solid',
        padding: '0px 4px',
        margin: '0px 0px',
        borderRadius: 4,
        cursor: 'pointer',
        fontSize: 10,
        minHeight: 25,
        minWidth: 1,
    },
    //editor
    editFieldsCtn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
        width: '45%',
        minWidth: 500,
        position: 'relative',
        padding: 2,
    },
    editIcon: {
        position: 'absolute',
        top: 10,
        left: -20,
        fontSize: 20,
        color: colors.mainButton,
        cursor: 'pointer'
    },
    editRow: {
        '& .MuiTableCell-root': {
            padding: '0px !important',
            verticalAlign: 'baseline',
            borderWidth: '0px !important',
            backgroundColor: colors.hoverBgnd,
        },
        zIndex: 0
    },
    singleEditorCtn: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'row',
        width: '100%',
        minWidth: isMobile ? 130 : 80,
    },
    editorCtn: {
        zIndex: 50,
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        padding: 2,
        backgroundColor: colors.hoverBgnd
    },
    voidCell: {
        height: 24,
        width: '100%'
    },
    absoluteIcon: {
        position: 'absolute',
    },
    validateIcon: {
        fontSize: 20,
        backgroundColor: colors.mainButton,
        borderRadius: 5,
        padding: '3px 8px',
        color: 'white',
        cursor: 'pointer'
    },
    textLength: {
        fontSize: 8,
        alignSelf: 'flex-start',
    },
    //fields
    singleTitleCtn: {
        order: 1,
        maxWidth: '20%',
        marginRight: 10
    },
    singleValueCtn: {
        display: 'flex',
        justifyContent: 'space-between',
        order: 2,
        width: 'inherit'
    },
    sendMailPreviewCtn: {
        minWidth: 60,
        display: 'flex',
        flexDirection: 'column',
        whiteSpace: 'break-spaces',
    },
    singleWiderValueCtn: {
        order: 2,
        // width: '80%',
    },
    fieldTitleContainer: {
        marginRight: 'auto',
        order: 1,
        width: '40%',
        alignSelf: 'center',
        display: 'inline-flex',
        alignItems: 'center',
        gap: 4,
    },
    cellPreviewCtn: {
        display: 'flex',
        order: 2,
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
    },
    fieldValueContainer: {
        display: 'flex',
        order: 2,
        width: '60%',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
    },
    widerFieldValueContainer: {
        marginLeft: isMobile ? 0 : -50,
        order: 2,
        width: isMobile ? '80vw' : 'calc(60% + 32px)',
    },
    fontFieldLabel: {
        fontSize: 12,
        fontWeight: 'bold',
        color: colors.main,
        wordBreak: 'break-word',
        display: 'inline-flex',
        gap: 4,
    },
    fontFieldNumber: {
        fontSize: 12,
        display: 'inline-flex',
        marginRight: 2,
        color: colors.main,
        border: '1px solid',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        verticalAlign: 'bottom',
        width: 20,
        height: 20,
    },
    stylizedValue: {
        fontSize: 12,
        fontWeight: 'bold',
        width: '100%',
        paddingLeft: 3,
        paddingRight: 3,
    },
    tablemultiFont: {
        fontSize: 12,
        fontWeight: 'bold',
        paddingLeft: '5px!important',
        textAlign: 'center',
    },
    fontError: {
        fontSize: 12,
        color: colors.red,
        fontWeight: 'bold',
        paddingLeft: '5px!important',
        width: '-webkit-fill-available',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    selectIcon: {
        left: 2,
        fontSize: 28,
        cursor: 'pointer',
    },
    scaledImg: {
        maxWidth: "100%",
        maxHeight: "100%",
        minWidth: 400,
        minHeight: 400,
    },
    selectSelect: {
        marginLeft: 33,
        paddingLeft: 10,
        paddingRight: '10px !important',
    },
    selectField: {
        maxHeight: 20,
    },
    tablemultiInputCtn: {
        display: 'flex',
        flex: 1,
        justifyContent: 'flex-start',
        flexDirection: 'row',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        gap: 3,
        flexWrap: 'nowrap',
        minWidth: 60,
        textAlign: 'center',
    },
    tablemultiRadioCtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        paddingLeft: 10,
        marginLeft: 3,
    },
    tablemultiInputBtn: {
        height: '20px',
        width: '20px',
        color: colors.main,
        alignItems: 'center',
        fontSize: "20px",
        margin: '0px 5px',
    },
    tablemultiBtn: {
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.main,
        // fontSize: 22,
    },
    addNewLineBtn: {
        marginTop: '0.5em',
        //fontSize: '40px'
    },
    actionsTableCellCtn: {
        display: 'flex',
        justifyContent: 'center'
    },
    duplicateBtnCtn: {
        cursor: 'pointer',
        color: colors.main,
        //top: 'calc(50% - 12px)',
    },
    duplicateBtn: {
        cursor: 'pointer',
        color: colors.main,
        //paddingLeft: '20px'
        //top: 'calc(50% - 12px)',
    },
    deleteBtnCtn: {
        color: colors.main,
        //position: 'absolute',
        // right: -25,
        top: 'calc(50% - 12px)',
    },
    deleteBtn: {
        color: colors.main,
        //position: 'absolute',
        // right: -25,
        //top: 'calc(50% - 12px)',
    },
    deleteConfirmationCtn: {
        backgroundColor: 'white',
        padding: '4px 10px',
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        // boxShadow: '0px 0px 20px 20px rgb(0 0 0 / 14%), 0px 11px 15px rgb(0 0 0 / 20%)',
    },
    confirmBtnCtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: 'relative',
    },
    confirmBtn: {},
    confirmIcon: {},
    headerDeleteBtnCtn: {
        marginRight: -6,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    loaderCtn: {
        position: 'absolute',
        display: 'flex',
        zIndex: 50,
        width: '96%',
        justifyContent: 'center',
        alignItems: 'center',
        height: 150,
        fontStyle: 'italic',
        backgroundColor: colors.hoverBgnd,
        borderBottom: `3px solid ${colors.mainButton}`,
    },
    totalValues: {
        color: colors.red,
        padding: '0 4px',
        wordBreak: 'break-word',
        textAlign: 'left',
        fontSize: 14,
        fontWeight: 'bold'
    },
    totalCtn: {
        display: 'flex',
        height: 20,
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginBottom: 7,
    },
    searchBtn: {
        fontSize: 15,
        minWidth: 70,
    },
    leftEditCtn: {
        // width: '60%',
        marginRight: 'auto',
    },
    centerEditCtn: {
        display: 'flex',
        justifyContent: 'center',
    },
    rightEditCtn: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    pickerHiddenInputCellEdit: {
        '& .MuiInputBase-root': {
            "& input": {
                display: 'none',
            },
            "& .MuiInputAdornment-root": {
                alignItems: 'unset !important',
                display: 'initial',
                "& .MuiIconButton-root": {
                    padding: '0px !important',
                },
            },
        },
    },
    timeElementsCtnCellView: {
        display: 'flex',
        flexWrap: 'wrap'
        //backgroundColor: 'lightcyan'
    },
    timeComponentCtnCellView: {
        '& .MuiInputBase-root': {
            "& input": {
                display: 'none',
            },
        },
        "& .MuiInputAdornment-root": {
            height: '24px',
            "& .MuiIconButton-root": {
                padding: '0px !important',
            },
        },
    },
    marginAuto: {
        margin: 'auto'
    },
    //Smail exclusifs
    mailButton: {
        display: 'inline-block',
        color: "white",
        backgroundColor: colors.mainButton,
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
        alignSelf: 'center',
    },
    unregisteredBtn: {
        alignSelf: 'center',
        display: 'inline-block',
        color: "white",
        backgroundColor: colors.bloodOrange,
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    //Smail modale
    header: {
        position: 'absolute',
        top: 0,
        width: '100%',
    },
    modalFooter: {
        '@media (min-height:640px)': {
            display: 'none'
        },
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },
    labelContainer: {
        width: '15%',
    },
    inputContainer: {
        width: '80%',
    },
    inputArea: {
        color: 'white',
        fontFamily: 'Arial, FontAwesome'
    },
    textFieldArea: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        // marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        paddingLeft: '10px',
        backgroundColor: '#FFFFFF',
        fontSize: '14px',
        width: '100%',
        border: '1px solid #ccc',
        MozBorderRadius: '5px',
        WebkitBorderRadius: '5px',
    },
    attItemsContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 5,
        overflow: "hidden",
        marginLeft: '10px'
    },
    attItem: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 5,
    },
    RadioGroupModal: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
    },
    showOption: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: 5
    },
    radioIconModal: {
        color: colors.mainButton,
        backgroundColor: colors.mainButton
    },
    radioLabelModal: {
        fontSize: 14,
        cursor: 'pointer'
    },
    pwcodeContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 30,
        gap: 10,
    },
    modalBtnContainer: {
        display: 'inline-flex',
        width: '100%',
        justifyContent: "space-between",
    },
    mainPhoneContainer: {
        marginTop: '20px',
    },
    phoneContainer: {
        border: '1px silver solid',
        borderRadius: 4,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    searchInput: {
        position: 'absolute',
        bottom: -30,
        right: 0,
        borderRadius: 8,
        minWidth: 140,
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F1F1F1',
        fontSize: 15
    },
    viewMoreCtn: {
        height: 100,
        color: `${colors.main} !important`,
        background: `linear-gradient(#ffffff2b, white)`,
        '&:hover': {
            background: `linear-gradient(transparent, ${colors.main}) !important`,
            color: 'white !important',
        },
        position: 'absolute',
        paddingTop: 70,
        bottom: 0,
        width: '100%',
        zIndex: 1,
        cursor: 'pointer',
    },
    viewMoreTxt: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'center',
        fontWeight: 'bold !important',
        backgroundColor: `${colors.transparent} !important`,
        cursor: 'pointer',
        '& .MuiTableCell-root': {
            border: 'none !important',
            borderRight: 'none !important',
            borderBottom: 'none !important',
            '&:hover': {
                backgroundColor: colors.transparent,
                color: colors.main,
                textDecoration: 'underline'
            }
        },
    },
    noDataCell: {
            textAlign: 'center',
            borderLeft: '1px solid #e0e0e0',
            borderRight: '1px solid #e0e0e0',
    },
    pickerHiddenInputRowEdit: {
        '& .MuiInputBase-root': {
            "& input": {
                display: 'none',
            },
        },
    },
    noDataTxt: {
        fontStyle: 'italic',
        fontSize: 12,
    },
    icon: { fontSize: 32, color: colors.mainButton },
    disabledIcon: {
        color: colors.iconGrey,
        cursor: "not-allowed",
        fontSize: 32,
    },
    //? impression PDF
    pdfComponentCtn: {
        textDecoration: 'none',
        color: 'black',
        display: 'flex',
        height: '50vh',
        width: '100%',
        justifyContent: 'center',
    },
    cancelPrintBtn: {
        position: 'absolute',
        bottom: 30,
        right: '15%',
        backgroundColor: colors.unselectedAlpha,
        padding: '4px 12px',
        cursor: 'pointer',
        borderRadius: 8,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 100,
    },
    previewCtn: {
        border: 'none',
        width: '100%',
        minWidth: '400px',
        height: '100%',
        '@media (min-width:1025px)': {
            minWidth: '60vw'
        },
        display: 'flex',
        gap: 10,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    pdfCtn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        height: '80vh',
        position: 'relative',
    },
    pointer: {
        cursor: 'pointer'
    },
    fontSizeSettingCtn: {
        // position: 'absolute',
        height: '100%',
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
        fontSize: 10
    },
    fontSizeSetting: {
        fontSize: 16,
    },
    resizable: {
        position: 'absolute',
        height: '100%',
        width: 5,
        padding: '0px 3px',
        top: 0,
        right: -2,
        cursor: 'col-resize',
        backgroundColor: colors.unselectedAlpha,
        '&:hover': {
            backgroundColor: colors.main,
        },
        '&:active': {
            backgroundColor: '#0AA1DD',
        },
    },
    disabledCellIcon: {
        position: 'absolute',
        top: -6,
        right: -4,
    },
    centeredCollapse: {
        '& .MuiBox-root .MuiBox-root-1054': {
            display: 'flex',
            justifyContent: 'center',
        }
    },
    centeredTextWithLeftIcon: {
        textAlign: 'center',
        marginRight: 32,
        fontWeight: 500,
    },
    centeredInputWithLeftIcon: {
        textAlign: 'center',
        marginRight: 32,
    },
}))

// Finds special chars in column label
const getColumnLabel = (col, _defaultLabelSize, orientation) => {
    const headerStyle = {
        fontSize: isDefined(col.headerFontSize) && col.headerFontSize > -1 ? col.headerFontSize : _defaultLabelSize,
        whiteSpace: "break-spaces",
        textAlign: col.labelAlignment || 'center',
        wordBreak: orientation === 'horizontal' ? 'normal' : 'break-word',
    }

    const headerIcon = {
        fontSize: '14px',
        fontWeight: 'bold',
        cursor: 'pointer',
    }

    let template
    let hasTooltip = false
    if (col.label?.includes('~')) {
        const parts = col.label.split("~");
        template = parts.map((part, i) => {
            let section;
            if (part?.includes('#')) {
                hasTooltip = true;
                section = part.split('#')[0] + '...';
            } else section = part;
            if (i !== parts.length - 1) {
                return (
                    <span key={'label-section-' + section} style={headerStyle} onClick={() => devLog('Column', col)}>
                        {
                            col.mandatory && i === 0 &&
                            <PlugTooltip title={i18n.t('common:helper.mandatory-column')}>
                                <span style={headerIcon} >* </span>
                            </PlugTooltip>
                        }
                        { section }<br />
                    </span>
                )
            }
            return (
                <span key={'label-section-' + section} style={headerStyle}>
                    {
                        col.mandatory && i === 0 &&
                        <PlugTooltip title={i18n.t('common:helper.mandatory-column')}>
                            <span style={headerIcon} >* </span>
                        </PlugTooltip>
                    }
                    {section}
                </span>
            )
        })
    } else if (col.label?.includes('#')) {
        const index = col.label.indexOf("#");
        hasTooltip = true;
        template = (
            [
                <span key={'label-slice-' + col.label} style={headerStyle} onClick={() => devLog('Column', col)}>
                    {
                        col.mandatory &&
                        <PlugTooltip title={i18n.t('common:helper.mandatory-column')}>
                            <span style={headerIcon} >* </span>
                        </PlugTooltip>
                    }
                    {col.label.slice(0, index)}
                    <span style={headerStyle}>...</span>
                </span>
            ]
        )
    } else {
        template = (
            [
                <div
                    key={'label-section-' + col.label}
                    style={headerStyle}
                    onClick={() => devLog('Column', col)}
                >
                    {
                        col.mandatory &&
                        <PlugTooltip title={i18n.t('common:helper.mandatory-column')}>
                            <span style={headerIcon} >* </span>
                        </PlugTooltip>
                    }
                    {col.label}
                </div>
            ]
        )
    }
    let label = col.label.replaceAll('#', ' ');
    return (hasTooltip ?
        <PlugTooltip title={label.replaceAll('~', '\n')} placement="top">
            <div style={headerStyle}>
                {template.map(part => part)}
            </div>
        </PlugTooltip>
        :
        template.map(part => part)
    )
}

const handlePreviewFtpFile = (uri, base64, type, filename) => {
    if (isDefined(base64) || isDefined(uri)) {
        switch (type.toLowerCase()) {
        case 'sheet':
        case 'doc':
        case 'docx':
        case 'pages':
        case 'pptx':
        case 'ppt':
        case 'xls':
        case 'xlsx':
        case 'csv': {
            const linkElement = document.createElement('a')
            linkElement.id = filename
            linkElement.href = base64
            linkElement.download = filename
            linkElement.click()
            break
        }

        case 'jpg':
        case 'jpeg':
        case 'png':
        case 'gif':
        case 'mp4':
        case 'mkv':
        case 'avi':
        case 'mov':
        case 'pdf':
        case 'zip':
        case 'rar':
        case 'mp3':
        case 'note':
        case 'aac':
        case 'html':
        case 'txt':
        default:
            window.open(uri, '_blank')
            break
        }
    }
}

const handleSpecialCharsInLabel = (label, classes) => {
    let template;
    let hasTooltip = false;
    if (label?.includes('~')) {
        const parts = label.split("~");
        template = parts.map((part, i) => {
            let section;
            if (part?.includes('#')) {
                hasTooltip = true;
                section = part.split('#')[0] + '...';
            } else section = part;
            if (i !== parts.length - 1) {
                return (<span key={'label-section-' + section} className={classes.fontFieldLabel}>{section} </span>);
            }
            return (<span key={'label-section-' + section} className={classes.fontFieldLabel}>{section}</span>);
        });
    } else if (label?.includes('#')) {
        const index = label.indexOf("#");
        hasTooltip = true;
        template = (
            [<span key={'label-slice-' + label} className={classes.fontFieldLabel}>
                {label.slice(0, index)}
                <span >...</span>
            </span>]
        );
    } else {
        template = (
            [<div
                key={'label-section-' + label}
                className={classes.fontFieldLabel}
            >
                {label}
            </div>]
        );
    }
    let _label = label.replaceAll('#', '');
    return (hasTooltip ?
        <PlugTooltip title={_label.replaceAll('~', ' ')} placement="top">
            <span className={classes.fontFieldLabel}>
                {template.map(part => part)}
            </span>
        </PlugTooltip>
        :
        <>
            {template.map(part => part)}
        </>
    );
}

function getCellColumn(cellId, columns) {
    // devLog('getCell', cellId, columns);
    if (cellId) {
        let column = columns.find(obj => {
            return obj.id.toString() === cellId.toString()
        })
        return column
    }
}

const immutableFormats = ['formula']

const RenderCellValue = (props) => {
    // devLog('RenderValue got props', props)
    const classes = useStyles()
    const columns = props.columns
    const value = props.value
    const cellId = props.id
    const column = getCellColumn(cellId, columns)
    const [modalStyle] = useState(getModalStyle);
    const { t } = useTranslation(['common']);
    const [openPreviewFromFtp, setOpenPreviewFromFtp] = useState(false);
    const [base64FromFtp, setBase64FromFtp] = useState(null)
    const [uriFromFtp, setUriFromFtp] = useState(null)
    const [openNotePreview, setOpenNotePreview] = useState(false)
    const [note, setNote] = useState(null)
    const [selectedTime, setSelectedTime] = useState(null)

    const handleOpenBase64Preview = (e, value) => {
        e.preventDefault()
        e.stopPropagation()
        trackPromise(
            axios.get(ApplicationConfig.apiUrl + 'ftp/preview/annexe?uri=' + value,
                {
                    crossdomain: true,
                    'headers': {
                        'X-AUTH-TOKEN': getToken(),
                    }
                }
            ).then((response) => {
                if (response.data.result) {
                    setBase64FromFtp(response.data.base64);
                    setUriFromFtp(response.data.uri);
                    // setOpenPreviewFromFtp(true);
                    let typeId = columns.find((col => col.format === 'file-type-text'));
                    let nameId = columns.find((col => col.format === 'file-name-text'));
                    let type = props.row[typeId.id];
                    let filename = props.row[nameId.id];
                    handlePreviewFtpFile(response.data.uri, response.data.base64, type, filename)
                } else {
                    console.error(response);
                }
            }).catch((response) => {
            })
        )
    }

    // const renderAnnexeImagePreview = () => {
        // try {
        //     let filePathId = columns.find((col => col.format === 'file-uri'));
        //     let filePath = props.row[filePathId.id];
        //     let fileInfos = getFilenameAndExtension(filePath);
        //     let name = fileInfos[0];
        //     let type = fileInfos[1];

        //     switch (type.toLowerCase()) {
        //         case "jpg":
        //         case "JPG":
        //         case "jpeg":
        //         case "png":
        //         case "PNG":
        //         case "gif":
        //             return (
        //                 <img
        //                     src={base64FromFtp}
        //                     alt={name}
        //                 />
        //             );
        //         case "mp4":
        //         case "mkv":
        //         case "avi":
        //         case "mov":
        //             return (
        //                 <video className={classes.modalFile} controls>
        //                     <source
        //                         src={base64FromFtp}
        //                         type={`video/${type.toLowerCase()}`}
        //                     />
        //                 </video>
        //             );
        //         case "pdf":
        //             return (
        //                 <iframe title={'iframe-' + name} src={uriFromFtp} className={classes.modalFile + ' ' + classes.iframe} />
        //             );
        //         case "html":
        //         case "txt":
        //             return (
        //                 <iframe title={'iframe-' + name} src={base64FromFtp} className={classes.modalFile + ' ' + classes.iframe} />
        //             );
        //         case "zip":
        //         case "rar":
        //             return (
        //                 <div className={classes.notRender}>
        //                     <FontAwesomeIcon
        //                         icon={faFileArchive}
        //                         className={classes.faIconView}
        //                     />
        //                     <p class={classes.notRenderText}>
        //                         {t("multiple.file-not-previewed")}
        //                     </p>
        //                 </div>
        //             );
        //         case "mp3":
        //         case "aac":
        //             return (
        //                 <audio className={classes.modalFile} controls>
        //                     <source
        //                         src={base64FromFtp}
        //                         type={`audio/${type.toLowerCase()}`}
        //                     />
        //                 </audio>
        //             );
        //         case "sheet":
        //         case "doc":
        //         case "docx":
        //         case "pptx":
        //         case "ppt":
        //         case "xls":
        //         case "xlsx":
        //         case "csv":
        //             return (
        //                 <iframe title={'iframe-' + name}
        //                     src={`https://view.officeapps.live.com/op/embed.aspx?src=${base64FromFtp}`}
        //                     className={classes.modalFile + ' ' + classes.iframe}
        //                 />
        //             );
        //         case "note":
        //             return (
        //                 <div className={classes.notRender}>
        //                     <FontAwesomeIcon
        //                         icon={faStickyNote}
        //                         className={classes.faIconSize}
        //                     />
        //                     <p class={classes.notRenderText}>
        //                         {t("multiple.file-not-previewed")}
        //                     </p>
        //                 </div>
        //             );
        //         case "pages":
        //             return (
        //                 <div className={classes.notRender}>
        //                     <FontAwesomeIcon icon={faFileWord} className={classes.faIconSize} />
        //                     <p class={classes.notRenderText}>
        //                         {t("multiple.file-not-previewed")}
        //                     </p>
        //                 </div>
        //             );
        //         default:
        //             return (
        //                 <div className={classes.notRender}>
        //                     <FontAwesomeIcon icon={faFile} className={classes.faIconView} />
        //                     <p class={classes.notRenderText}>
        //                         {t("multiple.file-not-previewed")}
        //                     </p>
        //                 </div>
        //             );
        //     }
        // } catch (e) {
        //     devLog('Render error', e)
        // }
        // if (isDefined(base64FromFtp) && isDefined(uriFromFtp)) {
        //     window.open(uriFromFtp, "_blank")
        //     setBase64FromFtp(null)
        //     setUriFromFtp(null)

            // return (
            //     <RenderFilePreview
            //         classes={classes} t={t}
            //         annexe={props.row} colonnes={columns}
            //         base64={base64FromFtp} uri={uriFromFtp}
            //         handleClose={handleCloseFTPPreviewModal} />
            // )
    //     }
    // }

    const handleCloseFTPPreviewModal = (_, reason) => {
        setBase64FromFtp(null)
        setUriFromFtp(null)
        if (reason === 'backdropClick') setOpenPreviewFromFtp(false)
    }

    const handleOpenNote = (hash) => {
        let _note = props.annexes.find(entry => entry.hash === hash)
        setOpenNotePreview(true)
        setNote(_note)
    }

    const handleCloseNote = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setOpenNotePreview(false)
        setNote(null)
    }

    const openInNewTab = (e, url) => {
        e.preventDefault()
        e.stopPropagation()
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    const handleConsultNote = e => {
        e.preventDefault()
        e.stopPropagation()
        handleOpenNote(value)
    }

    const textStyle = {
        fontSize: isDefined(props.defaultFontsize) ? props.defaultFontsize : 11,
        wordBreak: 'break-word',
    }
    if (isDefined(column.fontSize) && column.fontSize > -1) {
        textStyle.fontSize = column.fontSize
    }
    const rowAlignStyle = {}
    if (column.format === 'presel') {
        let col = props.columns.find(entry => entry.id.toString() === props.id)
        let rowAlign = col.rowAlignment
        switch (rowAlign) {
            case 'left':
                rowAlignStyle.justifyContent = 'flex-start'
                break
            case 'right':
                rowAlignStyle.justifyContent = 'flex-end'
                break
            default:
                rowAlignStyle.justifyContent = 'center'
                break
        }
    }

    // if (column.format === 'duration') { devLog('RenderCellValue props', props, textStyle) }
    let decimalSeparator = ','
    let thousandSeparator = '.'
    if (i18n.language === 'en') {
        decimalSeparator = '.'
        thousandSeparator = ','
    }
    switch (column.format) {
        case 'file-uri':
            if (isDefined(value && value.length > 0)) {
                let fileSource = props.columns.find(column => column.format === 'file-source');
                if (fileSource) {
                    switch (props.row[fileSource.id]) {
                        case 'PLUGNOTE':
                            return (
                                <div className={classes.cellPreviewCtn}>
                                    {
                                        value?.includes('.') && !value?.includes('MISSING_FILE_')
                                            ? (
                                                <span onClick={(e) => openInNewTab(e, value.toString())} data-skip="" className={classes.urlButton} rel="noreferrer noopener">
                                                    <span className="material-icons md-small white">link</span>
                                                </span>
                                            )
                                            : (
                                                <PlugTooltip title={t(value?.includes('MISSING_FILE_') ? 'document.missing' : 'global.open')} >
                                                    <span data-skip="" className={classes.urlButton} rel="noreferrer noopener" onClick={(e) => { if (!value?.includes('MISSING_FILE_')) handleConsultNote(e) }}>
                                                        {
                                                            value?.includes('MISSING_FILE_')
                                                                ? <MissingDocumentIcon color="white" size="22" />
                                                                : <span className="material-icons md-small white">visibility</span>
                                                        }
                                                    </span>
                                                </PlugTooltip>
                                            )
                                    }
                                    {
                                        isDefined(note) &&
                                        <ModalNotePreviewComponent open={openNotePreview} handleClose={handleCloseNote} note={note} />
                                    }
                                </div>
                            )
                        case 'URL':
                            return (
                                <div className={classes.cellPreviewCtn}>
                                    <span onClick={(e) => openInNewTab(e, value.toString())} data-skip="" className={classes.urlButton} rel="noreferrer noopener"><span className="material-icons md-small white">link</span></span>
                                </div>
                            )
                        case 'FTP':
                            return (
                                <div className={classes.cellPreviewCtn}>
                                    <span className={classes.urlButton} rel="noreferrer" onClick={(e) => handleOpenBase64Preview(e, value.toString())}><span className="material-icons md-small white">link</span></span>
                                    {/* {
                                        openPreviewFromFtp &&
                                        <Modal
                                            key={props.rindex + '-' + fileSource.id}
                                            keepMounted={true}
                                            open={openPreviewFromFtp}
                                            onClose={handleCloseFTPPreviewModal}
                                            aria-labelledby="simple-modal-title"
                                            aria-describedby="simple-modal-description"
                                            className={classes.modal}
                                        >
                                            <div style={modalStyle} className={classes.popup} onClick={handlePreventDefault}>
                                                <div className={classes.scaledImg}>
                                                    {renderAnnexeImagePreview()}
                                                </div>
                                            </div>
                                        </Modal>
                                    } */}
                                </div>
                            )
                        default:
                            break;
                    }
                }
                break;
            } else return null
        case 'numeric':
            return (
                <NumericFormat
                    value={value} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} displayType="text"
                    renderText={
                        (value, _) => <span style={textStyle}>
                            {value}
                        </span>}
                />
            )
        case 'url':
            if (isDefined(value && value.length > 0)) {
                return (
                    <div className={classes.cellPreviewCtn}>
                        <span style={textStyle}>{value}</span>
                        <span onClick={() => openInNewTab(value.toString())} data-skip="" className={classes.urlButton} rel="noreferrer noopener">
                            <span className="material-icons md-small white">link</span>
                        </span>
                    </div>)
            } else return null

        case 'radio':
            return (
                column.mask === 'Checkbox'
                ? (
                    <div
                        style={{ justifyContent: 'center', flex: 1 }}>
                        <ColorCheckbox
                            checked={value === 'Y'}
                            color={value === '' ? colors.greyLight : colors.main}
                            size={30}
                        />
                    </div>
                ) : <span style={textStyle}>{value}</span>
            )
        case 'file-source':
        case 'file-refid':
        case 'file-name-text':
        case 'file-comment-text':
            return <span style={textStyle}>{value}</span>
        case 'file-type-text':
        case 'file-size-num':
        case 'file-create-date':
        case 'date':
        case 'duration':
        case 'intdata':
        case 'formula':
        case 'mail':
        case 'select':
        case 'tel':
        case 'text':
        case 'textarea':
        case 's33data':
        case 'combofillextdata':
            return <span style={textStyle}>{value}</span>
        case 'time': //KeyboardTimePicker (hidden input, only icon & modal)
            let currentFieldMask = column?.mask ? column.mask : 'HH:MM (24H)'
            if(!props.isPreview) {
                let maskFormat='HH:mm'
                let pickerViews = ['hours', 'minutes']
                let minutesStepOption
                let tmpValue = value

                switch (currentFieldMask) {
                    case undefined: //console.log("maskFormat is UNDEFINED")
                        maskFormat='HH:mm'
                        break
                    case 'HH:MM (15min)':
                        pickerViews = ['hours', 'minutes']
                        maskFormat='HH:mm'
                        minutesStepOption = 15
                        break
                    case 'HH:MM (24H)':
                    case 'Free Text':
                        pickerViews = ['hours', 'minutes']
                        maskFormat='HH:mm'
                        break
                    case 'HH H MM':
                        if(value?.length > 0 && value?.includes(':'))
                        {
                            //tmpValue = value?.replace(':', 'H')
                            tmpValue = value?.split(':')
                            tmpValue = tmpValue[0] + " H " + tmpValue[1]
                        }
                        pickerViews = ['hours', 'minutes']
                        maskFormat='HH:mm'
                        break
                    case 'HH:MM:SS':
                        pickerViews = ['hours', 'minutes', 'seconds']
                        maskFormat='HH:mm:ss'
                        break
                    case 'MM:SS (60min)':
                        pickerViews = ['minutes', 'seconds']
                        maskFormat='mm:ss'
                        break
                    default:
                        break
                }

                function handleTimeChange(date,newValue) {
                    if(currentFieldMask === "HH:MM (15min)") {
                        if(!isUndefined(minutesStepOption) && newValue !== "") { // CASE HH:MM (15min)
                            let timeRounded = roundQuarter(newValue)
                            newValue = timeRounded.roundedHours + ":" + timeRounded.roundedMinutes
                            let newDateTime = new Date(null, null, null, timeRounded.roundedHours, timeRounded.roundedMinutes)
                            props.handleChangeChildrenValue(newValue)
                            setSelectedTime(newDateTime) //timepicker clock state
                        }
                    } else {
                        props.handleChangeChildrenValue(newValue)
                        setSelectedTime(date) //timepicker clock state
                    }
                }

                return (
                    <div className={classes.timeElementsCtnCellView}>
                        {props.isEditable && !props.disabled &&
                            <div className={classes.timeComponentCtnCellView}
                                 onClick={(event) => {handlePreventDefault(event)}}
                            >
                                <TimeComponentWithOptions
                                    {...props}
                                    className={`${classes.timePickerIcon + ` ` + classes.timePicker}`}
                                    ampm={false}
                                    autoComplete="off"
                                    disabled={props.disabled}
                                    format={maskFormat}
                                    InputAdornmentProps={{ position: 'start' }}
                                    InputProps={{ disableUnderline: true }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time',
                                    }}
                                    keyboardIcon={<Icon>access_time</Icon>}
                                    onChange={(date, value) => {handleTimeChange(date, value)}}
                                    minutesStep={isDefined(minutesStepOption) ? minutesStepOption : undefined}
                                    value={selectedTime || null} // https://github.com/mui/material-ui-pickers/issues/1544
                                    views={pickerViews}
                                />
                            </div>
                        }
                        <span className={classes.marginAuto} style={textStyle}>{currentFieldMask === "HH H MM" ? tmpValue : value}</span>
                    </div>
                )
            }
            else {return <span style={textStyle}>{value}</span>}
        case 'presel':
            if (column.mask.multiselect === true && Array.isArray(value)) return (
                <div className={classes.selectsCtn}>
                    {value.length > 0 &&
                        value
                            .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
                            .map((value, index) => (
                                <div className={classes.selectItem} key={index}>
                                    <span key={`select-item-${index}`} style={textStyle}>{value}</span>
                                </div>))}
                </div>
            );
            else return (
                <div className={classes.selectsCtn} style={rowAlignStyle}>
                    <div className={classes.selectItem}>
                        <span key={`select-item`} style={textStyle}>{value}</span>
                    </div>
                </div>
            )
        case 'numeric-ref':
            let tmpValue = value;
            if (typeof tmpValue !== 'undefined' && tmpValue == '') {
                let target = column?.configuration?.target;
                if (typeof target !== "undefined") {
                    let field = props.fields.find((field, index) => Number(field.id) === Number(target));
                    if (field) {
                        tmpValue = field.value;
                    }
                }
            }
            return <span style={textStyle}>{tmpValue}</span>
        case 'intdatamaj':
            if (typeof value.value !== "undefined" && value.updatedDate !== "undefined") {
                return <span style={textStyle}>{value.value} {value.updatedDate}</span>
            } else if (typeof value.value !== "undefined" && value.updatedDate === "undefined") {
                return <span style={textStyle}>{value.value}</span>
            } else {
                return <span style={textStyle} />
            }
        case 'extdata':
            return (
                <div style={{...textStyle, display: 'flex', flexDirection: 'row' }}>
                    {
                        !props.disabled &&
                        <span className="material-icons" style={{ left: 8 }}>
                            search
                        </span>
                    }
                    <span style={{ flex: 1, textAlign: 'center' }}>{value}</span>
                </div>
            )
        default:
            return (<span className={classes.columnHeader}>{`<!> ERROR <!>`}</span>)
    }
}

const ConfigModal = (props) => {
    // devLog('ConfigModal Tablemulti got props', props)
    const classes = useStyles();
    const { t } = useTranslation(['common', 'models']);
    const email = props.value
    const [phone, setPhone] = useState("");
    const [rules, setRules] = useState(-1);
    const [shareList, setShareList] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [hasChanged, setHasChanged] = useState(false)
    const external = props?.external || false

    const handleChangeRules = (event) => {
        setRules(event)
        setHasChanged(true)
    }

    const handleSubmitShare = () => {
        if (getPlugnote() !== null) {
            let tmpRules = Number(rules.toString())

            if ((email.length > 0 && tmpRules !== -1 && !external) || (email.length > 0 && tmpRules !== -1 && isValidPhoneNumber(phone) && external)) {
                let payload = {
                    read_fiche: "1",
                    edit_fiche: tmpRules >= 1 ? "1" : "0",
                    active: "1",
                    corporate: "0",
                    datahash: getPlugnote().hash,
                    email: props.value,
                    phone: isValidPhoneNumber(phone) && phone.length > 0 ? phone : ""
                };

                trackPromise(
                    postShareDataWithEmailUser(payload)
                        .then((response) => {
                            if (response.data.result) {
                                setShareList(response.data.data);
                                let code = response.data.data.readFiche.toString() + response.data.data.editFiche.toString();
                                switch (code.toString()) {
                                    case "10":
                                        setRules(0);
                                        break;
                                    case "11":
                                        setRules(1);
                                        break;
                                    default:
                                        break;
                                }
                                setEditMode(true);
                                props.setPwCode(response.data.data.code);
                                props.setPwField(response.data.data.code + ' ' + t('smailvalidmulti.unsent'))
                            } else if (!response.data.result) {
                                if (response.data.reason === 'user not exist') {
                                    confirmAlert({
                                        title: t('global.plugnotes'),
                                        message: t('common:global.user-not-exist'),
                                        buttons: [
                                            {
                                                label: t('global.ok'),
                                            },
                                        ]
                                    });
                                } else if (response.data.reason === 'datahash error') {
                                    confirmAlert({
                                        title: t('global.plugnotes'),
                                        message: t('common:global.plugnote-not-exist'),
                                        buttons: [
                                            {
                                                label: t('global.ok'),
                                            },
                                        ]
                                    })
                                } else {
                                    confirmAlert({
                                        title: t('global.plugnotes'),
                                        message: t('common:global.error'),
                                        buttons: [
                                            {
                                                label: t('global.ok'),
                                            },
                                        ]
                                    });
                                }


                            }
                        })
                        .catch((response) => {
                            alert('error:\n' + response + '\n' + t('smailvalidmulti.unregistered') + ' ?')
                        })
                );
            }
        }

    };

    const handleUpdateShare = (share) => {
        let tmpRules = Number(rules.toString());
        if ((email.length > 0 && tmpRules !== -1 && !external) || (email.length > 0 && tmpRules !== -1 && isValidPhoneNumber(phone) && external)) {
            let payload = {
                read_fiche: "1",
                edit_fiche: tmpRules >= 1 ? "1" : "0",
                active: "1",
                corporate: "0",
                datahash: props.plugnote.hash,
                email: props.value,
                hash: share.hash,
                phone: isValidPhoneNumber(phone) && phone.length > 0 ? phone : ""
            };
            trackPromise(
                putShareDataWithEmailUser(payload).then((response) => {
                    if (response.data.result) {
                        setShareList(response.data.data);
                        let code = response.data.data.readFiche.toString() + response.data.data.editFiche.toString();
                        switch (code.toString()) {
                            case "10":
                                setRules(0);
                                break;
                            case "11":
                                setRules(1);
                                break;
                            default:
                                break;
                        }
                        if (response.data.data.phone !== null) {
                            setPhone(response.data.data.phone)
                        }
                        setEditMode(true);
                        props.setPwCode(response.data.data.code);
                    }
                }).catch((response) => {
                    alert('error');
                })
            );
        }
        setHasChanged(false)
    }

    const handleCancelShare = (share) => {
        confirmAlert({
            title: t('global.confirm'),
            message: t('share.model-share-confirm-delete'),
            buttons: [
                {
                    label: t('global.ok'),
                    onClick: () => {
                        trackPromise(
                            deleteShareDataWithEmailUser(share.hash).then((response) => {
                                if (response.data.result) {
                                    props.setPwCode("");
                                    props.close();
                                }
                            }).catch((response) => {
                                alert('error');
                            })
                        );
                    }
                },
                { label: t('global.cancel') },
            ]
        });
    };

    const handleOpenMailModal = () => {
        props.openMail(shareList.code)
    }

    const handleOnCLose = (_, reason) => {
        // devLog('close reason', reason)
        if (reason && reason === 'backdropClick') { return }
        props.close()
    }

    useEffect(() => {
        if (props?.plugnote?.hash && props.value !== "") {
            trackPromise(
                getShareDataWithEmailUser(props.plugnote.hash, props.value).then((response) => {
                    if (response.data.result) {
                        // devLog('Response', response.data)
                        setShareList(response.data.data)
                        let code = response.data.data.readFiche.toString() + response.data.data.editFiche.toString();
                        switch (code.toString()) {
                            case "10":
                                setRules(0);
                                break;
                            case "11":
                                setRules(1);
                                break;
                            default:
                                break;
                        }
                        if (response.data.data.phone !== null) {
                            setPhone(response.data.data.phone)
                        }
                        setEditMode(true)
                        props.setPwCode(response.data.data.code)
                    }
                }).catch((response) => {
                    console.error(
                        "Could not Download the Excel report from the backend.",
                        response
                    );
                })
            )
        }
    }, [])

    return (
        <Dialog open={props.open} onClose={handleOnCLose} fullWidth={true} maxWidth="md" disableEscapeKeyDown onClick={handlePreventDefault}>
            <Header>
            </Header>
            <div className={classes.mSeparatorWrapper} />
            <Content>
                <div>
                    <div className="scrollbar-hidden popup_wrapper flexSpacebetween">
                        <div className="popup_wrapper_content_small">
                            <div className="sous-header_content">
                                <div>
                                    <span className="titres">
                                        {t('common:smailvalidmulti.config-title')}{email}
                                        {
                                            external &&
                                            <PlugTooltip title={t('smailvalidmulti.unregistered')} placement="bottom">
                                                <span id="icon-create" className="material-icons md-small" style={{ color: colors.bloodOrange, fontSize: 24 }}>
                                                    info
                                                </span>
                                            </PlugTooltip>
                                        }
                                        <br />
                                    </span>
                                </div>
                                <div>
                                    <span id="icon-close" className="material-icons md-50 lightgrey" onClick={props.close}>
                                        close
                                    </span>
                                </div>
                            </div>
                            <div className="sous-header_content lightgrey">
                                <div className="line flex_style">
                                </div>
                            </div>
                            <br />
                            <div className="flexSpacebetween">
                                <div className=" split-40">
                                    <span className="grey">{t('plugcode.rules')}</span>
                                    <div className={classes.RadioGroupModal} name={t('common:plugcode.rules')} value={rules}>
                                        <div className={classes.showOption} onClick={() => handleChangeRules(0)}>
                                            <input type='radio' size='small' value={0} checked={rules === 0} className={classes.radioIconModal} onChange={(e) => null} />
                                            <span className={classes.radioLabelModal}>
                                                {t('common:plugcode.rule-read')}
                                                <span> (</span>
                                                <span id="icon-visibility" className="material-icons md-small green">
                                                    visibility
                                                </span>
                                                <span>) </span>
                                            </span>
                                        </div>
                                        <div className={classes.showOption} onClick={() => handleChangeRules(1)}>
                                            <input type='radio' size='small' name={"show-empty-option"} value={1} checked={rules === 1} className={classes.radioIconModal} onChange={(e) => null} />
                                            <span className={classes.radioLabelModal}>
                                                {t('common:plugcode.rule-read-edit')}
                                                <span> (</span>
                                                <span id="icon-visibility" className="material-icons md-small green">
                                                    visibility
                                                </span>
                                                <span id="icon-visibility" className="material-icons md-small green">
                                                    create
                                                </span>
                                                <span>) </span>
                                            </span>
                                        </div>
                                    </div>
                                    {
                                        external &&
                                        <div className={classes.mainPhoneContainer}>
                                            <span className="grey">{t('common:plugcode.phones-access')}</span>
                                            <div className={classes.phoneContainer}>
                                                <PhoneInput
                                                    className={`${classes.phoneComponent} phoneComponent phoneSmailComponent`}
                                                    defaultCountry="BE"
                                                    international
                                                    value={phone}
                                                    placeholder={t('fields.format-choice-placeholder-phone')}
                                                    onClick={(e) => e.target.placeholder = ''}
                                                    onBlur={(e) => e.target.placeholder = t('fields.format-choice-placeholder-phone')}
                                                    onChange={(value) => {
                                                        setPhone(value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }
                                </div>

                                {
                                    editMode === false ?
                                    <div className="split-60">
                                        <div className="confirmation_pop-up" onClick={handleSubmitShare}>
                                            {
                                                external ?
                                                    <button className={rules === -1 || !isValidPhoneNumber(phone) || phone?.length === 0 ? "grey-button" : "button"}>{t('common:global.create')} PW- code</button>
                                                    :
                                                    <button className={rules === -1 ? "grey-button" : "button"}>{t('common:global.create')} PW- code</button>
                                            }

                                        </div>
                                    </div>
                                    :
                                    <div className="split-60">
                                        <span className="grey">{t('smailvalidmulti.current-link')}</span>

                                        {shareList !== null &&
                                            <div className={`grey ${classes.pwcodeContainer}`}>
                                                {/* <span className={"info-maximum-width "}>{shareList.email}</span> */}
                                                <span className="grey">{shareList.code}</span>
                                                <div>
                                                    <span>(</span>
                                                    <span id="icon-visibility" className="material-icons md-small green">
                                                        visibility
                                                    </span>
                                                    {shareList.editFiche === 1 &&
                                                        <span id="icon-create" className="material-icons md-small green">
                                                            create
                                                        </span>}
                                                    <span>)</span>
                                                </div>
                                                <span id="icon-create" className="material-icons md-small red" onClick={() => handleCancelShare(shareList)}>
                                                    delete
                                                </span>
                                            </div>
                                        }

                                    </div>}

                            </div>
                            {
                                external &&
                                <span>
                                    <div>
                                        <span id="icon-create" className="material-icons md-small" style={{ color: colors.bloodOrange, fontSize: 24 }}>
                                            info
                                        </span>
                                        <span style={{ whiteSpace: 'pre-line' }}>{t('smailvalidmulti.unregistered')}</span>
                                        {
                                            phone.length < 9 &&
                                            <span style={{ whiteSpace: 'pre-line' }}> - {t('smailvalidmulti.enter-mobile')}</span>
                                        }
                                    </div>
                                    <span style={{ whiteSpace: 'pre-line' }}>{t('smailvalidmulti.info-external')}</span>
                                </span>
                            }
                        </div>

                        <div className="popup_wrapper_content">
                            {editMode === false ?
                                null
                                :
                                <div className={classes.modalBtnContainer}>

                                    <div className="confirmation_pop-up">
                                        <button className="button-exit" onClick={() => handleUpdateShare(shareList)} style={hasChanged ? null : { display: 'none' }}>{t('common:smailvalidmulti.update-rules')}</button>
                                    </div>

                                    <div className="confirmation_pop-up" style={{ float: 'right' }}>
                                        <button className="button" onClick={handleOpenMailModal}>{t('common:annexe.send-email')}</button>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </Content>
        </Dialog>
    )
}

const restylizeNumber = (number) => {
    if ((typeof number === "number" || typeof number === 'string') && number !== '') {
        let nombre = 0
        let coma = false
        if (typeof number === "number") nombre = number;
        else if (number.charAt(number.length - 1) === ',') {
            nombre = parseFloat(number.slice(0, -1))
            coma = true
        }
        else nombre = parseFloat(number.replace(',', '.'))
        // devLog('nombre: ', number, nombre);
        let restyled = ''
        let ent = 0
        ent = Math.floor(nombre);
        // devLog('ent', ent);
        let stInt = ''
        let decim = number.toString().split('.')[1]
        if (!number.toString()?.includes('.')) decim = number.toString().split(',')[1]
        // devLog('decim', decim);
        if (nombre > 999) {
            ent += ''
            let rank = 1
            for (let index = ent.length - 1; index >= 0; index--) {
                const nb = ent.charAt(index);
                if (rank % 3 === 0 && index >= 1 && rank !== 0) stInt = '.' + nb + stInt;
                else stInt = nb + stInt.toString()
                rank++;
                // devLog(rank, '(',index, ') ',nombre,'- current char', nb, 'result =', stInt);
            }
        }
        else stInt = ent.toString()
        if (decim !== undefined) restyled = stInt + ',' + decim;
        else restyled = stInt
        // devLog('restyled', restyled);
        return restyled.concat(coma ? ',' : '')
    }
    return ''
}

const RenderFolderActionTemplate = (props) => {
    const useInternalStyles = makeStyles({
        root: {
            '&$selected': {
                backgroundColor: '#B9E5E4',
                '&:hover': {
                    backgroundColor: '#B9E5E4',
                }
            },
        },
        selected: {},
    });

    const classes = useInternalStyles();


    const [collapseFolderIndex, setCollapseFolderIndex] = useState(null);

    const handleClickOpenSubList = (index) => {
        if (index === collapseFolderIndex) {
            setCollapseFolderIndex(null)
        } else setCollapseFolderIndex(index);
    }

    return (
        <div className="margin-left-20" key={props.index}>
            <ListItem
                button
                selected={props.element === collapseFolderIndex}
                onClick={(event) => handleClickOpenSubList(props.element)}
                classes={{ root: classes.root, selected: classes.selected }}
            >
                <ListItemText primary={props.element.name} />
                {collapseFolderIndex !== null && props.element === collapseFolderIndex ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={collapseFolderIndex !== null && props.element === collapseFolderIndex} timeout="auto" unmountOnExit>
                <List component="nav" aria-label="secondary mailbox folder">
                    {
                        props.element.files.map((annexe, anindex) => {
                            if (annexe.content === 'FILE') {
                                return <RenderFileActionTemplate key={anindex} {...props} element={annexe} index={anindex} handleListItemFromFtpClick={props.handleListItemFromFtpClick} />
                            } else return <RenderFolderActionTemplate key={anindex} {...props} element={annexe} index={anindex} handleListItemFromFtpClick={props.handleListItemFromFtpClick} />
                        })
                    }
                </List>
            </Collapse>
        </div>
    )
}

const RenderFileActionTemplate = (props) => {
    return (
        <div className="margin-left-20" key={props.index}>
            <ListItem
                button
                //selected={selectedIndex === 3}
                onClick={(event) => props.handleListItemFromFtpClick(props.element, props.index, props.row, props.col, props.rindex)}
            >
                <ListItemText primary={props.element.name + ' (' + props.element.type + ' )'} />
            </ListItem>
        </div>
    )
}
export const RenderEditCellComponent = (props) => {
    // devLog('RenderEdit Cell props', props)
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle)
    let template;
    const { t } = useTranslation(['common'])

    //Props const
    const field = props.field
    const fields = props.fields
    const model = props.model
    const plugnote = props.plugnote
    const user = props.user
    const column = props.col

    const getInitialValue = () => {
        let tmpValue = "";
        if (typeof props.value !== "undefined" && (props.col.format !== 'numeric' || props.col.format !== 'numeric-ref' || props.col.format !== 'intdatamaj') && props.value.length > 0) {
            tmpValue = props.value;
        } else if (typeof props.value !== "undefined" && props.value !== null && props.col.format === 'numeric') {
            tmpValue = restylizeNumber(props.value);
        } else if (typeof props.value !== "undefined" && props.value !== null && props.col.format === 'numeric-ref') {
            //devLog(props.value);
            if (props.value === "") {
                let target = props?.col?.configuration?.target;
                //devLog('target' , target);
                if (typeof target !== "undefined") {
                    let field = props.fields.find((field, index) => Number(field.id) === Number(target));
                    if (field) {
                        //devLog('field',field);
                        tmpValue = field.value;
                    }
                }
            } else {
                tmpValue = props.value;
            }
        } else if(typeof props.value !== "undefined" && props.value !== null && props.col.format === 'intdatamaj'){
            if (typeof props.value.value !== 'undefined') {
              tmpValue = props.value
            } else {
              tmpValue = {
                value: '',
                updatedDate: ''
              }
            }
        }
        // if (column.format === 'extdata') { devLog('got initial value', tmpValue) }
        return tmpValue
    }

    let sentDate = t('smail.not-sent')
    if (column.id === 1001 && props.row[1000]?.length > 15) {
        sentDate = props.row[1000].split(' - ')[1]
    }

    let currentValue = null // TimePicker value
    const [selectedTime, setSelectedTime] = useState(currentValue)
    const [value, setValue] = useState(getInitialValue());
    const [valueChange, setValueChange] = useState(getInitialValue());
    const disabled = props.disabled === true
    const isEditable = isEditableDefaultValue(field, props.col.id, props.rindex, props.mode, props.isFromPwCode)
    const owner = typeof props.owner === 'undefined' ? null : props.owner
    const ref = props?.col?.configuration?.ref || ""
    const query = props?.col?.configuration?.query || ""
    const dbcolumn = props?.col?.configuration?.column || ""
    const dbcolumns = props?.col?.configuration?.columns || ""
    const options = props?.col?.configuration || { ref: "", query: "LIKE", columns: "", column: "", dest: "" }
    const dest = props?.col?.configuration?.dest || ""

    const hideSearch = isDefined(props?.model?.authoriseExternalDbInPf) ? (!props?.model?.authoriseExternalDbInPf && props.mode.includes('plugcode')) : false
    const formula = props?.col?.configuration?.formula || null
    const ownerId = props.ownerId
    const [open, setOpen] = useState(false);
    const [openComboFill, setOpenComboFill] = useState(false);
    const [openDefault,] = useState(props.open === true ? true : false);
    const [openSelect, setOpenSelect] = useState(false)
    const [loading, setLoading] = useState(false);
    const [optionFormule,] = useState({ licenseKey: 'gpl-v3' });
    const [accept, setAccept] = useState(false)
    const columns = props.columns

    //SmailValid
    const [contentTooltip, setContentTooltip] = useState('')
    const [customAnnexe, setCustomAnnexe] = useState(null)
    const [openConfigurator, setOpenConfigurator] = useState(false)
    const [openExternalConfigurator, setOpenExternalConfigurator] = useState(false)
    const [openPromised, setOpenPromised] = useState(false)
    const [openMail, setOpenMail] = useState(false)
    const [pwCode, setPwCode] = useState(props.row[1000]?.includes('PW') ? props.row[1000] : '')
    const [pwField, setPwField] = useState(props.row[1000] || '')
    const [registered, setRegistered] = useState(pwField !== '')
    const [templateOn,] = useState(props.field?.options?.template || "")
    const [openListAnnexes, setOpenListAnnexes] = useState(false)
    const [openListAnnexesFromFtp, setOpenListAnnexesFromFtp] = useState(false)
    const [foldersFromFtp, setFoldersFromFtp] = useState([])
    const [openPreviewFromFtp, setOpenPreviewFromFtp] = useState(false)
    const [base64FromFtp, setBase64FromFtp] = useState(null)
    const [uriFromFtp, setUriFromFtp] = useState(null)
    const [pdfBlob, setPdfBlob] = useState(null)
    const [plugnoteCopy, setPlugnoteCopy] = useState(null)
    const { promiseInProgress } = usePromiseTracker()

    let unregisteredMsg = t('smailvalidmulti.unregistered')

    let titleCtn,
        fieldCtn,
        widerCtn,
        editorCtn,
        inputStyle = classes.tablemultiInput

    // styles différents selon que l'on édite une cellule seule ou toute une ligne
    switch (props.edit) {
        case "row":
            titleCtn = classes.fieldTitleContainer
            fieldCtn = classes.fieldValueContainer
            widerCtn = classes.widerFieldValueContainer
            editorCtn = classes.editorCtn
            inputStyle = `${classes.tablemultiInput} ${classes.backgroundInput}`
            break;
        case "cell":
            titleCtn = classes.singleTitleCtn
            fieldCtn = classes.singleValueCtn
            widerCtn = classes.singleWiderValueCtn
            editorCtn = classes.singleEditorCtn
            break;
        case "preview":
            fieldCtn = classes.sendMailPreviewCtn
            break
        default:
            break
    }

    const handleOnBlur = (e) => {
        if (e.key === 'Enter') {
            if (column.format === 'extdata') handleOpenSearchModel()
            if (props.edit === 'cell') {
                props.handleValidate(e)
            }
        }
    }

    const handleOpenSearchModel = () => {
        props.attemptGetDataFromAnotherBaseByClientReset()
        setOpen(true)
    }

    const handleOpenSearchCombo = () => {
        props.attemptGetDataFromAnotherBaseByClientReset()
        setOpenComboFill(true)
    }

    const handleChangeTableValue = (newValue) => {
        devLog('handle change ', newValue)
        if (!disabled) {
            if (props.col.format === 'duration') { setAccept(false) }
            props.handleChangeChildrenValue(newValue)
            setValue(newValue)
        }
    }

    const handleGetValueFromTable = (col, fields, value) => {
        if (value !== "") {
            let target = col?.configuration?.target;
            let targettable = col?.configuration?.targettable;
            //devLog('target' , target);
            if (typeof target !== "undefined") {
                let field = fields.find((field, index) => Number(field.id) === Number(target));
                if (field) {
                    let tmpValue = field?.value?.list ? field.value.list : field.value;
                    if (tmpValue.length > 0 && Array.isArray(tmpValue)) {
                        let options = field.options?.lines ? field.options.lines : field.options;
                        setValue(tmpValue[(value - 1)][options[(targettable - 1)].id]);
                        handleChangeTableValue(tmpValue[(value - 1)][options[(targettable - 1)].id]);
                    }

                }
            }
        }
    }

    const handleChangeChildrenRowWithPreValueValue = (value) => {
        setValue(value)
        if (value === 'URL') {
            let data = { ...props.row }
            props.columns.forEach((col, index) => {
                switch (col.format) {
                    case 'file-source':
                        data[col.id] = value;
                        break;
                    case 'file-refid':
                        break;
                    case 'file-name-text':
                        data[col.id] = '-';
                        break;
                    case 'file-comment-text':
                        data[col.id] = '';
                        break;
                    case 'file-type-text':
                        data[col.id] = '-';
                        break;
                    case 'file-size-num':
                        data[col.id] = '0';
                        break;
                    case 'file-create-date':
                        data[col.id] = '-';
                        break;
                    case 'file-uri':
                        data[col.id] = '';
                        break;
                    default:
                        data[col.id] = '';
                        break;
                }
            })
            if (!disabled) props.handleChangeChildrenRowValue(data, props.col, props.row, props.rindex);
        } else {
            if (!disabled) props.handleChangeChildrenValue(value)
        }
    }

    const handleSelectData = (object) => {
        let jsonObjectToArray = Object.values(object);
        let destinations = [];
        if (dest.length > 0) {
            let champs = dest.split('-');
            champs.forEach((elm, index) => {
                elm = elm.replaceAll(' ', '')
                let field = elm.split(':');
                if (field.length > 0 && field.length <= 3) {
                    let dest = field[0];
                    let col = field[1];
                    let copyValue = col?.split(';') || [col];
                    let value = "";
                    let row = Object.values(props.row);
                    let keys = Object.keys(props.row);

                    if (typeof row[(dest - 1)] !== "undefined") {
                        copyValue.forEach((item, index) => {
                            let alphaNumIndexDest = convertersAlphaToNumber(item.toUpperCase()) - 1;
                            let data = jsonObjectToArray[alphaNumIndexDest];
                            if (typeof data !== "undefined") {
                                let temp = data;
                                if (temp !== "" && typeof temp !== "undefined") {
                                    if (temp !== "") {
                                        if (item === item.toUpperCase()) {
                                            value = value === "" ? value.concat(temp) : value.concat(' ~ \n' + temp);
                                        } else {
                                            value = value === "" ? value.concat(temp) : value.concat(' ~ ' + temp);
                                        }
                                    }
                                }
                            }
                        })
                    }
                    destinations.push({
                        "dest": keys[(dest - 1)],
                        "value": value
                    })
                }
            })
        }
        if (destinations.length > 0) {
            let value = { ...props.row }
            destinations.forEach(destination => {
                let column = getCellColumn(destination.dest, props.columns)
                value[destination.dest] = column.format === 'numeric' ? Number(destination.value?.replace(',','.')) : destination.value;
            })
            props.handleChangeChildrenRowValue(value, props.col, props.row, props.rindex)
        }
        setOpen(false)
    }

    const handleCalculateValue = async () => {
        setLoading((prevLoading) => !prevLoading);
        let tmp = [];
        columns.forEach((elm, index) => {
            // devLog('handle calculate', elm, props.row);
            let tmpValue = props.row[elm.id];
            if (Object.prototype.toString.call(tmpValue) === '[object Array]' || Object.prototype.toString.call(tmpValue) === '[object Object]') {
                tmpValue = "";
            }
            if (elm.format === 'numeric' && props.row[elm.id] !== undefined && typeof props.row[elm.id] !== 'number') {
                tmpValue = props.row[elm.id].replace(', ', '.');
            }
            tmp.push([tmpValue]);
        });
        if (formula !== null) {
            let data = tmp;
            const sheetInfo = {
                pnSheet: {
                    sheetName: "F32"
                },
                formulas: {
                    sheetName: "Formulas"
                }
            };
            let sheetAData = data;
            let newFormula = formula;
            const hfInstance = HyperFormula.buildEmpty(optionFormule);
            let formulasData = [[
                newFormula
            ]];

            // add 'TeamA' sheet
            hfInstance.addSheet(sheetInfo.pnSheet.sheetName);
            // insert playersA content into targeted 'TeamA' sheet
            hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo.pnSheet.sheetName), sheetAData);

            // add a sheet named 'Formulas'
            hfInstance.addSheet(sheetInfo.formulas.sheetName);
            // add formulas to that sheet
            hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo.formulas.sheetName), formulasData);

            const cellAddress = hfInstance.simpleCellAddressFromString(
                `${sheetInfo.formulas.sheetName}!A1`, hfInstance.getSheetId(sheetInfo.formulas.sheetName)
            );
            try {
                const mySum = hfInstance.getCellValue(cellAddress);

                if (!mySum.hasOwnProperty('type')) {
                    handleChangeTableValue(mySum);
                }
                else handleChangeTableValue("");
            }
            catch (e) {
                //setResult(e.message)
                handleChangeTableValue("");
            }
            hfInstance.destroy();
        }
        setLoading((prevLoading) => !prevLoading);
    }

    const handleOpenModalConfigurator = () => {
        setOpenConfigurator(true);
    }

    // const renderEntryLabelMarkup = (label) => {

    // }

    const checkRegistered = (email) => {
        let url = ApplicationConfig.apiUrl + 'utils/check/user/exist.json?email=' + email
        if (email === 'camille@plugnotes.com' || email === 'camille-db@hotmail.com' || email === 'vdb@plugnotes.com') setRegistered(true);
        else if (email !== '') {
            trackPromise(
                axios
                    .get(url,
                        {
                            'headers': {
                                // 'X-AUTH-TOKEN': 'rKDvZfk/vfZaGN4riX/b1PSk9JuQPBHwCyqJFk/exRs/WRKOn5ta0gc45LGkRiqepcU='},
                                'X-AUTH-TOKEN': getToken()
                            },
                        }
                    )
                    .then((response) => {
                        if (response) {
                            setRegistered(response.data.result)
                        }
                    })
                    .catch((response) => {
                        console.error(
                            "Could not check registration from the backend.",
                            response
                        );
                    })
            )
        }
    }

    // Smailvalidmulti
    useEffect(() => {
      if (isDefined(pdfBlob)) {
        pdfBlob.then(result => onLoadBlob(result))
      } else { setPlugnoteCopy(null) }
    }, [pdfBlob])

    const onLoadBlob = (result) => {
        let reader = new FileReader()
        try {
            reader.readAsDataURL(result)
            reader.onloadend = function () {
                let base64data = reader.result
                setPlugnoteCopy(base64data)
            }
        } catch (e) {
            console.error(`❌ onLoadBlob ${e}`)
        }
    }

    const controlBeforeOpen = (e) => {
        e.preventDefault()
        e.stopPropagation()
        checkRegistered(value)
        setOpenMail(false);
        setOpenConfigurator(false);
        setOpenExternalConfigurator(false);
        //devLog('control before', props.control, 'mode', props.mode, props.share);
        if (props.mode !== "modal-shared-create") {
            if (props.share && registered) {
                if (typeof props.control !== "undefined" && props.control) {
                    switch (props.mode) {
                        case 'create':
                        case 'shared-create':
                            setOpenPromised(true);
                            if (getPlugnote() === null) {
                                props.controlCreate();
                            }
                            else {
                                props.controlUpdate();
                            }
                            break;
                        case 'update':
                        case 'shared-update':
                        case 'modal-shared-update':
                            setOpenPromised(true);
                            props.controlUpdate();
                            break;
                        default:
                            break;

                    }
                }
                else {
                    setOpenMail(true);
                }
            }
            else if (props.share && !registered) {
                setOpenExternalConfigurator(true)
            }
            else {
                setOpenMail(true);
            }
        } else {
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('common:global.send-before'),
                buttons: [
                    {
                        label: t('global.ok'),
                    },
                ]
            });
        }
    }

    const getInformations = () => {
        if (getPlugnote() !== null && registered) {
            if (props?.plugnote?.hash && props.value !== "") {
                trackPromise(
                    getShareDataWithEmailUser(getPlugnote().hash, props.value).then((response) => {
                        // devLog('getShareDataWithEmailUser response ', response)
                        if (response.data.result) {
                            let rules = null;
                            let code = response.data.data.readFiche.toString() + response.data.data.editFiche.toString()
                            switch (code.toString()) {
                                case "10":
                                    rules = 0;
                                    break;
                                case "11":
                                    rules = 1;
                                    break;
                                default:
                                    break;
                            }

                            setContentTooltip(
                                <div>
                                    <p><span className="bold">{t('common:smail.share-to')} : <span className="normal">{props.value}</span></span></p>
                                    <p><span className="bold">{t('common:smail.share-code')} : <span className="normal">{response.data.data.code}</span></span></p>
                                    {
                                        rules !== null && rules === 0 &&
                                        <p><span className="bold">{t('common:smail.share-rules')} : <span className="normal">{t('common:plugcode.rule-read')}</span></span></p>
                                    }
                                    {
                                        rules !== null && rules === 1 &&
                                        <p><span className="bold">{t('common:smail.share-rules')} : <span className="normal">{t('common:plugcode.rule-read-edit')}</span></span></p>
                                    }
                                    <p><span className="bold">{t('smail.share-count')} : <span className="normal">{response.data.data.countopen.toString()}</span></span></p>
                                    <p><span className="bold">{t('smail.sent-date')} : <span className="normal">{sentDate}</span></span></p>
                                </div>
                            );
                            // setOpenTooltip(props.index);
                        }
                        else {
                            setContentTooltip(
                                <div>
                                    <p><span>{t('common:smail.share-no-to')} <span>{props.value}</span></span></p>
                                </div>
                            );
                            // setOpenTooltip(props.index);
                        }
                    }).catch((response) => {
                        setContentTooltip(
                            <div>
                                <p><span>{t('common:smail.share-no-to')} <span>{props.value}</span></span></p>
                            </div>
                        );
                        // setOpenTooltip(props.index);
                    })
                )
            }
        }
    }

    const handleOpenModalSmail = (pwCode) => {
        if (isDefined(pwCode) && !props.row[1000]?.includes('PW')) {
            let pwColumn = props.columns.find(entry => entry.id === 1000)
            props.handleChangeChildrenRowValue(pwCode, pwColumn, props.row, props.rindex)
        }
        if (templateOn !== "") {
            generateCustomAnnexeFromTemplate().then((result) => {
                let reader = new FileReader();
                reader.readAsDataURL(result);
                reader.onloadend = function () {
                    let base64data = reader.result;
                    setCustomAnnexe(base64data);
                    setOpenMail(true);
                }
            });
        } else {
            setCustomAnnexe(null);
            setOpenMail(true);
        }
    }

    const handleCloseModalSmail = () => {
        setOpenMail(false);
    }

    const handleCloseWhenSendModalSmail = () => {
        if (openConfigurator) { setOpenConfigurator(false) }
        setOpenMail(false)
        if (props.col.id === 1001 && props.share) {
            if (isDefined(pwCode)) {
                let pwField = pwCode + ' - ' + new Date().toLocaleString([], { day: '2-digit', month: '2-digit', year: '2-digit', hour: '2-digit', minute: '2-digit' })
                if (props.row[1000] !== pwField) {
                    let pwColumn = props.columns.find(entry => entry.id === 1000)
                    props.handleChangeChildrenRowValue(pwField, pwColumn, props.row, props.rindex)
                }
            }
        }
    }

    const handleSendMail = (payload, userhash) => {
        props.attemptSendMailSimple(payload, userhash)
        handleCloseWhenSendModalSmail()
    }

    const generateCustomAnnexeFromTemplate = async () => {
        return Data9002PrintableGetURL(props);
    }

    const handleOpenListAnnexes = () => {
        setOpenListAnnexes(true);
    }

    const handleGetListOpenListAnnexesFromFtp = () => {
        trackPromise(
            axios.get(ApplicationConfig.apiUrl + 'ftp/annexes?ownerid=' + props.model.owner,
                {
                    crossdomain: true,
                    'headers': {
                        'X-AUTH-TOKEN': getToken(),
                    }
                }
            ).then((response) => {
                if (response.data.result) {
                    setFoldersFromFtp(response.data.folders);
                    setOpenListAnnexesFromFtp(true);
                }
            }).catch((response) => {
            })
        )
    }

    const handleListItemClick = (index, row, col, rindex) => {
        let annexe = props.annexes[index];
        let tmpValue = { ...props.row };
        let value = props.createDataForAnnexeWithContent(tmpValue, annexe);
        props.handleChangeChildrenRowValue(value, col, row, rindex);
        setOpenListAnnexes(false);
    }

    const handleListItemFromFtpClick = (annexe, index, row, col, rindex) => {
        let tmpValue = { ...props.row };
        let value = props.createDataForAnnexeWithContent(tmpValue, annexe);
        props.handleChangeChildrenRowValue(value, col, row, rindex);
        setOpenListAnnexesFromFtp(false);
    }

    const handleOpenBase64Preview = (value, row) => {
        trackPromise(
            axios.get(ApplicationConfig.apiUrl + 'ftp/preview/annexe?uri=' + value,
                {
                    crossdomain: true,
                    'headers': {
                        'X-AUTH-TOKEN': getToken(),
                    }
                }
            ).then((response) => {
                console.log(response)
                if (response.data.result) {
                    setBase64FromFtp(response.data.base64);
                    setUriFromFtp(response.data.uri)
                    let typeId = columns.find((col => col.format === 'file-type-text'));
                    let nameId = columns.find((col => col.format === 'file-name-text'));
                    let type = props.row[typeId.id];
                    let filename = props.row[nameId.id];
                    handlePreviewFtpFile(response.data.uri, response.data.base64, type, filename)
                } else {
                    axios.get('https://files-dev.plugnotes.com/index.php?url=' + value)
                }
            }).catch((response) => {
                console.log('catch', response)
                axios.get('https://files-dev.plugnotes.com/index.php?url=' + value)
            })
        )
    }
    // const handleCloseFTPPreviewModal = () => {
    //     setOpenPreviewFromFtp(false)
    // }

    // const renderAnnexeImagePreview = () => {
    //     if (isDefined(base64FromFtp) && isDefined(uriFromFtp)) {
    //         // setBase64FromFtp(null);
    //         // setUriFromFtp(null)
    //         // setOpenPreviewFromFtp(false);
    //         window.open(uriFromFtp, "_blank")
    //         // return (
    //         //     <RenderFilePreview
    //         //         classes={classes} t={t}
    //         //         annexe={props.row} colonnes={columns}
    //         //         base64={base64FromFtp} uri={uriFromFtp}
    //         //         handleClose={handleCloseFTPPreviewModal} />
    //         // )
    //     }
    //     // try {
    //     //     renderFilePreview(classes, t, annexe, colonnes, base64, uri)
    //     // } catch (e) {
    //     //     setBase64PreviewFromFtp(null);
    //     //     setOpenBase64PreviewFromFtp(false);
    //     //     console.log(e)
    //     // }
    // }

    const handleValidateComment = (annexe, comment) => {
        props.handleSaveComment(annexe, comment)
        handleChangeTableValue(comment)
    }

    useEffect(() => {
        if (isDefined(props.value) && props.col.format === 'numeric-ref') {
            if (props.value === "") {
                let target = props?.col?.configuration?.target;
                //devLog('target' , target);
                if (typeof target !== "undefined") {
                    let field = props.fields.find((field, index) => Number(field.id) === Number(target));
                    // if (field) {
                    //     tmpValue = field.value;
                    // }
                }
            } else {
                setValue(props.value);
            }
        } else if (props.value !== value) {setValue(getInitialValue())}
        // Provoque des re-render et saut du curseur
        // else if (props.value !== undefined) { setValue(props.value); }
        // else setValue('')
    }, [props.value])

    useEffect(() => {
        if (props.col.format === 'numeric-ref') {
            if (props.value === "" && value !== "") {
                handleChangeTableValue(value);
            }
        } else if (props.id === '1001') { checkRegistered(value) }
    }, [value])

    useEffect(() => {
        if (openPromised) {
            if (!promiseInProgress && getPlugnote() !== null) {
                switch (props.mode) {
                    case 'create':
                    case 'update':
                    case 'shared-create':
                    case 'shared-update':
                    case 'modal-shared-update':
                        try {
                            handleOpenModalConfigurator();
                        }
                        catch (e) {
                            devLog('error', e);
                        }
                        break;
                    default:
                        break;
                }
                setOpenPromised(false);
            }
            else {
                setOpenPromised(true);
            }
        }
    }, [promiseInProgress, openPromised, getPlugnote()])

    let nowVal = getCurrentDateTimeByMask(column.mask, column)

    switch (column.format) {
        case 'file-source':
            const sourcePresels = Array.isArray(column.mask) ? column.mask : ['PLUGNOTE', 'FTP', 'URL'];
            template = (
                <div className={widerCtn}>
                    <RenderItemSingleSelectComponent
                        col={props.col}
                        handleChangeChildrenValue={handleChangeChildrenRowWithPreValueValue}
                        rindex={props.rindex}
                        row={props.row}
                        isTablemulti
                        multiselect={false} editable={false}
                        openDefault={openDefault} presel={sourcePresels} disabled={disabled || !isEditable} value={value} />
                </div>
            );
            // template = (
            //     <div className={widerCtn}>
            //         <Select
            //             classes={{
            //                 icon: classes.selectIcon,
            //                 root: `${classes.tablemultiFontWidth} ${classes.selectField}`,
            //                 select: `${classes.tablemultiInputCtn} ${classes.selectSelect}`,
            //             }}
            //             disabled={disabled || !isEditable}
            //             disableUnderline
            //             displayEmpty
            //             fullWidth
            //             id="demo-simple-select"
            //             labelId="demo-simple-select-label"
            //             onChange={event => handleChangeChildrenRowWithPreValueValue(event.target.value)}
            //             // onClick={() => setOpenSelect(!openSelect)}
            //             // open={openSelect}
            //             renderValue={(value) => value !== '' && (<span className={classes.selectedItem}>{value}</span>)}
            //             value={value}
            //         >
            //             {
            //                 sourcePresels.map((row, index) => (
            //                     <MenuItem key={index} value={row} dense>{row}</MenuItem>
            //                 ))
            //             }
            //         </Select>
            //     </div>
            // );
            break;
        case 'file-name-text':
            template = (
                <div className={classes.fieldValueContainer}>
                    <TextField
                        fullWidth
                        multiline
                        inputProps={{ width: '100%' }}
                        name={props.col.format + '-' + props.cindex}
                        type="text"
                        value={value}
                        disabled
                        InputProps={{
                            classes: {
                                input: classes.tablemultiFont,
                            }
                        }}
                    />
                </div>
            )
            break
        case 'file-comment-text':
            const fileUri = props.row[columns.find(entry => entry.format === 'file-uri').id]
            const annexe = fileUri?.includes('https://') ? props.annexes.find(entry => entry.path === fileUri) : props.annexes.find(entry => entry.hash === fileUri)
            template = (
                (disabled || !isEditable)
                    ? <div className={classes.fieldValueContainer}>
                        <TextField
                            fullWidth
                            multiline
                            inputProps={{ width: '100%' }}
                            name={props.col.format + '-' + props.cindex}
                            type="text"
                            value={annexe.comment}
                            disabled
                            InputProps={{
                                classes: {
                                    input: classes.tablemultiFont,
                                    root: props.col.id === 1000 ? classes.renderBlankContainer : classes.renderSingleSelContainer
                                },
                            }}
                        />
                    </div>
                    : <FileCommentComponent
                    annexe={annexe}
                    disabled={disabled || !isEditable}
                    edit={props.edit}
                    handleSaveComment={comment => handleValidateComment(annexe, comment)}
                    />
                // <div className={classes.fieldValueContainer}>
                //     <TextField
                //         fullWidth={true}
                //         multiline={true}
                //         inputProps={{ width: '100%' }}
                //         name={props.col.format + '-' + props.cindex}
                //         type="text"
                //         value={value}
                //         onChange={(event) => handleEditComment(event.target.value)}
                //         disabled={props.col.id === 1000 ? true : disabled}
                //         InputProps={{
                //             classes: {
                //                 input: classes.textFont,
                //                 root: props.col.id === 1000 ? classes.renderBlankContainer : classes.renderSingleSelContainer
                //             },
                //             endAdornment: isDefined(value) && value !== props.value && (
                //                 <span>
                //                     <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={handleValidateComment}>check_circle_outline</span>
                //                 </span>
                //             )
                //         }}
                //     />
                // </div>
            )
            break;
        case 'file-type-text':
            template = (
                <div className={classes.fieldValueContainer}>
                    <TextField
                        fullWidth={true}
                        multiline={true}
                        inputProps={{ maxLength: '4', width: '100%' }}
                        name={props.col.format + '-' + props.cindex}
                        type="text"
                        value={value}
                        disabled
                        InputProps={{
                            classes: {
                                input: classes.tablemultiFont,
                                root: props.col.id === 1000 ? classes.renderBlankContainer : classes.renderSingleSelContainer
                            },
                            endAdornment: value !== null && value !== undefined && (value.length > parseInt(props.col.mask) - 10 && props.col.id !== 1000 ?
                                <Fragment>
                                    <span className={classes.textLength}>{value.length}/4</span>
                                </Fragment>
                                : null
                            )
                        }}
                    />
                </div>
            )
            break;
        case 'file-size-num':
            //fixed pasting values
            // https://github.com/s-yadav/react-number-format/issues/349
            template = (
                <div className={`${classes.fieldValueContainer} ${classes.renderSingleSelContainer}`}>
                    <NumericFormat
                        {...props}
                        // allowedDecimalSeparators={[',', '.']}
                        className={classes.stylizedValue}
                        customInput={RestyledNumericInput}
                        decimalScale={true ? 9 : 0}
                        decimalSeparator={","}
                        format={null}
                        onCopy={(e) => {
                            e.preventDefault()
                            navigator.clipboard.writeText(value)
                        }}
                        onValueChange={(v, infos) => {
                            // formattedValue: '$23,234,235.56', // value after applying formatting
                            // value: '23234235.56', // non formatted value as numeric string 23234235.56, if you are setting this value to state make sure to pass isNumericString prop to true
                            // floatValue: 23234235.56 // floating point representation. For big numbers it can have exponential syntax
                            //setNumericValue(v);
                            setValue(v.floatValue);
                            if (infos.event !== null) {
                                handleChangeTableValue(v.floatValue);
                            } else if (infos.event === null && v.value !== value.toString()) {
                                handleChangeTableValue(v.floatValue)
                            }
                        }}
                        thousandSeparator={false}
                        value={value}
                    />
                </div>
            );
            break;
        case 'file-create-date':
            let dateCreateMask = ''
            template = (
                <div className={fieldCtn}>
                    <TextField

                        placeholder={dateCreateMask === '' ? '' : props.col.mask}
                        name={props.col.format + '-' + props.cindex}
                        type="text"
                        disabled={props.col.id === 1003}
                        fullWidth={true}
                        InputProps={{
                            classes: {
                                input: classes.tablemultiFont,
                                root: inputStyle,
                            },
                        }}
                        onChange={(event) => { handleChangeTableValue(event.target.value) }}
                        value={value}
                    />
                </div>
            );
            break;
        case 'file-refid':
            template = (
                <div className={fieldCtn}>
                    <TextField

                        placeholder={props.col.mask}
                        name={props.col.format + '-' + props.cindex}
                        type="text"
                        disabled={true}
                        fullWidth={true}
                        InputProps={{
                            classes: {
                                input: classes.tablemultiFont,
                                root: inputStyle,
                            },
                        }}
                        //onChange={(event) => {handleChangeTableValue(event.target.value)}}
                        value={value}
                    />
                </div>
            );
            break;
        case 'file-uri':
            let fileSource = props.columns.find((column, index) => column.format === 'file-source');

            if (fileSource) {
                switch (props.row[fileSource.id]) {
                    case 'PLUGNOTE':
                        if (value !== "") {
                            if (!value?.includes('.')) { devLog('file uri', value) }
                            template = (
                                <div className={classes.fieldValueContainer}>
                                    {
                                        value?.includes('.') && !value?.includes('MISSING_FILE_')
                                            ? <a href={value.toString()} target="_blank" className={classes.urlButton} rel="noreferrer"><span className="material-icons md-small white">link</span></a>
                                            : (
                                                <span className={classes.urlButton}>
                                                    {
                                                        value?.includes('MISSING_FILE_')
                                                        ? <MissingDocumentIcon color="white" size="20"/>
                                                        : <span className="material-icons md-small white">visibility</span>
                                                    }
                                                </span>
                                            )
                                    }
                                    {
                                        ((value?.includes('.') && value !== null && value !== undefined && value.length > 0 && props.col.id !== 1000) || (value?.includes('MISSING_FILE_'))) &&
                                        <span id="icon-create" className="material-icons md-small red" onClick={() => handleChangeTableValue("")}>delete</span>
                                    }
                                </div>
                            )
                        } else {
                            template = (
                                <div className={classes.fieldValueContainer}>
                                    <span id="icon-create" className="material-icons md-small red" onClick={() => handleOpenListAnnexes()}>open_in_new</span>
                                    {
                                        openListAnnexes &&
                                        <Modal
                                            key={props.rindex + '-' + props.col.id}
                                            keepMounted={false}
                                            open={openListAnnexes}
                                            onClose={(event) => setOpenListAnnexes(false)}
                                            aria-labelledby="simple-modal-title"
                                            aria-describedby="simple-modal-description"
                                            className={classes.modal}
                                        >
                                            <div style={modalStyle} className={classes.popup}>
                                                <List component="nav" aria-label="secondary mailbox folder">
                                                    {
                                                        props.annexes.map((annexe, index) => {
                                                            return (
                                                                <ListItem
                                                                    button
                                                                    key={'attachment-' + index}
                                                                    onClick={(event) => handleListItemClick(index, props.row, props.col, props.rindex)}
                                                                >
                                                                    <ListItemText primary={annexe.name + ' (' + annexe.type + ' )'} />
                                                                </ListItem>
                                                            )
                                                        })
                                                    }
                                                </List>
                                            </div>
                                        </Modal>
                                    }
                                </div>
                            )
                        }
                        break;
                    case 'URL':
                        if (value !== "") {
                            template = (
                                <div className={classes.fieldValueContainer}>
                                    <a href={value.toString()} target="_blank" className={classes.urlButton} rel="noreferrer"><span className="material-icons md-small white">link</span></a>
                                    {
                                        value !== null && value !== undefined && (value.length > 0 && props.col.id !== 1000) &&
                                        <span id="icon-create" className="material-icons md-small red" onClick={() => handleChangeTableValue("")}>edit</span>
                                    }
                                </div>
                            )
                        } else {
                            template = (
                                <div className={classes.fieldValueContainer}>
                                    <TextField
                                        fullWidth={true}
                                        multiline={true}
                                        inputProps={{ width: '100%' }}
                                        name={props.col.format + '-' + props.cindex}
                                        type="text"
                                        value={valueChange}
                                        onChange={(event) => setValueChange(event.target.value)}
                                        disabled={props.col.id === 1000 ? true : disabled}
                                        InputProps={{
                                            classes: {
                                                input: classes.textFont,
                                                root: props.col.id === 1000 ? classes.renderBlankContainer : classes.renderSingleSelContainer
                                            },
                                            endAdornment:
                                                <Fragment>
                                                    {valueChange !== undefined && valueChange.length > 0 && !disabled ?
                                                        <PlugTooltip title={t('tablemulti.validate')} placement="right">
                                                            <span onClick={() => handleChangeTableValue(valueChange)}>
                                                                <span id="icon-add-playlist" className={`${classes.tablemultiBtn} material-icons`}>
                                                                    check
                                                                </span>
                                                            </span>
                                                        </PlugTooltip> : null}
                                                </Fragment>,
                                        }}
                                    />
                                </div>
                            )
                        }
                        break;
                    case 'FTP':
                        if (value !== "") {
                            template = (
                                <div className={classes.fieldValueContainer}>
                                    <span className={classes.urlButton} rel="noreferrer" onClick={(event) => handleOpenBase64Preview(value.toString())}><span className="material-icons md-small white">link</span></span>
                                    {
                                        value !== null && value !== undefined && (value.length > 0 && props.col.id !== 1000) &&
                                        <span id="icon-create" className="material-icons md-small red" onClick={() => handleChangeTableValue("")}>delete</span>
                                    }
                                    {/* {openPreviewFromFtp &&
                                        <Modal
                                            key={props.rindex + '-' + props.col.id}
                                            keepMounted={false}
                                            open={openPreviewFromFtp}
                                            onClose={(event) => setOpenPreviewFromFtp(false)}
                                            aria-labelledby="simple-modal-title"
                                            aria-describedby="simple-modal-description"
                                            className={classes.modal}
                                        >
                                            <div style={modalStyle} className={classes.popup} onClick={handlePreventDefault}>
                                                <div className={classes.scaledImg}>
                                                    {
                                                        renderAnnexeImagePreview()
                                                    }
                                                </div>
                                            </div>
                                        </Modal>
                                    } */}
                                </div>
                            )
                        } else {
                            template = (
                                <div className={classes.fieldValueContainer}>
                                    <span id="icon-create" className="material-icons md-small red" onClick={() => handleGetListOpenListAnnexesFromFtp()}>open_in_new</span>
                                    {openListAnnexesFromFtp &&
                                        <Modal
                                            key={props.rindex + '-' + props.col.id}
                                            keepMounted={false}
                                            open={openListAnnexesFromFtp}
                                            onClose={(event) => setOpenListAnnexesFromFtp(false)}
                                            aria-labelledby="simple-modal-title"
                                            aria-describedby="simple-modal-description"
                                            className={classes.modal}
                                        >
                                            <div style={modalStyle} className={classes.popup}>
                                                <List component="nav" aria-label="secondary mailbox folder">
                                                    <div>
                                                        {
                                                            foldersFromFtp?.map((element, index) => {
                                                                if (element.content === 'FOLDER') {
                                                                    return <RenderFolderActionTemplate key={index} {...props} element={element} index={index} handleListItemFromFtpClick={handleListItemFromFtpClick} />
                                                                } else if (element.content === 'FILE') {
                                                                    return <RenderFileActionTemplate key={index} {...props} element={element} index={index} handleListItemFromFtpClick={handleListItemFromFtpClick} />
                                                                } else return null
                                                            })
                                                        }
                                                    </div>

                                                </List>
                                            </div>
                                        </Modal>
                                    }
                                </div>
                            )
                        }
                        break;
                    default:
                        break;
                }
            }
            break;
        case 'intdatamaj':
            const handleChangeIntDataMajRow = (value) => {
                handleChangeTableValue(value)
            }

            let copyOptionsLines = JSON.parse(JSON.stringify(field.options.lines));

            let destIntDataMaj = copyOptionsLines[props.cindex].configuration.dest;

            let indexLigne = props.rindex + 1

            if (!isNull(destIntDataMaj)) {
                let replaceDestX = destIntDataMaj.replaceAll('LX', 'L' + indexLigne);
                copyOptionsLines[props.cindex].configuration.dest = replaceDestX;
            }
            let subField = {
                value: value,
                options: copyOptionsLines[props.cindex].configuration,
            }
            template = (
                <div className={classes.fieldValueContainer}>
                    <FormatIntDataMajComponent
                        handleChangeIntDataMajRow={handleChangeIntDataMajRow}
                        field={subField}
                        cindex={props.cindex}
                        rindex={props.rindex}
                        disabled={disabled}
                        owner={props?.hashOwnerModel || props?.owner}
                        mode='table'
                        dataHash={props?.dataHash}
                        hidden={true}
                        control={props.control}
                        controlCreate={props?.controlCreate}
                        controlUpdate={props?.controlUpdate}
                    />
                </div>
            )
            break;
        case 'date':
            let dateMask = ''
            let retrievedDate
            if (typeof column.mask !== "undefined" && column.mask !== null) {
                dateMask = column.mask
            }
            let dateIsInvalid = false
            if (!value?.includes('_')) {dateIsInvalid = isInvalidDate(column.mask, value)}
            switch (column.mask) {
                case 'DD/MM':
                    dateMask = '99/99'
                    break;
                case 'DD/MM/YY':
                    dateMask = '99/99/99'
                    break;
                case 'MM/YYYY':
                    dateMask = '99/9999'
                    break;
                case 'DD/MM/YYYY':
                    dateMask = '99/99/9999'
                    break;
                case '99':
                case 'Free Text':
                    dateMask = ''
                    break;
                default:
                    break;
            }
            if (isDefined(value) && dateMask !== '') {
                retrievedDate = value.split('-')
                if (retrievedDate.length > 3) {
                    let year = retrievedDate[retrievedDate.length - 1]
                    let month = retrievedDate[retrievedDate.length - 2]
                    let day = retrievedDate[retrievedDate.length - 3]
                    if (month.length === 1) month = '0' + month.toString()
                    if (day.toString().trim().length === 1) day = '0'.concat(day.toString())
                    retrievedDate = day + '/' + month + '/' + year
                }
            }

            template = (
                <div className={fieldCtn}>
                    {dateMask !== '' ?
                        <InputMask
                            disabled={disabled || !isEditable}
                            maskChar="_"
                            mask={dateMask}
                            value={props.col.id !== 1003 ? value : retrievedDate}
                            onKeyDown={handleOnBlur}
                            onChange={(event) => {
                                setAccept(false);
                                handleChangeTableValue(event.target.value)
                            }}
                        >
                            {(inputProps) =>
                                <TextField
                                    {...inputProps}
                                    autoFocus={props.edit === "cell"}

                                    name={props.col.format + '-' + props.cindex}
                                    fullWidth={true}
                                    placeholder={dateMask === '' ? '' : props.col.mask}
                                    type="text"
                                    InputProps={{
                                        classes: {
                                            input: classes.tablemultiFont,
                                            root: inputStyle,
                                        },
                                        endAdornment:
                                            <Fragment>
                                                {value !== undefined && value.length > 0 && dateIsInvalid && !accept && !disabled ?
                                                    <PlugTooltip title={t('tablemulti.validate')} placement="right">
                                                        <span className={classes.fontError} onClick={() => setAccept(true)}>
                                                            {t('tablemulti.invalid-value')}
                                                            <span id="icon-add-playlist" className={`${classes.tablemultiBtn} material-icons`}>
                                                                check
                                                            </span>
                                                        </span>
                                                    </PlugTooltip> : null}
                                                {!disabled &&
                                                    <PlugTooltip title={(value !== "" && value !== null && value !== undefined) ? t('actions.delete') : t('tablemulti.current-date')} placement="top" className={classes.endButton} >
                                                        {value !== "" && value !== null && value !== undefined ?
                                                            <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={() => { setAccept(false); handleChangeTableValue("") }}>highlight_off</span>
                                                            :
                                                            <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={() => { setAccept(false); handleChangeTableValue(nowVal) }}>check_circle_outline</span>}
                                                    </PlugTooltip>}
                                            </Fragment>,
                                    }}
                                />
                            }
                        </InputMask>
                        :
                        <TextField
                            autoFocus={props.edit === "cell"}
                            placeholder={dateMask === '' ? '' : props.col.mask}
                            name={props.col.format + '-' + props.cindex}
                            type="text"
                            fullWidth
                            InputProps={{
                                classes: {
                                    input: classes.tablemultiFont,
                                    root: inputStyle,
                                },
                                endAdornment:
                                    <Fragment>
                                        {
                                            !disabled && isEditable &&
                                            <PlugTooltip title={value !== "" && isDefined(value) ? t('actions.delete') : t('tablemulti.current-date')} placement="top" className={classes.endButton} >
                                                {value !== "" && isDefined(value) ?
                                                    // delete
                                                    <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={() => { setAccept(false); handleChangeTableValue("") }}>highlight_off</span>
                                                    :
                                                    // autocomplete
                                                    <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={() => { setAccept(false); handleChangeTableValue(nowVal) }}>check_circle_outline</span>}
                                            </PlugTooltip>
                                        }
                                    </Fragment>,
                            }}
                            onBlur={handleOnBlur}
                            onChange={(e) => { handleChangeTableValue(e.target.value) }}
                            value={value}
                        />
                    }
                </div>
            );
            break;
        case 'duration':
            let durMask = '99:99:99'
            if (typeof props.col.regex !== "undefined" && props.col.regex !== null) {
                durMask = props.col.regex
            }
            template = (
                <div className={fieldCtn}>
                    <DurationInput
                        column={props.col}
                        accept={accept}
                        disabled={disabled || !isEditable}
                        edit={props.edit}
                        handleChangeTableValue={handleChangeTableValue}
                        mask={durMask}
                        handleAcceptValue={() => setAccept(true)}
                        value={value}
                    />
                </div>
            );
            break;
        case 'combofillextdata':
            const titleCombofillExtdataFormat = `( ${ref} ) [ ${dbcolumns} ] [ ${dest} ]`
            template = (
                <div className={fieldCtn}>
                    <Input
                        autoFocus={props.edit === "cell"}
                        className={`${classes.tablemultiFont} ${inputStyle}`}
                        classes={{ root: inputStyle, inputMultiline: classes.centeredInput }}
                        disabled={disabled || !isEditable}
                        fullWidth
                        inputProps={{ maxLength: props.col.mask, width: '100%' }}
                        disableUnderline={props.edit !== "row"}
                        multiline
                        name={props.col.format + '-' + props.cindex}
                        onKeyDown={handleOnBlur}
                        onChange={(event) => handleChangeTableValue(event.target.value, event)}
                        placeholder={props.edit !== "row" ? '' : t('common:global.search')}
                        startAdornment={
                            !disabled && !hideSearch &&
                            <InputAdornment onClick={(event) => handleOpenSearchCombo()} style={{ cursor: 'pointer', right: 0, position: 'absolute', }}
                                            position="start">
                                <Search />
                            </InputAdornment>
                        }
                        type="text"
                        value={value}
                    />
                    {
                        openComboFill &&
                        <ModalListComboFillExtDataComponent
                            titlePerso={props.title} titleFormat={titleCombofillExtdataFormat} format="combofillextdata"
                            open={openComboFill} close={(event) => setOpenComboFill(false)} options={options} owner={owner}
                            ownerId={ownerId} value={value} fields={props.fields}
                            handleSelectDataMultiCombo={props.handleSelectDataMultiCombo} external={props.external}
                            currentRow={props.rindex} currentColumn={props.col}
                            row={props.row}
                        />
                    }
                </div>
            )
            break;
        case 'extdata':
            // devLog("extdata props", props.col, props.value, value)
            const titleFormat = `( ${ref} )  ( ${query} ) [ ${dbcolumn} ] [ ${dbcolumns} ] [ ${dest} ]`
            let inputClass = props.edit === 'row' ? classes.centeredInputWithLeftIcon : classes.centeredTextWithLeftIcon
            template = (
                <div className={fieldCtn}>
                    <Input
                        autoFocus={props.edit === "cell"}
                        className={`${classes.tablemultiFont} ${inputStyle}`}
                        classes={{ root: inputStyle, input: inputClass }}
                        disabled={disabled || !isEditable}
                        fullWidth
                        inputProps={{ maxLength: props.col.mask, width: '100%' }}
                        disableUnderline={props.edit !== 'row'}
                        multiline={false}
                        name={props.col.format + '-' + props.cindex}
                        onKeyDown={handleOnBlur}
                        onChange={(event) => handleChangeTableValue(event.target.value, event)}
                        placeholder={props.edit !== "row" ? '' : t('common:global.search')}
                        startAdornment={
                            !disabled && !hideSearch &&
                            <PlugTooltip title={t('ext-table.search-table')} placement="right">
                                <InputAdornment
                                    onClick={(event) => handleOpenSearchModel()}
                                    position="start"
                                    style={{ cursor: 'pointer', zIndex: 10 }}
                                >
                                    <Search />
                                </InputAdornment>
                            </PlugTooltip>
                        }
                        // type="text"
                        style={{ textAlignLast: 'center' }}
                        value={value}
                    />
                    {
                        open &&
                        <ModalListExtDataComponent
                            titlePerso={props.title} titleFormat={titleFormat} format="extdata"
                            open={open} close={(event) => setOpen(false)} options={options} owner={owner}
                            ownerId={ownerId} value={value} fields={props.fields}
                            handleSelectData={handleSelectData} external={props.external}
                        />
                    }
                </div>
            )
            break;
        case 'intdata':
            const titleIntFormat = `( ${ref} )  ( ${query} ) [ ${dbcolumn} ] [ ${dbcolumns} ] [ ${dest} ]`
            template = (
                <div className={fieldCtn}>
                    <Input
                        autoFocus={props.edit === "cell"}
                        className={`${classes.tablemultiFont} ${classes.tablemultiInputCtn}`}
                        classes={{ root: inputStyle }}
                        disabled={disabled || !isEditable}
                        fullWidth={true}
                        inputProps={{ maxLength: props.col.mask, width: '100%' }}
                        multiline={true}
                        name={props.col.format + '-' + props.cindex}
                        onChange={(event) => handleChangeTableValue(event.target.value, event)}
                        onKeyDown={(e) => { if (e.key === 'Enter') handleOpenSearchModel() }}
                        placeholder={t('common:global.search')}
                        startAdornment={!disabled && !hideSearch &&
                            <InputAdornment onClick={(event) => handleOpenSearchModel()} style={{ cursor: 'pointer', right: 0, position: 'absolute', }} position="start">
                                <Search />
                            </InputAdornment>}
                        type="text"
                        value={value}
                    />
                    {open &&
                        <ModalListIntDataComponent titlePerso={props.title} titleFormat={titleIntFormat} format="intdata" open={open} close={(event) => setOpen(false)} options={options} owner={owner} ownerId={ownerId} value={value} fields={props.fields} handleSelectData={handleSelectData} external={props.external} />
                    }
                </div>
            )
            break;
        case 'formula':
            template = (
                <div className={fieldCtn}>
                    <Input
                        classes={{ root: classes.renderSingleFormulaContainer, inputMultiline: classes.centeredInput }}
                        fullWidth
                        placeholder={props?.col?.configuration?.formula || 'formula'}
                        multiline
                        className={classes.tablemultiFont}
                        inputProps={{ maxLength: props.col.mask, width: '100%' }}
                        name={props.col.format + '-' + props.cindex}
                        type="text"
                        value={value}
                        onChange={(event) => handleChangeTableValue(event.target.value)}
                        disabled
                        startAdornment={loading &&
                            <Fade
                                in={loading}
                                style={{
                                    transitionDelay: loading ? '800ms' : '0ms',
                                }}
                                unmountOnExit
                            >
                                <CircularProgress size={10} />
                            </Fade>
                        }
                        endAdornment={
                            !disabled &&
                            <InputAdornment position="end" onClick={(event) => handleCalculateValue()} style={{ cursor: 'pointer' }}>
                                <span className="material-icons md-medium green">calculate</span>
                            </InputAdornment>
                        }
                    />
                </div>
            )
            break;
        case 'url':
            template = (
                <div className={classes.fieldValueContainer}>
                    <TextField
                        disabled={disabled || !isEditable}

                        placeholder={props.col.mask}
                        name={props.col.format + '-' + props.cindex}
                        fullWidth={true}
                        InputProps={{
                            classes: {
                                input: classes.tablemultiFont,
                                root: inputStyle
                            },
                        }}
                        onKeyDown={handleOnBlur}
                        onChange={(event) => handleChangeTableValue(event.target.value, event)}
                        type="url"
                        value={value}
                    />
                    {
                        typeof value !== 'undefined' && value !== '' &&
                        <div style={{ marginLeft: '10px' }}>
                            <a href={value.toString()} target="_blank" className={classes.urlButton} rel="noreferrer"><span className="material-icons md-small white">link</span></a>
                        </div>
                    }
                </div>
            );
            break;
        case 'mail':
            const textStyle = { fontSize: props.col.fontSize > 0 ? props.col.fontSize : 16, wordBreak: 'break-word', }
            // const previewFieldStyle = { justifyContent: 'center', padding: '0px 4px', flexWrap: 'wrap', gap: 10 }
            template = (
                <div
                    // style={props.isPreview && previewFieldStyle}
                    style={isDefined(props.containerStyle) ? props.containerStyle : null}
                    className={fieldCtn}
                >
                    {
                        props.isPreview
                            ? <span style={textStyle}>{value}</span>
                            : <TextField
                                autoFocus={props.edit === "cell"}
                                disabled={disabled || !isEditable}

                                placeholder={props.col.mask}
                                name={props.col.format + '-' + props.cindex}
                                type="text"
                                fullWidth={true}
                                InputProps={{
                                    classes: {
                                        input: classes.tablemultiFont,
                                        root: inputStyle,
                                    },
                                }}
                                onChange={(e) => {
                                    handleChangeTableValue(e.target.value);
                                }}
                                onKeyDown={handleOnBlur}
                                defaultValue={value}
                            />
                    }
                    {
                        typeof value !== 'undefined' && value !== '' && props.col.id === 1001 && !props.mode?.includes('plugcode') &&
                        <PlugTooltip title={registered ? contentTooltip : unregisteredMsg} placement="right">
                            <span className={registered ? classes.mailButton : classes.unregisteredBtn} onClick={controlBeforeOpen} onMouseEnter={getInformations}>
                                <span className="material-icons md-small white">forward_to_inbox</span>
                            </span>
                        </PlugTooltip>
                    }

                    {
                        typeof value !== 'undefined' && value !== '' && props.col.id === 1001 && props.mode?.includes('plugcode') &&
                        <div style={{ marginLeft: '10px' }}>
                            <PlugTooltip title={registered ? contentTooltip : unregisteredMsg} placement="top">
                                <span className={registered ? classes.mailButton : classes.unregisteredBtn} onClick={getInformations} onMouseEnter={getInformations}>
                                    <span className="material-icons md-small white">info</span>
                                </span>
                            </PlugTooltip>
                        </div>
                    }

                    {
                        openMail && props.col.id === 1001 &&
                        <SmailModalComponent
                            annexes={props.annexes}
                            attemptSendMailSimple={handleSendMail}
                            close={handleCloseModalSmail}
                            customAnnexe={customAnnexe}
                            field={field}
                            fields={fields}
                            handleCloseWhenSendModalSmail={handleCloseWhenSendModalSmail}
                            mode={props.mode}
                            model={model}
                            open={openMail}
                            options={props.field.options}
                            plugnote={plugnote}
                            plugnoteCopy={plugnoteCopy}
                            pwCode={pwCode}
                            renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                            setPdfBlob={setPdfBlob}
                            user={user}
                            value={value}
                            ownerhash={props?.ownerhash}
                        />
                    }

                    {
                        openConfigurator && props.col.id === 1001 &&
                        <ConfigModal {...props} open={openConfigurator} external={false} close={() => setOpenConfigurator(false)} value={value} openMail={handleOpenModalSmail} setPwCode={setPwCode} setPwField={setPwField} />
                    }

                    {
                        openExternalConfigurator && props.col.id === 1001 &&
                        <ConfigModal {...props} open={openExternalConfigurator} external={true} close={() => setOpenExternalConfigurator(false)} value={value} openMail={handleOpenModalSmail} setPwCode={setPwCode} setPwField={setPwField} />
                    }
                </div>
            );
            break;
        case 'numeric':
            let separator = false
            let hasDecimal = false
            if (typeof props.col.regex !== "undefined" && props.col.regex !== null) {
                switch (props.col.regex) {
                    case "99,999999":
                        hasDecimal = true
                        break;
                    case "9.999":
                        separator = true;
                        // errorMsg = 'Integer only'
                        break;
                    case "9.999,99999":
                        separator = true;
                        hasDecimal = true;
                        break;
                    default:
                        break;
                }
            }
            let temp = typeof props.value === 'number' ? props.value : props.value?.replace(',','.')
            const numericValue = isNaN(Number(temp)) ? '' : Number(temp)

            //fixed pasting values
            // https://github.com/s-yadav/react-number-format/issues/349
            template = (
                <div className={`${fieldCtn} ${inputStyle}`}>
                    <NumericFormat
                        {...props}
                        allowedDecimalSeparators={[',', '.']}
                        autoFocus={props.edit === "cell"}
                        className={classes.stylizedValue}
                        customInput={RestyledNumericInput}
                        decimalScale={hasDecimal ? 99 : 0}
                        decimalSeparator={"."}
                        disabled={disabled || !isEditable}
                        format={null}
                        valueIsNumericString
                        onKeyDown={handleOnBlur}
                        onCopy={(e) => {
                            e.preventDefault();
                            navigator.clipboard.writeText(value)
                        }}
                        onValueChange={(v, infos) => {
                            // formattedValue: '$23,234,235.56', // value after applying formatting
                            // value: '23234235.56', //non formatted value as numeric string 23234235.56, if you are setting this value to state make sure to pass isNumericString prop to true
                            // floatValue: 23234235.56 //floating point representation. For big numbers it can have exponential syntax
                            devLog('value change', v, infos)
                            if (isDefined(infos.event) && v.value !== value.toString()) {
                                handleChangeTableValue(v.floatValue);
                            }
                        }}
                        thousandSeparator={separator ? ' ' : false}
                        value={numericValue}
                    />
                </div>
            );
            break;
        case 'numeric-ref':
            template = (
                <div className={fieldCtn}>
                    <TextField
                        autoFocus={props.edit === "cell"}
                        disabled={disabled || !isEditable}

                        placeholder={props.col.mask}
                        name={props.col.format + '-' + props.cindex}
                        fullWidth={true}
                        InputProps={{
                            classes: {
                                input: classes.tablemultiFont,
                                root: inputStyle
                            },
                        }}
                        onKeyDown={handleOnBlur}
                        onChange={(event) => {
                            setValue(event.target.value);
                            handleChangeTableValue(event.target.value);
                        }}
                        type="number"
                        value={value}
                    />
                </div>
            );
            break;
        case 's33data':
            template = (
                <div className={classes.fieldValueContainer}>
                    <TextField
                        autoFocus={props.edit === "cell"}
                        disabled={disabled}
                        // inputProps={{ style: { textAlign: 'right' } }}
                        placeholder={props.col.mask}
                        name={props.col.format + '-' + props.cindex}
                        fullWidth={true}
                        InputProps={{
                            classes: {
                                input: classes.tablemultiFont,
                                root: inputStyle
                            },
                        }}
                        onKeyDown={handleOnBlur}
                        onChange={(event) => {
                            setValue(event.target.value);
                            handleChangeTableValue(event.target.value);
                        }}
                        type="number"
                        value={value}
                    />
                    <span id="icon-create" className="material-icons md-small green" onClick={() => handleGetValueFromTable(props.col, props.fields, value)}>check</span>
                    {
                        value !== null && value !== undefined && (value.length > 0 && props.col.id !== 1000) &&
                        <span id="icon-create" className="material-icons md-small red" onClick={() => handleChangeTableValue("")}>delete</span>
                    }
                </div>
            )
            break;
        case 'presel':
            let multiselect = props?.col?.mask?.multiselect || false;
            let editable = props?.col?.mask?.editable || false;
            if (multiselect === false && editable === false) {
                template = (
                    <div className={widerCtn}>
                        <RenderItemSingleSelectComponent
                            col={props.col}
                            handleChangeChildrenValue={props.handleChangeChildrenValue}
                            rindex={props.rindex}
                            row={props.row}
                            isTablemulti
                            multiselect={multiselect} editable={editable}
                            presel={props.col.presel} disabled={disabled || !isEditable} value={value} />
                    </div>
                );
            }
            else if (multiselect === false && editable === true) {
                let presel = props.col.presel;
                if (!presel?.includes(value)) {
                    presel.push(value)
                }
                template = (
                    <div className={widerCtn}>
                        <RenderItemSingleSelectComponent
                            col={props.col}
                            handleChangeChildrenValue={handleChangeTableValue}
                            rindex={props.rindex}
                            row={props.row}
                            isTablemulti
                            multiselect={multiselect} editable={editable}
                            openDefault={openDefault} presel={presel} disabled={disabled || !isEditable} value={value} />
                    </div>
                );
            }
            else if (multiselect === true && editable === false) {
                template = (
                    <div className={widerCtn}>
                        <RenderItemMultipleSelectComponent
                            col={props.col}
                            handleChangeChildrenValue={props.handleChangeChildrenValue}
                            rindex={props.rindex}
                            row={props.row}
                            isTablemulti
                            multiselect={multiselect} editable={editable}
                            openDefault={openDefault} presel={props.col.presel} disabled={disabled || !isEditable} value={value} />
                    </div>
                );
            }
            else if (multiselect === true && editable === true) {
                let tmpValue = Array.isArray(value) ? value : [];
                let presel = props.col.presel;
                tmpValue.forEach((item, index) => {
                    if (!presel?.includes(item)) {
                        presel.push(item);
                    }
                });
                template = (
                    <div className={widerCtn}>
                        <RenderItemMultipleSelectComponent
                            col={props.col}
                            handleChangeChildrenValue={props.handleChangeChildrenValue}
                            rindex={props.rindex}
                            row={props.row}
                            isTablemulti
                            multiselect={multiselect} editable={editable}
                            presel={presel} disabled={disabled || !isEditable} value={value} />
                    </div>
                );
            }
            break;
        case 'radio':
            let masks = props.col.mask.split('/')
            template = (
                <div className={fieldCtn}>
                    {
                        props.col.mask === "Checkbox"
                        ? (
                            <div
                                onClick={() => handleChangeTableValue(value === 'Y' ? 'N' : 'Y')}
                                style={{ justifyContent: 'center', flex: 1 }}
                            >
                                <ColorCheckbox
                                    checked={value === 'Y'}
                                    color={value === '' ? colors.greyLight : colors.main}
                                    size={30}
                                />
                            </div>
                        )
                        : (
                            <div className={inputStyle} style={{ justifyContent: props.edit === 'cell' ? 'center' : 'flex-start' }}>
                                <RadioGroup
                                    className={classes.tablemultiRadioCtn}
                                    style={props.edit === 'cell' ? { justifyContent: 'center' } : undefined}
                                    aria-label="radio-button" name="radio-button"
                                    value={value} onChange={(event => handleChangeTableValue(event.target.value))}>
                                    {
                                        masks.map((mask, index) => {
                                            return (
                                                <FormControlLabel size='small' value={mask} control={<Radio classes={{ root: classes.radioIcon, checked: classes.radioIconChecked }} size='small' disabled={disabled || !isEditable} />} label={<span className={classes.radioLabel}>{mask}</span>} key={index} />
                                            )
                                        })
                                    }
                                </RadioGroup>
                            </div>
                        )
                    }
                </div>
            );
            break;
        case 'select':
            let presels = props.col.presel;
            template = (
                <div className={widerCtn}>
                    <Select
                        classes={{
                            icon: classes.selectIcon,
                            // nativeInput: `${classes.tablemultiInputCtn} ${classes.selectSelect}`,
                            root: `${classes.tablemultiFontWidth} ${classes.selectField}`,
                            select: `${classes.tablemultiInputCtn} ${classes.selectSelect}`,
                        }}
                        disabled={disabled || !isEditable}
                        disableUnderline
                        displayEmpty
                        fullWidth={true}
                        id="demo-simple-select"
                        labelId="demo-simple-select-label"
                        onChange={(event => handleChangeTableValue(event.target.value))}
                        onClick={() => setOpenSelect(!openSelect)}
                        open={openSelect}
                        renderValue={(value) => value !== '' && (<span className={classes.selectedItem}>{value}</span>)}
                        value={value}
                    >
                        {presels.map((row, index) => (
                            <MenuItem key={index} value={row} dense>{row}</MenuItem>
                        ))}
                    </Select>
                </div>
            );
            break;
        case 'tel':
            let telMasks = '+99999999999999'
            if (typeof props.col.regex !== "undefined" && props.col.regex !== null) {
                telMasks = props.col.regex
            }
            template = (
                <div className={fieldCtn}>
                    <TextField
                        autoFocus={props.edit === "cell"}
                        disabled={disabled || !isEditable}

                        placeholder={props.col.mask}
                        name={props.col.format + '-' + props.cindex}
                        fullWidth={true}
                        InputProps={{
                            classes: {
                                input: classes.tablemultiFont,
                                root: inputStyle
                            },
                        }}
                        onKeyDown={handleOnBlur}
                        onChange={(event) => handleChangeTableValue(event.target.value, event)}
                        type="tel"
                        value={value}
                    />
                </div>
            );
            break;
        case 'text':
            template = (
                <div className={fieldCtn}>
                    <TextField
                        autoFocus={props.edit === "cell"}
                        disabled={props.col.id === 1000 ? true : disabled || !isEditable}
                        fullWidth={true}
                        multiline={true}
                        inputProps={{ maxLength: props.col.mask, width: '100%' }}
                        name={props.col.format + '-' + props.cindex}
                        type="text"
                        value={value}
                        onKeyDown={handleOnBlur}
                        onChange={(event) => handleChangeTableValue(event.target.value, event)}
                        InputProps={{
                            classes: {
                                input: classes.tablemultiFont,
                                root: inputStyle
                            },
                            endAdornment: value !== null && value !== undefined && props.id !== '1000' && value.length > parseInt(props.col.mask) - 10 &&
                                <span className={classes.textLength}>{value.length}/{props.col.mask}</span>
                        }}
                        style={{ textAlign: 'center' }}
                    />
                </div>
            )
            break;
        case 'time': {
            //KeyboardTimePicker (hidden input, only icon & modal) --> in association  with InputMask
            let currentFieldMask = props.col?.mask ? props.col.mask : 'HH:MM (24H)'
            let maskFormat
            let pickerViews = []
            let minutesStepOption
            let placeholder
            let maskProp
            let timeIsInvalid = false

            if(props.value !== null && props.value?.length > 0) {
                if (!props.value?.includes('_')) { timeIsInvalid = isInvalidTime(currentFieldMask, props.value) }
            }

            switch (currentFieldMask) {
                case undefined:
                    maskFormat='HH:mm'
                    break
                case 'HH:MM (24H)':
                    //inputMask = '99:99'
                    pickerViews = ['hours', 'minutes']
                    maskFormat='HH:mm'
                    maskProp="__:__"
                    placeholder="HH:MM (24H)"
                    break
                case 'HH:MM:SS':
                    //inputMask = "99:99:99"
                    pickerViews = ['hours', 'minutes', 'seconds']
                    maskFormat='HH:mm:ss'
                    maskProp="__:__:__"
                    placeholder="HH:MM:SS"
                    break
                case 'MM:SS (60min)':
                    //inputMask = "99:99"
                    pickerViews = ['minutes', 'seconds']
                    maskFormat='mm:ss'
                    maskProp="__:__"
                    placeholder="MM:SS"
                    break
                case 'HH:MM (15min)':
                    //inputMask = '99:99'
                    pickerViews = ['hours', 'minutes']
                    maskFormat='HH:mm'
                    maskProp="__:__"
                    minutesStepOption = 15
                    placeholder="HH:MM (15min)"
                    break
                case 'HH H MM':
                    //inputMask = "99 H 99"
                    pickerViews = ['hours', 'minutes']
                    maskFormat='HH H MM'
                    maskProp="__H__"
                    placeholder="HH H HH"
                    break
                case 'Free Text':
                    pickerViews = ['hours', 'minutes']
                    maskFormat='HH:mm'
                    placeholder="Free Text"
                    break
                default:
                    break
            }

            let timeMask = '99:99:99'

            if (typeof props.col.regex !== "undefined" && props.col.regex !== null) {
                timeMask = props.col.regex
            }
            if (!value?.includes('_')) timeIsInvalid = isInvalidTime(props.col.mask, value)
            if (isDefined(value) && timeMask === '99') {
                timeMask = ''
            }

            function handleTimeChange(date, newValue) {
                let timeString = ""

                // CASES HH:MM (24H), MM:SS (60min)
                if(!newValue?.includes('_') && currentFieldMask !== "HH:MM (15min)" && currentFieldMask !== "HH H MM" && newValue?.length === 5) {
                    timeIsInvalid = isInvalidTime(currentFieldMask, newValue)
                    let newDateTime = getDateTimeFromValue(newValue, currentFieldMask)
                    if (!disabled) props.handleChangeChildrenValue(newValue, props.col, props.rindex)
                    if(!timeIsInvalid) setSelectedTime(newDateTime) //Picker state
                }
                // CASE HH:MM (15min)
                if(!newValue?.includes('_') && currentFieldMask?.includes("(15min)") && newValue?.length === 5) {
                    if(!isUndefined(minutesStepOption) && newValue !== "") {
                        let timeStringRounded = roundQuarter(newValue)
                        timeString = timeStringRounded.roundedHours + ":" + timeStringRounded.roundedMinutes
                        timeIsInvalid = isInvalidTime(currentFieldMask, timeString)
                        let newDateTime = new Date(null, null, null, timeStringRounded.roundedHours, timeStringRounded.roundedMinutes)
                        if (!disabled) props.handleChangeChildrenValue(timeString, props.col, props.row, props.rindex);
                        if(!timeIsInvalid) setSelectedTime(newDateTime) //Picker state
                    }
                }
                // CASE HH:MM:SS
                if(!newValue?.includes('_') && currentFieldMask === "HH:MM:SS" && newValue?.length === 8) {
                    timeIsInvalid = isInvalidTime(currentFieldMask, newValue)
                    let newDateTime = getDateTimeFromValue(newValue, currentFieldMask)
                    if (!disabled) props.handleChangeChildrenValue(newValue, props.col, props.rindex)
                    if(!timeIsInvalid) setSelectedTime(newDateTime) //Picker state
                }
                // CASE HH H MM
                if(!newValue?.includes('_') && currentFieldMask === "HH H MM" && newValue?.length === 7) {
                    timeIsInvalid = isInvalidTime(currentFieldMask, newValue)
                    let newDateTime = getDateTimeFromValue(newValue, currentFieldMask)
                    if (!disabled) props.handleChangeChildrenValue(newValue, props.col, props.rindex)
                    if(!timeIsInvalid) setSelectedTime(newDateTime) //Picker state
                }

                if(!newValue?.includes('_') && currentFieldMask === "HH:MM (15min)" && newValue?.length === 5) {
                    setValue(timeString) //InputMask state (quarter rounded)
                } else {
                    if (!disabled) props.handleChangeChildrenValue(newValue, props.col, props.rindex)
                    setValue(newValue) //InputMask state (other cases)
                }
                setSelectedTime(date) //Picker state
            }

            template = (
                <div className={fieldCtn}>
                    <Fragment>
                        <KeyboardTimePicker
                            ampm={false}
                            autoComplete="off"
                            className={props.edit === "row" ? classes.pickerHiddenInputRowEdit : classes.pickerHiddenInputCellEdit}
                            disabled={disabled || !isEditable}
                            format={maskFormat === 'HH H MM' ? 'HH:mm' : maskFormat}
                            KeyboardButtonProps={{
                                'aria-label': 'change time',
                            }}
                            keyboardIcon={<Icon>access_time</Icon>}
                            InputAdornmentProps={{ position: 'start', height: '5em' }}
                            InputProps={{ disableUnderline: true }}
                            mask={maskProp}
                            minutesStep={isDefined(minutesStepOption) ? minutesStepOption : undefined}
                            onChange={(date, value) => { handleTimeChange(date, value) }}
                            placeholder={placeholder}
                            value={selectedTime || null}
                            views={pickerViews}
                        />
                        {currentFieldMask !== "Free Text" &&
                            <InputMask
                                disabled={disabled || !isEditable}
                                maskChar="_"
                                mask={timeMask}
                                onChange={(event) => {
                                    setAccept(false)
                                    handleTimeChange(new Date(), event.target.value)
                                }}
                                onKeyDown={handleOnBlur}
                                value={value}
                            >
                                {(inputProps) =>
                                    <TextField
                                        {...inputProps}
                                        autoFocus={props.edit === "cell"}
                                        disabled={disabled || !isEditable}
                                        fullWidth

                                        //placeholder={timeMask === '' ? '' : props.col?.mask}
                                        name={props.col.format + '-' + props.cindex}
                                        placeholder={placeholder || ""}
                                        type="text"
                                        InputProps={{
                                            classes: {
                                                input: classes.tablemultiFont,
                                                root: inputStyle
                                            },
                                            endAdornment:
                                                <Fragment>
                                                    {
                                                        value?.length > 0 && timeIsInvalid && !accept && !disabled &&
                                                        <PlugTooltip title={t('tablemulti.validate')} placement="right">
                                                        <span className={classes.fontError} onClick={() => setAccept(true)}>
                                                            {t('tablemulti.invalid-value')}
                                                            <span id="icon-add-playlist" className={`${classes.tablemultiBtn} material-icons`}>
                                                                check
                                                            </span>
                                                        </span>
                                                        </PlugTooltip>
                                                    }
                                                    {
                                                        !disabled && isEditable &&
                                                        <PlugTooltip title={(isDefined(value) && value !== "" && !value?.includes('_')) ? t('actions.delete') : t('tablemulti.current-time')} placement="top" className={classes.endButton} >
                                                            {
                                                                isDefined(value) && value !== "" && !value?.includes('_')
                                                                    ? <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={() => { setAccept(false); handleChangeTableValue("") }}>highlight_off</span>
                                                                    : <span
                                                                        className={`${classes.tablemultiInputBtn} material-icons`}
                                                                        onClick={() => {
                                                                            setAccept(false)
                                                                            handleTimeChange(new Date(), nowVal)
                                                                        }}
                                                                    >check_circle_outline</span>}
                                                        </PlugTooltip>
                                                    }
                                                </Fragment>
                                        }}
                                    />
                                }
                            </InputMask>
                        }
                    </Fragment>
                    {currentFieldMask === "Free Text" &&
                        <TextField
                            autoFocus={props.edit === "cell"}
                            disabled={disabled || !isEditable}
                            fullWidth={true}
                            name={props.col.format + '-' + props.cindex}
                            onKeyDown={handleOnBlur}
                            onChange={(event) => {
                                handleChangeTableValue(event.target.value, event);
                            }}
                            placeholder={placeholder || ""}
                            type="text"
                            value={value}
                            InputProps={{
                                classes: {
                                    input: classes.tablemultiFont,
                                    root: inputStyle
                                },
                                endAdornment: !disabled && isEditable &&
                                    <PlugTooltip
                                        title={(value !== "" && value !== null && value !== undefined) ? t('actions.delete') : t('tablemulti.current-time')}
                                        placement="top" className={classes.endButton}>
                                        {value !== "" && value !== null && value !== undefined ?
                                            <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={() => {
                                                setAccept(false);
                                                handleChangeTableValue("")
                                            }}>highlight_off</span>
                                            :
                                            <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={() => {
                                                setAccept(false);
                                                handleChangeTableValue(nowVal)
                                            }}>check_circle_outline</span>}
                                    </PlugTooltip>
                            }}
                        />
                    }
                </div>
            )
            break;
        }
        default:
            return (
                <div className={classes.editorCtn}>
                    <span className={classes.columnHeader}>{`<!> ERROR <!>`}</span>
                </div>
            )
    }

    return (
        !props.isPreview ?
            <div className={editorCtn}>
                {
                    props.edit === "row" &&
                    <div className={titleCtn}>
                        <span className={classes.fontFieldNumber}> {columns.indexOf(column) + 1} </span>
                        <span className={classes.fontFieldLabel}> {handleSpecialCharsInLabel(column.label, classes)} </span>
                    </div>
                }
                {template}
            </div>
            :
            template
    )
}

const RenderEditRowComponent = (props) => {
    // devLog('RenderEdit Row props', props)
    const classes = useStyles();
    const row = props.row
    const { t } = useTranslation('common')
    const setupbis = props?.setupbis || null

    return (
        <div
            className={classes.editFieldsCtn}
        >
            {
                Object.keys(row)
                    .sort((a, b) => {
                        if (a === '1000' || a === 1000) return 1; else if (b === '1000' || b === 1000) return -1; else return a - b
                    })
                    .map((id, cindex) => {
                        let column = getCellColumn(id, props.columns)
                        if (isHiddenColumn(column, props.mode)) { return null }
                        if(!isNull(setupbis)){
                            if (((props.mode === 'shared-update' || props.mode === 'shared-create' || props.mode === 'shared-view') && setupbis[cindex]?.visible === false)) { return null; }
                        }
                        let value = row[id]
                        return (
                            <RenderEditCellComponent
                                annexes={props.annexes}
                                createDataForAnnexeWithContent={props.createDataForAnnexeWithContent}
                                attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                                attemptSendMailSimple={props.attemptSendMailSimple}
                                col={column}
                                columns={props.columns}
                                control={props.control}
                                controlCreate={props.controlCreate}
                                controlUpdate={props.controlUpdate}
                                disabled={props.disabled}
                                edit="row"
                                field={props.field}
                                fields={props.fields}
                                handleChangeChildrenRowValue={props.handleChangeChildrenRowValue}
                                handleChangeChildrenValue={(value) => props.handleChangeChildrenValue(value, column)}
                                handleSaveComment={props.handleSaveComment}
                                handleValidate={props.handleEndEdit}
                                id={id}
                                isFromPwCode={props.isFromPwCode}
                                key={'edit-cell-' + id}
                                mode={props.mode}
                                model={props.model}
                                owner={props.owner}
                                ownerId={props.ownerId}
                                plugnote={props.plugnote}
                                renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                rindex={props.rindex}
                                cindex={cindex}
                                row={row}
                                share={props.share}
                                title={props.title}
                                user={props.user}
                                value={value}
                                dataHash={props?.dataHash}
                                shared={props?.shared}
                                handleSelectDataMultiCombo={props.handleSelectDataMultiCombo}
                            />
                        )
                    })}
            <PlugTooltip title={t('global.validate')} placement="right-start">
                <span className={`material-icons ${classes.validateIcon} ${classes.absoluteIcon}`}
                    style={{ bottom: 8, right: isMobile ? 8 : -48, zIndex: 100 }}
                    onClick={(e) => props.handleValidate(e)}
                >check</span>
            </PlugTooltip>
        </div>
    )
}

const RenderMenuPopup = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('common')
    const [anchor, setAnchor] = useState(null)
    const rindex = props.rindex
    const row = props.row
    const rowId = props.rowId
    const rows = props.rows
    const rowToDelete = props.rowToDelete

    const handleCancelDelete = () => {
        props.handleClose()
        props.setRowToDelete(null)
    }

    const handleOpen = (e) => {
        setAnchor(e.currentTarget)
        props.handleOpen()
    }

    const handleDeleteRow = () => {
        props.handleClose()
        props.handleDeleteSingleRow()
    }

    const handleToggleToDelete = () => {
        props.handleClose()
        props.handleToggleToDelete(rowId)
    }

    const handleDuplicate = (e) => {
        props.handleClose()
        if (!props.isLimitReached()) { props.handleClickDuplicate(row)
        } else { props.openLastRow() }
    }

    return (
        <span className={classes.verticalDotsCtn} >
            <span className="material-icons md-medium"
                onClick={handleOpen}
                style={{ display: 'flex', maxWidth: 28, width: 28 }}
            >
                more_vertic
            </span>
            <Popover
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                anchorEl={anchor}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                marginThreshold={0}
                open={props.open}
                onClose={props.handleClose}
            >
                {
                    // DELETE ROW CONFIRMATION
                    rowToDelete === rowId ?
                        <div className={classes.deleteConfirmationCtn} >
                            <span >{t('tablemulti.delete-row')}?</span>
                            <div className={classes.confirmBtnCtn}>
                                <span className={classes.confirmBtn} onClick={handleCancelDelete}>
                                    <span className={`${classes.confirmIcon} material-icons`} style={{ color: colors.plugformBlue }}>cancel</span>
                                </span>
                                <span className={classes.confirmBtn} onClick={handleDeleteRow}>
                                    <span className={`${classes.confirmIcon} material-icons`} style={{ color: colors.bloodOrange }}>delete</span>
                                </span>
                            </div>
                        </div>
                        :
                        <div className={classes.popoverCtn}>
                            {
                                !row.locked &&
                                <PlugTooltip title={t('tablemulti.delete-row')} placement="top-end">
                                    <div className={classes.popoverItemCtn} onClick={(e) => props.handleClickDelete(e, rowId)}>
                                    <MdDelete
                                        style={{ width: '2rem', height: '2rem', fill: colors.main, margin: '0 4px 0 -4px' }}
                                        className={classes.duplicateBtn}
                                    />
                                        <span style={{ color: 'black' }}>{t('tablemulti.delete-row')}</span>
                                    </div>
                                </PlugTooltip>
                            }
                            {
                                // DELETE ROWS MENU BTN
                                !row.locked && rows.length > 1 &&
                                <div className={classes.popoverItemCtn}
                                    onClick={handleToggleToDelete}
                                >
                                    <MdDeleteSweep
                                        style={{ width: '2rem', height: '2rem', fill: colors.main }}
                                        className={classes.duplicateBtn}
                                    />
                                    {/* <span
                                        className={`${classes.deleteBtnCtn} material-icons`}
                                        style={{ color: colors.plugnotesBlueLight }}
                                    >
                                        delete
                                    </span> */}
                                    <span style={{ color: 'black' }}>{t('tablemulti.delete-multiple')}</span>
                                </div>
                            }
                            {
                                // DUPLICATE ROW MENU BTN
                                props.isDefaultRowsQtyReached() &&
                                <div
                                    className={classes.popoverItemCtn}
                                    onClick={handleDuplicate}
                                >
                                    <RiFileCopyLine
                                        style={{ width: '2rem', height: '2rem', fill: colors.main }}
                                        className={classes.duplicateBtn}
                                    />
                                    <span style={{ color: 'black' }}>{t('tablemulti.duplicate-row')}</span>
                                </div>
                            }
                        </div>
                }
            </Popover>
        </span>
    )
}

const DEFAULT_MIN_WIDTH_CELL = 40
const DEFAULT_MAX_WIDTH_CELL = 1000
export const ModalTablemultiTableViewComponent = (props) => {
    const classes = useStyles();
    const [modalStyle] = useState(getModalStyle)
    const { t } = useTranslation('common')

    //Props
    const value = props.search
    const hideSearch = props.hideSearch
    const criteria = props.criteria
    const setup = props.field?.options?.setup
    const setupbis = props.field?.options?.setupbis || null

    //Refs
    const searchInput = useRef()
    const editedRow = useRef()

    //variables
    const [search, setSearch] = useState('')
    const [openSearch, setOpenSearch] = useState(false)
    const [openPop, setOpenPop] = useState(false)
    const [rows, setRows] = useState(props.rows?.length > 0 ? props.rows : [])
    const [filteredRows, setFilteredRows] = useState(rows)
    const [displayedRows, setDisplayedRows] = useState(rows)
    const [keyList, setKeyList] = useState([])
    const [completed, setCompleted] = useState(false)
    const [openRow, setOpenRow] = useState(props.openRow !== null ? { row: rows[props.openRow] } : null)
    const [rowLength, setRowLength] = useState(rows.length)
    const [rowToDelete, setRowToDelete] = useState(null);
    const [rowsToDelete, setRowsToDelete] = useState([])
    const [printing, setPrinting] = useState(false)
    const colRefs = useRef([])
    const isResizing = useRef(-1)
    const [tableWidth] = useState(calculateTableWidth)

    const showRowNumbers = setup?.layout?.showRowNb !== false && (
        (props.mode !== 'pdf' && !props.mode?.includes('plugcode-consult') && !props.mode?.includes('plugcode-complete')) ||
        (props.mode === 'pdf' && setup?.layout?.showRowNb?.showOnPdf !== false) ||
        (props.mode?.includes('plugcode-complete') && setup?.layout?.showRowNb?.showOnPf !== false) ||
        (props.mode?.includes('plugcode-consult') && setup?.layout?.showRowNb?.showOnPn !== false))

    function calculateTableWidth() {
        if (props.colonnes.some(col => isNaN(col?.colWidth))) {
            return '100%'
        }
        let width = 0
        props.colonnes.forEach(col => {
            if (!isHiddenColumn(col, props.mode)) {
                width += (col.colWidth > 40 ? col.colWidth : 40)
            }
        })
        return Math.ceil(width)
    }

    // Double-click handler
    let timer
    function handleExitModal() {
        setOpenRow(null)
        props.handleClose()
        setPrinting(false)
    }

    function handleCloseModal() {
        let ssTableView = getTableView()
        if (isDefined(ssTableView)) {
            props.handleSafeExitPlugnote()
        }
        else handleExitModal()
    }

    function handleChangeSearch(e) {
        setSearch(e.target.value)
    }

    function handleTogglePrintTable() { setPrinting(previous => !previous) }

    function handleSearch() {
        let result = []
        rows.forEach((row) => {
            let present = false
            Object.values(row).forEach(value => {
                if (value !== '' && typeof value !== 'boolean') {
                    if (typeof value === 'number') {
                        if (value.toString()?.includes(search.toString().toLowerCase())) present = true
                    } else if (typeof value === 'string') {
                        if (value.toLowerCase()?.includes(search.toLowerCase())) present = true
                    } else value.forEach(entry => {
                        if (entry.toLowerCase()?.includes(search.toLowerCase())) present = true
                    })
                }
            })
            if (present) result.push(row)
        })
        setFilteredRows(result)
    }

    function handleResetSearch() {
        setSearch('')
        searchInput.current.focus()
        setFilteredRows(rows)
    }

    function completeRows() {
        let _rows
        if (rows.length > 0 && !completed) {
            _rows = [...rows]
            _rows.forEach((row) => {
                if (row.recap !== true) keyList.forEach((key) => {
                    if (!row.hasOwnProperty(key)) row[key] = ''
                })
            })
            // devLog('completing rows', _rows)
            setRows(_rows)
            setCompleted(true)
        }
    }

    function handleEditCellValue(row, id, rindex) {
        let isEditable = isDefined(id) ? isEditableDefaultValue(props.field, id, rows.indexOf(row), props.mode, props.isFromPwCode) : true
        let format = getCellColumn(id, props.colonnes)?.format;

        if ((isDefined(id) && immutableFormats?.includes(format)) || id === '1000' || !isEditable) {

            // devLog('handleEditCellValue |||', props.field, id, row, props.mode, props.isFromPwCode, format)

            toast.error(
                <ToastMessage
                    message={isEditable === false ? 'helper.default-not-editable' : 'multiple.edit-impossible'}
                />, { autoClose: 3000 })
        } else if (!props.disabled) {
            setOpenRow({ id: id, row: row, rindex: rindex })
        }
    }

    function handleEndEdit(e) {
        e.preventDefault()
        e.stopPropagation()
        setOpenRow(null)
    }

    function handleAddRow(e) {
        props.setNewRow(null)
        props.handleAddTableMulti(e)
    }

    function handleClickRowNb(e, row, rindex) {
        if (openRow !== null && openRow?.row === row && openRow?.rindex === rindex) { handleEndEdit(e) }
        else handleEditCellValue(row, undefined, rindex)
    }

    const isDefaultRowsQtyReached = () => {
        const defaultRows = props.field?.options?.setup?.defaultRows || [];
        let isRowsLengthBigger = rows.length >= defaultRows.length;
        return isRowsLengthBigger
    }

    function handleClickDuplicate(row) {
        handleDuplicateRow(row);
    }

    function handleDuplicateRow(row) {
        if (row.hasOwnProperty('locked')) {
            let _newRowToDuplicate = { ...row }
            _newRowToDuplicate.locked = false;
            row = _newRowToDuplicate;
        }
        props.setNewRow(null)
        props.handleDuplicateRow(row)
    }

    function handleClickDelete(e, id) {
        setRowToDelete(id)
        // Sans double-clic
        // if (!isDefined(timer)) (timer = setTimeout(() => { setRowToDelete(id) }, 500))
        // if (e.detail == 2) {
        //     clearTimeout(timer)
        //     handleToggleToDelete(id)
        // }
    }

    function handleDeleteSelected() {
        confirmAlert({
            title: t('tablemulti.delete-rows'),
            message: t('tablemulti.confirm-delete'),
            buttons: [
                {
                    label: t('common:global.delete'),
                    onClick: () => {
                        setOpenSearch(false)
                        // let toDelete = rowsToDelete.slice()
                        //     .sort((a, b) => b - a)
                        // toDelete.forEach(entry => props.handleDeleteRow(entry - 1))
                        props.handleDeleteRow(rowsToDelete)
                        setRowsToDelete([])
                    },
                },
                {
                    label: t('common:global.cancel')
                },
            ]
        })
    }

    function handleCancelDelete() {
        setRowsToDelete([])
    }

    function handleToggleToDelete(id) {
        if (rowsToDelete.length > 0) {
            if (rowsToDelete?.includes(id)) {
                if (rowsToDelete.length === 1) setRowsToDelete([]);
                else setRowsToDelete(rowsToDelete.filter(entry => entry !== id))
            } else setRowsToDelete([...rowsToDelete, id]);
        } else setRowsToDelete([id]);
    }

    function handleDeleteSingleRow() {
        setOpenSearch(false)
        props.handleDeleteRow(rowToDelete - 1)
        setRowToDelete(null)
    }

    function openLastRow() {
        let _rows = rows.filter(row => row.recap !== true)
        let finalRow = _rows[_rows.length - 1]
        setOpenRow({ row: finalRow, id: undefined, rindex: _rows.indexOf(finalRow) })
        if (editedRow.current !== null && editedRow.current !== undefined && openRow !== null) setTimeout(() => {
            editedRow.current.scrollIntoView({ block: 'center' })
            setTimeout(() => editedRow.current.scrollIntoView({ block: 'center' }), 200)
        }, 200)
    }

    function handleToggleSearch() {
        setOpenSearch(!openSearch)
    }

    function handleCellClick(e, row, key, rindex) {
        e.preventDefault()
        e.stopPropagation()
        if(!props.disabled && !props.editMode){
            props.setNewRow(null)
            setOpenRow({ row: row, id: key, rindex: rindex })
            if (isMobile) { handleClickRowNb(e, row, rindex) } else { handleEditCellValue(row, key, rindex) }
        }
    }

    function isLimitReached() {
        return isRowLimitReached(props.field?.options?.setup?.rowLimit, rows)
    }

    const isEditableDirectly = (col) => {
        return (
            !props.disabled && (
            // col.id === 1001 ||
            col.format === 'extdata' ||
            col.mask === 'Checkbox'
        ))
    }

    useEffect(() => {
        // devLog('Modal Tablemulti u-e props.rows', props.rows)
        setRows(props.rows)
        let keys = []
        props.colonnes.forEach((col) => {
            if (!keys?.includes(col.id.toString())) keys.push(col.id.toString())
        })
        setKeyList(keys)
        setCompleted(false)
    }, [props.rows])

    useEffect(() => {
        if (rows.length !== props.field.value.length && search === "") setRows(props.rows)
    }, [search])

    completeRows()

    useEffect(() => {
        if (openRow === null) {
            if (rows.length !== rowLength) {
                setRowLength(rows.length)
            }
        }
        setFilteredRows(rows)
    }, [rows])

    useEffect(() => {
        if (editedRow.current !== undefined && editedRow.current !== null) editedRow.current.scrollIntoView({ behavior: "smooth" })
    }, [rowLength])

    useEffect(() => {
        if (editedRow.current !== null && editedRow.current !== undefined && openRow !== null) setTimeout(() => {
            if (editedRow.current !== null && editedRow.current !== undefined && openRow !== null) editedRow.current.scrollIntoView({ block: 'center' })
            setTimeout(() => {
                if (editedRow.current !== null && editedRow.current !== undefined && openRow !== null) editedRow.current.scrollIntoView({ block: 'center' })
            }, 200)
        }, 200)
    }, [openRow])

    useEffect(() => {
        setDisplayedRows(openSearch ? filteredRows : rows)
    }, [openSearch, filteredRows, rows])

    // Resize Column Widths
    const adjustWidthColumn = (index, width) => {
        // devLog('adjusting', width)
        const minWidth = props.colonnes[index]?.minWidth ?? DEFAULT_MIN_WIDTH_CELL
        const maxWidth = props.widths[index]?.maxWidth ?? DEFAULT_MAX_WIDTH_CELL
        let newWidth = width
        if (newWidth > maxWidth) { newWidth = maxWidth }
        if (newWidth < minWidth) { newWidth = minWidth }
        let _colWidths = [...props.widths]
        _colWidths[index].width = newWidth
        props.setWidths(_colWidths)
    }

    const setCursorDocument = (isResizing) => {
        document.body.style.cursor = isResizing ? "col-resize" : "auto"
    }

    const handleOnMouseMove = (e) => {
        if (isResizing.current >= 0) {
            // devLog('onMove', e)
            const newWidth = e.clientX - colRefs.current[
                isResizing.current
            ].parentElement?.getBoundingClientRect().left
            adjustWidthColumn(isResizing.current, newWidth)
        }
    }

    const onClickResizeColumn = (index) => {
        // devLog("start resize", index)
        isResizing.current = index
        setCursorDocument(true)
        // devLog('isResizing', isResizing)
    }

    const handleOnMouseUp = () => {
        // devLog("end resize")
        if (props.isModelPreview) {
            isResizing.current = -1
            setCursorDocument(false)
            props.setEdited(true)
        }
    }

    useEffect(() => {
        document.onmousemove = handleOnMouseMove
        document.onmouseup = handleOnMouseUp
        return () => {
            document.onmousemove = null;
            document.onmouseup = null;
        }
    }, [])

    useLayoutEffect(() => {
        if (props.isModelPreview && isDefined(colRefs.current)) {
            let _colWidths = [...props.widths]
            _colWidths.forEach((col, index) => {
                if (isDefined(colRefs.current[index])) {
                    let width = colRefs.current[index].parentElement?.clientWidth
                    _colWidths[index].width = width
                }
            })
            props.setWidths(_colWidths)
            props.setEdited(false)
        }
        // devLog('colWidths', props.widths)
    }, [])

    useLayoutEffect(() => {
        //   devLog('Modale props u-e', props)
    }, [props.widths])

    let clickTimer;
    const handleClick = (e, rindex, key) => {
        e.preventDefault()
        e.stopPropagation()
        // devLog('handleClick')
        if (!props.disabled && rows.length === 0) {
            // devLog('setup', setup)
            props.handleAddTableMulti(setup?.defaultComplete)
        }
        if (!isDefined(clickTimer)) {
            clickTimer = setTimeout(() => {
                props.handleClickPreview()
            }, 500);
        } else {
            if (isDefined(key)) props.handleClickPreview(rindex, key)
            else props.handleClickPreview()
            clearTimeout(clickTimer);
        }
    }

    let _defaultFontsize = isDefined(props.field?.options?.setup?.layout?.defaultFontSize) ? props.field?.options?.setup?.layout?.defaultFontSize : 11
    let _defaultLabelSize = isDefined(props.field?.options?.setup?.layout?.defaultLabelSize) ? props.field?.options?.setup?.layout?.defaultLabelSize : 11

    const TablemultiHorizontalView = (
        <TablemultiHorizontalViewComponent
            annexes={props.annexes}
            attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
            attemptSendMailSimple={props.attemptSendMailSimple}
            columns={props.colonnes}
            control={props.control}
            controlCreate={props.controlCreate}
            controlUpdate={props.controlUpdate}
            createDataForAnnexeWithContent={props.createDataForAnnexeWithContent}
            dataHash={props?.dataHash}
            _defaultFontsize={_defaultFontsize}
            _defaultLabelSize={_defaultLabelSize}
            disabled={props.disabled}
            editedCell={openRow}
            field={props.field}
            fields={props.fields}
            getCellColumn={getCellColumn}
            getColumnLabel={getColumnLabel}
            handleChangeChildrenRowValue={props.handleChangeChildrenRowValue}
            handleChangeChildrenValue={props.handleChangeChildrenValue}
            handleClickCell={handleCellClick}
            handleClickPreview={handleClick}
            handleDeleteRow={props.handleDeleteRow}
            handleDuplicateRow={handleDuplicateRow}
            handleOpenModalView={props.handleClickPreview}
            isFromPwCode={props.isFromPwCode}
            handleValidate={handleEndEdit}
            isPreview={props.isPreview}
            isModelPreview={props.isModelPreview}
            keyList={keyList}
            mode={props.mode}
            model={props.model}
            openModal={props.open}
            owner={props.owner}
            ownerId={props.ownerId}
            plugnote={props.plugnote}
            renderAnnexeIconPreview={props.renderAnnexeIconPreview}
            renderCellValue={prop => <RenderCellValue {...prop}/>}
            renderEditCellComponent={prop => <RenderEditCellComponent {...prop}/>}
            rows={rows}
            share={props.share}
            title={props.title}
            user={props.user}
            showRowNumbers={showRowNumbers}
            widths={props.widths}
            handleSelectDataMultiCombo={props.handleSelectDataMultiCombo}
        />
    )

    let decimalSeparator = ','
    let thousandSeparator = '.'
    if (i18n.language === 'en') {
        decimalSeparator = '.'
        thousandSeparator = ','
    }

    if (props.isPreview === true) {
        return (
            <div
                className={`${props.isModelPreview ? classes.modelPreviewCtn : classes.tablePreviewCtn} ${classes.scrollBar} ${props.isModelPreview ? "disable-text-selection" : undefined}`}
                key={'table-' + props.field.label}
            >
                {
                    setup?.tableOrientation === 'horizontal'
                        ? (
                            TablemultiHorizontalView
                        )
                        : (
                            <Table stickyHeader
                                style={
                                    props.isModelPreview
                                        ? { maxWidth: '99%', position: 'relative' }
                                        : { position: 'relative' }
                                }
                            >
                                <TableHead className={classes.tableHeader} sx={{ display: "table-header-group" }} aria-label="simple table" onClick={(e) => { handleClick(e) }}>
                                    <TableRow className={classes.columnHeader}>
                                        {
                                            showRowNumbers &&
                                            <TableCell align="center" className={`${classes.stickyColumn} ${classes.rowNbColumnHeader}`} width={30}>
                                                <span className={classes.columnHeader} style={{ fontSize: 11 }} >N°</span>
                                            </TableCell>
                                        }
                                        {
                                            props.colonnes.map((entry, id) => {
                                                if (isHiddenColumn(entry, props.mode)) { return null; }
                                                if (props.mode === 'pdf' && entry.showOnPdf === false) { return null }
                                                if(!isNull(setupbis)){
                                                    if (((props.mode === 'shared-update' || props.mode === 'shared-create' || props.mode === 'shared-view') &&  setupbis[id]?.visible === false)) { return null; }
                                                }
                                                let lastRow = rows[rows.length - 1]
                                                let totals = []
                                                let total = ""
                                                if (rows.length > 0 && lastRow.hasOwnProperty('recap')) {
                                                    totals = Object.keys(lastRow)
                                                    if (totals?.includes(entry.id.toString())) {
                                                        if (lastRow[entry.id] !== null && lastRow[entry.id] !== undefined && lastRow[entry.id] !== "") {
                                                            if (lastRow[entry.id].toString()?.includes(':')) total = lastRow[entry.id];
                                                            else total = Math.round(lastRow[entry.id] * 1000) / 1000
                                                        }
                                                    }
                                                }
                                                let cellWidth = props.isModelPreview ? props.widths[id].width : entry.colWidth || 'auto'
                                                const headerStyle = {
                                                    fontSize: isDefined(entry.headerFontSize) ? entry.headerFontSize : _defaultLabelSize,
                                                    width: cellWidth,
                                                    whiteSpace: "break-spaces",
                                                }

                                                {/* if (props.isModelPreview) {
                                                    headerStyle.display = 'flex'
                                                    headerStyle.flexDirection = 'column'
                                                } */}

                                                const totalStyle = {
                                                    fontSize: isDefined(entry.fontSize) ? entry.fontSize : _defaultLabelSize,
                                                    verticalAlign: 'top',
                                                }
                                                switch (entry.rowAlignment) {
                                                    case 'left':
                                                        totalStyle.marginRight = 'auto'
                                                        break
                                                    case 'right':
                                                        totalStyle.marginLeft = 'auto'
                                                        break
                                                    case 'center':
                                                    default:
                                                        totalStyle.marginLeft = 'auto'
                                                        totalStyle.marginRight = 'auto'
                                                        break
                                                }

                                                return (
                                                    <TableCell
                                                        id={'table-header-' + props.field.label + '-' + id}
                                                        key={'table-header-' + props.field.label + '-' + id}
                                                        style={headerStyle}
                                                        align={entry.labelAlignment || 'center'}
                                                    >
                                                        <span className={classes.columnHeader}>
                                                            {getColumnLabel(entry, _defaultLabelSize, setup?.tableOrientation)}
                                                            {
                                                                total !== '' &&
                                                                <div className={classes.totalCtn} style={{ textAlign: entry.rowAlignment || 'center' }}>
                                                                    <NumericFormat value={total} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} displayType="text"
                                                                        renderText={
                                                                            (value, _) => <span className={classes.totalValues} style={totalStyle}>
                                                                                {value}
                                                                            </span>}
                                                                    />
                                                                </div>
                                                            }
                                                            <FieldVisibilityIndicator field={entry} mode={props.mode} isTableColumn />
                                                        </span>
                                                        {
                                                            props.isModelPreview &&
                                                            <div
                                                                onMouseDown={() => onClickResizeColumn(id)}
                                                                ref={el => colRefs.current[id] = el}
                                                                className={classes.resizable}
                                                            />
                                                        }
                                                    </TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.tableBodyCtn}>
                                    {
                                        rows.length > 0 &&
                                        rows.slice(0, props.isModelPreview ? 3 : 49)
                                            .map((row, rindex) => {
                                                if (row.recap !== true) {
                                                    return (
                                                        <TableRow key={'table-row-' + props.field.label + '-' + rindex}>
                                                            {
                                                                showRowNumbers &&
                                                                <TableCell
                                                                    align="center" key={"row-index-" + rindex}
                                                                    style={{ borderLeft: `1px solid ${colors.paleGrey}`, backgroundColor: 'white' }}
                                                                    className={`${classes.rowNbCell} ${classes.stickyColumn} `}
                                                                    component="th"
                                                                    scope="row"
                                                                >
                                                                    <span className={classes.rowNb}>{rindex + 1}</span>
                                                                </TableCell>
                                                            }
                                                            {
                                                                row.recap !== true &&
                                                                Object.keys(row)
                                                                    .sort((a, b) => {
                                                                        if (a === '1000' || a === 1000) return 1; else if (b === '1000' || b === 1000) return -1; else return a - b
                                                                    })
                                                                    .map((key, cindex) => {
                                                                        let col = props.colonnes.find(entry => entry.id.toString() === key.toString());
                                                                        if (!isDefined(col) || col.hidden || (props.mode === 'plugcode-complete' && col.encodable === false) || (props.mode === 'plugcode-consult' && col.visible === false)) { return null }
                                                                        if (props.mode === 'pdf' && col.showOnPdf === false) { return null }
                                                                        if(!isNull(setupbis)) {
                                                                            if (((props.mode === 'shared-update' || props.mode === 'shared-create' || props.mode === 'shared-view') && setupbis[cindex]?.visible === false)) { return null; }
                                                                        }
                                                                        const cellStyle = {
                                                                            fontSize: isDefined(col.fontSize) && col.fontSize > -1 ? col.fontSize : _defaultFontsize,
                                                                            whiteSpace: "break-spaces",
                                                                        }

                                                                        if (isEditableDirectly(col)) {
                                                                            return (
                                                                                <TableCell
                                                                                    align={col.rowAlignment || 'center'}
                                                                                    key={"cell-" + key + '-' + cindex}
                                                                                    style={{ ...cellStyle, position: 'relative' }}
                                                                                >
                                                                                    <RenderEditCellComponent
                                                                                        createDataForAnnexeWithContent={props.createDataForAnnexeWithContent}
                                                                                        annexes={props.annexes}
                                                                                        attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                                                                                        attemptSendMailSimple={props.attemptSendMailSimple}
                                                                                        containerStyle={cellStyle}
                                                                                        col={col}
                                                                                        columns={props.colonnes}
                                                                                        control={props.control}
                                                                                        controlCreate={props.controlCreate}
                                                                                        controlUpdate={props.controlUpdate}
                                                                                        disabled={props.disabled}
                                                                                        edit="preview"
                                                                                        field={props.field}
                                                                                        fields={props.fields}
                                                                                        handleChangeChildrenRowValue={props.handleChangeChildrenRowValue}
                                                                                        handleChangeChildrenValue={(value) => props.handleChangeChildrenValue(value, col, rindex)}
                                                                                        handleSaveComment={props.handleSaveComment}
                                                                                        handleValidate={handleEndEdit}
                                                                                        id={key}
                                                                                        isPreview
                                                                                        key={'edit-cell-' + rindex + '-' + key}
                                                                                        mode={props.mode}
                                                                                        model={props.model}
                                                                                        open={true}
                                                                                        owner={props.owner}
                                                                                        ownerId={props.ownerId}
                                                                                        plugnote={props.plugnote}
                                                                                        renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                                                                        rindex={rindex}
                                                                                        cindex={cindex}
                                                                                        row={row}
                                                                                        share={props.share}
                                                                                        title={props.title}
                                                                                        user={props.user}
                                                                                        value={row[key]}
                                                                                        dataHash={props?.dataHash}
                                                                                        handleSelectDataMultiCombo={props.handleSelectDataMultiCombo}
                                                                                    />
                                                                                </TableCell>
                                                                            )
                                                                        }

                                                                        return (keyList?.includes(key) &&
                                                                            <TableCell
                                                                                align={col.rowAlignment || 'center'}
                                                                                key={"cell-" + key + '-' + cindex}
                                                                                style={cindex === keyList.length - 1 ? { ...cellStyle, position: 'relative' } : cellStyle}
                                                                                onClick={(e) => handleClick(e, rindex, key)}
                                                                            >
                                                                                {
                                                                                    row[key] === '' ?
                                                                                        <div className={classes.voidCell}>
                                                                                            <span className={classes.voidCell} />
                                                                                        </div>
                                                                                        :
                                                                                        <RenderCellValue
                                                                                            annexes={props.annexes}
                                                                                            columns={props.colonnes}
                                                                                            defaultFontsize={_defaultFontsize}
                                                                                            disabled={props.disabled}
                                                                                            id={key}
                                                                                            value={row[key]}
                                                                                            row={row}
                                                                                            fields={props.fields}
                                                                                            isPreview
                                                                                        />
                                                                                }
                                                                            </TableCell>
                                                                        )
                                                                    })}
                                                            {
                                                                row.recap === true && keyList.map((key, cindex) => {
                                                                    let col = props.colonnes.find(entry => entry.id.toString() === key.toString());
                                                                    if ((props.mode === 'plugcode-complete' && col.encodable === false) || (props.mode === 'plugcode-consult' && col.visible === false)) { return null; }
                                                                    if (row.hasOwnProperty(key)) {
                                                                        return (
                                                                            keyList?.includes(key) &&
                                                                            <TableCell
                                                                                align={col.rowAlignment || 'center'}
                                                                                key={"cell-" + key + '-' + cindex}
                                                                                style={{ border: '1px solid #DEDEDE', borderTop: 'none' }}
                                                                            >
                                                                                <RenderCellValue
                                                                                    annexes={props.annexes}
                                                                                    columns={props.colonnes}
                                                                                    defaultFontsize={_defaultFontsize}
                                                                                    disabled={props.disabled}
                                                                                    id={key}
                                                                                    value={row[key]}
                                                                                    row={row}
                                                                                    fields={props.fields}
                                                                                />
                                                                            </TableCell>
                                                                        )
                                                                    }
                                                                    else return (
                                                                        <TableCell align="center" key={"cell-" + key + '-' + cindex} style={{ border: 'none' }}>
                                                                            <div className={classes.voidCell} >
                                                                                <span className={classes.voidCell} />
                                                                            </div>
                                                                        </TableCell>
                                                                    )
                                                                })
                                                            }
                                                        </TableRow>)
                                                }
                                                else return null
                                            })
                                    }
                                    {
                                        rows.length === 0 &&
                                        <TableRow >
                                            {
                                                showRowNumbers &&
                                                <TableCell
                                                    align="center"
                                                    key={props.field.label + '-cell-'}
                                                    onClick={(e) => { handleClick(e) }}
                                                    className={classes.noDataCell}
                                                >
                                                    <div className={classes.voidCell}>
                                                        <span className={classes.voidCell} />
                                                    </div>
                                                </TableCell>
                                            }
                                            {
                                                props.colonnes.map((entry, cindex) => {
                                                    const cellStyle = {
                                                        fontSize: isDefined(entry.fontSize) ? entry.fontSize : 12,
                                                        width: isDefined(entry.colWidth) ? entry.colWidth : 'auto',
                                                        whiteSpace: "break-spaces",
                                                    }
                                                    if ((props.mode !== 'plugcode-complete' || entry.encodable !== false) && (props.mode !== 'plugcode-consult' || entry.visible !== false)) {
                                                        return (<TableCell
                                                            align="center"
                                                            key={props.field.label + '-cell-' + cindex}
                                                            style={cindex === keyList.length - 1 ? { ...cellStyle, position: 'relative' } : cellStyle}
                                                            onClick={(e) => { handleClick(e) }}
                                                            className={classes.noDataCell}
                                                        >
                                                            <div className={classes.voidCell}>
                                                                <span className={classes.voidCell} />
                                                            </div>
                                                        </TableCell>)
                                                    } else return null
                                                })
                                            }
                                        </TableRow>
                                    }
                                    {
                                        rows.length > 50 &&
                                        <TableRow className={classes.viewMoreTxt} onClick={props.handleViewTable}>
                                            <TableCell className={classes.viewMoreTxt}>{t('tablemulti.view-more')}</TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        )
                }
            </div>
        )
    }
    else if (props.toPrint === true) {
        if (props.field?.options?.setup?.printable === false) {
            return null
        } else {
            let colonnes = []
            props.colonnes.forEach(entry => {
                let lastRow = rows[rows.length - 1]
                let hasTotal = false
                let totals = []
                let total = ""
                if (rows.length > 0 && lastRow.hasOwnProperty('recap')) {
                    totals = Object.keys(lastRow)
                    if (totals?.includes(entry.id.toString())) {
                        hasTotal = true
                        if (lastRow[entry.id] !== null && lastRow[entry.id] !== undefined && lastRow[entry.id] !== "") {
                            if (lastRow[entry.id].toString()?.includes(':')) total = lastRow[entry.id];
                            else total = Math.round(lastRow[entry.id] * 1000) / 1000
                        }
                    }
                }
                // Set the colWidth Value to send to PDF, preview or Modal if it is undefined or still not set
                if (entry.colWidth === undefined || entry.colWidth === null || entry.colWidth === '') {
                    const label = entry.label;

                    let maxLength = 0;
                    let currentLength = 0;
                    //check tilde and #
                    for (let i = 0; i < label.length; i++) {
                        if (label[i] === "~") {
                            maxLength = Math.max(maxLength, currentLength);
                            currentLength = 0;
                        } else if (label?.includes("#")) {
                            const indexOfHash = label.indexOf("#");
                            currentLength = indexOfHash;
                        } else {
                            currentLength++;
                        }
                    }
                    maxLength = Math.max(maxLength, currentLength);

                    entry.colWidth = maxLength
                }

                // Set the fontSize Value to send to PDF, preview or Modal if it is undefined or still not set
                if (!isDefined(entry.fontSize) || entry.fontSize === '' || entry.fontSize === -1) {
                    entry.fontSize = isDefined(_defaultFontsize) ? (_defaultFontsize) : 11
                }
                if (!isDefined(entry.headerFontSize) || entry.headerFontSize === -1) {
                    entry.headerFontSize = isDefined(_defaultLabelSize) ? _defaultLabelSize : 11
                }

                let col = {
                    editable: entry.encodable, // PF- visibility
                    format: entry.format,
                    hidden: entry.hidden,
                    id: entry.id,
                    value: entry.label,
                    colWidth: entry.colWidth,
                    fontSize: entry.fontSize,
                    headerFontSize: entry.headerFontSize,
                    labelAlignment: entry.labelAlignment,
                    rowAlignment: entry.rowAlignment,
                    showOnPdf: entry.showOnPdf,
                    visible: entry.visible,
                }
                if (hasTotal) col['total'] = total
                colonnes.push(col);
            })

            let _rows = Array.isArray(props.rows) ? props.rows : []
            // devLog('Printing columns', colonnes)
            // devLog('Printing rows', _rows)
            return (
                setup?.tableOrientation === 'horizontal'
                ? (
                    <PDFHorizontalTablemulti
                        borderColor={colors.borderGrey}
                        fieldId={props.field.id}
                        fieldLabel={props.field.title}
                        header={colonnes}
                        hiddenOpt={props.hiddenOpt}
                        rows={_rows.filter((row) => !row.hasOwnProperty('recap'))}
                        setup={props.field.options.setup}
                        setupbis={props.field?.options?.setupbis}
                        mode={props.mode}
                    />
                )
                : (
                    <PDFTablemulti
                        borderColor={colors.borderGrey}
                        fieldId={props.field.id}
                        fieldLabel={props.field.title}
                        header={colonnes}
                        hiddenOpt={props.hiddenOpt}
                        isAttachment={props.isAttachment}
                        rows={_rows.filter((row) => !row.hasOwnProperty('recap'))}
                        setup={props.field.options.setup}
                        setupbis={props.field?.options?.setupbis}
                        mode={props.mode}
                    />
                )
            )
        }
    }
    else {
        // devLog('Modale Tablemulti props', props)
        return (
            <Modal
                keepMounted={false}
                open={props.open}
                onClose={handleExitModal}
                className={classes.modal}
                BackdropProps={{ style: { backgroundColor: colors.blackAlpha } }}
            >
                <div style={modalStyle} className={classes.popup} key={'modal-table-' + props.field.label}>
                    <div className={classes.modalHeader}>
                        <div className={classes.searchInputCtn}>
                            {
                                props.hasDb &&
                                <TextField
                                    disabled={props.disabled}
                                    onChange={(event) => props.handleEditValue(event.target.value)}
                                    id="standard-full-width"
                                    style={{ margin: 0, maxWidth: isMobile ? '80vw' : 350 }}
                                    type="text"
                                    multiline={false}
                                    className={classes.textField}
                                    fullWidth={isMobile}
                                    helperText=""
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        disableUnderline: true,
                                        endAdornment: (
                                            <Fragment>
                                                {
                                                    !hideSearch &&
                                                    <div className={classes.searchBtn} > {criteria}
                                                        <span id="icon-listForm" className={`material-icons md-25`} style={{ color: colors.main }} onClick={(event) => { if (!props.disabled && !hideSearch) props.handleOpenSearchModel() }}>
                                                            search
                                                        </span>
                                                    </div>
                                                }
                                            </Fragment>
                                        ),
                                    }}
                                    margin="normal"
                                    onKeyDown={(e) => { if (e.key === 'Enter' && !props.disabled && !hideSearch) props.handleOpenSearchModel() }}
                                    placeholder={t('ext-table.search-table')}
                                    value={value}
                                />
                            }
                            {
                                props.isModelPreview &&
                                <FormControl>
                                    <FormLabel id="mode-select-group-label">{t('modes.mode-select')}</FormLabel>
                                    <RadioGroup
                                        className={classes.radioGroupCtn}
                                        aria-label="gender" name="gender1"
                                        title={t('modes.mode-select')}
                                        onChange={props.handleChangePreviewMode}
                                        value={props.mode}
                                    >
                                        {
                                            existingModes.map((group, index) => {
                                                let mode = group.modes[0]
                                                if (mode.mode?.includes('shared')) { return null }
                                                return (
                                                    <FormControlLabel size='small' value={mode.mode}
                                                        control={
                                                            <Radio classes={{ root: classes.radioIcon, checked: classes.radioIconChecked }}
                                                                size='small'
                                                            />
                                                        }
                                                        label={<span className={classes.radioLabel}>{t('modes.' + mode.mode)}</span>}
                                                        key={index}
                                                    />
                                                )
                                            })}
                                        <FormControlLabel size='small' value={"pw-code"}
                                            control={
                                                <Radio classes={{ root: classes.radioIcon, checked: classes.radioIconChecked }}
                                                    size='small'
                                                />
                                            }
                                            label={<span className={classes.radioLabel}>{t('modes.pw-code')}</span>}
                                            key="pw-code-radio"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            }
                        </div>

                        <div className={classes.headerTitle}>
                            <span className="titres align-items-center">
                                {props.field.titleTranslate}
                            </span>
                            <br />
                        </div>

                        <div className={classes.closeBtn}>
                            <span className={classes.exportIconCtn}>
                                {
                                    rows.length > 0 &&
                                    <PlugTooltip title={openSearch ? t('filters.close-search') : t('filters.search')} placement="bottom">
                                        <span style={{ fontSize: 30, color: colors.main }}>
                                            <span id="icon-add-playlist" className={`${classes.tablemultiExportButton} material-icons`} onClick={handleToggleSearch} style={{ fontSize: 30, color: colors.main }}>
                                                search
                                            </span>
                                        </span>
                                    </PlugTooltip>
                                }
                                {
                                    props.limited === true ?
                                        <PlugTooltip title={t('filters.update-selection')} placement="bottom">
                                            <span style={{ fontSize: 30, color: colors.main }}>
                                                <span id="icon-add-playlist" className={`${classes.tablemultiExportButton} material-icons`} onClick={props.handleUpdateClose} style={{ fontSize: 30, color: colors.main }}>
                                                    save
                                                </span>
                                            </span>
                                        </PlugTooltip>
                                        :
                                        <PlugTooltip title={t('filters.export-csv')} placement='bottom'>
                                            <span>
                                                <FontAwesomeIcon icon={faFileCsv} size="lg" style={{ fontSize: 33, color: colors.mainButton }} className={`${classes.tablemultiExportButton} material-icons`} onClick={props.handleExportTableMulti} />
                                                {props.openDownload &&
                                                    <CSVDownload data={props.datasToDownload} separator={";"} />
                                                }
                                            </span>
                                        </PlugTooltip>
                                }
                                <PlugTooltip title={t('print.print-pdf')} placement="bottom">
                                    <span onClick={handleTogglePrintTable}>
                                        <FontAwesomeIcon icon={faFilePdf} size="lg" style={{ fontSize: 33, color: colors.mainButton }} className={`${classes.tablemultiExportButton} material-icons`} />
                                    </span>
                                </PlugTooltip>
                                {
                                    props.recap && props.rows.length > 0 && !props.rows[props.rows.length - 1].hasOwnProperty('recap') &&
                                    <PlugTooltip title="Calculate total(s)" placement="bottom">
                                        <span id="icon-add-playlist" className={`${classes.tablemultiExportButton} material-icons `} onClick={(e) => { props.handleAddRecap(e) }} style={{ fontSize: 32, color: colors.mainButton }}>
                                            title
                                        </span>
                                    </PlugTooltip>
                                }
                                {
                                    !props.disabled && props.field?.options?.setup?.rowLimit !== 1 &&
                                    <PlugTooltip title={!isLimitReached() ? t('tablemulti.add-row') : t('tablemulti.row-limit-warn')} placement="bottom">
                                        <span id="icon-add-playlist"
                                            className={`${classes.tablemultiExportButton} material-icons ${isLimitReached() ? classes.disabledIcon : classes.icon} `}
                                            onClick={(e) => { if (!isLimitReached()) handleAddRow(e) }}
                                        >
                                            add_circle_outline
                                        </span>
                                    </PlugTooltip>
                                }
                            </span>
                            {
                                isDefined(getTableView()) &&
                                <PlugTooltip title={t('global.cancel')} placement="bottom">
                                    <span id="icon-close"
                                        className="material-icons md-50"
                                        color={colors.iconGrey}
                                        style={{
                                            color: colors.iconGrey,
                                            marginLeft: 20,
                                        }}
                                        onClick={handleExitModal}>
                                        cancel
                                    </span>
                                </PlugTooltip>
                            }

                            <PlugTooltip title={t('multiple.exit')} placement="bottom">
                                <span id="icon-close"
                                    className="material-icons md-50"
                                    color={colors.iconGrey}
                                    style={{
                                        color: colors.iconGrey,
                                        marginLeft: 20,
                                        // borderWidth: 4, borderRadius: '50%', width: 50, height: 50, border: 'solid', display: 'flex',
                                    }}
                                    onClick={handleCloseModal}>
                                    logout
                                </span>
                            </PlugTooltip>

                            {
                                openSearch &&
                                <Input
                                    autoComplete="new-password"
                                    autoFocus
                                    className={classes.searchInput}
                                    disableUnderline={true}
                                    endAdornment={
                                        search !== "" &&
                                        <InputAdornment
                                            onClick={handleResetSearch}
                                            position="end"
                                            style={{ cursor: 'pointer', color: colors.mainButton }}>
                                            <span className={`material-icons md-25 `}>highlight_off</span>
                                        </InputAdornment>
                                    }
                                    id="standard-search-field"
                                    inputRef={searchInput}
                                    margin="none"
                                    placeholder={t('common:global.search')}
                                    onChange={handleChangeSearch}
                                    onKeyPress={(e) => { if (e.key === 'Enter') handleSearch() }}
                                    startAdornment={
                                        <InputAdornment position="start" style={{ cursor: 'pointer', color: search === '' ? '#bababa' : colors.mainButton }} onClick={handleSearch}>
                                            <Search />
                                        </InputAdornment>}
                                    value={search}
                                />
                            }
                        </div>
                    </div>
                    {
                        printing ?
                            <div className={classes.printPreviewCtn}>
                                <PDFDownloadLink
                                    document={
                                        <TableMultiPrintable
                                            field={props.field}
                                            fieldLabel={props.field.title}
                                            plugnoteNb={props.plugnoteNb}
                                            mode={props.mode}
                                            model={props.model}
                                            t={t}
                                        />
                                    }
                                    fileName={props.field.title + '.pdf'}
                                    className={classes.pdfComponentCtn}
                                >
                                    {({ blob, url, loading, error }) =>
                                        loading
                                            ? <div style={{ display: 'flex' }}>
                                                <br />
                                                <span className="material-icons md-25 "> hourglass_top </span>
                                                <div className={classes.pointer}>{t('common:global.print-sync')}</div>
                                            </div>
                                            : <div className={classes.pdfCtn}>
                                                <iframe title='pdf-preview' src={url + '#toolbar=1&FitV'} className={classes.previewCtn} height="100%" style={{ width: 'calc(820px - 154px)', maxWidth: 'calc(80vw - 154px)' }} />
                                                <br />
                                                <div style={{ display: 'flex' }}>
                                                    <span className="material-icons md-25 "> picture_as_pdf </span>
                                                    <div className={classes.pointer}>{t('common:global.print')}</div>
                                                </div>
                                            </div>
                                    }
                                </PDFDownloadLink>
                                <div className={classes.cancelPrintBtn} onClick={handleTogglePrintTable}>
                                    <span style={{ color: 'white', fontWeight: "bold", fontSize: 18 }}>{t('global.cancel')}</span>
                                </div>
                            </div>
                            :
                            <div className={classes.modalBody}>
                                {
                                    rowsToDelete.length > 0 &&
                                        <span className={classes.headerDeleteBtnCtn}>
                                            <PlugTooltip title={t('global.cancel')} placement="right-start">
                                                <span className={`material-icons`}
                                                    onClick={handleCancelDelete}
                                                    style={{ color: colors.plugformBlue, fontSize: 36 }}
                                                >cancel</span>
                                            </PlugTooltip>
                                            <PlugTooltip title={t('tablemulti.delete-rows')} placement="right-start">
                                                <span className={` material-icons`}
                                                    onClick={handleDeleteSelected}
                                                    style={{ color: colors.redDark, fontSize: 36 }}
                                                >
                                                    delete
                                                </span>
                                            </PlugTooltip>
                                        </span>
                                }
                                <div className={`${classes.tableCtn} ${classes.scrollBar} ${setup?.tableOrientation === 'horizontal' ? classes.horizontalTable : classes.table}`}
                                    // style={setup?.tableOrientation === 'horizontal' ? undefined : { paddingRight: 20, marginRight: -20 }}
                                >
                                    {
                                        setup?.tableOrientation === 'horizontal'
                                            ? (
                                                TablemultiHorizontalView
                                            )
                                            : (
                                                <Table
                                                    stickyHeader
                                                    style={{
                                                        '& .MuiTableRoot': {
                                                            // width: 'max-content !important',
                                                        },
                                                        overflowX: 'auto',
                                                    }}
                                                >
                                                    <TableHead className={classes.tableHeader} sx={{ display: "table-header-group" }} aria-label="simple table">
                                                        <TableRow style={{ position: 'relative' }}>
                                                            {
                                                                !props.disabled ?
                                                                    <PlugTooltip title={t('tablemulti.infos-edit')} placement="right-start">
                                                                        <TableCell align="center">
                                                                            <span className={`${classes.rowHeader} material-icons`}>edit</span>
                                                                        </TableCell>
                                                                    </PlugTooltip>
                                                                    :
                                                                    <TableCell align="center">
                                                                        <span className={classes.columnHeader}>N°</span>
                                                                    </TableCell>
                                                            }
                                                            {
                                                                props.colonnes.map((entry, id) => {
                                                                    if (entry.hidden || (props.mode === 'plugcode-complete' && entry.encodable === false) || (props.mode === 'plugcode-consult' && entry.visible === false)) { return null; }
                                                                    if(!isNull(setupbis)) {
                                                                        if (((props.mode === 'shared-update' || props.mode === 'shared-create' || props.mode === 'shared-view') && setupbis[id]?.visible === false)) { return null; }
                                                                    }
                                                                    let lastRow = rows[rows.length - 1]
                                                                    let hasTotal = false
                                                                    let totals = []
                                                                    let total = ""
                                                                    {/* console.log('rows', rows)
                                                                    console.log('total row', lastRow) */}
                                                                    if (rows.length > 0 && lastRow.hasOwnProperty('recap')) {
                                                                        totals = Object.keys(lastRow)
                                                                        if (totals?.includes(entry.id.toString())) {
                                                                            hasTotal = true
                                                                            if (lastRow[entry.id] !== null && lastRow[entry.id] !== undefined && lastRow[entry.id] !== "") {
                                                                                if (lastRow[entry.id].toString()?.includes(':')) total = lastRow[entry.id];
                                                                                else total = Math.round(lastRow[entry.id] * 1000) / 1000
                                                                            }
                                                                        }
                                                                    }
                                                                    let colWidth = isDefined(entry.colWidth) && entry.colWidth > 40 ? entry.colWidth : 'auto'
                                                                    if (!isNaN(colWidth)) {
                                                                        colWidth = 'calc((100% - ' + (!props.editMode && !props.disabled ? '40px' : '10px') + ') * ' + colWidth + ' / ' + tableWidth + ')'
                                                                    }
                                                                    const cellStyle = {
                                                                        whiteSpace: "break-spaces",
                                                                        width: colWidth,
                                                                    }
                                                                    const totalStyle = {
                                                                        fontSize: isDefined(entry.fontSize) && entry.fontSize > -1 ? entry.fontSize : _defaultFontsize,
                                                                        verticalAlign: 'top',
                                                                    }
                                                                    if (hasTotal) {
                                                                        switch (entry.rowAlignment) {
                                                                            case 'left':
                                                                                totalStyle.marginRight = 'auto'
                                                                                break
                                                                            case 'right':
                                                                                totalStyle.marginLeft = 'auto'
                                                                                break
                                                                            case 'center':
                                                                            default:
                                                                                totalStyle.marginLeft = 'auto'
                                                                                totalStyle.marginRight = 'auto'
                                                                                break
                                                                        }
                                                                        {/* devLog('total style', totalStyle) */}
                                                                    }
                                                                    return (
                                                                        <TableCell align="center" key={"header-" + id} style={cellStyle}>
                                                                            <span className={classes.columnHeader} style={cellStyle}>
                                                                                {getColumnLabel(entry, _defaultLabelSize, setup?.tableOrientation)}
                                                                            </span>
                                                                            {
                                                                                hasTotal &&
                                                                                <div className={classes.totalCtn}>
                                                                                    <NumericFormat value={total} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} displayType="text"
                                                                                        renderText={
                                                                                            (value, props) => <span className={classes.totalValues} style={totalStyle}>
                                                                                                {value}
                                                                                            </span>}
                                                                                    />
                                                                                </div>
                                                                            }
                                                                            <FieldVisibilityIndicator field={entry} mode={props.mode} />
                                                                        </TableCell>)
                                                                })}
                                                            {
                                                                !props.editMode && !props.disabled &&
                                                                <TableCell className={`${classes.thinColumn} ${classes.actionColumn} `}
                                                                    style={{
                                                                        // display: 'none',
                                                                        backgroundColor: 'white',
                                                                        borderBottomWidth: 0,
                                                                        borderRightWidth: 0,
                                                                        borderTopWidth: 0,
                                                                    }}
                                                                >
                                                                    {/* Actions */}
                                                                </TableCell>
                                                            }
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody className={classes.tableBodyCtn}>
                                                        {
                                                            displayedRows.length > 0 &&
                                                            displayedRows.map((row, rindex) => {
                                                                let rowId = rindex + 1;

                                                                if (row.recap !== true) {
                                                                    return (
                                                                        <Fragment key={rindex}>
                                                                            <TableRow id={"row-index-" + rindex} style={row.recap === true ? { backgroundColor: colors.hoverBgnd, position: 'relative' } : { position: 'relative' }}>
                                                                                <TableCell align="center" key={"row-index-" + rindex} style={{ width: 25, position: 'relative' }} >
                                                                                    <RenderFirstColumnComponent
                                                                                        row={row}
                                                                                        disabled={props.disabled}
                                                                                        rowId={rowId}
                                                                                        handleClickRowNb={handleClickRowNb}
                                                                                        rindex={rindex}
                                                                                    />
                                                                                </TableCell>
                                                                                {
                                                                                    Object.keys(row)
                                                                                        .sort((a, b) => {
                                                                                            if (a === '1000' || a === 1000) return 1; else if (b === '1000' || b === 1000) return -1; else return a - b
                                                                                        })
                                                                                        .map((key, cindex) => {
                                                                                            // devLog('mappage key', key, row[key])
                                                                                            const col = props.colonnes.find(entry => entry.id.toString() === key.toString())
                                                                                            if (!isDefined(col) || col.hidden || (isDefined(col.encodable) && props.mode === 'plugcode-complete' && col.encodable === false) || (isDefined(col.visible) && props.mode === 'plugcode-consult' && col.visible === false)) { return null; }
                                                                                            if(!isNull(setupbis)) {
                                                                                                if (((props.mode === 'shared-update' || props.mode === 'shared-create' || props.mode === 'shared-view') && setupbis[cindex]?.visible === false)) { return null; }
                                                                                            }
                                                                                            const isEditable = isEditableDefaultValue(props.field, col.id, rindex, props.mode, props.isFromPwCode) && isEditableColumnFormat(col)

                                                                                            if (isEditableDirectly(col)) {
                                                                                                return (
                                                                                                <TableCell
                                                                                                    align={col.rowAlignment || 'center'}
                                                                                                    key={"cell-" + key + '-' + cindex} style={{ position: 'relative', }}
                                                                                                    >
                                                                                                        <RenderEditCellComponent
                                                                                                            createDataForAnnexeWithContent={props.createDataForAnnexeWithContent}
                                                                                                            annexes={props.annexes}
                                                                                                            attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                                                                                                            attemptSendMailSimple={props.attemptSendMailSimple}
                                                                                                            col={col}
                                                                                                            columns={props.colonnes}
                                                                                                            control={props.control}
                                                                                                            controlCreate={props.controlCreate}
                                                                                                            controlUpdate={props.controlUpdate}
                                                                                                            disabled={props.disabled}
                                                                                                            edit="cell"
                                                                                                            field={props.field}
                                                                                                            fields={props.fields}
                                                                                                            handleChangeChildrenRowValue={props.handleChangeChildrenRowValue}
                                                                                                            handleChangeChildrenValue={(value) => props.handleChangeChildrenValue(value, col, rindex)}
                                                                                                            handleSaveComment={props.handleSaveComment}
                                                                                                            handleValidate={handleEndEdit}
                                                                                                            id={key}
                                                                                                            isPreview
                                                                                                            key={'edit-cell-' + rindex + '-' + key}
                                                                                                            mode={props.mode}
                                                                                                            model={props.model}
                                                                                                            open={true}
                                                                                                            owner={props.owner}
                                                                                                            ownerId={props.ownerId}
                                                                                                            plugnote={props.plugnote}
                                                                                                            renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                                                                                            rindex={rindex}
                                                                                                            cindex={cindex}
                                                                                                            row={row}
                                                                                                            share={props.share}
                                                                                                            title={props.title}
                                                                                                            user={props.user}
                                                                                                            value={row[key]}
                                                                                                            dataHash={props?.dataHash}
                                                                                                            handleSelectDataMultiCombo={props.handleSelectDataMultiCombo}
                                                                                                        />
                                                                                                        {
                                                                                                            !isEditable &&
                                                                                                            <PlugTooltip title={t('tablemulti.not-editable')} placement="right">
                                                                                                                <div className={classes.disabledCellIcon} >
                                                                                                                    <LockedIcon locked fontSize={12} />
                                                                                                                </div>
                                                                                                            </PlugTooltip>
                                                                                                        }
                                                                                                    </TableCell>
                                                                                                )
                                                                                            } else if (openRow !== null && openRow.row === row && openRow.id === key && openRow.rindex === rindex) {
                                                                                                return (
                                                                                                    <TableCell
                                                                                                        align={col.rowAlignment || 'center'}
                                                                                                        key={"cell-" + key + '-' + cindex}
                                                                                                        style={{ backgroundColor: colors.plugnotesLightAlpha }}
                                                                                                    >
                                                                                                        <RenderEditCellComponent
                                                                                                            createDataForAnnexeWithContent={props.createDataForAnnexeWithContent}
                                                                                                            annexes={props.annexes}
                                                                                                            attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                                                                                                            attemptSendMailSimple={props.attemptSendMailSimple}
                                                                                                            col={col}
                                                                                                            columns={props.colonnes}
                                                                                                            control={props.control}
                                                                                                            controlCreate={props.controlCreate}
                                                                                                            controlUpdate={props.controlUpdate}
                                                                                                            disabled={props.disabled || (!isNull(setupbis) && ((props.mode === 'shared-update' || props.mode === 'shared-create' || props.mode === 'shared-view') && setupbis[cindex].editable === false))}
                                                                                                            edit="cell"
                                                                                                            field={props.field}
                                                                                                            fields={props.fields}
                                                                                                            handleChangeChildrenRowValue={props.handleChangeChildrenRowValue}
                                                                                                            handleChangeChildrenValue={(value) => props.handleChangeChildrenValue(value, col, rindex)}
                                                                                                            handleSaveComment={props.handleSaveComment}
                                                                                                            handleValidate={handleEndEdit}
                                                                                                            id={openRow.id}
                                                                                                            isFromPwCode={props.isFromPwCode}
                                                                                                            key={'edit-cell-' + openRow.id}
                                                                                                            mode={props.mode}
                                                                                                            model={props.model}
                                                                                                            open={true}
                                                                                                            owner={props.owner}
                                                                                                            ownerId={props.ownerId}
                                                                                                            plugnote={props.plugnote}
                                                                                                            renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                                                                                            rindex={rindex}
                                                                                                            cindex={cindex}
                                                                                                            row={row}
                                                                                                            share={props.share}
                                                                                                            title={props.title}
                                                                                                            user={props.user}
                                                                                                            value={row[openRow.id]}
                                                                                                            dataHash={props?.dataHash}
                                                                                                            shared={props?.shared}
                                                                                                            handleSelectDataMultiCombo={props.handleSelectDataMultiCombo}
                                                                                                        />
                                                                                                    </TableCell>
                                                                                                )
                                                                                            } else if (row[key] === '' && keyList?.includes(key)) {
                                                                                                return (
                                                                                                    <TableCell align="center" key={"cell-" + key + '-' + cindex} style={{ position: 'relative' }}
                                                                                                        onClick={(e) => {if (isEditable) handleCellClick(e, row, key, rindex)}}
                                                                                                    >
                                                                                                        {col.format === "time" && isEditable &&
                                                                                                            <RenderCellValue
                                                                                                                columns={props.colonnes}
                                                                                                                defaultFontsize={_defaultFontsize}
                                                                                                                disabled={props.disabled}
                                                                                                                id={key}
                                                                                                                isEditable={isEditable}
                                                                                                                value={row[key]}
                                                                                                                row={row}
                                                                                                                fields={props.fields}
                                                                                                                shared={props?.shared}
                                                                                                                rindex={rindex}
                                                                                                                handleChangeChildrenValue={value => props.handleChangeChildrenValue(value, col, rindex)}
                                                                                                            />
                                                                                                        }
                                                                                                        {col.format === "time" && !isEditable &&
                                                                                                            <div className={classes.voidCell}>
                                                                                                                <span className={classes.voidCell} />
                                                                                                            </div>
                                                                                                        }
                                                                                                        {col.format !== "time" &&
                                                                                                            <div className={classes.voidCell}>
                                                                                                                <span className={classes.voidCell} />
                                                                                                            </div>
                                                                                                        }
                                                                                                        {
                                                                                                            !isEditable &&
                                                                                                            <PlugTooltip title={t('tablemulti.not-editable')} placement="right">
                                                                                                                <div className={classes.disabledCellIcon} >
                                                                                                                    <LockedIcon locked fontSize={12} />
                                                                                                                </div>
                                                                                                            </PlugTooltip>
                                                                                                        }
                                                                                                    </TableCell>
                                                                                                )
                                                                                            } else return (keyList?.includes(key) &&
                                                                                                <TableCell
                                                                                                    align={col.rowAlignment || 'center'}
                                                                                                    key={"cell-" + key + '-' + cindex} style={{ position: 'relative', }}
                                                                                                    onClick={(e) => { if (isEditable) { handleCellClick(e, row, key, rindex) } else setOpenRow(null) }}
                                                                                                >
                                                                                                    <RenderCellValue
                                                                                                        annexes={props.annexes}
                                                                                                        columns={props.colonnes}
                                                                                                        defaultFontsize={_defaultFontsize}
                                                                                                        disabled={props.disabled}
                                                                                                        handleChangeChildrenValue={value => props.handleChangeChildrenValue(value, col, rindex)}
                                                                                                        id={key}
                                                                                                        isEditable={isEditable}
                                                                                                        options={props.field.options}
                                                                                                        rindex={rindex}
                                                                                                        row={row}
                                                                                                        shared={props?.shared}
                                                                                                        value={row[key]}
                                                                                                    />
                                                                                                    {
                                                                                                        !isEditable &&
                                                                                                        <PlugTooltip title={t('tablemulti.not-editable')} placement="right">
                                                                                                            <div className={classes.disabledCellIcon} >
                                                                                                                <LockedIcon locked fontSize={12} />
                                                                                                            </div>
                                                                                                        </PlugTooltip>
                                                                                                    }
                                                                                                </TableCell>
                                                                                            )
                                                                                        })}
                                                                                {
                                                                                    row.recap === true && keyList.map((key, cindex) => {
                                                                                        const col = props.colonnes.find(entry => entry.id.toString() === key.toString())
                                                                                        if (openRow !== null && openRow.row === row && openRow.id === key && openRow.rindex === rindex) {
                                                                                            return (
                                                                                                <TableCell align="center" key={"cell-" + key + '-' + cindex} style={{ border: '1px solid #DEDEDE', borderTop: 'none' }}>
                                                                                                    <RenderEditCellComponent
                                                                                                        createDataForAnnexeWithContent={props.createDataForAnnexeWithContent}
                                                                                                        annexes={props.annexes}
                                                                                                        attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                                                                                                        attemptSendMailSimple={props.attemptSendMailSimple}
                                                                                                        col={col}
                                                                                                        columns={props.colonnes}
                                                                                                        control={props.control}
                                                                                                        controlCreate={props.controlCreate}
                                                                                                        controlUpdate={props.controlUpdate}
                                                                                                        disabled={props.disabled || (!isNull(setupbis) && ((props.mode === 'shared-update' || props.mode === 'shared-create' || props.mode === 'shared-view') && setupbis[cindex].editable === false))}
                                                                                                        edit="cell"
                                                                                                        field={props.field}
                                                                                                        fields={props.fields}
                                                                                                        handleChangeChildrenRowValue={props.handleChangeChildrenRowValue}
                                                                                                        handleChangeChildrenValue={(value) => props.handleChangeChildrenValue(value, col, rindex)}
                                                                                                        handleSaveComment={props.handleSaveComment}
                                                                                                        handleValidate={handleEndEdit}
                                                                                                        id={openRow.id}
                                                                                                        isFromPwCode={props.isFromPwCode}
                                                                                                        key={'edit-cell-' + openRow.id}
                                                                                                        mode={props.mode}
                                                                                                        model={props.model}
                                                                                                        open={true}
                                                                                                        owner={props.owner}
                                                                                                        ownerId={props.ownerId}
                                                                                                        plugnote={props.plugnote}
                                                                                                        renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                                                                                        rindex={rindex}
                                                                                                        cindex={cindex}
                                                                                                        row={row}
                                                                                                        share={props.share}
                                                                                                        title={props.title}
                                                                                                        user={props.user}
                                                                                                        value={row[openRow.id]}
                                                                                                        dataHash={props?.dataHash}
                                                                                                        shared={props?.shared}
                                                                                                        handleSelectDataMultiCombo={props.handleSelectDataMultiCombo}
                                                                                                    />
                                                                                                </TableCell>
                                                                                            )
                                                                                        } else if (row.hasOwnProperty(key)) return (keyList?.includes(key) &&
                                                                                            <TableCell align="center" key={"cell-" + key + '-' + cindex} style={{ border: '1px solid #DEDEDE', borderTop: 'none' }}>
                                                                                                <div >
                                                                                                    <RenderCellValue
                                                                                                        annexes={props.annexes}
                                                                                                        columns={props.colonnes}
                                                                                                        defaultFontsize={_defaultFontsize}
                                                                                                        disabled={props.disabled}
                                                                                                        id={key}
                                                                                                        value={row[key]}
                                                                                                        row={row}
                                                                                                        fields={props.fields}
                                                                                                    />
                                                                                                </div>
                                                                                            </TableCell>)
                                                                                        else return (
                                                                                            <TableCell align="center" key={"cell-" + key + '-' + cindex} style={{ border: 'none' }}>
                                                                                                <div className={classes.voidCell} >
                                                                                                    <span className={classes.voidCell} />
                                                                                                </div>
                                                                                            </TableCell>
                                                                                        )
                                                                                    })}
                                                                                {
                                                                                    !props.editMode && !props.disabled &&
                                                                                    <TableCell align="center" width={30} className={`${classes.actionColumn}`}
                                                                                        style={{
                                                                                            // display: 'none',
                                                                                            backgroundColor: 'white',
                                                                                            borderBottomWidth: 0,
                                                                                            borderRightWidth: 0,
                                                                                            borderTopWidth: 0,
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            !row.locked && rowsToDelete.length > 0 ?
                                                                                                <span className={`${classes.deleteBtnCtn} material-icons`}
                                                                                                    onClick={() => handleToggleToDelete(rowId)}
                                                                                                    style={{ color: rowsToDelete?.includes(rowId) ? 'red' : colors.steel }}
                                                                                                >
                                                                                                    delete
                                                                                                </span>
                                                                                                :
                                                                                                <RenderMenuPopup
                                                                                                    handleToggleToDelete={handleToggleToDelete}
                                                                                                    handleClickDelete={handleClickDelete}
                                                                                                    handleClickDuplicate={handleClickDuplicate}
                                                                                                    handleClose={() => setOpenPop(null)}
                                                                                                    handleDeleteSingleRow={handleDeleteSingleRow}
                                                                                                    handleOpen={() => setOpenPop(rowId)}
                                                                                                    isDefaultRowsQtyReached={isDefaultRowsQtyReached}
                                                                                                    isLimitReached={isLimitReached}
                                                                                                    open={openPop === rowId}
                                                                                                    openLastRow={openLastRow}
                                                                                                    rindex={rindex}
                                                                                                    row={row}
                                                                                                    rowId={rowId}
                                                                                                    rows={rows}
                                                                                                    rowsToDelete={rowsToDelete}
                                                                                                    rowToDelete={rowToDelete}
                                                                                                    setRowToDelete={setRowToDelete}
                                                                                                />
                                                                                        }
                                                                                    </TableCell>
                                                                                }

                                                                            </TableRow>
                                                                            <TableRow key="edit-row" className={classes.editRow}
                                                                                style={{ position: 'relative' }}
                                                                            >
                                                                                {
                                                                                    !isMobile &&
                                                                                    <TableCell colSpan={1} >
                                                                                        <Collapse
                                                                                            className={classes.centeredCollapse}
                                                                                            in={openRow !== null && openRow.row === row && openRow.id === undefined && openRow.rindex === rindex}
                                                                                            timeout="auto" unmountOnExit>
                                                                                            <Box
                                                                                                sx={{
                                                                                                    display: 'flex',
                                                                                                    justifyContent: 'center',
                                                                                                    backgroundColor: colors.hoverBgnd,
                                                                                                }}
                                                                                            >
                                                                                                <PlugTooltip title={t('global.validate')} placement="right-start">
                                                                                                    <span className={`material-icons ${classes.validateIcon}`} onClick={handleEndEdit}>check</span>
                                                                                                </PlugTooltip>
                                                                                            </Box>
                                                                                        </Collapse>
                                                                                    </TableCell>
                                                                                }
                                                                                <TableCell
                                                                                    className={isMobile ? `${classes.stickyColumn} ${classes.noBorder}` : undefined}
                                                                                    colSpan={keyList.length}
                                                                                    ref={openRow !== null && openRow === row ? editedRow : null}
                                                                                    style={isMobile ? { width: '90vw' } : undefined}
                                                                                >
                                                                                    <Collapse in={openRow !== null && openRow.row === row && openRow.id === undefined && openRow.rindex === rindex} timeout="auto">
                                                                                        <Box margin={0} style={{ backgroundColor: colors.hoverBgnd }}>
                                                                                            {
                                                                                                openRow !== null && openRow.row === row && openRow.id === undefined && openRow.rindex === rindex &&
                                                                                                <RenderEditRowComponent
                                                                                                    createDataForAnnexeWithContent={props.createDataForAnnexeWithContent}
                                                                                                    annexes={props.annexes}
                                                                                                    attemptSendMailSimple={props.attemptSendMailSimple}
                                                                                                    attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                                                                                                    columns={props.colonnes}
                                                                                                    control={props.control}
                                                                                                    controlCreate={props.controlCreate}
                                                                                                    controlUpdate={props.controlUpdate}
                                                                                                    disabled={props.disabled}
                                                                                                    edit="row"
                                                                                                    field={props.field}
                                                                                                    fields={props.fields}
                                                                                                    handleChangeChildrenRowValue={props.handleChangeChildrenRowValue}
                                                                                                    handleChangeChildrenValue={(value, column) => props.handleChangeChildrenValue(value, column, rindex)}
                                                                                                    handleSaveComment={props.handleSaveComment}
                                                                                                    handleValidate={handleEndEdit}
                                                                                                    isFromPwCode={props.isFromPwCode}
                                                                                                    mode={props.mode}
                                                                                                    model={props.model}
                                                                                                    owner={props.owner}
                                                                                                    ownerId={props.ownerId}
                                                                                                    plugnote={props.plugnote}
                                                                                                    renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                                                                                    rindex={rindex}
                                                                                                    row={row}
                                                                                                    share={props.share}
                                                                                                    title={props.field.title}
                                                                                                    user={props.user}
                                                                                                    dataHash={props?.dataHash}
                                                                                                    handleSelectDataMultiCombo={props.handleSelectDataMultiCombo}
                                                                                                />
                                                                                            }
                                                                                        </Box>
                                                                                    </Collapse>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </Fragment>
                                                                    )
                                                                } else { return null }
                                                            })}
                                                    </TableBody>
                                                </Table>
                                            )
                                    }
                                </div>
                                {
                                    !props.disabled && props.field?.options?.setup?.rowLimit !== 1 &&
                                    <PlugTooltip title={!isLimitReached() ? t('tablemulti.add-row') : t('tablemulti.row-limit-warn')} placement="bottom">
                                            <span id="icon-add-playlist" style={{fontSize:'40px'}}
                                                    className={`${isLimitReached() ? classes.disabledIcon : classes.icon} ${classes.addNewLineBtn} material-icons`}
                                                    onClick={(e) => { if (!isLimitReached()) handleAddRow(e) }}
                                            >
                                                add_circle_outline
                                            </span>
                                    </PlugTooltip>
                                }
                            </div>
                    }
                </div>
            </Modal>
        )
    }
}

const RenderFirstColumnComponent = ({ row, disabled, rowId, handleClickRowNb, rindex }) => {
    const { t } = useTranslation('common')
    const classes = useStyles()

    if (row.recap === true) {
        return <span className={classes.rowTotal}>Total</span>
    }
    return (
        disabled ?
            <span className={classes.rowNb}>{rowId}</span>
            :
            <PlugTooltip title={t('multiple.edit-row')} placement="right-start">
                <span
                    className={classes.rowNumber}
                    onClick={(e) => handleClickRowNb(e, row, rindex)}
                >{rowId}</span>
            </PlugTooltip>
    )
}
