import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import { TextField } from "../../../node_modules/@material-ui/core/index";
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Modal from "@material-ui/core/Modal";
import {useTranslation} from "react-i18next";
import { camelCase } from "lodash";
import ApplicationConfig from "../../config/ApplicationConfig";
import {getModel, getPlugnote} from "../../utils/Common";
import ModalPluglinkComponent from "../modals/ModalPluglinkComponent";
import ModalListDatasPluglinkComponent from "../modals/ModalListDatasPluglinkComponent";
import colors from "../../assets/themes/Colors";
import qs from "querystringify";
import axios from "axios";
import Images from "../../assets/themes/Images";
// import { getPlugformWithCodeLogoff, getPlugnoteWithCodeLogoff } from "../../api/plugcodeApi";
import * as apiPlugcodes from '../../api/plugcodeApi'
import {isDefined, isJsonString} from "../../utils/utils";
import {usePromiseTracker} from "react-promise-tracker";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    fieldRight:{
        width : '100%',
        display: 'flex',
        minHeight: 42
    },
    addFormBtn: {
        fontSize: '17px',
        minHeight: '40px',
        paddingTop: '3px',
        paddingBottom: '3px',
        color: 'white!important',
        // fontWeight: 'bold!important',
        backgroundColor: colors.unselectedAlpha,
        borderRadius: '6px',
        justifyContent: 'center',
        textAlignLast: 'center',
        cursor: 'pointer',
        whiteSpace: 'break-spaces'
    },
    disabledBtn: {
        // textTransform: 'capitalize',
        fontSize: '17px',
        minHeight: '40px',
        maxWidth: 300,
        padding: '3px',
        color: 'white!important',
        backgroundColor: colors.plugnotesGris,
        borderRadius: '6px',
        textAlign: 'center',
        cursor: 'not-allowed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        "@media (max-width: 640px)": {
            maxWidth: '100%'
        },
    },
    testStyle: {
        borderColor: 'red',
        borderWidth: '3px',
        borderStyle: 'solid',
    },
    mainButtonText: {
        width: '80%',
    },
    mainButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    rightButtons: {
        cursor: 'pointer',
        maxHeight: '31px',
        padding: '3px 6px',
        borderRadius: "3px",
        // backgroundColor: colors.main,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        color: colors.unselected,
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    popup: {
        position: 'absolute',
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '90%',
        maxWidth: 450,
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    popupContent: {
        minHeight: 200,
        display: 'flex',
        flexDirection: 'column',
        margin: '5px',
        // textAlignLast: 'center',
    },
    popupHeader: {
        flex: '1 1',
        maxHeight: 80,
        display: 'flex',
        justifyContent: 'space-between'
    },
    popupInfos: {
        fontSize: '20px',
        paddingLeft: 30
    },
    fieldImage: {
        height: 20,
        width: 20,
    },
    fieldIcon: {
        width: 30,
        height: 30,
        // backgroundColor: colors.main,
        padding: 5,
        marginLeft: 15,
        borderRadius: 6,
        // display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: '8px'
    },
}));
function getModalStyle() {
    const top = 5;

    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}
const modalStyles = {overlay: {zIndex: 10000}};
const modalStylesClosed = {overlay: {zIndex: 0}};

const FormatPlugLinkComponent = (props) => { //F28
    const classes = useStyles();
    const model = getModel();

    const { t, i18n } = useTranslation(['common', 'models']);
    const [disabled, setDisabled] = React.useState(typeof props.disabled === 'undefined' ? false : props.disabled);
    const [consult, setConsult] = React.useState(typeof props.disabled === 'undefined' ? false : props.disabled);
    const [shared, setShared] = React.useState(typeof model !== 'undefined' && model !== null && (typeof model.ms_read_fiche !== "undefined" && model.ms_read_fiche !== null  )? true : false);
    const [sharedEdit, setSharedEdit] = React.useState(typeof model !== 'undefined'  && model !== null && typeof model.ms_read_fiche !== "undefined" && model.ms_edit_fiche === 1 ?  true : false);

    const [code, setCode] = useState(props.field.options.pfcode || "");
    const [userindex, setUserIndex] = useState(props.field.value.userindex || "");
    const [title, setTitle] = useState(props.field.title || "")
    const [field, setField] = React.useState(props.field.options.field);

    const [dataHash, setDataHash] = useState(props.dataHash);
    const [clef, setClef] = useState(props.field.value.clef || "");
    const [open, setOpen] = useState(false);
    const [openPromised, setOpenPromised] = useState(false);
    const [openList, setOpenList] = useState(false);
    const [pfData, setPfData] = useState(null)
    const [pfCode, setPfCode] = useState(props.field.options.pfcode || "")
    const [pfKey, setPfKey] = useState(props.field.options.code || "")
    const [pfOwner, setPfOwner] = useState("")
    const [pfTitle, setPfTitle] = useState("")
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [pfShowInfo, setPfShowInfo] = useState(false);
    const [getControlPlugnote, setControlPlugnote] = useState(null);

    const { promiseInProgress } = usePromiseTracker();

    const parseJson = (code) => {
        try {
            return JSON.parse(code);
        } catch (e) {
            return code;
        }
    };

    useEffect(() => {
        if(openPromised){
            if(!promiseInProgress && getPlugnote() !== null){
                switch (props.mode) {
                    case 'create':
                    case 'update':
                    case 'shared-create':
                    case 'shared-update':
                        try {
                            let tmp = getPlugnote();
                            setUserIndex(tmp.userindex);
                            setClef(tmp.modclef);
                            setOpenPromised(false);
                        }catch (e) {
                            //console.log('error',e);
                        }
                        break;
                    default:
                        break;
                }
                setOpen(true);
                setOpenPromised(false);
            }
            else{
                setOpen(false);
                setOpenPromised(true);
            }
        }


    }, [promiseInProgress, openPromised])

    useEffect(() => {
        setPfCode(props.field.options.pfcode)
        setPfKey(props.field.options.code)
    }, [props.field.options])

    useEffect(() => {
        if(typeof pfData !== "undefined"){
            if(pfData !== null) {
                setPfOwner(pfData?.proprio || "")
                if (isDefined(pfData.data?.data)) setPfTitle(pfData?.data?.data?.label || "")};
        }
    }, [pfData])

    useEffect(() => {
        if(typeof pfCode !== "undefined"){
            if(pfCode.startsWith("PF-")) {
                apiPlugcodes.getPlugformWithCodeLogoff(pfCode)
                    .then((e)=> {
                        // console.log("api",e);
                        setPfData(e)})
                // console.log("found pf", pf);
            }
            else if(pfCode.startsWith("PN-")) {
                apiPlugcodes.getPlugnoteWithCodeLogoff(pfCode)
                    .then((e)=> {
                        // console.log("api",e);
                        setPfData(e)})
                // console.log("found pf", pf);
            }
        }
    }, [pfCode])

    const handleClose = () => {
        setOpenPromised(false);
        setOpen(false);
    };
    const handleCloseList = () => {
        setOpenList(false);
    };

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    const onClickUrl = (url) => {
        return () => openInNewTab(url)
    }

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    const controlBeforeOpen = () => {
        setOpen(false);

        if(typeof props.control !== "undefined"){
            switch (props.mode) {
                case 'create':
                case 'update':
                case 'shared-create':
                case 'shared-update':
                    setOpenPromised(true);
                    props.control();
                    break;
                default:
                    break;

            }
        }else{
            setOpen(true);
        }
    }

    return (
        <div className={classes.fieldRight}>
            {
                pfData?.data?.result === "ok" ?
                    <div style={{ width: '90%', marginRight: '5px' }}>
                        {code ?
                            // ((shared && sharedEdit) || !shared) &&
                            !disabled &&
                            <div
                                className={classes.addFormBtn} onClick={() => controlBeforeOpen()}
                                id="standard-full-width"
                                //label={field.title}
                                // style={{ margin: 0, color: 'white' }}
                                type="text"
                                margin="normal"
                                multiline="true"
                            >
                                <div className={classes.mainButton}>
                                    <div className={classes.mainButtonText}>
                                        {t('pluglink.fill-form')}{pfTitle !== "" ? pfTitle : null}
                                    </div>
                                </div>
                            </div>
                            :
                            <TextField
                                id="standard-full-width"
                                style={{ margin: 0 }}
                                type="text"
                                helperText=""
                                fullWidth
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                className={classes.fieldRight}
                                defaultValue={code + ' - ' + clef.toString() + ' - ' + userindex}
                                disabled={true}
                            />}

                        {typeof code !== 'undefined' && code !== '' && userindex !== 'undefined' && userindex !== '' && consult === true &&
                            <div className={classes.addFormBtn} onClick={onClickUrl(ApplicationConfig.plugcodesUrl + code.toString() + '/' + clef.toString() + '/' + userindex.toString())}>
                                {t('pluglink.fill-form')}
                            </div>
                            /*            <div style={{width: '10%' , marginRight: '5px'}}>
                                            {
                                                getToken() !== null ?
                                                    <a href={ApplicationConfig.plugcodesUrl + '/' + code.toString() + '/' + clef.toString() +'/' + userindex.toString()} ><span className="material-icons md-medium green">link</span></a>
                                                    :
                                                    <a href={ApplicationConfig.plugcodesUrl + '/' + code.toString() + '/' + clef.toString() +'/' + userindex.toString()} target="_blank"><span className="material-icons md-medium green">link</span></a>
                                            }
    
    
                                            <a href={ApplicationConfig.plugcodesUrl + '/' + code.toString() + '/' + clef.toString() +'/' + userindex.toString()} target="_blank"><span className="material-icons md-medium green">link</span></a>
    
    
                                        </div>*/
                        }
                    </div>
                    :
                    <div style={{ width: '90%', marginRight: '5px' }}>
                        <div className={classes.disabledBtn}>
                            <span>{t('plugform.code-unavailable')}</span>
                        </div>
                    </div>
            }
            {
                typeof code !== 'undefined' &&  code !== '' && userindex !== 'undefined' &&  userindex !== '' && consult === false &&
            !disabled && pfData?.data?.result === "ok" &&
            <div className={classes.rightButtons}>
                <span className="material-icons md-25" onClick={(event) => {if(pfData != null) setPfShowInfo(true)}}>
                    info
                </span>
                <span className="material-icons md-25"
                      onClick={(event) => setOpenList(true)}
                    // onClick={()=> parsePgLinkOptions(model) }
                >
                    visibility
                </span>

                {/*                {
                        shared && sharedEdit ?
                            <span className="material-icons md-small" onClick={(event) => setOpen(true)}>
                                add
                            </span>
                            :
                            null
                    }

                    {
                        !shared &&
                            <span className="material-icons md-small" onClick={(event) => setOpen(true)}>
                                add
                            </span>
                    }*/}
            </div>
            }

            { typeof code !== 'undefined' &&  code !== '' && userindex !== 'undefined' &&  userindex !== '' && disabled &&
            <div style={{width: '10%' , marginRight: '5px'}}>
                {/*                {
                    getToken() !== null ?
                        <a href={ApplicationConfig.plugcodesUrl + '/' + code.toString() + '/' + clef.toString() +'/' + userindex.toString()} ><span className="material-icons md-medium green">link</span></a>
                        :
                        <a href={ApplicationConfig.plugcodesUrl + '/' + code.toString() + '/' + clef.toString() +'/' + userindex.toString()} target="_blank"><span className="material-icons md-medium green">link</span></a>
                }*/}
                {
                    !code &&
                    <a href={ApplicationConfig.plugcodesUrl + code.toString() + '/' + clef.toString() +'/' + userindex.toString()} target="_blank">
                        <span className="material-icons md-medium green">link</span>
                    </a>
                }
            </div>
            }

            {
                open &&
                <ModalPluglinkComponent open={open} close={handleClose} code={code.toString()} folder={clef.toString()} fiche={userindex.toString()} owner={props?.ownerHash} dataHash={dataHash}/>
            }

            { openList &&
            <ModalListDatasPluglinkComponent open={openList} close={handleCloseList} code={code.toString()} folder={clef.toString()} fiche={userindex.toString()} dataHash={dataHash}/>
            }

            {pfShowInfo &&  //modale d'infos du plugform
            <Modal
                keepMounted={false}
                cancellable={"true"}
                open={pfShowInfo}
                // onClose={setPfShowInfo(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
                onClose={()=>setPfShowInfo(false)}
            >
                <div style={modalStyle} className={classes.popup}>
                    <div className = "scrollbar-hidden popup_wrapper flexSpacebetween" >
                        <div className={classes.popupContent}>
                            <div className={classes.popupHeader}>
                                <div className="text_align">
                                <span className="titres">
                                </span>
                                    {/* <span className="font_size_24">
                                    {t("models.plugform-details")}
                                </span> */}
                                    <div className={classes.popupInfos}>
                                        {pfTitle + " (" + pfKey + ")" }<br/>
                                        {pfOwner }<br/>
                                        {pfCode }<br/>
                                    </div>
                                </div>
                                <span id="icon-close" onClick={()=> setPfShowInfo(false)} className="material-icons md-50 lightgrey">
                                close
                                </span>
                            </div>
                            <br/>
                            {Object.keys(pfData.data).map((key) => {
                                if(key.includes("label") && key !== "label"){
                                    let fieldIndex = key.charAt(7)=="l"? parseInt(key.slice(5,6)): parseInt(key.slice(5,7)) ;
                                    let iconKey = "field" + fieldIndex + "format"
                                    return(
                                        <div key={'field-'+key} style={{marginBottom: 6}}>
                                            {fieldIndex} -
                                            <span className={classes.fieldIcon}>
                                                <img className={classes.fieldImage}
                                                    src={ Images[camelCase(pfData.data[iconKey].toLowerCase()) + "IconSvg"] }
                                                    alt={t("fields.format-choice-" + pfData.data[iconKey].toLowerCase())}/>
                                            </span>
                                            {pfData.data[key]}
                                        </div>
                                    )}
                            })}
                        </div>
                    </div>
                </div>
            </Modal>
            }
        </div>
    );
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatPlugLinkComponent);
