import React  from "react"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import colors from "../../../assets/themes/Colors";
import { NumericFormat } from 'react-number-format'
import { Tooltip } from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    thinPopup: {
        position: "absolute",
        maxHeight: "calc(100% - 250px)",
        zIndex: 99,
        top: '120px',
        right: -15,
        backgroundColor: "white",
        maxWidth: "calc(100% - 540px)",
        width: "25vw",
        overflow: 'auto',
        boxSizing: "border-box",
        boxShadow: '0px 0px 20px 20px rgb(0 0 0 / 14%), 0px 11px 15px rgb(0 0 0 / 20%)',
        borderRadius: "5px",
        "@media (max-width: 1000px)": {
            right: -15,
            zIndex: 9999,
            width: '40vw',
            maxWidth: '90vw',
            // top: 340,
            maxHeight: '35vh'
        },
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxWidth: "90%",
        margin: "auto",
    },
    marginLeft: {
        marginLeft: "10px",
    },
    totalsCtn: {
        padding: '10px',
        flexDirection: 'column',
        width: '100%',
        display: 'flex',
        borderRadius: "6px",
        flexWrap: 'wrap',
        gap: 5,
        alignItems: 'stretch',
    },
    newSelectionContainer: {
        margin: '15px 10px',
        '@media (min-width: 640px)': {
            width: '50%'
        }
    },
    selectionContainer: {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid',
        borderRadius: 4,
        borderColor: colors.grey,
        padding: 5,
        marginBottom: 5,
        cursor: 'pointer',
        position: 'relative'
    },
    selectionDetails: {
        color: colors.grey,
        fontSize: 13
    },
    delContainer: {
        fontSize: 9,
        // width: 50,
        // color: colors.grey
    },
    searchField: {
        display: 'flex',
        gap: 5,
        alignItems: 'center',
        padding: '0 10px 0 0',
        color: 'black',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: 1000,
        }),
        justifyContent: 'space-between'
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'column'
    },
    headerField: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        position: 'relative',
        paddingLeft: 10,
        paddingTop: 10
    },
    topHeaderField: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        position: 'relative',
        paddingLeft: 10,
        paddingTop: 10
    },
    checkContainer: {
        cursor: 'pointer',
        bottom: 0,
        fontSize: 12,
        color: colors.darkSilver
    },
    checkbox: {
        color: colors.mainButton
    },
    textField: {
        fontWeight: '500',
        padding: '0 10px',
        backgroundColor: colors.greyLighter,
        fontSize: '14px',
        flexGrow: 1,
        border: '1px solid',
        borderRadius: 4,
        borderColor: colors.grey,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: 1000,
        }),
    },
    searchButton: {
        cursor: 'pointer',
    },
    btnContainer: {
        display: 'flex',
        position: 'relative',
        justifyContent: 'space-between',
        zIndex: 50
    },
    actionBtn: {
        // minWidth: "160px",
        height: 41,
        padding: "4px 20px",
        margin: '3px 5px',
        borderRadius: "3px",
        cursor: "pointer",
        color: colors.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.mainButton,
        textAlign: 'center',
        '@media (max-width: 1300px)': {
            margin: 0,
            height: 'auto'
        }
    },
    totalCtn: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid lightgrey',
        paddingBottom: 3,
    },
    totalLabel: {
        marginRight: 'auto',
        textAlign: 'left',
        fontSize: 16,
        fontWeight: 'bold',
    },
    totalValue: {
        color: colors.bloodOrange,
        padding: '0px 5px',
        wordBreak: 'break-word',
        marginLeft: 'auto',
        textAlign: 'end',
        fontSize: 16,
        fontWeight: 'bold',
        border: 'none',
        width: '50%'
    },
    totalCb: {
        margin: 0,
        width: '20px',
        height: '20px',
        marginRight : '5px',
        color: colors.bloodOrange,
        alignSelf: 'flex-start',
    },
    totalId: {
        marginLeft: 3,
        fontSize: 12,
        fontWeight: 'bold',
        alignSelf: 'flex-start',
    }
}));
const ModalTotalListComponent = (props) => {
    const { t } = useTranslation(['common', 'models', 'global', 'plugnote']);
    const classes = useStyles();

    //Props
    const totals = props.totals
    const numericColumns = props.numericColumns

    const handleClose = () => {
        props.onClose();
    }

    return (
        <div className={classes.thinPopup}>
            <div className="scrollbar-hidden popup_wrapper flexSpacebetween">
                <div
                // className="popup_wrapper_content"
                >
                    <div className="sous-header_modal_content">
                        <div className={classes.headerContainer}>
                            <div className={classes.topHeaderField}>
                                <div className={classes.searchField} onClick={(e) => {e.stopPropagation(); e.preventDefault() }}>
                                    <span className={classes.totalLabel}>{t('pluglist.totals')}</span>
                                    <Tooltip placement='bottom' title={t('pluglist.info-total')}>
                                        <span className="material-icons" style={{fontSize: 15, color: colors.bloodOrange}}>
                                            info
                                        </span>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>

                        <div onClick={() => handleClose()}>
                            <span
                                id="icon-close"
                                className="material-icons md-50 lightgrey"
                            >
                                close
                            </span>
                        </div>
                    </div>
                    <div className="sous-header_content lightgrey">
                        <div className="line flex_style"></div>
                    </div>

                    <div className={classes.totalsCtn}>
                        {/* <span className={classes.selectionDetails}>{t('filters.empty-selections')}</span> */}
                        {
                            totals.length > 0 &&
                            <div className={classes.totalsCtn}>
                                {
                                    totals
                                        .sort((a, b) => {
                                            if (numericColumns.includes(a.id) && !numericColumns.includes(b.id)) {
                                                return -1
                                            }
                                            else if (numericColumns.includes(b.id) && !numericColumns.includes(a.id)) {
                                                return 1
                                            }
                                            return a.id.localeCompare(b.id)}
                                        )
                                        .map((field, index) => {
                                        let fieldId = field.id.split('field')[1]
                                        return (
                                            <Tooltip title={t('pluglist.toggle-total')} key={'total-' + field.id}>
                                                <span className={classes.totalCtn}
                                                    onClick={(e) => {props.handleToggleTotal(e, field.id)}}
                                                    >
                                                    {
                                                        numericColumns.includes(field.id)?
                                                        <span
                                                            id="icon-more"
                                                            className={`material-icons md-small ${classes.totalCb}`}
                                                            >
                                                            check_box
                                                        </span>
                                                        :
                                                        <span
                                                            id="icon-more"
                                                            className={`material-icons md-small ${classes.totalCb}`}
                                                            >
                                                            check_box_outline_blank
                                                        </span>
                                                    }

                                                    <span className={classes.totalLabel}>
                                                        <span className={classes.totalId}>{fieldId} - </span>
                                                        {field.label}
                                                    </span>
                                                    {
                                                        numericColumns.includes(field.id) &&
                                                        <NumericFormat
                                                            className={classes.totalValue}
                                                            decimalSeparator={'.'}
                                                            displayType="text"
                                                            thousandSeparator={' '}
                                                            value={field.value.toFixed(3)}
                                                        />
                                                    }
                                                </span>
                                            </Tooltip>
                                        )
                                    })
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        mainHash : state?.user?.profil?.hash || null,
        list: state?.filter?.list || [],
    }
};

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalTotalListComponent);
