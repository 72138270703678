import { FormControl, FormLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PlugTooltip } from '../../custom/CustomComponents'
import { devLog, isDefined } from '../../../utils/utils'
import { getFormatDefaultValue, isEditableColumnFormat } from '../../../utils/Fields'
import { LockedIcon, VisibilityIndicator } from '../../custom/icons/CustomIcons'
import colors from '../../../assets/themes/Colors'
import RenderEditDefaultValueComponent from '../shared/RenderEditDefaultValueComponent'

const useStyles = makeStyles(() => ({
  mainCtn: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    height: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 12,
  },
  mainTable: {
    '& .MuiTableCell-root': {
      color: colors.unselected,
      border: '1px solid ' + colors.paleGrey + ' !important',
    }
  },
  mainLimitedHeight: {
    maxHeight: 'calc(50vh)',
    overflowY: 'auto',
  },
  tableBody: {
    '& tr:last-child': {
      borderBottomLeftRadius: '50%',
      borderBottomRightRadius: '50%',
      border: '0px solid !important'
    },
  },
  headerCtn: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: colors.palePlugGreen,
    borderColor: colors.palePlugGreen,
    boxShadow: '3px 0px 6px 4px rgba(136, 136, 136, 0.12)',
    '& .MuiTableCell-root': {
      padding: '4px 8px !important',
      border: '0px solid !important',
    },
  },
  centerCell: {
    textAlign: 'center',
  },
  thinCell: {
    width: 30,
  },
  disabledCell: {
    color: colors.darkSilver,
    fontStyle: 'italic',
  },
  formatTxt: {
    color: colors.darkSilver,
    fontSize: 12,
  },
  editorCtn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    minWidth: 200
  },
  indicatorsCtn: {
    display: 'block',
    margin: 'auto',
  },
  addRow: {},
  selectMenu: {
    height: 300
  }
}))

const RenderEditTableDefaultValuesComponent = (props) => {
  // console.log('RenderEditTableDefaultValuesComponent got props', props)
  const classes = useStyles()
  const { t } = useTranslation(['common'])
  const field = props.model[props.index]
  const setup = field.options.setup
  const columns = isDefined(props.model[props.index].formatoptions.lines) ? props.model[props.index].formatoptions.lines : props.model[props.index].formatoptions

  const createNewRow = () => {
    let newRow = {}
    columns.forEach(col => {
      if (!isEditableColumnFormat(col)) {
        newRow[col.id] = t('tablemulti.not-editable')
      } else { newRow[col.id] = { value: getFormatDefaultValue(col.format), options: { editable: true, editablePf: true, editablePw: true } } }
    })
    return newRow
  }

  const [defaultRows, setDefaultRows] = useState(isDefined(props.model[props.index].formatoptions.setup.defaultRows) ? props.model[props.index].formatoptions.setup.defaultRows : [createNewRow()])
  const [editedCell, setEditedCell] = useState(null)
  const [complete, setComplete] = useState(isDefined(setup.defaultComplete) ? setup.defaultComplete : 'open')

  // Methods
  const isLimitReached = () => {
    let isFull = false
    if (setup.rowLimit > 0) {
      if (defaultRows.length === setup.rowLimit) {
        isFull = true
      }
    }
    return isFull
  }

  const handleAddRow = () => {
    if (!isLimitReached()) {
      let newRow = createNewRow()
      newRow['locked'] = false
      setDefaultRows(previous => [...previous, newRow])
    }
  }

  const handleChangeRowLock = (index, value) => {
    let _rows = [...defaultRows]
    _rows[index].locked = value
    setDefaultRows(_rows)
  }

  const handleToggleLockRow = (index) => {
    let _rows = [...defaultRows]
    _rows[index].locked = !defaultRows[index].locked
    setDefaultRows(_rows)
  }

  const handleDeleteRow = (rindex) => {
    let _rows = defaultRows.filter((row, index) => index !== rindex)
    setDefaultRows(_rows)
  }

  const handleValidate = (rindex, colId, defaultValue) => {
    let _rows = [...defaultRows]
    _rows[rindex][colId] = {
      ..._rows[rindex][colId],
      value: defaultValue
    }
    setDefaultRows(_rows)
    handleToggleEditCell()
  }

  const handleToggleEditCell = (rindex, cindex) => {
    setEditedCell(isDefined(rindex) && isDefined(cindex) ? { rindex: rindex, cindex: cindex } : null)
  }

  const handleChangeDefaultValue = (rindex, cindex, value) => {
    let _rows = [...defaultRows]
    devLog('_rows', _rows)
    devLog('handleChangeDefaultValue params', rindex, cindex, value)
    if (typeof _rows[rindex][cindex] !== "object") {
      _rows[rindex][cindex] = {
        value: value,
        options: {
          editable: true,
          editablePf: true,
          editablePw: true
        }
      }
    }
    else { _rows[rindex][cindex].value = value }
    setDefaultRows(_rows)
  }

  const handleChangeDefaultValueOptions = (rindex, cindex, edit) => {
    let _rows = [...defaultRows]
    _rows[rindex][cindex].options = {
      ..._rows[rindex][cindex].options,
      ...edit,
    }
    setDefaultRows(_rows)
  }

  const handleToggleComplete = (e) => {
    let completion = e.target.value
    if (isDefined(completion) && completion !== 0) { setComplete(completion) }
    else {
      completion = isDefined(complete) ? complete : 'once'
      setComplete(completion)
    }
    let model = [...props.model]
    model[props.index].options.setup.defaultComplete = completion
    // devLog('model changed', model[props.index])
    props.setModel(model)
  }

  useEffect(() => {
    columns.forEach((col, cindex) => {
      defaultRows.forEach(row => {
        if (!row.hasOwnProperty(col.id)) {
          row[col.id] = isEditableColumnFormat(col) ? { value: getFormatDefaultValue(col.format), options: { editable: true, editablePf: true, editablePw: true } } : t('tablemulti.not-editable')
        }
      })
    })
  }, [])

  useEffect(() => {
    // devLog('default rows changed ***', defaultRows)
    let _model = [...props.model]
    _model[props.index].options.setup.defaultRows = defaultRows
    props.setModel(_model)
  }, [defaultRows])

  return (
    <div className={classes.mainCtn} >
      <div className={classes.mainLimitedHeight}>
        <Table className={classes.mainTable} >
          <TableHead className={classes.headerCtn} >
            <TableRow >
              <TableCell />
              <TableCell colSpan={2 + columns.length}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <FormControl sx={{ m: 1 }} variant="standard">
                    <FormLabel id="select-group-complete-default">{t('tablemulti.complete-default')}</FormLabel>
                    <Select
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left"
                        },
                        getContentAnchorEl: null
                      }}
                      id="demo-customized-select"
                      labelId="select-group-complete-default"
                      onChange={handleToggleComplete}
                      value={complete}
                    >
                      <MenuItem value="open">1. {t('tablemulti.complete-default-open')}</MenuItem>
                      <MenuItem value="once">2. {t('tablemulti.complete-default-once')}</MenuItem>
                      <MenuItem value="line">3. {t('tablemulti.complete-default-line')}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={`${classes.thinCell} ${classes.centerCell}`}>
                <span className="material-icons" >
                  lock
                </span>
              </TableCell>
              <TableCell className={` ${classes.centerCell}`}>{t('tablemulti.default-applied')}</TableCell>
              {
                columns.map(col => {
                  return (
                    <TableCell key={'field-' + field.id + '-col-' + col.id} className={classes.centerCell}>
                      <div>{(col.label || t('tablemulti.missing-label'))}</div>
                      <div className={classes.formatTxt} >{col.formatLabel}</div>
                    </TableCell>
                  )
                })
              }
              <TableCell>{t('global.delete')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody} >
            {
              defaultRows.map((row, rindex) => {
                let rowNb = rindex + 1
                let rowLabel = rowNb === defaultRows.length ? t('tablemulti.default-applied-other') : (t('tablemulti.row-number') + rowNb)
                if (defaultRows.length === 1) { rowLabel = t('tablemulti.default-applied-all') }
                return (
                  <TableRow key={'field-default-' + field.id + '-row-' + rowNb} style={{ backgroundColor: 'white' }}>
                    <TableCell className={classes.centerCell}>
                      <PlugTooltip title={t('tablemulti.default-lock')} placement="top" >
                        <div onClick={() => handleToggleLockRow(rindex)}>
                          <LockedIcon locked={row.locked} />
                        </div>
                      </PlugTooltip>
                    </TableCell>
                    <TableCell className={classes.centerCell}>{rowLabel}</TableCell>
                    {
                      columns.map((col, cindex) => {
                        if (!isEditableColumnFormat(col)) {
                          return (
                            <TableCell
                              className={`${classes.centerCell} ${classes.disabledCell}`}
                              key={'field-' + field.id + '-cell-' + col.id}
                            >
                              {t('tablemulti.not-editable')}
                            </TableCell>
                          )
                        }
                        else return (
                          isDefined(editedCell) && editedCell.rindex === rindex && editedCell.cindex === cindex ?
                            <TableCell
                              className={classes.centerCell}
                              key={'field-' + field.id + '-cell-' + col.id}>
                              <RenderEditDefaultValueComponent
                                defaultValue={row[col.id].value}
                                editOptions={row[col.id].options || { editable: true, editablePf: true, editablePw: true }}
                                field={col}
                                handleCancel={handleToggleEditCell}
                                handleChangeDefaultValue={(e) => handleChangeDefaultValue(rindex, col.id, e)}
                                handleChangeDefaultValueOptions={(e) => handleChangeDefaultValueOptions(rindex, col.id, e)}
                                handleChangeRowLock={(e) => handleChangeRowLock(rindex, e)}
                                handleValidate={(value) => handleValidate(rindex, col.id, value)}
                                isTablemulti
                              />
                            </TableCell>
                            :
                            <PlugTooltip title={t('tablemulti.info-default')}
                              key={'field-' + field.id + '-cell-' + col.id}
                              placement="top"
                            >
                              <TableCell
                                className={classes.centerCell}
                                key={'field-' + field.id + '-cell-' + col.id}
                                onClick={() => handleToggleEditCell(rindex, cindex)}
                                style={{ flexDirection: 'column' }}
                              >
                                {(row[col.id]?.value?.length > 0 || row[col.id]?.value > 0) ? row[col.id].value : '—'}
                                {
                                  isDefined(row[col.id]?.options) && Object.values(row[col.id]?.options).some(entry => entry === false) &&
                                  <div className={classes.indicatorsCtn}>
                                    {
                                      !row[col.id]?.options.editable &&
                                      <span className="material-icons" style={{ color: colors.bloodOrange, fontSize: 16 }}>
                                        lock
                                      </span>
                                    }
                                    {
                                      row[col.id]?.options.editable && !row[col.id]?.options.editablePf &&
                                        <VisibilityIndicator text="PF-" visible={false} />
                                    }
                                    {
                                      row[col.id]?.options.editable && !row[col.id]?.options.editablePw &&
                                      <VisibilityIndicator text="PW-" visible={false} />
                                    }
                                  </div>
                                }
                              </TableCell>
                            </PlugTooltip>
                        )
                      })
                    }
                    <TableCell className={`${classes.centerCell} ${classes.thinCell}`}>
                      <PlugTooltip title={t('tablemulti.delete-row')} placement="top" >
                        <div onClick={() => handleDeleteRow(rindex)}>
                          <span className="material-icons" >
                            delete
                          </span>
                        </div>
                      </PlugTooltip>
                    </TableCell>
                  </TableRow>

                )
              })
            }
            {
              !isLimitReached() &&
              <TableRow className={classes.addRow} >
                <TableCell>
                  <PlugTooltip title={t('tablemulti.add-row')} placement="top" >
                    <div onClick={handleAddRow} style={{ textAlign: 'center' }}>
                      <span className="material-icons" style={{ color: colors.main, fontSize: 30 }}>
                        add_circle_outline
                      </span>
                    </div>
                  </PlugTooltip>
                </TableCell>
              </TableRow>
            }
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export default RenderEditTableDefaultValuesComponent
