import React from 'react'
import { Input, TextField, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ColorCheckbox, PlugTooltip } from '../../custom/CustomComponents'
import colors from '../../../assets/themes/Colors'
import Images from '../../../assets/themes/Images'
import { isDefined } from '../../../utils/utils'

const useStyles = makeStyles(() => ({
  popup: {
    backgroundColor: 'white',
    width: '80%',
    margin: 'auto',
    minHeight: '300px',
    border: '1px solid #DEDEDE',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 20px 10px rgba(0, 0, 0, 0.14), 0px 4px 10px 0px rgba(0, 0, 0, 0.2)',
    borderRadius: 10,
    '@media (max-width: 1024px)': {
      width: '95vw',
    },
    padding: '16px 32px',
  },
  logoCtn: {
    width: 'auto',
    maxHeight: 100,
  },
  endAdornment: {
    padding: '0 12px',
    fontWeight: 'bold',
    color: colors.bloodOrange,
  },
}))

const PlugcodeSendingFirstPreviewComponent = ({ firstText, hasAttachments, hasLogo, isEmailRequired, }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')

  const logo = sessionStorage.getItem('logo')
  const logoSrc = isDefined(logo) ? logo : Images.logoPng

  return (
    <div className={classes.popup}>
      <div className="scrollbar-hidden popup_wrapper flexSpacebetween" >
        <div className="">
          <div >
          {
            hasLogo &&
            <div className={classes.logoCtn} >
              <img src={logoSrc} alt="first-logo" style={{ maxHeight: 100 }}/>
            </div>
          }
          <TextField
            disabled
            fullWidth
            InputProps={{ disableUnderline: true }}
            multiline
            placeholder={t('plugcode.settings-your-text')}
            value={firstText}
          />
          </div>
          <br />
          <br />
            <Input
              value="your@email.com"
              disabled
              id="standard-full-width-field"
              disableUnderline={true}
              placeholder={t('common:external.email')}
              endAdornment={
                isEmailRequired &&
                <PlugTooltip title={"E-mail required"}>
                  <span className={classes.endAdornment} >*</span>
                </PlugTooltip>
              }
              fullWidth
              margin="none"
              inputlabelprops={{ shrink: true }}
              className="input"
            />
          {
            hasAttachments &&
            <div className={classes.checkboxCtn}>
              <ColorCheckbox color={colors.main} size={25} checked />
              {t('plugcode.join-attachments')}
            </div>
          }
          <button className="button" >{t('multiple.send')}</button>
        </div>
      </div>
    </div>
  )
}

export default PlugcodeSendingFirstPreviewComponent
