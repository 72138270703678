import React, {useEffect, useRef, useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import {connect} from "react-redux";
import {KeyboardTimePicker} from "@material-ui/pickers";
import {useTranslation} from "react-i18next";
import colors from "../../assets/themes/Colors";
import { Tooltip } from "@material-ui/core";
import { PlugTooltip } from "../custom/CustomComponents";
import {KeyboardArrowDown, KeyboardArrowUp, Settings} from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import {
    FormControlLabel,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    fieldContainer: {
        width : 'auto',
        display: 'flex',
        flexDirection: 'column',
    },
    field : {
        width : '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    subContentTimeCtn: {
        borderRadius: '5px',
        border: '1px solid #58c3c1',
        padding: '5px',
        marginTop: 10,
        marginBottom: 10,
        '& .MuiFormControlLabel-root': {
            margin: '0px !important',
        },
    },
    selectedMask: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "6px",
        backgroundColor: colors.selectedAlpha,
        color: colors.white,
        padding: "2px 6px",
        minHeight: 18,
        gap: 5,
    },
    unselectedMask: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 18,
        borderRadius: "6px",
        backgroundColor: colors.unselectedAlpha,
        color: colors.white,
        padding: "2px 6px",
        gap: 5,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    datePickerIcon: {
        "& .MuiIconButton-root": {
            padding: 0
        }
    },
    timeContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row'
    },
    timeDelButton: {
        height:'22px',
        width: '12px',
        color: colors.main,
        alignItems: 'center',
        marginRight: '3px'
    },
    timeNowButton: {
        height:'21px',
        width: '21px',
        color: colors.main,
        alignItems: 'center',
        border: '2.5px solid',
        borderColor: colors.main,
        borderRadius: '22px',
        fontSize: "18px",
        fontWeight: '900',
    },
    endButton: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignSelf: 'center'
    },
}));

const formatWithExtraMenu = ['extdata', 'extdatan', 'smail', 'smailvalidmulti', 'tablemulti', 'tablemultin', 'document','intdatamaj']
const formatWithFields = ['extdatan', 'tablemulti', 'tablemultin'] // S02, F32, S32
//TODO VGade: Ajout tabs Options / Valeur par défaut pour format Time et Date
// Dev - mep dans format F32, modalview + horizontal...

const FormatTimeConstructorComponent = (props) => {
    //console.log("FormatTimeConstructor props", props);
    const classes = useStyles();
    const { t } = useTranslation(['common', 'models']);
    const [includes, ] = useState(['date','time','agenda','birthday','email','mail','smail','smailvalidmulti','url','tel','localis','now']);
    const [open, setOpen] = useState(props.openSMail === props.field.id);
    const [fields, setFields] = useState(null)
    const bottomRef = useRef()
    const [selectedMask, setSelectedMask] = useState(props.field.options?.setup?.mask || 'HH:MM (24H)');

    let optionsTimeFormat = [
       {mask: 'HH:MM (24H)'},   // (14:39) --> valeur de mask par défaut
       {mask: 'HH:MM:SS'},      // (22:35:47)
       {mask: 'MM:SS (60min)'}, // (60min - comptage)
       {mask: 'HH:MM (15min)'}, // (pas de 15 - minutesStep{15}: 00,15,30,45)
       {mask: 'HH H MM'},       // (13 H 45)
       {mask: 'Free Text'},
        /*{mask: 'HH:MM (>24H)'},   // (42:15) --> durée travail hebdo par ex
        {mask: 'MM:SS (>60MIN)'},   // (110:55) --> durée match sportif par ex*/
   ]

    const handleChangeSelectedMask = (e, value) => { //par ex: HH:mm:ss
        let model = [...props.model];
        model[props.index].options.setup.mask = value;
        props.setModel(model);
        setSelectedMask(value);
    }

    useEffect(() => {
        if(formatWithFields.includes(props.field.format)) {
            if (typeof props.field.formatoptions !== 'undefined') {
                if (typeof props.field.formatoptions.lines === 'undefined') setFields(props.field.formatoptions);
                else if (typeof props.field.formatoptions.lines !== 'undefined') setFields(props.field.formatoptions.lines)
            }
        }
    }, [props.field.format, props.field.formatoptions])

    return (
        <div className={classes.fieldContainer}>
            <div className={classes.field} ref={bottomRef}>
                <div style={{display: 'flex', flexDirection: 'row', gap: 8}}>
                    <div className={classes.subContentTimeCtn}>
                        <ToggleButtonGroup
                            style={{gap: 8}}
                            value={selectedMask}
                            exclusive
                            onChange={handleChangeSelectedMask}
                            aria-label="date format option"
                        >
                            {
                                optionsTimeFormat
                                    .map(
                                        (option, index) => {
                                            return (
                                                <FormControlLabel
                                                    size='small'
                                                    value={option.mask}
                                                    key={option.mask + '-' + index}
                                                    control={
                                                        <ToggleButton
                                                            className={(selectedMask === option.mask) ? classes.selectedMask : classes.unselectedMask}
                                                                      size={'small'}
                                                        >
                                                            {option.mask}
                                                        </ToggleButton>
                                                    }
                                                >
                                                </FormControlLabel>
                                            )
                                        }
                                    )
                            }
                        </ToggleButtonGroup>
                    </div>
                </div>
                {
                    formatWithExtraMenu.includes(props.field.format) &&
                    <span style={{ width: props.isCreate === true ? '35%' : '40%' , display: 'flex'}}>
                    <PlugTooltip title={t('helper.more-options')} placement="top">
                        <div
                            className={classes.paramsBtn}
                            onClick={() => {props.handleOpenCloseSmail(props.field.id)}}
                        >
                            {!open ?
                                <div className={classes.openClose}><Settings /><KeyboardArrowDown /></div>
                                :
                                <div className={classes.openClose}><Settings /><KeyboardArrowUp /></div>}
                        </div>
                    </PlugTooltip>
                </span>
                }
            </div>
            {
                fields !== null && fields.length > 0 &&
                <div className={classes.tableOverviewRow}>
                    {
                        fields.map((row, index) => {
                            return (
                                <div className={classes.tableRow} key={"header-row-" + index}>
                                    <span className={classes.rowNb}>{index + 1}</span>
                                    <span className={classes.rowFormat}> {row.label}</span>
                                </div>)})
                    }
                </div>
            }
        </div>
    );
};

// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatTimeConstructorComponent);