import React, { useContext, useState } from 'react'
import { Modal, Table, TableBody, TableCell, TableHead, TableRow, TextField, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import colors from '../../assets/themes/Colors'
import { PlugTooltip } from '../../components/custom/CustomComponents'
import { devLog, isDefined } from '../../utils/utils'
import { fileIsImg } from '../../utils/annexes'
import { NumericFormat } from 'react-number-format'
import i18n from '../../translations/i18n'
import { getCurrentEnvironnement, getPlugnote, getToken, getUser } from '../../utils/Common'
import { PluglinkContext } from '../../components/modals/ModalPluglinkComponent'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { trackPromise } from 'react-promise-tracker'
import ApplicationConfig from '../../config/ApplicationConfig'
import Axios from 'axios'
import { getCodePnPpCodeTemplate } from '../../utils/Fields'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf, faTable } from '@fortawesome/free-solid-svg-icons'
import ModalPrintAttachmentComponent from '../../components/modals/annexes/ModalPrintAttachmentComponent'
import { putAnnexeCommentModalRequest } from '../../actions/annexe'
import { connect } from 'react-redux'

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainCtn: {
    position: 'absolute',
    padding: 16,
    backgroundColor: 'white',
    width: '90vw',
    border: '1px solid #DEDEDE',
    boxSizing: 'border-box',
    boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
    borderRadius: 10,
    height: '99vh',
    scrollbarWidth: 'none!important',
    "&::-webkit-scrollbar": {
      display: 'none'
    },
    overflow: 'hidden',
    '@media (max-width: 1024px)': {
      width: '95vw',
      minHeight: '60vh'
    },
    '@media (max-width: 640px)': {
      padding: '8px 5px 30px',
    }
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    '@media (max-width: 850px)': {
      flexWrap: 'wrap',
    },
    marginBottom: 16,
  },
  title: {
    padding: 8,
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.unselected,
  },
  subtitle: {
    color: colors.darkSilver,
    fontSize: 12,
    fontWeight: 'bold',
    paddingLeft: 8,
  },
  closeBtnCtn: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
    marginBottom: 'auto',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  closeBtn: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignSelf: 'flex-end',
    position: 'relative',
    color: colors.iconGrey,
    fontSize: 36,
  },
  tableCtn: {
    maxHeight: 'calc(100vh - 150px)',
    overflowY: 'auto',
    '& .MuiTableCell-root': {
      padding: '4px 10px',
      borderColor: colors.unselected,
      borderLeft: '1px solid !important',
    },
    borderTop: '1px solid !important',
    borderRight: '1px solid !important',
    wordBreak: 'break-all',
  },
  tableHeader: {
    '& .MuiTableCell-stickyHeader': {
      backgroundColor: colors.palePlugGreen
    },
    borderRadius: 5,
  },
  titleCtn: {
    flex: 0.3,
    display: 'flex',
    flexDirection: 'column',
    '@media (max-width: 450px)': {
      flex: 0.7,
    },
  },
  dropzoneCtn: {
    flex: 0.5,
    justifyContent: 'center',
    display: 'inline-flex',
    '@media (max-width: 450px)': {
      margin: '0 auto',
      order: 3,
    },
  },
  cancelBtn: {
    cursor: "pointer",
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.redDark,
    padding: 4,
    height: 24,
    width: 24,
    border: '1px solid ' + colors.redDark,
    borderRadius: '50%',
  },
  okBtn: {
    width: 24,
    cursor: "pointer",
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.mainButton,
    border: '1px solid ' + colors.mainButton,
    borderRadius: '50%',
    height: 24,
  },
  button: {
    color: "white",
    cursor: "pointer",
    display: 'inline-block',
    backgroundColor: colors.mainButton,
    padding: "4px 16px",
    borderRadius: 4,
  },
  comment: {
    fontSize: 12,
    color: colors.unselected,
  },
  underline: {
    color: colors.mainButton,
    borderBottomWidth: 0,
    '&&&:after': {
      borderBottom: "none",
    },
    '&&&:before': {
      borderBottom: "none",
    },
    '&&&:hover': {
      borderBottom: '2px solid ' + colors.mainButton,
    },
    '& .MuiInput-underline:after': {
      borderBottom: "none",
    },
  },
  totalValues: {
    textAlign: 'left',
    fontSize: 14,
    fontWeight: 'bold'
  },
  totalCtn: {
    display: 'flex',
    height: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionCell: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tablemultiFont: {
      fontSize: 12,
      fontWeight: 'bold',
      textAlign: 'center',
  },
}))

export const FileCommentComponent = ({ annexe, disabled, edit, handleSaveComment }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const [comment, setComment] = useState(annexe?.comment)
  // devLog('FileComment handleSave', handleSaveComment)

  const handleOnFocus = e => {
    const val = e.target.value;
    e.target.value = '';
    e.target.value = val;
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginRight: isDefined(edit) ? 0 : -6, order: edit === 'row' ? 2 : 'inherit', width: edit === 'row' ? '60%' : 'auto' }} >
      <TextField
        autoFocus={edit === 'cell'}
        disabled={disabled}
        fullWidth
        InputProps={{
          classes: {
            input: isDefined(edit) ? classes.tablemultiFont : classes.comment,
            underline: edit === 'row' ? undefined : classes.underline
          },
        }}
        multiline
        placeholder=" — "
        onChange={e => setComment(e.target.value)}
        onFocus={handleOnFocus}
        value={comment}
      />
      {
        comment !== annexe?.comment &&
        <div style={{ marginRight: -10 }} >
          <PlugTooltip title={t('global.cancel')} placement="bottom">
            <div className={classes.cancelBtn} onClick={() => setComment(annexe?.comment)}>
              <span className={`material-icons`} style={{ fontSize: 16, fontWeight: 'bold', }}>
                close
              </span>
            </div>
          </PlugTooltip>
          <PlugTooltip title={t('global.validate')} placement="bottom" >
            <div className={classes.okBtn} onClick={() => handleSaveComment(comment)}>
              <span className={`material-icons`} style={{ fontSize: 16, fontWeight: 'bold', }}>
                check
              </span>
            </div>
          </PlugTooltip>
        </div>
      }
    </div>
  )
}

const FileLink = ({ annexe, handleOpenAnnexePreview, renderAnnexeImagePreview }) => {
  const { t } = useTranslation('common')
  const [openTooltip, setOpenTooltip] = useState(false)

  const handleClickLink = (e) => {
    setOpenTooltip(false)
    handleOpenAnnexePreview(e, annexe)
  }

  const buttonStyle = {
    color: "white",
    cursor: "pointer",
    display: 'inline-block',
    backgroundColor: colors.mainButton,
    padding: "4px 16px",
    borderRadius: 4,
  }

  return (
    <PlugTooltip
      key={annexe.name}
      title={
        fileIsImg(annexe)
          ? renderAnnexeImagePreview(annexe)
          : t('global.preview')
      }
      onClose={() => setOpenTooltip(false)}
      onMouseEnter={() => setOpenTooltip(true)}
      open={openTooltip}
      placement="left"
    >
      <div onClick={handleClickLink} style={buttonStyle} >
        <span className={`material-icons`} >{annexe.type === 'note' ? 'edit' : 'visibility'}</span>
      </div>
    </PlugTooltip>
  )
}

const AttachmentTableView = (props) => {
  // devLog('Table view props', props)
  const classes = useStyles()
  const { t } = useTranslation('common')
  const [hasChangedOrder, setHasChangedOrder] = useState(false)
  const [printing, setPrinting] = useState(false)
  const user = getCurrentEnvironnement()

  let decimalSeparator = ','
  let thousandSeparator = '.'
  if (i18n.language === 'en') {
    decimalSeparator = '.'
    thousandSeparator = ','
  }

  const pluglink = useContext(PluglinkContext)
  const plugnote = getPlugnote()
  const folder = '[' + (isDefined(pluglink) ? pluglink.folder : plugnote?.modclef) + ']'
  const plugcode = isDefined(pluglink) ? pluglink.code : getCodePnPpCodeTemplate(plugnote)
  const nameModel = isDefined(pluglink) ? pluglink.plugform.label : plugnote?.modname

  const handleReorderAnnexes = (content) => trackPromise(
    Axios.post(ApplicationConfig.apiUrl + `annexes/positions`,
      {
        content: content,
      },
      {
        crossdomain: true,
        'headers': {
          'X-AUTH-TOKEN': getToken(),
          'Content-Type': 'application/json',
        }
      })
      .then((response) => {
        console.log('response', response)
      })
      .catch((response) => {
      })
  )

  const handleExitModal = (_, reason) => {
    if (reason && reason === 'backdropClick') { return }
    if (hasChangedOrder) { handleReorderAnnexes(props.annexes.map((att, index) => ({ hash: att.hash, position: index + 1 }))) }
    props.handleOnClose()
  }

  const getListStyle = dragging => ({
    background: dragging ? "lightblue" : "white",
  })

  const handleTogglePrint = () => {
    setPrinting(current => !current)
  }

  const handleSaveComment = (comment, annexe) => {
    props.handleSaveComment(annexe, comment)
    props.attemptUpdateComment(annexe.hash, comment, user.userhash)
  }

  const onDragRow = (result) => {
    if (!result.destination || (result.destination.index === result.source.index)) {
      return
    }
    if (!hasChangedOrder) setHasChangedOrder(true)
    const source = result.source.index
    const destination = result.destination.index
    props.handleReorderAnnexes(source, destination)
  }

  const getRowBackground = (snapshot, id) => {
    if (id === props.highlighted) return colors.plugnotesLightAlpha
    return snapshot.isDragging
      ? colors.plugnotesLight
      // ? "rgba(245,245,245, 0.75)"
      : "none"
  }

  return (
    <Modal
      keepMounted={false}
      open={props.open}
      onClose={handleExitModal}
      className={classes.modal}
      BackdropProps={{ style: { backgroundColor: colors.blackAlpha } }}
    >
      <div className={`${classes.mainCtn} ${classes.scrollBar} `} >
        <div className={classes.modalHeader} >
          <div className={classes.titleCtn} >
            <span className={classes.title}>{t('document.tableview-title')}</span>
            <span className={classes.subtitle}>{folder + ' - ' + (plugcode?.length > 0 ? plugcode + ' - ' : '') + plugnote?.userindex}</span>
            <span className={classes.subtitle}>{nameModel}</span>
          </div>
          {
            !printing &&
            <div className={classes.dropzoneCtn} >
              {props.Dropzone}
            </div>
          }
          <div className={classes.closeBtnCtn} style={{ flex: 0.3 }} >
            <PlugTooltip title={t(printing ? 'multiple.table-view' : 'print.print-pdf')} placement="bottom">
              <span onClick={handleTogglePrint}>
                <FontAwesomeIcon icon={printing ? faTable : faFilePdf} size="lg" style={{ fontSize: 36, color: colors.mainButton, width: 40, cursor: 'pointer', }}  />
              </span>
            </PlugTooltip>
            <span className={`material-icons ${classes.closeBtn}`} onClick={handleExitModal}>
              close
            </span>
          </div>
        </div>
        {
          printing ?
            <ModalPrintAttachmentComponent
              annexes={props.annexes
                .map(annexe => {
                  const size = annexe.type === 'note' ? '-' : Math.round((annexe.size / 100)) / 10
                  const date = isDefined(annexe.data?.lastModifiedDate)
                      ? annexe.data?.lastModifiedDate.toLocaleDateString()
                      : (new Date(annexe.creationdate)).toLocaleDateString()
                  return (
                  {
                    900: annexe.type,
                    901: annexe.name,
                    902: annexe.comment,
                    903: date,
                    904: props.getAnnexeUrl(annexe),
                    905: size,
                    ...annexe,
                  }
                )})}
              columns={props.columns}
              plugnote={pluglink || plugnote}
              plugcode={plugcode}
              user={user}
            />
          :
          <div className={classes.tableCtn}>
            <Table stickyHeader>
              <TableHead className={classes.tableHeader} sx={{ display: "table-header-group" }} aria-label="simple table">
                <TableRow style={{ position: 'relative' }}>
                  <TableCell align="center" style={{ width: 30 }}>
                    <span className={classes.columnHeader}>N°</span>
                  </TableCell>
                  {
                    props.columns.map((entry, id) => {
                      const cellStyle = {
                        whiteSpace: "break-spaces",
                        wordBreak: 'break-word',
                        width: entry.colWidth,
                      }
                      return (
                        <TableCell align="center"
                          key={"header-" + id}
                          style={cellStyle}
                        >
                          <span className={classes.columnHeader} style={cellStyle}>
                            {entry.label}
                          </span>
                        </TableCell>
                      )
                    })
                  }
                  <TableCell align="center" style={{ width: 80 }}>
                    <span className={classes.columnHeader}>Actions</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <DragDropContext onDragEnd={onDragRow}>
                <Droppable droppableId="droppable" direction="vertical">
                  {(droppableProvided, snapshot) => (
                    <TableBody
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {
                        props.annexes
                          .slice()
                          .sort((a, b) => a?.position - b?.position)
                          .map((annexe, rindex) => {
                          const rowId = rindex + 1
                          const fileSize = annexe.type === 'note' ? '-' : Math.round((annexe.size / 100)) / 10
                          const moddate = isDefined(annexe.data?.lastModifiedDate)
                            ? annexe.data?.lastModifiedDate.toLocaleDateString()
                            : (new Date(annexe.creationdate)).toLocaleDateString()
                          return (
                            <Draggable
                              key={annexe.name}
                              draggableId={annexe.name}
                              index={rindex}
                            >
                              {(draggableProvided, snapshot) => (
                                <TableRow id={"attachment-index-" + rowId} key={rowId}
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                  style={{
                                    ...draggableProvided.draggableProps.style,
                                    background: getRowBackground(snapshot, annexe.id)
                                  }}
                                  className={annexe.id === props.highlighted ? "fading" : undefined}
                                >
                                  <TableCell align="center">
                                    {rowId}
                                  </TableCell>
                                  <TableCell align="center" >
                                    {annexe.type}
                                  </TableCell>
                                  <TableCell align="center" >
                                    {annexe.name}
                                  </TableCell>
                                  {
                                    snapshot.isDragging ?
                                      <TableCell align="center" style={{ position: 'relative' }}>
                                        {annexe?.comment?.slice(0, 30)}
                                      </TableCell>
                                      :
                                      <TableCell align="center" style={{ position: 'relative' }}>
                                        {
                                          props.disabled
                                            ? annexe.comment
                                            : <FileCommentComponent
                                              annexe={annexe}
                                              disabled={props.disabled}
                                              handleSaveComment={comment => handleSaveComment(comment, annexe)}
                                            />
                                        }
                                      </TableCell>
                                  }
                                  <TableCell align="center" >
                                    {moddate}
                                  </TableCell>
                                  <TableCell align="center" >
                                    <FileLink
                                      annexe={annexe}
                                      handleOpenAnnexePreview={props.handleOpenAnnexePreview}
                                      renderAnnexeImagePreview={props.renderAnnexeImagePreview}
                                      url={props.getAnnexeUrl(annexe)}
                                    />
                                  </TableCell>
                                  <TableCell align="center" >
                                    <div className={classes.totalCtn} style={{ textAlign: 'center' }}>
                                      <NumericFormat value={fileSize} thousandSeparator={thousandSeparator} decimalSeparator={decimalSeparator} displayType="text"
                                        renderText={
                                          (value, _) => <span className={classes.totalValues}>
                                            {value}
                                          </span>}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell align="center">
                                    <div className={classes.actionCell} >
                                      <PlugTooltip title={t('document.move-annexe')} >
                                        <span
                                          {...draggableProvided.dragHandleProps}
                                          style={{ cursor: 'grab' }}
                                          className={`material-icons `}
                                        >
                                          drag_handle
                                        </span>
                                      </PlugTooltip>
                                      {
                                        props.disabled === false &&
                                        <PlugTooltip title={t('global.delete')} >
                                          <span className="material-icons" onClick={(event) => props.handleDeleteAnnexe(event, annexe)} >
                                            delete
                                          </span>
                                        </PlugTooltip>
                                      }
                                      {
                                        props.mode !== "modal-plugnote-create" && typeof props.isAuthorizedToEdit !== "undefined" && props.isAuthorizedToEdit(annexe.type) &&
                                        <span
                                          id="icon-file_"
                                          className="material-icons"
                                          onClick={(event) => props.handleUpdateAnnexe(event, annexe)}
                                        >
                                          publish
                                        </span>
                                      }
                                      <span
                                        id="icon-file_copy"
                                        className="material-icons"
                                        onClick={(event) => props.downloadFile(event, annexe)}
                                      >
                                        get_app
                                      </span>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              )}
                            </Draggable>
                          )
                        })
                      }
                      {droppableProvided.placeholder}
                    </TableBody>
                  )}
                </Droppable>
              </DragDropContext>
            </Table>
          </div>
        }
      </div>
    </Modal>
  )
}

const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    // attemptUpdateCommentAnnexe: (annexehash, comment, userhash) =>
    //   dispatch(putAnnexeCommentRequest(annexehash, comment, userhash)),
    attemptUpdateComment: (annexehash, comment, userhash) => dispatch(putAnnexeCommentModalRequest(annexehash, comment, userhash)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentTableView)
