import React, { useEffect, useRef, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import Input from "@material-ui/core/Input"
import { useTranslation } from "react-i18next"
import { IconButton, InputLabel, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@material-ui/core"
import colors from "../../assets/themes/Colors"
import { addChar, devLog, isDefined } from "../../utils/utils"
import TableContainer from "@material-ui/core/TableContainer"
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import { SMailSideMenu } from "../custom/SMail"
import { Settings } from "@material-ui/icons"
import { ReactComponent as MultiSelect } from '../../assets/images/format-icon/svg/multi-select.svg'
import { ReactComponent as MultiSelectAdd } from '../../assets/images/format-icon/svg/free-multi-select.svg'
import { ReactComponent as SingleSelect } from '../../assets/images/format-icon/svg/single-select.svg'
import { ReactComponent as SingleSelectAdd } from '../../assets/images/format-icon/svg/free-single-select.svg'
import FormatTableMultipleConstructorComponent from "./FormatTableMultipleConstructorComponent"
import FormatExtDataConstructorComponent from "./FormatExtDataConstructorComponent"
import { PlugTooltip } from "../custom/CustomComponents"
import RichEditor from "../custom/rich-editor/RichEditor"

const useStyles = makeStyles((theme) => ({
    MuiInputBaseInput: {
        padding: 0
    },
    logo: {
        width: '10%',
    },
    mainField: {
        display: 'flex',
        flexDirection: 'column'
    },
    subfield: {
        width: 'auto',
        backgroundColor: colors.palePlugGreen,
    },
    selectType: {
        //paddingRight: '24px',
        marginTop: '15px',
        marginLeft: '10px',
        marginRight: '10px',
    },
    field: {
        width: '100%',
        display: 'flex',
    },
    inputField: {
        margin: '5px'
        //padding : '0px 0px 7px',
    },
    margin: {
        paddingTop: '15px',
        paddingLeft: 10,
        marginBottom: 10,
    },
    MuiFormControl: {
        margin: "inherit",
    },
    RadioGroupCell: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 20,
        //border: '1px solid greenyellow',
    },
    radioIcon: {
        color: colors.main,
        width: 25,
    },
    radioIconChecked: {
        color: colors.main,
    },
    radioLabel: {
        fontSize: 10,
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#FFFFFF',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius: '5px',
        border: '1px solid #ccc',
        WebkitBorderRadius: '5px',
    },
    textFieldArea: {
        padding: 10,
        marginBottom: 10,
        fontWeight: '500',
        borderRadius: '5px',
        backgroundColor: '#FFFFFF',
        fontSize: '14px',
        width: '100%',
        border: '1px solid #ccc',
        MozBorderRadius: '5px',
        WebkitBorderRadius: '5px',
    },
    buttonsBox: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex'
    },
    spaceBetweenFlexCtn: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    inputContainer: {
        width: '100%'
    },
    input: {
        padding: '0 10px'
    },
    selectedMask: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "6px",
        backgroundColor: colors.selectedAlpha,
        color: colors.white,
        padding: "3px 8px",
        minHeight: 24,
        gap: 5,
    },
    unselectedMask: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 24,
        borderRadius: "6px",
        backgroundColor: colors.unselectedAlpha,
        color: colors.white,
        padding: "3px 8px",
        gap: 5,
    },
    masksContainer: {
        width: 'inherit',
        minHeight: 24,
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        flexWrap: 'wrap',
        borderRadius: "6px",
        alignItems: 'flex-start',
        gap: 5,
    },
    subOpenClose: {
        display: 'flex',
        flexDirection: 'column',
        '&:hover': {
            borderRadius: 50,
            background: colors.frost
        }
    },
    fieldIcon: {
        stroke: colors.white,
        fill: colors.white,
        color: colors.white,
        width: 20,
        height: 'auto',
        verticalAlign: 'sub',
        cursor: 'pointer'
    },
    preselConfig: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 5,
        paddingBottom: 5
    },
    tableCtn: {
        marginTop: -24,
    },
    table: {
        '@media(max-width: 1024px)': {
            '& .MuiTableCell-root': {
                padding: 8
            }
        }
    },
    tableHeader: {
        '& .MuiTableCell-root': {
            padding: '8px 30px !important',
        },
    },
    headerTxt: {
        fontSize: 18,
    },
    secondColCell: {
        '& .MuiTableCell-root': {
            borderLeft: '1px solid #cccccc',
        },
        borderLeft: '1px solid #cccccc',
    },
    // Tabs
    gradientBgnd: {
        background: 'linear-gradient(' + colors.palePlugGreen + ', ' + colors.transparent + ')',
        backgroundColor: colors.palePlugGreen,
    },
    tabsWrapper: {
        display: 'flex',
        flexDirection: 'row',
    },
    tabsCtn: {
        '& .MuiTab-root': {
            textTransform: 'none !important',
        },
        '& .MuiTabs-indicator': {
            top: 0,
            backgroundColor: colors.main,
        },
        '& .MuiTabTextColorInherit.MuiSelected .MuiTab-textColorInherit.Mui-selected': {
            color: colors.main,
            fontWeight: 700,
        },
        '& div.MuiTabs-scroller.MuiTabs-fixed': {
            overflow: 'auto !important',
        }
    },
    tab: {
        // paddingTop: -20,
    },
    tabIcon: {
        height: 24,
        width: 24,
    },
    unselectedTab: {
        backgroundColor: colors.greyLight,
    },
    voidSpace: {
        flex: 1,
        background: `linear-gradient(90deg, ${colors.greyLight}, transparent)`,
        opacity: 0.7,
    },
}));

const getTypeValue = (value) => {
    let multiselect;
    let editable;
    if (value === null || value === '') {
        return 'T01';
    } else {
        multiselect = value.multiselect
        editable = value.editable
        if (multiselect === false && editable === false) {
            return 'T01';
        }
        else if (multiselect === false && editable === true) {
            return 'T02';
        }
        else if (multiselect === true && editable === false) {
            return 'T03';
        }
        else if (multiselect === true && editable === true) {
            return 'T04';
        }
    }
}

// Finds special chars in column label
export const getColumnLabel = (label) => {
    const textStyle = {}
    let template;
    let hasTooltip = false;
    if (label.includes('~')) {
        const parts = label.split("~");
        template = parts.map((part, i) => {
            let section;
            if (part.includes('#')) {
                hasTooltip = true;
                section = part.split('#')[0] + '...';
            } else section = part;
            if (i !== parts.length - 1) {
                return (<span style={textStyle} key={'label-section-' + section}>{section}<br /></span>);
            }
            return (<span style={textStyle} key={'label-section-' + section}>{section}</span>);
        });
    } else if (label.includes('#')) {
        const index = label.indexOf("#");
        hasTooltip = true;
        template = (
            [<span style={textStyle} key={'label-slice-' + label}>
                {label.slice(0, index)}
                <span style={textStyle}>...</span>
            </span>]
        );
    } else {
        template = (
            [<div style={textStyle} key={'label-slice-' + label}>{label}</div>]
        );
    }
    let _label = label.replaceAll('#', '');
    return (hasTooltip ?
        <PlugTooltip title={_label.replaceAll('~', '\n')}>
            <div>
                {template.map(part => part)}
            </div>
        </PlugTooltip>
        :
        <>
            {template.map(part => part)}
        </>
    );
}

export const RenderMaskList = (props) => {
    // console.log('RenderMaskList props', props);
    const classes = useStyles()
    const [newData, setNewData] = useState(props.row.mask !== null ? props.row.mask : '');
    const [masks, setMasks] = useState(props.rows.findIndex((row => row.format === props.row.format)) !== -1 ? [props.rows[props.rows.findIndex((row => row.format === props.row.format))].mask] : []);

    useEffect(() => {
        setNewData(props.row.mask !== null ? props.row.mask : '');
        let rindex = props.rows.findIndex((row => row.format === props.row.format));
        setMasks(rindex === -1 ? [] : props.rows[rindex].mask);
    }, [props.row.format]);

    const handleMaskChange = (value) => {
        let regex = null;
        let _value
        if (typeof value === 'string') _value = value;
        else _value = value.toString()
        setNewData(_value);
        switch (props.row.format) {
            case "duration":
            case "time":
            case "numeric":
            case "date":
            case "tel":
                let indexRow = props.rows.findIndex((row => row.mask.includes(_value)));
                if (indexRow !== -1) {
                    let row = props.rows[indexRow];
                    let indexMask = row.mask.findIndex((mask => mask === _value));
                    regex = row.regex[indexMask];
                }
                break;
            default:
                regex = null;
                break;
        }
        props.handleUpdateMask(props.index, _value, regex);
    }

    if (masks.length === 0) {
        return null;
    }

    if (props.row.format === 'presel') {
        return (
            <div className={classes.preselConfig}>
                <RenderTypeList {...props} row={props.row} index={props.index} rows={props.rows} handleUpdateType={props.handleUpdateType} />
                {
                    !props.isPreview &&
                    <IconButton aria-label="expand row" size="small" style={{ alignItems: 'flex-start' }} onClick={() => props.handleToggleSubConfig(props.index)}>
                        {
                            props.open && props.openRow === props.index
                                ? <div className={classes.subOpenClose}><Settings /><KeyboardArrowUpIcon /></div>
                                : <div className={classes.subOpenClose}><Settings /><KeyboardArrowDownIcon /></div>
                        }
                        ({props.row.presel.length})
                    </IconButton>
                }
            </div>
        )
    }
    return (
        <div className={classes.masksContainer} key="uniquevalue">
            {
                masks
                    .filter(mask => {
                        if (props.isPreview) { return mask === newData }
                        else return true
                    })
                    .map((row, index) => {
                        return (
                            <div className={row === newData ? classes.selectedMask : classes.unselectedMask} key={index + "-mask"} onClick={() => handleMaskChange(row)}>{row}</div>
                        )
                    })}
        </div>
    );
}

const RenderTypeList = (props) => {
    const { t, } = useTranslation(['common', 'models']);
    const [newData, setNewData] = useState(getTypeValue(props.row.mask));
    const selectTypes = ['T01', 'T02', 'T03', 'T04']
    const classes = useStyles()

    useEffect(() => {
        setNewData(getTypeValue(props.row.mask));
    }, [props.row]);

    const handleTypeChange = (event) => {
        setNewData(event.target.value.toString());
        props.handleUpdateType(props.index, event.target.value);
    }
    function rowRender(value) {
        let icon = ""
        let tooltip = ""
        let label = ""
        switch (value) {
            case 'T01':
                icon = <SingleSelect alt={t('fields.format-choice-' + props.field.format.toLowerCase())} fill='white !important' className={classes.fieldIcon} />
                tooltip = t("common:formats.preselection-type-unique-two")
                label = t("selects.single")
                break;
            case 'T02':
                icon = <SingleSelectAdd alt={t('fields.format-choice-' + props.field.format.toLowerCase())} fill='white !important' className={classes.fieldIcon} />
                tooltip = t("common:formats.preselection-type-unique-and-free-two")
                label = t("selects.single-add")
                break;
            case 'T03':
                icon = <MultiSelect alt={t('fields.format-choice-' + props.field.format.toLowerCase())} fill='white !important' className={classes.fieldIcon} />
                tooltip = t("common:formats.preselection-type-multiple-two")
                label = t("selects.multiple")
                break;
            case 'T04':
                icon = <MultiSelectAdd alt={t('fields.format-choice-' + props.field.format.toLowerCase())} fill='white !important' className={classes.fieldIcon} />
                tooltip = t("common:formats.preselection-type-multiple-and-free-two")
                label = t("selects.multiple-add")
                break;
            default:
                break;
        }
        let result = { icon: icon, label: label, tooltip: tooltip }
        // console.log('result', result);
        return result
    }

    return (
        <div className={classes.masksContainer} key="uniquevalue">
            {selectTypes
                .filter(mask => {
                    if (props.isPreview) { return mask === newData }
                    else return true
                })
                .map((row, index) => {
                    return (
                        <PlugTooltip title={rowRender(row).tooltip} key={"presel-" + index}>
                            <div className={row === newData ? classes.selectedMask : classes.unselectedMask} key={index + "-select"} onClick={() => handleTypeChange(row)}>
                                <div className={classes.fieldIcon} style={{ width: '20px', fill: 'white !important' }}>
                                    {rowRender(row).icon}
                                </div>
                                {rowRender(row).label}
                            </div>
                        </PlugTooltip>
                    )
                })}
        </div>
    );
}

const FormatSMailValidMultiConstructorComponent = (props) => {
    // console.log('SMailValid constructor props', props)
    const classes = useStyles()
    const inputSubjectRef = useRef()
    const inputBodyRef = useRef()
    const editorRef = useRef(null)
    const { t } = useTranslation(['common', 'models'])
    const auto = props?.field?.options?.note || "Y"
    const [template, setTemplate] = useState(props?.field?.options?.template || "")
    const [elements, setElements] = useState([])
    const [title, setTitle] = useState(props?.field?.options?.title || "")
    const [body, setBody] = useState(props?.field?.options?.body || "")
    const [note, setNote] = useState(props?.field?.options?.note || "Y")
    const [from, setFrom] = useState(props?.field?.options?.from || "noreply@plugnotes.com")
    const [cc, setCc] = useState(props?.field?.options?.cc || "")
    const [cci, setCci] = useState(props?.field?.options?.cci || "")
    const [share, setShare] = useState(props?.field?.options?.share || false)
    const [selectionStart, setSelectionStart] = useState()
    const [inserts, setInserts] = useState([])
    const [open, setOpen] = useState(false)
    const [openRow, setOpenRow] = useState(null)
    const [openSideMenu, setOpenSideMenu] = useState(true)

    let valueRows = [
        createData('E mail', 'Mail', 'mail', '@', null, 1001, [], null, null, false),
        createData('Valider', 'Radio', 'radio', 'Y/N/NA', null, 1002, [], null, true, false),
        createData('Suivi des dates', 'Date', 'date', "DD/MM/YYYY", null, 1003, '99/99/9999', null, true, false),
        createData('PW-code', 'Texte', 'text', '40', null, 1000, [], null, null, false),
    ]

    const [dataRows, setDataRows] = useState((!isDefined(props.field.formatoptions.lines) || props.field.formatoptions?.lines.length === 0) ? valueRows : props.field.formatoptions.lines);

    function createData(label, formatLabel, format, mask, presel, id, regex, configuration, visible, editable) {
        return { label, formatLabel, format, mask, presel, id, regex, configuration, visible, editable };
    }

    const rows = [
        createData('', '', '', [], [], 0, [], null, true, true),
        createData('Taches', 'Présélection', 'presel', [{ multiselect: false, editable: false }, { multiselect: false, editable: true }, { multiselect: true, editable: false }, { multiselect: true, editable: true }], [], 1, [], null, null, true),
        createData(t('smailvalidmulti.date-tracking'), 'Date', 'date', ["DD/MM", "DD/MM/YY", "DD/MM/YYYY", "MM/YYYY"], null, 2, ['99/99', '99/99/99', '99/99/9999', '99/9999'], null, null, true),
        createData('Quantité', 'Num', 'numeric', ['XX', 'XX,XXXXXX', 'X.XXX', 'X.XXX,XXXXX'], null, 3, ['99', '99,999999', '9.999', '9.999,99999'], null, null, true),
        createData('Debut', 'Heure', 'time', ['HH:MM (24H)', 'HH:MM:SS', 'MM:SS (60min)', 'Free Text', 'HH H MM'], null, 4, ['99:99', '99:99:99', '99:99', '99', '99 H 99'], null, null, true),
        createData('Commentaires', 'Texte', 'text', ['10', '40', '256', '512'], null, 5, [], null, null, true),
        createData(t('smailvalidmulti.validation'), 'Radio', 'radio', ['Y/N', 'Y/N/NA'], null, 6, [], null, null, true),
        createData('E mail', 'Mail', 'mail', ['@'], null, 7, [], null, null, true),
        createData('GSM', 'Téléphone', 'tel', ['xxxx/xxxxxxxx', '+xxxxxxxxxxxxxx'], null, 8, ['9999/99999999', '+99999999999999'], null, null, true),
        createData('Statut', 'Select', 'select', [], ['1-ouvert', '2-fermé'], 9, [], null, null, true),
        createData('Formula', 'Formula', 'formula', [], null, 10, null, { formula: "" }, null, true),
        createData('S01', 'S01', 'extdata', [], null, 11, null, { ref: "", query: "LIKE", columns: "", column: "", dest: "" }, null, true),
        createData(t('constructor.duration'), t('constructor.duration'), 'duration', ["HH:MM", "HH:MM:SS", "MM:SS"], null, 12, ['99:99', '99:99:99', '99:99'], null),
        createData('URL', 'Url', 'url', [], null, 13, [], null, null),
    ]
    const pwCodeColumn = {
        configuration: null,
        dragId: "col-99",
        editable: false,
        format: "text",
        formatLabel: "Texte",
        id: 1000,
        label: "PW-code",
        mask: "40",
        presel: null,
        regex: [],
        visible: null,
    }

    function setCaretPosition(elemId, caretPos) {
        var el = document.getElementById(elemId);
        el.value = el.value;
        // ^ this is used to not only get "focus", but
        // to make sure we don't have it everything -selected-
        // (it causes an issue in chrome, and having it doesn't hurt any other browser)
        if (el !== null) {
            if (el.createTextRange) {
                var range = el.createTextRange();
                range.move('character', caretPos);
                range.select();
                return true;
            }
            else {
                // (el.selectionStart === 0 added for Firefox bug)
                if (el.selectionStart || el.selectionStart === 0) {
                    el.focus();
                    el.setSelectionRange(caretPos, caretPos);
                    return true;
                }
                else { // fail city, fortunately this never happens (as far as I've tested) :)
                    el.focus();
                    return false;
                }
            }
        }
    }

    const handleUpdateMask = (index, value, regex) => {
        dataRows[index].mask = value;
        dataRows[index].regex = regex;
        setDataRows(dataRows);
    }

    const handleUpdateType = (index, value) => {
        let multiselect;
        let editable;

        switch (value) {
            case 'T01':
                multiselect = false;
                editable = false;
                break;
            case 'T02':
                multiselect = false;
                editable = true;
                break;
            case 'T03':
                multiselect = true;
                editable = false;
                break;
            case 'T04':
                multiselect = true;
                editable = true;
                break;
            default:
                break;
        }

        dataRows[index].mask = { multiselect: multiselect, editable: editable };
        setDataRows(dataRows);
    }

    const handleChangeTitle = (value, field, index) => {
        setTitle(value)
        field.options.title = value
        let model = [...props.model];
        model[index].options.title = value;
        props.setModel(model);
    }

    const handleChangeShare = (value, field, index) => {
        setShare(value)
        field.options.share = value
        let newLines
        let model = [...props.model]
        model[index].options.share = value
        newLines = value
            ? [...model[index].options.lines, pwCodeColumn]
            : model[index].options.lines.filter(col => col.id !== 1000)
        model[index].formatoptions.lines = newLines
        model[index].options.lines = newLines
        props.setModel(model)
        field.options.lines = newLines
        setDataRows(newLines)
    }

    const handleChangeBody = (value, addValue, field, index) => {
        let newBody = "";
        if (typeof selectionStart === 'undefined') {
            if (addValue !== null) {
                newBody = `${value} ${addValue}`
            }
            else {
                newBody = `${value}`
            }
        } else {
            if (addValue !== null) {
                newBody = addChar(`${value}`, `${addValue}`, selectionStart);
                setSelectionStart((selectionStart + addValue.length) + 2);
                setTimeout(() => {
                    setCaretPosition("body-" + props.field.key, selectionStart + addValue.length + 2);
                    inputBodyRef.current.blur()
                    inputBodyRef.current.focus()
                }, 200)
            } else {
                newBody = `${value}`
            }
        }

        // devLog('newBody', newBody)
        setBody(newBody);

        field.options.body = newBody;
        let model = [...props.model];
        model[index].options.body = newBody;
        props.setModel(model);
    }

    const handleChangeFrom = (value, field, index) => {
        setFrom(value);

        field.options.from = value;
        let model = [...props.model];
        model[index].options.from = value;
        props.setModel(model);
    }

    const handleChangeCc = (value, field, index) => {
        setCc(value);

        field.options.cc = value;
        let model = [...props.model];
        model[index].options.cc = value;
        props.setModel(model);
    }

    const handleChangeCci = (value, field, index) => {
        setCci(value);

        field.options.cci = value;
        let model = [...props.model];
        model[index].options.cci = value;
        props.setModel(model);
    }

    const handleChangeTemplateSelect = (value, field, index) => {
        setTemplate(value);
        field.options.template = value;
        let model = [...props.model];
        model[index].options.template = value;
        props.setModel(model);
    }

    const handleChangeTemplateRadio = (value, field, index) => {
        setNote(value);
        field.options.note = value;
        let model = [...props.model];
        model[index].options.note = value;
        props.setModel(model);
    }

    const updateSubjectSelectionStart = () => setSelectionStart(inputSubjectRef.current.selectionStart);

    const updateBodySelectionStart = () => {
        let cursor = inputBodyRef.current.selectionStart
        setSelectionStart(cursor);
        if (inserts.length > 0) {
            for (let index = 0; index < inserts.length; index++) {
                const element = inserts[index];
                if (cursor > element.start && cursor < element.end) {
                    inputBodyRef.current.setSelectionRange(element.start + 1, element.end - 1)
                }
            }
        }
    }

    const updateInsertedValues = () => {
        let values = []
        if (body !== '')
            for (let index = 0; index < body.length; index++) {
                const elementA = body.charAt(index);
                const elementB = body.charAt(index + 1);
                const elementC = body.charAt(index + 2);
                if (elementA === '{' && elementB === '{') {
                    let indice = index
                    let char = ''
                    let value = {}
                    while (char !== '}' && indice < body.length) {
                        char = body.charAt(indice)
                        indice++
                    }
                    value.start = index - 1
                    value.end = indice + 2
                    value.value = body.substring(index + 2, indice - 1)
                    values.push(value)
                }
                if (elementA === '*' && elementB === '*' && elementC === '*') {
                    let indice = index + 3
                    let char = ''
                    let value = {}
                    while (char !== '*' && body.charAt(indice + 1) && indice < body.length) {
                        char = body.charAt(indice)
                        indice++
                    }
                    value.start = index - 1
                    value.end = indice + 2
                    value.value = body.substring(index + 3, indice - 1)
                    values.push(value)
                }
            }
        setInserts(values)
    }

    const handleToggleSubConfig = (index) => {
        if (openRow === index) {
            setOpen(false);
            setOpenRow(null)
        }
        else {
            setOpen(true);
            setOpenRow(index)
        }
    }

    // useEffect(() => {
    //     if (typeof props.field.options.title === 'undefined' || props.field.options.title === "") {
    //         handleChangeTitle(title, props.field, props.index);
    //     }
    // }, [title]);

    useEffect(() => {
        if (typeof props.field.options.body === 'undefined' || props.field.options.body === "") {
            handleChangeBody(body, "", props.field, props.index);
        }
        updateInsertedValues()
    }, [body]);

    // useEffect(() => {
    //     if (typeof props.field.options.note === 'undefined' || props.field.options.note === "") {
    //         handleChangeTemplateRadio(note, props.field, props.index);
    //     }
    // }, [note]);

    useEffect(() => {
        if (typeof props.folderName !== "undefined") {
            if (title == "") {
                setTitle(props.folderName);
            }
            if (body == "") {
                setBody(t('common:mails.addto') + props.folderName + ".");
            }
        }
    }, [props.folderName]);

    const getElements = () => {
        let elements = []
        props.model.forEach((item, index) => {
            elements.push({ label: item.label, value: "field" + item.id, keyLabel: 'label-' + item.key, keyValue: 'value-' + item.key });
        })
        return elements
    }

    useEffect(() => {
        setElements([...getElements()]);
        let rows = (!isDefined(props.field.formatoptions.lines) || props.field.formatoptions?.lines.length === 0) ? valueRows : props.field.formatoptions.lines
        // Add pw line when missing
        if (rows.length > 0 && share) {
            if (rows[rows.length - 1].label !== 'PW-code') {
                let copyRows = [...rows, createData('PW-code', 'Texte', 'text', '40', null, 1000, [], null, null, false)]
                setDataRows(copyRows)
            }
        }
        // Remove pw line if no share
        if (rows.length > 0 && !share) {
            if (rows[rows.length - 1].label === 'PW-code') {
                let copyRows = rows.filter(row => row.label !== 'PW-code')
                setDataRows(copyRows)
            }
        }
    }, [props.field])

    // useEffect(() => {
    //     let model = [...props.model]
    //     model[props.index].formatoptions.lines = dataRows
    //     props.setModel(model)
    // }, [dataRows])

    useEffect(() => { updateInsertedValues() }, [])


    const handleToggleAutoJoinAtt = (value, index) => {
        let model = [...props.model]
        model[index].options.setup = {
            ...model[index].options.setup,
            autoAttachments: value,
        }
        props.setModel(model)
    }

    const handleToggleAutoJoinCopy = (value, index) => {
        let model = [...props.model]
        model[index].options.setup = {
            ...model[index].options.setup,
            autoCopy: value,
        }
        props.setModel(model)
    }

    const ExtdataTabComponent = (
        <div className={classes.tabCtn} >
            <div className={classes.subfield}>
                <FormatExtDataConstructorComponent
                    field={props.field}
                    handleEditLabel={props.handleEditLabel}
                    index={props.index}
                    setModel={props.setModel}
                    model={props.model}
                    update={props.update}
                />
                <TableContainer component={Paper} className={classes.tableCtn}>
                    <Table className={classes.table} style={{ width: '100%' }}>
                        <TableHead className={classes.tableHeader}  >
                            <TableRow>
                                <TableCell align="left" className={classes.headerTxt} colSpan={dataRows.length > 5 ? 9 : 4}>Field List</TableCell>
                            </TableRow>
                        </TableHead>
                        <div style={{ display: 'flex', flexDirection: 'row', }}>
                            <TableBody>
                                {dataRows.length > 0 &&
                                    <React.Fragment>
                                        {dataRows
                                            .slice(...(dataRows.length > 5 ? [0, Math.ceil(dataRows.length / 2)] : []))
                                            .map((row, index) => {
                                                let index2 = Math.ceil(index + (dataRows.length / 2))
                                                return (
                                                    <React.Fragment key={index}>
                                                        <TableRow >
                                                            <TableCell align="center">{index + 1}</TableCell>
                                                            <TableCell align="center">
                                                                <span>{row.label}</span>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <span>{row.formatLabel}</span>
                                                            </TableCell>
                                                            <TableCell className={classes.configCell}>
                                                                <RenderMaskList {...props}
                                                                    open={open}
                                                                    openRow={openRow}
                                                                    row={dataRows[index]}
                                                                    index={index}
                                                                    rows={rows}
                                                                    isPreview
                                                                    handleUpdateMask={handleUpdateMask}
                                                                    handleUpdateType={handleUpdateType}
                                                                    handleToggleSubConfig={handleToggleSubConfig}
                                                                />
                                                            </TableCell>
                                                            {dataRows.length > 5 &&
                                                                <TableCell colSpan={1} />
                                                            }
                                                            {(dataRows.length > 5 && isDefined(dataRows[index2])) ?
                                                                <React.Fragment>
                                                                    <TableCell align="center" className={classes.secondColCell} >{index2 + 1}</TableCell>
                                                                    <TableCell align="center">
                                                                        <span>{getColumnLabel(dataRows[index2].label)}</span>
                                                                    </TableCell>
                                                                    <TableCell align="center">
                                                                        <span>{dataRows[index2].formatLabel}</span>
                                                                    </TableCell>
                                                                    <TableCell className={classes.configCell}>
                                                                        <RenderMaskList {...props}
                                                                            open={open}
                                                                            openRow={openRow}
                                                                            row={dataRows[index2]}
                                                                            index={index2}
                                                                            rows={rows}
                                                                            isPreview
                                                                            handleUpdateMask={handleUpdateMask}
                                                                            handleUpdateType={handleUpdateType}
                                                                            handleToggleSubConfig={handleToggleSubConfig}
                                                                        />
                                                                    </TableCell>
                                                                </React.Fragment>
                                                                :
                                                                <TableCell colSpan={5} className={classes.secondColCell} />
                                                            }
                                                        </TableRow>
                                                    </React.Fragment>
                                                );
                                            })}
                                    </React.Fragment>
                                }
                            </TableBody >
                        </div>
                    </Table>
                </TableContainer>
            </div>
        </div>
    )

    const EmailTabComponent = (
        <div className={classes.tabCtn} >
            <div className={`${classes.spaceBetweenFlexCtn} ${classes.gradientBgnd}`}>
                <div className={classes.inputContainer}>
                    <InputLabel className={classes.margin} htmlFor={"from-" + props.field.key}>
                        {t('common:mails.destinatairefrom')}
                        <PlugTooltip classes={{ tooltip: classes.tooltipStyle }} title={t('common:smail.info-sender').split('\n').map((c, index) => {
                            return (<p key={'1' + index + '-' + c[0]}> {c} </p>)
                        })} placement="top">
                            <span className="material-icons" style={{ fontSize: 20, color: colors.mainButton }}>
                                info
                            </span>
                        </PlugTooltip>
                    </InputLabel>
                    <TextField
                        className={classes.input}
                        id={"from-" + props.field.key}
                        fullWidth={true}
                        placeholder="from"
                        InputProps={{ disableUnderline: true, }}
                        inputlabelprops={{
                            shrink: true,
                        }}
                        inputProps={{
                            fieldkey: "from-" + props.field.key,
                            className: classes.textField,
                        }}
                        margin="none"
                        onChange={(event) => handleChangeFrom(event.target.value, props.field, props.index)}
                        value={from}
                        variant="standard"
                    />
                </div>
                <div className={classes.inputContainer}>
                    <InputLabel className={classes.margin} htmlFor={"cc-" + props.field.key}>{t('common:mails.destinatairecc')}</InputLabel>
                    <TextField
                        id={"cc-" + props.field.key}
                        placeholder="cc"
                        fullWidth={true}
                        margin="none"
                        inputlabelprops={{
                            shrink: true,
                        }}
                        inputProps={{
                            className: classes.textField,
                            fieldkey: "cc-" + props.field.key,
                        }}
                        InputLabelProps={{
                            className: classes.margin,
                        }}
                        InputProps={{ disableUnderline: true, }}
                        className={classes.input}
                        onChange={(event) => handleChangeCc(event.target.value, props.field, props.index)}
                        value={cc}
                        variant="standard"
                    />
                </div>
                <div className={classes.inputContainer}>
                    <InputLabel className={classes.margin} htmlFor={"cc-" + props.field.key}>{t('common:mails.destinatairecci')}</InputLabel>
                    <TextField
                        id={"cci-" + props.field.key}
                        placeholder="cci"
                        fullWidth={true}
                        margin="none"
                        inputlabelprops={{
                            shrink: true,
                        }}
                        inputProps={{
                            className: classes.textField,
                            fieldkey: "cci-" + props.field.key,
                        }}
                        InputLabelProps={{
                            className: classes.margin,
                        }}
                        InputProps={{ disableUnderline: true, }}
                        className={classes.input}
                        onChange={(event) => handleChangeCci(event.target.value, props.field, props.index)}
                        value={cci}
                        variant="standard"
                    />
                </div>
            </div>
            <div className={classes.subfield}>
                <InputLabel className={classes.margin} htmlFor={"title-" + props.field.key}>{t('common:mails.sujet')}</InputLabel>
                <div className={classes.field}>
                    <Input
                        id={"title-" + props.field.key}
                        placeholder="title"
                        fullWidth={true}
                        margin="none"
                        disableUnderline={true}
                        inputlabelprops={{ shrink: true }}
                        inputProps={{
                            fieldkey: "title-" + props.field.key,
                            className: classes.textField,
                        }}
                        onSelect={updateSubjectSelectionStart}
                        inputRef={inputSubjectRef}
                        className="text-line-margin"
                        onChange={(event) => handleChangeTitle(event.target.value, props.field, props.index)}
                        value={title}
                    />
                </div>
                <InputLabel className={classes.margin} htmlFor={"body-" + props.field.key}>{t('common:mails.message')}</InputLabel>
                <div className={classes.field}>
                    <RichEditor
                        init={{
                            height: 500,
                            images_file_types: '',
                            menubar: true,
                            plugins: [
                                'advlist', 'anchor', 'autolink', 'image', 'link', 'lists',
                                'searchreplace', 'table', 'wordcount'
                            ],
                            // toolbar: 'undo redo | blocks | ' +
                            //     'bold italic forecolor | alignleft aligncenter ' +
                            //     'alignright alignjustify | bullist numlist outdent indent | ' +
                            //     'removeformat | help',
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                        onEditorChange={(value) => handleChangeBody(value, null, props.field, props.index)}
                        onInit={(evt, editor) => editorRef.current = editor}
                        value={body}
                    />
                    {/* <Input
                        id={"body-" + props.field.key}
                        placeholder="body"
                        fullWidth={true}
                        margin="none"
                        disableUnderline={true}
                        inputlabelprops={{ shrink: true }}
                        inputProps={{
                            fieldkey: "body-" + props.field.key,
                            className: classes.textFieldArea,
                        }}
                        onKeyDown={(e) => onKeyPress(e)}
                        onSelect={updateBodySelectionStart}
                        inputRef={inputBodyRef}
                        className="text-line-margin"
                        onChange={(e) => handleChangeBody(e.target.value, null, props.field, props.index)}
                        value={body}
                        multiline={true}
                        rows={15}
                    /> */}
                </div>
                <div id="smail-bottom-div" className={classes.targetDiv} />
            </div>
            <SMailSideMenu
                {...props}
                hasAuto={false}
                body={body}
                elements={elements}
                handleChangeBody={handleChangeBody}
                handleChangeShare={handleChangeShare}
                handleChangeTemplateRadio={handleChangeTemplateRadio}
                handleChangeTemplateSelect={handleChangeTemplateSelect}
                handleClose={() => setOpenSideMenu(false)}
                handleToggleAutoJoinAtt={handleToggleAutoJoinAtt}
                handleToggleAutoJoinCopy={handleToggleAutoJoinCopy}
                hasPw={true}
                template={template}
                note={note}
                auto={auto}
                autoAttachments={props?.field?.options?.setup?.autoAttachments}
                autoCopy={props?.field?.options?.setup?.autoCopy}
                share={share}
            />
        </div>
    )

    return (
        <div className={classes.mainField}>
            <FormatTableMultipleConstructorComponent
                columns={dataRows}
                emailTab={EmailTabComponent}
                extdataTab={ExtdataTabComponent}
                field={props.field}
                folderName={props.folderName}
                handleEditLabel={props.handleEditLabel}
                index={props.index}
                onChangeHandlerDrop={props.onChangeHandlerDrop}
                model={props.model}
                plugnoteCount={props.plugnoteCount}
                setModel={props.setModel}
                setDataRows={setDataRows}
                update={props.update}
            />
        </div>
    )
}

// redux providing state takeover
const mapStateToProps = (state) => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatSMailValidMultiConstructorComponent);
