import {call, fork, put, takeEvery} from 'redux-saga/effects';
import * as actions from '../actions/layout';
import * as api from '../api/layoutApi';
import {trackPromise} from "react-promise-tracker";

const myGenericTrackedWrapper = (fn, ...args) => trackPromise(fn(...args));
const myGenericTrackedWrapper2 = (fn, args1, args2) => trackPromise(fn(args1,args2));
const myGenericTrackedWrapper3 = (fn, args1, args2, args3) => trackPromise(fn(args1,args2, args3));
const myGenericTrackedWrapper4 = (fn, args1, args2, args3, args4) => trackPromise(fn(args1,args2, args3,args4));
const myGenericTrackedWrapper5 = (fn, args1, args2, args3, args4, args5) => trackPromise(fn(args1,args2, args3,args4, args5));

export function* fetchAllLayout(action) {
    try {
        const response = yield call(myGenericTrackedWrapper2,api.getAllLayoutsByModel,action.modelhash, action.userhash);
        if (response.data.result){
            yield put(actions.getAllLayoutsByModelSuccess(response.data.data));
        }else{
            yield put(actions.getAllLayoutsByModelFailure());
        }
    }catch (e) {
        yield put(actions.getAllLayoutsByModelError());

    }

}


export function* postLayout(action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.postLayoutByModel,action.payload);
        if (response.data.result){
            yield put(actions.postLayoutByModelSuccess());
            let payload = {
                modelhash : action.payload.mid,
                userhash: action.payload.uh
            }
            yield * fetchAllLayout(payload);
        }else{
            yield put(actions.postLayoutByModelFailure());
        }
    }catch(e) {
        yield put(actions.postLayoutByModelError());
    }
}


export function* fetchOneLayout(action) {
    try {
        const response = yield call(myGenericTrackedWrapper3,api.getOneLayoutByModel,action.modelhash, action.userhash, action.translateid);
        if (response.data.result){
            yield put(actions.getOneLayoutByModelSuccess(response.data.data));
        }else{
            yield put(actions.getOneLayoutByModelFailure());
        }
    }catch(e) {
        yield put(actions.getOneLayoutByModelError());
    }
}

export function* fetchDeleteOneLayout(action) {
    try {
        const response = yield call(myGenericTrackedWrapper3,api.deleteOneLayoutByModel,action.mid, action.userhash, action.id);
        if (response.data.result){
            yield put(actions.deleteDeleteLayoutByModelSuccess());
            let payload = {
                modelhash : action.mid,
                userhash: action.uh
            }
            yield * fetchAllLayout(payload);
        }else{
            yield put(actions.deleteDeleteLayoutByModelFailure());
        }
    }catch(e) {
        yield put(actions.deleteDeleteLayoutByModelError());
    }
}

function* watchFetchField() {
    // create watcher of fetchData function
    yield takeEvery(actions.Types.GET_ALL_LAYOUTS_BY_MODEL_REQUEST, fetchAllLayout);
    yield takeEvery(actions.Types.GET_ONE_LAYOUT_BY_MODEL_REQUEST, fetchOneLayout);
    yield takeEvery(actions.Types.POST_LAYOUT_BY_MODEL_REQUEST, postLayout);
    yield takeEvery(actions.Types.DELETE_DELETE_LAYOUT_BY_MODEL_REQUEST, fetchDeleteOneLayout);
}

const FieldSagas = [
    fork(watchFetchField)
];

export default FieldSagas;
