import React, { useState } from 'react'
import { FormControl, FormLabel, Input, Table, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import colors from '../../../assets/themes/Colors'
import EditTableOrientationComponent from '../../custom/buttons/EditTableOrientationComponent'
import { PlugTooltip } from '../../custom/CustomComponents'

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
  tableRoot: {
    marginRight: '20px',
  },
  headerCtn: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: colors.palePlugGreen,
    boxShadow: '3px 0px 6px 4px rgba(136, 136, 136, 0.12)',
    '& .MuiTableCell-root': {
      border: '0px solid !important',
    },
  },
  header: {
    height: 80,
    '& .MuiTableCell-root': {
      padding: '4px !important',
      textAlign: 'center',
      position: 'sticky',
    }
  },
  headers: {
    '& .MuiTableCell-root': {
      padding: '4px !important',
      textAlign: 'center',
    },
    marginTop: -2,
  },
  field: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(50vh)',
    overflowY: 'auto',
  },
  headerInputCtn: { fontSize: 13, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' },
}))

const RenderEditOtherOptionsComponent = (props) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const rowLimit = props.rowLimit

  return (
    <div className={classes.field}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className={classes.headerCtn} >
          <TableRow className={classes.header}>
            <TableCell colSpan={2} style={{ display: 'table-cell', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <PlugTooltip title={t('tablemulti.row-limit-info')}>
                  <FormControl>
                    <FormLabel id="row-limit-label">{t('tablemulti.row-limit')}</FormLabel>
                    <div className={classes.headerInputCtn}>
                      <div>
                        <span className="material-icons" style={{ fontSize: 20, marginRight: -8 }} >
                          height
                        </span>
                        <span className="material-icons" style={{ fontSize: 20 }} >
                          table_rows
                        </span>
                      </div>
                      <Input
                        autoComplete="off"
                        classes={{ input: classes.inputCenter }}
                        id="standard-full-width"
                        inputMode="numeric"
                        police="police"
                        inputlabelprops={{ shrink: true }}
                        onChange={props.handleChangeRowLimit}
                        style={{ width: '30%' }}
                        type="number"
                        value={rowLimit === 0 ? ' - ' : rowLimit}
                      />
                    </div>
                  </FormControl>
                </PlugTooltip>
              </div>
            </TableCell>
            <TableCell align="center" colSpan={2}>
            <div className={classes.field}>
                <FormLabel id="row-limit-label">{t('tablemulti.table-orientation')}</FormLabel>
                <EditTableOrientationComponent
                  size={24}
                  handleChangeOrientation={props.handleChangeOrientation}
                  orientation={props.field.options?.setup?.tableOrientation}
                />
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        {
          props.field?.options?.setup?.tableOrientation === 'horizontal' &&
          <TableRow>
            <TableCell style={{ whiteSpace: "break-spaces", fontSize: 20 }}>
              {t('tablemulti.orientation-warning')}
            </TableCell>
          </TableRow>
        }
      </Table>
    </div>
  )
}

export default RenderEditOtherOptionsComponent
