import React, {useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import {useTranslation} from "react-i18next";
import {postExtDataGenerateTableRequest, } from "../../actions/extdata";
import {trackPromise} from "react-promise-tracker";
import axios from "axios";
import ApplicationConfig from "../../config/ApplicationConfig";
import {getToken} from "../../utils/Common";
import {FormControl, InputAdornment, InputLabel, TextField, Tooltip} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { ExtDataSideMenu } from "../custom/ExtDataTable";
import colors from "../../assets/themes/Colors";
import { confirmAlert } from "react-confirm-alert";
import {ColorCheckbox} from "../custom/CustomComponents";
import { devLog } from "../../utils/utils";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    MuiInputBaseInput:{
        padding : 0
    },
    mainField : {
        display: 'flex',
        flexDirection : 'column'
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    inputType:{
        marginTop: '15px',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },    field : {
        width : '100%',
        display: 'flex',
    },
    subfield : {
        width : '95%',
    },
    inputField:{
        padding : '0px 0px 7px',
    },
    mainInputField:{
        margin: 20,
        marginTop: 0,
        width : '100%',
        display : 'grid'
    },
    alignedInput: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 10,
    },
    mainComponent:{
        marginLeft:  -50,
        marginRight: 10,
        alignSelf: "center",
        zIndex: 10,
    },
    tableRoot: {
        width: "100%", //it was 100%
        marginTop: theme.spacing(3),
        overflowX: "auto",
        //marginRight: 'auto',
    },
    inputLabel: {
        marginRight: -70,
    },
    inputMargin:{
        margin : '10px',
        minWidth: 200,
        alignSelf: 'flex-end',
        '& .MuiInputLabel-formControl': {
            width: '130%'
        }
    },
    selectMargin:{
        margin : '10px',
        minWidth: 200,
        height: 42,
        alignSelf: 'flex-end',
        display: 'flex',
        flexDirection: 'row',
    },
    inputLarge:{
        margin : '10px',
        minWidth: 300,
        alignSelf: 'flex-end'
    },
    tooltipStyle: {
        backgroundColor: colors.grey,
        color: colors.white,
        border: '1px solid',
        padding: '3px 8px',
        margin: 5,
        fontSize: '16px',
        borderRadius: 5
    },
    alertContainer: {
        padding: 20,
        position: 'relative',
        width: '60vw',
        height: '60vh',
        borderRadius: 20,
        boxShadow: '0px 0px 20px 20px rgb(0 0 0 / 14%), 0px 11px 15px rgb(0 0 0 / 20%)',
    },
    alertTitle: {
        display: 'flex',
        justifyContent: 'center',
        color: colors.darkSilver,
        fontWeight: 800,
        fontSize: 20,
        marginBottom: 10,
    },
    alertClose: {
        position: 'absolute',
        top: 15,
        right: 15,
        color: colors.frost,
    },
    alertCloseBtn: {
        position: 'absolute',
        bottom: 15,
        right: 15,
    },
    alertSubtitle: {
        color: colors.darkSilver,
        fontWeight: 600,
        fontSize: 17,
        marginLeft: 20,
        marginTop: 20,
    },
}));

const FormatExtDatanConstructorComponent = (props) => {
    const classes = useStyles();
    const { t } = useTranslation(['common', 'models']);
    const [newDataRef, setNewDataRef] = useState(props.field.options.ref !== ""  ? props.field.options.ref  : "");
    const [newDataQuery, setNewDataQuery] = useState(typeof props.field.options.query !== "undefined" &&props.field.options.query !== ""  ? props.field.options.query  : "LIKE");
    const [newDataColumns, setNewDataColumns] = useState(props.field.options.columns !== ""  ? props.field.options.columns  : "");
    const [newDataColumn, setNewDataColumn] = useState(props.field.options.column !== ""  ? props.field.options.column  : "");
    const [newDataPlugform, setNewDataPlugform] = useState(props.field.options.plugform !== ""  ? props.field.options.plugform  : "");
    const [newDataDest, setNewDataDest] = useState(props.field.options.dest !== ""  ? props.field.options.dest  : "");
    const [newDataSearch, setNewDataSearch] = useState(props.field.options.search !== undefined && props.field.options.search !== ""  ? props.field.options.search  : "")
    const [open, setOpen] = useState(false);
    const [preview, setPreview] = useState(null);
    const [record, setRecord] = useState("1");
    const [viewF28Search, setViewF28Search] = useState(props.field.options?.viewF28Search || false)

    const handlePreview = () => {
        callPreview();
    }

    const handleOpenPreview = () => {
        setOpen(!open);
    }

    const callPreview = () => {
        trackPromise(
            axios.post(ApplicationConfig.apiUrl + 'extdata/uniques/datas.json', {
                file : newDataRef,
                userhash : props.user.profil.hash,
                record : record
            },{crossdomain: true,
                'headers': {
                    'X-AUTH-TOKEN': getToken(),
                }
            }).then((response) => {
                setPreview(response.data.data);
            }).catch((response) => {
                console.error(
                    "Could not Download the Excel report from the backend.",
                    response
                );
            })
        )
    }

    const handleDataPlugform = (clef) => {
        setNewDataPlugform(clef);

        props.field.options.plugform = clef;
        let model = [...props.model];
        model[props.index].options.plugform = clef;
        props.setModel(model);
    }

    const handleDataColumns = (clef) => {
        setNewDataColumns(clef);

        props.field.options.columns = clef;
        let model = [...props.model];
        model[props.index].options.columns = clef;
        props.setModel(model);
    }

    const handleDataSearch = (clef) => {
        setNewDataSearch(clef);
        props.field.options.search = clef;
        let model = [...props.model];
        model[props.index].options.search = clef;
        props.setModel(model);
    }

    const handleDataColumn = (clef) => {
        setNewDataColumn(clef);
        props.field.options.column = clef;
        let model = [...props.model];
        model[props.index].options.column = clef;
        props.setModel(model);
    }

    const handleDataReference = (field) => {
        setNewDataRef(field);

        props.field.options.ref = field;
        let model = [...props.model];
        model[props.index].options.ref = field;
        props.setModel(model);
    }

    const handleDataDestination = (field) => {
        setNewDataDest(field);
        props.field.options.dest = field;
        let model = [...props.model];
        model[props.index].options.dest = field;
        props.setModel(model);
    }

    const handleToggleViewF28search = () => {
        let newVal = !viewF28Search
        setViewF28Search(!viewF28Search)
        props.field.options.viewF28Search = newVal
        let model = [...props.model];
        model[props.index].options.viewF28Search = newVal
        props.setModel(model);
    }

    const handleDataQuery = (clef) => {
        setNewDataQuery(clef);
        props.field.options.query = clef
        let model = [...props.model];
        model[props.index].options.query = clef;
        props.setModel(model);
    }

    const moreInfo = (type) => {
        switch (type) {
            case "multiple":
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                        <div className={classes.alertContainer}>
                            <span className={classes.alertTitle}>{t('common:ext-table.multi-search')}</span>
                            <span className={classes.alertClose}>
                                <span id="icon-close" class="material-icons md-30" onClick={() => onClose()}>
                                    close
                                </span>
                            </span>
                            <div className="sous-header_content lightgrey">
                                <div className="line flex_style"/>
                            </div>
                            <span className={classes.alertBody}>
                                <br/>
                                <span className={classes.alertSubtitle}>{t('ext-table.info-multi-t1', {"format": props.field.format === "extdata"? "S01" : "S02"})}</span>
                                    {t('ext-table.info-multi-1', {"format": props.field.format === "extdata"? "S01" : "S02"}).split('\n').map((c, index) => {
                                    return (<p key={'1' + index + '-' + c[0]}> {c} </p>)})}
                                    <br/>
                                <span className={classes.alertSubtitle}>{t('ext-table.info-multi-t2')}</span>
                                    {t('ext-table.info-multi-2').split('\n').map((c, index) => {
                                    return (<p key={'2' + index + '-' + c[0]}> {c} </p>)})}
                            </span>
                            <span className={classes.alertCloseBtn}>
                                <button
                                    class="button"
                                    onClick={() => onClose()}>
                                    {t('common:global.ok')}
                                </button>
                            </span>
                        </div>
                        );
                    },
                    // title: t('common:ext-table.multi-search'),
                    // message: t('common:formats.pluglink-configurator-not-compatible-format'),
                    // buttons: [{label: t('common:global.ok'),},]
                });
                break;
            case "special":
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                        <div className={classes.alertContainer}>
                            <span className={classes.alertTitle}>{t('common:ext-table.special-fields')}</span>
                            <span className={classes.alertClose}>
                                <span id="icon-close" class="material-icons md-30" onClick={() => onClose()}>
                                    close
                                </span>
                            </span>
                            <div className="sous-header_content lightgrey">
                                <div className="line flex_style"/>
                            </div>
                            <span className={classes.alertBody}>
                                <br/>
                                <span className={classes.alertSubtitle}>{t('ext-table.special-localisation')}</span>
                                    {t('ext-table.info-special-localisation').split('\n').map((c, index) => {
                                    return (<p key={'1' + index + '-' + c[0]}> {c} </p>)})}
                                    <br/>
                                <span className={classes.alertSubtitle}>{t('ext-table.special-phone')}</span>
                                    {t('ext-table.info-special-phone').split('\n').map((c, index) => {
                                    return (<p key={'2' + index + '-' + c[0]}> {c} </p>)})}
                            </span>
                            <span className={classes.alertCloseBtn}>
                                <button
                                    class="button"
                                    onClick={() => onClose()}>
                                    {t('common:global.ok')}
                                </button>
                            </span>
                        </div>
                        );
                    }});
                break;
            default:
                break;
        }
    }

    // devLog('extdata/n constructor props', props);

    return (
        <div className={classes.field}>
            <div className={classes.mainInputField}>
                <div className={classes.alignedInput}>
                    <FormControl variant="standard" className={classes.inputMargin}>
                        <InputLabel htmlFor={props.field.format.toLowerCase() + '-file'} className={classes.inputLabel} shrink={true}>{t('ext-table.search-table')}</InputLabel>
                        <InputLabel className={classes.sideInstructions}>
                        </InputLabel>
                        <Input
                            endAdornment={newDataRef !== "" && newDataRef !== undefined && newDataRef !== null ?
                                <span className="margin-left-auto cursor" onClick={(event) => handleOpenPreview()}>
                                    <Tooltip classes={{ tooltip: classes.tooltipStyle }} title={open ? t('common:ext-table.info-close') : t('common:ext-table.info-check')} placement="bottom">
                                        <span id="icon-close" className="material-icons md-25 black">
                                            {open ? "visibility_off" : "visibility"}
                                        </span>
                                    </Tooltip>
                                </span>
                                :
                                <InputAdornment position="end">
                                    <Tooltip classes={{ tooltip: classes.tooltipStyle }} title={t('common:ext-table.info-table')} placement="bottom">
                                        <span className="material-icons" style={{ fontSize: 20, color: colors.mainButton }}>
                                            info
                                        </span>
                                    </Tooltip>
                                </InputAdornment>}
                            id={props.field.format.toLowerCase() + '-file'}
                            label="Label"
                            fullWidth={true}
                            margin="none"
                            inputlabelprops={{
                                shrink: true,
                            }}
                            inputProps={{
                                fieldkey: props.field.key,
                                className: classes.inputField,
                                maxLength: 40
                            }}
                            onChange={(event) => handleDataReference(event.target.value)}
                            defaultValue={newDataRef}
                        />
                    </FormControl>

                    <FormControl className={classes.selectMargin}>
                        <InputLabel id="demo-simple-select-label" className={classes.inputLabel} shrink={true}>{t('ext-table.criterion')} {props.field.id}</InputLabel>
                        <Select
                            disabled={newDataRef === ''}
                            endAdornment={
                                <InputAdornment position="end">
                                    <Tooltip classes={{ tooltip: classes.tooltipStyle }} title={t('common:ext-table.info-criterion').split('\n').map((c, index) => {
                                        return (<p key={'1' + index + '-' + c[0]}> {c} </p>)
                                    })} placement="bottom">
                                        <span className="material-icons" style={{ fontSize: 20, color: colors.mainButton, zIndex: 1, marginLeft: -45 }}>
                                            info
                                        </span>
                                    </Tooltip>
                                </InputAdornment>}
                            fullWidth={true}
                            margin="none"
                            inputlabelprops={{ shrink: true, }}
                            key="uniquevalue"
                            displayEmpty
                            id="demo-simple-select-label"
                            value={newDataQuery}
                            onChange={(event) => handleDataQuery(event.target.value)}
                        >
                            <MenuItem value='LIKE'>1— {t('mysql.like')} (= xx)</MenuItem>
                            <MenuItem value='CONTAINS'>2— {t('mysql.contains')} (..xx..)</MenuItem>
                            <MenuItem value='START'>3— {t('mysql.start')} (xx..)</MenuItem>
                            <MenuItem value='MULTIPLE'>4— {t('mysql.multiple')} (xxx)</MenuItem>
                        </Select>
                    </FormControl>

                    {
                        props.field.format === 'tablemultin' &&
                        <FormControl variant="standard" className={classes.inputMargin}>
                            {/* <InputLabel htmlFor={props.field.format.toLowerCase()+'-search'}>{t('ext-table.default-search')}</InputLabel> */}
                            <TextField
                                defaultValue={newDataSearch}
                                disabled={newDataRef === ''}
                                InputProps={{
                                    endAdornment:
                                        <InputAdornment position="end">
                                            <Tooltip classes={{ tooltip: classes.tooltipStyle }} title={t('common:ext-table.info-default')} placement="bottom">
                                                <span className="material-icons" style={{ fontSize: 20, color: colors.mainButton }}>
                                                    info
                                                </span>
                                            </Tooltip>
                                        </InputAdornment>
                                }}
                                id={props.field.format.toLowerCase() + '-search'}
                                fullWidth={true}
                                InputLabelProps={{ shrink: true }}
                                inputProps={{
                                    fieldkey: props.field.key,
                                    className: classes.inputField,
                                }}
                                label={t('ext-table.default-search')}
                                margin="none"
                                onChange={(event) => handleDataSearch(event.target.value)}
                            />
                        </FormControl>
                    }

                    <FormControl variant="standard" className={classes.inputMargin}>
                        <InputLabel htmlFor={props.field.format.toLowerCase() + '-colonne'}>{t('ext-table.search-col')}</InputLabel>
                        <Input
                            defaultValue={newDataColumn}
                            disabled={newDataRef === ''}
                            endAdornment={
                                <InputAdornment position="end">
                                    {newDataQuery === "MULTIPLE" &&
                                        <Tooltip classes={{ tooltip: classes.tooltipStyle }} title={t('common:ext-table.info-more')} placement="bottom">
                                            <span className="material-icons" style={{ fontSize: 20, color: colors.red }} onClick={() => moreInfo("multiple")}>
                                                info
                                            </span>
                                        </Tooltip>}
                                    <Tooltip classes={{ tooltip: classes.tooltipStyle }} title={t('common:ext-table.info-search')} placement="bottom">
                                        <span className="material-icons" style={{ fontSize: 20, color: colors.mainButton }}>
                                            info
                                        </span>
                                    </Tooltip>
                                </InputAdornment>}
                            id={props.field.format.toLowerCase() + '-colonne'}
                            fullWidth={true}
                            inputlabelprops={{ shrink: true }}
                            inputProps={{
                                fieldkey: props.field.key,
                                className: classes.inputField,
                            }}
                            label="Label"
                            margin="none"
                            onChange={(event) => handleDataColumn(event.target.value)}
                        />
                    </FormControl>

                    <FormControl variant="standard" className={classes.inputMargin}>
                        <InputLabel htmlFor={props.field.format.toLowerCase() + '-colonne'} className={classes.inputLabel} shrink={true}>{t('ext-table.displayed-col')}</InputLabel>
                        <Input
                            disabled={newDataRef === ''}
                            endAdornment={
                                <InputAdornment position="end">
                                    <Tooltip classes={{ tooltip: classes.tooltipStyle }} title={t('common:ext-table.info-results').split('\n').map((c, index) => {
                                        return (<p key={'1' + index + '-' + c[0]}> {c} </p>)
                                    })} placement="bottom">
                                        <span className="material-icons" style={{ fontSize: 20, color: colors.mainButton }}>
                                            info
                                        </span>
                                    </Tooltip>
                                </InputAdornment>}
                            id={props.field.format.toLowerCase() + '-colonnes'}
                            label="Label"
                            fullWidth={true}
                            margin="none"
                            inputlabelprops={{ shrink: true, }}
                            inputProps={{
                                fieldkey: props.field.key,
                                className: classes.inputField,
                            }}
                            onChange={(event) => handleDataColumns(event.target.value)}
                            defaultValue={newDataColumns}
                        />
                    </FormControl>

                    {
                        props.field.format === "extdatan" &&
                        <FormControl variant="standard" className={classes.inputMargin}>
                            <InputLabel htmlFor={props.field.format.toLowerCase() + '-plugform'} className={classes.inputLabel} shrink={true}>{t('fields.format-choice-placeholder-' + props.field.format.toLowerCase() + '-plugform')}</InputLabel>
                            <Input
                                disabled={newDataRef === ''}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <Tooltip classes={{ tooltip: classes.tooltipStyle }} title={t('common:ext-table.info-s02')} placement="bottom">
                                            <span className="material-icons" style={{ fontSize: 20, color: colors.mainButton }}>
                                                info
                                            </span>
                                        </Tooltip>

                                    </InputAdornment>}
                                id={props.field.format.toLowerCase() + '-plugform'}
                                label="Label"
                                placeholder={t('fields.format-choice-placeholder-' + props.field.format.toLowerCase() + '-plugform')}
                                fullWidth={true}
                                margin="none"
                                inputlabelprops={{ shrink: true }}
                                inputProps={
                                    {
                                        fieldkey: props.field.key,
                                        className: classes.inputField,
                                    }
                                }
                                onChange={(event) => handleDataPlugform(event.target.value)}
                                defaultValue={newDataPlugform}
                            />
                        </FormControl>
                    }

                    <FormControl variant="standard" className={classes.inputLarge}>
                        <InputLabel htmlFor={props.field.format.toLowerCase() + '-dest'} className={classes.inputLabel} shrink={true}>{t('ext-table.autocomplete-rules', { "id": props.field.id })}</InputLabel>
                        <Input
                            endAdornment={
                                <InputAdornment position="end">
                                    {newDataQuery !== "" &&
                                        <Tooltip classes={{ tooltip: classes.tooltipStyle }} title={t('common:ext-table.info-special')} placement="bottom">
                                            <span className="material-icons" style={{ fontSize: 20, color: colors.red }} onClick={() => moreInfo("special")}>
                                                info
                                            </span>
                                        </Tooltip>}
                                    <Tooltip
                                        classes={{ tooltip: classes.tooltipStyle }}
                                        placement="bottom"
                                        title={t('common:ext-table.info-complete').split('\n').map((c, index) => {
                                            return (<p key={'1' + index + '-' + c[0]}> {c} </p>)
                                        })}
                                    >
                                        <span className="material-icons" style={{ fontSize: 20, color: colors.mainButton }}>
                                            info
                                        </span>
                                    </Tooltip>
                                </InputAdornment>}
                            id={props.field.format.toLowerCase() + '-dest'}
                            label="Label"
                            fullWidth={true}
                            multiline={true}
                            margin="none"
                            inputlabelprops={{
                                shrink: true,
                            }}
                            inputProps={{
                                fieldkey: props.field.key,
                                className: classes.inputField,
                            }}
                            onChange={(event) => handleDataDestination(event.target.value)}
                            defaultValue={newDataDest}
                        />
                    </FormControl>

                    {
                        props.field.format === 'extdata' &&
                        <div className={classes.inputMargin} style={{ display: 'inline-flex', cursor: 'pointer', }}
                            onClick={handleToggleViewF28search}>
                            <ColorCheckbox checked={viewF28Search} />
                            <div>{t('common:ext-table.view-search-f28')}</div>
                            <Tooltip classes={{ tooltip: classes.tooltipStyle }} title={t('common:ext-table.view-search-f28')}
                                placement="bottom">
                                <span className="material-icons" style={{ fontSize: 20, color: colors.mainButton }}>
                                    info
                                </span>
                            </Tooltip>
                        </div>
                    }

                </div>
            </div>
            {
                open &&
                <ExtDataSideMenu
                    {...props}
                    extTable={newDataRef}
                    fieldId={props.field.id}
                    fieldLabel={props.field.label}
                    handleClose={() => setOpen(false)}
                    handlePreview={handlePreview}
                    preview={preview}
                    setRecord={setRecord}
                    record={record}
                />
            }
        </div>
    )
};

// redux providing state takeover
const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptGenerateTableByClient: (payload) => dispatch(postExtDataGenerateTableRequest(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatExtDatanConstructorComponent);





