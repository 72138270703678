import React from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { ColorCheckbox, PlugTooltip } from '../../custom/CustomComponents'
import colors from '../../../assets/themes/Colors'
import Images from '../../../assets/themes/Images'
import { isDefined } from '../../../utils/utils'
import RichTextArea from '../../custom/rich-editor/RichTextArea'

const useStyles = makeStyles(() => ({
  popup: {
    backgroundColor: 'white',
    margin: 'auto',
    minHeight: '300px',
    border: '1px solid #DEDEDE',
    boxSizing: 'border-box',
    boxShadow: '0px 0px 20px 10px rgba(0, 0, 0, 0.14), 0px 4px 10px 0px rgba(0, 0, 0, 0.2)',
    borderRadius: 10,
    width: '80%',
    padding: '16px 32px',
  },
  richCtn: {
    '& div .tox-tinymce': {
      border: 'none !important',
    },
    '& div .tox:not(.tox-tinymce-inline) .tox-editor-header': {
      boxShadow: 'none !important',
    },
  },
  logoCtn: {
    width: 'auto',
    maxHeight: 100,
    zIndex: 99,
    position: 'relative',
  },
  endAdornment: {
    padding: '0 12px',
    fontWeight: 'bold',
    color: colors.bloodOrange,
  },
}))

const PlugcodeSendingSecondPreviewComponent = ({ secondText, hasLogo }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')

  const logo = sessionStorage.getItem('logo')
  const logoSrc = isDefined(logo) ? logo : Images.logoPng

  return (
    <div className={classes.popup}>
      <div className="scrollbar-hidden popup_wrapper flexSpacebetween" >
        <div className="">
          {
            hasLogo &&
            <div className={classes.logoCtn} >
              <img src={logoSrc} alt="second-logo" style={{ maxHeight: 100 }}/>
            </div>
          }
          <div className={classes.richCtn} >
            <RichTextArea
              init={{
                height: 500,
                menubar: false,
                selector: 'textarea',
                content_style: 'body { font-family: Helvetica, Arial, sans-serif; font-size:14px }'
              }}
              // onInit={(evt, editor) => editorRef.current = editor}
              disabled
              value={secondText}
            />
          </div>
          <br />
          <br />
          <button className="button" >{t('global.ok')}</button>
        </div>
      </div>
    </div>
  )
}

export default PlugcodeSendingSecondPreviewComponent
