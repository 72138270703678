import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import {useTranslation} from "react-i18next";
import {Add, Close, Delete, Search} from "@material-ui/icons";

import colors from "../../assets/themes/Colors";
import {isNull} from "lodash";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%',
        border: '3px solid green',
    },
    input_add_item:{
        flexDirection: 'row',
        height:42,
        display: 'flex',
        border: "solid #E3E3E3 1px",
        borderRadius: "6px",
        width: "100%",
        padding: "2px 5px 2px 15px",
    },
    margin_left_5:{
        marginLeft : '5px',
        //border: '3px solid green',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    textField: {
        fontWeight: '500',
        borderRadius: '5px',
        padding: '0 10px',
        backgroundColor: colors.greyLighter,
        fontSize: '14px',
        flexGrow: 1,
        border: 'none',
        // marginLeft: 10,
    },
    singleAdd: {
        height: '100%',
        width: '35%',
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        // marginTop: 10,
    },
    singleAddButton: {
        width : 20,
        height : 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid',
        borderColor: colors.main,
        color: colors.main,
        borderRadius: 50,
        fontSize: 16,
    },
    singleItemContainer: {
        flexDirection: 'row',
        // maxHeight: '300px',
        // width: '100%',
        display: 'flex',
        borderRadius: "6px",
        padding: "10px 15px",
        //marginTop: 10,
        flexWrap: 'wrap',
        gap: 5,
        alignItems: 'flex-start',
    },
    selectedItemContainer: {
        flexDirection: 'row',
        display: 'flex',
        borderRadius: "6px",
        flexWrap: 'wrap',
        gap: 5,
        alignItems: 'flex-start',
    },
    endButtonContainer:{
        cursor: 'pointer',
        maxHeight: '31px',
        padding: '3px 14px',
        borderRadius: "3px",
        backgroundColor: colors.main,
        alignItems: 'center',
        display: 'inline-block',
        color: 'white',
    },
    mainContainer:{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: ''
    },
    itemContainer: {
        width: 'inherit',
        minHeight: '40px',
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        flexWrap: 'wrap',
        borderRadius: "6px",
        alignItems: 'flex-start',
    },
    singleItem: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "6px",
        backgroundColor: colors.selectedAlpha,
        color: colors.white,
        padding: "0 10px 1px",
        minHeight: 42,
        gap: 5,
    },
    unselectedItem: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '42px',
        borderRadius: "6px",
        backgroundColor: colors.unselectedAlpha,
        color: colors.white,
        padding: "0 10px 1px",
        gap: 5,
    },
    singleInput: {
        //border: '1px solid red',
        textAlign: 'center',
        padding: 5,
        margin: 0,
        // fontWeight: '900',
        // color: colors.white,
    },
    singleDelButton: {
        width : 22,
        height : 18,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 50,
        fontSize: '28px',
    },
    singleOutlinedButton: {
        // width : 20,
        height : 22,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '2px solid',
        // color: colors.white,
        borderRadius: 50,
        fontSize: '15px',
    },
    singleItemSearchContainer: {
        flexDirection: 'row',
        width: '100%',
        display: 'flex',
        border: '1px solid #E3E3E3',
        borderRadius: "6px",
        padding: "10px 15px",
        marginTop: 10,
        gap: 5,
        alignItems: 'center',
    },
    singleItemSearchContainerOpen: {
        flexDirection: 'row',
        width: '100%',
        display: 'flex',
        border: '1px solid #E3E3E3',
        borderRadius: "6px",
        padding: "10px 15px",
        marginTop: 10,
        flexWrap: 'wrap',
        gap: 5,
        alignItems: 'flex-start',
    },
    singlePresetText: {
        color: colors.grey,
        fontSize: 14,
        whiteSpace: 'nowrap',
    },
    singleItemPresetOpen: {
        verticalAlign: 'middle',
        marginBottom: '2px',
        marginLeft: '2px'
    },
    singleSearchButton: {
        color: colors.main,
    },
    singleCleanButton: {
        width : 20,
        height : 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid',
        borderColor: colors.greyDark,
        color: colors.greyDark,
        borderRadius: 50,
    },
    singleCheckBoxGroup: {
        //color: colors.main,
        display: 'flex',
        alignItems: 'center',
        gap: 5,

    },
    singleCheckBox: {
        color: colors.main,
        width: 15,
        height: 15,
    },
}));


const FormatSingleSelectComponent = (props) => {

    const classes = useStyles();
    const { t, i18n } = useTranslation(['common']);

    const [selected, setSelected] = useState(props.field.value.length > 0 ? props.field.value[0] : "");
    const [newValue, setNewValue] = useState("");
    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [open, setOpen] = useState(props.field.visualDefault ? props.field.visualDefault : false);
    const [value, setValue] = useState('');
    const [setupbis, setSetubis] = useState(props.field?.options?.setupbis);

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    useEffect(() => {
        setSelected(props.field.value.length > 0 ? props.field.value[0] : "");
    },[props.field.value])


    const presels = [...props.field.options.presel]
    function isInPresel(value) {
        if(props.field.originalPresel !== undefined) return props.field.originalPresel.includes(value); else return false
    }
    const handleAddValue = (value) => {
        if (value.trim().length !== 0 && !presels.includes(value)) {
            props.field.options.presel.push(value);
            presels.push(value)
            props.handleChangeRadio(value, props.field.options.presel.length - 1,props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
            setSelected(value);
        }
        setNewValue('');
        setOpen(false)
    }

    const handleRemoveValue = (value) => {
        let index = presels.indexOf(value);
        if (index !== -1) {
            presels.splice(index, 1);
            let indice = props.field.options.presel.indexOf(value);
            if (indice !== -1) {
                props.field.options.presel.splice(indice, 1);
                props.handleChangeRadio(value, indice, props.field,props.index, props.field.options.multiselect, props.field.options.mandatory);
            }
        }
    }
    const setItem = (item, indexation) => {
        if(selected.includes(item)){
            setSelected("");
            setOpen(true);

            if(!isInPresel(item)){
                handleRemoveValue(item)
            }else{
                props.handleChangeRadio(item, indexation,props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
            }
        }
        else {
            setSelected(item)
            setOpen(false);
            props.handleChangeRadio(item, indexation,props.field,props.index,props.field.options.multiselect, props.field.options.mandatory);
        }
    }

    return (
        <div className={classes.mainContainer}>
            <div className={classes.itemContainer} onClick={(e) => {e.stopPropagation(); e.preventDefault() ; setOpen(!open)}}>
                {!open?
                    selected !== ""? <div className={classes.singleItem} onClick={() => {if (!disabled) setItem(selected, 0)} }>
                            <span className={ classes.singleInput}>{selected}</span>
                            <div onClick={() => {setOpen(true)}}>
                                {
                                    !disabled && (isInPresel(selected)?
                                        <span className={`${classes.singleDelButton} material-icons`}>
                                            highlight_off
                                        </span>
                                        :
                                        <span className={`${classes.singleOutlinedButton} material-icons`}>
                                            delete
                                        </span>)
                                }
                            </div>
                        </div>
                        : null :

                    <div>
                        {props.field.options.presel.length > 10? // filtre apparaît si plus de 10 items
                        (<Input
                            id={`standard-full-width-search-${props.field.id}`}
                            label=""
                            fullWidth={true}
                            margin="none"
                            disableUnderline={true}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Search className={classes.singleSearchButton}/>
                                </InputAdornment>}
                            endAdornment={ value.length > 0 &&
                            <InputAdornment position="end" onClick={(event) => setValue("")} style={{cursor : 'pointer'}}>
                                <Close className={classes.singleCleanButton}/>
                            </InputAdornment>
                            }
                            inputlabelprops={{
                                className: classes.input,
                            }}
                            className={classes.textField}
                            onClick={(e) =>  {e.stopPropagation(); e.preventDefault()}}
                            onChange={(event) => setValue(event.target.value)}
                            value={value}
                        />) : null}
                        <div className={classes.selectedItemContainer}>
                            {props.field.options.editable && !disabled &&
                            <div className={classes.singleAdd} onClick={(e) => {e.stopPropagation(); e.preventDefault() }}>
                                <div className={classes.input_add_item}>
                                    <Input
                                        disabled={disabled}
                                        autoComplete='off'
                                        id="standard-full-width"
                                        label="Label"
                                        style={{ margin: 0 }}
                                        placeholder={t('common:multiple.add-item')}
                                        helpertext="Full width!"
                                        fullWidth={false}
                                        margin="none"
                                        disableUnderline={true}
                                        inputlabelprops={{
                                            shrink: true,
                                        }}
                                        value={newValue}
                                        onBlur={() => handleAddValue(newValue.trim(), props.field, props.index)}
                                        onKeyPress={(e) => { if (e.key === 'Enter' && newValue.trim() !== "") handleAddValue(newValue.trim(), props.field, props.index)}}
                                        onChange={(event) => setNewValue(event.target.value)}
                                    />
                                </div>
                            </div>}

                            {open && selected !== "" ? //selected appears first
                            <div className={classes.singleItem} onClick={(e) => {e.stopPropagation(); e.preventDefault(); if (!disabled) setItem(selected, 0)} }>
                                <span className={ classes.singleInput}>{selected}</span>
                                <div onClick={() => {setOpen(true)}}>
                                    {
                                     !disabled && isInPresel(selected)?
                                        <span className={`${classes.singleDelButton} material-icons`}>
                                            highlight_off
                                        </span>
                                        :
                                        <span className={`${classes.singleOutlinedButton} material-icons`}>
                                            delete
                                        </span>
                                    }
                                </div>
                            </div>
                            : null}
                            {typeof props.field.options.presel !== 'undefined' && props.field.format.includes('select') && props.field.format.includes('single') && presels.filter((item, index) => {
                                let visible = true;

                                if(!isNull(setupbis) && typeof setupbis !== "undefined" && (props?.shared && setupbis[index].visible === false)){
                                    visible = false;
                                }

                                if(visible){
                                    if (!value) return true
                                    if (item.toLowerCase().includes(value.toLowerCase())) {
                                        return true
                                    }
                                }
                            }).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())).map((item, indexage) => { if (item !== selected)
                                return(
                                    <div key= {'divsingleitem' + props.field.id + '-' + indexage} className={item===selected? classes.singleItem : classes.unselectedItem} onClick={(e)=>{e.stopPropagation(); e.preventDefault()}}>
                                        <span className={ classes.singleInput} onClick={(e) =>  {e.stopPropagation(); if (!disabled) setItem(item,indexage)}}>{item}</span>
                                        {
                                          !disabled && (!isInPresel(item) &&
                                            <span className={`${classes.singleOutlinedButton} material-icons`} onClick={(e)=> {e.stopPropagation(); if (!disabled) handleRemoveValue(item)}}>
                                                delete
                                            </span>)
                                        }
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                }
            </div>
            <div className={classes.endButtonContainer} onClick={(event) => setOpen(!open)}>
                    <span id="icon-delete-playlist" className={`${classes.singleItemPresetOpen} material-icons md-small`}>
                                    {open? 'expand_less' : props.field.options.editable? 'playlist_add':'playlist_add_check'}
                    </span>
            </div>
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatSingleSelectComponent);
