import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {connect} from "react-redux";
import {getCurrentEnvironnement, setCurrentEnvironnement} from "../../utils/Common";
import TextField from "@material-ui/core/TextField";
import '@contentful/forma-36-react-components/dist/styles.css';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import _, {isString} from "lodash";
import moment from "moment";
import {postPlugcodeGalleryRequest} from "../../actions/gallery";
import {getModelsRequest} from "../../actions/model";
import {getFieldsRequest} from "../../actions/field";
import {postClassifyInItsPlugformRequest} from "../../actions/plugcode";
import {isCompatible, isLabelCompatible} from "../../utils/Compatibility";
import {history} from "../../hooks/history";

function getModalStyle() {
    const top = 5;

    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        width: '820px',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    marginLeft:{
        marginLeft : '10px'
    }
}));

const ModalCopyOrSavePlugcodeComponent = (props) => {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [t, i18n] = useTranslation('common');

    const plugform = props.plugform;
    const plugnote = props.plugnote;

    const environment = getCurrentEnvironnement();

    const sortedListEnvironnement = props.sortedListEnvironnement;

    const [choice, setChoice] = useState("save");
    const [title, setTitle] = useState(t("common:plugcode.choice-save-plugcode-title"));
    const [comment, setComment] = useState("");
    const [buttonTitle, setButtonTitle] = useState(t("common:plugcode.choice-save-plugcode-button"));
    const [hash, setHash] = React.useState(environment.userhash);

    const [models, setModels] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleCloseModal = () => {
        props.close();
    };

    const handleChangeTypeSelect = (value) => {
        setChoice(value);
    };

    const handleSavePlugcode = () => {
        let gallery = {
            plugcode : getCodePnPpCodeTemplate(),
            comment : comment,
        };
        props.attemptCreateGalleryRequest(gallery, props.user.profil.hash);
        props.close();
    };

    const handleCopyPlugcodeAndForm = () => {
        props.attemptClassifyInItsPlugform(plugnote, plugnote.hash, environment.userhash);
    };

    const handleSelectFormToTransfert = (model) => {
        history.push({
            pathname: '/plugcode-copy',
            state: { model: model, plugform : plugform, plugnote : plugnote }
        });
    };

    const handleChangeEnvironnement = (event) => {
        const value = event.target.value;
        let newEnvironnement = sortedListEnvironnement.find((element, index) => {
            return element.userhash === value;
        })

        setCurrentEnvironnement(newEnvironnement);
        //setModels([]);
        setHash(newEnvironnement.userhash);
        setLoading(true);
    }

    const getCodePnPpCodeTemplate = () => {
        const {hash, idOffline, userindex, creationdate, codepncode, publicpncode, modname, modclef} = plugnote
        //file-download
        let codePn= 'PN';

        switch (publicpncode) {
            case 0:
                codePn = 'PP';
                break;
            case 1:
                codePn = 'PN';
                break;
            case 2:
                codePn = 'PE';
                break;
            case 3:
                codePn = 'PS';
                break;
            default:
                break;
        }
        return codepncode ? `${codePn}-${moment(creationdate).format('YYMMDD')}-${codepncode}` : "";
    };

    const sortModels = () => {
        const { models } = props;
        const regex = /^[a-zA-Z ]/;

        let sortedModels = [ ...models ];

        if (models && models.length > 0) {

            let labelsModelFromPlugcode = Object.keys(plugform).filter((key) => {
                const regex = /field[0-9]+label/gi;
                return regex.test(key);
            });
            let fieldsModelFromPlugcode = labelsModelFromPlugcode
                .filter((item) => plugform[item] != null)
                .map((item, index) => {
                    const fieldIndex = item.replace('label', '').replace('field', '')
                    let options = [];
                    let format = plugform[item.replace('label', 'format')]

                    if (plugform[`field${fieldIndex}formatoptions`]) {
                        options = plugform[`field${fieldIndex}formatoptions`];
                        if (typeof options === 'string') {
                            options = JSON.parse(plugform[`field${fieldIndex}formatoptions`]);
                        }
                    }

                    // Vérifier les préselections
                    if (options.presel) {
                        if(options.multiselect && !options.editable){
                            format = "multi-select";
                        }else if(!options.multiselect && !options.editable){
                            format = "single-select";

                        }else if(options.multiselect && options.editable){
                            format = "free-multi-select";

                        }else if(!options.multiselect && options.editable){
                            format = "free-single-select";
                        }
                        // switch (options.multiselect) {
                        //   case true :
                        //     if (options.editable) {
                        //       format = "free-multi-select";
                        //     } else {
                        //       format = "multi-select";
                        //     }
                        //     break;
                        //   default:
                        //     if (options.editable) {
                        //       format = "free-single-select";
                        //     } else {
                        //       format = "single-select";
                        //     }
                        // }
                        //options = options.presel.split(";");
                        options = (options && options.presel && isString(options.presel)) ? options.presel.split(";") : [];
                    }

                    return {
                        label: plugform[item],
                        format,
                        options,
                        id: index + 1
                    }
                });

            sortedModels = sortedModels.sort((item1, item2) => {
                return item1.label.toLowerCase() < item2.label.toLowerCase() ? -1 : 1;
            });
            sortedModels = sortedModels.map((value, index) => {
                let sign = '';
                let labels = Object.keys(value).filter((key) => {
                    const regex = /field[0-9]+label/gi;
                    return regex.test(key);
                });
                let fields = labels
                    .filter((item) => value[item] != null)
                    .map((item, index) => {
                        const fieldIndex = item.replace('label', '').replace('field', '')
                        let options = [];
                        let format = value[item.replace('label', 'format')]

                        if (value[`field${fieldIndex}formatoptions`]) {
                            options = value[`field${fieldIndex}formatoptions`];
                            if (typeof options === 'string') {
                                options = JSON.parse(value[`field${fieldIndex}formatoptions`]);
                            }
                        }

                        // Vérifier les préselections
                        if (options.presel) {
                            if(options.multiselect && !options.editable){
                                format = "multi-select";
                            }else if(!options.multiselect && !options.editable){
                                format = "single-select";

                            }else if(options.multiselect && options.editable){
                                format = "free-multi-select";

                            }else if(!options.multiselect && options.editable){
                                format = "free-single-select";
                            }
                            // switch (options.multiselect) {
                            //   case true :
                            //     if (options.editable) {
                            //       format = "free-multi-select";
                            //     } else {
                            //       format = "multi-select";
                            //     }
                            //     break;
                            //   default:
                            //     if (options.editable) {
                            //       format = "free-single-select";
                            //     } else {
                            //       format = "single-select";
                            //     }
                            // }
                            //options = options.presel.split(";");
                            options = (options && options.presel && isString(options.presel)) ? options.presel.split(";") : [];
                        }

                        return {
                            label: value[item],
                            format,
                            options,
                            id: index + 1
                        }
                    });



                let fieldsToCompare = labels
                    .filter(item => value[item] != null)
                    .map(item => {
                        return {
                            label: value[item],
                            format: value[item.replace('label', 'format')]
                        }
                    })

                let countCompatible = 0;
                if(fields.length >= fieldsModelFromPlugcode.length){

                    fields.map((subItem, subIndex)=> {
                        if(!!fieldsModelFromPlugcode[subIndex]){
                            if(isCompatible(fieldsModelFromPlugcode[subIndex] , subItem)){
                                countCompatible++;
                            }else if(isLabelCompatible(fieldsModelFromPlugcode[subIndex] , subItem)){
                                countCompatible++;
                            }
                        }
                    });

                    if(countCompatible >= fieldsModelFromPlugcode.length){
                        //check if same fields sinon compatible;
                        let concordantFields = [];
                        let fieldsAreEquals = false;

                        if (plugform) {
                            const concordantsLabels = Object.keys(plugform).filter(key => {
                                const regex = /field[0-9]+label/gi;
                                return regex.test(key);
                            });

                            concordantFields = concordantsLabels
                                .filter(item => plugform[item] != null)
                                .map(item => {
                                    return {
                                        label: plugform[item],
                                        format: plugform[item.replace('label', 'format')]
                                    }
                                });
                            // Si tous les champs sont égaux alors c'est le même modèle
                            fieldsAreEquals = _.isEqual(fieldsToCompare, concordantFields);
                            if(fieldsAreEquals){
                                sign = 'equal';
                            }else{
                                sign = 'compatible';
                            }
                        }
                    }

                }

                let countOriginCompatible = 0;

                if(fields.length >= fieldsModelFromPlugcode.length && !!value.modelOrigin){

                    if(value.modelOrigin === plugform.id){
                        fields.map((subItem, subIndex)=> {
                            if(!!fieldsModelFromPlugcode[subIndex]){
                                if(isCompatible(fieldsModelFromPlugcode[subIndex] , subItem)){
                                    countOriginCompatible++;
                                }else if(isLabelCompatible(fieldsModelFromPlugcode[subIndex] , subItem)){
                                    countOriginCompatible++;
                                }
                            }
                        });

                        if(countOriginCompatible >= fieldsModelFromPlugcode.length){
                            sign = 'equal';
                        }
                    }
                }

                if(
                    (plugform.clef === value.clef && regex.test(plugform.clef.charAt(0)) && regex.test(plugform.clef.charAt(1))) ||
                    (plugnote.modclef === value.clef && regex.test(plugnote.modclef.charAt(0)) && regex.test(plugnote.modclef.charAt(1)))
                ){
                    sign = 'equal';
                }


                return {
                    ...value,
                    sign : sign
                };
            });
        }
        let finalModels = [];
        sortedModels.map((item) => {
            if(item.sign == 'equal'){
                finalModels.push(item);
            }
        });

        sortedModels.map((item) => {
            if(item.sign == 'compatible'){
                finalModels.push(item);
            }
        });

        sortedModels.map((item) => {
            if(item.sign != 'compatible' && item.sign != 'equal'){
                finalModels.push(item);
            }
        });

        setModels(finalModels);

    };

    useEffect(() => {
        setTitle(choice === "save" ?  t("common:plugcode.choice-save-plugcode-title") : t("common:plugcode.choice-copy-plugcode-title"));
        setButtonTitle(choice === "save" ?  t("common:plugcode.choice-save-plugcode-button") : t("common:plugcode.choice-copy-plugcode-button"));
    }, [choice]);

    useEffect(() => {
        if(loading === true){
            let language = i18n.language.charAt(0).toUpperCase() + i18n.language.charAt(1).toUpperCase();
            props.attemptGetModels(hash, language);
            setLoading(false);
        }
    }, [hash]);

    useEffect(() => {
        sortModels();
    }, [props.models]);

    /**
     * Affichage de l'icone de validation de la compatibilité
     * @param field
     * @returns {XML}
     * @private
     */
    const renderCompatibleIcon = (model) => {
        if(!!model.sign){
            return(
                <div className="renderIconRight">
                    {model.sign == 'compatible' ?
                        <span className="bloodOrange font_size_25">≈</span>
                        :
                        <span className="greenLight font_size_25">=</span>
                    }
                </div>
            )
        }else{
            return null;
        }
    };

    return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div key={props.countable} style={modalStyle} className={classes.popup}>
                <div className = "scrollbar-hidden popup_wrapper flexSpacebetween" >
                    <div className="popup_wrapper_content">
                        <div className="sous-header_modal_content">
                            <div>
                                <span className="titres">
                                    {title}
                                     <br/>
                                </span>
                                <span className="grey">
                                    [{plugform.clef}] {plugform.label} {'>'} {getCodePnPpCodeTemplate()}
                                </span>
                            </div>
                            <div onClick={handleCloseModal}>
                                <span id="icon-close" className="material-icons md-50 lightgrey">
                                    close
                                </span>
                            </div>

                        </div>
                        <div className="sous-header_content lightgrey">
                            <div className="line flex_style">
                                <div className={classes.selectType}>
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={choice}
                                        onChange={(event) => handleChangeTypeSelect(event.target.value)}
                                    >
                                        <MenuItem value={'save'}>{t("common:plugcode.choice-save-plugcode")}</MenuItem>
                                        <MenuItem value={'copy'}>{t("common:plugcode.choice-copy-plugnote-plugform")}</MenuItem>
{/*
                                        <MenuItem value={'transfert'}>{t("common:plugcode.choice-copy-select-plugform")}</MenuItem>
*/}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    </div>

                    { choice !== "save" ?
                        <div className="popup_wrapper_content">
                            <div className="choix-univers">
                                <Select
                                    disableUnderline
                                    disabled={choice === 'save'}
                                    native
                                    value={hash}
                                    onChange={handleChangeEnvironnement}
                                    label={t('models.environnementName')}
                                    inputProps={{
                                        name: 'age',
                                        id: 'outlined-age-native-simple',
                                        classes: {
                                            icon: classes.iconSelect,
                                        },
                                    }}
                                    className="select"
                                    IconComponent={props => (
                                        <i {...props} className={`material-icons ${props.className} md-30 green`}>
                                            keyboard_arrow_down
                                        </i>
                                    )}
                                >
                                    {
                                        sortedListEnvironnement.map((value, index) => {
                                            return <option key={index} value={value.userhash}>{value.name}</option>
                                        })
                                    }
                                </Select>

                                { choice === 'copy' &&

                                    <div className="select-dossier-Plugcode">
                                        <span className="green">[{plugform.clef}]</span>
                                        <span className="titres" style={{marginLeft: '30px'}}> {plugform.label}</span>
                                    </div>

                                }
                            </div>

                            {
                                choice === "transfert" &&
                                <div>
                                    { models.map((model,index) => {

                                        let count = Number(model.nbFichesNotArchived) + Number(model.nbFichesArchived);
                                        return (
                                            <div className="select-dossier-Plugcode cursor" key={index} onClick={(event) => handleSelectFormToTransfert(model)}>
                                                <span className="green">[{model.clef}]</span>
                                                <span className="titres" style={{marginLeft: '30px'}}> {model.label}</span>
                                                <span className="references grey">({count})</span>
                                                <span className="references grey">
                                                    {
                                                        renderCompatibleIcon(model)
                                                    }
                                                </span>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            }

                            {
                                choice === 'copy' &&
                                <button className="button" onClick={handleCopyPlugcodeAndForm}>{buttonTitle}</button>
                            }

                        </div>
                        :
                        <div className="popup_wrapper_content">
                            <div>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="Commentaire"
                                    multiline
                                    minRows={4}
                                    defaultValue={comment}
                                    variant="outlined"
                                    fullWidth={true}
                                    onChange={(event) => setComment(event.target.value)}
                                />
                            </div>
                            {
                                choice === 'save' &&
                                <button className="button" onClick={handleSavePlugcode}>{buttonTitle}</button>
                            }

                        </div>
                    }

                </div>
            </div>
        </Modal>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    const listEnvironment = state.user.user.profil.filter((value, index) => {
        if(!value.modelsshared){
            return value;
        }
    });
    const sortedListEnvironnement = listEnvironment.sort((left, right) => {
        if(left.profilid === ''){
            return -1
        }else{
            return left.profilid < right.profilid ? -1 : 1;
        }
    });

    let models = [];

    if(state.search.isSearchActive && state.search.isSearchResult && state.model.datas.length > 0){
        state.model.datas.map((item, index) => {
            let find = false;
            state.search.searchResult.map((search, index) => {
                if(item.hash === search.modhash){
                    find = true;
                }
            })
            if(find){
                models.push(item);
            }
        });
    }

    if(!state.search.isSearchActive && !state.search.isSearchResult){
        models = !! state.model.datas ? state.model.datas : [];
    }

    return {
        plugnote : state.plugcode.plugnote,
        plugform : state.plugcode.plugform,
        sortedListEnvironnement : sortedListEnvironnement,
        listEnvironment : listEnvironment,
        user : state.user,
        models : models.sort((item1, item2) => {
            return item1.clef.toLowerCase() > item2.clef.toLowerCase() ? -1 : 1;
        }),
        fetching : state.model.fetching,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        attemptCreateGalleryRequest: (gallery, userhash) => dispatch(postPlugcodeGalleryRequest(gallery, userhash)),
        attemptGetModels: (userhash, language) => dispatch(getModelsRequest(userhash,language)),
        attemptGetFields : () => dispatch(getFieldsRequest()),
        attemptClassifyInItsPlugform: (plugnote, datahash, userhash) => dispatch(postClassifyInItsPlugformRequest(plugnote, datahash, userhash)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalCopyOrSavePlugcodeComponent)
