import { makeStyles, DialogContent, DialogContentText, Paper } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react"
import Draggable from "react-draggable"
import colors from "../../assets/themes/Colors";
import { useTranslation } from "react-i18next";
import { getFloatingTotalDimensions, getFloatingTotalPosition, setFloatingTotalDimensions, setFloatingTotalPosition } from "../../utils/Common";
import { devLog } from "../../utils/utils";
import { ResizeBottomRightIcon } from "./icons/CustomIcons";
import ApplicationConfig from "../../config/ApplicationConfig";

const useStyles = makeStyles(() => ({
  resizeIconCtn: {
    position: 'absolute',
    width: 40,
    height: 'auto',
    verticalAlign: 'sub',
    bottom: -6,
    opacity: 0,
    transition: 'opacity 500ms ease-in-out',
  },
  dragIcon: {
    fill: colors.unselectedAlpha,
    '&:hover': {
      fill: colors.mainButton,
    },
  },
  visibleDrag: {
    fill: colors.unselectedAlpha,
    opacity: 1,
    transition: 'opacity 500ms ease-in-out',
  },
}))

function PaperComponent(props) {
  const position = props.position

  return (
    <Draggable
      cancel={'[class*="MuiDialogContent-root"]'}
      handle="#draggable-dialog-title"
      onDrag={props.handleOnDrag}
      onStop={props.handleSavePosition}
      position={position}
    >
      <Paper
        children={props.children}
        style={props.style}
      />
    </Draggable>
  )
}

export default function FloatingModal(props) {
  const classes = useStyles()
  const [showDrag, setShowDrag] = useState(false)
  const [width, setWidth] = useState(getFloatingTotalDimensions()?.width || 300)
  const [height, setHeight] = useState(getFloatingTotalDimensions()?.height || 240)
  const [isResizing, setIsResizing] = useState(false)
  const [position, setPosition] = useState(getFloatingTotalPosition())

  const xBoundary = width - window.innerWidth + 80

  const handleSavePosition = (e, data) => {
    setFloatingTotalPosition({ x: data.x, y: data.y })
  }

  const handleOnDrag = (e, data) => {
    let xAxis = data.x < 55 ? data.x : 55
    if ((window.innerWidth + xAxis - width - 120) < 0) { xAxis = width - window.innerWidth + 120 }
    let yAxis = data.y > -85 ? data.y : -85
    if ((window.innerHeight - yAxis < 200)) { yAxis = window.innerHeight - 200 }
    setPosition({ x: xAxis, y: yAxis })
  }
  const [side, setSide] = useState(null)
  const leftRef = useRef(null)
  const rightRef = useRef(null)

  const handleShowDragIcon = () => { setShowDrag(true) }
  const handleHideDragIcon = () => { setShowDrag(false) }

  const handleStartDrag = (side) => {
    setIsResizing(true)
    setSide(side)
  }

  const handleOnMouseUp = () => {
    setSide(null)
    setIsResizing(false)
    setFloatingTotalDimensions({ width: width, height: height })
  }

  const handleClose = () => {
    props.close()
  }

  const handleOnMouseMove = (e) => {
    if (isResizing) {
      const drag = side === 'left' ? leftRef : rightRef
      let xStart = (e.clientX > 80 ? e.clientX : 80)
      if (side === 'right' && xStart > window.innerWidth - 80) {
        xStart = window.innerWidth - 40
      }
      const xOffset = xStart - (side === 'left' ? drag.current.parentElement?.getBoundingClientRect().left : drag.current.parentElement?.getBoundingClientRect().right)
      const yOffset = e.clientY - drag.current.parentElement?.getBoundingClientRect().bottom

      handleResize(xOffset, yOffset)
      if (side === 'right' && width >= 250 && (window.innerWidth - width - 120) > 0) {
        setPosition(curr => {
          let xAxis = curr.x + xOffset
          if (xAxis < xBoundary) { xAxis = xBoundary }
          let newPosition = { ...curr, x: xAxis }
          setFloatingTotalPosition(newPosition)
          return newPosition
        })
      }
    }
  }

  const handleResize = (x, y) => {
    setHeight(current => {
      let newHeight = (current + y) > 200 ? current + y : 200
      return newHeight
    })
    setWidth(current => {
      let newWidth = side === 'left' ? current - x : current + x
      return newWidth >= 250 ? newWidth : 250
    })
  }

  useEffect(() => {
    if (isResizing) {
      document.onmousemove = handleOnMouseMove
      document.onmouseup = handleOnMouseUp
    } else {
      setFloatingTotalDimensions({ width: width, height: height })
      setFloatingTotalPosition(position)
    }
    return () => {
      document.onmousemove = null;
      document.onmouseup = null;
    }
  }, [isResizing])

  return (
    <div style={{ position: 'absolute', width: '90vw' }}>
      <div style={{ position: 'relative' }}>
        <PaperComponent
          style={{
            visibility: props.open ? "visible" : "hidden",
            width: width, minHeight: height,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            position: 'absolute', right: 0,
            backgroundColor: 'white',
            // backgroundColor: 'floralwhite',
            boxShadow: 'rgba(0, 0, 0, 0.14) 0px 0px 20px 5px, rgba(0, 0, 0, 0.2) -0px 0px 10px',
            zIndex: 100,
          }}
          height={height}
          width={width}
          position={position}
          handleOnDrag={handleOnDrag}
          handleSavePosition={handleSavePosition}
        >
          <div style={{ cursor: "move", display: 'flex', justifyContent: 'space-between', flexDirection: 'row', backgroundColor: colors.palePlugGreen }} id="draggable-dialog-title">
            <div style={{ padding: 12, paddingBottom: 8 }} >
              {
                props.title
                  ? props.title
                  : 'Plugnotes'
              }
            </div>
            <div onClick={handleClose}>
              <span
                id="icon-close"
                className="material-icons md-50"
                style={{ color: colors.unselected }}
              >
                close
              </span>
            </div>
          </div>

          <div className="sous-header_content lightgrey" style={{ flex: 0 }}>
            <div className="line flex_style"></div>
          </div>

          <DialogContent>
            {
              props.body
                ? props.body
                : (
                  <DialogContentText>
                    To subscribe to this website, please enter your email address here.
                    We will send updates occasionally.
                  </DialogContentText>
                )
            }
            {
              ApplicationConfig.devMode &&
              <div >
                Width: {width}
                <div >Height: {height}</div>
                <div >x:y: {`${position.x}:${position.y}`}</div>
              </div>
            }
          </DialogContent>
          <div style={{ backgroundColor: colors.plugnotesLightAlpha, minHeight: 36 }} onMouseEnter={handleShowDragIcon} onMouseLeave={handleHideDragIcon} />
          <div className={`${classes.resizeIconCtn} prevent-select ${showDrag ? classes.visibleDrag : undefined}`}
            style={{ cursor: 'nesw-resize', left: 0, transform: 'scaleX(-1)' }}
            onMouseEnter={handleShowDragIcon} onMouseLeave={handleHideDragIcon}
            onMouseDown={() => handleStartDrag('left')}
            onMouseUp={handleOnMouseUp}
            ref={leftRef}
          >
            <ResizeBottomRightIcon fill={colors.unselected} />
          </div>
          <div className={`${classes.resizeIconCtn} prevent-select ${showDrag ? classes.visibleDrag : undefined}`}
            style={{ cursor: 'nwse-resize', right: 0 }}
            onMouseDown={() => handleStartDrag('right')}
            onMouseEnter={handleShowDragIcon}
            onMouseLeave={handleHideDragIcon}
            ref={rightRef}
          >
            <ResizeBottomRightIcon />
          </div>
        </PaperComponent>
      </div>
    </div>
  )
}
