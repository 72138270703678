import { FormControlLabel, makeStyles, Radio, RadioGroup, TextField } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import colors from '../../../assets/themes/Colors'
import { devLog, isDefined } from '../../../utils/utils'
import {
  ColorCheckbox,
  PlugTooltip,
  RestyledNumericInput,
  RestyledNumericInputWithOptions
} from '../../custom/CustomComponents'
import InputMask from 'react-input-mask'
import { OpenFieldIcon } from '../../custom/icons/CustomIcons'
import { getCurrentDateTimeByMask, isInvalidDate, isInvalidTime } from '../../../utils/Fields'
import { NumericFormat } from 'react-number-format'

const useStyles = makeStyles((theme) => ({
  mainCtnH: {
    padding: '0 12px 12px 40px',
    width: '-webkit-fill-available',
  },
  mainCtnV: {
    padding: 0,
    minWidth: 300,
  },
  headerCtn: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: 'white',
    boxShadow: '3px 0px 6px 4px rgba(136, 136, 136, 0.12)'
  },
  horizontalCtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  inputsCtnH: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 8,
    '@media (max-width: 720px)': {
      alignItems: 'flex-start',
      paddingTop: 8,
    },
  },
  inputsCtnV: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  mainInputCtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minWidth: '50%',
    '@media(max-width: 600px)': {
      minWidth: '100%',
    }
  },
  editInput: {
    '& .MuiInputBase-root': {
      alignItems: 'flex-start !important',
      backgroundColor: colors.whiteAlpha,
      borderRadius: 8,
    },
  },
  monolineInput: {
    '& .MuiInputBase-multiline': {
      padding: '0px 0px 2px !important',
    }
  },
  editorCtn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minWidth: 300,
    overflowY: ""
  },
  preselsCtn: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 5,
  },
  preselCtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 16px',
    borderRadius: 6,
    color: 'white',
    cursor: 'pointer',
  },
  radioCtn: {
    flexDirection: 'row !important'
  },
  secondaryCtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 20,
    '@media (max-width: 720px)': {
      flexDirection: 'column',
      gap: 8,
    },
  },
  optionBtn: {
    margin: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
    border: '2px solid' + colors.main,
    color: colors.main,
    fontWeight: 'bold',
    padding: '4px 8px',
    cursor: 'pointer',
  },
  selectedBtn: {
    backgroundColor: colors.main,
    color: 'white',
  },
  validateBtn: {
    margin: '8px 4px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
    border: '2px solid' + colors.main,
    padding: '4px 8px',
    cursor: 'pointer',
  },
  title: {
    fontSize: 18,
    color: colors.dark,
    fontWeight: 600,
    opacity: 0.7,
  },
  disabled: {
    color: colors.iconGrey,
    display: 'inline-flex',
    alignItems: 'flex-start',
  },
  enabled: {
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'flex-start',
  },
  numericInput: {
    '& div.MuiTextField-root': {
      border: '1px solid #58c3c1',
      borderRadius: 4,
      backgroundColor: colors.whiteAlpha,
    },
  },
  validBtnCtn: {
    width: '-webkit-fill-available',
    justifyContent: 'space-evenly',
    display: 'inline-flex',
  },
}))

export const RenderEditDefaultValueEditabilityComponent = (props) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const editable = props.editable
  const editablePf = props.editablePf !== false && editable !== false
  const editablePw = props.editablePw !== false && editable !== false
  return <div className={classes.secondaryCtn}>
    <span onClick={props.handleToggleEditable} className={classes.enabled}>
      <ColorCheckbox
        checked={editable !== false} />
      {t('constructor.editable')}
    </span>
    <span onClick={props.handleToggleEditablePf} className={editable === false ? classes.disabled : classes.enabled}>
      <ColorCheckbox
        checked={editablePf}
        disabled={editable === false} />
      {t('constructor.editable-pf')}
    </span>
    <span onClick={props.handleToggleEditablePw} className={editable === false ? classes.disabled : classes.enabled}>
      <ColorCheckbox
        checked={editablePw}
        disabled={editable === false} />
      {t('constructor.editable-pw')}
    </span>
  </div>
}

const getDefaultMask = field => {
  switch (field.format) {
    case 'time':
      return 'HH:MM'
    case 'date':
      return 'DD/MM/YYYY'
    default:
      return ''
  }
}

const getFieldMask = field => {
  let mask = isDefined(field.mask) ? field.mask : field.options.mask
  return mask || getDefaultMask(field)
}

const getFieldRegex = mask => {
  // devLog('mask', mask)
  const regex = mask.replaceAll(/\w/g, '9')
  // devLog('regex', regex)
  return regex
}

const RenderEditDefaultValueComponent = (props) => {
  devLog('EditDefaultValue got props', props)
  const classes = useStyles()
  const { t } = useTranslation('common')
  const field = props.field
  const setup = isDefined(props.editOptions) ? props.editOptions : props.field.options.setup
  const _default = isDefined(setup.defaultValue) ? setup.defaultValue : props.defaultValue || ''
  const [defaultValue, setDefaultValue] = useState(_default)
  const [editable, setEditable] = useState(isDefined(setup.editable) ? setup.editable : true)
  const [editablePf, setEditablePf] = useState(isDefined(setup.editablePf) ? setup.editablePf : true)
  const [editablePw, setEditablePw] = useState(isDefined(setup.editablePw) ? setup.editablePw : true)

  // Presels
  const [openPresel, setOpenPresel] = useState(true)
  // Date & Time
  const [acceptInvalid, setAcceptInvalid] = useState(false)
  const mask = useMemo(() => getFieldMask(field), [])

  const handleChangeNumericDefaultValue = (v, infos) => {
    let newValue = isDefined(v.floatValue) ? v.floatValue : '';
    setDefaultValue(newValue);
    if (isDefined(infos.event)) {
      //if (isDefined(infos.event) && v.value !== defaultValue.toString()) {
      props.handleChangeDefaultValue(newValue)
      if (newValue.length === 0) { props.handleChangeDefaultValueOptions({ editable: true, editablePf: true, editablePw: true }) }
    }
  }

  const handleChangeDefaultValue = (e) => {
    let value = e?.target?.value || ''
    devLog('new value', value, e)
    setDefaultValue(value)
    if (value.length === 0) { props.handleChangeDefaultValueOptions({ editable: true, editablePf: true, editablePw: true }) }
    props.handleChangeDefaultValue(value)
  }

  const handleToggleDefaultBoolean = () => {
    setDefaultValue(curr => {
      const newValue = curr === false ? true : false
      props.handleChangeDefaultValue(newValue)
      return newValue
    })
  }

  const handleDeleteDefaultValue = () => {
    setDefaultValue('')
    props.handleChangeDefaultValue('')
    props.handleChangeDefaultValueOptions({ editable: true, editablePf: true, editablePw: true })
  }

  const handleChangeDefaultPresel = (e) => {
    let newValue = e === defaultValue ? '' : e
    setDefaultValue(newValue)
    props.handleChangeDefaultValue(newValue)
  }

  const handleToggleSetPnPlus = () => {
    let newValue = defaultValue === 'PN +' ? '' : 'PN +'
    setDefaultValue(newValue)
    props.handleChangeDefaultValue(newValue)
  }

  const handleChangeInputMask = (e) => {
    if (acceptInvalid) { setAcceptInvalid(false) }
    handleChangeDefaultValue(e)
  }

  const handleClickNowInputMask = () => {
    setAcceptInvalid(false)
    handleChangeDefaultValue({ target: { value: getCurrentDateTimeByMask(mask) } })
  }

  const handleClickDeleteInputMask = () => {
    setAcceptInvalid(false)
    handleDeleteDefaultValue()
  }

  const handleToggleOpenPresel = () => { setOpenPresel(previous => !previous) }

  const handleDeleteValue = () => {
    setDefaultValue('')
    props.handleChangeDefaultValue('')
  }

  const handleOnTapEnter = (e) => {
    if (e.key === 'Enter') {
      handleValidate()
    }
  }

  const handleValidate = () => {
    props.handleValidate(defaultValue)
  }

  const handleToggleEditable = () => {
    setEditable(previous => {
      if (previous) {
        props.handleChangeDefaultValueOptions({ editable: false, editablePf: false, editablePw: false })
        // if (props.isTablemulti) { props.handleChangeRowLock(true) }
      } else {
        props.handleChangeDefaultValueOptions({ editable: true })
        if (props.isTablemulti) { props.handleChangeRowLock(false) }
      }
      return !previous
    })
  }

  const handleToggleEditablePf = () => {
    if (editable) {
      setEditablePf(previous => {
        props.handleChangeDefaultValueOptions({ editablePf: !previous })
        return !previous
      })
    }
  }

  const handleToggleEditablePw = () => {
    if (editable) {
      setEditablePw(previous => {
        props.handleChangeDefaultValueOptions({ editablePw: !previous })
        return !previous
      })
    }
  }

  useEffect(() => {
    if (!editable) {
      setEditablePf(false)
      setEditablePw(false)
    }
  }, [editable])

  let template
  let title = t('helper.default-edit')

  switch (field.format) {
    case 'boolean':
      template = (
        <div onClick={handleToggleDefaultBoolean} style={{ cursor: 'pointer' }}>
          <ColorCheckbox
            checked={defaultValue === true}
            color={typeof defaultValue === "boolean" ? colors.main : colors.disabled}
          />
          <span style={{ color: typeof defaultValue === "boolean" ? colors.main : colors.disabled }}>
            {typeof defaultValue === "boolean" ? `(${defaultValue ? 'checked' : 'unchecked'})` : '(unset)'}
          </span>
        </div>
      )
      break
    case 'date':
    case 'duration':
    case 'time':
      let regex = getFieldRegex(mask)
      if (regex === '99') { regex = '' }
      let isInvalidDateTime = field.format === 'time' ? isInvalidTime(mask, defaultValue) : isInvalidDate(mask, defaultValue)
      template = (
        mask !== '' ?
          <InputMask
            maskChar="_"
            mask={regex}
            value={defaultValue}
            onChange={handleChangeInputMask}
            onKeyDown={handleOnTapEnter}
          >
            {(inputProps) =>
              <TextField
                {...inputProps}
                autoFocus
                className={classes.editInput}
                inputProps={{ style: { textAlign: 'center', paddingLeft: 5 } }}
                placeholder={mask === '' ? '' : mask}
                name={field.format + '-' + props.cindex}
                type="text"
                fullWidth
                InputProps={{
                  classes: {
                    input: classes.fontTablemulti,
                    root: classes.tablemultiInputCtn
                  },
                  endAdornment:
                    <>
                      {
                        defaultValue !== '' && isInvalidDateTime && !acceptInvalid &&
                          <PlugTooltip title={t('tablemulti.validate')} placement="right">
                            <span className={classes.fontError} onClick={() => setAcceptInvalid(true)}>
                              {t('tablemulti.invalid-value')}
                              <span id="icon-add-playlist" className={`${classes.tablemultiBtn} material-icons`}>
                                check
                              </span>
                            </span>
                          </PlugTooltip>
                      }
                      <PlugTooltip title={(defaultValue !== "" && isDefined(defaultValue)) ? t('actions.delete') : t('tablemulti.current-time')} placement="top" className={classes.endButton} >
                        {
                          defaultValue !== "" && isDefined(defaultValue) ?
                            <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={handleClickDeleteInputMask}>highlight_off</span>
                            :
                            <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={handleClickNowInputMask}>check_circle_outline</span>}
                      </PlugTooltip>
                    </>
                }}
              />
            }
          </InputMask>
          :
          <TextField
            autoFocus
            className={classes.editInput}
            inputProps={{ style: { textAlign: 'center', paddingLeft: 5 } }}
            placeholder={mask === '' ? '' : mask}
            name={field.format + '-' + props.cindex}
            type="text"
            fullWidth
            InputProps={{
              classes: {
                input: classes.fontTablemulti,
                root: classes.tablemultiInputCtn
              },
              endAdornment:
                <PlugTooltip title={(defaultValue !== "" && isDefined(defaultValue)) ? t('actions.delete') : t('tablemulti.current-time')} placement="top" className={classes.endButton} >
                  {
                    defaultValue !== "" && isDefined(defaultValue) ?
                      <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={handleClickDeleteInputMask}>highlight_off</span>
                      :
                      <span className={`${classes.tablemultiInputBtn} material-icons`} onClick={handleClickNowInputMask}>check_circle_outline</span>}
                </PlugTooltip>
            }}
            onKeyDown={handleOnTapEnter}
            onChange={handleChangeInputMask}
            value={defaultValue}
          />
      )
      break
    case 'presel':
    case 'select':
      title = t('helper.default-presel')
      template = (
        <div className={classes.editorCtn} >
          <div className={classes.horizontalCtn} onClick={handleToggleOpenPresel} style={{ cursor: 'pointer', justifyContent: 'flex-start' }}>
            <div className={classes.rightIconCtn}>
              <OpenFieldIcon open={openPresel} />
            </div>
            {
              openPresel ?
                <div className={classes.preselsCtn} >
                  {
                    field?.presel.length > 0 && field.presel
                      .map((presel, index) => {

                        return (
                          <div key={'presel-' + presel}
                            className={classes.preselCtn} onClick={() => handleChangeDefaultPresel(presel)}
                            style={{ backgroundColor: defaultValue === presel ? colors.main : colors.unselectedAlpha }}
                          >
                            {presel}
                          </div>
                        )
                      })
                  }
                </div>
                :
                defaultValue.length > 0 &&
                <div key={'presel-' + props.field.index}
                  className={classes.preselCtn}
                  style={{ backgroundColor: colors.main }}
                >
                  {defaultValue}
                </div>
            }
          </div>
        </div>
      )
      break
    case 'extdata':
    case 'file-uri':
    case 'intdata':
    case 'list':
    case 'localis':
    case 'mail':
    case 'numeric-ref':
    case 'qrcode':
    case 'smail':
    case 'text':
    case 'textarea':
    case 'url':
      let inputType
      switch (field.format) {
        case 'mail':
          inputType = "email"
          break
        case 'numeric-ref':
          inputType = "number"
          break
        case 'url':
          inputType = "url"
          break
        default:
          inputType = "text"
      }
      template = (
        <TextField
          autoFocus
          className={classes.editInput}
          fullWidth
          inputProps={{ style: { textAlign: 'center' } }}
          InputProps={{
            endAdornment:
              defaultValue?.length > 0 &&
              <span
                className="material-icons"
                onClick={handleDeleteValue}
                style={{ color: colors.main, zIndex: 1 }}
              >
                highlight_off
              </span>
          }}
          multiline
          onChange={handleChangeDefaultValue}
          value={defaultValue}
          type={inputType}
        />
      )
      break
    case 'file-source': {
      const defaultOptions = ['PLUGNOTE', 'URL', 'FTP']
      let masks = Array.isArray(field.mask) ? field.mask : [field.mask]
      if (!isDefined(field.mask)) { masks = defaultOptions}
      template = (
        <RadioGroup
          className={classes.radioCtn}
          aria-label="source-radio" name="source-radio"
          onChange={handleChangeDefaultValue}
          value={defaultValue}
        >
          {
            masks.map((mask, index) => {
              return (
                <FormControlLabel size='small' value={mask}
                  control={
                    <Radio classes={{ root: classes.radioIcon, checked: classes.radioIconChecked }}
                      size='small'
                      disabled={props.disabled}
                    />
                  }
                  label={<span className={classes.radioLabel}>{mask}</span>}
                  key={index}
                />
              )
            })}
        </RadioGroup>
      )
      break
    }
    case 'intdatamaj':
        template = (
          <div style={{ gap: 10, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <PlugTooltip title={t('tablemulti.s03-new')}>
              <div className={`${defaultValue === 'PN +' ? classes.selectedBtn : undefined} ${classes.optionBtn}`} onClick={handleToggleSetPnPlus}>PN +</div>
            </PlugTooltip>
            <TextField
              autoFocus
              className={`${classes.editInput} ${classes.monolineInput}`}
              // fullWidth={props.isTablemulti}
              disabled={defaultValue === 'PN +'}
              inputProps={{ style: { textAlign: 'center' } }}
              InputProps={{
                endAdornment:
                  defaultValue?.length > 0 &&
                  <span
                    className="material-icons"
                    onClick={handleDeleteValue}
                    style={{ color: colors.main, zIndex: 1 }}
                  >
                    highlight_off
                  </span>
              }}
              multiline
              onChange={handleChangeDefaultValue}
              placeholder='Plugnote N° 1-'
              value={defaultValue}
              type="number"
            />
          </div>
        )
        break

    case 'numeric':
      const sepdecimal =  props.field?.options?.setup?.sepdecimal || ','
      const septhousand =  field.options?.setup?.septhousand !== false ? field.options?.setup?.septhousand : ""
      const hasDecimalScale = props.field.options?.setup?.numberdecimals || 99;
      title = t('helper.default-edit')
      template = (
          <div className={classes.numericInput}>
            <NumericFormat
                {...props}
                customInput={RestyledNumericInputWithOptions}
                allowedDecimalSeparators={[",", "."]}
                decimalSeparator={sepdecimal}
                isNumericString={true}
                thousandSeparator={septhousand}
                onValueChange={(v, infos) => {
                  handleChangeNumericDefaultValue(v, infos)
                }}
                value={defaultValue}
                decimalScale={hasDecimalScale === '' ? undefined : parseInt(hasDecimalScale)} // all decimals or fixed qty
            />
          </div>
      )
      break

    case 'radio': {
      let masks = mask.split('/')
      title = t('helper.default-choose')
      template = (
        <RadioGroup
          className={classes.radioCtn}
          aria-label="gender" name="gender1"
          onChange={handleChangeDefaultValue}
          value={defaultValue}
        >
          {
            masks.map((mask, index) => {
              return (
                <FormControlLabel size='small' value={mask}
                  control={
                    <Radio classes={{ root: classes.radioIcon, checked: classes.radioIconChecked }}
                      size='small'
                      disabled={props.disabled}
                    />
                  }
                  label={<span className={classes.radioLabel}>{mask}</span>}
                  key={index}
                />
              )
            })}
        </RadioGroup>
      )
      break
    }
    case 'phone':
    case 'tel':
      template = (
        <TextField
          autoFocus
          className={classes.editInput}
          inputProps={{ style: { textAlign: 'center', paddingLeft: 5 } }}
          placeholder={mask}
          name={field.format + '-' + props.cindex}
          fullWidth
          InputProps={{
            classes: {
              input: classes.fontTablemulti,
              root: classes.tablemultiInputCtn
            },
            endAdornment:
              defaultValue?.length > 0 &&
              <span
                className="material-icons"
                onClick={handleDeleteValue}
                style={{ color: colors.main, zIndex: 1 }}
              >
                highlight_off
              </span>
          }}
          onKeyDown={handleOnTapEnter}
          onChange={handleChangeDefaultValue}
          type="tel"
          value={defaultValue}
        />
      )
      break
    default:
      template = (
        <div>default</div>
      )
      break
  }

  const isNotEmpty = () => {
    let notEmpty = isDefined(defaultValue) && (defaultValue?.length > 0 || defaultValue > 0)
    if (field.format === 'date'|| field.format === 'duration'|| field.format === 'time') {
      notEmpty = !defaultValue?.includes('_')
    }
    if (field.format === 'boolean') { notEmpty = typeof defaultValue === 'boolean' }
    return notEmpty
  }

  if (field.format === 'now') {
    return null
  }

  return (
    <div className={`${props.isTablemulti ? classes.mainCtnV : classes.mainCtnH} `} >
      <span className={classes.title}>{title} :</span>
      <div className={props.isTablemulti ? classes.inputsCtnV : classes.inputsCtnH} >
        <div className={classes.mainInputCtn} >
          {template}
        </div>
        {
          isNotEmpty() &&
          <RenderEditDefaultValueEditabilityComponent
            editable={editable}
            editablePf={editablePf}
            editablePw={editablePw}
            handleToggleEditable={handleToggleEditable}
            handleToggleEditablePf={handleToggleEditablePf}
            handleToggleEditablePw={handleToggleEditablePw}
            setup={props.field?.options?.setup}
          />
        }
        {
          props.isTablemulti &&
          <div className={classes.validBtnCtn} >
            {
              defaultValue?.length > 0 &&
              <PlugTooltip title={t('selects.delete-default')} placement='bottom'>
                <div className={classes.validateBtn} onClick={() => handleChangeDefaultValue('')} style={{ borderColor: colors.bloodOrange }}>
                  {t('actions.delete')}
                </div>
              </PlugTooltip>
            }
            <div className={classes.validateBtn} onClick={handleValidate}>{t('global.validate')}</div>
          </div>
        }
      </div>
    </div>
  )
}

export default RenderEditDefaultValueComponent
