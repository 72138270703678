import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {
    delAnnexes, deleteHistoryPlugAction,
    delModel,
    delPlugnote, delSelection, delSpecifiFieldsSelected, delSpecifiFieldsSize,
    delTopRow,
    getCurrentEnvironnement,
    getModel,
    getPlugnote,
    getSelection,
    getToken,
    getTopRow, getTypeOfPlugnote,
    getUser,
    setAnnexes, setFieldsSelected, setFieldsSize, setModel,
    setModelToUpdate,
    setPlugnote, setPlugnoteView, setSelection, setTableView, setTopRow
} from '../../utils/Common';
import {makeStyles} from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import clsx from "clsx";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import {Content, Header} from "rsuite";
import {connect} from "react-redux";
import {
    delDeleteDataRequest, delDeleteDatasRequest, getDatasPaginateRequest,
    getDatasRequest, getDatasSharedLightRequest, getDatasSharedPaginateLightRequest,
    putUpdateDataArchiveRequest,
    putUpdateDataFavorisRequest, putUpdateDataFieldRequest,
    putUpdateDataLockRequest,
    putUpdateDatasArchiveRequest, reduxDelAllDataFilterRequest,
    reduxDelDataFilterRequest, reduxDelGlobalDataFilterRequest,
    reduxSetDataFilterRequest
} from "../../actions/data";
import {usePromiseTracker} from "react-promise-tracker";
import ModalModelPlugcodeComponent from "../../components/modals/ModalModelPlugcodeComponent";
import ModalModelShareComponent from "../../components/modals/ModalModelShareComponent";
import {confirmAlert} from "react-confirm-alert";
import {useTranslation} from "react-i18next";
import {reduxGeneratePlugformcodeReset, reduxGeneratePlugnotecodeReset} from "../../actions/plugcode";
import {reduxGenerateShareReset} from "../../actions/share";
import {delModelUniqueRequest, postExportFullFileDatasRequest} from "../../actions/model";
import ModalSimpleExportCsvComponent from "../../components/modals/ModalSimpleExportCsvComponent";
import ModalTemplateImportExportCsvComponent from "../../components/modals/ModalTemplateImportExportCsvComponent";
import ModalImportExportCsvComponent from "../../components/modals/ModalImportExportCsvComponent";
import ModalPlugnotePlugcodeComponent from "../../components/modals/ModalPlugnotePlugcodeComponent";
import ModalCopyOrMovePlugnoteComponent from "../../components/modals/ModalCopyOrMovePlugnoteComponent";
import {
    isDefined,
    isJsonString,
    isJsonStringObject,
    isJsonStringObjectAndCount,
    isJsonStringObjectAndCountSubElement,
    isJsonStringObjectAndGetElement,
    validateEmail,
    validateNumber,
    validateText,
    validateUrl
} from "../../utils/utils";
import * as moment from "moment";
import FilterSelectComponent from "../../components/filters/FilterSelectComponent";
import {reduxGenerateAnnexeReset} from "../../actions/annexe";
import Button from "@material-ui/core/Button";
import {getFieldSetup, getTemplateFormatText} from "../../utils/Compatibility";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperclip, faTable} from "@fortawesome/free-solid-svg-icons";
import {faFileExcel} from "@fortawesome/free-regular-svg-icons";
import PopupState, {bindPopover, bindTrigger} from "material-ui-popup-state";
import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";
import {
    GridToolbarContainer,
    GridToolbarExport,
    GridOverlay,
    useGridApiRef,
    GRID_COLUMN_HEADER_DRAG_END,
    GRID_COLUMN_HEADER_DRAG_START,
    GridFilterPanel
} from "@material-ui/x-grid";
import DraggableHeaderRenderer from "../../components/renderers/DraggableHeaderRenderer";
import MenuLeftPlugSearchComponent from "../../components/searchs/MenuLeftPlugSearchComponent";
import HeaderSearch from "../../components/searchs/HeaderSearch";
import Images from "../../assets/themes/Images";
import LinearProgress from "@material-ui/core/LinearProgress";
import {GridCellParams, GridColumnHeaderParams, GridEditCellPropsParams, GridPanelContent} from "@material-ui/data-grid";
import PhoneInput from "react-phone-number-input";
import {KeyboardDatePicker, KeyboardDateTimePicker, KeyboardTimePicker} from "@material-ui/pickers";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl, Input,
    MenuItem,
    Select,
    TextField
} from "@material-ui/core";
import PlacesAutocomplete, {geocodeByAddress} from "react-places-autocomplete";
import InputAdornment from "@material-ui/core/InputAdornment";
import {history} from "../../hooks/history";
import Tooltip from "@material-ui/core/Tooltip";
import FilterSelectionSaveComponent from "../../components/filters/FilterSelectionSaveComponent";
import FilterSelectionLoadComponent from "../../components/filters/FilterSelectionLoadComponent";
import ModalSimpleExportTableMultipleCsvComponent from "../../components/modals/ModalSimpleExportTableMultipleCsvComponent";
import FilterSelectionList from '../../components/filters/FilterSelectionList';
import colors from '../../assets/themes/Colors';
import { parseFilterParameters } from '../../utils/Fields';
import { deleteFilterRequest, postSendListFilterRequest } from '../../actions/filter';
import InputMask from 'react-input-mask';
import { Date } from 'core-js';
import { DesignedGrid } from '../../components/custom/CustomComponents';
import ModalHelpComponent from "../../components/modals/ModalHelpComponent";
import {isNull} from "lodash";

const drawerWidth = 320;
const subDrawerWidth = 320;

const specialFields = ['textarea', 'text', 'numeric', 'phone', 'mail', 'url', 'date', 'boolean', 'select', 'agenda', 'localis', 'glocalis', 'password', 'time', 'list', 'birthday', 'qrcode','indatamaj'];
const editableDirectFields = ['text', 'numeric', 'phone', 'mail', 'url', 'date', 'boolean', 'select'];


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        backgroundColor : 'white',
    },
    buttonSimple:{
        backgroundColor: '#58C3C1',
        color: 'white',
        "&:hover":{
            backgroundColor: '#58C3C1'
        },
        "&:disabled":{
            backgroundColor: '#58C3C1'
        }
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F1F1F1',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    fieldImageSvg : {
        width: '20px',
        marginLeft : '10px'
    },
    grid:{
        minHeight : '40vh',
        height : '80vh',
        maxHeight : '80vh',
        '& .MuiDataGrid-cellEditing': {
            backgroundColor: 'rgb(255,215,115, 0.19)',
            color: '#1a3e72',
        },
        '& .Mui-error': {
            backgroundColor: `rgb(126,10,15, 0.1})`,
            color: '#750f0f',
        },
    },
    editSelect: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: 16,
        width: '100%'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    iconSearchActive:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : '#58C3C1'
    },
    iconSearch:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : '#DADADA'
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        padding: '15px 0px 0px 30px'
    },
    formControl: {
        margin: theme.spacing(1),
        width : '100%'
    },
    subDrawer: {
        width: subDrawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    paperAnchorDockedLeft : {
        boxShadow: '0px 0px 6px 4px rgba(136, 136, 136, 0.12)',
        border : 0,
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: '70px'
    },
    subDrawerOpen: {
        width: subDrawerWidth,
        // transition: theme.transitions.create('width', {
        //     easing: theme.transitions.easing.sharp,
        //     duration: theme.transitions.duration.enteringScreen,
        // }),
        marginLeft : '70px'
    },
    subDrawerClose: {
        // transition: theme.transitions.create('width', {
        //     easing: theme.transitions.easing.sharp,
        //     duration: theme.transitions.duration.leavingScreen,
        // }),
        overflowX: 'hidden',
        width: '0px'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    openClose: {
        bottom: 0,
        // position: 'absolute',
        width : '100%'
    },
    openCloseButton : {
        float : 'right',
        marginRight :'10px'
    },
    openedCloseButton : {
        float : 'right',
        marginRight :'20px'
    },
    content: {
        width : '100%',
        backgroundColor : 'white',
    },
    contentSub:{
        width : '100%',
        backgroundColor : 'white',
        padding: '64px 0px',
        marginLeft : '-70px'
    },
    contentModelGrid : {
        display: 'flex',
        backgroundColor : 'white',
        flexWrap : 'wrap',
        justifyContent: 'space-evenly',
        padding: '0px 20px',
        maxHeight : 'calc(85vh - 80px)',
    },
    contentModelList : {
        // marginTop : '20px',
        backgroundColor : 'white',
        flexWrap : 'wrap',
        justifyContent: 'space-evenly',
        columnGap: '20px',
        padding: '0px 20px',
        height : '-webkit-fill-available',
    },
    openCloseRelative: {
        bottom: 0,
        position: 'relative',
        width : '100%'
    },
    contentModelListOpenFilter : {
        marginTop : '150px',
        backgroundColor : 'white',
        flexWrap : 'wrap',
        justifyContent: 'space-evenly',
        columnGap: '20px',
    },
    icon:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : '#DADADA'
    },
    iconActive:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : '#58C3C1'
    },
    gridHeader : {
        padding: '10px 20px !important',
        width: '100%',
    },
    selectEnvironnement : {
    },
    iconSelect : {
        color : '#58C3C1 !important'
    },
    pointer:{
        cursor: 'pointer'
    },
    listHeight:{
        height: '100%'
    },
    margin_left_5:{
        marginLeft : '5px',
    },
    actionBtn: {
        // minWidth: "160px",
        height: 41,
        padding: "4px 20px",
        margin: '3px 5px',
        borderRadius: "3px",
        cursor: "pointer",
        color: colors.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // fontWeight: 'bold',
        backgroundColor: colors.mainButton
    },
    endBtn: {
        color: colors.white,
        backgroundColor: colors.mainButton,
        padding: "6px 0px",
        height: 41,
        borderRadius: "3px",
        marginLeft: '-10px',
        marginTop: '5px',
        cursor: "pointer",
    },
    overBtn: {
        position: 'absolute',
        left: 50,
        overflow: "visible",
        height: 41,
        paddingLeft: 5,
        paddingRight: 20,
        borderRadius: "3px",
        width: 'max-content',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'nowrap',
        flexDirection: 'row',
        backgroundColor: colors.mainButton
    },
    filterIndicator: {
        position: 'absolute',
        borderRadius: 50,
        top: -5,
        left: -5,
        width: 20,
        height: 20,
        display: 'flex',
        alignItems: 'center',
        fontSize: 15,
        justifyContent: 'center',
        backgroundColor: colors.unselected
    },
    selectionName: {
        marginLeft: 5,
        // fontWeight: 'bold'
    },
    newSelectionContainer: {
        margin: '5px 10px',
        '@media (min-width: 640px)': {
            width: '50%'
        }
    },
    selectionContainer: {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid',
        borderRadius: 4,
        borderColor: colors.grey,
        cursor: 'pointer',
        position: 'relative'
    },
    filterPanelActionContainer: {
        display: 'flex',
        flexDirection: 'space-between'
    },
    selectionTextField: {
        backgroundColor: colors.mainButton,
        marginLeft: 5,
        height: 32,
        display: 'flex',
        flexDirection: 'row',
    },
    selectionDetails: {
        color: colors.grey,
        fontSize: 13
    },
    underline: {
        color: 'white' ,
        '&::after': {
            borderBottom: '1.5px solid white !important'
        },
        '&::before': {
            borderBottom: '1.5px solid white !important'
        },
        '&::hover': {
            borderBottom: '1.5px solid white !important'
        },
        '&::hover:not': {
            borderBottom: '1.5px solid white !important'
        }
    },
    deleteBtn: {
        color: colors.white,
        backgroundColor: colors.mainButton,
        borderRadius: "3px",
        cursor: "pointer",
        marginLeft: 5,
    },
    filterBtn: {
        height: 41,
        padding: "4px 0px",
        margin: '3px 10px',
        borderRadius: "3px",
        gap: 5,
        color: colors.white,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: colors.mainButton
    },
    btnContainer: {
        display: 'flex',
        position: 'relative',
        justifyContent: 'space-between',
        zIndex: 50
    },
    gridContainer: {
        position: 'relative'
    },
    toolbarContainer: {
        // position: 'absolute',
        // top: 17,
        // left: 150
    },
    plugSearchContainer: {
        padding: '0px 8px',
        position: 'absolute',
        overflow: 'visible',
        height: 55,
        top: 50,
        left: 70,
        zIndex: 1250,
        backgroundColor: colors.white,
        borderRadius: "5px",
        boxSizing: "border-box",
        boxShadow: "24px 24px 38px rgb(0 0 0 / 14%), -10px 0px 5px 0px rgb(0 0 0 / 20%)",
    },
    tooltipStyle: {
        // borderColor: colors.main,
        backgroundColor: colors.grey,
        color: colors.white,
        border: '1px solid',
        padding: '3px 8px',
        margin: 5,
        fontSize: '18px',
        borderRadius: 5
    },
    hiddenFieldsContainer: {
        display: 'flex',
        gap: 5
    },
    hiddenFieldItemContainer: {
        display: 'flex',
        border: '1px solid',
        borderColor: colors.main,
        borderRadius: 5,
        padding: '2px 5px',
        cursor: 'pointer',
        minWidth: 40
    },
    filterRowsContainer: {
        '& div.MuiGridFilterForm-root': {  // & div pour appliquer à son enfant
            display: 'flex',
            padding: '8px',
            justifyContent: 'space-around',
            alignItems: 'flex-end',
        },
        '& div label + .MuiInput-formControl': {
            width: '100%',
        },
        '& div.MuiGridFilterForm-closeIcon': {
            justifyContent: 'center',
            display: 'flex !important',
        },
        '& div.MuiGridFilterForm-operatorSelect': {
            textAlignLast: 'center'
        },
        '& div.MuiFormControl-root': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center'
        }
    },
    plugcodeCtn: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));

const getCodePnPpCodeTemplate = (plug) => {

    const {hash, idOffline, userindex, creationdate, codepncode, publicpncode, modname,modclef} = plug
    //file-download
    let codePn= 'PN';
    switch (publicpncode) {
        case 0:
            codePn = 'PP';
            break;
        case 1:
            codePn = 'PN';
            break;
        case 2:
            codePn = 'PE';
            break;
        case 3:
            codePn = 'PS';
            break;
        default:
            break;
    }

    let pncode = plug.codepncode ? `${codePn}-${moment(plug.creationdate).format('YYMMDD')}-${plug.codepncode}` : null

    if(pncode !== null){
        pncode = pncode
    }
    else {
        pncode = "";
    }

    return pncode
};

const parseJson = (code) => {
    try {
        return JSON.parse(code);
    } catch (e) {
        return code;
    }
};

function CustomLoadingOverlay() {
    return (
        <GridOverlay>
            <div style={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
            </div>
        </GridOverlay>
    );
}

function renderPhoneEditInputCell (params) {
    return <PhoneEditInputCell {...params} />;
}

function renderSelectEditInputCell(params){
    return <SelectEditInputCell {...params} />
}

function renderDateEditInputCell (params) {
    return <DateEditInputCell {...params} />;
}

function PhoneEditInputCell (props) {
    const { id, value, api, field } = props;
    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'models', 'global']);

    const handleChange = useCallback(
        (value) => {
            const editProps = {
                value: value,
                format: "phone"
            };
            api.setEditCellProps({id, field,  props: editProps });
            //api.setCellMode(id, field, 'view');
        },
        [api, field, id],
    );

    return (
        <div className={classes.phone}>
            <PhoneInput
                defaultCountry="BE"
                value={typeof value !== 'undefined' ? value.toString() : ''}
                placeholder={t('fields.format-choice-placeholder-phone')}
                onChange={(event) =>  handleChange(event)}
                //disabled={disabled}
            />
        </div>
    );
}

function DateEditInputCell (props) {
    const { id, value, api, field } = props;
    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'models', 'global']);

    const handleChange = useCallback(
        (value) => {
            const editProps = {
                value: value,
            };
            api.setEditCellProps({id, field,  props: editProps });
        },
        [api, field, id],
    );

    return (
        <div className={classes.phone}>
            <KeyboardDatePicker
                className={classes.fullWidth}
                variant="inline"
                format="yyyy-MM-dd"
                fullWidth={true}
                id={'date-picker-inline-'+id+field}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                }}
                value={typeof value !== 'undefined' ? value.toString() : ''}
                onChange={(date, value) =>  handleChange(value)}
            />
        </div>
    );
}

function SelectEditInputCell(props){

    const { id, value, api, field } = props;
    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'models', 'global']);


    const handleChange = useCallback(
        (event) => {
            const editProps = {
                value: event.target.value,
                type : 'single-select',
                format : 'select',
                arrayValue : [event.target.value],
                arrayStringValue : JSON.stringify([event.target.value])
            };
            api.setEditCellProps({id, field,  props: editProps });
            event.stopPropagation();
            //api.setCellMode(id, field, 'view');
        },
        [api, field, id],
    );

    const handleChangeMultiple = useCallback(
        (event) => {
            const editProps = {
                value: event.target.value,
                type : 'multiple-select',
                format : 'select',
                arrayValue : event.target.value,
                arrayStringValue : JSON.stringify(event.target.value)
            };
            api.setEditCellProps({id, field,  props: editProps });
            event.stopPropagation();
            //api.setCellMode(id, field, 'view');
        },
        [api, field, id],
    );

    let valueSimple = "";
    let valueMultiple = [];

    let options = null

    if(typeof props.colDef.options !== "undefined" && isJsonStringObject(props.colDef.options)){
        options = JSON.parse(props.colDef.options)
    }else if(typeof props.colDef.options !== "undefined" && !isJsonStringObject(props.colDef.options)){
        options = props.colDef.options

    }

    let multiselect = false;
    let presel = [];

    if(options !== null){
        multiselect = options.multiselect;
        presel = Array.isArray(options.presel) &&  options.presel.length  === 0 ?  [] : options.presel.split(";")
    }

    if(typeof props.value !== "undefined"){

        if(typeof props.row.plugnote[field] !== "undefined"){
            if(options !== null && !options.multiselect && isJsonString(props.row.plugnote[field])){
                valueSimple = JSON.parse(props.row.plugnote[field])[0];
                if(options.editable){
                    if(!presel.includes(valueSimple)){
                        presel.push(valueSimple);
                    }
                }
            }
            else if(options !== null && !options.multiselect && props.row.plugnote[field].length > 0){
                valueSimple = props.row.plugnote[field];
                if(options.editable){
                    if(!presel.includes(valueSimple)){
                        presel.push(valueSimple);
                    }
                }
            }
            else if(options !== null && options.multiselect && isJsonString(props.row.plugnote[field])){
                valueMultiple = JSON.parse(props.row.plugnote[field]);
                if(options.editable){
                    valueMultiple.map((item, index) => {
                        if(!presel.includes(item)){
                            presel.push(item);
                        }
                    })
                }
            }
        }
    }



    const [selected, setSelected] = useState(valueSimple);
    const [selectedMultiple, setSelectedMultiple] = useState(valueMultiple);


    if(options !== null && typeof props.row.plugnote[field] !== "undefined"){

        if(multiselect){

            return (
                <div className={classes.editSelect}>
                    <FormControl className={classes.formControl}>

                        <Select
                            multiple={true}
                            value={selectedMultiple || []}
                            fullWidth={true}
                            id={id}
                            onChange={(event) => {
                                setSelectedMultiple(event.target.value);
                                handleChangeMultiple(event);
                            }}
                        >
                            {
                                presel.map((value, index) => {
                                    return  <MenuItem key={index} value={value}>{value}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
            );
        }
        else {
            return (
                <div className={classes.editSelect}>
                    <FormControl className={classes.formControl}>

                        <Select
                            multiple={false}
                            value={selected || ""}
                            fullWidth={true}
                            id={id}
                            onChange={(event) => {
                                setSelected(event.target.value);
                                handleChange(event);
                            }}
                        >
                            {
                                presel.map((value, index) => {
                                    return  <MenuItem key={index} value={value}>{value}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
            );
        }
    }
    else if(options !== null && typeof props.row.plugnote[field] === "undefined"){

        if(multiselect){

            return (
                <div className={classes.editSelect}>
                    <FormControl className={classes.formControl}>
                        <Select
                            multiple={true}
                            value={selectedMultiple || []}
                            fullWidth={true}
                            id={id}
                            onChange={(event) => {
                                setSelectedMultiple(event.target.value);
                                handleChangeMultiple(event);
                            }}
                        >
                            {
                                presel.map((value, index) => {
                                    return  <MenuItem key={index} value={value}>{value}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
            );
        }
        else {
            return (
                <div className={classes.editSelect}>
                    <FormControl className={classes.formControl}>

                        <Select
                            multiple={false}
                            value={selected || ""}
                            fullWidth={true}
                            id={id}
                            onChange={(event) => {
                                setSelected(event.target.value);
                                handleChange(event);
                            }}
                        >
                            {
                                presel.map((value, index) => {
                                    return  <MenuItem key={index} value={value}>{value}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
            );
        }
    }

    else return null;

}

function SimpleMemoDialog(props) {
    const classes = useStyles();
    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    const [content, setContent] = useState(value || '');

    const handleClose = () => {
        onClose(infos, content,infos.colDef.format);
    };

    const handleUpdateClose = () => {
        onUpdateClose(infos, content,infos.colDef.format);
    }

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={(event) => handleClose()} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>
                <TextField
                    multiline
                    minRows={10}
                    rowsMax={512}
                    inputProps={{ maxLength: 512 }}
                    defaultValue={content}
                    variant="outlined"
                    fullWidth={true}
                    onChange={(event) => setContent(event.target.value)}
                />
                <span style={{float : "right", fontSize : '10px'}}>{content.length +  ' / ' + '512'}</span>
            </DialogContent>
            <DialogActions>
                <Button onClick={(event) => handleClose()} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={(event) => handleUpdateClose()} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimpleAgendaDialog(props) {
    const classes = useStyles();

    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    const [selectedDate, handleDateChange] = useState(value !== null && value.length > 0 ? value : null);
    const [selectedDateValue, handleDateChangeValue] = useState();


    const handleClose = () => {
        onClose(infos, selectedDateValue,infos.colDef.format);
    };

    const handleUpdateClose = () => {
        onUpdateClose(infos, selectedDateValue,infos.colDef.format);
    }

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={(event) => handleClose()} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>
                <KeyboardDateTimePicker
                    variant="inline"
                    ampm={false}
                    fullWidth={true}
                    id={'date-picker-inline'}

                    //label={props.field.title}
                    //value={selectedDate}
                    //onChange={handleDateChange}
                    format="yyyy-MM-dd HH:mm"
                    value={selectedDate}
                    onChange={(date, value) => {
                        handleDateChange(date);
                        handleDateChangeValue(value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={(event) => handleClose()} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={(event) => handleUpdateClose()} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimpleLocalisDialog(props) {
    const classes = useStyles();

    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    const [address, setAddress] = useState(value.length > 0 ? value : "");

    const [fullAddress, setFullAddress] = useState("http://maps.google.com/maps?daddr="+address);

    const handleSelect = address => {
        geocodeByAddress(address)
            .then(results => {
                setAddress(results[0].formatted_address);
            })
            .catch(error => console.error('Error', error));
    };

    const  handleChange = address => {
        setAddress(address);
    };

    const handleClose = () => {
        onClose(infos, address,infos.colDef.format);
    };

    const handleUpdateClose = () => {
        onUpdateClose(infos, address,infos.colDef.format);
    }

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>
                <div style={{width: '100%' , marginRight: '5px', display: 'flex'}}>
                    <PlacesAutocomplete
                        value={address}
                        onChange={handleChange}
                        onSelect={handleSelect}
                        key={props.index}
                    >
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div style={{width : '100%'}}>
                                <input
                                    {...getInputProps({
                                        placeholder: 'Search Places ...',
                                        className: 'location-search-input',
                                    })}
                                />
                                <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, index) => {
                                        const className = suggestion.active
                                            ? 'suggestion-item--active'
                                            : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                                key={index}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </PlacesAutocomplete>
                    { typeof address !== 'undefined' &&  address !== '' &&
                        <a href={fullAddress} target="_blank"><span className="material-icons md-medium green">room</span></a>
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={handleUpdateClose} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimplePasswordDialog(props) {
    const classes = useStyles();
    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    const handleClose = () => {
        props.props.attemptResetDataPassword();
        onClose(infos, decryptPassword,infos.colDef.format);
    };

    const handleUpdateClose = () => {
        props.props.attemptResetDataPassword();
        onUpdateClose(infos, decryptPassword,infos.colDef.format);
    }

    const [password, setPassword] = useState(infos.value);
    const [decryptPassword, setDecryptPassword] = useState("");
    const [view, setView] = useState(false);
    const [edit, setEdit] = useState(false);
    const [fieldId, setFieldId] = useState(infos.field.replace("field", ""));

    const environment = getCurrentEnvironnement();
    const plugnote =  infos.row.plugnote;

    const handleViewCryptedPassword = (event) => {
        if(!edit){
            props.props.attemptGetPasswordField(plugnote.hash,fieldId,environment.userhash);
        }
        setView(true);
        setEdit(true);
    }

    const handleHidePassword = (event) => {
        setView(false);
        props.props.attemptResetDataPassword();
        setEdit(false);
    }

    const { t, i18n } = useTranslation(['common', 'login']);


    useEffect(() => {
        if(!!props.props.password && props.props.password !== null){
            if(typeof props.props.password[infos.field] !== "undefined"){
                setDecryptPassword(props.props.password[infos.field]);
            }
        }
    }, [props.props.password, view === true]);

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={(event) => handleClose()} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>
                <div className={classes.margin_5}>
                    {
                        view ?
                            <Input
                                autoComplete='new-password'
                                id={fieldId + '-' + 'password' + '-visible'}
                                placeholder="Password"
                                fullWidth={true}
                                style={{ margin: 0 }}
                                type="text"
                                margin="none"
                                disableUnderline={true}
                                className={`text-line ${classes.textField} input`}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <div onClick={(event) => handleHidePassword(event)}>
                                    <span className="material-icons">
                                        visibility_off
                                    </span>
                                        </div>
                                    </InputAdornment>}
                                onChange={(event) => {
                                    setEdit(true);
                                    setPassword(event.target.value);
                                    setDecryptPassword(event.target.value);
                                    //props.handleChangePassword(event.target.value, props.field, props.index)
                                }}
                                value={props.props.fetchingPassword ? t('global.loading') :  decryptPassword }
                            />
                            :

                            <Input
                                disableUnderline={true}
                                autoComplete='new-password'
                                id={fieldId + '-' + 'password' + '-invisible'}
                                placeholder="password"
                                fullWidth={true}
                                style={{ margin: 0 }}
                                margin="none"
                                type="password"
                                className={`text-line ${classes.textField} input`}
                                endAdornment={ password.length > 0 &&
                                    <InputAdornment position="end">
                                        <div onClick={(event) => handleViewCryptedPassword(event)}>
                                    <span className="material-icons">
                                        visibility
                                    </span>
                                        </div>
                                    </InputAdornment>}
                                onChange={(event) => {
                                    setEdit(true);
                                    setPassword(event.target.value);
                                    setDecryptPassword(event.target.value);
                                    //props.handleChangePassword(event.target.value, props.field, props.index)
                                }}
                                value={password.length > 0 ?  password : ""}
                            />
                    }

                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={(event) => handleClose()} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={(event) => handleUpdateClose()} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimpleTimeDialog(props) {
    const classes = useStyles();
    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    let currentValue = null;
    if(value !== null && value.length > 0){
        let split = value.split(':');
        currentValue = new Date(null, null, null, split[0], split[1]);
    }

    const [selectedDate, handleDateChange] = useState(currentValue);
    const [selectedDateValue, handleDateChangeValue] = useState();


    const handleClose = () => {
        onClose(infos, selectedDateValue,infos.colDef.format);
    };

    const handleUpdateClose = () => {
        onUpdateClose(infos, selectedDateValue,infos.colDef.format);
    }

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>
                <KeyboardTimePicker
                    fullWidth={true}
                    className={classes.fullWidth}
                    format="HH:mm"
                    margin="normal"
                    id="time-picker"
                    //label={props.field.title}
                    ampm={false}
                    //value={selectedDate}
                    //onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change time',
                    }}
                    value={selectedDate}
                    onChange={(date, value) => {
                        handleDateChange(date);
                        handleDateChangeValue(value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={handleUpdateClose} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimpleListDialog(props) {
    const classes = useStyles();

    const { t, i18n } = useTranslation(['common', 'models', 'global']);

    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    let tmpValue = [];

    if(typeof infos.row.plugnote[infos.field] === 'undefined'){
        tmpValue = [];
    }else if( infos.row.plugnote[infos.field].length > 0){

        let keywordList = JSON.parse(infos.row.plugnote[infos.field]);
        tmpValue = [];
        keywordList.map((item, index) => {
            tmpValue.push(item.text);
        })
    }


    const [selected, setSelected] = useState(tmpValue);
    const [newValue, setNewValue] = useState("");

    const handleAddValue = (value) =>
    {
        if(!selected.includes(value)){
            setSelected([...selected, value]);
        }
        setNewValue('');
    }

    const handleRemoveValue = (value) =>
    {
        if(selected.includes(value)){
            setSelected(selected.filter(item => item !== value));
        }
    }

    const handleClose = () => {
        onClose(infos, "",infos.colDef.format);
    };

    const handleUpdateClose = () => {
        let stringValue = selected.join(', ');
        let stringValueFormated = "";
        if (selected.length > 0) {
            let correctValue = [];
            selected.map((item, index) => {
                correctValue.push({"text" : item})
            });
            stringValueFormated = JSON.stringify(correctValue);
        }

        onUpdateClose(infos, stringValue,infos.colDef.format,stringValueFormated);
    }


    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>
                <div className={classes.margin_5}>
                    <div className={classes.input_add_item}>
                        <Input
                            autoComplete='off'
                            id="standard-full-width"
                            //label="Label"
                            style={{ margin: 0 }}
                            placeholder={t('common:multiple.add-item')}
                            //helpertext="Full width!"
                            fullWidth={true}
                            margin="none"
                            inputlabelprops={{
                                shrink: true,
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <div onClick={(event) => handleAddValue(newValue)}>
                                    <span className="material-icons">
                                        add
                                    </span>
                                    </div>
                                </InputAdornment>}
                            className={`text-line ${classes.textField}  ${classes.fieldRight} input`}
                            value={newValue}
                            disableUnderline={true}
                            onKeyPress={(e) => { if (e.key === 'Enter') { handleAddValue(newValue)}}}
                            onChange={(event) => setNewValue(event.target.value)}

                        />
                    </div>
                    {
                        selected.map((value,indexation) => {
                            return (
                                <div key={indexation} className={classes.input_view_item}>
                                    <Input
                                        autoComplete='off'
                                        readOnly={true}
                                        id="standard-full-width"
                                        //label="Label"
                                        style={{ margin: 0 }}
                                        placeholder={t('common:multiple.add-item')}
                                        //helpertext="Full width!"
                                        fullWidth={true}
                                        margin="none"
                                        inputlabelprops={{
                                            shrink: true,
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <div onClick={(event) => handleRemoveValue(value)}>
                                                    <span className="material-icons">
                                                        delete
                                                    </span>
                                                </div>
                                            </InputAdornment>
                                        }
                                        className={`text-line ${classes.fieldRight} input`}
                                        value={value}
                                        disableUnderline={true}
                                        onChange={(event) => setNewValue(event.target.value)}

                                    />
                                </div>
                            )
                        })
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={handleUpdateClose} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimpleBirthdayDialog(props) {
    const classes = useStyles();

    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    let currentValue = null;
    if(value !== null && value.length > 0){
        let currentTime = new Date();
        let split = value.split('-');
        currentValue = new Date(currentTime.getFullYear(), split[1] - 1, split[0], null, null);
    }

    const [selectedDate, handleDateChange] = useState(currentValue);
    const [selectedDateValue, handleDateChangeValue] = useState();


    const handleClose = () => {
        onClose(infos, selectedDateValue,infos.colDef.format);
    };

    const handleUpdateClose = () => {
        onUpdateClose(infos, selectedDateValue,infos.colDef.format);
    }

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>
                <KeyboardDatePicker
                    className={classes.fullWidth}
                    variant="inline"
                    format="dd-MM"
                    fullWidth={true}
                    id={'date-picker-inline'}
                    //label={props.field.title}
                    //value={selectedDate}
                    //onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                    value={selectedDate}
                    onChange={(date, value) => {
                        handleDateChange(date);
                        handleDateChangeValue(value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={handleUpdateClose} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimpleQrcodeDialog(props) {
    const classes = useStyles();
    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    let plugcode = typeof value !== 'undefined' ? infos.row.plugnote[infos.field].split(';') : [];

    let initCode = "";
    let initComment = "";

    if(plugcode.length === 2){
        initCode =  (plugcode[0] !== 'undefined' ? plugcode[0] : "");
        initComment =(typeof plugcode[1] !== 'undefined' ? plugcode[1] : "");

    }else if(plugcode.length === 1){
        let constainsCode = plugcode[0].startsWith('PN') || plugcode[0].startsWith('PP') || plugcode[0].startsWith('PF') || plugcode[0].startsWith('PE');
        if(constainsCode){
            initCode= (plugcode[0]);
            initComment =("");
        }else {
            initCode = ("");
            initComment = (plugcode[0]);
        }
    }

    const [code, setCode] = useState(initCode);
    const [comment, setComment] = useState(initComment);

    const handleClose = () => {
        onClose(infos, "(" + code +") "+ comment,infos.colDef.format, code+';'+comment);
    };

    const handleUpdateClose = () => {
        onUpdateClose(infos, "(" + code +") "+ comment,infos.colDef.format, code+';'+comment);
    }

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>
                <div className={classes.margin_5}>
                    <Input
                        autoComplete='off'
                        style={{ margin: 0 }}
                        id="standard-full-width"
                        placeholder="code"
                        fullWidth={true}
                        margin="none"
                        className="text-line"
                        disableUnderline={true}
                        onChange={(event) => {
                            setCode(event.target.value);
                        }}
                        defaultValue={code}
                    />
                    <div className="height_12">
                        <div className="line lightgrey flex_style">
                        </div>
                    </div>
                    <Input
                        disableUnderline={true}
                        style={{ margin: 0 }}
                        autoComplete='off'
                        id="standard-full-width"
                        placeholder="comment"
                        fullWidth={true}
                        multiline={true}
                        margin="none"
                        className="text-line"
                        onChange={(event) => {
                            setComment(event.target.value);
                        }}
                        defaultValue={comment}
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={handleUpdateClose} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimpleTableDialog(props) {
    const classes = useStyles();
    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    const [content, setContent] = useState(value || '');


    const handleClose = () => {
        onClose(infos, content,infos.colDef.format);
    };

    const handleUpdateClose = () => {
        onUpdateClose(infos, content,infos.colDef.format);
    }

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={handleUpdateClose} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function SimplePlugformDialog(props) {
    const classes = useStyles();
    const { onClose, onUpdateClose, value, open,  maxWidth, infos} = props;

    const [content, setContent] = useState(value || '');


    const handleClose = () => {
        onClose(infos, content,infos.colDef.format);
    };

    const handleUpdateClose = () => {
        onUpdateClose(infos, content,infos.colDef.format);
    }

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogTitle id="simple-dialog-title">{infos.colDef.headerName}</DialogTitle>
            <DialogContent>

            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} className={classes.buttonSimple}>
                    Cancel
                </Button>
                <Button onClick={handleUpdateClose} className={classes.buttonSimple}>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const CustomResizeIcon = () => {
    return (
        <div style={{cursor: 'col-resize', height: 45, width: 2, backgroundColor: colors.paleGrey, borderRadius:'50%', right: 0, marginLeft: -14}} />
    )
}

const CustomMenuIcon = () => {
    const { t, } = useTranslation(['common'])
    return (
        <Tooltip placement='top-end' title={t('pluglist.column-menu')}>
            <span className="material-icons md-20 " style={{backgroundColor: '#00000000 !important' , color: colors.mainButton, position: 'absolute', right: -5, top: -25}}>
                more_vert
            </span>
        </Tooltip>
    )
}

const CustomFilterPanel = (props) => {
    // console.log('filter panel props', props);
    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'models', 'global']);
    return (
        <GridPanelContent>
            <div className={classes.filterRowsContainer}>
                <GridFilterPanel
                    filterModel={{
                        items: props.filterSet,
                        linkOperator: props.linkOperator
                    }}
                    onFilterModelChange={(model) => props.handleModelChange(model)}
                />
            </div>

            <div className={classes.filterPanelActionContainer}>
                {/* <div className={classes.btnContainer}>
                <Tooltip title={t('filters.saved-selections')} placement="bottom">
                    <div
                        onClick={() => props.openSelectionList()}
                        // onClick={() => handleOpenListFilterSelection()}
                        className={classes.actionBtn}>
                        <span className="material-icons md-25 ">filter_alt</span>
                        <span className="material-icons md-25 ">format_list_bulleted</span>
                    </div>
                </Tooltip></div> */}
                {props.newFilter ?
                    <div
                        className={`${classes.selectionContainer} ${classes.newSelectionContainer}`}
                        key="new-selection">
                        <TextField
                            error={props.selectionLabel === t("common:filters.new-selection") || props.selectionLabel === ""}
                            variant="standard"
                            fullWidth={false}
                            inputProps={{cursor: 'pointer',}}
                            InputProps={{
                                disableUnderline: false,
                                cursor: 'pointer',
                                endAdornment: (
                                    <React.Fragment>
                                        <Tooltip title={t('filters.save-current')} placement="top">
                                    <span
                                        id="icon-save-selection"
                                        className="material-icons md-30"
                                        style={{color: props.selectionLabel === t("common:filters.new-selection") || props.selectionLabel.trim() === '' ? 'lightgrey': 'black'}}
                                        onClick={(e) => {e.preventDefault(); e.stopPropagation();
                                            if(props.selectionLabel !== t("common:filters.new-selection")) props.handleSave()
                                        }}>
                                        save
                                    </span>
                                        </Tooltip>
                                    </React.Fragment>
                                )
                            }}
                            onFocus={() => {if(props.selectionLabel === t("common:filters.new-selection")) props.setSelectionLabel("")}}
                            onBlur={(e) => {if(e.target.value.trim() === "") props.setSelectionLabel(t("common:filters.new-selection"))}}
                            label={props.selectionLabel === t("common:filters.new-selection") || props.selectionLabel.trim() === '' ? "Please enter a valid name" : "Selection name"}
                            placeholder="Current selection"
                            onChange={(e) => props.setSelectionLabel(e.target.value)}
                            value={props.selectionLabel}
                        />
                        {props.name === parseFilterParameters(props.fields, props.newFilter)? null :
                            <span className={classes.selectionDetails}
                                  onClick={(e) => {e.preventDefault(); e.stopPropagation(); props.handleLoad(props.newFilter)}}>
                    {props.params}
                </span> }
                    </div>
                    : null}
                {props.savedFilter && props.hasChanged?
                    <div
                        className={`${classes.selectionContainer} ${classes.newSelectionContainer}`}
                        key="new-selection">
                        <TextField
                            variant="standard"
                            fullWidth={false}
                            inputProps={{cursor: 'pointer',}}
                            InputProps={{
                                disableUnderline: false,
                                cursor: 'pointer',
                                endAdornment: (props.hasChanged?
                                    <React.Fragment>
                                        {props.savedFilter.authorName.hash === props.environment.userhash ?
                                            <Tooltip title={t('filters.overwrite-selection')} placement="top">
                                    <span
                                        id="icon-save-selection"
                                        className="material-icons md-30"
                                        onClick={(e) => {e.preventDefault(); e.stopPropagation(); props.handleOverwrite()}}>
                                        save_as
                                    </span>
                                            </Tooltip>
                                            : null}
                                        <Tooltip title={props.selectionLabel === props.savedFilter.label || props.selectionLabel.trim() === '' ?t('filters.invalid-save') : t('filters.save-current')} placement="top">
                                    <span
                                        id="icon-save-selection"
                                        className="material-icons md-30"
                                        style={{color: props.selectionLabel === props.savedFilter.label || props.selectionLabel.trim() === '' ? 'lightgrey': 'black'}}
                                        onClick={(e) => {e.preventDefault(); e.stopPropagation();
                                            if(props.selectionLabel !== props.savedFilter.label && props.selectionLabel.trim() !== '')
                                                props.handleSave()}
                                        }>
                                        save
                                    </span>
                                        </Tooltip>
                                    </React.Fragment>
                                    : null)
                            }}
                            // onFocus={() => {if(name === parseFilterParameters(fields, newFilter)) setName("")}}
                            onBlur={(e) => {if(e.target.value.trim() === "") props.setName(props.name)}}
                            label="Current selection"
                            placeholder="Selection name"
                            onChange={(e) => props.setSelectionLabel(e.target.value)}
                            value={props.selectionLabel}
                        />
                        <span className={classes.selectionDetails}>{props.name}</span>
                    </div>
                    : null}
            </div>
        </GridPanelContent>
    )
};

function ActionButtons (props) {
    const classes = useStyles();
    const { t, } = useTranslation(['common', 'models', 'global']);
    return(
        <div className={classes.btnContainer}>
            <Tooltip title={t('filters.saved-selections')} placement="bottom">
                <div
                    onClick={() => {if(props.openSelections) props.setOpenSelections(false); else props.openSelectionList()}}
                    className={classes.actionBtn}>
                    <span className="material-icons md-25 ">filter_alt</span>
                    <span className="material-icons md-25 " style={{marginRight: 5}}>format_list_bulleted</span>
                    {t('filters.filters').toUpperCase()}
                </div>
            </Tooltip>
            {/* Filter from Selection List */}
            {props.savedFilter !== null && props.savedFilter !== undefined && props.filterSet.length > 0 ?
                <div
                    className={classes.actionBtn}>
                    <Tooltip title={t('filters.filter-show')} placement="bottom">
                    <span
                        className="material-icons md-25 "
                        onClick={() => props.handleOpenFilterPanel()}>
                            filter_list
                    </span>
                    </Tooltip>
                    <span className={classes.filterIndicator}>{props.filterSet.length}</span>

                    <span className={classes.selectionName}>{props.savedFilter.label}</span>
                    <Tooltip title={t('filters.filter-off')} placement="bottom" >
                    <span className={classes.deleteBtn} onClick={props.handleDeleteCurrentFilter}>
                        <span className="material-icons">close</span>
                    </span>
                    </Tooltip>
                </div>
                : null
                // TODO Button for Filter from Pluglist
            }
            {(props.savedFilter === null || props.savedFilter === undefined) && props.filterSet.length > 0 ?
                <div
                    className={classes.actionBtn}>
                    <Tooltip title={t('filters.filter-show')} placement="bottom">
                    <span
                        className="material-icons md-25 "
                        onClick={() => props.handleOpenFilterPanel()}>
                            filter_list
                    </span>
                    </Tooltip>
                    <span className={classes.filterIndicator}>{props.filterSet.length}</span>
                    <span className={classes.selectionName}>{props.selectionLabel}</span>
                    <Tooltip title={t('filters.filter-off')} placement="bottom" >
                    <span className={classes.deleteBtn} onClick={props.handleDeleteCurrentFilter}>
                        <span className="material-icons">close</span>
                    </span>
                    </Tooltip>
                </div>
                : null}
        </div>
    )
}

const ViewPlugnotes = ({
                           plugnotes, listView, actif, archived,favoris,
                           handleSelectPlugnote,handleCreatePlugnote, handleFlip, flipped, handleDeletePlugnote,
                           handleArchivePlugnote, handleLockPlugnote, handleFavorisPlugnote,handleModalPlugnotePlugcodeOpen,
                           handleModalPlugnoteClassifyOpen, linkOperator, setLink, selectedFields,
                           attemptUpdatePlugnoteField,
                           attemptGetPlugnotesPaginateByModelHashAndUserHash,hash,modelhash,limitPage,startPage,
                           setSelectedFields, setFilters,setSubOpen,fields, filters, attemptDelAllDataFilter,
                           attemptDelDataFilter, selectedRows, setSelectedRows,defaultSelectedFields, setDefaultSelectedFields,
                           sizeFields, setSizeFields, defaultSizeFields, setDefaultSizeFields,props, filterSet, setFilterSet,exported
                       }) => {
    const classes = useStyles();
    const apiRef = useGridApiRef();
    const environment = getCurrentEnvironnement();
    const { t, i18n } = useTranslation(['common', 'models', 'global']);
    const [openMemo, setOpenMemo] = useState(false);
    const [openAgenda, setOpenAgenda] = useState(false);
    const [openLocalis, setOpenLocalis] = useState(false);
    const [openPassword, setOpenPassword] = useState(false);
    const [openTime, setOpenTime] = useState(false);
    const [openList, setOpenList] = useState(false);
    const [openBirthday, setOpenBirthday] = useState(false);
    const [openQrcode, setOpenQrcode] = useState(false);
    const [openTable, setOpenTable] = useState(false);
    const [openPlugform, setOpenPlugform] = useState(false);
    const [infoCurrentModel, setInfoCurrentModel] = useState(props.model);
    const [openSelections, setOpenSelections] = useState(false)
    const [filterIsOpen, setFilterIsOpen] = useState(false)
    const [retrieved, setRetrieved] = useState(false)
    const [savedFilter, setCurrentFilter] = useState(null)
    const [hasChanged, setHasChanged] = useState(false)
    const [isFromServer, setFromServer] = useState(false)
    const [saveSelection, setSaveSelection] = useState(false)
    const [validFilter, setValidFilter] = useState(false);
    const [hiddenFields, setHiddenFields] = useState([])
    const [selectionParams, setNewName] = useState('')
    const [selectionLabel, setSelectionLabel] = useState(t("common:filters.new-selection"))

    const [infos, setInfos] = useState(null);

    const gridRef = useRef(null);

    const initialRows = [];
    let initColumns = [];

    const handleClose = (info , value, format, valueFormatted) => {
        switch (format) {
            case "textarea":
                setOpenMemo(false);
                break;
            case "agenda":
                setOpenAgenda(false);
                break;
            case "localis":
                setOpenLocalis(false);
                break;
            case "password":
                setOpenPassword(false);
                break;
            case "time":
                setOpenTime(false);
                break;
            case "list":
                setOpenList(false);
                break;
            case "birthday":
                setOpenBirthday(false);
                break;
            case "qrcode":
                setOpenQrcode(false);
                break;
            case "table":
                setOpenTable(false);
                break;
            case "plugform":
                setOpenPlugform(false);
                break;
            default:
                break;
        }


        setInfos(null);
    };

    const handleUpdateClose = (infos , value, format, valueFormatted) => {
        switch (format) {
            case "textarea":
                setOpenMemo(false);
                break;
            case "agenda":
                setOpenAgenda(false);
                break;
            case "localis":
                setOpenLocalis(false);
                break;
            case "password":
                setOpenPassword(false);
                break;
            case "time":
                setOpenTime(false);
                break;
            case "list":
                setOpenList(false);
                break;
            case "birthday":
                setOpenBirthday(false);
                break;
            case "qrcode":
                setOpenQrcode(false);
                break;
            case "table":
                setOpenTable(false);
                break;
            case "plugform":
                setOpenPlugform(false);
                break;
            default:
                break;
        }

        const {id, field} = infos;

        const updatedRows = rows.map((row) => {
            let fieldName = field;
            if (row.hash === id ) {

                if(typeof row.plugnote[fieldName+'format'] === "undefined" || row.plugnote[fieldName+'format']  ===  null){
                    row.plugnote[fieldName+'format'] = format;
                }

                if(row.plugnote[fieldName+'format'] === 'qrcode'){
                    row.plugnote[fieldName]  = valueFormatted;
                }else if(row.plugnote[fieldName+'format'] === 'list'){
                    row.plugnote[fieldName]  = valueFormatted;
                }
                else {
                    row.plugnote[fieldName]  = value;
                }


                if(row.plugnote[fieldName+'format'] !== 'qrcode' && row.plugnote[fieldName+'format'] !== 'list'){
                    attemptUpdatePlugnoteField(props.model.hash, row.plugnote.hash, environment.userhash, {
                        field : fieldName,
                        value : value
                    });
                }else {
                    attemptUpdatePlugnoteField(props.model.hash, row.plugnote.hash, environment.userhash, {
                        field : fieldName,
                        value : valueFormatted
                    });
                }

                if(row.plugnote[fieldName+'format'] !== 'password'){
                    return { ...row,
                        [fieldName]: value,
                        plugnote : row.plugnote
                    };
                }
                else {
                    return {
                        ...row,
                        [fieldName] : value.length > 0 ?  '*'.repeat(6) : "",
                        plugnote : row.plugnote
                    }
                }
            }
            return row;
        });
        setRows(updatedRows);
        setInfos(null);
    };

    const viewAction = (object) => {
        return (
            <div onClick={(event) => {
                handleSelectPlugnote(object.row.plugnote);
            }} style={{ cursor: "pointer", width : "100%" }}>
                <span className="material-icons md-small">
                    zoom_out_map
                </span>
            </div>
        )
    };

    const headerView = (object) => {
        return (
            <div className="flex_display_direction_column" >
                <span className="material-icons md-small">
                        zoom_out_map
                </span>
            </div>
        )
    };

    const headerIdView = (object) => {
        return (
            <DraggableHeaderRenderer onColumnsReorder={handleColumnsReorder} column={object.column}>
                <div
                    style={{
                        color : '#58C3C1'
                    }}
                    className="flex_display_direction_column"
                >
                    <span className="material-icons md-25 md-small " onClick={(event) => handleRemoveColumnVisibility(event, object.column.item)}>
                        {selectedFields.includes(object.column.item) ? 'check_box' : 'check_box_outline_blank'}
                </span>
                    <span>
                    {object.column.name}
                </span>
                </div>
            </DraggableHeaderRenderer>
        );
    }

    const headerTitleView = (object) => {
        let item = object.colDef.item;
        let editable = object.colDef.editable;
        let complex = typeof object.colDef.complex !== "undefined" ? object.colDef.complex : false;
        let result = filters.find((element, indexField) => {
            if(typeof element.field !== "undefined" && element.field !== null){
                return  element.field.id === item
            }
        });
        let findIndex = filters.findIndex((element, indexField) => {
            if(typeof element.field !== "undefined" && element.field !== null){
                return  element.field.id === item
            }
        });
        let template = getTemplateFormatText(props.model['field'+item+'format'],props.model['field'+item+'formatoptions']);
        let fieldNb =  ' ( ' + item + ' )';
        let formatNb =  '( ' + template +' )';

        return (
            <div className="flex_display_direction_column" style={{width : "100%", textAlign : "center"}}>
                <span className="bold"  style={{width : "100%", textAlign : "center", paddingTop: 10, paddingLeft: 10}}>
                    {object.colDef.headerNameCustom}
                    <div
                        // className={!rowError ? "headerPlugLlist" : fieldFiltered === "" ?  "headerPlugLlist4" : "headerPlugLlist5"}
                        style={{position: 'absolute', top: -5, left: 1}}>
                        <span style={{fontSize : 9}}>{fieldNb}</span>
                    </div>
                </span>
                <div className="text_align" style={{width : "100%"}}>
                    {
                        complex ?
                            <div className="flex_display_direction_row_center" style={{width : "100%"}}>
                                <Tooltip  title={t('common:helper.edit-possible-via-modal')} aria-label="add" placement="top-start">
                                    <span className={`material-icons md-15 orange`}> create </span>
                                </Tooltip>
                                <span style={{fontSize : '10px'}}>{formatNb}</span>
                            </div>
                            :
                            <div className="flex_display_direction_row_center" style={{width : "100%"}}>
                                <Tooltip  title={editable ? t('common:helper.edit-possible') : t('common:helper.edit-not-possible')} aria-label="add" placement="top-start">
                                    <span className={`material-icons md-15 ${editable ? 'green' : 'red'}`}> create </span>
                                </Tooltip>
                                <span style={{fontSize : '10px'}}>{formatNb}</span>
                            </div>
                    }
                </div>
            </div>
        );
    }

    const headerSpecialTitleView = (object) => {

        return (
            <div className="flex_display_direction_column" style={{width : "100%", textAlign : "center"}}>
                <span className="green bold">{object.colDef.headerName}</span>
                {/* <div className="text_align" style={{width : "100%"}}>
                    <div className="flex_display_direction_row" style={{width : "100%"}}>
                        <div className="headerPlugLlist"><span style={{fontSize : '10px'}}>&nbsp;</span></div>
                        <div className="headerPlugLlist"><span className={`material-icons md-15`}> zoom_out_map </span></div>
                        <div className="headerPlugLlist"><span style={{fontSize : '10px'}}>&nbsp;</span></div>
                    </div>
                </div> */}
            </div>
        );
    }

    const headerSpecialTitleViewAnnexe = (object) => {
        return (
            <div  className="flex_display_direction_column" style={{width : "100%", textAlign : "center"}}>
                <span className={`material-icons md-small green bold fa-rotate-90`}>
                    attachment
                </span>
                {/* <div className="text_align" style={{width : "100%"}}>
                    <div className="flex_display_direction_row" style={{width : "100%"}}>
                        <div className="headerPlugLlist"><span style={{fontSize : '10px'}}>&nbsp;</span></div>
                        <div className="headerPlugLlist"><span className={`material-icons md-15`}> zoom_out_map </span></div>
                        <div className="headerPlugLlist"><span style={{fontSize : '10px'}}>&nbsp;</span></div>
                    </div>
                </div> */}
            </div>
        )
    }

    const onRowClick = (object) => {
        handleSelectPlugnote(object.row.plugnote);
    }

    const onTablemultiClick = (object) => {
        let table = {field: object.field, hash: object.row.plugnote.hash}
        setTableView(table)
        onRowClick(object)
    }

    // const specialIndexAction = (object) => {
    //     return (
    //         <div onClick={(event) => {onRowClick(object)}} style={{ cursor: "pointer", width : "100%" }}>
    //             <span>
    //                 {object.value}
    //             </span>
    //         </div>
    //     )
    // };

    const specialAction = (object) => {
        let totals = []
        let count = 0
        switch (object.colDef.format) {
            case 'mail':
                return (
                    <div>
                        <span>
                            <a href={`mailto:${object.value}`}>{object.value}</a>
                        </span>
                    </div>
                );
            case 'phone':
                return (
                    <div>
                        <span>
                            <a href={`tel:${object.value}`}>{object.value}</a>
                        </span>
                    </div>
                );
            case 'smailvalidmulti':
                count = isJsonStringObjectAndCountSubElement(object?.value, 'list') || 0
                let fieldTxt = isJsonStringObjectAndGetElement(object?.value, 'value') || ""
                return (
                    <div>
                        <span>
                            {
                                fieldTxt.toString() !== "" &&
                                <span>{fieldTxt.toString()}  <span> - </span> </span>
                            }
                            {'(' + count.toString() + ')'}
                        </span>
                    </div>
                );
            case 'tablemulti':
                let hasRecap = object.formattedValue?.includes('recap')
                // if (object.field === "field11") console.log('isJSon...?', object);
                count = isJsonStringObjectAndCount(object.value)
                if (hasRecap && count > 0) { count -= 1 }
                if (hasRecap) {
                    let field = JSON.parse(props.model[object.field + 'formatoptions'])
                    let recapVal = null;
                    if(isJsonStringObject(object?.value)){
                        recapVal  = JSON.parse(object?.value);
                    }
                    if(!isNull(recapVal) && typeof recapVal !== "undefined"){
                        recapVal = recapVal.find((obj) => obj.hasOwnProperty('recap'))
                        if(!isNull(recapVal) && typeof recapVal !== "undefined"){
                            Object.keys(recapVal).forEach((key, index) => {
                                if(field?.lines){
                                    if (key !== 'recap') {
                                        let line = field.lines.find((line) => line.id.toString() === key.toString())
                                        if (isDefined(line)) {
                                            let value = {label: line.label, value: recapVal[key], index: field.lines.indexOf(line) + 1}
                                            totals.push(value)
                                        }
                                    }
                                } else {
                                    if (key !== 'recap') {
                                        let line = field.find((line) => line.id.toString() === key.toString())
                                        if (isDefined(line)) {
                                            let value = {label: line.label, value: recapVal[key], index: field.indexOf(line) + 1}
                                            totals.push(value)
                                        }
                                    }
                                }
                            })
                        }
                    }
                }
                return (
                    <div onClick={(event) => {onTablemultiClick(object)}} style={{width : "100%", textAlign : "left", cursor: "pointer"}}>
                        {'(' + count.toString() + ')'}
                        {
                            hasRecap && totals.length > 0 &&
                            totals.map((entry, id) => {
                                return(
                                    <Tooltip key={"tooltip-total-" + entry.value + id} placement='top' title={entry.label + ': ' + entry.value}>
                                    <span className={classes.totalValue} key={"total-" + entry.value + id}>
                                        {` - `}
                                        <span className={classes.fieldNumber}>{entry.index}</span>
                                        <span className={classes.fieldTotal}>{entry.value}</span>
                                    </span>
                                    </Tooltip>
                                )
                            })
                        }
                    </div>
                );
            case 'document':
            case 'tablemultin':
                // console.log('object tablemultin', object, props.model)
                let recap = object.formattedValue?.includes('recap')
                let rowCount = isJsonStringObjectAndCountSubElement(object?.value, 'list')
                if (recap && rowCount > 0) rowCount -= 1
                if (recap) {
                    let field = JSON.parse(props.model[object.field + 'formatoptions'])
                    let recapVal = null;
                    if(isJsonStringObject(object?.value)){
                        recapVal  = JSON.parse(object?.value);
                    }
                    if(!isNull(recapVal) && typeof recapVal !== "undefined"){
                        if (recapVal.list !== undefined) recapVal = recapVal.list;
                        recapVal = recapVal.find((obj) => obj.hasOwnProperty('recap'))
                        if(!isNull(recapVal) && typeof recapVal !== "undefined"){
                            Object.keys(recapVal).forEach((key, index) => {
                                if (key !== 'recap') {
                                    let line = field.lines.find((line) => line.id.toString() === key.toString())
                                    if (isDefined(line)) {
                                        let value = { label: line.label, value: recapVal[key], index: field.lines.indexOf(line) + 1 }
                                        totals.push(value)
                                    }
                                }
                            })
                        }
                    }

                }
                return (
                    <div onClick={(event) => {onTablemultiClick(object)}} className={classes.cellContent}>
                        {'(' + rowCount.toString() + ')'}
                        {
                            recap && totals.length > 0 &&
                            totals.map((entry, id) => {
                                return(
                                    <Tooltip key={"tooltip-total-" + entry.value + id} placement='top' title={entry.label + ': ' + entry.value}>
                                    <span className={classes.totalValue} key={"total-" + entry.value + id}>
                                        {` - `}
                                        <span className={classes.fieldNumber}>{entry.index}</span>
                                        <span className={classes.fieldTotal}>{entry.value}</span>
                                    </span>
                                    </Tooltip>
                                )
                            })
                        }
                    </div>
                );
            case 'url':
                return (
                    <div>
                        <span>
                            <a href={object.value} target="_blank">{object.value}</a>
                        </span>
                    </div>
                );
            default :
                if (specialFields.includes(object.colDef.format)) {
                    return(
                        <div>
                            <span>
                                {object.value}
                            </span>
                        </div>
                    )
                }
                else {
                    switch (object.field) {
                        case "annexe":
                            // console.log('object', object);
                            return (
                                <div onClick={(event) => {onRowClick(object)}}  style={{width : "100%", textAlign : "center", cursor: "pointer"}}>
                                    { object.value === 0 ?
                                        <span style={{color: colors.frost}}>
                                            0
                                        </span>
                                        :
                                        <span>
                                            {object.value}
                                        </span>
                                    }
                                </div>
                            )
                        //! Case userindex pour mettre en forme la valeur numérique tout en la traitant comme un nombre
                        case "userindex":
                            return (
                                <div onClick={(event) => {onRowClick(object)}} style={{width : "100%", textAlign : "center", cursor: "pointer"}}>
                                    {typeof object.value !== "undefined" && object.value >= 0 ?
                                        <span>
                                    {'[' + environment.envIndex + '-' + object.value + ']'}
                                </span>
                                        :
                                        <span>
                                &nbsp;
                                </span>
                                    }
                                </div>)
                        default:
                            return (
                                <div onClick={(event) => {onRowClick(object)}}  style={{width : "100%", textAlign : "center", cursor: "pointer"}}>
                                    {typeof object.value !== "undefined" && object.value.toString().length > 0 ?
                                        <span>
                                    {object.value}
                                </span>
                                        :
                                        <span>
                                &nbsp;
                                </span>
                                    }
                                </div>
                            )}}
        }
    };

    const dateAction = (object) => {
        return(
            <div className="cursor" onClick={(event) => {onRowClick(object)}} style={{ cursor: "pointer", width : "100%" }}>
                <span>
                    {object.value}
                </span>
            </div>
        )
    };

    const handleClickQrCode = (object) => {
        setPlugnote(object);
        handleModalPlugnotePlugcodeOpen(object);
    };

    const plugcodeAction = (object) => {
        return (
            <div style={{ cursor: "pointer", width : "100%", textAlignLast: 'center'}}>
                {object.value.length > 0 &&
                    <span className={classes.plugcodeCtn}>
                    {object.value}
                        {
                            object.row.plugnote?.pncode_countopen > 0 && <span className="references_8"> ({object.row.plugnote?.pncode_countopen}) </span>
                        }
                </span>
                }
            </div>
        )
    }

    const allActions = (object) => {
        return (
            <div className="display_flex float_right grey">
                <PopupState variant="popover" popupId={object.row.plugnote.hash}>
                    {(popupState) => (
                        <div>
                            <span aria-describedby={object.row.plugnote.hash} className="material-icons md-medium" {...bindTrigger(popupState)}>
                                more_horiz
                            </span>
                            <Popover
                                {...bindPopover(popupState)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Box p={2}>
                                    <div className="flex_display_direction_column">
                                        <span className="p3 cursor"  onClick={(event) => handleFavorisPlugnote(object.row.plugnote)}>{t('common:plugnote.favorite')}
                                            <span className={"material-icons md-25 padding_0_10 " + (object.row.plugnote.favorite ? 'green' : 'black')}>star</span>
                                        </span>
                                        <span className="p3 cursor"  onClick={(event) => handleArchivePlugnote(object.row.plugnote)}>{ object.row.plugnote.isarchived ? t('common:plugnote.unarchive') : t('common:plugnote.archive')}
                                            {
                                                object.row.plugnote.isarchived ?
                                                    <span id="icon-archive" className="material-icons md-25 padding_0_10">
                                                        archive
                                                    </span>
                                                    :
                                                    <span id="icon-archive" className="material-icons md-25 padding_0_10">
                                                        unarchive
                                                    </span>
                                            }
                                        </span>
                                        <span className="p3 cursor"  onClick={(event) => handleLockPlugnote(object.row.plugnote)}>{ object.row.plugnote.locked ? t('common:plugnote.unlock') : t('common:plugnote.lock')}
                                            {
                                                object.row.plugnote.locked === true ?
                                                    <span id="icon-verouiller" className="material-icons md-25 black padding_0_10">
                                                        lock
                                                    </span>
                                                    :
                                                    <span id="icon-verouiller" className="material-icons md-25 black padding_0_10">
                                                        lock_open
                                                    </span>
                                            }
                                        </span>
                                        <span className="p3 cursor" onClick={(event) => {handleClickQrCode(object.row.plugnote)}}> {t('common:plugnote.manage-plugcode')}
                                            <span className="material-icons md-25 black padding_0_20" >qr_code</span>
                                        </span>
                                        {
                                            props.model.ms_delete_fiche > 0 &&
                                            <span className="p3 cursor" onClick={(event) => handleDeletePlugnote(object.row.plugnote)}>{t('common:plugnote.delete')}
                                                <span className="material-icons md-25 black padding_0_10" >delete</span>
                                        </span>
                                        }

                                    </div>
                                </Box>
                            </Popover>
                        </div>
                    )}
                </PopupState>
            </div>
        )
    }

    const handleRemoveColumnVisibility = (event, indice) => {
        event.preventDefault();
        event.stopPropagation();
        setSelectedFields(selectedFields.filter((e)=>(e !== indice)))
    }

    const handleActiveFilterSearch = (event, item, ) => {
        event.preventDefault();
        event.stopPropagation();
        setSubOpen(true);

        let result = fields.find((field, indexField) => field.id === item);

        if(typeof result !== "undefined"){
            setFilters(oldArray => [...oldArray, {position : oldArray.length + 1, field : result, value : null, query : null}])
        }
    }

    const handleUnActiveFilterSearch = (event, item, findIndex, result) => {
        event.preventDefault();
        event.stopPropagation();
        setFilters(filters.filter((item, index) => {
            return findIndex !== index;
        }));
        attemptDelDataFilter(result.field.key);
    };

    const headerActionsTitleView = (object) => {
        return (
            <div className="flex_display_direction_column">
                <span className="">{object.colDef.headerName}</span>
            </div>
        );
    }

    let preSizeFields = [];

    let checkExistingSizeFields = JSON.parse(localStorage.getItem('sizeFields'));

    if(checkExistingSizeFields){
        let findIndex = checkExistingSizeFields.findIndex(item  => item.hash === props.model.hash);
        if(findIndex !== -1){
            preSizeFields =checkExistingSizeFields[findIndex].fields;
        }
    }

    const FilterNumberInput = (props) => {
        const { item, applyValue } = props
        const handleFilterChange = (event) => {
            applyValue({ ...item, value: event.target.value });
        };
        return (
            <TextField
                defaultValue={item.value}
                onChange={handleFilterChange}
                style={{margin: '0 5px', textAlignLast: 'center',}}
                type='number'
            />
        )
    }

    const DateTimeInput = (props) => {
        const { item, applyValue } = props
        const handleFilterChange = (event) => {
            applyValue({ ...item, value: event.target.value });
        };
        let mask = ""
        let label = ""
        switch (props.type) {
            case 'birthday':
                mask = "99-99"
                label = "dd-MM"
                break;
            case 'date':
                mask = "99-99-99"
                label = "Date"
                break;
            case 'time':
                mask = "99:99"
                label = "Time"
                break;
            default:
                break;
        }
        return (
            <InputMask
                maskChar="_"
                mask={mask}
                value={item.value}
                onChange={handleFilterChange}
            >
                {(inputProps) =>
                    <TextField
                        label={label}
                        name="custom-time-input"
                        {...inputProps}
                    />}
            </InputMask>
        );
    }
    DateTimeInput.propTypes = {
        applyValue: PropTypes.func.isRequired,
        item: PropTypes.shape({
            columnField: PropTypes.string,
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            operatorValue: PropTypes.string,
            value: PropTypes.any,
        }).isRequired,
    };

    const emptyOperators = [{
        label: 'is empty',
        value: 'isEmpty',
        getApplyFilterFn: (filterItem, column) => {
            if (!filterItem.columnField || !filterItem.operatorValue) {
                return null;
            }
            return (params) => {
                return params.value === "" || params.value === undefined
            };
        },
    },
        {
            label: 'is not empty',
            value: 'isNotEmpty',
            getApplyFilterFn: (filterItem, column) => {
                if (!filterItem.columnField || !filterItem.operatorValue) {
                    return null;
                }
                return (params) => {
                    return params.value !== ""
                };
            },
        }]

    const timeOperators = [{
        label: 'at',
        value: 'at',
        getApplyFilterFn: (filterItem, column) => {
            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                return null;
            }
            return (params) => {
                if (filterItem.value.includes('_')) return true
                return params.value === filterItem.value
            };
        },
        InputComponent: DateTimeInput,
        InputComponentProps: { type: 'time' },
    },
        {
            label: 'before',
            value: 'before',
            getApplyFilterFn: (filterItem, column) => {
                if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                    return null;
                }
                return (params) => {
                    if (filterItem.value.includes('_')) return true
                    return params.value < filterItem.value
                };
            },
            InputComponent: DateTimeInput,
            InputComponentProps: { type: 'time' },
        },
        {
            label: 'after',
            value: 'after',
            getApplyFilterFn: (filterItem, column) => {
                if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                    return null;
                }
                return (params) => {
                    if (filterItem.value.includes('_')) return true
                    return params.value > filterItem.value
                };
            },
            InputComponent: DateTimeInput,
            InputComponentProps: { type: 'time' },
        },
        ...emptyOperators
    ]
    const annexesOperators = [
        {
            "label": " = ",
            "value": "=",
            getApplyFilterFn: (item, col) => {
                if (isNaN(item.value)) return true;
                return (params) => {
                    if (item.value === 0) return (isNaN(params.value) || params.value === 0 || typeof params.value === 'undefined');
                    else return item.value === params.value
                }
            },
            InputComponent: FilterNumberInput,
            InputComponentProps: {
                type: "number"
            }
        },
        {
            "label": " ≠ ",
            "value": "!=",
            getApplyFilterFn: (item, col) => {
                if (isNaN(item.value)) return true;
                return (params) => {
                    if (item.value === 0) return (!isNaN(params.value) && params.value !== 0 && typeof params.value !== 'undefined');
                    else return item.value !== params.value
                }
            },
            InputComponent: FilterNumberInput,
            InputComponentProps: {
                type: "number"
            }
        },
        {
            "label": " < ",
            "value": "<",
            getApplyFilterFn: (item, col) => {
                if (isNaN(item.value)) return true;
                return (params) => {
                    if (item.value === 0) return false;
                    if (isNaN(params.value) || params.value === 0 || typeof params.value === 'undefined') return true;
                    else return item.value > params.value
                }
            },
            InputComponent: FilterNumberInput,
            InputComponentProps: {
                type: "number"
            }
        },
        {
            "label": " > ",
            "value": ">",
            getApplyFilterFn: (item, col) => {
                if (isNaN(item.value)) return true;
                return (params) => {
                    if (item.value === 0 && !isNaN(params.value) && params.value !== 0 && typeof params.value !== 'undefined') return true;
                    if (isNaN(params.value) || params.value === 0 || typeof params.value === 'undefined') return false;
                    else return item.value < params.value
                }
            },
            InputComponent: FilterNumberInput,
            InputComponentProps: {
                type: "number"
            }
        },
        {
            label: 'none',
            value: 'none',
            getApplyFilterFn: (item, col) => {
                return (params) => {
                    // console.log('item /params', item, params);
                    if (isNaN(params.value) || params.value === 0 || typeof params.value === 'undefined') return true;
                    else return false
                }
            },
            InputComponent: null,
        },
    ]

    const dateOperators = (type) => [{
        label: 'on',
        value: 'on',
        getApplyFilterFn: (filterItem, column) => {
            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue ) {
                return null;
            }
            return (params) => {
                if (filterItem.value.includes('_')) return true
                return params.value === filterItem.value
            };
        },
        InputComponent: DateTimeInput,
        InputComponentProps: { type: type },
    },
        {
            label: 'before',
            value: 'before',
            getApplyFilterFn: (filterItem, column) => {
                if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                    return null;
                }
                return (params) => {
                    if (filterItem.value.includes('_')) return true

                    switch (type) {
                        case 'date':
                            return new Date('20' + params.value + 'Z') < new Date('20' + filterItem.value + 'Z');
                        case 'birthday':
                            let fMonth = parseInt(filterItem.value.substr(3,2))
                            let pMonth = parseInt(params.value.substr(3,2))
                            let fDay = parseInt(filterItem.value.substr(0,2))
                            let pDay = parseInt(params.value.substr(0,2))
                            if(fMonth > pMonth) return true;
                            else if (fMonth === pMonth) return pDay < fDay;
                            else return false
                        default:
                            return params.value < filterItem.value
                    }
                };
            },
            InputComponent: DateTimeInput,
            InputComponentProps: { type: type },
        },
        {
            label: 'after',
            value: 'after',
            getApplyFilterFn: (filterItem, column) => {
                if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
                    return null;
                }
                return (params) => {
                    if (filterItem.value.includes('_')) return true

                    switch (type) {
                        case 'date':
                            return new Date('20' + params.value + 'Z') > new Date('20' + filterItem.value + 'Z');
                        case 'birthday':
                            let fMonth = parseInt(filterItem.value.substr(3,2))
                            let pMonth = parseInt(params.value.substr(3,2))
                            let fDay = parseInt(filterItem.value.substr(0,2))
                            let pDay = parseInt(params.value.substr(0,2))
                            if(fMonth < pMonth) return true;
                            else if (fMonth === pMonth) return pDay > fDay;
                            else return false
                        default:
                            return params.value > filterItem.value
                    }
                };
            },
            InputComponent: DateTimeInput,
            InputComponentProps: { type: type },
        },
        ...emptyOperators
    ]

    if(plugnotes.length > 0 && props.model !== null){
        initColumns.push({ field: 'view',  key: 'view', headerName:  ' ', resizable: false, sortable : false, order : false, renderHeader: headerView, renderCell : viewAction, width: 65, align : 'center'});

        selectedFields.map((item, index) => {
            let find = preSizeFields.findIndex(elm => elm.field === item);
            let size = find !== -1 ? preSizeFields[find].width : 170;
            if(item === 0){
                size = find !== -1 ? preSizeFields[find].width : 100;
            }else if(item === 20){
                size = find !== -1 ? preSizeFields[find].width : 90;
            }
            if(item !== 0 && item !== 16 && item !== 17 && item !== 18 && item !== 19 && item !== 20 ){
                let visible = true;

                const setup = getFieldSetup(props.model['field'+item+'formatoptions']);

                if(!isNull(setup) && typeof setup !== "undefined"){
                    if(setup.hasOwnProperty('visible')){
                        visible = setup.visible;

                    }
                }

                if(visible){
                    if(props.model['field'+item+'label'] !== null){
                        let template = getTemplateFormatText(props.model['field'+item+'format'],props.model['field'+item+'formatoptions']);
                        switch (props.model['field'+item+'format']) {
                            case 'textarea':
                            case "agenda":
                                initColumns.push({
                                    cellClassName: (params) => {
                                        let regEx = /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}$/;
                                        return  clsx('super-app', {
                                            negative:  typeof params.value !== "undefined" && params.value.length > 0 && params.value.match(regEx)  === null
                                        })
                                    },
                                    complex : true,
                                    editable: specialFields.includes(props.model['field'+item+'format']),
                                    field: 'field' + item,
                                    format : props.model['field'+item+'format'],
                                    headerAlign: 'left',
                                    headerName:  props.model['field'+item+'label'] + ' ('+ item +') ('+  template +')',
                                    headerNameCustom:  props.model['field'+item+'label'],
                                    hide : false,
                                    item : item,
                                    key: 'field' + item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    resizable: true,
                                    rowError : plugnotes.filter((plug) => {
                                        let regEx = /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}$/;
                                        return typeof plug['field'+item] !== "undefined" && plug['field'+item].length > 0 && plug['field'+item].match(regEx)  === null
                                    }),
                                    sortable : true,
                                    special : false,
                                    type: 'date',
                                    width: size,
                                });
                                break;
                            case "localis":
                            case "password":
                            case "time":
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field'+item+'label'] + ' ('+ item +') ('+  template +')',
                                    headerNameCustom:  props.model['field'+item+'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    headerAlign: 'left',
                                    filterOperators: timeOperators,
                                    format : props.model['field'+item+'format'],
                                    hide : false,
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(props.model['field'+item+'format']),
                                    rowError : plugnotes.filter((plug) => {
                                        let regEx = /^\d{2}:\d{2}$/;
                                        return typeof plug['field'+item] !== "undefined" && plug['field'+item].length > 0 && plug['field'+item].match(regEx)  === null
                                    }),
                                    cellClassName: (params) => {

                                        let regEx = /^\d{2}:\d{2}$/;
                                        return  clsx('super-app', {
                                            negative: typeof params.value !== "undefined" &&  params.value.length > 0 && params.value.match(regEx)  === null
                                        })
                                    },
                                    complex : true,
                                });
                                break;
                            case "list":
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field' + item + 'label'] + ' (' + item + ') (' +  template + ')',
                                    headerNameCustom:  props.model['field' + item + 'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    headerAlign: 'left',
                                    format : props.model['field' + item + 'format'],
                                    hide : false,
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(props.model['field' + item + 'format']),
                                    complex : true,
                                });
                                break;
                            case "birthday":
                                initColumns.push({
                                    field: 'field' + item,
                                    filterOperators: dateOperators('birthday'),
                                    key: 'field' + item,
                                    headerName:  props.model['field'+item+'label'] + ' ('+ item +') ('+  template +')',
                                    headerNameCustom:  props.model['field'+item+'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    headerAlign: 'left',
                                    format : props.model['field'+item+'format'],
                                    hide : false,
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(props.model['field'+item+'format']),
                                    rowError : plugnotes.filter((plug) => {
                                        let regEx = /^\d{2}-\d{2}$/;
                                        return typeof plug['field'+item] !== "undefined" &&  plug['field'+item].length > 0 && plug['field'+item].match(regEx)  === null
                                    }),
                                    cellClassName: (params) => {

                                        let regEx = /^\d{2}-\d{2}$/;
                                        return  clsx('super-app', {
                                            negative: typeof params.value !== "undefined" &&  params.value.length > 0 && params.value.match(regEx)  === null
                                        })
                                    },
                                    complex : true,
                                });
                                break;
                            case "qrcode":
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field'+item+'label'] + ' ('+ item +') ('+  template +')',
                                    headerNameCustom:  props.model['field'+item+'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    headerAlign: 'center',
                                    format : props.model['field'+item+'format'],
                                    hide : false,
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(props.model['field'+item+'format'])  && props.model.ms_edit_fiche,
                                    complex : props.model.ms_edit_fiche === 1,
                                });
                                break;
                            case 'phone':
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field'+item+'label'] + ' ('+ item +') ('+  template +')',
                                    headerNameCustom:  props.model['field'+item+'label'],
                                    resizable: true,
                                    hide : false,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    renderEditCell: renderPhoneEditInputCell,
                                    format : props.model['field'+item+'format'],
                                    headerAlign: 'center',
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(props.model['field'+item+'format'])  && props.model.ms_edit_fiche
                                });
                                break;
                            case 'numeric':
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field'+item+'label'] + ' ('+ item +') ('+  template +')',
                                    headerNameCustom:  props.model['field'+item+'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    type : 'number',
                                    hide : false,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    headerAlign: 'center',
                                    format : props.model['field'+item+'format'],
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(props.model['field'+item+'format'])  && props.model.ms_edit_fiche
                                });
                                break;
                            case 'select':
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field'+item+'label'] + ' ('+ item +') ('+  template +')',
                                    headerNameCustom:  props.model['field'+item+'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    format : props.model['field'+item+'format'],
                                    special : false,
                                    hide : false,
                                    width: size,
                                    headerAlign: 'center',
                                    renderEditCell: renderSelectEditInputCell,
                                    editable: specialFields.includes(props.model['field'+item+'format'])  && props.model.ms_edit_fiche,
                                    options : props.model['field'+item+'formatoptions']
                                });
                                break;
                            case 'date':
                                initColumns.push({
                                    cellClassName: (params) => {
                                        let regEx = /^\d{4}-\d{2}-\d{2}$/;
                                        return  clsx('super-app', {
                                            negative: typeof params.value !== "undefined" && params.value.length > 0 && params.value.match(regEx)  === null
                                        })
                                    },
                                    editable: specialFields.includes(props.model['field'+item+'format']),
                                    field: 'field' + item,
                                    format : props.model['field'+item+'format'],
                                    headerAlign: 'left',
                                    headerName:  props.model['field'+item+'label'] + ' ('+ item +') ('+  template +')',
                                    headerNameCustom:  props.model['field'+item+'label'],
                                    hide : false,
                                    item : item,
                                    key: 'field' + item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    resizable: true,
                                    rowError : plugnotes.filter((plug) => {
                                        let regEx = /^\d{4}-\d{2}-\d{2}$/;
                                        return typeof plug['field'+item] !== "undefined" && plug['field'+item].length > 0 && plug['field'+item].match(regEx)  === null
                                    }),
                                    special : false,
                                    sortable : true,
                                    type : 'date',
                                    width: size,
                                });
                                break;
                            case 'boolean':
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field'+item+'label'] + ' ('+ item +') ('+  template +')',
                                    headerNameCustom:  props.model['field'+item+'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    type : 'boolean',
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    format : props.model['field'+item+'format'],
                                    special : false,
                                    hide : false,
                                    width: size,
                                    headerAlign: 'center',
                                    valueFormatter: ({ value }) => value === '1',
                                    editable: specialFields.includes(props.model['field'+item+'format'])  && props.model.ms_edit_fiche
                                });
                                break;
                            case 'now':
                                initColumns.push({
                                    cellClassName: (params) => {
                                        let regEx = /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}$/;
                                        return  clsx('super-app', {
                                            negative: typeof params.value !== "undefined" && params.value.length > 0 && params.value.match(regEx)  === null
                                        })
                                    },
                                    editable: specialFields.includes(props.model['field'+item+'format']),
                                    field: 'field' + item,
                                    format : props.model['field'+item+'format'],
                                    headerAlign: 'left',
                                    headerName:  props.model['field'+item+'label'] + ' ('+ item +') ('+  template +')',
                                    headerNameCustom:  props.model['field'+item+'label'],
                                    hide : false,
                                    item : item,
                                    key: 'field' + item,
                                    resizable: true,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    rowError : plugnotes.filter((plug) => {
                                        let regEx = /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}$/;
                                        return typeof plug['field'+item] !== "undefined" && plug['field'+item].length > 0 && plug['field'+item].match(regEx);
                                    }),
                                    sortable : true,
                                    special : false,
                                    type: 'date',
                                    width: size,
                                });
                                break;
                            default:
                                initColumns.push({
                                    editable: specialFields.includes(props.model['field'+item+'format'])  && props.model.ms_edit_fiche,
                                    field: 'field' + item,
                                    format : props.model['field'+item+'format'],
                                    headerAlign: 'center',
                                    headerName:  props.model['field'+item+'label'] + ' ('+ item +') ('+  template +')',
                                    headerNameCustom:  props.model['field'+item+'label'],
                                    hide : false,
                                    item : item,
                                    key: 'field' + item,
                                    resizable: true,
                                    sortable : true,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    special : false,
                                    width: size,
                                });
                                break;
                        }
                    }
                }
            }
            else {

                if(item === 0){
                    initColumns.push({
                        field: 'userindex',
                        key: 'userindex',
                        headerName: "N°",
                        resizable: true,
                        sortable : true,
                        item : 0,
                        renderHeader: headerSpecialTitleView,
                        renderCell : specialAction,
                        special : true,
                        hide : false,
                        headerAlign: 'center',
                        type: "number",
                        width: size,
                    });
                }

                if(item === 20){
                    initColumns.push({
                        align : 'center',
                        field: 'annexe',
                        headerName: "Nbr d'Annexes",
                        key: 'annexe',
                        filterOperators: annexesOperators,
                        headerAlign: 'left',
                        hide: false,
                        item : 20,
                        renderCell : specialAction,
                        renderHeader: headerSpecialTitleViewAnnexe,
                        resizable: true,
                        sortable : true,
                        special : true,
                        type: "number",
                        width: size,
                    });
                }

                if(item === 16){
                    initColumns.push({
                        field: 'plugcode',
                        key: 'plugcode',
                        headerName: "Plugcode",
                        resizable: true,
                        sortable : true,
                        item : 16,
                        renderHeader: headerSpecialTitleView,
                        hide : false,
                        renderCell : plugcodeAction,
                        special : true,
                        width: size,
                        headerAlign: 'center',
                    });
                }

                if(item === 17){
                    initColumns.push({
                        field: 'tiers',
                        key: 'tiers',
                        headerName: t('common:pluglist.header-created-by'),
                        resizable: true,
                        sortable : true,
                        item : 17,
                        renderHeader: headerSpecialTitleView,
                        hide : false,
                        renderCell : specialAction,
                        special : true,
                        width: size,
                        headerAlign: 'center',
                    });
                }

                if(item === 18){
                    initColumns.push({
                        field: 'creation',
                        filterOperators: dateOperators('date'),
                        key: 'creation',
                        headerName: t('common:pluglist.header-created'),
                        resizable: true,
                        sortable : true,
                        item :18,
                        hide: false,
                        headerAlign: 'left',
                        renderHeader: headerSpecialTitleView,
                        renderCell : dateAction,
                        align : 'center',
                        special : true,
                        type: 'date',
                        width: size,
                    });
                }

                if(item === 19){
                    initColumns.push({
                        field: 'modification',
                        filterOperators: dateOperators('date'),
                        key: 'modification',
                        headerName: t('common:pluglist.header-edited'),
                        resizable: true,
                        sortable : true,
                        headerAlign: 'left',
                        item : 19,
                        hide: false,
                        renderHeader: headerSpecialTitleView,
                        renderCell : dateAction,
                        special : true,
                        align : 'center',
                        type: 'date',
                        width: size,
                    });
                }
            }
        })

        defaultSelectedFields.map((item, index) => {
            let find = preSizeFields.findIndex(elm => elm.field === item);
            let size = find !== -1 ? preSizeFields[find].width : 170;
            if(item === 0){
                size = find !== -1 ? preSizeFields[find].width : 100;
            }else if(item === 20){
                size = find !== -1 ? preSizeFields[find].width : 90;
            }
            if(!selectedFields.includes(item)){
                if(item !== 0 && item !== 16 && item !== 17 && item !== 18 && item !== 19 && item !== 20 ){
                    if(props.model['field'+item+'label'] !== null){
                        let template = getTemplateFormatText(props.model['field'+item+'format'],props.model['field'+item+'formatoptions']);
                        switch (props.model['field'+item+'format']) {
                            case 'textarea':
                            case "agenda":
                            case "localis":
                            case "password":
                            case "time":
                            case "list":
                            case "birthday":
                            case "qrcode":
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field'+item+'label'] + ' ('+ item +') ('+  template +')',
                                    headerNameCustom:  props.model['field'+item+'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    headerAlign: 'center',
                                    format : props.model['field'+item+'format'],
                                    hide : false,
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(props.model['field'+item+'format']),
                                    complex : props.model.ms_edit_fiche === 1,
                                });
                                break;
                            case 'phone':
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field'+item+'label'] + ' ('+ item +') ('+  template +')',
                                    headerNameCustom:  props.model['field'+item+'label'],
                                    resizable: true,
                                    hide: true,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    renderEditCell: renderPhoneEditInputCell,
                                    format : props.model['field'+item+'format'],
                                    special : false,
                                    headerAlign: 'center',
                                    width: size,
                                    editable: specialFields.includes(props.model['field'+item+'format'])  && props.model.ms_edit_fiche
                                });
                                break;
                            case 'numeric':
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field'+item+'label'] + ' ('+ item +') ('+  template +')',
                                    headerNameCustom:  props.model['field'+item+'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    type : 'number',
                                    hide: true,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    format : props.model['field'+item+'format'],
                                    headerAlign: 'center',
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(props.model['field'+item+'format'])  && props.model.ms_edit_fiche
                                });
                                break;
                            case 'date':
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field'+item+'label'] + ' ('+ item +') ('+  template +')',
                                    headerNameCustom:  props.model['field'+item+'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    type : 'date',
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    hide: true,
                                    //renderEditCell: renderDateEditInputCell,
                                    format : props.model['field'+item+'format'],
                                    headerAlign: 'center',
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(props.model['field'+item+'format'])  && props.model.ms_edit_fiche
                                });
                                break;
                            case 'boolean':
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field'+item+'label'] + ' ('+ item +') ('+  template +')',
                                    headerNameCustom:  props.model['field'+item+'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    type : 'boolean',
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    format : props.model['field'+item+'format'],
                                    headerAlign: 'center',
                                    special : false,
                                    hide: true,
                                    width: size,
                                    valueFormatter: ({ value }) => value === '1',
                                    editable: specialFields.includes(props.model['field'+item+'format'])  && props.model.ms_edit_fiche
                                });
                                break;
                            default:
                                initColumns.push({
                                    field: 'field' + item,
                                    key: 'field' + item,
                                    headerName:  props.model['field'+item+'label'] + ' ('+ item +') ('+  template +')',
                                    headerNameCustom:  props.model['field'+item+'label'],
                                    resizable: true,
                                    sortable : true,
                                    item : item,
                                    renderHeader: headerTitleView,
                                    renderCell : specialAction,
                                    format : props.model['field'+item+'format'],
                                    headerAlign: 'center',
                                    hide: true,
                                    special : false,
                                    width: size,
                                    editable: specialFields.includes(props.model['field'+item+'format'])  && props.model.ms_edit_fiche
                                });
                                break;
                        }
                    }
                }
                else {
                    if(item === 0){
                        initColumns.push({
                            field: 'userindex',
                            key: 'userindex',
                            headerName: "N°",
                            resizable: true,
                            sortable : true,
                            item : 0,
                            renderHeader: headerSpecialTitleView,
                            renderCell : specialAction,
                            headerAlign: 'center',
                            special : true,
                            hide: true,
                            type: "number",
                            width: size,
                        });
                    }

                    if(item === 20){
                        initColumns.push({
                            align : 'center',
                            field: 'annexe',
                            headerName: "Nbr d'Annexes",
                            key: 'annexe',
                            filterOperators: annexesOperators,
                            headerAlign: 'left',
                            hide: false,
                            item : 20,
                            renderCell : specialAction,
                            renderHeader: headerSpecialTitleViewAnnexe,
                            resizable: true,
                            sortable : true,
                            special : true,
                            type: "number",
                            width: size,
                        });
                    }

                    if(item === 16){
                        initColumns.push({
                            field: 'plugcode',
                            key: 'plugcode',
                            headerName: "Plugcode",
                            resizable: true,
                            sortable : true,
                            headerAlign: 'center',
                            item : 16,
                            renderHeader: headerSpecialTitleView,
                            hide: true,
                            renderCell : plugcodeAction,
                            special : true,
                            width: size,
                        });
                    }

                    if(item === 17){
                        initColumns.push({
                            field: 'tiers',
                            key: 'tiers',
                            headerName: t('common:pluglist.header-created-by'),
                            resizable: true,
                            sortable : true,
                            item : 17,
                            headerAlign: 'center',
                            renderHeader: headerSpecialTitleView,
                            hide: true,
                            renderCell : specialAction,
                            special : true,
                            width: size,
                        });
                    }

                    if(item === 18){
                        initColumns.push({
                            field: 'creation',
                            filterOperators: dateOperators('date'),
                            key: 'creation',
                            headerName: t('common:pluglist.header-created'),
                            resizable: true,
                            sortable : true,
                            item :18,
                            headerAlign: 'left',
                            hide: true,
                            renderHeader: headerSpecialTitleView,
                            renderCell : dateAction,
                            align : 'center',
                            special : true,
                            width: size,
                        });
                    }

                    if(item === 19){
                        initColumns.push({
                            field: 'modification',
                            filterOperators: dateOperators('date'),
                            key: 'modification',
                            headerName: t('common:pluglist.header-edited'),
                            resizable: true,
                            sortable : true,
                            item : 19,
                            headerAlign: 'left',
                            hide: true,
                            renderHeader: headerSpecialTitleView,
                            renderCell : dateAction,
                            align : 'center',
                            special : true,
                            type: 'date',
                            width: size,
                        });
                    }
                }
            }
        })


        plugnotes.map((plugnote, index) => {
            let finalObject = {};
            finalObject.userindex = plugnote.userindex;

            defaultSelectedFields.map((item, index) => {
                if(item !== 0 && item !== 16 && item !== 17 && item !== 18 && item !== 19 && item !== 20 ){
                    if(props.model['field'+item+'label'] !== null){
                        let value = plugnote['field'+item];
                        if(plugnote['field'+item+'format'] === 'select'){
                            try {
                                let values = JSON.parse(plugnote['field'+item]).sort((str1, str2) => {
                                    let num1 = parseInt(str1);
                                    let num2 = parseInt(str2);

                                    if (isNaN(num1) && isNaN(num2)) {
                                        if (str1 > str2)
                                            return 1;
                                        else if (str1 < str2)
                                            return -1;
                                        else
                                            return 0;
                                    }

                                    else if (isNaN(num1))
                                        return 1;
                                    else if (isNaN(num2))
                                        return -1;
                                    else if (num1 > num2)
                                        return 1;
                                    else if (num1 < num2)
                                        return -1;
                                    else
                                        return 0;
                                });
                                value = values.join(', ');
                            } catch (error) {
                                value = plugnote['field'+item];
                            }
                        }
                        else if(plugnote['field'+item+'format'] === 'list'){
                            let plugValue = parseJson(plugnote['field' + item]);
                            if (typeof plugValue === 'string') {
                                if (plugValue) {
                                    value = [value];
                                }
                            } else if (plugValue == null   || typeof plugValue === 'number') {
                                value = [];
                            } else {
                                value = plugValue.reduce((res, obj) => {
                                    return res.concat(obj.text || '');
                                }, []).join(', ');
                            }
                        }
                        else if(plugnote['field'+item+'format'] === 'plugform'){
                            let plugValue = parseJson(plugnote['field' + item]);
                            value = typeof plugValue !== "undefined" ? plugValue.value : "";
                        }
                        else if(plugnote['field'+item+'format'] === 'table'){
                            let plugValue = parseJson(plugnote['field' + item]);
                            if (typeof plugValue === 'string') {
                                if (plugValue) {
                                    value = [value];
                                }
                            } else if (plugValue == null   || typeof plugValue === 'number') {
                                value = [];
                            } else {
                                value = plugValue.reduce((res, obj) => {
                                    return res.concat(obj.text + ' : ' + obj.value || '');
                                }, []).join(', ');
                            }
                        }
                        else if(plugnote['field'+item+'format'] === 'list'){
                            let plugValue = parseJson(plugnote['field' + item]);
                            if (typeof plugValue === 'string') {
                                if (plugValue) {
                                    value = [value];
                                }
                            } else if (plugValue == null || typeof plugValue === 'number') {
                                value = [];
                            } else {
                                value = plugValue.reduce((res, obj) => {
                                    return res.concat(obj.text || '');
                                }, []).join(', ');
                            }
                        }
                        else if(plugnote['field'+item+'format'] === 'table'){
                            let plugValue = parseJson(plugnote['field' + item]);
                            if (typeof plugValue === 'string') {
                                if (plugValue) {
                                    value = [value];
                                }
                            } else if (plugValue == null || typeof plugValue === 'number') {
                                value = [];
                            } else {
                                value = plugValue.reduce((res, obj) => {
                                    return res.concat(obj.text + ' : ' + obj.value  || '');
                                }, []).join(', ');
                            }
                        }
                        else if(plugnote['field'+item+'format'] === 'qrcode'){
                            if(typeof value !== 'undefined' && value.length > 0){
                                let qrCode = "";
                                let comment = "";
                                if(value.startsWith(';')){
                                    let fieldValue  = value.substring(1);
                                    qrCode = '';
                                    comment = fieldValue;
                                }else {
                                    let fieldValue = value.split(/([^,]*);(.*)/);
                                    let fieldFilteredValue =  fieldValue.filter( a => a);
                                    qrCode = fieldFilteredValue[0];
                                    comment = fieldFilteredValue[1];
                                }

                                if(qrCode !== undefined && qrCode.length > 0){
                                    if (value.startsWith('PN') || value.startsWith('PP') || value.startsWith('PF') || value.startsWith('pn') || value.startsWith('pp') || value.startsWith('pf')){
                                        value = '(' + qrCode.toUpperCase() + ')';
                                    }
                                }

                                if(comment !== undefined && comment.length > 0){
                                    value = comment;
                                }

                                if(qrCode !== undefined && comment !== undefined && qrCode.length > 0 && comment.length > 0){
                                    value = '(' + qrCode.toUpperCase() + ')' + ' ' + comment;
                                }
                            }

                        }

                        else if (plugnote['field' + item + 'format'] === 'qrcodeint') {

                            if (typeof value !== 'undefined' && value.length > 0) {
                                let qrcodeIntValue = parseJson(plugnote['field' + item]);
                                let modelClef = qrcodeIntValue?.model || "";
                                let dataIndex = qrcodeIntValue?.data || "";
                                let comment = qrcodeIntValue?.comment || "";

                                if(comment !== ""){
                                    value = comment;
                                }else if (modelClef != "" && dataIndex != "") {
                                    value = modelClef + ' - ' + dataIndex;
                                }else {
                                    value = ""
                                }
                            }

                        }

                        else if(plugnote['field'+item+'format'] === 'password'){
                            if(typeof value !== "undefined"  && value.length > 0){
                                value = '*'.repeat(6);
                            }else {
                                value = "";
                            }
                        }else if(plugnote['field'+item+'format'] === 'glocalis'){
                            if(typeof value !== "undefined"  && value.length > 0 && isJsonStringObject(value)){
                                let tmpValue = JSON.parse(value);
                                value = 'lnt : ' + tmpValue?.longitude +  ' lat : ' + tmpValue?.latitude;
                            }else {
                                value = "";
                            }
                        }else if(plugnote['field'+item+'format'] === 'intdatamaj'){
                            if(typeof value !== "undefined"  && value.length > 0 && isJsonStringObject(value)){
                                let tmpValue = JSON.parse(value);
                                value = 'TO : ' + tmpValue?.value +  ' AT : ' + tmpValue?.updatedDate;
                            }else {
                                value = "";
                            }
                        }else if(plugnote['field'+item+'format'] === 'boolean'){
                            value = (value === "1");
                        }
                        finalObject['field' + item] = value;
                    }
                }
            })

            let sharedInfoUser = "";

            if(plugnote.shared){
                sharedInfoUser =  plugnote.username;
            }

            if(plugnote.external){
                sharedInfoUser =  plugnote.email;
            }

            finalObject['plugnote'] =  plugnote;
            finalObject['hash'] =  plugnote.hash;
            finalObject['isarchived'] =  plugnote.isarchived;
            finalObject['isexported'] =  plugnote.isexported;
            finalObject['plugcode'] = getCodePnPpCodeTemplate(plugnote);
            finalObject['annexe'] = plugnote.numannexes;
            finalObject['tiers'] = sharedInfoUser;
            finalObject['creation'] = plugnote.creationdate ? moment(plugnote.creationdate).format('YY-MM-DD') : '';
            finalObject['modification'] = plugnote.moddate ? moment(plugnote.moddate).format('YY-MM-DD') : '';


            if(favoris === false){
                if(actif === true && archived === true && (plugnote.isarchived === false || plugnote.isarchived === true) && !plugnote.isexported){

                    initialRows.push(finalObject);
                }
                else if(actif === true && archived === false && plugnote.isarchived === false && !plugnote.isexported){

                    initialRows.push(finalObject);
                }
                else if(actif === false && archived === true && plugnote.isarchived === true && !plugnote.isexported){

                    initialRows.push(finalObject);
                }
                else if(actif === false && archived === false && exported === true && plugnote.isarchived === false  && plugnote.isexported){

                    initialRows.push(finalObject);
                }
            }
            else {
                if(actif === true && archived === true && (plugnote.isarchived === false || plugnote.isarchived === true) && plugnote.favorite === 1 && !plugnote.isexported){

                    initialRows.push(finalObject);
                }
                else if(actif === true && archived === false && plugnote.isarchived === false  && plugnote.favorite === 1 && !plugnote.isexported){

                    initialRows.push(finalObject);
                }
                else if(actif === false && archived === true && plugnote.isarchived === true  && plugnote.favorite === 1 && !plugnote.isexported){

                    initialRows.push(finalObject);
                }
            }
        });

        if(props.model.ms_edit_fiche){
            initColumns.push({ field: 'actions', key: 'actions', headerName:  'Actions', resizable: true, sortable : false,  renderHeader: headerActionsTitleView, renderCell : allActions, width: 175, align : 'center'});
        }
    }

    const [columns, setColumns] = useState(initColumns);
    const [rows, setRows] = useState(initialRows);
    const [page, setPage] = useState(typeof startPage === 'undefined' ? 1 : startPage);
    const [end, setEnd] = useState(typeof limitPage === 'undefined' ? 1 : limitPage);
    const [hasMore, setHasMore] = useState(startPage < limitPage);
    const [sortDirection, setSortDirection] = useState("NONE");
    const [sortColumn, setSortColumn] = useState("userindex");
    const [rowCount, setRowCount] = useState(rows.length);

    const [editRowsModel, setEditRowsModel] = useState({});
    const [loading, setLoading] = useState(false);
    const [onDrag, setOndrag] = useState(null);
    const [sourceColumnIndex, setSourceColumnIndex] = useState(null);
    const [targetColumnIndex, setTargetColumnIndex] = useState(null);
    const [recent, ] = useState(getTopRow()) // last opened Plugnote
    const sortRows = (initialRows, sortColumn, sortDirection) => rows => {
        const comparer = (a, b) => {
            if (sortDirection === "ASC") {
                return a[sortColumn] > b[sortColumn] ? 1 : -1;
            } else if (sortDirection === "DESC") {
                return a[sortColumn] < b[sortColumn] ? 1 : -1;
            }
        };
        return sortDirection === "NONE" ? initialRows : [...rows].sort(comparer);
    };

    function handleColumnsReorder(object) {
        setSourceColumnIndex(object.oldIndex);
        setTargetColumnIndex(object.targetIndex);
        setOndrag(false);
    }

    function handleColumnsResize(object) {
        let findIndex = sizeFields.findIndex(item  => item.field === object.colDef.item);
        if(findIndex !== -1){
            sizeFields[findIndex].width = object.width;
            let checkExistingSizeFields = JSON.parse(localStorage.getItem('sizeFields'));

            if(!checkExistingSizeFields){

                if((JSON.stringify(defaultSizeFields) !== JSON.stringify(sizeFields))){
                    let fieldByModel = {
                        'hash' : props.model.hash,
                        'fields' : sizeFields,
                        'environnement' : environment.userhash,
                        'date' : moment().format('YYYY/MM/DD'),
                    };

                    setFieldsSize(fieldByModel);
                }
            }
            else {
                let findIndex = checkExistingSizeFields.findIndex(item  => item.hash === props.model.hash);
                if(findIndex !== -1){
                    if((JSON.stringify(defaultSizeFields) !== JSON.stringify(sizeFields))){
                        let fieldByModel = {
                            'hash' : props.model.hash,
                            'fields' : sizeFields,
                            'environnement' : environment.userhash,
                            'date' : moment().format('YYYY/MM/DD'),
                        };

                        setFieldsSize(fieldByModel);
                    }else {
                        delSpecifiFieldsSize(props.model.hash);
                    }
                }else {
                    if((JSON.stringify(defaultSizeFields) !== JSON.stringify(sizeFields))){
                        let fieldByModel = {
                            'hash' : props.model.hash,
                            'fields' : sizeFields,
                            'environnement' : environment.userhash,
                            'date' : moment().format('YYYY/MM/DD'),
                        };

                        setFieldsSize(fieldByModel);
                    }
                }
            }
        }

    }

    function handleColumnsVisibility (object) {
        if(typeof object.colDef.item !== "undefined" && object.isVisible === false){
            setSelectedFields(selectedFields.filter((e)=>(e !== object.colDef.item)))
        }else if(typeof object.colDef.item !== "undefined" && object.isVisible === true){
            const tmp = selectedFields;
            tmp.push(object.colDef.item);
            setSelectedFields(tmp);
        }
    }

    const handleBlur = useCallback((params, event) => {
        if(params.cellMode === "edit" && params.colDef.format === "select"){
            event.stopPropagation();
        }
    }, []);


    useEffect(() => {
        if(apiRef.current !== null && typeof apiRef.current.subscribeEvent !== "undefined"){
            return apiRef.current.subscribeEvent(
                GRID_COLUMN_HEADER_DRAG_END,
                (params, event) => {
                    setOndrag(null);
                },
            );
        }
    }, [apiRef]);


    useEffect(() => {
        if(onDrag === false && sourceColumnIndex !== -1 && targetColumnIndex !== -1){
            const sourceColumnIndexSource = sourceColumnIndex - 1;
            const targetColumnIndexSource = targetColumnIndex - 1;

            if(sourceColumnIndexSource !== null && targetColumnIndexSource !== null){
                const sourceColumnSelectedFieldsIndex = selectedFields.findIndex(c => typeof initColumns[sourceColumnIndexSource].item !== "undefined" && c === initColumns[sourceColumnIndexSource].item);
                const targetColumnSelectedFieldsIndex = selectedFields.findIndex(c => typeof initColumns[targetColumnIndexSource].item !== "undefined" && c === initColumns[targetColumnIndexSource].item);

                if(sourceColumnSelectedFieldsIndex !== -1 && targetColumnSelectedFieldsIndex !== -1){
                    const reorderedSelectedFieldsColumns = [...selectedFields];

                    reorderedSelectedFieldsColumns.splice(
                        targetColumnSelectedFieldsIndex,
                        0,
                        reorderedSelectedFieldsColumns.splice(sourceColumnSelectedFieldsIndex, 1)[0]
                    );

                    setSelectedFields(reorderedSelectedFieldsColumns);
                }

                setSourceColumnIndex(null);
                setTargetColumnIndex(null);
                setOndrag(null);
            }
        }
    }, [onDrag]);

    useEffect(() => {
        if(apiRef.current !== null && typeof apiRef.current.subscribeEvent !== "undefined"){
            return apiRef.current.subscribeEvent(
                GRID_COLUMN_HEADER_DRAG_START,
                (params, event) => {
                    setOndrag(true)
                },
            );
        }
    }, [apiRef]);

    function oldHandleColumnsReorder(sourceKey, targetKey) {

        const sourceColumnIndex = initColumns.findIndex(c => c.key === sourceKey);
        const targetColumnIndex = initColumns.findIndex(c => c.key === targetKey);


        const sourceColumnSelectedFieldsIndex = selectedFields.findIndex(c => c === initColumns[sourceColumnIndex].item);
        const targetColumnSelectedFieldsIndex = selectedFields.findIndex(c => c === initColumns[targetColumnIndex].item);

        const reorderedSelectedFieldsColumns = [...selectedFields];

        reorderedSelectedFieldsColumns.splice(
            targetColumnSelectedFieldsIndex,
            0,
            reorderedSelectedFieldsColumns.splice(sourceColumnSelectedFieldsIndex, 1)[0]
        );


        setSelectedFields(reorderedSelectedFieldsColumns);

    }

    useEffect(() => {
        setRows(initialRows);
        setRowCount(initialRows.length);
    }, [favoris, actif, archived,plugnotes]);

    useEffect(() => {
        setPage(startPage);
        setEnd(limitPage);
        if(startPage < limitPage){
            setHasMore(true);
        }
    }, [startPage, limitPage]);


    const fetchDatas = () => {

        let nextPage = page + 1;
        setPage(nextPage);
        if(nextPage < limitPage){
            setHasMore(true);
        }else {
            setHasMore(false);
        }

        attemptGetPlugnotesPaginateByModelHashAndUserHash(modelhash, hash, nextPage);

    };

    const loadServerRows = () => {
        setLoading(true);
        let nextPage = page + 1;
        setPage(nextPage);
        if(nextPage < limitPage){
            setHasMore(true);
        }else {
            setHasMore(false);
        }

        attemptGetPlugnotesPaginateByModelHashAndUserHash(modelhash, hash, nextPage);

    };

    const handleEditCellChange = useCallback(
        ({ id, field, props }) => {
            let format = infoCurrentModel[field+'format'];
            let fieldName = field;
            let data = props; // Fix eslint value is missing in prop-types for JS files
            let isValid = true;
            let newState = {};

            switch (format) {
                case 'boolean':
                    newState[id] = {
                        ...editRowsModel[id],
                        [fieldName]: { ...props, value : data.value, type : 'boolean', stringValue :  data.value ? "1" : "", format : 'boolean'},
                    };
                    setEditRowsModel((state) => ({ ...state, ...newState }));
                    break;
                case 'mail':
                    isValid = validateEmail(data.value);
                    newState[id] = {
                        ...editRowsModel[id],
                        [fieldName]: { ...props, error: !isValid, format : 'mail' },
                    };
                    setEditRowsModel((state) => ({ ...state, ...newState }));
                    break;
                case 'date':
                    let date = data !==  null && data.value !== null ? moment(new Date(data.value.toString())).format('YYYY-MM-DD') : "";
                    newState[id] = {
                        ...editRowsModel[id],
                        [fieldName]: { ...props, error: false, value : date ,format : 'date'},
                    };
                    setEditRowsModel((state) => ({ ...state, ...newState }));
                    break;
                case 'phone':
                    newState[id] = {
                        ...editRowsModel[id],
                        [fieldName]: { ...props, format : 'phone' }
                    };
                    setEditRowsModel((state) => ({ ...state, ...newState }));
                    break;
                case 'text':
                    isValid = validateText(data.value);
                    newState[id] = {
                        ...editRowsModel[id],
                        [fieldName]: { ...props, error: !isValid, format : 'text' },
                    };
                    setEditRowsModel((state) => ({ ...state, ...newState }));
                    break;
                case 'numeric':
                    isValid = validateNumber(data.value);
                    newState[id] = {
                        ...editRowsModel[id],
                        [fieldName]: { ...props, error: !isValid, format : 'numeric' },

                    };
                    setEditRowsModel((state) => ({ ...state, ...newState }));
                    break;
                case 'url':
                    isValid = validateUrl(data.value);
                    newState[id] = {
                        ...editRowsModel[id],
                        [fieldName]: { ...props, error: !isValid,format : 'url' },

                    };
                    setEditRowsModel((state) => ({ ...state, ...newState }));
                    break;
                case 'select':
                    newState[id] = {
                        ...editRowsModel[id],
                        [fieldName]: { ...props, format : 'select' },

                    };
                    setEditRowsModel((state) => ({ ...state, ...newState }));
                    break;
                default:
                    break;
            }
        },
        [editRowsModel],
    );

    const handleEditCellChangeCommitted = useCallback(
        ({ id, field, props }) => {

            const updatedRows = rows.map((row) => {
                let fieldName = field;
                const data = props; // Fix eslint value is missing in prop-types for JS files

                if(typeof data !== 'undefined'){
                    if (row.hash === id && typeof data.type === "undefined" && editableDirectFields.includes(data.format)) {

                        row.plugnote[fieldName]  = data.value;

                        attemptUpdatePlugnoteField(infoCurrentModel.hash, row.plugnote.hash, environment.userhash, {
                            field : fieldName,
                            value : data.value
                        });

                        return { ...row,
                            [fieldName]: data.value,
                            plugnote : row.plugnote
                        };
                    }
                    else if (row.hash === id && typeof data.type !== "undefined" && editableDirectFields.includes(data.format)) {

                        switch (data.type) {
                            case 'boolean' :
                                row.plugnote[fieldName] = data.stringValue;

                                attemptUpdatePlugnoteField(infoCurrentModel.hash, row.plugnote.hash, environment.userhash, {
                                    field : fieldName,
                                    value : data.value
                                });

                                return { ...row,
                                    [fieldName]: data.value,
                                    plugnote : row.plugnote
                                };
                                break;

                            default:
                                row.plugnote[fieldName] = data.arrayStringValue;

                                attemptUpdatePlugnoteField(infoCurrentModel.hash, row.plugnote.hash, environment.userhash, {
                                    field : fieldName,
                                    value : data.arrayStringValue
                                });

                                return { ...row,
                                    [fieldName]: data.type === "single-select" ? data.value : data.arrayValue.join(", "),
                                    plugnote : row.plugnote
                                };
                                break;
                        }
                    }
                }
                return row;
            });
            setRows(updatedRows);
        },
        [rows],
    );

    function handleDoubleClick (object) {
        //agenda,localis, password,time,list,birthday,qrcode,table,plugform,pluglink

        switch (object.colDef.format) {
            case "textarea":
                setOpenMemo(true);
                setInfos(object);
                break;
            case "agenda":
                setOpenAgenda(true);
                setInfos(object);
                break;
            case "localis":
                setOpenLocalis(true);
                setInfos(object);
                break;
            case "password":
                setOpenPassword(true);
                setInfos(object);
                break;
            case "time":
                setOpenTime(true);
                setInfos(object);
                break;
            case "list":
                setOpenList(true);
                setInfos(object);
                break;
            case "birthday":
                setOpenBirthday(true);
                setInfos(object);
                break;
            case "qrcode":
                setOpenQrcode(true);
                setInfos(object);
                break;
            /*            case "table":
                            setOpenTable(true);
                            setInfos(object);
                            break;
                        case "plugform":
                            setOpenPlugform(true);
                            setInfos(object);
                            break;*/
            default:
                break;
        }
    }

    const onFilterModelChange = (filterObject) => {
        // Do something here
        setFilterSet(filterObject.items);
    };
    //! nouvel affichage des séléctions : directement dans la 1ère modale
    const openSelectionList = () => {
        // setLoading(true)
        setOpenSelections(true)
    }

    const handleSave = (overwrite) => {
        let payload
        if(savedFilter !== null) payload = {
            authorName: {hash: props.user.user.userhash},
            clef : environment.envIndex + '-' + props.model.clef,
            description : savedFilter.description,
            filters : filterSet,
            id : !!overwrite ?? savedFilter.id,
            label: selectionLabel,
            modelhash : savedFilter.model,
            text : selectionLabel,
            userhash : props.user.user.userhash,
        }
        else payload = {
            authorName: {hash: environment.userhash},
            clef : environment.envIndex + '-' + props.model.clef,
            description: "",
            filters : filterSet,
            label: selectionLabel,
            linkOperator: linkOperator,
            model: props.model,
            modelhash: props.model.hash,
            text : selectionLabel,
            userhash : props.user.user.userhash,
        }
        try {props.attempListFilterSaveByModel(payload);}
        catch(e) {console.log(e);}
        if(savedFilter !== null && !!overwrite) props.attemptDeleteFilter({id: savedFilter.id})
        setCurrentFilter(payload)
        setHasChanged(false)
        setOpenSelections(false)
        setRetrieved(false)
        setFromServer(true)
        apiRef.current.hideFilterPanel()
    }

    const handleOverwrite = () => {
        handleSave(true)
    }

    const handleDeleteCurrentFilter = () => {
        delSelection()
        setCurrentFilter(null);
        setValidFilter(false);
        setFromServer(false)
        setFilterSet([])
    }

    function checkValidFilter() {
        if(filterSet.length > 0) {
            let voidFilter = true
            Object.keys(filterSet).forEach(obj => {
                switch (filterSet[obj]["columnField"]) {
                    case 'view':
                        setValidFilter(false);
                        voidFilter = true;
                        return;
                    default:
                        if (filterSet[obj]["operatorValue"] === 'isNotEmpty' || filterSet[obj]["operatorValue"] === 'isEmpty') {voidFilter = false}
                        else if (filterSet[obj]["value"] !== undefined && filterSet[obj]["value"] !== "") {
                            voidFilter = false;
                            setValidFilter(false);
                            return;
                        }
                        break;
                }
            })
            if (voidFilter) {
                setValidFilter(false);
            }
            else setValidFilter(true)
        }
        else setValidFilter(false)
    }

    function deleteFilters () {
        setFilterSet([])
        setCurrentFilter(null)
        setSelectionLabel(t("common:filters.new-selection"))
        delSelection()
    }

    function handleOpenFilterPanel() {
        setFilterIsOpen(true)
        apiRef.current.showFilterPanel()
    }

    const handleModelChange = (model) => {
        setFilterSet(model.items);
        setHasChanged(true)
        if(model.linkOperator !== undefined) setLink(model.linkOperator);
        else setLink('and')
    }

    const objectifySelection = () => {
        let _selection = {}
        for(let i = 0; i < filterSet.length; i++){
            _selection[i] = filterSet[i]
        }
        _selection.linkOperator = linkOperator
        _selection.folder = modelhash
        if(savedFilter !== null) {
            _selection.label = savedFilter.label
        }
        return _selection
    }

    const parseSessionStorageSelection = () => {
        let sel = getSelection()
        // console.log('retrieved selection', sel);
        let savedSel = {}
        if (sel.folder === modelhash){
            let paramNb = 1
            let selArray = []
            if (sel.label !== undefined) paramNb = Object.keys(sel).length - 3;
            else paramNb = Object.keys(sel).length - 2
            for (let i = 0; i < paramNb; i++){
                selArray.push(sel[i])
            }
            setFilterSet(selArray)
        }
        if (sel.label !== undefined) {
            savedSel.label = sel.label
            setCurrentFilter(savedSel)
            // setSelectionLabel(sel.label)
        }
    }

    // function checkEqual(object1, object2) {
    //     const keys1 = Object.keys(object1);
    //     const keys2 = Object.keys(object2);
    //     // console.log(keys1, keys2);
    //     if (keys1.length !== keys2.length) {
    //         return false;
    //     }
    //     for (const key of keys1) {
    //         const val1 = object1[key];
    //         const val2 = object2[key];
    //         const areObjects = isObject(val1) && isObject(val2);
    //         if (
    //         areObjects && !checkEqual(val1, val2) ||
    //         !areObjects && val1 !== val2
    //         ) {
    //             return false;
    //         }
    //     }
    //     return true;
    // }
    // function isObject(object) {
    //     return object != null && typeof object === 'object';
    // }

    const scrollGrid = () => {
        let topRow = getTopRow()
        if(topRow !== null && apiRef?.current !== null) {
            if(topRow.folder === modelhash) {
                apiRef.current.scroll(topRow.position)
                apiRef.current.selectRow(topRow.rowId, true, false)
            }
        }
    }

    useEffect(() => {
        if(filterSet.length === 0) {
            parseSessionStorageSelection()
        }
        if(apiRef?.current !== null) setTimeout(() => {
            scrollGrid()
        }, 1200)
    }, [])

    const storeSelectedRow = (e, params) => {
        let position = apiRef.current.getScrollPosition()
        let rowId = params.id
        let row = {
            folder: modelhash,
            position: position,
            rowId: rowId
        }
        setTopRow(row)
        apiRef.current.selectRow(rowId, true, false)
    }

    const CustomToolbar = () => {
        return (
            <GridToolbarContainer>
                <GridToolbarExport  className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textSizeSmall MuiButton-sizeSmall button-important"/>
                <div className={classes.toolbarContainer}>
                    <ActionButtons
                        filterSet={filterSet}
                        handleDeleteCurrentFilter={handleDeleteCurrentFilter}
                        handleOpenFilterPanel={handleOpenFilterPanel}
                        handleSave={handleSave}
                        hasChanged={hasChanged}
                        model={props.model}
                        newName={selectionParams}
                        openSelections={openSelections}
                        openSelectionList={openSelectionList}
                        savedFilter={savedFilter}
                        selectionLabel={selectionLabel}
                        setSelectionName={setSelectionLabel}
                        setOpenSelections={setOpenSelections}

                    />
                </div>
                { openSelections?
                    <FilterSelectionList
                        clef={environment.envIndex + '-' + props.model.clef}
                        deleteFilters={deleteFilters}
                        filterIsOpen={apiRef?.current?.state?.preferencePanel.open}
                        filterLink={linkOperator}
                        filterSet={filterSet}
                        handleOpenFilterPanel={handleOpenFilterPanel}
                        model={props.model}
                        modelHash={props.model.hash}
                        openSelectionList={openSelectionList}
                        retrieved={retrieved}
                        selectionLabel={selectionLabel}
                        setFilterSet={setFilterSet}
                        setLink={setLink}
                        setRetrieved={setRetrieved}
                        setSelectionLabel={setSelectionLabel}
                        setFromServer={setFromServer}
                        close={(filterObj) => {
                            setOpenSelections(false);
                            if (filterObj !== null && filterObj !== undefined) {
                                setCurrentFilter(filterObj)
                                setNewName(filterObj.text)
                                setSelectionLabel(filterObj.label)
                                setFromServer(true);
                            };
                            setRetrieved(false)
                            setHasChanged(false)
                        }}
                        open={openSelectionList}
                    />
                    : null}
            </GridToolbarContainer>
        );
    };

    const CustomFooter = () => {
        return(
            hiddenFields.length > 0 ?
                <div className={classes.hiddenFieldsContainer}>
                    <span>Hidden fields: </span>
                    {hiddenFields.map((field) =>
                        {let fieldName = field.colDef.headerNameCustom ? field.colDef.headerNameCustom : field.colDef.headerName
                            return(
                                <span
                                    className={classes.hiddenFieldItemContainer}
                                    key={'field-' + field.colDef.item}
                                    onClick={() => {
                                        handleColumnsVisibility(field)
                                    }}
                                >{fieldName}
                    </span>
                            )
                        }
                    )}
                </div> : null)
    }

    useEffect(() => {
        checkValidFilter()
        if(filterSet.length > 0) {
            let objSelection = objectifySelection()
            setSelection(objSelection)
        }
    }, [filterSet])

    useEffect(() => {
        // exclude 'view' column from filtering
        let unfilteredIndex = columns.map(column => column.key).indexOf('view')
        if(unfilteredIndex !== -1) {
            columns[unfilteredIndex].filterable = false;
        }
    }, [columns])

    useEffect(() => {
        // if(filterSet.length === 0) {
        //     parseSessionStorageSelection()
        // }
        if(apiRef?.current !== null) setTimeout(() => {
            scrollGrid()
        }, 1200)
    }, [])


    return (
        <Box
            className="grid-container"
            sx={{
                '& .recent': {backgroundColor : `#51c3c13c !important`}
            }}
        >
            <DesignedGrid
                {...rows}
                apiRef={apiRef}
                components={{
                    ColumnMenuIcon: CustomMenuIcon,
                    ColumnResizeIcon: CustomResizeIcon,
                    FilterPanel: CustomFilterPanel,
                    Toolbar: CustomToolbar,
                }}
                checkboxSelection={props.model.ms_edit_fiche}
                className={classes.grid}
                columns={columns}
                componentsProps={{
                    filterPanel: {
                        environment: environment,
                        fields: fields,
                        filterSet: filterSet,
                        handleModelChange: handleModelChange,
                        handleOverwrite: handleOverwrite,
                        handleSave: handleSave,
                        hasChanged: hasChanged,
                        linkOperator: linkOperator,
                        params: selectionParams,
                        newFilter: validFilter && !isFromServer ? filterSet : null,
                        openSelectionList: openSelectionList,
                        savedFilter: savedFilter,
                        selectionLabel: selectionLabel,
                        setName: setNewName,
                        setSelectionLabel: setSelectionLabel,
                        validFilter: validFilter,
                    }
                }}
                // disableSelectionOnClick={true}
                editRowsModel={editRowsModel}
                filterModel={{
                    items: filterSet,
                    linkOperator: linkOperator
                }}
                getRowClassName={(params) => {
                    // console.log('row class params', params)
                    if (recent !== null && params.id === recent.rowId) {
                        return 'recent'
                    }
                }}
                getRowId={(row) => row.hash || ''}
                showCellRightBorder={true}
                hideFooter={true}
                hideFooterPagination
                loading={false}
                onColumnVisibilityChange={handleColumnsVisibility}
                onSelectionModelChange={(newSelection) => {
                    setSelectedRows(newSelection);
                }}
                onFilterModelChange={(model) => handleModelChange(model)}
                //onRowSelected={handleSelectRow}
                onCellBlur={handleBlur}
                onCellClick={(params, e)=> storeSelectedRow(e, params)}
                onCellDoubleClick={props.model.ms_edit_fiche ? handleDoubleClick : null}
                onColumnOrderChange={handleColumnsReorder}
                onColumnResizeCommitted={handleColumnsResize}
                onEditCellChange={handleEditCellChange}
                onEditCellChangeCommitted={handleEditCellChangeCommitted}
                rowHeight={50}
                rows={rows || []}
                selectionModel={selectedRows}
                onRowsScrollEnd={hasMore ? loadServerRows : null}
            />
            {infos !== null && openMemo &&
                <SimpleMemoDialog
                    value={infos.value}
                    infos={infos}
                    open={openMemo}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}
                />}

            {
                infos !== null && openLocalis &&
                <SimpleLocalisDialog
                    value={infos.value}
                    infos={infos}
                    open={openLocalis}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}
                />
            }

            {
                infos !== null && openAgenda &&
                <SimpleAgendaDialog
                    value={infos.value}
                    infos={infos}
                    open={openAgenda}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}
                />
            }

            {
                infos !== null && openList &&
                <SimpleListDialog
                    value={infos.value}
                    infos={infos}
                    open={openList}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}
                />
            }

            {
                infos !== null && openPassword &&
                <SimplePasswordDialog
                    value={infos.value}
                    infos={infos}
                    open={openPassword}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}
                />
            }

            {
                infos !== null && openBirthday &&
                <SimpleBirthdayDialog
                    value={infos.value}
                    infos={infos}
                    open={openBirthday}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}
                />
            }

            {
                infos !== null && openQrcode &&
                <SimpleQrcodeDialog
                    value={infos.value}
                    infos={infos}
                    open={openQrcode}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}
                />
            }

            {
                infos !== null && openPlugform &&
                <SimplePlugformDialog
                    value={infos.value}
                    infos={infos}
                    open={openPlugform}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}

                />
            }

            {
                infos !== null && openTable &&
                <SimpleTableDialog
                    value={infos.value}
                    infos={infos}
                    open={openTable}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                    props={props}
                />
            }

            {
                infos !== null && openTime &&
                <SimpleTimeDialog
                    props={props}
                    value={infos.value}
                    infos={infos}
                    open={openTime}
                    onUpdateClose={handleUpdateClose}
                    onClose={handleClose}
                    maxWidth="lg"
                    fullWidth
                    style={{ zIndex: 0 }}
                />
            }
        </Box>
    );
};


function PlugSharedListComponent(props) {
    const { t, i18n } = useTranslation(['common', 'models', 'global']);

    const classes = useStyles();
    const { promiseInProgress } = usePromiseTracker();
    const [open, setOpen] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);
    const [subOpen, setSubOpen] = useState(false);
    const [archived, setArchived] = useState(false);
    const [actif, setActif] = useState(getTypeOfPlugnote() !== 'exported');
    const [favoris, setFavoris] = useState(false);
    const [exported, setExported] = useState(getTypeOfPlugnote() === 'exported');
    const [openModalHelp, setOpenModalHelp] = useState(false);



    const [countArchived, setCountArchived] = useState(0);
    const [countFavorites, setCountFavorite] = useState(0);
    const [countActive, setCountActive] = useState(0);
    const [countExported, setCountExported] = useState(0);


    const [payload, setPayload] = useState(null);

    const [fields, setFields] = useState(() => {
        let currentModel = props.model;

        if(currentModel !== 'undefined' && currentModel !== null){
            const labels = Object.keys(currentModel).filter((key) => {
                const regex = /field[0-9]+label/gi;
                return regex.test(key);
            });

            let itemFields = labels.filter(Boolean);

            let currentItemFields = itemFields.map((item) => {
                return currentModel[item];
            });

            return currentItemFields.filter(Boolean);
        }
    });

    const [fieldsInfos, setFieldsInfos] = useState(() => {
        let currentModel = props.model;

        if(currentModel !== 'undefined' && currentModel !== null){
            const labels = Object.keys(currentModel).filter((key) => {
                const regex = /field[0-9]+label/gi;
                return regex.test(key) && currentModel[key] != null;
            });

            let fields = labels.map((label, index) => {
                let formatoptions = currentModel['field' + (index + 1) + 'formatoptions'] || {};
                if (typeof formatoptions !== 'object') {
                    formatoptions = JSON.parse(formatoptions);
                }

                return {
                    key: index + 1,
                    id: index + 1,
                    label: currentModel['field' + (index + 1) + 'label'],
                    format: currentModel['field' + (index + 1) + 'format'],
                    options: {
                        mandatory: !!currentModel['field' + (index + 1) + 'mandatory'],
                        ...formatoptions
                    },
                    formatoptions: currentModel['field' + (index + 1) + 'formatoptions']
                }
            });

            return fields;
        }
    });

    let checkExistingSelection = JSON.parse(localStorage.getItem('fieldsSelected'));
    let checkExistingSizeFields = JSON.parse(localStorage.getItem('sizeFields'));

    let preSelectedFields = [0];
    let preSpecialsSelectedFields = [16, 17, 18, 19, 20];

    let preSizeFields = [{width : 100, field : 0}];
    let preDefaultSizeFields = [{width : 100, field : 0}];
    let preSizeSelectedFields = [
        {width : 150, field : 16},
        {width : 150, field : 17},
        {width : 150, field : 18},
        {width : 150, field : 19},
        {width : 150, field : 20}
    ];
    let preDefaultSizeSelectedFields = [
        {width : 150, field : 16},
        {width : 150, field : 17},
        {width : 150, field : 18},
        {width : 150, field : 19},
        {width : 150, field : 20}
    ];

    fields.map((field, Findex) => {
        if (field !== null) {
            preSelectedFields.push(Findex + 1);
            preSizeFields.push({width : 150, field : Findex + 1});
            preDefaultSizeFields.push({width : 150, field : Findex + 1});
        }
    });

    preSelectedFields = preSelectedFields.concat(preSpecialsSelectedFields);
    preSizeFields = preSizeFields.concat(preSizeSelectedFields);
    preDefaultSizeFields = preDefaultSizeFields.concat(preDefaultSizeSelectedFields);

    const [defaultSelectedFields, setDefaultSelectedFields] = useState(preSelectedFields);
    const [defaultSizeFields, setDefaultSizeFields] = useState(preDefaultSizeFields);


    if(checkExistingSelection){
        let findIndex = checkExistingSelection.findIndex(item  => item.hash === props.model.hash);
        if(findIndex !== -1){
            preSelectedFields = checkExistingSelection[findIndex].fields;
            if(typeof checkExistingSelection[findIndex].specialFields !== 'undefined'){
                preSelectedFields.concat([0, 16, 17, 18, 19, 20]);
            }
        }
    }

    const [selectedFields, setSelectedFields] = useState(preSelectedFields);

    if(checkExistingSizeFields){
        let findIndex = checkExistingSizeFields.findIndex(item  => item.hash === props.model.hash);
        if(findIndex !== -1){
            preSizeFields = checkExistingSizeFields[findIndex].fields;
        }
    }

    const [sizeFields, setSizeFields] = useState(preSizeFields);

    const [isOpenModalModelPlugcode, setIsOpenModalModelPlugcode] = useState(false);
    const [isOpenModalPlugnotePlugcode, setIsOpenModalPlugnotePlugcode] = useState(false);
    const [isOpenModalModelShare, setIsOpenModalModelShare] = useState(false);
    const [isOpenModalImportExportCsv, setIsOpenModalImportExportCsv] = useState(false);
    const [isOpenModalTemplateImportExportCsv, setIsOpenModalTemplateImportExportCsv] = useState(false);
    const [isOpenModalExportSimpleCsv, setIsOpenModalExportSimpleCsv] = useState(false);
    const [isOpenModalExportTableMultipleSimpleCsv, setIsOpenModalExportTableMultipleSimpleCsv] = useState(false);
    const [isOpenClassify, setIsOpenClassify] = useState(false);
    const [filters, setFilters] = useState([]);
    const [filterFieldsOpen, setFilterFieldsOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    // const [newFilter, setNewFilter] = useState(null)
    const [filterSet, setFilterSet] = useState([]);
    const [link, setLink] = useState("and")
    const [openSaveSelectionFilter, setOpenSaveSelectionFilter] = useState(false);
    const [openLoadSelectionFilter, setOpenLoadSelectionFilter] = useState(false);

    const user = getUser();
    const environment = getCurrentEnvironnement();
    const [hash, setHash] = useState(environment.hash);
    const token = getToken();
    const [plugnotes, setPlugnotes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [listView, setListView] = useState(true);
    const [modelBaseInfo, setModelBaseInfo] = useState(null);

    //const [listView, setListView] = useState(getPlugnoteView() !== 'false');
    const [flipped, setFlipped] = useState(-1);

    const attemptSetDataFilter = (search, key) => {
        props.attemptSetDataFilter(search, key);
    };

    const attemptDelDataFilter = (search, key) => {
        props.attemptDelDataFilter(search, key);
    }

    const handleSelectPlugnote = (plugnote) => {
        setModel(props.model);
        props.attemptResetAnnexe();
        setPlugnote(plugnote);
        setAnnexes([]);
        if(!!plugnote.Annexes && plugnote.Annexes.length > 0){
            setAnnexes(plugnote.Annexes);
        }

        if(props.model.ms_edit_fiche > 0){
            props.history.push('/update-plugnote-shared');
        }else {
            props.history.push('/plugnote-view-shared');
        }

    }

    const handleCreatePlugnote = () => {
        props.attemptResetAnnexe();
        delPlugnote();
        delAnnexes();
        setAnnexes([]);
        setModel(props.model);
        props.history.push('/create-plugnote-shared');
    };

    const handleDeletePlugnote = (item) => {

        confirmAlert({
            title: t('global.confirm'),
            message: t('plugnote.plugnote-confirm-delete'),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () =>  props.attemptDeletePlugote(item.hash,environment.userhash)
                },
                {
                    label: t('global.cancel'),
                }
            ]
        });

    };

    const handleArchivePlugnote = (item) => {
        props.attemptUpdateArchivePlugnote(item.hash, !item.isarchived, item.locked, environment.userhash);
    }

    const handleLockPlugnote = (item) => {
        props.attemptUpdateLockPlugnote(item.hash, item.isarchived, !item.locked, environment.userhash);
    }

    const handleFavorisPlugnote = (item) => {
        props.attemptUpdateFavorisPlugnote(item.hash, !item.favorite, environment.userhash);
    }

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleChangeView = () => {
        setFilterFieldsOpen(false);
        setListView(!listView);
        setPlugnoteView(!listView);
    };

    const handleFlip = (value, event) => {
        event.preventDefault();
        event.stopPropagation();
        if(flipped === value){
            setFlipped(-1);
        }else {
            setFlipped(value);
        }
    }

    const handleExitPlugList = () => {
        props.history.push("/models");
        delModel();
        delTopRow()
    }

    const handleModalPlugnotePlugcodeOpen = () => {
        props.attemptResetPlugnotePlugcode();
        setIsOpenModalPlugnotePlugcode(true);
    }
    const handleModalPlugnotePlugcodeClose = () => {
        props.attemptResetPlugnotePlugcode();
        setIsOpenModalPlugnotePlugcode(false);
    };

    const handleModalPlugnoteClassifyClose = () => {
        delPlugnote();
        setIsOpenClassify(false);
    };

    const handleModalPlugnoteClassifyOpen = (item) => {
        setPlugnote(item);
        setIsOpenClassify(true);
    };

    const handleModalModelPlugcodeOpen = () => {
        props.attemptResetModelPlugcode();
        setIsOpenModalModelPlugcode(true);
    };

    const handleModalModelPlugcodeClose = () => {
        props.attemptResetModelPlugcode();
        setIsOpenModalModelPlugcode(false);

    };

    const handleModalModelShareOpen = () => {
        props.attemptResetModelShare();
        setIsOpenModalModelShare(true);
    };

    const handleModalModelShareClose = () => {
        props.attemptResetModelShare();
        setIsOpenModalModelShare(false);
    };

    const handleModalExportCsvClose = () => {
        setPayload(null);
        setIsOpenModalExportSimpleCsv(false);
    }

    const handleModalTemplateImportExportCsvClose = () => {
        setIsOpenModalTemplateImportExportCsv(false);
    }

    const handleModalImportExportCsvClose = () => {
        setIsOpenModalImportExportCsv(false);
    }

    const handleModalTemplateImportExportCsvOpen = () => {
        setIsOpenModalTemplateImportExportCsv(true);
    }

    const handleModalImportExportCsvOpen = () => {
        setIsOpenModalImportExportCsv(true);
    }

    const handleUpdateModel = () => {
        setModelToUpdate(props.model);
        props.history.push({
            pathname: '/update-model',
            state: { back: '/pluglist' }
        });
    }

    const handlePressExportFile = () => {
        props.attemptExportFullFiles(props.model.hash, props.user.user.userhash );
    };

    const handlePressExportCsv = () => {

        let tmpFieldsChecked = Array.from(selectedFields);

        let filteredPlugList = plugnotes.map(searchData => {
            /*            if(!selectedFields.includes(searchData.userindex)){
                            return searchData.userindex
                        }*/
            if(selectedRows.length > 0 && selectedRows.includes(searchData.hash)){
                //console.log(cleanFilteredPlugList);
                return searchData.userindex
            }
        });

        let cleanFilteredPlugList = filteredPlugList.filter(Boolean);


        let filter = 'all';

        if(!archived && actif){
            filter = 'isnotarchived';
        }else if(archived && !actif){
            filter = 'isarchived';
        }

        let payload = {
            model : props.model.hash,
            field : tmpFieldsChecked,
            filter : filter,
            plugList : selectedRows.length > 0 ? cleanFilteredPlugList : [],
            plugsSelectAll : selectedRows.length === 0
        };

        setPayload(payload);
        setIsOpenModalExportSimpleCsv(true);
    };

    const handlePressExportTableMultipleCsv = () => {

        let tmpFieldsChecked = Array.from(selectedFields);

        let filteredPlugList = plugnotes.map(searchData => {
            /*            if(!selectedFields.includes(searchData.userindex)){
                return searchData.userindex
            }*/
            if(selectedRows.length > 0 && selectedRows.includes(searchData.hash)){
                //console.log(cleanFilteredPlugList);
                return searchData.userindex
            }
        });

        let cleanFilteredPlugList = filteredPlugList.filter(Boolean);


        let filter = 'all';

        if(!archived && actif){
            filter = 'isnotarchived';
        }else if(archived && !actif){
            filter = 'isarchived';
        }

        let payload = {
            model : props.model.hash,
            field : tmpFieldsChecked,
            filter : filter,
            plugList : selectedRows.length > 0 ? cleanFilteredPlugList : [],
            plugsSelectAll : selectedRows.length === 0
        };

        setPayload(payload);
        setIsOpenModalExportTableMultipleSimpleCsv(true);
    };

    const handleModalExportTableMultipleCsvClose = () => {
        setPayload(null);
        setIsOpenModalExportTableMultipleSimpleCsv(false);
    }

    const handleOpenSubDrawer = () => {
        setOpen(false);
        setSubOpen(true);
    }

    const handleAllArchive = () => {

        let dataToDeleteHash = [...selectedRows];
        let dataToDeleteHashMap = [];
        plugnotes.map((plug, index) => {
            if(dataToDeleteHash.includes(plug.hash) && plug.isarchived === false){
                dataToDeleteHashMap.push(plug.hash);
            }
        });


        confirmAlert({
            title: t('global.confirm'),
            message: t('plugnote.plugnote-confirm-archive-multiple',{count : dataToDeleteHashMap.length}),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () =>  {
                        props.attemptArchivesDatas(dataToDeleteHashMap,environment.userhash)
                        setSelectedRows(new Set())
                    }
                },
                {
                    label: t('global.cancel'),
                }
            ]
        });
    };

    const handleAllDelete = () => {

        let dataToDeleteHashMap = [...selectedRows];

        confirmAlert({
            title: t('global.confirm'),
            message: t('plugnote.plugnote-confirm-delete-multiple',{count : dataToDeleteHashMap.length}),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () =>  {
                        props.attemptDeleteDatas(dataToDeleteHashMap,environment.userhash);
                        //setSelectedRows(new Set())
                        setSelectedRows([])
                    }
                },
                {
                    label: t('global.cancel'),
                }
            ]
        });

    };

    const handleSubDrawerClose = () => {
        setSubOpen(false);
        setOpen(false);
    };

    const handleResetPlugSearch = () => {
        props.attemptDelAllDataFilter();
        setFilters([]);
        setSubOpen(false);
        setOpen(false);
    };

    const handleAddFiltersLine = () => {
        setFilters(oldArray => [...oldArray, {position : oldArray.length + 1, field : null, value : null, query : null}]);
    };

    const handleDelFiltersLine = (indexFilter) => {
        setFilters(filters.filter((item, index) => {
            return indexFilter !== index;
        }));

    };

    const handleModels = () => {
        history.push('models')
    };

    const handleSaveCurrentFilterSelection = () => {
        setOpenSaveSelectionFilter(true);
    }

    const handleOpenListFilterSelection = () => {
        setOpenLoadSelectionFilter(true);
    }

    const handleSelectedExported = () => {
        if(exported){
            setActif(true);
            setFavoris(false);
            setArchived(false);
            setExported(!exported);
            props.attemptGetPlugnotesByModelHashAndUserHash(props.model.hash, hash,false);
        }else {
            setActif(false);
            setFavoris(false);
            setArchived(false);
            setExported(!exported);
            props.attemptGetPlugnotesByModelHashAndUserHash(props.model.hash, hash,true);
        }
    }

    const handleSelectedActif = () => {
        if(exported){
            setActif(true);
            setFavoris(false);
            setArchived(false);
            setExported(!exported);
            props.attemptGetPlugnotesByModelHashAndUserHash(props.model.hash, hash,false);
        }else {
            setActif(!actif);
        }
    }

    const handleSelectedFavoris = () => {
        if(exported){
            setActif(false);
            setFavoris(true);
            setArchived(false);
            setExported(!exported);
            props.attemptGetPlugnotesByModelHashAndUserHash(props.model.hash, hash,false);
        }else {
            setFavoris(!favoris);
        }
    }

    const handleSelectedArchived = () => {
        if(exported){
            setActif(false);
            setFavoris(false);
            setArchived(true);
            setExported(!exported);
            props.attemptGetPlugnotesByModelHashAndUserHash(props.model.hash, hash,false);
        }else {
            setArchived(!archived);
        }
    }

    const habilityToUse = () => {
        let obj =  props.params.find((elm) => elm.clef === 'process_transfert_customer_tables');
        if(obj){
            let values = obj.valeur.split(';')
            let valueObj = values.find((val) => val ===  props.model.envIndexOwner +'-'+ props.model.clef);
            return !!valueObj
        }

        return false;

    }

    const handleOpenHelp = () => {
        setOpenModalHelp(true);
    }

    const handleModalHelpClose = () => {
        setOpenModalHelp(false);
    }

    useEffect(() => {
        if (props.modelInfo !== null){
            setModelBaseInfo(props.modelInfo)
        }
    }, [props.modelInfo]);

    useEffect(() => {
        if(props.model !== null){
            let dataSource = props.plugnotes;

            if(props.globalDataFilter !== null){

                dataSource = dataSource.filter(data => {
                    return fields.reduce((res, field, fieldIndex) => {
                        if (!!field) {
                            const index = fieldIndex + 1 ;
                            let value = data[`field${index}`];
                            if (data[`field${index}format`] === 'select' && data[`field${index}`]) {
                                if(isJsonString(data[`field${index}`])){
                                    value = JSON.parse(data[`field${index}`]).join(', ');
                                }else {
                                    value = '';
                                }
                            }

                            if (data[`field${index}format`] === 'list' && data[`field${index}`] && (data[`field${index}`] !== '\'\'' && data[`field${index}`] !== '[]' &&  data[`field${index}`] !== 'NULL' && data[`field${index}`] !== '""' )) {
                                if(isJsonString(data[`field${index}`])){
                                    value = JSON.parse(data[`field${index}`]).reduce((res, obj) => {
                                        return res.concat(obj.text || '');
                                    }, []).join(', ');
                                }else {
                                    value = '';
                                }
                            }

                            if (data[`field${index}format`] === 'table' && data[`field${index}`]) {
                                if(isJsonString(data[`field${index}`])){
                                    value = JSON.parse(data[`field${index}`]).reduce((res, obj) => {
                                        return res.concat(obj.text + ' : ' + obj.value || '');
                                    }, []).join(', ');
                                }else {
                                    value = '';
                                }
                            }

                            if (data[`field${index}format`] === 'password' && data[`field${index}`]) {
                                value = '*'.repeat(value.length);
                            }

                            if(data[`field${index}format`] === 'glocalis' && data[`field${index}`]){
                                if(typeof value !== "undefined"  && value.length > 0 && isJsonStringObject( data[`field${index}`])){
                                    let tmpValue = JSON.parse( data[`field${index}`]);
                                    value = 'lnt : ' + tmpValue?.longitude +  ' lat : ' + tmpValue?.latitude;
                                }else {
                                    value = "";
                                }
                            }
                            if(data[`field${index}format`] === 'intdatamaj' && data[`field${index}`]){
                                if(typeof value !== "undefined"  && value.length > 0 && isJsonStringObject( data[`field${index}`])){
                                    let tmpValue = JSON.parse( data[`field${index}`]);
                                    value = 'TO : ' + tmpValue?.value +  ' AT : ' + tmpValue?.updatedDate;
                                }else {
                                    value = "";
                                }
                            }

                            if ((value || '').toLowerCase().includes(props.globalDataFilter.toLowerCase()) || (typeof data !== "undefined" && data.userindex.toString().includes(props.globalDataFilter))) {
                                return true;
                            }

                            return res || false;
                        }
                        return res;
                    }, false);
                })

                setCountActive(dataSource.filter((plug, index) => {
                    return !plug.isarchived && !plug.isexported
                }).length)
                setCountFavorite(dataSource.filter((plug, index) => {
                    return plug.favorite && !plug.isexported
                }).length)
                setCountArchived(dataSource.filter((plug, index) => {
                    return plug.isarchived && !plug.isexported
                }).length)
                setCountExported(dataSource.filter((plug, index) => {
                    return plug.isexported
                }).length)

            }
            if(props.dataFilter.length > 0){
                props.dataFilter.map((selectedDataFilter, index) => {
                    if(selectedDataFilter.simpleSearch){
                        dataSource = dataSource.filter(data => {
                            return fields.reduce((res, field, fieldIndex) => {
                                if (!!field) {
                                    const index = fieldIndex + 1;
                                    let value = data[`field${index}`];

                                    if(!!selectedDataFilter[`field${index}`]){
                                        if(index === selectedDataFilter[`field${index}`].field.key && data[`field${index}format`] ===  selectedDataFilter[`field${index}`].field.format && data[`field${index}`]){
                                            if (data[`field${index}format`] === 'select' && data[`field${index}`]) {
                                                if(isJsonString(data[`field${index}`])){
                                                    value = data[`field${index}`];
                                                    //.map(v => v.toLowerCase())
                                                }else {
                                                    value = '';
                                                }
                                            }

                                            if (data[`field${index}format`] === 'table' && data[`field${index}`]) {
                                                if(isJsonString(data[`field${index}`])){
                                                    value = data[`field${index}`];
                                                    //.map(v => v.toLowerCase())
                                                }else {
                                                    value = '';
                                                }
                                            }

                                            if (data[`field${index}format`] === 'list' && data[`field${index}`] && (data[`field${index}`] !== '\'\'' && data[`field${index}`] !== '[]' &&  data[`field${index}`] !== 'NULL' && data[`field${index}`] !== '""' )) {
                                                if(isJsonString(data[`field${index}`])){
                                                    value = data[`field${index}`];
                                                }else {
                                                    value = '';
                                                }
                                            }

                                            if (data[`field${index}format`] === 'password' && data[`field${index}`]) {
                                                value = '*'.repeat(value.length);
                                            }

                                            if(data[`field${index}format`] === 'glocalis' && data[`field${index}`]){
                                                if(typeof value !== "undefined"  && value.length > 0 && isJsonStringObject( data[`field${index}`])){
                                                    let tmpValue = JSON.parse( data[`field${index}`]);
                                                    value = 'lnt : ' + tmpValue?.longitude +  ' lat : ' + tmpValue?.latitude;
                                                }else {
                                                    value = "";
                                                }
                                            }

                                            if(data[`field${index}format`] === 'intdatamaj' && data[`field${index}`]){
                                                if(typeof value !== "undefined"  && value.length > 0 && isJsonStringObject( data[`field${index}`])){
                                                    let tmpValue = JSON.parse( data[`field${index}`]);
                                                    value = 'TO : ' + tmpValue?.value +  ' AT : ' + tmpValue?.updatedDate;
                                                }else {
                                                    value = "";
                                                }
                                            }

                                            if(data[`field${index}format`] === 'select' && data[`field${index}`]){

                                                let selectedDateFilterValue = selectedDataFilter[`field${index}`].value.map((v) => {

                                                    let found = false;
                                                    if(value.includes(v)){
                                                        found = true;
                                                    }
                                                    return found;
                                                });
                                                return !!selectedDateFilterValue.includes(true);

                                            }
                                            else if(data[`field${index}format`] === 'date' && data[`field${index}`]){
                                                if(!!selectedDataFilter[`field${index}`].query && selectedDataFilter[`field${index}`].query !== null){
                                                    switch (selectedDataFilter[`field${index}`].query) {
                                                        case 'from':
                                                            let date1 = new Date(value);
                                                            let date2 = new Date(selectedDataFilter[`field${index}`].value);
                                                            if(date1 >= date2){
                                                                return true;
                                                            }
                                                            break;
                                                        case 'to':
                                                            let date3 = new Date(value);
                                                            let date4 = new Date(selectedDataFilter[`field${index}`].value);
                                                            if(date3 <= date4){
                                                                return true;
                                                            }
                                                            break;
                                                        case 'equal':
                                                            if((value || '').toLowerCase().includes(selectedDataFilter[`field${index}`].value.toLowerCase())){
                                                                return true;
                                                            }
                                                            break;
                                                    }
                                                }
                                            }
                                            else if(data[`field${index}format`] === 'agenda' && data[`field${index}`]){
                                                if(!!selectedDataFilter[`field${index}`].query && selectedDataFilter[`field${index}`].query !== null){
                                                    switch (selectedDataFilter[`field${index}`].query) {
                                                        case 'from':
                                                            //                valueTZ = moment(date,'YYYY-MM-DD').format('YYYY-MM-DD');
                                                            let date1 = moment(value,'YYYY-MM-DD').format('YYYY-MM-DD');
                                                            let date2 = moment(selectedDataFilter[`field${index}`].value,'YYYY-MM-DD').format('YYYY-MM-DD')
                                                            if(date1 >= date2){
                                                                return true;
                                                            }
                                                            break;
                                                        case 'to':
                                                            let date3 = moment(value,'YYYY-MM-DD').format('YYYY-MM-DD');
                                                            let date4 = moment(selectedDataFilter[`field${index}`].value,'YYYY-MM-DD').format('YYYY-MM-DD')
                                                            if(date3 <= date4){
                                                                return true;
                                                            }
                                                            break;
                                                        case 'equal':
                                                            let date5 = moment(value,'YYYY-MM-DD').format('YYYY-MM-DD');
                                                            let date6 = moment(selectedDataFilter[`field${index}`].value,'YYYY-MM-DD').format('YYYY-MM-DD')
                                                            if(date5 == date6){
                                                                return true;
                                                            }
                                                            break;
                                                    }
                                                }
                                            }
                                            else if(data[`field${index}format`] === 'now' && data[`field${index}`]){
                                                if(!!selectedDataFilter[`field${index}`].query && selectedDataFilter[`field${index}`].query !== null){
                                                    switch (selectedDataFilter[`field${index}`].query) {
                                                        case 'from':
                                                            //                valueTZ = moment(date,'YYYY-MM-DD').format('YYYY-MM-DD');
                                                            let date1 = moment(value,'YYYY-MM-DD').format('YYYY-MM-DD');
                                                            let date2 = moment(selectedDataFilter[`field${index}`].value,'YYYY-MM-DD').format('YYYY-MM-DD')
                                                            if(date1 >= date2){
                                                                return true;
                                                            }
                                                            break;
                                                        case 'to':
                                                            let date3 = moment(value,'YYYY-MM-DD').format('YYYY-MM-DD');
                                                            let date4 = moment(selectedDataFilter[`field${index}`].value,'YYYY-MM-DD').format('YYYY-MM-DD')
                                                            if(date3 <= date4){
                                                                return true;
                                                            }
                                                            break;
                                                        case 'equal':
                                                            let date5 = moment(value,'YYYY-MM-DD').format('YYYY-MM-DD');
                                                            let date6 = moment(selectedDataFilter[`field${index}`].value,'YYYY-MM-DD').format('YYYY-MM-DD')
                                                            if(date5 == date6){
                                                                return true;
                                                            }
                                                            break;
                                                    }
                                                }
                                            }
                                            else if(data[`field${index}format`] === 'numeric' && data[`field${index}`]){
                                                switch (selectedDataFilter[`field${index}`].query) {
                                                    case 'from':
                                                        let number1 = Number(value);
                                                        let number2 = Number(selectedDataFilter[`field${index}`].value);
                                                        if(number1 >= number2){
                                                            return true;
                                                        }
                                                        break;
                                                    case 'to':
                                                        let number3 = Number(value);
                                                        let number4 = Number(selectedDataFilter[`field${index}`].value);
                                                        if(number3 <= number4){
                                                            return true;
                                                        }
                                                        break;
                                                    case 'equal':
                                                        let number5 = Number(value);
                                                        let number6 = Number(selectedDataFilter[`field${index}`].value);
                                                        if(number5 == number6){
                                                            return true;
                                                        }
                                                        break;
                                                }
                                            }
                                            else if(data[`field${index}format`] === 'list' && data[`field${index}`]){
                                                let selectedDateFilterValue = selectedDataFilter[`field${index}`].value.map((v) => {
                                                    let found = false;
                                                    if(value.includes(v)){
                                                        found = true;
                                                    }
                                                    return found;
                                                });
                                                return !!selectedDateFilterValue.includes(true);
                                            }
                                            else if(data[`field${index}format`] === 'table' && data[`field${index}`]){
                                                let selectedDateFilterValue = selectedDataFilter[`field${index}`].value.map((v) => {
                                                    let found = false;
                                                    if(value.includes(v)){
                                                        found = true;
                                                    }
                                                    return found;
                                                });
                                                return !!selectedDateFilterValue.includes(true);
                                            }
                                            else {

                                                if((value || '').toLowerCase().includes(selectedDataFilter[`field${index}`].value.toLowerCase())){
                                                    return true;
                                                }
                                            }
                                        }

                                    }
                                    return res || false;
                                }
                                return res;
                            }, false);
                        });
                    }

                });


                if(actif){
                    setCountActive(dataSource.filter((plug, index) => {
                        return !plug.isarchived && !plug.isexported
                    }).length)
                }

                if(favoris){
                    setCountFavorite(dataSource.filter((plug, index) => {
                        return plug.favorite && !plug.isexported
                    }).length)
                }

                if(archived){
                    setCountArchived(dataSource.filter((plug, index) => {
                        return plug.isarchived && !plug.isexported
                    }).length)
                }

                if(exported){
                    setCountExported(dataSource.filter((plug, index) => {
                        return plug.isexported
                    }).length)
                }

                if(filters.length === 0){
                    props.dataFilter.map((item, index) => {
                        const  fieldKey = Object.keys(item);
                        setFilters(oldArray => [...oldArray, {position : oldArray.length + 1, field : item[fieldKey[0]].field, value: item[fieldKey[0]].value, query: item[fieldKey[0]].query}]);
                    });
                    setOpen(false);
                    setSubOpen(true);
                }
            }
            if(props.globalDataFilter === null && props.dataFilter.length === 0){
                setCountActive(dataSource.filter((plug, index) => {
                    return !plug.isarchived
                }).length);
                setCountFavorite(dataSource.filter((plug, index) => {
                    return plug.favorite
                }).length);
                setCountArchived(dataSource.filter((plug, index) => {
                    return plug.isarchived
                }).length);
                setCountExported(dataSource.filter((plug, index) => {
                    return plug.isexported
                }).length);

                dataSource = dataSource.filter(data => {
                    return data;
                });

            }

            setPlugnotes(dataSource);
        }
    }, [props.plugnotes,props.globalDataFilter,props.dataFilter]);

    useEffect(() => {

        if (props.model !== null){
            let currentModel = props.model;

            if(currentModel !== 'undefined' && currentModel !== null){
                const labels = Object.keys(currentModel).filter((key) => {
                    const regex = /field[0-9]+label/gi;
                    return regex.test(key);
                });

                let itemFields = labels.filter(Boolean);

                let currentItemFields = itemFields.map((item) => {
                    return currentModel[item];
                });

                setFields(currentItemFields.filter(Boolean));
            }

            if(currentModel !== 'undefined' && currentModel !== null){
                const labels = Object.keys(currentModel).filter((key) => {
                    const regex = /field[0-9]+label/gi;
                    return regex.test(key) && currentModel[key] != null;
                });

                let fields = labels.map((label, index) => {
                    let formatoptions = currentModel['field' + (index + 1) + 'formatoptions'] || {};
                    if (typeof formatoptions !== 'object') {
                        formatoptions = JSON.parse(formatoptions);
                    }

                    return {
                        key: index + 1,
                        id: index + 1,
                        label: currentModel['field' + (index + 1) + 'label'],
                        format: currentModel['field' + (index + 1) + 'format'],
                        options: {
                            mandatory: !!currentModel['field' + (index + 1) + 'mandatory'],
                            ...formatoptions
                        },
                        formatoptions: currentModel['field' + (index + 1) + 'formatoptions']
                    }
                });

                setFieldsInfos(fields);
            }
        }

    }, [props.model]);

    useEffect(() => {

        let checkExistingSelection = JSON.parse(localStorage.getItem('fieldsSelected'));

        if(!checkExistingSelection){

            if((JSON.stringify(defaultSelectedFields) !== JSON.stringify(selectedFields))){
                let fieldByModel = {
                    'hash' : props.model.hash,
                    'fields' : selectedFields,
                    'environnement' : environment.userhash,
                    'date' : moment().format('YYYY/MM/DD'),
                };

                setFieldsSelected(fieldByModel);
            }
        }
        else {
            let findIndex = checkExistingSelection.findIndex(item  => item.hash === props.model.hash);
            if(findIndex !== -1){
                if((JSON.stringify(defaultSelectedFields) !== JSON.stringify(selectedFields))){
                    let fieldByModel = {
                        'hash' : props.model.hash,
                        'fields' : selectedFields,
                        'environnement' : environment.userhash,
                        'date' : moment().format('YYYY/MM/DD'),
                    };

                    setFieldsSelected(fieldByModel);
                }else {
                    delSpecifiFieldsSelected(props.model.hash);
                }
            }else {
                if((JSON.stringify(defaultSelectedFields) !== JSON.stringify(selectedFields))){
                    let fieldByModel = {
                        'hash' : props.model.hash,
                        'fields' : selectedFields,
                        'environnement' : environment.userhash,
                        'date' : moment().format('YYYY/MM/DD'),
                    };

                    setFieldsSelected(fieldByModel);
                }
            }
        }

    }, [selectedFields]);

    return (
        <div className={classes.root}>
            <CssBaseline />
            { !open && openSearch ?
                <div className={classes.plugSearchContainer}>
                    <MenuLeftPlugSearchComponent
                        globalApiDataFilter={props.globalApiDataFilter}
                        close={() => setOpenSearch(false)}
                    />
                </div>
                :
                null
            }
            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx(classes.paperAnchorDockedLeft,{
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                {/*<div className={classes.toolbar}/>*/}
                <div className={classes.openCloseRelative}>
                    {
                        !subOpen &&
                        <IconButton className={!open ? classes.openCloseButton : classes.openedCloseButton} onClick={open ? handleDrawerClose : handleDrawerOpen}>
                            {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    }
                </div>
                {/* <div style={{padding: '0px 8px'}}>
                    { open ?
                        <MenuLeftPlugSearchComponent globalDataFilter={props.globalDataFilter}/>
                        :
                        <div>
                            <span id="icon-listForm" className={`material-icons md-25 ${props.isDataSearchActive ? classes.iconSearchActive : classes.iconSearch}`} onClick={(event) => setOpen(true)}>
                                search
                            </span>
                            { props.isDataSearchActive &&
                            <span id="icon-listForm" className={`material-icons md-25`} onClick={(event) => props.attemptDelGlobalDataFilter()} >
                                close
                            </span>
                            }
                        </div>
                    }
                </div> */}
                <div style={{padding: '0px 8px'}}>
                    {/* { props.isDataSearchActive &&
                    <span id="icon-listForm" className={`material-icons md-25`} onClick={(event) => props.attemptDelGlobalDataFilter()} >
                        close
                    </span>
                    } */}
                    { open ?
                        <MenuLeftPlugSearchComponent
                            globalDataFilter={props.globalDataFilter}
                            close={() => setOpenSearch(false)}
                        />
                        :
                        <Tooltip title={t("common:plugnote.plug-search")} placement='right' classes={{ tooltip: classes.tooltipStyle }}>
                        <span id="icon-listForm" className={`material-icons md-25 ${props.isDataSearchActive ? classes.iconSearchActive : classes.iconSearch}`} onClick={(event) => setOpenSearch(!openSearch)}>
                            search
                        </span>
                        </Tooltip>
                    }
                </div>
                <List className={classes.listHeight}>
                    <div className="left-menu_contents">
                        {/*                        <ListItem disableGutters={true}>
                            <ListItemIcon className="green" ><Person/></ListItemIcon>
                            <ListItemText primary={environment.name} />
                        </ListItem>*/}
                        <div className="left-menu_contents_wrapper">
                            <ul className="left-menu_contents_actions">
                                {props.model.ms_add_fiche > 0 &&
                                    <li className={classes.pointer} onClick={handleCreatePlugnote}>
                                        <Tooltip title={t('common:pluglist.add-plugnote')} placement='right' classes={{ tooltip: classes.tooltipStyle }}>
                                        <span className="material-icons md-25 ">
                                            add
                                        </span>
                                        </Tooltip>
                                        <div>{t('common:pluglist.add-plugnote')}</div>
                                    </li>
                                }
                                {/*                                <li className={classes.pointer} onClick={handleOpenSubDrawer}>
                                <span className={`material-icons md-25 ${props.dataFilter.length > 0 ? 'green' : null}`}>
                                        filter_list
                                    </span>
                                    <div>{t('common:pluglist.filter-plugnote')}</div>
                                </li>*/}

                                {/* <li className={classes.pointer} onClick={handleSaveCurrentFilterSelection}>
                                <span className={`material-icons md-25 ${props.dataFilter.length > 0 ? 'green' : null}`}>
                                    filter_none
                                </span>
                                    <div>{t('common:pluglist.filter-plugnote')}</div>
                                </li>
                                <li className={classes.pointer} onClick={handleOpenListFilterSelection}>
                                <span className={`material-icons md-25 ${props.dataFilter.length > 0 ? 'green' : null}`}>
                                    filter_1
                                </span>
                                    <div>{t('common:pluglist.filter-plugnote')}</div>
                                </li> */}

                                {props.model.ms_read_fiche > 0 &&
                                    <li className={classes.pointer} onClick={handlePressExportCsv}>
                                        <Tooltip title={t('common:pluglist.export-excel')} placement='right' classes={{ tooltip: classes.tooltipStyle }}>
                                        <span className="fontAweome-icons">
                                            <FontAwesomeIcon icon={faFileExcel} size="lg" style={{fontSize : 21, marginRight:2}}/>
                                        </span>
                                        </Tooltip>
                                        <div>{t('common:pluglist.export-excel')}</div>
                                    </li>
                                }

                                {props.model && props.model.ms_read_fiche > 0 && Object.values(props.model).find(element => element === "tablemulti" || element === "tablemultin" || element === "document") &&
                                    <li className={classes.pointer} onClick={handlePressExportTableMultipleCsv}>
                                        <Tooltip title={t('common:pluglist.export-excel')} placement='right' classes={{ tooltip: classes.tooltipStyle }}>
                                        <span className="fontAweome-icons">
                                            <FontAwesomeIcon icon={faTable} size="lg" style={{fontSize : 21}}/>
                                        </span>
                                        </Tooltip>
                                        <div>{t('common:pluglist.export-excel')}</div>
                                    </li>
                                }

                                {props.model.add_fiche > 0 && props.model.ms_read_fiche > 0 && props.model.ms_edit_fiche > 0 &&
                                    <li className={classes.pointer} onClick={handleModalTemplateImportExportCsvOpen}>
                                        <Tooltip title={t('common:pluglist.template-import-generate')} placement='right' classes={{ tooltip: classes.tooltipStyle }}>
                                        <span className="material-icons md-25 ">
                                            table_view
                                        </span>
                                        </Tooltip>
                                        <div>{t('common:pluglist.template-import-generate')}</div>
                                    </li>
                                }

                                {props.model.add_fiche > 0 && props.model.ms_read_fiche > 0 && props.model.ms_edit_fiche > 0 &&
                                    <li className={classes.pointer} onClick={handleModalImportExportCsvOpen}>
                                        <Tooltip title={t('common:pluglist.template-import-receiver')} placement='right' classes={{ tooltip: classes.tooltipStyle }}>
                                        <span className="material-icons md-25 ">
                                            import_export
                                        </span>
                                        </Tooltip>
                                        <div>{t('common:pluglist.template-import-receiver')}</div>
                                    </li>
                                }

                                {props.model.ms_read_annexe > 0 &&
                                    <li className={classes.pointer} onClick={handlePressExportFile}>
                                        <Tooltip title={t('common:pluglist.export-attachments')} placement='right' classes={{ tooltip: classes.tooltipStyle }}>
                                    <span className="fontAweome-icons">
                                        <FontAwesomeIcon icon={faPaperclip} size="lg" style={{fontSize : 21}}/>
                                    </span>
                                        </Tooltip>
                                        <div>{t('common:pluglist.export-attachments')}</div>
                                    </li>
                                }
                                <li className={classes.pointer} onClick={handleOpenHelp}>
                                    <Tooltip title={t('common:global.help-manuels')} placement='right' classes={{ tooltip: classes.tooltipStyle }}>
                                        <span className="material-icons green md-25 ">
                                            help
                                        </span></Tooltip>
                                    <div>{t('common:global.help-manuels')}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </List>
                {!subOpen &&
                    <div className={classes.openClose}>
                        <IconButton className={classes.openCloseButton} onClick={open ? handleDrawerClose : handleDrawerOpen}>
                            {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                        </IconButton>
                    </div>
                }
            </Drawer>

            <Drawer
                variant="permanent"
                className={clsx(classes.subDrawer, {
                    [classes.subDrawerOpen]: subOpen,
                    [classes.subDrawerClose]: !subOpen,
                })}
                classes={{
                    paper: clsx(classes.paperAnchorDockedLeft,{
                        [classes.subDrawerOpen]: subOpen,
                        [classes.subDrawerClose]: !subOpen,
                    }),
                }}
            >
                <div className={classes.toolbar}/>
                <List>
                    <div className="left-menu_contents">
                        <div className="left-menu_contents_wrapper flexSpacebetween">
                                <span className="titres">
                                    Filtrer
                                </span>
                            <div className="lightgrey letterSpacingNegatif10">
                                <span className="material-icons md-25 cursor" onClick={(event) => handleResetPlugSearch()}>
                                    close
                                </span>
                                <span className="material-icons md-30 cursor" onClick={(event) => handleSubDrawerClose()}>
                                    keyboard_arrow_left
                                </span>
                            </div>
                        </div>
                        { filters.length > 0 && filters.map((item, index) => {
                            return (
                                <FilterSelectComponent
                                    key={index}
                                    fields={fieldsInfos}
                                    index={index}
                                    filter={item}
                                    handleDelFiltersLine={handleDelFiltersLine}
                                    attemptSetDataFilter={attemptSetDataFilter}
                                    attemptDelDataFilter={attemptDelDataFilter}
                                    length={props.dataFilter.length}
                                />
                            )})}
                        <div className="left-menu_contents_wrapper" onClick={(event) => handleAddFiltersLine()}>
                            <div className="cliquables centre">
                                <span> + {t('common:pluglist.add-another-filter')}</span>
                            </div>
                        </div>
                    </div>
                </List>
            </Drawer>

            <main className={subOpen === false ? classes.content : classes.contentSub}>
                {(props.isSearchActive || props.isDataSearchActive ) &&
                    <HeaderSearch />
                }

                {modelBaseInfo !== null &&
                    <Header>
                        <div className="sous-header-without-height p1 grey">
                            <div className="sous-header_content sous-header_content_center">
                                <div style={{flex : 1}}>
                                    <img className="logo" src={Images.logo} alt="logo" onClick={(event) => handleModels()} />
                                    {/*                                <span id="icon-listForm" className={`material-icons md-25 ${!listView ? classes.iconActive : classes.icon}`} onClick={handleChangeView}>
                                apps
                            </span>
                            <span id="icon-blockForm" className={`material-icons md-25 ${listView ? classes.iconActive : classes.icon}`} onClick={handleChangeView}>
                                menu
                            </span>*/}
                                </div>
                                <div style={{flex : 1, textAlign : 'center'}}>
                            <span className="titres">
                                {props.modelInfo.label}
                            </span>
                                </div>
                                <div style={{flex : 1}}>
                                    <div className="grey display_flex display_flex_right float_right">
                                        <div className="padding_left">
                                            <input className="checkbox" type="checkbox" checked={actif}
                                                   onChange={(event) => {handleSelectedActif()}}/>
                                            {t('common:plugnote.plugnote-active')} ({countActive})
                                        </div>
                                        <div className="padding_left">
                                            <input className="checkbox" type="checkbox" checked={archived}
                                                   onChange={(event) => {handleSelectedArchived()}}/>
                                            {t('common:plugnote.plugnote-archived')} ({countArchived})
                                        </div>
                                        {
                                            props.authapi && habilityToUse() &&
                                            <div className="padding_left">
                                                <input className="checkbox" type="checkbox" checked={exported}
                                                       onChange={(event) => handleSelectedExported()}/>
                                                {t('common:plugnote.plugnote-exported')} ({countExported})
                                            </div>
                                        }

                                        <div className="padding_left">
                                    <span id="icon-close" className="material-icons md-30" onClick={handleExitPlugList}>
                                        close
                                    </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="sous-header_content black">
                                <div className="line flex_style">
                                </div>
                            </div>

                        </div>
                    </Header>
                }

                {props.model !== null &&
                    <Content
                        className={!listView ? classes.contentModelGrid : !filterFieldsOpen ?  classes.contentModelList : classes.contentModelListOpenFilter}
                    >
                        {
                            listView && typeof plugnotes !== "undefined" && typeof selectedRows !== "undefined" &&  plugnotes.length > 0 &&  selectedRows.length > 0 &&
                            <span>

                        {
                            props.model.ms_edit_fiche > 0 &&
                            <span id="icon-archive" className="material-icons md-25" onClick={handleAllArchive}>
                                archive
                            </span>
                        }

                                {
                                    props.model.ms_delete_fiche > 0 &&
                                    <span id="icon-supprimer" className="material-icons md-25" onClick={handleAllDelete}>
                                delete
                            </span>
                                }
                    </span>
                        }

                        { plugnotes.length > 0 &&
                            <ViewPlugnotes
                                plugnotes={plugnotes}
                                listView={listView}
                                actif={actif}
                                archived={archived}
                                favoris={favoris}
                                handleSelectPlugnote={handleSelectPlugnote}
                                handleCreatePlugnote={handleCreatePlugnote}
                                handleFlip={handleFlip}
                                flipped={flipped}
                                handleDeletePlugnote={handleDeletePlugnote}
                                handleFavorisPlugnote={handleFavorisPlugnote}
                                handleArchivePlugnote={handleArchivePlugnote}
                                handleLockPlugnote={handleLockPlugnote}
                                handleModalPlugnotePlugcodeOpen={handleModalPlugnotePlugcodeOpen}
                                handleModalPlugnotePlugcodeClose={handleModalPlugnotePlugcodeClose}
                                handleModalPlugnoteClassifyOpen={handleModalPlugnoteClassifyOpen}
                                selectedFields={selectedFields}
                                attemptGetPlugnotesPaginateByModelHashAndUserHash={props.attemptGetPlugnotesPaginateByModelHashAndUserHash}
                                hash={hash}
                                modelhash={props.model.hash}
                                limitPage={props.end}
                                startPage={props.start}
                                setSelectedFields={setSelectedFields}
                                defaultSelectedFields={defaultSelectedFields}
                                setDefaultSelectedFields={setDefaultSelectedFields}
                                setFilters={setFilters}
                                setSubOpen={setSubOpen}
                                fields={fieldsInfos}
                                filters={filters}
                                linkOperator={link}
                                setLink={setLink}
                                attemptUpdatePlugnoteField={props.attemptUpdatePlugnoteField}
                                attemptDelAllDataFilter={props.attemptDelAllDataFilter}
                                attemptDelDataFilter={attemptDelDataFilter}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                                sizeFields = {sizeFields}
                                setSizeFields ={setSizeFields}
                                defaultSizeFields = {defaultSizeFields}
                                setDefaultSizeFields = {setDefaultSizeFields}
                                props={props}
                                filterSet={filterSet}
                                setFilterSet={setFilterSet}
                                exported={exported}
                            />
                        }
                        { plugnotes.length > 0 && props.model.ms_add_fiche > 0 &&
                            <span className="dot_fixed red" onClick={handleCreatePlugnote.bind(this)}>
                        <span id="icon-qrcode" className="material-icons icon-add_style">
                            add
                        </span>
                    </span>
                        }

                        { plugnotes.length === 0 && props.dataFilter.length === 0 && !promiseInProgress && props.model.ms_add_fiche > 0 &&
                            <div className="centre dossier_vide grey">
                                <div className="titres_vide">
                                    Vous êtes maintenant dans le dossier "{props.model.label}". <br/>
                                    Créez votre première Plugnote à l'intérieur du dossier.
                                </div>
                                <span className="dot red centre">
                            <span id="icon-qrcode" className="material-icons white" style={{color:'white', fontSize:'60px'}} onClick={handleCreatePlugnote.bind(this)}>
                                add
                            </span>
                        </span>

                                <div className="titres_vide P1 cliquables">
                                    Comment ça marche ?
                                </div>
                            </div>
                        }
                    </Content>
                }
            </main>

            { props.model && isOpenModalModelPlugcode ?
                <ModalModelPlugcodeComponent open={isOpenModalModelPlugcode} handleModalModelPlugcodeClose={handleModalModelPlugcodeClose} close={handleModalModelPlugcodeClose}/>
                :
                null
            }
            { props.model && isOpenModalModelShare ?
                <ModalModelShareComponent open={isOpenModalModelShare} handleModalModelShareClose={handleModalModelShareClose} close={handleModalModelShareClose}/>
                :
                null
            }

            { props.model  && isOpenModalExportSimpleCsv ?
                <ModalSimpleExportCsvComponent open={isOpenModalExportSimpleCsv} close={handleModalExportCsvClose} modelShared={true} payload={payload}/>
                :
                null
            }

            { props.model && isOpenModalTemplateImportExportCsv ?
                <ModalTemplateImportExportCsvComponent open={isOpenModalTemplateImportExportCsv} close={handleModalTemplateImportExportCsvClose} modelShared={true}/>
                :
                null
            }

            { props.model  && isOpenModalExportTableMultipleSimpleCsv ?
                <ModalSimpleExportTableMultipleCsvComponent open={isOpenModalExportTableMultipleSimpleCsv} close={handleModalExportTableMultipleCsvClose} modelShared={true} payload={payload}/>
                :
                null
            }

            { props.model && isOpenModalImportExportCsv ?
                <ModalImportExportCsvComponent open={isOpenModalImportExportCsv} close={handleModalImportExportCsvClose} modelShared={true}/>
                :
                null
            }

            { getPlugnote()  && isOpenModalPlugnotePlugcode ?
                <ModalPlugnotePlugcodeComponent open={isOpenModalPlugnotePlugcode} close={handleModalPlugnotePlugcodeClose} modelShared={true}/>
                :
                null
            }

            {
                isOpenClassify &&
                <ModalCopyOrMovePlugnoteComponent open={isOpenClassify} close={handleModalPlugnoteClassifyClose}/>
            }

            {
                props.model  && openSaveSelectionFilter && filterSet.length > 0 &&
                <FilterSelectionSaveComponent open={openSaveSelectionFilter} modelHash={props.model.hash} model={props.model} clef={props.model.envIndexOwner + '-' + props.model.clef} filterSet={filterSet} close={(event) => setOpenSaveSelectionFilter(false)}/>
            }

            {
                props.model  && openLoadSelectionFilter &&
                <FilterSelectionLoadComponent open={openLoadSelectionFilter} modelHash={props.model.hash} setFilterSet={setFilterSet} close={(event) => setOpenLoadSelectionFilter(false)}/>
            }

            {
                openModalHelp  ?
                    <ModalHelpComponent open={openModalHelp} close={handleModalHelpClose} from="plugnotes"/>
                    :
                    null
            }

        </div>
    );
}


function PlugListEmptyComponent(props) {

    const { t, i18n } = useTranslation(['common', 'models', 'global']);

    const classes = useStyles();
    const environment = getCurrentEnvironnement();

    const [hash, setHash] = useState(environment.userhash);
    const [open, setOpen] = useState(false);
    const [subOpen, setSubOpen] = useState(false);

    return(
        <div className={classes.root}>
            <CssBaseline />
            <main className={subOpen === false ? classes.content : classes.contentSub}>
                {
                    (props.isSearchActive || props.isDataSearchActive ) &&
                    <HeaderSearch />
                }

                {
                    props.modelInfo !== null &&
                    <Header>
                        <div className="sous-header-without-height p1 grey">

                            <div className="sous-header_content sous-header_content_center">
                                <div style={{flex : 1}}>
                                    <img className="logo" src={Images.logo} alt="logo" />
                                </div>
                                <div style={{flex : 1, textAlign : 'center'}}>
                                <span className="titres">
                                    {props.modelInfo.label}
                                </span>
                                </div>
                                <div style={{flex : 1}}>
                                    <div className="grey display_flex display_flex_right float_right">
                                        <div className="padding_left">
                                            <input className="checkbox" type="checkbox" checked={false} readOnly={true} />
                                            {t('common:plugnote.plugnote-active')} (0)
                                        </div>
                                        <div className="padding_left">
                                            <input className="checkbox" type="checkbox" checked={false} readOnly={true} />
                                            {t('common:plugnote.plugnote-archived')} (0)
                                        </div>
                                        <div className="padding_left">
                                        <span id="icon-close" className="material-icons md-30">
                                            close
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="sous-header_content black">
                                <div className="line flex_style">
                                </div>
                            </div>

                        </div>
                    </Header>
                }
            </main>
        </div>

    )
}


function PlugSharedListLight(props) {
    const environment = getCurrentEnvironnement();

    useEffect(() => {
        deleteHistoryPlugAction();
        if(getTypeOfPlugnote() === 'exported'){
            props.attemptGetPlugnotesByModelHashAndUserHash(getModel().hash, environment.userhash,true);
        }else{
            props.attemptGetPlugnotesByModelHashAndUserHash(getModel().hash, environment.userhash);
        }

    }, [])

    if (props.model !== null){
        return(
            <PlugSharedListComponent modelInfo={getModel()} {...props} />
        )
    }else {
        return(
            <PlugListEmptyComponent modelInfo={getModel()} {...props} />
        );
    }

}


// redux providing state takeover
const mapStateToProps = (state) => {
    // console.log('state', state);
    let datas = [];
    let dataFilter = [];
    let globalDataFilter = null;


    if(state.search.isSearchActive && state.search.isSearchResult && state.data.list.length > 0 && (state.search.searchParams.hasOwnProperty('pnnumber') || state.search.searchParams.hasOwnProperty('content') )){
        state.data.list.map((item, index) => {
            let find = false;
            state.search.searchResult.map((search, index) => {
                if(item.hash === search.datahash){
                    find = true;
                }
            });
            if(find){
                datas.push(item);
            }
        });
    }else {
        datas = !! state.data.list ? state.data.list : [];
    }

    if(!state.search.isSearchActive && !state.search.isSearchResult){
        datas = !! state.data.list ? state.data.list : [];
    }


    let datasSorted = datas.sort((a,b) => {
        return a.userindex - b.userindex;
    })

    if(typeof state.data.dataFilter !== 'undefined' ){
        if(state.data.dataFilter.length > 0){
            dataFilter = state.data.dataFilter;
        }
    }

    if(typeof state.data.dataGlobalFilter !== 'undefined' ){
        if(state.data.dataGlobalFilter !== null){
            globalDataFilter = state.data.dataGlobalFilter;
        }
    }

    let dataFilterSorted = dataFilter.sort((a,b) => {
        return a.position - b.position;
    })

    return {
        model : state.model.model,
        end : state.data.end,
        start : state.data.start,
        user : state.user,
        profil: state.user.profil,
        token : state.user.token,
        plugnotes : datasSorted.reverse(),
        models : !! state.model.datas ? state.model.datas : [],
        fetching : state.data.fetching,
        dataFilter : dataFilterSorted,
        globalDataFilter : globalDataFilter,
        isDataSearchActive : state.data.isDataSearchActive,
        params : state?.params?.list || [],

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptDeleteFilter: (payload) => dispatch(deleteFilterRequest(payload)),
        attempListFilterSaveByModel: (payload) => dispatch(postSendListFilterRequest(payload)),
        attemptResetAnnexe: () => dispatch(reduxGenerateAnnexeReset()),
        attemptSetDataFilter: (dataFilter, key) => dispatch(reduxSetDataFilterRequest(dataFilter, key)),
        attemptDelDataFilter: (dataFilter) => dispatch(reduxDelDataFilterRequest(dataFilter)),
        attemptGetPlugnotesByModelHashAndUserHash: (modelhash,userhash,mode) => dispatch(getDatasSharedLightRequest(modelhash,userhash,mode)),
        old_attemptGetPlugnotesByModelHashAndUserHash: (modelhash,userhash) => dispatch(getDatasRequest(modelhash,userhash)),
        attemptGetPlugnotesPaginateByModelHashAndUserHash: (modelhash,userhash,page,mode) => dispatch(getDatasSharedPaginateLightRequest(modelhash,userhash,page,mode)),
        old_attemptGetPlugnotesPaginateByModelHashAndUserHash: (modelhash,userhash,page) => dispatch(getDatasPaginateRequest(modelhash,userhash,page)),
        attemptDeletePlugote: (datahash, userhash) => dispatch(delDeleteDataRequest(datahash, userhash)),
        attemptUpdateFavorisPlugnote: (datahash, favorite, userhash) => dispatch(putUpdateDataFavorisRequest(datahash, favorite, userhash)),
        attemptUpdateLockPlugnote: (datahash, isarchived, locked, userhash) => dispatch(putUpdateDataLockRequest(datahash, isarchived, locked, userhash)),
        attemptUpdateArchivePlugnote: (datahash, isarchived, locked, userhash) => dispatch(putUpdateDataArchiveRequest(datahash, isarchived, locked, userhash)),
        attemptResetModelPlugcode: () => dispatch(reduxGeneratePlugformcodeReset()),
        attemptResetPlugnotePlugcode: () => dispatch(reduxGeneratePlugnotecodeReset()),
        attemptResetModelShare: () => dispatch(reduxGenerateShareReset()),
        attemptRemoveModel :(modelhash,userhash) => dispatch(delModelUniqueRequest(modelhash, userhash)),
        attemptExportFullFiles:(modelhash, usermainhash, payloadhash) => dispatch(postExportFullFileDatasRequest(modelhash, usermainhash, payloadhash)),
        attemptDeleteDatas: (payloadhash, userhash) => dispatch(delDeleteDatasRequest(payloadhash, userhash)),
        attemptArchivesDatas: (payloadhash, userhash) => dispatch(putUpdateDatasArchiveRequest(payloadhash, userhash)),
        attemptDelAllDataFilter: () => dispatch(reduxDelAllDataFilterRequest()),
        attemptDelGlobalDataFilter: () => dispatch(reduxDelGlobalDataFilterRequest()),
        attemptUpdatePlugnoteField: (modelhash, datahash, userhash, formData) => dispatch(putUpdateDataFieldRequest(modelhash, datahash, userhash, formData)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlugSharedListLight)
