import React, { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next";
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { connect } from "react-redux";
import { getModel, getPlugnote } from "../../utils/Common";
import TextField from "@material-ui/core/TextField";
import '@contentful/forma-36-react-components/dist/styles.css';
import RichEditor from "../custom/rich-editor/RichEditor";

function getModalStyle() {
    const top = 5;
    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        //width: '50%',
        //height : '50%',
        //backgroundColor: theme.palette.background.paper,
        //border: '2px solid #000',
        //boxShadow: theme.shadows[5],
        minWidth : '90%',
        maxWidth : '90%',
        //padding: theme.spacing(2, 4, 3),
        backgroundColor: 'white',
        //width: '820px',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 10,
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    marginLeft:{
        marginLeft : '10px'
    }
}));

const ModalNoteComponent = (props) => {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const { t } = useTranslation('common');
    const editorRef = useRef(null)

    const model = getModel();
    const plugnote = getPlugnote();
    const [update, setUpdate] = React.useState(false);

    const [open, setOpen] = React.useState(true);
    const [key, setKey] = React.useState(0);
    const [note, setNote] = React.useState(null);
    const [title, setTitle] = React.useState("" );
    const [content, setContent] = React.useState("");
    const disabled = typeof props.disabled === 'undefined' ? false: props.disabled

    const handleEditBody = (value) => setContent(value)

    const handleCloseModal = () => {
        props.close();
    };

    const handleSaveNote = () => {
        //modelhash, datahash, userhash, name, note
        let titleSave = title;
        let contentSave = content;

        props.handleSaveNote(title, content);
        props.close();
    };

    const handleUpdateNote = () => {
        let titleSave = title;
        let contentSave = content;
        let hash = note.hash;
        let webdocHash =  note.Webdoc.hash;
        props.handleUpdateNote(titleSave,contentSave, hash, webdocHash);
        props.close();
    }

    useEffect(() => {

        let isCancelled = false;

        if(props.open){

            if (!isCancelled) {
                if(props.selectedNote !== null){
                    setKey(props.selectedNote.id);
                    setUpdate(true);
                    setNote(props.selectedNote);
                    setTitle(props.selectedNote.name);
                    setContent(props.selectedNote.Webdoc.content);
                }else{
                    setTitle("");
                    setContent("");
                    setUpdate(false);
                    setNote(null);
                    setKey(0);
                }
            }
        }

        return () => {
            isCancelled = true;
        };


    }, [props.open]);

    return (
        <Modal
            key={key}
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
        >
            <div style={modalStyle} className={classes.popup}>
                <div className = "scrollbar-hidden popup_wrapper flexSpacebetween" >
                    <div className="popup_wrapper_content">
                        <div className="sous-header_modal_content">
                            <div>
                                <span className="titres">
                                    <TextField
                                        disabled={disabled}
                                        onChange={(event) => setTitle(event.target.value)}
                                        id="standard-full-width"
                                        //label={field.title}
                                        type="text"
                                        placeholder="Edit Titre"
                                        helperText=""
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        //className={classes.fieldRight}
                                        value={title}
                                        InputProps={{ disableUnderline: true }}
                                    />
                                     <br/>
                                </span>
                            </div>
                            <div onClick={handleCloseModal}>
                                    <span id="icon-close" className="material-icons md-50 lightgrey">
                                    close
                                    </span>
                            </div>

                        </div>
                        <div className="sous-header_content lightgrey">
                            <div className="line flex_style">
                            </div>
                        </div>
                    </div>
                    <div className="popup_wrapper_content">

                        {/* <HtmlEditor
                            options={options}
                            value={content}
                            onChange={(value) => setContent(value)}
                            className="input input_area"
                        /> */}
                        <RichEditor
                            init={{
                                height: 500,
                                menubar: true,
                                plugins: [
                                    'advlist', 'anchor', 'autolink', 'image', 'link', 'lists',
                                    'searchreplace', 'table', 'wordcount'
                                ],
                                // toolbar: 'undo redo | blocks | ' +
                                //     'bold italic forecolor | alignleft aligncenter ' +
                                //     'alignright alignjustify | bullist numlist outdent indent | ' +
                                //     'removeformat | help',
                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                            }}
                            onEditorChange={handleEditBody}
                            onInit={(evt, editor) => editorRef.current = editor}
                            value={content}
                        />
                    </div>
                    {
                        !disabled &&
                            <div>
                                {
                                    update ?
                                        <button className="button" onClick={handleUpdateNote} disabled={content.length === 0 && title.length === 0}>{t('common:multiple.update')}</button>
                                        : 
                                        <button className="button" onClick={handleSaveNote} disabled={content.length === 0 && title.length === 0}>{t('common:multiple.save')}</button>
                                }
                            </div>
                    }
                </div>
            </div>
        </Modal>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalNoteComponent)