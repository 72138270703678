import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { trackPromise } from 'react-promise-tracker'

import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import _, { isNull } from 'lodash'
import { connect } from 'react-redux'
import { getCurrentEnvironnement, getModel, getToken } from '../../utils/Common'
import {
  deleteGeneratePlugformcodeRequest,
  getPlugformcodeRequest,
  postGeneratePlugformcodeRequest,
  putGeneratePlugformcodeRequest
} from '../../actions/plugcode'
import Images from '../../assets/themes/Images'
import QRCode from 'qrcode.react'
import ApplicationConfig from '../../config/ApplicationConfig'
import * as moment from 'moment'
import html2canvas from 'html2canvas'
import { confirmAlert } from 'react-confirm-alert'
import { toast } from 'react-toastify'
import { isFirefox, isSafari } from 'react-device-detect'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import TagsInputObjectMails from '../specials/input/TagsInputObjectMails'
import ModalSimpleSendMailV2PlugformCodeComponent from './ModalSimpleSendMailV2PlugformCodeComponent'
import {
  addZeroBefore,
  dateFormat,
  devLog,
  handlePreventDefault,
  isDefined,
  isJsonString,
  returnJSON
} from '../../utils/utils'
import colors from '../../assets/themes/Colors'
import PlugTabs from '../constructors/shared/PlugTabs'
import { ColorCheckbox, PlugTooltip } from '../custom/CustomComponents'
import { InputLabel, Tab } from '@material-ui/core'
import {
  BackupTable,
  CheckScreenIcon,
  EditValueIcon,
  SendOptionsIcon,
  SentEmailIcon,
  SettingsIcon,
  TableOrientationIcon
} from '../custom/icons/CustomIcons'
import { ContactMail, Subject } from '@material-ui/icons'
import Axios from 'axios'
import TextField from '@material-ui/core/TextField'
import RichEditor from '../custom/rich-editor/RichEditor'
import PlugcodeSendingFirstPreviewComponent from './plugcode/PlugcodeSendingFirstPreviewComponent'
import PlugRadio from '../custom/PlugRadio'
import PlugcodeSendingSecondPreviewComponent from './plugcode/PlugcodeSendingSecondPreviewComponent'
import { encodeHtml } from '../../utils/pdf'
import MaskedInput from 'react-text-mask'
import tinymce from 'tinymce/tinymce'

const myGenericTrackedWrapper = (fn, ...args) => trackPromise(fn(...args))

function getModalStyle() {
  const top = 10
  return {
    top: 'auto',
    margin: 'auto'
    //left: `${left}%`,
    //transform: `translate(-${top}%, -${left}%)`,
  }
}

const useStyles = makeStyles((theme) => ({
  popup: {
    position: 'absolute',
    backgroundColor: 'white',
    width: '95vw',
    height: '100vh',
    minHeight: '300px',
    boxSizing: 'border-box',
    boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
    borderRadius: 10,
    padding: '40px 32px 0'
  },
  mainCtn: {
    '&::-webkit-scrollbar': {
      display: 'block',
      overflowX: 'auto',
      height: '5px',
      background: '#E3E3E3',
      width: '5px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#9B9B9B'
    },
    flexGrow: 1,
    minHeight: 300,
    maxHeight: '90vh',
    height: '90vh',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    margin: '0 -32px 0 0',
    paddingRight: 32
  },
  headerCtn: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  headerShadow: {
    boxShadow: '-20px 20px 20px 0px rgb(0 0 0 / 5%)'
  },
  previewCtn: {
    padding: '16px 0 60px'
  },
  divSharedUser: {
    marginTop: 10,
    height: '10vh',
    maxHeight: '20vh',
    overflowY: 'scroll'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paddingMargin5: {
    padding: '5px',
    backgroundColor: '#92FCEF',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px'
  },
  paddingMargin5v2: {
    padding: '5px',
    backgroundColor: '#70AFB4',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px'
  },
  divRadius: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: '500',
    borderRadius: '5px',
    minHeight: '38px',
    minLineHeight: '38px',
    backgroundColor: '#92fcef',
    fontSize: '14px',
    width: '100%',
    MozBorderRadius: '5px',
    border: 'none',
    WebkitBorderRadius: '5px'
  },
  divRadiusShare: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: '500',
    borderRadius: '5px',
    minHeight: '38px',
    minLineHeight: '38px',
    backgroundColor: '#ACDAD9',
    fontSize: '14px',
    width: '100%',
    MozBorderRadius: '5px',
    border: 'none',
    WebkitBorderRadius: '5px'
  },
  divRadiusNotif: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: '500',
    borderRadius: '5px',
    minHeight: '38px',
    minLineHeight: '38px',
    backgroundColor: '#ACDAD9',
    fontSize: '14px',
    width: '100%',
    MozBorderRadius: '5px',
    border: 'none',
    WebkitBorderRadius: '5px'
  },
  textFieldChips: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: '500',
    borderRadius: '5px',
    minHeight: '38px',
    minLineHeight: '38px',
    paddingLeft: '10px',
    backgroundColor: '#F3F3F3',
    fontSize: '14px',
    width: '100%',
    MozBorderRadius: '5px',
    border: 'none',
    WebkitBorderRadius: '5px'
  },
  textField: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 0,
    marginTop: 0,
    fontWeight: '500',
    borderRadius: '5px',
    height: '38px',
    lineHeight: '38px',
    paddingLeft: '10px',
    backgroundColor: '#F3F3F3',
    fontSize: '14px',
    width: '100%',
    MozBorderRadius: '5px',
    border: 'none',
    WebkitBorderRadius: '5px'
  },
  input: {
    color: 'white',
    fontFamily: 'Arial, FontAwesome'
  },
  mailRowCtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  switchCtn: {
    margin: '0px !important',
    padding: '0px 15px'
  },
  fieldRight: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #E3E3E3',
    borderRadius: '4px'
  },
  stickyHeader: {
    marginBottom: 12,
    position: 'sticky',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    zIndex: 100,
    top: 0
  },
  tab: {
    '& .MuiTab-wrapper': {
      flexDirection: 'row',
      fontSize: 15,
      gap: 4
    },
    '&.MuiTab-labelIcon': {
      minHeight: 'auto !important'
    }
  },
  tabCtn: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 40
  },
  editorCtn: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
    justifyContent: 'center',
    marginLeft: 'calc(500px - 50%)',
    '@media(max-width:1200px)': {
      marginLeft: 0,
    }
  },
  optionCtn: {
    width: '-webkit-fill-available',
  },
  optionLabelCtn: {
    width: 'fit-content'
  },
  active: {
    cursor: 'pointer',
    marginBottom: 16
  },
  disabled: {
    color: colors.disabled,
    cursor: 'default',
    overflow: 'hidden',
  },
  radioCtnH: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  fieldsCtn: {
    width: 320,
    display: 'flex',
    flexDirection: 'column',
    padding: '8px 40px 8px 8px',
    transition: 'margin-left 500ms ease-out, padding-right 500ms ease-out',
    gap: 4
  },
  fieldListCtn: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: 4
  },
  fieldNb: {
    fontSize: 12
  },
  fieldCtn: {
    display: 'flex',
    flexDirection: 'row',
    gap: 12,
    alignItems: 'stretch',
    lineHeight: '12px !important'
  },
  field: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: 4,
    padding: '0 4px'
  },
  fieldLabel: {
    textAlign: 'left',
    fontSize: 12
  },
  fieldValue: {
    flex: 0.2,
    flexDirection: 'row',
    alignSelf: 'flex-start',
    borderRadius: 4,
    padding: 4
  },
  button: {
    padding: '4px 12px',
    borderRadius: 4,
    width: 'fit-content',
    maxWidth: 160,
  },
  activeBtn: { color: 'white', cursor: 'pointer', backgroundColor: colors.mainButton }
}))

const switchStyles = makeStyles((theme) => ({
  switchTrack: {
    backgroundColor: colors.unselectedAlpha
  },
  switchBase: {
    color: colors.unselected,
    '&.Mui-checked': {
      color: colors.selectedMain
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: colors.selectedMain
    }
  }
}))

const checkDoublon = (list, text) => {
  return list.findIndex((item) => item === text) > -1
}

const checkDoublonObject = (list, text) => {
  return list.findIndex((item) => item.email === text) > -1
}

const getPlugcodeLink = (model) => {
  let link = null
  const pfCode = _.get(model, 'pfcode', null)
  const hash = _.get(model, 'hash', null)
  const baseURL = ApplicationConfig.plugUrl

  if (hash && pfCode) {
    link = `${baseURL}?fh=${hash}&pfc=${pfCode}`
  }

  return link || baseURL
}

const getCodePFCode = (model) => {
  return model.codepfcode !== null
    ? 'PF-' + moment(model.creationdate.date).format('YYMMDD') + '-' + model.codepfcode
    : ''
}

const checkComment = (model) => {
  if (_.get(model, 'pfcomment', '') !== null) {
    return _.get(model, 'pfcomment', '')
  }

  return ''
}

const checkCount = (model) => {
  if (_.get(model, 'pfcode_countopen', 0) !== null) {
    return _.get(model, 'pfcode_countopen', 0)
  }

  return 0
}

const isEmail = (value) => {
  return (
    value.match(
      new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
    ) !== null
  )
  //return /(.)+@(.)+/.test(value)
}

const HTML_START = '<link type="text/css" rel="stylesheet" id="dark-mode-custom-link"><link type="text/css" rel="stylesheet" id="dark-mode-general-link"><style lang="en" type="text/css" id="dark-mode-custom-style"></style><style lang="en" type="text/css" id="dark-mode-native-style"></style><style lang="en" type="text/css" id="dark-mode-native-sheet"></style><head><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><style type="text/css">body { font-family:Helvetica,Arial,sans-serif; font-size:14px }</style></head><body id="tinymce" class="mce-content-body " data-id="tiny-react_3743579911687875411941" aria-label="Rich Text Area. Press ALT-0 for help." contenteditable="true" spellcheck="false">'

const Model = (props) => {
  const classes = useStyles()
  const classesSwitch = switchStyles()
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [t, i18n] = useTranslation('common')
  const environment = getCurrentEnvironnement()
  const currentChooseModel = getModel()

  let model = props.model
  const link = model.pfcode !== null ? getPlugcodeLink(model) : null
  
  const fields = useMemo(() => {
    const _fields = []
    for (let index = 1; index < 15; index++) {
      if (isDefined(model['field' + index + 'label'])) {
        _fields.push({
          id: index,
          label: model['field' + index + 'label']
        })
      } else break
    }
    return _fields
  }, [])

  const [comment, setComment] = useState(checkComment(model))
  const [countView, setCountView] = useState(checkCount(model))
  const [openSendMail, setOpenSendMail] = useState(false)
  const [dataHasChanged, setDataHasChanged] = useState(false)
  const currentLanguageLower = i18n.language.charAt(0).toLowerCase() + i18n.language.charAt(1).toLowerCase()
  const [error, setError] = useState(null)
  const [previewData, setPreviewData] = useState(null)

  const [tabNb, setTabNb] = useState(0)
  const tableTab = 0
  const defaultTab = tableTab
  const exitFirst = tableTab + 1
  const exitSecond = tableTab + 2
  const emailTab = tableTab + 3
  const [openFieldList, setOpenFieldList] = useState(false)
  const [HTMLNode, setHTMLNode] = useState(undefined)
  const [HTMLNodes, setHTMLNodes] = useState(undefined)

  let mailNotification = []
  if (model && model.mailNotification && model.mailNotification.length > 0) {
    mailNotification = model.mailNotification.split(/\s*;\s*/).filter((item) => item !== '')
  }

  if (mailNotification && mailNotification.length === 0) {
    mailNotification.push(props.email)
  }

  let mailNotificationRules = []
  if (model && model.mailNotificationRules && model.mailNotificationRules.length > 0) {
    try {
      let textMailNotificationRulesToJson = JSON.parse(model.mailNotificationRules)
      mailNotificationRules = textMailNotificationRulesToJson.map((notification) => {
        return {
          email: notification.email,
          read: notification.read,
          readedit: notification.readedit,
          addannexes: notification.addannexes,
          main: notification.main
        }
      })
    } catch (err) {
      let textMailNotificationRulesToJson = JSON.stringify(model.mailNotificationRules)
      let textMailNotificationRuleFromJson = JSON.parse(textMailNotificationRulesToJson)
      mailNotificationRules = textMailNotificationRuleFromJson.map((notification) => {
        return {
          email: notification.email,
          read: notification.read,
          readedit: notification.readedit,
          addannexes: notification.addannexes,
          main: notification.main
        }
      })
    }
  }

  if (mailNotificationRules && mailNotificationRules.length === 0) {
    mailNotificationRules.push({
      email: props.email,
      read: null,
      readedit: null,
      addannexes: null,
      main: true
    })
  }

  if (
    mailNotificationRules.length === 0 ||
    mailNotificationRules.length < mailNotification.length
  ) {
    /*        if(!mailNotificationRules.find((mail) => mail.email === props.email)){
                    mailNotificationRules.push({"email" : props.email, "read" : null, "readedit": null, "addannexes" : null ,  "main" : true})
                }*/
    let addMailNotificationRulesEmpty = mailNotification.filter(
      (item) => item.email !== props.email
    )
    addMailNotificationRulesEmpty.forEach((notification) => {
      if (!mailNotificationRules.find((mail) => mail.email === notification)) {
        mailNotificationRules.push({
          email: notification,
          read: true,
          readedit: false,
          addannexes: false,
          main: false
        })
      }
    })
  }

  const [modelMailNotification, setModelMailNotification] = useState(mailNotification)
  const [modelMailNotificationDefault, setModelMailNotificationDefault] = useState(mailNotification)
  const [modelMailNotificationRules, setModelMailNotificationRules] = useState(mailNotificationRules)
  const [modelMailNotificationRulesDefault, setModelMailNotificationRulesDefault] = useState(mailNotificationRules)
  let mailInviteUsers = []
  if (model && model.mailInviteUsers && model.mailInviteUsers.length > 0) {
    //mailInviteUsers.push({"email" : props.email, "read" : null, "date": null, "addannexes" : null ,  "main" : true})
    try {
      let textMailInviteUsersToJson = JSON.parse(model.mailInviteUsers)
      mailInviteUsers = textMailInviteUsersToJson.map((notification) => {
        return {
          email: notification.email,
          date: notification.date
        }
      })
    } catch (err) {
      let textMailInviteUsersToJson = JSON.stringify(model.mailInviteUsers)
      let textMailInviteUsersFromJson = JSON.parse(textMailInviteUsersToJson)
      mailInviteUsers = textMailInviteUsersFromJson.map((notification) => {
        return {
          email: notification.email,
          date: notification.date
        }
      })
    }
  }

  const [modelMailInviteUsers, setModelMailInviteUsers] = useState(mailInviteUsers)
  const [modelMailNewInviteUsers, setModelMailNewInviteUsers] = useState([])
  const [modelMailDeleteInviteUsers, setModelMailDeleteInviteUsers] = useState([])
  const [modelMailRefreshInviteUsers, setModelMailRefreshInviteUsers] = useState([])
  const [authAnnexeInPublicMode, setAuthAnnexeInPublicMode] = useState(
    model.authAnnexeInPublicMode === 1
  )
  const [authNote, setAuthNote] = useState(model.options?.authorizeNote !== false)
  const [isCreated, setIsCreated] = useState(model ? !!model.isCreated : false)
  const [isLinked, setIsLinked] = useState(model ? !!model.isLinked : false)
  const [isNotificationLinked, setIsNotificationLinked] = useState(
    model ? !!model.isNotificationLinked : false
  )
  const [isEditable, setIsEditable] = useState(model ? !!model.isEditable : false)
  const [errorMail, setErrorEmail] = useState(false)
  const [errorMailInvite, setErrorEmailInvite] = useState(false)
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')

  const [stepConstructor, setStepConstructor] = useState(
    isNull(model.pfoptions) || !isJsonString(model.pfoptions)
      ? {
          default: {},
          exitFirst: {
            active: false,
            logo: false,
            activeMessage: false,
            message: '',
            emailMandatory: false
          },
          exitSecond: {
            active: false,
            logo: false,
            activeMessage: false,
            message: '',
            activeLink: false,
            activeSimplifiedView: false,
            simplifiedView: null,
            activeCustomMail: false,
            subject: null,
            body: null
          }
        }
      : JSON.parse(model.pfoptions)
  )

  const handleChangeStepConstructor = (index, property, value) => {
    // devLog('change constructor ', index, property, value)
    if ((property === 'active' || property === 'activeCustomMail') && !value && openFieldList) {
      handleToggleFieldList()
      tinymce.execCommand('mceResize', true, { height: 200 })
    }
    let tmpConstructor = { ...stepConstructor }
    tmpConstructor[index][property] = value
    setStepConstructor({ ...tmpConstructor })
  }

  const handleAddMailNotification = (mail) => {
    if (isEmail(mail)) {
      setErrorEmail(false)
      let mailNotification = modelMailNotification
      let mailNotificationRules = modelMailNotificationRules

      if (!checkDoublon(mailNotification, mail)) {
        mailNotification = mailNotification.concat(mail)
      }

      if (!checkDoublonObject(mailNotificationRules, mail)) {
        if (props.email === mail) {
          mailNotificationRules = mailNotificationRules.concat({
            email: mail,
            read: null,
            readedit: null,
            addannexes: null,
            main: true
          })
        } else {
          mailNotificationRules = mailNotificationRules.concat({
            email: mail,
            read: true,
            readedit: false,
            addannexes: false,
            main: false
          })
        }
      }
      setModelMailNotification(mailNotification)
      setModelMailNotificationRules(mailNotificationRules)
      setDataHasChanged(true)
    } else {
      setErrorEmail(true)
    }
  }

  const handleDelMailNotification = (mail, index) => {
    let mailNotification = modelMailNotification.filter((item) => item !== mail)
    let mailNotificationRules = modelMailNotificationRules.filter((item) => item.email !== mail)

    setModelMailNotification(mailNotification)
    setModelMailNotificationRules(mailNotificationRules)
    setDataHasChanged(true)
  }

  const handleAddMailInviteUser = (mail) => {
    if (isEmail(mail)) {
      setErrorEmailInvite(false)
      let mailNewInviteUsers = modelMailNewInviteUsers
      let mailInviteUsers = modelMailInviteUsers

      if (!checkDoublonObject(modelMailInviteUsers, mail)) {
        const formatYmd = dateFormat(new Date(), '%Y-%m-%d %H:%M:%S', true)
        mailNewInviteUsers = mailNewInviteUsers.concat({ email: mail, date: formatYmd })
        mailInviteUsers = mailInviteUsers.concat({ email: mail, date: formatYmd })
      }

      setModelMailNewInviteUsers(mailNewInviteUsers)
      setModelMailInviteUsers(mailInviteUsers)
      setDataHasChanged(true)
    } else {
      setErrorEmailInvite(true)
    }
  }

  const handleDelMailNewInviteUser = (mail, index) => {
    let mailInviteUsers = modelMailNewInviteUsers.filter((item) => item.email !== mail)
    setModelMailNewInviteUsers(mailInviteUsers)
    setDataHasChanged(true)
  }

  const handleDelMailInviteUser = (mail, index) => {
    let mailInviteUsers = modelMailInviteUsers.filter((item) => item.email !== mail)
    let mailDeleteInviteUsers = modelMailDeleteInviteUsers

    if (!checkDoublonObject(modelMailDeleteInviteUsers, mail)) {
      mailDeleteInviteUsers = mailDeleteInviteUsers.concat(modelMailInviteUsers[index])
      setModelMailDeleteInviteUsers(mailDeleteInviteUsers)
    }

    setModelMailInviteUsers(mailInviteUsers)
    setDataHasChanged(true)
  }

  const handleRefreshMailInviteUser = (mail, index) => {
    //let mailInviteUsers = modelMailInviteUsers.filter(item => item.email !== mail);
    //setModelMailInviteUsers(mailInviteUsers);
    //setDataHasChanged(true);

    const formatYmd = dateFormat(new Date(), '%Y-%m-%d %H:%M:%S', true)

    let mailRefreshInviteUsers = modelMailRefreshInviteUsers

    if (!checkDoublonObject(modelMailRefreshInviteUsers, mail)) {
      mailRefreshInviteUsers = mailRefreshInviteUsers.concat({ email: mail, date: formatYmd })
    }

    if (checkDoublonObject(modelMailInviteUsers, mail)) {
      let mailInviteUsers = modelMailInviteUsers.filter((item) => item.email !== mail)
      mailInviteUsers = mailInviteUsers.concat({ email: mail, date: formatYmd })
      setModelMailInviteUsers(mailInviteUsers)
    }

    setModelMailRefreshInviteUsers(mailRefreshInviteUsers)
    setDataHasChanged(true)
  }

  /**
   * Vérifie si les options d'utilisation ont changé
   * @private
   */
  const accessibilitiesHasChanged = () => {
    return (
      isLinked !== !!model.isLinked ||
      isEditable !== !!model.isEditable ||
      authAnnexeInPublicMode !== !!model.authAnnexeInPublicMode ||
      isCreated !== !!model.isCreated ||
      !_.isEqual(mailNotification, modelMailNotificationDefault) ||
      !_.isEqual(modelMailNotificationRules, modelMailNotificationRulesDefault) ||
      isNotificationLinked !== !!model.isNotificationLinked
    )
  }

  const handleGeneratePfCode = () => {
    props.generatePlugcodePlugform(
      '',
      currentChooseModel.hash,
      environment.userhash,
      currentChooseModel.isPublic,
      currentChooseModel
    )
  }

  const handleDeletePfCode = () => {
    confirmAlert({
      title: t('global.plugnotes'),
      message: t('global.exit-delete'),
      buttons: [
        {
          label: t('global.ok'),
          onClick: () => {
            props.deletePlugcodePlugform(model.hash, model.pfcode, environment.userhash)
          }
        },
        {
          label: t('global.cancel')
        }
      ]
    })
  }

  const handleUpdatePfCode = () => {
    setDataHasChanged(false)

    const payload = {
      mh: model.hash,
      comment: comment,
      pfc: model.pfcode,
      uh: environment.userhash,
      stepConstructor: stepConstructor
    }

    let accessPayload = {
      auth_annexe_in_public_mode: authAnnexeInPublicMode,
      is_created: isCreated,
      mail_notification: modelMailNotification.join(';'),
      mail_notification_rules: modelMailNotificationRules,
      mail_invite_users: modelMailInviteUsers.length > 0 ? modelMailInviteUsers : null,
      mail_invite_new_users: modelMailNewInviteUsers.length > 0 ? modelMailNewInviteUsers : null,
      mail_invite_refresh_users:
        modelMailRefreshInviteUsers.length > 0 ? modelMailRefreshInviteUsers : null,
      mail_invite_delete_users:
        modelMailDeleteInviteUsers.length > 0 ? modelMailDeleteInviteUsers : null
    }
    props.updatePlugcodePlugform(payload, accessPayload, model.hash, environment.userhash, model)

    setModelMailNewInviteUsers([])
    setModelMailRefreshInviteUsers([])
    setModelMailDeleteInviteUsers([])
  }

  const copyPfCodeToClipboard = () => {
    if (isFirefox || isSafari) {
      let pfCode = getCodePFCode(model)
      navigator.clipboard.writeText(pfCode)
      document.execCommand('copy')
      toast.success(i18n.t('common:global.plugcodes-to-clipboard'))
    } else {
      navigator.permissions.query({ name: 'clipboard-write' }).then((result) => {
        if (result.state == 'granted' || result.state == 'prompt') {
          let pfCode = getCodePFCode(model)
          navigator.clipboard.writeText(pfCode)
          toast.success(i18n.t('common:global.plugcodes-to-clipboard'))
        }
      })
    }
  }

  const copyLnkPfCodeToClipboard = () => {
    if (isFirefox || isSafari) {
      navigator.clipboard.writeText(link)
      document.execCommand('copy')
      toast.success(i18n.t('common:global.link-to-clipboard'))
    } else {
      navigator.permissions.query({ name: 'clipboard-write' }).then((result) => {
        if (result.state == 'granted' || result.state == 'prompt') {
          navigator.clipboard.writeText(link)
          toast.success(i18n.t('common:global.link-to-clipboard'))
        }
      })
    }
  }

  const sendMail = () => {
    setOpenSendMail(true)
  }

  const closeSendMail = () => {
    setOpenSendMail(false)
  }

  const downloadPfQrCode = () => {
    let tmpId = getCodePFCode(model)
    html2canvas(document.querySelector(`#plugcode-model-${tmpId}`)).then((canvas) => {
      //document.body.appendChild(canvas)
      const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
      let downloadLink = document.createElement('a')
      downloadLink.href = pngUrl
      downloadLink.download = id + '.png'
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
    })
  }

  const id = getCodePFCode(model)

  const _handlePressAccessibilityIsCreated = () => {
    setIsCreated(!isCreated)
    setDataHasChanged(true)

    // this.setState(prevState => {
    //     prevState[option] = !prevState[option];
    //
    //     if (option === 'isLinked' && !prevState[option]) {
    //         prevState['isEditable'] = false
    //     }
    //
    //     if (option === 'isNotificationLinked' && !prevState[option]) {
    //         prevState['isNotificationLinked'] = false;
    //
    //         let mailNotificationRules = prevState.mailNotification;
    //         prevState.mailNotificationRules = mailNotificationRules.map(notification => {
    //             return {
    //                 email: notification.email,
    //                 read : prevState['isNotificationLinked'],
    //                 readedit: false,
    //                 addannexes: false,
    //                 main: notification.main
    //             }
    //         });
    //
    //     }
    //
    //     return prevState
    // })
  }

  const _handlePressAccessibilityAuthAnnexeInPublicMode = () => {
    setAuthAnnexeInPublicMode(!authAnnexeInPublicMode)
    setDataHasChanged(true)
    // this.setState(prevState => {
    //     prevState[option] = !prevState[option];
    //
    //     if (option === 'isLinked' && !prevState[option]) {
    //         prevState['isEditable'] = false
    //     }
    //
    //     if (option === 'isNotificationLinked' && !prevState[option]) {
    //         prevState['isNotificationLinked'] = false;
    //
    //         let mailNotificationRules = prevState.mailNotification;
    //         prevState.mailNotificationRules = mailNotificationRules.map(notification => {
    //             return {
    //                 email: notification.email,
    //                 read : prevState['isNotificationLinked'],
    //                 readedit: false,
    //                 addannexes: false,
    //                 main: notification.main
    //             }
    //         });
    //
    //     }
    //
    //     return prevState
    // })
  }

  const _handleChangeComment = (event) => {
    setDataHasChanged(true)
    setComment(event.target.value)
  }

  const handleClosePopup = () => {
    if (dataHasChanged) {
      confirmAlert({
        title: t('global.plugnotes'),
        message: t('global.exit-without-saving'),
        buttons: [
          {
            label: t('global.ok'),
            onClick: () => {
              props.close()
            }
          },
          {
            label: t('global.cancel')
          }
        ]
      })
    } else {
      props.close()
    }
  }

  const handleEraseView = () => {
    setDataHasChanged(false)

    const payload = {
      mh: model.hash,
      comment: comment,
      pfc: model.pfcode,
      uh: environment.userhash,
      pfcode_countopen: '0'
    }

    let accessPayload = {
      auth_annexe_in_public_mode: authAnnexeInPublicMode,
      is_created: isCreated,
      mail_notification: modelMailNotification.join(';'),
      mail_notification_rules: modelMailNotificationRules,
      mail_invite_users: modelMailInviteUsers.length > 0 ? modelMailInviteUsers : null,
      mail_invite_new_users: modelMailNewInviteUsers.length > 0 ? modelMailNewInviteUsers : null,
      mail_invite_refresh_users:
        modelMailRefreshInviteUsers.length > 0 ? modelMailRefreshInviteUsers : null,
      mail_invite_delete_users:
        modelMailDeleteInviteUsers.length > 0 ? modelMailDeleteInviteUsers : null
    }
    props.updatePlugcodePlugform(payload, accessPayload, model.hash, environment.userhash, model)

    setModelMailNewInviteUsers([])
    setModelMailRefreshInviteUsers([])
    setModelMailDeleteInviteUsers([])
    setCountView(0)
  }

  const getFieldDataByFieldId = (plugNb) => {
    let url =
      ApplicationConfig.apiv2Url +
      `Datas/GetField.php?uh=${props.userhash}&field=${props.field.id}&mid=${props.model.id}${
        isDefined(plugNb) ? 'userindex=' + plugNb : ''
      }`
    // devLog('fieldNb', props.field.id)
    return Axios.get(url, {
      crossdomain: true,
      headers: {
        'X-AUTH-TOKEN': getToken()
      }
    })
  }

  const handleToggleTablePreview = () => {
    getFieldDataByFieldId()
      .then((result) => {
        // devLog('getFieldDataByFieldId result', result)
        if (result.statusText === 'OK') {
          let jsonData = returnJSON(result.data['field' + props.field.id])
          if (!isDefined(jsonData)) {
            jsonData = []
          }
          setPreviewData(jsonData)
          setTabNb(emailTab)
        }
      })
      .catch((e) => {
        setPreviewData([])
        setTabNb(emailTab)
        setError(e)
      })
  }

  const handleChangeTab = (_, nb) => {
    setOpenFieldList(false)
    setTabNb(nb)
  }

  function handleSelectedTags(items) {}

  const handleToggleCustomFirst = (value) => {
    handleChangeStepConstructor('exitFirst', 'active', value)
  }

  const handleChangeEditor = (value) => {
    // devLog('rich edit 2', value)
    handleChangeStepConstructor('exitSecond', tabNb === exitSecond ? 'message' : 'body', value)
  }

  const handleToggleFieldList = () => {
    if ((tabNb === exitSecond && stepConstructor.exitSecond.active) || (tabNb === emailTab && stepConstructor.exitSecond.activeCustomMail)) setOpenFieldList((curr) => !curr)
  }

  const handleClickBody = (e) => {
    // devLog('ClickBody event ', e)
    const currentNode = e.target
    // devLog('clicked node', { currentNode })
    setHTMLNode(currentNode)
  }

  const handleInsertText = (text) => {
    const insertedHTML = `<span style="font-size: 10pt; ${text?.includes('***') ? 'color: rgb(52,183,187);' : ''}" id="${text}"><strong>${text}</strong></span>`
    if (isDefined(HTMLNode) && !HTMLNode.localName !== 'link') {
      const parentTag = HTMLNode.localName
      // devLog('current node', HTMLNode, { HTMLNode })
      const newNode = `<${parentTag}>${HTMLNode.innerHTML}${insertedHTML}</${parentTag}>`.replace('<span id="mce_marker" data-mce-type="bookmark"></span><br>', '')
      tinymce.execCommand('mceSelectNode', true, HTMLNode)
      tinymce.execCommand('mceReplaceContent', true, newNode)
    } else {
      tinymce.execCommand('mceReplaceContent', true, insertedHTML)
    }
  }

  const handleOnKeyDown = e => {
    // devLog('key', e.key)
    const nodes = Array.from(e.target.children)
    if (e.key === 'Enter' || e.key === 'Backspace' || e.key === 'Delete') {
      setHTMLNodes(nodes)
    }
  }

  const handleOnKeyUp = e => {
    const nodes = Array.from(e.target.children)
    if (e.key === 'Enter') {
      setTimeout(() => {
        nodes.forEach((node) => {
          // devLog('Node', node.localName, node.innerText)
          let index = HTMLNodes.indexOf(node)
          if (index < 0) {
            setHTMLNode(node)
          }
        })
      }, 500)
    } else {
      // devLog('deleted node ?', nodes?.length, HTMLNodes?.length)
      if (nodes?.length < HTMLNodes?.length) {
        setTimeout(() => setHTMLNode(undefined), 500)
      }
    }
  }

  const handleSelectionChange = (e) => {
    const nodes = Array.from(e.target.all)
    if (nodes.length > 0) {
      const nodeArray = Array.from(nodes.find((entry) => entry.localName === 'body').children)
      // devLog('selection change', nodeArray, HTMLNode)
      if (isDefined(HTMLNode)) {
        const currentNode = nodeArray?.find((entry) => (entry?.innerHTML)?.includes(HTMLNode?.innerHTML))
        if (isDefined(currentNode)) {
          setHTMLNode(currentNode)
        }
      }
    }
  }

  const handleCopyContentFromSecond = (e) => {
    handlePreventDefault(e)
    if (stepConstructor.exitSecond.activeCustomMail) handleChangeStepConstructor('exitSecond', 'body', stepConstructor.exitSecond.message)
  }

  useEffect(() => {
    setComment(checkComment(model))
    setAuthAnnexeInPublicMode(model.authAnnexeInPublicMode === 1)

    let mailInviteUsers = []

    if (model && model.mailInviteUsers && model.mailInviteUsers.length > 0) {
      //mailInviteUsers.push({"email" : props.email, "read" : null, "date": null, "addannexes" : null ,  "main" : true})
      try {
        let textMailInviteUsersToJson = JSON.parse(model.mailInviteUsers)
        mailInviteUsers = textMailInviteUsersToJson.map((notification) => {
          return {
            email: notification.email,
            date: notification.date
          }
        })
        setModelMailInviteUsers(mailInviteUsers)
      } catch (err) {
        let textMailInviteUsersToJson = JSON.stringify(model.mailInviteUsers)
        let textMailInviteUsersFromJson = JSON.parse(textMailInviteUsersToJson)
        mailInviteUsers = textMailInviteUsersFromJson.map((notification) => {
          return {
            email: notification.email,
            date: notification.date
          }
        })
        setModelMailInviteUsers(mailInviteUsers)
      }
    }
  }, [props.model])

  useEffect(() => {
    if (model !== null) {
      if (currentLanguageLower === 'fr') {
        setTitle('Complétez le formulaire ' + model.clef + ' ' + model.label)
        setContent(
          'Bonjour,\n\n' +
            'Je vous invite à completer le formulaire ' +
            model.clef +
            ' ' +
            model.label +
            ' via Plugnotes.\n\n' +
            'Accédez au formulaire en cliquant sur le lien ci-dessous ou en encodant le Plugcode ' +
            getCodePFCode(model) +
            " dans l'application ou sur www.plugcodes.com.\n\n" +
            getPlugcodeLink(model) +
            '\n\n\n' +
            'Cordialement,\n\n\n' +
            props.pseudo
        )
      } else {
        setTitle('Complete Form ' + model.clef + ' ' + model.label)
        setContent(
          'Dear,\n\n' +
            'I invite you to complete the ' +
            model.clef +
            ' ' +
            model.label +
            ' form via Plugnotes.\n\n' +
            'Access the form by clicking on the link below or by encoding the Plugcode ' +
            getCodePFCode(model) +
            ' in the app or on www.plugcodes.com.\n\n' +
            getPlugcodeLink(model) +
            '\n\n\n' +
            'Kind regards,\n\n\n' +
            props.pseudo
        )
      }
    }
    // devLog('field list', fields)
  }, [props.model])

  if (model.pfcode == null) {
    return (
      <div className='popup_wrapper_plugcode flexSpacebetween'>
        <div className=''>
          <div className='sous-header_modal_content' style={{ position: 'sticky' }}>
            <div>
              <span className='titres'>
                {t('plugcode.send-form')} <br />
              </span>
              <span className='grey'>
                [{model.clef}] {model.label}
              </span>
            </div>
            <div onClick={props.close}>
              <span id='icon-close' className='material-icons md-25 lightgrey'>
                close
              </span>
            </div>
          </div>
        </div>
        <div className=''>
          <div>
            <button className='button button_full_width' onClick={handleGeneratePfCode}>
              {t('common:models.generate-plugcode')}
            </button>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <div className='scrollbar-hidden flexSpacebetween'>
        <div className={` ${classes.mainCtn}`}>
          <div className={classes.stickyHeader}>
            <div className={classes.headerCtn}>
              <div>
                <span className='titres'>{t('common:plugcode.send-form-title')}</span>
                <div className='grey margin-left-20'>
                  [{model.clef}] {model.label}
                </div>
              </div>
              <div>
                <div className='display_flex' style={{ gap: 4, color: colors.mainButton }}>
                  <div className='display_flex cursor ' onClick={copyPfCodeToClipboard}>
                    <span className='titres'>{getCodePFCode(model)}</span>
                  </div>
                  <div className='display_flex cursor ' onClick={copyPfCodeToClipboard}>
                    <span id='icon-close' className='material-icons md-30'>
                      content_copy
                    </span>
                  </div>
                  <div onClick={handleUpdatePfCode} className='cursor'>
                    <span id='icon-close' className='material-icons md-30'>
                      save
                    </span>
                  </div>
                  <div onClick={handleClosePopup} className='cursor'>
                    <span id='icon-close' className='material-icons md-30'>
                      close
                    </span>
                  </div>
                </div>
                {countView > 0 && (
                  <div className='display_flex'>
                    <div>
                      <span className='green-blue bold'>{t('common:plugcode.view-count')} : </span>
                      <span className='green-blue bold'>{countView}</span>
                    </div>
                    <div className='margin-left-20 cursor green-blue bold' onClick={(event) => handleEraseView()}>
                      <span>{t('common:plugcode.view-count-erase')}</span>
                      <span className='material-icons md-15 margin-left-5 margin-top-minder-5'>cleaning_services</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={` ${classes.headerShadow} sous-header_modal_content `}>
              <PlugTabs color={colors.unselected} handleToggleTablePreview={handleToggleTablePreview} onChange={handleChangeTab} value={tabNb || 0}>
                <Tab disableRipple icon={<SettingsIcon color={tabNb === defaultTab ? colors.unselected : colors.coal} />} className={`${classes.tab} ${tabNb !== defaultTab && classes.unselectedTab}`} label={t('plugcode.settings-main')} />

                {/* <Tab disableRipple icon={<SendOptionsIcon color={tabNb === exitFirst ? colors.unselected : colors.coal} />} className={`${classes.tab} ${tabNb !== defaultTab && classes.unselectedTab}`} label={t('plugcode.settings-send')} />

                <Tab disableRipple icon={<CheckScreenIcon color={tabNb === exitFirst ? colors.unselected : colors.coal} />} className={`${classes.tab} ${tabNb !== defaultTab && classes.unselectedTab}`} label={t('plugcode.settings-post-send')} />
                <Tab disableRipple icon={<SentEmailIcon color={tabNb === emailTab ? colors.unselected : colors.coal} />} className={`${classes.tab} ${tabNb !== defaultTab && classes.unselectedTab}`} label={t('plugcode.settings-email')} /> */}
              </PlugTabs>
            </div>
          </div>

          {tabNb === defaultTab && (
            <div>
              <div>
                <div>
                  <span className='grey'>{t('common:modal-plugcode.invite-users')}</span>
                </div>
                <div className={classes.textFieldChips}>
                  <TagsInputObjectMails handleRemove={handleDelMailNewInviteUser} handleAdd={handleAddMailInviteUser} selectedTags={handleSelectedTags} fullWidth tags={modelMailNewInviteUsers} controls={modelMailInviteUsers} id='tags' name='tags-shared' placeholder='' label='' />
                </div>
                <div className={classes.divRadiusShare}>
                  <div className={classes.paddingMargin5v2}>
                    <span className='white margin-5'>{t('common:plugcode.list-shared-user')}</span>
                  </div>
                  <div className='line-white flex_style'></div>
                  <div className='scrollbar-hidden inputWrapper retrait-popup-margin'>
                    <div className={`scrollbar-hidden  ${classes.divSharedUser}`}>
                      {modelMailInviteUsers.map((mail, index) => {
                        return (
                          <div key={index} className={classes.mailRowCtn}>
                            {mail.email}
                            <span className='margin-left-auto cursor' onClick={(event) => handleRefreshMailInviteUser(mail.email, index)}>
                              {mail.date}
                              <span id='icon-close' className='material-icons md-25' style={{ color: colors.unselected }}>
                                refresh
                              </span>
                            </span>
                            <span id='icon-close' className='material-icons md-25 margin-left-auto cursor' onClick={(event) => handleDelMailInviteUser(mail.email, index)} style={{ color: colors.unselected }}>
                              delete
                            </span>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>

                <div className={classes.divRadius}>
                  <div className={classes.paddingMargin5}>
                    <span className='black margin-5'>{t('common:modal-plugcode.params')}</span>
                  </div>
                  <div className='line-white flex_style'></div>
                  <div className='inputWrapper retrait-popup-margin'>
                    <div>
                      <FormControlLabel
                        control={
                          <Switch
                            color='default'
                            classes={{
                              track: classesSwitch.switchTrack,
                              switchBase: classesSwitch.switchBase
                            }}
                            checked={authAnnexeInPublicMode}
                            onChange={_handlePressAccessibilityAuthAnnexeInPublicMode}
                            name={t('common:plugcode.auth-public-annexe')}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                        }
                        label={t('common:plugcode.auth-public-annexe')}
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        control={
                          <Switch
                            color='default'
                            classes={{
                              track: classesSwitch.switchTrack,
                              switchBase: classesSwitch.switchBase
                            }}
                            checked={authNote}
                            onChange={_handlePressAccessibilityAuthAnnexeInPublicMode}
                            name={t('common:plugcode.auth-public-note')}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                        }
                        label={t('common:plugcode.auth-public-note')}
                      />
                    </div>
                    <div>
                      <FormControlLabel
                        control={
                          <Switch
                            color='default'
                            classes={{
                              track: classesSwitch.switchTrack,
                              switchBase: classesSwitch.switchBase
                            }}
                            checked={isCreated}
                            onChange={_handlePressAccessibilityIsCreated}
                            name={t('common:plugcode.receive-notification')}
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                        }
                        label={t('common:plugcode.receive-notification')}
                      />
                    </div>
                  </div>
                </div>
                {isCreated && (
                  <div>
                    <span className='grey'>{t('common:modal-plugcode.notifications')}</span>
                  </div>
                )}

                {isCreated && (
                  <div className={classes.textFieldChips}>
                    <TagsInputObjectMails handleRemove={handleDelMailNotification} handleAdd={handleAddMailNotification} selectedTags={handleSelectedTags} tags={modelMailNotificationRules} fullWidth id='tags' name='tags-shared' placeholder='' label='' />
                    <div>{errorMail && <span className='red'>{t('common:plugcode.error-mail')}</span>}</div>
                  </div>
                )}

                <div>
                  <span className='grey'>{t('common:modal-plugcode.comment-plugform')}</span>
                </div>

                <div className='inputWrapper popup-TextInput'>
                  <div>
                    <textarea className='input_area_small' name='description' rows='2' value={comment} onChange={_handleChangeComment} />
                    {/* <span style={{float : "right"}}>{comment.length +  ' / ' + '256'}</span> */}
                  </div>
                </div>
                <div className='margin-top flex_display_direction_row_center'>
                  <div className='flex_display_direction_row_center'>
                    <div className='confirmation_pop-up cursor' onClick={handleDeletePfCode}>
                      <button className='grey-button'>
                        <span className='greyFine'>{t('common:models.delete-plugcode')}</span>
                      </button>
                    </div>
                  </div>
                  <div className='order-2 flex_display_direction_row_center'>
                    <div onClick={downloadPfQrCode} className='cursor margin-5'>
                      <button className='grey-button margin-5'>
                        <span className='material-icons md-25 black'>qr_code</span>
                        <span className='greyFine'>{t('common:plugcode.qr-code')}</span>
                      </button>
                    </div>
                    <div onClick={copyLnkPfCodeToClipboard} className='cursor'>
                      <button className='green-button margin-5'>
                        <span className='material-icons md-25 '>link</span>
                        <span className='white'>{t('common:plugcode.copy-link')}</span>
                      </button>
                    </div>
                    <div onClick={sendMail} className='cursor'>
                      <button className='base-button'>
                        <span className='material-icons md-25 '>mail_outline</span>
                        <span className='white'>{t('common:modal-plugcode.invite')}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className='popup_wrapper_content_secret'>
                <div id={`plugcode-model-${id}`}>
                  <div
                    style={{
                      flexDirection: 'column',
                      alignItems: 'center',
                      display: 'flex'
                    }}
                  >
                    <div className='subQrCode-media-div'>
                      <span> {comment} </span>
                    </div>
                    <div className='qrCode-media'>
                      <div className='subQrCode-media-color-1'>
                        <div className='subQrCode-media-color-2'>
                          <div>
                            <QRCode
                              id={id}
                              value={link}
                              size={180}
                              bgColor={'#ffffff'}
                              fgColor={'#20959D'}
                              level={'L'}
                              includeMargin={false}
                              renderAs={'png'}
                              imageSettings={{
                                src: Images.iconQrCode,
                                x: null,
                                y: null,
                                height: 40,
                                width: 40,
                                excavate: false
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div>
                        <span className='white'>{ApplicationConfig.url}</span>
                      </div>
                      <div>
                        <span className='white'>{getCodePFCode(model)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <ModalSimpleSendMailV2PlugformCodeComponent open={openSendMail} close={closeSendMail} code={getCodePFCode(model)} link={getPlugcodeLink(model)} title={title} content={content} model={model} />
            </div>
          )}
          {tabNb === exitFirst && (
            <div className={classes.tabCtn}>
              <div className={classes.radioCtnH}>
                <PlugRadio checked={stepConstructor.exitFirst.active} label={<span className={classes.radioLabel}>{t('plugcode.settings-modify-first')}</span>} onClick={() => handleToggleCustomFirst(true)} placement='right' />
                <PlugRadio checked={!stepConstructor.exitFirst.active} label={<span className={classes.radioLabel}>{t('plugcode.settings-default')}</span>} onClick={() => handleToggleCustomFirst(false)} placement='left' />
              </div>
              <div className={classes.active} />
              <div className={stepConstructor.exitFirst.active ? classes.active : classes.disabled}>
                <div onClick={() => handleChangeStepConstructor('exitFirst', 'logo', !stepConstructor.exitFirst.logo)}>
                  <ColorCheckbox checked={stepConstructor.exitFirst.active && stepConstructor.exitFirst.logo} />
                  <span className={classes.optionTxt}>{t('plugcode.settings-logo')}</span>
                </div>
              </div>
              <div className={stepConstructor.exitFirst.active ? classes.active : classes.disabled}>
                <div onClick={() => handleChangeStepConstructor('exitFirst', 'emailMandatory', !stepConstructor.exitFirst.emailMandatory)}>
                  <ColorCheckbox checked={stepConstructor.exitFirst.active && stepConstructor.exitFirst.emailMandatory} />
                  <span className={classes.optionTxt}>{t('plugcode.settings-email-mandatory')}</span>
                </div>
              </div>
              <div className={stepConstructor.exitFirst.active ? classes.active : classes.disabled}>
                <div onClick={() => handleChangeStepConstructor('exitFirst', 'allowAttachmentsInEmail', !stepConstructor.exitFirst.allowAttachmentsInEmail)}>
                  <ColorCheckbox checked={stepConstructor.exitFirst.active && stepConstructor.exitFirst.allowAttachmentsInEmail} />
                  <span className={classes.optionTxt}>{t('plugcode.settings-join-attachments')}</span>
                </div>
              </div>
              <div className={stepConstructor.exitFirst.active ? classes.active : classes.disabled}>
                <div onClick={() => handleChangeStepConstructor('exitFirst', 'activeMessage', !stepConstructor.exitFirst.activeMessage)}>
                  <ColorCheckbox checked={stepConstructor.exitFirst.active && stepConstructor.exitFirst.activeMessage} />
                  <span className={classes.optionTxt}>{t('plugcode.settings-modify-text')}</span>
                </div>
                {stepConstructor.exitFirst.activeMessage && (
                  <div>
                    <TextField className={classes.fieldRight} disabled={!stepConstructor.exitFirst.activeMessage} fullWidth helperText='' id={'exitFirst-message-input'} InputLabelProps={{ shrink: true }} InputProps={{ disableUnderline: true }} margin='normal' minRows={2} multiline placeholder={t('plugcode.settings-your-text')} onChange={(event) => handleChangeStepConstructor('exitFirst', 'message', event.target.value)} style={{ margin: 0 }} type='text' value={stepConstructor.exitFirst.message} />
                  </div>
                )}
              </div>
              {stepConstructor.exitFirst.active && (
                <div className={classes.previewCtn}>
                  <div className='modalTitle' style={{ marginBottom: 8 }}>
                    {t('global.preview')}
                  </div>
                  <PlugcodeSendingFirstPreviewComponent firstText={stepConstructor.exitFirst.activeMessage ? stepConstructor.exitFirst.message : t('external.insert-email-pf-code-complete')} hasAttachments={stepConstructor.exitFirst.allowAttachmentsInEmail === true} hasLogo={stepConstructor.exitFirst.logo === true} isEmailRequired={stepConstructor.exitFirst.emailMandatory} />
                </div>
              )}
            </div>
          )}

          {tabNb === exitSecond && (
            <div className={classes.tabCtn}>
              <div className={`${classes.active} ${classes.optionLabelCtn}`} onClick={() => handleChangeStepConstructor('exitSecond', 'active', !stepConstructor.exitSecond.active)}>
                <ColorCheckbox checked={stepConstructor.exitSecond.active} />
                <span className={classes.optionTxt}>{t('plugcode.settings-toggle-post-send')}</span>
              </div>
              <div className={stepConstructor.exitSecond.active ? undefined : classes.disabled}>
                <div className={`${classes.active} ${classes.optionLabelCtn}`} onClick={() => handleChangeStepConstructor('exitSecond', 'logo', !stepConstructor.exitSecond.logo)}>
                  <ColorCheckbox checked={stepConstructor.exitSecond.active && stepConstructor.exitSecond.logo} />
                  <span className={classes.optionTxt}>{t('plugcode.settings-logo')}</span>
                </div>
              </div>
              <div className={stepConstructor.exitSecond.active ? undefined : classes.disabled}>
                <div className='modalTitle' style={{ marginBottom: 8 }}>
                  {t('plugcode.settings-modify-text')}
                </div>
                <div style={{ marginTop: 12 }} className={stepConstructor.exitSecond.active? classes.optionCtn : classes.disabled}>
                  <div className={classes.optionLabelCtn} onClick={handleToggleFieldList}>
                    <span className={`material-icons`}>{openFieldList ? 'expand_more' : 'chevron_left'}</span>
                    <span className={classes.active}>{t('plugcode.settings-insert-fields')}</span>
                  </div>
                  <div className={classes.editorCtn} style={stepConstructor.exitSecond.active? undefined : { maxHeight: 200 }}>
                    <PlugnoteFieldsComponent fields={fields} onClick={handleInsertText} open={openFieldList} />
                    <RichEditor
                      init={{
                        height: stepConstructor.exitSecond.active ? 600 : 200,
                        menubar: true,
                        plugins: ['advlist', 'anchor', 'autolink', 'image', 'link', 'lists', 'searchreplace', 'table', 'wordcount'],
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                      }}
                      disabled={stepConstructor.exitSecond.active !== true}
                      onClick={handleClickBody}
                      onEditorChange={handleChangeEditor}
                      onKeyDown={handleOnKeyDown}
                      onKeyUp={handleOnKeyUp}
                      onMouseDown={handleClickBody}
                      onSelectionChange={handleSelectionChange}
                      value={stepConstructor.exitSecond.active? stepConstructor.exitSecond.message.replaceAll('<br>', '<p>') : 'Default text'}
                    />
                  </div>
                </div>
              </div>
              <div className={`${classes.previewCtn} ${stepConstructor.exitSecond.active ? undefined : classes.disabled}`}>
                <div className='modalTitle' style={{ marginBottom: 8 }}>
                  {t('global.preview')}
                </div>
                <PlugcodeSendingSecondPreviewComponent hasLogo={stepConstructor.exitSecond.logo} secondText={stepConstructor.exitSecond.active ? stepConstructor.exitSecond.message : t('external.insert-email-pf-code-complete')} />
              </div>
            </div>
          )}

          {tabNb === emailTab && (
            <div className={classes.tabCtn}>
              <div className={classes.active} onClick={() => handleChangeStepConstructor('exitSecond', 'activeCustomMail', !stepConstructor.exitSecond.activeCustomMail)}>
                <ColorCheckbox checked={stepConstructor.exitSecond.activeCustomMail} />
                <span className={classes.optionTxt}>{t('plugcode.settings-custom-email')}</span>
              </div>
              <div className={stepConstructor.exitSecond.activeCustomMail ? undefined : classes.disabled}>
                <div className={stepConstructor.exitSecond.active ? undefined : classes.disabled}>
                  <div style={{ display: 'flex', flexDirection: 'row', gap: 16 }}>
                    <PlugTooltip title={t('plugcode.settings-copy-second')}>
                      <div className={`${stepConstructor.exitSecond.activeCustomMail ? classes.activeBtn : classes.disabled} ${classes.button}`} onClick={handleCopyContentFromSecond}>
                        <span className={`material-icons`} style={{ alignSelf: 'center' }}>
                          content_paste_go
                        </span>
                      </div>
                    </PlugTooltip>
                    <div className='modalTitle' style={{ marginBottom: 8 }}>
                      {t('plugcode.settings-modify-text')}
                    </div>
                  </div>
                  <div style={{ marginTop: 12 }} className={stepConstructor.exitSecond.activeCustomMail ? classes.optionCtn : classes.disabled}>
                    <div className={classes.optionLabelCtn} onClick={handleToggleFieldList}>
                      <span className={`material-icons`}>{openFieldList ? 'expand_more' : 'chevron_left'}</span>
                      <span className={classes.active}>{t('plugcode.settings-insert-fields')}</span>
                    </div>
                    <div className={classes.editorCtn} style={stepConstructor.exitSecond.activeCustomMail? undefined : { maxHeight: 200 }}>
                      <PlugnoteFieldsComponent fields={fields} onClick={handleInsertText} open={openFieldList} />
                      <RichEditor
                        init={{
                          height: stepConstructor.exitSecond.activeMessage ? 600 : 200,
                          menubar: true,
                          plugins: ['advlist', 'anchor', 'autolink', 'image', 'link', 'lists', 'searchreplace', 'table', 'wordcount'],
                          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                        disabled={!stepConstructor.exitSecond.activeCustomMail}
                        onEditorChange={handleChangeEditor}
                        value={stepConstructor.exitSecond.activeCustomMail ? stepConstructor.exitSecond.body : 'Default email'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

const PlugnoteFieldsComponent = ({ fields, onClick, open }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const [openInput, setOpenInput] = useState(false)
  const [plugcode, setPlugcode] = useState('P')

  const handleOnClick = (e, value) => {
    handlePreventDefault(e)
    onClick(value)
  }

  const handleChangePlugcode = (e) => {
    setPlugcode(e.target.value?.toUpperCase())
    devLog('code', plugcode)
  }

  const handleInsertPlugcode = (e) => {
    if (plugcode?.includes('_') || plugcode.length < 15) { return }
    handleOnClick(e, '***CODE:' + plugcode + '**')
    setPlugcode('P')
    setOpenInput(false)
  }

  const handleToggleInput = (e) => {
    handlePreventDefault(e)
    setOpenInput(curr => !curr)
  }

  const plugcodeMask = [
    /P/i,
    /[FNPW]/i, '-',
    /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,'-',
    /[A-Z0-9]/i,/[A-Z0-9]/i,/[A-Z0-9]/i,/[A-Z0-9]/i,/[A-Z0-9]/i,
  ]

  const isValidPlugcode = !(plugcode?.includes('_') || plugcode?.includes('•') || plugcode.length < 15)

  return (
    <div
      className={classes.fieldsCtn}
      style={{
        marginLeft: open ? 0 : '-50%',
        height: open ? 'auto' : 0,
        paddingRight: open ? 8 : '50%'
      }}
    >
      <InputLabel className={classes.margin}>{t('plugcode.settings-insert-instructions')}</InputLabel>
      <div className={classes.fieldListCtn}>
        <div className={classes.fieldCtn}>
          <button className={classes.field} onClick={(e) => handleOnClick(e, '***DATE**')}>
            <span className={classes.fieldLabel}>Date</span>
          </button>
          <PlugTooltip title={t('plugcode.settings-email-field')} placement='top-start'>
            <button className={classes.field} onClick={(e) => handleOnClick(e, '***EMAIL**')}>
              <span className={classes.fieldLabel}>{t('plugcode.email')}</span>
            </button>
          </PlugTooltip>
        </div>
        <div className={classes.fieldCtn}>
          <PlugTooltip title={t('plugcode.settings-plugcode-field')} placement='top-start'>
          <button className={classes.field} onClick={(e) => handleOnClick(e, '***PN**')}>
            <span className={classes.fieldLabel}>PN-code</span>
          </button>
          </PlugTooltip>
          <PlugTooltip title={t('plugcode.settings-other-plugcode-field')} placement='top-start'>
          <button className={classes.field} onClick={handleToggleInput}>
            <span className={classes.fieldLabel}>{t('plugcode.settings-other-plugcode')}</span>
          </button>
          </PlugTooltip>
        </div>
        {openInput && (
          <div className={classes.fieldCtn} style={{ marginLeft: 24 }}>
            <MaskedInput
              className='form-control'
              placeholder='Enter a Plugcode'
              guide
              // onBlur={() => {}}
              autoFocus
              placeholderChar='•'
              mask={plugcodeMask}
              onChange={handleChangePlugcode}
              style={{ flex: 1, borderRadius: 4, borderColor: colors.greyLight }}
              value={plugcode}
            />
            <PlugTooltip title={isValidPlugcode ? t('plugcode.settings-insert-plugcode') : null} placement='top-start'>
              <button className={`${classes.fieldValue} ${!isValidPlugcode ? classes.disabled : classes.activeBtn}`} onClick={handleInsertPlugcode}>
                <span className={`material-icons ${classes.valueButton} ${!isValidPlugcode ? classes.disabled : classes.activeBtn}`}>check</span>
              </button>
            </PlugTooltip>
          </div>
        )}
        {fields &&
          fields.length > 0 &&
          fields.length <= 15 &&
          fields.map((el, index) => (
            <div className={classes.fieldCtn} key={el.label}>
              <span className={classes.fieldNb}>{addZeroBefore(index + 1)}</span>
              <PlugTooltip title={t('smail.insert-label')} placement='top-start'>
                <button className={classes.field} onClick={(e) => handleOnClick(e, el.label + '&#xFEFF')}>
                  <span className={classes.fieldLabel}>{el.label}</span>
                </button>
              </PlugTooltip>
              <PlugTooltip title={t('smail.insert-value')} placement='top-start'>
                <button className={classes.fieldValue} key={el.label} onClick={(e) => handleOnClick(e, ' ***VALUE_FIELD_' + el.id + '**')}>
                  <Subject className={classes.valueButton} />
                </button>
              </PlugTooltip>
            </div>
          ))}
      </div>
    </div>
  )
}

const ModalModelPlugcodeComponentV2 = (props) => {
  const classes = useStyles()

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle)

  const [model, setModel] = useState(null)
  const environment = getCurrentEnvironnement()

  useEffect(() => {
    if (props.open) {
      if (props.model === null && getModel().actifpfcode) {
        props.attemptGetPlugcodePlugformRequest(
          getModel().hash,
          environment.userhash,
          props.profilHash
        )
      } else if (props.model === null && !getModel().actifpfcode) {
        setModel(getModel())
      } else {
        setModel(props.model)
      }
    }
  }, [props.model])

  return (
    <Modal
      keepMounted={false}
      open={props.open}
      onClose={props.handleModalModelPlugcodeClose}
      className={classes.modal}
    >
      <div style={modalStyle} className={classes.popup}>
        {model !== null && <Model {...props} model={model} />}
      </div>
    </Modal>
  )
}

// redux providing state takeover
const mapStateToProps = (state, props) => {
  return {
    model: state.plugcode.model,
    email: state.user.profil.email,
    pseudo: state.user.profil.pseudo,
    profilHash: state.user.profil.hash
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    generatePlugcodePlugform: (comment, modelhash, userhash, isPublic, model) =>
      dispatch(postGeneratePlugformcodeRequest(comment, modelhash, userhash, isPublic, model)),
    deletePlugcodePlugform: (modelhash, modelpfc, userhash) =>
      dispatch(deleteGeneratePlugformcodeRequest(modelhash, modelpfc, userhash)),
    updatePlugcodePlugform: (payload, accessPayload, modelhash, userhash, model) =>
      dispatch(putGeneratePlugformcodeRequest(payload, accessPayload, modelhash, userhash, model)),
    attemptGetPlugcodePlugformRequest: (modelhash, userhash, usermainhash, type) =>
      dispatch(getPlugformcodeRequest(modelhash, userhash, usermainhash, type))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalModelPlugcodeComponentV2)
