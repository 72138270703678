import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {connect} from "react-redux";
import {getCurrentEnvironnement, getModel, getPlugnote,} from "../../utils/Common";
import "@contentful/forma-36-react-components/dist/styles.css";
import ApplicationConfig from "../../config/ApplicationConfig";
import * as moment from "moment";
import {confirmAlert} from "react-confirm-alert";
import PopupState, {bindPopover, bindTrigger} from "material-ui-popup-state";
import Popover from "@material-ui/core/Popover";
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleLeft, faArrowAltCircleRight, faEdit,} from "@fortawesome/free-regular-svg-icons";
import * as XLSX from "xlsx";

import ModalAddAnnexeCommentComponent from "../../components/modals/ModalAddAnnexeCommentComponent";
import colors from "../../assets/themes/Colors";
import { fileIsImg } from "../../utils/annexes";

// const zeroPad = (num, places) => String(num).padStart(places, "0");

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  popup: {
    position: "relative",
    display: "flex",
    width: "85vw",
    maxHeight: '95vh',
    padding: "15px 25px",
    backgroundColor: "white",
    borderRadius: "10px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    "@media (max-width: 1024px)": {
      minWidth: "600px",
    },
  },
  popup_content: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: 'column',
    gap: 25,
    "@media (max-width: 1024px)": {
      flexDirection: "column",
      gap: 0,
    },
  },
  arrow_smallScreen: {
    display: "none",
    "@media (max-width: 1024px)": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: 'center',
    },
  },
  arrow_icon: {
    fontSize: "38px",
    color: colors.plugnotesLight,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  arrowPrev: {
    "@media (max-width: 1024px)": {
      order: 1,
      display: "none",
    },
  },
  arrowNext: {
    "@media (max-width: 1024px)": {
      order: 2,
      display: "none",
    },
  },
  elVisibility: {
    fontSize: "40px",
    color: "transparent",
    visibility: "hidden",
  },
  content_change: {
    /* border: '1px solid green', */
    flex: "1 1 100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    "@media (max-width: 1024px)": {
      order: 3,
      width: "100%",
    },
  },
  file_content: {
    /* border: '1px solid red', */
  },
  modalHeader: {
    // padding: "15px 0",
    display: "flex",
    minWidth: '45vw',
    height: 45,
    alignItems: "center",
    margin: '0 5px',
    "@media (max-width: 1024px)": {
      flexDirection: "row",
      alignItems: "center",
    },
    "@media (min-width: 1025px)": {
      // marginRight: '25px'
    }
  },
  modalHeaderIcon: {
    // transform: 'scale(0.5)',
    marginRight: '5px',
    color: colors.plugnotesLight,
  },
  modalHeaderName: {
    height: "24px",
    // fontSize: "20px",
    fontWeight: "bold",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    "@media (max-width: 1024px)": {
      fontSize: "16px",
      height: "24px",
    },
  },
  modalHeaderTitle: {
    display: "flex",
    flexDirection: 'row',
    gap: 15,
  },
  modalClose: {
    position: 'absolute',
    top:'25px',
    right: '25px',
    gap: 10,
    height: 'fit-content',
    "@media (max-width: 1024px)": {
      justifyContent: 'center',
      },
  },
  modalHeaderSizeClose: {
    display: 'flex',
    alignItems: 'center',
    width: '30%',
    justifyContent: 'flex-end',
    gap: 10,
    "@media (max-width: 1024px)": {
      justifyContent: 'center',
      },
  },
  modalHeaderSize: {
    color: colors.plugnotesGris,
    marginRight: 10,
  },
  close_buttonSmall: {
    display: 'none',
    color: colors.frost,
    "@media (max-width: 1024px)": {
      fontSize: '38px',
      // fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'center'
    },
  },
  close_button: {
    color: colors.frost,
    fontSize: '45px',
    // fontWeight: 'bold',
    "@media (max-width: 1024px)": {
      display: 'none',
    },
  },
  rightArrow: {
    color: colors.plugnotesLight,
    fontSize: '45px',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top:'40vh',
    right: '25px',
    gap: 10,
    height: 'fit-content',
    "@media (max-width: 1024px)": {
      display: 'none',
      justifyContent: 'center',
      },
  },
  leftArrow: {
    color: colors.plugnotesLight,
    fontSize: '45px',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top:'40vh',
    left: '25px',
    gap: 10,
    height: 'fit-content',
    "@media (max-width: 1024px)": {
      display: 'none',
      justifyContent: 'center',
      },
  },
  modalAttPrev: {
    backgroundColor: "#E3E3E3",
    verticalAlign: 'middle',
    minHeight: '70vh',
    maxHeight: '80vh',
    minWidth:'55vw',
    maxWidth: '80vw',
    "@media (min-width: 1025px)": {
      minWidth:'65vw',
      maxWidth: '70vw',
    },
    "@media (min-height: 700px)": {
      minHeight: '70vh',
      maxHeight: '80vh',
    },
    marginLeft: 'auto',
    marginRight: 'auto',
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    position: 'relative'
  },
  modalFooter: {
    position: 'absolute',
    bottom: 0,
    width: '50px',
    padding: '3px 8px',
    borderRadius: 15,
    display: 'flex',
    justifyContent: 'space-evenly',
    flexDirection: 'row-reverse',
    marginBottom: '20px',
    gap: 20,
    alignItems: 'center',
    backgroundColor: colors.whiteAlpha,
    "@media (max-width: 1024px)": {
      display :'none'
    }
  },
  modalIcon: {
    width: '50px',
    padding: '3px 8px',
    margin: 5,
    borderRadius: 15,
    backgroundColor: colors.whiteAlpha,
  },
  scaledImg: {
    maxWidth: "100%",
    maxHeight: "100%",
  },
  fieldTitle: {
    fontWeight: "600",
  },
  commentField: {
    // flex: "0 0 100%",
    margin: "0 0 5px 0",
    borderBottom: "2px solid #E3E3E3",
  },
  commentText: {
    display: "flex",
    flexDirection: "row",
  },
  commentEdit: {
    display: "flex",
    alignItems: "flex-start",
    gap: 10,
    color: colors.plugnotesGreen
  },
  commentEditIcon: {
    color: colors.plugnotesGris,
    cursor: "pointer",
  },
  commentDesc: {
    fontSize: "20px",
    overflow: "auto",
    minHeight: "50px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    "@media (max-width: 1024px)": {
      minHeight: "30px",
      fontSize: "16px",
    },
  },
  historyField: {
    display: "flex",
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    '@media (max-width: 1024px)': {
      flexDirection: 'column'
    }
  },
  historyText: {
    display: "flex",
    flexDirection: "row",
    alignItems: 'flex-start',
    // marginRight: 15
  },
  historyDesc: {
    fontSize: "14px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
  },
  buttonsGroup: {
    padding: "15px 0",
    display: "flex",
    justifyContent: "space-between",
    gap: 5,
  },
  buttonsRight: {
    display: "flex",
    color: colors.plugnotesGris,
    gap: 5,
  },
  modal_button: {
    minWidth: "150px",
    padding: "4px 20px",
    borderRadius: "3px",
    cursor: "pointer",
    color: colors.white,
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: colors.main
  },
  modalSubheader: {
    padding: 5,
    display: 'flex',
    gap: 10,
    marginTop: 5,
    justifyContent: "flex-end",
    '@media (max-width: 1024px)': {
      display: 'none'
    }
  },
  button_download: {
    color: "white",
    backgroundColor: "#58c3c1",
  },
  button_delete: {
    color: colors.unselectedAlpha,
    backgroundColor: "transparent",
    border: "2px solid",
    fontWeight: "600",
  },
  button_update: {
    color: "white",
    backgroundColor: "#384C5A",
  },
  edit_comment: {
    border: '1px solid red',
    width: '90px',
  },
  marginLeft: {
    marginLeft: "10px",
  },
  pointer: {
    cursor: "pointer",
  },
  mainColor: {
    color: colors.plugnotesLight,
    cursor: "pointer",
    marginLeft: 'auto',
    display: 'inline-block',
    "@media (min-width: 1025px)": {
      display: 'none'
    }
  },
  modalPgNb: {
    fontSize: 15,
    color: colors.selectedMain,
    fontWeight: 'bold'
  }
}));

const ModalTestAnnexeGallery = (props) => {

  const [fileId, setFileId] = useState(props.fileId); //receive the attachment index from the list on plugforms as a number
  const [focus, setFocus] = useState('')

  let contentId = props.annexes[fileId]; //all the fields from a specific attachment
  let fileName = contentId.name.length > 30 ? contentId.name.slice(0,30) + '...' + contentId.type : contentId.name + '.' + contentId.type

  const [openInfos, setOpenInfos] = useState(contentId.comment? contentId.comment !== "" : false)
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [t, i18n] = useTranslation("common");

  // const plugnote = getPlugnote();
  // const environment = getCurrentEnvironnement();

  const handleCloseModal = () => {
    props.close();
  };

  const nextFile = () => {
    if (fileId + 1 >= props.annexes.length) {
      return fileId;
    } else {
      setFileId(fileId + 1);
    }
  };

  const previousFile = () => {
    if (fileId <= 0) {
      return fileId;
    } else {
      setFileId(fileId - 1);
    }
  };

  const handlePreviewClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let imgSrc = contentId.path
    switch (contentId.type.toLowerCase()) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        imgSrc === (null || undefined)? window.open(props.renderAnnexeImagePreview(contentId).props.src,"_blank")
        : window.open(imgSrc, "_blank")
        break;
      default:
        break;
    }
  }
  // let current = new Date();

  useEffect(()=> {
    if(contentId?.comment !== "") setOpenInfos(true);
    else setOpenInfos(false)
  }, [contentId.comment])

  return (
    <Modal
      keepMounted={false}
      open={props.open}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={`${classes.modal}`}
    >
      <div className={`${classes.popup}`}>
        <div className={classes.modalClose}>
          <div
            className={`${classes.close_button} material-icons`}
            onClick={handleCloseModal}
          >
            highlight_off
          </div>
        </div>
        <div className={classes.rightArrow}>
          <Tooltip
            className={`${classes.arrowNext}`}
            title={`file ${fileId + 1} of  ${props.annexes.length}`}
            placement="top"
          >
            <div
              onMouseEnter={() => setFocus('right')}
              onMouseLeave={() => setFocus('')}
              className={
                fileId + 1 >= props.annexes.length
                  ? `${classes.elVisibility}`
                  : `${classes.arrow_icon}`
              }
              onClick={nextFile}
              style={focus==='right'? {color: colors.plugnotesGris, borderRadius: 50, backgroundColor: '#fff'} : null}
            >
              <FontAwesomeIcon icon={faArrowAltCircleRight} />
            </div>
          </Tooltip>
          {/* <span className={fileId + 1 < props.annexes.length? classes.modalPgNb : classes.elVisibility}>{props.annexes.length}</span> */}
        </div>
        <div className={classes.leftArrow}>
          {/* <span className={fileId > 0? classes.modalPgNb : classes.elVisibility}>1</span> */}
          <Tooltip
            className={`${classes.arrowPrev}`}
            title={`file ${fileId + 1} of ${props.annexes.length}`}
            placement="top"
          >
            <div
              onMouseEnter={() => setFocus('left')}
              onMouseLeave={() => setFocus('')}
              className={fileId <= 0 ?
              `${classes.elVisibility}`
              :
              `${classes.arrow_icon}`
              }
              onClick={previousFile}
              style={focus==='left'? {color: colors.plugnotesGris, borderRadius: 50, backgroundColor: '#fff'} : null}
            >
              <FontAwesomeIcon icon={faArrowAltCircleLeft} />
            </div>
          </Tooltip>
        </div>
        <div className={`${classes.popup_content}`}>
          <div className={`${classes.arrow_smallScreen}`} >
            <Tooltip title={`file ${fileId}`} placement="top">
              <div
                onMouseEnter={() => setFocus('left')}
                onMouseLeave={() => setFocus('')}
                className={fileId <= 0 ?
                `${classes.elVisibility}`
                :
                `${classes.arrow_icon}`
                }
                onClick={previousFile}
              >
                <FontAwesomeIcon icon={faArrowAltCircleLeft} />
              </div>
            </Tooltip>

            <div
              className={`${classes.close_buttonSmall} material-icons`}
              onClick={handleCloseModal}
            >
              highlight_off
            </div>

            <Tooltip
              title={`file ${fileId + 1} of  ${props.annexes.length}`}
              placement="top"
            >
              <div
                onMouseEnter={() => setFocus('right')}
                onMouseLeave={() => setFocus('')}
                className={
                  fileId + 1 >= props.annexes.length
                    ? `${classes.elVisibility}`
                    : `${classes.arrow_icon}`
                }
                onClick={nextFile}
              >
                <FontAwesomeIcon icon={faArrowAltCircleRight} />
              </div>
            </Tooltip>
          </div>

          <div className={classes.content_change}>
            <div className={classes.modalHeader}>
              {openInfos? null :
              <div className={classes.modalHeaderIcon}>
                {props.renderAnnexeIconPreview(contentId, true)}
              </div>}
              {openInfos?
                <div className={`${classes.historyField}`}>
                  <span>{contentId.name}.{contentId.type}
                  <span className={classes.modalHeaderSize}>
                    {`   `}({(contentId.size / 1000).toFixed(0)} kb)
                  </span></span>
                  <div className={classes.historyText}>
                    <div className={`${classes.fieldTitle}`}>
                      {/* {t("multiple.history")} */}
                    </div>
                    <div className={classes.historyDesc}>
                      {/* Version {fileId.countVersion} : {current.getDate()}/
                      {current.getMonth() + 1}/{current.getFullYear()} */}
                      {/* Version {contentId.countVersion} {/* / {contentId.lastversion} / {contentId.editorName} : */}
                      {moment(contentId.creationdate).format('YY/MM/DD H:m')}
                    </div>
                  </div>
                </div>
                :
                <div className={classes.modalHeaderTitle}>
                  <div className={`${classes.modalHeaderName} titleScrollHor`}>
                    {fileName}{`   `}
                    <span className={classes.modalHeaderSize}>
                    ({(contentId.size / 1000).toFixed(0)} kb)
                  </span>
                  </div>
                </div>
              }

              <div className={classes.mainColor}>
                {/* {
                !props.locked &&
                      props.isAuthorisedToEdit &&
                      (
                        <Tooltip
                          title={i18n.t("common:actions.update")}
                          aria-label="add"
                          placement="top-start"
                        >
                          <div
                            onClick={(event) =>
                              props.handleUpdateAnnexe(event, contentId.annexe)
                            }
                              id="icon-archive"
                              className={`${classes.mainColor} material-icons md-25`}
                            >
                              publish
                          </div>
                        </Tooltip>
                      )} */}
                {!props.locked &&
                (<Tooltip title="Delete file" placement="top" >
                  <span
                    id="icon-delete"
                    style={{cursor: 'pointer', marginLeft: 5, fontSize: 35}}
                    className={`material-icons`}
                    onClick={(event) => props.handleDeleteAnnexe(event, contentId, previousFile)}
                  >
                    delete
                  </span>
                </Tooltip>)}
                {(props.mode !== "external-shared-create" && props.mode !== "external-create" && props.mode !== 'external-shared-create' && props.mode !== 'modal-shared-create' && props.mode !== 'plugcode-complete-gallery' && props.mode !== 'plugcode-complete' ) ?
                <Tooltip title="Download file" placement="top" >
                  <span
                    id="icon-download"
                    style={{cursor: 'pointer', marginLeft: 5, fontSize: 35}}
                    className={`material-icons`}
                    onClick={(event) => props.downloadEmployeeData(event, contentId)}
                  >
                    download
                  </span>
                </Tooltip>
                :
                null}
                {!props.locked && (
                <Tooltip
                  title={`${t("actions.comment-edit")}`}
                  placement="top-start"
                >
                  <span
                    className={`material-icons`}
                  style={{cursor: 'pointer', marginLeft: 5, fontSize: 35}}
                    onClick={(event) => props.handleModalAnnexeOpen(event, contentId)}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </span>
                </Tooltip>
                )}

                {!openInfos &&
                <Tooltip title={openInfos? "Hide details" : "Show details"} placement="top" >
                  <span
                    id="icon-infos"
                    style={{cursor: 'pointer', marginLeft: 5, fontSize: 35}}
                    className={`material-icons`}
                    onClick={() => setOpenInfos(!openInfos)}
                  >
                    {openInfos? 'visibility_off' : 'info'}
                  </span>
                </Tooltip>}
              </div>
            </div>
            <div className={`${classes.commentField}`}>
              <div className={classes.modalSubheader}>
                {!props.locked &&
                <div className={`${classes.modal_button} ${classes.button_delete}`}
                  onClick={(event) => props.handleDeleteAnnexe(event, contentId, previousFile)}>
                  {t('global.delete')}
                </div>}
                {!props.locked && !openInfos &&
                <div className={classes.modal_button}
                  onClick={(event) => {
                    setOpenInfos(true);
                    !contentId.comment && props.handleModalAnnexeOpen(event, contentId)
                  }}
                >
                  {t('actions.comment')}
                </div>}
                {props.locked &&
                <div className={classes.modal_button}
                  onClick={(event) => setOpenInfos(!openInfos)}
                >
                  {openInfos? t('actions.hide-details') : t('actions.see-details')}
                </div>}
                <div className={classes.modal_button}
                  onClick={(event) => props.downloadEmployeeData(event, contentId)}>
                  {t('actions.download')}
                </div>
              </div>
            </div>
            {openInfos &&
            <div className={`${classes.commentField}`}>
              <div className={classes.commentText}>
                <div className={classes.commentEdit}>
                  {!props.locked && (
                    <Tooltip
                      title={`${t("actions.comment-edit")}`}
                      placement="top-start"
                    >
                      <div
                        className={`${classes.commentEditIcon}`}
                        onClick={(event) =>
                          props.handleModalAnnexeOpen(event, contentId)
                        }
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </div>
                    </Tooltip>
                  )}
                  <span className={`${classes.fieldTitle}`}>
                    {t("actions.comment")}:
                  </span>
                  <span className={classes.commentDesc}>{contentId.comment}</span>
                </div>
                <Tooltip title={openInfos? "Hide comment" : "Show details"} placement="top" >
                <span
                  id="icon-infos"
                  style={{cursor: 'pointer', marginLeft: 'auto', color: colors.main}}
                  className={`material-icons`}
                  onClick={() => setOpenInfos(!openInfos)}
                >
                  {openInfos? 'visibility_off' : 'info'}
                </span>
              </Tooltip>
              </div>
            </div>
            }
            <div className={classes.modalAttPrev}>
              {
                fileIsImg(contentId) &&
                <Tooltip title="See full image" placement="top" >
                  <span
                    id="icon-zoom"
                    style={{cursor: 'pointer', marginLeft: 'auto', fontSize: 35, color: colors.main, position: 'absolute', right: 0}}
                    className={`material-icons ${classes.modalIcon}`}
                    onClick={(e) => handlePreviewClick(e)}
                  >
                    zoom_out_map
                  </span>
                </Tooltip>
              }
              <div className={classes.scaledImg}>{props.renderAnnexeImagePreview(contentId)}</div>
            </div>
          </div>
          <div className={classes.modalFooter}>
            <span className={classes.modalPgNb}>{fileId + 1}/{props.annexes.length}</span>
          </div>
        </div>
        {
          contentId !== null &&
          <ModalAddAnnexeCommentComponent
            modal={props.modal}
            className={classes.edit_comment}
            open={props.isOpenModalAnnexe}
            close={props.handleModalAnnexeClose}
            annexe={contentId}
            selectedAnnexe={props.selectedAnnexe}
            mode={props.mode}
            handleSaveFictifComment={props.handleSaveFictifComment}
          />
        }
      </div>
    </Modal>
  )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
  return {
    list: props.annexes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalTestAnnexeGallery);
