import React, { useEffect, useRef, useState } from "react";
import {useTranslation} from "react-i18next";
import {camelCase, isArray, isNull} from "lodash";
import Images from "../../assets/themes/Images";
import {makeStyles} from "@material-ui/core/styles";
import {Content, Header} from "rsuite";
import TextField from "@material-ui/core/TextField";
import Select from "react-select";
import Input from "@material-ui/core/Input";
import {confirmAlert} from "react-confirm-alert";
import FormatMemoComponent from "../../components/formats/FormatMemoComponent";
import FormatDateComponent from "../../components/formats/FormatDateComponent";
import FormatLocalisationComponent from "../../components/formats/FormatLocalisationComponent";
import FormatGeoLocalisationComponent from "../../components/formats/FormatGeoLocalisationComponent";
import FormatPlugLinkComponent from "../../components/formats/FormatPlugLinkComponent";
import FormatMultipleSelectComponent from "../formats/FormatMultipleSelectComponent";
import FormatSingleSelectComponent from "../formats/FormatSingleSelectComponent";
import FormatPhoneComponent from "../formats/FormatPhoneComponent";
import FormatAgendaComponent from "../formats/FormatAgendaComponent";
import FormatNowComponent from "../formats/FormatNowComponent";
import FormatBirthdayComponent from "../formats/FormatBirthdayComponent";
import FormatTimeComponent from "../formats/FormatTimeComponent";
import FormatBooleanComponent from "../formats/FormatBooleanComponent";
import FormatPasswordComponent from "../formats/FormatPasswordComponent";
import FormatMailComponent from "../formats/FormatMailComponent";
import FormatUrlComponent from "../formats/FormatUrlComponent";
import FormatPlugcodeComponent from "../formats/FormatPlugcodeComponent";
import FormatSingleSelectTableComponent from "../formats/FormatSingleSelectTableComponent";
import FormatMultipleSelectTableComponent from "../formats/FormatMultipleSelectTableComponent";
import FormatKeywordComponent from "../formats/FormatKeywordComponent";
import FormatFormulaComponent from "../formats/FormatFormulaComponent";
import FormatPlugformComponent from "../formats/FormatPlugformComponent";
import FormatExtDataComponent from "../formats/FormatExtDataComponent";
import FormatExtDatanComponent from "../formats/FormatExtDatanComponent";
import {MdNoteAdd,} from "react-icons/md";
import ModalSavePlugcodeComponent from "../modals/ModalSavePlugcodeComponent";
import ModalConfirmComponent from "../modals/ModalConfirmComponent";
import {getAllTranslationsByModelOfflineRequest, getAllTranslationsByModelRequest,} from "../../actions/translate";
import {connect} from "react-redux";
import FormatTableMultipleComponent from "../formats/FormatTableMultipleComponent";
import colors from "../../assets/themes/Colors";
import FormatSignComponent from "../formats/FormatSignComponent";
import FormatSMailComponent from "../formats/FormatSMailComponent";
import { fieldIsVisible, _getCodePFCode, getCodePnPpCodeTemplate } from "../../utils/Fields";
import FormatTableMultiplenComponent from "../formats/FormatTableMultiplenComponent";
import { Tooltip } from "../../../node_modules/@material-ui/core/index";
import FormatSMailValidMultiComponent from "../formats/FormatSMailValidMultiComponent";
import FormatPlugcodeInternalComponent from "../formats/FormatPlugcodeInternalComponent";
import {delTableView, getToken} from "../../utils/Common";
import FormatIndiceComponent from "../formats/FormatIndiceComponent";
import FormatDocumentComponent from "../formats/FormatDocumentComponent";
import { trackPromise } from "react-promise-tracker";
import { api } from "../../api";
import ModalViewFicheComponent from "../modals/ModalViewFicheComponent";
import { devLog, getLanguageFromFlag, isDefined, isJsonString } from "../../utils/utils";
import FormatIntDataMajComponent from "../formats/FormatIntDataMajComponent";
import { FieldVisibilityIndicator, PlugTooltip } from "../custom/CustomComponents";
import FormatNumericComponent from "../formats/FormatNumericComponent";
import { isMobile } from "react-device-detect";
import FormatTestDateComponent from "../formats/FormatTestDateComponent";
import FormatTextComponent from "../formats/FormatTextComponent";
// import ApplicationConfig from "../../config/ApplicationConfig";
import AttachmentBanner from "../../containers/annexes/AttachmentBanner";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import { AttachFile } from "@material-ui/icons";
import Plugform from "../../classes/Plugform";
import { getAnnexeUrl } from "../../utils/annexes";

const specialFields = ["plugform", "textarea", "list", "single-select", "free-single-select", "multi-select", "free-multi-select", "single-table", "free-single-table", "multi-table", "free-multi-table", "qrcode", "qrcodeint", "localis", "glocalis", "tablemulti", "tablemultin", "extdata", "extdatan", "smailvalidmulti", "document", 'intdatamaj'];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  contentModel: {
    //flexGrow: 1,
    padding: theme.spacing(2),
    overflow: "scroll",
    //maxWidth: "73%",
    "@media (max-width: 1280px)": {
      maxWidth: "80%",
    },
    "@media (max-width: 1024px)": {
      maxWidth: "100%",
      padding: 0,
    },
  },
  fieldImageSvg: {
    width: 20,
    height: 20,
    opacity: 0.7,
    marginTop: 3,
  },
  fieldImage: {
    height: 10,
    width: 10,
  },
  fieldIconMandatory: {
    width: 20,
    height: 20,
    backgroundColor: "#57bbb8",
    borderRadius: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "1px solid red",
  },
  fieldIcon: {
    width: 20,
    height: 20,
    backgroundColor: "#57bbb8",
    borderRadius: 50,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  pointer: {
    cursor: "pointer",
  },
  /* ---------------------- new styles ---------------------------- */
  /* ------ format styles ------- */
  formatContainer: {
    display: "flex",
    justifyContent: "flex-start",
    backgroundColor: "white",
    width: "100%",
    marginBottom: 20,
    gap: 10,
    position: 'relative',
    maxWidth: isMobile ? '70vw' : '90vw',
  },
  formatContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    flexGrow: 1,
    gap: 5,
    maxWidth: '95%',
    "&::-webkit-scrollbar": {
      display: "block",
      overflowX: 'auto',
      background: '#E3E3E3',
      width: '5px',
    },
  },
  formatHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    zIndex: 100,
  },
  numTitleFormat: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    whiteSpace: "nowrap",
    textShadow: "none",
    width: '30%',
    justifyContent: 'space-between',
    paddingRight: 5
  },
  numHeaderFormat: {
    whiteSpace: "nowrap",
    color: colors.darkgrey,
    fontWeight: "thin",
    textShadow: "none",
    fontSize: 12,
  },
  separatorHeaderFormat: {
    backgroundColor: colors.greyLight,
    width: 1,
    height: 16,
  },
  fieldIconCtn: {
    position: 'relative',
    height: 36,
  },
  fieldIndex: {
    marginLeft: -12,
    fontSize: 8,
    position: 'absolute',
    top: 16,
  },
  fieldIndexPlugcode: {
    marginLeft: -12,
    fontSize: 8,
    position: 'absolute',
    bottom: -8,
  },
  formatTitleCtn: {
    display: 'inline-flex',
    gap: 5,
    alignItems: 'center',
  },
  titleHeaderFormat: {
    whiteSpace: "break-spaces",
    color: colors.unselected,
    fontWeight: "bold",
    textShadow: "none",
    fontSize: 14,
    paddingRight: '5px'
  },
  formatInputField: {
    border: "solid #E3E3E3 1px",
    borderRadius: "4px",
    marginLeft: "auto",
    width: "100%",
    minHeight: '40px',
    padding: "3px 10px",
  },
  formatInputBooleanField: {
    // border: "solid #E3E3E3 1px",
    borderRadius: "4px",
    // marginLeft: "auto",
    width: "92.5%",
    // padding: "5px 15px",
  },
  formatInputFieldInline: {
    border: "solid #E3E3E3 1px",
    borderRadius: "4px",
    width: "70%",
    padding: "3px 10px",
    minHeight: 34,
  },
  formatInlineNoBorder: {
    width: "70%",
  },
  formatInlineNoBorderLocked: {
    backgroundColor: colors.greyLighter,
    width: "70%",
  },
  formatInputFieldInlineLocked: {
    backgroundColor: colors.greyLighter,
    border: "solid #E3E3E3 1px",
    borderRadius: "4px",
    width: "70%",
    padding: "3px 10px",
  },
  formatInputFieldComposed: {
    //border: "solid red 1px",
    borderRadius: "4px",
    marginLeft: "auto",
    width: "100%",
    padding: "5px 0",
    // overflowX: 'hidden', //temp fix for f32 size issue
  },
  formatInputFieldLocked: {
    backgroundColor: colors.greyLighter,
    border: "solid #E3E3E3 1px",
    borderRadius: "4px",
    width: "100%",
    padding: "3px 10px",
  },
  formatInputFieldLockedComposed: {
    backgroundColor: colors.greyLighter,
    //border: "solid #E3E3E3 1px",
    borderRadius: "4px",
    width: "100%",
    // overflowX: 'hidden', //temp fix for f32 size issue
  },
  formatText: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  visibilityIcon: {
    //border: "solid blue 1px",
    width: "30px",
  },
  confirmContainer: {
    border: "1px solid #F9F9F9",
    backgroundColor: "white",
    borderRadius: 15,
    padding: "15px",
  },
  confirmTitle: {
    color: colors.unselected,
    fontSize: "30px",
  },
  confirmText: {
    color: "#88949C",
  },
  confirmButtons: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "space-between",
  },
  confirmButtonStyle: {
    width: "100px",
    padding: "4px 20px",
    borderRadius: "3px",
    cursor: "pointer",
    "@media (max-width: 1024px)": {
      /* width: "100%", */
    },
  },
  confirmAffirmative: {
    color: "white",
    backgroundColor: colors.mainButton,
    border: "none",
  },
  confirmNegative: {
    color: colors.darkSilver,
    borderColor: colors.darkSilver,
    backgroundColor: "transparent",
    border: "2px solid",
    fontWeight: "600",
  },

  /* ------ other styles ------- */
  mainColor: {
    color: colors.plugnotesLight,
    cursor: "default"
  },
  lockedColor: {
    color: "#e73536",
  },
  fieldBorder: {
    border: "2px solid",
    borderColor: colors.greyLight,
    borderRadius: "5px",
    // width: "100%",
    "@media (max-width: 1024px)": {
      border: "none",
    },
  },
  titleBorder: {
    border: "2px solid #E3E3E3",
    borderRadius: "5px",
    "@media (max-width: 1024px)": {
      border: "none",
      margin: -4,
      backgroundColor: 'white',
    },
    padding: 15,
    paddingBottom: 20,
    position: 'relative',
  },
  formContainer: {
    maxWidth: "80vw",
    position: "relative",
    top: "65px",
    height: "calc(100vh - 65px)",
    padding: 15,
    display: "flex",
    alignItems: 'flex-start',
    alignContent: 'baseline',
    flexWrap: "wrap",
    justifyContent: 'flex-end',
    overflow: "auto",
    scrollbarWidth: 'thin', //without buttons on firefox, change to 'auto' if buttons wanted
    scrollbarColor: '#9B9B9B #E3E3E3', //first color to scrollbutton and the second to the bar
    "&::-webkit-scrollbar": {
      display: "block",
      overflowX: 'auto',
      background: '#E3E3E3',
      width: '5px',
    },
    "&::-webkit-scrollbar-thumb": {
      background: '#9B9B9B',
    },
    /* border: "1px solid blue", */
    margin: "0 auto",
    //scrollbarWidth: "none",
    // "&::-webkit-scrollbar": {
    //   display: "none",
    // },
    "@media (max-width: 1024px)": {
      maxWidth: "65vw",
      top: "45px",
    },
    "@media (max-width: 700px)": {
      maxWidth: "100vw",
    },
  },
  formModalContainer: {
    maxWidth: "65vw",
    position: "relative",
    top: "5px",
    padding: 15,
    display: "flex",
    flexWrap: "wrap",
    overflow: "auto",
    /* border: "1px solid blue", */
    margin: "0 auto",
    alignItems: 'flex-start',
    alignContent: 'baseline',
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "@media (max-width: 1024px)": {
      maxWidth: "65vw",
      top: "45px",
    },
    "@media (max-width: 700px)": {
      maxWidth: "100vw",
    },
  },
  formModalCreateContainer: {
    height: "calc(100vh - 65px)",
    maxWidth: "80vw",
    position: "relative",
    top: "5px",
    padding: 15,
    display: "flex",
    flexWrap: "wrap",
    margin: "0 auto",
    alignItems: 'flex-start',
    alignContent: 'baseline',
    overflow: "auto",
    scrollbarWidth: 'thin', //without buttons on firefox, change to 'auto' if buttons wanted
    scrollbarColor: '#9B9B9B #E3E3E3', //first color to scrollbutton and the second to the bar
    "&::-webkit-scrollbar": {
      display: "block",
      overflowX: 'auto',
      background: '#E3E3E3',
      width: '5px',
    },
    "&::-webkit-scrollbar-thumb": {
      background: '#9B9B9B',
    },
    "@media (max-width: 1024px)": {
      maxWidth: "65vw",
    },
    "@media (max-width: 700px)": {
      maxWidth: "100vw",
    },
  },
  header: {
    width: "100%",
    flex: "0 0 100%",
    top: 0,
  },
  headerInfos: {
    fontSize: '12px !important'
  },
  sous_header_content: {
    fontSize: '13px !important',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 5,
    "@media (max-width: 1300px)": {
      flexDirection: "column-reverse",
    },
  },
  header_content: {
    fontSize: '13px !important',
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    justifyContent: "space-between",
    gap: 5,
    "@media (max-width: 1300px)": {
      flexDirection: "column",
    },
  },
  sous_header_content_title: {
    flexGrow: 1,
    overflow: "hidden",
    display: "flex",
    "@media (max-width: 1024px)": {
      width: "100%",
    },
    justifyContent: 'space-between',
  },
  sous_header_content_title_input: {
    color: colors.unselected,
    "@media (max-width: 1024px)": {
      textAlign: "left",
    },
  },
  sous_title_content: {
    display: 'flex',
    flexDirection: "row",
    gap: "25px",
  },
  sous_header_content_buttons: {
    display: "flex",
    marginLeft: 'auto',
    gap: 5,
    maxHeight: 40,
    "@media (max-width: 1024px)": {
      width: "300px",
      justifyContent: "end",
    },
  },
  sous_header_content_buttons_one: {

    display: "flex",
    marginTop : '5px',
    marginLeft: 'auto',
    gap: 5,
    maxHeight: 40,
    "@media (max-width: 1024px)": {
      width: "300px",
      justifyContent: "end",
    },
  },
  sous_header_button: {
    width: "150px",
    padding: "4px 20px",
    borderRadius: "3px",
    cursor: "pointer",
    "@media (max-width: 1024px)": {
      width: "100%",
    },
  },
  sous_header_button_one: {
    padding: "4px 20px",
    borderRadius: "3px",
    cursor: "pointer",
    "@media (max-width: 1024px)": {
      width: "100%",
    },
  },
  button_save: {
    color: "white",
    backgroundColor: colors.mainButton,
    border: "none",
  },
  button_cancel: {
    color: colors.mainDark,
    backgroundColor: "transparent",
    border: `2px solid ${colors.mainDark}`,
    fontWeight: "600",
  },
  fieldTitle: {
    color: "gray",
    margin: "0 0 15px 0",
  },
  commentField: {
    marginTop: 10,
    "@media (max-width: 1024px)": {
      margin: "15px 0",
    },
  },
  commentContent: {
    padding: 15,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 5,
    "@media (max-width: 1024px)": {
      flexDirection: "column",
      alignItems: "center",
      padding: 10,
      border: `2px solid ${colors.frost}`,
      borderRadius: "5px",
    },
  },
  commentText: {
    display: "flex",
    flexDirection: "row-reverse",
    width: '100%',
    "@media (max-width: 1024px)": {
      alignItems: "center",
    },
  },
  commentDesc: {
    fontSize: "14px",
    "@media (max-width: 1024px)": {
      textAlign: "center",
    },
    color: colors.unselected
  },
  contentFields: {
    overflow: 'hidden',
    height: 'fit-content',
    flexGrow: 1,
    display: "flex",
    alignItems: 'flex-start',
    gap: 15,
    justifyContent: "space-between",
    "@media (max-width: 1300px)": {
      flexDirection: "column",
      display: "inherit"
    },
  },
  columnModal: {
    overflow: 'hidden',
    height: 'fit-content',
    flexGrow: 1,
    display: "flex",
    alignItems: 'stretch',
    gap: 15,
    flexDirection: 'column',
    justifyContent: "space-between",
    "@media (max-width: 1300px)": {
      flexDirection: "column",
      display: "inherit"
    },
  },
  contentFieldModelConsult: {
    width: '100%',
  },
  contentFieldModel: {
    padding: theme.spacing(2),
    overflowX: 'hidden', //temp fix for f32 size issue
    // height: "calc(100vh - 65px)",
    // flex: "0 0 69%",
    "@media (max-width: 1300px)": {
      minHeight: "auto",
      width: '100%'
    },
    "@media (max-width: 1280px)": {
      maxWidth: "100%",
      minHeight: "auto",
      // maxHeight: 'calc(100vh - 120px)',
      overflow: "overlay"
    },
    "@media (max-width: 1024px)": {
      maxWidth: "100%",
      padding: 0,
    },
  },
  attField: {
    padding: '10px',
    flex: "0 1 30%",
    marginBottom: '25px',
    "@media (max-width: 1300px)": {
      flex : 'inherit',
      width: "100%",
      padding: '10px 15px',
    },
  },
  attachmentTitle: {
    color: colors.unselected,
    fontWeight: "600",
  },
  attachmentBox: {
    margin: "0 0 25px 0",
    border: "dashed 2px #51C3C1",
    borderRadius: "5px",
    backgroundColor: colors.greenLightest,
    height: 90,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: 'center',
    alignItems: "center",
    cursor: 'pointer',
    position: 'relative',
  },
  attContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    overflow: "hidden",
  },
  itemContainer: {
    '&:hover': {
      backgroundColor: colors.mainPale,
      borderRadius: 4,
      whiteSpace: 'wrap',
    },
    padding: 5
  },
  attItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  attAnnexName: {
    overflow: "hidden",
    whiteSpace: 'nowrap',
    alignSelf: "flex-start",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    flexGrow: 1,
    fontSize: "14px",
    cursor: "pointer",
    marginTop: -5,
    textOverflow: 'ellipsis',
    maxWidth: 130,
    '@media (max-width: 1300px)': {
      maxWidth: 'inherit'
    }
  },
  attIconDlInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    color: colors.main,
    alignSelf: 'flex-start',
  },
  attIconType: {
    marginRight: 10,
    display: "flex",
    flexDirection: "column-reverse",
    alignSelf: 'flex-start',
    alignItems: 'center',
    fontSize: 12,
    cursor: "pointer",
    '@media (max-width: 1300px)': {
      flex: "0 0 10%",
      textAlignLast: 'center',
    },
  },
  attIcon: {
    fontSize: '64px',
    color: colors.white,
    position: 'absolute',
    top: '-10px',
    right: '-25px',
    zIndex: 25
  },
  attIconShadow: {
    fontSize: '64px',
    color: colors.plugnotesGris,
    position: 'absolute',
    top: '-11px',
    right: '-28px',
    zIndex: 1
  },
  memoCount:{
    width: '50px',
    fontSize : '10px',
    textAlign: 'center',
    color: colors.unselected,
    lineHeight: '10px',
  },
  pageInfos: {
    fontSize: 8,
    color: colors.frost,
    textAlignLast: 'right',
    marginBottom: -16,
    marginTop: -16,
    zIndex: 1001
  },
  //Lang select
  langCtn: {
    minWidth: 90,
    zIndex: 2000,
    height: 38,
    maxHeight: 40,
    overflowX: 'clip',
    position: 'relative',
    '@media (max-width: 1024px)': {
      minWidth: 140,
    },
    '& .css-1pahdxg-control': {
        boxShadow: 'none !important',
        borderColor: `${colors.main} !important`,
        borderWidth: 2
    },
    '& .css-1kcwhfq-singleValue:before': {
      display: 'none'
    },
    '& .css-5h2isx-placeholder:before': {
      display: 'none'
    },
    '& .css-yk16xz-control': {
        borderColor: colors.frost,
        borderWidth: '2px',
        '&:hover': {
            borderColor: colors.main
        }
    },
    '& .css-g1d714-ValueContainer': {
      padding: '0px !important',
      overflow: 'visible',
      position: 'relative',
      width: '-webkit-fill-available',
      maxWidth: 120,
    },
    '& .css-5duvv3-control': {
      flexWrap: 'nowrap !important',
    },
    '&.css-1iava1u-Input': {
      visibility: 'hidden !important',
    },
    '& .css-1uccc91-singleValue': {
        color: colors.unselected
    },
    '& .css-1kcwhfq-singleValue': {
      maxWidth: 140,
      overflow: 'visible',
      top: 14,
    },
    '& .css-1hb7zxy-IndicatorsContainer': {
      backgroundColor: colors.transparent,
      // display: 'none !important',
      flexDirection: 'row-reverse',
    },
    '& .css-tlfecz-indicatorContainer': {
      backgroundColor: colors.transparent,
      padding: '16px 0 0',
    },
    '& .css-1gtu0rj-indicatorContainer': {
      backgroundColor: colors.transparent,
      padding: '16px 0 0',
    },
    '& .css-1okebmr-indicatorSeparator': {
      width: 0,
    },
  },
  deleteLngIcon: {
    position: 'absolute',
    top: 8,
    zIndex: 2001,
  },
  infobulle: {
    backgroundColor: colors.grey,
    color: colors.white,
    border: '1px solid',
    padding: '6px 10px',
    margin: 5,
    fontSize: '18px',
    borderRadius: 5,
    whiteSpace: 'pre-line',
  },
  noTooltip: {
		backgroundColor: colors.transparent,
	},
  translationSelect: {
      lineHeight: 0.6,
      fontSize: 9,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      flex: 1
  },
  // Banner
  verticalFlex: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  horizontalFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',
  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
  },
});

const selectStyle = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    // const color = chroma(data.color);
    return {
      ...styles,
      backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? colors.palePlugGreen
        : isFocused
        ? colors.palePlugGreen
        : undefined,
      color: isDisabled
        ? '#ccc'
        : 'black',
        // : colors.darkgrey,
      cursor: isDisabled ? 'not-allowed' : 'default',
      ':active': {
        ...styles[':active'],
        backgroundColor:
          isSelected && isFocused ?
            colors.palePlugGreen
          : undefined,
        color: 'white'
      },
    };
  },
  input: (styles) => ({ ...styles, ...dot() }),
  placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
  singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
};

const PlugnoteHeader = ({
  disabled, environment, external,
  handleExitPlugnote, handleToggleBanner, handleOpenDataOrigin,
  handleOpenSave, handleSafeExitPlugnote,
  handleSaveCreatePlugnote, handleSaveCreatePlugnoteWithoutRedirect,
  handleSaveUpdatePlugnote, handleSaveUpdatePlugnoteWithoutRedirect, handleSavePlugnote,
  handleUpdatePlugnote, handleSavePlugform,
  mode, plugnoteAnnexes, languageSelector, plugnote,
  plugKey, pfCode, pnCode, plugcode, pfComment, pnComment, nameModel, model, openBanner
}) => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  const [openComment, setOpenComment] = useState(true)

  return (
    <Header className={`sticky ${classes.header}`}>
      <div className={classes.titleBorder} >
        <div className={classes.sous_header_content}>
          <div className={classes.horizontalFlex} style={{ flex: 1, alignItems: 'flex-start' }}>
            <div className={classes.header_content}>
              <div className={classes.sous_header_content_title}>
                <span className={classes.sous_header_content_title_input}>
                  {`${nameModel} - ${environment.name} ${isDefined(plugcode) ? ' - ' + plugcode : ''}`}
                </span>
                {
                  !openComment &&
                  <PlugTooltip title={"Show comments"} placement="top" >
                    <span
                      id="icon-infos"
                      style={{ cursor: 'pointer', alignSelf: 'center', marginRight: 8 }}
                      className={`${classes.mainColor} material-icons md-25`}
                      onClick={() => setOpenComment(true)}
                    >
                      visibility
                    </span>
                  </PlugTooltip>
                }
              </div>
              <div className={classes.sous_title_content}>
                <div className={classes.headerInfos}>
                  {plugKey} {pfCode !== '' ? ' - ' + pfCode : ''}
                  {
                    (mode?.includes("update") || mode?.includes('view')) &&
                      pnCode !== '' ? ' - ' + pnCode : ''
                  }
                </div>
              </div>
            </div>
            {
              plugnoteAnnexes?.length > 0 &&
              <PlugTooltip title={t('multiple.' + (openBanner ? 'close' : 'open') + '-att-banner')} placement="left">
                <div
                  className={classes.horizontalFlex}
                  onClick={handleToggleBanner}
                  style={{ cursor: 'pointer', marginLeft: 'auto' }}>
                  <span className={classes.verticalFlex} style={{ fontSize: 11, flexDirection: openBanner ? 'column' : 'column-reverse' }}>
                    {openBanner ? <BiChevronUp /> : <BiChevronDown />}
                    ({plugnoteAnnexes?.length})
                  </span>
                  <AttachFile style={{ width: 36, fill: colors.unselected, fontSize: 32 }} />
                </div>
              </PlugTooltip>
            }
          </div>
          <div className={classes.sous_header_content_buttons}>
            {languageSelector}

            <PlugTooltip title={t('global.cancel-exit')}>
              <button
                className={`${classes.sous_header_button} ${classes.button_cancel}`}
                onClick={handleExitPlugnote}
              >
                <span className="material-icons" style={{ color: colors.main }} >
                  cancel
                </span>
              </button>
            </PlugTooltip>

            {
              (
                mode === "create" ||
                mode === "update" ||
                (mode === "plugcode-consult" && plugnote.publicpncode === 0 && (plugnote?.pncodeprivileges === "readcopyedit" || plugnote?.pncodeprivileges === "readecopy")) ||
                (mode === "plugcode-consult" && plugnote.publicpncode === 1)
              ) &&
              <PlugTooltip title={mode.includes('plugcode') ? t("common:multiple.copy") : t('common:global.save')}>
                <button
                  className={`${classes.sous_header_button} ${classes.button_save}`}
                  onClick={handleSavePlugnote}
                >
                  <span className="material-icons" style={{ color: colors.hoverBgnd }}>save</span>
                </button>
              </PlugTooltip>
            }
            {
              (mode === "shared-create" || mode === "shared-update") &&
              <PlugTooltip title={t('common:global.save')}>
                <button
                  className={`${classes.sous_header_button} ${classes.button_save}`}
                  onClick={
                    plugnote === null
                      ? handleSaveCreatePlugnoteWithoutRedirect
                      : handleSaveUpdatePlugnoteWithoutRedirect
                  }
                >
                  <span className="material-icons" style={{ color: colors.hoverBgnd }}>save</span>{" "}
                </button>
              </PlugTooltip>
            }
            {
              (mode === "plugcode-consult" || mode === "plugcode-consult-gallery") && plugnote?.pncodeprivileges === "readedit" && !disabled &&
              <PlugTooltip title={t('common:global.update')}>
                <button
                  className={`${classes.sous_header_button} ${classes.button_save}`}
                  onClick={handleUpdatePlugnote}
                >
                  {t("common:global.update")}
                </button>
              </PlugTooltip>
              }

            {
              mode === "plugcode-complete" &&
              <button
                className={`${classes.sous_header_button} ${classes.button_save}`}
                onClick={handleSavePlugform}
              >
                {t("common:multiple.save")}
              </button>
            }
            {
              (
                mode === "shared-create" ||
                mode === "shared-update" ||
                mode === "plugcode-complete" ||
                mode === "plugcode-complete-modal" ||
                mode === "plugcode-complete-gallery"
              ) &&
              <PlugTooltip title={mode.includes('plugcode') ? t("common:multiple.send") : t('common:global.safe-exit')}>
                <button
                  className={`${classes.sous_header_button} ${classes.button_save}`}
                  onClick={
                    (plugnote === null || mode.includes('plugcode'))
                      ? handleSaveCreatePlugnote
                      : handleSaveUpdatePlugnote
                  }
                >
                  <span className="material-icons" style={{ color: colors.hoverBgnd }}>
                    logout
                  </span>{" "}
                </button>
              </PlugTooltip>
            }

            {
              (mode === "create" || mode === "update") &&
              <PlugTooltip title={t('common:global.safe-exit')}>
                <button
                  className={`${classes.sous_header_button} ${classes.button_save}`}
                  onClick={handleSafeExitPlugnote}
                >
                  <span className="material-icons" style={{ color: colors.hoverBgnd }}>
                    logout
                  </span>
                </button>
              </PlugTooltip>
            }

            {
              (mode === "modal-plugnote-create") && (
              typeof external !== "undefined" ?
                <button
                  className={`${classes.sous_header_button} ${classes.button_save}`}
                  onClick={handleOpenSave}
                >
                  {t("common:multiple.send")}
                </button>
                :
                <button
                  className={`${classes.sous_header_button} ${classes.button_save}`}
                  onClick={handleSaveCreatePlugnote}
                >
                  {t("common:multiple.save")}
                </button>
            )}
          </div>
        </div>
      </div>
      {(pfComment?.length > 0) && plugnote === null && openComment ?
        <div className={`${classes.commentField} ${classes.fieldBorder}`} style={(openComment && pnComment !== '') ? { marginBottom: 10 } : null}>
          <div className={classes.commentContent}>
            <div className={classes.commentText}>
              <TextField className={classes.commentDesc}
                value={pfComment}
                multiline
                style={{ width: '100%' }}
                InputProps={{ disableUnderline: true, }}
                disabled={true}
              />
            </div>
            <PlugTooltip title={"Hide comment"} placement="top" >
              <span
                id="icon-infos"
                style={{ cursor: 'pointer', alignSelf: 'flex-start' }}
                className={`${classes.mainColor} material-icons md-25`}
                onClick={() => setOpenComment(false)}
              >
                visibility_off
              </span>
            </PlugTooltip>
          </div>
        </div>
        : null}
      {
        !!model && (pnComment?.length > 0) && openComment &&
        <div className={`${classes.commentField} ${classes.fieldBorder}`}>
          <div className={classes.commentContent}>
            <div className={classes.commentText}>
              <PlugTooltip title={"Hide comment"} placement="top" >
                <span
                  id="icon-infos"
                  style={{ cursor: 'pointer', alignSelf: 'flex-start' }}
                  className={`${classes.mainColor} material-icons md-25`}
                  onClick={() => setOpenComment(false)}
                >
                  visibility_off
                </span>
              </PlugTooltip>
              <TextField
                className={classes.commentDesc}
                value={pnComment}
                multiline
                style={{ width: '100%' }}
                InputProps={{ disableUnderline: true, }}
                disabled={true}
              />
            </div>
          </div>
        </div>
      }
      {
        model?.authoriseShowPlugLinkData === true && isDefined(plugnote) && plugnote.dataHashOrigin?.length > 0 &&
        <div className={classes.sous_header_content_buttons_one}>
          <button
            className={`${classes.sous_header_button_one} ${classes.button_save}`}
            onClick={handleOpenDataOrigin}
          >
            <span>{t("common:global.open-origin")}</span>
          </button>
        </div>
      }
    </Header>
  )
}

function Plugforms(props) {
  const { t } = useTranslation(["common", "models"]);
  const classes = useStyles();
  const topRef = useRef(null)
  const [lngOptions, setOptions] = useState([]);
  const [originalLng, setOriginalLng] = useState(null);
  const [loading, setLoading] = useState(lngOptions.length === 0);
  const [lang, setLang] = useState(null);
  const [nameModel, setNameModel] = useState(props.nameModel);
  const nameModelOrigin = props.nameModel
  const [fields, setFields] = useState(props.fields);
  const [pnComment, setPnComment] = useState('')
  const [pfComment, setPfComment] = useState('')
  const [infobulles, setInfobulles] = useState(null)
  const [openComment, setOpenComment] = useState(true) //pfComment, pnComment
  const [openBanner, setOpenBanner] = useState(false)
  const [pfCode, setPfCode] = useState(isDefined(props.model) ? _getCodePFCode(props.model) : '')
  const [pnCode, setPnCode] = useState(isDefined(props.plugnote) ? getCodePnPpCodeTemplate(props.plugnote) : '')
  const [fileId, setFileId] = useState("");
  const [view, setView] = useState(false);

  const plugCode = typeof props.plugnote !== "undefined" && props.plugnote !== null ? getCodePnPpCodeTemplate(props.plugnote) : typeof props.model !== "undefined" && props.model !== null ? _getCodePFCode(props.model) : props.searchPlugcode
  const pnKey = typeof props.plugnote !== "undefined" && props.plugnote !== null ? "[" + props.environment?.envIndex + "-" + props.plugnote.userindex + "]" : ""
  const pfKey = typeof props.model !== "undefined" && props.model !== null ? "[" + props.model.clef + "]" : ""

  useEffect(() => {
    if (lngOptions.length === 0) {
      let active = true;
      if (!loading) {
        return undefined;
      }
      handleFetchTranslations();
      return () => {
        active = false;
      };
    } else {
      setLoading(false);
    }
  }, [loading]);

  useEffect(() => {
    if (lngOptions.length !== props.list?.length) {
      let langs = [];
      props.list.forEach((i, index) => {
        i = getLanguageFromFlag(i)
        langs.push({
          ...i,
          value: i.id,
          title: i.label,
          label: (
            <div className={classes.translationSelect}>
              <img alt="" src={require('../../assets/images/flags/' + i.flag + '.svg')} height="30px" width="30px" />
              {i.name}
            </div>
          ),
          indice: index, translation: i.translationId
        });
      });
      setOptions(langs);
    }
  }, [props.list]);

  useEffect(() => {
    if (typeof props.setFields !== "undefined") {
      if (lang !== null && lang !== "") {
        translateFields()
      } else {
        restoreOriginalFieldLng()
      }
    }

    if (lang !== null && lang !== "" && lang["title"] !== "") {
      setNameModel(lang["title"]);
    } else {
      setNameModel(nameModelOrigin);
    }
    //Champ de titre
    let fCode = props.model !== null ? _getCodePFCode(props.model) : ''
    setPfCode(fCode)
    let nCode = props.plugnote !== null ? getCodePnPpCodeTemplate(props.plugnote) : ''
    setPnCode(nCode)

    //Champ de commentaire - PN & PF
    let pfCode = props.model !== null ? _getCodePFCode(props.model) : ''
    let pnComment = props.plugnote === null ? "" : typeof props.plugnote !== undefined ? props.plugnote?.pncomment ? props.plugnote.pncomment : "" : "";

    if (typeof plugCode !== "undefined") {
      let prefix = plugCode.startsWith("PN") ? plugCode + ' : ' : ''
      pnComment === '' ? setPnComment("") : setPnComment(prefix + pnComment);
      let comment = props.model === null ? "" : typeof props.model !== undefined ? props.model?.pfcomment ? props.model.pfcomment : "" : "";
      prefix = comment.length !== 0 ? pfCode + ' : ' : ''
      comment === '' ? setPfComment("") : setPfComment(prefix + comment)
    }
  }, [lang]);

  useEffect(() => {
    compileInfobulles()
  }, [props.fields])

  useEffect(() => {
    setNameModel(props.nameModel);
  }, [props.nameModel])

  useEffect(() => {
    const fetchFieldTooltips = () => {
      let hash = props.model.hash
      trackPromise(
        api.get(`models/infosbulles/infb/unique.json?mh=${hash}`),
        {
          crossdomain: true,
          'headers': {
            'X-AUTH-TOKEN': getToken(),
          }
        })
        .then((response) => {
          if (response.data.result) {
            setInfobulles(response.data.data)
          }
        })
        .catch(e => console.log(e))
    }
    if (infobulles === null && props.model?.hash !== undefined) {
      fetchFieldTooltips()
    }
  }, [props.model])

  useEffect(() => {
    if (originalLng === null && props.fields.length > 0) backupOriginalLng();
  }, [fields])

  function compileInfobulles() {
    // console.log('COMPILE INFOBULLES');
    // console.log('Infobulles', infobulles);
    if (infobulles !== null && infobulles !== undefined && props.fields.length > 0) {
      let _fields = [...props.fields]
      fields.forEach((field, fid) => {
        if (infobulles.hasOwnProperty('field' + field.id)) _fields[fid]['infobulle'] = infobulles['field' + field.id]
      })
      //console.log('Fields avec infobulles', _fields);
      setFields(_fields)
    } else {
      setFields(props.fields);
    }
  }

  function backupOriginalLng() {
    let original = {
      description: "Original labels",
      label: props.model.label
    }
    fields.forEach((field, findex) => {
      let options = field.options?.lines !== undefined ? field.options.lines : field.options;

      if(typeof options !== "undefined"){
        if (options.presel !== undefined) options = options.presel
        let originalOptions = [];
        if (options.length > 0 && isArray(options)) {
          options.forEach((subfield, index) => originalOptions.push({
            id: subfield.id !== undefined ? subfield.id : index,
            label: subfield.label !== undefined ? subfield.label : subfield
          }))
        }
        original['field' + (findex + 1)] = field.title;
        original['field' + (findex + 1) + 'formatoptions'] = originalOptions;
      }
    })
    //console.log("Backed up originals :", original);
    setOriginalLng(original);
  }

  function restoreOriginalFieldLng() {
    // console.log('Fields to restore : ', fields);
    if (fields.length > 0) {
      fields.map((field, index) => {
        if(originalLng !== null){
          field['titleTranslate'] = originalLng['field' + (index + 1)]
          if (field.options?.lines !== undefined) {
            field.options.lines.map((subfield, subindex) => {
              let label = originalLng['field' + (index + 1) + 'formatoptions'].find((opt) => opt.id === subfield.id)['label']
              subfield.label = label

              return subfield;
            })
          } else if (field.options?.presel !== undefined) {
            let originalPreselNb = field.originalPresel.length // concaténation des éventuels ajouts
            field.options.presel = field.originalPresel.concat(field.options.presel.slice(originalPreselNb))
          } else if (field.options?.length > 0) {
            field.options.map((subfield, subindex) => {
              let label = originalLng['field' + (index + 1) + 'formatoptions'].find((opt) => opt.id === subfield.id)['label']
              subfield.label = label
              return subfield;
            })
          }
        }
        return field;
      })
    }
    props.setFields([...fields])
  }

  function translateFields() {
    fields.map(field => {
      let options = field.options?.lines !== undefined ? field.options.lines : field.options?.length > 0 ? field.options : []
      if (lang !== null && lang !== "" && lang["field" + field.id] !== "") {
        field.titleTranslate = lang["field" + field.id];
      } else {
        field.titleTranslate = field.title;
      }
      if (lang["field" + field.id + "formatoptions"] !== undefined) {
        const tradOptions = lang["field" + field.id + "formatoptions"];
        if (options.length > 0) {
          options.forEach((row, subindex) => {
            let tradRow = tradOptions.find(el => el.id === row.id);
            if (isDefined(tradRow)) {
              let trad = tradRow.label;
              if (trad !== '' && trad !== undefined) {
                row.label = trad
              } else {
                let tradOptions = originalLng["field" + field.id + "formatoptions"];
                let trad = (tradOptions?.lines !== undefined) ? tradOptions.lines.find(el => el.id === row.id)['label'] : tradOptions.find(el => el.id === row.id)['label']
                if (trad !== '' && trad !== undefined) {
                  row.label = trad
                }
              }
              if (tradRow.hasOwnProperty('presels')) {
                let tradPresels = tradRow.presels;
                options[subindex].presel = options[subindex].presel.map((presel, id) => tradPresels[id] !== '' ? tradPresels[id] : presel);
              }
            }
          })
          if (field.options?.lines !== undefined) field.options.lines = options;
          else if (field.options?.length > 0) field.options = options;
        }
      } else if (originalLng["field" + field.id + "formatoptions"] !== undefined) {
        let tradOptions = originalLng["field" + field.id + "formatoptions"];
        if (options.length > 0) {
          options.forEach((row, subindex) => {
            let trad = (tradOptions?.lines !== undefined) ? tradOptions.lines.find(el => el.id === row.id)['label'] : tradOptions.find(el => el.id === row.id)['label']
            if (trad !== '' && trad !== undefined) {
              row.label = trad
            }
          })
          if (field.options?.lines !== undefined) field.options.lines = options;
          else if (field.options?.length > 0) field.options = options;
        }
      }
      return field;
    })
    props.setFields([...fields]);
  }
  const handleToggleBanner = () => {
    setOpenBanner(current => {
      if (!current) {
        topRef.current.scrollIntoView({ behavior: "smooth" })
      }
      return !current
    })
  }

  useEffect(() => {
    compileInfobulles()
  }, [infobulles])

  const handleFormatInput = (event, inputTitle, inputText) => {
    event.preventDefault();
    event.stopPropagation();
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className={classes.confirmContainer}>
            <h1 className={classes.confirmTitle}>{inputTitle}</h1>
            <p className={classes.confirmText}>{inputText}</p>
            <div className={classes.confirmButtons}>
              <button
                className={`${classes.confirmButtonStyle} ${classes.confirmAffirmative}`}
                onClick={onClose}
              >
                {t("global.close")}
              </button>
            </div>
          </div>
        );
      },
    });
  };

  const handleFetchTranslations = async () => {
    // const response = await fetch('https://country.register.gov.uk/records.json?page-size=5000');
    // await sleep(1e3); // For demo purposes.
    // const countries = await response.json();
    //console.log('fetching translations ... ');
    switch (props.mode) {
      case "external-shared-create":
        await props.attemptGetAllTranslationsOffline(props.model.hash, null);
        break;
      default:
        try {
          await props.attemptGetAllTranslations(
            props.model.hash,
            props.environment.userhash
          );
        } catch (e) {

        }
        break;
    }
  }

  const handleChangeLanguage = (value) => {
    let language = { ...value }
    // Parsing du fichier de traduction
    Object.keys(language).forEach((entry, id) => {
      if (entry.includes('formatoptions')) language[entry] = isJsonString(language[entry]) ? JSON.parse(language[entry]) : language[entry]
    })
    if (value) setLang(language); else setLang("")
  }

  const handleOpenDataOrigin = (datahash) => {
    setView(true)
  }

  const handleExitPlugnote = () => {
    delTableView()
    switch (props.mode) {
      case "create":
      case "shared-create":
      case "modal-plugnote-create":
        props.handleExitCreatePlugnote();
        break;
      case "update":
      case "shared-update":
      case "shared-view":
        props.handleExitUpdatePlugnote();
        break;

      case "plugcode-consult":
      case "plugcode-consult-modal":
      case "plugcode-consult-gallery":
        props.handleExitPlugnote()
        break

      case "plugcode-complete":
      case "plugcode-complete-modal":
      case "plugcode-complete-gallery":
        props.handleExitCreatePlugnote()
        break

      case "modal-shared-create":
        props.handleExitCreatePlugnote();
        break;
      case "modal-shared-update":
        props.handleExitUpdatePlugnote();
        break;
      default:
        break;
    }
  }

  const handleSavePlugnote = () => {
    if (props.plugnote === null) { props.handleSaveCreatePlugnoteWithoutRedirect() }
    else { props.handleSaveUpdatePlugnoteWithoutRedirect() }
  }

  const handleSafeExitPlugnote = () => {
    if (props.plugnote === null) { props.handleSaveCreatePlugnote() }
    else { props.handleSaveUpdatePlugnote() }
  }

  const handleDropdownFiles = (files) => {
    if (Array.isArray(files)) {
      for (const file of files) {
        props.onChangeHandlerDrop([file])
      }
    }
  }

  const nowTime = new Date(Date.now())
  const dateOptions = { day: '2-digit', month: '2-digit', year: '2-digit' }
  const now = nowTime.toLocaleDateString(undefined, dateOptions)

  // console.log('Plugform.js', props);
  // devLog('Plugform.js', props.plugnoteAnnexes);
  // devLog('Plugform.js # fields', props.fields);

  switch (props.mode) {
/************************************************************************************************************
CREATE / UPDATE
*************************************************************************************************************/
    case "create":
    case "update":
    case "modal-plugnote-create":
      return (
        <div className={`${classes.root}`}>
          <div className={`${classes.formContainer}`}>
            <div className={classes.pageInfos} ref={topRef}>{props.environment.name} - {now} - {props.mode} - </div>
            <PlugnoteHeader
              environment={props.environment}
              external={props.external}
              handleExitPlugnote={handleExitPlugnote}
              handleOpenDataOrigin={() => handleOpenDataOrigin(props.plugnote.dataHashOrigin)}
              handleSafeExitPlugnote={handleSafeExitPlugnote}
              handleSaveCreatePlugnote={props.handleSaveCreatePlugnote}
              handleSavePlugnote={handleSavePlugnote}
              handleOpenSave={props.handleOpenSave}
              handleToggleBanner={handleToggleBanner}
              languageSelector={
                lngOptions.length > 0 &&
                  <div className={classes.langCtn}>
                    <Select
                      fullWidth
                      id="demo-simple-select"
                      isClearable
                      labelId="demo-simple-select-label"
                      placeholder={t('common:models.list-translation')}
                      onChange={(value) => handleChangeLanguage(value)}
                      onFocus={() => { if (props.list?.length < 1) setLoading(true) }}
                      options={lngOptions}
                      styles={selectStyle}
                      value={lang}
                    />
                  </div>
              }
              mode={props.mode}
              model={props.model}
              nameModel={nameModel}
              openBanner={openBanner}
              pnCode={pnCode}
              pfCode={pfCode}
              pfKey={pfKey}
              pfComment={pfComment}
              pnComment={pnComment}
              plugKey={pnKey}
              plugnote={props.plugnote}
              plugnoteAnnexes={props.plugnoteAnnexes}
            />
            <AttachmentBanner
              annexes={props.plugnoteAnnexes}
              disabled={props.disabled}
              handleCloseModalListAnnexes={props._handleCloseModalListAnnexes}
              handleCreateNote={props.handleCreateNote}
              handleDeleteAnnexe={props.handleDeleteAnnexe}
              downloadFile={props.downloadEmployeeData}
              handleNoteClose={props.handleNoteClose}
              handleNoteOpen={props.handleNoteOpen}
              handleOpenGallery={props._handleOpenModalListAnnexes}
              handleReorderAnnexes={props.handleReorderAnnexes}
              handleSaveComment={props.handleSaveFictifComment}
              handleToggleBanner={() => setOpenBanner(current => !current)}
              handleSaveNote={props.handleSaveNote}
              getAnnexeUrl={annexe => getAnnexeUrl(annexe, props.environment, props.plugnote)}
              mode={props.mode}
              onChangeHandlerDrop={props.onChangeHandlerDrop}
              open={openBanner}
              plugnote={{ title: props.nameModel }}
              renderAnnexeIconPreview={props.renderAnnexeIconPreview}
              renderAnnexeImagePreview={props.renderAnnexeImagePreview}
              setFileId={setFileId}
            />
            <div className={classes.contentFields}>
              <Content className={`${classes.contentFieldModel} ${classes.fieldBorder} scrollbar-hidden`} >
                {
                  typeof fields !== "undefined" && fields.length > 0 &&
                  fields.map((field, index) => {
                    {/* console.log('mappage fields create & modal', field) */}
                    let visible;
                    switch (props.mode) {
                      case "modal-plugnote-create":
                        visible = fieldIsVisible(field, props)
                        break;
                      case "update":
                        visible = fieldIsVisible(field, props)
                        break;
                      default:
                        visible = true
                        break;
                    }
                    let fieldNb = index + 1
                    if (visible) {
                      return (
                        <div className="flexbox-container" key={'field-' + fieldNb}>
                          <div className={`${classes.formatContainer}`}>
                            <div className={classes.fieldIconCtn} >
                              <span className={`${classes.fieldIndex}`} style={props.mode?.includes('modal') ? { marginLeft: 5 } : undefined}>({fieldNb})</span>
                              {
                                field.visuelFormat.toLowerCase() === "now" ?
                                  <div className={field.mandatory ? classes.fieldIconMandatory : classes.fieldIcon}>
                                    <img className={classes.fieldImage} src={Images[camelCase(field.visuelFormat.toLowerCase()) + "Icon"]} alt={t("fields.format-choice-" + field.visuelFormat.toLowerCase())} />
                                  </div>
                                  :
                                  <img className={classes.fieldImageSvg} src={field.mandatory ? Images[camelCase(field.visuelFormat.toLowerCase()) + "IconSvgRed"] : Images[camelCase(field.visuelFormat.toLowerCase()) + "IconSvg"]} alt={t("fields.format-choice-" + field.visuelFormat.toLowerCase())} />
                              }

                              <FieldVisibilityIndicator field={field}  mode={props.mode} />
                            </div>

                              <div className={classes.formatContent}>
                              <div className={classes.formatHeader}>
                                <PlugTooltip
                                  title={field.hasOwnProperty('infobulle') ? infobulles['field' + field.id] : ''}
                                  classes={{ tooltip: field.hasOwnProperty('infobulle') ? classes.infobulle : classes.noTooltip}} placement="bottom"
                                >
                                  <div className={classes.numTitleFormat}>
                                    <span className={classes.formatTitleCtn}>
                                      <div className={classes.separatorHeaderFormat} />
                                      <span className={classes.titleHeaderFormat}>
                                        {field.titleTranslate}
                                      </span>
                                    </span>
                                    {
                                      field.hasOwnProperty('infobulle') &&
                                      <span
                                        id="icon-file_"
                                        className="material-icons"
                                        style={{ color: colors.mainButton, fontSize: 25 }}
                                      >
                                        info
                                      </span>
                                    }
                                  </div>
                                </PlugTooltip>

                                {!specialFields.includes(field.format) && field.format === "sign" &&
                                  <div className={classes.formatInputFieldInlineLocked}>
                                    <FormatSignComponent
                                      handleEditValue={props.handleEditValue}
                                      field={field}
                                      index={index}
                                      disabled={props.disabled || field.disabled}
                                      model={props?.model || null}
                                      plugnote={props?.plugnote || null}
                                      annexes={props.plugnoteAnnexes.length > 0 ? props.plugnoteAnnexes : []}
                                      fields={fields}
                                      onChangeHandlerDrop={props.onChangeHandlerDrop}
                                    />
                                  </div>
                                }

                                {!specialFields.includes(field.format) &&
                                  field.format === "boolean" &&
                                  <div className={(props.disabled === false || field.disabled === false) ? classes.formatInlineNoBorder : classes.formatInlineNoBorderLocked}>
                                    <FormatBooleanComponent
                                      handleChangeBoolean={props.handleChangeBoolean}
                                      field={field}
                                      index={index}
                                      disabled={props.disabled || field.disabled}
                                    />
                                  </div>
                                }

                                {(field.format === "phone" ||
                                  field.format === "list" ||
                                  field.format === "mail" ||
                                  field.format === "smail" ||
                                  field.format === "url" ||
                                  field.format === "localis" ||
                                  field.format === "textarea" ||
                                  field.format === "indice" ||
                                  field.format === "date" ||
                                  field.format === "agenda" ||
                                  field.format === "now" ||
                                  field.format === "birthday" ||
                                  field.format === "formula" ||
                                  field.format === "time" ||
                                  field.format === "extdata" ||
                                  field.format === "extdatan" ||
                                  field.format === "numeric" ||
                                  field.format === "password" ||
                                        field.format === "text" ||
                                  (field.format.includes("table") && field.format.includes("single") && !field.format.includes("tablemulti")) ||
                                  (field.format.includes("table") && field.format.includes("multi") && !field.format.includes("tablemulti")) ||
                                  (field.format.includes("select") && field.format.includes("single") && !field.format.includes("tablemulti")) ||
                                  (field.format.includes("select") && field.format.includes("multi") && !field.format.includes("tablemulti"))) &&
                                  <div className={props.disabled === false ? classes.formatInputFieldInline : classes.formatInputFieldInlineLocked}>
                                    {specialFields.includes(field.format) && field.format === "textarea" &&
                                      <FormatMemoComponent
                                        handleEditValue={props.handleEditValue}
                                        field={field}
                                        index={index}
                                        disabled={props.disabled || field.disabled || field?.options?.setup?.editable === false}
                                      />}

                                    {!specialFields.includes(field.format) &&
                                      field.format === "indice" &&
                                      <FormatIndiceComponent
                                        handleEditValue={props.handleEditValue}
                                        field={field}
                                        fields={fields}
                                        index={index}
                                        disabled={props.disabled || field.disabled}
                                        control={props.mode != 'modal-plugnote-create' ? props.plugnote === null ? props.handleCreatePlugnoteIndice : null : null}
                                        shared={false}
                                      />}

                                    {!specialFields.includes(field.format) &&
                                      field.format === "formula" && (
                                        <FormatFormulaComponent
                                          field={field}
                                          index={index}
                                          handleEditValue={props.handleEditValue}
                                          disabled={props.disabled || field.disabled}
                                          model={props.model}
                                          fields={fields}
                                          dataHasChanged={props.dataHasChanged}
                                        />
                                      )}

                                    {specialFields.includes(field.format) &&
                                      field.format.includes("table") &&
                                      field.format.includes("multi") &&
                                      !field.format.includes("tablemulti") && (
                                        <FormatMultipleSelectTableComponent
                                          handleChangeRadio={
                                            props.handleChangeRadioTable
                                          }
                                          handleChangeInputTable={
                                            props.handleChangeInputTable
                                          }
                                          handleUpdateInputTable={
                                            props.handleUpdateInputTable
                                          }
                                          handleDeleteInputTable={
                                            props.handleDeleteInputTable
                                          }
                                          field={field}
                                          index={index}
                                          disabled={props.disabled || field.disabled}
                                          expand={false}
                                        />
                                      )}

                                    {specialFields.includes(field.format) &&
                                      field.format.includes("table") &&
                                      field.format.includes("single") && (
                                        <FormatSingleSelectTableComponent
                                          handleChangeRadio={
                                            props.handleChangeRadioTable
                                          }
                                          handleChangeInputTable={
                                            props.handleChangeInputTable
                                          }
                                          handleUpdateInputTable={
                                            props.handleUpdateInputTable
                                          }
                                          handleDeleteInputTable={
                                            props.handleDeleteInputTable
                                          }
                                          field={field}
                                          index={index}
                                          disabled={props.disabled || field.disabled}
                                          expand={false}
                                        />
                                      )}

                                    {specialFields.includes(field.format) &&
                                      field.format.includes("select") &&
                                      field.format.includes("multi") &&
                                      !field.format.includes("tablemulti") && (
                                        <FormatMultipleSelectComponent
                                          handleChangeRadio={
                                            props.handleChangeRadio
                                          }
                                          field={field}
                                          index={index}
                                          disabled={props.disabled || field.disabled}
                                          expand={false}
                                        />
                                      )}

                                    {specialFields.includes(field.format) &&
                                      field.format === "list" && (
                                        <FormatKeywordComponent
                                          handleChangeKeyword={
                                            props.handleChangeKeyword
                                          }
                                          field={field}
                                          index={index}
                                          disabled={props.disabled || field.disabled}
                                        />
                                      )}

                                    {specialFields.includes(field.format) &&
                                      field.format.includes("select") &&
                                      field.format.includes("single") && (
                                        <FormatSingleSelectComponent
                                          handleChangeRadio={
                                            props.handleChangeRadio
                                          }
                                          field={field}
                                          index={index}
                                          disabled={props.disabled || field.disabled}
                                          expand={false}
                                        />
                                      )}
                                    {!specialFields.includes(field.format) &&
                                      field.format === "phone" && (
                                        <FormatPhoneComponent
                                          field={field}
                                          index={index}
                                          handleEditPhoneValue={
                                            props.handleEditPhoneValue
                                          }
                                          disabled={props.disabled || field.disabled}
                                        />
                                      )}

                                    {!specialFields.includes(field.format) &&
                                      field.format === "mail" && (
                                        <FormatMailComponent
                                          field={field}
                                          index={index}
                                          handleEditValue={props.handleEditValue}
                                          disabled={props.disabled || field.disabled}
                                        />
                                      )}

                                {
                                  !specialFields.includes(field.format) && field.format === "smail" &&
                                  <FormatSMailComponent
                                    field={field}
                                    fields={fields}
                                    index={index}
                                    handleEditValue={props.handleEditValue}
                                    disabled={
                                      props.disabled || field.disabled ||
                                      field?.options?.setup?.editable === false}
                                    mode={props.mode}
                                    model={props?.model || null}
                                    plugnote={props?.plugnote || null}
                                    annexes={props.plugnoteAnnexes.length > 0 ? props.plugnoteAnnexes : []}
                                    renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                    owner={props.hashOwnerModel}
                                  />
                                }

                                    {!specialFields.includes(field.format) &&
                                      field.format === "url" && (
                                        <FormatUrlComponent
                                          field={field}
                                          index={index}
                                          handleEditValue={props.handleEditValue}
                                          disabled={props.disabled || field.disabled || field?.options?.setup?.editable === false}
                                        />
                                      )}

                                    {specialFields.includes(field.format) &&
                                      field.format === "localis" && (
                                        <FormatLocalisationComponent
                                          handleChangeLocalisation={
                                            props.handleChangeLocalisation
                                          }
                                          field={field}
                                          index={index}
                                          disabled={props.disabled || field.disabled}
                                        />
                                      )}

                                    {!specialFields.includes(field.format) &&
                                      field.format === "date" && (
                                        <div className={classes.fieldRight}>
                                          {/*<FormatDateComponent
                                            field={field}
                                            handleEditDateTime={props.handleEditDateTime
                                            }
                                            index={index}
                                            disabled={props.disabled || field.disabled}
                                          />*/}
                                          <div className={classes.fieldRight}>
                                            <FormatTestDateComponent
                                                field={field}
                                                handleEditDateTime={
                                                  props.handleEditDateTime
                                                }
                                                index={index}
                                                disabled={props.disabled || field.disabled}
                                            />
                                          </div>
                                        </div>
                                      )}
                                    {!specialFields.includes(field.format) &&
                                      field.format === "agenda" && (
                                        <div className={classes.fieldRight}>
                                          <FormatAgendaComponent
                                            field={field}
                                            handleEditDateTime={props.handleEditDateTime
                                            }
                                            index={index}
                                            disabled={props.disabled || field.disabled}
                                          />
                                        </div>
                                      )}

                                    {!specialFields.includes(field.format) &&
                                      field.format === "now" && (
                                        <div className={classes.fieldRight}>
                                          <FormatNowComponent
                                            field={field}
                                            handleEditDateTime={props.handleEditDateTime
                                            }
                                            index={index}
                                            disabled={props.disabled || field.disabled}
                                          />
                                        </div>
                                      )}

                                    {!specialFields.includes(field.format) &&
                                      field.format === "birthday" && (
                                        <div className={classes.fieldRight}>
                                          <FormatBirthdayComponent
                                            field={field}
                                            handleEditDateTime={props.handleEditDateTime
                                            }
                                            index={index}
                                            disabled={props.disabled || field.disabled}
                                          />
                                        </div>
                                      )}

                                    {!specialFields.includes(field.format) &&
                                      field.format === "time" && (
                                        <div className={classes.fieldRight}>
                                          <FormatTimeComponent
                                            field={field}
                                            handleEditDateTime={props.handleEditDateTime
                                            }
                                            index={index}
                                            disabled={props.disabled || field.disabled}
                                          />
                                        </div>
                                      )}

                                    {!specialFields.includes(field.format) &&
                                      field.format === "numeric" && (
                                        /*<TextField
                                          onChange={(event) =>
                                            props.handleEditValue(
                                              event.target.value === '' ? null : event.target.value,
                                              field,
                                              index
                                            )
                                          }
                                          id="standard-full-width-num"
                                          style={{ margin: 0 }}
                                          autoComplete="off"
                                          type="number"
                                          onWheel={(event) => event.target.blur()}
                                          placeholder={t("fields.format-choice-placeholder-" + field.visuelFormat.toLowerCase())}
                                          onFocus={(e) => e.target.placeholder = ''}
                                          onBlur={(e) => e.target.placeholder = t("fields.format-choice-placeholder-" + field.visuelFormat.toLowerCase())}
                                          helperText=""
                                          fullWidth
                                          margin="normal"
                                          InputLabelProps={{ shrink: true }}
                                          inputProps={{ step: "0.1" }}
                                          className={classes.fieldRight}
                                          value={isNaN(field.value) ? null : field.value}
                                          InputProps={{ disableUnderline: true }}
                                          disabled={props.disabled || field.disabled}
                                        />*/

                                            <FormatNumericComponent
                                                field={field}
                                                index={index}
                                                handleEditValue={props.handleEditValue}
                                                disabled={props.disabled || field.disabled}

                                            />
                                      )}
                                    {!specialFields.includes(field.format) &&
                                      field.format === "password" && (
                                        <FormatPasswordComponent
                                          handleSavePassword={props.handleSavePassword}
                                          handleChangePassword={
                                            props.handleChangePassword
                                          }
                                          field={field}
                                          index={index}
                                          disabled={props.disabled || field.disabled}
                                        />
                                      )}
                                    {specialFields.includes(field.format) && field.format === "extdata" && (
                                      <FormatExtDataComponent
                                        hideSearch={false}
                                        ownerId={props.model.owner}
                                        fields={fields}
                                        handleChangeExtData={props.handleChangeExtData}
                                        handleEditValue={props.handleEditValue}
                                        field={field}
                                        index={index}
                                        disabled={props.disabled || field.disabled}
                                        owner={props.hashOwnerModel}
                                        title={field.titleTranslate}
                                      />
                                    )}
                                    {specialFields.includes(field.format) && field.format === "extdatan" && (
                                      <FormatExtDatanComponent
                                        hideSearch={false}
                                        ownerId={props.model.owner}
                                        fields={fields}
                                        handleChangeExtDatan={props.handleChangeExtDatan}
                                        handleEditValue={props.handleEditValue}
                                        field={field}
                                        index={index}
                                        disabled={props.disabled || field.disabled}
                                        owner={props.hashOwnerModel}
                                        title={field.titleTranslate}
                                      />
                                    )}

                                    {!specialFields.includes(field.format) && field.format === "text" &&
                                        //<div className={`${props.disabled === false ? classes.formatInputFieldInline : classes.formatInputFieldInlineLocked} ${classes.formatText}`}>
                                        <>
                                          <FormatTextComponent
                                              field={field}
                                              index={index}
                                              handleEditValue={(value) => {props.handleEditValue(value,field,index)}}
                                              style={{ margin: 0 }}
                                              placeholder={t("fields.format-choice-placeholder-" + field.visuelFormat.toLowerCase())}
                                              helperText={props.helperText}
                                              fullWidth
                                              margin="normal"
                                              InputLabelProps={{
                                                shrink: true,
                                              }}
                                              inputProps={{ maxLength: 40 }}
                                              value={field.value}
                                              InputProps={{
                                                disableUnderline: true,
                                              }}
                                              disabled={props.disabled || field.disabled || field?.options?.setup?.editable === false}
                                          />
                                          {/*<TextField
                                        onChange={(event) => props.handleEditValue(event.target.value,field,index)}
                                        style={{ margin: 0 }}
                                        placeholder={t("fields.format-choice-placeholder-" + field.visuelFormat.toLowerCase())}
                                        onBlur={(e) => e.target.placeholder = t("fields.format-choice-placeholder-" + field.visuelFormat.toLowerCase())}
                                        onFocus={(e) => e.target.placeholder = ''}
                                        helperText=""
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        inputProps={{ maxLength: 40 }}
                                        value={field.value}
                                        InputProps={{
                                          disableUnderline: true,
                                        }}
                                        disabled={props.disabled || field.disabled || field?.options?.setup?.editable === false}
                                      />*/}
                                          {(field.disabled === false) ? null :  field.value?.length > 29 ? <span className={classes.memoCount}>{field.value.length + ' / 40'}</span> : null}
                                        </>

                                      //</div>
                                    }

                                  </div>
                                }

                                {/* {props.disabled === false ? null : (
                                      <span
                                          className={`${classes.lockedColor} material-icons md-15`}
                                      >
                                  lock
                                  </span>)} */}
                                {field.format === "pluglink" ||
                                  field.format === "glocalis" ||
                                  field.format === "plugform" ||
                                  field.format === "intdatamaj" ||
                                  field.format === "document" ||
                                  field.format === "qrcode" ||
                                  field.format === "qrcodeint" ? (
                                  <div className={props.disabled === false ? classes.formatInlineNoBorder : classes.formatInlineNoBorderLocked}>
                                    {!specialFields.includes(field.format) &&
                                      field.format === "pluglink" && (
                                        <FormatPlugLinkComponent
                                          mode={props.mode}
                                          field={field}
                                          index={index}
                                          handleEditValue={props.handleEditValue}
                                          disabled={props.disabled || field.disabled}
                                          control={props.mode != 'modal-plugnote-create' ? props.plugnote === null ? props.handleCreatePlugnote : props.handleSavePlugnote : null}
                                          ownerHash={props.model.hash}
                                            dataHash={props?.plugnote?.hash}
                                        />
                                      )}

                                    {
                                      specialFields.includes(field.format) && (field.format === "qrcode") &&
                                      <div
                                        className={
                                          props.disabled === false
                                            ? classes.formatInputField
                                            : classes.formatInputFieldLocked
                                        }
                                      >
                                        <FormatPlugcodeComponent
                                          handleChangePlugcode={props.handleChangePlugcode}
                                          field={field}
                                          index={index}
                                          disabled={props.disabled || field.disabled}
                                          mode={props.mode}
                                          owner={props.hashOwnerModel}
                                        />
                                      </div>
                                    }

                                    {specialFields.includes(field.format) &&
                                      (field.format === "qrcodeint") && (
                                        <div
                                          className={
                                            props.disabled === false
                                              ? classes.formatInputField
                                              : classes.formatInputFieldLocked
                                          }
                                        >
                                          <FormatPlugcodeInternalComponent
                                            handleChangePlugcode={props.handleChangePlugcodeInternal}
                                            field={field}
                                            fields={fields}
                                            index={index}
                                            disabled={props.disabled || field.disabled}
                                            mode={props.mode}
                                            owner={props.hashOwnerModel}
                                          />
                                        </div>
                                      )}

                                    {specialFields.includes(field.format) && field.format === "plugform" &&
                                      <FormatPlugformComponent
                                        handleChangePlugform={props.handleChangePlugform}
                                        field={field}
                                        index={index}
                                        disabled={props.disabled || field.disabled}
                                        owner={props.hashOwnerModel}
                                        mode={props.mode}
                                      />
                                    }

                                    {specialFields.includes(field.format) && field.format === "intdatamaj" &&
                                        <FormatIntDataMajComponent
                                            handleChangeIntDataMaj={props.handleChangeIntDataMaj}
                                            field={field}
                                            index={index}
                                            disabled={props.disabled || field.disabled}
                                            owner={props.hashOwnerModel}
                                            mode={props.mode}
                                            dataHash={props?.plugnote?.hash}
                                            control={props.mode !== 'modal-plugnote-create'}
                                            controlUpdate={props.handleSavePlugnote}
                                            controlCreate={props.handleCreatePlugnote}
                                        />
                                    }

                                    {specialFields.includes(field.format) &&
                                      field.format === "glocalis" && (
                                        <div
                                          className={
                                            props.disabled === false
                                              ? classes.formatInputField
                                              : classes.formatInputFieldLocked
                                          }
                                        >
                                          <FormatGeoLocalisationComponent
                                            handleChangeLocalisation={props.handleChangeLocalisation
                                            }
                                            field={field}
                                            index={index}
                                            disabled={props.disabled || field.disabled}
                                          />
                                        </div>
                                      )}
                                  </div>
                                ) : null}
                              </div>

                                {
                                  specialFields.includes(field.format) && field.format === "tablemulti" &&
                                      <FormatTableMultipleComponent
                                        handleChangeTableValue={props.handleChangeTableValue}
                                        handleSafeExitPlugnote={handleSafeExitPlugnote}
                                        hash={props.plugnote?.hash}
                                        field={field}
                                        index={index}
                                        disabled={props.disabled}
                                        mode={props.mode}
                                        owner={props.hashOwnerModel}
                                        title={field.titleTranslate}
                                        ownerId={props.model.owner}
                                        model={props.model}
                                        plugNb={props.plugnote?.userindex}
                                        dataHash={props?.plugnote?.hash}
                                        control={props.mode !== 'modal-plugnote-create'}
                                        controlUpdate={props.handleSavePlugnote}
                                        controlCreate={props.handleCreatePlugnote}
                                      />
                                }
                              {
                                field.format === "tablemultin" && specialFields.includes(field.format) &&
                                <FormatTableMultiplenComponent
                                  handleChangeTableValue={props.handleChangeTableRowsValue}
                                  handleSafeExitPlugnote={handleSafeExitPlugnote}
                                  hash={props.plugnote?.hash}
                                  field={field}
                                  index={index}
                                  fields={fields}
                                  disabled={props.disabled || field.disabled}
                                  mode={props.mode}
                                  model={props.model}
                                  owner={props.hashOwnerModel}
                                  title={field.titleTranslate}
                                  ownerId={props.model.owner}
                                  plugNb={props.plugnote?.userindex}
                                  handleChangeExtData={props.handleChangeExtData}
                                  dataHash={props?.plugnote?.hash}
                                  control={props.mode !== 'modal-plugnote-create'}
                                  controlUpdate={props.handleSavePlugnote}
                                  controlCreate={props.handleCreatePlugnote}
                                />
                              }

                                {
                                  specialFields.includes(field.format) && field.format === "smailvalidmulti" &&
                                  <div className={classes.formatInputFieldComposed}>
                                    <FormatSMailValidMultiComponent
                                        mode={props.mode}
                                        hideSearch={false}
                                        ownerId={props.model.owner}
                                        handleChangeTableValue={props.handleChangeTableRowsValue}
                                        handleEditValue={props.handleEditValue}
                                        handleSafeExitPlugnote={handleSafeExitPlugnote}
                                        field={field}
                                        fields={fields}
                                        index={index}
                                        disabled={props.disabled || field.disabled}
                                        owner={props.hashOwnerModel}
                                        title={field.titleTranslate}
                                        model={props?.model || null}
                                        plugNb={props.plugnote?.userindex}
                                        plugnote={props?.plugnote || null}
                                        annexes={props.plugnoteAnnexes.length > 0 ? props.plugnoteAnnexes : []}
                                        renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                        control={props.mode !== 'modal-plugnote-create'}
                                        controlUpdate={props.handleSavePlugnote}
                                        controlCreate={props.handleCreatePlugnote}
                                    />
                                  </div>
                                }

                              {specialFields.includes(field.format) && field.format === "document" &&
                                <div className={classes.formatInputFieldComposed}>
                                  <FormatDocumentComponent
                                    mode={props.mode}
                                    hideSearch={false}
                                    ownerId={props.model.owner}
                                    handleChangeTableValue={props.handleChangeTableRowsValue}
                                    handleEditValue={props.handleEditValue}
                                    handleSafeExitPlugnote={handleSafeExitPlugnote}
                                    handleSaveComment={props.handleSaveFictifComment}
                                    field={field}
                                    fields={fields}
                                    index={index}
                                    disabled={props.disabled || field.disabled}
                                    owner={props.hashOwnerModel}
                                    title={field.titleTranslate}
                                    model={props?.model || null}
                                    plugNb={props.plugnote?.userindex}
                                    plugnote={props?.plugnote || null}
                                    annexes={props.plugnoteAnnexes.length > 0 ? props.plugnoteAnnexes : []}
                                    renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                    control={props.mode !== 'modal-plugnote-create'}
                                    controlUpdate={props.handleSavePlugnote}
                                    controlCreate={props.handleCreatePlugnote}
                                  />
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      );
                    }
                    else return null;
                  })}
              </Content>

              {/* {
                !ApplicationConfig.devMode &&
                <Content
                  className={`${classes.attField} ${classes.fieldBorder} scrollbar-hidden`}
                  >
                  {props.disabled === false &&
                  <div
                    className={`${classes.fieldTitle} ${classes.attachmentTitle} cursor icon-style`}
                    onClick={(event) => props.handleCreateNote(event)}
                    >
                    {t("common:plugnote.add-attachment-note")}
                    <div>
                      <MdNoteAdd style={{ fontSize: 20, color: "black" }} />
                    </div>
                  </div>
                  }

                  {props.disabled === false &&
                  <div
                    className={`${classes.fieldTitle} ${classes.attachmentTitle}`}
                    >
                    {t("multiple.add-attachments")}
                  </div>
                  }
                  <Dropzone onDrop={(acceptedFiles) => handleDropdownFiles(acceptedFiles)} >
                    {({ getRootProps, getInputProps }) => (
                      <section className="">
                        {" "}
                        <div {...getRootProps()}>
                          {props.disabled === false &&
                          <input {...getInputProps()} />
                          }

                          {props.disabled === false &&
                          <div className={classes.attachmentBox}>
                            <span className={classes.mainColor} style={{ cursor: "pointer"}}>
                                {t("multiple.attachement-drag-and-drop")}
                            </span>
                          </div>
                          }
                          <div
                            className={`${classes.fieldTitle} ${classes.attachmentTitle}`}
                            >
                            {t("multiple.current-attachments")}{" "}
                          </div>

                            {
                              props.plugnoteAnnexes.length > 0 ?
                                <div className={classes.attContainer}>
                                  {
                                    props.plugnoteAnnexes.map((annexe, index) => {
                                      // console.log('annexe ', index, ': ', annexe);
                                      return (
                                        <div
                                          className={classes.itemContainer}
                                          key={index + 1}
                                          onClick={(event) => {
                                            event.preventDefault();
                                            event.stopPropagation();
                                          }}
                                        >
                                          <div className={classes.attItem}>
                                            <div
                                              className={classes.attIconType}
                                              onClick={(event) => {
                                                props._handleOpenModalListAnnexes();
                                                setFileId(index);
                                              }}
                                            >
                                              {index + 1} {props.renderAnnexeIconPreview(annexe)}
                                            </div>
                                            <div
                                              className={classes.attAnnexName}
                                              onClick={(event) => {
                                                props._handleOpenModalListAnnexes();
                                                setFileId(index);
                                              }}
                                            >
                                              {annexe.comment !== undefined && annexe.comment !== "" ?
                                                annexe.comment : annexe.name}
                                            </div>

                                            <div className={classes.attIconDlInfo}>
                                              {props.mode != "modal-plugnote-create" && typeof props.isAuthorisedToEdit !== "undefined" && props.isAuthorisedToEdit(annexe.type) && (
                                                <span
                                                  id="icon-file_"
                                                  className="material-icons"
                                                  onClick={(event) => props.handleUpdateAnnexe(event, annexe)}
                                                  style={{ color: colors.black }}
                                                >
                                                  publish
                                                </span>
                                              )}
                                              {annexe.type !== "note" &&
                                                <span
                                                  id="icon-file_copy"
                                                  className="material-icons"
                                                  onClick={(event) => props.downloadEmployeeData(event, annexe)}
                                                  style={{ color: colors.black }}
                                                >
                                                  get_app
                                                </span>
                                              }

                                              {annexe.type === "note" &&
                                                <span
                                                  id="icon-file_copy"
                                                  className="material-icons md-25"
                                                  onClick={(event) => props.handleNoteOpen(event, annexe)}
                                                  style={{ color: colors.black }}
                                                >
                                                  create
                                                </span>
                                              }

                                              <div className="">
                                                {annexe.groupid !== 0 ?
                                                  <span>{annexe.groupid}</span>
                                                  :
                                                  <span />
                                                }
                                              </div>

                                              {props.disabled === false &&
                                                <span
                                                  id="icon-file_"
                                                  className="material-icons"
                                                  onClick={(event) => props.handleDeleteAnnexe(event, annexe)}
                                                  style={{ color: colors.black }}
                                                >
                                                  delete
                                                </span>
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  <div style={{ textAlign: "center" }}>
                                    * * *
                                  </div>
                                </div>
                                :
                                <span>{t("multiple.no-attachments")}</span>
                            }
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  {props.plugnoteAnnexes.length > 0 && props.isOpenModalListAnnexes &&
                  <ModalTestAnnexeGallery
                    mode={props.mode}
                    locked={props.disabled}
                    open={props.isOpenModalListAnnexes}
                    close={props._handleCloseModalListAnnexes}
                    annexes={props.plugnoteAnnexes}
                    isAuthorisedToEdit={props.isAuthorisedToEdit}
                    downloadEmployeeData={props.downloadEmployeeData}
                    handleUpdateAnnexe={props.handleUpdateAnnexe}
                    handleModalAnnexeOpen={props.handleModalAnnexeOpen}
                    handleNoteOpen={props.handleNoteOpen}
                    handleDeleteAnnexe={props.handleDeleteAnnexe}
                    handleModalListAnnexesVersionOpen={props.handleModalListAnnexesVersionOpen}
                    handlePreviewAnnexe={props.handlePreviewAnnexe}
                    handlePreviewPdfAnnexe={props.handlePreviewPdfAnnexe}
                    fileId={fileId}
                    renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                    renderAnnexeImagePreview={props.renderAnnexeImagePreview}
                    isOpenModalAnnexe={props.isOpenModalAnnexe}
                    selectedAnnexe={props.selectedAnnexe}
                    handleModalAnnexeClose={props.handleModalAnnexeClose}
                    handleSaveFictifComment={props.handleSaveFictifComment}
                    />
                  }
                </Content>
              } */}
            </div>
          </div>

        {
          view &&
          <ModalViewFicheComponent
            mode={props.mode}
            open={view}
            dataHash={props?.plugnote?.dataHashOrigin}
            close={() => setView(false)}
          />
        }
        </div>
      );

/************************************************************************************************************
 SHARED-CREATE/UPDATE/VIEWS
  *************************************************************************************************************/
    case "shared-create":
    case "shared-update":
    case "shared-view":
      return (
      <div className={`${classes.root}`}>
        <div className={`${classes.formContainer}`}>
          <div className={classes.pageInfos}>{props.environment.name} - {now} - {props.mode} - </div>
          <PlugnoteHeader
              environment={props.environment}
              external={props.external}
              handleExitPlugnote={handleExitPlugnote}
              handleOpenDataOrigin={() => handleOpenDataOrigin(props.plugnote.dataHashOrigin)}
              handleSafeExitPlugnote={handleSafeExitPlugnote}
              handleSaveCreatePlugnote={props.handleSaveCreatePlugnote}
              handleSaveCreatePlugnoteWithoutRedirect={props.handleSaveCreatePlugnoteWithoutRedirect}
              handleSaveUpdatePlugnote={props.handleSaveUpdatePlugnote}
              handleSaveUpdatePlugnoteWithoutRedirect={props.handleSaveUpdatePlugnoteWithoutRedirect}
              handleSavePlugnote={handleSavePlugnote}
              handleOpenSave={props.handleOpenSave}
              handleToggleBanner={() => setOpenBanner(current => !current)}
              languageSelector={
                lngOptions.length > 0 &&
                  <div className={classes.langCtn}>
                    <Select
                      fullWidth
                      id="demo-simple-select"
                      isClearable
                      labelId="demo-simple-select-label"
                      placeholder={t('common:models.list-translation')}
                      onChange={(value) => handleChangeLanguage(value)}
                      onFocus={() => { if (props.list?.length < 1) setLoading(true) }}
                      options={lngOptions}
                      styles={selectStyle}
                      value={lang}
                    />
                  </div>
              }
              mode={props.mode}
              model={props.model}
              nameModel={nameModel}
              openBanner={openBanner}
              pnCode={pnCode}
              pfCode={pfCode}
              pfKey={pfKey}
              pfComment={pfComment}
              pnComment={pnComment}
              plugKey={pnKey}
              plugnote={props.plugnote}
              plugnoteAnnexes={props.plugnoteAnnexes}
            />

            <AttachmentBanner
              annexes={props.plugnoteAnnexes}
              disabled={props.disabled}
              handleCloseModalListAnnexes={props._handleCloseModalListAnnexes}
              handleCreateNote={props.handleCreateNote}
              handleDeleteAnnexe={props.handleDeleteAnnexe}
              downloadFile={props.downloadEmployeeData}
              handleNoteClose={props.handleNoteClose}
              handleNoteOpen={props.handleNoteOpen}
              handleOpenGallery={props._handleOpenModalListAnnexes}
              handleReorderAnnexes={props.handleReorderAnnexes}
              handleSaveComment={props.handleSaveFictifComment}
              handleToggleBanner={() => setOpenBanner(current => !current)}
              handleSaveNote={props.handleSaveNote}
              getAnnexeUrl={annexe => getAnnexeUrl(annexe, props.environment, props.plugnote, props.model)}
              mode={props.mode}
              onChangeHandlerDrop={props.onChangeHandlerDrop}
              open={openBanner}
              plugnote={{ title: props.nameModel }}
              renderAnnexeIconPreview={props.renderAnnexeIconPreview}
              renderAnnexeImagePreview={props.renderAnnexeImagePreview}
              setFileId={setFileId}
            />

          <div className={classes.contentFields}>
            <Content className={`${classes.contentFieldModel} ${classes.fieldBorder} scrollbar-hidden`}>
              {
                fields.map((field, index) => {
                  {/* console.log('mappage fields shared', field) */}
                  let visible;
                  if (props.mode === "shared-update") {
                    let tmpValue = typeof field.value !== "undefined" ? field.value.toString() : "";
                    let length = field.format === "numeric" && tmpValue === "NaN" ? 0 : tmpValue.length;
                    if (typeof tmpValue === "string" && Object(tmpValue) && tmpValue !== null && field.format === "plugform"
                    ) {
                      let tmp = field.value;
                      if (tmp.hasOwnProperty("value")) {
                        length = tmp.value.length;
                      } else {
                        length = 0;
                      }
                    }
                    visible = ((props.visibleAll === false && length > 0) || props.visibleAll === true)
                  }
                  else {
                    visible = true
                  }

                  if(field.options.setup?.visible === false){
                    visible = false;
                  }



                  let fieldNb = index + 1



                  if (visible){
                    return (
                      <div className="flexbox-container" key={'field-' + fieldNb}>
                        <div className={`${classes.formatContainer}`}>
                          <div className={classes.fieldIconCtn} >
                          <span className={classes.fieldIndex}>({fieldNb})</span>
                          {field.visuelFormat.toLowerCase() === "now" ?
                            <div className={field.mandatory ? classes.fieldIconMandatory : classes.fieldIcon}>
                              <img
                                className={classes.fieldImage}
                                src={
                                  Images[camelCase(field.visuelFormat.toLowerCase()
                                  ) + "Icon"
                                  ]
                                }
                                alt={t(
                                  "fields.format-choice-" +
                                  field.visuelFormat.toLowerCase()
                                )}
                              />
                            </div>
                          : (
                              <img
                                className={classes.fieldImageSvg}
                                src={
                                  field.mandatory
                                    ? Images[camelCase(field.visuelFormat.toLowerCase()) + "IconSvgRed"]
                                    : Images[camelCase(field.visuelFormat.toLowerCase()) + "IconSvg"]
                                }
                                alt={t("fields.format-choice-" + field.visuelFormat.toLowerCase())}
                              />
                          )}
                          </div>

                          <div className={classes.formatContent}>
                            <div className={classes.formatHeader}>
                              <PlugTooltip
                                title={field.hasOwnProperty('infobulle') ? infobulles['field' + field.id] : ''}
                                classes={{ tooltip: field.hasOwnProperty('infobulle') ? classes.infobulle : classes.noTooltip }} placement="bottom"
                              >
                                <div className={classes.numTitleFormat}>
                                  <span className={classes.formatTitleCtn}>
                                    <div className={classes.separatorHeaderFormat} />
                                    <span className={classes.titleHeaderFormat}>
                                      {field.titleTranslate}
                                    </span>
                                  </span>
                                  <div>
                                    {
                                      field.hasOwnProperty('infobulle') &&
                                      <span
                                        id="icon-file_"
                                        className="material-icons"
                                        style={{ color: colors.mainButton, fontSize: 25 }}
                                      >
                                        info
                                      </span>
                                    }
                                  </div>
                                </div>
                              </PlugTooltip>

                              {!specialFields.includes(field.format) && field.format === "sign" && (
                                  <div className={classes.formatInputFieldInlineLocked}>
                                    <FormatSignComponent
                                        handleEditValue={props.handleEditValue}
                                        field={field}
                                        index={index}
                                        disabled={props.disabled || field.disabled || field.options.setup?.editable === false}
                                        model={props?.model || null}
                                        plugnote={props?.plugnote || null}
                                        annexes={props.plugnoteAnnexes.length > 0 ? props.plugnoteAnnexes : []}
                                        fields={fields}
                                        onChangeHandlerDrop={props.onChangeHandlerDrop}
                                    />
                                  </div>
                              )}

                              {!specialFields.includes(field.format) &&
                                field.format === "boolean" && (
                                  <div className={(props.disabled === false || field.disabled === false) ? classes.formatInlineNoBorder : classes.formatInlineNoBorderLocked}>
                                    <FormatBooleanComponent
                                      handleChangeBoolean={props.handleChangeBoolean}
                                      field={field}
                                      index={index}
                                      disabled={props.disabled || field.disabled || field.options.setup?.editable === false}
                                    />
                                  </div>
                              )}

                              {field.format === "pluglink" ||
                              field.format === "plugform" ||
                              field.format === "intdatamaj" ||
                              field.format === "qrcodeint" ||
                              field.format === "qrcodeint" ||
                              field.format === "qrcode" ? (
                                <div className={props.disabled === false ? classes.formatInlineNoBorder : classes.formatInlineNoBorderLocked}>

                                  {specialFields.includes(field.format) &&
                                  (field.format === "qrcode") && (
                                      <FormatPlugcodeComponent
                                          handleChangePlugcode={props.handleChangePlugcode
                                          }
                                          field={field}
                                          index={index}
                                          disabled={props.disabled || field.options.setup?.editable === false}
                                          mode={props.mode}
                                          owner={props.hashOwnerModel}
                                      />
                                  )}

                                  {specialFields.includes(field.format) &&
                                  (field.format === "qrcodeint") && (
                                      <FormatPlugcodeInternalComponent
                                          handleChangePlugcode={props.handleChangePlugcodeInternal
                                          }
                                          field={field}
                                          fields={fields}
                                          index={index}
                                          disabled={props.disabled  || field.options.setup?.editable === false}
                                          mode={props.mode}
                                          owner={props.hashOwnerModel}
                                      />
                                  )}

                                    {specialFields.includes(field.format) && field.format === "plugform" &&
                                        <FormatPlugformComponent
                                            handleChangePlugform={props.handleChangePlugform}
                                            field={field}
                                            index={index}
                                            disabled={props.disabled  || field.options.setup?.editable === false}
                                            owner={props.hashOwnerModel}
                                            mode={props.mode}
                                        />
                                    }

                                  {specialFields.includes(field.format) && field.format === "intdatamaj" &&
                                      <FormatIntDataMajComponent
                                          handleChangeIntDataMaj={props.handleChangeIntDataMaj}
                                          field={field}
                                          index={index}
                                          disabled={props.disabled || field.disabled  || field.options.setup?.editable === false}
                                          owner={props.hashOwnerModel}
                                          mode={props.mode}
                                          dataHash={props?.plugnote?.hash}
                                          control={props.mode !== 'shared-view'}
                                          controlUpdate={props.handleSaveSharedPlugnote}
                                          controlCreate={props.handleCreateSharedPlugnote}
                                      />
                                  }

                                  {!specialFields.includes(field.format) &&
                                  field.format === "pluglink" && (
                                      <FormatPlugLinkComponent
                                          mode={props.mode}
                                          field={field}
                                          index={index}
                                          handleEditValue={props.handleEditValue}
                                          disabled={props.disabled  || field.options.setup?.editable === false}
                                          ownerHash={props.model.hash}
                                          control={props.mode !== 'shared-view' ? props.plugnote === null ? props.handleCreateSharedPlugnote : props.handleSaveSharedPlugnote : null}
                                          dataHash={props?.plugnote?.hash}
                                      />
                                  )}

                                </div>) : null}

                              {
                                (field.format === "phone" ||
                                field.format === "mail" ||
                                field.format === "smail" ||
                                field.format === "url" ||
                                field.format === "localis" ||
                                field.format === "date" ||
                                field.format === "agenda" ||
                                field.format === "now" ||
                                field.format === "birthday" ||
                                field.format === "textarea" ||
                                field.format === "indice" ||
                                field.format === "time" ||
                                field.format === "formula" ||
                                field.format === "extdata" ||
                                field.format === "extdatan" ||
                                field.format === "list" ||
                                field.format === "numeric" ||
                                field.format === "password" ||
                                    field.format === "text" ||
                                (field.format.includes("table") && field.format.includes("single") && !field.format.includes("tablemulti")) ||
                                (field.format.includes("table") && field.format.includes("multi") && !field.format.includes("tablemulti")) ||
                                (field.format.includes("select") && field.format.includes("single") && !field.format.includes("tablemulti")) ||
                                (field.format.includes("select") && field.format.includes("multi") && !field.format.includes("tablemulti"))) &&

                              <div className={props.disabled === false ? classes.formatInputFieldInline : classes.formatInputFieldInlineLocked}>
                                {
                                  specialFields.includes(field.format) && field.format === "textarea" && (
                                  <FormatMemoComponent
                                    handleEditValue={props.handleEditValue}
                                    field={field}
                                    index={index}
                                    disabled={props.disabled || field.disabled || field?.options?.setup?.editable === false}
                                  />)}

                                {!specialFields.includes(field.format) &&
                                  field.format === "indice" && (
                                      <FormatIndiceComponent
                                          handleEditValue={props.handleEditValue}
                                          field={field}
                                          fields={fields}
                                          index={index}
                                          disabled={props.disabled || field.disabled || field.options.setup?.editable === false}
                                          control={props.mode != 'shared-view' ? props.plugnote === null ? props.handleCreateSharedPlugnoteIndice : null : null}
                                          shared={true}
                                      />)}

                                {specialFields.includes(field.format) &&
                                field.format.includes("table") &&
                                field.format.includes("multi") &&
                                !field.format.includes("tablemulti") && (
                                    <FormatMultipleSelectTableComponent
                                        handleChangeRadio={props.handleChangeRadioTable}
                                        handleChangeInputTable={props.handleChangeInputTable}
                                        handleUpdateInputTable={props.handleUpdateInputTable}
                                        handleDeleteInputTable={props.handleDeleteInputTable}
                                        field={field}
                                        index={index}
                                        disabled={props.disabled  || field.options.setup?.editable === false}
                                        expand={false}
                                        shared={true}
                                    />
                                )}

                                {specialFields.includes(field.format) &&
                                field.format.includes("table") &&
                                field.format.includes("single") && (
                                    <FormatSingleSelectTableComponent
                                        handleChangeRadio={props.handleChangeRadioTable}
                                        handleChangeInputTable={props.handleChangeInputTable}
                                        handleUpdateInputTable={props.handleUpdateInputTable}
                                        handleDeleteInputTable={props.handleDeleteInputTable}
                                        field={field}
                                        index={index}
                                        disabled={props.disabled  || field.options.setup?.editable === false}
                                        expand={false}
                                        shared={true}
                                    />
                                )}

                                {specialFields.includes(field.format) &&
                                field.format.includes("select") &&
                                field.format.includes("multi") &&
                                !field.format.includes("tablemulti") && (
                                    <FormatMultipleSelectComponent
                                        handleChangeRadio={props.handleChangeRadio}
                                        field={field}
                                        index={index}
                                        disabled={props.disabled  || field.options.setup?.editable === false}
                                        expand={false}
                                        shared={true}
                                    />
                                )}

                                {specialFields.includes(field.format) &&
                                field.format.includes("select") &&
                                field.format.includes("single") && (
                                    <FormatSingleSelectComponent
                                        handleChangeRadio={props.handleChangeRadio}
                                        field={field}
                                        index={index}
                                        disabled={props.disabled  || field.options.setup?.editable === false}
                                        expand={false}
                                        shared={true}
                                    />
                                )}

                                {specialFields.includes(field.format) &&
                                field.format === "list" && (
                                    <FormatKeywordComponent
                                        handleChangeKeyword={props.handleChangeKeyword}
                                        field={field}
                                        index={index}
                                        disabled={props.disabled  || field.options.setup?.editable === false}
                                    />
                                )}

                                {!specialFields.includes(field.format) &&
                                field.format === "phone" && (
                                    <FormatPhoneComponent
                                        field={field}
                                        index={index}
                                        handleEditPhoneValue={props.handleEditPhoneValue}
                                        disabled={props.disabled  || field.options.setup?.editable === false}
                                    />
                                )}

                                {!specialFields.includes(field.format) &&
                                field.format === "mail" && (
                                    <FormatMailComponent
                                        field={field}
                                        index={index}
                                        handleEditValue={props.handleEditValue}
                                        disabled={props.disabled  || field.options.setup?.editable === false}
                                    />
                                )}

                              {!specialFields.includes(field.format) &&
                              field.format === "smail" && (
                                  <FormatSMailComponent
                                      field={field}
                                      fields={fields}
                                      index={index}
                                      handleEditValue={props.handleEditValue}
                                      disabled={props.disabled || field.disabled || field?.options?.setup?.editable === false}
                                      mode={props.mode}
                                      model={props?.model || null}
                                      ownerHash={props?.model?.hash || null}
                                      plugnote={props?.plugnote || null}
                                      annexes={props.plugnoteAnnexes.length > 0 ? props.plugnoteAnnexes : []}
                                      renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                      owner={props.hashOwnerModel}
                                  />
                              )}

                                {!specialFields.includes(field.format) &&
                                field.format === "url" && (
                                    <FormatUrlComponent
                                        field={field}
                                        index={index}
                                        handleEditValue={props.handleEditValue}
                                        disabled={props.disabled || field.disabled || field?.options?.setup?.editable === false}
                                    />
                                )}

                                {specialFields.includes(field.format) &&
                                field.format === "localis" && (
                                    <FormatLocalisationComponent
                                        handleChangeLocalisation={
                                          props.handleChangeLocalisation
                                        }
                                        field={field}
                                        index={index}
                                        disabled={props.disabled  || field.options.setup?.editable === false}
                                    />
                                )}

                                {!specialFields.includes(field.format) &&
                                field.format === "date" && (
  /*                                  <div className={classes.fieldRight}>
                                      <FormatDateComponent
                                          field={field}
                                          handleEditDateTime={
                                            props.handleEditDateTime
                                          }
                                          index={index}
                                          disabled={props.disabled  || field.options.setup?.editable === false}
                                      />
                                    </div>*/
                                  <div className={classes.fieldRight}>
                                    <FormatTestDateComponent
                                        field={field}
                                        handleEditDateTime={
                                          props.handleEditDateTime
                                        }
                                        index={index}
                                        disabled={props.disabled}
                                    />
                                  </div>
                                )}
                                {!specialFields.includes(field.format) &&
                                field.format === "agenda" && (
                                    <div className={classes.fieldRight}>
                                      <FormatAgendaComponent
                                          field={field}
                                          handleEditDateTime={
                                            props.handleEditDateTime
                                          }
                                          index={index}
                                          disabled={props.disabled  || field.options.setup?.editable === false}
                                      />
                                    </div>
                                )}

                                {!specialFields.includes(field.format) &&
                                field.format === "now" && (
                                    <div className={classes.fieldRight}>
                                      <FormatNowComponent
                                          field={field}
                                          handleEditDateTime={
                                            props.handleEditDateTime
                                          }
                                          index={index}
                                          disabled={props.disabled  || field.options.setup?.editable === false}
                                      />
                                    </div>
                                )}

                                {!specialFields.includes(field.format) &&
                                field.format === "birthday" && (
                                    <div className={classes.fieldRight}>
                                      <FormatBirthdayComponent
                                          field={field}
                                          handleEditDateTime={
                                            props.handleEditDateTime
                                          }
                                          index={index}
                                          disabled={props.disabled  || field.options.setup?.editable === false}
                                      />
                                    </div>
                                )}

                                {!specialFields.includes(field.format) &&
                                field.format === "time" && (
                                    <div className={classes.fieldRight}>
                                      <FormatTimeComponent
                                          field={field}
                                          handleEditDateTime={
                                            props.handleEditDateTime
                                          }
                                          index={index}
                                          disabled={props.disabled  || field.options.setup?.editable === false}
                                      />
                                    </div>
                                )}

                                {!specialFields.includes(field.format) &&
                                field.format === "numeric" && (
                                    /*<TextField
                                        onChange={(event) =>
                                            props.handleEditValue(
                                                event.target.value === '' ? null : event.target.value,
                                                field,
                                                index
                                            )
                                        }
                                        id="standard-full-width-num"
                                        style={{ margin: 0 }}
                                        type="number"
                                        autoComplete="off"
                                        onWheel={(event) => event.target.blur()}
                                        placeholder={t("fields.format-choice-placeholder-" + field.visuelFormat.toLowerCase())}
                                        onFocus={(e) => e.target.placeholder = ''}
                                        onBlur={(e) => e.target.placeholder = t("fields.format-choice-placeholder-" + field.visuelFormat.toLowerCase())}
                                        helperText=""
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{ shrink: true }}
                                        inputProps={{ step: "0.1" }}
                                        className={classes.fieldRight}
                                        value={isNaN(field.value) ? null : field.value}
                                        InputProps={{ disableUnderline: true }}
                                        disabled={props.disabled  || field.options.setup?.editable === false}
                                    />*/
                                      <FormatNumericComponent
                                        field={field}
                                        index={index}
                                        handleEditValue={props.handleEditValue}
                                        disabled={props.disabled}
                                        // disabled={props.disabled || field.disabled || field?.options?.setup?.editable === false}

                                      />
                                )}
                                {!specialFields.includes(field.format) &&
                                field.format === "password" && (
                                    <FormatPasswordComponent
                                        handleSavePassword={props.handleSavePassword}
                                        handleChangePassword={
                                          props.handleChangePassword
                                        }
                                        field={field}
                                        index={index}
                                        disabled={props.disabled  || field.options.setup?.editable === false}
                                    />
                                )}
                                {specialFields.includes(field.format) && field.format === "extdata" && (
                                    <FormatExtDataComponent
                                        ownerId={props.model.owner}
                                        model={props.model}
                                        fields={fields}
                                        handleChangeExtData={props.handleChangeExtData}
                                        handleEditValue={props.handleEditValue}
                                        field={field}
                                        index={index}
                                        disabled={props.disabled  || field.options.setup?.editable === false}
                                        owner={props.hashOwnerModel}
                                        title={field.titleTranslate}
                                    />
                                )}
                                {specialFields.includes(field.format) && field.format === "extdatan" && (
                                    <FormatExtDatanComponent
                                        ownerId={props.model.owner}
                                        model={props.model}
                                        fields={fields}
                                        handleChangeExtDatan={props.handleChangeExtDatan}
                                        handleEditValue={props.handleEditValue}
                                        field={field}
                                        index={index}
                                        disabled={props.disabled  || field.options.setup?.editable === false}
                                        owner={props.hashOwnerModel}
                                        title={field.titleTranslate}
                                    />
                                )}

                                {!specialFields.includes(field.format) &&
                                field.format === "formula" && (
                                    <FormatFormulaComponent
                                        field={field}
                                        index={index}
                                        handleEditValue={props.handleEditValue}
                                        disabled={props.disabled  || field.options.setup?.editable === false}
                                        model={props.model}
                                        fields={fields}
                                        dataHasChanged={props.dataHasChanged}
                                    />
                                )}

                              </div>
                              }

                              {field.format === "text" &&
                              <div className={`${props.disabled === false ? classes.formatInputFieldInline : classes.formatInputFieldInlineLocked} ${classes.formatText}`} >
                                <TextField
                                    onChange={(event) =>
                                        props.handleEditValue(
                                            event.target.value,
                                            field,
                                            index
                                        )
                                    }
                                    style={{margin: 0}}
                                    placeholder={t(
                                        "fields.format-choice-placeholder-" +
                                        field.visuelFormat.toLowerCase()
                                    )}
                                    helperText=""
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    inputProps={{maxLength: 40}}
                                    value={field.value}
                                    InputProps={{
                                      disableUnderline: true,
                                    }}
                                    disabled={props.disabled || field.disabled || field?.options?.setup?.editable === false}
                                />
                                {field.value.length > 39 &&
                                <div
                                  className={classes.visibilityIcon}
                                  onClick={(e) => handleFormatInput(e, field.titleTranslate, field.value)}
                                  >
                                  <span className="material-icons">
                                    visibility_on
                                  </span>
                                </div>
                                }
                              </div>
                              }
                            </div>

                            {
                              specialFields.includes(field.format) && field.format === "tablemulti" &&
                              <FormatTableMultipleComponent
                                handleChangeTableValue={props.handleChangeTableValue}
                                handleSafeExitPlugnote={handleSafeExitPlugnote}
                                hash={props.plugnote?.hash}
                                field={field}
                                index={index}
                                disabled={props.disabled  || field.options.setup?.editable === false}
                                mode={props.mode}
                                owner={props.hashOwnerModel}
                                title={field.titleTranslate}
                                ownerId={props.model.owner}
                                model={props.model}
                                plugNb={props.plugnote?.userindex}
                                dataHash={props?.plugnote?.hash}
                                control={props.mode !== 'shared-view'}
                                controlUpdate={props.handleSaveSharedPlugnote}
                                controlCreate={props.handleCreateSharedPlugnote}
                                shared={true}
                              />
                            }
                            {
                              field.format === "tablemultin" && specialFields.includes(field.format) &&
                              <FormatTableMultiplenComponent
                                handleChangeTableValue={props.handleChangeTableRowsValue}
                                handleSafeExitPlugnote={handleSafeExitPlugnote}
                                hash={props.plugnote?.hash}
                                field={field}
                                index={index}
                                fields={fields}
                                disabled={props.disabled || field.disabled  || field.options.setup?.editable === false}
                                mode={props.mode}
                                model={props.model}
                                owner={props.hashOwnerModel}
                                title={field.titleTranslate}
                                ownerId={props.model.owner}
                                plugNb={props.plugnote?.userindex}
                                handleChangeExtData={props.handleChangeExtData}
                                dataHash={props?.plugnote?.hash}
                                control={props.mode !== 'shared-view'}
                                controlUpdate={props.handleSaveSharedPlugnote}
                                controlCreate={props.handleCreateSharedPlugnote}
                                shared={true}
                              />
                            }

                            {
                              (
                                  field.format === "glocalis" ||
                                  field.format === "document" ||
                                  field.format === "smailvalidmulti"

                              ) &&
                              <div className={props.disabled === false ? classes.formatInputFieldComposed : classes.formatInputFieldLockedComposed}>
                                {specialFields.includes(field.format) && field.format === "smailvalidmulti" && (
                                  <div className={classes.formatInputFieldComposed}>
                                    <FormatSMailValidMultiComponent
                                        mode={props.mode}
                                        ownerId={props.model.owner}
                                        handleChangeTableValue={props.handleChangeTableRowsValue}
                                        handleEditValue={props.handleEditValue}
                                        handleSafeExitPlugnote={handleSafeExitPlugnote}
                                        field={field}
                                        fields={fields}
                                        index={index}
                                        disabled={props.disabled || field.disabled  || field.options.setup?.editable === false}
                                        owner={props.hashOwnerModel}
                                        title={field.titleTranslate}
                                        model={props?.model || null}
                                        plugnote={props?.plugnote || null}
                                        annexes={props.plugnoteAnnexes.length > 0 ? props.plugnoteAnnexes : []}
                                        renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                        control={props.mode !== 'shared-view'}
                                        controlUpdate={props.handleSaveSharedPlugnote}
                                        controlCreate={props.handleCreateSharedPlugnote}
                                        shared={true}
                                    />
                                    </div>
                                )}

                                  {specialFields.includes(field.format) && field.format === "document" &&
                                    <div className={classes.formatInputFieldComposed}>
                                      <FormatDocumentComponent
                                        mode={props.mode}
                                        ownerId={props.model.owner}
                                        handleChangeTableValue={props.handleChangeTableRowsValue}
                                        handleEditValue={props.handleEditValue}
                                        handleSafeExitPlugnote={handleSafeExitPlugnote}
                                        handleSaveComment={props.handleSaveFictifComment}
                                        field={field}
                                        fields={fields}
                                        index={index}
                                        disabled={props.disabled || field.disabled  || field.options.setup?.editable === false}
                                        owner={props.hashOwnerModel}
                                        title={field.titleTranslate}
                                        model={props?.model || null}
                                        plugNb={props.plugnote?.userindex}
                                        plugnote={props?.plugnote || null}
                                        annexes={props.plugnoteAnnexes.length > 0 ? props.plugnoteAnnexes : []}
                                        renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                        control={props.mode !== 'shared-view'}
                                        controlUpdate={props.handleSaveSharedPlugnote}
                                        controlCreate={props.handleCreateSharedPlugnote}
                                        shared={true}
                                      />
                                    </div>
                                  }

                                {specialFields.includes(field.format) &&
                                field.format === "glocalis" && (
                                    <div
                                        className={
                                          props.disabled === false
                                              ? classes.formatInputField
                                              : classes.formatInputFieldLocked
                                        }
                                    >
                                      <FormatGeoLocalisationComponent
                                          handleChangeLocalisation={
                                            props.handleChangeLocalisation
                                          }
                                          field={field}
                                          index={index}
                                          disabled={props.disabled  || field.options.setup?.editable === false}
                                      />
                                    </div>
                                )}
                              </div>}
                          </div>
                        </div>
                      </div>
                    );
                  }
                })
              }
            </Content>

            {/*<Content className={`${classes.attField} ${classes.fieldBorder} scrollbar-hidden`}>
              {
                props.disabled === false &&
                <div
                  className={`${classes.fieldTitle} ${classes.attachmentTitle} cursor icon-style`}
                  onClick={(event) => props.handleCreateNote(event)}
                >
                  {t("common:plugnote.add-attachment-note")}
                  <div>
                    <MdNoteAdd style={{ fontSize: 20, color: "black" }} />
                  </div>
                </div>
              }

              {
                props.disabled === false &&
                <div className={`${classes.fieldTitle} ${classes.attachmentTitle}`}>
                  {t("multiple.add-attachments")}
                </div>
              }

              <Dropzone
                onDrop={(acceptedFiles) =>
                  props.onChangeHandlerDrop(acceptedFiles)
                }
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="">
                    {" "}
                    <div {...getRootProps()}>
                      {props.disabled === false ? (
                        <input {...getInputProps()} />
                      ) : null}

                      {props.disabled === false ?
                        <div className={classes.attachmentBox}>
                          <span
                        id="icon-file_copy"
                        className={`material-icons md ${classes.attIcon}`}
                        >
                        attach_file
                      </span>
                      <span
                        id="icon-file_copy"
                        className={`material-icons md ${classes.attIconShadow}`}
                        >
                        attach_file
                      </span>
                          <span className={classes.mainColor} style={{ cursor: "pointer" }}>
                            {t("multiple.attachement-drag-and-drop")}
                          </span>
                        </div>
                        : null}

                      <div
                        className={`${classes.fieldTitle} ${classes.attachmentTitle}`}
                      >
                        {t("multiple.current-attachments")}{" "}
                      </div>

                      {props.plugnoteAnnexes.length > 0 ?
                        <div className={classes.attContainer}>
                          {props.plugnoteAnnexes.map((annexe, index) => {
                            return (
                              <div
                                className={classes.itemContainer}
                                key={index + 1}
                                onClick={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                }}
                              >
                                <div className={classes.attItem}>
                                  <div
                                    className={classes.attIconType}
                                    onClick={(event) => {
                                      props._handleOpenModalListAnnexes();
                                      setFileId(index);
                                    }}
                                  >
                                    {index + 1} {props.renderAnnexeIconPreview(annexe)}
                                  </div>
                                  <div
                                    className={classes.attAnnexName}
                                    onClick={(event) => {
                                      props._handleOpenModalListAnnexes();
                                      setFileId(index);
                                    }}
                                  >
                                    {annexe.comment !== undefined && annexe.comment !== "" ?
                                      annexe.comment : annexe.name}
                                  </div>

                                  <div className={classes.attIconDlInfo}>
                                    {props.isAuthorisedToEdit(annexe.type) && props.mode != "shared-view" &&
                                      <span
                                        id="icon-file_upload"
                                        className="material-icons"
                                        onClick={(event) => props.handleUpdateAnnexe(event, annexe)}
                                        style={{ color: colors.black }}
                                      >
                                        publish
                                      </span>
                                    }
                                    {annexe.type !== "note" &&
                                      <span
                                        id="icon-file_copy"
                                        className="material-icons md-20"
                                        onClick={(event) => props.downloadEmployeeData(event, annexe)}
                                        style={{ color: colors.black }}
                                      >
                                        get_app
                                      </span>
                                    }

                                    {annexe.type === "note" && (
                                      <span
                                        id="icon-file_copy"
                                        className="material-icons md-25"
                                        onClick={(event) => props.handleNoteOpen(event, annexe)}
                                        style={{ color: colors.black }}
                                      >
                                        create
                                      </span>
                                    )}

                                    {annexe.countVersion > 0 && (
                                      <span
                                          id="icon-file_copy"
                                          className={classes.pointer}
                                          onClick={(event) =>
                                              props.handleModalListAnnexesVersionOpen(
                                                  event,
                                                  annexe.hash
                                              )
                                          }
                                            >
                                        ({annexe.countVersion})
                                      </span>
                                  )}

                                    <div className="">
                                      {annexe.groupid !== 0 ? (
                                        <span>{annexe.groupid}</span>
                                      ) : (
                                        <span />
                                      )}
                                    </div>

                                    {props.disabled === false &&
                                      <span
                                        id="icon-file_"
                                        className="material-icons"
                                        onClick={(event) => props.handleDeleteAnnexe(event, annexe)}
                                        style={{ color: colors.black }}
                                      >
                                        delete
                                      </span>
                                    }
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                          <div style={{ textAlign: "center" }}>
                            * * *
                          </div>
                        </div>
                        :
                        <span>{t("multiple.no-attachments")}</span>
                      }
                    </div>
                  </section>
                )}
              </Dropzone>
              {props.plugnoteAnnexes.length > 0 && props.isOpenModalListAnnexes &&
                <ModalTestAnnexeGallery
                  locked={false}
                  open={props.isOpenModalListAnnexes}
                  close={props._handleCloseModalListAnnexes}
                  annexes={props.plugnoteAnnexes}
                  isAuthorisedToEdit={props.isAuthorisedToEdit}
                  downloadEmployeeData={props.downloadEmployeeData}
                  handleUpdateAnnexe={props.handleUpdateAnnexe}
                  handleModalAnnexeOpen={props.handleModalAnnexeOpen}
                  handleNoteOpen={props.handleNoteOpen}
                  handleDeleteAnnexe={props.handleDeleteAnnexe}
                  handleModalListAnnexesVersionOpen={
                    props.handleModalListAnnexesVersionOpen
                  }
                  handlePreviewAnnexe={props.handlePreviewAnnexe}
                  handlePreviewPdfAnnexe={props.handlePreviewPdfAnnexe}
                  fileId={fileId}
                  renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                  renderAnnexeImagePreview={props.renderAnnexeImagePreview}
                  isOpenModalAnnexe={props.isOpenModalAnnexe}
                  selectedAnnexe={props.selectedAnnexe}
                  handleModalAnnexeClose={props.handleModalAnnexeClose}
                />
              }
            </Content> */}
          </div>
        </div>

        {
            view &&
            <ModalViewFicheComponent
                mode={props.mode}
                open={view}
                dataHash={props?.plugnote?.dataHashOrigin}
                close={() => setView(false)}
            />
        }
      </div>
      );

/************************************************************************************************************
 PLUGCODE-CONSULT/COMPLETE/CONSULT-GALLERY/COMPLETE-GALLERY
  *************************************************************************************************************/
    case "plugcode-consult":
    case "plugcode-consult-modal":
    case "plugcode-consult-gallery":
    case "plugcode-complete":
    case "plugcode-complete-modal":
    case "plugcode-complete-gallery":
      let isFromPlugFormCode = (props.mode === "plugcode-complete" || props.mode === "plugcode-complete-gallery")
      let isFromPwCode = isDefined(props.plugnote?.pncodeprivileges)
      return (
        <div className={`${classes.root}`}>
          <div className={props.mode.includes('modal')? classes.formModalContainer : classes.formContainer}>
            <div className={classes.pageInfos}>{props.environment.name} - {now} - {props.mode} - </div>

            <PlugnoteHeader
              disabled={props.disabled}
              environment={props.environment}
              external={props.external}
              handleExitPlugnote={handleExitPlugnote}
              handleOpenDataOrigin={() => handleOpenDataOrigin(props.plugnote.dataHashOrigin)}
              handleSafeExitPlugnote={handleSafeExitPlugnote}
              handleSaveCreatePlugnote={props.handleSaveCreatePlugnote}
              handleSavePlugform={props.handleSavePlugform}
              handleSavePlugnote={handleSavePlugnote}
              handleOpenSave={props.handleOpenSave}
              handleToggleBanner={() => setOpenBanner(current => !current)}
              handleUpdatePlugnote={props.handleUpdatePlugnote}
              languageSelector={
                lngOptions.length > 0 &&
                <div className={classes.langCtn}>
                  <Select
                    fullWidth
                    id="demo-simple-select"
                    isClearable
                    labelId="demo-simple-select-label"
                    placeholder={t('common:models.list-translation')}
                    onChange={(value) => handleChangeLanguage(value)}
                    onFocus={() => { if (props.list?.length < 1) setLoading(true) }}
                    options={lngOptions}
                    styles={selectStyle}
                    value={lang}
                  />
                </div>
              }
              mode={props.mode}
              model={props.model}
              nameModel={nameModel}
              openBanner={openBanner}
              pnCode={pnCode}
              pfCode={pfCode}
              pfKey={pfKey}
              pfComment={pfComment}
              pnComment={pnComment}
              plugcode={plugCode}
              plugKey={pnKey || pfKey}
              plugnote={props.plugnote}
              plugnoteAnnexes={props.plugnoteAnnexes}
            />

            <AttachmentBanner
              annexes={props.plugnoteAnnexes}
              disabled={props.disabled}
              handleCloseModalListAnnexes={props._handleCloseModalListAnnexes}
              handleCreateNote={props.handleCreateNote}
              handleDeleteAnnexe={props.handleDeleteAnnexe}
              downloadFile={props.downloadEmployeeData}
              handleNoteClose={props.handleNoteClose}
              handleNoteOpen={props.handleNoteOpen}
              handleOpenGallery={props._handleOpenModalListAnnexes}
              handleReorderAnnexes={props.handleReorderAnnexes}
              handleSaveComment={props.handleSaveFictifComment}
              handleToggleBanner={() => setOpenBanner(current => !current)}
              handleSaveNote={props.handleSaveNote}
              getAnnexeUrl={annexe => getAnnexeUrl(annexe, props.environment, props.plugnote)}
              mode={props.mode}
              onChangeHandlerDrop={props.onChangeHandlerDrop}
              open={openBanner}
              plugnote={{ title: props.nameModel }}
              renderAnnexeIconPreview={props.renderAnnexeIconPreview}
              renderAnnexeImagePreview={props.renderAnnexeImagePreview}
              setFileId={setFileId}
            />

            <div className={props.mode.includes('consult-modal') ? classes.columnModal : classes.contentFields}>
              <Content className={`${classes.contentFieldModel} ${classes.fieldBorder} ${classes.contentFieldModelConsult} scrollbar-hidden` }>
                {/* <div className={classes.fieldTitle}>
                {t("multiple.fields-labels-lowercase")}
                </div> */}

                {typeof fields !== "undefined" &&
                fields.length > 0 &&
                fields.map((field, index) => {
                  let visible;
                  switch (props.mode) {
                    case "plugcode-consult":
                    case "plugcode-consult-modal":
                    case "plugcode-consult-gallery":
                      visible = fieldIsVisible(field, props)
                      // visible = true
                      break;

                    case "plugcode-complete":
                    case "plugcode-complete-modal":
                    case "plugcode-complete-gallery":
                      visible = field.encodable;
                      break;

                    default:
                      break;
                  }
                  let fieldNb = index + 1

                  if (visible) {
                    return (
                      <div className="flexbox-container" key={'field-' + fieldNb}>
                        <div className={`${classes.formatContainer}`}>
                          <div className={classes.fieldIconCtn} >
                            <span className={classes.fieldIndex} style={props.mode?.includes('modal') ? { marginLeft: 5 } : undefined}>({fieldNb})</span>
                            {
                              field.visuelFormat.toLowerCase() === "now" ?
                              <div className={field.mandatory ? classes.fieldIconMandatory : classes.fieldIcon}>
                                <img
                                  className={classes.fieldImage}
                                  src={Images[camelCase(field.visuelFormat.toLowerCase()) + "Icon"]}
                                  alt={t("fields.format-choice-" + field.visuelFormat.toLowerCase())}
                                />
                              </div>
                              : <img
                                className={classes.fieldImageSvg}
                                src={
                                  field.mandatory
                                    ? Images[camelCase(field.visuelFormat.toLowerCase()) + "IconSvgRed"]
                                    : Images[camelCase(field.visuelFormat.toLowerCase()) + "IconSvg"]
                                }
                                alt={t("fields.format-choice-" + field.visuelFormat.toLowerCase())}
                              />
                            }
                          </div>

                          <div className={classes.formatContent}>
                            <div className={classes.formatHeader}>
                                <PlugTooltip
                                  title={field.hasOwnProperty('infobulle') ? infobulles['field' + field.id] : ''}
                                  classes={{ tooltip: field.hasOwnProperty('infobulle') ? classes.infobulle : classes.noTooltip }} placement="bottom"
                                >
                                  <div className={classes.numTitleFormat}>
                                    <span className={classes.formatTitleCtn}>
                                      <div className={classes.separatorHeaderFormat} />
                                      <span className={classes.titleHeaderFormat}>
                                        {field.titleTranslate}
                                      </span>
                                    </span>
                                    <div>
                                      {
                                        field.hasOwnProperty('infobulle') &&
                                        <span
                                          id="icon-file_"
                                          className="material-icons"
                                          style={{ color: colors.mainButton, fontSize: 25 }}
                                        >
                                          info
                                        </span>
                                      }
                                    </div>
                                  </div>
                                </PlugTooltip>
                              {!specialFields.includes(field.format) && field.format === "sign" && (
                                  <div className={classes.formatInputFieldInlineLocked}>
                                    <FormatSignComponent
                                        handleEditValue={props.handleEditValue}
                                        field={field}
                                        index={index}
                                        disabled={props.disabled || field.disabled}
                                        model={props?.model || null}
                                        plugnote={props?.plugnote || null}
                                        annexes={props.plugnoteAnnexes.length > 0 ? props.plugnoteAnnexes : []}
                                        fields={fields}
                                        onChangeHandlerDrop={props.onChangeHandlerDrop}
                                    />
                                  </div>
                              )}
                                {
                                  !specialFields.includes(field.format) && field.format === "boolean" &&
                                  <div className={(props.disabled === false || field.disabled === false) ? classes.formatInlineNoBorder : classes.formatInlineNoBorderLocked}>
                                    <FormatBooleanComponent
                                      handleChangeBoolean={props.handleChangeBoolean}
                                      field={field}
                                      index={index}
                                      disabled={props.disabled || field.disabled}
                                    />
                                  </div>
                                }

                              {
                                field.format === "pluglink" ||
                                field.format === "plugform" ||
                                field.format === "qrcodeint" ||
                                field.format === "intdatamaj" ||
                                field.format === "qrcode" ?
                                  <div
                                    className={props.disabled === false || field.disabled === false
                                      ? classes.formatInlineNoBorder
                                      : classes.formatInlineNoBorderLocked
                                    }
                                  >

                                      {
                                        specialFields.includes(field.format) && field.format === "qrcode" &&
                                        <div
                                          className={
                                            props.disabled === false
                                              ? classes.formatInputField
                                              : classes.formatInputFieldLocked
                                          }
                                        >
                                          <FormatPlugcodeComponent
                                            handleChangePlugcode={props.handleChangePlugcode}
                                            field={field}
                                            index={index}
                                            isFromPwCode={isFromPwCode}
                                            disabled={props.disabled || field.disabled}
                                            mode={props.mode}
                                            owner={props.hashOwnerModel}
                                          />
                                        </div>
                                      }

                                    {
                                      specialFields.includes(field.format) && field.format === "qrcodeint" &&
                                        <div
                                          className={ props.disabled === false
                                          ? classes.formatInputField
                                          : classes.formatInputFieldLocked
                                          }
                                        >
                                          <FormatPlugcodeInternalComponent
                                            handleChangePlugcode={props.handleChangePlugcodeInternal}
                                            field={field}
                                            fields={fields}
                                            index={index}
                                            isFromPwCode={isFromPwCode}
                                            disabled={props.disabled || field.disabled}
                                            mode={props.mode}
                                            owner={props.hashOwnerModel}
                                          />
                                        </div>
                                    }

                                    {specialFields.includes(field.format) && field.format === "plugform" &&
                                        <FormatPlugformComponent
                                            handleChangePlugform={props.handleChangePlugform}
                                            field={field}
                                            index={index}
                                            disabled={props.disabled || field.disabled}
                                            owner={props.hashOwnerModel}
                                            mode={props.mode}
                                        />
                                    }

                                    {specialFields.includes(field.format) && field.format === "intdatamaj" &&
                                        <FormatIntDataMajComponent
                                            handleChangeIntDataMaj={props.handleChangeIntDataMaj}
                                            field={field}
                                            index={index}
                                            disabled={props.disabled || field.disabled}
                                            owner={props.hashOwnerModel}
                                            mode={props.mode}
                                            dataHash={props?.plugnote?.hash}
                                        />
                                    }

                                    {!specialFields.includes(field.format) &&
                                    field.format === "pluglink" && (
                                        <FormatPlugLinkComponent
                                            mode={props.mode}
                                            field={field}
                                            index={index}
                                            handleEditValue={props.handleEditValue}
                                            disabled={props.disabled || field.disabled}
                                            dataHash={props?.plugnote?.hash}
                                        />
                                    )}

                                  </div>
                                  : null}


                              {(field.format === "phone" ||
                                  field.format === "mail" ||
                                  field.format === "smail" ||
                                  field.format === "url" ||
                                  field.format === "localis" ||
                                  field.format === "date" ||
                                  field.format === "agenda" ||
                                  field.format === "now" ||
                                  field.format === "birthday" ||
                                  field.format === "textarea" ||
                                  field.format === "indice" ||
                                  field.format === "time" ||
                                  field.format === "formula" ||
                                  field.format === "extdata" ||
                                  field.format === "extdatan" ||
                                  field.format === "list" ||
                                  field.format === "numeric" ||
                                  field.format === "password" ||
                                  (field.format.includes("table") && field.format.includes("single") && !field.format.includes("tablemulti")) ||
                                  (field.format.includes("table") && field.format.includes("multi") && !field.format.includes("tablemulti")) ||
                                  (field.format.includes("select") && field.format.includes("single") && !field.format.includes("tablemulti")) ||
                                  (field.format.includes("select") && field.format.includes("multi") && !field.format.includes("tablemulti"))) &&

                              <div className={(props.disabled === false || field.disabled === false) ? classes.formatInputFieldInline : classes.formatInputFieldInlineLocked}>
                                {
                                  specialFields.includes(field.format) && field.format === "textarea" &&
                                    <FormatMemoComponent
                                      handleEditValue={props.handleEditValue}
                                      field={field}
                                      index={index}
                                      disabled={
                                        props.disabled || field.disabled ||
                                        field?.options?.setup?.editable === false ||
                                        (field?.options?.setup?.editablePf === false && !isFromPwCode) ||
                                        (field?.options?.setup?.editablePw === false && isFromPwCode)}
                                    />}

                                {
                                  !specialFields.includes(field.format) && field.format === "indice" && (
                                    <FormatIndiceComponent
                                      handleEditValue={props.handleEditValue}
                                      field={field}
                                      index={index}
                                      disabled={props.disabled || field.disabled}
                                    />)}

                                {!specialFields.includes(field.format) &&
                                field.format === "formula" && (
                                    <FormatFormulaComponent
                                        field={field}
                                        index={index}
                                        handleEditValue={props.handleEditValue}
                                        disabled={props.disabled || field.disabled}
                                        model={props.model}
                                        fields={fields}
                                        dataHasChanged={props.dataHasChanged}
                                    />
                                )}

                                {!specialFields.includes(field.format) &&
                                field.format === "phone" && (
                                    <FormatPhoneComponent
                                        field={field}
                                        index={index}
                                        handleEditPhoneValue={
                                          props.handleEditPhoneValue
                                        }
                                        disabled={props.disabled || field.disabled}
                                    />
                                )}

                                {!specialFields.includes(field.format) &&
                                field.format === "mail" && (
                                    <FormatMailComponent
                                        field={field}
                                        index={index}
                                        handleEditValue={props.handleEditValue}
                                        disabled={props.disabled || field.disabled}
                                    />
                                )}

                              {!specialFields.includes(field.format) &&
                              field.format === "smail" && (
                                  <FormatSMailComponent
                                      field={field}
                                      fields={fields}
                                      index={index}
                                      handleEditValue={props.handleEditValue}
                                      disabled={
                                        props.disabled || field.disabled ||
                                        field?.options?.setup?.editable === false ||
                                        (field?.options?.setup?.editablePf === false && !isFromPwCode) ||
                                        (field?.options?.setup?.editablePw === false && isFromPwCode)}
                                      mode={props.mode}
                                      model={props?.model || null}
                                      plugnote={props?.plugnote || null}
                                      annexes={props.plugnoteAnnexes.length > 0 ? props.plugnoteAnnexes : []}
                                      renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                      owner={props.hashOwnerModel}
                                  />
                              )}

                                {!specialFields.includes(field.format) &&
                                field.format === "url" && (
                                    <FormatUrlComponent
                                      field={field}
                                      index={index}
                                      handleEditValue={props.handleEditValue}
                                      disabled={
                                        props.disabled || field.disabled ||
                                        field?.options?.setup?.editable === false ||
                                        (field?.options?.setup?.editablePf === false && !isFromPwCode) ||
                                        (field?.options?.setup?.editablePw === false && isFromPwCode)}
                                    />
                                )}

                                {
                                  specialFields.includes(field.format) && field.format === "localis" &&
                                    <FormatLocalisationComponent
                                      handleChangeLocalisation={props.handleChangeLocalisation}
                                      field={field}
                                      index={index}
                                      disabled={props.disabled || field.disabled}
                                    />
                                }

                                {!specialFields.includes(field.format) &&
                                field.format === "date" && (
                                    <div className={classes.fieldRight}>
                                      {/*<FormatDateComponent
                                          field={field}
                                          handleEditDateTime={
                                            props.handleEditDateTime
                                          }
                                          index={index}
                                          disabled={props.disabled || field.disabled}
                                      />*/}
                                    <div className={classes.fieldRight}>
                                      <FormatTestDateComponent
                                          field={field}
                                          handleEditDateTime={
                                            props.handleEditDateTime
                                          }
                                          index={index}
                                          disabled={props.disabled || field.disabled}
                                      />
                                    </div>
                                  </div>
                                )}
                                {!specialFields.includes(field.format) &&
                                field.format === "agenda" && (
                                    <div className={classes.fieldRight}>
                                      <FormatAgendaComponent
                                          field={field}
                                          handleEditDateTime={
                                            props.handleEditDateTime
                                          }
                                          index={index}
                                          disabled={props.disabled || field.disabled}
                                      />
                                    </div>
                                )}

                                {!specialFields.includes(field.format) &&
                                field.format === "now" && (
                                    <div className={classes.fieldRight}>
                                      <FormatNowComponent
                                          field={field}
                                          handleEditDateTime={
                                            props.handleEditDateTime
                                          }
                                          index={index}
                                          disabled={props.disabled || field.disabled}
                                      />
                                    </div>
                                )}

                                {!specialFields.includes(field.format) &&
                                field.format === "birthday" && (
                                    <div className={classes.fieldRight}>
                                      <FormatBirthdayComponent
                                          field={field}
                                          handleEditDateTime={
                                            props.handleEditDateTime
                                          }
                                          index={index}
                                          disabled={props.disabled || field.disabled}
                                      />
                                    </div>
                                )}

                                {!specialFields.includes(field.format) &&
                                field.format === "time" && (
                                    <div className={classes.fieldRight}>
                                      <FormatTimeComponent
                                          field={field}
                                          handleEditDateTime={
                                            props.handleEditDateTime
                                          }
                                          index={index}
                                          disabled={props.disabled || field.disabled}
                                      />
                                    </div>
                                )}

                                {!specialFields.includes(field.format) &&
                                field.format === "numeric" && (
    /*                               <TextField
                                        onChange={(event) =>
                                            props.handleEditValue(
                                                event.target.value === '' ? null : event.target.value,
                                                field,
                                                index
                                            )
                                        }
                                        id="standard-full-width-num"
                                        style={{ margin: 0 }}
                                        type="number"
                                        autoComplete="off"
                                        onWheel={(event) => event.target.blur()}
                                        placeholder={t("fields.format-choice-placeholder-" + field.visuelFormat.toLowerCase())}
                                        onFocus={(e) => e.target.placeholder = ''}
                                        onBlur={(e) => e.target.placeholder = t("fields.format-choice-placeholder-" + field.visuelFormat.toLowerCase())}
                                        helperText=""
                                        fullWidth
                                        margin="normal"
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        inputProps={{ step: "0.1" }}
                                        className={classes.fieldRight}
                                        value={isNaN(field.value) ? null : field.value}
                                        InputProps={{ disableUnderline: true }}
                                        disabled={props.disabled || field.disabled}
                                    />*/
                                        <FormatNumericComponent
                                            field={field}
                                            index={index}
                                            handleEditValue={props.handleEditValue}
                                            disabled={props.disabled || field.disabled}
                                        />
                                )}

                                {!specialFields.includes(field.format) &&
                                field.format === "password" && (
                                    <FormatPasswordComponent
                                        isFromPlugFormCode={isFromPlugFormCode}
                                        handleChangePassword={
                                          props.handleChangePassword
                                        }
                                        field={field}
                                        index={index}
                                        disabled={props.disabled || field.disabled}
                                    />
                                )}
                                {specialFields.includes(field.format) && field.format === "extdata" && (
                                    <FormatExtDataComponent
                                        ownerId={props.model.owner}
                                        model={props.model}
                                        fields={fields}
                                        handleChangeExtData={props.handleChangeExtData}
                                        handleEditValue={props.handleEditValue}
                                        field={field}
                                        index={index}
                                        disabled={props.disabled || field.disabled}
                                        owner={props.hashOwnerModel}
                                        title={field.titleTranslate}
                                    />
                                )}
                                {specialFields.includes(field.format) && field.format === "extdatan" && (
                                    <FormatExtDatanComponent
                                        ownerId={props.model.owner}
                                        model={props.model}
                                        fields={fields}
                                        handleChangeExtDatan={props.handleChangeExtDatan}
                                        handleEditValue={props.handleEditValue}
                                        field={field}
                                        index={index}
                                        disabled={props.disabled || field.disabled}
                                        owner={props.hashOwnerModel}
                                        title={field.titleTranslate}
                                    />)}

                                {specialFields.includes(field.format) &&
                                field.format.includes("select") &&
                                field.format.includes("multi") &&
                                !field.format.includes("tablemulti") && (
                                    <FormatMultipleSelectComponent
                                        handleChangeRadio={
                                          props.handleChangeRadio
                                        }
                                        field={field}
                                        index={index}
                                        disabled={props.disabled || field.disabled}
                                        expand={false}
                                    />
                                )}

                                {specialFields.includes(field.format) &&
                                field.format.includes("select") &&
                                field.format.includes("single") && (
                                    <FormatSingleSelectComponent
                                        handleChangeRadio={
                                          props.handleChangeRadio
                                        }
                                        field={field}
                                        index={index}
                                        disabled={props.disabled || field.disabled}
                                        expand={false}
                                    />
                                )}

                                {specialFields.includes(field.format) &&
                                field.format.includes("table") &&
                                field.format.includes("multi") &&
                                !field.format.includes("tablemulti") && (
                                    <FormatMultipleSelectTableComponent
                                        handleChangeRadio={
                                          props.handleChangeRadioTable
                                        }
                                        handleChangeInputTable={
                                          props.handleChangeInputTable
                                        }
                                        handleUpdateInputTable={
                                          props.handleUpdateInputTable
                                        }
                                        handleDeleteInputTable={
                                          props.handleDeleteInputTable
                                        }
                                        field={field}
                                        index={index}
                                        disabled={props.disabled || field.disabled}
                                        expand={false}
                                    />
                                )}

                                {specialFields.includes(field.format) &&
                                field.format.includes("table") &&
                                field.format.includes("single") && (
                                    <FormatSingleSelectTableComponent
                                        handleChangeRadio={
                                          props.handleChangeRadioTable
                                        }
                                        handleChangeInputTable={
                                          props.handleChangeInputTable
                                        }
                                        handleUpdateInputTable={
                                          props.handleUpdateInputTable
                                        }
                                        handleDeleteInputTable={
                                          props.handleDeleteInputTable
                                        }
                                        field={field}
                                        index={index}
                                        disabled={props.disabled || field.disabled}
                                        expand={false}
                                    />
                                )}



                                {specialFields.includes(field.format) &&
                                field.format === "list" && (
                                    <FormatKeywordComponent
                                        handleChangeKeyword={
                                          props.handleChangeKeyword
                                        }
                                        field={field}
                                        index={index}
                                        disabled={props.disabled || field.disabled}
                                    />
                                )}
                              </div>
                              }

                              {
                                field.format === "text" &&
                                <div className={(props.disabled === false || field.disabled === false) ? classes.formatInputFieldInline : classes.formatInputFieldInlineLocked}>
                                  <TextField
                                      onChange={(event) => props.handleEditValue(event.target.value, field, index)}
                                      style={{ margin: 0 }}
                                      placeholder={t(
                                          "fields.format-choice-placeholder-" +
                                          field.visuelFormat.toLowerCase()
                                      )}
                                      helperText=""
                                      fullWidth
                                      margin="normal"
                                      InputLabelProps={{ shrink: true }}
                                      inputProps={{ maxLength: 40 }}
                                      value={field.value}
                                      InputProps={{ disableUnderline: true }}
                                      disabled={
                                        props.disabled || field.disabled ||
                                        field?.options?.setup?.editable === false ||
                                        (field?.options?.setup?.editablePf === false && !isFromPwCode) ||
                                        (field?.options?.setup?.editablePw === false && isFromPwCode)}
                                  />
                                      {
                                        field.value.length > 39 &&
                                        <div
                                          className={classes.visibilityIcon}
                                          onClick={(e) => handleFormatInput(e, field.titleTranslate, field.value)}
                                        >
                                          <span className="material-icons">
                                            visibility_on
                                          </span>
                                        </div>
                                      }
                                </div>
                              }
                            </div>

                              {
                                specialFields.includes(field.format) && field.format === "tablemulti" &&
                                <FormatTableMultipleComponent
                                  handleChangeTableValue={props.handleChangeTableValue}
                                  handleSafeExitPlugnote={handleSafeExitPlugnote}
                                  hash={props.plugnote?.hash}
                                  field={field}
                                  index={index}
                                  isFromPwCode={isFromPwCode}
                                  disabled={props.disabled || field.disabled}
                                  mode={props.mode}
                                  owner={props.hashOwnerModel}
                                  title={field.titleTranslate}
                                  ownerId={props.model.owner}
                                  model={props.model}
                                  plugNb={props.plugnote?.userindex}
                                  dataHash={props?.plugnote?.hash}
                                />
                              }

                              {
                                field.format === "tablemultin" && specialFields.includes(field.format) &&
                                <FormatTableMultiplenComponent
                                  handleChangeTableValue={props.handleChangeTableRowsValue}
                                  handleSafeExitPlugnote={handleSafeExitPlugnote}
                                  hash={props.plugnote?.hash}
                                  field={field}
                                  fields={fields}
                                  index={index}
                                  isFromPwCode={isFromPwCode}
                                  disabled={props.disabled || field.disabled}
                                  mode={props.mode}
                                  owner={props.hashOwnerModel}
                                  title={field.titleTranslate}
                                  ownerId={props.model.owner}
                                  model={props.model}
                                  plugNb={props.plugnote?.userindex}
                                  handleChangeExtData={props.handleChangeExtData}
                                  dataHash={props?.plugnote?.hash}
                                />
                              }

                            {
                              field.format === "smailvalidmulti" ||
                              field.format === "document" ||
                              field.format === "glocalis"
                              ? <div
                                  className={ props.disabled === false || field.disabled === false
                                    ? classes.formatInputFieldComposed
                                    : classes.formatInputFieldLockedComposed
                                  }
                                >
                                    {
                                      specialFields.includes(field.format) && field.format === "smailvalidmulti" &&
                                      <div className={classes.formatInputFieldComposed}>
                                        <FormatSMailValidMultiComponent
                                          ownerId={props.model.owner}
                                          handleChangeTableValue={props.handleChangeTableRowsValue}
                                          handleEditValue={props.handleEditValue}
                                          handleSafeExitPlugnote={handleSafeExitPlugnote}
                                          field={field}
                                          fields={fields}
                                          index={index}
                                          isFromPwCode={isFromPwCode}
                                          disabled={props.disabled || field.disabled}
                                          owner={props.hashOwnerModel}
                                          title={field.titleTranslate}
                                          mode={props.mode}
                                          model={props?.model || null}
                                          plugnote={props?.plugnote || null}
                                          annexes={props.plugnoteAnnexes.length > 0 ? props.plugnoteAnnexes : []}
                                          renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                          plugNb={props.plugnote?.userindex}
                                        />
                                      </div>
                                    }

                                    {specialFields.includes(field.format) && field.format === "document" && (
                                        <div className={classes.formatInputFieldComposed}>
                                          <FormatDocumentComponent
                                              ownerId={props.model.owner}
                                              handleChangeTableValue={props.handleChangeTableRowsValue}
                                              handleEditValue={props.handleEditValue}
                                              handleSafeExitPlugnote={handleSafeExitPlugnote}
                                              handleSaveComment={props.handleSaveFictifComment}
                                              field={field}
                                              fields={fields}
                                              index={index}
                                              isFromPwCode={isFromPwCode}
                                              disabled={props.disabled || field.disabled}
                                              owner={props.hashOwnerModel}
                                              title={field.titleTranslate}
                                              mode={props.mode}
                                              model={props?.model || null}
                                              plugNb={props.plugnote?.userindex}
                                              plugnote={props?.plugnote || null}
                                              annexes={props.plugnoteAnnexes.length > 0 ? props.plugnoteAnnexes : []}
                                              renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                          />
                                        </div>
                                    )}

                                    {specialFields.includes(field.format) &&
                                    field.format === "glocalis" && (
                                        <div
                                          className={
                                            props.disabled === false || field.disabled === false
                                                ? classes.formatInputField
                                                : classes.formatInputFieldLocked
                                          }
                                        >
                                          <FormatGeoLocalisationComponent
                                              handleChangeLocalisation={props.handleChangeLocalisation}
                                              field={field}
                                              index={index}
                                              disabled={props.disabled || field.disabled}
                                          />
                                        </div>
                                    )}
                                  </div>
                                : null}
                          </div>
                        </div>
                      </div>
                    )
                  }
                  else { return null }
                })}
              </Content>

              {/* {props.plugnoteAnnexes.length === 0 && props.mode.includes('consult') ?
                  <Content/>
                  :
                  <Content className={`${classes.attField} ${classes.fieldBorder} scrollbar-hidden`}>
                    {props.disabled === false ?
                        <div
                            className={`${classes.fieldTitle} ${classes.attachmentTitle} cursor icon-style`}
                            onClick={(event) => props.handleCreateNote(event)}
                        >
                          {t("common:plugnote.add-attachment-note")}
                          <div>
                            <MdNoteAdd style={{ fontSize: 20, color: "black" }} />
                          </div>
                        </div>
                        : null}
                    {props.disabled === false ?
                        <div className={`${classes.fieldTitle} ${classes.attachmentTitle}`}>
                          {t("multiple.add-attachments")}
                        </div>
                        : null}
                    <Dropzone disabled={props.disabled} onDrop={(acceptedFiles) => handleDropdownFiles(acceptedFiles)}>
                      {({ getRootProps, getInputProps }) => (
                          <section className="">
                            <div {...getRootProps()}>
                              {props.disabled === false ?
                                  <input {...getInputProps()} />
                                  : null}
                              {props.disabled === false ?
                                  <div className={classes.attachmentBox}>
                                    <span className={classes.mainColor} style={{ cursor: "pointer"}}>{t("multiple.attachement-drag-and-drop")}</span>
                                  </div>
                                  : null
                              }
                              <div
                                  className={`${classes.fieldTitle} ${classes.attachmentTitle}`}
                              >
                                {t("multiple.current-attachments")}{" "}
                              </div>

                              {props.plugnoteAnnexes.length > 0 ?
                                  <div className={classes.attContainer}>
                                    {props.plugnoteAnnexes.map((annexe, index) => {
                                      return (
                                          <div className={classes.itemContainer}
                                              key={index + 1}
                                              onClick={(event) => {
                                                event.preventDefault();
                                                event.stopPropagation();
                                              }}
                                          >
                                            <div className={classes.attItem}>
                                              <div className={classes.attIconType} onClick={(event) => {props._handleOpenModalListAnnexes();setFileId(index);}}>
                                                {index+1} {props.renderAnnexeIconPreview(annexe)}
                                              </div>

                                              <div className={classes.attAnnexName} onClick={(event) => { props._handleOpenModalListAnnexes(); setFileId(index); }}>
                                                {annexe.comment !== undefined && annexe.comment !== ""?
                                                    annexe.comment : annexe.name}
                                              </div>

                                              <div className={classes.attIconDlInfo}>
                                                {
                                                  annexe.type !== "note" &&
                                                  <span
                                                    id="icon-file_copy"
                                                    className="material-icons md-20"
                                                    onClick={(event) => props.downloadEmployeeData(event, annexe)}
                                                    style={{color: colors.black}}
                                                  >
                                                    get_app
                                                  </span>
                                                }
                                {annexe.type === "note" && props.disabled === false &&
                                    <span
                                      id="icon-file_copy"
                                      className="material-icons md-25"
                                      onClick={(event) => props.handleNoteOpen(event, annexe)}
                                      style={{color: colors.black}}
                                    >
                                    create
                                  </span>
                                }

                                {annexe.countVersion > 0 && (
                                    <span
                                        id="icon-file_copy"
                                        className={classes.pointer}
                                        onClick={(event) =>
                                            props.handleModalListAnnexesVersionOpen(
                                                event,
                                                annexe.hash
                                            )
                                        }
                                    >
                        ({annexe.countVersion})
                      </span>
                                                )}

                                                <div className="">
                                                  {annexe.groupid !== 0 ? (
                                                      <span>{annexe.groupid}</span>
                                                  ) : (
                                                      <span />
                                                  )}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                      );
                                    })}
                                    <div style={{textAlign: "center"}}>
                                      * * *</div>
                                  </div>
                                  :
                                  <span>{t("multiple.no-attachments")}</span>
                              }
                            </div>
                          </section>
                      )}
                    </Dropzone>
                    {props.plugnoteAnnexes.length > 0 && props.isOpenModalListAnnexes &&
                        <ModalTestAnnexeGallery
                            mode={props.mode}
                            locked={props.disabled}
                            open={props.isOpenModalListAnnexes}
                            close={props._handleCloseModalListAnnexes}
                            annexes={props.plugnoteAnnexes}
                            isAuthorisedToEdit={props.isAuthorisedToEdit}
                            downloadEmployeeData={props.downloadEmployeeData}
                            handleUpdateAnnexe={props.handleUpdateAnnexe}
                            handleModalAnnexeOpen={props.handleModalAnnexeOpen}
                            handleNoteOpen={props.handleNoteOpen}
                            handleDeleteAnnexe={props.handleDeleteAnnexe}
                            handleModalListAnnexesVersionOpen={
                              props.handleModalListAnnexesVersionOpen
                            }
                            handlePreviewAnnexe={props.handlePreviewAnnexe}
                            handlePreviewPdfAnnexe={props.handlePreviewPdfAnnexe}
                            fileId={fileId}
                            renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                            renderAnnexeImagePreview={props.renderAnnexeImagePreview}
                            isOpenModalAnnexe={props.isOpenModalAnnexe}
                            selectedAnnexe={props.selectedAnnexe}
                            handleModalAnnexeClose={props.handleModalAnnexeClose}
                            handleSaveFictifComment={props.handleSaveFictifComment}
                        />
                    }
                  </Content>} */}
            </div>
          </div>
          {
              view &&
              <ModalViewFicheComponent
                  mode={props.mode}
                  open={view}
                  dataHash={props?.plugnote?.dataHashOrigin}
                  close={() => setView(false)}
              />
          }
        </div>
      );

/************************************************************************************************************
 MODAL-SHARED-CREATE/MODAL-SHARED-UPDATE
  *************************************************************************************************************/
    case "modal-shared-create":
    case "modal-shared-update":
      return (
        <div className={`${classes.root}`}>
          <div className={`${classes.formModalCreateContainer}`}>
            <Header className={`sticky  ${classes.header}`}>
              <div className={classes.titleBorder} >
                {
                  !props.viewInputSearchCode ?
                    <div className={classes.sous_header_content}>
                      <div className={classes.sous_header_content_title}>
                        <span className={classes.sous_header_content_title_input}>
                          {props.nameModel}
                        </span>
                        {
                          props.plugnoteAnnexes?.length > 0 &&
                          <PlugTooltip title={t('multiple.' + (openBanner ? 'close' : 'open') + '-att-banner')} placement="left">
                            <div className={classes.horizontalFlex} onClick={() => setOpenBanner(current => !current)} style={{ cursor: 'pointer' }}>
                              <span className={classes.verticalFlex} style={{ fontSize: 11, flexDirection: openBanner ? 'column' : 'column-reverse' }}>
                                {openBanner ? <BiChevronUp /> : <BiChevronDown />}
                                ({props.plugnoteAnnexes?.length})
                              </span>
                              <AttachFile style={{ width: 36, fill: colors.unselected, fontSize: 32 }} />
                            </div>
                          </PlugTooltip>
                        }
                    </div>
                    <div className={classes.sous_header_content_buttons}>
                      {
                        lngOptions.length > 0 &&
                        <div className={classes.langCtn}>
                          <Select
                            fullWidth
                            id="demo-simple-select"
                            isClearable
                            labelId="demo-simple-select-label"
                            placeholder={t('common:models.list-translation')}
                            onChange={(value) => handleChangeLanguage(value)}
                            onFocus={() => { if (props.list?.length < 1) setLoading(true) }}
                            options={lngOptions}
                            styles={selectStyle}
                            value={lang}
                          />
                        </div>
                      }
                      <button
                        className={`${classes.sous_header_button} ${classes.button_cancel}`}
                        onClick={() => handleExitPlugnote()}
                      >
                        {t("common:global.close")}
                      </button>
                      {
                        props.mode === "modal-shared-create" &&
                        <button
                          className={`${classes.sous_header_button} ${classes.button_save}`}
                          onClick={props.handleSaveCreatePlugnoteConfirm}
                        >
                          {t("common:multiple.send")}
                        </button>
                      }

                      {/* {
                        props.mode === "modal-shared-update" && (
                          !props.disabled ?
                            <button className={`${classes.sous_header_button} ${classes.button_cancel} fullWidthImportant`} onClick={props.handleExitUpdatePlugnote}>
                              {t("common:multiple.exit")}
                              {t("common:multiple.save-without")}
                              {t("common:multiple.save-off-exit")}
                            </button>
                            :
                            <button className={`${classes.sous_header_button} ${classes.button_cancel}`} onClick={props.handleExitUpdatePlugnote}>
                              {t("common:multiple.exit")}
                            </button>
                        )} */}

                      {props.mode === "modal-shared-update" &&
                        !props.disabled && (
                          <button className={`${classes.sous_header_button} ${classes.button_save} fullWidthImportant`} onClick={props.handleSaveUpdatePlugnote}>
                            {t("common:multiple.save")}{" "}
                            {t("common:multiple.save-and")}{" "}
                            {t("common:multiple.save-on-exit")}
                          </button>
                        )
                      }

                    </div>
                  </div>
                  :
                <div className={classes.sous_header_content}>
                  <div className={classes.sous_header_content_title}>
                    <span
                      className={classes.sous_header_content_title_input}>
                      {
                        props.type !== "demo"
                          ? props.environment.name + " | " + props.searchPlugcode || props.fullNamePlugnote + " | " + props.nameModel
                          : props.searchPlugcode || props.fullNamePlugnote + " | " + props.nameModel
                      }
                  </span>
                  </div>
                  <div className={classes.sous_header_content_buttons}>
                    <button
                        className={`${classes.sous_header_button} ${classes.button_cancel}`}
                        onClick={(event) => handleExitPlugnote()}
                    >
                      {t("common:global.close")}
                    </button>
                  </div>
                </div>
              }
            </div>
            {
              pfComment?.length > 0 && props.plugnote === null && openComment[0] &&
                <div className={`${classes.commentField} ${classes.fieldBorder}`} style={(openComment[1] && pnComment !== '')? {marginBottom: 10}: null}>
                  <div className={classes.commentContent}>
                    <div className={classes.commentText}>
                      <span className={classes.commentDesc}>
                        {pfComment}
                      </span>
                    </div>
                    <PlugTooltip title={"Hide comment"} placement="top" >
                      <span
                        id="icon-infos"
                        style={{ cursor: 'pointer', alignSelf: 'flex-start' }}
                        className={`${classes.mainColor} material-icons md-25`}
                        onClick={() => setOpenComment(false)}
                      >
                        visibility_off
                      </span>
                    </PlugTooltip>
                  </div>
                </div>
            }
            {!props.viewInputSearchCode && !!props.model && (pnComment?.length > 0) && openComment[1]?
                (
                    <div className={`${classes.commentField} ${classes.fieldBorder}`}>
                      <div className={classes.commentContent}>
                        <div className={classes.commentText}>
                          <PlugTooltip title={"Hide comment"} placement="top" >
                    <span
                        id="icon-infos"
                        style={{cursor: 'pointer', alignSelf: 'flex-start'}}
                        className={`${classes.mainColor} material-icons md-25`}
                        onClick={()=>setOpenComment(false)}
                    >
                      visibility_off
                    </span></PlugTooltip>
                          <TextField className={classes.commentDesc}
                                      value={pnComment}
                                      multiline
                                      style={{width: '100%'}}
                                      InputProps={{disableUnderline : true, }}
                                      disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                ):null}

            {props?.model?.authoriseShowPlugLinkData === true && props.plugnote !== null  && typeof props.plugnote !== "undefined" && typeof props.plugnote.dataHashOrigin !== "undefined" && props.plugnote.dataHashOrigin !== "" && props.plugnote.dataHashOrigin !== null &&
                <div className={classes.sous_header_content_buttons_one}>
                  <button
                      className={`${classes.sous_header_button_one} ${classes.button_save}`}
                      onClick={() => handleOpenDataOrigin(props.plugnote.dataHashOrigin)}
                  >
                    <span>{t("common:global.open-origin")}</span>
                  </button>
                </div>
            }
            </Header>
          {
            !props.viewInputSearchCode &&
              <AttachmentBanner
                annexes={props.plugnoteAnnexes}
                disabled={props.disabled}
                handleCloseModalListAnnexes={props._handleCloseModalListAnnexes}
                handleCreateNote={props.handleCreateNote}
                handleDeleteAnnexe={props.handleDeleteAnnexe}
                handleDownloadAttachment={props.downloadEmployeeData}
                handleNoteClose={props.handleNoteClose}
                handleNoteOpen={props.handleNoteOpen}
                handleOpenGallery={props._handleOpenModalListAnnexes}
                handleSaveComment={props.handleSaveFictifComment}
                handleToggleBanner={() => setOpenBanner(current => !current)}
                handleSaveNote={props.handleSaveNote}
                getAnnexeUrl={annexe => getAnnexeUrl(annexe, props.environment, props.plugnote)}
                mode={props.mode}
                onChangeHandlerDrop={props.onChangeHandlerDrop}
                open={openBanner}
                plugnote={{ title: props.nameModel, }}
                renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                renderAnnexeImagePreview={props.renderAnnexeImagePreview}
                setFileId={setFileId}
              />
          }
          <div className={classes.columnModal}>
            {
              !props.viewInputSearchCode && (
                <Content className={`${classes.contentFieldModel} ${classes.fieldBorder} scrollbar-hidden`}>
                  {typeof fields !== "undefined" &&
                  fields.length > 0 && fields
                    .map((field, index) => {
                      {/* console.log('mappage fields modal-shared', field) */}
                    let visible;
                    if (props.mode === "modal-shared-update") {
                        visible = field.encodable
                    } else visible = true

                    if (visible && field?.hasOwnProperty('format')) {
                      return (
                        <div className="flexbox-container" key={index + 1}>
                          <div className={`${classes.formatContainer}`}>
                            <span className={classes.fieldIndex} style={props.mode?.includes('modal') ? { marginLeft: 5 } : undefined}>({field.id})</span>
                            {field.visuelFormat.toLowerCase() === "now" ? (
                              <div
                                className={
                                  field.mandatory
                                    ? classes.fieldIconMandatory
                                    : classes.fieldIcon
                                }
                              >
                                <img
                                  className={classes.fieldImage}
                                  src={
                                    Images[camelCase(  field.visuelFormat?.toLowerCase()
                                    ) + "Icon"
                                    ]
                                  }
                                  alt={t(
                                    "fields.format-choice-" +
                                    field.visuelFormat.toLowerCase()
                                  )}
                                />
                              </div>
                            ) : (
                                  <img
                                      className={classes.fieldImageSvg}
                                      src={
                                        field.mandatory
                                            ? Images[camelCase(            field.visuelFormat.toLowerCase()
                                            ) + "IconSvgRed"
                                                ]
                                            : Images[camelCase(            field.visuelFormat.toLowerCase()
                                            ) + "IconSvg"
                                                ]
                                      }
                                      alt={t(
                                          "fields.format-choice-" +
                                          field.visuelFormat.toLowerCase()
                                      )}
                                  />
                              )}

                              <div className={classes.formatContent}>
                                <div className={classes.formatHeader}>
                                <PlugTooltip
                                  title={field.hasOwnProperty('infobulle') ? infobulles['field' + field.id] : ''}
                                  classes={{ tooltip: field.hasOwnProperty('infobulle') ? classes.infobulle : classes.noTooltip }} placement="bottom"
                                  >
                                  <div className={classes.numTitleFormat}>
                                    <span className={classes.formatTitleCtn}>
                                      <div className={classes.separatorHeaderFormat} />
                                      <span className={classes.titleHeaderFormat}>
                                        {field.titleTranslate}
                                      </span>
                                    </span>
                                    <div>
                                      {
                                        field.hasOwnProperty('infobulle') &&
                                        <span
                                          id="icon-file_"
                                          className="material-icons"
                                          style={{ color: colors.mainButton, fontSize: 25 }}
                                        >
                                          info
                                        </span>
                                      }
                                    </div>
                                  </div>
                                </PlugTooltip>

                                  {!specialFields.includes(field.format) && field.format === "sign" && (
                                      <div className={classes.formatInputFieldInlineLocked}>
                                        <FormatSignComponent
                                            handleEditValue={props.handleEditValue}
                                            field={field}
                                            index={index}
                                            disabled={props.disabled || field.disabled}
                                            model={props?.model || null}
                                            plugnote={props?.plugnote || null}
                                            annexes={props.plugnoteAnnexes.length > 0 ? props.plugnoteAnnexes : []}
                                            fields={fields}
                                            onChangeHandlerDrop={props.onChangeHandlerDrop}
                                            owner={props.hashOwnerModel}
                                        />
                                      </div>
                                  )}

                                  {!specialFields.includes(field.format) &&
                                  field.format === "boolean" && (
                                      <div className={(props.disabled === false || field.disabled === false) ? classes.formatInlineNoBorder : classes.formatInlineNoBorderLocked}>
                                        <FormatBooleanComponent
                                            handleChangeBoolean={
                                              props.handleChangeBoolean
                                            }
                                            field={field}
                                            index={index}
                                            disabled={props.disabled || field.disabled}
                                        />
                                      </div>
                                  )}

                                  {field.format === "pluglink" ||
                                  field.format === "plugform" ||
                                  field.format === "intdatamaj" ||
                                  field.format === "qrcodeint" ||
                                  field.format === "qrcode"
                                      ? (
                                          <div
                                              className={
                                                props.disabled === false || field.disabled === false
                                                    ? classes.formatInlineNoBorder
                                                    : classes.formatInlineNoBorderLocked
                                              }
                                          >
                                            {specialFields.includes(field.format) &&
                                            (field.format === "qrcode") && (
                                                <FormatPlugcodeComponent
                                                    handleChangePlugcode={props.handleChangePlugcode
                                                    }
                                                    field={field}
                                                    index={index}
                                                    disabled={props.disabled || field.disabled}
                                                    mode={props.mode}
                                                    owner={props.hashOwnerModel}
                                                />
                                            )}

                                            {
                                              specialFields.includes(field.format) && field.format === "qrcodeint" &&
                                              <FormatPlugcodeInternalComponent
                                                  handleChangePlugcode={props.handleChangePlugcodeInternal}
                                                  field={field}
                                                  fields={fields}
                                                  index={index}
                                                  disabled={props.disabled || field.disabled}
                                                  mode={props.mode}
                                                  owner={props.hashOwnerModel}
                                              />
                                            }

                                            {specialFields.includes(field.format) && field.format === "plugform" &&
                                                <FormatPlugformComponent
                                                    handleChangePlugform={props.handleChangePlugform}
                                                    field={field}
                                                    index={index}
                                                    disabled={props.disabled || field.disabled}
                                                    mode={props.mode}
                                                    owner={props.hashOwnerModel}
                                                />
                                            }

                                            {specialFields.includes(field.format) && field.format === "intdatamaj" &&
                                                <FormatIntDataMajComponent
                                                    handleChangeIntDataMaj={props.handleChangeIntDataMaj}
                                                    field={field}
                                                    index={index}
                                                    disabled={props.disabled || field.disabled}
                                                    owner={props.hashOwnerModel}
                                                    mode={props.mode}
                                                    dataHash={props?.plugnote?.hash}
                                                    control={props.mode !== 'modal-shared-create'}
                                                    controlUpdate={props.handleSavePlugnote}
                                                />
                                            }

                                            {!specialFields.includes(field.format) &&
                                            field.format === "pluglink" && (
                                                <FormatPlugLinkComponent
                                                    mode={props.mode}
                                                    field={field}
                                                    index={index}
                                                    handleEditValue={props.handleEditValue}
                                                    disabled={props.disabled || field.disabled}
                                                    dataHash={props?.plugnote?.hash}
                                                />
                                            )}
                                          </div>
                                      ) : null}

                                  {(field.format === "phone" ||
                                      field.format === "mail" ||
                                      field.format === "smail" ||
                                      field.format === "url" ||
                                      field.format === "localis" ||
                                      field.format === "date" ||
                                      field.format === "agenda" ||
                                      field.format === "now" ||
                                      field.format === "birthday" ||
                                      field.format === "textarea" ||
                                      field.format === "indice" ||
                                      field.format === "time" ||
                                      field.format === "formula" ||
                                      field.format === "extdata" ||
                                      field.format === "extdatan" ||
                                      field.format === "list" ||
                                      field.format === "numeric" ||
                                      field.format === "password" ||
                                      (field.format.includes("table") && field.format.includes("single") && !field.format.includes("tablemulti")) ||
                                      (field.format.includes("table") && field.format.includes("multi") && !field.format.includes("tablemulti")) ||
                                      (field.format.includes("select") && field.format.includes("single") && !field.format.includes("tablemulti")) ||
                                      (field.format.includes("select") && field.format.includes("multi") && !field.format.includes("tablemulti"))) &&
                                  <div className={ (props.disabled === false || field.disabled === false) ? classes.formatInputFieldInline : classes.formatInputFieldInlineLocked}>

                                    {specialFields.includes(field.format) && field.format === "textarea" && (
                                        <FormatMemoComponent
                                            handleEditValue={props.handleEditValue}
                                            field={field}
                                            index={index}
                                            disabled={props.disabled || field.disabled || (field?.options?.setup?.editablePf === false && !isFromPwCode) || field?.options?.setup?.editablePw === false}
                                        />)}

                                    {!specialFields.includes(field.format) && field.format === "indice" && (
                                      <FormatIndiceComponent
                                        handleEditValue={props.handleEditValue}
                                        field={field}
                                        index={index}
                                        disabled={props.disabled || field.disabled}
                                      />)}

                                    {!specialFields.includes(field.format) &&
                                    field.format === "formula" && (
                                        <FormatFormulaComponent
                                            field={field}
                                            index={index}
                                            handleEditValue={props.handleEditValue}
                                            disabled={props.disabled || field.disabled}
                                            model={props.model}
                                            fields={fields}
                                            dataHasChanged={props.dataHasChanged}
                                        />
                                    )}

                                    {specialFields.includes(field.format) &&
                                    field.format.includes("select") &&
                                    field.format.includes("multi") &&
                                    !field.format.includes("tablemulti") && (
                                        <FormatMultipleSelectComponent
                                            handleChangeRadio={
                                              props.handleChangeRadio
                                            }
                                            field={field}
                                            index={index}
                                            disabled={props.disabled || field.disabled}
                                            expand={false}
                                        />
                                    )}

                                    {specialFields.includes(field.format) &&
                                    field.format.includes("select") &&
                                    field.format.includes("single") && (
                                        <FormatSingleSelectComponent
                                            handleChangeRadio={
                                              props.handleChangeRadio
                                            }
                                            field={field}
                                            index={index}
                                            disabled={props.disabled || field.disabled}
                                            expand={false}
                                        />
                                    )}

                                    {specialFields.includes(field.format) &&
                                    field.format.includes("table") &&
                                    field.format.includes("multi") &&
                                    !field.format.includes("tablemulti") && (
                                        <FormatMultipleSelectTableComponent
                                            handleChangeRadio={
                                              props.handleChangeRadioTable
                                            }
                                            handleChangeInputTable={
                                              props.handleChangeInputTable
                                            }
                                            handleUpdateInputTable={
                                              props.handleUpdateInputTable
                                            }
                                            handleDeleteInputTable={
                                              props.handleDeleteInputTable
                                            }
                                            field={field}
                                            index={index}
                                            disabled={props.disabled || field.disabled}
                                            expand={false}
                                        />
                                    )}

                                    {specialFields.includes(field.format) &&
                                    field.format.includes("table") &&
                                    field.format.includes("single") && (
                                        <FormatSingleSelectTableComponent
                                            handleChangeRadio={
                                              props.handleChangeRadioTable
                                            }
                                            handleChangeInputTable={
                                              props.handleChangeInputTable
                                            }
                                            handleUpdateInputTable={
                                              props.handleUpdateInputTable
                                            }
                                            handleDeleteInputTable={
                                              props.handleDeleteInputTable
                                            }
                                            field={field}
                                            index={index}
                                            disabled={props.disabled || field.disabled}
                                            expand={false}
                                        />
                                    )}

                                    {specialFields.includes(field.format) &&
                                    field.format === "list" && (
                                        <FormatKeywordComponent
                                            handleChangeKeyword={
                                              props.handleChangeKeyword
                                            }
                                            field={field}
                                            index={index}
                                            disabled={props.disabled || field.disabled}
                                        />
                                    )}
                                    {!specialFields.includes(field.format) &&
                                    field.format === "phone" && (
                                        <FormatPhoneComponent
                                            field={field}
                                            index={index}
                                            handleEditPhoneValue={
                                              props.handleEditPhoneValue
                                            }
                                            disabled={props.disabled || field.disabled}
                                        />
                                    )}

                                    {!specialFields.includes(field.format) &&
                                    field.format === "mail" && (
                                        <FormatMailComponent
                                            field={field}
                                            index={index}
                                            handleEditValue={props.handleEditValue}
                                            disabled={props.disabled || field.disabled}
                                        />
                                    )}

                                    {!specialFields.includes(field.format) && field.format === "smail" && (
                                    <FormatSMailComponent
                                      field={field}
                                      fields={fields}
                                      index={index}
                                      handleEditValue={props.handleEditValue}
                                      disabled={
                                        props.disabled || field.disabled ||
                                        field?.options?.setup?.editable === false}
                                      mode={props.mode}
                                      model={props?.model || null}
                                      plugnote={props?.plugnote || null}
                                      annexes={props.plugnoteAnnexes.length > 0 ? props.plugnoteAnnexes : []}
                                      renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                      owner={props.hashOwnerModel}
                                    />
                                    )}

                                    {!specialFields.includes(field.format) &&
                                    field.format === "url" && (
                                        <FormatUrlComponent
                                            field={field}
                                            index={index}
                                            handleEditValue={props.handleEditValue}
                                            disabled={props.disabled || field.disabled || (field?.options?.setup?.editablePf === false && !isFromPwCode) || field?.options?.setup?.editablePw === false}
                                        />
                                    )}

                                    {specialFields.includes(field.format) &&
                                    field.format === "localis" && (
                                        <FormatLocalisationComponent
                                            handleChangeLocalisation={
                                              props.handleChangeLocalisation
                                            }
                                            field={field}
                                            index={index}
                                            disabled={props.disabled || field.disabled}
                                        />
                                    )}

                                    {!specialFields.includes(field.format) &&
                                    field.format === "date" && (
                                        <div className={classes.fieldRight}>
                                          {/*<FormatDateComponent
                                              field={field}
                                              handleEditDateTime={
                                                props.handleEditDateTime
                                              }
                                              index={index}
                                              disabled={props.disabled || field.disabled}
                                          />*/}
                                          <FormatTestDateComponent
                                              field={field}
                                              handleEditDateTime={
                                                props.handleEditDateTime
                                              }
                                              index={index}
                                              disabled={props.disabled || field.disabled}
                                          />
                                        </div>
                                    )}
                                    {!specialFields.includes(field.format) &&
                                    field.format === "agenda" && (
                                        <div className={classes.fieldRight}>
                                          <FormatAgendaComponent
                                              field={field}
                                              handleEditDateTime={
                                                props.handleEditDateTime
                                              }
                                              index={index}
                                              disabled={props.disabled || field.disabled}
                                          />
                                        </div>
                                    )}

                                    {!specialFields.includes(field.format) &&
                                    field.format === "now" && (
                                        <div className={classes.fieldRight}>
                                          <FormatNowComponent
                                              field={field}
                                              handleEditDateTime={
                                                props.handleEditDateTime
                                              }
                                              index={index}
                                              disabled={props.disabled || field.disabled}
                                          />
                                        </div>
                                    )}

                                    {!specialFields.includes(field.format) &&
                                    field.format === "birthday" && (
                                        <div className={classes.fieldRight}>
                                          <FormatBirthdayComponent
                                              field={field}
                                              handleEditDateTime={
                                                props.handleEditDateTime
                                              }
                                              index={index}
                                              disabled={props.disabled || field.disabled}
                                          />
                                        </div>
                                    )}

                                    {!specialFields.includes(field.format) &&
                                    field.format === "time" && (
                                        <div className={classes.fieldRight}>
                                          <FormatTimeComponent
                                              field={field}
                                              handleEditDateTime={
                                                props.handleEditDateTime
                                              }
                                              index={index}
                                              disabled={props.disabled || field.disabled}
                                          />
                                        </div>
                                    )}

                                    {!specialFields.includes(field.format) &&
                                    field.format === "numeric" && (

                                      <FormatNumericComponent
                                        field={field}
                                        index={index}
                                        handleEditValue={props.handleEditValue}
                                        disabled={props.disabled || field.disabled}
                                      />
                                    )}
                                    {!specialFields.includes(field.format) &&
                                    field.format === "password" && (
                                        <FormatPasswordComponent
                                            handleChangePassword={
                                              props.handleChangePassword
                                            }
                                            field={field}
                                            index={index}
                                            disabled={props.disabled || field.disabled}
                                            isFromPlugFormCode={props.mode === "modal-shared-create"}
                                        />
                                    )}
                                    {specialFields.includes(field.format) && field.format === "extdata" && (
                                        <FormatExtDataComponent
                                            ownerId={props.model.owner}
                                            fields={fields}
                                            handleChangeExtData={props.handleChangeExtData}
                                            handleEditValue={props.handleEditValue}
                                            field={field}
                                            index={index}
                                            disabled={props.disabled || field.disabled}
                                            owner={props.hashOwnerModel}
                                            title={field.titleTranslate}
                                            f28={true}
                                        />
                                    )}
                                    {specialFields.includes(field.format) && field.format === "extdatan" && (
                                        <FormatExtDatanComponent
                                            ownerId={props.model.owner}
                                            fields={fields}
                                            handleChangeExtDatan={props.handleChangeExtDatan}
                                            handleEditValue={props.handleEditValue}
                                            field={field}
                                            index={index}
                                            disabled={props.disabled || field.disabled}
                                            owner={props.hashOwnerModel}
                                            title={field.titleTranslate}
                                        />)}

                                  </div>
                                  }

                                  {field.format === "text" &&
                                  <div className={(props.disabled === false || field.disabled === false) ? classes.formatInputFieldInline : classes.formatInputFieldInlineLocked}>
                                    <TextField
                                      onChange={(event) => props.handleEditValue(event.target.value, field, index)}
                                      style={{ margin: 0 }}
                                      placeholder={t("fields.format-choice-placeholder-" + field.visuelFormat.toLowerCase())}
                                      //helperText=""
                                      fullWidth
                                      margin="normal"
                                      InputLabelProps={{ shrink: true }}
                                      inputProps={{ maxLength: 40 }}
                                      value={field.value}
                                      InputProps={{ disableUnderline: true }}
                                      disabled={props.disabled || field.disabled || (field?.options?.setup?.editablePf === false && !isFromPwCode) || field?.options?.setup?.editablePw === false}
                                    />
                                    {field.value.length > 39 &&
                                      <div
                                        className={classes.visibilityIcon}
                                        onClick={(e) => handleFormatInput(e, field.titleTranslate, field.value)}
                                      >
                                        <span className="material-icons">
                                          visibility_on
                                        </span>
                                      </div>
                                    }
                                  </div>
                                }
                              </div>

                                {
                                  field.format === "smailvalidmulti" ||
                                  field.format === "tablemulti" ||
                                  field.format === "tablemultin" ||
                                  field.format === "document" ||
                                  field.format === "glocalis" ? (
                                    <div
                                        className={ props.disabled === false || field.disabled === false
                                          ? classes.formatInputFieldComposed
                                          : classes.formatInputFieldLockedComposed
                                        }
                                    >
                                      {specialFields.includes(field.format) &&
                                      field.format === "glocalis" && (
                                        <div
                                          className={ props.disabled === false
                                            ? classes.formatInputField
                                            : classes.formatInputFieldLocked
                                          }
                                        >
                                          <FormatGeoLocalisationComponent
                                            handleChangeLocalisation={props.handleChangeLocalisation}
                                            field={field}
                                            index={index}
                                            disabled={props.disabled || field.disabled}
                                          />
                                        </div>
                                      )}
                                      {specialFields.includes(field.format) && field.format === "tablemulti" &&
                                          <FormatTableMultipleComponent
                                              handleChangeTableValue={props.handleChangeTableValue}
                                              handleSafeExitPlugnote={handleSafeExitPlugnote}
                                              hash={props.plugnote?.hash}
                                              field={field}
                                              index={index}
                                              disabled={props.disabled || field.disabled}
                                              mode={props.mode}
                                              owner={props.hashOwnerModel}
                                              title={field.titleTranslate}
                                              ownerId={props.model.owner}
                                              model={props.model}
                                              plugNb={props.plugnote?.userindex}
                                              dataHash={props?.plugnote?.hash}
                                              control={props.mode !== 'modal-shared-create'}
                                              controlUpdate={props.handleSavePlugnote}
                                          />}

                                      {
                                        field.format === "tablemultin" && specialFields.includes(field.format) &&
                                        <FormatTableMultiplenComponent
                                          handleChangeTableValue={props.handleChangeTableRowsValue}
                                          handleSafeExitPlugnote={handleSafeExitPlugnote}
                                          hash={props.plugnote?.hash}
                                          field={field}
                                          index={index}
                                          fields={fields}
                                          disabled={props.disabled || field.disabled}
                                          mode={props.mode}
                                          model={props.model}
                                          owner={props.hashOwnerModel}
                                          title={field.titleTranslate}
                                          ownerId={props.model.owner}
                                          plugNb={props.plugnote?.userindex}
                                          handleChangeExtData={props.handleChangeExtData}
                                          dataHash={props?.plugnote?.hash}
                                          control={props.mode !== 'modal-shared-create'}
                                          controlUpdate={props.handleSavePlugnote}
                                          />
                                      }

                                      {specialFields.includes(field.format) && field.format === "smailvalidmulti" &&
                                      <div className={classes.formatInputFieldComposed}>
                                        <FormatSMailValidMultiComponent
                                          annexes={props.plugnoteAnnexes.length > 0 ? props.plugnoteAnnexes : []}
                                          disabled={props.disabled || field.disabled}
                                          field={field}
                                          fields={fields}
                                          handleChangeTableValue={props.handleChangeTableRowsValue}
                                          handleEditValue={props.handleEditValue}
                                          handleSafeExitPlugnote={handleSafeExitPlugnote}
                                          index={index}
                                          mode={props.mode}
                                          owner={props.hashOwnerModel}
                                          ownerId={props.model.owner}
                                          model={props?.model || null}
                                            plugNb={props.plugnote?.userindex}
                                          plugnote={props?.plugnote || null}
                                          renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                          title={field.titleTranslate}
                                          control={props.mode !== 'modal-shared-create'}
                                          controlUpdate={props.handleSavePlugnote}
                                        />
                                      </div>}

                                      {specialFields.includes(field.format) && field.format === "document" &&
                                        <div className={classes.formatInputFieldComposed}>
                                          <FormatDocumentComponent
                                            annexes={props.plugnoteAnnexes.length > 0 ? props.plugnoteAnnexes : []}
                                            disabled={props.disabled || field.disabled}
                                            field={field}
                                            fields={fields}
                                            handleChangeTableValue={props.handleChangeTableRowsValue}
                                            handleEditValue={props.handleEditValue}
                                            handleSafeExitPlugnote={handleSafeExitPlugnote}
                                            handleSaveComment={props.handleSaveFictifComment}
                                            index={index}
                                            mode={props.mode}
                                            owner={props.hashOwnerModel}
                                            ownerId={props.model.owner}
                                            model={props?.model || null}
                                            plugNb={props.plugnote?.userindex}
                                            plugnote={props?.plugnote || null}
                                            renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                                            title={field.titleTranslate}
                                            control={props.mode !== 'modal-shared-create'}
                                            controlUpdate={props.handleSavePlugnote}
                                          />
                                        </div>}
                                    </div>
                                ) : null}

                              </div>
                            </div>
                          </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Content>
            )}

            {
            /*
            <Content className={`${classes.attField} ${classes.fieldBorder} scrollbar-hidden`} >
              {
                props.disabled === false &&
                <div
                  className={`${classes.fieldTitle} ${classes.attachmentTitle} cursor icon-style`}
                  onClick={(event) => props.handleCreateNote(event)}
                >
                  {t("common:plugnote.add-attachment-note")}
                  <div>
                    <MdNoteAdd style={{ fontSize: 20, color: "black" }} />
                  </div>
                </div>
              }
              {
                props.disabled === false &&
                <div className={`${classes.fieldTitle} ${classes.attachmentTitle}`} >
                  {t("multiple.add-attachments")}
                </div>
              }
              <Dropzone onDrop={(acceptedFiles) => handleDropdownFiles(acceptedFiles)} >
                {({ getRootProps, getInputProps }) => (
                  <section className="">
                    <div {...getRootProps()}>
                      {props.disabled === false &&
                      <input {...getInputProps()} />
                      }
                      {props.disabled === false &&
                      <div className={classes.attachmentBox}>
                        <span
                          id="icon-file_copy"
                          className={`material-icons md ${classes.attIcon}`}
                          >
                          attach_file
                        </span>
                        <span
                          id="icon-file_copy"
                          className={`material-icons md ${classes.attIconShadow}`}
                          >
                          attach_file
                        </span>
                        <span className={classes.mainColor} style={{ cursor: "pointer"}}>
                          {t("multiple.attachement-drag-and-drop")}
                        </span>
                      </div>
                      }
                      <div
                          className={`${classes.fieldTitle} ${classes.attachmentTitle}`}
                      >
                        {t("multiple.current-attachments")}{" "}
                      </div>
                      {props.plugnoteAnnexes.length > 0 ?
                      <div className={classes.attContainer}>
                        {props.plugnoteAnnexes.map((annexe, index) => {
                          return (
                          <div
                            className={classes.itemContainer}
                            key={index + 1}
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                            }}
                            >
                            <div className={classes.attItem}>
                              <div
                                  className={classes.attIconType}
                                  onClick={(event) => {
                                    props._handleOpenModalListAnnexes();
                                    setFileId(index);
                                  }}
                              >
                                {index+1} {props.renderAnnexeIconPreview(annexe)}
                              </div>

                              <div
                                  className={classes.attAnnexName}
                                  onClick={(event) => {
                                    props._handleOpenModalListAnnexes();
                                    setFileId(index);
                                  }}
                              >
                                {
                                  annexe.comment !== undefined && annexe.comment !== "" ?
                                  annexe.comment : annexe.name}
                              </div>

                              <div className={classes.attIconDlInfo}>
                                {
                                  annexe.type === "note" &&
                                  <span
                                    id="icon-file_copy"
                                    className="material-icons md-25"
                                    onClick={(event) => props.handleNoteOpen(event, annexe)}
                                    style={{color: colors.black}}
                                    >
                                    create
                                  </span>
                                }

                                {annexe.countVersion > 0 && (
                                  <span
                                      id="icon-file_copy"
                                      className={classes.pointer}
                                      onClick={(event) =>
                                          props.handleModalListAnnexesVersionOpen(
                                              event,
                                              annexe.hash
                                          )
                                      }
                                        >
                                    ({annexe.countVersion})
                                  </span>
                              )}

                                <div className="">
                                  {annexe.groupid !== 0 ? (
                                      <span>{annexe.groupid}</span>
                                  ) : (
                                      <span />
                                  )}
                                </div>

                                {
                                  props.disabled === false &&
                                  <span
                                    id="icon-file_"
                                    className="material-icons"
                                    onClick={(event) => props.handleDeleteAnnexe(event, annexe)}
                                    style={{ color: colors.black }}
                                  >
                                    delete
                                  </span>
                                }
                              </div>
                            </div>
                          </div>
                          )})}
                        <div style={{textAlign: "center"}}>
                          * * *
                        </div>
                      </div>
                      :
                      <span>{t("multiple.no-attachments")}</span>
                      }
                    </div>
                  </section>
                )}
              </Dropzone>
              {
                props.plugnoteAnnexes.length > 0 && props.isOpenModalListAnnexes &&
                <ModalTestAnnexeGallery
                  modal={props.modal}
                  mode={props.mode}
                  locked={props.disabled}
                  open={props.isOpenModalListAnnexes}
                  close={props._handleCloseModalListAnnexes}
                  annexes={props.plugnoteAnnexes}
                  isAuthorisedToEdit={props.isAuthorisedToEdit}
                  downloadEmployeeData={props.downloadEmployeeData}
                  handleUpdateAnnexe={props.handleUpdateAnnexe}
                  handleModalAnnexeOpen={props.handleModalAnnexeOpen}
                  handleNoteOpen={props.handleNoteOpen}
                  handleDeleteAnnexe={props.handleDeleteAnnexe}
                  handleModalListAnnexesVersionOpen={
                    props.handleModalListAnnexesVersionOpen
                  }
                  handlePreviewPdfAnnexe={props.handlePreviewPdfAnnexe}
                  fileId={fileId}
                  renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                  renderAnnexeImagePreview={props.renderAnnexeImagePreview}
                  isOpenModalAnnexe={props.isOpenModalAnnexe}
                  selectedAnnexe={props.selectedAnnexe}
                  handleModalAnnexeClose={props.handleModalAnnexeClose}
                  handleSaveFictifComment={props.handleSaveFictifComment}
                />
              }
            </Content>*/
            }

            {/* to open the modal for send*/}
            {
              props.openClassify && !props.viewInputSearchCode &&
              <ModalSavePlugcodeComponent
                  open={props.openClassify}
                  close={props.handleCloseClassify}
                  history={props.history}
              />
            }

            {
              props.openSave && !props.viewInputSearchCode &&
                <ModalConfirmComponent
                    open={props.openSave}
                    close={props.handleCloseSave}
                    handleChangeEmail={props.handleChangeEmail}
                    handleChangeSendCopy={props.handleChangeSendCopy}
                    handleSaveCreatePlugnoteConfirm={props.handleSaveCreatePlugnoteConfirm}
                    copy={props.copy}
                    emailValid={props.emailValid}
                    email={props.email}
                />
            }
          </div>
          </div>
        {
            view &&
            <ModalViewFicheComponent
                mode={props.mode}
                open={view}
                dataHash={props?.plugnote?.dataHashOrigin}
                close={() => setView(false)}
            />
        }
      </div>
      );

    default:
      break;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    token: state.user.token,
    list: !!state.translate ? state.translate.list : [],
    type: !!state.user && !!state.user.user ? state.user.user.type : null,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    attemptGetAllTranslations: (modelhash, userhash) =>
        dispatch(getAllTranslationsByModelRequest(modelhash, userhash)),
    attemptGetAllTranslationsOffline: (modelhash, userhash) =>
        dispatch(getAllTranslationsByModelOfflineRequest(modelhash, userhash)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Plugforms);
