// @flow

const colors = {
  background: '#1F0808',
  black: '#000000',
  blackAlpha: 'rgba(0,0,0, 0.6)',
  bloodOrange: '#fb5f26',
  bloodAlpha: '#fb5f264a',
  blueHeader : '#334d5c',
  borderGrey: '#c5c5c5',
  charcoal: '#595959',
  checkbox: '#EF0047',
  clear: 'rgba(0,0,0,0)',
  cloud: 'rgba(200,200,200, 0.35)',
  coal: '#2d2d2d',
  dark: '#4a4a4a',
  darkgrey : '#A9A9A9',
  darkSilver: '#7f7f7f',
  disabled: '#ddd',
  drawer: 'rgba(30, 30, 29, 0.95)',
  ember: 'rgba(164, 0, 48, 0.5)',
  error: 'rgba(200, 0, 0, 0.8)',
  facebook: '#3b5998',
  frost: '#D8D8D8',
  fire: '#e73536',
  grey: '#9B9B9B',
  greyDark: '#686868',
  greyDark2: '#88949C',
  greyLighter: '#F9F9F9',
  greyLight: '#E3E3E3',
  greyPale: "#E2E2E2",
  greenLight: '#7ED321',
  greenLighter: '#7ED321',
  greenLightest: '#c5e4e3',
  hoverBgnd: '#F7F9FA',
  iconGrey: '#cacaca',
  labelGrey:'rgba(0, 0, 0, 0.54)',
  main: '#34b7bb',
  mainAlpha: '#58c3c1dd',
  mainButton: '#58c3c1',
  mainDark: '#16868c',
  mainLight: '#44c9cd',
  mainMedium: '#20959D',
  mainPale: '#58c3c170',
  mandatory: '#E54F62',
  olive: '#32322C',
  paleGrey: '#e0e0e0',
  palePlugGreen: '#B9E5E4',
  panther: '#161616',
  plugformBlue: '#1675e0',
  plugnotesDark: '#22969D',
  plugnotesGreen: '#27858b',
  plugnotesLight : '#51C3C1',
  plugnotesLightAlpha : '#51C3C13C',
  plugnotesError : '#E2574C',
  plugnotesOrangeLight : '#ff892c',
  plugnotesBlueLight : '#0abde3',
  plugnotesBlueApp : '#384C5A',
  plugnotesGreenLighter: '#16b84e',
  plugnotesGris : '#88949C',
  orangeLight: '#ff892c',
  pink: '#EF0047',
  red: '#ff0000',
  redDark: '#c00000',
  ricePaper: 'rgba(255,255,255, 0.75)',
  // selectedAlpha : 'rgba(32, 149, 157, 0.8)',
  selectedRow : '#00a9b54a',
  selectedMain: '#34b7bb',
  selectedAlpha: '#34b6bbcc',
  silver: '#F7F7F7',
  snow: 'white',
  steel: '#CCCCCC',
  titleGrey: '#0000008a',
  transparent: 'rgba(0,0,0,0)',
  unselected : 'rgba(56, 76, 90, 1)',
  unselectedAlpha : 'rgba(56, 76, 90, 0.93)',
  unselectedPale : 'rgba(56, 76, 90, 0.6)',
  white: '#ffffff',
  whiteAlpha: 'rgba(255,255,255,0.8)',
  windowTint: 'rgba(0, 0, 0, 0.4)',
}

export default colors
