import { store } from "../store";
import { getCurrentEnvironnement, getModel, getPlugnote, getToken, setPlugnote } from "../utils/Common";
import { isNull } from "lodash";
import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import ApplicationConfig from "../config/ApplicationConfig";
import qs from "querystringify";
import * as moment from "moment/moment";
import { postGeneratePlugnotecodeRequest } from "../actions/plugcode";
import { postCreateDataWithoutRedirectRequest, putUpdateDataWithoutRedirectRequest } from "../actions/data";
import { hasMissingMandatoryValue } from "../utils/Plugnotes";


export default class Plugform {


    constructor(mode, fields) {
        this.mode = mode;
        this.fields = fields;
    }

    #value = () => {
        //console.log('mode',this.mode);
        //console.log('fields',this.fields);
    }

    #buildNewAnnexe = () => {
        let newData = {};

        const modelForHash = getModel();

        this.fields.map((field) => {
            if (field.format === "multi-select" || field.format === "free-multi-select" || field.format === "single-select" || field.format === "free-single-select") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    newData["field" + field.id] = JSON.stringify(field.value);
                } else {
                    newData["field" + field.id] = JSON.stringify([field.value]);
                }
            } else if (field.format === "multi-table" || field.format === "free-multi-table" || field.format === "single-table" || field.format === "free-single-table") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    newData["field" + field.id] = JSON.stringify(field.value);
                } else {
                    newData["field" + field.id] = JSON.stringify([field.value]);
                }
            } else if (field.format === "plugform") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if (field.format === "qrcodeint") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = JSON.stringify({ "code": "", "model": "", "data": "", "comment": "" });
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            } else if (field.format === "intdatamaj") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = JSON.stringify({ "value": "", "dataUpdated": "" });
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if (field.format === "list") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    let correctValue = [];
                    field.value.map((item, index) => {
                        correctValue.push({ text: item });
                    });
                    newData["field" + field.id] = JSON.stringify(correctValue);
                } else {
                    let transformValue = [field.value];
                    let correctValue = [];
                    transformValue.map((item, index) => {
                        correctValue.push({ text: item });
                    });
                    newData["field" + field.id] = correctValue;
                }
            }
            else if (field.format === "tablemulti") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if (field.format === "tablemultin") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = { "value": "", "list": [] };
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if (field.format === "document") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = { "value": "", "list": [] };
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if (field.format === "smailvalidmulti") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = { "value": "", "list": [] };
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else {
                newData["field" + field.id] = field.value;
            }

            if (
                field.format === "multi-select" ||
                field.format === "free-multi-select" ||
                field.format === "single-select" ||
                field.format === "free-single-select"
            ) {
                newData["field" + field.id + "format"] = "select";
            } else if (
                field.format === "multi-table" ||
                field.format === "free-multi-table" ||
                field.format === "single-table" ||
                field.format === "free-single-table"
            ) {
                newData["field" + field.id + "format"] = "table";
            } else {
                newData["field" + field.id + "format"] = field.format;
            }

            if (modelForHash && field.format === "indice") {
                let editable = JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.editable || false;
                if (!editable && field.value === "" || (editable && field.value === "")) {
                    newData["field" + field.id] = (JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.racine || "") + (JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.indice || 0);
                }
            }

            if (
                (field.format && field.format === "agenda") ||
                (field.format && field.format === "birthday")
            ) {
                newData["field" + field.id + "options"] = field.event;
            }

            newData["field" + field.id + "label"] = field.title;
        });
        newData.Annexes = [];
        newData.lastversion_model = modelForHash ? modelForHash.version : null;
        newData.modid = modelForHash.id;
        newData.offline = modelForHash.offline;

        return newData;
    };

    #buildUpdateAnnexe = () => {
        let newData = getPlugnote();
        const modelForHash = getModel();

        this.fields.map((field) => {
            if (field.format === "multi-select" || field.format === "free-multi-select"
                || field.format === "single-select" || field.format === "free-single-select") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    newData["field" + field.id] = JSON.stringify(field.value);
                } else {
                    newData["field" + field.id] = JSON.stringify([field.value]);
                }
            } else if (field.format === "multi-table" || field.format === "free-multi-table"
                || field.format === "single-table" || field.format === "free-single-table") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    newData["field" + field.id] = JSON.stringify(field.value);
                } else {
                    newData["field" + field.id] = JSON.stringify([field.value]);
                }
            } else if (field.format === "plugform") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            } else if (field.format === "qrcodeint") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = JSON.stringify({ "code": "", "model": "", "data": "", "comment": "" });
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            } else if (field.format === "intdatamaj") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = JSON.stringify({ "value": "", "dataUpdated": "" });
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if (field.format === "list") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    let correctValue = [];
                    field.value.map((item, index) => {
                        correctValue.push({ "text": item })
                    });
                    newData["field" + field.id] = JSON.stringify(correctValue);
                } else {
                    let transformValue = [field.value];
                    let correctValue = [];
                    transformValue.map((item, index) => {
                        correctValue.push({ "text": item })
                    });
                    newData["field" + field.id] = correctValue;
                }
            }
            else if (field.format === "tablemulti") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if (field.format === "tablemultin") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = { "value": "", "list": [] };
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if (field.format === "document") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = { "value": "", "list": [] };
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if (field.format === "smailvalidmulti") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = { "value": "", "list": [] };
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else {
                newData["field" + field.id] = field.value;
            }

            if (field.format === "multi-select" || field.format === "free-multi-select"
                || field.format === "single-select" || field.format === "free-single-select") {
                newData["field" + field.id + "format"] = "select";
            } else if (field.format === "multi-table" || field.format === "free-multi-table"
                || field.format === "single-table" || field.format === "free-single-table") {
                newData["field" + field.id + "format"] = "table";
            } else {
                newData["field" + field.id + "format"] = field.format;
            }

            if (modelForHash && field.format === "indice") {
                let editable = JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.editable || false;
                if (!editable && field.value === "" || (editable && field.value === "")) {
                    newData["field" + field.id] = (JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.racine || "") + (JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.indice || 0);
                }
            }

            if (field.format && field.format === 'agenda' || field.format && field.format === 'birthday') {
                newData["field" + field.id + "options"] = field.event;
            }

            newData["field" + field.id + "label"] = field.title;
        });
        newData.Annexes = [];
        newData.lastversion_model = modelForHash ? modelForHash.version : null;
        newData.modid = modelForHash.id;
        newData.offline = modelForHash.offline;

        return newData;
    };

    createOrUpdatePlugnoteGeneratePnCodeAndRefreshBody = () => {


        return new Promise((resolve, reject) => {
            if (this.mode === "create" || this.mode === "update") {
                if (isNull(getPlugnote())) {
                    //store.dispatch(postCreateDataWithoutRedirectRequest(getModel().hash, environment.userhash, this.buildNewAnnexe(this.fields)));

                    trackPromise(
                        axios.post(ApplicationConfig.apiUrl + `datas.json` + qs.stringify({ userhash: getCurrentEnvironnement().userhash, modelhash: getModel().hash }, '?') + `&internal=${getCurrentEnvironnement().userhash}`, this.#buildNewAnnexe()
                            , {
                                crossdomain: true,
                                'headers': {
                                    'X-AUTH-TOKEN': getToken(),
                                }
                            })
                            .then((response) => {
                                if (response.status === 200) {
                                    const currentPlugnote = response.data;
                                    setPlugnote(currentPlugnote);
                                    if (typeof currentPlugnote.pncode === 'undefined' || currentPlugnote.pncode === null || currentPlugnote.pncode == "") {
                                        let data = {
                                            comment: "",
                                            dh: currentPlugnote.hash
                                        };

                                        let typePnCodeNumber = "1";

                                        const payload = {
                                            ...data,
                                            uh: getCurrentEnvironnement().userhash,
                                            public: typePnCodeNumber
                                        }

                                        //postGeneratePlugcodePlugnote = (payload) =>  api.post('pncode/generatepns.json', payload);

                                        trackPromise(
                                            axios.post(ApplicationConfig.apiUrl + `pncode/generatepns.json`, payload
                                                , {
                                                    crossdomain: true,
                                                    'headers': {
                                                        'X-AUTH-TOKEN': getToken(),
                                                    }
                                                })
                                                .then((response) => {
                                                    if (response.data.result === 'ok' || response.data.result === true) {
                                                        trackPromise(
                                                            axios.get(ApplicationConfig.apiUrl + 'datauniques/' + currentPlugnote.hash + '.json', {
                                                                crossdomain: true,
                                                                'headers': {
                                                                    'X-AUTH-TOKEN': getToken(),
                                                                }
                                                            }).then((response) => {
                                                                if (response.statusText == "OK" && response.data && response.data.id) {
                                                                    const currentPlugnote = response.data;
                                                                    setPlugnote(currentPlugnote);
                                                                    resolve(true);
                                                                } else {
                                                                    resolve(false);
                                                                }
                                                            }).catch((response) => {
                                                                resolve(false);
                                                            })
                                                        )
                                                    }
                                                })
                                                .catch((response) => {
                                                    resolve(false);
                                                })
                                        )
                                    }
                                    else {
                                        setPlugnote(currentPlugnote);
                                        resolve(true);
                                    }
                                }
                                else resolve(false);
                            })
                            .catch((response) => {
                                resolve(false);
                            })
                    )


                }
                else if (!isNull(getPlugnote())) {
                    trackPromise(
                        axios.put(ApplicationConfig.apiUrl + `datas.json` + qs.stringify({ userhash: getCurrentEnvironnement().userhash, modelhash: getModel().hash }, '?') + `&internal=${getCurrentEnvironnement().userhash}`, this.#buildUpdateAnnexe()
                            , {
                                crossdomain: true,
                                'headers': {
                                    'X-AUTH-TOKEN': getToken(),
                                }
                            })
                            .then((response) => {
                                if (response.status === 200) {
                                    const currentPlugnote = response.data;
                                    if (typeof currentPlugnote.pncode === 'undefined' || currentPlugnote.pncode === null || currentPlugnote.pncode == "") {
                                        let data = {
                                            comment: "",
                                            dh: currentPlugnote.hash
                                        };

                                        let typePnCodeNumber = "1";

                                        const payload = {
                                            ...data,
                                            uh: getCurrentEnvironnement().userhash,
                                            public: typePnCodeNumber
                                        }

                                        //postGeneratePlugcodePlugnote = (payload) =>  api.post('pncode/generatepns.json', payload);

                                        trackPromise(
                                            axios.post(ApplicationConfig.apiUrl + `pncode/generatepns.json`, payload
                                                , {
                                                    crossdomain: true,
                                                    'headers': {
                                                        'X-AUTH-TOKEN': getToken(),
                                                    }
                                                })
                                                .then((response) => {
                                                    if (response.data.result === 'ok' || response.data.result === true) {
                                                        trackPromise(
                                                            axios.get(ApplicationConfig.apiUrl + 'datauniques/' + getPlugnote().hash + '.json', {
                                                                crossdomain: true,
                                                                'headers': {
                                                                    'X-AUTH-TOKEN': getToken(),
                                                                }
                                                            }).then((response) => {
                                                                if (response.statusText == "OK" && response.data && response.data.id) {
                                                                    const currentPlugnote = response.data;
                                                                    setPlugnote(currentPlugnote);
                                                                    resolve(true);
                                                                } else {
                                                                    resolve(false)
                                                                }
                                                            }).catch((response) => {
                                                                resolve(false);
                                                            })
                                                        )
                                                    }
                                                })
                                                .catch((response) => {
                                                    resolve(false);
                                                })
                                        )
                                    }
                                    else {
                                        setPlugnote(currentPlugnote);
                                        resolve(true);
                                    }

                                }
                                else {
                                    resolve(false)
                                };
                            })
                            .catch((response) => {
                                resolve(false);
                            })
                    )
                }
                else {
                    resolve(false)
                };
            }
            else {
                resolve(false);
            }
        });

    }
}
