import React, { useState } from 'react'
import { Drawer, IconButton, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'
import { PlugTooltip } from '../../components/custom/CustomComponents'
import { AttachFile, ChevronLeft, ChevronRight } from '@material-ui/icons'
import { Content } from 'rsuite'
import { MdNoteAdd } from 'react-icons/md'
import Dropzone from 'react-dropzone'
import colors from '../../assets/themes/Colors'
import { handlePreventDefault } from '../../utils/utils'
import { fileIsImg } from '../../utils/annexes'

const drawerWidth = 300
const useStyles = makeStyles((theme) => ({
  borderedCtn: {
    border: "2px solid " + colors.greyLight,
    borderRadius: 5,
    "@media (max-width: 1024px)": {
      border: "none",
    },
    padding: 15,
    paddingBottom: 20,
    position: 'relative',
    width: '-webkit-fill-available',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    // width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    // '@media (max-width: 1000px)': {
    //   width: 200,
    // }
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 80,
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  openClose: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  openCloseTop: {
    top: '80px',
    //bottom: '-20px',
    //position: 'absolute',
    width: '100%'
  },
  openCloseButton: {
    float: 'left',
    padding: '8px 4px',
    cursor: 'pointer',
  },
  slider: {
    width: 8,
    height: '100vh',
    backgroundColor: colors.mainButton,
    '&:hover': {
      backgroundColor: colors.unselectedAlpha,
      cursor: 'e-resize',
    }
  },
  paper: {
    display: 'block !important',
    overflow: 'hidden !important'
  },
  paperAnchorDockedLeft: {
    boxShadow: '0px 0px 6px 4px rgba(136, 136, 136, 0.12)',
    border: 0,
    backgroundColor: colors.mainButton,
  },
  dropzoneCtn: {
    paddingLeft: 10,
    alignSelf: 'flex-start',
  },
  attField: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    "@media (max-width: 1300px)": {
      width: "100%",
      padding: '10px 0px',
    },
  },
  attachmentTitle: {
    color: colors.unselected,
    fontWeight: "600",
    marginBottom: 16,
    width: '100%',
  },
  attachmentBox: {
    margin: "0 0 16px 10px",
    border: "dashed 2px #51C3C1",
    borderRadius: "5px",
    backgroundColor: colors.greenLightest,
    height: 90,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: 'center',
    alignItems: "center",
    cursor: 'pointer',
    position: 'relative',
    marginRight: 18,
  },
  attContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 8,
    paddingRight: 16,
    maxHeight: 'calc(100vh - 200px)',
    overflowY: 'auto',
    "&::-webkit-scrollbar": {
      display: "block",
      overflowX: 'auto',
      height: '10px',
      background: '#E3E3E3',
      width: 5,
      right: -5,
      position: 'absolute',
    },
    "&::-webkit-scrollbar-thumb": {
      background: colors.unselected,
    },
  },
  itemContainer: {
    '&:hover': {
      backgroundColor: colors.mainPale,
      whiteSpace: 'wrap',
      borderColor: colors.unselected,
    },
    border: '2px solid white',
    borderRadius: 4,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 4,
  },
  borderedItemCtn: {
    border: '2px solid ' + colors.main,
  },
  attListCtn: {
    overflowX: 'clip',
    marginRight: -4,
    width: '-webkit-fill-available',
  },
  attAnnexName: {
    overflow: "hidden",
    whiteSpace: 'nowrap',
    alignSelf: "flex-start",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    flexGrow: 1,
    fontSize: "14px",
    cursor: "pointer",
    marginTop: -5,
    textOverflow: 'ellipsis',
    maxWidth: 130,
    '@media (max-width: 1300px)': {
      maxWidth: 'inherit'
    },
    color: 'white',
  },
  attIconDlInfo: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    color: 'white',
    alignSelf: 'flex-start',
  },
  mainColor: {
    color: colors.plugnotesLight,
    cursor: "default",
    whiteSpace: "pre-line",
  },
  attIconType: {
    margin: '0 10px',
    display: "flex",
    flexDirection: "column-reverse",
    alignSelf: 'flex-start',
    alignItems: 'center',
    fontSize: 12,
    cursor: "pointer",
    '@media (max-width: 1300px)': {
      flex: "0 0 10%",
      textAlignLast: 'center',
    },
    color: colors.white,
    position: 'relative',
  },
  attIcon: {
    fontSize: '64px',
    color: colors.white,
    position: 'absolute',
    top: '-10px',
    right: '-25px',
    zIndex: 25
  },
  attIconShadow: {
    fontSize: '64px',
    color: colors.plugnotesGris,
    position: 'absolute',
    top: '-11px',
    right: '-28px',
    zIndex: 1
  },
  memoCount: {
    width: '50px',
    fontSize: '10px',
    textAlign: 'center',
    color: colors.unselected,
    lineHeight: '10px',
  },
}))

const AttachmentDrawer = (props) => {
  const classes = useStyles()
  const { t } = useTranslation('common')

  const [openDrawer, setOpenDrawer] = useState(window.innerWidth > 960)
  const [openTooltip, setOpenTooltip] = useState(null)

  const handleToggleDrawer = () => { setOpenDrawer(previous => !previous) }

  const handleDropdownFiles = (files) => {
    // devLog('files', files)
    if (Array.isArray(files)) {
      for (const file of files) {
        props.onChangeHandlerDrop([file])
      }
    }
  }

  const handleClickItem = (e, index) => {
    setOpenTooltip(null)
    handlePreventDefault(e)
    if (!openDrawer) {
      setOpenDrawer(true)
    }
    props.setFileId(index)
    props.handleOpenGallery()
  }

  const handleRenderLargePreview = annexe => {
    if (fileIsImg(annexe)) {
      return props.renderAnnexeImagePreview(annexe)
    } else {
      return props.renderAnnexeIconPreview(annexe, 'white', 32)
    }
  }

  return (
    <Drawer
      anchor="right"
      variant="permanent"
      className={
        clsx(classes.paper, classes.drawer, {
          [classes.drawerOpen]: openDrawer,
          [classes.drawerClose]: !openDrawer,
        })
      }
      classes={{
        paper: clsx(classes.paper, classes.paperAnchorDockedLeft, {
          [classes.drawerOpen]: openDrawer,
          [classes.drawerClose]: !openDrawer,
        }),
      }}
    >
      <div style={{ flexDirection: 'row', display: 'flex', }}>
        <div
          className={classes.slider}
          draggable
          onClick={handleToggleDrawer}
          onDragStart={handleToggleDrawer}
        />

        <div>
          <div className={classes.toolbar} />
          <div className={classes.openClose} style={{ justifyContent: openDrawer ? 'flex-start' : 'center' }}>
            <div className={classes.openCloseButton}
              onClick={handleToggleDrawer}>
              {
                openDrawer
                  ? <div style={{ position: 'relative', }}>
                    <ChevronRight />
                  </div>
                  :
                  <PlugTooltip title={t('multiple.open-att-panel')} placement={openDrawer ? "top" : "left"}>
                    <div style={{ position: 'relative' }}>
                      <ChevronLeft />
                      <AttachFile style={{ width: 36, fill: colors.unselected, fontSize: 32 }} />
                      <span style={{ position: 'absolute', bottom: -8, right: -8, fontSize: 11 }}>({props.annexes.length})</span>
                    </div>
                  </PlugTooltip>
              }
            </div>
            {
              openDrawer &&
              <span style={{ display: 'flex', justifyContent: 'space-between', flex: 1, fontWeight: 'bold', }}
                className="modalTitle">
                {t('multiple.attachments-lowercase')}
                <span style={{ flex: 1, marginLeft: 'auto' }} />
                <AttachFile style={{ width: 36, fill: colors.unselected, fontSize: 32 }} />
              </span>
            }
          </div>
          <Content className={`${classes.attField} ${classes.fieldBorder} scrollbar-hidden`} >
            <Dropzone onDrop={(acceptedFiles) => handleDropdownFiles(acceptedFiles)}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()} className={classes.attListCtn}>
                  {props.disabled === false &&
                    <input {...getInputProps()} />
                  }

                  {
                    props.disabled === false && openDrawer &&
                    <PlugTooltip title={t('multiple.attachement-drag-and-drop')} placement="left">
                      <div className={classes.attachmentBox}>
                        <span className={classes.mainColor} style={{ cursor: "pointer" }}>
                          {t("multiple.add-attachments")}
                        </span>
                      </div>
                    </PlugTooltip>
                  }
                </div>
              )}
            </Dropzone>
            {
              props.disabled === false &&
              <PlugTooltip title={t('multiple.add-note')} placement="left">
                <div
                  className={`${classes.fieldTitle} ${classes.attachmentTitle} cursor icon-style`}
                  onClick={(event) => props.handleCreateNote(event)}
                  style={openDrawer ? undefined : { justifyContent: 'center' }}
                >
                  {openDrawer && t("common:plugnote.add-attachment-note")}
                  <MdNoteAdd style={{ fontSize: 32, color: colors.unselected }} />
                </div>
              </PlugTooltip>
            }
            <div style={{ width: openDrawer ? 300 : 80 }} className={classes.dropzoneCtn} >
              {
                openDrawer &&
                <div className={`${classes.fieldTitle} ${classes.attachmentTitle}`} >
                  {t("multiple.current-attachments")}
                </div>
              }

              {
                props.annexes.length > 0 ?
                  <div className={classes.attContainer} style={openDrawer ? { maxHeight: 'calc(100vh - 380px)' } : undefined}>
                    {
                      props.annexes.map((annexe, index) => {
                        {/* devLog("annexe", annexe) */ }
                        return (
                          <PlugTooltip
                            key={annexe.hash}
                            title={
                              <div style={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
                                {
                                  fileIsImg(annexe) && props.renderAnnexeImagePreview(annexe)
                                }
                                <span>{t('document.docfilename')}:</span>
                                <span style={{ fontWeight: 'bold', }}>{annexe.name}</span>
                                {
                                  annexe.comment?.length > 0 &&
                                  <div>
                                    <span className="material-icons" >
                                      info
                                    </span>
                                    {t('actions.comment')}:<br />
                                    <span style={{ fontStyle: 'italic', }}>{annexe.comment}</span>
                                  </div>
                                }
                              </div>
                            }
                            onClose={() => setOpenTooltip(null)}
                            onMouseEnter={() => setOpenTooltip(annexe.hash)}
                            open={openTooltip === (annexe.hash)}
                            placement="left"
                          >
                            <div
                              className={`${classes.itemContainer} `}
                              key={index + 1}
                              onClick={(e) => handleClickItem(e, index, annexe)}
                            >
                              <div className={classes.attIconType} style={fileIsImg(annexe) ? { flex: '0 0 20%' } : undefined}>
                                {
                                  annexe.comment?.length > 0 &&
                                  <span style={{ position: 'absolute', top: -12, left: -20, fontWeight: 'bold', }}>
                                    <span className="material-icons" style={{ fontSize: 20 }}>
                                      info
                                    </span>
                                  </span>
                                }
                                {
                                  openDrawer
                                    ? handleRenderLargePreview(annexe)
                                    : props.renderAnnexeIconPreview(annexe, 'white', 32)
                                }
                              </div>
                              {
                                openDrawer &&
                                <div className={classes.attAnnexName} >
                                  {
                                    annexe.comment?.length > 0
                                      ? annexe.comment
                                      : annexe.name
                                  }
                                </div>
                              }
                              {
                                openDrawer &&
                                <div className={classes.attIconDlInfo} >
                                  {
                                    props.mode !== "modal-plugnote-create" && typeof props.isAuthorizedToEdit !== "undefined" && props.isAuthorizedToEdit(annexe.type) &&
                                    <span
                                      id="icon-file_"
                                      className="material-icons"
                                      onClick={(event) => props.handleUpdateAnnexe(event, annexe)}
                                    >
                                      publish
                                    </span>
                                  }
                                  {
                                    annexe.type !== "note" &&
                                    <span
                                      id="icon-file_copy"
                                      className="material-icons"
                                      onClick={(event) => props.downloadEmployeeData(event, annexe)}
                                    >
                                      get_app
                                    </span>
                                  }

                                  {
                                    annexe.type === "note" &&
                                    <span
                                      id="icon-file_copy"
                                      className="material-icons md-25"
                                      onClick={(event) => props.handleNoteOpen(event, annexe)}
                                    >
                                      create
                                    </span>
                                  }

                                  {/* {
                                    annexe.countVersion > 0 &&
                                      <span
                                      id="icon-file_copy"
                                      className={classes.pointer}
                                      onClick={(event) =>
                                          props.handleModalListAnnexesVersionOpen(
                                              event,
                                              annexe.hash
                                          )
                                      }
                                          >
                                          ({annexe.countVersion})
                                          </span>
                                    } */}

                                  <div className="">
                                    {annexe.groupid !== 0 ?
                                      <span>{annexe.groupid}</span>
                                      :
                                      <span />
                                    }
                                  </div>

                                  {
                                    props.disabled === false &&
                                    <span
                                      id="icon-file_"
                                      className="material-icons"
                                      onClick={(event) => props.handleDeleteAnnexe(event, annexe)}
                                    >
                                      delete
                                    </span>
                                  }
                                </div>
                              }
                            </div>
                          </PlugTooltip>
                        );
                      })}
                    <div style={{ textAlign: "center", padding: '10px 0', color: 'white' }}>
                      * * *
                    </div>
                  </div>
                  :
                  openDrawer && <span>{t("multiple.no-attachments")}</span>
              }
            </div>
          </Content>
          {props.children}
          <div className={classes.openClose}>
            <IconButton className={classes.openCloseButton}
              onClick={handleToggleDrawer}>
              {
                openDrawer && <ChevronRight />
              }
            </IconButton>
          </div>
          <div>
          </div>
        </div>
      </div>
    </Drawer>
  )
}

export default AttachmentDrawer
