import moment from "moment";
import React, { useEffect } from "react";
import { formatTypes } from "./Compatibility";
import ApplicationConfig from '../config/ApplicationConfig'

/**
 * Function returning the build date(as per provided epoch)
 * @param epoch Time in milliseconds
 */
export const getBuildDate = (epoch) => {
    return moment(epoch).format("DD-MM-YYYY HH:mm");
};

export function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function isJsonStringObject(str) {
    try {
        let o = JSON.parse(str);
        return !!(o && typeof o === "object");
    } catch (e) {
        return false;
    }
}

export function returnJSON(obj) {
    if (isJsonString(obj)) return JSON.parse(obj)
    else return obj
}

export function isIterableArray(value) {
    return Symbol.iterator in Object(value);
}

export function isJsonStringArray(str) {
    try {
        return typeof str === "object";
    } catch (e) {
        return false;
    }
}

export function isJsonStringObjectAndCount(str) {
    try {
        let o = JSON.parse(str);
        if (!!(o && typeof o === "object")) {
            if (o.hasOwnProperty('list')) { return o.list.length }
            return o.length;
        }
        else return 0;
    } catch (e) {
        return 0;
    }
}

export function isJsonStringObjectAndCountSubElement(str, criterion) {
    try {
        let o = JSON.parse(str);
        if (!!(o && typeof o === "object")) {
            return o[criterion].length;
        } else return 0;
    } catch (e) {
        return 0;
    }
}

export function isJsonStringObjectAndGetElement(str, criterion) {
    try {
        let o = JSON.parse(str);
        if (!!(o && typeof o === "object")) {
            return o[criterion];
        } else return "";
    } catch (e) {
        return "";
    }
}

export function isEquivalent(a, b) {
    // Create arrays of property names
    let aProps = Object.getOwnPropertyNames(a);
    let bProps = Object.getOwnPropertyNames(b);

    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length != bProps.length) {
        return false;
    }

    for (let i = 0; i < aProps.length; i++) {
        let propName = aProps[i];

        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
            return false;
        }
    }

    // If we made it this far, objects
    // are considered equivalent
    return true;
}

export function sortValue(options) {
    options.sort((str1, str2) => {
        let num1 = parseInt(str1);
        let num2 = parseInt(str2);

        if (isNaN(num1) && isNaN(num2)) {
            if (str1 > str2)
                return 1;
            else if (str1 < str2)
                return -1;
            else
                return 0;
        }

        else if (isNaN(num1))
            return 1;
        else if (isNaN(num2))
            return -1;
        else if (num1 > num2)
            return 1;
        else if (num1 < num2)
            return -1;
        else
            return 0;
    });

    return options;
}

export function isDefined(e) {
    return typeof e !== 'undefined' && e !== null;
}

/**
 * Extract file extension from URL.
 * @param {String} url
 * @returns {String} File extension or empty string if no extension is present.
 */
export function getFileExtension(url) {
    if (url === null) {
        return "";
    }
    let index = url.lastIndexOf("/");
    if (index !== -1) {
        url = url.substring(index + 1); // Keep path without its segments
    }
    index = url.indexOf("?");
    if (index !== -1) {
        url = url.substring(0, index); // Remove query
    }
    index = url.indexOf("#");
    if (index !== -1) {
        url = url.substring(0, index); // Remove fragment
    }
    index = url.lastIndexOf(".");
    return index !== -1
        ? url.substring(index + 1) // Only keep file extension
        : ""; // No extension found
};

export function urlify(text) {
    let urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(urlRegex, function (url) {
        return '<a href="' + url + '">' + url + '</a>';
    })
    // or alternatively
    // return text.replace(urlRegex, '<a href="$1">$1</a>')
}

export const validateUrl = (url) => {
    try {
        new URL(url);
    } catch (e) {
        console.error(e);
        return false;
    }
    return true;
};

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const validateText = (text) => {
    return text.length <= 40;
}

export const validateTextarea = (textarea) => {
    return textarea.length <= 512;
}

export const validateNumber = (number) => {
    return !isNaN(number);
}


/**
 * If the element/node ('child') has the class 'classname' => return true
 * Else: call the function again with parent until parent with class is found or no parent is left
 */

export const hasParentClass = (child, classname) => {
    if (child.className.split(' ').indexOf(classname) >= 0) return true;
    try {
        //Throws TypeError if child doesn't have parent any more
        return child.parentNode && hasParentClass(child.parentNode, classname);
    } catch (TypeError) {
        return false;
    }
}

export const dateFormat = (date, fstr, utc) => {
    utc = utc ? 'getUTC' : 'get';
    return fstr.replace(/%[YmdHMS]/g, function (m) {
        switch (m) {
            case '%Y': return date[utc + 'FullYear'](); // no leading zeros required
            case '%m': m = 1 + date[utc + 'Month'](); break;
            case '%d': m = date[utc + 'Date'](); break;
            case '%H': m = date[utc + 'Hours'](); break;
            case '%M': m = date[utc + 'Minutes'](); break;
            case '%S': m = date[utc + 'Seconds'](); break;
            default: return m.slice(1); // unknown code, remove %
        }
        // add leading zero if required
        return ('0' + m).slice(-2);
    });
}

export const getBool = (val) => {
    if(!isDefined(val)) return false;
    switch (val.toLowerCase()) {
        case "true":
            return true;
        case "false":
            return false;
        default:
            break;
    }
}

export const isEven = (n) => !(n & 1);

export const blobToBase64 = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
        reader.onloadend = () => {
            resolve(reader.result);
        };
    });
};

export const convertersAlphaToNumber = (value) => {
    let letters = value?.trim().toUpperCase()
    let base = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ', i, j, result = 0;

    for (i = 0, j = letters.length - 1; i < letters.length; i += 1, j -= 1) {
        result += Math.pow(base.length, j) * (base.indexOf(letters[i]) + 1);
    }

    return result;
    // var chars = ' ABCDEFGHIJKLMNOPQRSTUVWXYZ', mode = chars.length - 1, number = 0;
    // for(var p = 0; p < letters.length; p++){
    //     number = number * mode + chars.indexOf(letters[p]);
    // }
    // return number;
};

export const convertersNumberToAlpha = (val) => {
    let s = '', t;

    while (val > 0) {
        t = (val - 1) % 26;
        s = String.fromCharCode(65 + t) + s;
        val = (val - t) / 26 | 0;
    }
    return s;
};

export const addChar = (str, ch, position) => {
    let origString = str;
    let stringToAdd = " " + ch + " ";
    let indexPosition = position;

    // Split the string into individual
    // characters
    origString = origString.split('');

    // Insert the string at the index position
    origString.splice(indexPosition, 0, stringToAdd);

    // Join back the individual characters
    // to form a new string
    return origString.join('');
}

export const currentDate = () => {
    let d = new Date();
    let m = d.getMonth() + 1
    return d.getDate() + '-' + (m < 10 ? '0' + m : m) + '-' + d.getFullYear();
}

export const currentDateAndHours = () => {
    let d = new Date();
    let m = d.getMonth() + 1
    let date = d.getDate() + '-' + (m < 10 ? '0' + m : m) + '-' + d.getFullYear();
    let h = d.getHours()
    if (h < 10) { h = '0'.concat(h) }
    let mm = d.getMinutes()
    if (mm < 10) { mm = '0'.concat(mm) }
    let s = d.getSeconds()
    if (s < 10) { s = '0'.concat(s) }
    let hours = h + ":" + mm + ":" + s
    return date + ' ' + hours
}

export const randomString = (len) => {
    let i, key = "", characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

    let charactersLength = characters.length;

    for (i = 0; i < len; i++) {
        key += characters.substr(Math.floor((Math.random() * charactersLength) + 1), 1);
    }

    return key;
}

export const convertToByteArray = (input) => {
    let sliceSize = 512;
    let bytes = [];

    for (let offset = 0; offset < input.length; offset += sliceSize) {
        let slice = input.slice(offset, offset + sliceSize);

        let byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        bytes.push(byteArray);
    }

    return bytes;
}

export function useTitle(title) {
    useEffect(() => {
        const prevTitle = document.title
        document.title = title
        return () => {
            document.title = prevTitle
        }
    })
}

export function objectsAreSame(value, other) {
    // Get the value type
    let type = Object.prototype.toString.call(value);

    // If the two objects are not the same type, return false
    if (type !== Object.prototype.toString.call(other)) return false;

    // If items are not an object or array, return false
    if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

    // Compare the length of the length of the two items
    let valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
    let otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
    if (valueLen !== otherLen) return false;

    // Compare two items
    let compare = function (item1, item2) {

        // Get the object type
        let itemType = Object.prototype.toString.call(item1);

        // If an object or array, compare recursively
        if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
            if (!objectsAreSame(item1, item2)) return false;
        }

        // Otherwise, do a simple comparison
        else {

            // If the two items are not the same type, return false
            if (itemType !== Object.prototype.toString.call(item2)) return false;

            // Else if it's a function, convert to a string and compare
            // Otherwise, just compare
            if (itemType === '[object Function]') {
                if (item1.toString() !== item2.toString()) return false;
            } else {
                if (item1 !== item2) return false;
            }

        }
    };

    // Compare properties
    if (type === '[object Array]') {
        for (let i = 0; i < valueLen; i++) {
            if (compare(value[i], other[i]) === false) return false;
        }
    } else {
        for (let key in value) {
            if (value.hasOwnProperty(key)) {
                if (compare(value[key], other[key]) === false) return false;
            }
        }
    }

    // If nothing failed, return true
    return true;
}

export function zeroPad(num, places) {
    return String(num).padStart(places, '0');
}

export function getLanguageFromFlag(lang) {
    if (lang.flag !== '') {
        let language;
        switch (lang.flag) {
            case 'fr':
                language = 'français';
                break;
            case 'gb':
                language = 'English';
                break;
            case 'nl':
                language = 'Nederlands';
                break;
            case 'it':
                language = 'italiano';
                break;
            case 'de':
                language = 'Deutsch';
                break;
            case 'es':
                language = 'espanol';
                break;
            case 'pt':
                language = 'português';
                break;
            case 'ro':
                language = 'română';
                break;
            case 'ru':
                language = 'Русский';
                break;
            case 'pl':
                language = 'polski';
                break;
            case 'lu':
                language = 'Lëtzebuergesch';
                break;
            default: break;
        }
        lang['name'] = language
    }
    return lang
}

export function unflatten(data) {
    if (Object(data) !== data || Array.isArray(data))
        return data;
    const regex = /\.?([^.\[\]]+)$|\[(\d+)\]$/
    const props = Object.keys(data);
    let result, p;
    while (p = props.shift()) {
        const m = regex.exec(p)
        let target;
        if (m.index) {
            const rest = p.slice(0, m.index);
            if (!(rest in data)) {
                data[rest] = m[2] ? [] : {};
                props.push(rest);
            }
            target = data[rest];
        } else {
            if (!result) {
                result = (m[2] ? [] : {});
            }
            target = result
        }
        target[m[2] || m[1]] = data[p];
    }
    return result;
};

export function renderFormatText(field) {
    let fieldFormat = field.format;

    // Vérifier les préselections
    let template = '';
    if (field.format === 'select') {

        let options = 'options';

        if (typeof field.formatoptions !== "undefined") {
            options = 'formatoptions';
        }

        if (field[options].multiselect && !field[options].editable) {
            fieldFormat = "multi_select";
        } else if (!field[options].multiselect && !field[options].editable) {
            fieldFormat = "single_select";

        } else if (field[options].multiselect && field[options].editable) {
            fieldFormat = "free_multi_select";

        } else if (!field[options].multiselect && field[options].editable) {
            fieldFormat = "free_single_select";
        }

    }

    if (field.format === 'table') {

        let options = 'options';

        if (typeof field.formatoptions !== "undefined") {
            options = 'formatoptions';
        }

        if (field[options].multiselect && !field[options].editable) {
            fieldFormat = "multi_table";
        } else if (!field[options].multiselect && !field[options].editable) {
            fieldFormat = "single_table";

        } else if (field[options].multiselect && field[options].editable) {
            fieldFormat = "free_multi_table";

        } else if (!field[options].multiselect && field[options].editable) {
            fieldFormat = "free_single_table";
        }

    }

    if (typeof formatTypes[fieldFormat.toUpperCase()] !== "undefined") {
        template = <span className="references">{formatTypes[fieldFormat.toUpperCase()]}</span>;
    }

    return template;
}

export function getFilenameAndExtension(pathfilename) {

    let filenameextension = pathfilename.replace(/^.*[\\\/]/, '');
    let filename = filenameextension.substring(0, filenameextension.lastIndexOf('.'));
    let ext = filenameextension.split('.').pop();

    return [filename, ext];

}

export function devLog(...text) {
    if (ApplicationConfig.devMode && ApplicationConfig.developer === 'tech2') {
        console.log(...text)
    } else if (ApplicationConfig.devMode && ApplicationConfig.developer === 'tech') {
        console.log(...text)
    }
    else if(ApplicationConfig.devMode && ApplicationConfig.developer === 'tech3') {
        console.log(...text);
    }
}

export const compareArrays = (a, b) => {
    if (!isDefined(a) || !isDefined(b)) { return false }
    const arrayA = JSON.stringify(a)
    const arrayB = JSON.stringify(b)
    return arrayA === arrayB
}

export const handlePreventDefault = e => {
    e.preventDefault()
    e.stopPropagation()
}

export const addZeroBefore = (n) => {
    return (n < 10 ? '0' : '') + n
}

export const formatNumberApplyThousandSeparator = (integerToFormat, separator) => {
    //console.log('integerToFormat : ', integerToFormat);
    //console.log('separator : ', separator);
    integerToFormat = integerToFormat.toString();
    let pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(integerToFormat))
        integerToFormat = integerToFormat.replace(pattern, "$1" + separator + "$2");
    //integerToFormat = integerToFormat.replace(pattern, "$1,$2");
    return integerToFormat;
}

export const splitDecimalNumberApplySeparator = (numberToFormat, separatorToApply) => {
    // split('.') is here used on numbers saved with dot decimal separator
    const [partBeforeDecSeparator, partAfterDecSeparator] = numberToFormat.split('.');
    //console.log("partBeforeDecSeparator : ", partBeforeDecSeparator);
    //console.log("partAfterDecSeparator : ", partAfterDecSeparator);
    return partBeforeDecSeparator + separatorToApply + partAfterDecSeparator;
}

export const formatNumberApplyOptions = (isActiveThousandSeparator, tmpValueToFormat, thousandSepToApply, decimalSepToApply, decimalQuantity) => {
    if (isActiveThousandSeparator) {
        if (tmpValueToFormat.includes('.')) // tmpValueToFormat has DECIMALS
        {
            const [partBeforeDecSeparator, partAfterDecSeparator] = tmpValueToFormat.split('.');
            let partBeforeDecSeparatorWithThousandSeparator = formatNumberApplyThousandSeparator(partBeforeDecSeparator, thousandSepToApply);
            return partBeforeDecSeparatorWithThousandSeparator + decimalSepToApply + partAfterDecSeparator;
        }
        else // NO DECIMALS in tmpValueToFormat
        {
            return formatNumberApplyThousandSeparator(tmpValueToFormat, thousandSepToApply);
        }
    }
    else // NO ACTIVE THOUSAND SEPARATOR
    {
        if (decimalQuantity === '0') {
            return tmpValueToFormat;
        }
        else {
            let tmpVal = '';
            tmpValueToFormat.includes('.') ? // REPLACE DECIMAL SEPARATOR IF NECESSARY
                tmpVal = splitDecimalNumberApplySeparator(tmpValueToFormat, decimalSepToApply)
                :
                tmpVal = tmpValueToFormat;
            return tmpVal;
        }
    } // END IF / ELSE
    // (isActiveThousandSeparator was previously corresponding to field.options?.setup?.isActiveThousandSeparator,
    // now based on field.options?.setup?.septhousand === false if not activated)
}

export const findInMultiDimByTextAndColEncoded = (text, colSearch, col, array)  => {
    for(let i = 0; i < array.length; i++){
        let tmpValue = Object.values(array[i]);
        if(tmpValue[colSearch].toString() === text.toString()) return tmpValue[col];
    }
    return null;
}

export const findInMultiDimByLigAndColEncoded = (ligSearch, colSearch, col, array)  => {

    let tmpValue = Object.values(array[ligSearch]);
    if(tmpValue[colSearch]) return tmpValue[col];

    return null;
}

export const getFormatCode = field => {
    const options = field?.options
    // devLog('feld', field)
    switch (field.format) {
        case 'agenda': return 'F17'
        case 'birthday': return 'F18'
        case 'boolean': return 'F16'
        case 'date': return 'F04'
        case 'document': return 'A32'
        case 'extdata': return 'S01'
        case 'extdatan': return 'S02'
        case 'formula': return 'F29'
        case 'glocalis': return 'F30'
        case 'indice': return 'F31'
        case 'intdatamaj': return 'F--'
        case 'list': return 'F10'
        case 'localis': return 'F09'
        case 'mail': return 'F06'
        case 'now': return 'F20'
        case 'numeric': return 'F03'
        case 'phone': return 'F05'
        case 'pluglink': return 'F28'
        case 'plugform': return 'F27'
        case 'password': return 'F08'
        case 'qrcode': return 'F19'
        case 'qrcodeint': return 'F22'

        case 'select':
            if (options.editable) {
                if (options.multiselect) {
                    return 'F14'
                } else return 'F12'
            } else {
                if (options.multiselect) {
                    return 'F13'
                } else return 'F11'
            }
        case 'single-select': return 'F11'
        case 'free-single-select': return 'F12'
        case 'multi-select': return 'F13'
        case 'free-multi-select': return 'F14'

        case 'sign': return 'M01'
        case 'smail': return 'F21'
        case 'smailvalidmulti': return 'S21'

        case 'table':
            if (isDefined(options)) {
                if (options.editable) {
                    if (options.multiselect) {
                        return 'F26'
                    } else return 'F24'
                } else {
                    if (options.multiselect) {
                        return 'F25'
                    } else return 'F23'
                }
            }
            return 'F23-26'
        case 'single-table': return 'F23'
        case 'free-single-table': return 'F24'
        case 'multi-table': return 'F25'
        case 'free-multi-table': return 'F26'

        case 'tablemultin': return 'S32'
        case 'tablemulti': return 'F32'
        case 'text': return 'F01'
        case 'textarea': return 'F02'
        case 'time': return 'F15'
        case 'url': return 'F07'
        default: return 'F--'
    }
}

export const getSearchCriteria = (query) => {
    switch (query) {
        case 'CONTAINS':
            return ("..xx..")
        case 'START':
            return ("xx..")
        case 'MULTIPLE':
            return ("xxx")
        default: return ("= xx")
    }
}

export const getSortedPresels = (presels, order) => {
    if (order === 'alphanumeric') {
        return (
            presels.slice()
                .sort((a, b) => a.toLowerCase().trim().localeCompare(b.toLowerCase().trim()))
        )
    } else if (order === 'reverse') {
        return presels.slice().reverse()
    } else return presels
}

export const  number_format = (number, decimals, dec_point, thousands_sep) => {
    let n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        toFixedFix = function (n, prec) {
            // Fix for IE parseFloat(0.55).toFixed(0) = 0;
            var k = Math.pow(10, prec);
            return Math.round(n * k) / k;
        },
        s = (prec ? toFixedFix(n, prec) : Math.round(n)).toString().split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

export const date_format = (currentDate, currentFormatString, newFormatString) => {
    return moment(currentDate, currentFormatString).format(newFormatString)
}

export const date_valid = (date) => {
    return date instanceof Date && !isNaN(date.valueOf())
}

export const renameObjectKey = (oldObj, oldName, newName) => {
    const newObj = {};

    Object.keys(oldObj).forEach(key => {
        const value = oldObj[key];

        if (key === oldName) {
            newObj[newName] = value;
        } else {
            newObj[key] = value;
        }
    });

    return newObj;
}

