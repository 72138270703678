import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { devLog, isDefined } from '../../../utils/utils';
import { Input, Table, TableBody, TableCell, TableHead, TableRow, makeStyles } from '@material-ui/core';
import { PlugTooltip } from '../../custom/CustomComponents';
import colors from '../../../assets/themes/Colors';
import { RenderInputLabelEdit } from './RenderEditTableComponent';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  table: {
    minWidth: 650,
  },
  tableRoot: {
    marginRight: '20px',
  },
  headerCtn: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: colors.palePlugGreen,
    boxShadow: '3px 0px 6px 4px rgba(136, 136, 136, 0.12)',
    '& .MuiTableCell-root': {
      border: '0px solid !important',
    },
  },
  header: {
    height: 80,
    '& .MuiTableCell-root': {
      padding: '4px !important',
      textAlign: 'center',
      position: 'sticky',
    }
  },
  headers: {
    '& .MuiTableCell-root': {
      padding: '4px !important',
      textAlign: 'center',
    },
    marginTop: -2,
  },
  flatHeader: {
    display: 'none',
  },
  appBar: {
    backgroundColor: 'white',
    borderBottom: '3px solid grey',
    zIndex: theme.zIndex.drawer + 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: 'black',
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    width: '10%',
  },
  field: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 'calc(50vh)',
    overflowY: 'auto',
  },
  inputField: {
    padding: '0px 0px 7px',
  },
  headerInputCtn: { fontSize: 13, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' },
  inputCenter: { textAlign: 'center' },
  mainField: {
    minWidth: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  selectType: {
    //paddingRight: '24px',
    marginTop: '15px',
    marginLeft: '10px',
  },
  inputType: {
    marginTop: '30px',
    marginLeft: '0px',
    marginRight: '20px',
  },
  inputListType: {
    marginTop: '15px',
    marginLeft: '10px',
  },
  configCell: {
    height: 60,
  },
  formatSelectCtn: {
    width: 100,
    paddingLeft: '12px !important',
    // paddingRight:'4px !important',
  },
  nbColumn: {
    width: '30px !important',
    paddingLeft: 5,
    paddingRight: 5
  },
  preselConfig: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 5,
    paddingBottom: 5
  },
  switchCtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  configRow: {
    // verticalAlign: 'bottom',
    height: '60px !important',
  },
  openClose: {
    flex: '0 0 auto',
    color: 'rgba(0, 0, 0, 0.54)',
    cursor: 'pointer',
    overflow: 'visible',
    fontSize: '1.5rem',
    textAlign: 'center',
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: '50%',
    scrollMarginTop: '30px',
  },
  tableRow: {
    borderRadius: 4,
    color: colors.main,
    padding: '0px 5px',
    gap: 5,
    // display: 'flex',
    // alignItems: 'center'
  },
  rowNb: {
    fontSize: 14,
    marginRight: 4,
    border: '1px solid',
    padding: '0px 4px',
    borderRadius: '50%',
    verticalAlign: 'bottom',
  },
  rowContainer: {
    '& .MuiTableCell-root': {
      padding: '0px!important'
    },
  },
  selectedMask: {
    cursor: 'pointer',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: "6px",
    backgroundColor: colors.selectedAlpha,
    color: colors.white,
    padding: "3px 8px",
    minHeight: 24,
    gap: 5,
  },
  unselectedMask: {
    cursor: 'pointer',
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 24,
    borderRadius: "6px",
    backgroundColor: colors.unselectedAlpha,
    color: colors.white,
    padding: "3px 8px",
    gap: 5,
  },
  masksContainer: {
    width: 'inherit',
    minHeight: 24,
    cursor: 'pointer',
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: "6px",
    alignItems: 'flex-start',
    gap: 5,
    paddingLeft: 12,
    paddingTop: 4,
    paddingBottom: 4,
  },
  subOpenClose: {
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      borderRadius: 50,
      background: colors.frost
    }
  },
  fieldIcon: {
    // stroke: colors.white,
    fill: colors.white,
    color: colors.white,
    width: 20,
    height: 'auto',
    verticalAlign: 'sub',
    cursor: 'pointer'
  },
  switch: {
    '& .MuiSwitch-root': { height: '43px !important' }, height: '43px !important',
    '& .MuiSwitch-track': { backgroundColor: colors.unselectedAlpha, opacity: 1, },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { backgroundColor: colors.unselectedAlpha, opacity: 1 }
  },
  switchIcon: { fontSize: 24, color: 'white', backgroundColor: colors.main, borderRadius: 12, padding: 2, },
  clickableText: {
    cursor: 'pointer',
  },
  warnToast: {
    backgroundColor: colors.unselected,
  },
}))

const RenderColumnWidthEditor = (props) => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  let isCustom = isDefined(props.colWidths[props.index]) && props.colWidths[props.index] !== 0
  const [width, setWidth] = useState(isCustom ? props.colWidths[props.index] : '-')
  const ref = useRef()

  const handleInputChange = (e) => {
    // console.log('input' , e);
    setWidth(e);
    props.handleUpdateHeaderLength(props.index, e);
  }
  const handleOnFocus = () => {
    setWidth(props.colWidths[props.index])
    if (width <= 0 || width === '-') {
      setTimeout(() => { ref?.current?.setSelectionRange(1, 1) }, 100)
    }
  };

  const handleOnBlur = () => {
    if (width === 0) setWidth('-')
  }

  const handleResetValue = () => {
    setWidth(0)
    props.handleUpdateHeaderLength(props.index, 0)
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', }}>
      <Input
        autoComplete="off"
        classes={{ input: classes.inputCenter }}
        id="standard-full-width"
        inputRef={ref}
        police="police"
        style={{ width: '50%', minWidth: 30, maxWidth: 70, paddingLeft: 4 }}
        inputlabelprops={{ shrink: true }}
        inputMode="numeric"
        value={width}
        onBlur={handleOnBlur}
        onChange={(e) => handleInputChange(Number(e.target.value))}
        onFocus={handleOnFocus}
      />
      {
        isCustom &&
        <PlugTooltip title={t('tablemulti.reset-auto')} placement="right" alignment="center" >
          <span className="material-icons" style={{ color: colors.main, marginRight: -28, fontSize: 20 }} onClick={handleResetValue}>
            highlight_off
          </span>
        </PlugTooltip>
      }
    </div>
  )
}

export const RenderInputLabelFontsizeEdit = (props) => {
  // devLog('EditLabelSize', props)
  const classes = useStyles()
  const { t } = useTranslation('common')
  const [headerFontSize, setHeaderFontSize] = useState(props.row?.headerFontSize === props.default ? -1 : props.row?.headerFontSize)
  const ref = useRef()

  const handleInputChange = (event) => {
    let _fontSize = event.target.value
    if (_fontSize.length >= 2 && _fontSize.startsWith('0')) { _fontSize = _fontSize.slice(1) }
    if (_fontSize === props.default) { _fontSize = -1 }
    setHeaderFontSize(_fontSize)
    props.handleUpdatePolice(Number(_fontSize), props.row.id)
  }

  const handleResetValue = () => {
    setHeaderFontSize(-1)
    props.handleUpdatePolice(-1, props.row.id)
  }

  const handleOnFocus = () => {
    if (headerFontSize <= 0) {
      setHeaderFontSize(0)
      setTimeout(() => { ref?.current?.setSelectionRange(1, 1) }, 100)
    }
  };

  const handleOnBlur = () => {
    if (headerFontSize === '') setHeaderFontSize(-1)
  }

  useEffect(() => {
    setHeaderFontSize(props.row?.headerFontSize || -1)
  }, [props.row?.headerFontSize]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
      <Input
        autoComplete="off"
        classes={{ input: classes.inputCenter }}
        id="standard-full-width"
        inputMode="numeric"
        inputRef={ref}
        police="police"
        inputlabelprops={{ shrink: true }}
        onBlur={handleOnBlur}
        onChange={(event) => handleInputChange(event)}
        onFocus={handleOnFocus}
        style={{ width: '30%', minWidth: 70 }}
        value={headerFontSize < 0 ? '-' : headerFontSize}
      />
      {
        (headerFontSize !== props.default && headerFontSize >= 0) &&
        <PlugTooltip title={t('tablemulti.reset-default')} placement="right" alignment="center" >
          <span className="material-icons" style={{ color: colors.main, marginRight: -28, fontSize: 20 }} onClick={handleResetValue}>
            highlight_off
          </span>
        </PlugTooltip>
      }
    </div>
  )
}

export const RenderInputRowFontsizeEdit = (props) => {
  // devLog('Render input props', props)
  const classes = useStyles()
  const { t } = useTranslation('common')
  const [fontSize, setFontSize] = useState(isDefined(props.row?.fontSize) ? props.row.fontSize : -1)
  const ref = useRef()

  const handleInputChange = (event) => {
    let _fontSize = event.target.value
    if (_fontSize.length >= 2 && _fontSize.startsWith('0')) { _fontSize = _fontSize.slice(1) }
    setFontSize(_fontSize)
    props.handleUpdatePolice(Number(_fontSize), props.row.id)
  }

  const handleResetValue = () => {
    setFontSize(-1)
    props.handleUpdatePolice(-1, props.row.id)
  }

  const handleOnFocus = () => {
    if (fontSize <= 0) {
      setFontSize(0)
      setTimeout(() => { ref?.current?.setSelectionRange(1, 1) }, 100)
    }
  };

  const handleOnBlur = () => {
    if (fontSize === '') setFontSize(-1)
  }

  useEffect(() => {
    setFontSize(isDefined(props.row?.fontSize) ? props.row.fontSize : -1);
  }, [props.row?.fontSize]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
      <Input
        autoComplete="off"
        inputRef={ref}
        classes={{ input: classes.inputCenter }}
        id="standard-full-width"
        inputMode="numeric"
        police="police"
        inputlabelprops={{ shrink: true }}
        onBlur={handleOnBlur}
        onChange={(event) => handleInputChange(event)}
        onFocus={handleOnFocus}
        style={{ width: '30%', minWidth: 70 }}
        value={fontSize < 0 ? '-' : fontSize}
      />
      {
        (fontSize !== props.default && fontSize >= 0) &&
        <PlugTooltip title={t('tablemulti.reset-default')} placement="right" alignment="center">
          <span className="material-icons" style={{ color: colors.main, marginRight: -28, fontSize: 20 }} onClick={handleResetValue}>
            highlight_off
          </span>
        </PlugTooltip>
      }
    </div>
  )
}

const RenderEditLayoutComponent = (props) => {
  // devLog('RenderEditLayout got props', props)
  const { t } = useTranslation('common')
  const classes = useStyles()

  // Variables
  const dataRows = props.dataRows
  let valueRows = props.field.formatoptions.length > 0 ? props.field.formatoptions : []
  if ((props.field.format.includes('tablemulti') || props.field.format === 'smailvalidmulti') && props.field.formatoptions.hasOwnProperty('lines')) {
    valueRows = props.field.formatoptions.lines.length > 0 ? props.field.formatoptions.lines : []
  }

  let _widths = valueRows.map(row => {
    if (isDefined(row.colWidth)) { return row.colWidth; } else { return 0 }
  })

  let _fontSizes = valueRows.map(row => {
    if (isDefined(row.fontSize)) { return row.fontSize; } else { return -1 }
  })

  let _labelSizes = valueRows.map(row => {
    if (isDefined(row.labelSize)) { return row.labelSize } else { return -1 }
  })

  // UseStates
  const [editColWidths, setEditColWidths] = useState(dataRows.some(row => row?.colWidth == null || row?.colWidth === 0))
  const [colWidths, setColWidths] = useState(_widths);
  const [colFontSizes, setColFontSizes] = useState(_fontSizes)
  const [labelFontSizes, setLabelFontSizes] = useState(_labelSizes)
  const [alignment, setAlignment] = useState(props.field.formatoptions.setup?.layout?.alignment || 'full')

  // Methods
  const handleToggleTablePreview = () => {
    props.setOpenPreview(previous => !previous)
  }

  const handleUpdateHeaderPolice = (index, value) => {
    labelFontSizes[index] = Number(value);
    dataRows[index].headerFontSize = Number(value);
    props.setDataRows([...dataRows]);
  }

  const handleUpdatePolice = (index, value) => {
    colFontSizes[index] = Number(value);
    dataRows[index].fontSize = Number(value);
    props.setDataRows([...dataRows]);
  }

  const handleUpdateHeaderLength = (index, value) => {
    colWidths[index] = Number(value);
    setColWidths([...colWidths]);
    dataRows[index].colWidth = Number(value);
    props.setDataRows([...dataRows]);
  }

  const handleToggleEditColWidths = () => {
    setEditColWidths(previous => !previous);
    if (!editColWidths) {
      dataRows.forEach((row, index) => {
        row.colWidth = 0
      });
    } else {
      dataRows.forEach((row, index) => {
        row.colWidth = colWidths[index];
      });
    }
  }

  function handleToggleAlignement(e) {
    let align = e.target.value;
    if (isDefined(align) && align !== 0) setAlignment(align);
    else {
      align = isDefined(alignment) ? alignment : 'full'
      setAlignment(align)
    }
    let model = [...props.model]
    model[props.index].options.setup.layout.alignment = align;
    props.handleUpdateModel(model)
    devLog('updated model', model)
  }

  return (
    <div className={classes.field}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead className={classes.headerCtn} >
          <TableRow className={classes.header}>
            <TableCell align="center" colSpan={1}>
              <div className={classes.field}>

              </div>
            </TableCell>
            <TableCell colSpan={11} style={{ display: 'table-cell', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span
                  style={{
                    flexWrap: 'nowrap',
                    padding: 10,
                    borderRadius: 5,
                    backgroundColor: colors.main,
                    color: colors.white,
                    cursor: "pointer",
                    textAlign: "center",
                    alignSelf: 'flex-end',
                  }}
                  onClick={props.handleAddRow}>
                  {t('tablemulti.add-column')}
                </span>
              </div>
            </TableCell>
          </TableRow>
          <TableRow className={classes.headers}>
            <TableCell align="center" className={classes.nbColumn}>
              <span style={{ display: "inline-block" }}>
                Col.
              </span>
            </TableCell>
            <PlugTooltip title={<span style={{ fontSize: 15, whiteSpace: 'pre-line' }}>{t('tablemulti.infos-chars')}</span>} >
              <TableCell >
                <span>Libellé</span>
                <span className="material-icons" style={{ fontSize: 20, color: colors.main }}>
                  info
                </span>
              </TableCell>
            </PlugTooltip>

            <TableCell align="center">
              <span>Format</span>
              <PlugTooltip title={<span style={{ fontSize: 15, whiteSpace: 'pre-line' }}>{t('constructor.info-format')}</span>} >
                <span className="material-icons" style={{ fontSize: 20, color: colors.main }}>
                  info
                </span>
              </PlugTooltip>
            </TableCell>
            <PlugTooltip title={<span style={{ fontSize: 15 }}>{t('tablemulti.info-col-width')}</span>} >
              <TableCell align="center" style={{ maxWidth: 120, }}>
                <div style={{ fontSize: 16, display: 'flex', flexDirection: 'column', justifyContent: 'center', }}>
                  {t('tablemulti.col-width')}
                  <div style={{ fontSize: 10, alignItems: 'center' }}>
                    {t('tablemulti.auto-values')}
                    <input
                      type="checkbox" className="checkbox" style={{ color: colors.main, minWidth: '30px' }}
                      onChange={handleToggleEditColWidths}
                      checked={!editColWidths}
                    />
                  </div>
                </div>
              </TableCell>
            </PlugTooltip>

            <TableCell align="center" colSpan={3}>{t('common:helper.visibility')}</TableCell>
            <TableCell align="center" />
          </TableRow>
        </TableHead>
        {
          dataRows.length > 0 &&
          <TableBody >
            {
              dataRows.map((row, index) => (
                <TableRow
                  key={'table-' + props.field.id + '-row-' + row.id}
                  className={`${classes.configRow} ${classes.rowContainer}`}>
                  <TableCell
                    align="center"
                    className={classes.configCell}
                  >
                    {index + 1}
                  </TableCell>

                  <TableCell align="center" className={classes.configCell} style={{ width: 200 }}>
                    <RenderInputLabelEdit {...props} row={dataRows[index]} index={index} handleUpdateLabel={props.handleUpdateLabel} missingLabel={props.missingLabel} />
                  </TableCell>

                  <TableCell align="center" className={`${classes.configCell} `}>
                    <div style={{ textAlign: 'center', }}>{(props.rows.find((entry => entry.format === dataRows[index].format)).formatLabel).toString()}</div>
                  </TableCell>

                  <TableCell align="center" className={classes.configCell} style={{ minWidth: 70, maxWidth: 120 }}>
                    {
                      editColWidths ?
                        <RenderColumnWidthEditor
                          colWidths={colWidths}
                          index={index}
                          handleUpdateHeaderLength={handleUpdateHeaderLength}
                        />
                        :
                        <>
                          <div>-</div>
                        </>
                    }
                  </TableCell>
                  <TableCell>
                    <span
                      id="icon-add-playlist" className="material-icons md-small"
                      onClick={() => props.handleRemoveSpecificRow(index)}
                    >
                      delete
                    </span>
                  </TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        }
      </Table>
    </div>
  )
}

export default RenderEditLayoutComponent
