import React, {useEffect, useRef, useState} from 'react';
import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import {saveAs} from 'file-saver';
import {
    delDeleteDataRequest,
    getDataUniqueRequest,
    putUpdateDataArchiveRequest,
    putUpdateDataFavorisRequest,
    putUpdateDataLockRequest,
    putUpdateDataRequest,
    putUpdateDataWithoutRedirectModalRequest,
    putUpdateSharedDataRequest,
    putUpdateSharedDataWithoutRedirectModalRequest
} from "../../actions/data";
import {
    delDeleteAnnexeModalRequest,
    getAnnexesVersionRequest,
    postAddNoteModalRequest,
    postAnnexesModalRequest,
    postAnnexesSharedModalRequest,
    postAnnexesVersionModalRequest,
    postAnnexesVersionSharedModalRequest,
    putEditNoteModalRequest
} from "../../actions/annexe";
import {reduxGeneratePlugnotecodeReset} from "../../actions/plugcode";
import {connect} from "react-redux";
import _, {isString} from "lodash";
import ModalNoteComponent from "./ModalNoteComponent";
import Immutable from "seamless-immutable";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faFile,
    faFileArchive,
    faFileAudio,
    faFileCode,
    faFileExcel,
    faFileImage,
    faFilePdf,
    faFilePowerpoint,
    faFileVideo,
    faFileWord,
    faStickyNote
} from "@fortawesome/free-regular-svg-icons";
import {
    delTableView,
    getCurrentEnvironnement,
    getModalAnnexes,
    getModalModel,
    getModalPlugnote, getModel, getPlugnote,
    getToken,
    getUser,
    setModalAnnexes
} from "../../utils/Common";
import {confirmAlert} from "react-confirm-alert";
import ApplicationConfig from "../../config/ApplicationConfig";
import {trackPromise} from "react-promise-tracker";
import axios from "axios";
import Plugforms from "../plugforms/Plugforms";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
/*import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';*/

const useStyles = makeStyles((theme) =>
    createStyles({
        appBar: {
            position: 'relative',
            backgroundColor : 'white'
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        root: {
            display: 'flex',
        },
        paperAnchorDockedLeft: {
            boxShadow: '0px 0px 6px 4px rgba(136, 136, 136, 0.12)',
            border: 0,
        },
        fieldImageSvg: {
            width: 25,
            height: 25,
        },
        menuButton: {
            marginRight: 36,
        },
        hide: {
            display: 'none',
        },
        toolbar: {
            width : '100%',
            display : 'block'
        },
        openClose: {
            bottom: 0,
            position: 'absolute',
            width: '100%'
        },
        openCloseButton: {
            float: 'right'
        },
        content: {
            flexGrow: 1,
            padding: 0,
        },
        contentModel: {
            flexGrow: 1,
            padding: theme.spacing(2),
            overflow: 'scroll',
            maxHeight: 'calc(100vh - 150px)'
        },
        icon: {
            width: 56,
            height: 56,
            lineHeight: '56px',
            textAlign: 'center',
            color: 'black'
        },
        iconActive: {
            width: 56,
            height: 56,
            lineHeight: '56px',
            textAlign: 'center',
            color: 'blue'
        },
        gridHeader: {
            padding: '10px 20px !important',
            width: '100%',
        },
        selectEnvironnement: {},
        fieldImage: {
            height: 10,
            width: 10
        },
        fieldIconMandatory: {
            width: 20,
            height: 20,
            backgroundColor: '#57bbb8',
            borderRadius: 50,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid red'
        },
        fieldIcon: {
            width: 20,
            height: 20,
            backgroundColor: '#57bbb8',
            borderRadius: 50,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: '25ch',
        },
        field: {
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
        },
        fieldRight: {
            width: '100%'
        },
        fieldLeft: {
            alignContent: 'flex-start',
            display: 'flex',
            alignItems: 'center',
        },
        fieldRightLeft: {
            alignContent: 'flex-end',
            //textAlign: 'center'
        },
        fullWidth: {
            width: '100%'
        },
        paper : {
            overflow : 'hidden'
        },
        modalFile: {
            border: "none",
            width: "100%",
            height: "100%",
        },
        confirmContainer: {
            border: "1px solid #F9F9F9",
            backgroundColor: "white",
            borderRadius: 15,
            padding: "15px",
        },
        confirmTitle: {
            color: "black",
            fontSize: "30px",
        },
        confirmText: {
            color: "#88949C",
        },
        confirmButtons: {
            marginTop: "30px",
            display: "flex",
            justifyContent: "space-between",
        },
        confirmButtonStyle: {
            width: "100px",
            padding: "4px 20px",
            borderRadius: "3px",
            cursor: "pointer",
            "@media (max-width: 1024px)": {
                /* width: "100%", */
            },
        },
        confirmAffirmative: {
            color: "white",
            backgroundColor: "#58c3c1",
            border: "none",
        },
        confirmNegative: {
            color: "#E3E3E3",
            backgroundColor: "transparent",
            border: "2px solid #E3E3E3",
            fontWeight: "600",
        },
        notRender: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 15,
        },
        notRenderText: {
            fontWeight: 'bold',
            color: '#88949C',
        },
    }),
);

/*const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});*/

//TransitionComponent={Transition}


const modalStyles = {overlay: {zIndex: 10000}};
const modalStylesClosed = {overlay: {zIndex: 0}};

const specialFields = ['plugform', 'textarea', 'list', 'single-select', 'free-single-select', 'multi-select', 'free-multi-select', 'single-table', 'free-single-table', 'multi-table', 'free-multi-table', 'qrcode', 'localis', 'glocalis'];

const authorisedToEdit = ['doc', 'docx', 'xls', 'xlsx', 'pages', 'csv', 'odt', 'ods', 'pptx', 'ppt'];

const zeroPad = (num, places) => String(num).padStart(places, '0');


function ModalPlugnoteUpdateComponent(props) {

    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'models']);

    const inputFile = useRef(null);
    const inputUpdateFile = useRef(null);

    const theme = useTheme();
    const [plugnote, setPlugnote] = useState(props.plugnote);
    const [dataForHash, setDataForHash] = useState(props.plugnote);
    const [model, setModel] = useState(props.model);
    const [modelForHash, setModelForHash] = useState(props.model);
    const [cancel, setCancel] = useState(false);

    const [isOpenModalAnnexe, setIsOpenModalAnnexe] = useState(false);
    const [openModalPreview, setOpenModalPreview] = useState(false);

    const [selectedAnnexe, setSelectedAnnexe] = useState(null);
    const [selectedNote, setSelectedNote] = useState(null);
    const [selectedPreviewAnnexe, setSelectedPreviewAnnexe] = useState(null);
    const [images, setImages] = useState([null]);

    const [openModal, setOpenModal] = useState(false);
    const [openNote, setOpenNote] = useState(false);
    const [isOpenFloatingMenu, setIsOpenFloatingMenu] = useState(false);

    const [isOpenModalPlugnotePlugcode, setIsOpenModalPlugnotePlugcode] = useState(false);
    const [isOpenModalListAnnexesVersion, setIsOpenModalListAnnexesVersion] = useState(false);
    const [isOpenModalSendMailAnnexe, setIsOpenModalSendMailAnnexe] = useState(false);
    const [isOpenModalListAnnexes, setIsOpenModalListAnnexes] = useState(false);

    const [isOpenClassify, setIsOpenClassify] = useState(false);

    const [dataHasChanged, setDataHasChanged] = useState(false);

    const environment = getCurrentEnvironnement();

    const [fields, setFields] = useState([]);

    const [selectedFiles, setSelectedFiles] = useState(null);
    const [selectedUpdateFiles, setSelectedUpdateFiles] = useState(null);

    const user = getUser();
    const token = getToken();

    const [plugnoteAnnexes, setPlugnotesAnnexes] = useState( typeof props.annexes !== "undefined" && props.annexes.length > 0 ? props.annexes  : []);
    //const [plugnoteAnnexes, setPlugnotesAnnexes] = useState( getModalAnnexes());
    const [loading, setLoading] = useState(true);
    const [loaded, setLoaded] = useState(0);
    const [visibleAll, setVisibleAll] = useState(typeof model.fieldEmptyVisible !== "undefined" ? model.fieldEmptyVisible === 1 : false);

    const [nameModel, setNameModel] = useState(modelForHash !== null ?  modelForHash.label : '');
    const plugIndex = 'Plugnote ' + environment.envIndex + '-' + props.plugnote.userindex;
    const [fullNamePlugnote, setFullNamePlugnote] = useState(modelForHash !== null ? plugIndex : null);

    const [openPdfPreview, setOpenPdfPreview] = useState(false);
    const [selectedPreviewPdfAnnexe, setSelectedPreviewPdfAnnexe] = useState(null);

    const [fileInputKey, setFileInputKey] = useState(Math.random().toString(36));
    const [fileUpdateInputKey, setFileUpdateInputKey] = useState(Math.random().toString(36));

    const [locked, setLocked] = useState(plugnote.locked);
    const [favorite, setFavorite] = useState(plugnote.favorite);


    useEffect(() => {
        const labels = Object.keys(model).filter((key) => {
            const regex = /field[0-9]+label/gi;
            return regex.test(key);
        });

        let champs = labels.map((item) => {
            return model[item];
        });

        let listFields = [];

        champs.forEach((field, index) => {
            if (field) {

                const fieldIndex = index + 1;
                let value = "";
                let format = {};
                let visuelFormat = {};
                let show = false;
                let event = "";
                let encodable = false;
                let disabled = false;
                let visualDefault = false;

                let options = [];
                let originalOptions = [];


                if (modelForHash && modelForHash[`field${fieldIndex}formatoptions`]) {
                    options = typeof modelForHash[`field${fieldIndex}formatoptions`] !== 'string' ? Object.create(modelForHash[`field${fieldIndex}formatoptions`])  : Object.create(JSON.parse(modelForHash[`field${fieldIndex}formatoptions`]));
                    originalOptions =  typeof modelForHash[`field${fieldIndex}formatoptions`] !== 'string' ? Object.create(modelForHash[`field${fieldIndex}formatoptions`]) : Object.create(JSON.parse(modelForHash[`field${fieldIndex}formatoptions`]));
                }

                // Récupération du format du champ, si le modèle existe le format vient du modèle sinon il vient de la fiche
                if (!_.isEmpty(modelForHash)) {
                    format = modelForHash[`field${fieldIndex}format`];
                    encodable = modelForHash[`field${fieldIndex}encodable`] === 1;
                    visuelFormat = modelForHash[`field${fieldIndex}format`];
                }

                // Si c'est une préselection on initialise la valeur à un tableau vide
                if ( options != null && (options.presel || format === "list")) {
                    value = [];
                }

                if (!_.isEmpty(dataForHash)) {
                    if(format === 'numeric' && typeof dataForHash[`field${fieldIndex}`] !== "undefined"){
                        value = Number.isInteger(dataForHash[`field${fieldIndex}`]) ?  parseInt(dataForHash[`field${fieldIndex}`]) : parseFloat(dataForHash[`field${fieldIndex}`].replace(',', '.'));
                    }
                    else if(format === 'list'){
                        if(typeof dataForHash[`field${fieldIndex}`] === 'undefined'){
                            value = [];
                        }else if( dataForHash[`field${fieldIndex}`].length > 0){

                            let keywordList = JSON.parse(dataForHash[`field${fieldIndex}`]);
                            value = [];
                            keywordList.map((item, index) => {
                                value.push(item.text);
                            })
                        }
                    }
                    else if(format === 'tablemulti'){
                        if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                            value = {"value" : ""};
                        }else if( dataForHash[`field${fieldIndex}`].length > 0){
                            value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                        }
                    }
                    else if(format === 'tablemultin'){
                        if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                            value = {"value" : "", "list" : []};
                        }else if( dataForHash[`field${fieldIndex}`].length > 0){
                            value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                        }
                    }
                    else if(format === 'document'){
                        if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                            value = {"value" : "", "list" : []};
                        }else if( dataForHash[`field${fieldIndex}`].length > 0){
                            value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                        }
                    }
                    else if(format === 'smailvalidmulti'){
                        if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                            value = {"value" : "", "list" : []};
                        }else if( dataForHash[`field${fieldIndex}`].length > 0){
                            value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                        }
                    }
                    else if(format === 'plugform'){
                        if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                            value = {"value" : "", "index" : ""};
                        }else if( dataForHash[`field${fieldIndex}`].length > 0){
                            value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                        }
                    }
                    else if(format === 'intdatamaj'){
                        if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                            value = {"value" : "", "updatedDate" : ""};
                        }else if( dataForHash[`field${fieldIndex}`].length > 0){
                            value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                        }
                    }
                    else if(format === 'qrcodeint'){
                        if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                            value = {"code" : "", "model" : "", "data" : "", "comment" : ""};
                        }else if( dataForHash[`field${fieldIndex}`].length > 0){
                            value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                        }
                    }
                    else if(format === 'pluglink'){
                        //value = {"code" : JSON.parse(modelForHash[`field${fieldIndex}formatoptions`]).pfcode, "clef" : modelForHash[`clef`], "userindex" : dataForHash[`userindex`]};
                        value = {"code" : typeof modelForHash[`field${fieldIndex}formatoptions`] !== "undefined" ? JSON.parse(modelForHash[`field${fieldIndex}formatoptions`])?.pfcode : "", "clef" : modelForHash[`clef`], "userindex" : dataForHash[`userindex`]};
                    }
                    else{
                        value = dataForHash[`field${fieldIndex}`];
                    }
                }

                // On vérifie si le champ est une présélection avec les options
                if (options != null && options.presel && format === 'select') {
                    if (Array.isArray(value) && Immutable.isImmutable(value)) {
                        value = Immutable.asMutable(value);
                    }

                    if(options?.visibledefault){
                        visualDefault = options?.visibledefault;
                    }

                    // On vérifie si la valeur est un tableau sous forme de chaine de caractères, dans ce cas on sérialize le tableau
                    if (!Array.isArray(value)) {
                        if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@')
                            .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                            value = JSON.parse(value);
                        } else {
                            if (value && value != null) {
                                value = [value];
                            } else {
                                value = [];
                            }
                        }
                    }

                    switch (options.multiselect) {
                        case true :
                            if (options.editable) {
                                format = "free-multi-select";
                                visuelFormat = "free-multi-select";
                            } else {
                                format = "multi-select";
                                visuelFormat = "multi-select";
                            }
                            break;
                        default:
                            if (options.editable) {
                                format = "free-single-select";
                                visuelFormat = "free-single-select";
                            } else {
                                format = "single-select";
                                visuelFormat = "single-select";
                            }
                    }
                    options.presel = (options && options.presel && isString(options.presel)) ? options.presel.split(";") : options.presel;
                }
                else if (options != null && options.presel && format === 'table') {
                    if (Array.isArray(value) && Immutable.isImmutable(value)) {
                        value = Immutable.asMutable(value);
                    }

                    if(options?.visibledefault){
                        visualDefault = options?.visibledefault;
                    }

                    // On vérifie si la valeur est un tableau sous forme de chaine de caractères, dans ce cas on sérialize le tableau
                    if (!Array.isArray(value)) {
                        if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@')
                            .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                            value = JSON.parse(value);
                        } else {
                            if (value && value != null) {
                                value = [value];
                            } else {
                                value = [];
                            }
                        }
                    }

                    switch (options.multiselect) {
                        case true :
                            if (options.editable) {
                                format = "free-multi-table";
                                visuelFormat = "free-multi-table";
                            } else {
                                format = "multi-table";
                                visuelFormat = "multi-table";
                            }
                            break;
                        default:
                            if (options.editable) {
                                format = "free-single-table";
                                visuelFormat = "free-single-table";
                            } else {
                                format = "single-table";
                                visuelFormat = "single-table";
                            }
                    }
                    options.presel = (options && options.presel && isString(options.presel)) ? options.presel.split(";") : options.presel;
                }
                else {

                    if (format === 'table') {
                        format = 'multi-table'
                        options = [];

                        if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                            value = JSON.parse(value);
                        } else {
                            if (value && value != null) {
                                value = [value];
                            } else {
                                value = [];
                            }
                        }
                    }

                    if (format === 'select') {
                        format = 'multi-select'
                        options = [];

                        if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                            value = JSON.parse(value);
                        } else {
                            if (value && value != null) {
                                value = [value];
                            } else {
                                value = [];
                            }
                        }
                    }
                }

                let originalPresel = undefined;
                if(typeof originalOptions.presel !== "undefined" && originalOptions.presel.length > 0){
                    if(Array.isArray(originalOptions.presel)){
                        originalPresel = originalOptions.presel
                    }else{
                        originalPresel = originalOptions.presel.split(';')
                    }
                }


                if (format === "now" && !value) {
                    value = moment().format("YYYY-MM-DD HH:mm:ss");
                    setDataHasChanged(true);
                }

                if (format === "tablemultin" && value.length === 0) {
                    value = {"value" : "", "list" : []};
                }

                if (format === "document" && value.length === 0) {
                    value = {"value" : "", "list" : []};
                }

                if (format === "smailvalidmulti" && value.length === 0) {
                    value = {"value" : "", "list" : []};
                }

                if( (format === "free-multi-select" || format === "free-single-select") && value.length > 0){
                    value.map((item, index) => {
                        if(!options.presel.includes(item)){
                            options.presel.push(item);
                        }
                    })
                }

                if( (format === "free-multi-table" || format === "free-single-table") && value.length > 0){
                    value.map((item, index) => {
                        if(!options.presel.includes(item.text)){
                            options.presel.push(item.text);
                        }
                    })
                }

                listFields.push({
                    id: fieldIndex,
                    title: modelForHash[`field${fieldIndex}label`],
                    titleTranslate: modelForHash[`field${fieldIndex}label`],
                    value: typeof value === "undefined" ? "" : value,
                    visuelValue : value,
                    encodable,
                    format,
                    visuelFormat,
                    options,
                    originalPresel,
                    mandatory: modelForHash[`field${fieldIndex}mandatory`],
                    show,
                    event : event,
                    disabled,
                    visualDefault
                });

            }
        });

        setFields(listFields);

    }, []);

    useEffect(() => {
        setPlugnotesAnnexes(props.annexes);
    }, [props.annexes]);

    useEffect(() => {
        if(selectedFiles !== null){
            onClickHandler();
        }
    }, [selectedFiles]);

    useEffect(() => {
        if(selectedUpdateFiles !== null){
            onClickUpdateHandler();
        }
    }, [selectedUpdateFiles]);


    const resetFileInput = ()  => {
        let randomString = Math.random().toString(36);
        setFileInputKey(randomString);
    };

    const resetFileUpdateInput = ()  => {
        let randomString = Math.random().toString(36);
        setFileUpdateInputKey(randomString);
    };


    /**
     * Quand l'utilisateur veut éditer un champ
     * @param value
     * @param field
     * @param index
     * @private
     */

    const handleModalAnnexeClose = () => {
        setSelectedAnnexe(null);
        setIsOpenModalAnnexe(false);
    }

    const handleModalAnnexeOpen = (event,annexe) => {
        event.preventDefault();
        event.stopPropagation();
        setSelectedAnnexe(annexe);
        setIsOpenModalAnnexe(true);
    }

    const handleUpdateAnnexe = (event,annexe) => {
        event.preventDefault();
        event.stopPropagation();
        setSelectedAnnexe(annexe);
        inputUpdateFile.current.click();
    }

    const handleEditValue = (value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields([...listFields]);
        setDataHasChanged(true);
    }

    const handleEditPhoneValue = (value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields(listFields);
        setDataHasChanged(true);
    }

    const handleChangeTableValue = (value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields([...listFields]);
        setDataHasChanged(true);
    }

    const handleChangeTableRowsValue = (value, rows, field, index) => {
        let listFields = fields;
        listFields[index].value.value = value;
        listFields[index].value.list = rows;
        setFields([...listFields]);
        setDataHasChanged(true);
    }

    const handleEditDateTime = (date, value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;

        listFields[index].visuelValue = date;

        setFields([...listFields]);
        setDataHasChanged(true);
    }

    const handleClickOpenModal = () => {
        setOpenModal(true);
    };

    const handleClickCancelModal = () => {
        props.history.push("/pluglist");
        setOpenModal(false);
    };

    const handleClickContinueModal = () => {
        setOpenModal(false);
    };

    const handleExitUpdatePlugnote = () => {
        props.close();
    }

    const checkData = (fields) => {
        return fields.some((field) =>{
            if(field.format !== null && typeof field.format !== "undefined"){
                if(!!field.mandatory && field.mandatory === 1){
                    if(field.format.includes('select')){
                        return field.value.length === 0;
                    }else if(field.format === 'list'){
                        return ((!field.value || field.value === '[]' || field.value.length === 0) && field.format != "indice");
                    }else if(field.format === 'boolean'){
                        return (field.value === "" );
                    }else{
                        return (!field.value && field.format != "indice");
                    }
                }
            }
        })
    }

    const handlePreviewAnnexeClose = () => {
        setOpenModalPreview(false);
        setSelectedPreviewAnnexe(null);
        setImages([]);
    }

    /**
     * Construction de la nouvelle fiche à partir des champs
     * @param fields
     */
    const buildUpdateAnnexe = (fields) => {

        let newData = getModalPlugnote();

        const modelForHash = getModalModel();

        fields.map((field) => {
            if (field.format === "multi-select" || field.format === "free-multi-select" || field.format === "single-select" || field.format === "free-single-select") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    newData["field" + field.id] = JSON.stringify(field.value);
                } else {
                    newData["field" + field.id] = JSON.stringify([field.value]);
                }
            } else if (field.format === "multi-table" || field.format === "free-multi-table" || field.format === "single-table" || field.format === "free-single-table") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    newData["field" + field.id] = JSON.stringify(field.value);
                } else {
                    newData["field" + field.id] = JSON.stringify([field.value]);
                }
            } else if (field.format === "plugform") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }else if (field.format === "qrcodeint") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }else if (field.format === "intdatamaj") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = JSON.stringify({"value" : "", "dataUpdated" : ""});
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if(field.format === "tablemulti") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if(field.format === "smailvalidmulti") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if(field.format === "document") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if(field.format === "list") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    let correctValue = [];
                    field.value.map((item, index) => {
                        correctValue.push({"text" : item})
                    });
                    newData["field" + field.id] = JSON.stringify(correctValue);
                } else {
                    let transformValue = [field.value];
                    let correctValue = [];
                    transformValue.map((item, index) => {
                        correctValue.push({"text" : item})
                    });
                    newData["field" + field.id] = correctValue;
                }
            } else {
                newData["field" + field.id] = field.value;
            }

            if (field.format === "multi-select" || field.format === "free-multi-select" || field.format === "single-select" || field.format === "free-single-select") {
                newData["field" + field.id + "format"] = "select";
            }else  if (field.format === "multi-table" || field.format === "free-multi-table" || field.format === "single-table" || field.format === "free-single-table") {
                newData["field" + field.id + "format"] = "table";
            } else {
                newData["field" + field.id + "format"] = field.format;
            }

            if (modelForHash && field.format === "indice") {
                let editable = JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.editable || false;
                if(!editable && field.value === "" || (editable && field.value === "")){
                    newData["field" + field.id] = (JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.racine || "") +  (JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.indice || 0);
                }
            }

            if(field.format && field.format === 'agenda' || field.format && field.format === 'birthday'){
                newData["field" + field.id + "options"] = field.event;
            }

            newData["field" + field.id + "label"] = field.title;
        });
        newData.Annexes = [];
        newData.lastversion_model = modelForHash ? modelForHash.version : null;
        newData.modid = modelForHash.id;
        newData.offline = modelForHash.offline;

        return newData;
    };

    const handleClickAlertWithCancel = () => {
        setCancel(true);
        setOpenModal(true);
    }

    const handleClickAlertWithoutCancel = () => {
        setCancel(false);
        setOpenModal(true);
    }

    const handleSaveUpdatePlugnote = () => {
        //props.history.push("/pluglist");
        delTableView()
        if (dataHasChanged) {
            if (checkData(fields)) {
                if(selectedFiles !== null){
                    handleClickAlertWithCancel();
                }else{
                    handleClickAlertWithoutCancel();
                }
            }
            else{
                const modelForHash = getModalModel();

                //this._handleSaveAgendaToCalendar();

                let dataForHash = buildUpdateAnnexe(fields);

                props.attemptUpdateDataWithoutRedirect(modelForHash.hash, environment.userhash, dataForHash);

                props.close();
            }
        }
        else{
            if (checkData(fields)) {
                if(selectedFiles !== null){
                    handleClickAlertWithCancel();
                }else{
                    handleClickAlertWithoutCancel();
                }
            }else{
                //props.close();
            }
        }

    };

    const handleChangeTextField = () => {

    }

    const handleChangeCheckBox = (event, field, index, multiselect) => {

    }

    const handleChangeRadio = (value, indexation,field,index,multiselect,mandatory) => {

        let listFields = fields;

        if(mandatory){
            if(listFields[index].value.includes(value)){
                listFields[index].value = listFields[index].value.filter(item => item !== value)
            }else {
                if (!multiselect) {
                    listFields[index].value = [];
                    listFields[index].value.push(value);
                } else {
                    listFields[index].value.push(value);
                }
            }
        }else{
            if(listFields[index].value.includes(value)){
                listFields[index].value = listFields[index].value.filter(item => item !== value)
            }else{
                if(!multiselect){
                    listFields[index].value = [];
                    listFields[index].value.push(value);
                }else{
                    listFields[index].value.push(value);
                }
            }
        }
        setFields([...listFields]);
        setDataHasChanged(true);
    }

    const includesInJson = (search , value) => {
        return search.findIndex((item, index) => {
            return item.text === value
        })
    }

    const handleChangeRadioTable = (value, indexation,field,index,multiselect,mandatory) => {

        let listFields = fields;

        if(mandatory){
            if(!multiselect){
                listFields[index].value = [];
                listFields[index].value.push({"text" : value, "value" : 1});
            }else{
                listFields[index].value.push({"text" : value, "value" : 1});
            }
        }else{
            if(includesInJson(listFields[index].value, value) !== -1){
                listFields[index].value = listFields[index].value.filter(item => item.text !== value)
            }else{
                if(!multiselect){
                    listFields[index].value = [];
                    listFields[index].value.push({"text" : value, "value" : 1});
                }else{
                    listFields[index].value.push({"text" : value, "value" : 1});
                }
            }
        }

        setFields(listFields);
        setDataHasChanged(true);
    }

    const handleChangeInputTable = (value, indexation,field,index,multiselect,mandatory) => {

        let listFields = fields;

        if(mandatory){
            if(!multiselect){
                listFields[index].value = [];
                listFields[index].value.push({"text" : value.text, "value" : value.value});
            }else{
                if(includesInJson(listFields[index].value, value.text) === -1){
                    listFields[index].value.push({"text" : value.text, "value" : value.value});
                }else{
                    listFields[index].value[includesInJson(listFields[index].value, value.text)].value =  value.value;
                }
            }
        }
        else{
            if(!multiselect){
                listFields[index].value = [];
                listFields[index].value.push({"text" : value.text, "value" :  value.value});
            }else{
                if(includesInJson(listFields[index].value, value.text) === -1){
                    listFields[index].value.push({"text" : value.text, "value" : value.value});
                }else{
                    listFields[index].value[includesInJson(listFields[index].value, value.text)].value =  value.value;
                }
            }
        }

        setFields([...listFields]);
        setDataHasChanged(true);
    }

    const handleUpdateInputTable = (value, indexation,field,index,multiselect,mandatory) => {
        let listFields = fields;
        let tmpValue = listFields[index].value;

        listFields[index].value = tmpValue.filter(item => value.includes(item.text));

        setFields(listFields);
        setDataHasChanged(true);
    }

    const handleDeleteInputTable = (value, indexation, field, index, multiselect, mandatory) => {
        let listFields = fields;
        let tmpValue = listFields[index].value;

        listFields[index].value = tmpValue.filter((item) => {
            return !item.text.includes(value)
        });

        setFields([...listFields]);
        setDataHasChanged(true);
    };

    const handleChangeKeyword = (value, indexation,field,index) => {

        let listFields = fields;
        if(listFields[index].value.includes(value)){
            listFields[index].value = listFields[index].value.filter(item => item !== value)
        }else{
            listFields[index].value.push(value);
        }

        setFields(listFields);
        setDataHasChanged(true);
    };

    const handleChangeBoolean  = (value,field,index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields(listFields);
        setDataHasChanged(true);
    };

    const handleChangePlugcode = (plugcode, comment, field, index) => {
        let listFields = fields;
        listFields[index].value = plugcode + ';' + comment;
        setFields(listFields);
        setDataHasChanged(true);
    };

    const handleChangePlugcodeInternal = (plugcode, comment, field, index, destinations) => {
        let listFields = [...fields];

        listFields[index].value = plugcode;

        if (destinations?.length > 0) {
            let updatedListFields =  listFields.map((field, index) => {
                if(!!field.id){
                    if(typeof destinations.find((elm) => Number(field.id) === Number(elm.dest)) !== "undefined"){
                        let destination = destinations.find((elm) => Number(field.id) === Number(elm.dest));
                        switch (field.format) {
                            case 'numeric':
                                let tmpValue = destination.value.replaceAll(",", ".");
                                return {...field, value : tmpValue, visuelValue :  tmpValue}
                            default:
                                return {...field, value : destination.value, visuelValue :  destination.value}
                        }
                    }
                }
                return field;
            });

            setFields(updatedListFields);
            setDataHasChanged(true);
        }else{
            setFields(listFields);
            setDataHasChanged(true);
        }
    }

    const handleChangePassword = (value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields(listFields);
        setDataHasChanged(true);
    };

    const handleChangeLocalisation = (value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields([...listFields]);
        setDataHasChanged(true);
    };

    const handleChangePlugform = (value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields(listFields);
        setDataHasChanged(true);
    }

    const handleChangeIntDataMaj = (value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields(listFields);
        setDataHasChanged(true);
    };

    const handleChangeExtData = async (destinations) => {
        let listFields = [...fields];

        if (destinations.length > 0) {
            let updatedListFields =  listFields.map((field, index) => {
                if(!!field.id){
                    if(typeof destinations.find((elm) => Number(field.id) === Number(elm.dest)) !== "undefined"){
                        let destination = destinations.find((elm) => Number(field.id) === Number(elm.dest));
                        return {...field, value : destination.value, visuelValue :  destination.value}
                    }
                }
                return field;
            });

            setFields(updatedListFields);
            setDataHasChanged(true);
        }

    }

    const handleSavePlugnote = () => {
        if (dataHasChanged) {
            if (checkData(fields)) {
                if(selectedFiles !== null){
                    handleClickAlertWithCancel();
                }else{
                    handleClickAlertWithoutCancel();
                }
            }
            else{
                setDataHasChanged(false);
                const modelForHash = getModel();
                let dataForHash = buildUpdateAnnexe(fields);

                props.attemptUpdateDataWithoutRedirect(
                    modelForHash.hash,
                    environment.userhash,
                    dataForHash);

            }
        }
    }


    const handleUploadClick = () => {
        // `current` points to the mounted file input element
        inputFile.current.click();
    };

    const handleDeleteAnnexe = (event,annexe,previousFile) => {
        event.preventDefault();
        event.stopPropagation();

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className={classes.confirmContainer}>
                        <h1 className={classes.confirmTitle}>{t('global.confirm')}</h1>
                        <p className={classes.confirmText}>{t('plugnote.confirm-delete-annexe')}</p>
                        <div className={classes.confirmButtons}>
                            <button className={`${classes.confirmButtonStyle} ${classes.confirmNegative}`} onClick={onClose}>{t('global.cancel')}</button>
                            <button
                                className={`${classes.confirmButtonStyle} ${classes.confirmAffirmative}`}
                                onClick={() => {
                                    let dataForHash = buildUpdateAnnexe(fields);
                                    props.attemptDeleteAnnexe(annexe.hash, environment.userhash, modelForHash.hash,dataForHash);
                                    if(typeof previousFile !== "undefined" && previousFile !== null){
                                        previousFile();
                                    }
                                    onClose();
                                }}
                            >
                                {t('global.yes')}
                            </button>
                        </div>
                    </div>
                );
            }
        });
    };

    const handleDownloadFile = (annexe) => {
        saveAs(ApplicationConfig.downloadUrl + environment.userhash + '/' + plugnote.hash + '/' + annexe.name + '.' + annexe.type, annexe.name + '.' + annexe.type);
    };

    //      .fetch('GET', baseURL + userHash + "/" + action.dataHash + "/" + encodeURIComponent(action.annexe.name) + "." + action.annexe.type)
    const downloadEmployeeData = (event,annexe) => {
        event.preventDefault();
        event.stopPropagation();
        // Fetch the dynamically generated excel document from the server.
        trackPromise(
            axios.get(ApplicationConfig.apiUrl + 'annexe.json?annexehash=' + annexe.hash, {crossdomain: true,
                'headers': {
                    'X-AUTH-TOKEN': getToken(),
                }
            }).then((response) => {
                if(response.data.result){
                    const fileNameHeader = "x-suggested-filename";
                    const suggestedFileName = response.headers[fileNameHeader];
                    const userindex = zeroPad(plugnote.userindex, 5); // "000005"
                    const effectiveFileName = (suggestedFileName === undefined ? 'PN-' + environment.envIndex + '-' + userindex + '-' + annexe.name + '-' + moment().format('YYMMDDHHmmss') + '.' + annexe.type : suggestedFileName);
                    var a = document.createElement("a"); //Create <a>
                    a.href = response.data.base64; //Image Base64 Goes here
                    a.download = effectiveFileName; //File name Here
                    a.click(); //Downloaded file

                }
            }).catch((response) => {
                console.error("Could not Download the Excel report from the backend.", response);
            })
        )

    };

    //      .fetch('GET', baseURL + userHash + "/" + action.dataHash + "/" + encodeURIComponent(action.annexe.name) + "." + action.annexe.type)

    const getUrlPreviewAnnexe = (annexe) => {
        return ApplicationConfig.downloadUrl + environment.userhash + "/" + plugnote.hash + "/" + encodeURIComponent(annexe.name) + "." + annexe.type
    }

    const renderAnnexePreview = (annexeToPreview) => {
        switch (annexeToPreview.type.toLowerCase()) {
            case 'jpg':
            case 'JPG':
            case 'jpeg':
            case 'png':
            case 'PNG':
            case 'gif':
                return <img src={getUrlPreviewAnnexe(annexeToPreview)} alt={annexeToPreview.name} onClick={(event) => handlePreviewAnnexe(event,annexeToPreview.hash)} className={classes.pointer}/>;
                break;

            case 'mp4':
            case 'mkv':
            case 'avi':
            case 'mov':
                return <FontAwesomeIcon icon={faFileVideo} size="lg" style={{fontSize : '50', marginRight : '10'}}  />;
                break;

            case 'pdf':
                return <FontAwesomeIcon icon={faFilePdf} size="lg" style={{fontSize : '50', marginRight : '10'}}   onClick={(event) => handlePreviewPdfAnnexe(event,getUrlPreviewAnnexe(annexeToPreview))} className={classes.pointer}/>;
                break;

            case 'zip':
            case 'rar':
                return <FontAwesomeIcon icon={faFileArchive} size="lg" style={{fontSize : '50', marginRight : '10'}}  />;
                break;

            case 'mp3':
            case 'aac':
                return <FontAwesomeIcon icon={faFileAudio} size="lg" style={{fontSize : '50', marginRight : '10'}}   />;
                break;

            case 'html':
                return <FontAwesomeIcon icon={faFileCode} size="lg" style={{fontSize : '50', marginRight : '10'}}  />;
                break;

            case 'txt':
            case 'sheet':
            case 'doc':
            case 'docx':
                return <FontAwesomeIcon icon={faFileWord} size="lg" style={{fontSize : '50', marginRight : '10'}}  />;
                break;

            case 'xls':
            case 'xlsx':
            case 'csv':
                return <FontAwesomeIcon icon={faFileExcel} size="lg" style={{fontSize : '50', marginRight : '10'}}  />;
                break;

            case 'note':
                return <FontAwesomeIcon icon={faStickyNote} size="lg" style={{fontSize : '50', marginRight : '10'}}   />;
                break;

            case 'pages':
                return <FontAwesomeIcon icon={faFileWord} size="lg" style={{fontSize : '50', marginRight : '10'}}  />;
                break;

            case 'pptx':
            case 'ppt':
                return <FontAwesomeIcon icon={faFilePowerpoint} size="lg" style={{fontSize : '50', marginRight : '10'}}  />;
                break;
            default :
                return <FontAwesomeIcon icon={faFile} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;
        }
    };

    const handleSaveNote = (title, content) => {
        props.attemptAddNoteAnnexe(model.hash, plugnote.hash,environment.userhash, title, content , false, null);
        setSelectedNote(null);
    }

    const handleUpdateNote = (title, content, annexehash, notehash) => {
        props.attemptEditNoteAnnexe(annexehash,notehash,title,content);
        setSelectedNote(null);
    }

    const handleNoteOpen = (event,annexe) => {
        event.preventDefault();
        event.stopPropagation();
        if(typeof annexe !== "undefined"){
            setSelectedNote(annexe);
        }else{
            setSelectedNote(null);
        }

        setOpenNote(true);

    };

    const handleNoteClose = () => {
        setOpenNote(false);
        setSelectedNote(null);

    };

    const handleCreateNote = (event) => {
        handleNoteOpen(event);
    }

    const handlePreviewAnnexe = (event,hash) => {
        event.preventDefault();
        event.stopPropagation();
        const images = [];
        plugnoteAnnexes.map((annexeToPreview, index) => {
            switch (annexeToPreview.type.toLowerCase()) {
                case 'jpg':
                case 'JPG':
                case 'jpeg':
                case 'png':
                case 'PNG':
                case 'gif':
                    let url = getUrlPreviewAnnexe(annexeToPreview);
                    images.push(url);
                    if(hash === annexeToPreview.hash){
                        setSelectedPreviewAnnexe(images.length - 1);
                    }
                    break;
            }
        });

        setImages(images);
        setOpenModalPreview(true);
    };

    const handlePreviewPdfAnnexe = (event,url) => {
        event.preventDefault();
        event.stopPropagation();
        window.open(url, "_blank");
        //setOpenPdfPreview(true);
        //setSelectedPreviewPdfAnnexe(url);
    };

    const handleModalSendMailAnnexeOpen = () => {
        setIsOpenModalSendMailAnnexe(true);
    }

    const handleModalSendMailAnnexeClose = () => {
        setIsOpenModalSendMailAnnexe(false);
    };

    const handlePreviewPdfAnnexeClose = () => {
        setOpenPdfPreview(false);
        setSelectedPreviewPdfAnnexe(null);
    }

    const handleModalPlugnotePlugcodeOpen = () => {
        props.attemptResetPlugnotePlugcode();
        if (dataHasChanged) {
            if (checkData(fields)) {
                if(selectedFiles !== null){
                    handleClickAlertWithCancel();
                }else{
                    handleClickAlertWithoutCancel();
                }
            }
            else{
                setDataHasChanged(false);
                const modelForHash = getModalModel();
                let dataForHash = buildUpdateAnnexe(fields);

                props.attemptUpdateDataWithoutRedirect(
                    modelForHash.hash,
                    environment.userhash,
                    dataForHash);

                setIsOpenModalPlugnotePlugcode(true)
            }
        }else{
            setIsOpenModalPlugnotePlugcode(true);
        }
    }

    const handleModalPlugnotePlugcodeClose = () => {
        props.attemptResetPlugnotePlugcode();
        setIsOpenModalPlugnotePlugcode(false);
    };

    const handleModalListAnnexesVersionOpen = (event,annexehash) => {
        event.preventDefault();
        event.stopPropagation();
        props.attemptGetAnnexesVersion(annexehash);
        setIsOpenModalListAnnexesVersion(true);

    }

    const handleModalListAnnexesVersionClose = () => {
        setIsOpenModalListAnnexesVersion(false);
    };

    const handleClickFavoris = () => {
        props.attemptUpdateFavorisPlugnote(plugnote.hash, !plugnote.favorite, environment.userhash);
        plugnote.favorite = !plugnote.favorite;
        setFavorite(plugnote.favorite)
    };
    const handleClickLock = () => {
        props.attemptUpdateLockPlugnote(plugnote.hash, plugnote.isarchived, !plugnote.locked, environment.userhash);
        plugnote.locked = !plugnote.locked;
        setLocked(plugnote.locked);
    };
    const handleClickArchive = () => {
        props.attemptUpdateArchivePlugnote(plugnote.hash, !plugnote.isarchived, plugnote.locked, environment.userhash);
        plugnote.isarchived = !plugnote.isarchived;
    };

    const handleDeletePlugnote = () => {

        confirmAlert({
            title: t('global.plugnotes'),
            message: t('plugnote.confirm-delete'),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () =>   props.attemptDeletePlugote(plugnote.hash,environment.userhash,'/pluglist')
                },
                {
                    label: t('global.cancel'),
                }
            ]
        });

    };

    const handleModalPlugnoteClassifyClose = () => {
        setIsOpenClassify(false);
    };

    const handleModalPlugnoteClassifyOpen = () => {
        setIsOpenClassify(true);
    };

    const _handleOpenModalListAnnexes = () => {
        setIsOpenModalListAnnexes(true)
    }

    const _handleCloseModalListAnnexes = () => {
        setIsOpenModalListAnnexes(false)

    }

    const onError = (e) => {
        //console.log(e, 'error in file-viewer');
    }

    const checkMimeType=(event)=>{
        //getting file object
        let files = event.target.files
        //define message container
        let err = []
        // list allow mime type
        // const types = ['image/png', 'image/jpeg', 'image/gif']
        // // loop access array
        // for(var x = 0; x<files.length; x++) {
        //     // compare file type find doesn't matach
        //     if (types.every(type => files[x].type !== type)) {
        //         // create error message and assign to container
        //         err[x] = files[x].type+' is not a supported format\n';
        //     }
        // };
        for(var z = 0; z<err.length; z++) {// if message not same old that mean has error
            // discard selected file
            toast.error(err[z])
            event.target.value = null
        }
        return true;
    }
    const maxSelectFile=(event)=>{
        let files = event.target.files
        if (files.length > 1) {
            const msg = 'Only 1 images can be uploaded at a time'
            event.target.value = null
            toast.warn(msg)
            return false;
        }
        return true;
    }
    const checkFileSize=(event)=>{
        let files = event.target.files
        let size = 1000000000
        let err = [];
        for(var x = 0; x<files.length; x++) {
            if (files[x].size > size) {
                err[x] = files[x].type+'is too large, please pick a smaller file\n';
            }
        };
        for(var z = 0; z<err.length; z++) {// if message not same old that mean has error
            // discard selected file
            toast.error(err[z])
            event.target.value = null
        }
        return true;
    }

    const maxSelectFileDrop=(files)=>{
        if (files.length > 1) {
            const msg = 'Only 1 images can be uploaded at a time'
            toast.warn(msg)
            return false;
        }
        return true;
    };

    const checkFileSizeDrop=(files)=>{
        let size = 100000000;
        let err = [];
        for(var x = 0; x<files.length; x++) {
            if (files[x].size > size) {
                err[x] = files[x].type+'is too large, please pick a smaller file\n';
            }
        };
        for(var z = 0; z<err.length; z++) {// if message not same old that mean has error
            // discard selected file
            toast.error(err[z])
        }
        return true;
    };

    const onChangeHandlerDrop = (acceptedFiles) => {
        const files = acceptedFiles;

        if(maxSelectFileDrop(files) &&  checkFileSizeDrop(files)){
            setLoaded(0);
            setSelectedFiles(files);
        }
    }

    const onChangeHandler=event=>{

        const files = event.target.files;

        if(maxSelectFile(event) && checkMimeType(event) &&    checkFileSize(event)){
            // if return true allow to setState
            setLoaded(0);
            setSelectedFiles(files);
        }
    }

    const onChangeUpdateHandler=event=>{

        const files = event.target.files;

        if(maxSelectFile(event) && checkMimeType(event) &&    checkFileSize(event)){
            // if return true allow to setState
            setLoaded(0);
            setSelectedUpdateFiles(files);
        }
    }

    const onClickHandler = () => {
        const data = new FormData();
        for(let x = 0; x < selectedFiles.length; x++) {
            data.append('file', selectedFiles[x])
        }
        props.attemptPostAnnexes(model.hash, environment.userhash, plugnote.hash, false, false, null ,data);
        resetFileInput();
    };

    const onClickUpdateHandler = () => {
        const data = new FormData();
        for(let x = 0; x < selectedUpdateFiles.length; x++) {
            data.append('file', selectedUpdateFiles[x])
        }
        props.attemptPostUpdateAnnexes(model.hash, environment.userhash, plugnote.hash, selectedAnnexe.hash, false, false, null ,data);
        setSelectedAnnexe(null);
        setSelectedUpdateFiles(null);
        resetFileUpdateInput();
    };

    const isAuthorisedToEdit = (type) => {
        return authorisedToEdit.includes(type.toLowerCase());
    }

    const renderAnnexeIconPreview = (annexeToPreview) => {
        if (annexeToPreview !== null) {
            switch (annexeToPreview.type.toLowerCase()) {
                case "jpg":
                case "JPG":
                case "jpeg":
                case "png":
                case "PNG":
                case "gif":
                    /* return <img src={getUrlPreviewAnnexe(annexeToPreview)} alt={annexeToPreview.name} onClick={(event) => handlePreviewAnnexe(event,annexeToPreview.hash)} className={classes.pointer}/>; */
                    return (
                        <FontAwesomeIcon
                            icon={faFileImage}
                            className={classes.faIconSize}
                        />
                    );
                    break;

                case "mp4":
                case "mkv":
                case "avi":
                case "mov":
                    return (
                        <FontAwesomeIcon
                            icon={faFileVideo}
                            className={classes.faIconSize}
                        />
                    );
                    break;

                case "pdf":
                    return (
                        <FontAwesomeIcon
                            icon={faFilePdf}
                            className={classes.faIconSize}
                            onClick={(event) =>
                                handlePreviewPdfAnnexe(
                                    event,
                                    getUrlPreviewAnnexe(annexeToPreview)
                                )
                            }
                        />
                    );
                    break;

                case "zip":
                case "rar":
                    return (
                        <FontAwesomeIcon
                            icon={faFileArchive}
                            className={classes.faIconSize}
                        />
                    );
                    break;

                case "mp3":
                case "aac":
                    return (
                        <FontAwesomeIcon
                            icon={faFileAudio}
                            className={classes.faIconSize}
                        />
                    );
                    break;

                case "html":
                    return (
                        <FontAwesomeIcon icon={faFileCode} className={classes.faIconSize} />
                    );
                    break;

                case "txt":
                case "sheet":
                case "doc":
                case "docx":
                    return (
                        <FontAwesomeIcon icon={faFileWord} className={classes.faIconSize} />
                    );
                    break;

                case "xls":
                case "xlsx":
                case "csv":
                    return (
                        <FontAwesomeIcon
                            icon={faFileExcel}
                            className={classes.faIconSize}
                        />
                    );
                    break;

                case "note":
                    return (
                        <FontAwesomeIcon
                            icon={faStickyNote}
                            className={classes.faIconSize}
                        />
                    );
                    break;

                case "pages":
                    return (
                        <FontAwesomeIcon icon={faFileWord} className={classes.faIconSize} />
                    );
                    break;

                case "pptx":
                case "ppt":
                    return (
                        <FontAwesomeIcon
                            icon={faFilePowerpoint}
                            className={classes.faIconSize}
                        />
                    );
                    break;
                default:
                    return (
                        <FontAwesomeIcon icon={faFile} className={classes.faIconSize} />
                    );
                    break;
            }
        }
    };

    const renderAnnexeImagePreview = (annexeToPreview) => {

        if(!annexeToPreview.hasOwnProperty('path')){
            let url = getUrlPreviewAnnexe(annexeToPreview);
            annexeToPreview.path = url;
        }

        switch (annexeToPreview.type.toLowerCase()) {
            case "jpg":
            case "JPG":
            case "jpeg":
            case "png":
            case "PNG":
            case "gif":
                /* case 'jfif': */
                return (
                    <img
                        src={annexeToPreview.path}
                        alt={annexeToPreview.name}
                        onClick={(event) =>
                            handlePreviewAnnexe(event, annexeToPreview.hash)
                        } /* className={classes.modalFile} */
                    />
                );
                break;

            case "mp4":
            case "mkv":
            case "avi":
            case "mov":
                return (
                    <video className={classes.modalFile} controls>
                        <source
                            src={annexeToPreview.path}
                            type={`video/${annexeToPreview.type.toLowerCase()}`}
                        />
                    </video>
                );
                break;

            case "pdf":
                return (
                    <iframe src={annexeToPreview.path} className={classes.modalFile} />
                );
                break;

            case "zip":
            case "rar":
                return (
                    <div className={classes.notRender}>
                        <FontAwesomeIcon
                            icon={faFileArchive}
                            className={classes.faIconView}
                        />
                        <p class={classes.notRenderText}>
                            {t("multiple.file-not-previewed")}
                        </p>
                    </div>
                );
                break;

            case "mp3":
            case "aac":
                return (
                    <audio className={classes.modalFile} controls>
                        <source
                            src={annexeToPreview.path}
                            type={`audio/${annexeToPreview.type.toLowerCase()}`}
                        />
                    </audio>
                );
                break;

            case "html":
                return (
                    <iframe src={annexeToPreview.path} className={classes.modalFile} />
                );
                break;

            case "txt":
                return (
                    <iframe
                        src={annexeToPreview.path}
                        className={classes.modalFile}
                        onClick={(event) =>
                            handlePreviewPdfAnnexe(
                                event,
                                getUrlPreviewAnnexe(annexeToPreview)
                            )
                        }
                    />
                );
                break;

            case "sheet":
            case "doc":
            case "docx":
                /* return <iframe src={`https://docs.google.com/gview?url=${getUrlPreviewAnnexe(annexeToPreview)}&embedded=true`} className={classes.modalFile} onClick={(event) => handlePreviewPdfAnnexe(event,getUrlPreviewAnnexe(annexeToPreview))} />; */
                return (
                    <iframe
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${annexeToPreview.path}`}
                        className={classes.modalFile}
                    />
                );
                break;

            case "xls":
            case "xlsx":
            case "csv":
                return (
                    <iframe
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${annexeToPreview.path}`}
                        className={classes.modalFile}
                    />
                );
                break;

            case "note":
                return (
                    <div className={classes.notRender}>
                        <FontAwesomeIcon
                            icon={faStickyNote}
                            className={classes.faIconSize}
                        />
                        <p class={classes.notRenderText}>
                            {t("multiple.file-not-previewed")}
                        </p>
                    </div>
                );
                break;

            case "pages":
                return (
                    <div className={classes.notRender}>
                        <FontAwesomeIcon icon={faFileWord} className={classes.faIconSize} />
                        <p class={classes.notRenderText}>
                            {t("multiple.file-not-previewed")}
                        </p>
                    </div>
                );
                break;

            case "pptx":
            case "ppt":
                return (
                    <iframe
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${annexeToPreview.path}`}
                        className={classes.modalFile}
                    />
                );
                break;
            default:
                return (
                    <div className={classes.notRender}>
                        <FontAwesomeIcon icon={faFile} className={classes.faIconView} />
                        <p class={classes.notRenderText}>
                            {t("multiple.file-not-previewed")}
                        </p>
                    </div>
                );
                break;
        }
    };

    return (
        <Dialog fullScreen open={props.open} onClose={props.close} >
            <Plugforms
                download={false}
                mode="modal-shared-update"
                model={modelForHash}
                modal={true}
                shared={false}
                fields={fields}
                visibleAll={visibleAll}
                attemptGetDataUnique={props.attemptGetDataUnique}
                handleChangeTableValue={handleChangeTableValue}
                handleChangeTableRowsValue={handleChangeTableRowsValue}
                handleEditValue={handleEditValue}
                handleEditDateTime={handleEditDateTime}
                plugnoteAnnexes={plugnoteAnnexes}
                nameModel={nameModel}
                //searchPlugcode={searchPlugcode}
                //viewInputSearchCode={viewInputSearchCode}
                plugnote={plugnote}
                //handleExitCreatePlugnote={handleExitCreatePlugnote}
                //openSave={openSave}
                //openClassify={openClassify}
                //handleCloseClassify={handleCloseClassify}
                handleExitUpdatePlugnote={handleExitUpdatePlugnote}
                handleSaveUpdatePlugnote={handleSaveUpdatePlugnote}
                //email={email}
                //emailValid={emailValid}
                //handleSaveUpdatePlugnote = {handleSaveUpdatePlugnote}
                handleChangeLocalisation={handleChangeLocalisation}
                //isAuthorisedToEdit = {isAuthorisedToEdit}
                handleUpdateAnnexe = {handleUpdateAnnexe}
                handleModalAnnexeOpen={handleModalAnnexeOpen}
                handleDeleteAnnexe={handleDeleteAnnexe}
                environment={environment}
                fullNamePlugnote = {fullNamePlugnote}
                disabled= {locked}
                isOpenModalAnnexe={isOpenModalAnnexe}
                handleModalAnnexeClose={handleModalAnnexeClose}
                handleEditPhoneValue={handleEditPhoneValue}
                handleChangeBoolean={handleChangeBoolean}
                handleChangePassword={handleChangePassword}
                handleChangePlugcode={handleChangePlugcode}
                handleChangePlugcodeInternal={handleChangePlugcodeInternal}
                handleChangeRadioTable={handleChangeRadioTable}
                handleChangeInputTable={handleChangeInputTable}
                handleUpdateInputTable={handleUpdateInputTable}
                handleDeleteInputTable={handleDeleteInputTable}
                handleChangeKeyword={handleChangeKeyword}
                selectedAnnexe={selectedAnnexe}
                //handleSaveFictifComment={handleSaveFictifComment}
                setFields={setFields}
                handleChangeRadio={handleChangeRadio}
                onChangeHandlerDrop={onChangeHandlerDrop}
                handleUploadClick={handleUploadClick}

                //handleSaveCreatePlugnote={handleSaveCreatePlugnote}
                //handleCloseSave={handleCloseSave}
                //handleChangeEmail={handleChangeEmail}
                //handleSaveUpdatePlugnote = {handleSaveUpdatePlugnote}
                isOpenModalListAnnexes={isOpenModalListAnnexes}
                _handleOpenModalListAnnexes={_handleOpenModalListAnnexes}
                _handleCloseModalListAnnexes={_handleCloseModalListAnnexes}
                //isAuthorisedToEdit = {isAuthorisedToEdit}
                downloadEmployeeData={downloadEmployeeData}
                //handleUpdateAnnexe = {handleUpdateAnnexe}
                handleModalListAnnexesVersionOpen={handleModalListAnnexesVersionOpen}
                handlePreviewAnnexe={handlePreviewAnnexe}
                handlePreviewPdfAnnexe={handlePreviewPdfAnnexe}
                renderAnnexeIconPreview={renderAnnexeIconPreview}
                renderAnnexeImagePreview={renderAnnexeImagePreview}
                //fullNamePlugnote = {fullNamePlugnote}
                //disabled= {locked}
                handleNoteOpen = {handleNoteOpen}
                handleCreateNote = {handleCreateNote}
                handleUpdateNote = {handleUpdateNote}
                handleNoteClose = {handleNoteClose}
                handleSavePlugnote={handleSavePlugnote}
            />
            {
                openNote &&
                <ModalNoteComponent selectedNote={selectedNote} open={openNote} close={handleNoteClose} handleSaveNote={handleSaveNote} handleUpdateNote={handleUpdateNote}/>
            }

            <Dialog
                open={openModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{t('global.plugnotes')}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {
                            t('plugnote-create-update.plug-form-mandatory-fields-empty')
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    { cancel &&
                        <Button onClick={handleClickCancelModal} color="primary">
                            {
                                t('global.cancel')
                            }
                        </Button>
                    }
                    <Button onClick={handleClickContinueModal} color="primary" autoFocus>
                        {
                            t('global.ok')
                        }
                    </Button>
                </DialogActions>
            </Dialog>
        </Dialog>
    );
}



// redux providing state takeover
const mapStateToProps = (state, props) => {

    let plugnote = props.plugnote;

    const annexes = plugnote !== null &&  getModalAnnexes() !== null ? getModalAnnexes() : [];
    //const annexes = plugnote !== null &&  getModalAnnexes() !== null ? plugnote.Annexes : [];
    if(!!state.annexe.listModal){
        if(state.annexe.listModal.length > 0){
            state.annexe.listModal.map((an,index) => {
                if(!annexes.find(element => element.hash === an.hash)){
                    annexes.push(an);
                    setModalAnnexes(annexes);
                }else if(annexes.find(element => element.hash === an.hash)){
                    let findIndex = annexes.findIndex(element => element.hash === an.hash);
                    annexes[findIndex].lastversion = an.lastversion;
                    annexes[findIndex].creationdate = an.creationdate;
                    annexes[findIndex].countVersion = an.countVersion;
                    setModalAnnexes(annexes);
                }
            })

        }
    }

    return {
        user : state.user,
        token : state.user.token,
        plugnotes : !!state.data.list ? state.data.list : [],
        plugnote : plugnote,
        model : props.model,
        fetching : state.data.fetching,
        progress : state.annexe.progress,
        annexes : annexes,
        isSearchActive: state.search.isSearchActive,
        isDataSearchActive : state.data.isDataSearchActive,
        type : !!state.user && !!state.user.user  ? state.user.user.type : null,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptGetDataUnique: (datahash, userhash) => dispatch(getDataUniqueRequest(datahash, userhash)),
        attemptResetPlugnotePlugcode: () => dispatch(reduxGeneratePlugnotecodeReset()),
        attemptUpdateData: (modelHash, userHash ,formData) => {
            return dispatch(putUpdateDataRequest(modelHash, userHash, formData))
        },
        attemptUpdateDataShared: (modelHash, userHash ,formData) => {
            return dispatch(putUpdateSharedDataRequest(modelHash, userHash, formData))
        },
        attemptUpdateDataWithoutRedirect: (modelHash, userHash ,formData) => {
            return dispatch(putUpdateDataWithoutRedirectModalRequest(modelHash, userHash, formData))
        },
        attemptUpdateDataSharedWithoutRedirect: (modelHash, userHash ,formData) => {
            return dispatch(putUpdateSharedDataWithoutRedirectModalRequest(modelHash, userHash, formData))
        },
        attemptPostAnnexes: (modelhash,userhash, datahash,modelshared,newdata,formdata,formfile) => dispatch(postAnnexesModalRequest(modelhash,userhash, datahash,modelshared,newdata,formdata,formfile)),
        attemptPostSharedAnnexes: (modelhash,userhash, datahash,modelshared,newdata,formdata,formfile) => dispatch(postAnnexesSharedModalRequest(modelhash,userhash, datahash,modelshared,newdata,formdata,formfile)),
        attemptPostUpdateAnnexes: (modelhash,userhash, datahash, annexehash,modelshared,newdata,formdata,formfile) => dispatch(postAnnexesVersionModalRequest(modelhash,userhash, datahash,annexehash,modelshared,newdata,formdata,formfile)),
        attemptPostUpdateSharedAnnexes: (modelhash,userhash, datahash, annexehash,modelshared,newdata,formdata,formfile) => dispatch(postAnnexesVersionSharedModalRequest(modelhash,userhash, datahash,annexehash,modelshared,newdata,formdata,formfile)),
        attemptDeleteAnnexe : (annexehash, userhash,modalhash,formdata) => dispatch(delDeleteAnnexeModalRequest(annexehash, userhash,modalhash,formdata)),
        attemptAddNoteAnnexe: (modelhash, datahash, userhash, name, note, newdata, formdata) => dispatch(postAddNoteModalRequest(modelhash, datahash, userhash, name, note, newdata,formdata)),
        attemptEditNoteAnnexe: (annexehash, notehash, name, note) => dispatch(putEditNoteModalRequest(annexehash, notehash, name, note)),
        attemptDeletePlugote: (datahash, userhash,back) => dispatch(delDeleteDataRequest(datahash, userhash,back)),
        attemptUpdateFavorisPlugnote: (datahash, favorite, userhash) => dispatch(putUpdateDataFavorisRequest(datahash, favorite, userhash)),
        attemptUpdateLockPlugnote: (datahash, isarchived, locked, userhash) => dispatch(putUpdateDataLockRequest(datahash, isarchived, locked, userhash)),
        attemptUpdateArchivePlugnote: (datahash, isarchived, locked, userhash) => dispatch(putUpdateDataArchiveRequest(datahash, isarchived, locked, userhash)),
        attemptGetAnnexesVersion : (annexehash) => dispatch(getAnnexesVersionRequest(annexehash)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalPlugnoteUpdateComponent)
