import { useEffect, useState } from "react"
import qs from "querystringify";
import { trackPromise } from "react-promise-tracker"
import ApplicationConfig from "../config/ApplicationConfig"
import axios from "axios"
import { getToken } from "../utils/Common"
import { devLog, isDefined } from "../utils/utils"
import { getModelUniqueByClefRequest } from "../actions/model"
import { api } from "../api";

const useFetchPlugform = (field) => {
  const [model, setModel] = useState(null)
  const pfcode = field?.options?.pfcode

  const handleFetchModel = () => {
    trackPromise(
      api.get('pfcode/modelcodelogoff.json' + qs.stringify({code: encodeURIComponent(pfcode)}, '?'))
      .then((response) => {
        // devLog('response', response)
        setModel(response.data.data)
      }).catch((response) => {
          console.error(
              "Could not download the Extdata from the backend.",
              response
          )
      }))
  }
  useEffect(() => {
    // devLog('pfcode', pfcode)
    if (isDefined(field)) {
      handleFetchModel()
    }
  }, [field])

  return model
}

export default useFetchPlugform