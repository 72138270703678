import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import colors from '../../assets/themes/Colors'
import { isDefined } from '../../utils/utils'

const useStyles = makeStyles(() => ({
  mainCtn: {
    display: 'flex',
    gap: 4,
    cursor: 'pointer'
  },
  left: {
    flexDirection: 'row',
  },
  right: {
    flexDirection: 'row-reverse',
  },
}))

const PlugRadio = ({ color = colors.main, checked, label, onClick, placement = "left" }) => {
  const classes = useStyles()
  
  const handleClick = () => {
    if (isDefined(onClick)) { onClick() }
    else return
  }

  return (
    <span className={` ${classes.mainCtn} ${placement === 'left' ? classes.left : classes.right}`} onClick={handleClick}>
      <span className={`material-icons`} style={{ color: color }}>
        radio_button_{
          checked
          ? 'checked'
          : 'unchecked'
        }
      </span>
      {label}
    </span>
  );
}

export default PlugRadio
