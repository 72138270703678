import {apiV2} from "./indexV2";
import qs from "querystringify";


export let getAllLayoutsByModel,getOneLayoutByModel,postLayoutByModel,deleteOneLayoutByModel;

deleteOneLayoutByModel = (mid,userhash,id) => apiV2.delete('Models/DeleteModelLayout.php' + qs.stringify({uh: userhash,mid: mid, id : id}, '?'));
getOneLayoutByModel = (mid,identifiant,userhash) => apiV2.get('Models/GetModelLayout.php' + qs.stringify({uh: userhash,mid: mid,id:identifiant}, '?'));
getAllLayoutsByModel = (mid,userhash) => apiV2.get('Models/GetModelLayouts.php' + qs.stringify({uh: userhash,mid: mid}, '?'));
postLayoutByModel = (payload) => apiV2.post('Models/SaveModelLayout.php', payload);
