import {call, fork, put, select, take, takeEvery} from 'redux-saga/effects';

import * as actions from '../actions/eximport';
import * as api from '../api/eximportApi';
import {trackPromise} from "react-promise-tracker";
import {createUploadFileChannel} from "./createFileUploadChannel";
import ApplicationConfig from "../config/ApplicationConfig";
import qs from "querystringify";
import {postExportDataWithF32FromModelToCsv} from "../api/eximportApi";

const myGenericTrackedWrapper = (fn, args1, args2, args3) => trackPromise(fn(args1,args2, args3));


export function * postExportDataFromModelCsv (action) {

    try {

        if(action.shared){
            //const profil = yield select((state) => state.login.profile);
            yield call(myGenericTrackedWrapper,api.postExportDataFromModelToCsv, action.userhash, action.payload, action.shared);
        }else{
            yield call(myGenericTrackedWrapper,api.postExportDataFromModelToCsv, action.userhash, action.payload, action.shared);
        }

        yield put(actions.postExportDataFromModelCsvSuccess());

    }catch (e) {
        yield put(actions.postExportDataFromModelCsvError());

    }
}

export function * postExportDataF32FromModelCsv (action) {

    try {

        if(action.shared){
            //const profil = yield select((state) => state.login.profile);
            yield call(myGenericTrackedWrapper,api.postExportDataWithF32FromModelToCsv, action.userhash, action.payload, action.shared);
        }else{
            yield call(myGenericTrackedWrapper,api.postExportDataWithF32FromModelToCsv, action.userhash, action.payload, action.shared);
        }

        yield put(actions.postExportDataTableMultiFromModelCsvSuccess());

    }catch (e) {
        yield put(actions.postExportDataTableMultiFromModelCsvError());

    }
}



export function * postExportTemplateCsv(action) {

    try {

        if(action.shared){
            //const profil = yield select((state) => state.login.profile);
            yield call(myGenericTrackedWrapper,api.postExportsTemplates, action.userhash, action.payload, action.shared);
        }else{
            yield call(myGenericTrackedWrapper,api.postExportsTemplates, action.userhash, action.payload, action.shared);
        }

        yield put(actions.postExportTemplateCsvSuccess());

    }catch (e) {
        yield put(actions.postExportTemplateCsvError());

    }
}

export function * postExportDataTemplateCsv(action) {

    try {

        if(action.shared){
            //const profil = yield select((state) => state.login.profile);
            yield call(myGenericTrackedWrapper,api.postExportsTablesTemplates, action.userhash, action.payload, action.shared);
        }else{
            yield call(myGenericTrackedWrapper,api.postExportsTablesTemplates, action.userhash, action.payload, action.shared);
        }

        yield put(actions.postExportTemplateCsvSuccess());

    }catch (e) {
        yield put(actions.postExportTemplateCsvError());

    }
}

export function * postExportPluglinkTemplateCsv(action) {

    try {

        if(action.shared){
            //const profil = yield select((state) => state.login.profile);
            yield call(myGenericTrackedWrapper,api.postExportsPluglinkTemplates, action.userhash, action.payload, action.shared);
        }else{
            yield call(myGenericTrackedWrapper,api.postExportsPluglinkTemplates, action.userhash, action.payload, action.shared);
        }


        yield put(actions.postExportTemplateCsvSuccess());

    }catch (e) {
        yield put(actions.postExportTemplateCsvError());

    }
}

export function * postImportTemplateCsv(action) {

    const file = action.annexe;
    if(action.shared){
        const profil = yield select((state) => state.user.profil);
        action.userhash = profil.hash;
    }
    const query = {userhash: action.userhash, modelhash: action.modelhash, shared : action.shared};


    const channel = yield call(createUploadFileChannel, ApplicationConfig.apiUrl + `import-export-plugnotes/v2imports/templates.json`+ qs.stringify(query, "?"), file);

    while (true) {
        const { progress = 0, err, success,response } = yield take(channel);
        if (err) {
            yield put(actions.postImportTemplateCsvError(file, err));
            return;
        }
        if (success) {
            if(typeof response.result !== 'undefined'  && response.result === 'nok'){
                yield put(actions.postImportTemplateCsvFailure(response.result));
            }else{
                yield put(actions.postImportTemplateCsvSuccess());
            }
            return;
        }
        yield put(actions.postImportTemplateCsvProgress(file, progress));
    }
}

export function * postImportTemplateDataCsv(action) {
    const file = action.annexe;
    if(action.shared){
        const profil = yield select((state) => state.user.profil);
        action.userhash = profil.hash;
    }
    const query = {userhash: action.userhash, modelhash: action.modelhash, shared : action.shared};

    const channel = yield call(createUploadFileChannel, ApplicationConfig.apiUrl + `import-export-plugnotes/v2imports/datas/templates.json`+ qs.stringify(query, "?"), file);

    while (true) {
        const { progress = 0, err, success,response } = yield take(channel);
        if (err) {
            yield put(actions.postImportTemplateDataCsvError(file, err));
            return;
        }
        if (success) {
            if(typeof response.result !== 'undefined'  && response.result === 'nok'){
                yield put(actions.postImportTemplateDataCsvFailure(response.result));
            }else{
                yield put(actions.postImportTemplateDataCsvSuccess());
            }
            return;
        }
        yield put(actions.postImportTemplateDataCsvProgress(file, progress));
    }
}

export function * postImportTemplateDataTableCsv(action) {
    const file = action.annexe;
    if(action.shared){
        const profil = yield select((state) => state.user.profil);
        action.userhash = profil.hash;
    }
    const query = {userhash: action.userhash, modelhash: action.modelhash, shared : action.shared};

    const channel = yield call(createUploadFileChannel, ApplicationConfig.apiUrl + `import-export-plugnotes/tabs/imports/templates.json`+ qs.stringify(query, "?"), file);

    while (true) {
        const { progress = 0, err, success,response } = yield take(channel);
        if (err) {
            yield put(actions.postImportTemplateDataTableCsvError(file, err));
            return;
        }
        if (success) {
            if(typeof response.result !== 'undefined'  && response.result === 'nok'){
                yield put(actions.postImportTemplateDataTableCsvFailure(response.result));
            }else{
                yield put(actions.postImportTemplateDataTableCsvSuccess());
            }
            return;
        }
        yield put(actions.postImportTemplateDataTableCsvProgress(file, progress));
    }
}

export function * postImportTemplatePluglinkCsv(action) {
    const file = action.annexe;
    if(action.shared){
        const profil = yield select((state) => state.user.profil);
        action.userhash = profil.hash;
    }
    const query = {userhash: action.userhash, modelhash: action.modelhash, shared : action.shared};

    const channel = yield call(createUploadFileChannel, ApplicationConfig.apiUrl + `import-export-plugnotes/f28s/imports/templates.json`+ qs.stringify(query, "?"), file);

    while (true) {
        const { progress = 0, err, success,response } = yield take(channel);
        if (err) {
            yield put(actions.postImportTemplatePluglinkCsvError(file, err));
            return;
        }
        if (success) {
            if(typeof response.result !== 'undefined'  && response.result === true){
                yield put(actions.postImportTemplatePluglinkCsvFailure(response.result));
            }else{
                yield put(actions.postImportTemplatePluglinkCsvSuccess());
            }
            return;
        }
        yield put(actions.postImportTemplatePluglinkCsvProgress(file, progress));
    }
}


function* watchFetchExport() {
    // create watcher of fetchData function
    yield takeEvery(actions.Types.POST_EXPORT_DATA_TABLE_MULTI_FROM_MODEL_CSV_REQUEST, postExportDataF32FromModelCsv);
    yield takeEvery(actions.Types.POST_EXPORT_DATA_FROM_MODEL_CSV_REQUEST, postExportDataFromModelCsv);
    yield takeEvery(actions.Types.POST_EXPORT_TEMPLATE_CSV_REQUEST, postExportTemplateCsv);
    yield takeEvery(actions.Types.POST_EXPORT_TEMPLATE_PLUGLINK_CSV_REQUEST, postExportPluglinkTemplateCsv);
    yield takeEvery(actions.Types.POST_EXPORT_DATA_TEMPLATE_CSV_REQUEST, postExportDataTemplateCsv);
    yield takeEvery(actions.Types.POST_IMPORT_TEMPLATE_CSV_REQUEST, postImportTemplateCsv);
    yield takeEvery(actions.Types.POST_IMPORT_TEMPLATE_DATA_CSV_REQUEST, postImportTemplateDataCsv);
    yield takeEvery(actions.Types.POST_IMPORT_TEMPLATE_DATA_TABLE_CSV_REQUEST, postImportTemplateDataTableCsv);
    yield takeEvery(actions.Types.POST_IMPORT_TEMPLATE_PLUGLINK_CSV_REQUEST, postImportTemplatePluglinkCsv);

}

const ExImportSagas = [
    fork(watchFetchExport)
];

export default ExImportSagas;
