import React, {useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {GoogleApiWrapper, Map, Marker} from 'google-maps-react';
import {isJsonStringObject, returnJSON} from "../../utils/utils";
import {useTranslation} from "react-i18next";
import PlacesAutocomplete, {geocodeByAddress} from "react-places-autocomplete";
import Tooltip from "@material-ui/core/Tooltip";
import Geocode from "react-geocode";
import colors from "../../assets/themes/Colors";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    localButton:{
        display: 'flex',
        color: "white",
        backgroundColor: colors.main,
        padding: "3px 15px",
        marginTop: '5px',
        borderRadius: "3px",
        cursor: "pointer",
        alignItems: 'center',
        height: '38px'
    },
    locateBtn:{
        display: 'flex',
        color: "white",
        backgroundColor: colors.main,
        padding: "3px 15px",
        marginTop: '5px',
        borderRadius: "3px",
        cursor: "pointer",
        alignItems: 'center',
        height: '50px',
        maxWidth: '50px',
        position: 'relative',
        bottom: '75px',
        left: '12px',
        boxShadow: '0px 0px 4px grey',
    },
    searchHeader: {
        height: '5.5vh',
        display: 'flex',
        justifyContent: 'space-between'
    }
}));



const FormatGeoLocalisationComponent = (props) => {
    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'models']);

    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [defaultProps , setDefaultProps] = useState({latitude: 53, longitude: 53, address : ""});
    const [address , setAddress] = useState("");
    const [lng , setLng] = useState("");
    const [lat , setLat] = useState("");
    const [addressFound, setAddressFound] = useState(false)
    const [fullAddress, setFullAddress] = useState("https://www.google.com/maps/place/"+defaultProps.latitude+','+defaultProps.longitude);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(props.field.value? props.field.value : 'No address')

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    useEffect(() => {
        if(isJsonStringObject(props.field?.value)){
            let value = JSON.parse(props.field?.value);
            setDefaultProps({
                latitude: value.latitude,
                longitude: value.longitude,
                address: value?.address
            });
            setLat(value.latitude);
            setLng(value.longitude);
            setFullAddress("https://www.google.com/maps/place/"+value.latitude+','+value.longitude);
            setAddress(typeof value.address !== "undefined" &&value?.address.length > 0 ? value?.address : "")
        }else{
            handleGetCurrentLocation();
        }
    }, [props.field]);

    const onMarkerDragEnd = (mapProps, map) => {
        if (!disabled){
            setAddressFound(false)
            setDefaultProps({
                latitude: map.position.lat(),
                longitude: map.position.lng()
            });
            setAddress("");
            setLat(map.position.lat());
            setLng(map.position.lng());
            setFullAddress("http://www.google.com/maps/place/"+ map.position.lat()+','+map.position.lng());
            setTimeout(() => handleSelectFromLatLng(), 800)
        }
    }

    const handleChange = () => {
        let newProps = JSON.stringify(defaultProps)
        props.handleChangeLocalisation(newProps, props.field, props.index)
    }

    const position = (position) =>{
        setDefaultProps({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        })
        setFullAddress("https://www.google.com/maps/place/"+position.coords.latitude+','+position.coords.longitude);
        setAddress("");
        setLat(position.coords.latitude);
        setLng(position.coords.longitude);
    }

    const error = (err) => {
        console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    const handleGetCurrentLocation = () => {
        if (navigator.geolocation) {
            const options = {
                enableHighAccuracy: true,
                timeout: 5000,
                maximumAge: 0
            };
            navigator.geolocation.getCurrentPosition(position,error,options);
        }else{
            alert('not working')
        }
    }

    const handleSelect = address => {
        setAddressFound(false)
        geocodeByAddress(address)
            .then(results => {
                setAddress(results[0].formatted_address);
                setDefaultProps({
                    latitude: results[0].geometry.location.lat(),
                    longitude: results[0].geometry.location.lng(),
                    address: results[0].formatted_address
                });
                setLat(results[0].geometry.location.lat());
                setLng(results[0].geometry.location.lng());
                //props.handleChangeLocalisation(results[0].formatted_address, props.field, props.index)
            })
            .catch(error => console.error('Error', error));
    };

    const handleSelectFromLatLng = () => {
        setAddressFound(false)
        Geocode.setApiKey("AIzaSyDhcQZNiwclLgi3Q0uiIrTHyjLXZwnbxCw");
        // Get address from latitude & longitude.
        Geocode.fromLatLng(lat, lng).then(
            (response) => {
                const results = response.results;
                setAddress(results[0].formatted_address);
                setDefaultProps({
                    latitude: results[0].geometry.location.lat,
                    longitude: results[0].geometry.location.lng,
                    address: results[0].formatted_address
                });
                setLat(results[0].geometry.location.lat);
                setLng(results[0].geometry.location.lng);
            },
            (error) => {
                console.error(error);
            },
        );
    }

    const  handleChangeAddress = address => {
        setAddress(address);
    };

    useEffect(()=>{
        if(!addressFound && !disabled) setTimeout(()=> {
            if(lat !== "" && lng !== "" && address === ""){
                handleSelectFromLatLng();
                setAddressFound(true);
                handleChange()
            }
        }, 800)
    },[lat, lng])

    useEffect(() => {
        if (props.field.value !== '') {
            let _adresse = returnJSON(props.field.value)
            if(_adresse.address !== null && _adresse.address !== undefined) setValue(_adresse.address)
        }
    }, [props.field.value])

    useEffect(() => {
        if(address !== "" && address !== null && address !== undefined) setValue(address)
    }, [address])

    return (
    <div>
        {!disabled &&
        <div className={classes.searchHeader}>
            <div style={{ marginTop : '5px', marginRight: '5px', display: 'flex', width: '100%', zIndex : 10}}>
                <PlacesAutocomplete
                    value={address}
                    onChange={handleChangeAddress}
                    onSelect={handleSelect}
                    key={props.index}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                        <div style={{width : '100%'}}>
                            <input
                                {...getInputProps({
                                    className: 'location-search-input',
                                    disabled: disabled,
                                    placeholder: 'Search Places ...',
                                })}
                            />
                            <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion, index) => {
                                    const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                    return (
                                        <div
                                            {...getSuggestionItemProps(suggestion, {
                                                className,
                                                style,
                                            })}
                                            key={index}
                                        >
                                            <span>{suggestion.description}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
            </div>
            <Tooltip title={open ? t('common:global.close') : t('common:glocalis.map')} aria-label="add" placement="top-start">
                <span id="icon-delete-playlist" className="material-icons md-30 float_right" onClick={(event) => setOpen(!open)}>
                    {open  ?
                    <div className={classes.localButton}><span className="material-icons md-small white">check</span></div>
                    :
                    <div className={classes.localButton}><span className="material-icons md-small white">room</span></div>
                    }
                </span>
            </Tooltip>
        </div>
        }

        {disabled &&
        <div className={classes.searchHeader}>
            <input
                value={value}
                disabled={disabled}
                style={{border: 'none', borderRadius: 6, paddingLeft: 10, width: '100%'}}
                inputMode="none"
                className={classes.inputContainer}
            />
            <div style={open ? {height: '5vh'} : {height: '1vh'}}>
                <Tooltip title={open ? t('common:global.close') : t('common:glocalis.map')} aria-label="add" placement="top-end">
                    <span id="icon-delete-playlist" className="material-icons md-30 float_right" onClick={(event) => setOpen(!open)}>
                        {open ?
                        <div className={classes.localButton}><span className="material-icons white">highlight_off</span></div>
                        :
                        <div className={classes.localButton}><span className="material-icons white">map</span></div>
                        }
                    </span>
                </Tooltip>
            </div>
        </div>
        }

        {open &&
        <div style={{height: '50vh', position: 'sticky'}}>
            <Map
                google={props.google} zoom={14} style={{width: '100%', height: '100%', position: 'relative'}}
                streetViewControl={false}
                initialCenter={{ lat: defaultProps.latitude, lng: defaultProps.longitude }}
                center={{ lat: defaultProps.latitude, lng: defaultProps.longitude }}
            >
                {!disabled && <Tooltip title={t('common:global.get-current-geolocalisation')} aria-label="add" placement="top-start">
                <div className={classes.locateBtn}
                    onClick={()=>handleGetCurrentLocation()}>
                    <span className="material-icons md-small white">my_location</span>
                </div></Tooltip>}
                <Tooltip title={t('common:glocalis.marker')} aria-label="add" placement="top">
                    <Marker
                        draggable={!disabled}
                        onDragend={onMarkerDragEnd}
                        position={{lat: defaultProps.latitude, lng: defaultProps.longitude}}
                        onClick={() => {
                        window.open(fullAddress,'_blank')}} // <- This is what makes it open in a new window.
                    />
                </Tooltip>
            </Map>
        </div>
        }

        <div style={{width: '100%' , marginTop : '15px', marginRight: '5px', display: 'flex', alignItems : 'baseline'}}>
            <input
                type="number" autoComplete="off" role="combobox" aria-autocomplete="list" aria-expanded="false" disabled={disabled }
                placeholder="Lat ..." className="location-search-input"
                value={lat}
                onChange={(event) => setLat(event.target.value)}/>
            <input
                type="number" autoComplete="off" role="combobox" aria-autocomplete="list" aria-expanded="false" disabled={disabled }
                placeholder="Lng ..." className="location-search-input margin-left-10"
                value={lng}
                onChange={(event) => setLng(event.target.value)}/>
            {!disabled &&
            <Tooltip title={t('common:global.set-geolocalisation')} aria-label="add" placement="top-start">
                <button className="button button-padding fullWidth" onClick={(event) => handleSelectFromLatLng()}>
                    { t('common:glocalis.gps') }
                </button>
            </Tooltip>
            }
        </div>
    </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({apiKey:'AIzaSyDhcQZNiwclLgi3Q0uiIrTHyjLXZwnbxCw'})(FormatGeoLocalisationComponent));



