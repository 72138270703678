import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import colors from "../assets/themes/Colors"
import {
  faFile,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileExcel,
  faFilePdf,
  faFilePowerpoint,
  faFileImage,
  faFileVideo,
  faFileWord,
  faStickyNote,
} from "@fortawesome/free-regular-svg-icons"
import { devLog, handlePreventDefault, isDefined } from "./utils"
import ApplicationConfig from "../config/ApplicationConfig"

export const fileIsImg = att => {
  const type = att.type.toLowerCase()
  return (
    type === "jpg" ||
    type === "JPG" ||
    type === "jpeg" ||
    type === "png" ||
    type === "PNG" ||
    type === "gif"
  )
}

export const handleOpenPreview = (e, url) => {
  handlePreventDefault(e)
  window.open(url, "_blank")
}

export const getAnnexeUrl = (annexe, environment, plugnote, model) => {
  if (isDefined(model)) {
    return ApplicationConfig.downloadUrl + model.mainOwner + "/" + plugnote.hash + "/" + encodeURIComponent(annexe.name) + "." + annexe.type
  }
  if (isDefined(environment) && isDefined(plugnote)) return ApplicationConfig.downloadUrl + environment.userhash + "/" + plugnote.hash + "/" + encodeURIComponent(annexe.name) + "." + annexe.type;
  else return annexe.path || ''
}

export const renderAnnexeIconPreview = (annexe, color, size, environment, plugnote) => {
  const customStyle = {
    color: color || colors.mainButton,
    fontSize: size || 20,
  }

  switch (annexe.type?.toLowerCase()) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return <FontAwesomeIcon icon={faFileImage} style={customStyle} />

    case 'mp4':
    case 'mkv':
    case 'avi':
    case 'mov':
      return <FontAwesomeIcon icon={faFileVideo} style={customStyle} />;

    case 'pdf':
      return (
        <FontAwesomeIcon
          icon={faFilePdf}
          style={customStyle}
        // onClick={e => handleOpenPreview(e, getAnnexeUrl(annexe, environment, plugnote))}
        />
      );

    case 'zip':
    case 'rar':
      return <FontAwesomeIcon icon={faFileArchive} style={customStyle} />;

    case 'mp3':
    case 'aac':
      return <FontAwesomeIcon icon={faFileAudio} style={customStyle} />;

    case 'html':
      return <FontAwesomeIcon icon={faFileCode} style={customStyle} />;

    case 'txt':
    case 'sheet':
    case 'doc':
    case 'docx':
      return <FontAwesomeIcon icon={faFileWord} style={customStyle} />;

    case 'xls':
    case 'xlsx':
    case 'csv':
      return <FontAwesomeIcon icon={faFileExcel} style={customStyle} />;

    case 'note':
      return <FontAwesomeIcon icon={faStickyNote} style={customStyle} />;

    case 'pages':
      return <FontAwesomeIcon icon={faFileWord} style={customStyle} />;

    case 'pptx':
    case 'ppt':
      return <FontAwesomeIcon icon={faFilePowerpoint} style={customStyle} />;
    default:
      return <FontAwesomeIcon icon={faFile} style={customStyle} />;
  }
}

export const renderAnnexePdfPreviewThumbnail = (annexe, color, width, height ) => {
  return (
    <div style={{ width: width || 70 }}>
      <iframe
        src={annexe.path + '#FitV&toolbar=0&navpanes=0'}
        style={{ width: width || 80, border: 'none', height: height || 52, marginRight: -12, }}
        title={annexe.name}
      />
    </div>
  )
}
