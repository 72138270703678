import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import { InputLabel, MenuItem, Select, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    mainField: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: 40,
        width: '70%'
    },
}));

const FormatTextConstructorComponent = (props) => {
    //console.log(props)
    const classes = useStyles()
    const [mask, setMask] = useState(props.field.options?.setup?.mask || "")

    const optionsMask = [
        { value: '', label: 'Free text' },
        { value: 'BBAN', label: 'Belgium account BBAN' },
        { value: 'IBAN', label: 'IBAN' },
        { value: 'TVA', label: 'TVA' }
    ]

    const handleChangeMask = (event) => {
        let model = [...props.model];
        model[props.index].options.setup.mask = event.target.value;
        props.setModel(model);
    }

    return (
        <div className={classes.mainField}>
            {/*<InputLabel>Type</InputLabel>*/}
            <Select
                fullWidth={true}
                name="mask-choice"
                value={mask}
                onChange={(e) => {
                    //console.log("e in onChange : ", e)
                    handleChangeMask(e)
                    setMask(e.target.value)
                    }}
                options={optionsMask}
            >
                {
                    optionsMask.map(
                        option => {
                            return (
                                <MenuItem value={option.value} key={option.value}>{option.label === "Free text" ? <em>Free text</em> : option.label}</MenuItem>
                            )
                        }
                    )
                }
            </Select>
        </div>
    )
}

// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(FormatTextConstructorComponent)