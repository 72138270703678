import {api} from "./index";
import * as qs from "querystringify";

export let
    getModels,
    getModelsLight,
    getModelsLightCountablePlugnote,
    getFullModels,
    getModelsCategories,
    getModelUnique,
    getV2ModelUnique,
    getModelSharedUnique,
    postModel,
    postV2Model,
    postModels,
    updateModel,
    replaceModel,
    updateAccess,
    updateZapier,
    duplicateModel,
    deleteModel,
    generatePlugcodePlugform,
    exportFullFilesDatas,
    getFullModelsShared,
    getModelsShared,
    confirmModelShared,
    getModelByClefUnique,
    getModelByClefAndOwnerUnique,
    postModelsByBibloCats;

getFullModels = (userhash,language) => api.get( `v6/models.json?uh=${userhash}&umh=${userhash}&lg=`+language);
getModelsCategories = (language) => api.get( `models/categorie.json?lg=`+language);
getModels = (userhash,language,page) => api.get( `v8/models.json?uh=${userhash}&umh=${userhash}&lg=${language}&page=${page}`);
getModelsLightCountablePlugnote = (userhash) => api.get( `light/countable/plugnote/models.json?uh=${userhash}&umh=${userhash}`);
getModelsLight = (userhash) => api.get( `light/models.json?uh=${userhash}&umh=${userhash}`);
getModelUnique = (modelHash,userhash,userHashMain) => api.get('modelunique?mh=' + modelHash + '&uh=' + userhash + '&umh=' + userHashMain );
getV2ModelUnique = (modelHash,userhash,userHashMain) => api.get('v2/modelunique?mh=' + modelHash + '&uh=' + userhash + '&umh=' + userHashMain );
getModelSharedUnique = (modelHash,userhash,userHashMain) => api.get('modelsharedunique?mh=' + modelHash + '&uh=' + userhash + '&umh=' + userHashMain );
getModelByClefUnique = (clef,userhash,userHashMain) => api.get('modeluniquebyclefcode?clef=' + clef + '&uh=' + userhash + '&umh=' + userHashMain );
getModelByClefAndOwnerUnique = (clef,owner,userHashMain) => api.get('modeluniquebyclefandowner?clef=' + clef + '&id=' + owner + '&umh=' + userHashMain );
postModel = (userHash, payload) => api.post('models.json?userhash=' + userHash, payload);
postV2Model = (userHash, payload) => api.post('creates/models.json?userhash=' + userHash, payload);
duplicateModel = (userHash, model) => api.post('models/duplicates.json?uh=' + userHash + '&mh=' + model.hash);
updateModel = (userHash, model) => api.put('v2/models.json?userhash=' + userHash + '&modelhash=' + model.hash, model);
replaceModel = (userHash, model) => api.put('model/replaces.json?userhash=' + userHash + '&modelhash=' + model.hash, model);
generatePlugcodePlugform = (payload) => api.post('pfcode/generatepfs.json', payload);
updateAccess = (modelhash, payload) => api.post('models/settings/accesses?mh=' + modelhash, payload);
updateZapier = (modelhash, payload) => api.post('models/settings/zapiers?mh=' + modelhash, payload);
deleteModel = (modelHash,userHash) => api.delete('models.json?modelhash=' + modelHash + '&userhash=' + userHash);
postModels = (userhash, payload) => api.post('models/multiples.json?userhash=' + userhash, payload);
postModelsByBibloCats = (userhash, payload, language) => api.post('models/bibliocats.json?userhash=' + userhash + '&lg=' + language, payload);
exportFullFilesDatas = (payload, umh, mh) => api.post('models/fulls/exports/files.json'  + '?umh=' + umh + '&mh=' + mh, payload);
getFullModelsShared = (userhash, usermainhash,language) => api.get('models/shared.json' + qs.stringify({
    uh: userhash,
    umh: usermainhash,
    lg : language
}, '?'));
getModelsShared = (userhash, usermainhash,language,page) => api.get('v2/models/shared.json' + qs.stringify({
    uh: userhash,
    umh: usermainhash,
    lg : language,
    page :page
}, '?'));
confirmModelShared = (modelsharedhash, response) => api.post('v2shareds/confirms.json', {modelsharedhash, response})



