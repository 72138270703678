import { makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PlugTooltip } from '../CustomComponents'
const useStyles = makeStyles((theme) => ({
  verticalFlex: {
    display: 'flex',
    flexDirection: 'column',
  }
}))

const UpAndDownButton = (props) => {
  const { t } = useTranslation('common')
  const styles = useStyles()

  return (
    <div className={styles.verticalFlex}>
      <PlugTooltip title={props.tooltipUp || null} placement="top">
        <div onClick={props.handleClickUp}>
          <span className="material-icons" >
              arrow_drop_up
          </span>
        </div>
      </PlugTooltip>
      <PlugTooltip title={props.tooltipDown || null}>
        <div onClick={props.handleClickDown}>
          <span className="material-icons" >
              arrow_drop_down
          </span>
        </div>
      </PlugTooltip>
    </div>
  );
}

export default UpAndDownButton
