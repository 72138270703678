import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import Colors from "../../assets/themes/Colors";
import colors from "../../assets/themes/Colors";
import { currentDateAndHours, devLog, isDefined } from "../../utils/utils";
import { postSendMailV3Request } from "../../actions/utils";
import { Data9002PrintableGetURL } from "../printable/Data9002Printable";
import { PlugTooltip } from "../custom/CustomComponents";
import SmailModalComponent from "../modals/smail/SmailModalComponent";
import { returnJSON } from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    '& .MuiDialog-paperScrollPaper': {
    display: 'flex',
    maxHeight: 'calc(100% - 64px)',
    flexDirection: 'inherit !important',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    fieldRight:{
       flexGrow: 1,
    },
    mailContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    mailButton:{
    /* border: '1px red solid', */
    display: 'inline-block',
    color: "white",
    backgroundColor: "#58c3c1",
    padding: "3px 15px",
    borderRadius: "3px",
    cursor: "pointer",
    },
    header: {
        flex: "0 0 100%",
    },
    sous_header_content: {
        padding: 15,
        display: "flex",
        justifyContent: "space-between",
        gap: 5,
        "@media (max-width: 1024px)": {
            flexDirection: "column",
            alignItems: "center",
            padding: 0,
            gap: 15,
        },
    },
    sous_header_content_title: {
        flexGrow: 1,
        overflow: "hidden",
        display: "flex",
        "@media (max-width: 1024px)": {
            width: "100%",
        },
    },
    sous_header_content_title_input: {
        color: "black",
        "@media (max-width: 1024px)": {
            textAlign: "center",
        },
    },
    sous_header_content_buttons: {
        display: "flex",
        gap: 5,
        "@media (max-width: 1024px)": {
            width: "100%",
            justifyContent: "space-between",
        },
    },
    sous_header_button: {
        width: "150px",
        padding: "4px 20px",
        borderRadius: "3px",
        cursor: "pointer",
        "@media (max-width: 1024px)": {
            width: "100%",
        },
    },
    marginLeft:{
        marginLeft : '10px'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#FFFFFF',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: '1px solid #ccc',
        WebkitBorderRadius: '5px',
    },
    faIconSize: {
        fontSize: 20,
        color: "#51C3C1",
    },
    textFieldLocked: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F3F3F3',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: '1px solid #ccc',
        WebkitBorderRadius: '5px',
    },
    textFieldArea: {
        padding: 10,
        margin: '10px auto',
        fontWeight: '500',
        borderRadius: '5px',
        backgroundColor: '#FFFFFF',
        fontSize: '14px',
        width: '100%',
        border: '1px solid #ccc',
        MozBorderRadius : '5px',
        WebkitBorderRadius: '5px',
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    mSeparatorWrapper:{
        height:3,
        backgroundColor: Colors.plugnotesBlueApp,
    },
    images: {
        paddingTop: 10,
        paddingBottom: 10,
        zIndex: 10
    },
    imageContainer: {
        paddingLeft: 15,
        paddingRight: 15,
        alignItems: 'center',
        justifyContent: 'space-between',
        maxHeight : 100,
    },
    attachmentTitle: {
        color: "black",
        fontWeight: "600",
    },
    attachmentBox: {
        margin: "0 0 40px 0",
        border: "dashed 2px #51C3C1",
        borderRadius: "5px",
        backgroundColor: "#c5e4e3",
        height: 150,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    attachmentsCtn: {
        display: "flex",
        flexDirection: "column",
        gap: 5
    },
    attItemsContainer: {
        overflow: "hidden",
    },
    attItem: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    attAnnexName: {
        overflow: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        height: "30px",
        flex: "none",
        width : "100%",
        fontSize: "14px",
        cursor: "pointer",
    },
    attIconDlInfo: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: "#D8D8D8",
    },
    attIconType: {
        margin: 10,
        width: "30px",
        height: "30px",
        cursor: "pointer",
    },
    multipleCheckBox: {
        color: colors.main,
        width: 15,
        height: 15,
    },
    switchBase: {
        "&.Mui-checked": {
            color: colors.main
        }
    },
    labelContainer: {
        width: '15%',
    },
    inputContainer: {
        width: '80%',
    },
    modalHeader: {
        // position: 'absolute',
        top: 0,
        width: '100%',
    },
    modalFooter: {
        '@media (min-height:640px)': {
            display: 'none'
        },
        // position: 'absolute',
        bottom: 0,
        width: '100%',
    },
}));

const getEmailValue = (value) => {
    if (value?.length > 0) {
        return value.split(' - ')[0]
    } else return ''
}
const getDateValue = (value) => {
    if (value?.includes(' - ')) {
        return returnJSON(value.split(' - ')[1])
    } else return ''
}


const FormatSMailComponent = (props) => {
    // devLog('SMailComponent got props', props)
    const classes = useStyles()
    let initialValue = isDefined(props.field?.options?.setup?.defaultValue) ? props.field?.options?.setup?.defaultValue : ''
    let sendValue = null
    if (isDefined(props.field.value) && props.field.value !== '') {
        initialValue = getEmailValue(props.field.value)
        let sendValue = getDateValue(props.field.value)
    }

    const { t } = useTranslation('common')
    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [email, setEmail] = useState(initialValue);
    const [open, setOpen] = useState(false);
    const [pdfBlob, setPdfBlob] = useState(null)
    const [attachedPlugnote, setAttachedPlugnote] = useState(null);
    const template = props.field?.options?.template || ''

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled)
        if(typeof props.handleEditValue !== "undefined"){
            props.handleEditValue(initialValue, props.field, props.index);
        }
    },[ props.disabled]);

    useEffect(() => {
        setEmail(!!props.field.value ? getEmailValue(props.field.value) : initialValue);
    },[props.field.value])

    useEffect(() => {
        // console.log('pdfBlob', pdfBlob, typeof pdfBlob);
      if (isDefined(pdfBlob)) {
        pdfBlob.then(result => onLoadBlob(result))
      } else { setAttachedPlugnote(null) }
    }, [pdfBlob]);

    const handleEditValue = (value, field, index) => {
        setEmail(value);
        props.handleEditValue(value, field, index)
    }

    const handleOpenModalSmail = async () => {
        if(template !== "" && template === '9002'){
            generateEmailAttachmentsFromTemplate9002()
                .then((result) => {
                    // console.log('generate attachedPlugnoteFromTemplate9002 result', result);
                    onLoadBlob(result)
                });
        }
        else if(template !== "" && template === '2001'){
            await generateAttachedPlugnoteFromTemplate2001()
        }
        else{
            setAttachedPlugnote(null);
            setOpen(true);
        }
    }

    const onLoadBlob = (result) => {
        let reader = new FileReader();
        try {
            reader.readAsDataURL(result);
            reader.onloadend = function () {
                let base64data = reader.result;
                setAttachedPlugnote(base64data);
                setOpen(true);
            }
        } catch (e) {
            console.error(`❌ onLoadBlob ${e}`);
        }
    }

    const generateEmailAttachmentsFromTemplate9002 = async () => {
        return Data9002PrintableGetURL(props);
    }

    const generateAttachedPlugnoteFromTemplate2001 = async () => {
        setOpen(true)
    }

    const handleCloseModalSmail = () => {
        setOpen(false)
    }

    const handleRecordSendDate = (mail) => {
        let _sendValue = { email: mail, date: currentDateAndHours() }
        // props.handleEditValue(email + ' - ' + JSON.stringify(_sendValue), props.field, props.index)
    }

    return (
        <div className={classes.mailContainer}>
            <TextField
                onChange={(event) => handleEditValue(event.target.value, props.field, props.index)}
                id={'smail-input-' + props.field.id}
                type="text"
                helperText=""
                InputLabelProps={{ shrink: true }}
                className={`${classes.fieldRight} testeBorder`}
                value={getEmailValue(email)}
                disabled={disabled}
                InputProps={{ disableUnderline: true }}
            />
            {
                isDefined(email) && email !== '' && !props.mode?.includes('plugcode') &&
                <PlugTooltip title={t('common:annexe.send-email') + (sendValue?.email === email && sendValue?.date?.length > 0 ? ('\n' + t('smail.sent-date') + ' : ' + sendValue?.date) : '')} placement="top">
                    <div className={classes.mailButton} onClick={handleOpenModalSmail}>
                        <span className="material-icons md-small white">forward_to_inbox</span>
                    </div>
                </PlugTooltip>
            }

            {
                open &&
                <SmailModalComponent {...props}
                    mode={props.mode}
                    close={handleCloseModalSmail}
                    handleRecordSendDate={handleRecordSendDate}
                    open={open}
                    options={props.field.options}
                    plugnoteCopy={attachedPlugnote}
                    setPdfBlob={setPdfBlob}
                    value={email}
                    fields={props.fields}
                    ownerhash={typeof props.owner !== "undefined" ? props.owner : props.mainHash}
                />
            }
        </div>
    )
}

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        main : state?.user || null,
        user : state?.user?.user || null,
        profil : state?.user?.profil || null,
        user2 : state?.user || null,
        mainHash : state?.user?.profil?.hash || null
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptSendMailSimple: (payload, userhash,ownerhash) => dispatch(postSendMailV3Request(payload,userhash,ownerhash)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatSMailComponent);





