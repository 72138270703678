import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { BlobProvider, pdf } from '@react-pdf/renderer'
import { devLog, isDefined } from '../../../utils/utils'
import colors from '../../../assets/themes/Colors'
import { ColorCheckbox } from '../../custom/CustomComponents'
import { AttachmentTableDoc, SelectThumbnailLayoutComponent } from '../../printable/Data2001Printable'
import { handleMergeMultiplePDF } from '../../../utils/pdf'
import { fileIsImg } from '../../../utils/annexes'
import { getCurrentDate } from '../../../utils/date'
import { saveAs } from "file-saver"

const useStyles = makeStyles(() => ({
  mainCtn: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    fontSize: 24,
    color: colors.darkSilver,
    marginBottom: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  optionCtn: {
    cursor: 'pointer',
    marginBottom: 8,
    paddingLeft: 24,
  },
  printBtn: {
    padding: '4px 16px',
    borderRadius: 4,
    color: 'white',
    backgroundColor: colors.main,
    fontWeight: 'bold',
    cursor: 'pointer',
    width: 160,
    textAlign: 'center',
    display: 'inline-flex',
    alignItems: 'center',
    marginTop: -64,
  },
  pdfCtn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    height: 'calc(95vh - 140px)'
  },
}))

const ModalPrintAttachmentComponent = ({ annexes, columns, plugcode, plugnote }) => {
  // devLog('PrintAtt props', plugnote, plugcode)
  const classes = useStyles()
  const { t } = useTranslation('common')
  const [print, setPrint] = useState(false)
  const [hasLinks, setHasLinks] = useState(false)
  const [hasNote, setHasNote] = useState(false)
  const [hasThumbnails, setHasThumbnails] = useState(false)
  const [merge, setMerge] = useState(false)
  const [merging, setMerging] = useState(false)
  const [pdfUrl, setPdfUrl] = useState(null)
  const [pdfDocument, setPdfDocument] = useState(null)
  const [mergedPdfDoc, setMergedPdfDoc] = useState(null)
  const [layout, setLayout] = useState(100)

  const filename = '[' + plugnote.modclef + '] - ' + plugnote.userindex + ' - ' + getCurrentDate()

  const handleDownloadPdf = (e) => {
    e.preventDefault()
    e.stopPropagation()
    pdf(isDefined(mergedPdfDoc) ? mergedPdfDoc : pdfDocument)
      .toBlob()
      .then((blob) => saveAs(blob, filename.replaceAll('/', '-')))
    // window.open(mergedPdfDoc, "_blank")
  }

  const handleSelectLayout = (value) => { setLayout(value) }

  const handleClickPrint = () => {
    let mainPdf = (
      <AttachmentTableDoc
        annexes={annexes}
        attLayout={layout}
        field={{
          id: 0,
          format: 'tablemulti',
          options: {
            colonnes: columns
          },
          value: annexes,
        }}
        filename={filename}
        hasLinks={hasLinks}
        hasNote={hasNote}
        hasThumbnails={hasThumbnails}
        plugcode={plugcode}
        plugnote={plugnote}
        t={t}
      />
    )
    if (!merging) {
      setPdfDocument(mainPdf)
    }
    setPrint(true)
  }

  useEffect(() => {
    const handleMergeAll = async (pdfList) => {
      await handleMergeMultiplePDF(pdfUrl, pdfList)
        .then(res => {
          const bytes  = new Uint8Array( res )
          const blob   = new Blob( [ bytes ], { type: "application/pdf" } )
          const docUrl = URL.createObjectURL( blob )
          setMergedPdfDoc(docUrl)
          setMerging(false)
          setMerge(false)
        })
    }
    if (merging) {
      const pdfList = annexes.filter(att => att.type === 'pdf')
      if (isDefined(pdfUrl)) {
        handleMergeAll(pdfList)
      }
    }
  }, [merging, pdfUrl])

  if (print) {
    return (
      <BlobProvider
        document={pdfDocument}
        fileName={(plugcode === '' ? plugnote.label : plugcode) + '.pdf'}
        style={{ textDecoration: 'none', color: 'black', display: 'flex', height: '70vh', width: '100%', justifyContent: 'center', }}
      >
        {({ blob, url, loading, error }) => {
          if (isDefined(error)) { console.error(`❌ generate ${error}`) }
          if (merge) {
            if (isDefined(blob) && isDefined(url)) {
              setMerging(true)
              setPdfUrl(url)
            }
          }
          return (
            loading
              ? <div style={{ display: 'flex' }}>
                <br />
                <span className="material-icons md-25 "> hourglass_top </span>
                <div className={classes.pointer}>{t('common:global.print-sync')}</div>
              </div>
              : 
              <div className={classes.pdfCtn}>
                <div className={classes.printBtn} onClick={handleDownloadPdf} style={{ marginLeft: 'auto' }}>
                  <span className="material-icons md-25 "> picture_as_pdf </span>
                  {t('common:global.print')}
                </div>
                <br />
                <iframe title={filename} src={isDefined(mergedPdfDoc) ? mergedPdfDoc : (url + '#toolbar=1&FitV')}
                  className={classes.previewCtn} height="100%"
                  style={{
                    width: '100%',
                    // maxWidth: 'calc(80vw - 154px)'
                  }}
                />
              </div>
          )
        }}
      </BlobProvider>
    )
  } else {
    return (
      <div className={classes.mainCtn} >
        <span className={classes.header} >
          {t('print.print-options')}
          <div className={classes.printBtn} onClick={handleClickPrint}>
            <span className="material-icons md-25 "> picture_as_pdf </span>
            {t('print.generate-pdf')}
          </div>
        </span>
        <div className={classes.optionCtn} >
          {
            annexes.some(entry => fileIsImg(entry)) &&
            <div onClick={() => setHasThumbnails(current => !current)}>
              <ColorCheckbox checked={hasThumbnails} />
              <span >{t('print.fullpage-att')}</span>
            </div>
          }
          {
            hasThumbnails && 1 === 0 &&
            <div >
              <span >{t('print.select-layout')}</span>
              <SelectThumbnailLayoutComponent attLayout={layout} handleSelectLayout={handleSelectLayout} t={t} />
            </div>
          }
        </div>
          <div onClick={() => setHasLinks(current => !current)} className={classes.optionCtn} >
            <ColorCheckbox checked={hasLinks} />
            <span >{t('print.att-link')}</span>
          </div>
        {
          annexes.some(entry => entry[900] === 'pdf') &&
          <div onClick={() => setMerge(current => !current)} className={classes.optionCtn} >
            <ColorCheckbox checked={merge} />
            <span >{t('print.merge-pdf')}</span>
          </div>
        }
        {
          annexes.some(entry => entry[900] === 'note') &&
          <div onClick={() => setHasNote(current => !current)} className={classes.optionCtn} >
            <ColorCheckbox checked={hasNote} />
            <span >{t('print.note-content')}</span>
          </div>
        }
      </div>
    )
}
}

export default ModalPrintAttachmentComponent
