import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import logo from "../../assets/images/logos/plugnotes_logo_color.png";
import {useTranslation} from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
    deleteHistoryAction,
    getDemoHash,
    getLoadModel, getToken,
    removeDemoHash,
    setLanguage,
    setLoadModel
} from "../../utils/Common";
import {getDemoRequest, getloginRealUserRequest, postDemoRequest} from "../../actions/user";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import qs from "querystringify";
import {trackPromise} from "react-promise-tracker";
import axios from "axios";
import ApplicationConfig from "../../config/ApplicationConfig";

import { green } from '@material-ui/core/colors';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import {FormControl, Tooltip, withStyles} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    logoContainer:{
        display: 'inline-block',
        float: 'left',
    },
    logo:{
        width: '35%',
    },
    formContainer: {
        padding: '20px',
        border: 'solid',
        borderRadius: '10px',
        margin: '20px',
        borderColor: 'darkgray',
        boxShadow: '5px 5px 5px grey',
    },
    displayFlex:{
        display : 'flex',
        flexDirection : 'row'
    },
    marginLeftAuto:{
        marginLeft : 'auto'
    }
}));

const GreenCheckbox = withStyles({
    root: {
        color: green[400],
        '&$checked': {
            color: green[600],
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

function CheckBoxLists (props){
    const [state, setState] = React.useState({
        checked: false
    });

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
        props.handleChangeChoice(props.indice);
    };

    return(
        <Tooltip title={props.description} placement="top">
        <FormGroup row>
            <FormControlLabel
                control={<GreenCheckbox checked={state.checked} onChange={handleChange} name="checked" />}
                label={props.label}
            /></FormGroup>
        </Tooltip>
    )
}


function Demo(props) {


    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'login']);

    //const [demoHash, setDemoHash] = useState(getDemoHash() === null ?  null : getDemoHash());
    const [demoHash, setDemoHash] = useState(null);
    const [email, setEmail] = useState("");
    const [lists, setLists] = useState([]);
    const [referents, setReferents] = useState([]);
    const [choices, setChoices] = useState([]);
    const [referent, setReferent] = useState("");

    const [lg, setLg] = React.useState(qs.parse(props.location.search, { ignoreQueryPrefix: true })?.lg || 'fr');


    const handleCreateDemoAccount = () => {
        if(email !== ""){
            let payload = {
                email : email,
                hubspotLanguage : i18n.language.toUpperCase(),
                lists : choices,
                referent : referent
            }

            props.attemptCreateDemoAccount(payload);

            setEmail("");
            setReferent("");
            setChoices([]);
        }
    }

    const handleChangeChoice = (value) => {
        switch (choices.includes(value)) {
            case true:
                setChoices(choices.filter(item => item !== value));
                break;
            case false:
                setChoices([...choices, value]);
                break;
            default:
                break;
        }
    }

    const handleChangeReferent = (value) => {
        setReferent(value);
    }

    /*    const handleRettrieveDemoAccount = () => {
            if(demoHash !== null){
                props.attemptRetrieveDemoAccount(demoHash);
            }
        }*/

    const handleCheckValidEmail = () => {
        if(email !== "" && email.length > 0){
            //return email.match(new RegExp(/^.*@.*\..*$/)) != null;
            return email.match(new RegExp( /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) != null;
        }

        return false;

    }

    const fetchListScenari = () => {
        trackPromise(
            axios.get(ApplicationConfig.apiUrl + `demo/lists/scenaris.json`, {crossdomain: true,
                'headers': {
                    'X-AUTH-TOKEN': getToken(),
                }
            }).then((response) => {
                if(response.data.result && response.data.hasOwnProperty('data') ){
                    setLists(response.data.data);
                }else{
                    setLists([]);
                }
            }).catch((response) => {
                setLists([]);
            }))
    }

    const fetchListReferent = () => {
        trackPromise(
            axios.get(ApplicationConfig.apiUrl + `demo/lists/referents.json`, {crossdomain: true,
                'headers': {
                    'X-AUTH-TOKEN': getToken(),
                }
            }).then((response) => {
                if(response.data.result && response.data.hasOwnProperty('data') ){
                    setReferents(response.data.data);
                }else{
                    setReferents([]);
                }
            }).catch((response) => {
                setReferents([]);
            }))
    }

    const handleLogin = () => {
        props.history.push('/');
    };

    useEffect(() => {
        i18n.changeLanguage(lg.toLowerCase());
        setLanguage(lg.toLowerCase());
    }, [lg])

    useEffect(() => {

        fetchListScenari();
        fetchListReferent();

    }, [])

    return (
        <Grid>
            <div className="container_signin">
                <div className="logoSignin">
                    <img className="" src={logo} alt="Plugnotes" />
                </div>

                <div>

                    <div className="popup signin">
                        <div className="popup_wrapper flexSpacebetween">

                            <div className="popup_wrapper_content_login">
                                <div className="sous-header_content">
                                    <div>
                                        <span className="titres">
                                            {t('demo.title')}
                                        </span>
                                    </div>
                                </div>
                                <div className="sous-header_content lightgrey">
                                    <div className="line flex_style">
                                    </div>
                                </div>
                                <div className="popup_wrapper_content_login">
                                    <article>
                                        <div>
                                        <span className="-medium">
                                            {t('demo.email')}
                                        </span>
                                        </div>
                                        <Input
                                            id="standard-full-width"
                                            label="Label"
                                            type="email"
                                            onChange={(event) => setEmail(event.target.value)}
                                            style={{ margin: 8 }}
                                            placeholder={t('common:demo.mail')}
                                            helpertext="Full width!"
                                            fullWidth={true}
                                            margin="none"
                                            inputlabelprops={{
                                                shrink: true,
                                            }}
                                            className={classes.fieldRight}
                                            value={email}
                                        />
                                        <br/>
                                        <br/>
                                        <div>
                                        <span className="-medium">
                                            {t('demo.lists')}
                                        </span>
                                        </div>
                                        {
                                            lists.map((item, index) => {
                                                return (<CheckBoxLists key={index} {...props} label={item.label} description={item.descriptions} indice={item.userid} handleChangeChoice={handleChangeChoice} />)
                                            })
                                        }
                                        <br/>
                                        <div>
                                        <span className="-medium">
                                            {t('demo.referent')}
                                        </span>
                                        </div>
                                        <FormControl variant="filled" fullWidth={true}>
                                            <InputLabel id="demo-simple-select-helper-label">{t('demo.choose-referent')}</InputLabel>
                                            <Select
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={referent}
                                                disableUnderline={true}

                                                onChange={(event) => handleChangeReferent(event.target.value)}
                                            >
                                                {
                                                    referents.map((ref, index) => {
                                                        return (<MenuItem key={index} value={ref.email}>{ref.email}</MenuItem>)
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                        <br/>
                                        <br/>
                                        <br/>
                                        <button className={referent !== "" && referent.length > 0 && email !== "" && email.length > 0  && choices.length > 0  && handleCheckValidEmail() ? "button_signin" : "button_signin_grey"}
                                                disabled={referent !== "" && referent.length > 0 && email !== "" && email.length > 0 && !handleCheckValidEmail() &&  choices.length === 0}
                                                onClick={(event) => (referent !== "" && referent.length > 0 && email !== "" && email.length > 0 && handleCheckValidEmail()  && choices.length > 0 ) ?  handleCreateDemoAccount() : null }
                                        >{t('common:demo.create')}</button>
                                        <br/>
                                        <br/>
                                        <span onClick={handleLogin} className="cliquables grey margin-left-auto">{t('login.connection')}</span>
                                    </article>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </Grid>
    );
}

// redux providing state takeover
const mapStateToProps = (state) => {
    return {
        fetching : !!state.user ? state.user.fetching : false,
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptCreateDemoAccount: (payload) => dispatch(postDemoRequest(payload)),
    attemptRetrieveDemoAccount : (hash) => dispatch(getDemoRequest(hash))
});

export default connect(mapStateToProps, mapDispatchToProps)(Demo)
