import { styled, TextField, Tooltip } from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import { XGrid } from "@material-ui/x-grid";
import React, { useState } from "react";
import colors from "../../assets/themes/Colors";
import InputMask from 'react-input-mask';
import { devLog, isDefined } from "../../utils/utils";
import { InvisiblePDF, InvisiblePF, InvisiblePN } from "./icons/CustomIcons";
import ApplicationConfig from "../../config/ApplicationConfig";
import {KeyboardTimePicker} from "@material-ui/pickers";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((_) => ({
    durationInputContainer: {
        display: 'flex',
        width: '-webkit-fill-available',
        background: colors.greenLightest,
        marginBottom: 3,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottom: '1px solid black',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    input: {
        display: 'flex',
        flexGrow: 1,
        width: 'auto'
    },
    stylizedInput: {
        fontSize: 12,
        fontWeight: 'bold',
        width: '100%',
        paddingLeft: 3,
        paddingRight: 3,
    },
    fontStyle: {
        fontSize: 11,
        fontWeight: 'bold',
    },
    stylizedInputCustom: {
        fontSize: 11,
        fontWeight: 'normal',
        width: '100%',
        paddingLeft: 3,
        paddingRight: 3,
    },
    fontStyleCustom: {
        fontSize: 16,
        fontWeight: 'normal',
    },
    fontError: {
        fontSize: 12,
        color: colors.red,
        fontWeight: 'bold',
        paddingLeft: '5px!important',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    delButton: {
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.main,
        position: 'absolute',
        right: 0
    },
    indicatorCtn: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        gap: 10,
        height: 15,
        cursor: 'default',
        justifyContent: 'center',
    },
    indicator: {
        position: 'relative',
        fontSize: 8,
        width: 10,
        height: 10,
    },
    indicatorFalse: {
        fontWeight: "bolder",
        color: colors.bloodOrange,
        fontSize: 7,
        position: 'absolute',
        top: -2,
        left: 0,
        right: 0,
    },
    slashIcon: {
        fontSize: 8,
        color: colors.bloodOrange,
        position: 'absolute',
        top: 6,
        left: 0,
        right: 0,
    },
    columnSeparator: {
        borderLeft: '1px solid',
        height: 56,
        borderColor: 'inherit',
        padding: '0 6px',
        '&:hover': {
            borderLeft: '3px solid',
            borderColor: colors.mainButton,
        },
    },
    timePicker: {
        '& .MuiInputBase-root': {
            "& input": {
                display:'none',
            },
            "& .MuiInputAdornment-root": {
                //display: 'unset',
            }
        },
    },
    timePickerIcon: {
        "& .MuiIconButton-root": {
            padding: 0
        }
    },
}))

const StyledXGrid = styled(XGrid)((_) => ({
    '& .MuiDataGrid-root, .MuiDataGrid-row.Mui-selected': {
        backgroundColor: colors.selectedRow,
    },
    '& .MuiDataGrid-columnHeaderWrapper': {
        alignItems: 'stretch',
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-root .MuiDataGrid-cell': {
        // padding: '0px !important',
    },
    '& .MuiDataGrid-columnsContainer': {
        flexDirection: 'unset',
        backgroundColor: colors.plugnotesLightAlpha,
    },
    '& .MuiDataGrid-main': {
        // backgroundColor: colors.plugnotesLightAlpha,
    },
    //#region icons
    '& .MuiDataGrid-iconButtonContainer': {
        position: 'absolute',
        left: 0,
        top: 0,
    },
    '& .MuiDataGrid-columnHeader--alignCenter .MuiDataGrid-menuIcon': {
        position: 'absolute',
        top: 0,
        right: 0,
    },
    '& .MuiDataGrid-menuIcon': {
        visibility: 'visible !important',
        position: 'absolute',
        top: 0,
        right: 0,
    },
    //#endregion
    '& .MuiDataGrid-columnHeader': {
        padding: '0px !important',
        paddingLeft: 4,
        border: '0.5px solid ' + colors.mainAlpha,
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
        padding: '0px !important',
        flexDirection: 'row-reverse',
        justifyContent: 'center',
    },
    // ...other style rules
}));

export function DesignedGrid (props) {
    const classes = useStyles()
    return (
    <StyledXGrid {...props}
        components={{
            ...props.components,
            ColumnResizeIcon: () => <div className={classes.columnSeparator} />
        }}
    />
    )
}

export function DurationInput (props) {
    // devLog('Duration Input got props', props)
    let classes = useStyles();
    const { t } = useTranslation(['common']);
    const allowedInput = ['0','1','2','3','4','5','6','7','8','9','Backspace', null, ':']
    const [mask, setMask] = useState(props.mask)
    const [duration, setDuration] = useState(props.value)
    const firstInput = 0
    // const firstInput = props.value?.length > 0 ? props.value?.split(':')[0]?.length() : 0

    const handleChangeValue = (e) => {
        // devLog('change duration', e)
        if (!props.disabled) {
            const newValue = e.target.value
            setDuration(newValue)
            // if (!allowedInput.includes(e.nativeEvent.data)) { return }
            props.handleChangeTableValue(newValue)
        }
    }

    const handleDeleteValue = () => {
        props.handleChangeTableValue('')
        props.handleAcceptValue()
    }


    return (
        <div className={classes.durationInputContainer} >
            <InputMask
                disabled={props.disabled}
                maskChar="_"
                mask={mask}
                value={duration}
                onChange={handleChangeValue}
            // onBlur={}
            >
                {(inputProps) =>
                    <TextField
                        {...inputProps}
                        autoFocus={props.edit === 'cell'}
                        id={props.cindex + 'second'}
                        inputProps={{ style: { textAlign: 'center', flexGrow: 1 } }}
                        name={props.column.format + '-' + props.cindex}
                        placeholder={t('fields.placeholder-duration') + props.mask}
                        InputProps={{
                            classes: {
                                input: classes.fontStyle,
                                root: classes.input,
                            },
                            disableUnderline: true,
                            endAdornment: (
                                <React.Fragment>
                                    {
                                        ApplicationConfig.devMode &&
                                        <span style={{ display: "inline-flex", fontSize: 10 }}>1st: {firstInput}</span>
                                    }
                                    {
                                        props.value.length > 0 && !props.accept && !props.disabled &&
                                        <Tooltip title={t('common:multiple.validate')} placement="right">
                                            <span className={classes.fontError} onClick={props.handleAcceptValue} style={{ marginLeft: 23 }}>
                                                {t('common:multiple.invalid-value')}
                                                <span id="icon-add-playlist" className={`${classes.tableMultiButton} material-icons`}>
                                                    check
                                                </span>
                                            </span>
                                        </Tooltip>
                                    }
                                </React.Fragment>
                            ),
                        }}
                    />
                }
            </InputMask>
            {
                props.value.length > 0 && !props.disabled &&
                <Tooltip title={t('common:global.delete')} placement="right">
                    <span className={classes.deleteBtn} onClick={() => handleDeleteValue()}>
                        <span id="icon-add-playlist" className={`${classes.delButton} material-icons`}>
                            highlight_off
                        </span>
                    </span>
                </Tooltip>
            }
        </div>
    )
}

export const RestyledNumericInput = (props) => {
    // devLog('NumInput got props', props)
    let classes = useStyles()

    const handlePeriod = (e) => {
        let cursorA = e.target.selectionStart
        let cursorB = e.target.selectionEnd
        if(e.key === '.' || e.key === ',') {
            let _e = {...e}
            let text = props.value
            text = text.slice(0, cursorA) + '.' + text.slice(cursorB)
            _e.target.value = text
            props.onChange(_e)
            e.target.setSelectionRange(cursorA + 1, cursorA + 1)
        }
    }

    return(
        <TextField
            autoComplete="none"
            autoFocus={props.edit === "cell"}
            disabled={props.disabled}
            fullWidth={true}
            inputProps={{ style: {textAlign: 'center'} }}
            InputProps={{
                classes: {
                    input: classes.fontStyle,
                    root: classes.stylizedInput,
                },
            }}
            name={(props.col?.format || props.field.format) + '-' + props.cindex}
            onChange={props.onChange}
            onKeyDown={(e) => handlePeriod(e)}
            placeholder={props.col?.mask || props.field.mask}
            value={props.value}
        />)
}

export const RestyledNumericInputWithOptions = (props) => {
    let classes = useStyles()
    let pastedText = '';
    const decSeparator = props.field.options?.setup?.sepdecimal || ',';

    const handlePeriod = (e) => {
        let cursorA = e.target.selectionStart
        let cursorB = e.target.selectionEnd
        let text = props.value
        if ((e.key === '.' || e.key === ',')) {
            devLog('e.key', e.key)
            let _e = { ...e }
            devLog('cursor \n', text.slice(0, cursorA), decSeparator, text.slice(cursorB))
            _e.target.value = text.slice(0, cursorA).concat(decSeparator).concat(text.slice(cursorB))
            
            props.onChange(_e)
            e.target.setSelectionRange(cursorA + 1, cursorA + 1)
        }

        /// formatage valeur d'un nombre à décimales provenant de l'action coller 1939.49///
        if ((pastedText !== '' && (pastedText.indexOf(',') !== -1)) || pastedText.indexOf('.') !== -1) {
            e.preventDefault();
            let _e = { ...e }
            let newText = pastedText.replace('/([,.])/', decSeparator);
            // console.log('pastedText in handlePeriod :', pastedText, newText);
            _e.target.value = text.slice(0, cursorA) + newText + text.slice(cursorB)
            props.onChange(_e);
            pastedText = ''
        }
    }

    return (
        <TextField
            autoComplete="none"
            autoFocus={props.edit === "cell"}
            disabled={props.disabled}
            fullWidth={true}
            inputProps={{ style: { textAlign: 'left' } }}
            InputProps={{
                classes: {
                    input: classes.fontStyleCustom,
                    root: classes.stylizedInputCustom,
                },
                disableUnderline: true,
            }}
            name={props.col?.format + '-' + props.cindex}
            onPaste={(e) => {
                pastedText = e.clipboardData.getData('text')
                handlePeriod(e)
            }}
            onChange={props.onChange}
            // onKeyDown={handlePeriod}
            placeholder={props.col?.mask || props.field.mask}
            value={props.value}
        />
    )
}

export const TimeComponentWithOptions = ({value, mask, onChange, format, helperText, handleTimeChange, InputAdornmentProps, InputProps, minutesStep, views}) => {
    const classes = useStyles()
    const testMethod = () => {
        //alert('TEST')
    }
    return (
        // <div>TEST</div>
        <KeyboardTimePicker
            //id={maskProp === "__H__" ? "time-picker-custom-input-mask" : "time-picker"}
            className={`${classes.timePickerIcon + ` ` + classes.timePicker}`}
            ampm={false}
            autoComplete={"off"}
            disabled={false}
            format={format}
            //helperText={helperText}
            InputAdornmentProps={InputAdornmentProps}
            InputProps={InputProps}
            //KeyboardButtonProps={props.KeyboardButtonProps}
            keyboardIcon={<Icon>access_time</Icon>}
            mask={mask}
            minutesStep={minutesStep}
            //minutesStep={props.minutesStep}
            onChange={onChange}
            //placeholder={props.placeholder}
            value={value}
            views={views}
        />
    )
}

export const ColorCheckbox = (props) => {
    let color = props.color ? props.color : colors.selectedMain
    if (props.disabled) { color = colors.iconGrey }
    const size  = props.size? props.size : 20
    const checked = props.checked
    const propStyle = props.style || null
    if (checked) return (
        <span
            id="icon-more"
            className={`material-icons md ${props.disabled ? 'material-icons-disabled' : undefined}`}
            style={{ margin: 0, width: size, height: size, marginRight: '5px', color: color, fontSize: size, ...propStyle }}
        >
            check_box
        </span>
    )
    else return (
        <span
            id="icon-more"
            className={`material-icons md  ${props.disabled ? 'material-icons-disabled' : undefined}`}
            style={{ margin: 0, width: size, height: size, marginRight: '5px', color: color, fontSize: size, ...propStyle }}
        >
            check_box_outline_blank
        </span>
    )
}

export function PlugTooltip({ title = null, placement = "top", debug = false, size = 15, alignment = "left", ...rest }) {
    if (debug) { console.log('Plug TT props', rest) }
    const textStyle = {
        fontSize: size,
        whiteSpace: 'break-spaces',
        textAlign: alignment,
        display: 'flex',
    }

    return (
        <Tooltip
            {...rest}
            placement={placement}
            title={
                <span style={textStyle}>{title}</span>
            }
        />
    )
}

export function FieldVisibilityIndicator(props) {
    let field = props.field
    let mode = props.mode
    // console.log('FieldVisibility got props', props)
    const classes = useStyles();
    let printable = (field.showOnPdf === true || !isDefined(field.showOnPdf)) && (!isDefined(field.options?.setup?.printable) || field.options?.setup?.printable === true)
    let visible = field.visible === true || !isDefined(field.visible)
    let editable
    if (field.hasOwnProperty('editable') && !props.isTableColumn) { editable = field.editable === true || !isDefined(field.editable) }
    else if (field.hasOwnProperty('encodable')) { editable = field.encodable === true || !isDefined(field.encodable) }
    else { editable = true }
    let shown = !visible || !editable || !printable
    let infobulle = ''
    if (!visible) infobulle += "Not visible via PN-code";
    if (!editable) {
        if (infobulle.length > 0) {infobulle += '\n'}
        infobulle += "Not visible via PF-code"
    }
    if (!printable) {
        if (infobulle.length > 0) {infobulle += '\n'}
        infobulle += "Not printed in PDF"
    }

    if ((mode === 'create' || mode === 'update' || mode === 'edit') && shown) return (
        <PlugTooltip title={infobulle} placement="bottom" size={13} >
            <span className={classes.indicatorCtn}>
                {
                    !editable &&
                    <InvisiblePF />
                }
                {
                    !visible &&
                    <InvisiblePN />
                }
                {
                    !printable &&
                    <InvisiblePDF />
                }
            </span>
        </PlugTooltip>
    );
    else return null
}



