import {call, fork, put, select, takeEvery} from 'redux-saga/effects';
import * as actions from '../actions/user';
import * as paramsActions from '../actions/params';
import * as appActions from '../actions/startup';
import * as api from '../api/loginApi';
import * as apiParams from '../api/paramsApi';
import * as apiUser from '../api/userApi';
import {history} from '../hooks/history'
import {
    logout, removeLogo,
    removeToken,
    setCurrentEnvironnement,
    setDemoHash,
    setLanguage,
    setLogo,
    setToken
} from '../utils/Common'
import {trackPromise} from "react-promise-tracker";
import {confirmAlert} from "react-confirm-alert";
import i18n from './../translations/i18n';
import _ from 'lodash'
import ApplicationConfig from "../config/ApplicationConfig";

const myGenericTrackedWrapper = (fn, args1, args2, args3) => trackPromise(fn(args1,args2, args3));


function forwardTo(location) {
    history.push(location);
}

function forwardToProps(location, valueProp) {

    history.push({
        pathname: location,
        state: { email: valueProp }
    });
}

// create a generator function
function* fetchUser(action) {
    yield put(appActions.resetAllData());

    try {
        const response = yield call(api.postLoginReal, action.username, action.password );
        if(response.data.result === true){
            let token = response.data.token;
            let logo = response.data?.logoBase64 || null;
            setToken(token);
            setLogo(logo);
            if(response.data._value.langue === 'fr'){
                setLanguage('fr');
            }else{
                setLanguage('en');
            }
            setCurrentEnvironnement(response.data.profil[response.data.profil.length -1]);

            if(response.data.auth === true){
                const authResponse = yield call(apiParams.getAuthConfig, response.data.userhash);
                try {
                    if(authResponse.data.result === true){
                        yield put(paramsActions.getParamsSuccess(authResponse.data.data));
                    }else{
                        yield put(paramsActions.getParamsFailure());
                    }
                }catch (e) {
                    yield put(paramsActions.getParamsError());
                }
            }

            yield put(actions.getloginRealUserSuccess(response.data));

            yield call(forwardTo, '/models');
        }else{
            confirmAlert({
                title: i18n.t('common:global.plugnotes'),
                message: i18n.t('common:login.wrong-login-password'),
                buttons: [
                    {
                        label: i18n.t('common:global.ok'),
                    }
                ]
            });
            yield put(actions.getloginRealUserFailure());
        }
    }catch(e) {
        yield put(actions.getloginRealUserError());
    }
}

function* fetchTokenUser(action) {

    const token = sessionStorage.getItem('token');

    if(action.userhash !== null && token !== null){
        try {
            const response = yield call(api.getCheckToken, action.userhash);
            if(response.data.result){
                yield put(actions.getCheckTokenSuccess(response.data));
            }else{
                yield call(history.push, '/');
                yield put(actions.getCheckTokenFailure(response.data.message));
            }
        }catch(e) {
            removeToken();
            removeLogo();
            yield put(actions.postLogoutSuccess());
            yield call(forwardTo, '/');
            yield put(appActions.resetAllData());
            logout();
        }
    }else{
        removeToken();
        removeLogo();
        yield put(actions.postLogoutSuccess());
        yield call(forwardTo, '/');
        yield put(appActions.resetAllData());
        logout();
    }
}

function* fetchLogoutUser(action){
    try {
        const response = yield call(api.postLogout, action.username);
        // Success ?
        if (response.ok && response.data && response.data.result === 'ok') {
            removeToken();
            removeLogo();
            yield put(actions.postLogoutSuccess());
            yield call(forwardTo, '/');
            yield put(appActions.resetAllData());
            logout();
            //yield put(actions.postLogoutSuccess());
        } else {
            removeToken();
            removeLogo();
            yield put(actions.postLogoutSuccess());
            yield call(forwardTo, '/');
            yield put(appActions.resetAllData());
            logout();
            //yield put(actions.postLogoutFailure());
        }
    }catch(e) {
        removeToken();
        removeLogo();
        yield put(actions.postLogoutSuccess());
        yield call(forwardTo, '/');
        yield put(appActions.resetAllData());
        logout();
        //yield put(actions.postLogoutError());
    }
}

function * fetchProfilsUser(action){
    try {
        const response = yield call(api.getProfils, action.userhash);
        // Success ?
        if (response.data.result === true) {
            yield put(actions.getProfilsSuccess(response.data.data));
        } else {
            yield put(actions.getProfilsFailure());
        }
    }catch(e) {
        yield put(actions.getProfilsError());
    }
}


export function* fetchForceLogoutUser(){
    removeToken();
    removeLogo();
    yield put(actions.postLogoutSuccess());
    yield call(forwardTo, '/');
    yield put(appActions.resetAllData());
    logout();
}


/**
 * Saga pour faire une inscription
 * @param api
 * @param action
 */
function* register(action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.register, action.payload);
        if(response.data && response.data.result === 'ok'){
            let email = action.payload.email;
            yield put(actions.postRegisterSuccess());

            if(ApplicationConfig.production){

                try {
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            "fields": [
                                {
                                    "objectTypeId": "0-1",
                                    "name": "email",
                                    "value": action.payload.email
                                },
                                {
                                    "objectTypeId": "0-1",
                                    "name": "firstname",
                                    "value": action.payload.firstname
                                },
                                {
                                    "objectTypeId": "0-1",
                                    "name": "lastname",
                                    "value": action.payload.lastname
                                },
                                {
                                    "objectTypeId": "0-1",
                                    "name": "company",
                                    "value": action.payload.company
                                },
                                {
                                    "objectTypeId": "0-1",
                                    "name": "language",
                                    "value": action.payload.hubspotLanguage
                                },
                                {
                                    "objectTypeId": "0-1",
                                    "name": "phone",
                                    "value": action.payload.mobile
                                }
                            ],
                            "legalConsentOptions": {
                                "consent": {
                                    "consentToProcess": true,
                                    "text": "I agree to allow Plugnotes Company to store and process my personal data.",
                                    "communications": [
                                        {
                                            "value": true,
                                            "subscriptionTypeId": 999,
                                            "text": "I agree to receive marketing communications from Plugnotes Company."
                                        }
                                    ]
                                }
                            }
                        })
                    };
                    yield call(fetch("https://api.hsforms.com/submissions/v3/integration/submit/19919009/0c21b5c9-48e7-4311-842b-158a7531db4e", requestOptions));
                    confirmAlert({
                        title: i18n.t('common:global.plugnotes'),
                        message: i18n.t('common:login.register-success'),
                        buttons: [
                            {
                                label: i18n.t('common:global.ok'),
                                onClick: () => forwardToProps('/confirm-password', email)
                            },
                        ]
                    });

                }catch (e) {
                    confirmAlert({
                        title: i18n.t('common:global.plugnotes'),
                        message: i18n.t('common:login.register-success'),
                        buttons: [
                            {
                                label: i18n.t('common:global.ok'),
                                onClick: () => forwardToProps('/confirm-password', email)
                            },
                        ]
                    });
                }
            }
            else{
                confirmAlert({
                    title: i18n.t('common:global.plugnotes'),
                    message: i18n.t('common:login.register-success'),
                    buttons: [
                        {
                            label: i18n.t('common:global.ok'),
                            onClick: () => forwardToProps('/confirm-password', email)
                        },
                    ]
                });
            }
        }else{
            yield put(actions.postRegisterFailure());
        }
    }catch(e) {
        yield put(actions.postRegisterError());
    }
}

/**
 * Saga pour faire une confirmation d'inscription
 * @param api
 * @param action
 */
function* newPasswordConfirmation(action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.resetNewPassword, action.payload);
        if(response.data && response.data.result === 'ok'){
            yield put(actions.postNewPasswordSuccess());
            confirmAlert({
                title: i18n.t('common:global.plugnotes'),
                message: i18n.t('common:login.confirm-change-password-success'),
                buttons: [
                    {
                        label: i18n.t('common:global.ok'),
                        onClick: () => forwardTo('/')
                    },
                ]
            });
        }else{
            yield put(actions.postNewPasswordFailure());
        }
    }catch(e) {
        yield put(actions.postNewPasswordError());
    }
}

/**
 * Saga pour faire une confirmation d'inscription
 * @param api
 * @param action
 */
function* registerRetrivedConfirmation(action) {

//     // Affichage loader
//     yield call(NavigationActions.loader);
//
//     const response = yield call(api.postCodeRegister, action.confirmProfile);
//
// // Success Server ?
//     if (response.ok) {
//         // Success API ?
//         if (response.data && response.data.result === 'ok') {
//
//             yield put(LoginActions.registerRetrivedConfirmationSuccess());
//             // Fermeture loader
//             yield call(NavigationActions.pop);
//             yield call(NavigationActions.refresh,{code_sms: response.data.data.codesms, code_mail: response.data.data.codemail, displayConfirmCodeMailRetrieve : false});
//
//         } else {
//             yield put(LoginActions.loginFailure(response.data));
//             yield call(Alert.alert, I18n.t('confirm-register-failure'), I18n.t(response.data.message));
//
//             // Fermeture loader
//             yield call(NavigationActions.pop);
//         }
//
//     }
//     else {
//
//         yield put(LoginActions.loginFailure(response.data));
//         yield call(Alert.alert, I18n.t('confirm-register-failure'), response.data);
//
//         // Fermeture loader
//         yield call(NavigationActions.pop);
//     }

}

function* registerGetInfos(action) {

//     // Affichage loader
//     yield call(NavigationActions.loader);
//
//     const response = yield call(api.postCodeRegisterInfos, action.confirmProfile);
//
// // Success Server ?
//     if (response.ok) {
//         // Success API ?
//         if (response.data && response.data.result === 'ok') {
//
//             yield put(LoginActions.registerGetInfosSuccess());
//             // Fermeture loader
//             yield call(NavigationActions.pop);
//             yield call(NavigationActions.refresh,{code_sms: response.data.data.codesms, email: response.data.data_user.email, username : response.data.data_user.username, userhash: response.data.data_user.hash ,displayConfirmCodeMailRetrieve : true, displayRemoveSubscriptionButton : true});
//         } else {
//             yield put(LoginActions.loginFailure(response.data));
//             yield call(Alert.alert, I18n.t('confirm-register-failure'), I18n.t(response.data.message));
//
//             // Fermeture loader
//             yield call(NavigationActions.pop);
//         }
//
//     }
//     else {
//
//         yield put(LoginActions.loginFailure(response.data));
//         yield call(Alert.alert, I18n.t('confirm-register-failure'), response.data);
//
//         // Fermeture loader
//         yield call(NavigationActions.pop);
//     }

}

function* registerCancelSubscription(action){
    // // Affichage loader
    // yield call(NavigationActions.loader);
    //
    // const response = yield call(api.deleteUserSubscription, action.userhash);
    //
    // if (response.ok) {
    //     // Success API ?
    //     if (response.data && response.data.result === 'ok') {
    //
    //         yield put(LoginActions.registerCancelSubscriptionSuccess());
    //         // Fermeture loader
    //         yield call(NavigationActions.pop);
    //         yield call(NavigationActions.welcome,{type: "reset"});
    //         yield call(NavigationActions.register);
    //     } else {
    //         yield put(LoginActions.loginFailure(response.data));
    //         yield call(Alert.alert, I18n.t('confirm-register-failure'), I18n.t(response.data.message));
    //
    //         // Fermeture loader
    //         yield call(NavigationActions.pop);
    //     }
    //
    // }
    // else {
    //
    //     yield put(LoginActions.loginFailure(response.data));
    //     yield call(Alert.alert, I18n.t('confirm-register-failure'), response.data);
    //
    //     // Fermeture loader
    //     yield call(NavigationActions.pop);
    // }
}

/**
 * Saga pour la récupération du mot de passe
 * @param action
 */
function* forgottenPassword(action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.resetPassword, action.email);
        if(response.data && response.data.result){
            let email = response.data.email;
            yield put(actions.postLostPasswordSuccess());
            confirmAlert({
                title: i18n.t('common:global.plugnotes'),
                message: i18n.t('common:login.forgotten-success-message'),
                buttons: [
                    {
                        label: i18n.t('common:global.ok'),
                        onClick: () => forwardToProps('/confirm-password', email)
                    },
                ]
            });
        }else{
            yield put(actions.postLostPasswordFailure());
        }
    }catch(e) {
        yield put(actions.postLostPasswordError());
    }
}


export function * getUser(action) {

    try {
        const uhm = yield select((state) => state.user.profil.hash);

        const response = yield call(myGenericTrackedWrapper,api.getUser, action.userhash, uhm );
        if(response.statusText === 'OK'){
            yield put(actions.getUserSuccess(response.data));
        }else{
            yield put(actions.getUserFailure())
        }
    }catch(e) {
        yield put(actions.getUserError());
    }

}

export function * putUser(action) {

    try {
        const uhm = yield select((state) => state.user.profil.hash);

        const response = yield call(myGenericTrackedWrapper,api.updateUser,  action.payload, uhm );
        if(response.data.result === "ok" && response.data.result === 'ok'){
            yield put(actions.putUserSuccess(response.data.data));
        }else{
            yield put(actions.putUserFailure())
        }
    }catch(e) {
        yield put(actions.putUserError());
    }
}

/**
 * Création d'un environnement
 * @param api
 * @param action
 */
export function * postCreateEnvironment (action) {

    try {
        const response = yield call(myGenericTrackedWrapper,api.postCreateEnvironment, action.payload, action.userhash);
        if (response.statusText === 'OK') {
            if(response.data.result === 'nol'){
                yield put(actions.postCreateEnvironmentFailure())
            }else{

                try {

                    const profil = yield select((state) => state.user.profil);
                    const getResponse = yield call(api.getEnvironments, profil.hash);

                    if(getResponse.statusText === 'OK' && typeof getResponse.data.profils !== "undefined"){
                        const profils = _.sortBy(getResponse.data.profils, "profilid");
                        yield put(actions.getEnvironmentsSuccess(getResponse.data.profils));
                        setCurrentEnvironnement(_.last(profils));
                    }else{
                        yield put(actions.getEnvironmentsFailure())
                    }
                }catch(e) {
                    yield put(actions.getEnvironmentsError());
                }

            }
        }else{
            yield put(actions.getEnvironmentsFailure())
        }

        if(response.data.result === "ok" && response.data.result === 'ok'){
            yield put(actions.putUserSuccess(response.data.data));
        }else{
            yield put(actions.postCreateEnvironmentFailure())
        }
    }catch(e) {
        yield put(actions.postCreateEnvironmentError())
    }
}


export function * getUserQuota(action) {

    try {
        const uhm = yield select((state) => state.user.profil.hash);

        const response = yield call(myGenericTrackedWrapper,api.getUserQuota, action.userhash, uhm );
        if(response.statusText === 'OK' && response.data.result === 'ok'){
            yield put(actions.getUserQuotaSuccess(response.data));
        }else{
            yield put(actions.getUserQuotaFailure())
        }
    }catch(e) {
        yield put(actions.getUserQuotaError());
    }
}

/**
 * Récupération de tous les environnements + le profil comme environnement
 * @param api
 * @param action
 * @param login
 */
export function * getEnvironments (action) {

    try {

        const response = yield call(myGenericTrackedWrapper,api.getEnvironments, action.userhash );
        if(response.statusText === 'OK' && typeof response.data.profils !== "undefined"){
            const profils = _.sortBy(response.data.profils, "profilid");
            yield put(actions.getEnvironmentsSuccess(response.data.profils));
            let listToAccept = [];

            let nombre_plugforms = 0;
            let nombre_plugnotes = 0;
            let nombre_annexes = 0;
            let nombre_univers = 0;
            let nombre_univers_partages = 0;
            let nombre_plugforms_shared = 0;
            let nombre_plugnotes_shared = 0;

            let environnement  = yield select((state) => state.login.profile);

            profils.forEach((profile) => {
                if (profile.listmodelssharedinwaiting && profile.listmodelssharedinwaiting.length > 0) {
                    listToAccept = listToAccept.concat(profile.listmodelssharedinwaiting);
                }

                if(environnement.type === 'user' || environnement.type === 'admin'){
                    if(profile.modelsshared !== 1){
                        nombre_plugforms += parseInt(profile.count_pf);
                        nombre_plugnotes += parseInt(profile.count_pn);
                        nombre_annexes += parseInt(profile.count_annexes);
                        nombre_plugforms_shared += parseInt(profile.count_pf_shared);
                        nombre_plugnotes_shared += parseInt(profile.count_pn_shared);
                        nombre_univers += 1;
                    }else if(profile.modelsshared === 1){
                        nombre_univers_partages += 1;
                    }

                    /*
                    Intercom.logEvent('statistiques_globales', {
                      nombre_univers: nombre_univers,
                      nombre_univers_partages: nombre_univers_partages,
                      nombre_plugforms: nombre_plugforms,
                      nombre_plugnotes: nombre_plugnotes,
                      nombre_annexes: nombre_annexes,
                      userId:environnement.id });
                     */

                    let name = !!environnement.lastname && environnement.lastname !== '' && !!environnement.firstname && environnement.firstname !== '' ? environnement.lastname+ '-' + environnement.firstname : environnement.pseudo
                    window.Intercom("update", {
                        app_id: 'm63djv8b',
                        email: environnement.email,
                        phone: environnement.mobile,
                        user_id: environnement.id,
                        name: name,
                        browser_language : i18n.language.substr(0, 2).toUpperCase(),
                        language_override : i18n.language.substr(0, 2).toUpperCase(),
                        horizontal_padding: 20,
                        vertical_padding: 20,
                        custom_launcher_selector: '#menu_open_intercom_button',
                        nombre_univers: nombre_univers,
                        nombre_univers_partages: nombre_univers_partages,
                        nombre_plugforms: nombre_plugforms,
                        nombre_plugnotes: nombre_plugnotes,
                        nombre_annexes: nombre_annexes,
                        nombre_plugforms_shared : nombre_plugforms_shared,
                        nombre_plugnotes_shared : nombre_plugnotes_shared,
                    })
                }
            })

        }else{
            yield put(actions.getEnvironmentsFailure())
        }
    }catch(e) {
        yield put(actions.getEnvironmentsError());
    }
}


/**
 * Création d'un environnement
 * @param api
 * @param action
 */
/**
 * Création d'un environnement
 * @param api
 * @param action
 */
export function* postCreateDemoEnvironment(action) {

    yield put(appActions.resetAllData());

    try {
        const response = yield call(myGenericTrackedWrapper, api.postCreateDemoAccount, action.payload);
        if (response.data.result === true) {
            if (ApplicationConfig.production) {
                try {
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            "fields": [
                                {
                                    "objectTypeId": "0-1",
                                    "name": "email",
                                    "value": action.payload.email
                                },
                                {
                                    "objectTypeId": "0-1",
                                    "name": "language",
                                    "value": action.payload.hubspotLanguage
                                }
                            ],
                            "legalConsentOptions": {
                                "consent": {
                                    "consentToProcess": true,
                                    "text": "I agree to allow Plugnotes Company to store and process my personal data.",
                                    "communications": [
                                        {
                                            "value": true,
                                            "subscriptionTypeId": 999,
                                            "text": "I agree to receive marketing communications from Plugnotes Company."
                                        }
                                    ]
                                }
                            }
                        })
                    };

                    yield call(fetch("https://api.hsforms.com/submissions/v3/integration/submit/19919009/d8a18051-d0ef-4e48-85ac-c3a70663b95c", requestOptions));
                    yield put(actions.postDemoSuccess());
                    confirmAlert({
                        title: i18n.t('common:global.plugnotes'),
                        message: i18n.t('common:demo.sucess'),
                        buttons: [
                            {
                                label: i18n.t('common:global.ok'),
                            }
                        ]
                    });
                    /*
                                        let token = response.data.token;
                                        let logo = response.data?.logoBase64 || null;
                                        setToken(token);
                                        setLogo(logo);

                                        setDemoHash(response.data.userhash)
                                        if (response.data._value.langue === 'fr') {
                                            setLanguage('fr');
                                        } else {
                                            setLanguage('en');
                                        }
                                        setCurrentEnvironnement(response.data.profil[response.data.profil.length - 1]);
                                        yield put(actions.postDemoSuccess())
                                        yield put(actions.getloginRealUserSuccess(response.data));
                                        //yield call(forwardTo, '/models');*/
                }catch (e) {
                    /*                    let token = response.data.token;
                                        let logo = response.data?.logoBase64 || null;
                                        setToken(token);
                                        setLogo(logo);
                                        setDemoHash(response.data.userhash)
                                        if (response.data._value.langue === 'fr') {
                                            setLanguage('fr');
                                        } else {
                                            setLanguage('en');
                                        }
                                        setCurrentEnvironnement(response.data.profil[response.data.profil.length - 1]);
                                        yield put(actions.postDemoSuccess())
                                        yield put(actions.getloginRealUserSuccess(response.data));
                                        yield call(forwardTo, '/models');*/
                    yield put(actions.postDemoSuccess());
                    confirmAlert({
                        title: i18n.t('common:global.plugnotes'),
                        message: i18n.t('common:demo.sucess'),
                        buttons: [
                            {
                                label: i18n.t('common:global.ok'),
                            }
                        ]
                    });
                }
            }
            else{
                /*                let token = response.data.token;
                                let logo = response.data?.logoBase64 || null;
                                setToken(token);
                                setLogo(logo);
                                setDemoHash(response.data.userhash)
                                if (response.data._value.langue === 'fr') {
                                    setLanguage('fr');
                                } else {
                                    setLanguage('en');
                                }
                                setCurrentEnvironnement(response.data.profil[response.data.profil.length - 1]);
                                yield put(actions.postDemoSuccess())
                                yield put(actions.getloginRealUserSuccess(response.data));
                                yield call(forwardTo, '/models');*/
                yield put(actions.postDemoSuccess());
                confirmAlert({
                    title: i18n.t('common:global.plugnotes'),
                    message: i18n.t('common:demo.sucess'),
                    buttons: [
                        {
                            label: i18n.t('common:global.ok'),
                        }
                    ]
                });
            }
        } else {
            yield put(actions.postDemoFailure());
            //yield put(actions.getloginRealUserFailure());
            confirmAlert({
                title: i18n.t('common:global.plugnotes'),
                message: i18n.t('common:demo.error'),
                buttons: [
                    {
                        label: i18n.t('common:global.ok'),
                    }
                ]
            });
        }
    } catch (e) {
        yield put(actions.postDemoError());
        //yield put(actions.getloginRealUserError());
        confirmAlert({
            title: i18n.t('common:global.plugnotes'),
            message: i18n.t('common:demo.error'),
            buttons: [
                {
                    label: i18n.t('common:global.ok'),
                }
            ]
        });
    }
}

export function * getCreatedDemoEnvironment (action) {

    yield put(appActions.resetAllData());

    try {
        const response = yield call(myGenericTrackedWrapper,api.getCreatedDemoAccount, action.hash );
        if(response.data.result === true){
            let token = response.data.token;
            let logo = response.data?.logoBase64 || null;
            setToken(token);
            setLogo(logo);
            setDemoHash(response.data.userhash)
            if(response.data._value.langue === 'fr'){
                setLanguage('fr');
            }else{
                setLanguage('en');
            }
            setCurrentEnvironnement(response.data.profil[response.data.profil.length -1]);
            yield put(actions.getDemoSuccess())
            yield put(actions.getloginRealUserSuccess(response.data));
            yield call(forwardTo, '/models');
        }else{
            yield put(actions.getDemoFailure());
            yield put(actions.getloginRealUserFailure());

            const promise = new Promise((resolve, reject) => {
                confirmAlert({
                    title: i18n.t('common:global.plugnotes'),
                    message: i18n.t('common:global.create-new-demo-environment'),
                    buttons: [
                        {
                            label: i18n.t('common:global.ok'),
                            onClick: () =>  resolve(true)
                        },
                        {
                            label: i18n.t('common:global.cancel'),
                            onClick: () => resolve(false)
                        },
                    ]
                });
            });

            const respUser = yield call(() => promise);

            if (respUser) {
                yield call(postCreateDemoEnvironment,action);
            }else{
                forwardToProps('/');
            }
        }
    }catch(e) {
        yield put(actions.getDemoError());
        yield put(actions.getloginRealUserError());
    }
}


export function * getUserConfig(action) {

    try {
        const uhm = yield select((state) => state.user.profil.hash);

        const response = yield call(myGenericTrackedWrapper,apiUser.getUserConfig, uhm );
        if(response.statusText === 'OK' && response.data.result === true){
            yield put(actions.getUserConfigSuccess(response.data.data));
        }else{
            yield put(actions.getUserConfigFailure())
        }
    }catch(e) {
        yield put(actions.getUserConfigError());
    }

}

export function * postUserConfig(action) {

    try {
        action.payload.uh = yield select((state) => state.user.profil.hash);
        const response = yield call(myGenericTrackedWrapper,apiUser.postUserOksignConfig,  action.payload );
        if(response.statusText === 'OK' && response.data.result === true){
            yield put(actions.postUserConfigSuccess());
        }else{
            yield put(actions.postUserConfigFailure())
        }
    }catch(e) {
        yield put(actions.postUserConfigError());
    }
}

export function * postUserEmailConfig(action) {

    try {
        action.payload.uh = yield select((state) => state.user.profil.hash);
        const response = yield call(myGenericTrackedWrapper,apiUser.postMailConfig,  action.payload );
        if(response.statusText === 'OK' && response.data.result === true){
            yield put(actions.postUserConfigSuccess());
        }else{
            yield put(actions.postUserConfigFailure())
        }
    }catch(e) {
        yield put(actions.postUserConfigError());
    }
}


function* watchFetchUser() {
    // create watcher of fetchData function
    yield takeEvery(actions.Types.GET_LOGIN_REAL_USER_REQUEST, fetchUser);
    yield takeEvery(actions.Types.GET_PROFILS_REQUEST, fetchProfilsUser);
    yield takeEvery(actions.Types.GET_CHECK_TOKEN_REQUEST, fetchTokenUser);
    yield takeEvery(actions.Types.POST_LOGOUT_REQUEST, fetchLogoutUser);
    //yield takeEvery(actions.Types.POST_LOGOUT_REQUEST, fetchForceLogoutUser);
    yield takeEvery(actions.Types.POST_LOST_PASSWORD_REQUEST, forgottenPassword);
    yield takeEvery(actions.Types.POST_NEW_PASSWORD_REQUEST, newPasswordConfirmation);
    yield takeEvery(actions.Types.POST_REGISTER_REQUEST, register);
    yield takeEvery(actions.Types.GET_USER_REQUEST, getUser);
    yield takeEvery(actions.Types.PUT_USER_REQUEST, putUser);
    yield takeEvery(actions.Types.GET_USER_QUOTA_REQUEST, getUserQuota);
    yield takeEvery(actions.Types.POST_CREATE_ENVIRONMENT_REQUEST, postCreateEnvironment);
    yield takeEvery(actions.Types.POST_DEMO_REQUEST, postCreateDemoEnvironment);
    yield takeEvery(actions.Types.GET_DEMO_REQUEST, getCreatedDemoEnvironment);
    yield takeEvery(actions.Types.GET_USER_CONFIG_REQUEST, getUserConfig);
    yield takeEvery(actions.Types.POST_USER_CONFIG_REQUEST, postUserConfig);
    yield takeEvery(actions.Types.POST_EMAIL_CONFIG_REQUEST, postUserEmailConfig);
}

const UserSagas = [
    fork(watchFetchUser),
];

export default UserSagas;
