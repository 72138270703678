import React, {useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {useTranslation} from "react-i18next";
import Input from "@material-ui/core/Input";
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormatTMExtDataConstructorComponent from "./FormatTMExtDataConstructorComponent";
import FormatTMFormulaConstructorComponent from "./FormatTMFormulaConstructorComponent";
import colors from "../../assets/themes/Colors";
import {ReactComponent as MultiSelect} from '../../assets/images/format-icon/svg/multi-select.svg'
import {ReactComponent as MultiSelectAdd} from '../../assets/images/format-icon/svg/free-multi-select.svg'
import {ReactComponent as SingleSelect} from '../../assets/images/format-icon/svg/single-select.svg'
import {ReactComponent as SingleSelectAdd} from '../../assets/images/format-icon/svg/free-single-select.svg'
import {ColorCheckbox, PlugTooltip} from "../custom/CustomComponents";
import ModalHelpComponent from "../modals/ModalHelpComponent";
import { devLog, isDefined } from "../../utils/utils";
import FormatTableMultipleConstructorComponent from "./FormatTableMultipleConstructorComponent";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    table: {
        minWidth: 650,
    },
    tableRoot: {
        marginRight : '20px',
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    tooltipStyle: {
        backgroundColor: colors.grey,
        color: colors.white,
        border: '1px solid',
        padding: '3px 8px',
        margin: 5,
        fontSize: '16px',
        borderRadius: 5
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    field : {
        width : '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    inputField:{
        padding : '0px 0px 7px',
    },
    mainField : {
        display: 'flex',
        flexDirection : 'column'
    },
    selectType:{
        //paddingRight: '24px',
        marginTop: '15px',
        marginLeft: '10px',
    },
    inputType:{
        marginTop: '30px',
        marginLeft: '0px',
        marginRight: '20px',
    },
    inputListType:{
        marginTop: '15px',
        marginLeft: '10px',
    },
    configCell: {
        height: 60,
    },
    nbColumn: {
        width: '30px !important',
        paddingLeft: 5,
        paddingRight: 5
    },
    preselConfig: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 5,
        paddingBottom: 5
    },
    configRow: {
        // verticalAlign: 'bottom',
        height: '60px !important',
    },
    openClose: {
        flex: '0 0 auto',
        color: 'rgba(0, 0, 0, 0.54)',
        cursor: 'pointer',
        overflow: 'visible',
        fontSize: '1.5rem',
        textAlign: 'center',
        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: '50%',
        scrollMarginTop: '30px',
    },
    headerCtn: {
        '& .MuiTableCell-root': {
          padding: '4px !important',
          textAlign: 'center',
          position: 'sticky',
        },
        backgroundColor: colors.palePlugGreen,
    },
    tableRow: {
        borderRadius: 4,
        color: colors.main,
        padding: '0px 5px',
        gap: 5,
        // display: 'flex',
        // alignItems: 'center'
    },
    rowNb: {
        fontSize: 14,
        marginRight: 4,
        border: '1px solid',
        padding: '0px 4px',
        borderRadius: '50%',
        verticalAlign: 'bottom',
    },
    rowContainer: {
        '& .MuiTableCell-root': {
            padding: '8px!important'
        },
    },
    selectedMask: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "6px",
        backgroundColor: colors.selectedAlpha,
        color: colors.white,
        padding: "3px 8px",
        minHeight: 24,
        gap: 5,
    },
    unselectedMask: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 24,
        borderRadius: "6px",
        backgroundColor: colors.unselectedAlpha,
        color: colors.white,
        padding: "3px 8px",
        gap: 5,
    },
    masksContainer: {
        width: 'inherit',
        minHeight: 24,
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        flexWrap: 'wrap',
        borderRadius: "6px",
        alignItems: 'flex-start',
        gap: 5,
    },
    subOpenClose: {
        display: 'flex',
        flexDirection: 'column',
        '&:hover': {
            borderRadius: 50,
            background: colors.frost
        }
    },
    fieldIcon: {
        // stroke: colors.white,
        fill: colors.white,
        color: colors.white,
        width: 20,
        height: 'auto',
        verticalAlign: 'sub',
        cursor: 'pointer',
    }
}));

const RenderInputLabelEdit = (props) => {
    const [newData, setNewData] = useState(props.row.label);

    const handleInputChange = (event) => {
        setNewData(event.target.value);
        props.handleUpdateLabel(props.index, event.target.value);
    }

    useEffect(() => {
        setNewData(props.row.label);
    }, [props.row]);

    return(
        <Input
            id="standard-full-width"
            label="Label"
            style={{ width: '100%' }}
            //placeholder={t('fields.format-choice-placeholder-'+ props.field.format.toLowerCase())}
            fullWidth={false}
            margin="none"
            inputlabelprops={{
                shrink: true,
            }}
            value={newData}
            //InputProps={{fieldkey: props.field.key}}
            className="text-line"
            onChange={handleInputChange}
        />
    )
}

const getTypeValue = (value) => {
    let multiselect;
    let editable;
    if(value === null || value === ''){
        return 'T01';
    } else
    {
        multiselect = value.multiselect
        editable = value.editable
        if(multiselect === false && editable === false){
            return 'T01';
        }
        else if(multiselect === false && editable === true){
            return 'T02';
        }
        else if(multiselect === true && editable === false){
            return 'T03';
        }
        else if(multiselect === true && editable === true){
            return 'T04';
        }
    }
}

const RenderMaskList = (props) => {
    const classes = useStyles()
    const [newData, setNewData] = useState(props.row.mask !== null ? props.row.mask : '');

    const [masks, setMasks] = useState(props.rows.findIndex((r => r.format === r.format)) !== -1 ? [props.rows[props.rows.findIndex((r => r.format === r.format))].mask] : [])

    useEffect(() => {
        setNewData(props.row.mask !== null ? props.row.mask : '');
        setMasks(props.rows[props.rows.findIndex((row => row.format === row.format))].mask);
    }, [props.row.format]);

    const  handleMaskChange = (value) => {
        let regex = null;
        let _value
        if(typeof value === 'string') _value = value;
        else _value = value.toString()
        setNewData(_value);
        switch (props.row.format) {
            case "duration":
            case "time":
            case "numeric":
            case "date":
            case "tel":
                let indexRow = props.rows.findIndex((row => row.mask.includes(_value)));
                if(indexRow !== -1){
                    let row = props.rows[indexRow];
                    let indexMask = row.mask.findIndex((mask => mask === _value));
                    regex = row.regex[indexMask];
                }
                break;
            default:
                regex = null;
                break;
        }
        props.handleUpdateMask(props.index, _value, regex);
    }

    if(masks.length === 0){
        return null;
    }

    return (
        <div className={classes.masksContainer} key="uniquevalue">
            {masks.map((row, index) => {
                return(
                    <div className={row === newData? classes.selectedMask : classes.unselectedMask} key={index + "-mask"} onClick={() => props.row.editable ? handleMaskChange(row) : null}>{row}</div>
                )
            })}
        </div>
    );
}

const RenderTypeList = (props) => {
    const { t, } = useTranslation(['common', 'models']);
    const [newData, setNewData] = useState(getTypeValue(props.row.mask));
    const selectTypes = ['T01', 'T02', 'T03', 'T04']
    const classes = useStyles()

    useEffect(() => {
        setNewData(getTypeValue(props.row.mask));
    }, [props.row]);

    const  handleTypeChange = (event) =>{
        setNewData(event.target.value.toString());
        props.handleUpdateType(props.index, event.target.value);
    }
    function rowRender (value)
    {
        let icon = ""
        let tooltip = ""
        let label = ""
        switch(value) {
            case 'T01':
                icon = <SingleSelect alt={t('fields.format-choice-'+ props.field.format.toLowerCase())} fill='white !important' className={classes.fieldIcon}/>
                tooltip = t("common:formats.preselection-type-unique-two")
                label = t("selects.single")
                break;
            case'T02':
                icon = <SingleSelectAdd alt={t('fields.format-choice-'+ props.field.format.toLowerCase())} fill='white !important' className={classes.fieldIcon}/>
                tooltip = t("common:formats.preselection-type-unique-and-free-two")
                label = t("selects.single-add")
                break;
            case'T03':
                icon = <MultiSelect alt={t('fields.format-choice-'+ props.field.format.toLowerCase())} fill='white !important' className={classes.fieldIcon}/>
                tooltip = t("common:formats.preselection-type-multiple-two")
                label = t("selects.multiple")
                break;
            case'T04':
                icon = <MultiSelectAdd alt={t('fields.format-choice-'+ props.field.format.toLowerCase())} fill='white !important' className={classes.fieldIcon}/>
                tooltip = t("common:formats.preselection-type-multiple-and-free-two")
                label = t("selects.multiple-add")
                break;
            default:
                break;
        }
        let result = {icon: icon, label: label, tooltip: tooltip}
        // console.log('result', result);
        return result
    }

    return (
        <div className={classes.masksContainer} key="uniquevalue">
            {selectTypes.map((row, index) => {
                return(
                    <PlugTooltip title={rowRender(row).tooltip} key={"presel-" + index}>
                        <div className={row === newData? classes.selectedMask : classes.unselectedMask} key={index + "-select"} onClick={() => handleTypeChange(row)}>
                            <div className={classes.fieldIcon} style={{width : '20px', fill: 'white !important'}}>
                                {rowRender(row).icon}
                            </div>
                            {rowRender(row).label}
                        </div>
                    </PlugTooltip>
                )
            })}
        </div>
        // <Select
        //     disabled={!props.row.editable}
        //     fullWidth
        //     displayEmpty
        //     labelId="demo-simple-select-label"
        //     id="demo-simple-select"
        //     value={newData || ""}
        //     onChange={(event) => {handleTypeChange(event)}}
        // >
        //     <MenuItem value={'T01'}>{t("common:formats.preselection-type-unique-two")}</MenuItem>
        //     <MenuItem value={'T02'}>{t("common:formats.preselection-type-unique-and-free-two")}</MenuItem>
        //     <MenuItem value={'T03'}>{t("common:formats.preselection-type-multiple-two")}</MenuItem>
        //     <MenuItem value={'T04'}>{t("common:formats.preselection-type-multiple-and-free-two")}</MenuItem>
        // </Select>
    );
}

const RenderFormatList = (props) => {

    let disabled = ['file-source', 'file-uri', 'file-refid'];

    let rows = [...props.rows];

    let dataFormat = rows.findIndex((row => row.format === props.row.format)).toString();

    const [newData, setNewData] = useState(dataFormat);
    const  handleFormatChange = (event) =>{
        setNewData(event.target.value.toString());
        props.handleUpdateFormat(props.index, event.target.value);
    }

    useEffect(() => {
        let dataFormat = rows.findIndex((row => row.format === props.row.format)).toString();
        setNewData(dataFormat);
    }, [props.row]);

    return(
        <Select
            disabled={!props.row.editable}
            fullWidth
            displayEmpty
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={newData}
            onChange={(event) => {handleFormatChange(event)}}
        >
            {rows.map((row, index) => (
                <MenuItem key={index} value={index} disabled={disabled.includes(row.format)}>{row.formatLabel}</MenuItem>
            ))}
        </Select>
    )
}

const RenderFormulaComponent = (props) => {

    // const classes = useStyles();
    // const { t, i18n } = useTranslation(['common', 'models']);

    return(
        <div>
            <FormatTMFormulaConstructorComponent options={props.row.configuration}/>
        </div>
    )
}

const RenderExtdataComponent = (props) => {
    // const classes = useStyles();
    // const { t, i18n } = useTranslation(['common', 'models']);
    // console.log('render props', props);
    const field = {
        label: props.row.label || '',
        key: props.field.key,
        index: props.index,
        format: 'table'
    }
    return <FormatTMExtDataConstructorComponent options={props.row.configuration} field={field}/>
}

const FormatDocumentConstructorComponent = (props) => {
    // devLog('DocumentConstructor got props', props)
    const classes = useStyles()
    const setup = props.field.options.setup
    const { t } = useTranslation('common')
    const [openModalHelp, setOpenModalHelp] = useState(false)
    const [importAnnexes, setImportAnnexes] = useState(props.field.options?.import_annexes || false)
    const [importFromFtp, setImportFromFtp] = useState(props.field.options?.import_from_ftp || false)
    const [source, setSource] = useState(Array.isArray(props.field.options?.mask) ? props.field.options?.mask : ['PLUGNOTE'])
    const masks = ['PLUGNOTE', 'URL', 'FTP']

    const handleToggleAutoImportAnnexes = () => {
        let model = [...props.model]
        model[props.index].options.setup = {
            ...model[props.index].options.setup,
            autoImportAttachment: isDefined(setup?.autoImportAttachment) ? !setup?.autoImportAttachment : true,
        }
        props.setModel(model)
    }

    const handleToggleImportPrevious = () => {
        let model = [...props.model]
        model[props.index].options.setup = {
            ...model[props.index].options.setup,
            excludePreviousAttachments: isDefined(setup?.excludePreviousAttachments) ? !setup?.excludePreviousAttachments : false,
        }
        props.setModel(model)
    }

    const handleToggleImportAnnexe = () => {
        let newVal = !importAnnexes
        setImportAnnexes(curr => !curr)
        props.field.options.import_annexes = newVal
        let model = [...props.model]
        model[props.index].options.import_annexes = newVal
        props.setModel(model)
    }

    const handleToggleEnableFromFTP = () => {
        let newVal = !importFromFtp
        setImportFromFtp(curr => !curr)
        props.field.options.import_from_ftp = newVal
        let model = [...props.model]
        model[props.index].options.import_from_ftp = newVal
        props.setModel(model)
    }

    let valueRows = [
        createData(t('document.docsource'), 'A32 - Source (Mandatory)', 'file-source', "", null, new Date().getTime() - 35, null, null, true, false),
        createData(t('document.docrefid'), 'A32 - Reference (Mandatory)', 'file-refid', "", null, new Date().getTime() - 25, null, null, true, false),
        createData(t('document.docstockage'), 'A32 - Access path (Mandatory)', 'file-uri', "", null, new Date().getTime() - 10, null, null, true, false),
    ]

    const [dataRows, setDataRows] = useState(typeof props.field.formatoptions.lines === "undefined" || props.field.formatoptions?.lines.length === 0 ? valueRows : props.field.formatoptions.lines);

    function createData(label, formatLabel, format, mask, presel,id,regex, configuration, visible,editable) {
        return { label, formatLabel, format, mask, presel, id, regex, configuration, visible, editable }
    }

    // const rows = [
    //     createData('', '', '', [], [], 0, [], null, true, true),
    //     createData('Taches', 'Présélection', 'presel', [{ multiselect: false, editable: false }, { multiselect: false, editable: true }, { multiselect: true, editable: false }, { multiselect: true, editable: true }], [], 1, [], null, null, true),
    //     createData('Suivi des dates', 'Date', 'date', ["DD/MM", "DD/MM/YY", "DD/MM/YYYY", "MM/YYYY"], null, 2, ['99/99', '99/99/99', '99/99/9999', '99/9999'], null, null, true),
    //     createData('Quantité', 'Num', 'numeric', ['XX', 'XX,XXXXXX', 'X.XXX', 'X.XXX,XXXXX'], null, 3, ['99', '99,999999', '9.999', '9.999,99999'], null, null, true),
    //     createData('Debut', 'Heure', 'time', ['HH:MM (24H)', 'HH:MM:SS', 'MM:SS (60min)', 'Free Text', 'HH H MM'], null, 4, ['99:99', '99:99:99', '99:99', '99', '99 H 99'], null, null, true),
    //     createData('Commentaires', 'Texte', 'text', ['10', '40', '256', '512'], null, 5, [], null, null, true),
    //     createData('Valider', 'Radio', 'radio', ['Y/N', 'Y/N/NA', 'Checkbox'], null, 6, [], null, null, true),
    //     createData('E mail', 'Mail', 'mail', ['@'], null, 7, [], null, null, true),
    //     createData('GSM', 'Téléphone', 'tel', ['xxxx/xxxxxxxx', '+xxxxxxxxxxxxxx'], null, 8, ['9999/99999999', '+99999999999999'], null, null, true),
    //     createData('Statut', 'Select', 'select', [], ['1-ouvert', '2-fermé'], 9, [], null, null, true),
    //     createData('Formula', 'Formula', 'formula', [], null, 10, null, { formula: "" }, null, true),
    //     createData('S01', 'S01', 'extdata', [], null, 11, null, { ref: "", query: "LIKE", columns: "", column: "", dest: "" }, null, true),
    //     createData(t('constructor.duration'), t('constructor.duration'), 'duration', ["HH:MM", "HH:MM:SS", "MM:SS"], null, 12, ['99:99', '99:99:99', '99:99'], null, true),
    //     createData('URL', 'Url', 'url', [], null, 13, [], null, null, true),
    //     createData(t('document.docsource'), 'A32 - Source (Mandatory)', 'file-source', "", null, 10000, null, null, true, false),
    //     createData(t('document.docrefid'), 'A32 - Reference (Mandatory)', 'file-refid', "", null, 10011, null, null, true, false),
    //     createData(t('document.docfilename'), 'A32 - Filename', 'file-name-text', ['512'], null, 10022, [], null, null, true),
    //     createData(t('document.doccomment'), 'A32 - Attachment comment', 'file-comment-text', ['512'], null, 10033, [], null, null, true),
    //     createData(t('document.doctype'), 'A32 - Format', 'file-type-text', ['4'], null, 10044, null, null, true, null),
    //     createData(t('document.docsize'), 'A32 - Size', 'file-size-num', ["XX,X"], null, 10055, null, null, true, null),
    //     createData(t('document.docdate'), 'A32 - Create date', 'file-create-date', ["Free Text"], null, 10066, null, true, null, true),
    //     createData(t('document.docstockage'), 'A32 - Access path (Mandatory)', 'file-uri', "", null, 10077, null, null, true, false),
    // ]

    // const handleUpdateLabel = (index, value) => {
    //     dataRows[index].label = value;
    //     setDataRows(dataRows);
    // }

    // const handleUpdateFormat = (index, value) => {

    //     console.log(rows[value]);
    //     dataRows[index].format = rows[value].format;
    //     dataRows[index].formatLabel = rows[value].formatLabel;
    //     dataRows[index].configuration = rows[value].configuration;
    //     dataRows[index].mask = rows[value].format === 'presel' ? {multiselect : false, editable : false} : null;
    //     dataRows[index].presel = rows[value].format === 'select' ? ['1-ouvert','2-fermé'] : [];
    //     if(rows[value].format !== 'formula' && rows[value].format !== 'numeric'){
    //         dataRows[index].recap = null;
    //     }
    //     if(rows[value].regex?.length > 0) {
    //         dataRows[index].mask = rows[value].mask[0]
    //         dataRows[index].regex = rows[value].regex[0]
    //     }

    //     setDataRows([...dataRows]);
    // }

    const handleUpdateMask = (index, value, regex) => {
        dataRows[index].mask = value
        dataRows[index].regex = regex
        let model = [...props.model]
        model[props.index].options.mask = value
        props.setModel(model)
        setDataRows(dataRows)
    }
    const handleToggleSource = entry => {
        setSource(current => {
          const newMask = current?.includes(entry) ? current.filter(value => value !== entry) : [...current, entry]
          handleUpdateMask(props.index, newMask, newMask)
          return newMask
        })
      }

    // const handleUpdateType = (index, value) => {
    //     let multiselect;
    //     let editable;

    //     switch (value) {
    //         case 'T01':
    //             multiselect = false;
    //             editable = false;
    //             break;
    //         case 'T02':
    //             multiselect = false;
    //             editable = true;
    //             break;
    //         case 'T03':
    //             multiselect = true;
    //             editable = false;
    //             break;
    //         case 'T04':
    //             multiselect = true;
    //             editable = true;
    //             break;
    //         default:
    //             break;
    //     }

    //     dataRows[index].mask = {multiselect : multiselect, editable : editable};
    //     setDataRows(dataRows);
    // }

    // const handleUpdatePresel = (index, value) => {
    //     dataRows[index].presel = value;
    //     setDataRows([...dataRows]);
    // }

    // const handleRemoveSpecificRow = (value) => {
    //     let items = dataRows.filter((row , index)=> index !== value);
    //     setDataRows(items);
    // };

    // const handleChangeVisibilitySpecificRow = (value, visibility) => {

    //     switch (dataRows[value].format) {
    //         case 'file-source':
    //         case 'file-refid':
    //         case 'file-uri':

    //             let rowID = dataRows[value].id;
    //             let datas = dataRows.map((row) => {
    //                 if(row.id === rowID && visibility === false){
    //                     row.visible = true;
    //                 }else if(row.id === rowID && visibility === true){
    //                     row.visible = false;
    //                 }
    //                 return row;
    //             });

    //             setDataRows(datas);

    //             break;

    //     }
    // };

    // const handleEnableRecapSpecificRow = (index, value) => {
    //     //setDataRows(items);
    //     dataRows[index].recap = value;
    //     setDataRows([...dataRows]);
    // };

    // const handleOpenHelp = () => {
    //     setOpenModalHelp(true);
    // }

    const handleModalHelpClose = () => {
        setOpenModalHelp(false);
    }

    useEffect(() => {
        let model = [...props.model]
        model[props.index].formatoptions.lines = dataRows
        model[props.index].options.lines = dataRows
        props.setModel(model)
    }, [dataRows])

    const DocumentTabComponent = (
      <div className={classes.tabCtn}>
        <div className={classes.subfield}>
          <TableContainer component={Paper} className={classes.tableCtn} style={{ width: '100%' }}>
            <Table className={classes.table}>
              <TableHead className={classes.tableHeader}>
                <TableRow className={classes.headerCtn}>
                  <TableCell colSpan={2}>
                    <div style={{ color: colors.labelGrey, fontWeight: 'bold', marginBottom: 12, marginLeft: 40 }}>{t('document.allowed-src')}</div>
                    <div style={{ marginBottom: 20, marginLeft: 20 }}>
                        {masks.map((entry) => (
                        <PlugTooltip title={t('document.src-' + entry)} >
                            <span key={entry} onClick={() => handleToggleSource(entry)} style={{ marginRight: 8, cursor: 'pointer' }}>
                                <ColorCheckbox checked={source?.includes(entry)} />
                                {entry}
                            </span>
                        </PlugTooltip>
                        ))}
                    </div>
                  </TableCell>
                </TableRow>
                {
                    source?.includes('PLUGNOTE') &&
                    <TableRow>
                        <TableCell align='left' className={classes.headerTxt} >
                            <div className={classes.inputMargin} style={{ display: 'inline-flex', cursor: 'pointer' }} onClick={handleToggleAutoImportAnnexes}>
                                <ColorCheckbox checked={setup?.autoImportAttachment === true} /> {t('document.import-auto')}
                                <PlugTooltip classes={{ tooltip: classes.tooltipStyle }} title={t('document.import-auto-info')} placement='bottom'>
                                    <span className='material-icons' style={{ fontSize: 20, color: colors.mainButton }}>
                                        info
                                    </span>
                                </PlugTooltip>
                            </div>
                        </TableCell>
                        {setup?.autoImportAttachment === true &&
                            <TableCell align='left' className={classes.headerTxt} >
                                <div className={classes.inputMargin} style={{ display: 'inline-flex', cursor: 'pointer' }} onClick={handleToggleImportPrevious}>
                                    <ColorCheckbox checked={setup?.excludePreviousAttachments === true} /> {t('document.import-auto-exclude')}
                                    <PlugTooltip classes={{ tooltip: classes.tooltipStyle }} title={t('document.import-exclude-info')} placement='bottom'>
                                        <span className='material-icons' style={{ fontSize: 20, color: colors.mainButton }}>
                                            info
                                        </span>
                                    </PlugTooltip>
                                </div>
                            </TableCell>
                        }
                    </TableRow>
                }
                <TableRow>
                  { !setup?.autoImportAttachment &&
                    <TableCell align='left' className={classes.headerTxt}>
                        <div className={classes.inputMargin} style={{ display: 'inline-flex', cursor: 'pointer' }} onClick={handleToggleImportAnnexe}>
                        <ColorCheckbox checked={importAnnexes} />
                        {t('common:document.import-annexes')}
                        <PlugTooltip classes={{ tooltip: classes.tooltipStyle }} title={t('common:document.info-import-annexes')} placement='bottom'>
                            <span className='material-icons' style={{ fontSize: 20, color: colors.mainButton }}>
                            info
                            </span>
                        </PlugTooltip>
                        </div>
                    </TableCell>
                  }
                  <TableCell align='left' className={classes.headerTxt} colSpan={1}>
                    {props.import_from_ftp && source.includes('FTP') && (
                      <div className={classes.subfield}>
                        <div className={classes.inputMargin} style={{ display: 'inline-flex', cursor: 'pointer' }} onClick={handleToggleEnableFromFTP}>
                          <ColorCheckbox checked={importFromFtp} />
                          {t('common:document.import-from-ftp')}
                          <PlugTooltip classes={{ tooltip: classes.tooltipStyle }} title={t('common:document.info-import-from-ftp')} placement='bottom'>
                            <span className='material-icons' style={{ fontSize: 20, color: colors.mainButton }}>
                              info
                            </span>
                          </PlugTooltip>
                        </div>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
        </div>
      </div>
    )

    return (
        <div className={classes.mainField}>
            <FormatTableMultipleConstructorComponent
                documentTab={DocumentTabComponent}
                field={props.field}
                folderName={props.folderName}
                handleEditLabel={props.handleEditLabel}
                index={props.index}
                onChangeHandlerDrop={props.onChangeHandlerDrop}
                plugnoteCount={props.plugnoteCount}
                setModel={props.setModel}
                setDataRows={setDataRows}
                model={props.model}
                update={props.update}
            />

            {/* <div className={classes.subfield}>
                <TableContainer component={Paper} className={classes.tableRoot}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" className={classes.nbColumn}>Col.</TableCell>
                                <TableCell align="center">Libellé</TableCell>
                                <TableCell align="center">Format</TableCell>
                                <TableCell align="center">Configuration</TableCell>
                                <TableCell align="right">
                                    <span id="icon-add-playlist" className="material-icons md-small" onClick={handleAddTableMulti}>
                                        add
                                    </span>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataRows.length > 0 ?
                                dataRows.map((row, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <TableRow >
                                                <TableCell align="center">{index + 1}</TableCell>
                                                <TableCell align="center">
                                                    <RenderInputLabelEdit {...props}  row={dataRows[index]} index={index} handleUpdateLabel={handleUpdateLabel}/>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <RenderFormatList {...props} row={dataRows[index]} index={index} rows={rows} handleUpdateFormat={handleUpdateFormat} createData={createData}/>
                                                </TableCell>
                                                <TableCell className={classes.configCell}>
                                                    {row.format === 'presel' ?
                                                        <div className={classes.preselConfig}>
                                                            <RenderTypeList {...props}  row={dataRows[index]} index={index}  rows={rows} handleUpdateType={handleUpdateType}/>
                                                            <IconButton aria-label="expand row" size="small" style={{alignItems: 'flex-start'}} onClick={() => {
                                                                if(openRow === index){
                                                                    setOpen(false);
                                                                    setOpenRow(null)
                                                                }
                                                                else{
                                                                    setOpen(true);
                                                                    setOpenRow(index)
                                                                }
                                                            }}>
                                                                {open && openRow === index ? <div className={classes.subOpenClose}><Settings/><KeyboardArrowUpIcon/></div> : <div className={classes.subOpenClose}><Settings/><KeyboardArrowDownIcon/></div>}
                                                                ({row.presel.length})
                                                            </IconButton>
                                                        </div>
                                                        :
                                                        <RenderMaskList {...props}  row={dataRows[index]} index={index}  rows={rows} handleUpdateMask={handleUpdateMask}/>
                                                    }
                                                    {(row.format === 'formula' || row.format === "extdata") &&
                                                        <IconButton
                                                            aria-label="expand row"

                                                            onClick={() => {
                                                                if(openRow === index){
                                                                    setOpen(false);
                                                                    setOpenRow(null)}
                                                                else {
                                                                    setOpen(true);
                                                                    setOpenRow(index)}}}
                                                            size="small"
                                                        >
                                                            {!open && openRow !== index ?
                                                                <div className={classes.openClose}><SettingsIcon/><ArrowDropDown/></div>
                                                                :
                                                                <div className={classes.openClose}><SettingsIcon/><ArrowDropUp/></div>}
                                                        </IconButton>
                                                    }
                                                </TableCell>
                                                <TableCell>
                                                    {
                                                        row.visible !== null &&
                                                        <span id="icon-add-playlist" className="material-icons md-small" onClick={(event) => handleChangeVisibilitySpecificRow(index,row.visible)}>
                                                            { !row.visible ? "visibility_off" : "visibility"}
                                                        </span>
                                                    }

                                                    {row.editable &&
                                                        <span id="icon-add-playlist" className="material-icons md-small" onClick={(event) => handleRemoveSpecificRow(index)}>
                                                        delete
                                                        </span>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                            {row.format === 'presel' &&
                                                <TableRow>
                                                    <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                                                        <Collapse in={open && openRow === index} timeout="auto" unmountOnExit>
                                                            <Box>
                                                                <RenderPreselList
                                                                    {...props}
                                                                    getTypeValue={getTypeValue}
                                                                    handleClose={() => {setOpen(false); setOpenRow(null)}}
                                                                    handleUpdatePresel={handleUpdatePresel}
                                                                    index={index}
                                                                    onChangeHandlerDrop={props.onChangeHandlerDrop}
                                                                    row={dataRows[index]}
                                                                    rows={rows}
                                                                />
                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                            {row.format === 'formula' &&
                                                <TableRow>
                                                    <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                                                        <Collapse in={open && openRow === index} timeout="auto" unmountOnExit>
                                                            <Box margin={1}>
                                                                <Typography variant="h6" gutterBottom component="div">
                                                                    Configuration
                                                                </Typography>
                                                                <section>
                                                                    <RenderFormulaComponent {...props} row={dataRows[index]} rows={rows} index={index}/>
                                                                </section>
                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                            {row.format === "extdata" &&
                                                <TableRow>
                                                    <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                                                        <Collapse in={open && openRow === index} timeout="auto" unmountOnExit>
                                                            <Box margin={1}>
                                                                <Typography variant="h6" gutterBottom component="div">
                                                                    Configuration
                                                                </Typography>
                                                                <section>
                                                                    <RenderExtdataComponent {...props} row={dataRows[index]} rows={rows} index={index}/>
                                                                </section>
                                                            </Box>
                                                        </Collapse>
                                                    </TableCell>
                                                </TableRow>
                                            }
                                        </React.Fragment>
                                    );
                                }): null}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div> */}
            {
                openModalHelp  ?
                    <ModalHelpComponent open={openModalHelp} close={handleModalHelpClose} from="table"/>
                    :
                    null
            }
        </div>
    )
}

// redux providing state takeover
const mapStateToProps = (state) => {
    // console.log(state);
    return {
        import_from_ftp : !!(state.user.user.auth && state.user.user.technical_password !== "" && state.user.user.technical_username !== "")
    }
}

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(FormatDocumentConstructorComponent);





