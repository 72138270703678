import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

// Icons
import { MdOutlineTableRows } from "react-icons/md"
import { TbColumns3 } from "react-icons/tb"
import colors from '../../../assets/themes/Colors'
import { isDefined } from '../../../utils/utils'
import { PlugTooltip } from '../CustomComponents'

const useStyles = makeStyles(() => ({
  main: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
    margin: '4px auto 0',
  },
  iconCtn: {
    cursor: 'pointer',
    borderBottom: '2px solid ' + colors.transparent,
  }
}))

const EditTableOrientationComponent = (props) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const orientation = props.orientation || 'vertical'

  return (
    <div className={classes.main} >
    <PlugTooltip title={t('tablemulti.orientation-vertical')} placement="left">
        <span onClick={() => props.handleChangeOrientation('vertical')} className={classes.iconCtn}
          style={orientation === 'vertical' ? { borderBottomColor: colors.main } : undefined}
        >
          <TbColumns3
            size={props.size || 24} 
            stroke={orientation === 'vertical' ? props.color || colors.main : colors.unselectedAlpha}
          />
        </span>
      </PlugTooltip>
      <PlugTooltip title={t('tablemulti.orientation-horizontal')} placement="right">
        <span onClick={() => props.handleChangeOrientation('horizontal')} className={classes.iconCtn}
          style={orientation === 'horizontal' ? { borderBottomColor: colors.main } : undefined}
        >
          <MdOutlineTableRows
            size={props.size || 24}
            color={orientation === 'horizontal' ? props.color || colors.main : colors.unselectedAlpha}
          />
        </span>
      </PlugTooltip>
    </div>
  );
}

export default EditTableOrientationComponent
