import React, {useEffect, useRef, useState} from "react"
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import colors from "../../../assets/themes/Colors";
import {useTranslation} from "react-i18next";
import {Translation} from "react-i18next";
import {toast} from "react-toastify";
import qs from "querystringify";
import {trackPromise} from "react-promise-tracker";
import axios from "axios";
import { api } from "../../../api/index";
import {connect} from "react-redux";
import ApplicationConfig from "../../../config/ApplicationConfig";
import { delTableView, getCurrentEnvironnement, getPlugnote, getToken } from "../../../utils/Common";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faFile,
    faFileArchive,
    faFileAudio,
    faFileCode,
    faFileExcel,
    faFileImage,
    faFilePdf,
    faFilePowerpoint,
    faFileVideo,
    faFileWord,
    faStickyNote
} from "@fortawesome/free-regular-svg-icons";
import {confirmAlert} from "react-confirm-alert";
import Immutable from 'seamless-immutable'
import _, {isString} from 'lodash'
import moment from 'moment'
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import Plugforms from "../../plugforms/Plugforms";
import ModalSavePlugcodeComponent from "../ModalSavePlugcodeComponent";
import ModalNoteComponent from "../ModalNoteComponent";
import {
    postCreatePFCodePlugnoteInternalRequest,
    postCreatePlugnoteInternalBase64Request
} from "../../../actions/external";
import {blobToBase64} from "../../../utils/utils";
import { renderAnnexeIconPreview } from "../../../utils/annexes";

function getModalStyle() {
    const top = 5;

    return {
        top: `${top}%`,
        margin:'auto',
        overflow: 'auto',
        //transform: `translate(-${top}%, -${left}%)`,
    };
}

// const modalStyles = {overlay: {zIndex: 10000}};
// const modalStylesClosed = {overlay: {zIndex: 0}};

const useStyles = makeStyles((theme) => ({
    popup: {
        position: 'absolute',
        padding: theme.spacing(2, 3, 3),
        backgroundColor: 'white',
        width: '80vw',
        border: '1px solid #DEDEDE',
        boxSizing: 'border-box',
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 6,
        maxHeight: '90vh',
        scrollbarWidth: 'none!important',
        "&::-webkit-scrollbar": {
            display: 'none'
        },
        overflow: 'hidden',
    },
    closeBtn: {
        position: "absolute",
        top: 5,
        right: 5,
    },
    modal: {
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    },
    modalHeader: {
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        position: 'sticky',
        top: -17,
        padding: 15,
        zIndex: 9999,
        width: '100%',
        margin: '5px 0px',
    },
    headerTitle:{
        textAlign: 'center',
        // transform: 'translateY(-50px)',
        '@media (max-width: 1299px)': {
            fontSize: '12px !important'
        }
    },
    titleDetails: {
        fontSize: 12
    },
    marginLeft:{
        marginLeft : '10px'
    },
    icon:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : 'black'
    },
    fieldImageSvg: {
        width: 25,
        height: 25,
    },
    fieldImage: {
        height: 10,
        width: 10
    },
    modalBody: {
        display: 'flex',
        alignItems: 'center',
        // transform: 'translateY(-50px)',
        '@media (max-width: 1024px)': {
            transform: 'translateY(-10px)',
        }
    },
    errorNote: {
        backgroundColor: colors.greyDark,
    },
    "&::-webkit-scrollbar": {
        display: 'none'
    }
}));

const ModalViewPlugcodeComponent = (props) => {
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = useState(getModalStyle);
    const [t, i18n] = useTranslation('common');
    const [response, setResponse] = useState(null)
    const [pnData, setPnData] = useState(null)
    const [pfModel, setPfModel] = useState(null)
    const [loading, setLoading] = useState(true)
    const [loaded, setLoaded] = useState(0);
    const [cancel, setCancel] = useState(false);

    const plugCode = props.code
    const plugtype = plugCode.startsWith("PF")? "plugform" : plugCode.startsWith("PN") ? "plugnote" : "private"

    const [modalTitle, setModalTitle] = useState(`Opening Plugcode ${plugCode}...`)
    const [openModal, setOpenModal] = useState(false);
    const [openNote, setOpenNote] = useState(false);

    const [fields, setFields] = useState([]);

    const [selectedFiles, setSelectedFiles] = useState(null);
    const [selectedNote, setSelectedNote] = useState(null);
    const [openClassify, setOpenClassify] = useState(false);

    const [dataHasChanged, setDataHasChanged] = useState(false);
    const [createDateWithAnnexe, setCreateDateWithAnnexe] = useState(false);

    const [plugnoteAnnexes, setPlugnotesAnnexes] = useState([]);
    const [isOpenModalAnnexe, setIsOpenModalAnnexe] = React.useState(false);
    const [selectedAnnexe, setSelectedAnnexe] = useState(null);
    const [isOpenModalListAnnexes, setIsOpenModalListAnnexes] = useState(false);
    const [openModalPreview, setOpenModalPreview] = useState(false);
    const [selectedPreviewAnnexe, setSelectedPreviewAnnexe] = useState(null);
    const [images, setImages] = useState([null]);
    const inputFile = useRef(null)

    const environment = getCurrentEnvironnement();

    //TODO model / modelForHash / dataForHash / plugnote
    const [model, setModel] = useState(null)
    const [modelForHash, setModelForHash] = useState(pnData !== null ? pnData.data : pfModel!== null ? pfModel.data : null)
    const [dataForHash, setDataForHash] = useState(pnData !== null ? pnData.data : null)
    const [plugnote, setPlugnote] = useState(pnData !== null ? pnData.data : null)

    const [nameModel, setNameModel] = useState(modelForHash !== null ?  modelForHash.label : '');
    const [hashOwnerModel, setHashOwnerModel] = useState(modelForHash !== null ?  modelForHash.mainOwner : '');


    // *** Plugform methods *** //

    const handleModalAnnexeClose = () => {
        setSelectedAnnexe(null);
        setIsOpenModalAnnexe(false);
    };

    const handleModalAnnexeOpen = (annexe) => {
        setSelectedAnnexe(annexe);
        setIsOpenModalAnnexe(true);
    };

    const handleSaveFictifComment = (annexe, comment) => {
        let annexes = plugnoteAnnexes;
        annexes.map((item, index) => {
            if(item.id === annexe.id){
                item.comment = comment
            }
            return item;
        });
        setPlugnotesAnnexes(annexes);
        setDataHasChanged(true);
    }

    const handleDeleteAnnexeSharedExternal = (annexe) => {
        let annexes = plugnoteAnnexes;
        let filteredAnnexes = annexes.filter((item, index) => {
            return item.id !== annexe.id;
        });
        filteredAnnexes.filter(Boolean);
        setPlugnotesAnnexes(filteredAnnexes);
        setDataHasChanged(true);
    };

    const handleDeleteAnnexe = (event, annexe, previousFile) => {
        event.preventDefault();
        event.stopPropagation();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className={classes.confirmContainer}>
                        <h1 className={classes.confirmTitle}>{t("global.confirm")}</h1>
                        <p className={classes.confirmText}>
                            {t("plugnote.confirm-delete-annexe")}
                        </p>
                        <div className={classes.confirmButtons}>
                            <button
                                className={`${classes.confirmButtonStyle} ${classes.confirmNegative}`}
                                onClick={onClose}
                            >
                                {t("global.cancel")}
                            </button>
                            <button
                                className={`${classes.confirmButtonStyle} ${classes.confirmAffirmative}`}
                                onClick={() => {
                                    //props.attemptDeleteAnnexe(annexe.hash, environment.userhash);
                                    handleDeleteAnnexeSharedExternal(annexe);
                                    if(typeof previousFile !== "undefined" && previousFile !== null){
                                        previousFile();
                                    }
                                    onClose();
                                }}
                            >
                                {t("global.yes")}
                            </button>
                        </div>
                    </div>
                );
            },
        });
    };

    /**
     * Quand l'utilisateur veut éditer un champ
     * @param value
     * @param field
     * @param index
     * @private
     */
    const handleEditValue = (value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields([...listFields]);
        setDataHasChanged(true);
    }

    const handleSaveNote = (title, content) => {
        setSelectedNote(null);
        let annexes = plugnoteAnnexes;
        let newId = typeof annexes[annexes.length - 1] === "undefined" ? 1 : annexes[annexes.length - 1].id + 1;
        annexes.push({
            id : newId,
            hash: undefined,
            type: 'note',
            name: title,
            title: title,
            comment: '',
            Webdoc: {
                hash:undefined,
                content : content
            },
        });
        setPlugnotesAnnexes(annexes);
        setDataHasChanged(true);
    };

    const handleUpdateNote = (title, content, annexehash, notehash) => {
        setSelectedNote(null);
        let annexes = plugnoteAnnexes;
        annexes.map((annexe, index) => {
            if (annexe.id === selectedNote.id && annexe.type === 'note') {
                annexe.name = title;
                annexe.title = title;
                annexe.Webdoc.content = content;
            }
        });
        setPlugnotesAnnexes(annexes);
        setDataHasChanged(true);
    }

    const handleEditPhoneValue = (value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields(listFields);
        setDataHasChanged(true);
    }

    const handleEditDateTime = (date,value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        listFields[index].visuelValue = date;
        setFields([...listFields]);
        setDataHasChanged(true);
    }

    const handleClickCancelModal = () => {
        setOpenModal(false);
    };

    const handleClickContinueModal = () => {
        setOpenModal(false);
    };

    const handleNoteOpen = (event,annexe) => {
        event.preventDefault();
        event.stopPropagation();
        if(typeof annexe !== "undefined"){
            setSelectedNote(annexe);
        }else {
            setSelectedNote(null);
        }
        setOpenNote(true);
    };

    const handleNoteClose = () => {
        setOpenNote(false);
    };

    const handleCloseClassify = () => {
        setOpenClassify(false);
    }

    const handleExitCreatePlugnote = () => {
        // props.history.push("/plugcodes");
        props.close()
    }
    const handleExitPlugnote = () => {
        // props.history.push("/plugcodes");
        props.close()
    }

    const checkData = (fields) => {
        return fields.some((field) =>{
            if(field.format !== null && typeof field.format !== "undefined"){
                if(!!field.mandatory && field.mandatory === 1){
                    if(field.format.includes('select')){
                        return field.value.length === 0;
                    }else if(field.format === 'list'){
                        return ((!field.value || field.value === '[]' || field.value.length === 0) && field.format != "indice");
                    }else if(field.format === 'boolean'){
                        return (field.value === "" );
                    }else {
                        return (!field.value && field.format != "indice");
                    }
                }
            }
        })
    }

    /**
     * Construction de la nouvelle fiche à partir des champs
     * @param fields
     */
    const buildNewAnnexe = (fields) => {
        let newData = {};
        // ! const modelForHash = props.plugform;
        fields.map((field) => {
            if (field.format === "list" || field.format === "multi-select" || field.format === "free-multi-select" || field.format === "single-select" || field.format === "free-single-select" || field.format === "multi-table" || field.format === "free-multi-table" || field.format === "single-table" || field.format === "free-single-table") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    newData["field" + field.id] = JSON.stringify(field.value);
                } else {
                    newData["field" + field.id] = JSON.stringify([field.value]);
                }
            }
            else if(field.format === "tablemulti") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if(field.format === "tablemultin") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = {"value" : "", "list" : []};
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if(field.format === "document") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = {"value" : "", "list" : []};
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else {
                newData["field" + field.id] = field.value;
            }

            if (field.format === "multi-select" || field.format === "free-multi-select" || field.format === "single-select" || field.format === "free-single-select") {
                newData["field" + field.id + "format"] = "select";
            } else if (field.format === "multi-table" || field.format === "free-multi-table" || field.format === "single-table" || field.format === "free-single-table") {
                newData["field" + field.id + "format"] = "table";
            } else {
                newData["field" + field.id + "format"] = field.format;
            }

            if (modelForHash && field.format === "indice") {
                let editable = JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.editable || false;
                if(!editable && field.value === "" || (editable && field.value === "")){
                    newData["field" + field.id] = (JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.racine || "") +  (JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.indice || 0);
                }
            }

            if(field.format && field.format === 'agenda' || field.format && field.format === 'birthday'){
                newData["field" + field.id + "options"] = field.event;
            }

            newData["field" + field.id + "label"] = field.title;
        });
        newData.Annexes = [];
        newData.lastversion_model = modelForHash ? modelForHash.version : null;
        newData.modid = modelForHash.id;
        newData.offline = modelForHash.offline;
        return newData;
    };

    /**
     * Construction de la nouvelle fiche à partir des champs
     * @param fields
     */
    const buildNewAnnexeBase64 = async (fields,annexes) => {
        let newData = {};

        fields.map((field) => {
            if (field.format === "list" || field.format === "multi-select" || field.format === "free-multi-select" || field.format === "single-select" || field.format === "free-single-select" || field.format === "multi-table" || field.format === "free-multi-table" || field.format === "single-table" || field.format === "free-single-table") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    newData["field" + field.id] = JSON.stringify(field.value);
                } else {
                    newData["field" + field.id] = JSON.stringify([field.value]);
                }
            }
            else if(field.format === "tablemulti") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if(field.format === "tablemultin") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = {"value" : "", "list" : []};
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else if(field.format === "document") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = {"value" : "", "list" : []};
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }
            else {
                newData["field" + field.id] = field.value;
            }

            if (field.format === "multi-select" || field.format === "free-multi-select" || field.format === "single-select" || field.format === "free-single-select") {
                newData["field" + field.id + "format"] = "select";
            } else if (field.format === "multi-table" || field.format === "free-multi-table" || field.format === "single-table" || field.format === "free-single-table") {
                newData["field" + field.id + "format"] = "table";
            } else {
                newData["field" + field.id + "format"] = field.format;
            }

            if (modelForHash && field.format === "indice") {
                let editable = JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.editable || false;
                if(!editable && field.value === "" || (editable && field.value === "")){
                    newData["field" + field.id] = (JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.racine || "") +  (JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.indice || 0);
                }
            }

            if(field.format && field.format === 'agenda' || field.format && field.format === 'birthday'){
                newData["field" + field.id + "options"] = field.event;
            }

            newData["field" + field.id + "label"] = field.title;
        });
        newData.Annexes = [];

        if (annexes.length > 0) {
            await Promise.all(
                annexes.map(async (annexe, index) => {
                    if(annexe.type !== 'note'){
                        let res = await blobToBase64(annexe.data);
                        annexe.base64 = res.substr(res.indexOf(',') + 1);
                        annexe.name = annexe.name +  '.' + annexe.type;
                    }
                    return annexe;
                }));
        }

        if (annexes.length > 0) {
            newData.Annexes = annexes
        }

        newData.lastversion_model = modelForHash ? modelForHash.version : null;
        newData.modid = modelForHash.id;
        newData.offline = modelForHash.offline;

        return newData;
    };

    /**
     * Construction de la mise à jour de la fiche à partir des champs
     * @param fields
     */
    const buildUpdateAnnexe = (fields) => {
        let newData = getPlugnote();
        //! const modelForHash = props.plugform;
        fields.map((field) => {
            if (field.format === "multi-select" || field.format === "free-multi-select"
                || field.format === "single-select" || field.format === "free-single-select") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    newData["field" + field.id] = JSON.stringify(field.value);
                } else {
                    newData["field" + field.id] = JSON.stringify([field.value]);
                }
            }   else         if (field.format === "multi-table" || field.format === "free-multi-table"
                || field.format === "single-tabletable" || field.format === "free-single-table") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    newData["field" + field.id] = JSON.stringify(field.value);
                } else {
                    newData["field" + field.id] = JSON.stringify([field.value]);
                }
            } else if (field.format === "plugform") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }else if (field.format === "qrcodeint") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            }else if (field.format === "intdatamaj") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = JSON.stringify({"value" : "", "dataUpdated" : ""});
                } else {
                    newData["field" + field.id] = JSON.stringify(field.value);
                }
            } else if (field.format === "extdata") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else {
                    newData["field" + field.id] = field.value;
                }
            }else if(field.format === "list") {
                if (field.value.length === 0) {
                    newData["field" + field.id] = "";
                } else if (Array.isArray(field.value)) {
                    let correctValue = [];
                    field.value.map((item, index) => {
                        correctValue.push({"text" : item})
                    });
                    newData["field" + field.id] = JSON.stringify(correctValue);
                } else {
                    let transformValue = [field.value];
                    let correctValue = [];
                    transformValue.map((item, index) => {
                        correctValue.push({"text" : item})
                    });
                    newData["field" + field.id] = correctValue;
                }
            } else {
                newData["field" + field.id] = field.value;
            }

            if (field.format === "multi-select" || field.format === "free-multi-select"
                || field.format === "single-select" || field.format === "free-single-select") {
                newData["field" + field.id + "format"] = "select";
            }else             if (field.format === "multi-table" || field.format === "free-multi-table"
                || field.format === "single-table" || field.format === "free-single-table") {
                newData["field" + field.id + "format"] = "table";
            } else {
                newData["field" + field.id + "format"] = field.format;
            }

            if (modelForHash && field.format === "indice") {
                let editable = JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.editable || false;
                if(!editable && field.value === "" || (editable && field.value === "")){
                    newData["field" + field.id] = (JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.racine || "") +  (JSON.parse(modelForHash["field" + field.id + "formatoptions"] || '{}')?.indice || 0);
                }
            }

            if(field.format && field.format === 'agenda' || field.format && field.format === 'birthday'){
                newData["field" + field.id + "options"] = field.event;
            }

            newData["field" + field.id + "label"] = field.title;
        });
        newData.Annexes = [];
        newData.lastversion_model = modelForHash ? modelForHash.version : null;
        newData.modid = modelForHash.id;
        newData.offline = modelForHash.offline;

        return newData;
    };

    const handleClickAlertWithCancel = () => {
        setCancel(true);
        setOpenModal(true);
    }

    const handleClickAlertWithoutCancel = () => {
        setCancel(false);
        setOpenModal(true);
    }

    const handleSaveCreatePlugnote = async () => {
        //props.history.push("/pluglist");
        delTableView()
        if (dataHasChanged) {
            if (checkData(fields)) {
                if (selectedFiles !== null) {
                    handleClickAlertWithCancel();
                } else {
                    handleClickAlertWithoutCancel();
                }
            } else {
                let dataForHash = await buildNewAnnexeBase64(fields, plugnoteAnnexes);

                let payload = {
                    dataForHash,
                    copy: false,
                    email : props.email,
                    hash: model.hash,
                    plugname: model.label,
                    plugkey: model.clef,
                    model : model,
                };

                props.close();
                props.attemptCreatePFCodePlugnoteBase64(payload);
            }
        } else {
            if (checkData(fields)) {
                if (selectedFiles !== null) {
                    handleClickAlertWithCancel();
                } else {
                    handleClickAlertWithoutCancel();
                }
            } else {
                toast.warn("Aucune donnée ajoutée")
            }
        }
    };

    const handleChangeRadio = (value, indexation,field,index,multiselect,mandatory) => {
        let listFields = fields;
        if(mandatory){
            if(listFields[index].value.includes(value)){
                listFields[index].value = listFields[index].value.filter(item => item !== value)
            }else {
                if (!multiselect) {
                    listFields[index].value = [];
                    listFields[index].value.push(value);
                } else {
                    listFields[index].value.push(value);
                }
            }
        }else{
            if(listFields[index].value.includes(value)){
                listFields[index].value = listFields[index].value.filter(item => item !== value)
            }else{
                if(!multiselect){
                    listFields[index].value = [];
                    listFields[index].value.push(value);
                }else{
                    listFields[index].value.push(value);
                }
            }
        }
        setFields([...listFields]);
        setDataHasChanged(true);
    }

    const includesInJson = (search , value) => {
        return search.findIndex((item, index) => {
            return item.text === value
        })
    }

    const handleChangeRadioTable = (value, indexation,field,index,multiselect,mandatory) => {
        let listFields = fields;
        if(mandatory){
            if(!multiselect){
                listFields[index].value = [];
                listFields[index].value.push({"text" : value, "value" : 1});
            }else{
                listFields[index].value.push({"text" : value, "value" : 1});
            }
        }else{
            if(includesInJson(listFields[index].value, value) !== -1){
                listFields[index].value = listFields[index].value.filter(item => item.text !== value)
            }else{
                if(!multiselect){
                    listFields[index].value = [];
                    listFields[index].value.push({"text" : value, "value" : 1});
                }else{
                    listFields[index].value.push({"text" : value, "value" : 1});
                }
            }
        }
        setFields(listFields);
        setDataHasChanged(true);
    }

    const handleChangeInputTable = (value, indexation,field,index,multiselect,mandatory) => {
        let listFields = fields;
        if(mandatory){
            if(!multiselect){
                listFields[index].value = [];
                listFields[index].value.push({"text" : value.text, "value" : value.value});
            }else{
                if(includesInJson(listFields[index].value, value.text) === -1){
                    listFields[index].value.push({"text" : value.text, "value" : value.value});
                }else{
                    listFields[index].value[includesInJson(listFields[index].value, value.text)].value =  value.value;
                }            }
        }
        else{
            if(!multiselect){
                listFields[index].value = [];
                listFields[index].value.push({"text" : value.text, "value" :  value.value});
            }else{
                if(includesInJson(listFields[index].value, value.text) === -1){
                    listFields[index].value.push({"text" : value.text, "value" : value.value});
                }else{
                    listFields[index].value[includesInJson(listFields[index].value, value.text)].value =  value.value;
                }
            }
        }
        setFields([...listFields]);
        setDataHasChanged(true);
    }
    const handleUpdateInputTable = (value, indexation,field,index,multiselect,mandatory) => {
        let listFields = fields;
        let tmpValue = listFields[index].value;
        listFields[index].value = tmpValue.filter(item => value.includes(item.text));
        setFields(listFields);
        setDataHasChanged(true);
    }

    const handleDeleteInputTable = (value, indexation, field, index, multiselect, mandatory) => {
        let listFields = fields;
        let tmpValue = listFields[index].value;
        listFields[index].value = tmpValue.filter((item) => {
            return !item.text.includes(value)
        });
        setFields([...listFields]);
        setDataHasChanged(true);
    };

    const handleChangeKeyword = (value, indexation,field,index) => {
        let listFields = fields;
        if(listFields[index].value.includes(value)){
            listFields[index].value = listFields[index].value.filter(item => item !== value)
        }else{
            listFields[index].value.push(value);
        }
        setFields(listFields);
        setDataHasChanged(true);
    }

    const handleChangeBoolean  = (value,field,index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields(listFields);
        setDataHasChanged(true);
    }

    const handleChangePlugcode = (plugcode, comment, field, index) => {
        let listFields = fields;
        listFields[index].value = plugcode + ';' + comment;
        setFields(listFields);
        setDataHasChanged(true);
    }

    const handleChangePlugcodeInternal = (plugcode, comment, field, index, destinations) => {
        let listFields = [...fields];

        listFields[index].value = plugcode;

        if (destinations?.length > 0) {
            let updatedListFields =  listFields.map((field, index) => {
                if(!!field.id){
                    if(typeof destinations.find((elm) => Number(field.id) === Number(elm.dest)) !== "undefined"){
                        let destination = destinations.find((elm) => Number(field.id) === Number(elm.dest));
                        switch (field.format) {
                            case 'numeric':
                                let tmpValue = destination.value.replaceAll(",", ".");
                                return {...field, value : tmpValue, visuelValue :  tmpValue}
                            default:
                                return {...field, value : destination.value, visuelValue :  destination.value}
                        }
                    }
                }
                return field;
            });

            setFields(updatedListFields);
            setDataHasChanged(true);
        }else{
            setFields(listFields);
            setDataHasChanged(true);
        }
    }

    const handleChangePassword = (value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields(listFields);
        setDataHasChanged(true);
    };

    const handleChangeLocalisation = (value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields([...listFields]);
        setDataHasChanged(true);
    };

    //.fetch('GET', baseURL + userHash + "/" + action.dataHash + "/" + encodeURIComponent(action.annexe.name) + "." + action.annexe.type)
    const downloadEmployeeData = (event,annexe) => {
        event.preventDefault();
        event.stopPropagation();
        // Fetch the dynamically generated excel document from the server.
        trackPromise(
            axios.get(ApplicationConfig.apiUrl + 'annexe.json?annexehash=' + annexe.hash, {crossdomain: true,
                'headers': {
                    'X-AUTH-TOKEN': getToken(),
                }
            }).then((response) => {
                if(response.data.result){
                    const fileNameHeader = "x-suggested-filename";
                    const suggestedFileName = response.headers[fileNameHeader];
                    const effectiveFileName = (suggestedFileName === undefined
                        ? annexe.name + '.' + annexe.type
                        : suggestedFileName);
                    var a = document.createElement("a"); //Create <a>
                    a.href = response.data.base64; //Image Base64 Goes here
                    a.download = effectiveFileName; //File name Here
                    a.click(); //Downloaded file

                }
            }).catch((response) => {
                console.error("Could not Download the Excel report from the backend.", response);
            })
        )

    };

    const getUrlPreviewAnnexe = (annexe) => {
        return ApplicationConfig.downloadUrl + environment.userhash + "/" + pnData.data.hash + "/" + encodeURIComponent(annexe.name) + "." + annexe.type
    };

    const renderAnnexePreview = (annexeToPreview) => {
        switch (annexeToPreview.type.toLowerCase()) {
            case 'jpg':
            case 'JPG':
            case 'jpeg':
            case 'png':
            case 'PNG':
            case 'gif':
                // return <img src={getUrlPreviewAnnexe(annexeToPreview)} alt={annexeToPreview.name} />;
                return <FontAwesomeIcon icon={faFile} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'mp4':
            case 'mkv':
            case 'avi':
            case 'mov':
                return <FontAwesomeIcon icon={faFileVideo} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'pdf':
                return <FontAwesomeIcon icon={faFilePdf} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'zip':
            case 'rar':
                return <FontAwesomeIcon icon={faFileArchive} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'mp3':
            case 'aac':
                return <FontAwesomeIcon icon={faFileAudio} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'html':
                return <FontAwesomeIcon icon={faFileCode} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'txt':
            case 'sheet':
            case 'doc':
            case 'docx':
                return <FontAwesomeIcon icon={faFileWord} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'xls':
            case 'xlsx':
            case 'csv':
                return <FontAwesomeIcon icon={faFileExcel} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'note':
                return <FontAwesomeIcon icon={faStickyNote} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'pages':
                return <FontAwesomeIcon icon={faFileWord} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            case 'pptx':
            case 'ppt':
                return <FontAwesomeIcon icon={faFilePowerpoint} size="lg" style={{fontSize : '50', marginRight : '10'}}/>;
                break;

            default:
                break;
        }
    };

    const checkMimeType=(event)=>{
        //getting file object
        let files = event.target.files
        //define message container
        let err = []
        // list allow mime type
        // const types = ['image/png', 'image/jpeg', 'image/gif']
        // // loop access array
        // for(var x = 0; x<files.length; x++) {
        //     // compare file type find doesn't matach
        //     if (types.every(type => files[x].type !== type)) {
        //         // create error message and assign to container
        //         err[x] = files[x].type+' is not a supported format\n';
        //     }
        // };
        for(var z = 0; z<err.length; z++) {// if message not same old that mean has error
            // discard selected file
            toast.error(err[z])
            event.target.value = null
        }
        return true;
    };

    const maxSelectFile=(event)=>{
        let files = event.target.files
        if (files.length > 1) {
            const msg = 'Only 1 images can be uploaded at a time'
            event.target.value = null
            toast.warn(msg)
            return false;
        }
        return true;
    };

    const checkFileSize=(event)=>{
        let files = event.target.files
        let size = 1000000000
        let err = [];
        for(var x = 0; x<files.length; x++) {
            if (files[x].size > size) {
                err[x] = files[x].type+'is too large, please pick a smaller file\n';
            }
        };
        for(var z = 0; z<err.length; z++) {// if message not same old that mean has error
            // discard selected file
            toast.error(err[z])
            event.target.value = null
        }
        return true;
    };

    const handleCreateNote = (event) => {
        handleNoteOpen(event);
    }
    const handleSavePlugform = () => {
        //sauvegarde du formulaire dans la gallerie
        setOpenClassify(true);
    }

    const handleChangePlugform = (value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields(listFields);
        setDataHasChanged(true);
    }

    const handleChangeIntDataMaj = (value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields(listFields);
        setDataHasChanged(true);
    };

    const handleChangeExtData = async (destinations) => {
        let listFields = [...fields];
        if (destinations.length > 0) {
            let updatedListFields =  listFields.map((field, index) => {
                if(!!field.id){
                    if(typeof destinations.find((elm) => Number(field.id) === Number(elm.dest)) !== "undefined"){
                        let destination = destinations.find((elm) => Number(field.id) === Number(elm.dest));
                        return {...field, value : destination.value, visuelValue :  destination.value}
                    }
                }
                return field;
            });
            setFields(updatedListFields);
            setDataHasChanged(true);
        }
    }

    useEffect(() => {
        if (createDateWithAnnexe) {
            if(selectedFiles.length > 0){
                onClickHandler();
                setCreateDateWithAnnexe(false);
            }
        }
    }, [createDateWithAnnexe]);

    const onClickHandler = () => {
        const data = plugnoteAnnexes;
        for(let x = 0; x < selectedFiles.length; x++) {
            let tmpFile =  URL.createObjectURL(selectedFiles[x]);
            if(typeof tmpFile !== 'undefined'){
                let fullName = selectedFiles[x].name;
                let name = fullName.split('.').slice(0, -1).join('.');
                let type = fullName.substring(fullName.lastIndexOf(".") + 1);
                let newId = typeof data[data.length - 1] === "undefined" ? 1 : data[data.length - 1].id + 1;
                let tmpPayload = {
                    id: newId,
                    hash: undefined,
                    type: type,
                    path: tmpFile,
                    name: name,
                    comment: "",
                    creationdate: undefined,
                    expirationdate: undefined,
                    groupid: undefined,
                    size: selectedFiles[x].size,
                    data:selectedFiles[x]
                };
                data.push(tmpPayload)
                setDataHasChanged(true);
            }
        }
        data.filter(Boolean);
        setPlugnotesAnnexes(data);
    };

    const _handleOpenModalListAnnexes = () => {
        setIsOpenModalListAnnexes(true);
    };

    const _handleCloseModalListAnnexes = () => {
        setIsOpenModalListAnnexes(false);
    };

    // const renderAnnexeIconPreview = (annexeToPreview) => {
    //     if (annexeToPreview !== null) {
    //         switch (annexeToPreview.type.toLowerCase()) {
    //             case "jpg":
    //             case "JPG":
    //             case "jpeg":
    //             case "png":
    //             case "PNG":
    //             case "gif":
    //                 /* return <img src={getUrlPreviewAnnexe(annexeToPreview)} alt={annexeToPreview.name} onClick={(event) => handlePreviewAnnexe(event,annexeToPreview.hash)} className={classes.pointer}/>; */
    //                 return (
    //                     <FontAwesomeIcon
    //                         icon={faFileImage}
    //                         className={classes.faIconSize}
    //                     />
    //                 );
    //                 break;

    //             case "mp4":
    //             case "mkv":
    //             case "avi":
    //             case "mov":
    //                 return (
    //                     <FontAwesomeIcon
    //                         icon={faFileVideo}
    //                         className={classes.faIconSize}
    //                     />
    //                 );
    //                 break;

    //             case "pdf":
    //                 return (
    //                     <FontAwesomeIcon
    //                         icon={faFilePdf}
    //                         className={classes.faIconSize}
    //                         onClick={(event) =>
    //                             handlePreviewPdfAnnexe(
    //                                 event,
    //                                 getUrlPreviewAnnexe(annexeToPreview)
    //                             )
    //                         }
    //                     />
    //                 );
    //                 break;

    //             case "zip":
    //             case "rar":
    //                 return (
    //                     <FontAwesomeIcon
    //                         icon={faFileArchive}
    //                         className={classes.faIconSize}
    //                     />
    //                 );
    //                 break;

    //             case "mp3":
    //             case "aac":
    //                 return (
    //                     <FontAwesomeIcon
    //                         icon={faFileAudio}
    //                         className={classes.faIconSize}
    //                     />
    //                 );
    //                 break;

    //             case "html":
    //                 return (
    //                     <FontAwesomeIcon icon={faFileCode} className={classes.faIconSize} />
    //                 );
    //                 break;

    //             case "txt":
    //             case "sheet":
    //             case "doc":
    //             case "docx":
    //                 return (
    //                     <FontAwesomeIcon icon={faFileWord} className={classes.faIconSize} />
    //                 );
    //                 break;

    //             case "xls":
    //             case "xlsx":
    //             case "csv":
    //                 return (
    //                     <FontAwesomeIcon
    //                         icon={faFileExcel}
    //                         className={classes.faIconSize}
    //                     />
    //                 );
    //                 break;

    //             case "note":
    //                 return (
    //                     <FontAwesomeIcon
    //                         icon={faStickyNote}
    //                         className={classes.faIconSize}
    //                     />
    //                 );
    //                 break;

    //             case "pages":
    //                 return (
    //                     <FontAwesomeIcon icon={faFileWord} className={classes.faIconSize} />
    //                 );
    //                 break;

    //             case "pptx":
    //             case "ppt":
    //                 return (
    //                     <FontAwesomeIcon
    //                         icon={faFilePowerpoint}
    //                         className={classes.faIconSize}
    //                     />
    //                 );
    //                 break;
    //             default:
    //                 return (
    //                     <FontAwesomeIcon icon={faFile} className={classes.faIconSize} />
    //                 );
    //                 break;
    //         }
    //     }
    // };

    const renderAnnexeImagePreview = (annexeToPreview) => {
        if(!annexeToPreview.hasOwnProperty('path')){
            annexeToPreview.path = getUrlPreviewAnnexe(annexeToPreview);
        }
        // console.log('annexe', annexeToPreview);
        switch (annexeToPreview.type.toLowerCase()) {
            case "jpg":
            case "JPG":
            case "jpeg":
            case "png":
            case "PNG":
            case "gif":
                /* case 'jfif': */
                return (
                    <img
                        alt={annexeToPreview.name}
                        onClick={(event) =>
                            handlePreviewAnnexe(event, annexeToPreview.hash)
                        } /* className={classes.modalFile} */
                        src={annexeToPreview.path}
                    />
                );

            case "mp4":
            case "mkv":
            case "avi":
            case "mov":
                return (
                    <video className={classes.modalFile} controls>
                        <source
                            src={annexeToPreview.path}
                            type={`video/${annexeToPreview.type.toLowerCase()}`}
                        />
                    </video>
                );

            case "pdf":
                return (
                    <iframe
                        className={classes.modalFile}
                        src={annexeToPreview.path}
                        title={`att-${annexeToPreview.name}`}/>
                );

            case "zip":
            case "rar":
                return (
                    <div className={classes.notRender}>
                        <FontAwesomeIcon
                            icon={faFileArchive}
                            className={classes.faIconView}
                        />
                        <p class={classes.notRenderText}>
                            {t("multiple.file-not-previewed")}
                        </p>
                    </div>
                );

            case "mp3":
            case "aac":
                return (
                    <audio className={classes.modalFile} controls>
                        <source
                            src={annexeToPreview.path}
                            type={`audio/${annexeToPreview.type.toLowerCase()}`}
                        />
                    </audio>
                );

            case "html":
                return (
                    <iframe
                        className={classes.modalFile}
                        src={annexeToPreview.path}
                        title={`att-${annexeToPreview.name}`}/>
                );

            case "txt":
                return (
                    <iframe
                        src={annexeToPreview.path}
                        className={classes.modalFile}
                        onClick={(event) =>
                            handlePreviewPdfAnnexe(
                                event,
                                getUrlPreviewAnnexe(annexeToPreview)
                            )
                        }
                        title={`att-${annexeToPreview.name}`}
                    />
                );

            case "sheet":
            case "doc":
            case "docx":
                /* return <iframe src={`https://docs.google.com/gview?url=${getUrlPreviewAnnexe(annexeToPreview)}&embedded=true`} className={classes.modalFile} onClick={(event) => handlePreviewPdfAnnexe(event,getUrlPreviewAnnexe(annexeToPreview))} />; */
                return (
                    <iframe
                        className={classes.modalFile}
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${annexeToPreview.path}`}
                        title={`att-${annexeToPreview.name}`}
                    />
                );

            case "xls":
            case "xlsx":
            case "csv":
                return (
                    <iframe
                        className={classes.modalFile}
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${annexeToPreview.path}`}
                        title={`att-${annexeToPreview.name}`}
                    />
                );

            case "note":
                return (
                    <div className={classes.notRender}>
                        <FontAwesomeIcon
                            icon={faStickyNote}
                            className={classes.faIconSize}
                        />
                        <p class={classes.notRenderText}>
                            {t("multiple.file-not-previewed")}
                        </p>
                    </div>
                );

            case "pages":
                return (
                    <div className={classes.notRender}>
                        <FontAwesomeIcon icon={faFileWord} className={classes.faIconSize} />
                        <p class={classes.notRenderText}>
                            {t("multiple.file-not-previewed")}
                        </p>
                    </div>
                );

            case "pptx":
            case "ppt":
                return (
                    <iframe
                        className={classes.modalFile}
                        src={`https://view.officeapps.live.com/op/embed.aspx?src=${annexeToPreview.path}`}
                        title={`att-${annexeToPreview.name}`}
                    />
                );
            default:
                return (
                    <div className={classes.notRender}>
                        <FontAwesomeIcon icon={faFile} className={classes.faIconView} />
                        <p class={classes.notRenderText}>
                            {t("multiple.file-not-previewed")}
                        </p>
                    </div>
                );
        }
    };

    const handlePreviewAnnexe = (event,hash) => {
        event.preventDefault();
        event.stopPropagation();
        const images = [];
        plugnoteAnnexes.map((annexeToPreview, index) => {
            switch (annexeToPreview.type.toLowerCase()) {
                case "jpg":
                case "JPG":
                case "jpeg":
                case "png":
                case "PNG":
                case "gif":
                    let url = getUrlPreviewAnnexe(annexeToPreview);
                    images.push(url);
                    if (hash === annexeToPreview.hash) {
                        setSelectedPreviewAnnexe(images.length - 1);
                    }
                    break;
                default:
                    break;
            }
        });
        setImages(images);
        setOpenModalPreview(true);
    };

    const handlePreviewPdfAnnexe = (event, url) => {
        event.preventDefault();
        event.stopPropagation();
        window.open(url, "_blank");
        //setOpenPdfPreview(true);
        //setSelectedPreviewPdfAnnexe(url);
    };

    const maxSelectFileDrop = (files) => {
        if (files.length > 1) {
            const msg = "Only 1 images can be uploaded at a time";
            toast.warn(msg);
            return false;
        }
        return true;
    };

    const checkFileSizeDrop = (files) => {
        let size = 100000000;
        let err = [];
        for (var x = 0; x < files.length; x++) {
            if (files[x].size > size) {
                err[x] = files[x].type + "is too large, please pick a smaller file\n";
            }
        }
        for (var z = 0; z < err.length; z++) {
            // if message not same old that mean has error
            // discard selected file
            toast.error(err[z]);
        }
        return true;
    };

    const onChangeHandlerDrop = (acceptedFiles) => {
        const files = acceptedFiles;
        if (maxSelectFileDrop(files) && checkFileSizeDrop(files)) {
            setLoaded(0);
            setSelectedFiles(files);
            setCreateDateWithAnnexe(true);
        }
    };

    const handleChangeTableValue = (value, field, index) => {
        let listFields = fields;
        listFields[index].value = value;
        setFields([...listFields]);
        setDataHasChanged(true);
    }

    const handleChangeTableRowsValue = (value, rows, field, index) => {
        let listFields = fields;
        listFields[index].value.value = value;
        listFields[index].value.list = rows;
        setFields([...listFields]);
        setDataHasChanged(true);
    }
    // *** end of copy *** //


    const handleCloseModal = (event, reason) => {
        if (reason && reason == "backdropClick")
            return;
        props.close();
    };

    const token = sessionStorage.getItem('token');

    const seeProps = () => {
    }

    const ErrorToastMessage = ({ title, message }) => (
        <div>
            <Translation ns="common">
                {
                    (t, { i18n }) => <h6>{t(title)}</h6>
                }
            </Translation>
            <Translation ns="common">
                {
                    (t, { i18n }) => <p>{t(message)}</p>
                }
            </Translation>
        </div>
    )

    const searchCode = ()=> {
        if (plugCode.startsWith("PF")) {
            //props.attemptGetPlugform(value);
            trackPromise(
                //const res = await axios.get('https://httpbin.org/get', { params: { answer: 42 } });
                axios.get(ApplicationConfig.apiUrl + 'pfcode/modelcodelogin.json' + qs.stringify({
                    code: encodeURIComponent(plugCode),
                    uh : encodeURIComponent(props.userhash)
                }, '?'), { //token
                    headers: {
                        'X-AUTH-TOKEN': token
                    }
                })
                    .then((response) => {
                        if(response.data.result == true || response.data.result === 'ok'){
                            setResponse(response.data)
                        }else if(response.data.result == false || response.data.result === 'onk'){
                            setResponse(null)
                            toast.warn(`${i18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.errorNote}`,
                            });
                        }else if(response.data.result == 'nok'){
                            setResponse(null)
                            toast.warn(`${i18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.errorNote}`,
                            });
                        } else {
                            setResponse(null)
                            toast.warn(`${i18n.t('common:template.code-error')}`, {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 5000,
                                className: `${classes.errorNote}`,
                            });
                        }
                        setLoading(false)
                    })
                    .catch((response) => {
                        //je lance une notification (toast error)
                        toast.error(<ErrorToastMessage title='global.plugnotes' message='plugcode.delete-plugcode-success' />)
                    })
            )
        }
        else
        {   //props.attemptGetPlugnote(value);
            trackPromise(
                //const res = await axios.get('https://httpbin.org/get', { params: { answer: 42 } });
                axios.get(ApplicationConfig.apiUrl + 'pncode/datacodelogin.json' + qs.stringify({
                    code: encodeURIComponent(plugCode),
                    uh : encodeURIComponent(props.userhash)
                }, '?'),
                    { //token
                        headers: {
                            'X-AUTH-TOKEN': token
                        }}
                ).then((response) => {
                    if(response.statusText == "OK" && typeof response.data.result === 'undefined'){
                        setResponse(response.data)
                    }else if(response.data.result == false){
                        setResponse(null)
                        toast.warn(`${i18n.t('common:template.code-error')}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 5000,
                            className: `${classes.errorNote}`,
                        });
                    }else if(response.data.result == 'nok'){
                        setResponse(null)
                        toast.warn(`${i18n.t('common:template.code-error')}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 5000,
                            className: `${classes.errorNote}`,
                        });
                    } else {
                        setResponse(null)
                        toast.warn(`${i18n.t('common:template.code-error')}`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 5000,
                            className: `${classes.errorNote}`,
                        });
                    }
                    setLoading(false)
                }).catch((response) => {
                    //je lance une notification (toast error)
                    toast.error(<ErrorToastMessage title='global.plugnotes' message='plugcode.delete-plugcode-success' />)
                })
            )
        }
    }

    useEffect(()=>{
        searchCode()
    },[])

    useEffect(() => {
        if(response !== null && response !== undefined) {
            switch (plugtype) {
                case "plugform":
                    setPfModel(response);
                    setModel(response.data.data)
                    break;

                case "plugnote":
                    setPnData(response);
                    setModel(response.data.model)
                    setPlugnote(response.data.data)
                    break;

                default:
                    break;
            }
        }
    }, [loading, response])

    useEffect(() => {
        if(pnData !== null) {
            setModalTitle(pnData.model.label)
            setPlugnote(pnData.data);
            setPlugnotesAnnexes(pnData.data?.Annexes || [])
            setModel(pnData.model)
            setModelForHash(pnData.model)
            setDataForHash(pnData.data)
        }
    }, [pnData])

    useEffect(() => {
        // console.log('hash', modelForHash);
        if (model !== null && model !== undefined) {
            const labels = Object.keys(model).filter((key) => {
                const regex = /field[0-9]+label/gi;
                return regex.test(key);
            });
            let champs = labels.map((item) => {
                return model[item];
            });
            let listFields = [];
            champs.forEach((field, index) => {
                if(plugtype === 'plugform'){
                    if (field) {
                        const fieldIndex = index + 1;
                        let value = "";
                        let format = {};
                        let visuelFormat = {};
                        let show = false;
                        let event = "";
                        let encodable;
                        let visualDefault = false;
                        let options = [];
                        let originalOptions = [];
                        let sharedPlugform = false;

                        if (modelForHash && modelForHash[`field${fieldIndex}formatoptions`]) {
                            options = typeof modelForHash[`field${fieldIndex}formatoptions`] !== 'string' ? Object.create(modelForHash[`field${fieldIndex}formatoptions`])  : Object.create(JSON.parse(modelForHash[`field${fieldIndex}formatoptions`]));
                            originalOptions =  typeof modelForHash[`field${fieldIndex}formatoptions`] !== 'string' ? Object.create(modelForHash[`field${fieldIndex}formatoptions`]) : Object.create(JSON.parse(modelForHash[`field${fieldIndex}formatoptions`]));
                        }

                        // Récupération du format du champ, si le modèle existe le format vient du modèle sinon il vient de la fiche
                        if (!_.isEmpty(modelForHash)) {
                            format = modelForHash[`field${fieldIndex}format`];
                            encodable = modelForHash[`field${fieldIndex}encodable`] === 1;
                            visuelFormat = modelForHash[`field${fieldIndex}format`];
                            sharedPlugform = typeof modelForHash[`plugformModelIsSharedField${fieldIndex}`] !== "undefined" ?  modelForHash[`plugformModelIsSharedField${fieldIndex}`] : false;

                        }

                        // Si c'est une préselection on initialise la valeur à un tableau vide
                        if ( options != null && (options.presel || format === "list")) {
                            value = [];
                        }

                        // On vérifie si le champ est une préselection avec les options
                        if (options != null && options.presel && format === 'select') {

                            if (Array.isArray(value) && Immutable.isImmutable(value)) {
                                value = Immutable.asMutable(value);
                            }

                            if(options?.visibledefault){
                                visualDefault = options?.visibledefault;
                            }

                            // On vérifie si la valeur est un tableau sous forme de chaine de caractères, dans ce cas on sérialize le tableau
                            if (!Array.isArray(value)) {
                                if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@')
                                    .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                                    value = JSON.parse(value);
                                }
                                else {
                                    if (value && value != null) {
                                        value = [value];
                                    }
                                    else {
                                        value = [];
                                    }
                                }
                            }

                            switch (options.multiselect) {
                                case true :
                                    if (options.editable) {
                                        format = "free-multi-select";
                                        visuelFormat = "free-multi-select";
                                    } else {
                                        format = "multi-select";
                                        visuelFormat = "multi-select";
                                    }
                                    break;
                                default:
                                    if (options.editable) {
                                        format = "free-single-select";
                                        visuelFormat = "free-single-select";
                                    } else {
                                        format = "single-select";
                                        visuelFormat = "single-select";
                                    }
                            }

                            options.presel = (options && options.presel && isString(options.presel)) ? options.presel.split(";") : options.presel;
                        }
                        else if (options != null && options.presel && format === 'table') {

                            if (Array.isArray(value) && Immutable.isImmutable(value)) {
                                value = Immutable.asMutable(value);
                            }

                            if(options?.visibledefault){
                                visualDefault = options?.visibledefault;
                            }

                            // On vérifie si la valeur est un tableau sous forme de chaine de caractères, dans ce cas on sérialize le tableau
                            if (!Array.isArray(value)) {
                                if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@')
                                    .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                                    value = JSON.parse(value);
                                } else {
                                    if (value && value != null) {
                                        value = [value];
                                    } else {
                                        value = [];
                                    }
                                }
                            }

                            switch (options.multiselect) {
                                case true :
                                    if (options.editable) {
                                        format = "free-multi-table";
                                        visuelFormat = "free-multi-table";
                                    } else {
                                        format = "multi-table";
                                        visuelFormat = "multi-table";
                                    }
                                    break;
                                default:
                                    if (options.editable) {
                                        format = "free-single-table";
                                        visuelFormat = "free-single-table";
                                    } else {
                                        format = "single-table";
                                        visuelFormat = "single-table";
                                    }
                            }
                            options.presel = (options && options.presel && isString(options.presel)) ? options.presel.split(";") : options.presel;
                        }
                        else {
                            if (format === 'select') {
                                format = 'multi-select'
                                options = [];

                                if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                                    value = JSON.parse(value);
                                } else {
                                    if (value && value != null) {
                                        value = [value];
                                    } else {
                                        value = [];
                                    }
                                }
                            }
                            if (format === 'table') {
                                format = 'multi-table'
                                options = [];

                                if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                                    value = JSON.parse(value);
                                } else {
                                    if (value && value != null) {
                                        value = [value];
                                    } else {
                                        value = [];
                                    }
                                }
                            }
                        }

                        if (format === "now" && !value) {
                            value = moment().format("YYYY-MM-DD HH:mm:ss");
                            setDataHasChanged(true);
                        }

                        if (format === "tablemultin" && value.length === 0) {
                            value = {"value" : "", "list" : []};
                        }

                        if (format === "document" && value.length === 0) {
                            value = {"value" : "", "list" : []};
                        }

                        if (format === "smailvalidmulti" && value.length === 0) {
                            value = {"value" : "", "list" : []};
                        }


                        let originalPresel = undefined;
                        if(typeof originalOptions.presel !== "undefined" && originalOptions.presel.length > 0){
                            if(Array.isArray(originalOptions.presel)){
                                originalPresel = originalOptions.presel
                            }else{
                                originalPresel = originalOptions.presel.split(';')
                            }
                        }

                        listFields.push({
                            id: fieldIndex,
                            title: modelForHash[`field${fieldIndex}label`],
                            titleTranslate: modelForHash[`field${fieldIndex}label`],
                            value: value,
                            visuelValue : value,
                            encodable,
                            format,
                            visuelFormat,
                            sharedPlugform,
                            options,
                            originalPresel,
                            mandatory: modelForHash[`field${fieldIndex}mandatory`],
                            show,
                            event : event,
                            visualDefault
                        });

                    }
                }
                else {
                    if (field) {
                        const fieldIndex = index + 1;
                        let value = "";
                        let format = {};
                        let visuelFormat = {};
                        let show = false;
                        let visible = false;
                        let event = "";
                        let visualDefault = false;
                        let options = [];

                        if (modelForHash && modelForHash[`field${fieldIndex}formatoptions`]) {
                            options = modelForHash[`field${fieldIndex}formatoptions`];
                            if (typeof options === 'string') {
                                options = JSON.parse(modelForHash[`field${fieldIndex}formatoptions`]);
                            }
                        }

                        // Récupération du format du champ, si le modèle existe le format vient du modèle sinon il vient de la fiche
                        if (!_.isEmpty(modelForHash)) {
                            format = modelForHash[`field${fieldIndex}format`];
                            visible = modelForHash[`field${fieldIndex}visible`];
                            visuelFormat = modelForHash[`field${fieldIndex}format`];
                        }

                        // Si c'est une préselection on initialise la valeur à un tableau vide
                        if ( options != null && (options.presel || format === "list")) {
                            value = [];
                        }

                        if (!_.isEmpty(dataForHash)) {
                            if(format === 'numeric' && typeof dataForHash[`field${fieldIndex}`] !== "undefined"){
                                value = Number.isInteger(dataForHash[`field${fieldIndex}`]) ?  parseInt(dataForHash[`field${fieldIndex}`]) : parseFloat(dataForHash[`field${fieldIndex}`].replace(',', '.'));
                            }else if(format === 'list'){
                                if(typeof dataForHash[`field${fieldIndex}`] === 'undefined'){
                                    value = [];
                                }else if( dataForHash[`field${fieldIndex}`].length > 0){
                                    let keywordList = JSON.parse(dataForHash[`field${fieldIndex}`]);
                                    value = [];
                                    keywordList.map((item, index) => {
                                        value.push(item.text);
                                    })
                                }
                            }
                            else if(format === 'plugform'){
                                if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                                    value = {"value" : "", "index" : ""};
                                }else if( dataForHash[`field${fieldIndex}`].length > 0){
                                    value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                                }
                            }
                            else if(format === 'intdatamaj'){
                                if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                                    value = {"value" : "", "updatedDate" : ""};
                                }else if( dataForHash[`field${fieldIndex}`].length > 0){
                                    value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                                }
                            }
                            else if(format === 'pluglink'){
                                //value = {"code" : JSON.parse(modelForHash[`field${fieldIndex}formatoptions`]).pfcode, "clef" : modelForHash[`clef`], "userindex" : dataForHash[`userindex`]};
                                value = {"code" : typeof modelForHash[`field${fieldIndex}formatoptions`] !== "undefined" ? JSON.parse(modelForHash[`field${fieldIndex}formatoptions`])?.pfcode : "", "clef" : modelForHash[`clef`], "userindex" : dataForHash[`userindex`]};
                            }
                            else if(format === 'qrcodeint'){
                                if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                                    value = {"code" : "", "model" : "", "data" : "", "comment" : ""};
                                }else if( dataForHash[`field${fieldIndex}`].length > 0){
                                    value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                                }
                            }
                            else if(format === 'smailvalidmulti'){
                                if(typeof dataForHash[`field${fieldIndex}`] === 'undefined' || dataForHash[`field${fieldIndex}`] === ""){
                                    value = {"value" : "", "list" : []};
                                }else if( dataForHash[`field${fieldIndex}`].length > 0){
                                    value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                                }
                            }
                            else if(format === 'tablemulti'){
                                if(typeof dataForHash[`field${fieldIndex}`] === 'undefined'){
                                    value = {"value" : ""};
                                }else if( dataForHash[`field${fieldIndex}`].length > 0){
                                    value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                                }
                            }
                            else if(format === 'tablemultin'){
                                if(typeof dataForHash[`field${fieldIndex}`] === 'undefined'){
                                    value = {"value" : "", "list" : []};
                                }
                                else if( dataForHash[`field${fieldIndex}`].length > 0){
                                    value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                                }
                            }
                            else if(format === 'document'){
                                if(typeof dataForHash[`field${fieldIndex}`] === 'undefined'){
                                    value = {"value" : "", "list" : []};
                                }
                                else if( dataForHash[`field${fieldIndex}`].length > 0){
                                    value = JSON.parse(dataForHash[`field${fieldIndex}`]);
                                }
                            }
                            else {
                                value = dataForHash[`field${fieldIndex}`];
                            }
                        }

                        // On vérifie si le champ est une préselection avec les options
                        if (options != null && options.presel && format === 'select') {
                            if (Array.isArray(value) && Immutable.isImmutable(value)) {
                                value = Immutable.asMutable(value);
                            }

                            if(options?.visibledefault){
                                visualDefault = options?.visibledefault;
                            }

                            // On vérifie si la valeur est un tableau sous forme de chaine de caractères, dans ce cas on sérialize le tableau
                            if (!Array.isArray(value)) {
                                if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@')
                                    .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                                    value = JSON.parse(value);
                                } else {
                                    if (value && value != null) {
                                        value = [value];
                                    } else {
                                        value = [];
                                    }
                                }
                            }

                            switch (options.multiselect) {
                                case true :
                                    if (options.editable) {
                                        format = "free-multi-select";
                                    } else {
                                        format = "multi-select";
                                    }
                                    break;
                                default:
                                    if (options.editable) {
                                        format = "free-single-select";
                                    } else {
                                        format = "single-select";
                                    }
                            }
                            options.presel = (options && options.presel && isString(options.presel)) ? options.presel.split(";") : options.presel;
                        }
                        else if (options != null && options.presel && format === 'table') {
                            if (Array.isArray(value) && Immutable.isImmutable(value)) {
                                value = Immutable.asMutable(value);
                            }

                            if(options?.visibledefault){
                                visualDefault = options?.visibledefault;
                            }

                            // On vérifie si la valeur est un tableau sous forme de chaine de caractères, dans ce cas on sérialize le tableau
                            if (!Array.isArray(value)) {
                                if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@')
                                    .replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                                    value = JSON.parse(value);
                                } else {
                                    if (value && value != null) {
                                        value = [value];
                                    } else {
                                        value = [];
                                    }
                                }
                            }

                            switch (options.multiselect) {
                                case true :
                                    if (options.editable) {
                                        format = "free-multi-table";
                                    } else {
                                        format = "multi-table";
                                    }
                                    break;
                                default:
                                    if (options.editable) {
                                        format = "free-single-table";
                                    } else {
                                        format = "single-table";
                                    }
                            }
                            options.presel = (options && options.presel && isString(options.presel)) ? options.presel.split(";") : options.presel;
                        }
                        else {
                            if (format === 'select') {
                                format = 'multi-select'
                                options = [];

                                if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                                    value = JSON.parse(value);
                                } else {
                                    if (value && value != null) {
                                        value = [value];
                                    } else {
                                        value = [];
                                    }
                                }
                            }
                            else if (format === 'table') {
                                format = 'multi-table'
                                options = [];

                                if (value && value != null && value.length && /^[\],:{}\s]*$/.test(value.replace(/\\["\\\/bfnrtu]/g, '@').replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
                                    value = JSON.parse(value);
                                } else {
                                    if (value && value != null) {
                                        value = [value];
                                    } else {
                                        value = [];
                                    }
                                }
                            }
                        }

                        if (format === "now" && !value) {
                            value = moment().format("YYYY-MM-DD HH:mm:ss");
                            setDataHasChanged(true);
                        }

                        if( (format === "free-multi-select" || format === "free-single-select") && value.length > 0){
                            value.map((item, index) => {
                                if(!options.presel.includes(item)){
                                    options.presel.push(item);
                                }
                            })
                        }

                        if( (format === "free-multi-table" || format === "free-single-table") && value.length > 0){
                            value.map((item, index) => {
                                if(!options.presel.includes(item.text)){
                                    options.presel.push(item.text);
                                }
                            })
                        }

                        listFields.push({
                            id: fieldIndex,
                            title: modelForHash[`field${fieldIndex}label`],
                            titleTranslate: modelForHash[`field${fieldIndex}label`],
                            value: typeof value === "undefined" ? "" : value,
                            visuelValue : value,
                            format,
                            visible,
                            visuelFormat,
                            options,
                            mandatory: modelForHash[`field${fieldIndex}mandatory`],
                            show,
                            event : event,
                            visualDefault
                        });

                    }
                }

            });
            setFields(listFields);}
    }, [model, plugnote]);

    useEffect(() => {
        if(pfModel !== null) {
            setModalTitle(pfModel.data.label + ' - ' + pfModel.proprio)
            setModel(pfModel.data)
            setModelForHash(pfModel.data)
        }
    }, [pfModel])

    return (
        <Modal
            keepMounted={false}
            open={props.open}
            onClose={handleCloseModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            className={classes.modal}
            BackdropProps={{style: {backgroundColor: colors.blackAlpha}}}
        >
            <div style={modalStyle} className={classes.popup}>
                <div className={classes.modalHeader}>
                    {/* <span id="icon-listForm" className={`material-icons md-25 `} style={{marginLeft: 'auto'}} onClick={() => seeProps()}>
                        visibility
                    </span> */}
                    <div className={classes.headerTitle}>
                        <span className="titres align-items-center">
                                {modalTitle}
                        </span>
                        <span className={classes.titleDetails}>{` (${props.codes})`}</span>
                        <br/>
                    </div>
                    <div className={classes.closeBtn}>
                        <span id="icon-close"
                            className="material-icons md-50 lightgrey"
                            onClick={handleCloseModal}>
                            close
                        </span>
                    </div>
                </div>

                {loading? null :
                <div className={classes.modalBody}>
                    <Plugforms
                        mode={plugtype === "plugform" ? "plugcode-complete-modal" : "plugcode-consult-modal"}
                        shared={false}
                        fields={fields}
                        nameModel={nameModel}
                        handleExitCreatePlugnote={handleExitCreatePlugnote}
                        handleExitPlugnote={handleExitPlugnote}
                        plugnote={pnData}
                        model={modelForHash}
                        setFields={setFields}
                        handleChangeTableValue={handleChangeTableValue}
                        handleChangeTableRowsValue={handleChangeTableRowsValue}
                        handleEditDateTime={handleEditDateTime}
                        handleEditValue={handleEditValue}
                        handleEditPhoneValue={handleEditPhoneValue}
                        handleChangeKeyword={handleChangeKeyword}
                        handleChangeRadioTable={handleChangeRadioTable}
                        handleChangePlugform={handleChangePlugform}
                        handleChangeIntDataMaj={handleChangeIntDataMaj}
                        handleChangeExtData={handleChangeExtData}
                        handleChangePassword={handleChangePassword}
                        handleChangeRadio={handleChangeRadio}
                        handleChangeBoolean={handleChangeBoolean}
                        handleChangePlugcode={handleChangePlugcode}
                        handleChangePlugcodeInternal={handleChangePlugcodeInternal}
                        handleChangeInputTable={handleChangeInputTable}
                        handleUpdateInputTable={handleUpdateInputTable}
                        handleDeleteInputTable={handleDeleteInputTable}
                        handleChangeLocalisation={handleChangeLocalisation}
                        handleSaveCreatePlugnote={handleSaveCreatePlugnote}
                        plugnoteAnnexes={plugnoteAnnexes}
                        _handleOpenModalListAnnexes={_handleOpenModalListAnnexes}
                        _handleCloseModalListAnnexes={_handleCloseModalListAnnexes}
                        downloadEmployeeData={downloadEmployeeData}
                        handleModalAnnexeOpen={handleModalAnnexeOpen}
                        handleDeleteAnnexe={handleDeleteAnnexe}
                        handlePreviewAnnexe={handlePreviewAnnexe}
                        handlePreviewPdfAnnexe={handlePreviewPdfAnnexe}
                        onChangeHandlerDrop={onChangeHandlerDrop}
                        renderAnnexeIconPreview={renderAnnexeIconPreview}
                        disabled={plugtype !== "plugform"}
                        environment={environment}
                        selectedAnnexe={selectedAnnexe}
                        renderAnnexeImagePreview={renderAnnexeImagePreview}
                        isOpenModalAnnexe={isOpenModalAnnexe}
                        handleModalAnnexeClose={handleModalAnnexeClose}
                        attemptGetDataUnique={props.attemptGetDataUnique}
                        isOpenModalListAnnexes={isOpenModalListAnnexes}
                        handleSaveFictifComment={handleSaveFictifComment}
                        handleSavePlugform={handleSavePlugform}
                        handleNoteOpen={handleNoteOpen}
                        handleCreateNote={handleCreateNote}
                        handleUpdateNote={handleUpdateNote}
                        handleNoteClose={handleNoteClose}
                    />
                    <Dialog
                        open={openModal}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">{t('global.plugnotes')}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {
                                    t('plugnote-create-update.plug-form-mandatory-fields-empty')
                                }
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            { cancel &&
                            <Button onClick={handleClickCancelModal} color="primary">
                                {
                                    t('global.cancel')
                                }
                            </Button>
                            }
                            <Button onClick={handleClickContinueModal} color="primary" autoFocus>
                                {
                                    t('global.ok')
                                }
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {
                        openNote &&
                        <ModalNoteComponent selectedNote={selectedNote} open={openNote}  close={handleNoteClose} handleSaveNote={handleSaveNote} handleUpdateNote={handleUpdateNote}/>
                    }
                    {
                        openClassify &&
                        <ModalSavePlugcodeComponent open={openClassify} close={handleCloseClassify} />
                    }
                </div>}
            </div>
        </Modal>)
}

const mapStateToProps = (state, props) => {
    // console.log('global',state);
    return {
        userhash: state?.user?.user?.userhash || null,
        email: typeof state.user.profil !== "undefined" ? state.user.profil.email : '',
    }
}
const mapDispatchToProps = (dispatch) => {return {
    attemptCreatePFCodePlugnote: (payload, files, model, userhash) => dispatch(postCreatePFCodePlugnoteInternalRequest(payload, files, model, userhash)),
    attemptCreatePFCodePlugnoteBase64: (payload) => dispatch(postCreatePlugnoteInternalBase64Request(payload))
}};

export default connect(mapStateToProps, mapDispatchToProps)(ModalViewPlugcodeComponent)
