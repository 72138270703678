import React, {useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import Input from "@material-ui/core/Input";
import {useTranslation} from "react-i18next";
import TextField from "@material-ui/core/TextField";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    MuiInputBaseInput:{
        padding : 0
    },
    mainField : {
        display: 'flex',
        flexDirection : 'column',
        paddingLeft: 40,
        paddingRight: 10,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    inputType: {
        marginBottom: 8,
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    field : {
        display: 'flex',
    },
    inputField:{
        padding : '0px 0px 7px',
    },
    mainInputField:{
        display: 'flex',
    }
}));


const FormatPlugformConstructorComponent = (props) => {
    const classes = useStyles();
    const { t } = useTranslation(['common', 'models']);
    const [newDataClef, setDataClef] = useState(props.field.options.clef !== ""  ? props.field.options.clef  : "");
    const [newDataField, setDataField] = useState(props.field.options.field !== ""  ? props.field.options.field  : "");

    const handleDataClef = (clef) => {
        setDataClef(clef);
        props.field.options.clef = clef;
        let model = [...props.model];
        model[props.index].options.clef = clef;
        props.setModel(model);
    }

    const handleDataField = (field) => {
        setDataField(field);
        props.field.options.field = field;
        let model = [...props.model];
        model[props.index].options.field = field;
        props.setModel(model);
    }

    return (
        <div className={classes.mainField}>
            {/* <div className={classes.field}>
                <Input
                    id="standard-full-width-plugform"
                    label="Label"
                    placeholder={t('fields.format-choice-placeholder-'+props.field.format.toLowerCase())}
                    fullWidth={true}
                    margin="none"
                    inputlabelprops={{
                        shrink: true,
                    }}
                    inputProps={
                        {
                            fieldkey: props.field.key,
                            className: classes.inputField,
                            maxLength: 40
                        }
                    }
                    className="text-line"
                    onChange={(event) => props.handleEditLabel(event.target.value, props.field, props.index)}
                    defaultValue={props.field.label}
                />
            </div> */}
            <div className={classes.inputType}>
                <div className={classes.mainInputField}>
                    <Input
                        id="standard-full-width-clef"
                        label="Label"
                        placeholder={t('fields.format-choice-placeholder-'+props.field.format.toLowerCase()+'-clef')}
                        fullWidth={true}
                        margin="none"
                        inputlabelprops={{
                            shrink: true,
                        }}
                        inputProps={
                            {
                                fieldkey: props.field.key,
                                className: classes.inputField,
                                maxLength: 40
                            }
                        }
                        className="text-line"
                        onChange={(event) => handleDataClef(event.target.value)}
                        defaultValue={newDataClef}
                    />

                    <Input
                        id="standard-full-width-field"
                        label="Label"
                        placeholder={t('fields.format-choice-placeholder-'+props.field.format.toLowerCase()+'-field')}
                        fullWidth={true}
                        margin="none"
                        inputlabelprops={{
                            shrink: true,
                        }}
                        inputProps={
                            {
                                fieldkey: props.field.key,
                                className: classes.inputField,
                                maxLength: 40
                            }
                        }
                        className="text-line"
                        onChange={(event) => handleDataField(event.target.value)}
                        defaultValue={newDataField}
                    />
                </div>
            </div>
        </div>
    );
};

// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatPlugformConstructorComponent);





