import React, {useEffect, useState} from "react"
//import styles from "./styles.module.less"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import {useTranslation} from "react-i18next";
import ModalListFicheComponent from "../modals/ModalListFicheComponent";
import ModalViewFicheComponent from "../modals/ModalViewFicheComponent";
import {getCurrentEnvironnement, getToken} from "../../utils/Common";
import {isJsonString} from "../../utils/utils";
import {getDataByClefUserIndexRequest, reduxGetDataByClefUserIndexReset} from "../../actions/data";
import {confirmAlert} from "react-confirm-alert";
import { Tooltip } from "@material-ui/core";
import colors from "../../assets/themes/Colors";
import ApplicationConfig from '../../config/ApplicationConfig';
import axios from "axios";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    fieldTop:{
        width : '100%',
        // border: '1px solid #E3E3E3',
        borderRadius: '6px',
        display: 'flex',
    },
    fieldValueCtn:{
        width : '100%',
        border: '1px solid #E3E3E3',
        borderRadius: '6px',
        marginTop: '5px',
        padding: '3px 10px',
        display: 'flex',
        flexDirection: 'row',
    },
    fieldBottom: {
        '& .MuiInputBase-inputMultiline': {
            alignSelf: 'flex-start',
        }
    },
    mainContainer:{
        minHeight: '40px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    rightBtns: {
        cursor: 'pointer',
        padding: '3px 6px',
        marginLeft: 5,
        borderRadius: "3px",
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        color: colors.unselectedAlpha,
    },
    topRightBtns: {
        flexDirection: 'row',
        display: 'flex',
        maxHeight: 'none',
        height: 'auto',
        justifyContent: 'flex-end',
    },
    inputAdornmentCtn: {
        maxHeight: 'none',
        height: 'auto',
    },
    addFormBtn: {
        fontSize: '17px',
        minHeight: '40px',
        padding: '3px 6px',
        color: 'white!important',
        alignSelf: 'center',
        backgroundColor: colors.unselectedAlpha,
        borderRadius: '6px',
        justifyContent: 'center',
        textAlign: 'center',
        cursor: 'pointer',
        whiteSpace: 'break-spaces',
    },
    folderText: {
        fontSize: 16,
        color: colors.unselected
    },
    formBtnText: {
        verticalAlign: '-webkit-baseline-middle',
        fontSize : 14,
        display: 'inline-flex',
        alignItems: 'center',
    },
    searchFieldCtn: {
        border: '1px solid',
        borderColor: colors.greyLight,
        borderRadius: 4,
        padding: '3px 10px',
    },
    searchField: {
        minWidth: 150,
        width: '50%',
        transition: 'margin 0.5s'
    },
    searchCtn: {
        display: 'inline-flex',
        flexDirection: 'row',
        width: '-webkit-fill-available',
        gap: 8,
        justifyContent: 'space-between',
    },
    searchBtnText: {
        '@media(max-width: 500px)': {
            display: 'none'
        }
    }
}));

const parseJson = (code) => {
    try {
        return JSON.parse(code);
    } catch (e) {
        return code;
    }
};

const FormatPlugformComponent = (props) => { // F27
    const classes = useStyles();
    const environment = getCurrentEnvironnement();
    const { t } = useTranslation(['common', 'models']);
    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [owner, setOwner] = useState(typeof props.owner === 'undefined' ? null: props.owner);
    const [open, setOpen] = useState(false);
    const [view, setView] = useState(false);
    const [clef, setClef] = useState(props.field.options.clef);
    const [field, setField] = useState(props.field.options.field);
    const [value, setValue] = useState(!!props.field.value.value ? props.field.value.value : "");
    const [index, setIndex] = useState(!!props.field.value.index ? props.field.value.index : "");
    const [name, setName] = useState(!!props.field.value.name ? props.field.value.name : "");
    const [model, setModel] = useState(null);
    const [filter, setFilter] = useState('')
    const [openSearch, setOpenSearch] = useState(false)

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled);
    },[ props.disabled]);

    useEffect(() => {
        setValue(!!props.field.value.value ? props.field.value.value : "");
        setIndex(!!props.field.value.index ? props.field.value.index : "");
        setName(!!props.field.value.name ? props.field.value.name : "");
    },[props.field.value])

    useEffect(() => {
        if(props.cached !== null && props.model !== null && props.field.id.toString() === props.fieldToRefresh.toString()){
            let fields = field.toString().split(';');
            let infos = [];
            if(fields.length > 0){
                fields.map((value, index) => {
                    infos.push(
                        {
                            "format" : props.cached["field"+value+"format"],
                            "value" : props.cached["field"+value]
                        }
                    );
                })
                handleSelectPlugnoteAndCopyDataFieldCached(props.cached.userindex,infos, props.model);
            }
        }
    }, [props.cached, props.model])

    useEffect(() => {
        let hash = typeof props.owner === "undefined" || props.owner === null ? environment.userhash : props.owner
        axios.get(
            ApplicationConfig.apiUrl + 'modeluniquebyclefcode?clef=' + props.field.options.clef + '&uh=' + hash + '&umh=' + environment.userhash,
            {
                crossdomain: true,
                'headers': {
                    'X-AUTH-TOKEN': getToken(),
                }
            })
        .then((response) => {
            // console.log('response', response);
            setModel(response.data.data)
            setName(response.data.data.label)
        })
        .catch((e) => console.log('error', e))
    }, [])

    const handleOpenFiche = () => {
        setView(true);
    }

    const handleOpenSearchModel = () => {
        setOpen(true);
    }

    const handleRefreshContentData = () => {
        confirmAlert({
            title: t('global.plugnotes'),
            message: t('global.content-replace'),
            buttons: [
                {
                    label: t('global.yes'),
                    onClick: () => {
                        if(owner !== null){
                            props.attemptGetPlugnoteByClefAndUserHashAndUserIndex(clef,owner,index, props.field.id);
                        }
                        else {
                            props.attemptGetPlugnoteByClefAndUserHashAndUserIndex(clef,environment.userhash,index, props.field.id);
                        }
                    }
                },
                {
                    label: t('global.no'),
                },
            ]
        });

    }

    const handleSelectPlugnoteAndCopyDataFieldOld = (userindex, format, value, model) => {
        let tmp = "";
        switch (format){
            case 'select' :
                let tmpValueSelect = !Array.isArray(value) && value !== "[]" && value !== ""  ?  JSON.parse(value).join(',') : "";
                tmp = {"value" : tmpValueSelect , "index" : userindex, "name" : model.label};
                break;
            case 'table' :
                let tmpValueTable = !Array.isArray(value) && value !== "[]" && value !== "" ? JSON.parse(value).reduce((res, obj) => {
                    return res.concat(obj.text + ' : ' + obj.value || '');
                }, []).join(', ') : "";
                tmp = {"value" : tmpValueTable , "index" : userindex, "name" : model.label};
                break;
            case 'list' :
                let tmpValueList = !Array.isArray(value) && value !== "[]" && value !== "" ? JSON.parse(value).reduce((res, obj) => {
                    return res.concat(obj.text || '');
                }, []).join(', ') : "";
                tmp = {"value" : tmpValueList , "index" : userindex, "name" : model.label};
                break;
            case 'plugform' :
                let tmpValuePlugform = "";
                if(isJsonString(value)){
                    tmpValuePlugform = JSON.parse(value).value;
                }
                tmp = {"value" : tmpValuePlugform , "index" : userindex, "name" : model.label};
                break;
            default :
                tmp = {"value" : value , "index" : userindex, "name" : model.label};
                break;
        }

        props.handleChangePlugform(tmp, props.field, props.index);
        setOpen(false);
    }

    const handleSelectPlugnoteAndCopyDataFieldCached  = (userindex, object, model) => {
        let tmp = "";
        if(object.length > 0){
            let value = "";
            object.forEach((item, index) => {
                let temp = "";
                switch (item.format){
                    case 'select' :
                        let tmpValueSelect = !Array.isArray(item.value) && item.value !== "[]" && item.value !== ""  ?  JSON.parse(item.value).join(',') : "";
                        temp = tmpValueSelect;
                        break;
                    case 'table' :
                        let tmpValueTable = !Array.isArray(item.value) && item.value !== "[]" && item.value !== "" ? JSON.parse(item.value).reduce((res, obj) => {
                            return res.concat(obj.text + ' : ' + obj.value || '');
                        }, []).join(', ') : "";
                        temp = tmpValueTable;
                        break;
                    case 'list' :
                        let tmpValueList = !Array.isArray(item.value) && item.value !== "[]" && item.value !== "" ? JSON.parse(item.value).reduce((res, obj) => {
                            return res.concat(obj.text || '');
                        }, []).join(', ') : "";
                        temp = tmpValueList;
                        break;
                    case 'plugform' :
                        let tmpValuePlugform = "";
                        if(isJsonString(item.value)){
                            tmpValuePlugform = JSON.parse(item.value).value;
                        }
                        temp = tmpValuePlugform;
                        break;
                    case 'glocalis':
                        let plugValue = parseJson(item.value);
                        let tmpValue = "";
                        if(typeof plugValue.address !== "undefined" && plugValue.address.length > 0){
                            tmpValue = tmpValue.concat(plugValue.address);
                        }

                        if(typeof plugValue.latitude !== "undefined"  &&  typeof plugValue.longitude !== "undefined"){
                            if(tmpValue.length > 0){
                                tmpValue = tmpValue.concat(' ~ ');
                                tmpValue = tmpValue.concat(' \n ');
                            }
                            tmpValue = tmpValue.concat('lat: ' + plugValue.latitude + ' , lng: ' + plugValue.longitude);
                        }

                        temp = tmpValue;
                        break;
                    default :
                        temp = item.value;
                        break;
                }
                if(temp !== "" && typeof temp !== "undefined"){
                    value = value === "" ? value.concat(temp) : value.concat(' ~ \n'+ temp);
                }
            })

            tmp = {"value" : value , "index" : userindex, "name" : model.label};
            props.handleChangePlugform(tmp, props.field, props.index);
        }
        setOpen(false);
        setOpenSearch(false)
        props.attemptGetPlugnoteByClefAndUserHashAndUserIndexReset();
    }

    const handleSelectPlugnoteAndCopyDataField = (userindex, object, model) => {
        let tmp = "";
        if(object.length > 0){
            let value = "";
            object.forEach((item, index) => {
                let temp = "";
                switch (item.format){
                    case 'select' :
                        let tmpValueSelect = !Array.isArray(item.value) && item.value !== "[]" && item.value !== ""  ?  JSON.parse(item.value).join(',') : "";
                        temp = tmpValueSelect;
                        break;
                    case 'table' :
                        let tmpValueTable = !Array.isArray(item.value) && item.value !== "[]" && item.value !== "" ? JSON.parse(item.value).reduce((res, obj) => {
                            return res.concat(obj.text + ' : ' + obj.value || '');
                        }, []).join(', ') : "";
                        temp = tmpValueTable;
                        break;
                    case 'list' :
                        let tmpValueList = !Array.isArray(item.value) && item.value !== "[]" && item.value !== "" ? JSON.parse(item.value).reduce((res, obj) => {
                            return res.concat(obj.text || '');
                        }, []).join(', ') : "";
                        temp = tmpValueList;
                        break;
                    case 'plugform' :
                        let tmpValuePlugform = "";
                        if(isJsonString(item.value)){
                            tmpValuePlugform = JSON.parse(item.value).value;
                        }
                        temp = tmpValuePlugform;
                        break;
                    case 'glocalis':
                        let plugValue = parseJson(item.value);
                        let tmpValue = "";
                        if(typeof plugValue.address !== "undefined" && plugValue.address.length > 0){
                            tmpValue = tmpValue.concat(plugValue.address);
                        }

                        if(typeof plugValue.latitude !== "undefined"  &&  typeof plugValue.longitude !== "undefined"){
                            if(tmpValue.length > 0){
                                tmpValue = tmpValue.concat(' ~ ');
                                tmpValue = tmpValue.concat(' \n ');
                            }
                            tmpValue = tmpValue.concat('lat: ' + plugValue.latitude + ' , lng: ' + plugValue.longitude);
                        }

                        temp = tmpValue;
                        break;
                    case 'password': 
                        if (item.value.length > 0) temp = temp.concat('*******')
                        break;
                    default :
                        temp = item.value;
                        break;
                }
                if(temp !== "" && typeof temp !== "undefined"){
                    value = value === "" ? value.concat(temp) : value.concat(' ~ \n'+ temp);
                }
            })
            tmp = {"value" : value , "index" : userindex, "name" : model.label};
            props.handleChangePlugform(tmp, props.field, props.index);
        }
        setOpen(false);
        setOpenSearch(false);
    }

    const handleDeleteContentData = () => {
        // console.log('F27 - props', props);
        let emptyTmp = {"value" : "" , "index" : null, "name" : null}
        props.handleChangePlugform(emptyTmp, props.field, props.index);
    }

    function handleSearchValue (value) {
        setFilter(value)
    }

    // console.log('F27 - props', props, environment, model);
    return (
        <div className={classes.mainContainer}>
        {
            value !== '' ?
            <div className={classes.fieldValueCtn}>
                <TextField
                    disabled={true}
                    id={'plugform-input-' + props.field.id}
                    multiline={true}
                    helperText=""
                    fullWidth
                    margin="normal"
                    InputProps={{
                        disableUnderline: true,
                        // endAdornment:
                        //     <InputAdornment
                        //         classes={{root: classes.inputAdornmentCtn}}
                        //         position="end"
                        //     >
                        //     </InputAdornment>,
                        sx: {
                            "& .css-3fezr7-MuiInputBase-root-MuiOutlinedInput-root" : {
                                padding: "4px 8px 4px 8px"
                                },
                                fontSize: ".875rem;",
                                alignItems:"flex-start"
                            }
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    className={classes.fieldBottom}
                    style={{ margin: 0}}
                    type="text"
                    value={value}
                />
                <div>
                    <div className={classes.topRightBtns}>
                        <Tooltip title={t('plugform.view-plugnote')} placement='top'>
                            <span id="icon-listForm" className={`material-icons md-25`} onClick={(event) => handleOpenFiche()} style={{ alignSelf: 'flex-end', }}>
                                info
                            </span>
                        </Tooltip>
                        {
                            typeof index == 'number' && !disabled &&
                            <Tooltip title={t('plugform.refresh-content')} placement='top'>
                                <span id="icon-listForm" className={`material-icons md-25`} onClick={() => handleRefreshContentData()}>
                                    cached
                                </span>
                            </Tooltip>
                        }
                    </div>
                    <div className={classes.topRightBtns}>
                        {
                            typeof index == 'number' && !disabled &&
                            <span id="icon-listForm" className={`material-icons md-25`} onClick={() => handleOpenSearchModel()}>
                                search
                            </span>
                        }
                        {
                            typeof index == 'number' && !disabled &&
                            <Tooltip title={t('plugform.delete-content')} placement='top'>
                                <span id="icon-listForm" className={`material-icons md-25`} onClick={() => handleDeleteContentData()}>
                                    delete
                                </span>
                            </Tooltip>
                        }
                    </div>
                </div>
            </div>
            :
            <div className={classes.searchFieldCtn}>
                <div className={classes.fieldTop}>
                    {
                        typeof clef !== "undefined" && typeof name !== "undefined" ?
                        <div className={classes.folderCtn}>
                            <span className={classes.folderText}>From folder: </span>
                            <span className={classes.folderText}>
                                {
                                    index !== "" ?
                                    name + " [" + clef + "] ("  + index + ")"
                                    :
                                    name + " [" + clef + "] "
                                }
                            </span>
                        </div>
                        :
                        <span>not configured</span>
                    }

                    { typeof clef !== "undefined" && typeof name !== "undefined" &&
                    <div className={classes.rightBtns}>
                        {/* {
                            typeof index == 'number' && !disabled &&
                            <span id="icon-listForm" className={`material-icons md-25`} onClick={(event) => handleDeleteContentData()}>
                                delete
                            </span>
                        }
                        {
                            typeof index == 'number' && !disabled &&
                            <span id="icon-listForm" className={`material-icons md-25`} onClick={(event) => handleRefreshContentData()}>
                                cached
                            </span>
                        } */}


                        {
                            typeof index == 'number' && !disabled &&
                            <Tooltip title={t('plugform.view-plugnote')} placement='top'>
                                <span id="icon-listForm" className={`material-icons md-25`} onClick={(event) => handleOpenFiche()} style={{alignSelf: 'flex-end',}}>
                                    info
                                </span>
                            </Tooltip>
                        }
                    </div>
                    }
                </div>
                {
                    !disabled &&
                    <div className={classes.searchCtn}>
                        <TextField
                            className={classes.searchField}
                            helperText=""
                            id={'plugform-filter-input-' + props.field.id}
                            margin="none"
                            multiline={true}
                            placeholder={t('plugform.filtered-search')}
                            onChange={(event) => handleSearchValue(event.target.value)}
                            onKeyDown={(e) => {if (e.key === 'Enter') handleOpenSearchModel()}}
                            value={filter}
                        />
                        <Tooltip title={t('plugform.select-plugnote')} placement='top'>
                            <div className={classes.addFormBtn} onClick={(event) => handleOpenSearchModel()}>
                                <span className={classes.formBtnText}>
                                    <span className={classes.searchBtnText}>{t('plugnote.select-plugnote')}</span>
                                    <span id="icon-listForm" className={`material-icons md-25`} onClick={() => handleOpenSearchModel()}>
                                        search
                                    </span>
                                </span>
                            </div>
                        </Tooltip>
                    </div>
                }
            </div>
        }

            {
                open &&
                <ModalListFicheComponent
                    clef={clef}
                    close={() => setOpen(false)}
                    handleSelectPlugnoteAndCopyDataField={handleSelectPlugnoteAndCopyDataField}
                    index={index}
                    label={name} field={field} filter={filter}
                    mode={props.mode}
                    open={open}
                    owner={owner}
                    value={value}
                />
            }

            {
                view &&
                <ModalViewFicheComponent
                    clef={clef}
                    close={() => setView(false)}
                    index={index}
                    mode={props.mode}
                    open={view}
                    owner={owner}
                />
            }
        </div>
    );
};


// redux providing state takeover
const mapStateToProps = (state) => {
    let cached = state.data.uniqueClefUserIndex || null;
    let model = state.data.uniqueModelClef || null;
    let fieldToRefresh = state.data.fieldToRefresh || null;
    // console.log('F27 state', state);

    return {
        model,
        cached,
        fieldToRefresh
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptGetPlugnoteByClefAndUserHashAndUserIndex: (clef,userhash,userindex, fieldId) => dispatch(getDataByClefUserIndexRequest(clef,userhash,userindex, fieldId)),
    attemptGetPlugnoteByClefAndUserHashAndUserIndexReset: () => dispatch(reduxGetDataByClefUserIndexReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatPlugformComponent);
