import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import axios from "axios";
import ApplicationConfig from "../../config/ApplicationConfig";
import {getCurrentEnvironnement, getModel, getToken} from "../../utils/Common";
import CssBaseline from "@material-ui/core/CssBaseline";
import {Content, Header} from "rsuite";
import Input from "@material-ui/core/Input";
import {confirmAlert} from "react-confirm-alert";
import Select from "react-select";
import {formatTypes} from "../../utils/Compatibility";
import {Checkbox, FormControl, TextField} from "@material-ui/core";
import Images from "../../assets/themes/Images";
import colors from '../../assets/themes/Colors';
import {isDefined} from '../../utils/utils';
import {
    deleteDeleteLayoutByModelRequest,
    getAllLayoutsByModelRequest,
    getOneLayoutByModelRequest,
    postLayoutByModelRequest
} from "../../actions/layout";


const drawerWidth = 320;

const selectStyles = {
    menu: base => ({
        ...base,
        zIndex: 100
    })
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    mandatory:{
        color : '#E54F62',
    },
    notMandatory:{
        color : '#7F7F7F',
    },
    isTrue:{
        color : '#58C3C1',
    },
    isFalse:{
        color : '#E54F62',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: '40px'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    openClose: {
        bottom: 0,
        width : '100%'
    },
    openCloseButton : {
        float : 'right'
    },
    content: {
        width : '100%',
        backgroundColor : 'white',
        padding: '20px 20px',
    },
    contentModel : {
        flexGrow: 1,
        padding: theme.spacing(3),
        overflow : 'scroll',
        maxHeight : 'calc(80vh - 100px)'
    },
    icon:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : 'black'
    },
    iconActive:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : 'blue'
    },
    gridHeader : {
        padding: '10px 20px !important',
        width: '100%',
    },
    selectEnvironnement : {
    },
    fieldImage: {
        height: 10,
        width: 10
    },
    fieldImageSvg: {
        width: 25,
        height: 25,
    },
    fieldIcon : {
        width: 20,
        height: 20,
        backgroundColor: '#57bbb8',
        borderRadius: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    fieldIconSvg : {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
    field : {
        display: 'flex',
        flexDirection: 'row',
    },
    fieldLeft : {
        alignContent: 'flex-start',
        display: 'flex',
        alignItems: 'center',
    },
    fieldRightLeft : {
        alignContent: 'flex-end'
    },
    fieldRight:{
        textAlign : 'center'
    },
    fullWidth :{
        width : '100%'
    },
    paper : {
        display: 'block !important',
        overflow : 'hidden !important'
    },
    paperAnchorDockedLeft : {
        boxShadow: '0px 0px 6px 4px rgba(136, 136, 136, 0.12)',
        border : 0,
    },
    listHeight:{
        height: '100%'
    },
    buttonCtn: {
        height: 52,
        marginLeft: 30
    },
    createBtn: {
        width: 'max-content'
    },
    subtitleCtn: {
        display: 'flex',
        flexDirection: 'row',
    },
    fieldCtn: {
        marginBottom: 8,
    },
    // Select
    selectsCtn: {
        minWidth: 100,
        minHeight: 40,
        marginRight: 30,
    },
    // Sub-Fields
    subfieldsCtn: {
        marginLeft: 25,
        // marginRight: 15,
    },
    subfieldCtn: {
        display: 'flex',
        alignItems: 'center',
        gap: 5,
    },
    subfield: {
        // marginLeft: 25,
        flex: 1,
    },
    subfieldNb: {
		fontSize: 20,
        fontWeight: 'bold',
		color: colors.main,
		display: 'inline-flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: '2px solid',
		borderRadius: '50%',
		verticalAlign: 'middle',
		width: 30,
		height: 30,
	},
    // Sub-Presels
    subPreselsCtn: {
        paddingLeft: 40,
    },
    subPreselNb: {
		fontSize: 12,
        fontWeight: 'bold',
		color: colors.main,
		display: 'inline-flex',
        width: 30,
        height: 30,
		border: '1px solid',
		borderRadius: '50%',
		justifyContent: 'center',
		alignItems: 'center',
    },
    layoutSelect: {
        lineHeight: 0.6,
        fontSize: 9,
        display: 'flex',
        flexDirection: 'column',
    },
    fieldWithSubfieldsCtn: {
        display: 'flex',
        flexDirection: 'row',
    },
    openSubfieldsBtn: {
        flex: 0.2,
        margin: '3px 15px',
        borderRadius: 4,
        backgroundColor: colors.mainButton,
        color: 'white',
        cursor: 'pointer',
        minWidth: 180,
        alignSelf: 'center',
        padding: '8px 15px',
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'center',
    }
}));


function ModelLayout(props) {
    const classes = useStyles();
    const hasSubfields = ["tablemulti", "smailvalidmulti", "tablemultin", "document", "select", ] // ajouter table
    const { t, i18n } = useTranslation(['common', 'models']);
    const [myModel, setMyModel] = useState(null)
    const [updateModelLayout, setUpdateModelLayout] = useState([]);
    const [updated, setUpdated] = useState(false);
    const environment = getCurrentEnvironnement();

    const model = getModel();

    function getFieldOptions (field) {
        if (typeof field !== "undefined" && hasSubfields.includes(field.format)) {
            let array = []
            if (field.format === 'select') {
                field.formatoptions.presel.forEach((presel, pindex) => array.push({visible : true, encodable : true}))
                return array.length > 0 ? array : null
            }
            else {
                let options = isDefined(field.options.lines) ? field.options.lines : field.options;
                options.forEach((row) => {
                    let presels = {};
                    if ((row.format === 'presel' || row.format === 'select') && row.presel?.length > 0) {
                        row.presel.forEach((entry, index) => presels[index] = {visible : true , encodable : true} );
                    }
                    array.push((row.format === 'presel' || row.format === 'select') ? { id: row.id, label: '', presels: presels, visible : true, encodable : true  } : { id: row.id, label: '', visible : true, encodable : true });
                });
                return array.length > 0 ? array : null
            }
        }
        else return null
    }
    useEffect(() => {
        axios.get(ApplicationConfig.apiUrl + 'v2/modelunique?mh=' + model.hash + '&uh=' + environment.userhash,
        {crossdomain: true,
            'headers': {
                'X-AUTH-TOKEN': getToken(),
            }
        }).then((response) => {
            setMyModel(response.data.data);
        }).catch((response) => {
            setMyModel(null);
        })
    }, [])

    useEffect(() => {
        if (myModel !== null) {
            const labels = Object.keys(myModel).filter((key) => {
                const regex = /field[0-9]+label/gi;
                return regex.test(key) && myModel[key] != null;
            });

            let fields = labels.map((label, index) => {
                let formatoptions = myModel['field' + (index + 1) + 'formatoptions'] || {};
                if (typeof formatoptions !== 'object') {
                    formatoptions = JSON.parse(formatoptions);
                }

                if(myModel['field' + (index + 1) + 'format'] === "select"){
                    if(typeof formatoptions.presel !== "undefined" && formatoptions.presel !== "" && formatoptions.presel !== "[]" && formatoptions.presel.length !== 0){
                        formatoptions.presel = formatoptions.presel.toString().split(";")
                    }else{
                        formatoptions.presel = [];
                    }
                }

                if(myModel['field' + (index + 1) + 'format'] === "table"){
                    if(typeof formatoptions.presel !== "undefined" && formatoptions.presel !== "" && formatoptions.presel !== "[]" && formatoptions.presel.length !== 0){
                        formatoptions.presel = formatoptions.presel.toString().split(";")
                    }else{
                        formatoptions.presel = [];
                    }
                }

                let fieldFormat = myModel['field' + (index + 1) + 'format'].toUpperCase();
                // Vérifier les présélections
                if ( fieldFormat === 'SELECT') {

                    if( myModel['field' + (index + 1) + 'formatoptions'].multiselect && !myModel['field' + (index + 1) + 'formatoptions'].editable){
                        fieldFormat = "MULTI_SELECT";
                    }else if(!myModel['field' + (index + 1) + 'formatoptions'].multiselect && !myModel['field' + (index + 1) + 'formatoptions'].editable){
                        fieldFormat = "SINGLE_SELECT";

                    }else if(myModel['field' + (index + 1) + 'formatoptions'].multiselect && myModel['field' + (index + 1) + 'formatoptions'].editable){
                        fieldFormat = "FREE_MULTI_SELECT";

                    }else if(!myModel['field' + (index + 1) + 'formatoptions'].multiselect && myModel['field' + (index + 1) + 'formatoptions'].editable){
                        fieldFormat = "FREE_SINGLE_SELECT";
                    }

                }

                if (fieldFormat === 'TABLE') {

                    if(myModel['field' + (index + 1) + 'formatoptions'].multiselect && !myModel['field' + (index + 1) + 'formatoptions'].editable){
                        fieldFormat = "MULTI_SELECT_TABLE";
                    }else if(!myModel['field' + (index + 1) + 'formatoptions'].multiselect && !myModel['field' + (index + 1) + 'formatoptions'].editable){
                        fieldFormat = "SINGLE_SELECT_TABLE";

                    }else if(myModel['field' + (index + 1) + 'formatoptions'].multiselect && myModel['field' + (index + 1) + 'formatoptions'].editable){
                        fieldFormat = "FREE_MULTI_SELECT_TABLE";

                    }else if(!myModel['field' + (index + 1) + 'formatoptions'].multiselect && myModel['field' + (index + 1) + 'formatoptions'].editable){
                        fieldFormat = "FREE_SINGLE_SELECT_TABLE";
                    }

                }
                let obj = {
                    newField: false,
                    dragid : 'item-' + index,
                    key: index + 1,
                    id: index + 1,
                    position : formatTypes[fieldFormat],
                    isEncodable : !!myModel['field' + (index + 1) + 'encodable'] && myModel['field' + (index + 1) + 'encodable'] === 1,
                    isVisible : !!myModel['field' + (index + 1) + 'visible'] && myModel['field' + (index + 1) + 'visible'] === 1,
                    label: myModel['field' + (index + 1) + 'label'],
                    format: myModel['field' + (index + 1) + 'format'],
                    options: {
                        mandatory: !!myModel['field' + (index + 1) + 'mandatory'],
                        ...formatoptions
                    },
                    formatoptions: formatoptions
                }
                return obj
            });

            setUpdateModelLayout(fields)
        }
    }, [myModel])

    const [nameModel, ] = useState(model.label);
    const [hash, ] = useState(!!environment ? environment.userhash : null);
    const [generateOptions, setGenerateOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedLayout, setSelectedLayout] = useState(null);
    const [createdLayout, setCreatedLayout] = useState(null);
    const [creation, setCreation] = useState(false);
    const [openPresel, setOpenPresel] = useState(null);
    const [openSubPresel, setOpenSubPresel] = useState(null);

    const handleExitModel = () => {
        props.history.push("/models");
    };

    const handleCreateExitModel = () => {
        confirmAlert({
            title: t('global.plugnotes'),
            message: t('global.exit-saving-model-layout'),
            buttons: [
                {
                    label: t('global.ok'),
                    onClick: () => {
                        handleSaveLayout();
                    }
                },
                {
                    label: t('global.cancel'),
                    onClick: () => {
                        props.history.push("/models");
                    }
                },
            ]
        });
    };

    const handleDeleteLayoutModel = () => {
        confirmAlert({
            title: t('global.plugnotes'),
            message: t('global.delete'),
            buttons: [
                {
                    label: t('global.ok'),
                    onClick: () => {
                        handleDeleteLayout();
                    }
                },
                {
                    label: t('global.cancel'),
                },
            ]
        });
    }

    const handleUpdateExitModel = () => {
        confirmAlert({
            title: t('global.plugnotes'),
            message: t('global.exit-saving-model-layout'),
            buttons: [
                {
                    label: t('global.ok'),
                    onClick: () => {
                        handleUpdateLayout();
                    }
                },
                {
                    label: t('global.cancel'),
                    onClick: () => {
                        props.history.push("/models");
                    }
                },
            ]
        });
    };

    const handleSaveLayout = () => {
        let payload = {...createdLayout};
        payload.modelhash = model.hash;
        payload.userhash = hash;
        payload.uh = hash;
        payload.mid = model.id;

        for (let i = 1; i <= 15 ; i++) {

            if (payload.hasOwnProperty('field' + i)) {
                payload['field' + i] = JSON.stringify(payload['field'+i]);
            }

            if (payload.hasOwnProperty('field' + i + 'formatoptions')) {
                payload['field' + i + 'formatoptions'] = JSON.stringify(payload['field'+i+'formatoptions']);
            }

        }

        props.attemptPostLayout(payload);
        setCreation(false)
        setCreatedLayout(null);
        setSelectedOption('');
        setUpdated(false);
    }

    const handleUpdateLayout = () => {
        let payload = {...selectedLayout};

        payload.modelhash = model.hash;
        payload.userhash = hash;
        payload.uh = hash;
        payload.mid = model.id;

        for (let i = 1; i <= 15 ; i++) {

            if (payload.hasOwnProperty('field' + i)) {
                payload['field' + i] = JSON.stringify(payload['field'+i]);
            }

            if (payload.hasOwnProperty('field' + i + 'formatoptions')) {
                payload['field' + i + 'formatoptions'] = JSON.stringify(payload['field'+i+'formatoptions']);
            }

        }

        props.attemptPostLayout(payload);

        setCreation(false);
        setSelectedLayout(null);
        setSelectedOption('');
    }

    const handleDeleteLayout = () => {
        props.attemptDeleteLayout(model.id,hash,selectedLayout.id);
        setCreation(false);
        setSelectedLayout(null);
        setSelectedOption('');
    }

    const handleChangeOption = async (newValue) => {
        setCreation(false);
        setSelectedOption(newValue);
        let choice = props.list[newValue.indice];

        for (let i = 1; i <= 15 ; i++) {

            if (choice.hasOwnProperty('field' + i)) {
                choice['field' + i] = choice['field' + i] !== "" ? JSON.parse(choice['field'+i]) : null
            }

            if (choice.hasOwnProperty('field' + i + 'formatoptions')) {
                choice['field' + i + 'formatoptions'] = choice['field' + i + 'formatoptions'] !== "" ? JSON.parse(choice['field'+i+'formatoptions']) : null
            }
            let format = myModel['field' + i + 'format']
            if (hasSubfields.includes(format) && !choice.hasOwnProperty('field' + i + 'formatoptions')) {
                choice['field' + i + 'formatoptions'] = getFieldOptions(updateModelLayout[i - 1])
            }
        }

        await setSelectedLayout(choice);
    }

    const handleCreateLayout = async () => {
        setSelectedLayout(null);
        setSelectedOption('');
        setCreation(true);
        await setCreatedLayout({
            "layout" : {value : 1 , label : <div><img alt='' src={Images.fr} height="30px" width="30px"/></div>},
            "layoutId" : 1,
            "description" : "",
            "identifiant" : "",
            "label" : nameModel,
            "field1" : {"visible" : true, "encodable" : true},
            "field2" : {"visible" : true, "encodable" : true},
            "field3" : {"visible" : true, "encodable" : true},
            "field4" : {"visible" : true, "encodable" : true},
            "field5" : {"visible" : true, "encodable" : true},
            "field6" : {"visible" : true, "encodable" : true},
            "field7" : {"visible" : true, "encodable" : true},
            "field8" : {"visible" : true, "encodable" : true},
            "field9" : {"visible" : true, "encodable" : true},
            "field10" : {"visible" : true, "encodable" : true},
            "field11" : {"visible" : true, "encodable" : true},
            "field12" : {"visible" : true, "encodable" : true},
            "field13" : {"visible" : true, "encodable" : true},
            "field14" : {"visible" : true, "encodable" : true},
            "field15" : {"visible" : true, "encodable" : true},
            "field1formatoptions": getFieldOptions(updateModelLayout[0]),
            "field2formatoptions": getFieldOptions(updateModelLayout[1]) ,
            "field3formatoptions": getFieldOptions(updateModelLayout[2]) ,
            "field4formatoptions": getFieldOptions(updateModelLayout[3]) ,
            "field5formatoptions": getFieldOptions(updateModelLayout[4]) ,
            "field6formatoptions": getFieldOptions(updateModelLayout[5]) ,
            "field7formatoptions": getFieldOptions(updateModelLayout[6]) ,
            "field8formatoptions": getFieldOptions(updateModelLayout[7]) ,
            "field9formatoptions": getFieldOptions(updateModelLayout[8]) ,
            "field10formatoptions": getFieldOptions(updateModelLayout[9]) ,
            "field11formatoptions": getFieldOptions(updateModelLayout[10]) ,
            "field12formatoptions": getFieldOptions(updateModelLayout[11]) ,
            "field13formatoptions": getFieldOptions(updateModelLayout[12]) ,
            "field14formatoptions": getFieldOptions(updateModelLayout[13]) ,
            "field15formatoptions": getFieldOptions(updateModelLayout[14]) ,
        });
    }

    const handleUpdateLayoutEffect = async () => {
        let _selectedLayout = {...selectedLayout}
        updateModelLayout.forEach((field, findex) => {
            if (field.format === 'select' || field.format === 'presel') {
                let formatOptions = selectedLayout['field' + (findex + 1) + 'formatoptions'];
                if (formatOptions.length !== field.formatoptions.presel.length) {
                    let updatedField = field.formatoptions.presel.map((presel, id) => formatOptions[id] || {visible : true, encodable : true});
                    _selectedLayout['field' + (findex + 1) + 'formatoptions'] = updatedField;
                }
            } else {
                let options = field.formatoptions
                if (isDefined(options)) {
                    let subfields = isDefined(options.lines) ? options.lines : options
                    if (_selectedLayout.hasOwnProperty('field' + (findex + 1) + 'formatoptions')) {
                        let updatedSubfields = []
                        if (subfields?.length > 0) {
                            subfields.forEach((subfield) => {
                                let existingField = selectedLayout['field' + (findex + 1) + 'formatoptions'].find(field => field.id === subfield.id);
                                if(typeof existingField !== "undefined"){
                                    if ((subfield.format === 'presel' || subfield.format === 'select') && !existingField.hasOwnProperty('presels')) {
                                        let presels = {};
                                        subfield.presel.forEach((presel, id) => presels[id] = {visible : true, encodable : true});
                                        existingField['presels'] = presels;
                                    }
                                    updatedSubfields.push((subfield.format === 'presel' || subfield.format === 'select')
                                        ? { id: subfield.id, label: existingField['label'] || {visible : true , encodable : true}, presels: existingField.presels }
                                        : { id: subfield.id, label: existingField['label'] || {visible : true , encodable : true} })
                                }
                            });
                        }
                        _selectedLayout['field' + (findex + 1) + 'formatoptions'] = updatedSubfields
                    }
                }
            }
        })
        setSelectedLayout(selectedLayout)
        setUpdated(true)
    }

    function handleTogglePresel(id) {
        setOpenPresel(id === openPresel ? null : id)
    }

    function handleToggleSubPresel(id) {
        setOpenSubPresel(id === openSubPresel ? null : id);
    }

    useEffect(() => {
        let options = [];
        props.list.forEach((i, index) => {
            options.push({
                value : i.id ,
                label : <div>{i.label} - {i.identifiant}</div>,
                description : <div>{i.description}</div>,
                indice: index,
                layout : i.layoutId
            });
        });
        setGenerateOptions(options)

    }, [props.list]);


    useEffect(() => {
        props.attemptGetAllLayouts(model.id, hash)
    }, [hash]);

    useEffect(() => {
        if (selectedLayout !== null && !updated) handleUpdateLayoutEffect()
    }, [selectedLayout])

    const RenderCheckboxOption = (props) => {

        const baseLayout = creation === true ? createdLayout : selectedLayout;

        const [checkedVisibility, setCheckedVisibility] = useState(baseLayout['field' + props.field.key].visible);
        const [checkedEncodability, setCheckedEncodability] = useState(baseLayout['field' + props.field.key].editable);

        const handleChangeVisibility = (value, field, index) => {
            baseLayout['field' + field.key].visible = value;
            if(checkedEncodability && !value){
                baseLayout['field' + field.key].editable = value;
            }
            if (creation === true) setCreatedLayout({ ...baseLayout });
            else setSelectedLayout({ ...baseLayout });
            if(checkedEncodability && !value){
                setCheckedVisibility(value);
            }
        }

        const handleChangeEncodability = (value, field, index) => {
            baseLayout['field' + field.key].editable = value;
            if(!checkedVisibility && value){
                baseLayout['field' + field.key].visible = true;
            }
            if (creation === true) setCreatedLayout({ ...baseLayout });
            else setSelectedLayout({ ...baseLayout });
            setCheckedEncodability(value);
            if(!checkedVisibility && value){
                setCheckedVisibility(true);
            }
        }

        return (
            <div className="fullWidth">
                <TextField
                    disabled={true}
                    fullWidth={true}
                    id="filled-helperText"
                    label={(props.index + 1) + ' - ' + props.field.label}
                    variant="filled"
                />
                <div>
                        <div className={classes.visibleBox} onClick={() => {handleChangeVisibility(!checkedVisibility, props.field, props.index)}}>
                            <Checkbox
                                style={{color: colors.main, width: '30px', marginRight: '5px'}}
                                className={'green'}
                                checked={checkedVisibility}
                                onChange={() => {handleChangeVisibility(!checkedVisibility, props.field, props.index)}}
                            />
                            <span className={classes.visibleText}>{ t('models.layout-make-field-visible-default') }</span>
                        </div>

                    <div className={classes.visibleBox} onClick={() => {handleChangeEncodability(!checkedEncodability, props.field, props.index)}}>
                        <Checkbox
                            style={{color: colors.main, width: '30px', marginRight: '5px'}}
                            className={'green'}
                            checked={checkedEncodability}
                            onChange={() => {handleChangeEncodability(!checkedEncodability, props.field, props.index)}}
                        />
                        <span className={classes.visibleText}>{ t('models.layout-make-field-encodable-default') }</span>
                    </div>
                </div>
            </div>

        )
    }

    const RenderCheckboxFormatOption = (props) => {

        const baseLayout = creation === true ? createdLayout : selectedLayout;

        const [checkedVisibility, setCheckedVisibility] = useState(baseLayout['field' + props.field.key + 'formatoptions'][props.subindex].visible);
        const [checkedEncodability, setCheckedEncodability] = useState(baseLayout['field' + props.field.key + 'formatoptions'][props.subindex].editable);

        const handleChangeVisibility = (value, field, index) => {
            baseLayout['field' + props.field.key + 'formatoptions'][props.subindex].visible = value;
            if(checkedEncodability && !value){
                baseLayout['field' + props.field.key + 'formatoptions'][props.subindex].editable = value;
            }

            if (creation === true) setCreatedLayout({ ...baseLayout });
            else setSelectedLayout({ ...baseLayout });
            setCheckedVisibility(value);
            if(checkedEncodability && !value){
                setCheckedEncodability(value);
            }

        }

        const handleChangeEncodability = (value, field, index) => {
            baseLayout['field' + props.field.key + 'formatoptions'][props.subindex].editable = value;
            if(!checkedVisibility && value){
                baseLayout['field' + props.field.key + 'formatoptions'][props.subindex].visible = true;
            }
            if (creation === true) setCreatedLayout({ ...baseLayout });
            else setSelectedLayout({ ...baseLayout });
            setCheckedEncodability(value);
            if(!checkedVisibility && value){
                setCheckedVisibility(true);
            }
        }

        return (
            <div className="fullWidth" key={'field-' + props.index + '-formatoptions-' + props.subindex }>
                <TextField
                    className={classes.subfield}
                    disabled={true}
                    fullWidth={true}
                    id="filled-helperText"
                    label={typeof props.subfield.label !== "undefined" ?  props.subfield.label : props.subfield}
                    variant="filled"
                />
                <div>
                    <div className={classes.visibleBox} onClick={() => {handleChangeVisibility(!checkedVisibility, props.field, props.index)}}>
                        <Checkbox
                            className={'green'}
                            style={{color: colors.main, width: '30px', marginRight: '5px'}}
                            checked={checkedVisibility}
                            onChange={() => {handleChangeVisibility(!checkedVisibility, props.field, props.index)}}
                        />
                        <span className={classes.visibleText}>{ t('models.layout-make-field-visible-default') }</span>
                    </div>

                    <div className={classes.visibleBox} onClick={() => {handleChangeEncodability(!checkedEncodability, props.field, props.index)}}>
                        <Checkbox
                            className={'green'}
                            style={{color: colors.main, width: '30px', marginRight: '5px'}}
                            checked={checkedEncodability}
                            onChange={() => {handleChangeEncodability(!checkedEncodability, props.field, props.index)}}
                        />
                        <span className={classes.visibleText}>{ t('models.layout-make-field-encodable-default') }</span>
                    </div>
                </div>
            </div>

        )
    }

    const RenderCheckboxFormatOptionPresel = (props) => {
        const baseLayout = creation === true ? createdLayout : selectedLayout;


        const [checkedVisibility, setCheckedVisibility] = useState(baseLayout['field' + (props.index + 1) + 'formatoptions'][props.subindex.toString()]["presels"][props.pindex].visible);
        const [checkedEncodability, setCheckedEncodability] = useState(baseLayout['field' + (props.index + 1) + 'formatoptions'][props.subindex.toString()]["presels"][props.pindex].editable);

        const handleChangeVisibility = (value, field, index) => {
            baseLayout['field' + (props.index + 1) + 'formatoptions'][props.subindex.toString()]["presels"][props.pindex].visible = value;
            if(checkedEncodability && !value){
                baseLayout['field' + (props.index + 1) + 'formatoptions'][props.subindex.toString()]["presels"][props.pindex].editable = value;
            }
            if (creation === true) setCreatedLayout({ ...baseLayout });
            else setSelectedLayout({ ...baseLayout });
            setCheckedVisibility(value);
            if(checkedEncodability && !value){
                setCheckedEncodability(value);
            }
        }
        const handleChangeEncodability = (value, field, index) => {
            baseLayout['field' + (props.index + 1) + 'formatoptions'][props.subindex.toString()]["presels"][props.pindex].editable = value;
            if(!checkedVisibility && value){
                baseLayout['field' + (props.index + 1) + 'formatoptions'][props.subindex.toString()]["presels"][props.pindex].visible = true;
            }
            if (creation === true) setCreatedLayout({ ...baseLayout });
            else setSelectedLayout({ ...baseLayout });
            setCheckedEncodability(value);
            if(!checkedVisibility && value){
                setCheckedVisibility(true);
            }
        }

        return (
            <div className={classes.subfieldCtn} key={'field-' + props.index + '-subfield-' + props.subindex + '-presel-' + props.pindex}>
                <div className={classes.visibleBox} onClick={() => {handleChangeVisibility(!checkedVisibility, props.field, props.index)}}>
                    <Checkbox
                        className={'green'}
                        style={{color: colors.main, width: '30px', marginRight: '5px'}}
                        checked={checkedVisibility}
                        onChange={() => {handleChangeVisibility(!checkedVisibility, props.field, props.index)}}
                    />
                    <span className={classes.visibleText}>{ t('models.layout-make-field-visible-default') }</span>
                </div>

                <div className={classes.visibleBox} onClick={() => {handleChangeEncodability(!checkedEncodability, props.field, props.index)}}>
                    <Checkbox
                        className={'green'}
                        style={{color: colors.main, width: '30px', marginRight: '5px'}}
                        checked={checkedEncodability}
                        onChange={() => {handleChangeEncodability(!checkedEncodability, props.field, props.index)}}
                    />
                    <span className={classes.visibleText}>{ t('models.layout-make-field-encodable-default') }</span>
                </div>
            </div>
        )
    }

    const renderField = (field, index) => {
        let subfields = []
        if (field.options?.lines !== null && field.options?.lines !== undefined) subfields = field.options.lines;
        else if (field.format === "tablemulti") subfields = field.formatoptions;
        else if (field.format === "select") subfields = field.formatoptions.presel;

        return (
            <div className={classes.fieldCtn} key={index}>
                <div className={classes.fieldWithSubfieldsCtn}>
                    <RenderCheckboxOption field={field} index={index}/>
                    {
                        subfields.length > 0 &&
                        <div onClick={() => handleTogglePresel(index)} className={classes.openSubfieldsBtn}>
                            Layout sub fields
                        </div>
                    }
                </div>
                {
                    openPresel === index && subfields.length > 0 &&
                    <div className={classes.subfieldsCtn}>
                        {
                            field.format === "select" ?
                                subfields.map((subfield, subindex) => {
                                    return (
                                        <div className={classes.subfieldCtn} key={'sub' + index + '-' + subindex}>
                                            <span className={classes.subfieldNb}>{subindex + 1}</span>
                                            <RenderCheckboxFormatOption field={field} index={index} subindex={subindex} subfield={subfield}/>
                                        </div>)
                                })
                                :
                                subfields.map((subfield, subindex) => {
                                    return (
                                        <div>
                                            <div className={classes.subfieldCtn} key={'sub' + index + '-' + subindex}>
                                                <span className={classes.subfieldNb}>{subindex + 1}</span>
                                                <RenderCheckboxFormatOption field={field} index={index} subindex={subindex} subfield={subfield}/>
                                                {
                                                    (subfield.format === 'select' || subfield.format === 'presel') &&
                                                    <div onClick={() => handleToggleSubPresel(subindex)} className={classes.openSubfieldsBtn}>
                                                        Layout presels
                                                    </div>
                                                }
                                            </div>
                                            {
                                                openSubPresel === subindex &&
                                                renderSubPresels(subfield, index, subindex)
                                            }
                                        </div>
                                    )
                                })}
                    </div>
                }
            </div>
        );
    }

    const renderSubPresels = (field, index, subindex) => {
        return (
        <div className={classes.subPreselsCtn} >
            {
                field.presel
                    .map((presel, pindex) => {
                        return (
                            <div className={classes.subfieldCtn} key={'field-' + index + '-subfield-' + subindex + '-presel-' + pindex}>
                                <span className={classes.subPreselNb}>{subindex + 1}-{pindex + 1}</span>
                                <TextField
                                    className={classes.subfield}
                                    disabled={true}
                                    fullWidth={true}
                                    id="filled-helperText"
                                    label={presel}
                                    variant="filled"
                                />
                                <RenderCheckboxFormatOptionPresel pindex={pindex} index={index} subindex={subindex} presel={presel}/>
                            </div>
                        )
                    })}
        </div>);
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Header>
                    <div className="sous-header-without-height p1 grey">
                        <div className="text_align">
                            <h3 className="">{t('common:models.form-layout')}</h3>
                        </div>
                    </div>
                    <div className="sous-header_no_restrict m2 grey">
                        <div className="sous-header_content">
                            <div>
                                {
                                    selectedOption && selectedOption.value !== '' && creation === false &&
                                    <span id="icon-close" className="material-icons md-30" onClick={handleUpdateExitModel}>
                                        keyboard_arrow_left
                                    </span>
                                }

                                {
                                    creation &&
                                    <span id="icon-close" className="material-icons md-30" onClick={handleCreateExitModel}>
                                        keyboard_arrow_left
                                    </span>
                                }

                                {selectedOption === '' && creation === false &&
                                    <span id="icon-close" className="material-icons md-30" onClick={handleExitModel}>
                                        keyboard_arrow_left
                                    </span>
                                }
                            </div>

                            <div className="div_width_50">
                                <Input
                                    id="standard-full-width"
                                    label="Label"
                                    disabled={true}
                                    style={{ margin: 8 }}
                                    placeholder={t('common:models.form-title')}
                                    helpertext="Full width!"
                                    fullWidth={true}
                                    margin="none"
                                    inputlabelprops={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className: classes.fieldRight
                                    }}
                                    value={nameModel}
                                />
                            </div>
                            <div>

                                {
                                    selectedOption && selectedOption.value !== '' && creation === false &&
                                    <button className="button" onClick={handleDeleteLayoutModel}>{t('common:global.delete')}</button>
                                }

                                {
                                    selectedOption && selectedOption.value !== '' && creation === false &&
                                    <button className="button" onClick={handleUpdateExitModel}>{t('common:multiple.save')}</button>
                                }

                                {
                                    creation === true && createdLayout !== null &&
                                    <button className="button" onClick={handleCreateExitModel}>{t('common:multiple.save')}</button>
                                }
                            </div>
                        </div>

                        <div className="sous-header_content grey">
                            <div className="line flex_style">
                            </div>
                        </div>

                        <div className="popup_wrapper_content">
                            <div className="flex_display_direction_column_centered">
                                <div className="fullWidth">
                                    <Select
                                        fullWidth
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        placeholder={t('common:models.update-layout')}
                                        options={generateOptions}
                                        value={selectedOption}
                                        onChange={handleChangeOption}
                                        styles={selectStyles}
                                    />
                                </div>
                                {
                                    !selectedOption && creation === false &&
                                    <div className={classes.buttonCtn}>
                                        <button className={`button ${classes.createBtn}`} onClick={handleCreateLayout}>{t('common:models.create-layout')}</button>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Header>
                <Content className={`${classes.contentModel} scrollbar-hidden`}>
                    <div className={classes.subtitleCtn}>
                        {
                            selectedOption && selectedOption.value !== '' && creation === false && selectedLayout !== null &&
                            <TextField
                                fullWidth={true}
                                id="filled-helperText-title"
                                label={t('common:global.identifiant')}
                                value={selectedLayout.identifiant}
                                onChange={(event) => {
                                    selectedLayout.identifiant = event.target.value;
                                    setSelectedLayout({ ...selectedLayout });
                                }}
                                variant="filled"
                            />
                        }

                        {
                            creation === true && createdLayout !== null &&
                            <TextField
                                fullWidth={true}
                                id="filled-helperText-title"
                                label={t('common:global.identifiant')}
                                value={createdLayout.identifiant}
                                onChange={(event) => {
                                    createdLayout.identifiant = event.target.value;
                                    setCreatedLayout({ ...createdLayout });
                                }}
                                variant="filled"
                                />
                        }
                    </div>

                    <br />

                    <div className={classes.subtitleCtn}>
                        {
                            selectedOption && selectedOption.value !== '' && creation === false && selectedLayout !== null &&
                            <TextField
                                fullWidth={true}
                                id="filled-helperText-title"
                                label={t('common:global.description')}
                                value={selectedLayout.description}
                                onChange={(event) => {
                                    selectedLayout.description = event.target.value;
                                    setSelectedLayout({ ...selectedLayout });
                                }}
                                variant="filled"
                            />
                        }

                        {
                            creation === true && createdLayout !== null &&
                            <TextField
                                fullWidth={true}
                                id="filled-helperText-title"
                                label={t('common:global.description')}
                                value={createdLayout.description}
                                onChange={(event) => {
                                    createdLayout.description = event.target.value;
                                    setCreatedLayout({ ...createdLayout });
                                }}
                                variant="filled"
                            />
                        }
                    </div>

                    <br />
                    {
                        selectedOption && selectedOption.value !== '' && creation === false && selectedLayout !== null &&
                        <div className={classes.fieldCtn}>
                            <TextField
                                fullWidth={true}
                                id="filled-helperText-title"
                                label={t('common:models.form-title')}
                                defaultValue={selectedLayout.label}
                                disabled={true}
                                variant="filled"
                                />
                        </div>
                    }

                    {
                        creation === true && createdLayout !== null &&
                        <div className={classes.fieldCtn}>
                            <TextField
                                fullWidth={true}
                                id="filled-helperText-title"
                                label={t('common:models.form-title')}
                                defaultValue={createdLayout.label}
                                disabled={true}
                                variant="filled"
                            />
                        </div>
                    }

                    <div>
                        {
                            selectedOption && selectedOption.value !== '' && creation === false &&
                            updateModelLayout
                                .map((field, index) => renderField(field, index))
                        }

                        {
                            creation === true && createdLayout !== null &&
                            updateModelLayout
                                .map((field, index) => renderField(field, index))
                        }
                    </div>
                </Content>
            </main>
        </div>
  );
}

// redux providing state takeover
const mapStateToProps = (state) => {

    return {
        user : state.user,
        token : state.user.token,
        list : !! state.layout ? state.layout.list : [],
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptPostLayout: (payload) => dispatch(postLayoutByModelRequest(payload)),
        attemptDeleteLayout: (mid,userhash, id) => dispatch(deleteDeleteLayoutByModelRequest(mid,userhash, id)),
        attemptGetAllLayouts: (modelhash, userhash) => dispatch(getAllLayoutsByModelRequest(modelhash, userhash)),
        attemptGetOneLayout: (modelhash, userhash,identifiant) => dispatch(getOneLayoutByModelRequest(modelhash, userhash, identifiant)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelLayout)
