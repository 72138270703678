export const MailExportWithoutDataPluglinkTemplateEN = ({ }) => {
    return `Hello,\n
    By opening the attached file in your table (Excel,..), you can linked Plugnotes with F28 format in the folder mentioned above.\n
    Please observe the following instructions:\n
\n
        1. Read and follow the instructions specific to each format given in the header of the columns,\n
        2. Do not modify or delete header lines,\n
        3. It is necessary to keep at least the first 5 characters of the template name (A0xx_) and to keep the same format (XLS - Workbook excel 97-2003),\n
        4. Re-import the completed file via the 3rd icon (with the 2 arrows) of the original plugform tools. (Left)\n
\n
    We remain at your disposal in case of questions.\n
    Good job!\n
    The Plugnotes team`
};




