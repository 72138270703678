import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Input, Switch } from '@material-ui/core'
import { ColorCheckbox } from '../custom/CustomComponents'
import colors from '../../assets/themes/Colors'

const useStyles = makeStyles(() => ({
  mainField: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: 40,
    paddingTop: 12
  },
  inputField: {
    '& .MuiInputBase-input': {
      paddingBottom: '0px !important',
      paddingTop: '0px !important'
    },
    maxWidth: '2rem',
    paddingLeft: 10
  },
  selected: {
    fontSize: 'xx-large',
    textAlign: 'center',
    verticalAlign: 'middle',
    marginTop: '0.6rem',
    color: colors.mainButton
  },
  unselected: {
    fontSize: 'x-large',
    textAlign: 'center',
    verticalAlign: 'middle',
    marginTop: '0.6rem',
    color: colors.unselected
  },
  subContentDecimalCtn: {
    borderRadius: '5px',
    border: '1px solid #58c3c1',
    padding: '5px',
    marginBottom: 10
  },
  subContentThousandCtn: {
    borderRadius: '5px',
    border: '1px solid #58c3c1',
    padding: '5px',
    marginBottom: 10
  },
  charCtn: {
    cursor: 'pointer',
    lineHeight: '16px',
    padding: 4
  }
}))

const FormatNumericConstructorComponent = (props) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const [isActiveThousandSeparator, setIsActiveThousandSeparator] = useState(props.field.options?.setup?.septhousand || false)
  const [decimalSeparator, setDecimalSeparator] = useState(props.field.options?.setup?.sepdecimal || ',')
  const [thousandSeparator, setThousandSeparator] = useState(props.field.options?.setup?.septhousand || '.')

  const handleChangeDecimalsQuantity = (value) => {
    let model = [...props.model]
    model[props.index].options.setup.numberdecimals = value.target.value
    props.setModel(model)
  }

  const handleChangeSwitchDecimalSeparator = (value) => {
    let decimalSep
    let thousandSep
    if (!value) {
      decimalSep = ','
      thousandSep = !isActiveThousandSeparator ? false : '.'
    } else {
      decimalSep = '.'
      thousandSep = !isActiveThousandSeparator ? false : ','
    }
    let model = [...props.model]
    model[props.index].options.setup.sepdecimal = decimalSep
    model[props.index].options.setup.septhousand = thousandSep
    setDecimalSeparator(decimalSep)
    setThousandSeparator(thousandSep)
    props.setModel(model)
  }

  const handleChangeSwitchThousandSeparator = (value) => {
    let thousandSeparator
    let decimalSeparator
    if (!value) {
      thousandSeparator = '.'
      decimalSeparator = ','
    } else {
      thousandSeparator = ','
      decimalSeparator = '.'
    }
    let model = [...props.model]
    model[props.index].options.setup.septhousand = thousandSeparator
    model[props.index].options.setup.sepdecimal = decimalSeparator
    setDecimalSeparator(decimalSeparator)
    setThousandSeparator(thousandSeparator)
    props.setModel(model)
  }

  const handleToggleThousandSeparator = () => {
    setIsActiveThousandSeparator((curr) => {
      let model = [...props.model]
      let separator = decimalSeparator === '.' ? ',' : '.'
      model[props.index].options.setup = {
        ...model[props.index].options.setup,
        septhousand: curr ? false : separator
      }
      props.setModel(model)
      return !curr
    })
  }

  return (
    <div className={classes.mainField}>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
        <div className={classes.subContentDecimalCtn}>
          <div style={{ textAlign: 'center' }}>
            <span style={{ fontSize: 'small' }}>{t('setup.nb-decimal')}</span>
            <div style={{ display: 'inline-block', float: 'right', alignItems: 'center' }}>
              <div className={classes.inputField}>
                <Input id='decimal-qty' type={'number'} placeholder=' ∞ ' disableUnderline={false} size='small' margin='none' inputProps={{ style: { textAlign: 'center' } }} onChange={handleChangeDecimalsQuantity} style={{ textAlign: 'center' }} value={!props.field.options?.setup?.hasOwnProperty('numberdecimals') ? '2' : props.field.options?.setup?.numberdecimals} />
              </div>
            </div>
          </div>
          {props.field.options?.setup?.numberdecimals !== 0 && (
            <div style={{ display: 'flex', flexDirection: 'row', gap: 8, justifyContent: 'space-evenly' }}>
              <div className={classes.charCtn} onClick={() => handleChangeSwitchDecimalSeparator(false)}>
                <span className={decimalSeparator === ',' ? classes.selected : classes.unselected}>,</span>
              </div>
              <Switch color='default' checked={decimalSeparator === '.'} onChange={(e) => handleChangeSwitchDecimalSeparator(e.target.checked)} />
              <div className={classes.charCtn} onClick={() => handleChangeSwitchDecimalSeparator(true)}>
                <span className={decimalSeparator === '.' ? classes.selected : classes.unselected}>.</span>
              </div>
            </div>
          )}
        </div>
        <div className={classes.subContentThousandCtn}>
          <div style={{ textAlign: 'center' }}>
            <div onClick={handleToggleThousandSeparator}>
              <span style={{ fontSize: 'small', cursor: 'pointer' }}>{t('setup.sep-thousand')}</span>
              <div style={{ display: 'inline-block', float: 'right' }}>
                <ColorCheckbox checked={isActiveThousandSeparator} />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {isActiveThousandSeparator && (
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                  <div className={classes.charCtn} onClick={() => handleChangeSwitchThousandSeparator(false)}>
                    <span className={thousandSeparator === '.' ? classes.selected : classes.unselected}>.</span>
                  </div>
                  <Switch color='default' checked={thousandSeparator === ','} onChange={(e) => handleChangeSwitchThousandSeparator(e.target.checked)} />
                  <div className={classes.charCtn} onClick={() => handleChangeSwitchThousandSeparator(true)}>
                    <span className={thousandSeparator === ',' ? classes.selected : classes.unselected}>,</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

// redux providing state takeover
const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(FormatNumericConstructorComponent)
