import React, {useEffect, useMemo, useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import colors from "../../assets/themes/Colors";
import {postExtDataReset} from "../../actions/extdata";
import {convertersAlphaToNumber, devLog, isDefined, zeroPad} from "../../utils/utils";
import {HyperFormula} from "hyperformula";
import ModalListExtDataMultipleComponent from "../modals/extdata/ModalListExtDataMultipleComponent";
import {createEmptyRow, getTotalForSpecificColumnIndex, getLabelWithSpecialChar, isRowLimitReached, isVisibleInCSV, populateRowWithDefaultValues} from "../../utils/Fields";
import { getTableView, getToken } from "../../utils/Common";
import {postSendMailV3Request} from "../../actions/utils";
import {trackPromise} from "react-promise-tracker";
import axios from "axios";
import ApplicationConfig from "../../config/ApplicationConfig";
import { Tooltip } from "@material-ui/core";
import { ModalTablemultiTableViewComponent } from "../modals/tablemulti/ModalTablemultiTableViewComponent";
import moment from "moment/moment";
import { toast } from "react-toastify";
import { putAnnexeCommentModalRequest } from "../../actions/annexe";
import { PlugTooltip } from "../custom/CustomComponents";
import {isNull} from "lodash";


const useStyles = makeStyles((theme) => ({
	mainCtn: {
		width: '100%',
		marginTop: -30,
		overflowY: 'visible',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
	},
    urlButton:{
        /* border: '1px red solid', */
        display: 'inline-block',
        color: "white",
        backgroundColor: "#58c3c1",
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    mainPhoneContainer:{
        marginTop : '20px',
    },
    phoneContainer:{
        border: '1px black solid',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    mailButton:{
        /* border: '1px red solid', */
        display: 'inline-block',
        color: "white",
        backgroundColor: colors.mainButton,
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    unregisteredBtn: {
        display: 'inline-block',
        color: "white",
        backgroundColor: colors.bloodOrange,
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    textFieldArea: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        // marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        paddingLeft: '10px',
        backgroundColor: '#FFFFFF',
        fontSize: '14px',
        width: '100%',
        border: '1px solid #ccc',
        MozBorderRadius : '5px',
        WebkitBorderRadius: '5px',
    },
    inputArea: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    table: {
        //maxWidth : 'fit-content',
        // width: "99%",
        userSelect: 'none',
        "&::-youbkit-user-select": {
            color: 'red',
            backgroundColor: 'yellow',
        },

    },
    tableRoot: {
        // width: "99%", //it was 100%
        marginTop: 8,
        overflowX: "auto",
        //marginRight: 'auto',
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    margin_left_5:{
        marginLeft : '5px',
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        // marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F1F1F1',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    scrollBar: {
        // padding: '5px',
        overflowX: 'auto',
        scrollbarWidth: 'thin', //without buttons on firefox, change to 'auto' if buttons wanted
        scrollbarColor: '#9B9B9B #E3E3E3', //first color to scrollbutton and the second to the bar
        "&::-webkit-scrollbar": {
            display: "block",
            overflowX: 'auto',
            height: '10px',
            background: '#E3E3E3',
        },
        "&::-webkit-scrollbar-thumb": {
            background: '#9B9B9B',
        },
        //if buttons wanted
        /*  "&::-webkit-scrollbar-button:single-button": {
             backgroundColor: '#bbbbbb',
       display: 'block',
       height: '13px',
       width: '16px',
         }, */

    },
    addCellButton: {
        backgroundColor: colors.greyLighter,
        borderBottom: 'none'
    },
    rowDelCellButton: {
        backgroundColor: colors.greyLighter,
        display: 'flex',
        alignItems: 'center',
    },
    rowNum: {
        color: colors.grey,
        //fontWeight: 'bold',
    },
    tableHeader: {
        padding: 15,
    },
    headerSeparator: {
        borderColor: colors.greyLight,
        borderStyle: 'solid',
        borderWidth: 0,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        padding: 10,
    },
    RadioGroupCell: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 20,
        //border: '1px solid greenyellow',
    },
    radioIcon: {
        color: colors.main,
        width: 25,
    },
    radioIconChecked: {
        color: colors.main,
    },
    radioLabel: {
        fontSize: 10,
    },
    stickyCol:{
        position: "sticky",
        left: '30px',
        background: colors.white,
        zIndex: 100,
        width :'100%',
        wordBreak: 'break-all',
        borderBottom: 'none'
    },
    stickyColRight:{
        position: "sticky",
        left: 0,
        textAlignLast: 'center',
        zIndex: 50,
        borderBottom: 'none'
    },
    //Render Item Single Select Component Styles
    renderSingleSelMainContainer: {
        justifyContent: 'space-between',
    },
    renderSingleSelContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
        flexWrap: 'wrap',
    },
    renderBlankContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        // background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
        flexWrap: 'wrap',
    },
    renderSingleFormulaContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
    },
    renderInputContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
        flexWrap: 'no-wrap',
    },
    renderSingleSelSubContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    renderSingleSelPreselNum: {
        fontSize: 10,
        color: colors.greyLight,
        border: `1px solid ${colors.greyLight}`,
        borderRadius: 50,
        padding: '1px 2px',
    },
    renderSingleSelIcon: {
        fontSize: 28,
        color: colors.greyDark,
    },
    fontChange: {
        fontSize: 12,
        paddingLeft: '5px!important',
    },
    fontChangeLabel: {
        fontSize: 12,
        fontWeight: 'bold',
        color: colors.main
    },
    fontChangeWidth: {
        fontSize: 12,
        minWidth : 120
    },
    fontError: {
        fontSize: 12,
        color: colors.red,
        fontWeight: 'bold',
        paddingLeft: '5px!important',
        width: '-webkit-fill-available',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    formatInputFieldComposed: {
        marginLeft: '29%',
        borderRadius: "4px",
        paddingRight: '8px',
        height: 41,
        // padding: "5px 0",
        overflowX: 'visible!important', //temp fix for f32 size issue
    },
    formatInputFieldLockedComposed: {
        backgroundColor: colors.white,
        marginLeft: '29%',
        paddingRight: '7px',
        borderRadius: "4px",
        height: 41,
        overflowX: 'visible!important', //temp fix for f32 size issue
    },
    upliftedContainer: {
        marginTop: '-37px',
        overflowY : 'visible'
    },
    searchField: {
        border: "solid #E3E3E3 1px",
        borderRadius: "4px",
        padding: '0px 10px',
    },
    searchBtn: {
        fontSize: 15,
        minWidth: 70,
    },
    textFont: {
        fontSize: 12,
        // fontWeight: 'bold',
        paddingLeft: '5px!important',
    },
    textLength: {
        fontSize: 8,
        position: 'absolute',
        top: -6,
        right: 0,
    },
    fontFieldNumber: {
        fontSize: 8,
        marginRight: 2,
        color: colors.main,
        border: '1px solid',
        padding: '0px 2px',
        borderRadius: '50%',
        verticalAlign: 'bottom',
    },
    btnContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    selectItemContainer: {
        display: 'flex',
        gap: 3,
        minHeight: 27,
        width: '100%',
        flexWrap: 'wrap',
    },
    selectItem: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    selectedItem: {
        color: colors.white,
        backgroundColor: colors.selectedAlpha,
        padding: '0px 5px',
        margin: '1px 0px',
        borderRadius: 4,
        cursor: 'pointer',
        fontSize: 13,
    },
    unselectedItem: {
        color: colors.white,
        backgroundColor: colors.unselectedAlpha,
        padding: '0px 5px',
        margin: '1px 0px',
        borderRadius: 4,
        cursor: 'pointer',
        fontSize: 13,
    },
    noSelectedItem: {
        color: colors.dark,
        fontSize: 13,
        fontStyle: 'italic',
        marginLeft: 10
    },
    selectIcon: {
        left: 2,
        fontSize: 28,
        cursor: 'pointer',
    },
    selectSelect: {
        marginLeft: 33,
        paddingLeft: 2,
    },
    selectField: {
        minHeight: 24
    },
    fieldContainer: {
        display : 'flex',
        alignItems : 'flex-start',
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: 3,
    },
    widerFieldValueContainer: {
        marginLeft: -50,
        order: 2,
        width : 'calc(60% + 32px)',
    },
    fieldTitleContainer: {
        marginRight: 'auto',
        order: 1,
        width: '40%',
    },
    fieldValueContainer: {
        display: 'flex',
        marginLeft: 'auto',
        minHeight: 33,
        order: 2,
        width : '60%'
    },
    fieldValues: {
        padding: 0,
        paddingLeft: 5,
        cursor: 'pointer',
        wordBreak: 'break-word',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'left',
    },
    fieldLabels: {
        fontWeight: 'bold',
        wordBreak: 'break-word',
        color: colors.main,
        textAlign: 'left',
        paddingLeft: 5,
    },
    attItemsContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 5,
        overflow: "hidden",
        marginLeft : '10px'
    },
    attItem: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 5,
    },
    labelContainer: {
        width: '15%',
    },
    inputContainer: {
        width: '80%',
    },
    header: {
        position: 'absolute',
        top: 0,
        width: '100%',
    },
    modalBody: {
        paddingTop: 50,
        position: 'relative',
        paddingBottom: 50
    },
    modalFooter: {
        '@media (min-height:640px)': {
            display: 'none'
        },
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },
    RadioGroupModal: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        // paddingLeft: 20,
        //border: '1px solid greenyellow',
    },
    showOption: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: 5
    },
    radioIconModal: {
        color: colors.mainButton,
        backgroundColor: colors.mainButton
    },
    radioLabelModal: {
        fontSize: 14,
        cursor: 'pointer'
    },
    '&input[name="show-empty-option"]': {
        backgroundColor: 'blue'
    },
    pwcodeContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 30,
        gap: 10,
    },
    modalBtnContainer: {
        display: 'inline-flex',
        width: '100%',
        justifyContent: "space-between",
    },
    arrowRight: {
        textAlign: 'left',
        height: 28,
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignSelf: 'center',
        "@media (max-width: 640px)": {
            fontSize: 18
        },
        color: colors.main,
        borderBottom: 'none',
    },
    arrowLeft: {
        textAlign: 'right',
        height: 30,
        justifyContent: 'flex-end',
        alignItems: 'center',
        alignSelf: 'center',
        "@media (max-width: 640px)": {
            fontSize: 18
        },
        color: colors.main,
        borderBottom: 'none',
    },
    hidden: {
        color: colors.greyLighter
    },
    tripleCell: {
        '&.MuiTableCell-root': {
            borderBottom: 'none !important'
        },
        height: 30,
        display: 'flex',
        flexDirection: 'row',
    },
    valuesCell: {
        borderBottom: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    totalValues: {
        color: colors.bloodOrange,
        padding: 0,
        paddingLeft: 5,
        wordBreak: 'break-word',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'left',
        fontSize: 12,
    },
    //New design
    summaryCtn: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: 5
    },
    summary: {
        paddingRight: 4
    },
    rowCtn: {
        justifyContent: 'space-between',
		zIndex: 200,
        display: 'flex',
        flexDirection: 'row',
    },
    topBtnCtn: {
        right: 0,
        gap: 3,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    topBtnCtnRow: {
        right: 0,
        gap: 3,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    top : {
        marginTop : 5
    },
    topBtn: {
        width: 60,
        height: 30,
        padding: 5,
        borderRadius: 5,
        boxShadow: '0px 1px 2px 1px #ddd',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: colors.mainButton,
        color: 'white',
        '&:hover': {
            boxShadow: '0px 0px 10px 1px #aaa',
            fontWeight: 'bold',
            borderColor: colors.mainButton,
        },
    },
    openRow: {
        backgroundColor: colors.greyLighter
    },
    pageRowCtn: {
        height: 30,
        verticalAlign: 'middle',
        backgroundColor: colors.greyLighter,
        borderBottom: `1px solid ${colors.greyLight}`
    },
    warnToast: {
        backgroundColor: colors.unselected,
    },
}))

const FormatDocumentComponent = (props) => {
    // devLog('FormatDocumentComponent got props', props);
    let tmpDisabled = false;
    if (typeof props.disabled !== "undefined") {
        tmpDisabled = props.disabled;
    }
    else if (typeof props.field.disabled !== "undefined") {
        tmpDisabled = props.field.disabled;
    }
    let _table = getTableView() || null
    const classes = useStyles();
    const { t } = useTranslation(['common'])
    const colonnes = props.field.options?.lines?.length > 0 ? props.field?.options?.lines : []
    const table = _table?.hash === props.hash ? _table : null
    const [rows, setRows] = useState(props.field.value?.list?.length > 0 ? props.field?.value?.list : []);
    const [disabled, setDisabled] = useState(tmpDisabled);
    const [openTable, setOpenTable] = useState(false)
    const [openRow, setOpenRow] = useState(null);
    const [openDownload, setOpenDownload] = useState(false);
    const [datasToDownload, setDatasToDownload] = useState([])
    const plugNb = props?.plugNb
    const [openModal, setOpenModal] = useState(false);
    const owner = typeof props.owner === 'undefined' ? null : props.owner
    const ref = props.field.options.ref
    const field = props.field.options.field
    const column = props.field.options.column
    const columns = props.field.options.columns
    const options = props.field.options
    const dest = props.field.options.dest
    const setup = props.field.options?.setup
	const defaultRows = setup?.defaultRows || []
	const rowLimit = setup?.rowLimit === 0 ? Infinity : setup?.rowLimit || Infinity
    const openingDate = useMemo(() => {
        if (setup?.excludePreviousAttachments) {
          return new Date()
        } else { return new Date('1970-1-1') }
    }, [])

    const optionFormule = { licenseKey: 'gpl-v3' }
    const ownerId = props.ownerId
    const importFromAnnexes = props.field.options.import_annexes !== undefined? props.field.options.import_annexes : false

    const [value, setValue] = useState(!!props.field?.value?.value ? props.field?.value?.value : props.field.options.search !== undefined? props.field.options.search : "");
    const [criteria, setCriteria] = useState("= xx")
    const [recap, setRecap] = useState(false)
    const [pages, setPages] = useState(0)
    const [tableLength, setTableLength] = useState(recap ? rows.length - 1 : rows.length)
    const [newRow, setNewRow] = useState(null);

    const initCountRefId = () => {
        try {
            if(rows.length === 0){
                return {count : "0"};
            } else {
                let obj = colonnes.find((column, index) => column.format === 'file-refid');
                if(obj){
                    let countSplit = rows[(rows.length - 1)][obj.id].split('-');
                    if(countSplit.length === 2){
                        // console.log(countSplit);
                        // console.log(countSplit[0]);
                        // console.log(countSplit[1]);
                        return {count : parseInt(countSplit[1], 10).toString() };
                    }else{
                        return {count : "0"};
                    }
                }
            }
            return {count : "0"};

        } catch (e) {
            return {count : "0"};
        }

    }
    const [countRefId, setCountRefId] = useState(initCountRefId)

    // console.log('countRefId',countRefId);

    //function deleteEmpty pour supprimer une rows[n] vide dans un useEffect ([])
    function deleteEmptyRows(rows) {
        let newRows = []
        if (rows.length > 0) {
            rows.forEach((row, index) => {
                let value = ''
                Object.values(row).forEach((valeur, id) => {
                    if (valeur !== '' && valeur !== undefined) value = valeur
                })
                if (value !== '') newRows.push(row)
            })
        }
        return newRows
    }

    useEffect(() => {
        let hasRecap = props.field.options.lines.some(col => col.recap === true)
        setRecap(hasRecap)
        let newRows = deleteEmptyRows(rows)
        setRows(newRows)
        if (table !== null && table !== undefined && table.field.split('field')[1] === props.field.id.toString()) { setOpenTable(true) }
        if (rows.length > 0 && props.annexes?.length > 0) { handleCheckMissingAttachments() }
    }, [])

    useEffect(() => {
        const handleAutoImportAttachment = async () => {
            if (props.annexes?.length > 0) {
                const attList = props.annexes.filter(att => (new Date(att.creationdate)) > openingDate)
                handleCheckMissingAttachments()
                handleAddTableMultiImport(attList)
            }
        }
        if (props.annexes?.length > 0) {
            if (setup?.autoImportAttachment === true) { handleAutoImportAttachment() }
            let commentColumn = colonnes.find(entry => entry.format === 'file-comment-text')
            let uriColumn = colonnes.find(entry => entry.format === 'file-uri')
            if (isDefined(commentColumn)) {
                const _rows = [...rows]
                props.annexes.forEach(att => {
                    if (att.comment?.length > 0) {
                        let rindex = rows.findIndex(entry => entry[uriColumn.id] === (att.type === 'note' ? att.hash : att.path))
                        if (rindex > -1 && rows[rindex][commentColumn.id] !== att?.comment) {
                            _rows[rindex][commentColumn.id] = att.comment
                        }
                    }
                })
                props.handleChangeTableValue(value, _rows, props.field, props.index);
                setRows(_rows)
            }
        }
    }, [props.annexes])

    useEffect(() => {
        handleChangeTableValue();
        setTableLength(recap && rows.length > 0 && rows[rows.length - 1].hasOwnProperty('recap') ? rows.length - 1 : rows.length)
    }, [rows]);

    const isAlreadyReferencedAttachment = (row, rows) => {
        let find = false;
        try {
            let nameId = colonnes.find((element, index) => element.format === 'file-name-text');
            let typeId = colonnes.find((element, index) => element.format === 'file-type-text');
            let dateId = colonnes.find((element, index) => element.format === 'file-create-date');
            let uriId = colonnes.find((element, index) => element.format === 'file-uri');
            let srcId = colonnes.find((element, index) => element.format === 'file-source');
            if (nameId && typeId && dateId) {
                rows.forEach((element, index) => {
                    if (element[nameId.id] === row[nameId.id] && element[typeId.id] === row[typeId.id] && element[dateId.id] === row[dateId.id]) {
                        find = true;
                    }
                });
            } else {
                if (uriId && srcId) {
                    rows.forEach((element, index) => {
                        if (element[uriId.id] === row[uriId.id] && element[srcId.id] === row[srcId.id]) {
                            find = true;
                        }
                    });
                }
                return find;
            }
            return find;
        } catch (e) {
            return false;
        }
    }

    const handleClickAddRow = () => {
        handleAddTableMulti()
        if(!openTable) setOpenTable(true)
    }

    const createNewRowWithDefaultValues = () => {
        let row = createEmptyRow(colonnes, { countRefId, setCountRefId }, props.field.id)
        return populateRowWithDefaultValues(colonnes, defaultRows, rows.length, row)
    }

    const handleAddTableMulti = async () => {
        let _newRow = createNewRowWithDefaultValues()
        if (rows.length !== 0) {
            let rowLimit = isDefined(setup?.rowLimit) ? setup?.rowLimit : 0
            let isEmpty = true
            let _rows = rows.filter(row => !row.hasOwnProperty('recap'));
            _rows.filter(Boolean)
            if ((rowLimit > 0 && _rows.length < rowLimit) || rowLimit === 0) {
                if (_rows.length > 0) {
                    let lastOne = _rows[_rows.length - 1];
                    Object.values(lastOne).forEach((k) => {
                        let control = typeof k !== "string" ? k.toString() : k;
                        if (control !== "" && control !== '0:00' && control.length > 0) isEmpty = false
                    })
                    if (!isEmpty) {
                        if (recap) setRows([..._rows, _newRow, createRecapData()])
                        else setRows([..._rows, _newRow]) //ouvre automatiquement la ligne créée
                        setNewRow(_newRow)
                    } else {
                        setOpenRow(_rows.length - 1)
                    }
                } else {
                    if (recap) setRows([_newRow, createRecapData()])
                    else setRows([_newRow]);
                    setNewRow(_newRow)
                }
            } else {
                toast.info(t('tablemulti.row-limit-warn'),
                    {
                        autoClose: 3000,
                        hideProgressBar: true,
                        bodyClassName: classes.warnToast,
                        className: classes.warnToast,
                        toastClassName: classes.warnToast,
                    })
            }
        } else {
            if (recap) setRows([...rows, _newRow, createRecapData()])
            else setRows([...rows, _newRow]);
            setNewRow(_newRow)
        }
    }

    const handleAddTableMultiImport = (attList) => {
        let newAttachmentRows = []
        let tmpCountRefId = countRefId.count

        const attachmentList = attList || props.annexes
        attachmentList.forEach(annexe => {
            let _newRow = createDataForAnnexeWithContent(null, annexe, (Number(tmpCountRefId) + 1));
            if (!isAlreadyReferencedAttachment(_newRow, rows)) {
                tmpCountRefId = Number(tmpCountRefId) + 1
                newAttachmentRows.push(_newRow);
            }
        })

        setCountRefId(previous =>({ ...previous, count : tmpCountRefId}));

        if (newAttachmentRows.length > 0) {
            let _rows = rows.filter(row => !row.hasOwnProperty('recap'));
            _rows.filter(Boolean)
                if (recap) {
                  newAttachmentRows = [...newAttachmentRows, createRecapData()]
                }
                const newRows = [..._rows, ...newAttachmentRows]
                setRows(newRows)
                props.handleChangeTableValue(value, newRows, props.field, props.index);
            if (!openTable && !isDefined(attList)) {
              setOpenTable(true)
            }
        }
    }

    const handleRemoveRecap = () => {
        if (rows[(rows.length - 1)].hasOwnProperty('recap')) {
            handleRemoveSpecificRow((rows.length - 1));
        }
    }

    const handleAddRecap = () => {
        if(rows.length > 0 && !rows[rows.length - 1].hasOwnProperty('recap')) setRows([...rows, createRecapData()]);
    }

    const handleDuplicateRow = async (row) => {
        // let _newRowDuplicated = row
        let _newRowDuplicated = { ...row }
        if (rows.length > 0) {
            let isEmpty = true
            let _rows = rows.filter(row => !row.hasOwnProperty('recap'));
            _rows = _rows.filter(Boolean);
            if ((rowLimit > 0 && _rows.length < rowLimit) || rowLimit === 0) {
                if (_rows.length > 0) {
                    let lastOne = _rows[_rows.length - 1];
                    Object.values(lastOne).forEach((k) => {
                        let control = typeof k !== "string" ? k.toString() : k;
                        if (control !== "" && control !== '0:00' && control.length > 0) isEmpty = false
                    })
                    if (!isEmpty) {
                        if (recap) setRows([..._rows, _newRowDuplicated, createRecapData()]);
                        else setRows([..._rows, _newRowDuplicated]);
                        //setNewRow(_newRowDuplicated)
                    }
                    else {
                        setOpenRow(_rows.length - 1)
                    }
                }
                else {
                    if (recap) setRows([_newRowDuplicated, createRecapData()])
                    else setRows([_newRowDuplicated]);
                    //setNewRow(_newRowDuplicated)
                }
            } else {
                if (!toast.isActive()) {
                    toast.info(t('tablemulti.row-limit-warn'),
                        {
                            autoClose: 3000,
                            hideProgressBar: true,
                            bodyClassName: classes.warnToast,
                            className: classes.warnToast,
                            toastClassName: classes.warnToast,
                        })
                }
            }
        } else {
            if (recap) { setRows([...rows, _newRowDuplicated, createRecapData()]) }
            else { setRows([...rows, _newRowDuplicated]) }
        }
    }

    const handleExportTableMulti = () => {
        let dataRows = [];
        if (colonnes.length > 0){
            let line1 = [];
            line1.push(t('export.export-date'))
            line1.push(t('export.export-key'))
            line1.push(t('export.export-number'))
            line1.push(t('export.export-field-number'))
            line1.push(t('export.export-field'))
            colonnes.forEach(col => {
                if (isVisibleInCSV(col, props.mode)) line1.push(getLabelWithSpecialChar(col.label))
            });

            dataRows.push(line1);
            rows.forEach((row, index) => {
                let dataRow = [];
                // date et heure de l'export
                // ajouter Clé du plugform
                // Numero de plugnote
                // Numero du champ
                // Libellé du champ
                let now = new Date()
                dataRow.push(now.toLocaleString())
                dataRow.push(props.plugKey)
                dataRow.push(plugNb)
                dataRow.push(props.index + 1 + (row.recap === true ? ' - Total' : '.' + (index + 1)))
                dataRow.push(props.title)

                colonnes.forEach(col => {
                    if (isVisibleInCSV(col, props.mode)) {
                        let someText = typeof row[col.id] === 'string'
                            ? row[col.id].replace(/\r?\n?/g, '').trim()
                            : row[col.id]
                        if (someText === undefined) {
                            someText = "";
                        }
                        dataRow.push(someText);
                }})
                dataRows.push(dataRow);
            });
        }

        if (dataRows.length > 0) {
            setDatasToDownload(dataRows);
            setOpenDownload(true);
        }
    }

    useEffect(() => {
        if (openDownload) {
            setDatasToDownload([]);
            setOpenDownload(false);
        }
    }, [openDownload]);

    const handleRemoveSpecificRow = async (value) => {
        let items
        if (Array.isArray(value)) {
            items = rows.filter((_, index) => !value.includes(index + 1))
        } else {
        items = [...rows];
        items = items.filter((row, index) => index !== value);
        }
        if (recap) {
            let totalRow = items.find((row) => row?.recap === true)
            if ((totalRow && items.length > 1) || items.length >= 1) {
              let rindex = items.indexOf(totalRow)
              if (rindex > -1) {
                colonnes.forEach((col, index) => {
                  if (col?.recap) {
                    totalRow['recap'] = true
                    totalRow[col.id] = getTotalForSpecificColumnIndex(items, col.id, colonnes)
                  }
                })
                items[rindex] = totalRow
              }
              setRows([...items])
            } else setRows([])
        }
        else setRows(items);
    };

    function createRecapData() {

        let data = {};
        colonnes.forEach((col, index) => {
            if (col?.recap) {
                data["recap"] = true;
                data[col.id] = getTotalForSpecificColumnIndex(rows, col.id, colonnes)
            }
        })


        return data;


    }

    function createRecapDataForce(rows) {
        let data = {};
        colonnes.forEach((col, index) => {
            if (col?.recap === true) {
                data["recap"] = true;
                data[col.id] = getTotalForSpecificColumnIndex(rows, col.id, colonnes)
            }
        })
        return data;
    }

    function refreshRecapData() {
        let _rows = [...rows]
        let row = _rows.find(row => row?.recap === true)
        if (row) {
            let rindex = _rows.indexOf(row)
            colonnes.forEach((col, index) => {
                if (col?.recap) {
                    row["recap"] = true;
                    row[col.id] = getTotalForSpecificColumnIndex(rows, col.id, colonnes)
                }
            })
            rows[rindex] = row;
            setRows([...rows]);
        }
    }

    function createData() {
        let data = {};
        colonnes.forEach((col, index) => {
            if(col.format === 'file-refid'){
                let tmpCountRefId = countRefId.count;
                tmpCountRefId = Number(tmpCountRefId) + 1;
                data[col.id] =  zeroPad(props.field.id,2) + '-' + zeroPad(tmpCountRefId.toString(),3);
                setCountRefId(previous =>({ ...previous, count : tmpCountRefId.toString()}));

            }else{
                data[col.id] = '';
            }
        })
        return data;
    }

    /*
        'file-source'
        'file-name-text'
        'file-comment-text'
        'file-type-text'
        'file-size-num'
        'file-create-date'
        'file-uri'
    */

    const createDataForAnnexeWithContentInit = (preload,content) => {
        let tmpCountRefId = countRefId.count;

        let _newRow = createDataForAnnexeWithContent(preload, content, tmpCountRefId);

        setCountRefId(previous =>({ ...previous, count : tmpCountRefId}));

        return _newRow;
    }

    function createDataForAnnexeWithContent(preload, content, tmpCountRefId) {
        let data = preload === null ? {} : preload;
        colonnes.forEach((col, index) => {
            switch (col.format) {
                case 'file-source':
                    if (preload === null) {
                        data[col.id] = 'PLUGNOTE';
                    }
                    break;
                case 'file-refid':
                    data[col.id] = zeroPad(props.field.id, 2) + '-' + zeroPad(tmpCountRefId.toString(), 3);
                    break;
                case 'file-name-text':
                    data[col.id] = content.name;
                    break;
                case 'file-comment-text':
                    data[col.id] = content.comment;
                    break;
                case 'file-type-text':
                    data[col.id] = content.type;
                    break;
                case 'file-size-num':
                    data[col.id] = content.size;
                    break;
                case 'file-create-date':

                    if (content.hasOwnProperty('from')) {
                        data[col.id] = content.creationdate;
                    } else {
                        try {
                            data[col.id] = moment(content.creationdate).format('DD/MM/YYYY');
                        } catch (e) {
                            data[col.id] = content.creationdate;
                        }
                    }
                    break;
                case 'file-uri':
                    if (content.type === 'note') {
                        data[col.id] = content.hash
                    } else {
                        data[col.id] = content.path;
                    }
                    break;
                default:
                    break
            }

        })
        return data;
    }

    const handleChangeTableValue = () => {
        props.handleChangeTableValue(value, rows, props.field, props.index);
    }

    const handleEditValue = (value) => {
        setValue(value);
        props.handleChangeTableValue(value, rows, props.field, props.index);
    }

    const handleCalculateValueAuto = async (row, formula) => {
        let answer = null;
        let tmp = [];

        colonnes.forEach((elm, index) => {
            let tmpValue = row[elm.id];

            if (Object.prototype.toString.call(tmpValue) === '[object Array]' || Object.prototype.toString.call(tmpValue) === '[object Object]') {
                tmpValue = "";
            }

            if (elm.format === 'numeric' && typeof tmpValue === "string" && typeof tmpValue !== "undefined") {
                // console.log('tmpValue', tmpValue, typeof tmpValue);
                if (tmpValue !== null && tmpValue !== '') tmpValue = tmpValue.replace(', ', '.');
            }
            tmp.push([tmpValue]);
        });

        if (formula !== null) {
            let data = tmp;
            const sheetInfo = {
                pnSheet: {
                    sheetName: "F32"
                },
                formulas: {
                    sheetName: "Formulas"
                }
            };

            let sheetAData = data;

            let newFormula = formula;

            const hfInstance = HyperFormula.buildEmpty(optionFormule);

            let formulasData = [[
                newFormula
            ]];
            // add 'TeamA' sheet
            hfInstance.addSheet(sheetInfo.pnSheet.sheetName);
            // insert playersA content into targeted 'TeamA' sheet
            hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo.pnSheet.sheetName), sheetAData);
            // add a sheet named 'Formulas'
            hfInstance.addSheet(sheetInfo.formulas.sheetName);
            // add formulas to that sheet
            hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo.formulas.sheetName), formulasData);
            const cellAddress = hfInstance.simpleCellAddressFromString(
                `${sheetInfo.formulas.sheetName}!A1`, hfInstance.getSheetId(sheetInfo.formulas.sheetName)
            );

            try {
                const mySum = hfInstance.getCellValue(cellAddress);

                if (!mySum.hasOwnProperty('type')) {
                    answer = mySum;
                } else {
                    answer = "";
                }
            }
            catch (e) {
                //setResult(e.message)
                answer = "";
            }
            hfInstance.destroy();
        }
        return answer;
    }

    const handleChangeChildrenValue = async (value, col, rindex) => {
        let row = rows[rindex]
        if (!props.disabled) {
            let obj = colonnes.find(o => o.format === 'formula');

            switch (col.format) {
                case 'presel':
                    let multiselect = col.mask.multiselect;
                    let editable = col.mask.editable;
                    if (multiselect === false && editable === false) {
                        row[col.id] = value;
                        if (obj) {
                            let tmpValue = await handleCalculateValueAuto(row, obj?.configuration?.formula);
                            if (tmpValue !== null) {
                                row[obj.id] = tmpValue
                            }
                        }
                        rows[rindex] = row;
                        setRows([...rows]);
                    }
                    else if (multiselect === false && editable === true) {
                        row[col.id] = value;
                        if (obj) {
                            let tmpValue = await handleCalculateValueAuto(row, obj?.configuration?.formula);
                            if (tmpValue !== null) {
                                row[obj.id] = tmpValue
                            }
                        }
                        rows[rindex] = row;
                        setRows([...rows]);
                    }
                    else if (multiselect === true && editable === false) {
                        row[col.id] = value;
                        if (obj) {
                            let tmpValue = await handleCalculateValueAuto(row, obj?.configuration?.formula);
                            if (tmpValue !== null) {
                                row[obj.id] = tmpValue
                            }
                        }
                        rows[rindex] = row;
                        setRows([...rows]);
                    }
                    else if (multiselect === true && editable === true) {
                        row[col.id] = value;
                        if (obj) {
                            let tmpValue = await handleCalculateValueAuto(row, obj?.configuration?.formula);
                            if (tmpValue !== null) {
                                row[obj.id] = tmpValue
                            }
                        }
                        rows[rindex] = row;
                        setRows([...rows]);
                    }
                    break;
                default:
                    row[col.id] = value;
                    if (obj) {
                        let tmpValue = await handleCalculateValueAuto(row, obj?.configuration?.formula);
                        if (tmpValue !== null) {
                            row[obj.id] = tmpValue
                        }
                    }
                    setRows(previous => {
                        let _rows = [...previous]
                        _rows[rindex] = row;
                        return _rows
                    });
                    break;
            }
            refreshRecapData();
        }
    }

    const handleChangeChildrenRowValue = async (value, col, row, rindex) => {
        if (!props.disabled) {
            switch (col.format) {
                case 'file-uri':
                case 'file-source':
                case 'extdata':
                    rows[rindex] = value;
                    setRows([...rows]);

                    if (typeof row.recap === "undefined") {
                        await handleRemoveRecap();
                    }
                    break;
                default:
                    break;
            }
        }
    }

    async function handleCheckMissingAttachments() {
        const fileSourceCol = colonnes.find(col => col.format === 'file-source')
        const fileUriCol = colonnes.find(col => col.format === 'file-uri')
        const _rows = [...rows]
        // devLog('comparing rows & attachments', _rows, props.annexes)
        _rows.forEach(row => {
            if (row[fileSourceCol.id] === 'PLUGNOTE' && !row[fileUriCol.id]?.includes('MISSING_FILE_') && row[fileUriCol.id] !== '') {
                const correspondingAttachment = props.annexes.find(att => (att.path === row[fileUriCol.id] || att.hash === row[fileUriCol.id]))
                // devLog('from Plugnote =', correspondingAttachment)
                if (!isDefined(correspondingAttachment)) {
                    row[fileUriCol.id] = 'MISSING_FILE_' + row[fileUriCol.id]
                }
            }
        })
        setRows(_rows)
    }

    useEffect(async () => {
        if (pages > 0 && typeof value !== 'undefined' && value !== "") {
            await fetchDatas()
        }
    }, [pages])

    const fetchDatas = async () => {
        let valueTmp = value;
        if (valueTmp === "") {
            valueTmp = 'ùµùµùµ?';
        }

        let payload = {
            "column": options.column,
            "query": typeof options.query !== "undefined" ? options.query : 'LIKE',
            "columns": options?.columns?.split(';') || [],
            "userhash": owner,
            "value": valueTmp,
            "file": options.ref,
            "ownerId": ownerId,
            "page": pages
        }

        if (payload.value !== "") {
            await trackPromise(
                axios.post(ApplicationConfig.apiUrl + 'extdata/autos/retrieves/ands/filters/datas.json', payload,
                    {
                        crossdomain: true,
                        'headers': {
                            'X-AUTH-TOKEN': getToken(),
                        }
                    }).then(async (response) => {
                    if (response.data.result && response.data.data.length > 0) {
                        await handleSelectData((response.data.data));
                    }

                    if (pages < response.data.end) {
                        setPages(pages + 1);
                    } else {
                        setPages(0);
                    }
                }).catch((error) => {
                    setPages(0);
                })
            )
            //api.post('extdata/generates/datas.json', payload)
        }
    };

    useEffect(() => {
        let tmpDisabled = false;
        if (typeof props.disabled !== "undefined") {
            tmpDisabled = props.disabled;
        }
        else if (typeof props.field.disabled !== "undefined") {
            tmpDisabled = props.field.disabled;
        }
        setDisabled(tmpDisabled);
    }, [props]);

    useEffect(() => {
        switch (props.field.options.query) {
            case 'CONTAINS':
                setCriteria("..xx..")
                break;
            case 'START':
                setCriteria("xx..")
                break;
            case 'MULTIPLE':
                setCriteria("..xx ; xx..")
                break;
            default: setCriteria("= xx")
                break;
        }
    }, [props.field.options.query])

    // useEffect(() => {
    //     setValue(!!props.field?.value?.value ? props.field.value.value : "");
    //     setRows(!!props.field?.value?.list ? props.field?.value?.list : []);
    // },[props.field.value])

    const handleOpenSearchModel = () => {
        props.attemptGetDataFromAnotherBaseByClientReset();
        setOpenModal(true);
    }

    const handleSaveComment = (annexe, comment) => {
        props.handleSaveComment(annexe, comment)
        props.attemptUpdateComment(annexe.hash, comment, props.user.userhash)
    }

    const handleSelectData = async (objects) => {
        let datas = [];
        if (objects.length > 0) {
            let resolve = new Promise((resolve, reject) => {
                objects.map(async (object, indexObject) => {
                    let jsonObjectToArray = Object.values(object)
                    let destinations = [];
                    let champs = dest.split('-');

                    champs.forEach((elm, index) => {
                        let field = elm.split(':');
                        if (field.length > 0 && field.length <= 3) {
                            let dest = colonnes[(field[0] - 1)].id;
                            let col = field[1];
                            let copyValue = col?.split(';') || col;
                            let value = ""

                            copyValue.forEach((item, index) => {
                                let alphaNumIndexDest = convertersAlphaToNumber(item.toUpperCase()) - 1;
                                let data = jsonObjectToArray[alphaNumIndexDest];
                                if (typeof data !== "undefined") {
                                    let temp = data;
                                    if (temp !== "" && typeof temp !== "undefined") {
                                        if (temp === null) {
                                            temp = "";
                                        }

                                        if (temp !== "") {
                                            if (item === item.toUpperCase()) {
                                                value = value === "" ? value.concat(temp) : value.concat(' ~ \n' + temp);
                                            }
                                            else {
                                                value = value === "" ? value.concat(temp) : value.concat(' ~ ' + temp);
                                            }
                                        }
                                    }
                                }
                            });
                            destinations.push({
                                "dest": dest,
                                "value": value
                            })
                        }
                    })

                    if (destinations.length > 0) {
                        let obj = colonnes.find(o => o.format === 'formula');
                        let data = createData();
                        destinations.map(async (item, index) => {
                            let col = colonnes.find(o => ((o.id === Number(item.dest)) && (o.format === 'numeric')));

                            if (typeof col === "undefined") {
                                data[item.dest] = item.value;
                            } else {
                                data[item.dest] = item.value.replace(',', '.');
                            }

                            if (obj) {
                                let tmpValue = await handleCalculateValueAuto(data, obj?.configuration?.formula);
                                if (tmpValue !== null) {
                                    data[obj.id] = tmpValue
                                }
                            }
                        })
                        datas.push(data);
                        //props.handleChangeExtData(destinations);
                    }
                    resolve(true);
                })
            })

            let rowLimit = setup?.rowLimit
            let rowsLength = recap ? rows.length -1 : rows.length
            if (typeof rowLimit !== "undefined" && rowLimit > 0 && datas.length > rowLimit - rowsLength) {
                toast.info(t('tablemulti.row-limit-warn') + ': ' + (datas.length - (rowLimit - rowsLength)) + t('tablemulti.rows-ignored'),
                    {
                        autoClose: 3000,
                        hideProgressBar: true,
                        bodyClassName: classes.warnToast,
                        className: classes.warnToast,
                        toastClassName: classes.warnToast,
                    })
                datas = datas.slice(0, rowLimit - rowsLength)
            }

            if (datas.length > 0 && await resolve) {
                let newRows = [...rows];

                if (newRows.length > 0) {
                    if (newRows[(newRows.length - 1)].hasOwnProperty('recap')) {
                        newRows = newRows.filter((row, index) => index !== (newRows.length - 1));
                    }
                }

                datas.forEach((item) => {
                    newRows.push(item);
                })

                if (recap) setRows([...newRows, createRecapDataForce(newRows)]);
                else setRows(newRows);
            }
        }
    }

    const handleSelectDataMultiCombo = async (objects, currentColumn, currentRow) => {
        let datas = [];
        let datasFields = null;
        let destCombo = currentColumn?.configuration.dest;

        if (objects.length > 0) {
            let resolve = new Promise((resolve, reject) => {
                objects.map(async (object, indexObject) => {
                    let jsonObjectToArray = Object.values(object)
                    let destinations = [];
                    let fieldDestinations = [];
                    let champs = destCombo.split('-');

                    champs.forEach((elm, index) => {
                        try {
                            let field = elm.split(':');
                            if (field.length > 0) {
                                let fieldDest = field[0];
                                if(fieldDest.startsWith('F')){
                                    fieldDest = fieldDest.replace('F', '');
                                    let dest = fieldDest
                                    let col = field[1];
                                    let copyValue = col?.split(';') || col;
                                    let value = "";

                                    let lineDest = null;
                                    let colDest = null;

                                    if(typeof field[2] !== "undefined" && typeof field[3] !== "undefined"){
                                        lineDest = field[2];
                                        colDest = field[3];
                                    }

                                    if(typeof props.fields[(dest - 1)] !== "undefined"){
                                        if(props.fields[(dest - 1)].format === "glocalis"){
                                            value = {
                                                latitude : "",
                                                longitude : "",
                                                address : ""
                                            }
                                        }
                                        else if(props.fields[(dest - 1)].format === "tablemultin" || props.fields[(dest - 1)].format === "smailvalidmulti" || props.fields[(dest - 1)].format === "document"){
                                            value = {
                                                value : "",
                                                list : [],
                                            }
                                        }
                                        else if(props.fields[(dest - 1)].format === "intdatamaj"){
                                            value = {
                                                value : "",
                                                updatedDate : "",
                                            }
                                        }else if(props.fields[(dest - 1)].format === "tablemulti"){
                                            value = [];
                                        }
                                        copyValue.map((item, index) => {
                                            let alphaNumIndexDest = convertersAlphaToNumber(item.toUpperCase()) - 1;
                                            let data = jsonObjectToArray[alphaNumIndexDest];

                                            if(typeof data !== "undefined"){
                                                let temp = data;
                                                if(temp !== "" && typeof temp !== "undefined"){
                                                    if(temp === null){
                                                        temp = "";
                                                    }

                                                    if(temp != ""){
                                                        switch (props.fields[(dest - 1)].format) {
                                                            case 'free-single-select':
                                                            case 'single-select':
                                                            case 'multi-select':
                                                            case 'free-multi-select':
                                                                try {
                                                                    value = [temp];
                                                                }catch (e) {
                                                                    value = [];
                                                                }
                                                                break;
                                                            case 'glocalis' :
                                                                value.address = value.address == "" ? value.address.concat(temp) : value.address.concat(' ' + temp) ;
                                                                break;
                                                            case 'tablemulti':
                                                                if(!isNull(lineDest) && !isNull(colDest)){
                                                                    let field = props.fields.find((field, index) => Number(field.id) === Number(dest));
                                                                    if(field){
                                                                        let tmpValue = field?.value ? field.value : null;
                                                                        if(!isNull(tmpValue)){
                                                                            if (tmpValue.length > 0 && Array.isArray(tmpValue)) {
                                                                                let options = field.options?.lines ? field.options.lines : field.options;
                                                                                let targert = (lineDest.replace('L', ''));
                                                                                let targettable = (colDest.replace('C', '') );
                                                                                tmpValue[(targert - 1)][options[(targettable - 1)].id] = temp;
                                                                                value = tmpValue;
                                                                            }else{
                                                                                let options = field.options?.lines ? field.options.lines : field.options;
                                                                                let newLine = {};
                                                                                for (const option of options) {
                                                                                    newLine[option.id] = "";
                                                                                }
                                                                                let targert = (lineDest.replace('L', ''));
                                                                                let targettable = (colDest.replace('C', '') );
                                                                                newLine[options[(targettable - 1)].id] = temp;
                                                                                let newValue = [];
                                                                                newValue[(targert - 1)] = newLine
                                                                                value = newValue;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                break;
                                                            case 'tablemultin' :
                                                            case 'smailvalidmulti' :
                                                                if(isNull(lineDest) && isNull(colDest)){
                                                                    value.value = temp;
                                                                }else if(!isNull(lineDest) && !isNull(colDest)){
                                                                    let field = props.fields.find((field, index) => Number(field.id) === Number(dest));
                                                                    if(field){
                                                                        let tmpValue = field?.value?.list ? field.value.list : null;
                                                                        if(!isNull(tmpValue)){
                                                                            if (tmpValue.length > 0 && Array.isArray(tmpValue)) {
                                                                                let options = field.options?.lines ? field.options.lines : field.options;
                                                                                let targert = (lineDest.replace('L', ''));
                                                                                let targettable = (colDest.replace('C', '') );
                                                                                tmpValue[(targert - 1)][options[(targettable - 1)].id] = temp;
                                                                                value.list = tmpValue;
                                                                            }else{
                                                                                let options = field.options?.lines ? field.options.lines : field.options;
                                                                                let newLine = {};
                                                                                for (const option of options) {
                                                                                    newLine[option.id] = "";
                                                                                }
                                                                                let targert = (lineDest.replace('L', ''));
                                                                                let targettable = (colDest.replace('C', '') );
                                                                                newLine[options[(targettable - 1)].id] = temp;
                                                                                let newValue = [];
                                                                                newValue[(targert - 1)] = newLine
                                                                                value.list = newValue;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                break;
                                                            case 'localis' :
                                                                value = value == "" ? value.concat(temp) : value.concat(' ' + temp) ;
                                                                break;
                                                            case 'intdatamaj' :
                                                                value.value = temp;
                                                                break;
                                                            default:
                                                                if (item == item.toUpperCase()) {
                                                                    value = value == "" ? value.concat(temp) : value.concat(' ~ ' + '\n'+ temp);
                                                                }else{
                                                                    value = value == "" ? value.concat(temp) : value.concat(' ~ ' + temp);
                                                                }
                                                                break;

                                                        }
                                                    }
                                                }
                                            }
                                        });


                                        if(props.fields[(dest - 1)].format === "glocalis" ){
                                            value = JSON.stringify(value);
                                        }

                                        fieldDestinations.push({
                                            "dest" : dest,
                                            "value" : value
                                        })
                                    }
                                }
                                else {
                                    let dest = colonnes[(fieldDest - 1)].id;
                                    let col = field[1];
                                    let copyValue = col?.split(';') || col;
                                    let value = ""

                                    copyValue.forEach((item, index) => {
                                        let alphaNumIndexDest = convertersAlphaToNumber(item.toUpperCase()) - 1;
                                        let data = jsonObjectToArray[alphaNumIndexDest];
                                        if (typeof data !== "undefined") {
                                            let temp = data;
                                            if (temp !== "" && typeof temp !== "undefined") {
                                                if (temp === null) {
                                                    temp = "";
                                                }

                                                if (temp !== "") {
                                                    if (item === item.toUpperCase()) {
                                                        value = value === "" ? value.concat(temp) : value.concat(' ~ \n' + temp);
                                                    }
                                                    else {
                                                        value = value === "" ? value.concat(temp) : value.concat(' ~ ' + temp);
                                                    }
                                                }
                                            }
                                        }
                                    });
                                    destinations.push({
                                        "dest": dest,
                                        "value": value
                                    })
                                }
                            }
                        }catch (e) {

                        }
                    })

                    if (destinations.length > 0) {
                        let copyColonnes = [...colonnes];
                        let obj = copyColonnes.filter(o => o.format === 'formula').filter(Boolean);
                        let obj2 = copyColonnes.filter(o => o.format === 'numeric-ref').filter(Boolean);
                        let obj3 = copyColonnes.filter(o => o.format === 's33data').filter(Boolean);

                        let data = createNewRowWithDefaultValues();
                        destinations.map(async (item, index) => {
                            let col = colonnes.find(o => ((o.id === Number(item.dest)) && (o.format === 'numeric')));

                            if (typeof col === "undefined") {
                                data[item.dest] = item.value;
                            } else {
                                data[item.dest] = item.value.replace(',', '.');
                            }

                            if(obj2){
                                for (const list2 of obj2) {
                                    let tmpValue = data[list2.id];
                                    if (tmpValue == "") {
                                        let target = list2?.configuration?.target;
                                        if (typeof target !== "undefined") {
                                            let field = props.fields.find((field, index) => Number(field.id) === Number(target));
                                            if (field) {
                                                data[list2.id] = field.value;
                                            }
                                        }
                                    }
                                }
                            }

                            if(obj3){
                                for (const list3 of obj3) {
                                    let value = data[list3.id];

                                    try {
                                        if (value !== "") {
                                            let target = list3?.configuration?.target;
                                            let targettable = list3?.configuration?.targettable;
                                            if (typeof target !== "undefined") {
                                                let field = props.fields.find((field, index) => Number(field.id) === Number(target));
                                                if (field) {
                                                    let tmpValue = field?.value?.list ? field.value.list : field.value;
                                                    if (tmpValue.length > 0 && Array.isArray(tmpValue)) {
                                                        let options = field.options?.lines ? field.options.lines : field.options;
                                                        data[list3.id] = tmpValue[(value - 1)][options[(targettable - 1)].id];
                                                    }
                                                }
                                            }
                                        } else {
                                            data[list3.id] = value;
                                        }
                                    } catch (e) {
                                        data[list3.id] = value
                                    }
                                }
                            }

                            if (obj) {
                                for (const list of obj) {
                                    let tmpValue = await handleCalculateValueAuto(data, list?.configuration?.formula);
                                    if (tmpValue !== null) {
                                        data[list.id] = tmpValue
                                    }
                                }
                            }
                        })

                        datas.push(data);
                        //props.handleChangeExtData(destinations);
                    }

                    if(fieldDestinations.length > 0){
                        datasFields = fieldDestinations;
                    }

                    resolve(true);
                });

            })

            if (typeof rowLimit !== "undefined" && rowLimit > 0 && datas.length > rowLimit - tableLength) {
                datas = datas.slice(0, rowLimit - tableLength)
            }

            if ((datas.length > 0 || (!isNull(datasFields) && datasFields.length > 0)) && await resolve) {
                if(datas.length > 0 ){
                    let newRows = [...rows]
                    if (newRows.length > 0) {
                        if (newRows[(newRows.length - 1)].hasOwnProperty('recap')) {
                            newRows = newRows.filter((row, index) => index !== (newRows.length - 1));
                        }
                    }

                    let forek = new Promise((resolve, reject) => {
                        datas.forEach((item, index, array) => {
                            if(typeof currentRow !== "undefined" && index === 0){
                                newRows[currentRow] = item
                            }else{
                                newRows.push(item);
                            }
                            if (index === array.length -1) resolve();
                        });
                    });

                    forek.then(() => {
                        if (recap) setRows([...newRows, createRecapDataForce(newRows)]);
                        else setRows(newRows);
                    });
                }

                if (!isNull(datasFields) && datasFields.length > 0) {
                    props.handleChangeExtData(datasFields);
                }
            }
        }
    }

    const handleViewTable = () => {
        setOpenTable(true)
    }

    const handleClickPreview = (index, id) => {
        if (!disabled) {
            if (isDefined(id)) { setOpenRow({row: rows[index], id: id}); }
        }
        handleViewTable();
    }

	const isLimitReached = () => {
		return isRowLimitReached(props.field.options?.setup?.rowLimit, rows)
    }

    const titleFormat = `[ ${field} ] ( ${ref} ) [ ${columns} ] [ ${column} ]`
    // console.log('Format Document props', props);

    return (
        <div className={`${classes.mainCtn}`}>
            <div className={classes.rowCtn}>
                <div className={classes.leftCtn}>
                    <div className={classes.inputCtn}/>
                </div>
                {
                    openModal &&
                    <ModalListExtDataMultipleComponent titlePerso={props.title} titleFormat={titleFormat} format="extdata" open={openModal} close={(event) => setOpenModal(false)} options={options} owner={owner} ownerId={ownerId} value={value} fields={props.fields} field={props.field} handleSelectData={handleSelectData} external={props.external} />
                }
                <div className={classes.topBtnCtnRow}>
                    {
                        !disabled && tableLength < rowLimit &&
                        <PlugTooltip title={isLimitReached() ? t('tablemulti.row-limit-warn') : t('tablemulti.add-row')} placement="top">
                            <div className={classes.topBtn} onClick={handleClickAddRow}>
								<span id="icon-add-playlist" className={`material-icons`}>
									add_circle_outline
								</span>
                            </div>
                        </PlugTooltip>
                    }
                    {
                        !disabled && importFromAnnexes &&
                        <PlugTooltip title={t('document.import-missing')} placement="top">
                            <div className={classes.topBtn} onClick={() => handleAddTableMultiImport()}>
								<span id="icon-add-playlist" className={`material-icons`}>
									attachment
								</span>
                            </div>
                        </PlugTooltip>
                    }
                    {
                        rows.length > 0 &&
                        <PlugTooltip title={t('tablemulti.table-view').concat(` ${tableLength} `).concat(t('tablemulti.rows'))} placement="top">
                            <div className={classes.topBtn} onClick={handleViewTable}>
								<span id="icon-add-playlist" className="material-icons">
									visibility
								</span>
                                <span style={{ fontSize: 10, fontWeight: 'bolder', }}>
									{`(${tableLength})`}
								</span>
                            </div>
                        </PlugTooltip>}
                </div>
            </div>
            <div className={classes.top}>
                <ModalTablemultiTableViewComponent
                    annexes={props.annexes}
                    attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                    criteria={criteria}
                    colonnes={colonnes}
                    datasToDownload={datasToDownload}
                    disabled={disabled}
                    field={props.field}
                    handleAddRecap={handleAddRecap}
                    handleAddTableMulti={handleClickAddRow}
                    handleChangeChildrenRowValue={handleChangeChildrenRowValue}
                    handleChangeChildrenValue={handleChangeChildrenValue}
                    handleClickPreview={handleClickPreview}
                    handleClose={() => setOpenTable(false)}
                    handleDeleteRow={handleRemoveSpecificRow}
                    handleDuplicateRow={handleDuplicateRow}
                    handleEditValue={handleEditValue}
                    handleExportTableMulti={handleExportTableMulti}
                    handleOpenSearchModel={handleOpenSearchModel}
                    handleSaveComment={handleSaveComment}
                    createDataForAnnexeWithContent={createDataForAnnexeWithContentInit}
                    hasDb={false}
                    hideSearch={true}
                    isFromPwCode={props.isFromPwCode}
                    mode={props.mode}
                    model={props.model}
                    newRow={newRow}
                    open={openTable}
                    openDownload={openDownload}
                    openRow={openRow}
                    owner={owner}
                    ownerId={ownerId}
                    recap={recap}
                    rows={rows}
                    search={value}
                    setNewRow={setNewRow}
                    setOpenRow={setOpenRow}
                    title={props.title}
                    countRefId={countRefId}
                    shared={props?.shared}
                    handleSafeExitPlugnote={props.handleSafeExitPlugnote}
                    handleViewTable={handleViewTable}
                    isPreview={!openTable}
                    plugnoteNb={isDefined(props.plugnote) ? props.plugnote.userindex : props.plugNb}
                    handleSelectDataMultiCombo={handleSelectDataMultiCombo}
                />
            </div>
        </div>
    );
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        main : state?.user || null,
        user : state?.user?.user || null,
        profil : state?.user?.profil || null,
		"plugKey": state?.model?.model?.clef || null
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptGetDataFromAnotherBaseByClientReset: () => dispatch(postExtDataReset()),
    attemptSendMailSimple: (payload, userhash,ownerhash) => dispatch(postSendMailV3Request(payload,userhash,ownerhash)),
    attemptUpdateComment: (annexehash, comment, userhash) => dispatch(putAnnexeCommentModalRequest(annexehash, comment, userhash)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatDocumentComponent);
