// actions types is object with the
// key: GET_DATA_REQUEST -> value: describes the action
import {toast} from "react-toastify";
import React from "react";
import {Translation} from "react-i18next";

const ErrorToastMessage = ({ title, message }) => (
    <div>
        <Translation ns="common">
            {
                (t, { i18n }) => <h6>{t(title)}</h6>
            }
        </Translation>
        <Translation ns="common">
            {
                (t, { i18n }) => <p>{t(message)}</p>
            }
        </Translation>
    </div>

)

export const Types = {

    POST_EXT_DATA_REQUEST: 'post_ext_data_request',
    POST_EXT_DATA_SUCCESS: 'post_ext_data_success',
    POST_EXT_DATA_FAILURE: 'post_ext_data_failure',
    POST_EXT_DATA_ERROR: 'post_ext_data_error',
    POST_EXT_DATA_RESET: 'post_ext_data_reset',

    POST_COMBO_EXT_DATA_REQUEST: 'post_combo_ext_data_request',
    POST_COMBO_EXT_DATA_SUCCESS: 'post_combo_ext_data_success',
    POST_COMBO_EXT_DATA_FAILURE: 'post_combo_ext_data_failure',
    POST_COMBO_EXT_DATA_ERROR: 'post_combo_ext_data_error',
    POST_COMBO_EXT_DATA_RESET: 'post_combo_ext_data_reset',

    //postExtDataGenerateTableRequest
    POST_EXT_DATA_GENERATE_TABLE_REQUEST: 'post_ext_data_generate_table_request',
    POST_EXT_DATA_GENERATE_TABLE_SUCCESS: 'post_ext_data_generate_table_success',
    POST_EXT_DATA_GENERATE_TABLE_FAILURE: 'post_ext_data_generate_table_failure',
    POST_EXT_DATA_GENERATE_TABLE_ERROR: 'post_ext_data_generate_table_error',

    //postExtDataPreviewRequest
    POST_EXT_DATA_PREVIEW_REQUEST: 'post_ext_data_preview_request',
    POST_EXT_DATA_PREVIEW_SUCCESS: 'post_ext_data_preview_success',
    POST_EXT_DATA_PREVIEW_FAILURE: 'post_ext_data_preview_failure',
    POST_EXT_DATA_PREVIEW_ERROR: 'post_ext_data_preview_error',
    POST_EXT_DATA_PREVIEW_RESET: 'post_ext_data_preview_reset',
};


/*
Token
 */
// function that returns an object literal
export const postExtDataRequest = (payload) => ({
    type: Types.POST_EXT_DATA_REQUEST,
    payload : payload
});

// function that returns an object literal
export const postExtDataSuccess = (data) => ({
    type: Types.POST_EXT_DATA_SUCCESS,
    payload : data
});

// function that returns an object literal
export const postExtDataFailure = () => ({
    type: Types.POST_EXT_DATA_FAILURE,
});

// function that returns an object literal
export const postExtDataError = () => ({
    type: Types.POST_EXT_DATA_ERROR,
});


// function that returns an object literal
export const postExtDataReset = () => ({
    type: Types.POST_EXT_DATA_RESET,
});

/*
Token
 */
// function that returns an object literal
export const postComboExtDataRequest = (payload) => ({
    type: Types.POST_COMBO_EXT_DATA_REQUEST,
    payload : payload
});

// function that returns an object literal
export const postComboExtDataSuccess = (data) => ({
    type: Types.POST_COMBO_EXT_DATA_SUCCESS,
    payload : data
});

// function that returns an object literal
export const postComboExtDataFailure = () => ({
    type: Types.POST_COMBO_EXT_DATA_FAILURE,
});

// function that returns an object literal
export const postComboExtDataError = () => ({
    type: Types.POST_COMBO_EXT_DATA_ERROR,
});


// function that returns an object literal
export const postComboExtDataReset = () => ({
    type: Types.POST_COMBO_EXT_DATA_RESET,
});

/*
Token
 */
// function that returns an object literal
export const postExtDataGenerateTableRequest = (payload) => ({
    type: Types.POST_EXT_DATA_GENERATE_TABLE_REQUEST,
    payload : payload
});


// function that returns an object literal
export const postExtDataGenerateTableSuccess = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_EXT_DATA_GENERATE_TABLE_SUCCESS,
        });
        toast.success(<ErrorToastMessage title='global.plugnotes' message='' />)
    }
};

// function that returns an object literal
export const postExtDataGenerateTableFailure = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_EXT_DATA_GENERATE_TABLE_FAILURE,
        });
        toast.error(<ErrorToastMessage title='global.plugnotes' message='' />)
    }
};

export const postExtDataGenerateTableError = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_EXT_DATA_GENERATE_TABLE_ERROR,
        });
        toast.error(<ErrorToastMessage title='global.plugnotes' message='' />)
    }
};



/*
Token
 */
// function that returns an object literal
export const postExtDataPreviewRequest = (payload) => ({
    type: Types.POST_EXT_DATA_PREVIEW_REQUEST,
    payload : payload
});

// function that returns an object literal
export const postExtDataPreviewSuccess = (data) => ({
    type: Types.POST_EXT_DATA_PREVIEW_SUCCESS,
    payload : data
});

// function that returns an object literal
export const postExtDataPreviewFailure = () => ({
    type: Types.POST_EXT_DATA_PREVIEW_FAILURE,
});

// function that returns an object literal
export const postExtDataPreviewError = () => ({
    type: Types.POST_EXT_DATA_PREVIEW_ERROR,
});


// function that returns an object literal
export const postExtDataPreviewReset = () => ({
    type: Types.POST_EXT_DATA_PREVIEW_RESET,
});

