import React, { useContext, useEffect, useMemo, useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { connect } from "react-redux"
import Input from "@material-ui/core/Input"
import { useTranslation } from "react-i18next"
import { InputLabel, TextField, Tooltip } from "@material-ui/core"
import colors from "../../assets/themes/Colors"
import { addChar, devLog, isDefined } from "../../utils/utils"
import { useRef } from "react"
import { SMailSideMenu } from "../custom/SMail"
import RichEditor from "../custom/rich-editor/RichEditor"
import { DrawerContext } from "../../App"


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    MuiInputBaseInput: {
        padding: 0
    },
    appBar: {
        backgroundColor: 'white',
        borderBottom: '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color: 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo: {
        width: '10%',
    },
    mainField: {
        display: 'flex',
        flexDirection: 'column'
    },
    subfield: {
        width: '100%',
    },
    selectType: {
        //paddingRight: '24px',
        // marginTop: '15px',
        marginLeft: '10px',
        marginRight: '10px',
    },
    field: {
        // width : '100%',
        display: 'flex',
    },
    inputField: {
        margin: '5px'
        //padding : '0px 0px 7px',
    },
    margin: {
        marginTop: '15px',
        marginBottom: '5px',
    },
    MuiFormControl: {
        margin: "inherit",
    },
    RadioGroupCell: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 20,
    },
    radioIcon: {
        color: colors.main,
        width: 25,
    },
    radioIconChecked: {
        color: colors.main,
    },
    radioLabel: {
        fontSize: 10,
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        // paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '26px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: 'white',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius: '5px',
        border: '1px solid #ccc',
        WebkitBorderRadius: '5px',
    },
    textFieldArea: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        paddingLeft: '10px',
        backgroundColor: '#FFFFFF',
        fontSize: '14px',
        width: '100%',
        border: '1px solid #ccc',
        MozBorderRadius: '5px',
        WebkitBorderRadius: '5px',
    },
    buttonsBox: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex'
    },
    marginbuttonsBox: {
        margin: 5,
        cursor: 'pointer',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        padding: 5,
        gap: 5
    },
    inputContainer: {
        width: '100%'
    },
    input: {
        padding: '0 10px',
    },
    itemContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    itemGroupContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    valueButton: {
        height: '20px',
        width: '20px',
        color: colors.white,
        alignItems: 'center',
        backgroundColor: colors.main,
        borderRadius: 4,
        fontSize: "18px",
        fontWeight: '900',
    },
    labelButton: {
        color: colors.mainButton,
        fontWeight: '900',
    },
    sideMenu: {
        position: 'fixed',
        left: 0,
        top: 66,
        marginLeft: 15,
        paddingTop: 40,
        paddingBottom: 150,
        overflowY: 'scroll',
        width: 335,
        height: 'calc(100% + 35px)',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 2000,
        backgroundColor: colors.white
    },
    sideHeader: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    sideInstructions: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        padding: '0 10px',
        gap: 10,
    },
    instructionsFont: {
        fontSize: 12,
        display: 'flex',
        alignItems: 'center',
    },
    tooltipStyle: {
        backgroundColor: colors.grey,
        color: colors.white,
        border: '1px solid',
        padding: '3px 8px',
        margin: 5,
        fontSize: '16px',
        borderRadius: 5
    },
}));

const FormatSMailConstructorComponent = (props) => {
    // devLog('SMail constructor got props', props)
    const classes = useStyles();
    const inputSubjectRef = useRef();
    const inputBodyRef = useRef()
    const editorRef = useRef(null)
    const bottomRef = useRef()
    const { t } = useTranslation(['common', 'models']);
    const [template, setTemplate] = useState(props?.field?.options?.template || "");
    const [elements, setElements] = useState([]);
    const [auto, setAuto] = useState(props?.field?.options?.auto || "N");
    const [title, setTitle] = useState(props?.field?.options?.title || "");
    const _body = isDefined(props?.field?.options?.body)
        ? props?.field?.options?.body
        // ? props?.field?.options?.body.replaceAll('\n', '<br/>') // Ajoute une ligne après chq ligne vide
        : ''
    const [body, setBody] = useState(_body);
    const [note, setNote] = useState(props?.field?.options?.note || "Y");
    const [from, setFrom] = useState(props?.field?.options?.from || "noreply@plugnotes.com");
    const [cc, setCc] = useState(props?.field?.options?.cc || "");
    const [cci, setCci] = useState(props?.field?.options?.cci || "");
    const [openCc, setOpenCc] = useState(false)
    const [selectionStart, setSelectionStart] = useState();
    const [inserts, setInserts] = useState([])
    const { openDrawer, handleToggleDrawer } = useContext(DrawerContext)
    const drawerWasOpen = useMemo(() => openDrawer, [])

    function setCaretPosition(elemId, caretPos) {
        var el = document.getElementById(elemId);
        el.value = el.value;
        // ^ this is used to not only get "focus", but
        // to make sure we don't have it everything -selected-
        // (it causes an issue in chrome, and having it doesn't hurt any other browser)
        if (el !== null) {
            if (el.createTextRange) {
                var range = el.createTextRange();
                range.move('character', caretPos);
                range.select();
                return true;
            }
            else {
                // (el.selectionStart === 0 added for Firefox bug)
                if (el.selectionStart || el.selectionStart === 0) {
                    el.focus();
                    el.setSelectionRange(caretPos, caretPos);
                    return true;
                }
                else { // fail city, fortunately this never happens (as far as I've tested) :)
                    el.focus();
                    return false;
                }
            }
        }
    }

    const handleChangeTitle = (value, field, index) => {
        setTitle(value);
        field.options.title = value;
        let model = [...props.model];
        model[index].options.title = value;
        props.setModel(model);
    }

    const handleChangeBody = (value, addValue, field, index) => {
        let newBody = ""
        if (typeof selectionStart === 'undefined') {
            if (addValue !== null) {
                newBody = `${value} ${addValue}`
            } else {
                newBody = value
            }
        } else {
            if (addValue !== null) {
                newBody = addChar(`${value}`, `${addValue}`, selectionStart)
            } else {
                newBody = value
            }
        }
        // devLog('newBody', newBody)
        setBody(newBody)
        field.options.body = newBody
        let model = [...props.model]
        model[index].options.body = newBody
        props.setModel(model)
    }

    const handleChangeFrom = (value, field, index) => {
        setFrom(value);
        field.options.from = value;
        let model = [...props.model];
        model[index].options.from = value;
        props.setModel(model);
    }

    const handleChangeCc = (value, field, index) => {
        setCc(value);
        field.options.cc = value;
        let model = [...props.model];
        model[index].options.cc = value;
        props.setModel(model);
    }

    const handleChangeCci = (value, field, index) => {
        setCci(value);
        field.options.cci = value;
        let model = [...props.model];
        model[index].options.cci = value;
        props.setModel(model);
    }

    const handleChangeTemplateSelect = (value) => {
        setTemplate(value);
        props.field.options.template = value;
        let _model = [...props.model];
        _model[props.index].options.template = value;
        props.setModel(_model);
    }

    const handleChangeTemplateRadio = (value, field, index) => {
        setNote(value);
        field.options.note = value;
        let model = [...props.model];
        model[index].options.note = value;
        props.setModel(model);
    }

    const handleToggleAutoJoinAtt = (value, index) => {
        let model = [...props.model]
        model[index].options.setup = {
            ...model[index].options.setup,
            autoAttachments: value,
        }
        props.setModel(model)
    }

    const handleToggleAutoJoinCopy = (value, index) => {
        let model = [...props.model]
        model[index].options.setup = {
            ...model[index].options.setup,
            autoCopy: value,
        }
        props.setModel(model)
    }

    const handleChangeAuto = (value, field, index) => {
        // console.log('ici');
        // console.log(value);
        // console.log(field);
        // console.log(index);
        setAuto(value);
        field.options.auto = value;
        let model = [...props.model];
        model[index].options.auto = value;
        props.setModel(model);
    }

    const updateSubjectSelectionStart = () => setSelectionStart(inputSubjectRef.current.selectionStart);

    const updateBodySelectionStart = () => {
        let cursor = inputBodyRef.current.selectionStart
        setSelectionStart(cursor);
        if (inserts.length > 0) {
            for (let index = 0; index < inserts.length; index++) {
                const element = inserts[index];
                if (cursor > element.start && cursor < element.end) {
                    inputBodyRef.current.setSelectionRange(element.start + 1, element.end - 1)
                }
            }
        }
    }

    const updateInsertedValues = () => {
        let values = []
        if (body !== '')
            for (let index = 0; index < body.length; index++) {
                const elementA = body.charAt(index);
                const elementB = body.charAt(index + 1);
                const elementC = body.charAt(index + 2);
                if (elementA === '{' && elementB === '{') {
                    let indice = index
                    let char = ''
                    let value = {}
                    while (char !== '}' && indice < body.length) {
                        char = body.charAt(indice)
                        indice++
                    }
                    value.start = index - 1
                    value.end = indice + 2
                    value.value = body.substring(index + 2, indice - 1)
                    values.push(value)
                }
                if (elementA === '*' && elementB === '*' && elementC === '*') {
                    let indice = index + 3
                    let char = ''
                    let value = {}
                    while (char !== '*' && body.charAt(indice + 1) && indice < body.length) {
                        char = body.charAt(indice)
                        indice++
                    }
                    value.start = index - 1
                    value.end = indice + 2
                    value.value = body.substring(index + 3, indice - 1)
                    values.push(value)
                }
            }
        setInserts(values)
    }

    useEffect(() => {
        if (typeof props.field.options.title === 'undefined' || props.field.options.title === "") {
            handleChangeTitle(title, props.field, props.index);
        }
    }, [title]);

    useEffect(() => {
        if (typeof props.field.options.body === 'undefined' || props.field.options.body === "") {
            handleChangeBody(body, "", props.field, props.index);
        }
        updateInsertedValues()
    }, [body]);

    useEffect(() => {
        if (typeof props.field.options.note === 'undefined' || props.field.options.note === "") {
            handleChangeTemplateRadio(note, props.field, props.index);
        }
    }, [note]);

    useEffect(() => {
        if (typeof props.field.options.auto === 'undefined' || props.field.options.auto === "") {
            handleChangeAuto(auto, props.field, props.index);
        }
    }, [auto]);

    useEffect(() => {
        if (typeof props.folderName !== "undefined") {
            if (title == "") {
                setTitle(props.folderName);
            }

            if (body == "") {
                setBody(t('common:mails.addto') + props.folderName + ".");
            }
        }
    }, [props.folderName]);

    const getElements = () => {
        let elements = [];
        props.model.forEach((item, index) => {
            elements.push({ label: item.label, value: "field" + item.id, keyLabel: 'label-' + item.key, keyValue: 'value-' + item.key });
        });
        return elements;
    };

    function onKeyPress(e) {
        let insertSelected = inputBodyRef.current.selectionStart !== inputBodyRef.current.selectionEnd
        let before = inputBodyRef.current.selectionStart
        let after = inputBodyRef.current.selectionEnd
        switch (e.key) {
            case 'ArrowLeft':
            case 'ArrowUp':
                if (insertSelected) inputBodyRef.current.setSelectionRange(before, before)
                break;
            case 'ArrowRight':
            case 'ArrowDown':
                if (insertSelected) inputBodyRef.current.setSelectionRange(after, after)
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        setElements([...getElements()]);
    }, [props.fields]);

    useEffect(() => {
        updateInsertedValues()
        if (template === '') { handleChangeTemplateSelect('2001') }
        if (!openDrawer) { handleToggleDrawer() }
        return () => {
            if (!drawerWasOpen) { handleToggleDrawer() }
        }
    }, [])

    const handlePasteContent = (e) => {
        devLog('mouse',)
        navigator.clipboard.readText().then(result => devLog('read', result))
    }

    const handleClickBody = (e) => {
        // devLog('clicked body', e)
    }

    const handleEditBody = (value) => {
        // devLog('changing body || event', value)
        handleChangeBody(value, null, props.field, props.index)
    }

    const handleToggleCc = () => setOpenCc(curr => !curr)

    return (
        <div className={classes.mainField}>
            <div className={classes.subfield}>
                <div className={classes.sideHeader}>
                    <div className={classes.inputContainer} style={{ flex: '1 1 100%' }}>
                        <InputLabel className={classes.margin} htmlFor={"from-" + props.field.key}>
                            {t('common:mails.destinatairefrom')}
                            <Tooltip classes={{ tooltip: classes.tooltipStyle }} title={t('common:smail.info-sender').split('\n').map((c, index) => {
                                return (<p key={'1' + index + '-' + c[0]}> {c} </p>)
                            })} placement="top">
                                <span className="material-icons" style={{ fontSize: 20, color: colors.mainButton }}>
                                    info
                                </span>
                            </Tooltip>
                        </InputLabel>
                        <TextField
                            className={classes.input}
                            id={"from-" + props.field.key}
                            fullWidth={true}
                            placeholder="from"
                            InputProps={{ disableUnderline: true, }}
                            inputlabelprops={{
                                shrink: true,
                            }}
                            inputProps={{
                                fieldkey: "from-" + props.field.key,
                                className: classes.textField,
                            }}
                            margin="none"
                            onChange={(event) => handleChangeFrom(event.target.value, props.field, props.index)}
                            value={from}
                            variant="standard"
                        />
                    </div>
                    <div style={{ width: 'auto', padding: '0 8px', alignSelf: "center" }} onClick={handleToggleCc}>
                        <InputLabel className={classes.margin} htmlFor={"cc-" + props.field.key} style={{ textDecoration: 'underline', cursor: "pointer" }}>Cc/Cci</InputLabel>
                    </div>
                </div>
                {
                    openCc &&
                    <div className={classes.sideHeader}>
                        <div className={classes.inputContainer}>
                            <InputLabel className={classes.margin} htmlFor={"cc-" + props.field.key}>{t('common:mails.destinatairecc')}</InputLabel>
                            <TextField
                                id={"cc-" + props.field.key}
                                placeholder="cc"
                                fullWidth={true}
                                margin="none"
                                inputlabelprops={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    className: classes.textField,
                                    fieldkey: "cc-" + props.field.key,
                                }}
                                InputLabelProps={{
                                    className: classes.margin,
                                }}
                                InputProps={{ disableUnderline: true, }}
                                className={classes.input}
                                onChange={(event) => handleChangeCc(event.target.value, props.field, props.index)}
                                value={cc}
                                variant="standard"
                            />
                        </div>
                        <div className={classes.inputContainer}>
                            <InputLabel className={classes.margin} htmlFor={"cc-" + props.field.key}>{t('common:mails.destinatairecci')}</InputLabel>
                            <TextField
                                id={"cci-" + props.field.key}
                                placeholder="cci"
                                fullWidth
                                margin="none"
                                inputlabelprops={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    className: classes.textField,
                                    fieldkey: "cci-" + props.field.key,
                                }}
                                InputLabelProps={{
                                    className: classes.margin,
                                }}
                                InputProps={{ disableUnderline: true, }}
                                className={classes.input}
                                onChange={(event) => handleChangeCci(event.target.value, props.field, props.index)}
                                value={cci}
                                variant="standard"
                            />
                        </div>
                    </div>
                }
                <InputLabel className={classes.margin} htmlFor={"title-" + props.field.key}>{t('common:mails.sujet')}</InputLabel>
                <div className={classes.field}>
                    <Input
                        id={"title-" + props.field.key}
                        placeholder="title"
                        fullWidth={true}
                        margin="none"
                        disableUnderline={true}
                        inputlabelprops={{
                            shrink: true,
                        }}
                        inputProps={{
                            className: classes.textField,
                            fieldkey: "title-" + props.field.key,
                        }}
                        onSelect={updateSubjectSelectionStart}
                        inputRef={inputSubjectRef}
                        className="text-line-margin"
                        onChange={(event) => handleChangeTitle(event.target.value, props.field, props.index)}
                        value={title}
                    />
                </div>
                <InputLabel className={classes.margin} htmlFor={"body-" + props.field.key}>{t('common:mails.message')}</InputLabel>
                <span style={{ height: '25px', fontSize: 10, fontStyle: 'italic', fontWeight: 'bold', color: colors.red }}>
                    {t('common:smail.info-error')}
                </span>
                <div className={classes.field}>
                    <RichEditor
                        init={{
                            height: 500,
                            menubar: true,
                            plugins: [
                                'advlist', 'anchor', 'autolink', 'image', 'link', 'lists',
                                'searchreplace', 'table', 'wordcount'
                            ],
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                        onClickBody={handleClickBody}
                        onEditorChange={handleEditBody}
                        onInit={(evt, editor) => editorRef.current = editor}
                        // onMouseUp={handlePasteContent}
                        value={body}
                    />
                </div>
                <InputLabel className={classes.margin} style={{ textAlignLast: 'center' }} ref={bottomRef}>***</InputLabel>
                <SMailSideMenu
                    {...props}
                    hasAuto={true}
                    body={body}
                    elements={elements}
                    handleChangeBody={handleChangeBody}
                    handleChangeTemplateRadio={handleChangeTemplateRadio}
                    handleChangeAuto={handleChangeAuto}
                    handleChangeTemplateSelect={handleChangeTemplateSelect}
                    handleToggleAutoJoinAtt={handleToggleAutoJoinAtt}
                    handleToggleAutoJoinCopy={handleToggleAutoJoinCopy}
                    hasPW={false}
                    template={template}
                    note={note}
                    auto={auto}
                    autoAttachments={props?.field?.options?.setup?.autoAttachments}
                    autoCopy={props?.field?.options?.setup?.autoCopy}
                />
            </div>
        </div>
    )
}

// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatSMailConstructorComponent);
