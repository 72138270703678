import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Editor } from '@tinymce/tinymce-react'

import tinymce from 'tinymce/tinymce'
// DOM model
import 'tinymce/models/dom/model'
// Theme
import 'tinymce/themes/silver'
// Toolbar icons
import 'tinymce/icons/default'
// Editor styles
import 'tinymce/skins/ui/oxide/skin.min.css'
// if you use a plugin that is not listed here the editor will fail to load
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/anchor'
import 'tinymce/plugins/autolink'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/autosave'
import 'tinymce/plugins/charmap'
import 'tinymce/plugins/code'
import 'tinymce/plugins/codesample'
import 'tinymce/plugins/directionality'
import 'tinymce/plugins/emoticons'
import 'tinymce/plugins/fullscreen'
import 'tinymce/plugins/image'
import 'tinymce/plugins/importcss'
import 'tinymce/plugins/insertdatetime'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/media'
import 'tinymce/plugins/nonbreaking'
import 'tinymce/plugins/pagebreak'
import 'tinymce/plugins/preview'
import 'tinymce/plugins/quickbars'
import 'tinymce/plugins/save'
import 'tinymce/plugins/searchreplace'
import 'tinymce/plugins/table'
import 'tinymce/plugins/template'
import 'tinymce/plugins/visualblocks'
import 'tinymce/plugins/visualchars'
import 'tinymce/plugins/wordcount'

// importing plugin resources
import 'tinymce/plugins/emoticons/js/emojis'
import { useTranslation } from 'react-i18next'
import { devLog } from '../../../utils/utils'

// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
// import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css'
// import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css'

const useStyles = makeStyles(() => ({
  mainCtn: {
    position: 'relative',
    marginTop: -48,
    marginBottom: -24,
  },
  patch: {
    position: 'absolute',
    right: 8,
    bottom: -5,
    height: 30,
    width: '100%',
    backgroundColor: 'white',
    zIndex: 10,
  },
}))

const RichTextArea = (props) => {
  const classes = useStyles()
  const { init, ...rest } = props
  const { t } = useTranslation('common')

  // const handleDropPaste = () => {
  //   navigator.clipboard.readText()
  //     .then(text => {
  //       devLog('text', text)
  //       tinymce.activeEditor.execCommand('paste', false, { content: text })
  //       navigator.clipboard.writeText('')
  //     })
  // }

  return (
    <div className={classes.mainCtn} >
      <span className={classes.patch} />
      <Editor
        init={{
          ...init,
          skin: false,
          menubar: false,
          content_css: false,
          selector: 'textarea',
          content_style: 'body { font-family: Helvetica, Arial, sans-serif; font-size:14px }',
          text_patterns: false,
        }}
        {...rest}
      />
    </div>
  )
}

export default RichTextArea
