import React, {useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import {isNull} from "lodash";
import { NumericFormat } from 'react-number-format'
import {RestyledNumericInputWithOptions} from "../custom/CustomComponents";
import { devLog, isDefined } from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));

const FormatNumericComponent = (props) => {
    // devLog('FormatNumeric got props', props)
    const classes = useStyles();

    const field = props.field
    const [value, setValue] = useState(!isNull(field.value) ? field.value : '');

    const sepdecimal = field.options?.setup?.sepdecimal || ','
    const septhousand = field.options?.setup?.septhousand !== false ? field.options?.setup?.septhousand : ""
    const decimalScale = field.options?.setup?.numberdecimals || ""

    useEffect(() => {
        setValue(!!props.field.value ? props.field.value : '');
    }, [props.field.value])

    const handleEditValue = (v, infos) => {
        const newValue = v.value
        if (infos?.event !== null && newValue !== value?.toString()) {
            props.handleEditValue(newValue, field, props.index)
            setValue(v.floatValue)
        }
    }

    return (
        <div>
            <NumericFormat
                {...props}
                allowedDecimalSeparators={[",", "."]}
                className={classes.numericFormatElement}
                customInput={RestyledNumericInputWithOptions}
                disabled={props.disabled || props.field.disabled || field?.options?.setup?.editable === false}
                decimalScale={decimalScale === '' ? 99 : parseInt(decimalScale)}
                decimalSeparator={sepdecimal}
                onValueChange={handleEditValue}
                thousandSeparator={septhousand}
                value={value}
                valueIsNumericString
            />
        </div>
    )
}

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {}
}

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(FormatNumericComponent);
