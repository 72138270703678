import React, { memo, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { PlugTooltip } from '../../components/custom/CustomComponents'
import { MdNoteAdd, MdPostAdd } from 'react-icons/md'
import Dropzone from 'react-dropzone'
import colors from '../../assets/themes/Colors'
import { devLog, handlePreventDefault, isDefined } from '../../utils/utils'
import { fileIsImg, handleOpenPreview, renderAnnexePdfPreviewThumbnail } from '../../utils/annexes'
import { useHorizontalScroll } from '../../utils/hooks/useHorizontalScroll'
import ModalAddAnnexeCommentComponent from '../../components/modals/ModalAddAnnexeCommentComponent'
import { BackupTable } from '../../components/custom/icons/CustomIcons'
import AttachmentTableView from './AttachmentTableView'
import { AiOutlineFileAdd } from 'react-icons/ai'
import { toast } from 'react-toastify'
import { DrawerContext } from '../../App'

const useStyles = makeStyles((theme) => ({
  bannerCtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '-webkit-fill-available',
    // justifyContent: 'space-between',
    borderRadius: 5,
    marginBottom: 10,
    marginTop: -10,
  },
  borderedCtn: {
    border: "2px solid " + colors.greyLight,
    borderRadius: 5,
    "@media (max-width: 1024px)": {
      border: "none",
    },
    position: 'relative',
    width: '-webkit-fill-available',
  },
  labelledIcon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: 80,
  },
  drawer: {
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  openClose: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  openCloseButton: {
    float: 'left',
    padding: '8px 4px',
    cursor: 'pointer',
  },
  dropzone: {
    alignSelf: 'flex-start',
    display: 'flex',
    flexDirection: 'row',
  },
  dropzoneCtn: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: "dashed 2px #51C3C1",
    borderRadius: 5,
  },
  attachmentTitle: {
    color: colors.unselected,
    fontWeight: "600",
    whiteSpace: "pre-line",
    textAlign: 'center',
  },
  attachmentBox: {
    borderRadius: 5,
    margin: 5,
    height: 90,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: 'center',
    alignItems: "center",
    cursor: 'pointer',
    position: 'relative',
    backgroundColor: colors.greenLightest,
    '&:hover': {
      boxShadow: '2px 2px 4px 1px ' + colors.mainAlpha,
    },
  },
  smallBox: {
    margin: 0,
    minWidth: 60,
  },
  lgHalf: { width: 120, maxWidth: 120, },
  leftHalf: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRightWidth: 0,
  },
  rightHalf: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderLeftWidth: 0,
  },
  attContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
    height: 90,
    overflowY: 'auto',
    "&::-webkit-scrollbar": {
      display: "block",
      overflowX: 'auto',
      height: 8,
      background: '#E3E3E3',
      width: 3,
      position: 'absolute',
      cursor: 'grab',
    },
    "&::-webkit-scrollbar-thumb": {
      background: colors.main,
      borderTop: '3px white solid',
      backgroundClip: 'padding-box',
    },
  },
  itemContainer: {
    '&:hover': {
      backgroundColor: colors.hoverBgnd,
      whiteSpace: 'wrap',
      boxShadow: '2px 2px 4px 1px ' + colors.mainAlpha,
    },
    border: '2px solid white',
    borderRadius: 4,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 4,
    cursor: "pointer",
  },
  borderedItemCtn: {
    border: '2px solid ' + colors.main,
    overflow: "hidden",
  },
  lgPreview: {
    minWidth: 120,
    alignItems: 'flex-start',
  },
  smPreview: {
    minWidth: 40,
    justifyContent: 'center',
  },
  xlPreview: {
    minWidth: 200,
  },
  attListCtn: {
    overflowX: 'auto',
    maxWidth: props => props.open ? 'calc(75vw - 250px)' : 'calc(75vw - 200px)',
    flex: 1,
    margin: '0px 8px',
  },
  attAnnexName: {
    whiteSpace: 'pre-line',
    overflow: "hidden",
    alignSelf: "flex-start",
    fontSize: 14,
    marginTop: -5,
    maxWidth: 80,
    color: 'white',
    wordBreak: 'break-all',
  },
  attIconCtn: {
    // display: "flex",
    // justifyContent: "space-between",
    // alignItems: "flex-start",
    // alignSelf: 'flex-start',
    // flexDirection: 'column',
    color: colors.mainButton,
    height: '-webkit-fill-available',
  },
  mainColor: {
    color: colors.plugnotesLight,
    cursor: 'pointer',
    whiteSpace: "pre-line",
  },
  attIconType: {
    display: "flex",
    flexDirection: "column",
    alignItems: 'center',
    fontSize: 12,
    cursor: "pointer",
    '@media (max-width: 1300px)': {
      textAlignLast: 'center',
    },
    color: colors.white,
    position: 'relative',
  },
  attIconShadow: {
    fontSize: '64px',
    color: colors.plugnotesGris,
    position: 'absolute',
    top: '-11px',
    right: '-28px',
    zIndex: 1
  },
  memoCount: {
    width: '50px',
    fontSize: '10px',
    textAlign: 'center',
    color: colors.unselected,
    lineHeight: '10px',
  },
  flexVertical: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 2,
  },
  flexHorizontal: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 4,
    padding: 4,
  },
  button: {
    width: 60,
    height: 30,
    padding: 5,
    borderRadius: 5,
    boxShadow: '0px 1px 2px 1px #ddd',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: colors.mainButton,
    color: 'white',
    '&:hover': {
      boxShadow: '0px 0px 10px 1px #aaa',
      fontWeight: 'bold',
      borderColor: colors.mainButton,
    },
  },
  warnToast: {
      backgroundColor: colors.unselected,
  },
}))

const AttachmentDropzone = ({ openBanner, disabled, handleCreateNote, handleDropdownFiles, fullMode }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  if (disabled === true) return null;
  return (
    <div className={`${classes.dropzoneCtn} scrollbar-hidden`} >
      <Dropzone onDrop={handleDropdownFiles}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className={classes.dropzone}>
            <input {...getInputProps()} />
            <PlugTooltip title={t('multiple.attachement-drag-and-drop')} placement="bottom">
              <div
                className={`${classes.attachmentBox} ${fullMode ? undefined : classes.leftHalf} ${!fullMode ? classes.smallBox : classes.lgHalf}`}
                style={{ height: openBanner ? 90 : 56, width: fullMode ? 160 : 60 }}
              >
                <div className={classes.flexHorizontal} >
                  <AiOutlineFileAdd style={{ fontSize: 36, color: colors.main }} />
                  {fullMode && <span className={classes.mainColor}>{t("multiple.add-attachments")}</span>}
                </div>
              </div>
            </PlugTooltip>
            {
              fullMode && <div style={{
                padding: 4,
                // borderLeft: '2px solid ' + colors.mainButton,
                width: 20,
                height: 48
              }} />
            }
            <PlugTooltip title={t('multiple.add-note')} placement="bottom">
              <div
                className={`${classes.labelledIcon} ${classes.attachmentBox} ${fullMode ? undefined : classes.rightHalf} ${!fullMode ? classes.smallBox : classes.lgHalf} cursor icon-style `}
                onClick={handleCreateNote}
                style={{ height: openBanner ? 90 : 56, width: fullMode ? 160 : 60 }}
              >
                <div className={classes.flexHorizontal} >
                  {
                    fullMode
                    && <span className={classes.mainColor}>{t("multiple.add-note")}</span>
                  }
                  <MdPostAdd style={{ fontSize: 36, color: colors.main }} />
                </div>
              </div>
            </PlugTooltip>
          </div>
        )}
      </Dropzone>
    </div>
  )
}

const AttachmentItem = ({ annexe, disabled, downloadFile, handleClickItem, handleDeleteAnnexe, handleEditComment, handleHighlightAnnexe, handleNoteOpen, handleRenderLargePreview, handleUpdateAnnexe, index, isAuthorizedToEdit, mode, openBanner, renderAnnexeIconPreview, renderAnnexeImagePreview }) => {
  const [openTooltip, setOpenTooltip] = useState(false)
  const classes = useStyles()
  const itemRef = useRef(null)
  const { t } = useTranslation('common')

  const handleOnClick = (e) => {
    setOpenTooltip(false)
    handleClickItem(e, annexe)
  }

  const handleShowMore = (e) => {
    handlePreventDefault(e)
    handleHighlightAnnexe(annexe.id)
  }

  return (
    <PlugTooltip
      key={annexe.name}
      title={
        <div style={{ display: 'flex', flexDirection: 'column', gap: 4, maxHeight: 'calc(90vh - ' + itemRef?.current?.getBoundingClientRect()?.y || 0 + 'px)'}}>
          {fileIsImg(annexe) && renderAnnexeImagePreview(annexe)}
          {annexe.type === 'pdf' && renderAnnexePdfPreviewThumbnail(annexe, colors.mainButton, 300, 300)}
          <span style={{ fontWeight: 'bold' }}>{annexe.name}</span>
          {
            annexe.comment?.length > 0 &&
            <div>
              <span className="material-icons" >
                info
              </span>
              <span style={{ fontStyle: 'italic' }}>{annexe.comment}</span>
            </div>
          }
        </div>
      }
      onClose={() => setOpenTooltip(false)}
      onMouseEnter={() => setOpenTooltip(true)}
      open={openTooltip}
      placement="bottom"
    >
      <div
        className={`${classes.itemContainer} ${classes.borderedItemCtn} ${openBanner ? classes.lgPreview : classes.smPreview}`}
        key={index + 1}
        onClick={handleOnClick}
        ref={itemRef}
      >
        <div className={classes.flexVertical} >
          <div className={classes.attIconType} >
            {
              annexe.comment?.length > 0 &&
              <span style={{ position: 'absolute', top: -12, left: -12, fontWeight: 'bold', }}>
                <span className="material-icons" style={{ fontSize: 20, color: colors.mainButton }}>
                  info
                </span>
              </span>
            }
            {
              openBanner
                ? handleRenderLargePreview(annexe)
                : renderAnnexeIconPreview(annexe, colors.mainButton, 32)
            }
          </div>
          {
            openBanner &&
            <div className={classes.attAnnexName} style={{ color: colors.unselected, width: '-webkit-fill-available' }} >
              {
                annexe.comment?.length > 0
                  ? annexe.comment
                  : annexe.name
              }
            </div>
          }
        </div>
        {
          openBanner &&
          <div className={classes.attIconCtn} >
          <PlugTooltip title={t('helper.more-options')} placement="right">
                <span className="material-icons" onClick={handleShowMore} >
                  more_vert
                </span>
              </PlugTooltip>
            {/* {
              disabled === false &&
              <PlugTooltip title={t('actions.delete')} placement="right">
                <span className="material-icons" onClick={(event) => handleDeleteAnnexe(event, annexe)} >
                  delete
                </span>
              </PlugTooltip>
            }
            {
              mode !== "modal-plugnote-create" && typeof isAuthorizedToEdit !== "undefined" && isAuthorizedToEdit(annexe.type) &&
              <PlugTooltip title={t('actions.update')} placement="right">
                <span
                  id="icon-file_"
                  className="material-icons"
                  onClick={(event) => handleUpdateAnnexe(event, annexe)}
                >
                  publish
                </span>
              </PlugTooltip>
            }
            {
              !mode.includes('modal') &&
              (annexe.type !== "note" ?
                <PlugTooltip title={t('actions.download')} placement="right">
                  <span
                    id="icon-file_copy"
                    className="material-icons"
                    onClick={(event) => downloadFile(event, annexe)}
                  >
                    get_app
                  </span>
                </PlugTooltip>
                :
                <span
                  id="icon-file_copy"
                  className="material-icons md-25"
                  onClick={(event) => handleNoteOpen(event, annexe)}
                >
                  create
                </span>)
            }
            <div>
              {
                annexe.groupid !== 0 ?
                  <span>{annexe.groupid}</span>
                  :
                  <span />
              }
            </div>
            <PlugTooltip title={t('actions.comment-edit')} placement="right">
              <div>
                <span className="material-icons" onClick={(e) => handleEditComment(e, annexe)}>
                  info
                </span>
              </div>
            </PlugTooltip> */}
          </div>
        }
      </div>
    </PlugTooltip>
  )
}

const AttachmentBanner = (props) => {
  // devLog('Attachment Banner got props', props)
  const horizontalScrollRef = useHorizontalScroll()
  const { openDrawer, handleToggleDrawer } = useContext(DrawerContext)
  const classes = useStyles({ open: openDrawer })
  const { t } = useTranslation('common')

  const openBanner = props.open
  const [selectedItem, setSelectedItem] = useState(null)
  const [openComment, setOpenComment] = useState(false)
  const [openTable, setOpenTable] = useState(false)
  const [annexes, setAnnexes] = useState(props.annexes)
  const [highlighted, setHighlighted] = useState(null)

  const handleDropdownFiles = (files) => {
    devLog('files', files)
    if (Array.isArray(files)) {
      // for (const file of files) {
      //   props.onChangeHandlerDrop([file])
      // }
      if (files.length > 1) {
        toast.warn(
          'One file at a time',
          {
            autoClose: 3000,
            hideProgressBar: true,
            bodyClassName: classes.warnToast,
            className: classes.warnToast,
            toastClassName: classes.warnToast,
          })
      } else {
        props.onChangeHandlerDrop(files)
      }
    }
  }

  const handleClickItem = (e, annexe) => {
    handlePreventDefault(e)
    if (!openBanner) { props.handleToggleBanner() }
    setSelectedItem(annexe)
    handleOpenAnnexePreview(e, annexe)
  }

  const handleRenderLargePreview = annexe => {
    if (fileIsImg(annexe)) {
      return <span style={{ maxWidth: 100, maxHeight: 52, overflow: 'hidden' }}>{props.renderAnnexeImagePreview(annexe, colors.mainButton, 20)}</span>
    } else if (annexe.type === 'pdf') {
      return <span style={{ maxWidth: 100, maxHeight: 52, overflow: 'hidden' }}>{renderAnnexePdfPreviewThumbnail(annexe, colors.mainButton, 80)}</span>
    } else {
      return props.renderAnnexeIconPreview(annexe, colors.mainButton, 20)
    }
  }

  const handleEditComment = (e, annexe) => {
    handlePreventDefault(e)
    setSelectedItem(annexe)
    setOpenComment(true)
  }

  const handleOpenAnnexePreview = (e, annexe) => {
    handlePreventDefault(e)
    if (annexe.type === 'note') { props.handleNoteOpen(e, annexe) }
    else handleOpenPreview(e, props.getAnnexeUrl(annexe))
  }

  const handleHighlightAnnexe = (id) => {
    setHighlighted(id)
    setOpenTable(true)
  }

  const handleCloseTableView = () => {
    setHighlighted(null)
    setOpenTable(false)
  }

  const tableColumns = useMemo(() => {
    return [
      {
        id: 900,
        label: t('document.doctype'),
        format: 'text',
        colWidth: 60,
      },
      {
        id: 901,
        label: t('document.docfilename'),
        format: 'text',
        colWidth: 160,
      },
      {
        id: 902,
        label: t('document.doccomment'),
        format: 'text',
        colWidth: 180,
      },
      {
        id: 903,
        label: t('document.last-moddate'),
        format: 'text',
        colWidth: 100,
      },
      {
        id: 904,
        label: t('document.docstockage'),
        format: 'file-uri',
        colWidth: 80,
      },
      {
        id: 905,
        label: t('document.docsize') + ' (kb)',
        format: 'numeric',
        colWidth: 80,
      },
    ]
  }, [])

  useEffect(() => {
    if (!props.open) { setSelectedItem(null) }
  }, [props.open])

  useEffect(() => {
    // devLog('u-e props.annexes', props.annexes)
    setAnnexes(props.annexes)
  }, [props.annexes])

  const Dropzone = memo(AttachmentDropzone)

  return (
    <div className={classes.bannerCtn} style={!openBanner ? { height: 60 } : undefined}>
      <Dropzone
        disabled={props.disabled}
        handleCreateNote={props.handleCreateNote}
        handleDropdownFiles={handleDropdownFiles}
        openBanner={openBanner}
      />
      <div style={{ width: 'auto' }} className={classes.attListCtn} >
        <div className={classes.attContainer} style={{ height: openBanner ? 90 : 56 }} ref={horizontalScrollRef}>
          {
            annexes?.slice()
              .sort((a, b) => a?.position - b?.position)
              .map((annexe, index) => (
                <AttachmentItem
                  annexe={annexe}
                  key={index}
                  open={openBanner}
                  disabled={props.disabled}
                  downloadFile={props.downloadFile}
                  handleClickItem={handleClickItem}
                  handleDeleteAnnexe={props.handleDeleteAnnexe}
                  handleEditComment={handleEditComment}
                  handleHighlightAnnexe={handleHighlightAnnexe}
                  handleNoteOpen={props.handleNoteOpen}
                  handleRenderLargePreview={handleRenderLargePreview}
                  handleUpdateAnnexe={props.handleUpdateAnnexe}
                  index={index}
                  isAuthorizedToEdit={props.isAuthorizedToEdit}
                  mode={props.mode}
                  openBanner={openBanner}
                  renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                  renderAnnexeImagePreview={props.renderAnnexeImagePreview}
                />
            ))}
        </div>
      </div>
      <PlugTooltip title={t('multiple.table-view')} placement='bottom'>
        <div
          onClick={() => setOpenTable(props.annexes?.length > 0)}
          className={classes.button}
          style={{ backgroundColor: props.annexes?.length > 0 ? colors.mainButton : colors.disabled, height: openBanner ? 90 : 56, cursor: !(props.annexes?.length > 0) ? 'not-allowed' : 'pointer', marginLeft: 'auto' }}>
          <BackupTable size={28} color="white" disabled={!(props.annexes?.length > 0)} />
        </div>
      </PlugTooltip>
      {
        selectedItem !== null && openComment &&
        <ModalAddAnnexeCommentComponent
          modal={props.modal}
          open={openComment}
          close={() => setOpenComment(false)}
          annexe={selectedItem}
          selectedAnnexe={selectedItem}
          mode={props.mode}
          handleSaveFictifComment={props.handleSaveComment}
          handleSaveComment={props.handleSaveComment}
        />
      }
      {
        openTable &&
        <AttachmentTableView
          annexes={annexes}
          columns={tableColumns}
          disabled={props.disabled}
          downloadFile={props.downloadFile}
          Dropzone={
            <Dropzone
              disabled={props.disabled}
              handleCreateNote={props.handleCreateNote}
              handleDropdownFiles={handleDropdownFiles}
              openBanner={openBanner}
              fullMode
            />
          }
          getAnnexeUrl={props.getAnnexeUrl}
          handleDeleteAnnexe={props.handleDeleteAnnexe}
          handleDropdownFiles={handleDropdownFiles}
          handleEditComment={handleEditComment}
          handleOnClose={handleCloseTableView}
          handleOpenAnnexePreview={handleOpenAnnexePreview}
          handleSaveComment={props.handleSaveComment}
          handleReorderAnnexes={props.handleReorderAnnexes}
          handleUpdateAnnexe={props.handleUpdateAnnexe}
          highlighted={highlighted}
          isAuthorizedToEdit={props.isAuthorizedToEdit}
          key={props.annexes}
          mode={props.mode}
          open={openTable}
          renderAnnexeImagePreview={props.renderAnnexeImagePreview}
        />
      }
    </div>
  )
}

export default AttachmentBanner
