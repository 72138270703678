import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { connect } from "react-redux";
import { getCurrentEnvironnement } from "../../utils/Common";
import TextField from "@material-ui/core/TextField";
import { putAnnexeCommentModalRequest, putAnnexeCommentRequest, } from "../../actions/annexe";

function getModalStyle() {
  const top = 25;

  return {
    top: `${top}%`,
    margin: "auto",
    overflow: "auto",
    //transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  popup: {
    position: "absolute",
    minWidth: "50%",
    maxWidth: "90%",
    backgroundColor: "white",
    width: "820px",
    border: "1px solid #DEDEDE",
    boxSizing: "border-box",
    boxShadow:
      "0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)",
    borderRadius: 8,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "40%",
    margin: "auto",
  },
  modalBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '91%',
    margin: '0 auto',
  },
  marginLeft: {
    marginLeft: "10px",
  },
  buttonSaveComment: {
    width: "98%",
    padding: '8px 20px',
    color: 'white',
    backgroundColor: '#58c3c1',
    border: 'none',
    borderRadius: '3px',
    margin: '5px auto 10px',
    cursor: 'pointer',
  },
  attach_comment_input: {
    flexGrow: 1,
    margin: '0 5px 5px',
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(10px, -10px) scale(0.9)',
      maxHeight: 24,
      paddingBottom: 4,
      overflow: 'hidden',
    },
  },
}))

const ModalAddAnnexeCommentComponent = (props) => {
  const classes = useStyles()
  const [modalStyle] = useState(getModalStyle);
  const { t } = useTranslation("common")
  const [comment, setComment] = useState(props.annexe.comment);
  const modal = typeof props.modal !== "undefined" ? props.modal : false
  const environment = getCurrentEnvironnement();

  const handleCloseModal = () => {
    props.close();
  };

  const handleSaveComment = () => {
    if (modal) {
      props.attemptUpdateCommentAnnexeModal(
        props.annexe.hash,
        comment,
        environment.userhash
      );
    } else {
      props.attemptUpdateCommentAnnexe(
        props.annexe.hash,
        comment,
        environment.userhash
      );
    }
    props.close();
  };

  const handleFictifSaveComment = () => {
    props.handleSaveFictifComment(props.annexe, comment);
    props.close();
  };

  return (
    <Modal
      keepMounted={false}
      open={props.open}
      onClose={handleCloseModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
    >
      <div key={props.countable} style={modalStyle} className={classes.popup}>
        <div className={classes.modalBody}>
          <div onClick={handleCloseModal} style={{ textAlign: 'end', }}>
            <span
              id="icon-close"
              className="material-icons md-50 lightgrey"
            >
              close
            </span>
          </div>
          <TextField
            label={t("search.plug-search-annexe-comment-type-title") + ' : ' + props.annexe.name}
            multiline
            minRows={4}
            defaultValue={props.annexe.comment}
            variant="outlined"
            className={classes.attach_comment_input}
            onChange={(event) => setComment(event.target.value)}
            maxRows={20}
          />
          <button
            className={classes.buttonSaveComment}
            onClick={props.mode !== 'external-shared-create' && props.mode !== "plugcode-complete-modal" && props.mode !== 'modal-shared-create' && props.mode !== 'plugcode-complete-gallery' && props.mode !== 'plugcode-complete' && props.mode !== 'modal-plugnote-create' ? handleSaveComment : handleFictifSaveComment}
            disabled={comment.length === 0}
          >
            {t("common:multiple.save")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    attemptUpdateCommentAnnexe: (annexehash, comment, userhash) =>
      dispatch(putAnnexeCommentRequest(annexehash, comment, userhash)),
    attemptUpdateCommentAnnexeModal: (annexehash, comment, userhash) =>
      dispatch(putAnnexeCommentModalRequest(annexehash, comment, userhash)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalAddAnnexeCommentComponent);
