import React, { useEffect, useState } from "react"
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import colors from "../../assets/themes/Colors";
import { postExtDataReset } from "../../actions/extdata";
import { convertersAlphaToNumber, currentDate, devLog, getSearchCriteria, isDefined } from "../../utils/utils";
import { HyperFormula } from "hyperformula";
import ModalListExtDataMultipleComponent from "../modals/extdata/ModalListExtDataMultipleComponent";
import { createEmptyRow, getAllDefaultRows, getLabelWithSpecialChar, isRowLimitReached, isVisibleInCSV, populateRowWithDefaultValues } from "../../utils/Fields";
import { postSendMailV3Request } from "../../actions/utils";
import { ModalTablemultiTableViewComponent } from "../modals/tablemulti/ModalTablemultiTableViewComponent";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import { PlugTooltip } from "../custom/CustomComponents";
import {isNull} from "lodash";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
	mainCtn: {
		width: '100%',
		marginTop: isMobile ? -50 : -30,
		overflowY: 'visible',
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		gap: 8,
	},
    urlButton:{
        /* border: '1px red solid', */
        display: 'inline-block',
        color: "white",
        backgroundColor: "#58c3c1",
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    mainPhoneContainer:{
        marginTop : '20px',
    },
    phoneContainer:{
        border: '1px black solid',
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    mailButton:{
        alignSelf: 'center',
        display: 'inline-block',
        color: "white",
        backgroundColor: colors.mainButton,
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    unregisteredBtn: {
        alignSelf: 'center',
        display: 'inline-block',
        color: "white",
        backgroundColor: colors.bloodOrange,
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    textFieldArea: {
        padding: 10,
        margin: '10px auto',
        fontWeight: '500',
        borderRadius: '5px',
        backgroundColor: '#FFFFFF',
        fontSize: '14px',
        width: '100%',
        border: '1px solid #ccc',
        MozBorderRadius : '5px',
        WebkitBorderRadius: '5px',
    },
    inputArea: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    table: {
        //maxWidth : 'fit-content',
        // width: "99%",
        userSelect: 'none',
        "&::-youbkit-user-select": {
            color: 'red',
            backgroundColor: 'yellow',
        },

    },
    tableRoot: {
        // width: "99%", //it was 100%
        marginTop: 8,
        overflowX: "auto",
        //marginRight: 'auto',
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    margin_5:{
        margin : '5px',
        width : '100%'
    },
    margin_left_5:{
        marginLeft : '5px',
    },
    input_add_item:{
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        // marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F1F1F1',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    scrollBar: {
        // padding: '5px',
        overflowX: 'auto',
        scrollbarWidth: 'thin', //without buttons on firefox, change to 'auto' if buttons wanted
        scrollbarColor: '#9B9B9B #E3E3E3', //first color to scrollbutton and the second to the bar
        "&::-webkit-scrollbar": {
            display: "block",
            overflowX: 'auto',
            height: '10px',
            background: '#E3E3E3',
            width: '5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background: '#9B9B9B',
        },
        //if buttons wanted
        /*  "&::-webkit-scrollbar-button:single-button": {
             backgroundColor: '#bbbbbb',
       display: 'block',
       height: '13px',
       width: '16px',
         }, */

    },
    addCellButton: {
        backgroundColor: colors.greyLighter,
        borderBottom: 'none'
    },
    rowDelCellButton: {
        backgroundColor: colors.greyLighter,
        display: 'flex',
        alignItems: 'center',
    },
    rowNum: {
        color: colors.grey,
        //fontWeight: 'bold',
    },
    tableHeader: {
        padding: 15,
    },
    tableMultButton: {
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.main,
        // fontSize: 22,
    },
    tableMultAddButton: {
        // width : 20,
        // height : 20,
        display : 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.main,
        fontSize: 28,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    tableMultExportButton: {
        width : 20,
        height : 20,
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: colors.main,
        color: colors.main,
        fontSize: 16,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    headerSeparator: {
        borderColor: colors.greyLight,
        borderStyle: 'solid',
        borderWidth: 0,
        borderRightWidth: 1,
        borderBottomWidth: 1,
        padding: 10,
    },
    RadioGroupCell: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 20,
        //border: '1px solid greenyellow',
    },
    radioIcon: {
        color: colors.main,
        width: 25,
    },
    radioIconChecked: {
        color: colors.main,
    },
    radioLabel: {
        fontSize: 10,
    },
    stickyCol:{
        position: "sticky",
        left: '30px',
        background: colors.white,
        zIndex: 100,
        width :'100%',
        wordBreak: 'break-all',
        borderBottom: 'none'
    },
    stickyColRight:{
        position: "sticky",
        left: 0,
        textAlignLast: 'center',
        zIndex: 50,
        borderBottom: 'none'
    },
    paginationSpacer: {
        flex: 'none',
    },
    tableMultPageText: {
        color: colors.main,
        fontSize: 15,
        display: 'flex',
        gap: '8px'
    },
    tableMultPageChoice: {
        color: colors.white,
        backgroundColor: colors.unselectedAlpha,
        height: 24,
        fontSize: 15,
        alignSelf: 'center',
        padding: '0 5px',
        borderRadius: '6px'
    },
    //Render Item Single Select Component Styles
    renderSingleSelMainContainer: {
        justifyContent: 'space-between',
    },
    renderSingleSelContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
        flexWrap: 'wrap',
    },
    renderBlankContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        // background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
        flexWrap: 'wrap',
    },
    renderSingleFormulaContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
    },
    renderInputContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
        flexWrap: 'no-wrap',
    },
    renderSingleSelSubContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    renderSingleSelPreselNum: {
        fontSize: 10,
        color: colors.greyLight,
        border: `1px solid ${colors.greyLight}`,
        borderRadius: 50,
        padding: '1px 2px',
    },
    renderSingleSelIcon: {
        fontSize: 28,
        color: colors.greyDark,
    },
    fontChange: {
        fontSize: 12,
        paddingLeft: '5px!important',
    },
    fontChangeLabel: {
        fontSize: 12,
        fontWeight: 'bold',
        color: colors.main
    },
    fontChangeWidth: {
        fontSize: 12,
        minWidth : 120
    },
    fontError: {
        fontSize: 12,
        color: colors.red,
        fontWeight: 'bold',
        paddingLeft: '5px!important',
        width: '-webkit-fill-available',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    formatInputFieldComposed: {
        marginLeft: '29%',
        borderRadius: "4px",
        paddingRight: '8px',
        height: 41,
        // padding: "5px 0",
        overflowX: 'visible!important', //temp fix for f32 size issue
    },
    formatInputFieldLockedComposed: {
        backgroundColor: colors.white,
        marginLeft: '29%',
        paddingRight: '7px',
        borderRadius: "4px",
        height: 41,
        overflowX: 'visible!important', //temp fix for f32 size issue
    },
    upliftedContainer: {
        marginTop: '-37px',
        overflowY : 'visible'
    },
    searchField: {
        border: "solid #E3E3E3 1px",
        borderRadius: "4px",
        padding: '0px 10px',
        margin: '0px 10px'
    },
    searchBtn: {
        fontSize: 15,
        minWidth: 70,
    },
    textFont: {
        fontSize: 12,
        // fontWeight: 'bold',
        paddingLeft: '5px!important',
    },
    textLength: {
        fontSize: 8,
        position: 'absolute',
        top: -6,
        right: 0,
    },
    fontFieldNumber: {
        fontSize: 8,
        marginRight: 2,
        color: colors.main,
        border: '1px solid',
        padding: '0px 2px',
        borderRadius: '50%',
        verticalAlign: 'bottom',
    },
    btnContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    selectItemContainer: {
        display: 'flex',
        gap: 3,
        minHeight: 27,
        width: '100%',
        flexWrap: 'wrap',
    },
    selectItem: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    selectedItem: {
        color: colors.white,
        backgroundColor: colors.selectedAlpha,
        padding: '0px 5px',
        margin: '1px 0px',
        borderRadius: 4,
        cursor: 'pointer',
        fontSize: 13,
    },
    unselectedItem: {
        color: colors.white,
        backgroundColor: colors.unselectedAlpha,
        padding: '0px 5px',
        margin: '1px 0px',
        borderRadius: 4,
        cursor: 'pointer',
        fontSize: 13,
    },
    noSelectedItem: {
        color: colors.dark,
        fontSize: 13,
        fontStyle: 'italic',
        marginLeft: 10
    },
    selectIcon: {
        left: 2,
        fontSize: 28,
        cursor: 'pointer',
    },
    selectSelect: {
        marginLeft: 33,
        paddingLeft: 2,
    },
    selectField: {
        minHeight: 24
    },
    fieldContainer: {
        display : 'flex',
        alignItems : 'flex-start',
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: 3,
    },
    widerFieldValueContainer: {
        marginLeft: -50,
        order: 2,
        width : 'calc(60% + 32px)',
    },
    fieldTitleContainer: {
        marginRight: 'auto',
        order: 1,
        width: '40%',
    },
    fieldValueContainer: {
        display: 'flex',
        marginLeft: 'auto',
        minHeight: 33,
        order: 2,
        width : '60%'
    },
    fieldValues: {
        padding: 0,
        paddingLeft: 5,
        cursor: 'pointer',
        wordBreak: 'break-word',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'left',
    },
    fieldLabels: {
        fontWeight: 'bold',
        wordBreak: 'break-word',
        color: colors.main,
        textAlign: 'left',
        paddingLeft: 5,
    },
    attItemsContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 5,
        overflow: "hidden",
        marginLeft : '10px'
    },
    attItem: {
        display: "flex",
        alignItems: "center",
        gap: 5,
    },
    labelContainer: {
        width: '15%',
    },
    inputContainer: {
        width: '80%',
    },
    header: {
        position: 'absolute',
        top: 0,
        width: '100%',
    },
    modalBody: {
        paddingTop: 50,
        position: 'relative',
        paddingBottom: 50
    },
    modalFooter: {
        '@media (min-height:640px)': {
            display: 'none'
        },
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },
    RadioGroupModal: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        // paddingLeft: 20,
        //border: '1px solid greenyellow',
    },
    showOption: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        gap: 5
    },
    radioIconModal: {
        color: colors.mainButton,
        backgroundColor: colors.mainButton
    },
    radioLabelModal: {
        fontSize: 14,
        cursor: 'pointer'
    },
    '&input[name="show-empty-option"]': {
        backgroundColor: 'blue'
    },
    pwcodeContainer: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: 30,
        gap: 10,
    },
    modalBtnContainer: {
        display: 'inline-flex',
        width: '100%',
        justifyContent: "space-between",
    },
    arrowRight: {
        textAlign: 'left',
        height: 28,
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignSelf: 'center',
        "@media (max-width: 640px)": {
            fontSize: 18
        },
        color: colors.main,
        borderBottom: 'none',
    },
    arrowLeft: {
        textAlign: 'right',
        height: 30,
        justifyContent: 'flex-end',
        alignItems: 'center',
        alignSelf: 'center',
        "@media (max-width: 640px)": {
            fontSize: 18
        },
        color: colors.main,
        borderBottom: 'none',
    },
    hidden: {
        color: colors.greyLighter
    },
    tripleCell: {
        '&.MuiTableCell-root': {
            borderBottom: 'none !important'
        },
        height: 30,
        display: 'flex',
        flexDirection: 'row',
    },
    valuesCell: {
        borderBottom: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    totalValues: {
        color: colors.bloodOrange,
        padding: 0,
        paddingLeft: 5,
        wordBreak: 'break-word',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'left',
        fontSize: 12,
    },
    //New design
    summaryCtn: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: 5
    },
    summary: {
        paddingRight: 4
    },
    rowCtn: {
        alignSelf: 'flex-end',
        justifyContent: 'flex-end',
        zIndex: 200,
        display: 'flex',
        width: '70%',
        flexDirection: isMobile ? 'column' : 'row',
        gap: isMobile ? 8 : 0,
    },
    topBtnCtn: {
        right: 0,
        gap: 4,
        display: 'flex',
        flexDirection: 'row',
		justifyContent: isMobile ? 'center' : 'flex-start',
    },
    topBtn: {
        width: 60,
        height: 30,
        padding: 5,
        borderRadius: 5,
        boxShadow: '0px 1px 2px 1px #ddd',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: colors.mainButton,
        color: 'white',
		'&:hover': {
			boxShadow: '0px 0px 10px 1px #aaa',
			fontWeight: 'bold',
			borderColor: colors.mainButton,
		},
	},
    disabledBtn: {
        cursor: 'not-allowed',
        backgroundColor: colors.iconGrey,
        '&:hover': {
            boxShadow: '0px 0px 10px 1px #aaa',
            fontWeight: 'bold',
            borderColor: colors.iconGrey,
        },
    },
    openRow: {
        backgroundColor: colors.greyLighter
    },
    pageRowCtn: {
        height: 30,
        verticalAlign: 'middle',
        backgroundColor: colors.greyLighter,
        borderBottom: `1px solid ${colors.greyLight}`
    },
    warnToast: {
        backgroundColor: colors.unselected,
    },
}))

const FormatSMailValidMultiComponent = (props) => {
    let tmpDisabled = false;
    if (typeof props.disabled !== "undefined"){
        tmpDisabled = props.disabled;
    } else if (typeof props.field.disabled !== "undefined"){
        tmpDisabled = props.field.disabled;
    }

    const classes = useStyles();
    const { t } = useTranslation(['common']);

    let tmpColonnes = props.field.options?.lines?.length > 0 ? props.field?.options?.lines : []
    if (props.mode.includes('plugcode')) tmpColonnes = tmpColonnes.filter((col) => col.visible !== false)

    const [colonnes, ] = useState(tmpColonnes);
    const [rows , setRows] = useState(props.field.value?.list?.length > 0 ? props.field?.value?.list : []);
    const [disabled , setDisabled] = useState(tmpDisabled)
    const [openDownload, setOpenDownload] = useState(false);
    const [datasToDownload, setDatasToDownload] = useState([]);
    const [excessLimit, setExcessLimit] = useState(0)
    const [openModal, setOpenModal] = useState(false)
    const [value, setValue] = useState(!!props.field?.value?.value ? props.field?.value?.value : "")

    const setup = props.field?.options?.setup
	const defaultRows = setup?.defaultRows || []
	const rowLimit = setup?.rowLimit === 0 ? Infinity : setup?.rowLimit || Infinity

    let hideSearch = props?.model?.authoriseExternalDbInPf === undefined ? false  : !props?.model?.authoriseExternalDbInPf;
    if (typeof props.hideSearch !== "undefined") { hideSearch = props.hideSearch }

    const owner = typeof props.owner === 'undefined' ? null: props.owner
    const options = props.field.options
    const ref = options.ref
    const field = options.field
    const column = options.column
    const columns = options.columns
    const dest = options.dest
    const ownerId = props.ownerId
    const criteria = getSearchCriteria(options.query)
    const hasDb = (ref !== '' && column !== '' && columns !== '') || false
    const optionFormule = { licenseKey: 'gpl-v3' }

    //TableView
    const [newRow, setNewRow] = useState(null)
    const [openTable, setOpenTable] = useState(false)

    //Add pw line when missing //! inutile: feature not released
    // if (colonnes.length > 0 && share) {
    //     if (colonnes[1].label !== 'PW-code') {
    //         let copyRows = [...colonnes]
    //         copyRows.splice(1, 0, {'label':'PW-code', 'formatLabel':'Texte', 'format':'text', 'mask':'40', 'presel':null, 'id':1000, 'regex':[], 'configuration':null, 'visible':true, 'editable':false})
    //         setColonnes(copyRows)
    //     }
    //     // console.log('cols after', colonnes);
    // }
    // //Remove pw line if no share
    // if (colonnes.length > 0 && !share) {
    //     if (colonnes[1].label === 'PW-code') {
    //         let copyRows = [...colonnes]
    //         copyRows.splice(1, 1)
    //         // setRows(copyRows)
    //     }
    //     console.log('rows after', colonnes);
    // }

    //function deleteEmpty pour supprimer une rows[n] vide dans un useEffect ([])
    function deleteEmptyRows (rows) {
        let newRows = []
        if (rows.length > 0) {
            rows.forEach((row, index) => {
                let value = ''
                Object.values(row).forEach((valeur, id) => {
                    if (valeur !== '' && valeur !== undefined) value = valeur
                })
                if (value !== '') newRows.push(row)
            })
        }
        return newRows
    }

    useEffect(() => {
        if (rows.length > 0) {
            let newRows = deleteEmptyRows(rows)
            setRows(newRows)
        } else if ((props.mode.includes('create') || props.mode.includes('complete')) && defaultRows?.length > 0) {
            if (setup.defaultComplete === 'open') {
                let _rows = getAllDefaultRows(colonnes, defaultRows)
                //devLog('initial rows', _rows)
                setRows(_rows)
            }
        }
    }, [])

    const handleAddTableMulti = async () => {
        let rowLimit = isDefined(setup?.rowLimit) ? setup?.rowLimit : 0
        let isEmpty = true
        let _rows = rows.filter(row => !row.hasOwnProperty('recap'));
        _rows.filter(Boolean)
        if ((rowLimit > 0 && _rows.length < rowLimit) || rowLimit === 0) {
            let _newRow = createNewRowWithDefaultValues()
            if (_rows.length > 0) {
                let lastOne = _rows[_rows.length - 1]
                Object.values(lastOne).forEach(k => {
                    let control = typeof k !== "string" ? k?.toString() : k;
                    if (control !== "" && control !== '0:00' && control?.length > 0) { isEmpty = false }
                })
                if (!isEmpty) {
                    setRows([..._rows, _newRow]);
                    setNewRow(_newRow)
                } else {
                    setNewRow(_rows[_rows.length - 1])
                }
            } else {
                setRows([_newRow])
                setNewRow(_newRow)
            }
        } else {
            toast.info(t('tablemulti.row-limit-warn'),
                {
                    autoClose: 3000,
                    hideProgressBar: true,
                    bodyClassName: classes.warnToast,
                    className: classes.warnToast,
                    toastClassName: classes.warnToast,
                })
        }
    }

    const createNewRowWithDefaultValues = () => {
        let row = createEmptyRow(colonnes)
        return populateRowWithDefaultValues(colonnes, defaultRows, rows.length, row)
    }

    const handleClickAddRow = () => {
        handleAddTableMulti()
        if(!openTable) setOpenTable(true)
    }

    const handleDuplicateRow = async (row) => {
        // let _newRowDuplicated = row
        let _newRowDuplicated = { ...row }
        if (rows.length > 0) {
            let isEmpty = true
            let _rows = rows.filter(row => !row.hasOwnProperty('recap'));
            _rows = _rows.filter(Boolean);
            if ((rowLimit > 0 && _rows.length < rowLimit) || rowLimit === 0) {
                if (_rows.length > 0) {
                    let lastOne = _rows[_rows.length - 1];
                    Object.values(lastOne).forEach((k) => {
                        let control = typeof k !== "string" ? k.toString() : k;
                        if (control !== "" && control !== '0:00' && control.length > 0) isEmpty = false
                    })
                    if (!isEmpty) {
                        setRows([..._rows, _newRowDuplicated]);
                    }
                    else {
                        //setOpenRow(_rows.length - 1)
                    }
                }
                else {
                    setRows([_newRowDuplicated]);
                }
            } else {
                if (!toast.isActive()) {
                    toast.info(t('tablemulti.row-limit-warn'),
                        {
                            autoClose: 3000,
                            hideProgressBar: true,
                            bodyClassName: classes.warnToast,
                            className: classes.warnToast,
                            toastClassName: classes.warnToast,
                        })
                }
            }
        } else {
            setRows([...rows, _newRowDuplicated])
        }
    }

    const handleExportTableMulti = () => {
		let datas = [];
		if (colonnes.length > 0) {
			let line1 = [];
			line1.push(t('export.export-date'))
			line1.push(t('export.export-key'))
			line1.push(t('export.export-number'))
			line1.push(t('export.export-field-number'))
			line1.push(t('export.export-field'))
			colonnes.forEach(col => {
				if (isVisibleInCSV(col, props.mode)) line1.push(getLabelWithSpecialChar(col.label))
			})
			datas.push(line1);
			rows.forEach((row, index) => {
				let data = [];
				let now = new Date()
				data.push(now.toLocaleString())
				data.push(props.plugKey)
				data.push(props.plugNb)
				data.push(props.index + 1 + (row.recap === true ? ' - Total' : '.' + (index + 1)))
				data.push(props.title)

				colonnes.forEach(col => {
					if (isVisibleInCSV(col, props.mode)) {
					let someText = typeof row[col.id] === 'string' ? row[col.id].replace(/\r?\n?/g, '').trim() : row[col.id];
					if (someText === undefined) {
						someText = "";
					}
					data.push(someText);
				}})

				datas.push(data);
			});
		}
		if (datas.length > 0) {
			setDatasToDownload(datas);
			setOpenDownload(true);
		}
	}

    useEffect(() => {
        if (openDownload){
            setDatasToDownload([]);
            setOpenDownload(false);
        }
    }, [openDownload]);

    const handleRemoveSpecificRow = (value) => {
        let items
        if (Array.isArray(value)) { // Array des index à supprimer
            items = rows.filter((_, index) => !value.includes(index + 1))
        } else {
            items = rows.filter((_, index) => index !== value);
        }
        setRows(items);
    }

    const handleChangeTableValue =  () => {
        props.handleChangeTableValue(value, rows, props.field, props.index);
    }

    const handleEditValue = (value) => {
        setValue(value);
        props.handleChangeTableValue(value, rows, props.field, props.index);
    }

    const handleCalculateValueAuto = async (row, formula) => {
        let answer = null;
        let tmp = [];

        if (formula !== null && formula !== '') {
            colonnes.forEach(elm => {
                let tmpValue = row[elm.id];
                if (elm.format === 'numeric'){
                    tmpValue = row[elm.id].replace(', ', '.');
                }
                tmp.push([tmpValue]);
            });
            let data = tmp;
            const sheetInfo = {
                pnSheet: {
                    sheetName: "F32"
                },
                formulas: {
                    sheetName: "Formulas"
                }
            };

            let sheetAData = data;

            let newFormula =  formula;

            const hfInstance = HyperFormula.buildEmpty(optionFormule);

            let formulasData = [[
                newFormula
            ]];

            // add 'TeamA' sheet
            hfInstance.addSheet(sheetInfo.pnSheet.sheetName);
            // insert playersA content into targeted 'TeamA' sheet
            hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo.pnSheet.sheetName), sheetAData);

            // add a sheet named 'Formulas'
            hfInstance.addSheet(sheetInfo.formulas.sheetName);
            // add formulas to that sheet
            hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo.formulas.sheetName), formulasData);


            const cellAddress = hfInstance.simpleCellAddressFromString(
                `${sheetInfo.formulas.sheetName}!A1`, hfInstance.getSheetId(sheetInfo.formulas.sheetName)
            );

            try {
                const mySum = hfInstance.getCellValue(cellAddress);

                if (!mySum.hasOwnProperty('type')){
                    answer =  mySum;
                }else{
                    answer =  "";
                }
            }catch (e){
                //setResult(e.message)
                answer =  "";
            }

            hfInstance.destroy();

        }

        return answer;

    }

    const handleChangeChildrenValue = async (value, col, rindex) => {
        // console.log('changechildren col - row - rindex', col, row, rindex);
        let row = rows[rindex]

        let copyColonnes = [...colonnes];
        let obj = copyColonnes.filter(o => o.format === 'formula').filter(Boolean);

        if (col !== null && col !== undefined) switch (col.format) {
            case 'presel':
                let multiselect = col.mask.multiselect;
                let editable = col.mask.editable;
                if (multiselect === false && editable === false){
                    row[col.id] =  value;
                    for (const list of obj) {
                        let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                        if (tmpValue !== null){
                            row[list.id] = tmpValue
                        }
                    }
                    rows[rindex] = row;
                    setRows([...rows]);
                }
                else if (multiselect === false && editable === true){
                    row[col.id] =  value;
                    if (obj) {
                        for (const list of obj) {
                            let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                            if (tmpValue !== null){
                                row[list.id] = tmpValue
                            }
                        }
                    }
                    rows[rindex] = row;
                    setRows([...rows]);
                }
                else if (multiselect === true && editable === false){
                    row[col.id] =  value;
                    if (obj) {
                        for (const list of obj) {
                            let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                            if (tmpValue !== null){
                                row[list.id] = tmpValue
                            }
                        }
                    }
                    rows[rindex] = row;
                    setRows([...rows]);
                }
                else if (multiselect === true && editable === true){
                    row[col.id] =  value;
                    if (obj) {
                        for (const list of obj) {
                            let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                            if (tmpValue !== null){
                                row[list.id] = tmpValue
                            }
                        }
                    }
                    rows[rindex] = row;
                    setRows([...rows]);
                }
                break;
            case 'radio':
                if (col.id === 1002){
                    let colDate = colonnes.filter((colonne) => colonne.id === 1003);
                    if (colDate.length > 0){
                        if (props.profil !== null){
                            row[colDate[0].id] = props.profil.lastname + ' ' + props.profil.firstname + ' - ' +  currentDate();
                        }
                        else row[colDate[0].id] = currentDate();
                    }
                    row[col.id] =  value;

                    if (obj) {
                        for (const list of obj) {
                            let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                            if (tmpValue !== null){
                                row[list.id] = tmpValue
                            }
                        }
                    }
                    rows[rindex] = row;
                    setRows([...rows]);
                }
                else {
                    row[col.id] =  value;
                    if (obj) {
                        for (const list of obj) {
                            let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                            if (tmpValue !== null){
                                row[list.id] = tmpValue
                            }
                        }
                    }
                    rows[rindex] = row;
                    setRows([...rows]);
                }
                break;
            default:
                row[col.id] =  value;
                if (obj) {
                    for (const list of obj) {
                        let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                        if (tmpValue !== null){
                            row[list.id] = tmpValue
                        }
                    }
                }
                setRows(previous => {
                    let _rows = [...previous]
                    _rows[rindex] = row;
                    return _rows
                });
                break;
        }
    }

    const handleChangeChildrenRowValue = (value, col, row, rindex) => {
        // devLog('handleChangeChildrenRowValue', value, col, row, rindex)
        if (col?.format === 'extdata') {
            rows[rindex] = value
            setRows([...rows])
        } else if (col?.id === 1000) {
            let _row = {...row}
            _row[1000] = value
            rows[rindex] = _row
            setRows([...rows])
        }
    }

    const handleViewTable = () => {
        setOpenTable(true)
    }

    function handleCloseTable () {
        setOpenTable(false)
        setNewRow(null)
        // delTableView()
    }

    useEffect(() => {
        // console.log('u-e rows')
        handleChangeTableValue();
    }, [rows]);

    useEffect (() => {
        setValue(!!props.field?.value?.value ? props.field?.value?.value : "");
        setRows(!!props.field?.value?.list ? props.field?.value?.list : []);
    }, [props.field])

    useEffect(() => {
        let tmpDisabled = false;
        if (typeof props.disabled !== "undefined"){
            tmpDisabled = props.disabled;
        }
        else if (typeof props.field.disabled !== "undefined"){
            tmpDisabled = props.field.disabled;
        }
        setDisabled(tmpDisabled);
    }, [props]);


    const handleOpenSearchModel = () => {
        props.attemptGetDataFromAnotherBaseByClientReset();
        setOpenModal(true);
    }

    const handleSelectData = (objects) => {
        let datas = [];
        if (objects.length > 0){
            objects.forEach((object, indexObject) => {
                let jsonObjectToArray = Object.values(object)
                let destinations = [];
                let champs = dest.split('-');
                champs.map((elm, index) => {
                    let field = elm.split(':');

                    if (field.length > 0 && field.length <= 3){

                        let dest = colonnes[(field[0] - 1)].id;
                        let col = field[1];
                        let copyValue = col?.split(';') || col;
                        let value = ""

                        copyValue.map((item, index) => {
                            let alphaNumIndexDest = convertersAlphaToNumber(item.toUpperCase()) - 1;
                            let data = jsonObjectToArray[alphaNumIndexDest];
                            if (typeof data !== "undefined"){
                                let temp = data;
                                if (temp !== "" && typeof temp !== "undefined"){
                                    if (temp === null){
                                        temp = "";
                                    }

                                    if (temp !== ""){
                                        if (item === item.toUpperCase()) {
                                            value = value === "" ? value.concat(temp) : value.concat(' ~ ' + '\n'+ temp);
                                        }else{
                                            value = value === "" ? value.concat(temp) : value.concat(' ~ ' + temp);
                                        }
                                    }
                                }
                            }
                        });
                        destinations.push({
                            "dest" : dest,
                            "value" : value
                        })
                    }
                })
                if (destinations.length > 0){
                    let data = createEmptyRow(colonnes)
                    destinations.forEach((item, index) => {
                        data[item.dest] = item.value;
                    })
                    datas.push(data)
                }
            });
        }
        let rowLimit = setup?.rowLimit
        if (typeof rowLimit !== "undefined" && rowLimit > 0 && datas.length > rowLimit - rows.length) {
            setExcessLimit(previous => {
                let excess = datas.length - (rowLimit - rows.length)
                if (excess !== 100 && previous > 0) {
                    toast.dismiss('row-limit-toast')
                    toast.info(
                        t('tablemulti.row-limit-warn') + ': ' + (previous + excess) + t('tablemulti.rows-ignored'),
                        {
                            autoClose: 3000,
                            hideProgressBar: true,
                            bodyClassName: classes.warnToast,
                            className: classes.warnToast,
                            toastClassName: classes.warnToast,
                        })
                    return 0
                }
                else { return previous + excess }
            })
            datas = datas.slice(0, rowLimit - rows.length)
        }
        if (datas.length > 0) {
            const newRows = [...rows]
            datas.forEach(item => {
                newRows.push(item)
            })
            setRows(newRows)
        }
    }

    const handleSelectDataMultiCombo = async (objects, currentColumn, currentRow) => {
        let datas = [];
        let datasFields = null;
        let destCombo = currentColumn?.configuration.dest;

        if (objects.length > 0) {
            let resolve = new Promise((resolve, reject) => {
                objects.map(async (object, indexObject) => {
                    let jsonObjectToArray = Object.values(object)
                    let destinations = [];
                    let fieldDestinations = [];
                    let champs = destCombo.split('-');

                    champs.forEach((elm, index) => {
                        try {
                            let field = elm.split(':');
                            if (field.length > 0) {
                                let fieldDest = field[0];
                                if(fieldDest.startsWith('F')){
                                    fieldDest = fieldDest.replace('F', '');
                                    let dest = fieldDest
                                    let col = field[1];
                                    let copyValue = col?.split(';') || col;
                                    let value = "";

                                    let lineDest = null;
                                    let colDest = null;

                                    if(typeof field[2] !== "undefined" && typeof field[3] !== "undefined"){
                                        lineDest = field[2];
                                        colDest = field[3];
                                    }

                                    if(typeof props.fields[(dest - 1)] !== "undefined"){
                                        if(props.fields[(dest - 1)].format === "glocalis"){
                                            value = {
                                                latitude : "",
                                                longitude : "",
                                                address : ""
                                            }
                                        }
                                        else if(props.fields[(dest - 1)].format === "tablemultin" || props.fields[(dest - 1)].format === "smailvalidmulti" || props.fields[(dest - 1)].format === "document"){
                                            value = {
                                                value : "",
                                                list : [],
                                            }
                                        }
                                        else if(props.fields[(dest - 1)].format === "intdatamaj"){
                                            value = {
                                                value : "",
                                                updatedDate : "",
                                            }
                                        }else if(props.fields[(dest - 1)].format === "tablemulti"){
                                            value = [];
                                        }
                                        copyValue.map((item, index) => {
                                            let alphaNumIndexDest = convertersAlphaToNumber(item.toUpperCase()) - 1;
                                            let data = jsonObjectToArray[alphaNumIndexDest];

                                            if(typeof data !== "undefined"){
                                                let temp = data;
                                                if(temp !== "" && typeof temp !== "undefined"){
                                                    if(temp === null){
                                                        temp = "";
                                                    }

                                                    if(temp != ""){
                                                        switch (props.fields[(dest - 1)].format) {
                                                            case 'free-single-select':
                                                            case 'single-select':
                                                            case 'multi-select':
                                                            case 'free-multi-select':
                                                                try {
                                                                    value = [temp];
                                                                }catch (e) {
                                                                    value = [];
                                                                }
                                                                break;
                                                            case 'glocalis' :
                                                                value.address = value.address == "" ? value.address.concat(temp) : value.address.concat(' ' + temp) ;
                                                                break;
                                                            case 'tablemulti':
                                                                if(!isNull(lineDest) && !isNull(colDest)){
                                                                    let field = props.fields.find((field, index) => Number(field.id) === Number(dest));
                                                                    if(field){
                                                                        let tmpValue = field?.value ? field.value : null;
                                                                        if(!isNull(tmpValue)){
                                                                            if (tmpValue.length > 0 && Array.isArray(tmpValue)) {
                                                                                let options = field.options?.lines ? field.options.lines : field.options;
                                                                                let targert = (lineDest.replace('L', ''));
                                                                                let targettable = (colDest.replace('C', '') );
                                                                                tmpValue[(targert - 1)][options[(targettable - 1)].id] = temp;
                                                                                value = tmpValue;
                                                                            }else{
                                                                                let options = field.options?.lines ? field.options.lines : field.options;
                                                                                let newLine = {};
                                                                                for (const option of options) {
                                                                                    newLine[option.id] = "";
                                                                                }
                                                                                let targert = (lineDest.replace('L', ''));
                                                                                let targettable = (colDest.replace('C', '') );
                                                                                newLine[options[(targettable - 1)].id] = temp;
                                                                                let newValue = [];
                                                                                newValue[(targert - 1)] = newLine
                                                                                value = newValue;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                break;
                                                            case 'tablemultin' :
                                                            case 'smailvalidmulti' :
                                                                if(isNull(lineDest) && isNull(colDest)){
                                                                    value.value = temp;
                                                                }else if(!isNull(lineDest) && !isNull(colDest)){
                                                                    let field = props.fields.find((field, index) => Number(field.id) === Number(dest));
                                                                    if(field){
                                                                        let tmpValue = field?.value?.list ? field.value.list : null;
                                                                        if(!isNull(tmpValue)){
                                                                            if (tmpValue.length > 0 && Array.isArray(tmpValue)) {
                                                                                let options = field.options?.lines ? field.options.lines : field.options;
                                                                                let targert = (lineDest.replace('L', ''));
                                                                                let targettable = (colDest.replace('C', '') );
                                                                                tmpValue[(targert - 1)][options[(targettable - 1)].id] = temp;
                                                                                value.list = tmpValue;
                                                                            }else{
                                                                                let options = field.options?.lines ? field.options.lines : field.options;
                                                                                let newLine = {};
                                                                                for (const option of options) {
                                                                                    newLine[option.id] = "";
                                                                                }
                                                                                let targert = (lineDest.replace('L', ''));
                                                                                let targettable = (colDest.replace('C', '') );
                                                                                newLine[options[(targettable - 1)].id] = temp;
                                                                                let newValue = [];
                                                                                newValue[(targert - 1)] = newLine
                                                                                value.list = newValue;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                break;
                                                            case 'localis' :
                                                                value = value == "" ? value.concat(temp) : value.concat(' ' + temp) ;
                                                                break;
                                                            case 'intdatamaj' :
                                                                value.value = temp;
                                                                break;
                                                            default:
                                                                if (item == item.toUpperCase()) {
                                                                    value = value == "" ? value.concat(temp) : value.concat(' ~ ' + '\n'+ temp);
                                                                }else{
                                                                    value = value == "" ? value.concat(temp) : value.concat(' ~ ' + temp);
                                                                }
                                                                break;

                                                        }
                                                    }
                                                }
                                            }
                                        });


                                        if(props.fields[(dest - 1)].format === "glocalis" ){
                                            value = JSON.stringify(value);
                                        }

                                        fieldDestinations.push({
                                            "dest" : dest,
                                            "value" : value
                                        })
                                    }
                                }
                                else {
                                    let dest = colonnes[(fieldDest - 1)].id;
                                    let col = field[1];
                                    let copyValue = col?.split(';') || col;
                                    let value = ""

                                    copyValue.forEach((item, index) => {
                                        let alphaNumIndexDest = convertersAlphaToNumber(item.toUpperCase()) - 1;
                                        let data = jsonObjectToArray[alphaNumIndexDest];
                                        if (typeof data !== "undefined") {
                                            let temp = data;
                                            if (temp !== "" && typeof temp !== "undefined") {
                                                if (temp === null) {
                                                    temp = "";
                                                }

                                                if (temp !== "") {
                                                    if (item === item.toUpperCase()) {
                                                        value = value === "" ? value.concat(temp) : value.concat(' ~ \n' + temp);
                                                    }
                                                    else {
                                                        value = value === "" ? value.concat(temp) : value.concat(' ~ ' + temp);
                                                    }
                                                }
                                            }
                                        }
                                    });
                                    destinations.push({
                                        "dest": dest,
                                        "value": value
                                    })
                                }
                            }
                        }catch (e) {

                        }
                    })

                    if (destinations.length > 0) {
                        let copyColonnes = [...colonnes];
                        let obj = copyColonnes.filter(o => o.format === 'formula').filter(Boolean);
                        let obj2 = copyColonnes.filter(o => o.format === 'numeric-ref').filter(Boolean);
                        let obj3 = copyColonnes.filter(o => o.format === 's33data').filter(Boolean);

                        let data = createEmptyRow(colonnes);
                        destinations.map(async (item, index) => {
                            let col = colonnes.find(o => ((o.id === Number(item.dest)) && (o.format === 'numeric')));

                            if (typeof col === "undefined") {
                                data[item.dest] = item.value;
                            } else {
                                data[item.dest] = item.value.replace(',', '.');
                            }

                            if(obj2){
                                for (const list2 of obj2) {
                                    let tmpValue = data[list2.id];
                                    if (tmpValue == "") {
                                        let target = list2?.configuration?.target;
                                        if (typeof target !== "undefined") {
                                            let field = props.fields.find((field, index) => Number(field.id) === Number(target));
                                            if (field) {
                                                data[list2.id] = field.value;
                                            }
                                        }
                                    }
                                }
                            }

                            if(obj3){
                                for (const list3 of obj3) {
                                    let value = data[list3.id];

                                    try {
                                        if (value !== "") {
                                            let target = list3?.configuration?.target;
                                            let targettable = list3?.configuration?.targettable;
                                            if (typeof target !== "undefined") {
                                                let field = props.fields.find((field, index) => Number(field.id) === Number(target));
                                                if (field) {
                                                    let tmpValue = field?.value?.list ? field.value.list : field.value;
                                                    if (tmpValue.length > 0 && Array.isArray(tmpValue)) {
                                                        let options = field.options?.lines ? field.options.lines : field.options;
                                                        data[list3.id] = tmpValue[(value - 1)][options[(targettable - 1)].id];
                                                    }
                                                }
                                            }
                                        } else {
                                            data[list3.id] = value;
                                        }
                                    } catch (e) {
                                        data[list3.id] = value
                                    }
                                }
                            }

                            if (obj) {
                                for (const list of obj) {
                                    let tmpValue = await handleCalculateValueAuto(data, list?.configuration?.formula);
                                    if (tmpValue !== null) {
                                        data[list.id] = tmpValue
                                    }
                                }
                            }
                        })

                        datas.push(data);
                        //props.handleChangeExtData(destinations);
                    }

                    if(fieldDestinations.length > 0){
                        datasFields = fieldDestinations;
                    }

                    resolve(true);
                });

            })

            if ((datas.length > 0 || (!isNull(datasFields) && datasFields.length > 0)) && await resolve) {
                if(datas.length > 0 ){
                    let newRows = [...rows]
                    if (newRows.length > 0) {
                        if (newRows[(newRows.length - 1)].hasOwnProperty('recap')) {
                            newRows = newRows.filter((row, index) => index !== (newRows.length - 1));
                        }
                    }

                    let forek = new Promise((resolve, reject) => {
                        datas.forEach((item, index, array) => {
                            if(typeof currentRow !== "undefined" && index === 0){
                                newRows[currentRow] = item
                            }else{
                                newRows.push(item);
                            }
                            if (index === array.length -1) resolve();
                        });
                    });

                    forek.then(() => {
                        //if (recap) setRows([...newRows, createRecapDataForce(newRows)]);
                        //else
                            setRows(newRows);
                    });
                }

                if (!isNull(datasFields) && datasFields.length > 0) {
                    props.handleChangeExtData(datasFields);
                }
            }
        }
    }


    useEffect(() => {
		if (excessLimit > 0 && !toast.isActive('row-limit-toast')) {
			toast.warn(t('tablemulti.row-limit-warn'), {
				hideProgressBar: true,
				bodyClassName: classes.warnToast,
				className: classes.warnToast,
				toastClassName: classes.warnToast,
				toastId: 'row-limit-toast',
				isLoading: true,
				autoClose: 3000,
			})
		}
	}, [excessLimit])

    const handleClickPreview = (index, id) => {
        handleViewTable()
    }

	const isLimitReached = () => {
		return isRowLimitReached(props.field?.options?.setup?.rowLimit, rows)
    }

    const titleFormat = `[ ${field} ] ( ${ref} ) [ ${columns} ] [ ${column} ]`

    return (
        colonnes.length > 0 &&
        <div className={classes.mainCtn}>
            <div className={classes.rowCtn}>
                {
                    hasDb &&
                    <TextField
                        disabled={disabled}
                        onChange={(event) => handleEditValue(event.target.value)}
                        id={'search-db-input-' + props.field.id}
                        type="text"
                        multiline={false}
                        InputProps={{
                            disableUnderline: true,
                            endAdornment: (
                                <React.Fragment>
                                    {
                                        !hideSearch && !disabled &&
                                        <div className={classes.searchBtn} > {criteria}
                                            <span id="icon-listForm" className={`material-icons md-25`} style={{ color: colors.main }} onClick={(event) => handleOpenSearchModel()}>
                                                search
                                            </span>
                                        </div>
                                    }
                                </React.Fragment>
                            ),
                        }}
                        helperText=""
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        className={classes.searchField}
                        value={value}
                    />
                }
                <div className={classes.topBtnCtn}>
                    {
                        !disabled && rows?.length < rowLimit &&
                        <PlugTooltip title={isLimitReached() ? t('tablemulti.row-limit-warn') : t('tablemulti.add-row')} placement="top">
                            <div className={`${classes.topBtn} ${isLimitReached() ? classes.disabledBtn : undefined} `} colSpan={3} onClick={handleClickAddRow}>
                                <span id="icon-add-playlist" className={` material-icons`} style={isLimitReached() ? { cursor: 'not-allowed' } : undefined}>
                                    add_circle_outline
                                </span>
                            </div>
                        </PlugTooltip>
                    }

                    {
                        rows.length > 0 &&
                        <PlugTooltip title={t('tablemulti.table-view').concat(` ${rows.length} `).concat(t('tablemulti.rows'))} placement="top">
                            <div className={classes.topBtn} onClick={handleViewTable}>
                            <span id="icon-add-playlist" className="material-icons">
                                visibility
                            </span>
                                <span style={{fontSize: 10, fontWeight: 'bolder',}}>
                                {`(${rows.length})`}
                            </span>
                            </div>
                        </PlugTooltip>
                    }
                </div>
            </div>
            {
                openModal &&
                <ModalListExtDataMultipleComponent titlePerso={props.title} titleFormat={titleFormat} format="extdata" open={openModal} close={(event) => setOpenModal(false)} options={options} owner={owner} ownerId={ownerId} value={value} fields={props.fields} field={props.field} handleSelectData={handleSelectData} external={props.external} />
            }
            <ModalTablemultiTableViewComponent
                {...props}
                attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
                attemptSendMailSimple={props.attemptSendMailSimple}
                colonnes={colonnes}
                controlCreate={props.controlCreate}
                controlUpdate={props.controlUpdate}
                datasToDownload={datasToDownload}
                disabled={disabled}
                field={props.field}
                fields={props.fields}
                handleAddRecap={() => null}
                handleAddTableMulti={handleClickAddRow}
                handleChangeChildrenRowValue={handleChangeChildrenRowValue}
                handleChangeChildrenValue={handleChangeChildrenValue}
                handleClickPreview={handleClickPreview}
                handleClose={handleCloseTable}
                handleDeleteRow={handleRemoveSpecificRow}
                handleDuplicateRow={handleDuplicateRow}
                handleEditValue={handleEditValue}
                handleExportTableMulti={handleExportTableMulti}
                handleOpenSearchModel={handleOpenSearchModel}
                handleSafeExitPlugnote={props.handleSafeExitPlugnote}
                hasDb={hasDb}
                hideSearch={hideSearch}
                isFromPwCode={props.isFromPwCode}
                isPreview={!openTable}
                mode={props.mode}
                newRow={newRow}
                open={openTable}
                openDownload={openDownload}
                openModal={openModal}
                owner={owner}
                ownerId={ownerId}
                plugnoteNb={isDefined(props.plugnote) ? props.plugnote.userindex : props.plugNb}
                renderAnnexeIconPreview={props.renderAnnexeIconPreview}
                rows={rows}
                search={value}
                setNewRow={setNewRow}
                share={props.field?.options?.share === undefined ? false : props.field.options?.share}
                title={props.title}
                shared={props?.shared}
                ownerhash={typeof props.owner !== "undefined" ? props.owner : props.mainHash}
                handleSelectDataMultiCombo={handleSelectDataMultiCombo}
            />
        </div>
    )
};

const mapStateToProps = (state) => {
    return {
        main : state?.user || null,
        user : state?.user?.user || null,
        user2 : state?.user || null,
        profil : state?.user?.profil || null,
        mainHash : state?.user?.profil?.hash || null,
        "plugKey": state?.model?.model?.clef || null
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptGetDataFromAnotherBaseByClientReset: () => dispatch(postExtDataReset()),
    attemptSendMailSimple: (payload, userhash,ownerhash) => dispatch(postSendMailV3Request(payload,userhash,ownerhash)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatSMailValidMultiComponent);
