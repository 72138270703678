import axios from 'axios'
import ApplicationConfig from '../config/ApplicationConfig'
import { trackPromise } from 'react-promise-tracker'
import qs from 'querystringify'
import { toast } from 'react-toastify'
import i18n from '../translations/i18n'
import { devLog, isDefined } from './utils'
import { fieldsWithColumns, isEditableColumnMode, isEditableField } from './Fields'

export const getPlugnoteFromCode = async (plugCode, userhash) => {
  const token = sessionStorage.getItem('token')
  let plugnote = null
  if (plugCode.startsWith('PF')) {
    await trackPromise(
      axios.get(ApplicationConfig.apiUrl + 'pfcode/modelcodelogin.json' + qs.stringify({
        code: encodeURIComponent(plugCode),
        uh: encodeURIComponent(userhash)
      }, '?'), { // token
        headers: {
          'X-AUTH-TOKEN': token
        }
      })
        .then((response) => {
          // console.log('response from API :', response)
          if (response.data.result === true || response.data.result === 'ok') {
            plugnote = (response.data)
          } else if (response.data.result === false || response.data.result === 'onk') {
            toast.warn(`${i18n.t('common:template.code-error')}`, {
              position: toast.POSITION.TOP_CENTER,
              autoClose: 5000
            })
            return (null)
          } else if (response.data.result === 'nok') {
            // toast.warn(`${i18n.t('common:template.code-error')}`, {
            //   position: toast.POSITION.TOP_CENTER,
            //   autoClose: 5000,
            //   className: `${classes.errorNote}`
            // })
            return (null)
          } else {
            // toast.warn(`${i18n.t('common:template.code-error')}`, {
            //   position: toast.POSITION.TOP_CENTER,
            //   autoClose: 5000,
            //   className: `${classes.errorNote}`
            // })
            return (null)
          }
        })
        .catch((response) => {
          // je lance une notification (toast error)
          // toast.error(<ErrorToastMessage title='global.plugnotes' message='plugcode.delete-plugcode-success' />)
        })
    )
  } else {
    await trackPromise(
      axios.get(ApplicationConfig.apiUrl + 'pncode/datacodelogin.json' + qs.stringify({
        code: encodeURIComponent(plugCode),
        uh: encodeURIComponent(userhash)
      }, '?'),
        { // token
          headers: {
            'X-AUTH-TOKEN': token
          }
        }
      )
        .then((response) => {
          console.log('response from API :', response)
          if (response.statusText === 'OK' && typeof response.data.result === 'undefined') {
            plugnote = (response.data)
          } else if (response.data.result === false) {
            // toast.warn(`${i18n.t('common:template.code-error')}`, {
            //   position: toast.POSITION.TOP_CENTER,
            //   autoClose: 5000,
            //   className: `${classes.errorNote}`
            // })
            return (null)
          } else if (response.data.result === 'nok') {
            // toast.warn(`${i18n.t('common:template.code-error')}`, {
            //   position: toast.POSITION.TOP_CENTER,
            //   autoClose: 5000,
            //   className: `${classes.errorNote}`
            // })
            return (null)
          } else {
            // toast.warn(`${i18n.t('common:template.code-error')}`, {
            //   position: toast.POSITION.TOP_CENTER,
            //   autoClose: 5000,
            //   className: `${classes.errorNote}`
            // })
            return (null)
          }
        })
        .catch((response) => {
          // toast.error(<ErrorToastMessage title='global.plugnotes' message='plugcode.delete-plugcode-success' />)
        })
    )
  }
  return plugnote
}

export const hasMissingMandatoryValue = (fields, mode) => {
  // console.log('checking missing mandatory values', fields, mode)
  let missing = []
  fields.forEach((field) => {
    if (isDefined(field.format) && isEditableField(field, mode)) {
      if (!!field.mandatory && field.mandatory === 1) {
        if (fieldsWithColumns.includes(field.format)) {
          if (isDefined(field?.value?.list)) {
            if (field.value.list?.length === 0 || field.value?.length === 0) {
              missing.push({ field: field })
            }}
        } else if (field.format.includes('select')) {
            if (field.value.length === 0) {
              missing.push({ field: field })
          }} else if (field.format === 'list') {
            if ((!field.value || field.value === '[]' || field.value.length === 0) && field.format !== "indice") {
            missing.push({ field: field })
          }} else if (field.format === 'boolean') {
            if (field.value === "") {
            missing.push({ field: field })
          }} else {
          if (!field.value && field.format !== "indice") {
            missing.push({ field: field })
          }
        }
      }
      if (fieldsWithColumns.includes(field.format)) {
        const rows = isDefined(field?.value?.list) ? field.value.list : field.value
        if (rows.length > 0) {
          // Looking for mandatory column
          const columns = isDefined(field?.options.lines) ? field.options.lines : field.options
          const mandatoryCol = columns.filter(entry => entry?.mandatory === true && isEditableColumnMode(field, entry, mode))
          // console.log('mandatory columns', columns, field)
          rows.forEach((row, rindex) => {
            if (row?.recap !== true) {
              mandatoryCol.forEach(col => { if (row[col.id] === "" || !isDefined(row[col.id])) {
                missing.push({ field: field, rindex: rindex, column: col })
              } }) 
            }
          })
        }
      }
    }
  })
  console.log('missing values', missing)
  return missing.length === 0 ? false : missing
}