import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core'
import i18n from "i18next"
import { View, StyleSheet, Text } from "@react-pdf/renderer";
import { devLog, isDefined } from '../../../utils/utils';
import colors from '../../../assets/themes/Colors';
import { getDataFontSize, getLabelFontSize } from '../../../utils/pdf';

const styles = StyleSheet.create({
  table: {
    borderTop: '1px solid ' + colors.borderGrey,
    fontFamily: 'Poppins',
    fontSize: 10,
    fontWeight: 'light',
  },
  tableRow: {
    flexDirection: 'row',
    flexGrow: 1,
    borderLeft: '1px solid ' + colors.borderGrey,
    borderRight: '1px solid ' + colors.borderGrey,
    borderBottom: '1px solid ' + colors.borderGrey,
  },
  labelColumn: {
    backgroundColor: colors.palePlugGreen,
    paddingLeft: 12,
  },
  numberRow: {
    backgroundColor: colors.palePlugGreen,
  },
  tableCell: {
    flex: 1,
    borderLeft: '1px solid ' + colors.borderGrey,
  },
  headerTxt: {
    // textAlign: 'center',
    // justifyContent: 'center',
    display: 'flex',
    alignSelf: 'center',
  },
})

const PDFHorizontalTablemulti = (props) => {
  // devLog('PDF Horizontal got props', props)
  // const t = (code) => i18n.t(code)
  const mode = props.mode || ''
  const setup = props.setup
  const showRowNb = setup?.layout?.showRowNb !== false &&
    setup?.layout?.showRowNb?.showOnPdf !== false && (
      (mode !== 'pdf' && !mode?.includes('plugcode-consult') && !mode?.includes('plugcode-complete')) ||
      (mode === 'pdf' && setup.layout?.showRowNb.showOnPdf !== false) ||
      (mode?.includes('plugcode-complete') && setup.layout?.showRowNb.showOnPf !== false) ||
      (mode?.includes('plugcode-consult') && setup.layout?.showRowNb.showOnPn !== false))

  const colWidths = props.header.map(col => col.colWidth || 60)
  const labelColumnWidth = (Math.min(...colWidths) < 170 && Math.min(...colWidths) > 30) ? Math.min(...colWidths) : 170
  const dataColumnWidth = (514 - labelColumnWidth) / (props.rows.length > 0 ? props.rows.length : 1)
  // devLog('widths:', labelColumnWidth, dataColumnWidth)
  
  return (
    <View>
      <View style={styles.table}>
        {
          showRowNb &&
          <View style={styles.tableRow}>
            <View
              style={[styles.labelColumn, { minWidth: labelColumnWidth, maxWidth: '30%' }]}
            >
              <Text>N°</Text>
            </View>
            {
              props.rows.length > 0 ?
              props.rows
                .map((_, rindex) => (
                  <View style={[styles.tableCell, { width: dataColumnWidth }, styles.numberRow]} key={props.fieldLabel + "col-nb-" + rindex}>
                    <Text style={styles.headerTxt}>{rindex + 1}</Text>
                  </View>
                ))
              : (
                  <View style={[styles.tableCell, { width: dataColumnWidth }]} >
                    <Text> </Text>
                  </View>
              )
            }
          </View>
        }
        {
          props.header
            .map((col, cindex) => {
              if (props.hiddenOpt?.pdf || col.showOnPdf !== false) {
                const labelStyle = {
                  fontSize: getLabelFontSize(setup, col),
                }
                const dataStyle = {
                  fontSize: getDataFontSize(setup, col),
                }
                const labelAlignment = col.labelAlignment
                if (isDefined(labelAlignment)) {
                  switch (labelAlignment) {
                    case "left":
                      labelStyle.alignSelf = "flex-start !important"
                      break
                    case "right":
                      labelStyle.alignSelf = "flex-end !important"
                      break
                    default:
                      break
                  }
                }
                return (
                  <View style={styles.tableRow}>
                    <View style={[styles.labelColumn, { minWidth: labelColumnWidth, maxWidth: '30%' }]} >
                      <Text style={labelStyle}>{col.value}</Text>
                    </View>
                    {
                      props.rows.length > 0 ?
                        props.rows
                          .map((row, rindex) => (
                            <View style={[styles.tableCell,]} >
                              <Text style={[styles.headerTxt, dataStyle]}>{row[col.id] || ''}</Text>
                            </View>
                          ))
                        : (
                          <View style={[styles.tableCell,]} >
                            <Text style={[styles.headerTxt, dataStyle]}> </Text>
                          </View>
                        )
                    }

                  </View>
                )
              } else { return null }
            })
        }
      </View>
    </View>
  )
}

export default PDFHorizontalTablemulti
