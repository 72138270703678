import React, {useEffect, useState} from "react"
import { makeStyles } from "@material-ui/core/styles";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import colors from "../../assets/themes/Colors";
import {postExtDataReset} from "../../actions/extdata";
import {convertersAlphaToNumber, devLog, isDefined} from "../../utils/utils";
import { HyperFormula } from "hyperformula";
import { ModalTablemultiTableViewComponent } from "../modals/tablemulti/ModalTablemultiTableViewComponent";
import { getTableView } from "../../utils/Common";
import { createEmptyRow, getAllDefaultRows, getTotalForSpecificColumnIndex, getLabelWithSpecialChar, isEmptyField, isRowLimitReached, isVisibleInCSV, populateRowWithDefaultValues, handleCheckHasRecap } from "../../utils/Fields";
import { PlugTooltip } from "../custom/CustomComponents";
import { toast } from "react-toastify";
import {isNull} from "lodash";

const useStyles = makeStyles((theme) => ({
    table: {
        //maxWidth : 'fit-content',
        // width: "99%",
        userSelect: 'none',
        "&: : -youbkit-user-select": {
            color: 'red',
            backgroundColor: 'yellow',
        },
    },
    urlButton:{
        /* border: '1px red solid', */
        display: 'inline-block',
        color: "white",
        backgroundColor: "#58c3c1",
        padding: "3px 15px",
        borderRadius: "3px",
        cursor: "pointer",
    },
    mainCtn: {
		width: '100%',
        marginTop: -30,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    tableRoot: {
        width: "99%", //it was 100%
        overflowX: "auto",
    },
    topBtnCtn: {
        right: 0,
        gap: 3,
        display: 'flex',
        flexDirection: 'row-reverse',
        justifyContent: 'flex-start',
        // maxHeight: 45,
    },
    topBtn: {
        width: 60,
        height: 30,
        padding: 5,
        borderRadius: 5,
        boxShadow: '0px 1px 2px 1px #ddd',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: colors.mainButton,
        color: 'white',
        '&:hover': {
            boxShadow: '0px 0px 10px 1px #aaa',
            fontWeight: 'bold',
            borderColor: colors.mainButton,
        },
    },
    disabledBtn: {
        cursor: 'not-allowed',
        backgroundColor: colors.iconGrey,
        '&:hover': {
            boxShadow: '0px 0px 10px 1px #aaa',
            fontWeight: 'bold',
            borderColor: colors.iconGrey,
        },
    },
    appBar: {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo: {
        width: '10%',
    },
    margin_5: {
        margin : '5px',
        width : '100%'
    },
    margin_left_5: {
        marginLeft : '5px',
    },
    input_add_item: {
        flexDirection: 'row',
        width: '100%',
        display: 'flex'
    },
    input: {
        color: 'white',
        fontFamily : 'Arial, FontAwesome'
    },
    textField: {
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingBottom: 0,
        marginTop: 0,
        fontWeight: '500',
        borderRadius: '5px',
        height: '38px',
        lineHeight: '38px',
        paddingLeft: '10px',
        backgroundColor: '#F1F1F1',
        fontSize: '14px',
        width: '100%',
        MozBorderRadius : '5px',
        border: 'none',
        WebkitBorderRadius: '5px',
    },
    scrollBar: {
        // padding: '0px 5px',
        overflowX: 'auto',
        scrollbarWidth: 'thin', //without buttons on firefox, change to 'auto' if buttons wanted
        scrollbarColor: '#9B9B9B #E3E3E3', //first color to scrollbutton and the second to the bar
        "&: : -webkit-scrollbar": {
            display: "block",
            overflowX: 'auto',
            height: '5px',
            background: '#E3E3E3',
            width: '5px',
        },
        "&: : -webkit-scrollbar-thumb": {
            background: '#9B9B9B',
        },
        //if buttons wanted
        /*  "&: : -webkit-scrollbar-button: single-button": {
             backgroundColor: '#bbbbbb',
       display: 'block',
       height: '13px',
       width: '16px',
         }, */

    },
    addCellButton: {
        backgroundColor: colors.greyLighter,
        borderBottom: 'none',
        '&.MuiTableCell-root': {
            padding: 21
        },
        borderLeft: '2px solid',
        borderColor: colors.greyLight
    },
    rowDelCellButton: {
        backgroundColor: colors.greyLighter,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    rowNum: {
        color: colors.grey,
    },
    tableHeader: {
        padding: '3px 10px',
    },
    tableMultButton: {
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.unselected
        // fontSize: 22,
    },
    tableMultAddButton: {
        display : 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: colors.main,
        fontSize: 28,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    tableMultExportButton: {
        width : 20,
        height : 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderColor: colors.main,
        color: colors.main,
        fontSize: 16,
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    buttonBar: {
        marginLeft: 'auto',
        backgroundColor: colors.greyLighter,
        display: 'flex',
        justifyContent: 'space-between',
        height: 30,
        alignItems: 'center'
    },
    RadioGroupCell: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingLeft: 10,
        marginLeft: 3,
    },
    radioIcon: {
        color: colors.main,
        width: 25,
    },
    radioIconChecked: {
        color: colors.main,
    },
    radioLabel: {
        fontSize: 10,
    },
    stickyCol: {
        position: "sticky",
        zIndex: 100,
        width : '100%',
        wordBreak: 'break-word',
        borderBottom: 'none'
    },
    stickyColRight: {
        position: "sticky",
        left: 0,
        textAlign: 'center',
        zIndex: 50,
        borderBottom: 'none'
    },
    paginationSpacer: {
        flex: 'none',
    },
    width: {
      width : '99%'
    },
    paginationStyle: {
        border: '1px solid blue',
        position: "sticky",
        left: '-10px',
        background: colors.white,
        borderWidth: 0,
    },
    tableMultPageText: {
        color: colors.main,
        fontSize: 15,
        display: 'flex',
        gap: '8px'
    },
    tableMultPageChoice: {
        color: colors.white,
        backgroundColor: colors.unselectedAlpha,
        height: '100%',
        fontSize: 15,
        padding: '0 5px',
        borderRadius: '6px',
        display: 'flex',
        alignItems: 'center',
        '@media(max-width: 900px)': {
            fontSize: 11
        }
    },
    paginationStyleRow: {
        //border: '3px solid blue',
    },
    paginationStyleFooter: {
        //border: '3px solid blue',
    },

    //Render Item Single Select Component Styles
    renderSingleSelMainContainer: {
        justifyContent: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
    },
    renderSingleSelContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
        flexWrap: 'wrap',
        minHeight: 28,
    },
    renderSingleFormulaContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
        minHeight: 28,
    },
    renderSingleSubTotalContainer: {
        padding : 0,
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
        minHeight: 28,
    },
    renderInputContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
        flexWrap: 'no-wrap',
        minHeight: 28,
    },
    durationInputContainer: {
        display: 'flex',
        width: '100%',
        background: colors.greenLightest,
        marginBottom: 3,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottom: '1px solid black',
        position: 'relative'
    },
    renderFirstInputContainer: {
        display: 'flex',
        width: '40px'
    },
    renderSecondInputContainer: {
        display: 'flex',
        flexGrow: 1,
        width: 'auto'
    },
    renderCentralInputContainer: {
        display: 'flex',
        flexGrow: 1,
        width: 17
    },
    renderThirdInputContainer: {
        width: '70%'
    },
    renderSingleSelSubContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    renderSingleSelPreselNum: {
        fontSize: 10,
        color: colors.greyLight,
        border: `1px solid ${colors.greyLight}`,
        borderRadius: 50,
        padding: '1px 2px',
    },
    renderSingleSelIcon: {
        fontSize: 28,
        color: colors.greyDark,
    },
    stylizedValue: {
        fontSize: 12,
        fontWeight: 'bold',
        width: '100%',
        paddingLeft: 3,
        paddingRight: 3,
    },
    fontChange: {
        fontSize: 12,
        fontWeight: 'bold',
    },
    fontFirst: {fontSize: 12, fontWeight: 'bold'},
    fontError: {
        fontSize: 12,
        color: colors.red,
        fontWeight: 'bold',
        paddingLeft: '5px!important',
        width: '-webkit-fill-available',
        display: 'flex',
        justifyContent: 'flex-end',
    },
    deleteBtn: {
        fontSize: 12,
        color: colors.mainButton,
        fontWeight: 'bold',
        position: 'absolute',
        right: 0
    },
    textFont: {
        fontSize: 12,
        fontWeight: 'bold',
        paddingLeft: '5px!important',
    },
    textLength: {
        fontSize: 8,
        position: 'absolute',
        top: -6,
        right: 0,
    },
    fontChangeLabel: {
        fontSize: 12,
        fontWeight: 'bold',
        color: colors.main
    },
    fontFieldNumber: {
        fontSize: 8,
        marginRight: 2,
        color: colors.main,
        border: '1px solid',
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%',
        verticalAlign: 'middle',
        width: 16,
        height: 16,
    },
    fontTotalNumber: {
        fontSize: 8,
        marginRight: 2,
        border: '1px solid',
        padding: 2,
        borderRadius: '50%',
        verticalAlign: 'bottom',
    },
    fontChangeWidth: {
        fontSize: 12,
        minWidth : 120
    },
    btnContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    selectItemContainer: {
        display: 'flex',
        gap: 3,
        minHeight: 27,
        width: '100%',
        flexWrap: 'wrap',
    },
    selectItem: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    selectedItem: {
        color: colors.white,
        backgroundColor: colors.selectedAlpha,
        padding: '0px 5px',
        margin: '1px 0px',
        borderRadius: 4,
        cursor: 'pointer',
        fontSize: 13,
    },
    unselectedItem: {
        color: colors.white,
        backgroundColor: colors.unselectedAlpha,
        padding: '0px 5px',
        margin: '1px 0px',
        borderRadius: 4,
        cursor: 'pointer',
        fontSize: 13,
    },
    noSelectedItem: {
        color: colors.dark,
        fontSize: 13,
        fontStyle: 'italic',
        marginLeft: 10
    },
    selectIcon: {
        left: 2,
        fontSize: 28,
        cursor: 'pointer',
    },
    selectSelect: {
        marginLeft: 33,
        paddingLeft: 2,
    },
    selectField: {
        minHeight: 24
    },
    fieldContainer: {
        display : 'flex',
        alignItems : 'flex-start',
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: 3,
    },
    widerFieldValueContainer: {
        marginLeft: -50,
        order: 2,
        width : 'calc(60% + 32px)',
    },
    fieldTitleContainer: {
        marginRight: 'auto',
        order: 1,
        width: '40%',
    },
    fieldValueContainer: {
        display: 'flex',
        marginLeft: 'auto',
        order: 2,
        width : '60%'
    },
    fieldValues: {
        padding: 0,
        paddingLeft: 5,
        cursor: 'pointer',
        wordBreak: 'break-word',
        marginRight: 'auto',
        textAlign: 'left',
    },
    rowCellBtn: {
        backgroundColor: colors.greyLighter,
        borderBottom: 'none',
        // '&.MuiTableCell-root': {
        //     padding: 21
        // },
    },
    fieldLabels: {
        fontWeight: 'bold',
        wordBreak: 'break-word',
        color: colors.main,
        textAlign: 'left',
        padding: '10px 15px',
        backgroundColor: colors.greyLighter
    },
    totalFieldLabel: {
        backgroundColor: `${colors.hoverBgnd} !important`
    },
    timeNowButton: {
        height:'20px',
        width: '20px',
        color: colors.main,
        alignItems: 'center',
        fontSize: "20px",
        margin: '0px 5px',
    },
    endButton: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignSelf: 'center'
    },
    tableMultVisibilityBtn: {
        width: 50,
        color: colors.main,
    },
    openRow: {
        backgroundColor: colors.greyLighter
    },
    pageRowCtn: {
        height: 30,
        verticalAlign: 'middle',
        backgroundColor: colors.greyLighter,
        borderBottom: `1px solid ${colors.greyLight}`
    },
    pageCell: {
        textAlign: 'center',
        borderBottom: 'none',
    },
    arrowRight: {
        textAlign: 'left',
        height: 28,
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignSelf: 'center',
        "@media (max-width: 640px)": {
            fontSize: 18
        },
        color: colors.main,
        borderBottom: 'none',
    },
    arrowLeft: {
        textAlign: 'right',
        height: 30,
        justifyContent: 'flex-end',
        alignItems: 'center',
        alignSelf: 'center',
        "@media (max-width: 640px)": {
            fontSize: 18
        },
        color: colors.main,
        borderBottom: 'none',
    },
    hidden: {
        color: colors.greyLighter
    },
    tripleCell: {
        '&.MuiTableCell-root': {
            borderBottom: 'none !important'
        },
        height: 30,
        display: 'flex',
        flexDirection: 'row',
    },
    valuesCell: {
        borderBottom: 'none',
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
    },
    totalValues: {
        color: colors.bloodOrange,
        padding: 0,
        paddingLeft: 5,
        wordBreak: 'break-word',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'left',
        fontSize: 12,
    },
    //New design
    summaryCtn: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        gap: 5
    },
    summary: {
        paddingRight: 4
    },
    rowCtn: {
        justifyContent: 'flex-end',
		zIndex: 200,
        display: 'flex',
        flexDirection: 'row',
        width: '70%',
        alignSelf: 'flex-end',
    },
    //!Preview
	tooltipCtn: {
		maxWidth: 'none',
		backgroundColor: colors.greyLighter,
		boxShadow: '0px 0px 20px 20px rgb(0 0 0 / 14%), 0px 11px 15px rgb(0 0 0 / 20%)',
		borderRadius: 8,
	},
	noTooltip: {
		backgroundColor: colors.transparent,
	},
	previewCtn: {
		backgroundColor: colors.transparent,
		margin: 10,
		maxWidth: 'none',
        minWidth: 160,
        padding: 20,
	},
    warnToast: {
        backgroundColor: colors.unselected,
    },
}))

const FormatTableMultipleComponent = (props) => {
    // console.log('TM got props', props);
    let tmpDisabled = false;
    if (typeof props.disabled !== "undefined"){
        tmpDisabled = props.disabled;
    }
    else if (typeof props.field.disabled !== "undefined"){
        tmpDisabled = props.field.disabled;
    }
    let _table = getTableView() || null
    const classes = useStyles();
    const { t, } = useTranslation(['common']);
    const owner = typeof props.owner === 'undefined' ? null: props.owner
    const ownerId = props.ownerId

    let colonnesOptions = props.field.options.length > 0 ? props.field.options : []
    if (props.field.options?.lines) {
        colonnesOptions = props.field.options?.lines?.length > 0 ? props.field?.options?.lines : []
    }
    const colonnes = colonnesOptions
    const table = _table?.hash === props.hash ? _table : null
    const optionFormule = {licenseKey: 'gpl-v3'}
    const plugNb = props?.plugNb

    const [rows, setRows] = useState(props.field.value.length > 0 ? props.field.value : [])
    const [disabled , setDisabled] = useState(tmpDisabled)
    const [openTable, setOpenTable] = useState(false)
    const [openRow, setOpenRow] = useState(null)
    const [openDownload, setOpenDownload] = useState(false)
    const [datasToDownload, setDatasToDownload] = useState([])
    const [recap, _ ] = useState(handleCheckHasRecap(props?.field?.options))
    const [tableLength, setTableLength] = useState(recap ? rows.length - 1 : rows.length)
    const [newRow, setNewRow] = useState(null)
    const setup = props.field?.options?.setup
	const defaultRows = setup?.defaultRows || []
    const rowLimit = setup?.rowLimit === 0 ? Infinity : setup?.rowLimit || Infinity
    const [excessLimit, setExcessLimit] = useState(0)

    //function rowIsEmpty pour supprimer une rows[n] vide dans un useEffect ([])
    const deleteEmptyRows = (rows) => {
        let newRows = []
        if (rows.length > 0) {
            rows.forEach((row, index) => {
                let value = ''
                Object.values(row).forEach((valeur, id) => {
                    if (valeur !== '' && valeur !== undefined) value = valeur
                })
                if (value !== '') newRows.push(row)
            })
        }
        return newRows
    }

    const handleClickAddRow = () => {
        handleAddTableMulti()
        if(!openTable) setOpenTable(true)
    }

    const handleAddTableMulti = async () => {
        let _newRow = createNewRowWithDefaultValues()
        if (rows.length > 0) {
            let isEmpty = true
            let _rows = rows.filter(row => !row.hasOwnProperty('recap'));
            _rows = _rows.filter(Boolean);
            if ((rowLimit > 0 && _rows.length < rowLimit) || rowLimit === 0) {
                if (_rows.length > 0) {
                    let lastOne = _rows[_rows.length - 1];
                    Object.values(lastOne).forEach((k) => {
                        let control = typeof k !== "string" ? k.toString() : k;
                        if (control !== "" && control !== '0:00' && control.length > 0) isEmpty = false
                    })
                    if (!isEmpty) {
                        if (recap) setRows([..._rows, _newRow, createRecapData()]);
                        else setRows([..._rows, _newRow]);
                        setNewRow(_newRow)
                    }
                    else {
                        setOpenRow(_rows.length - 1)
                    }
                }
                else {
                    if (recap) setRows([_newRow, createRecapData()])
                    else setRows([_newRow]);
                    setNewRow(_newRow)
                }
            } else {
                // console.log('Toast', toast)
                if (!toast.isActive()) {
                    toast.info(t('tablemulti.row-limit-warn'),
                        {
                            autoClose: 3000,
                            hideProgressBar: true,
                            bodyClassName: classes.warnToast,
                            className: classes.warnToast,
                            toastClassName: classes.warnToast,
                        })
                }
            }
        } else {
            if (recap) { setRows([...rows, _newRow, createRecapData()]) }
            else { setRows([...rows, _newRow]) }
            setNewRow(_newRow)
        }
    }

    const handleAddRecap = () => {
        if (rows.length > 0 && !rows[rows.length - 1].hasOwnProperty('recap')) setRows([...rows, createRecapData()]);
    }

    const handleDuplicateRow = async (row) => {
        let _newRowDuplicated = { ...row }
        if (rows.length > 0) {
            let isEmpty = true
            let _rows = rows.filter(row => !row.hasOwnProperty('recap'));
            _rows = _rows.filter(Boolean);
            if ((rowLimit > 0 && _rows.length < rowLimit) || rowLimit === 0) {
                if (_rows.length > 0) {
                    let lastOne = _rows[_rows.length - 1];
                    Object.values(lastOne).forEach((k) => {
                        let control = typeof k !== "string" ? k.toString() : k;
                        if (control !== "" && control !== '0:00' && control.length > 0) isEmpty = false
                    })
                    if (!isEmpty) {
                        if (recap) setRows([..._rows, _newRowDuplicated, createRecapData()]);
                        else setRows([..._rows, _newRowDuplicated]);
                        //setNewRow(_newRowDuplicated)
                    } else {
                        setOpenRow(_rows.length - 1)
                    }
                } else {
                    if (recap) setRows([_newRowDuplicated, createRecapData()])
                    else setRows([_newRowDuplicated]);
                }
            } else {
                if (!toast.isActive()) {
                    toast.info(t('tablemulti.row-limit-warn'),
                        {
                            autoClose: 3000,
                            hideProgressBar: true,
                            bodyClassName: classes.warnToast,
                            className: classes.warnToast,
                            toastClassName: classes.warnToast,
                        })
                }
            }
        } else {
            if (recap) { setRows([...rows, _newRowDuplicated, createRecapData()]) }
            else { setRows([...rows, _newRowDuplicated]) }
        }
    }

    const handleExportTableMulti = () => {
        let dataRows = [];
        if (colonnes.length > 0){
            let line1 = [];
            line1.push(t('export.export-date'))
            line1.push(t('export.export-key'))
            line1.push(t('export.export-number'))
            line1.push(t('export.export-field-number'))
            line1.push(t('export.export-field'))
            colonnes.forEach(col => {
                if (isVisibleInCSV(col, props.mode)) line1.push(getLabelWithSpecialChar(col.label))
            });

            dataRows.push(line1);
            rows.forEach((row, index) => {
                let dataRow = [];
                // date et heure de l'export
                // ajouter Clé du plugform
                // Numero de plugnote
                // Numero du champ
                // Libellé du champ
                let now = new Date()
                dataRow.push(now.toLocaleString())
                dataRow.push(props.plugKey)
                dataRow.push(plugNb)
                dataRow.push(props.index + 1 + (row.recap === true ? ' - Total' : '.' + (index + 1)))
                dataRow.push(props.title)

                colonnes.forEach(col => {
                    if (isVisibleInCSV(col, props.mode)) {
                        let someText = typeof row[col.id] === 'string'
                            ? row[col.id].replace(/\r?\n?/g, '').trim()
                            : row[col.id]
                        if (someText === undefined) {
                            someText = "";
                        }
                        dataRow.push(someText);
                }})
                dataRows.push(dataRow);
            });
        }

        if (dataRows.length > 0) {
            setDatasToDownload(dataRows);
            setOpenDownload(true);
        }
    }

    useEffect(() => {
        if (openDownload){
            setDatasToDownload([]);
            setOpenDownload(false);
        }
    }, [openDownload]);

    const handleRemoveSpecificRow = (value) => {
        setOpenRow(null)
        let items
        if (Array.isArray(value)) {
            items = rows.filter((_, index) => !value.includes(index + 1))
        } else {
            items = rows.filter((row, index) => index !== value);
        }
        if (recap) {
            console.log('has recap', recap, items)
            let totalRow = items.find(row => row?.recap === true)
            if ((totalRow && items.length > 1) || items.length >= 1) {
                let rindex = items.indexOf(totalRow)
                if (rindex > -1) {
                  colonnes.forEach((col, index) => {
                    if (col?.recap) {
                      totalRow['recap'] = true
                      totalRow[col.id] = getTotalForSpecificColumnIndex(items, col.id, colonnes)
                    }
                  })
                  items[rindex] = totalRow
                }
                setRows([...items]);
            } else setRows([])
        } else setRows(items);
    }

    function createNewRowWithDefaultValues() {
        let row = createEmptyRow(colonnes)
        return populateRowWithDefaultValues(colonnes, defaultRows, tableLength, row)
    }

    function createRecapData() {
        let data = {};
        colonnes.forEach((col, index) => {
            if (col?.recap){
                data["recap"] = true;
                data[col.id] = 0
            }
        })
        return data;
    }

    function refreshRecapData () {
        let _rows = [...rows]
        let row = _rows.find(row => row?.recap === true)
        if (row) {
            let rindex = _rows.indexOf(row)
            colonnes.forEach( (col, index) => {
                if (col?.recap){
                    row["recap"] = true;
                    row[col.id] = getTotalForSpecificColumnIndex(rows, col.id, colonnes)
                }
            })
            rows[rindex] = row;
            setRows([...rows]);
        }
    }

    const handleChangeTableValue =  () => {
        if(typeof props.handleChangeTableValue !== "undefined"){
            props.handleChangeTableValue(rows, props.field, props.index);
        }
    }

    const handleCalculateValueAuto = async (row, formula) => {
        let answer = null;
        let tmp = [];

        colonnes.forEach((elm, index) => {
            let tmpValue = row[elm.id];
            if (Object.prototype.toString.call(tmpValue) === '[object Array]' || Object.prototype.toString.call(tmpValue) === '[object Object]') {
                tmpValue = "";
            }
            if (elm.format === 'numeric' && row[elm.id] !== undefined && typeof row[elm.id] !== 'number'){
                if (tmpValue !== null) tmpValue = row[elm.id].replace(', ', '.');
            }
            tmp.push([tmpValue]);
        });

        if (formula !== null) {
            let data = tmp;
            const sheetInfo = {
                pnSheet: {
                    sheetName: "F32"
                },
                formulas: {
                    sheetName: "Formulas"
                }
            };

            let sheetAData = data;

            let newFormula =  formula;

            const hfInstance = HyperFormula.buildEmpty(optionFormule);

            let formulasData = [[
                newFormula
            ]];

            // add 'TeamA' sheet
            hfInstance.addSheet(sheetInfo.pnSheet.sheetName);
            // insert playersA content into targeted 'TeamA' sheet
            hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo.pnSheet.sheetName), sheetAData);

            // add a sheet named 'Formulas'
            hfInstance.addSheet(sheetInfo.formulas.sheetName);
            // add formulas to that sheet
            hfInstance.setSheetContent(hfInstance.getSheetId(sheetInfo.formulas.sheetName), formulasData);

            const cellAddress = hfInstance.simpleCellAddressFromString(
                `${sheetInfo.formulas.sheetName}!A1`, hfInstance.getSheetId(sheetInfo.formulas.sheetName)
            );

            try {
                const mySum = hfInstance.getCellValue(cellAddress);

                if (!mySum.hasOwnProperty('type')){
                    answer =  mySum;
                }else{
                    answer =  "";
                }
            }catch (e){
                //setResult(e.message)
                answer =  "";
            }

            hfInstance.destroy();

        }
        return answer;
    }

    const handleChangeChildrenValue = async (value, col, rindex) => {
        // devLog('F32 handleChange', value, col, rindex)
        let row = rows[rindex]
        if (!props.disabled) {
            let obj = colonnes.filter(o => o.format === 'formula').filter(Boolean);
            switch (col.format) {
                case 'presel':
                    let multiselect = col.mask.multiselect;
                    let editable = col.mask.editable;
                    if (multiselect === false && editable === false) {
                        row[col.id] = value;
                        if(obj){
                            for (const list of obj) {
                                let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                                if (tmpValue !== null){
                                    row[list.id] = tmpValue
                                }
                            }
                        }
                        rows[rindex] = row;
                        setRows([...rows]);
                    } else if (multiselect === false && editable === true) {
                        row[col.id] = value;
                        if(obj){
                            for (const list of obj) {
                                let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                                if (tmpValue !== null){
                                    row[list.id] = tmpValue
                                }
                            }
                        }
                        rows[rindex] = row;
                        setRows([...rows]);
                    } else if (multiselect === true && editable === false) {
                        row[col.id] = value;
                        if(obj){
                            for (const list of obj) {
                                let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                                if (tmpValue !== null){
                                    row[list.id] = tmpValue
                                }
                            }
                        }
                        rows[rindex] = row;
                        setRows([...rows]);
                    } else if (multiselect === true && editable === true) {
                        row[col.id] = value;
                        if(obj){
                            for (const list of obj) {
                                let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                                if (tmpValue !== null){
                                    row[list.id] = tmpValue
                                }
                            }
                        }
                        rows[rindex] = row;
                        setRows([...rows]);
                    }
                    break;
                default:
                    row[col.id] = value;
                    if (obj) {
                        for (const list of obj) {
                            let tmpValue  = await handleCalculateValueAuto(row, list?.configuration?.formula);
                             if (tmpValue !== null){
                                 row[list.id] = tmpValue
                             }
                        }
                    }
                    setRows(previous => {
                        let _rows = [...previous]
                        _rows[rindex] = row;
                        return _rows
                    });
                    break;
            }
            refreshRecapData();
        }
    }

    const handleChangeChildrenRowValue =  async (value, col, row, rindex) => {
        if (!props.disabled) {
            switch (col.format) {
                case 'intdata':
                case 'extdata':
                    rows[rindex] = value;
                    setRows([...rows]);
                    break;
                default:
                    break;
            }
        }
    }

    const handleViewTable = () => {
        setOpenTable(true)
    }

    const handleClickPreview = (index, id) => {
        if (!disabled) {
            if (isDefined(id)) { setOpenRow({ row: rows[index], id: id }); }
        }
        handleViewTable();
    }

    const isLimitReached = () => {
        return isRowLimitReached(props.field?.options?.setup?.rowLimit, rows)
    }

    const handleSetDefaultRowsOnOpen = () => {
        if ((props.mode.includes('create') || props.mode.includes('complete')) && defaultRows?.length > 0) {
            if (setup.defaultComplete === 'open') {
                // devLog('on open default :', defaultRows)
                let _default = [...defaultRows]
                if (defaultRows.length > 0) {
                    let lastRow = defaultRows[defaultRows.length -1]
                    if (isEmptyField(lastRow)) {
                        _default.pop()
                    }
                    let _rows = getAllDefaultRows(colonnes, _default)
                    // devLog('initial rows', _rows)
                    setRows(recap ? [..._rows, createRecapData()] : _rows)
                }
            }
        }
    }

    const handleSelectDataMultiCombo = async (objects, currentColumn, currentRow) => {
        let datas = [];
        let datasFields = null;
        let destCombo = currentColumn?.configuration.dest;

        if (objects.length > 0) {
            let resolve = new Promise((resolve, reject) => {
                objects.map(async (object, indexObject) => {

                    let jsonObjectToArray = Object.values(object)
                    let destinations = [];
                    let fieldDestinations = [];
                    let champs = destCombo.split('-');

                    champs.forEach((elm, index) => {
                        try {
                            let field = elm.split(':');
                            if (field.length > 0) {
                                let fieldDest = field[0];
                                if(fieldDest.startsWith('F')){
                                    fieldDest = fieldDest.replace('F', '');
                                    let dest = fieldDest
                                    let col = field[1];
                                    let copyValue = col?.split(';') || col;
                                    let value = "";

                                    let lineDest = null;
                                    let colDest = null;

                                    if(typeof field[2] !== "undefined" && typeof field[3] !== "undefined"){
                                        lineDest = field[2];
                                        colDest = field[3];
                                    }

                                    if(typeof props.fields[(dest - 1)] !== "undefined"){
                                        if(props.fields[(dest - 1)].format === "glocalis"){
                                            value = {
                                                latitude : "",
                                                longitude : "",
                                                address : ""
                                            }
                                        }
                                        else if(props.fields[(dest - 1)].format === "tablemultin" || props.fields[(dest - 1)].format === "smailvalidmulti" || props.fields[(dest - 1)].format === "document"){
                                            value = {
                                                value : "",
                                                list : [],
                                            }
                                        }
                                        else if(props.fields[(dest - 1)].format === "intdatamaj"){
                                            value = {
                                                value : "",
                                                updatedDate : "",
                                            }
                                        }else if(props.fields[(dest - 1)].format === "tablemulti"){
                                            value = [];
                                        }
                                        copyValue.map((item, index) => {
                                            let alphaNumIndexDest = convertersAlphaToNumber(item.toUpperCase()) - 1;
                                            let data = jsonObjectToArray[alphaNumIndexDest];

                                            if(typeof data !== "undefined"){
                                                let temp = data;
                                                if(temp !== "" && typeof temp !== "undefined"){
                                                    if(temp === null){
                                                        temp = "";
                                                    }

                                                    if(temp != ""){
                                                        switch (props.fields[(dest - 1)].format) {
                                                            case 'free-single-select':
                                                            case 'single-select':
                                                            case 'multi-select':
                                                            case 'free-multi-select':
                                                                try {
                                                                    value = [temp];
                                                                }catch (e) {
                                                                    value = [];
                                                                }
                                                                break;
                                                            case 'glocalis' :
                                                                value.address = value.address == "" ? value.address.concat(temp) : value.address.concat(' ' + temp) ;
                                                                break;
                                                            case 'tablemulti':
                                                                if(!isNull(lineDest) && !isNull(colDest)){
                                                                    let field = props.fields.find((field, index) => Number(field.id) === Number(dest));
                                                                    if(field){
                                                                        let tmpValue = field?.value ? field.value : null;
                                                                        if(!isNull(tmpValue)){
                                                                            if (tmpValue.length > 0 && Array.isArray(tmpValue)) {
                                                                                let options = field.options?.lines ? field.options.lines : field.options;
                                                                                let targert = (lineDest.replace('L', ''));
                                                                                let targettable = (colDest.replace('C', '') );
                                                                                tmpValue[(targert - 1)][options[(targettable - 1)].id] = temp;
                                                                                value = tmpValue;
                                                                            }else{
                                                                                let options = field.options?.lines ? field.options.lines : field.options;
                                                                                let newLine = {};
                                                                                for (const option of options) {
                                                                                    newLine[option.id] = "";
                                                                                }
                                                                                let targert = (lineDest.replace('L', ''));
                                                                                let targettable = (colDest.replace('C', '') );
                                                                                newLine[options[(targettable - 1)].id] = temp;
                                                                                let newValue = [];
                                                                                newValue[(targert - 1)] = newLine
                                                                                value = newValue;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                break;
                                                            case 'tablemultin' :
                                                            case 'smailvalidmulti' :
                                                                if(isNull(lineDest) && isNull(colDest)){
                                                                    value.value = temp;
                                                                }else if(!isNull(lineDest) && !isNull(colDest)){
                                                                    let field = props.fields.find((field, index) => Number(field.id) === Number(dest));
                                                                    if(field){
                                                                        let tmpValue = field?.value?.list ? field.value.list : null;
                                                                        if(!isNull(tmpValue)){
                                                                            if (tmpValue.length > 0 && Array.isArray(tmpValue)) {
                                                                                let options = field.options?.lines ? field.options.lines : field.options;
                                                                                let targert = (lineDest.replace('L', ''));
                                                                                let targettable = (colDest.replace('C', '') );
                                                                                tmpValue[(targert - 1)][options[(targettable - 1)].id] = temp;
                                                                                value.list = tmpValue;
                                                                            }else{
                                                                                let options = field.options?.lines ? field.options.lines : field.options;
                                                                                let newLine = {};
                                                                                for (const option of options) {
                                                                                    newLine[option.id] = "";
                                                                                }
                                                                                let targert = (lineDest.replace('L', ''));
                                                                                let targettable = (colDest.replace('C', '') );
                                                                                newLine[options[(targettable - 1)].id] = temp;
                                                                                let newValue = [];
                                                                                newValue[(targert - 1)] = newLine
                                                                                value.list = newValue;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                break;
                                                            case 'localis' :
                                                                value = value == "" ? value.concat(temp) : value.concat(' ' + temp) ;
                                                                break;
                                                            case 'intdatamaj' :
                                                                value.value = temp;
                                                                break;
                                                            default:
                                                                if (item == item.toUpperCase()) {
                                                                    value = value == "" ? value.concat(temp) : value.concat(' ~ ' + '\n'+ temp);
                                                                }else{
                                                                    value = value == "" ? value.concat(temp) : value.concat(' ~ ' + temp);
                                                                }
                                                                break;

                                                        }
                                                    }
                                                }
                                            }
                                        });


                                        if(props.fields[(dest - 1)].format === "glocalis" ){
                                            value = JSON.stringify(value);
                                        }

                                        fieldDestinations.push({
                                            "dest" : dest,
                                            "value" : value
                                        })
                                    }
                                }
                                else {
                                    let dest = colonnes[(fieldDest - 1)].id;
                                    let col = field[1];
                                    let copyValue = col?.split(';') || col;
                                    let value = ""

                                    copyValue.forEach((item, index) => {
                                        let alphaNumIndexDest = convertersAlphaToNumber(item.toUpperCase()) - 1;
                                        let data = jsonObjectToArray[alphaNumIndexDest];
                                        if (typeof data !== "undefined") {
                                            let temp = data;
                                            if (temp !== "" && typeof temp !== "undefined") {
                                                if (temp === null) {
                                                    temp = "";
                                                }

                                                if (temp !== "") {
                                                    if (item === item.toUpperCase()) {
                                                        value = value === "" ? value.concat(temp) : value.concat(' ~ \n' + temp);
                                                    }
                                                    else {
                                                        value = value === "" ? value.concat(temp) : value.concat(' ~ ' + temp);
                                                    }
                                                }
                                            }
                                        }
                                    });
                                    destinations.push({
                                        "dest": dest,
                                        "value": value
                                    })
                                }
                            }
                        }catch (e) {

                        }
                    })

                    if (destinations.length > 0) {
                        let copyColonnes = [...colonnes];
                        let obj = copyColonnes.filter(o => o.format === 'formula').filter(Boolean);
                        let obj2 = copyColonnes.filter(o => o.format === 'numeric-ref').filter(Boolean);
                        let obj3 = copyColonnes.filter(o => o.format === 's33data').filter(Boolean);

                        let data = createEmptyRow(colonnes);
                        destinations.map(async (item, index) => {
                            let col = colonnes.find(o => ((o.id === Number(item.dest)) && (o.format === 'numeric')));

                            if (typeof col === "undefined") {
                                data[item.dest] = item.value;
                            } else {
                                data[item.dest] = item.value.replace(',', '.');
                            }

                            if(obj2){
                                for (const list2 of obj2) {
                                    let tmpValue = data[list2.id];
                                    if (tmpValue == "") {
                                        let target = list2?.configuration?.target;
                                        if (typeof target !== "undefined") {
                                            let field = props.fields.find((field, index) => Number(field.id) === Number(target));
                                            if (field) {
                                                data[list2.id] = field.value;
                                            }
                                        }
                                    }
                                }
                            }

                            if(obj3){
                                for (const list3 of obj3) {
                                    let value = data[list3.id];

                                    try {
                                        if (value !== "") {
                                            let target = list3?.configuration?.target;
                                            let targettable = list3?.configuration?.targettable;
                                            if (typeof target !== "undefined") {
                                                let field = props.fields.find((field, index) => Number(field.id) === Number(target));
                                                if (field) {
                                                    let tmpValue = field?.value?.list ? field.value.list : field.value;
                                                    if (tmpValue.length > 0 && Array.isArray(tmpValue)) {
                                                        let options = field.options?.lines ? field.options.lines : field.options;
                                                        data[list3.id] = tmpValue[(value - 1)][options[(targettable - 1)].id];
                                                    }
                                                }
                                            }
                                        } else {
                                            data[list3.id] = value;
                                        }
                                    } catch (e) {
                                        data[list3.id] = value
                                    }
                                }
                            }

                            if (obj) {
                                for (const list of obj) {
                                    let tmpValue = await handleCalculateValueAuto(data, list?.configuration?.formula);
                                    if (tmpValue !== null) {
                                        data[list.id] = tmpValue
                                    }
                                }
                            }
                        })

                        datas.push(data);
                        //props.handleChangeExtData(destinations);
                    }

                    if(fieldDestinations.length > 0){
                        datasFields = fieldDestinations;
                    }

                    resolve(true);
                });

            })

            if (typeof rowLimit !== "undefined" && rowLimit > 0 && datas.length > rowLimit - tableLength) {
                setExcessLimit(previous => {
                    let excess = datas.length - (rowLimit - tableLength)
                    if (excess !== 100 && previous > 0) {
                        toast.dismiss('row-limit-toast')
                        toast.info(
                            t('tablemulti.row-limit-warn') + ': ' + (previous + excess) + t('tablemulti.rows-ignored'),
                            {
                                autoClose: 3000,
                                hideProgressBar: true,
                                bodyClassName: classes.warnToast,
                                className: classes.warnToast,
                                toastClassName: classes.warnToast,
                            })
                        return 0
                    }
                    else { return previous + excess }
                })
                datas = datas.slice(0, rowLimit - tableLength)
            }

            if ((datas.length > 0 || (!isNull(datasFields) && datasFields.length > 0)) && await resolve) {
                if(datas.length > 0 ){
                    let newRows = [...rows]

                    let forek = new Promise((resolve, reject) => {
                        datas.forEach((item, index, array) => {
                            if(typeof currentRow !== "undefined" && index === 0){
                                newRows[currentRow] = item
                            }else{
                                newRows.push(item);
                            }
                            if (index === array.length -1) resolve();
                        });
                    });

                    forek.then(() => {
                        setRows(newRows);
                    });
                }

                if (!isNull(datasFields) && datasFields.length > 0) {
                    props.handleChangeExtData(datasFields);
                }
            }
        }

    }


    useEffect(() => {
        handleChangeTableValue()
        setTableLength(rows.length > 0 && rows[rows.length - 1].hasOwnProperty('recap')? rows.length - 1 : rows.length)
    }, [rows])

    useEffect(() => {
        let tmpDisabled = false;
        if (typeof props.disabled !== "undefined"){
            tmpDisabled = props.disabled;
        }
        else if (typeof props.field.disabled !== "undefined"){
            tmpDisabled = props.field.disabled;
        }
        setDisabled(tmpDisabled);
    }, [props.disabled, props.field.disabled])

    useEffect(() => {
        setRows(!!props.field?.value ? props.field.value : []);
    },[props.field.value])

    useEffect(() => {
        if (rows.length > 0) {
            let newRows = deleteEmptyRows(rows)
            setRows(newRows)
        } else {
            handleSetDefaultRowsOnOpen()
        }
        if (isDefined(table) && table.field.split('field')[1] === props.field.id.toString()) setOpenTable(true)
    }, [])

    useEffect(() => {
        if (excessLimit > 0 && !toast.isActive('row-limit-toast')) {
            toast.warn(t('tablemulti.row-limit-warn'), {
                hideProgressBar: true,
                bodyClassName: classes.warnToast,
                className: classes.warnToast,
                toastClassName: classes.warnToast,
                toastId: 'row-limit-toast',
                isLoading: true,
                autoClose: 3000,
            })
        }
    }, [excessLimit])

    return (
    colonnes.length > 0 &&
    <div className={classes.mainCtn}>
        <div className={classes.rowCtn}>
            <div className={classes.topBtnCtn}>
                {
                    rows.length > 0 &&
                    <PlugTooltip title={t('tablemulti.table-view').concat(` - ${tableLength} `).concat(t('tablemulti.rows'))} placement="top">
                        <div className={classes.topBtn} onClick={handleViewTable}>
                            <span id="icon-add-playlist" className="material-icons">
                                visibility
                            </span>
                            <span style={{fontSize: 10, fontWeight: 'bolder',}}>
                                {`(${tableLength})`}
                            </span>
                        </div>
                    </PlugTooltip>
                }

                {
                    !disabled && rowLimit > tableLength &&
                    <PlugTooltip title={isLimitReached() ? t('tablemulti.row-limit-warn') : t('tablemulti.add-row')} placement="top">
                        <div className={`${classes.topBtn} ${isLimitReached() ? classes.disabledBtn : undefined} `} colSpan={3} onClick={handleClickAddRow}>
                            <span id="icon-add-playlist" className={` material-icons`} style={isLimitReached() ? { cursor: 'not-allowed' } : undefined}>
                                add_circle_outline
                            </span>
                        </div>
                    </PlugTooltip>
                }
            </div>
        </div>
        <ModalTablemultiTableViewComponent
            {...props}
            attemptGetDataFromAnotherBaseByClientReset={props.attemptGetDataFromAnotherBaseByClientReset}
            colonnes={colonnes}
            datasToDownload={datasToDownload}
            disabled={disabled}
            field={props.field}
            handleAddRecap={handleAddRecap}
            handleAddTableMulti={handleAddTableMulti}
            handleChangeChildrenRowValue={handleChangeChildrenRowValue}
            handleChangeChildrenValue={handleChangeChildrenValue}
            handleClose={() => setOpenTable(false)}
            handleClickPreview={handleClickPreview}
            handleDeleteRow={handleRemoveSpecificRow}
            handleDuplicateRow={handleDuplicateRow}
            handleExportTableMulti={handleExportTableMulti}
            handleSafeExitPlugnote={props.handleSafeExitPlugnote}
            hasDb={false}
            isFromPwCode={props.isFromPwCode}
            isPreview={!openTable}
            mode={props.mode}
            model={props.model}
            newRow={newRow}
            open={openTable}
            openDownload={openDownload}
            openRow={openRow}
            owner={owner}
            ownerId={ownerId}
            plugnoteNb={isDefined(props.plugnote) ? props.plugnote.userindex : props.plugNb}
            recap={recap}
            rows={rows}
            setNewRow={setNewRow}
            setOpenRow={setOpenRow}
            title={props.title}
            shared={props?.shared}
            handleSelectDataMultiCombo={handleSelectDataMultiCombo}
        />
    </div>
    )
};

// redux providing state takeover
const mapStateToProps = (state, props) => {
    return {
        "plugKey": state?.model?.model?.clef || null,
    }
}

const mapDispatchToProps = (dispatch) => ({
    attemptGetDataFromAnotherBaseByClientReset: () => dispatch(postExtDataReset()),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormatTableMultipleComponent);
