import React, { useEffect, useState } from 'react'
import { makeStyles, Tab, Tabs } from '@material-ui/core'
import colors from '../../assets/themes/Colors'
import { useTranslation } from 'react-i18next'
import {
  BlobProvider,
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer'
import { ColorCheckbox, PlugTooltip } from '../custom/CustomComponents'
import { FormatListNumbered, Visibility } from '@material-ui/icons'
import { AttLayout, BackupTable, EditValueIcon } from '../custom/icons/CustomIcons'
import { convertersAlphaToNumber, convertersNumberToAlpha, currentDateAndHours, devLog, getFormatCode, isDefined } from '../../utils/utils'
import moment from 'moment'
import Images from '../../assets/themes/Images'
import useFetchExtdata from '../../hooks/useFetchExtdata'
import { getPDFColumnLabel } from '../custom/pdftable/PDFHeaderRow'
import { convertHtmlToNative } from '../../utils/pdf'
import useFetchPlugform from '../../hooks/useFetchPlugform'

const styles = StyleSheet.create({
  page: {
    padding: '50px 40px',
    position: 'relative',
    flex: 1,
    fontSize: 10,
    maxWidth: 792,
  },
  // Header
  mainInfoCtn: {
    display: 'flex',
    flexDirection: 'row',
  },
  mainInfo: {
    marginRight: 20,
    paddingRight: 20,
    borderRightWidth: 1,
    borderRightColor: colors.greyLight,
    flex: 0.7,
  },
  mainTitle: {
    fontWeight: 'bold',
    fontSize: 12,
    color: colors.unselected,
    whiteSpace: "break-line"
  },
  headerTitle: {
    color: colors.main,
    fontSize: 14,
  },
  printInfoCtn: {
    paddingRight: 20,
    paddingLeft: 20,
    flex: 0.3,
  },
  fieldListCtn: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
  },
  // Field
  borderedCtn: {
    marginTop: 8,
    padding: 8,
    borderWidth: 1,
    borderColor: colors.main,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  fieldCtn: {
    display: 'flex',
    flexDirection: 'row',
  },
  fieldMainInfoCtn: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 0.3,
    alignItems: 'center',
  },
  fieldInfoCtn: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.2,
  },
  verticalCtn: {
    display: 'flex',
    flexDirection: 'column',
  },
  columnCtn: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: 12,
    width: '50%',
    paddingRight: 8,
  },
  detailsCtn: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexShrink: 0,
  },
  subtitle: {
    fontSize: 12,
    color: colors.unselected,
  },
  separationLine: {
    width: 'auto',
    borderTopWidth: 1,
    borderColor: colors.borderGrey,
    paddingTop: 4,
    marginTop: 4,
  },
  separationCol: {
    marginHorizontal: 6,
    borderLeftWidth: 1,
    borderColor: colors.borderGrey,
    height: 'auto',
  },
  optionsCtn: {
    paddingLeft: 12,
  },
  emailCtn: {
    padding: 20,
    borderWidth: 1,
    borderColor: colors.borderGrey,
    margin: 6,
    borderRadius: 8,
  },
  italicGrey: {
    fontFamily: 'Poppins',
    fontStyle: 'italic',
    fontWeight: 'light',
    color: colors.darkgrey,
  },
  boldOrange: {
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    color: colors.bloodOrange,
  },
  infobulleCtn: {
    display: 'flex',
    flexDirection: 'row',
  },
  iconCtn: {
    maxHeight: 12,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginRight: 8,
  },
  icon: {
    width: "auto",
    maxHeight: 20,
    height: 20,
    aspectRatio: '1/1',
    color: colors.main,
  },
  //#region Presels
  preselsCtn: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginVertical: 6,
  },
  preselCtn: {
    padding: '3px 4px',
    marginLeft: 4,
    marginBottom: 4,
    borderWidth: 1,
    borderColor: colors.unselected,
    borderRadius: 4,
  },
  //#endregion
  //#region Table
  tableCtn: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 6,
    width: 'auto',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderColor: colors.unselected,
    borderLeftWidth: 1,
    borderRightWidth: 1,
  },
  tableHeader: {
    backgroundColor: colors.palePlugGreen,
  },
  tableHeaderLabel: {
    paddingVertical: 2,
  },
  tableFirstRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderColor: colors.unselected,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 9,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderRightWidth: 1,
  },
  tableCell: {
    borderLeftWidth: 1,
    borderColor: colors.unselected,
    width: '16%',
    maxWidth: '16%',
    paddingHorizontal: 4,
    paddingVertical: 2,
    height: '100%',
  },
  optionsCell: {
    minWidth: '50%',
    width: '100%',
  },
  rowNumberColumn: {
    width: 30,
    textAlign: 'center',
    paddingLeft: 0,
  },
  //#endregion
  //#region Indicator
  indicatorCtn: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'flex-end',
  },
  indicatorTxtCtn: {
    position: 'relative',
    marginRight: 4,
  },
  indicatorSlash: {
    fontSize: 11,
    fontWeight: 'bold',
    color: colors.bloodOrange,
    position: 'absolute',
    left: '40%',
    top: -2,
    transform: 'rotate(20)'
  },
  indicatorTxt: {
    fontSize: 7,
    color: colors.bloodOrange,
  },
  //#endregion
  //#region Footer
  footer: {
    position: 'absolute',
    height: 50,
    top: 0,
    left: 0,
    right: 0,
    paddingTop: 20,
    paddingRight: 30,
    justifyContent: 'flex-end',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    // backgroundColor: 'yellow',
  },
  footerText: {
    justifyContent: 'flex-start',
    color: 'grey',
    textAlign: 'center',
    fontSize: 10,
    fontFamily: 'Poppins',
    fontStyle: 'italic',
    fontWeight: 'light'
  },
  footerPageNb: {
    width: '20%',
    right: 30,
    // bottom: 25,
    fontSize: 10,
    justifyContent: 'flex-end',
    textAlign: 'right',
    color: 'grey',
  },
  //#endregion
})
const hyphenationCallback = (word) => {
  if (word.length > 0) {
    // return word.split(/(?=.)/g); // splits any character without removing splitting char
    let splitString = word.split(/(?=[A-Z  _]*)/)
    // let splitString = word.split(/(?= )(?=[A-Z])(?='_')+/g)
    // if (word.includes('_')) { devLog(splitString)}
    return splitString // splits the string without removing splitting char
  }
  else return ['']
}
Font.registerHyphenationCallback(hyphenationCallback)

const PageNumber = (props) => {
  return (
    <View style={styles.footer} fixed>
      <Text style={styles.footerText}>Plugform Setup</Text>
      <Text style={styles.footerPageNb} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} />
    </View>
  )
}

const infoIcon = Images.infoIconPng
const defaultValueIcon = Images.notePlugLightIcon
const optionsIcon = Images.settingsIcon

const renderExtdataOptions = (field) => {
  const options = field.options
  return (
    <View style={styles.detailsCtn} >
      <View style={styles.columnCtn}>
        <Text>Table: {options.ref}</Text>
        <Text>Query: {options.query}</Text>
        <Text>Search: {options.search}</Text>
      </View>
      <View style={styles.separationCol} />
      <View style={styles.columnCtn}>
        <Text>Searched Column(s): {options.column}</Text>
        <Text>Displayed Column(s): {options.columns}</Text>
        {
          field.format === "extdatan" &&
          <Text>Plugform: {options.plugform}</Text>
        }
        <Text>Field(s) completed: {options.dest}</Text>
      </View>
    </View>
  )
}

const renderSmailOptions = (field, t) => {
  const options = field.options
  return (
    <View style={styles.verticalCtn}>
      {renderBasicFieldOptions({ ...field, isFieldToPrint: false }, t)}
      <Text style={{ paddingTop: 6 }} >{t('common:mails.message')}: </Text>
      <View style={styles.emailCtn} >
        {
          options.body?.includes('<p')
            ? convertHtmlToNative(options.body)
            : <Text>{options.body}</Text>
        }
      </View>
    </View>
  )
}

const renderBasicFieldOptions = (field, t) => {
  const options = field.options
  const setup = options?.setup
  let template
  const seeBelowText = (
    <View style={styles.separationLine} >
      <Text style={styles.italicGrey} >{t('print.setup-see-more')}</Text>
    </View>
  )

  switch (field.format) {
    case 'agenda':
      template = <View ><Text>yyyy-mm-dd HH:MM</Text></View>
      break
    case 'birthday':
      template = <View ><Text>mm-dd</Text></View>
      break
    case 'boolean':
      template = <View ><Text>Y/N</Text></View>
      break
    case 'date':
      template = <View ><Text>yyyy-mm-dd</Text></View>
      break
    case 'document':
    case 'tablemultin':
    case 'smailvalidmulti':
    case 'tablemulti':
      const columns = isDefined(options?.lines) ? options.lines : []
      template = (
        <View>
          <Text>{columns?.length + ' ' + t('tablemulti.columns')}</Text>
          {
            isDefined(options?.ref) &&
            <View style={styles.separationLine} >
              {renderExtdataOptions(field)}
            </View>
          }
          {
            isDefined(options?.template) &&
            <View style={styles.separationLine} >
              {renderBasicFieldOptions({...field, format: 'smail'}, t)}
            </View>
          }
          {field.isFieldToPrint && seeBelowText}
        </View>
      )
      break
    case 'extdata':
    case 'extdatan':
      template = (
        <View>
          {renderExtdataOptions(field)}
          {field.isFieldToPrint && seeBelowText}
        </View>
      )
      break
    case 'formula':
      template = (
        <View>
          <Text>{t('fields.format-choice-formula') + ' : ' + options?.formula}</Text>
        </View>
      )
      break
    case 'numeric':
      let numMask
      if (isDefined(setup)) {
        let decimal = setup?.sepdecimal || '.'
        let thousand = setup?.septhousand || ','
        //numMask = setup?.isActiveThousandSeparator === true ? ('X' + thousand + 'XXX') : 'XXXX'
        numMask = setup?.septhousand !== false ? ('X' + thousand + 'XXX') : 'XXXX'
        if (isDefined(setup?.numberdecimals) && setup?.numberdecimals > 0) {
          numMask += decimal
          if (setup?.numberdecimals < 7) {
            for (let index = 0; index < setup?.numberdecimals; index++) {
              numMask += 'x'
            }
          } else numMask += 'xxx...'
        } else numMask += (decimal + 'xxx...')
      } else numMask = 'XXXX.xxx...'
      template = (
        <View>
          <Text>{numMask}</Text>
        </View>
      )
      break
    case 'pluglink':
      template = (
        <View>
          <Text>PF-code: {options?.pfcode || 'Ø'}</Text>
          {field.isFieldToPrint && seeBelowText}
        </View>
      )
      break
    case 'plugform':
      template = (
        <View>
          <Text>{t('print.setup-key') + ' Plugform: ' + (options?.clef || '-')}</Text>
          <Text>{t('print.setup-fields') + ': ' + (options?.field || '-')}</Text>
        </View>
      )
      break
    case 'select':
    case 'single-select':
    case 'free-single-select':
    case 'multi-select':
    case 'free-multi-select':
    case 'table':
    case 'single-table':
    case 'free-single-table':
    case 'multi-table':
    case 'free-multi-table':
      let formatType = field.options.multiselect ? 'multiple' : 'single'
      if (field.options.editable) { formatType = formatType + '-add' }
      template = (
        <View>
          <Text>{t('fields.format-choice-placeholder-preselection') + ' ' + t('selects.' + formatType)}</Text>
          <Text>{field.options?.presel?.length + ' ' + t('selects.presels')}</Text>
        </View>
      )
      break
    case 'sign':
      template = <View ><Text>{t('print.setup-template') + ' : ' + options?.template || 'Ø'}</Text></View>
      break
    case 'smail':
      // devLog('smail', field)
      template = (
        <View style={styles.verticalCtn} >
          <View style={styles.detailsCtn} >
            <View style={styles.columnCtn}>
              <Text>From: {options.from ? options.from : 'noreply@plugnotes.com'}</Text>
              <Text>Cc: {options.cc ? options.cc : '-'}</Text>
              <Text>Cci: {options.cci ? options.cci : '-'}</Text>
              <Text>Subject: {options.title}</Text>
            </View>
            <View style={styles.separationCol} />
            <View style={styles.columnCtn}>
              <Text>Template: {options.template}</Text>
              <Text>Copy auto: {options?.setup?.autoCopy ? 'Y' : 'N'}</Text>
              <Text>Attachments auto: {options?.setup?.autoAttachments ? 'Y' : 'N'}</Text>
              <Text>Attach notes: {options?.note}</Text>
            </View>
          </View>
          {field.isFieldToPrint && !isDefined(field.options.lines) && seeBelowText}
        </View>
      )
      break
    case 'text':
      template = <View ><Text>40 {t('print.setup-chars')}</Text></View>
      break
    case 'textarea':
      template = <View ><Text>1500 {t('print.setup-chars')}</Text></View>
      break
    case 'time':
      template = <View ><Text>HH:MM</Text></View>
      break
    case 'intdatamaj':
    case 'glocalis':
    case 'localis':
    case 'mail':
    case 'password':
    case 'qrcode':
    case 'qrcodeint':
    case 'url':
    default:
      template = (
        <View >
          <Text style={styles.italicGrey} >{t('print.setup-no-option')}</Text>
        </View>
      )
      break
  }
  return (
    template
  )
}

const renderColumnOptions = (col, t) => {
  let template
  switch (col.format) {
    case 's33data':
    case 'date':
    case 'duration':
    case 'mail':
    case 'numeric':
    case 'numeric-ref':
    case 'radio':
    case 'tel':
    case 'time':
    case 'url':
      template = (
        <View style={[styles.fieldCtn, { alignItems: 'center', }]}>
          <Text>
            {col.mask}
          </Text>
          {col.recap === true && <Text style={[styles.boldOrange, { marginLeft: 4 }]}>{' ('}Total)</Text>}
        </View>
      )
      break
    case 'text':
      template = (
        <Text>
          {col.mask} {t('print.setup-chars')}
        </Text>
      )
      break
    case 'extdata':
      const extdataCol = {
        format: 'extdata',
        id: col.id,
        options: col.configuration,
      }
      template = renderExtdataOptions(extdataCol)
      break
    case 'file-comment-text':
    case 'file-create-date':
    case 'file-name-text':
    case 'file-refid':
    case 'file-size-num':
    case 'file-source':
    case 'file-type-text':
    case 'file-uri':
      template = (
        <Text>{col.label}</Text>
      )
      break
    case 'formula':
      template = (
        <View style={[styles.fieldCtn, { alignItems: 'center', }]}>
          <Text>{col.configuration.formula}</Text>
          {col.recap === true && <Text style={[styles.boldOrange, { marginLeft: 4 }]}>{' ('}Total)</Text>}
        </View>
      )
      break
    case 'presel':
    case 'select':
      template = (
        <View style={styles.preselsCtn}>
          {col.presel.map(presel => <Text key={presel} style={styles.preselCtn}>{presel}</Text>)}
        </View>
        
      )
      break
    case 'intdata':
    case 'intdatamaj':
    case 'qrcode':
    case 'qrcodeint':
    default:
  }
  return template
}

const renderFieldDefaultValue = (field, t) => {
  const defaultValue = field?.options?.setup?.defaultValue || ''
  const defaultRows = field?.options?.setup?.defaultRows || []
  const lastRow = defaultRows[defaultRows.length - 1]
  if (typeof lastRow === 'object') {
    const isNotEmpty = Object.keys(lastRow).some(key => lastRow[key]?.value !== '')
    if (!isNotEmpty) { defaultRows.pop() }
  } else { defaultRows.pop() }
  if (defaultValue.length > 0 || defaultRows.length > 0 || typeof defaultValue === 'number') {
    return (
      <View style={{ flexDirection: 'row' }} >
        <View style={styles.iconCtn}>
          <Image src={defaultValueIcon} style={styles.icon} />
        </View>
        <View style={styles.verticalCtn}>
          <Text>
            {(defaultValue.length > 0 || typeof defaultValue === 'number') && defaultValue}
            {defaultRows.length > 0 && defaultRows.length + ' ' + t('print.setup-default-rows')}
          </Text>
          {defaultRows.length > 0 && field.isFieldToPrint && <Text style={styles.italicGrey} >{t('print.setup-see-rows')}</Text>}
        </View>
      </View>
    )
  } else { return null }
}

const firstColumnStyle = (data, cindex) => {
  let style = {}
  if (cindex === 0) { 
    style = { ...style, borderTopLeftRadius: 9 }
  }
  return style
}

const renderFieldOptions = (field, t, model) => {
  const fieldCode = getFormatCode(field)
  const fieldName = t('fields.format-choice-' + field.format)
  let template
  let options = field.options
  let extraTitle
  switch (field.format) {
    case "extdata":
    case "extdatan":
      const extdata = field?.extdata
      if (isDefined(extdata) && extdata.length > 0) {
        const tableColumns = Object.keys(extdata[0])
        extraTitle = `Table: ${options.ref}`
        const searchedCol = options.column?.split(';')
        const displayedCol = options.columns?.split(';')
        const completion = options?.dest.split('-').map(entry => {
          let options = entry.split(':')
          return {
            fieldId: options[0],
            columns: options[1].split(';'),
          }
        })

        template = (
          <View style={[styles.verticalCtn]}>
            <View style={[styles.fieldCtn, { width: 'auto', alignItems: 'stretch' }]} >
              <View style={styles.verticalCtn}>
                <Text>{t('ext-table.search-col')} : </Text>
                <View style={styles.optionsCtn}>
                  {searchedCol.map((col, cindex) => {
                    let colId = convertersAlphaToNumber(col)
                    let colName = tableColumns[colId - 1]
                    return (
                      <Text key={col + '-' + cindex}>{col}: {colName}
                        {/* {cindex + 1 < searchedCol.length ? ' | ' : ''} */}
                      </Text>
                    )
                  })}
                </View>
              </View>
              <View style={styles.separationCol} />
              <View style={styles.verticalCtn}>
                <Text>{t('ext-table.displayed-col')} : </Text>
                <View style={styles.optionsCtn} >
                  {displayedCol.map((col, cindex) => {
                    let colId = convertersAlphaToNumber(col)
                    let colName = tableColumns[colId - 1]
                    return (
                      <Text key={col + '-' + cindex}>{col}: {colName}
                        {/* {cindex + 1 < displayedCol.length ? ' | ' : ''} */}
                      </Text>
                    )
                  })}
                </View>
              </View>
              <View style={styles.separationCol} />
              <View style={[styles.verticalCtn, {flex: 1}]}>
                <Text>{t('ext-table.autocomplete-rules')} : </Text>
                <View style={[styles.fieldCtn, { flexWrap: 'wrap', }]} >
                  {
                    completion.map((option, index) => {
                      return (
                        <View key={option.fieldId} style={[{ paddingHorizontal: 6 }, index < (completion.length - 1) && { borderRightWidth: 1, borderColor: colors.borderGrey }]}>
                          <Text style={{ fontWeight: 'bold', }} >* {t('helper.field') + ' ' + option.fieldId} :</Text>
                          <View style={styles.optionsCtn}>
                            {
                              option.columns.map(col => {
                                let colId = convertersAlphaToNumber(col?.toUpperCase()) - 1
                                let colName = tableColumns[colId]
                                return (
                                  <Text key={col}>• {col?.trim()}: {colName}</Text>
                                )
                              })
                            }
                          </View> 
                        </View>
                      )
                    })
                  }
                </View>
                <View style={styles.optionsCtn}>
                  {/* <Text >{options.dest}</Text> */}
                </View>
              </View>

            </View>
            <View style={styles.tableCtn}>
              {
                tableColumns.map((col, cindex) => {
                  return (
                    <View style={[cindex === 0 ? styles.tableFirstRow : styles.tableRow, firstColumnStyle(tableColumns, cindex)]} key={col} wrap={false}>
                      <View style={[styles.tableHeader, { paddingHorizontal: 4, height: '100%', flexDirection: 'row' }, firstColumnStyle(tableColumns, cindex), { width: 20, height: '100%' }]}>
                        <Text style={styles.tableHeaderLabel}>{convertersNumberToAlpha((cindex + 1))}</Text>
                      </View>
                      <View style={[styles.tableCell, styles.tableHeader, { paddingHorizontal: 4, height: '100%', flexDirection: 'row' }, { width: '20%', height: '100%' }]}>
                        <Text style={styles.tableHeaderLabel}>{col}</Text>
                      </View>
                      {
                        extdata.map((row, rindex) => {
                          return (
                            <View style={styles.tableCell} key={row[col] + '-' + rindex}>
                              <Text>{row[col]}</Text>
                            </View>
                          )
                        })
                      }
                      {/* </View> */}
                    </View>
                  )
                })
              }
            </View>
          </View>
        )
      } else {
        template = (
          <View style={styles.preselsCtn} >
            <Text style={[styles.italicGrey, styles.boldOrange]} >{t('print.setup-undefined-table')}</Text>
          </View>
        )
      }
      break
    case "select":
    case "table":
      extraTitle = t('selects.select-list')
      template = (
        <View style={styles.preselsCtn}>
          {
            field.options?.presel.length > 0 &&
            field.options?.presel.map(presel => (<View key={presel} style={styles.preselCtn} ><Text>{presel}</Text></View>))
          }
        </View>
      )
      break
    case "pluglink":
      if (isDefined(field.pluglink)) {
        // devLog('PLUGLINK', field.pluglink)
        // devLog('MODEL', model, model['field7label'])
        template = (
          <View style={[styles.verticalCtn]} >
            <Text>{t('fields.format-title-pluglink-1')} : {field.pluglink.label} / {options.pfcode}</Text>
            {
              options.fields?.map((option, index) => {
                let source = model['field' + option.from + 'label']
                let destination = field.pluglink['field' + option.to + 'label']
                return (
                  <View key={index} style={[{ flexDirection: 'row', paddingVertical: 4 }, styles.optionsCtn]}>
                    <Text style={{ flex: 0.3}} >{t('print.from-field') + option.from} : {source}</Text>
                    <Text style={{ flex: 0.5}}>{t('print.to-field') + ' ' + option.to} : {destination}</Text>
                  </View>
                )
              })
            }
          </View>
        )
      }
      break
    case "tablemulti":
    case "tablemultin":
    case "document":
    case "smailvalidmulti":
      const tableOptions = {
        ...field,
        format: 'extdata',
      }
      template = (
        <View style={styles.verticalCtn} >
          {renderTablemultiOptions(field, t)}
          {(field.format === 'tablemultin' || field.format === 'smailvalidmulti') && renderFieldOptions(tableOptions, t)}
        </View>
      )
      break
    case "smail":
      template = renderSmailOptions(field, t)
      break
    default:
      break
  }

  return (
    <View key={'options-' + field.label + ' - ' + field.id} style={[styles.verticalCtn, { marginVertical: 12 }]} break={false} >
      <Text style={styles.subtitle} >
        {t('helper.field') + ' N° ' + field.id} : {field.label} {extraTitle?.length > 0 && (' | ' + extraTitle)}
        <Text style={styles.italicGrey}>{' ' + fieldCode + ' - ' + fieldName}</Text>
      </Text>
      {template}
    </View>
  )
}

const renderTablemultiOptions = (field, t) => {
  const columns = field.options.lines

  return (
    <View style={[styles.tableCtn, { alignItems: 'stretch' }]} >
      <View style={[styles.tableHeader, styles.tableFirstRow]} wrap={false} fixed>
        <View style={[styles.tableCell, { borderTopLeftRadius: 8, borderLeftWidth: 0 }, styles.rowNumberColumn]} >
          <Text>N°</Text>
        </View>
        <View style={styles.tableCell} >
          <Text>{t('helper.label')} (* {t('fields.format-choice-placeholder-required')})</Text>
        </View>
        <View style={styles.tableCell} >
          <Text>Format</Text>
        </View>
        <View style={[styles.tableCell, styles.optionsCell]} >
          <Text>{t('helper.options')}</Text>
        </View>
        <View style={[styles.tableCell, { width: 50 }]} >
          <Text>{t('helper.visibility')}</Text>
        </View>
      </View>
      {
        columns.map((col, cindex) => {
          return (
            <View style={[styles.tableRow, { alignItems: 'stretch', }]} key={col.id} wrap={false}>
              <View style={[styles.tableCell, styles.rowNumberColumn, { borderLeftWidth: 0 }]}>
                <Text>{cindex + 1}</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>{col.mandatory && '* '}{getPDFColumnLabel(col)}</Text>
              </View>
              <View style={styles.tableCell}>
                <Text>{t('fields.format-choice-' + col.format)}</Text>
              </View>
              <View style={[styles.tableCell, styles.optionsCell]}>
                {renderColumnOptions(col, t)}
              </View>
              <View style={[styles.tableCell, { width: 50 }]}>
                {renderFieldVisibility(col)}
              </View>
            </View>
          )
        })
      }
    </View> 
  )
}

const renderFieldVisibility = (field) => {
  const isTable = !isDefined(field.options)
  const encodable = field.isEncodable !== false && field.encodable !== false
  const visible = field.isVisible !== false && field.visible !== false
  const printable = field?.options?.setup?.printable !== false && field.showOnPdf !== false

  return (
  <View style={[styles.indicatorCtn, isTable ? { alignSelf: 'flex-start', } : undefined]}>
    {
      !encodable &&
      <View style={styles.indicatorTxtCtn}>
        <Text style={styles.indicatorTxt} >PF-</Text>
        <Text style={styles.indicatorSlash} >/</Text>
      </View>
    }
    {
      !visible &&
      <View style={styles.indicatorTxtCtn}>
        <Text style={styles.indicatorTxt} >PN-</Text>
        <Text style={styles.indicatorSlash} >/</Text>
      </View>
    }
    {
      !printable &&
      <View style={styles.indicatorTxtCtn}>
        <Text style={styles.indicatorTxt} >PDF</Text>
        <Text style={styles.indicatorSlash} >/</Text>
      </View>
    }
  </View>
)}

const renderBasicField = (field, t) => {
  const fieldCode = getFormatCode(field)
  const fieldName = t('fields.format-choice-' + field.format)
  const infobulle = field.infobulle || ''
  return (
    <View style={styles.borderedCtn} key={field.label + ' - ' + field.id} wrap={false}>
      <View style={styles.verticalCtn}>
        <View style={[styles.fieldCtn, { justifyContent: 'space-between', position: 'relative' }]} >
          <View style={[styles.fieldMainInfoCtn, { flexGrow: 1 }]}>
            <View style={{
              // flexGrow: 0.6,
              width: '60%',
              flexDirection: 'row' }} >
              <Text style={{ marginRight: 4 }}>{field.id}</Text>
              <Text style={{ marginRight: 4 }}>{fieldCode}</Text>
              <Text style={[{ fontWeight: 'bold' }, styles.subtitle]} >{field?.options?.mandatory ? '* ' : ''}{field.label}</Text>
            </View>
            <View style={{
              // flexGrow: 0.4,
              width: '40%',
            alignSelf: 'flex-start', textAlign: 'left' }} >
              <Text style={[{ fontWeight: 'bold' }, styles.italicGrey]} >{fieldName}</Text>
            </View>
          </View>
          <View style={[styles.fieldSubInfoCtn, { position: 'absolute', top: 2, right: 0, width: 60 }]}>
            {renderFieldVisibility(field)}
          </View>
        </View>
        <View style={styles.fieldCtn}>
          <View style={{ flex: 0.6 }} >
            <View style={{ flexDirection: 'row', flexShrink: 0, flexGrow: 1, marginTop: 4 }} >
              <View style={styles.iconCtn}>
                <Image src={optionsIcon} style={styles.icon} />
              </View>
              {renderBasicFieldOptions(field, t)}
            </View>
          </View>
          <View style={{ flex: 0.4, flexDirection: 'column', marginHorizontal: 8 }} >
            {renderFieldDefaultValue(field, t)}
            {
              infobulle.length > 0 &&
              <View style={styles.infobulleCtn} >
                <View style={styles.iconCtn}>
                  <Image src={infoIcon} style={styles.icon} />
                </View>
                <Text>{infobulle}</Text>
              </View>
            }
          </View>
        </View>

      </View> 
    </View>
  )
}

const MyDoc = (props) => {
  // devLog('MyDoc got props', props)
  const t = props.t
  const fields = props.fields
  const model = props.model
  const printDate = currentDateAndHours()
  const username = isDefined(props.user?.pseudo) ? props.user.pseudo : props.user?.firstname + ' ' + props.user?.lastname

  return (
    <Document>
      <Page size="A4" style={styles.page} orientation="landscape">
        <PageNumber fixed />
        <Text style={styles.headerTitle} >Plugform Setup</Text>
        <View style={styles.mainInfoCtn} >
          <View style={styles.mainInfo} >
            <Text style={styles.mainTitle} >{model.label}<br />[{model.clef}] {props.pfCode?.length > 0 ? ('- ' + props.pfCode) : ''}</Text>
          </View>
          <View style={styles.printInfoCtn} >
            <Text>{t('print.printed-on') + printDate}</Text>
            <Text>{t('print.printed-by') + username}</Text>
          </View>
        </View>
        <View style={styles.fieldListCtn} >
          <Text style={styles.headerTitle}>{t('fields.field-list')}</Text>
          {
            fields.map(field => renderBasicField(field, t))
          }
        </View>
        {
          fields.some(field => field?.isFieldToPrint) &&
          <View style={styles.fieldListCtn} >
            <Text style={styles.headerTitle}>{t('print.setup-details')}</Text>
            {
              fields.map(field => {
                if (field.isFieldToPrint) {
                  return renderFieldOptions(field, t, props.model)
                } else return null
              })
            }
          </View>
        }
      </Page>
    </Document>
  )
}

const useStyles = makeStyles(() => ({
  tabsCtn: {
    boxShadow: '0px 20px 20px 3px rgb(0 0 0 / 14%)',
    top: 0,
    position: 'sticky',
    zIndex: 1,
    backgroundColor: 'white',
    height: 'fit-content',
    padding: '0 40px',
    '& .MuiTab-root': {
      textTransform: 'none !important',
    },
    '@media (max-width: 600px)': {
      margin: '0 -50px',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: colors.main,
      width: 3,
    },
  },
  tab: {
    '& .MuiTab-root': {
      textTransform: 'none !important',
    },
  },
  tabScreenCtn: {
    paddingTop: 40,
  },
  tabScreen: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    paddingLeft: 40,
  },
  radioCtn: {
    display: 'flex',
    flexDirection: 'row',
  },
  cbOptionCtn: {
    display: 'flex',
    flexDirection: 'row',
    gap: 4,
    alignItems: 'center',
    marginBottom: 12,
    cursor: 'pointer',
  },
  pdfCtn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    height: 'calc(95vh - 140px)'
  },
  previewCtn: {
    border: 'none',
    width: '100%',
    minWidth: '400px',
    height: '100%',
    '@media (min-width:1025px)': {
      minWidth: '60vw'
    }
  },
  printBtn: {
    width: 100,
    minWidth: '100px !important',
    flexDirection: 'row',
    display: 'flex',
    position: 'sticky',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const PlugformSetupPrintable = (props) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  const model = props.model
  const [tabNb, setTabNb] = useState(0)
  const [visibility, setVisibility] = useState(false)
  const [defaultValues, setDefaultValues] = useState(false)
  const [print, setPrint] = useState(false)
  const [fields, setFields] = useState(props.fields.map(field => {
    return {
      ...field, isFieldToPrint: false
    }
  }));
  const [selectAllFields, setSelectAllFields] = useState(false);
  const [pfCode, setPfCode] = useState('')
  const [dbName, setDbName] = useState('')
  const [pluglink, setPluglink] = useState({})
  const { extdata, isReady, db } = useFetchExtdata(dbName, props.user.hash)
  const pluglinkModel = useFetchPlugform(pluglink)


  const fieldsToShowInTab = ["extdata", "extdatan", "select", "table", "pluglink", "tablemulti", "tablemultin", "document", "smailvalidmulti", "smail"];
  const fieldsWithDb = ["extdata", "extdatan", "smailvalidmulti", "tablemultin"]
  const defaultTab = 0;
  const tablemultiTab = fields.some(entry => fieldsToShowInTab.includes(entry.format)) ? 1 : -1;

  useEffect(() => {
    if (isDefined(model.codepfcode)) {
      const plugcode = 'PF-' + moment(model.creationdate.date).format('YYMMDD') + '-' + model.codepfcode
      // devLog('code:', plugcode)
      setPfCode(plugcode)
    }
  }, [])

  useEffect(() => {
    if (isDefined(extdata)) {
      // devLog('fetched', extdata)
      let field = fields.find(entry => entry.options.ref === db)
      let findex = fields.indexOf(field)
      field.extdata = extdata
      let _fields = [...fields]
      _fields[findex] = field
      setFields(_fields)
    }
  }, [extdata])

  useEffect(() => {
    // devLog('fetched model', pluglinkModel)
    if (isDefined(pluglinkModel)) {
      let field = fields.find(entry => entry.id === pluglink.id)
      let findex = fields.indexOf(field)
      
      field.pluglink = pluglinkModel
      let _fields = [...fields]
      _fields[findex] = field
      setFields(_fields)
    }
  }, [pluglinkModel])

  const handleChangeTab = (_, nb) => {
    setTabNb(nb)
  }

  const handleClickPrint = () => { setPrint(true) }

  const handleDownloadPdf = (link) => { window.open(link, "_blank") }

  const handleClickFieldCheckBox = (index) => {
    let fieldTemp = [...fields];
    fieldTemp[index].isFieldToPrint = !fieldTemp[index].isFieldToPrint;
    if (fieldTemp[index].isFieldToPrint && fieldsWithDb.includes(fieldTemp[index].format)) {
      setDbName(fieldTemp[index].options?.ref)
    } else if (fieldTemp[index].format === 'pluglink') {
      devLog('field', fieldTemp[index])
      if (isDefined(fieldTemp[index]?.options?.pfcode)) {
        setPluglink(fieldTemp[index])
      }
    }
    setFields(fieldTemp);
  }

  const handleClickSelectAllFieldsCheckbox = () => {
    let fieldTemp = [...fields];
    selectAllFields ? fieldTemp.map(field => { field.isFieldToPrint = false }) : fieldTemp.map(field => { field.isFieldToPrint = true });
    setSelectAllFields(previous => !previous);
    setFields(fieldTemp);
  }

  if (print) {
    return (
      <BlobProvider
        document={<MyDoc {...props} pfCode={pfCode} t={t} fields={fields} />}
        style={{ textDecoration: 'none', color: 'black', display: 'flex', height: '70vh', width: '100%', justifyContent: 'center', }}
      >
        {({ blob, url, loading, error }) => {
          return (
            <div className={classes.pdfCtn}>
              <iframe title='pdf-preview' src={url + '#toolbar=1&FitV'}
                className={classes.previewCtn} height="100%"
                style={{
                  width: '100%',
                  // maxWidth: 'calc(80vw - 154px)'
                }}
              />
              <br />
              <div style={{ display: 'flex' }} onClick={() => handleDownloadPdf(url)}>
                <span className="material-icons md-25 "> picture_as_pdf </span>
                <div className={classes.pointer}>{t('common:global.print')}</div>
              </div>
            </div>
          )
        }}
      </BlobProvider>
    )
  }
  else return (
    <div>
      <div className={classes.tabsCtn} >
        <span style={{ position: 'relative', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', }}>
          <Tabs
            onChange={handleChangeTab}
            textColor="inherit"
            value={tabNb}
            variant="scrollable"
            scrollButtons="on"
          >
            <Tab
              disableRipple
              icon={<FormatListNumbered fill={tabNb === 0 ? colors.main : colors.coal} size={20} />}
              label={t('print.setup-fields')} className={`${classes.tab} ${tabNb !== 0 && classes.unselectedTab}`}
            />
            {/* <Tab
              icon={<BackupTable fill={tabNb === tablemultiTab ? colors.main : colors.coal} size={20} />}
              disableRipple
              label={t('print.setup-tablemulti')} className={`${classes.tab} ${tabNb !== tablemultiTab && classes.unselectedTab}`}
              style={tablemultiTab < 0 ? { display: 'none' } : undefined}
            /> */}
          </Tabs>
          <div className={`button ${classes.printBtn}`} onClick={handleClickPrint}>
            <span className="material-icons" style={{ color: 'white' }}>
              print
            </span>
          </div>
        </span>
      </div>
      <div className={classes.tabScreenCtn} >
        {/* <div className={classes.screenOptCtn} >
          <RadioGroup
            className={classes.radioCtn}

            onChange={handleToggleAllOptions}
            value={true}
          >
            <FormControlLabel size='small' value={true}
              control={
                <Radio classes={{ root: classes.radioIcon, checked: classes.radioIconChecked }}
                  size='small'
                  disabled={props.disabled}
                />
              }
              label={<span className={classes.radioLabel}>{t('global.options-select-all')}</span>}
              key="select-all"
            />
            <FormControlLabel size='small' value={false}
              control={
                <Radio classes={{ root: classes.radioIcon, checked: classes.radioIconChecked }}
                  size='small'
                  disabled={props.disabled}
                />
              }
              label={<span className={classes.radioLabel}>{t('global.options-select-none')}</span>}
              key="select-none"
            />
          </RadioGroup>
        </div> */}
        {
          tabNb === 1 &&
          <div className={classes.tabScreen}>
            <div className={classes.cbOptionCtn} onClick={() => setDefaultValues(previous => !previous)}>
              <ColorCheckbox checked={defaultValues} />
              <EditValueIcon color={tabNb === defaultTab ? colors.unselected : colors.coal} />
              {t('print.setup-default')}
            </div>
            <div className={classes.cbOptionCtn} onClick={() => setVisibility(previous => !previous)}>
              <ColorCheckbox checked={visibility} />
              <Visibility color={tabNb === defaultTab ? colors.unselected : colors.coal} />
              {t('print.setup-visibility')}
            </div>
          </div>
        }

        {
          tabNb === 0 &&
          <div className={classes.tabScreen}>
            {/* <div onClick={() => handleClickSelectAllFieldsCheckbox()}>
              <ColorCheckbox
                checked={selectAllFields}
              />
              <span>Tous les champs</span>
              <hr />
            </div> */}
            <span>{t('print.detailed-select')}</span>
            <div>
              {
                fields
                  .map((field, index) => {
                    let fieldFormat = field.format;
                    let fieldLabel = field.label;

                    if (fieldsToShowInTab.includes(fieldFormat)) {
                      return (
                        <div key={index} onClick={() => handleClickFieldCheckBox(index)} style={{ cursor: 'pointer' }}>
                          <ColorCheckbox
                            key={index}
                            checked={field.isFieldToPrint}
                          />
                          <span> {fieldLabel} - </span>
                          <i> {t("common:fields.format-choice-" + fieldFormat.toLowerCase())} </i>
                          <i>
                            {`(${getFormatCode(field)})`}
                          </i>
                          <hr />
                        </div>
                      )
                    } else return null
                  })
              }
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default PlugformSetupPrintable
