import { useState } from "react"
import { isMobile } from "react-device-detect"

export const useDrawer = () => {
  const [openDrawer, setOpenDrawer] = useState(isMobile ? false : true)

  const handleToggleDrawer = () => { 
    // console.log('toggle drawer')
    setOpenDrawer(curr => !curr)
  }

  return { openDrawer, handleToggleDrawer }
}