import React from 'react'
import { Modal, makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { FaEdit } from 'react-icons/fa'
import colors from '../../../assets/themes/Colors'
import { handlePreventDefault } from '../../../utils/utils'
import RichEditor from '../../custom/rich-editor/RichEditor'

const useStyles = makeStyles(() => ({
  modalBody: {
    position: "absolute",
    minWidth: "50%",
    maxWidth: "90%",
    backgroundColor: "white",
    width: "820px",
    border: "1px solid #DEDEDE",
    boxSizing: "border-box",
    boxShadow: "0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)",
    borderRadius: "20px",
    padding: 30,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxWidth: "90%",
    margin: "auto",
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  note: {
    padding: 10,
    backgroundColor: colors.paleGrey,
    borderRadius: 8,
    margin: 8,
    minHeight: 180,
    color: colors.unselected,
  },
  commentCtn: {
    padding: 10,
  }
}))

const ModalNotePreviewComponent = (props) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
 
  return (
    <Modal
      keepMounted={false}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      className={classes.modal}
      onClick={handlePreventDefault}
    >
      <div className={classes.modalBody}>
        <div className={classes.header} >
          <span className="modalTitle">{t('tablemulti.note-preview')}</span>
          <div className={classes.closeBtn} >
            <span id="icon-close"
              className="material-icons md-50"
              color={colors.iconGrey}
              style={{
                color: colors.iconGrey,
                marginLeft: 20,
              }}
              onClick={props.handleClose}>
              close
            </span>
          </div>
        </div>
        <div className={classes.noteCtn} >
          <div>{props.note.name}</div>
          {/* <div dangerouslySetInnerHTML={{ __html: props.note.Webdoc.content }} className={classes.note} /> */}
          <RichEditor
            init={{
              height: 500,
              menubar: true,
              plugins: [
                'advlist', 'anchor', 'autolink', 'image', 'link', 'lists',
                'searchreplace', 'table', 'wordcount'
              ],
              // toolbar: 'undo redo | blocks | ' +
              //   'bold italic forecolor | alignleft aligncenter ' +
              //   'alignright alignjustify | bullist numlist outdent indent | ' +
              //   'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
            disabled
            // onEditorChange={handleEditBody}
            // onInit={(evt, editor) => editorRef.current = editor}
            value={props.note.Webdoc.content}
          />
          {/* <HtmlEditor
            disabled
            options={options}
            value={props.note.Webdoc.content}
            className="input input_area"
          /> */}
          {
            props.note.comment?.length > 0 &&
            <div className={classes.commentCtn} >
              <FaEdit />
              {props.note.comment}
            </div>
          }
        </div>
      </div>
    </Modal>
  )
}

export default ModalNotePreviewComponent
