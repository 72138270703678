import { Document, Font, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import colors from '../../assets/themes/Colors';
import i18n from '../../translations/i18n';
import popLig from '../../fonts/Poppins-Regular.ttf'
import popLigIt from '../../fonts/Poppins-LightItalic.ttf'
import popBol from '../../fonts/Poppins-Bold.ttf'
import popReg from '../../fonts/Poppins-Medium.ttf'
import { ModalTablemultiTableViewComponent } from '../modals/tablemulti/ModalTablemultiTableViewComponent';
import { devLog, isDefined } from '../../utils/utils';

Font.register({
  family: 'Poppins',
  fonts: [
    { src: popBol, fontWeight: 'bold' },
    { src: popLig, fontWeight: 'light' },
    { src: popReg, fontWeight: 'normal' },
    { src: popLigIt, fontStyle: 'italic', fontWeight: 'light' }
  ]
})

const styles = StyleSheet.create({
  mainCtn: {
    position: 'relative',
    flexGrow: 1,
    height: '100%',
    width: '100%',
    paddingBottom: 50
  },
  // BODY
  bodyCtn: {
    flexDirection: 'column'
    // marginTop: -20,
  },
  page: {
    paddingTop: 40,
    paddingBottom: 60
  },
  //HEADER
  headerCtn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0px 50px 0px 50px',
    position: 'relative',
    minHeight: 60
  },
  // BODY
  titleCtn: {
    display: 'inline-flex',
    flexDirection: 'row',
    alignContent: 'center',
    marginBottom: 12
  },
  titleLine: {
    width: 100,
    height: 6,
    backgroundColor: colors.main,
    marginRight: 15
  },
  subtitle: {
    color: colors.unselected,
    fontWeight: 'bold',
    fontFamily: 'Poppins',
    fontSize: 11,
    letterSpacing: 1,
    marginTop: -5
  },
  // FIELD
  mainFieldCtn: {
    flexDirection: 'column'
  },
  fullWidthFieldCtn: {
    alignContent: 'center',
    flexDirection: 'column',
    marginBottom: 8,
    paddingLeft: 35,
    // backgroundColor: colors.cloud // pour test
  },
  fullWidthLabelCtn: {
    width: '100%',
    marginBottom: 10
  },
  fieldLabel: {
    fontFamily: 'Poppins',
    color: colors.main,
    fontSize: 11
  },
  fullWidthValueCtn: {
    width: '100%',
    margin: '0px',
    padding: '5px 20px 5px 0px',
    // backgroundColor: colors.darkgrey // pour test
  },
})


const TableMultiPrintable = (props) => {
  // devLog('TablemultiPrintable got props', props)
  const t = props.t
  const field = props.field

  let today = new Date()
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0') // January is 0!
  const yyyy = today.getFullYear()
  let date = i18n.language === 'en' ? (mm + '/' + dd + '/' + yyyy) : (dd + '/' + mm + '/' + yyyy)
  const formatOptions = field.options
  const headers = isDefined(formatOptions.lines) ? formatOptions.lines : formatOptions
  const value = isDefined(field.value.list) ? field.value.list : field.value

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.headerCtn}>
          <Text style={{ color: colors.unselected, fontSize: 9, fontFamily: 'Poppins' }}>{t('common:print.printed-on')}{date}</Text>
          <Text style={{ color: colors.unselected, fontSize: 13, fontFamily: 'Poppins' }}>[{props.model.clef}] - PN: {props.plugnoteNb}</Text>
        </View>

        <View style={styles.bodyCtn}>
          <View style={styles.titleCtn}>
            <View style={styles.titleLine} />
            <Text style={styles.subtitle} wrap>
              {props.model.label.toUpperCase()}
            </Text>
          </View>

          <View
            key={'field-' + props.fieldLabel}
            style={styles.mainFieldCtn}
          >
            <View style={styles.fullWidthFieldCtn} >
              <View style={styles.fullWidthLabelCtn}>
                <Text style={styles.fieldLabel}>{field.title}: </Text>
              </View>
              <View>
                <View style={styles.fullWidthValueCtn}>
                  <ModalTablemultiTableViewComponent
                    colonnes={headers}
                    field={field}
                    isPreview={false}
                    mode={props.mode}
                    rows={Array.isArray(value) ? value : []}
                    toPrint
                  />
                </View>
              </View>
            </View>

          </View>
        </View>
      </Page>
    </Document>
  );
};

export default TableMultiPrintable;
