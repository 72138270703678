import {Types} from '../actions/user';

// create initial state for reducers
const INIT_STATE = {
    fetching : false,
    fetchingConfig : false,
    user : [],
    token : null,
    hash : null,
    profil : null,
    environnement : null,
    listEnvironnement : [],
    sortedAndFilteredEnvironnement : [],
    authLoading : false,
    main : null,
    package: null,
    packages: [],
    listToAccept : [],
    config : null
};

// reducer function to transform state
export default function user(state = INIT_STATE, action) {
    switch(action.type) {
        case Types.RESET_ALL_DATA : return INIT_STATE;
        case Types.GET_PROFILS_SUCCESS:{
            return {
                ...state,
                listToAccept : !!action.payload[action.payload.length - 1].listmodelssharedinwaiting ? action.payload[action.payload.length - 1].listmodelssharedinwaiting : [],
            }
        }

        case Types.GET_USER_QUOTA_SUCCESS:{
            return{
                ...state,
                package: action.payload.data.package,
                packages: action.payload.data.packages,
            }
        }
        case Types.GET_LOGIN_REAL_USER_SUCCESS: {
            return {
                ...state,
                user: action.payload.data,
                profil : action.payload.data._value,
                token : action.payload.data.token,
                hash : action.payload.data.hash,
                listToAccept : !!action.payload.data.profil[action.payload.data.profil.length - 1].listmodelssharedinwaiting ? action.payload.data.profil[action.payload.data.profil.length - 1].listmodelssharedinwaiting : [],
                fetching : false,
                authLoading : true,
            }
        }

        case Types.GET_ENVIRONMENTS_SUCCESS:{
            let user = state.user;
            user.profil = action.payload.data;
            return {
                ...state,
                user
            }
        }

        case Types.GET_LOGIN_REAL_USER_REQUEST: {
            return {
                ...state,
                fetching: true,
            }
        }
        case Types.GET_LOGIN_REAL_USER_ERROR: {
            return {
                ...state,
                fetching : false,
                user : [],
                token : null,
                hash : null,
                environnement : null,
                listEnvironnement : [],
                sortedAndFilteredEnvironnement : [],
                authLoading : false,
            }
        }
        case Types.GET_LOGIN_REAL_USER_FAILURE: {
            return {
                ...state,
                fetching : false,
                user : [],
                token : null,
                hash : null,
                environnement : null,
                listEnvironnement : [],
                sortedAndFilteredEnvironnement : [],
                authLoading : false,
            }
        }
        case Types.POST_LOGOUT_SUCCESS: {
            return {
                fetching: false,
                authLoading : false,
            }
        }
        case Types.POST_LOGOUT_FAILURE: {
            return {
                fetching: false,
            }
        }
        case Types.POST_LOGOUT_ERROR: {
            return {
                fetching: false,
            }
        }
        case Types.GET_CHECK_TOKEN_ERROR:{
            return {
                fetching: false,
            }
        }

        case Types.GET_USER_REQUEST:{
            return {
                ...state,
                main : null,
                fetching : true,
            }
        }

        case Types.GET_USER_SUCCESS:{
            return {
                ...state,
                main : action.payload.data,
                fetching : false,
            }
        }

        case Types.PUT_USER_SUCCESS:{
            return {
                ...state,
                main : action.payload.data,
                fetching : false,
            }
        }

        case Types.GET_USER_CONFIG_REQUEST:{
            return {
                ...state,
                config : null,
                fetchingConfig : true,
            }
        }

        case Types.GET_USER_CONFIG_SUCCESS:{
            return {
                ...state,
                config: action.payload.data,
                fetchingConfig : false
            }
        }

        case Types.GET_USER_CONFIG_ERROR:
        case Types.GET_USER_CONFIG_FAILURE:{
            return {
                ...state,
                config: null,
                fetchingConfig : false
            }
        }

        case Types.CLEAN_LIST_TO_ACCEPT:{

            let list = state.listToAccept.filter((item, index) => {
                if(item.modelsshared_hash !== action.payload){
                    return item;
                }
            });

            list.filter(Boolean);

            return{
                ...state,
                listToAccept: list,
            }
        }
        default: return state;
    }
};
