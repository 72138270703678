import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {toast} from "react-toastify";
import {Content, Header} from "rsuite";
import {getCurrentEnvironnement, getModelToUpdate, removeModelToUpdate} from "../../utils/Common";
import {useTranslation} from "react-i18next";
import {connect} from "react-redux";
import {getFieldsRequest} from "../../actions/field";
import _, {camelCase, isEmpty, sortBy} from 'lodash'
import Images from "../../assets/themes/Images";
import {postModelRequest, putModelRequest} from "../../actions/model";
import Input from "@material-ui/core/Input";
import FormatSelectConstructorComponent from "../../components/constructors/FormatSelectConstructorComponent";
import FormatFieldConstructorComponent from "../../components/constructors/FormatFieldConstructorComponent";
import {fieldTypes, formatTypes} from "../../utils/Compatibility";
import {confirmAlert} from "react-confirm-alert";
import Dropzone from "react-dropzone";
import * as XLSX from "xlsx";
import Tooltip from "@material-ui/core/Tooltip";
import FormatTableConstructorComponent from "../../components/constructors/FormatTableConstructorComponent";
import FormatPlugformConstructorComponent from "../../components/constructors/FormatPlugformConstructorComponent";
import FormatPluglinkConstructorComponent from "../../components/constructors/FormatPluglinkConstructorComponent";
import FormatFormulaConstructorComponent from "../../components/constructors/FormatFormulaConstructorComponent";

const drawerWidth = 320;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    mandatory:{
        color : '#E54F62',
    },
    notMandatory:{
        color : '#7F7F7F',
    },
    isTrue:{
        color : '#58C3C1',
    },
    isFalse:{
        color : '#E54F62',
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: '60px'
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    openClose: {
        //bottom: '-20px',
        //position: 'absolute',
        width : '100%'
    },
    openCloseButton : {
        float : 'right'
    },
    content: {
        width : '100%',
        backgroundColor : 'white',
        padding: '20px 20px',
    },
    contentModel : {
        flexGrow: 1,
        padding: theme.spacing(3),
        overflow : 'scroll',
        maxHeight : 'calc(80vh - 100px)'
    },
    icon:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : 'black'
    },
    iconActive:{
        width: 56,
        height: 56,
        lineHeight: '56px',
        textAlign: 'center',
        color : 'blue'
    },
    gridHeader : {
        padding: '10px 20px !important',
        width: '100%',
    },
    selectEnvironnement : {
    },
    fieldImage: {
        height: 10,
        width: 10
    },
    fieldImageSvg: {
        width: 25,
        height: 25,
    },
    fieldIcon : {
        width: 20,
        height: 20,
        backgroundColor: '#57bbb8',
        borderRadius: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    fieldIconSvg : {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: '25ch',
    },
    field : {
        display: 'flex',
        flexDirection: 'row',
    },
    fieldLeft : {
        alignContent: 'flex-start',
        display: 'flex',
        alignItems: 'center',
    },
    fieldRightLeft : {
        alignContent: 'flex-end'
    },
    fullWidth :{
        width : '100%'
    },
    paper : {
        display: 'block !important',
        overflow : 'hidden !important'
    },
    paperAnchorDockedLeft : {
        boxShadow: '0px 0px 6px 4px rgba(136, 136, 136, 0.12)',
        border : 0,
    },
    fieldRight:{
        textAlign : 'center'
    },
}));

const speciaFields = ['formula', 'plugform', 'pluglink',]


function ModelUpdateV1(props) {
    const classes = useStyles();
    const { t, i18n } = useTranslation(['common', 'models']);
    const langue = i18n.language.charAt(0).toLowerCase() + i18n.language.charAt(1).toLowerCase() === 'fr' ? '' : 'En'

    let model = getModelToUpdate();

    const [structureEditable, setStructureEditable] = useState(model.isStructureEditable === 1)

    const labels = Object.keys(model).filter((key) => {
        const regex = /field[0-9]+label/gi;
        return regex.test(key) && model[key] != null;
    });

    let fields = labels.map((label, index) => {
        let formatoptions = model['field' + (index + 1) + 'formatoptions'] || {};
        if (typeof formatoptions !== 'object') {
            formatoptions = JSON.parse(formatoptions);
        }

        if(model['field' + (index + 1) + 'format'] === "select"){
            if(typeof formatoptions.presel !== "undefined" && formatoptions.presel !== "" && formatoptions.presel !== "[]" && formatoptions.presel.length !== 0){
                formatoptions.presel = formatoptions.presel.toString().split(";")
            }else{
                formatoptions.presel = [];
            }
        }

        if(model['field' + (index + 1) + 'format'] === "table"){
            if(typeof formatoptions.presel !== "undefined" && formatoptions.presel !== "" && formatoptions.presel !== "[]" && formatoptions.presel.length !== 0){
                formatoptions.presel = formatoptions.presel.toString().split(";")
            }else{
                formatoptions.presel = [];
            }
        }

        if(structureEditable){
            return {
                newField: !model.nbFichesArchived && !model.nbFichesNotArchived,
                dragid : 'item-' + index,
                key: index + 1,
                id: index + 1,
                position : formatTypes[model['field' + (index + 1) + 'format'].toUpperCase()],
                isEncodable : !!model['field' + (index + 1) + 'encodable'] && model['field' + (index + 1) + 'encodable'] === 1,
                isVisible : !!model['field' + (index + 1) + 'visible'] && model['field' + (index + 1) + 'visible'] === 1,
                label: model['field' + (index + 1) + 'label'],
                format: model['field' + (index + 1) + 'format'],
                options: {
                    mandatory: !!model['field' + (index + 1) + 'mandatory'],
                    ...formatoptions
                },
                formatoptions: formatoptions
            }
        }else{
            return {
                newField: false,
                dragid : 'item-' + index,
                key: index + 1,
                id: index + 1,
                position : formatTypes[model['field' + (index + 1) + 'format'].toUpperCase()],
                isEncodable : !!model['field' + (index + 1) + 'encodable'] && model['field' + (index + 1) + 'encodable'] === 1,
                isVisible : !!model['field' + (index + 1) + 'visible'] && model['field' + (index + 1) + 'visible'] === 1,
                label: model['field' + (index + 1) + 'label'],
                format: model['field' + (index + 1) + 'format'],
                options: {
                    mandatory: !!model['field' + (index + 1) + 'mandatory'],
                    ...formatoptions
                },
                formatoptions: formatoptions
            }
        }
    });

    let title = model.label;
    let clef = model.clef;
    let visibleField = typeof model.fieldEmptyVisible !== "undefined" ? model.fieldEmptyVisible : false;


    const [open, setOpen] = React.useState(true);

    const user = props.user;
    const environment = getCurrentEnvironnement();

    const token = props.token;

    const [updateModel, setUpdateModel] = useState(fields);

    const [nameModel, setNameModel] = useState(title);
    const [clefModel, setClefModel] = useState(clef);
    const [fieldEmptyVisible, setFieldEmptyVisible] = useState(visibleField);


    let regexp = new RegExp('^[A-Z]{2}[0-9]{2}$');
    let  test = regexp.test(model.clef);

    const [modelKeyCanChange, setModelKeyCanChange] = useState(test);
    const [modelKeyDoChange, setModelKeyDoChange] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const handleListItemClick = (event, fieldSelected) => {

        if(updateModel.length < 15){

            let options = {};
            // Récupération du dernier item, avec le tri des valeurs en fonction de la clé
            let lastField = _.orderBy(Object.values(updateModel), 'key', 'desc')[0];

            // Si c'est le premier champ on initialise à 0
            if (!lastField) lastField = { key: 0, id: 0 };


            if(fieldSelected.identifiant === 'select'){
                setUpdateModel(oldArray => [...oldArray, {
                    dragid : 'item-' + lastField.id,
                    key: lastField.key + 1,
                    id: lastField.id + 1,
                    label: "",
                    isEncodable : true,
                    position : fieldSelected.position,
                    isVisible : true,
                    format: fieldSelected.identifiant,
                    options: {
                        mandatory: false,
                        editable : false,
                        multiselect : false,
                        presel : [],
                    },
                    newField : true,
                }]);
            }
            else  if(fieldSelected.identifiant === 'table'){
                setUpdateModel(oldArray => [...oldArray, {
                    dragid : 'item-' + lastField.id,
                    key: lastField.key + 1,
                    id: lastField.id + 1,
                    label: "",
                    position : fieldSelected.position,
                    isVisible : true,
                    isEncodable : true,
                    format: fieldSelected.identifiant,
                    options: {
                        mandatory: false,
                        editable : false,
                        multiselect : false,
                        presel : [],
                    },
                    newField : true,
                }]);
            }
            else if(fieldSelected.identifiant === 'plugform'){
                setUpdateModel(oldArray => [...oldArray, {
                    dragid : 'item-' + lastField.id,
                    key: lastField.key + 1,
                    id: lastField.id + 1,
                    label: "",
                    isEncodable : true,
                    isVisible : true,
                    position : fieldSelected.position,
                    format: fieldSelected.identifiant,
                    options: {
                        mandatory: false,
                        editable : false,
                        multiselect : false,
                        presel : [],
                    },
                    newField : true,
                }]);
            }
            else if(fieldSelected.identifiant === 'formula'){
                setUpdateModel(oldArray => [...oldArray, {
                    dragid : 'item-' + lastField.id,
                    key: lastField.key + 1,
                    id: lastField.id + 1,
                    label: "",
                    isEncodable : true,
                    isVisible : true,
                    position : fieldSelected.position,
                    format: fieldSelected.identifiant,
                    options: {
                        mandatory: false,
                        editable : false,
                        multiselect : false,
                        formula : null,
                    },
                    newField : true,
                }]);
            }
            else{
                setUpdateModel(oldArray => [...oldArray, {
                    dragid : 'item-' + lastField.id,
                    key: lastField.key + 1,
                    id: lastField.id + 1,
                    label: "",
                    position : fieldSelected.position,
                    isEncodable : true,
                    isVisible : true,
                    format: fieldSelected.identifiant,
                    options: {
                        mandatory: false,
                        ...options || {}
                    },
                    newField : true,
                }]);
            }

            setModelKeyDoChange(true);

        }
    }

    const handleDeleteField = (fieldToDelete) => {


        const temp = updateModel.filter(item => fieldToDelete !== item);
        setUpdateModel(temp);
    }

    const handleChangeFolderName = (event, value) => {
        setNameModel(event.target.value);
        setModelKeyDoChange(true);
    };

    const handleMandatoryField = (field, position) => {
        const temp = updateModel.map((item, index) => {

            if(field === item && position === index){

                if(item.isEncodable === false && !item.options.mandatory === true) {
                    item.options.mandatory = !item.options.mandatory;
                    item.isEncodable = true;
                }else if(item.isEncodable === true && !item.options.mandatory === false){
                    item.options.mandatory = !item.options.mandatory;

                }else{
                    item.options.mandatory = !item.options.mandatory;
                }

            }
            return item;
        } );
        setUpdateModel(temp);
    }

    const handleEditableField = (field, position) => {
        const temp = updateModel.map((item, index) => {

            if(field === item && position === index){
                if(!item.isEncodable === false && item.options.mandatory === true){
                    item.isEncodable = !item.isEncodable;
                    item.options.mandatory = false
                }else{
                    item.isEncodable = !item.isEncodable;
                }
            }
            return item;
        } );
        setUpdateModel(temp);
    }

    const handleVisibleField = (field, position) => {
        const temp = updateModel.map((item, index) => {

            if(field === item && position === index){
                item.isVisible = !item.isVisible;
            }
            return item;
        } );
        setUpdateModel(temp);
    }

    /**
     * Quand l'utilisateur veut éditer un champ
     * @param value
     * @param field
     * @param index
     * @private
     */
    const handleEditLabel = (value, field, index) => {
        let model = [...updateModel];
        model[index].label = value;
        setUpdateModel(model);
        setModelKeyDoChange(true);
    };

    const ErrorToastMessage = ({ title, message }) => (
        <div>
            <h6> {title}</h6>
            <p> {message} </p>
        </div>
    );

    const handleExitModel = () => {


        confirmAlert({
            title: t('global.plugnotes'),
            message: t('global.exit-saving-model-clef',{"name" : nameModel,"clef" : clefModel }),
            buttons: [
                {
                    label: t('global.ok'),
                    onClick: () => {
                        handleSaveModel();
                    }
                },
                {
                    label: t('global.cancel'),
                    onClick: () => {
                        if(props.back.length > 0){
                            props.history.push(props.back);
                        }else{
                            props.history.push("/models");
                        }

                        removeModelToUpdate();

                    }
                },
            ]
        });
    }

    const handleSaveModel = () => {

        // Si la liste des champs est vide on affiche une pop-up
        if(nameModel.length === 0){
            toast(<ErrorToastMessage title={t('global.error')} message={t('model-create-update.model-form-title-empty')} />)
        }
        else if (updateModel.length === 0) {
            toast(<ErrorToastMessage title={t('global.error')} message={t('model-create-update.model-form-fields-empty')} />)

        }
        // Si il manque un libellé sur un champ on affiche une pop-up
        else if (!isEmpty(updateModel.find((field) => isEmpty(field.label) && (field.format !== "delete-field")))) {
            toast(<ErrorToastMessage title={t('model-create-update.model-form-fields-no-name-title')} message={t('model-create-update.model-form-fields-no-name')} />)
        }
        else{

            const modelToUpdate = {...getModelToUpdate()}

            let newFieldsList = [];

            updateModel.forEach((item, index) => {
                newFieldsList.push(item);
            })

            newFieldsList.forEach((field, index) => {
                modelToUpdate["field" + (index + 1) + "label"] = field.label
                modelToUpdate["field" + (index + 1) + "format"] = field.format
                modelToUpdate["field" + (index + 1) + "mandatory"] = field.options.mandatory
                modelToUpdate["field" + (index + 1) + "encodable"] = field.isEncodable
                modelToUpdate["field" + (index + 1) + "visible"] = field.isVisible

                if (field.format === "select" || field.format === 'indice') {
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options;
                }else if(field.format === "table"){
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options;
                }else if(field.format === "plugform"){
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options;
                }else if(field.format === "pluglink"){
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options;
                }else if(field.format === "formula"){
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = field.options;
                } else {
                    modelToUpdate["field" + (index + 1) + "formatoptions"] = [];
                }
            });

            let updatedModel = {};
            Object.keys(modelToUpdate).map(fieldName => {
                if (!/field/.test(fieldName)) {
                    updatedModel[fieldName] = modelToUpdate[fieldName]
                }
            });

            newFieldsList.forEach((field, index) => {
                updatedModel["field" + (index + 1) + "label"] = modelToUpdate["field" + (index + 1) + "label"]
                updatedModel["field" + (index + 1) + "format"] = modelToUpdate["field" + (index + 1) + "format"]
                updatedModel["field" + (index + 1) + "mandatory"] = modelToUpdate["field" + (index + 1) + "mandatory"]
                updatedModel["field" + (index + 1) + "visible"] = modelToUpdate["field" + (index + 1) + "visible"]
                updatedModel["field" + (index + 1) + "encodable"] = modelToUpdate["field" + (index + 1) + "encodable"]

                if (field.format === "select" || field.format === 'indice') {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                    if(field.format === "select"  && updatedModel["field" + (index + 1) + "formatoptions"].presel.length > 0){
                        updatedModel["field" + (index + 1) + "formatoptions"].presel = updatedModel["field" + (index + 1) + "formatoptions"].presel.join(";");
                    }
                }else if (field.format === "table" ) {
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                    if(field.format === "table"  && updatedModel["field" + (index + 1) + "formatoptions"].presel.length > 0){
                        updatedModel["field" + (index + 1) + "formatoptions"].presel = updatedModel["field" + (index + 1) + "formatoptions"].presel.join(";");
                    }
                }else if(field.format === "plugform"){
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                }else if(field.format === "pluglink"){
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                }else if(field.format === "formula"){
                    updatedModel["field" + (index + 1) + "formatoptions"] = modelToUpdate["field" + (index + 1) + "formatoptions"];
                } else {
                    updatedModel["field" + (index + 1) + "formatoptions"] = [];
                }
            });

            updatedModel.label = nameModel;
            updatedModel.hash = modelToUpdate.hash;
            updatedModel.fieldEmptyVisible = fieldEmptyVisible;

            if(modelKeyCanChange && modelKeyDoChange && structureEditable){
                updatedModel.clef = "new";
            }

            props.attemptUpdateModel(updatedModel, environment.userhash, props.back);
        }
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const grid = 8;

    const getItemStyle = (isDragging, draggableStyle) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
        //padding: grid * 2,
        //margin: `0 0 ${grid}px 0`,

        // change background colour if dragging
        background: isDragging ? "#58C3C1" : "white",

        // styles we need to apply on draggables
        ...draggableStyle
    });

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            updateModel,
            result.source.index,
            result.destination.index
        );

        setUpdateModel(items);
    }

    const getListStyle = isDraggingOver => ({
        background: isDraggingOver ? "lightblue" : "white",
        //padding: grid,
        //width: 250
    });

    useEffect(() => {
        props.attemptGetFields()
    }, []);

    const renderFormatText = (field) => {
        let fieldFormat = field.format;
        // Vérifier les préselections
        let template = '';
        if (field.format === 'select') {

            if(field.options.multiselect && !field.options.editable){
                fieldFormat = "multi-select";
            }else if(!field.options.multiselect && !field.options.editable){
                fieldFormat = "single-select";

            }else if(field.options.multiselect && field.options.editable){
                fieldFormat = "free-multi-select";

            }else if(!field.options.multiselect && field.options.editable){
                fieldFormat = "free-single-select";
            }

        }

        if (field.format === 'table') {

            if(field.options.multiselect && !field.options.editable){
                fieldFormat = "multi-select-table";
            }else if(!field.options.multiselect && !field.options.editable){
                fieldFormat = "single-select-table";

            }else if(field.options.multiselect && field.options.editable){
                fieldFormat = "free-multi-select-table";

            }else if(!field.options.multiselect && field.options.editable){
                fieldFormat = "free-single-select-table";
            }

        }

        switch (fieldFormat) {
            case fieldTypes.QRCODE:
                template = <span>{formatTypes.QRCODE}</span>; break;
            case fieldTypes.BIRTHDAY:
                template = <span>{formatTypes.BIRTHDAY}</span>; break;
            case fieldTypes.TEXT:
                template = <span>{formatTypes.TEXT}</span>; break;
            case fieldTypes.TEXTAREA:
                template = <span>{formatTypes.TEXTAREA}</span>; break;
            case fieldTypes.PHONE:
                template = <span>{formatTypes.PHONE}</span>; break;
            case fieldTypes.MAIL:
                template = <span>{formatTypes.MAIL}</span>; break;
            case fieldTypes.BOOLEAN:
                template = <span>{formatTypes.BOOLEAN}</span>; break;
            case fieldTypes.URL:
                template = <span>{formatTypes.URL}</span>; break;
            case fieldTypes.LOCALIS:
                template = <span>{formatTypes.LOCALIS}</span>; break;
            case fieldTypes.NUMERIC:
                template = <span>{formatTypes.NUMERIC}</span>; break;
            case fieldTypes.FREE_MULTI_SELECT:
                template = <span>{formatTypes.FREE_MULTI_SELECT}</span>; break;
            case fieldTypes.MULTI_SELECT:
                template = <span>{formatTypes.MULTI_SELECT}</span>; break;
            case fieldTypes.SINGLE_SELECT:
                template = <span>{formatTypes.SINGLE_SELECT}</span>; break;
            case fieldTypes.FREE_SINGLE_SELECT:
                template = <span>{formatTypes.FREE_SINGLE_SELECT}</span>; break;
            case fieldTypes.FREE_MULTI_SELECT_TABLE:
                template = <span>{formatTypes.FREE_MULTI_SELECT_TABLE}</span>; break;
            case fieldTypes.MULTI_SELECT_TABLE:
                template = <span>{formatTypes.MULTI_SELECT_TABLE}</span>; break;
            case fieldTypes.SINGLE_SELECT_TABLE:
                template = <span>{formatTypes.SINGLE_SELECT_TABLE}</span>; break;
            case fieldTypes.FREE_SINGLE_SELECT_TABLE:
                template = <span>{formatTypes.FREE_SINGLE_SELECT_TABLE}</span>; break;
            case fieldTypes.LIST:
                template = <span>{formatTypes.LIST}</span>; break;
            case fieldTypes.NOW:
                template = <span>{formatTypes.NOW}</span>; break;
            case fieldTypes.AGENDA:
                template = <span>{formatTypes.AGENDA}</span>; break;
            case fieldTypes.DATE:
                template = <span>{formatTypes.DATE}</span>; break;
        }

        return template;
    }

    // process CSV data
    const processData = (dataString, field, index) => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const list = [];
        for (let i = 0; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            for (let j = 0; j < 1; j++) {
                let d = row[j];
                if (d.length > 0) {
                    if (d[0] == '"')
                        d = d.substring(1, d.length - 1);
                    if (d[d.length - 1] == '"')
                        d = d.substring(d.length - 2, 1);
                }

                if(d.length > 0 && !field.options.presel.includes(d)){
                    list.push(d);
                }
            }
        }

        if(list.length > 0){
            //field.options.presel = field.options.presel.concat(list);
            let model = [...updateModel];
            model[index].options.presel = model[index].options.presel.concat(list);
            setUpdateModel(model);
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.traitement-finish'),
                buttons: [
                    {
                        label: t('global.yes'),
                    }
                ]
            });
        }else{
            confirmAlert({
                title: t('global.plugnotes'),
                message: t('global.traitement-no-data'),
                buttons: [
                    {
                        label: t('global.yes'),
                    }
                ]
            });
        }
    }

    const onChangeHandlerDrop = (acceptedFiles, field, index) => {

        var files = acceptedFiles, f = files[0];
        var reader = new FileReader();
        reader.onload = function(e) {
            var bstr = new Uint8Array(e.target.result);
            var workbook = XLSX.read(bstr, {type: 'array'});
            /* DO SOMETHING WITH workbook HERE */
            /* Get first worksheet */
            const wsname = workbook.SheetNames[0];
            const ws = workbook.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(data, field, index);
        };
        reader.readAsArrayBuffer(f);

    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Drawer
                variant="permanent"
                className={clsx(classes.paper, classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx(classes.paper,classes.paperAnchorDockedLeft,{
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.toolbar}/>
{/*                <List>
                    <ListItem button>
                        <ListItemIcon className="green"><Person/></ListItemIcon>
                        <ListItemText primary={environment.name} />

                    </ListItem>
                </List>
                <Divider />
                <List>
                    {props.fields.map((field, index) => (
                        (field.format !== "timeplus" && field.format !== "indice") &&
                        <ListItem button key={index}
                                  onClick={(event) => handleListItemClick(event, field)}
                        >
                            <ListItemText primary={t('fields.format-choice-'+field.format.toLowerCase())} />
                            <div className={classes.fieldIcon}>
                                <img
                                    className={classes.fieldImage}
                                    src ={Images[camelCase(field.format.toLowerCase())+"Icon"]}
                                    alt={t('fields.format-choice-'+field.format.toLowerCase())}
                                />
                            </div>
                        </ListItem>

                    ))}
                </List>
                <Divider />
                <Divider />*/}

                {
                    structureEditable &&
                    <div className="left-menu_contents_models">

                        <div className="text_align">
                        <span className="titres unbreakText">
                            {t('common:models.select-formats')}
                        </span>
                            <br/>
                            <span className="titres unbreakText ">
                            {t('common:models.select-formats-sub')}
                        </span>
                        </div>
                        <div className="left-menu_contents_wrapper">

                            <ul className="scrollbar-hidden left-menu_contents_actions liste-formats-update-v3">
                                {props.fields.map((field, index) => (
                                    (field.format !== "timeplus" && field.format !== "indice" && field !== null && !speciaFields.includes(field.format)) &&
                                    <Tooltip  key={index} title={field[`description${langue}`]} aria-label="add" placement="top-start">
                                        <li className="formats "  onClick={(event) => handleListItemClick(event, field)}>
                                            {
                                                ( field.format.toLowerCase() === 'now') ?
                                                    <div style={{display : 'flex', alignItems : 'baseline'}}>
                                                        <div className={classes.fieldIcon} style={{width : '20px'}}>
                                                            <img
                                                                className={classes.fieldImage}
                                                                src ={Images[camelCase(field.format.toLowerCase())+"Icon"]}
                                                                alt={t('fields.format-choice-'+field.format.toLowerCase())}
                                                            />
                                                        </div>
                                                        <div style={{marginLeft : '5px'}}>{t('fields.format-choice-'+field.format.toLowerCase())}</div>
                                                    </div>
                                                    :
                                                    <div style={{display : 'flex', alignItems : 'inherit'}}>
                                                        <div className={classes.fieldIconSvg} style={{width : '20px'}}>
                                                            <img
                                                                className={classes.fieldImageSvg}
                                                                src ={Images[camelCase(field.format.toLowerCase())+"IconSvg"]}
                                                                alt={t('fields.format-choice-'+field.format.toLowerCase())}
                                                            />
                                                        </div>
                                                        <div style={{marginLeft : '5px'}}>{t('fields.format-choice-'+field.format.toLowerCase())}</div>
                                                    </div>
                                            }
                                            <div className="grey flexSpacebetween padding_2">
                                            <span className="references line_height_22" >
                                            {
                                                (field.position === 11) && <span className="references line_height_22">F11-14</span>
                                            }

                                                {
                                                    (field.position === 23) && <span className="references line_height_22">F23-F26</span>
                                                }

                                                {
                                                    (field.position !== 11 && field.position !== 23) && <span className="references line_height_22">F{field.position <=9 && <span className="references line_height_22">0</span>}{field.position}</span>
                                                }
                                            </span>
                                            </div>
                                        </li>
                                    </Tooltip>
                                ))}
                                <li className="inheritClass"><div className="line flex_style"/></li>
                                {props.fields.map((field, index) => (
                                    (field.format !== "timeplus" && field.format !== "indice" && field !== null && speciaFields.includes(field.format)) &&
                                    <Tooltip  key={index} title={field[`description${langue}`]} aria-label="add" placement="top-start">
                                        <li className="formats "  onClick={(event) => handleListItemClick(event, field)}>
                                            {
                                                ( field.format.toLowerCase() === 'now') ?
                                                    <div style={{display : 'flex', alignItems : 'baseline'}}>
                                                        <div className={classes.fieldIcon} style={{width : '20px'}}>
                                                            <img
                                                                className={classes.fieldImage}
                                                                src ={Images[camelCase(field.format.toLowerCase())+"Icon"]}
                                                                alt={t('fields.format-choice-'+field.format.toLowerCase())}
                                                            />
                                                        </div>
                                                        <div style={{marginLeft : '5px'}}>{t('fields.format-choice-'+field.format.toLowerCase())}</div>
                                                    </div>
                                                    :
                                                    <div style={{display : 'flex', alignItems : 'inherit'}}>
                                                        <div className={classes.fieldIconSvg} style={{width : '20px'}}>
                                                            <img
                                                                className={classes.fieldImageSvg}
                                                                src ={Images[camelCase(field.format.toLowerCase())+"IconSvg"]}
                                                                alt={t('fields.format-choice-'+field.format.toLowerCase())}
                                                            />
                                                        </div>
                                                        <div style={{marginLeft : '5px'}}>{t('fields.format-choice-'+field.format.toLowerCase())}</div>
                                                    </div>
                                            }
                                            <div className="grey flexSpacebetween padding_2">
                                            <span className="references line_height_22" >
                                            {
                                                (field.position === 11) && <span className="references line_height_22">F11-14</span>
                                            }

                                                {
                                                    (field.position === 23) && <span className="references line_height_22">F23-F26</span>
                                                }

                                                {
                                                    (field.position !== 11 && field.position !== 23) && <span className="references line_height_22">F{field.position <=9 && <span className="references line_height_22">0</span>}{field.position}</span>
                                                }
                                            </span>
                                            </div>
                                        </li>
                                    </Tooltip>
                                ))}
                            </ul>

                        </div>
                    </div>
                }

                <div className={classes.openClose}>
                    <IconButton className={classes.openCloseButton} onClick={open ? handleDrawerClose : handleDrawerOpen}>
                        {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </div>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar}/>
                <Header>
                    <div className="sous-header-without-height p1 grey">
                        <div className="text_align">
                            <h3 className="">{t('common:models.update-model')}</h3>
                        </div>
                    </div>
                    <div className="sous-header p1 m2 grey">

                        <div className="sous-header_content">
                            <div>
                                {/*<button className="button"  onClick={handleSaveModel}>{t('common:multiple.save')}</button>*/}
                                <span id="icon-close" className="material-icons md-30" onClick={handleExitModel}>
                                    keyboard_arrow_left
                                </span>
                            </div>
                            <div className="div_width_50">
                                <Input
                                    id="standard-full-width"
                                    label="Label"
                                    style={{ margin: 8 }}
                                    placeholder={t('common:models.form-title')}
                                    helpertext="Full width!"
                                    fullWidth={true}
                                    margin="none"
                                    inputlabelprops={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        className : classes.fieldRight
                                    }}
                                    onChange={handleChangeFolderName}
                                    value={nameModel}
                                />
                            </div>
                            <div>
                                <button className="button"  onClick={handleExitModel}>{t('common:multiple.save')}</button>
{/*                                <span id="icon-close" className="material-icons md-30" onClick={handleExitModel}>
                                    keyboard_arrow_left
                                </span>*/}
                            </div>
                        </div>

                        <div className="sous-header_content black">
                            <div className="line flex_style">
                            </div>
                        </div>

                        <div>
                            <span>{t('common:global.model-annexe')}</span>
                        </div>
                    </div>

                </Header>
                <Content className={`${classes.contentModel} scrollbar-hidden`}>

                    <div className="split-100 formulaireGauche p1">
                        <div id="liste-champs_remplir">
                            {t('common:plugnote.view-all-fields-empty')}
                            <input className="checkbox" type="checkbox" checked={fieldEmptyVisible}
                                   onChange={(event) => setFieldEmptyVisible(!fieldEmptyVisible)}/>
                        </div>

                    </div>

                    <div className="split-100 formulaireGauche p1">
                        <div id="liste-champs_remplir">
                            <div className="champs_remplir flexbox-field-item m2">
                                <div className="flexbox-field-item_textes flex_display_direction_row"  style={{width: '100%', margin: '10px'}}>
                                    <div style={{width: '5%', textAlign :'center'}}>
                                        <span>Icon</span>
                                    </div>
                                    <div style={{width: '5%', textAlign :'center'}}>
                                        <span>{t('common:helper.number')}</span>
                                    </div>
                                    <div style={{width: '20%', textAlign :'center'}}>
                                        <span>{t('common:helper.format')}</span>
                                    </div>
                                    <div style={{width: '50%', textAlign :'center'}}>
                                        <span>{t('common:helper.label')}</span>
                                    </div>
                                    <div style={{width: '5%', textAlign :'center'}}>
                                        <span>{t('common:helper.visible')}</span>
                                    </div>
                                    <div style={{width: '5%', textAlign :'center'}}>
                                        <span>{t('common:helper.editable')}</span>
                                    </div>
                                    <div style={{width: '10%', textAlign :'center'}}>
                                        <span>{t('common:helper.actions')}</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="splitContent flexSpacebetween p1">
                        <div className="split-100 formulaireGauche">
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            style={getListStyle(snapshot.isDraggingOver)}
                                        >
                                            {updateModel.map((field, index) => (
                                                <div key={index}>
                                                    { !!field && field.format !== null &&
                                                    <div key={index}>
                                                        { !!field && field.newField ?
                                                            <Draggable key={field.dragid} draggableId={field.dragid} index={index} >
                                                                {(provided, snapshot) => (
                                                                    <div
                                                                        ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps}
                                                                        style={getItemStyle(
                                                                            snapshot.isDragging,
                                                                            provided.draggableProps.style
                                                                        )}
                                                                    >
                                                                        <div  key={index + 1} id="liste-champs_remplir">
                                                                            <div className="champs_remplir flexbox-field-item m2 ">
                                                                                <div className="flexbox-field-item_textes flex_display_direction_row"  style={{width: '100%', margin: '10px'}}>
                                                                                    <div style={{width: '5%' , textAlign :'center'}}>
                                                                                        <span className="green">
                                                                            {
                                                                                ( field.format.toLowerCase() === 'now') ?
                                                                                    <div className={classes.fieldIcon}>
                                                                                        <img
                                                                                            className={classes.fieldImage}
                                                                                            src ={Images[camelCase(field.format.toLowerCase())+"Icon"]}
                                                                                            alt={t('fields.format-choice-'+field.format.toLowerCase())}
                                                                                        />
                                                                                    </div>
                                                                                    :
                                                                                    <div className={classes.fieldIconSvg}>
                                                                                        <img
                                                                                            className={classes.fieldImageSvg}
                                                                                            src ={Images[camelCase(field.format.toLowerCase())+"IconSvg"]}
                                                                                            alt={t('fields.format-choice-'+field.format.toLowerCase())}
                                                                                        />
                                                                                    </div>
                                                                            }
                                                                            </span>
                                                                                        {
                                                                                            (field.format.toLowerCase() === 'select' ||  field.format.toLowerCase() === 'table') &&
                                                                                            <Dropzone
                                                                                                // onDrop={acceptedFiles => console.log(acceptedFiles)}
                                                                                                onDrop={acceptedFiles => onChangeHandlerDrop(acceptedFiles, field, index)}
                                                                                            >
                                                                                                {({getRootProps, getInputProps}) => (
                                                                                                    <section>
                                                                                                        <div {...getRootProps()}>
                                                                                                            <input {...getInputProps()} />
                                                                                                            <div className="flexbox-item-drop draganddrop m3">
                                                                                                                <span className="titres-default-line-height grey margin-auto">
                                                                                                                    {t('multiple.attachement-drag-and-drop')}
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </section>
                                                                                                )}
                                                                                            </Dropzone>
                                                                                        }
                                                                                    </div>
                                                                                    <div style={{width: '5%' , textAlign :'center'}}>
                                                                                    <span className="references line_height_22" >
                                                                                         {
                                                                                             (field.position === 11) && <span className="references line_height_22">F11-14</span>
                                                                                         }

                                                                                        {
                                                                                            (field.position === 23) && <span className="references line_height_22">F23-F26</span>
                                                                                        }

                                                                                        {
                                                                                            (field.position !== 11 && field.position !== 23) && <span className="references line_height_22">F{field.position <=9 && <span className="references line_height_22">0</span>}{field.position}</span>
                                                                                        }
                                                                                    </span>
                                                                                    </div>
                                                                                    <div style={{width: '20%'}}>
                                                                                        <span>{t('fields.format-choice-'+field.format.toLowerCase())}</span>
                                                                                    </div>
                                                                                    <div className=" flexSpacebetween m3" style={{width: '50%'}}>
                                                                                        { field.format.toLowerCase() === 'select' &&
                                                                                        <FormatSelectConstructorComponent field={field} handleEditLabel={handleEditLabel} index={index} setUpdateModel={setUpdateModel} updateModel={updateModel} update={true}/>
                                                                                        }


                                                                                        { field.format.toLowerCase() === 'formula' &&
                                                                                            <FormatFormulaConstructorComponent field={field} handleEditLabel={handleEditLabel} index={index} setUpdateModel={setUpdateModel} updateModel={updateModel} update={true}/>
                                                                                        }

                                                                                        { field.format.toLowerCase() === 'table' &&
                                                                                        <FormatTableConstructorComponent field={field} handleEditLabel={handleEditLabel} index={index} setUpdateModel={setUpdateModel} updateModel={updateModel} update={true}/>
                                                                                        }

                                                                                        { field.format.toLowerCase() === 'plugform' &&
                                                                                        <FormatPlugformConstructorComponent field={field} handleEditLabel={handleEditLabel} index={index} setUpdateModel={setUpdateModel} updateModel={updateModel} update={true}/>
                                                                                        }

                                                                                        { field.format.toLowerCase() === 'pluglink' &&
                                                                                        <FormatPluglinkConstructorComponent field={field} handleEditLabel={handleEditLabel} index={index} setUpdateModel={setUpdateModel} updateModel={updateModel} update={true} folderName={nameModel}/>
                                                                                        }

                                                                                        { field.format.toLowerCase() !== 'formula' && field.format.toLowerCase() !== 'select' && field.format.toLowerCase() !== 'table' && field.format.toLowerCase() !== 'plugform' && field.format.toLowerCase() !== 'pluglink' &&
                                                                                        <FormatFieldConstructorComponent field={field} handleEditLabel={handleEditLabel} index={index} />
                                                                                        }

                                                                                    </div>
                                                                                    <div style={{width: '5%', justifyContent : 'center' , display : 'flex'}}>
                                                                                        <div className="grey flexSpacebetween">
                                                                                            {field.isVisible ?
                                                                                                <Tooltip
                                                                                                    title={t('common:helper.visible-2')}
                                                                                                    aria-label="add"
                                                                                                    placement="top-start">
                                                                                                        <span
                                                                                                            id="icon-more"
                                                                                                            className={`material-icons md-small ${field.isVisible ? classes.isTrue : classes.isFalse}`}
                                                                                                            onClick={handleVisibleField.bind(this, field, index)}>
                                                                                                        check_box
                                                                                                    </span>
                                                                                                </Tooltip>
                                                                                                :
                                                                                                <Tooltip
                                                                                                    title={t('common:helper.visible-2')}
                                                                                                    aria-label="add"
                                                                                                    placement="top-start">
                                                                                                        <span
                                                                                                            id="icon-more"
                                                                                                            className={`material-icons md-small ${field.isVisible ? classes.isTrue : classes.isFalse}`}
                                                                                                            onClick={handleVisibleField.bind(this, field, index)}>
                                                                                                        check_box_outline_blank
                                                                                                    </span>
                                                                                                </Tooltip>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{width: '5%', justifyContent : 'center' , display : 'flex'}}>
                                                                                        <div className="grey flexSpacebetween">
                                                                                            { field.isEncodable ?

                                                                                                <Tooltip  title={t('common:helper.editable-2')} aria-label="add" placement="top-start">
                                                                                                        <span id="icon-more" className={`material-icons md-small ${field.isEncodable ? classes.isTrue : classes.isFalse}`} onClick={handleEditableField.bind(this,field, index)}>
                                                                                                            check_box
                                                                                                        </span>
                                                                                                </Tooltip>

                                                                                                :

                                                                                                <Tooltip  title={t('common:helper.editable-2')} aria-label="add" placement="top-start">
                                                                                                        <span id="icon-more" className={`material-icons md-small ${field.isEncodable ? classes.isTrue : classes.isFalse}`} onClick={handleEditableField.bind(this,field, index)}>
                                                                                                            check_box_outline_blank
                                                                                                        </span>
                                                                                                </Tooltip>

                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                    <div style={{width: '10%', justifyContent : 'center', display : 'flex'}}>
                                                                                        <div className="grey flexSpacebetween">
                                                                                            <Tooltip  title={t('common:helper.move-field')} aria-label="add" placement="top-start">
                                                                                                <span id="icon-more"
                                                                                                      className="material-icons md-small">
                                                                                                    drag_handle
                                                                                                </span>
                                                                                            </Tooltip>
                                                                                            <Tooltip  title={t('common:helper.remove-field')} aria-label="add" placement="top-start">
                                                                                                <span id="icon-more" className="material-icons md-small" onClick={handleDeleteField.bind(this,field)}>
                                                                                                        clear
                                                                                                </span>
                                                                                            </Tooltip>
                                                                                            {structureEditable ?
                                                                                                <Tooltip  title={t('common:helper.mandatory-field')} aria-label="add" placement="top-start">
                                                                                                    <span id="icon-more" className={`material-icons md-small ${field.options.mandatory ? classes.mandatory : classes.notMandatory}`} onClick={handleMandatoryField.bind(this,field, index)}>panorama_fish_eye</span>
                                                                                                </Tooltip>
                                                                                                :
                                                                                                <Tooltip  title={t('common:helper.mandatory-field')} aria-label="add" placement="top-start">
                                                                                                    <span id="icon-more" className={`material-icons md-small ${field.options.mandatory ? classes.mandatory : classes.notMandatory}`} >panorama_fish_eye</span>
                                                                                                </Tooltip>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                            :
                                                            <div  key={index + 1} id="liste-champs_remplir">
                                                                <div className="champs_remplir flexbox-field-item m2 ">
                                                                    <div className="flexbox-field-item_textes flex_display_direction_row"  style={{width: '100%', margin: '10px'}}>
                                                                        <div style={{width: '5%' , textAlign :'center'}}>
                                                                            <span className="green">
                                                                            {
                                                                                ( field.format.toLowerCase() === 'now') ?
                                                                                    <div className={classes.fieldIcon}>
                                                                                        <img
                                                                                            className={classes.fieldImage}
                                                                                            src ={Images[camelCase(field.format.toLowerCase())+"Icon"]}
                                                                                            alt={t('fields.format-choice-'+field.format.toLowerCase())}
                                                                                        />
                                                                                    </div>
                                                                                    :
                                                                                    <div className={classes.fieldIconSvg}>
                                                                                        <img
                                                                                            className={classes.fieldImageSvg}
                                                                                            src ={Images[camelCase(field.format.toLowerCase())+"IconSvg"]}
                                                                                            alt={t('fields.format-choice-'+field.format.toLowerCase())}
                                                                                        />
                                                                                    </div>
                                                                            }
                                                                            </span>
                                                                            {
                                                                                (field.format.toLowerCase() === 'select' || field.format.toLowerCase() === 'table') &&
                                                                                <Dropzone
                                                                                    // onDrop={acceptedFiles => console.log(acceptedFiles)}
                                                                                    onDrop={acceptedFiles => onChangeHandlerDrop(acceptedFiles, field, index)}
                                                                                >
                                                                                    {({getRootProps, getInputProps}) => (
                                                                                        <section>
                                                                                            <div {...getRootProps()}>
                                                                                                <input {...getInputProps()} />
                                                                                                <div className="flexbox-item-drop draganddrop m3">
                                                                                                    <span className="titres-default-line-height grey margin-auto">
                                                                                                        {t('multiple.attachement-drag-and-drop')}
                                                                                                    </span>
                                                                                                </div>
                                                                                            </div>
                                                                                        </section>
                                                                                    )}
                                                                                </Dropzone>
                                                                            }
                                                                        </div>
                                                                        <div style={{width: '5%' , textAlign :'center'}}>
                                                                                    <span className="references line_height_22" >
                                                                                        {
                                                                                            field.position
                                                                                        }
                                                                                    </span>
                                                                        </div>
                                                                        <div style={{width: '20%' }}>
                                                                            <span>{t('fields.format-choice-'+field.format.toLowerCase())}</span>
                                                                        </div>
                                                                        <div className=" flexSpacebetween m3" style={{width: '50%'}}>
                                                                            { field.format.toLowerCase() === 'select' &&
                                                                            <FormatSelectConstructorComponent field={field} handleEditLabel={handleEditLabel} index={index} setUpdateModel={setUpdateModel} updateModel={updateModel} update={true}/>
                                                                            }

                                                                            { field.format.toLowerCase() === 'formula' &&
                                                                            <FormatFormulaConstructorComponent field={field} handleEditLabel={handleEditLabel} index={index} setUpdateModel={setUpdateModel} updateModel={updateModel} update={true}/>
                                                                            }

                                                                            { field.format.toLowerCase() === 'table' &&
                                                                            <FormatTableConstructorComponent field={field} handleEditLabel={handleEditLabel} index={index} setUpdateModel={setUpdateModel} updateModel={updateModel} update={true}/>
                                                                            }
                                                                            { field.format.toLowerCase() !== 'formula' && field.format.toLowerCase() !== 'select' && field.format.toLowerCase() !== 'table' && field.format.toLowerCase() !== 'plugform' && field.format.toLowerCase() !== 'pluglink' &&
                                                                            <FormatFieldConstructorComponent field={field} handleEditLabel={handleEditLabel} index={index}/>}
                                                                            { field.format.toLowerCase() === 'plugform' &&
                                                                            <FormatPlugformConstructorComponent field={field} handleEditLabel={handleEditLabel} index={index} setUpdateModel={setUpdateModel} updateModel={updateModel} update={true}/>
                                                                            }
                                                                            { field.format.toLowerCase() === 'pluglink' &&
                                                                            <FormatPluglinkConstructorComponent field={field} handleEditLabel={handleEditLabel} index={index} setUpdateModel={setUpdateModel} updateModel={updateModel} update={true}/>
                                                                            }
                                                                        </div>
                                                                        <div style={{width: '5%', justifyContent : 'center' , display : 'flex'}}>
                                                                            <div className="grey flexSpacebetween">
                                                                                {field.isVisible ?
                                                                                    <Tooltip  title={t('common:helper.visible-2')} aria-label="add" placement="top-start">
                                                                                            <span id="icon-more" className={`material-icons md-small ${field.isVisible ? classes.isTrue : classes.isFalse}`} onClick={handleVisibleField.bind(this,field, index)}>
                                                                                                        check_box
                                                                                                    </span>

                                                                                    </Tooltip>
                                                                                    :
                                                                                    <Tooltip  title={t('common:helper.visible-2')} aria-label="add" placement="top-start">
                                                                                            <span id="icon-more" className={`material-icons md-small ${field.isVisible ? classes.isTrue : classes.isFalse}`} onClick={handleVisibleField.bind(this,field, index)}>
                                                                                                        check_box_outline_blank
                                                                                                    </span>
                                                                                    </Tooltip>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div style={{width: '5%', justifyContent : 'center' , display : 'flex'}}>
                                                                            <div className="grey flexSpacebetween">
                                                                                { field.isEncodable ?

                                                                                    <Tooltip  title={t('common:helper.editable-2')} aria-label="add" placement="top-start">
                                                                                        <span id="icon-more" className={`material-icons md-small ${field.isEncodable ? classes.isTrue : classes.isFalse}`} onClick={handleEditableField.bind(this,field, index)}>
                                                                                                        check_box
                                                                                                    </span>
                                                                                </Tooltip>

                                                                                    :
                                                                                    <Tooltip  title={t('common:helper.editable-2')} aria-label="add" placement="top-start">

                                                                                        <span id="icon-more" className={`material-icons md-small ${field.isEncodable ? classes.isTrue : classes.isFalse}`} onClick={handleEditableField.bind(this,field, index)}>
                                                                                                        check_box_outline_blank
                                                                                                    </span>
                                                                                    </Tooltip>
                                                                                }

                                                                            </div>
                                                                        </div>
                                                                        <div style={{width: '10%', justifyContent : 'center' , display : 'flex'}}>
                                                                            <div className="grey flexSpacebetween">
                                                                                <Tooltip  title={t('common:helper.move-field')} aria-label="add" placement="top-start">
                                                                                    <span id="icon-more" className="material-icons md-small invisible">
                                                                                            drag_handle
                                                                                    </span>
                                                                                </Tooltip>
                                                                                <Tooltip  title={t('common:helper.remove-field')} aria-label="add" placement="top-start">
                                                                                    <span id="icon-more" className="material-icons md-small invisible" onClick={handleDeleteField.bind(this,field)}>
                                                                                        clear
                                                                                    </span>
                                                                                </Tooltip>
                                                                                {structureEditable ?
                                                                                    <Tooltip  title={t('common:helper.mandatory-field')} aria-label="add" placement="top-start">
                                                                                        <span id="icon-more" className={`material-icons md-small ${field.options.mandatory ? classes.mandatory : classes.notMandatory}`} onClick={handleMandatoryField.bind(this,field, index)}>panorama_fish_eye</span>
                                                                                    </Tooltip>
                                                                                    :
                                                                                    <Tooltip  title={t('common:helper.mandatory-field')} aria-label="add" placement="top-start">
                                                                                        <span id="icon-more" className={`material-icons md-small ${field.options.mandatory ? classes.mandatory : classes.notMandatory}`} >panorama_fish_eye</span>
                                                                                    </Tooltip>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    }

                                                </div>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                            <div className="flexbox-container-hidden"/>

                        </div>
                        {/*                        <div className="lineMiddle flex_style"/>
                        <div className="split formulaireDroite">
                            { updateModel.length > 0 && updateModel.map((field, index) => {
                                if(field.format !== null){
                                    return (
                                        <div className="champs_apercu flexbox-plug-mod-item m2" key={index}>
                                            <div className="flexbox-plug-mod-item-text">

                                                <div className="infos-supplementaires grey">

                                                    <div className="flex_display_direction_row">
                                                        {
                                                            ( field.format.toLowerCase() === 'now') ?
                                                                <div className={classes.fieldIcon}>
                                                                    <img
                                                                        className={classes.fieldImage}
                                                                        src ={Images[camelCase(field.format.toLowerCase())+"Icon"]}
                                                                        alt={t('fields.format-choice-'+field.format.toLowerCase())}
                                                                    />
                                                                </div>
                                                                :
                                                                <div className={classes.fieldIconSvg}>
                                                                    <img
                                                                        className={classes.fieldImageSvg}
                                                                        src ={Images[camelCase(field.format.toLowerCase())+"IconSvg"]}
                                                                        alt={t('fields.format-choice-'+field.format.toLowerCase())}
                                                                    />
                                                                </div>
                                                        }
                                                        <span id="icon-qrcode" className="material-icons md-small">
                                                            alternate_email
                                                        </span>
                                                        <div className="label-margin-left">
                                                        <span className="references">
                                                        {t('fields.format-choice-'+field.format.toLowerCase())}
                                                    </span>
                                                        </div>
                                                    </div>
                                                    <div>
                                                    <span className="references">
                                                        n°{field.id} {renderFormatText(field)}
                                                    </span>
                                                    </div>

                                                </div>

                                                <div className="sous-header_content height_12">
                                                    <div className="line  separateur-dossier lightgrey flex_style">
                                                        {
                                                            (field.format.toLowerCase() === 'select' ||  field.format.toLowerCase() === 'table') &&
                                                            <Dropzone
                                                                // onDrop={acceptedFiles => console.log(acceptedFiles)}
                                                                onDrop={acceptedFiles => onChangeHandlerDrop(acceptedFiles, field, index)}
                                                            >
                                                                {({getRootProps, getInputProps}) => (
                                                                    <section>
                                                                        <div {...getRootProps()}>
                                                                            <input {...getInputProps()} />
                                                                            <div className="flexbox-item draganddrop m3">
                                                                            <span className="titres-default-line-height grey margin-auto">
                                                                                {t('multiple.attachement-drag-and-drop')}
                                                                            </span>
                                                                            </div>
                                                                        </div>
                                                                    </section>
                                                                )}
                                                            </Dropzone>
                                                        }
                                                    </div>
                                                </div>

                                                <span className="black p2 indent">
                                    <i>{field.label}</i>
                                </span>

                                            </div>
                                        </div>
                                    )
                                }
                            })
                            }
                        </div>*/}
                    </div>
                </Content>
            </main>
        </div>
    );
}

// redux providing state takeover
const mapStateToProps = (state, props) => {

    let back = "";

    if(typeof props.location.state !== "undefined"){
        if(typeof props.location.state.back !== "undefined"){
            back = props.location.state.back;
        }
    }

    return {
        back,
        user : state.user,
        token : state.user.token,
        fields : sortBy(state.field.list, 'position').filter((field) => field.active)
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        attemptGetFields: () => dispatch(getFieldsRequest()),
        attemptCreateModel: (model,userhash) => dispatch(postModelRequest(model,userhash)),
        attemptUpdateModel: (model,userhash,back) => dispatch(putModelRequest(model,userhash,back))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelUpdateV1)
