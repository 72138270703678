import { Checkbox, FormLabel, Input, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import colors from "../../assets/themes/Colors";
import Dropzone from "react-dropzone";
import { devLog, getSortedPresels, handlePreventDefault, isDefined } from "../../utils/utils";
import { PlugTooltip } from "./CustomComponents";
import { RenderEditDefaultValueEditabilityComponent } from "../constructors/shared/RenderEditDefaultValueComponent";
import { EditValueIcon } from "./icons/CustomIcons";
import { Dropdown } from 'rsuite'

const useStyles = makeStyles((theme) => ({
    sideMenu: {
        position: 'fixed',
        left: 0,
        top: 0,
        paddingLeft: 10,
        paddingTop: 10,
        height: '100vh',
        width: 370,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        zIndex: 2000,
        backgroundColor: colors.white,
        boxShadow: '0px 24px 38px rgba(0, 0, 0, 0.14), 0px 11px 15px rgba(0, 0, 0, 0.2)',
    },
    sideHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        '& .MuiSvgIcon-root': {
            color: `${colors.main}!important`
        }
    },
    margin: {
        marginTop: '15px',
        marginBottom: '5px',
    },
    subtitle: {
        fontWeight: 'bold'
    },
    titleCtn: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 16,
    },
    bodyContainer: {
        marginTop: '15px',
        width: '100%',
        // maxHeight: '60vh',
        // overflowY: 'auto',
    },
    inputType: {
        marginTop: '15px',
        marginLeft: '10px',
        marginRight: '10px',
        width: '100%',
    },
    visibleBox: {
        height: '40px',
        margin: '5px 0 15px',
        justifyContent: 'space-between',
        textAlign: 'start',
        alignItems: 'flex-start',
        display: 'flex',
        cursor: 'pointer',
        '& .MuiIconButton-label': {
            color: colors.mainButton
        },
    },
    mainContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'flex-start',
        margin: '5px 0px 5px 5px',
        gap: 5,
        overflowY: 'auto',
    },
    selectsContainer: {
        gap: 5,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
    },
    selectItem: {
        color: colors.white,
        backgroundColor: colors.unselected,
        padding: 4,
        margin: 2,
        borderRadius: 4,
        cursor: 'pointer',
        fontSize: 13,
        minHeight: 27,
    },
    selectedItem: {
        backgroundColor: colors.main,
    },
    dropzoneContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        //marginLeft: -15,
        marginRight: '10px',
        //position: 'absolute',
        position: 'sticky',
        bottom: 0
    },
    dropZone: {
        alignSelf: "center",
        cursor: 'pointer'
    },
    defaultValueCtn: {
        display: 'flex',
        flexDirection: 'column',
    },
    inputCtn: {
        paddingLeft: 8,
        '& .MuiInput-underline:after .MuiInput-underline:before': {
            borderBottom: '2px solid white',
        },
        '& .MuiInput-underline:before': { borderBottomColor: 'white' },
        '& .MuiInput-underline:after': { borderBottomColor: 'white' },
        '& .MuiInput-underline:hover:not(.Mui-disabled):before': { borderBottomColor: 'white' },
    },
    underline: {
        borderBottom: '1px solid white',
        '& .MuiInput-underline:after .MuiInput-underline': {
            borderBottom: '2px solid white !important',
        },
    },
    radioLabel: {
        width: 240,
        display: 'flex',
        textWrap: 'balance',
        flexDirection: 'column',
        alignItems: 'stretch',
        color:colors.unselected,
    },
}))

const sortingModes = ['alphanumeric', 'chronological', 'reverse']
export function SetupSelects(props) {
    // devLog("SetupSelect ", props)
    const classes = useStyles();
    const { t } = useTranslation('common')
    const field = props.field
    const order = props.isTablemulti ? (field.sortOrder || 'alphanumeric') : (field.options?.setup?.sortOrder || 'alphanumeric')
    const defaultValue = field.options?.setup?.defaultValue || []
    const index = props.index
    const newData = props.newData
    const newDataSelect = props.newDataSelect
    const visibleByDefault = props.visibleByDefault
    const [selected, setSelected] = useState(null)

    let formatLabel = ''
    switch (props.format) {
        case 'F11':
        case 'T01':
            formatLabel = t("common:formats.preselection-type-unique")
            break;
        case 'F12':
        case 'T02':
            formatLabel = t("common:formats.preselection-type-unique-and-free")
            break;
        case 'F13':
        case 'T03':
            formatLabel = t("common:formats.preselection-type-multiple")
            break;
        case 'F14':
        case 'T04':
            formatLabel = t("common:formats.preselection-type-multiple-and-free")
            break;
        case 'F23':
            formatLabel = t("common:formats.preselection-table-type-unique")
            break;
        case 'F24':
            formatLabel = t("common:formats.preselection-table-type-unique-and-free")
            break;
        case 'F25':
            formatLabel = t("common:formats.preselection-table-type-multiple")
            break;
        case 'F26':
            formatLabel = t("common:formats.preselection-table-type-multiple-and-free")
            break;

        default:
            break;
    }

    const handleChangeDefaultValue = (e, presel, text) => {
        // devLog('handle change default', presel)
        handlePreventDefault(e)
        if (!props.isTablemulti) { props.handleChangeDefaultValue(presel, text) }
    }

    const handleDelPresel = (e, presel) => {
        // devLog('handle delete', presel)
        handlePreventDefault(e)
        props.handleDeletePresel(presel)
    }

    const handleChangeSelected = (e, presel) => {
        handlePreventDefault(e)
        setSelected(current => current === presel ? null : presel)
    }

    return (
        <div className={classes.sideMenu}>
            <div className={classes.sideHeader}>
                <InputLabel className={`${classes.margin} ${classes.subtitle}`} htmlFor={"body-"}>{formatLabel}</InputLabel>

                <span id="icon-close" className="material-icons md-50 lightgrey" onClick={() => { props.handleClose() }}>
                    close
                </span>
            </div>
            <div className={classes.mainContainer}>
                <div className={classes.inputType}>
                    <InputLabel className={classes.subtitle}>{t('common:ext-table.for-field')} {isDefined(props.index) ? (props.index + 1) : props.field.id}: {props.field.label}</InputLabel>
                    <InputLabel className={classes.margin}>{t('common:selects.instructions')}</InputLabel>
                    <div className="flex_display_direction_row div_width_100">
                        <Input
                            autoComplete="new-password"
                            id={'new-select-input-' + props.field.id}
                            label="Label"
                            style={{ width: '100%' }}
                            fullWidth={true}
                            margin="none"
                            inputlabelprops={{ shrink: true }}
                            value={newData}
                            className="text-line"
                            onChange={(event) => props.setNewData(event.target.value)}
                            onKeyPress={(e) => { if (e.key === 'Enter') { props.handleAddPresel(newData, props.field, props.index) } }}
                        />
                        <span id="icon-add-playlist" className="material-icons md-small" onClick={() => props.handleAddPresel(newData, props.field, props.index)}>
                            playlist_add
                        </span>
                    </div>
                </div>

                <div className={classes.bodyContainer}>
                    {
                        props.handleChangeVisibility !== null &&
                        <div className={classes.visibleBox} onClick={() => { props.handleChangeVisibility(!visibleByDefault, props.field, props.index) }}>
                            <InputLabel className={classes.margin}>{t('plugnote.make-visible-default')}</InputLabel>
                            <Checkbox
                                style={{ color: colors.main, width: '30px', marginRight: '5px' }}
                                checked={visibleByDefault}
                                onChange={() => { props.handleChangeVisibility(!visibleByDefault, props.field, props.index) }}
                            />
                        </div>
                    }
                    <InputLabel className={`${classes.margin} ${classes.subtitle}`} htmlFor={"body-"}>{t('modes.mode-select')}</InputLabel>
                    <Dropdown
                        title={<span className={classes.radioLabel} style={{ fontSize: 14, textAlign: 'initial', width: 300 }}>{t('selects.order-' + order)}</span>}
                        value={order}
                    >
                        {
                            sortingModes.map((mode, index) => {
                                return (
                                    <Dropdown.Item key={index} onClick={() => props.handleChangeSortOrder(mode)}>
                                        <span className={classes.radioLabel}>
                                            {t('selects.order-' + mode)}
                                        </span>
                                    </Dropdown.Item>
                                )
                            })
                        }
                    </Dropdown>
                    <InputLabel className={`${classes.margin} ${classes.subtitle}`} htmlFor={"body-"}>{t('common:selects.select-list')}</InputLabel>

                    {
                        !props.isTablemulti &&
                        <FormLabel >{t('selects.select-default')}</FormLabel>
                    }
                    <div className={classes.selectsContainer}>
                        {
                            newDataSelect.length > 0 &&
                            getSortedPresels(newDataSelect, order)
                                .map((presel, index) => (
                                    props.field.format.includes('table')
                                        ? <SelectTableItem
                                            classes={classes}
                                            defaultValue={defaultValue}
                                            index={index}
                                            isSelected={selected === presel}
                                            handleChangeDefaultValue={handleChangeDefaultValue}
                                            handleChangeSelected={handleChangeSelected}
                                            handleDelPresel={handleDelPresel}
                                            key={`select-table-item-${index}`}
                                            presel={presel}
                                        />
                                        : <SelectItem
                                            classes={classes}
                                            defaultValue={defaultValue}
                                            index={index}
                                            handleChangeDefaultValue={handleChangeDefaultValue}
                                            handleChangeSelected={handleChangeSelected}
                                            handleDelPresel={handleDelPresel}
                                            key={`select-item-${index}`}
                                            presel={presel}
                                        />
                                ))
                        }
                    </div>
                    {
                        defaultValue.length > 0 &&
                        <div className={classes.defaultValueCtn} >
                            <div className={classes.titleCtn} >
                                <EditValueIcon />
                                <FormLabel id="mode-select-group-label">Default Value Options</FormLabel>
                            </div>
                            <RenderEditDefaultValueEditabilityComponent
                                handleToggleEditable={props.handleToggleEditable}
                                handleToggleEditablePf={props.handleToggleEditablePf}
                                handleToggleEditablePw={props.handleToggleEditablePw}
                                setup={props.field.options.setup}
                            />
                        </div>
                    }
                </div>

                <div className={classes.dropzoneContainer}>
                    <Dropzone
                        // onDrop={acceptedFiles => console.log('dropped ',acceptedFiles)}
                        onDrop={acceptedFiles => props.onChangeHandlerDrop(acceptedFiles, field, index)}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <section className={classes.dropZone}>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    <div className="flexbox-item-drop draganddrop m3">
                                        <span className="titres-default-line-height grey margin-auto">
                                            {t('selects.import-table')}
                                        </span>
                                    </div>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                </div>
            </div>
        </div>
    )
}

const SelectItem = ({ classes, defaultValue, presel, index, handleChangeDefaultValue, handleDelPresel }) => {
    const { t } = useTranslation('common')
    return (
        <div className={`${classes.selectItem} ${defaultValue.includes(presel) ? classes.selectedItem : undefined}`} key={index} onClick={e => handleChangeDefaultValue(e, presel)}>
            <span key={`select-item-${index}`}>{presel}</span>
            <PlugTooltip title={t('selects.delete-item')} placement="top-end">
                <span id="icon-delete-playlist" className="material-icons md-small" onClick={(e) => handleDelPresel(e, presel, index)}>
                    delete
                </span>
            </PlugTooltip>
        </div>
    )
}

const SelectTableItem = ({ classes, defaultValue, presel, isSelected, index, handleChangeDefaultValue, handleDelPresel, handleChangeSelected}) => {

    const { t } = useTranslation('common')
    return (
        <div className={`${classes.selectItem} ${defaultValue.some(entry => entry.text === presel) ? classes.selectedItem : undefined}`} key={`select-table-item-${index}`} onClick={e => handleChangeSelected(e, presel)}>
            <span >{presel}</span>
            <span className={classes.inputCtn} onClick={handlePreventDefault}>
                {
                    (isSelected || isDefined(defaultValue.find(entry => entry.text === presel)?.value)) &&
                    <Input
                        autoFocus={isSelected}
                        defaultValue={defaultValue.find(entry => entry.text === presel)?.value || ''}
                        onChange={e => handleChangeDefaultValue(e, presel, e.target.value)}
                        endAdornment={
                            isDefined(defaultValue.find(entry => entry.text === presel)?.value) &&
                            <PlugTooltip title={t('selects.delete-default')} placement="top-end">
                                <span onClick={e => {handleChangeDefaultValue(e, presel); handleChangeSelected(e, null)}}>
                                    <span className="material-icons" >
                                        highlight_off
                                    </span>
                                </span>
                            </PlugTooltip>
                        }
                    />
                }
            </span>
            <PlugTooltip title={t('selects.delete-item')} placement="top-end">
                <span id="icon-delete-playlist" className="material-icons md-small" onClick={(e) => handleDelPresel(e, presel)}>
                    delete
                </span>
            </PlugTooltip>
        </div>
    )
}
