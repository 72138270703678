import React, {Fragment, useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import {connect} from "react-redux";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import {MuiPickersUtilsProvider, KeyboardTimePicker} from "@material-ui/pickers";
import { Tooltip } from "@material-ui/core";
import colors from "../../assets/themes/Colors";
import {devLog, isDefined, addZeroBefore} from "../../utils/utils";
import {isNull, isUndefined} from "lodash";
import { TextField } from "@material-ui/core";
import InputMask from "react-input-mask";
import { roundQuarter, isInvalidTime, getPickerMaskDetails, getDateTimeFromValue, getCurrentDateTimeByMask, getTimeStringFromDateTime } from "../../utils/Fields";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar : {
        backgroundColor : 'white',
        borderBottom : '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color : 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo:{
        width: '10%',
    },
    timeContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        '& .MuiFormControl-root': {
            flexDirection: 'row !important',
            marginRight: '2px'
        },
        '& .MuiFormHelperText-root': {
            paddingRight: '1em',
            color: colors.red
        }
    },
    timePicker: {
        '& .MuiInputBase-root': {
            "& input": {
                display:'none',
            },
            /*"& .MuiInputAdornment-root": {
                //display: 'unset',
            }*/
        },
    },
    timePickerIcon: {
        "& .MuiIconButton-root": {
            padding: 0
        }
    },
    timeDelButton: {
        height:'22px',
        width: '12px',
        color: colors.main,
        alignItems: 'center',
        marginRight: '3px'
    },
    timeNowButton: {
        height:'21px',
        width: '21px',
        color: colors.main,
        alignItems: 'center',
        border: '2.5px solid',
        borderColor: colors.main,
        borderRadius: '22px',
        fontSize: "18px",
        fontWeight: '900',
    },
    endButton: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'center',
        alignSelf: 'center'
    },
}));


const FormatTimeComponent = (props) => {
    /*if(props.field?.options?.setup?.hasOwnProperty('mask')) {
        //console.log("props")
    }*/

    //"2018-01-01T11:12:00.000Z"
    const classes = useStyles()
    // devLog('FormatTimeComp props',props)
    let currentFieldMask = props.field.options?.setup?.hasOwnProperty('mask') ? props.field.options?.setup?.mask : 'HH:MM (24H)'
    let stringValue
    let dateTimeValue
    let timeIsInvalid = false
    if(props.field.value !== null && props.field.value?.length > 0) {
        stringValue = props.field.value
        if (!stringValue.includes('_')) timeIsInvalid = isInvalidTime(currentFieldMask, stringValue)
        if(!timeIsInvalid) {
            if (currentFieldMask !== "Free Text") {
                dateTimeValue = getDateTimeFromValue(stringValue, currentFieldMask)
            }
        } else {dateTimeValue = null}
    }
    const art = /.*/
    const [inputMaskValue, setInputMaskValue] = useState(stringValue || "")
    const [selectedDateTime, setSelectedDateTime] = useState(dateTimeValue)
    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false: props.disabled)

    let maskFormat = getPickerMaskDetails(currentFieldMask).maskFormat
    let maskFormatOption = getPickerMaskDetails(currentFieldMask).maskFormatOption
    let inputMask
    let maskProp
    let pickerViews = []
    let minutesStepOption
    let placeholder
    let helperText

    switch (maskFormat) {
        case undefined:
            maskFormat='HH:mm'
            break
        case 'HH:MM':
            inputMask = '99:99'
            pickerViews = ['hours', 'minutes']
            maskFormat='HH:mm'
            maskProp="__:__"
            placeholder="HH:MM"
            helperText='Invalid time'
            break
        case 'HH:MM:SS':
            inputMask = "99:99:99"
            pickerViews = ['hours', 'minutes', 'seconds']
            maskFormat='HH:mm:ss'
            maskProp="__:__:__"
            placeholder="HH:MM:SS"
            helperText='Invalid time'
            break
        case 'MM:SS':
            inputMask = "99:99"
            pickerViews = ['minutes', 'seconds']
            maskFormat='mm:ss'
            maskProp="__:__"
            placeholder="MM:SS"
            helperText='Invalid time'
            break
        case 'HH H MM':
            inputMask = "99 H 99"
            pickerViews = ['hours', 'minutes']
            maskFormat='HH H MM'
            maskProp="__H__"
            placeholder="HH H HH"
            helperText='Invalid time'
            break
        case 'Free Text':
            //inputMask = '99:99'
            pickerViews = ['hours', 'minutes']
            maskFormat='HH:mm'
            //maskProp="__:__"
            placeholder="Free Text"
            break
        default:
            break
    }

    switch (maskFormatOption) {
        case undefined:
            break
        case '24H':
            placeholder="HH:MM (24H)"
            break
        case '60min':
            placeholder="MM:SS (60MIN)"
            maskProp="__:__"
            break
        case '15min':
            minutesStepOption = 15
            placeholder="HH:MM (15MIN)"
            break
        default:
            break
    }

    let timeString

    const handleChangeInputMaskValue = (value) => {
        // CASES HH:MM (24H), MM:SS (60min)
        if(!value?.includes('_') && currentFieldMask !== "HH:MM (15min)" && currentFieldMask !== "HH H MM" && value?.length === 5) {
            timeIsInvalid = isInvalidTime(currentFieldMask, value)
            let newDateTime = getDateTimeFromValue(value, currentFieldMask)
            props.handleEditDateTime(newDateTime, value, props.field, props.index)
            if(!timeIsInvalid) setSelectedDateTime(newDateTime) //timepicker clock state
        }
        // CASE HH:MM (15min)
        if(!value?.includes('_') && currentFieldMask === "HH:MM (15min)" && value?.length === 5) {
            if(!isUndefined(minutesStepOption) && value !== "") {
                let timeStringRounded = roundQuarter(value)
                timeString = timeStringRounded.roundedHours + ":" + timeStringRounded.roundedMinutes
                timeIsInvalid = isInvalidTime(currentFieldMask, timeString)
                let newDateTime = new Date(null, null, null, timeStringRounded.roundedHours, timeStringRounded.roundedMinutes)
                props.handleEditDateTime(newDateTime, timeString, props.field, props.index)
                if(!timeIsInvalid) setSelectedDateTime(newDateTime) //timepicker clock state
            }
        }
        // CASE HH:MM:SS
        if(!value?.includes('_') && currentFieldMask === "HH:MM:SS" && value?.length === 8) {
            timeIsInvalid = isInvalidTime(currentFieldMask, value)
            let newDateTime = getDateTimeFromValue(value, currentFieldMask)
            props.handleEditDateTime(newDateTime, value, props.field, props.index)
            if(!timeIsInvalid) setSelectedDateTime(newDateTime) //timepicker clock state
        }
        // CASE HH H MM
        if(!value?.includes('_') && currentFieldMask === "HH H MM" && value?.length === 7) {
            timeIsInvalid = isInvalidTime(currentFieldMask, value)
            let newDateTime = getDateTimeFromValue(value, currentFieldMask)
            props.handleEditDateTime(newDateTime, value, props.field, props.index)
            if(!timeIsInvalid) setSelectedDateTime(newDateTime) //timepicker clock state
        }
        // CASE HH:MM (15min) value includes "_"
        if(value?.includes('_') && currentFieldMask === "HH:MM (15min)" && value?.length === 5) {
            timeString = value
            setInputMaskValue(timeString) //InputMask state (not rounded)
        }
        if(!value?.includes('_') && currentFieldMask === "HH:MM (15min)" && value?.length === 5) {
            setInputMaskValue(timeString) //InputMask state (quarter rounded)
        } else {setInputMaskValue(value)} //InputMask state (other cases)
    }

    function handleTimeChange(date, value) {
        if(isNull(value)) value = ""

        if (isNull(date) && value === "") {
            props.handleEditDateTime(date, '', props.field, props.index)
            setInputMaskValue(value)
            if(currentFieldMask!=="Free Text") setSelectedDateTime(null)
        }

        if (!isNull(date) && !disabled) {
            switch (currentFieldMask) {
                case "HH:MM (24H)":
                case 'HH:MM:SS':
                case 'MM:SS (60min)':
                case "HH:MM (15min)":
                case "HH H MM":
                    handleChangeInputMaskValue(value)
                    break
                case "Free Text":
                    props.handleEditDateTime(date, value, props.field, props.index)
                    setInputMaskValue(value)
                    break
                default:
                    break
            }
        }
    }

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled)
    },[ props.disabled])

    return (
        <div className={classes.timeContainer}>
            <Fragment>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                        id={maskProp === "__H__" ? "time-picker-custom-input-mask" : "time-picker"}
                        className={`${classes.timePickerIcon + ` ` + classes.timePicker}`}
                        ampm={false}
                        autoComplete="off"
                        disabled={disabled}
                        format={maskFormat === 'HH H MM' ? 'HH:mm' : maskFormat} //format="HH:mm"
                        helperText={timeIsInvalid && !timeString?.includes('_') ? helperText : ''}
                        InputAdornmentProps={{ position: 'start' }}
                        InputProps={{ disableUnderline: true }}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                        }}
                        keyboardIcon={<Icon>access_time</Icon>}
                        mask={maskProp}
                        minutesStep={isDefined(minutesStepOption) ? minutesStepOption : undefined}
                        onChange={(date, value) => {
                            handleTimeChange(date, value)
                            setSelectedDateTime(date)
                        }}
                        placeholder={placeholder}
                        value={selectedDateTime || null} // https://github.com/mui/material-ui-pickers/issues/1544
                        views={pickerViews}
                    />
                </MuiPickersUtilsProvider>
                {currentFieldMask !== "Free Text" &&
                    <InputMask
                        disabled={disabled}
                        mask={inputMask}
                        value={inputMaskValue || ""}
                        onChange={(event) => {
                            handleChangeInputMaskValue(event.target.value)
                        }}
                    >
                        {(inputProps) => (
                            <TextField
                                {...inputProps}
                                fullWidth={true}
                                placeholder={placeholder}
                                InputProps={{ disableUnderline: true }}
                                type={"text"}
                            />
                        )}
                    </InputMask>
                }
                {currentFieldMask === "Free Text" &&
                    <TextField
                        disabled={disabled}
                        fullWidth={true}
                        placeholder={placeholder}
                        InputProps={{ disableUnderline: true }}
                        type={"text"}
                        value={inputMaskValue || ""}
                        onChange={(event) => {
                            handleTimeChange(event.target.value, event.target.value)
                        }}
                    />
                }
                {!disabled &&
                    <Tooltip
                        title={(inputMaskValue !== "") ? "Delete" : "Present time"} placement="top" className={classes.endButton} >
                        {(inputMaskValue !== "") ?
                            <span className={`${classes.timeDelButton} material-icons`}
                                  onClick={() => {handleTimeChange(null, '')}}
                            >highlight_off
                            </span>
                            :
                            <span className={`${classes.timeNowButton} material-icons`}
                                  onClick={() => {
                                      if(inputMaskValue === "" && !disabled) {
                                          if(currentFieldMask === 'Free Text') {
                                              let newDateTime = new Date()
                                              let newTimeString = getTimeStringFromDateTime(newDateTime, currentFieldMask)
                                              handleTimeChange(newDateTime, newTimeString)
                                          } else {
                                              let newTimeString = getCurrentDateTimeByMask(currentFieldMask)
                                              let newDateTime = getDateTimeFromValue(newTimeString, currentFieldMask)
                                              handleTimeChange(newDateTime, newTimeString)
                                          }
                                      }
                                  }}
                            >check
                            </span>
                        }
                    </Tooltip>
                }
            </Fragment>
        </div>
    );
}

// redux providing state takeover
const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(FormatTimeComponent)
