import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineColumnWidth } from 'react-icons/ai'
import { ModalTablemultiTableViewComponent } from '../../modals/tablemulti/ModalTablemultiTableViewComponent'
import { devLog, isDefined } from '../../../utils/utils'
import { getLabelWithSpecialChar, isVisibleInCSV } from '../../../utils/Fields'
import { FormControl, FormControlLabel, FormLabel, MenuItem, Radio, RadioGroup, Select, makeStyles } from '@material-ui/core'
import colors from '../../../assets/themes/Colors'
import RenderEditPerColumnSettingsComponent from './RenderEditPerColumnSettingsComponent'
import { ColorCheckbox } from '../../custom/CustomComponents'
import { DrawerContext } from '../../../containers/models/ModelUpdateV2'

const useStyles = makeStyles(() => ({
  mainCtn: {
    width: 'auto%',
    display: 'flex',
    flexDirection: 'column',
  },
  tabOptionsCtn: {
    paddingLeft: 25,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: colors.palePlugGreen,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  previewCtn: {
    maxHeight: 'calc(50vh)',
    overflowY: 'clip',
    display: 'flex',
    flexDirection: 'row',
  },
  tablePreviewCtn: {
    // padding: 8,
    paddingLeft: 36,
  },
  mainPage: {
    width: 594,
    height: 792,
    margin: 40,
    padding: '50px 30px 50px 35px',
    boxShadow: '0px 0px 20px 5px rgba(0, 0, 0, 0.14), 20px 20px 20px 0px rgba(0, 0, 0, 0.2)',
    backgroundColor: 'white',
  },
  radioGroupCtn: {
    flexDirection: 'row !important',
  },
  radioIcon: {
    color: colors.main,
    width: 25,
  },
  radioIconChecked: {
    color: colors.main,
  },
  radioLabel: {
    fontSize: 12,
  },
  inputCenter: { textAlign: 'center' },
  optionTitleCtn: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: 8,
    fontSize: 18,
    color: colors.labelGrey,
    paddingLeft: 36,
    paddingBottom: 6,
  },
  optionTitle: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: 8,
    fontSize: 18,
    color: colors.labelGrey,
  },
  roundedBorder: {
    display: 'flex',
    flexWrap: 'nowrap',
    padding: 10,
    borderRadius: 5,
    borderWidth: 2,
    border: '2px solid ' + colors.unselected,
    color: colors.unselected,
    cursor: "pointer",
    textAlign: "center",
    alignItems: 'center',
    margin: 5,
    gap: 8,
  },
  selectedBtn: {
    border: '2px solid ' + colors.unselected,
    borderRadius: 5,
    color: colors.unselected,
    textAlign: 'center',
    cursor: 'pointer',
    minWidth: 120,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 16px'
  },
  normalBtn: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: 10,
    borderRadius: 5,
    backgroundColor: colors.main,
    color: colors.white,
    cursor: "pointer",
    textAlign: "center",
    margin: 5,
  },
  labelEditCtn: {
    width: 120,
    display: 'flex',
    flexDirection: 'column',
  },
  labelEdit: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
  },
  textBtn: {
    boxShadow: 'inset 5px 5px 10px 0px rgba(0, 0, 0, 0.14), 0px 0px 2px 0px rgba(0, 0, 0, 0.2)',
    border: '2px solid ' + colors.main,
    borderRadius: 8,
    padding: '4px 8px',
    margin: 4,
    cursor: 'pointer',
  },
  textBtnOff: {
    boxShadow: '5px 5px 10px 0px rgba(0, 0, 0, 0.14), 0px 0px 2px 0px rgba(0, 0, 0, 0.2)',
    border: '1px solid ' + colors.main,
    borderRadius: 8,
    padding: '4px 8px',
    margin: 4,
    cursor: 'pointer',
  },
  disabled: {
    color: colors.iconGrey,
  },
  enabled: {
    cursor: 'pointer',
  },
  clickable: {
    cursor: 'pointer',
  }, 
}))

const TablemultiEditLayoutComponent = (props) => {
  // console.log('TM EditLayout', props)
  const { t } = useTranslation('common')
  const classes = useStyles()
  let _rows = isDefined(props.rows) ? props.rows : []
  const rows = isDefined(_rows.list) ? _rows.list : _rows
  const [mode, setMode] = useState("edit")
  const [plugnoteView, setPlugnoteView] = useState(false)
  const [isPreview, setIsPreview] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [csvData, setCsvData] = useState([])
  const [downloadCsv, setDownloadCsv] = useState(false)
  const [edited, setEdited] = useState(false)
  const initialWidths = props.colonnes.map(col => ({ width: Math.round((props.containerWidth - 200) / (props.colonnes.length)), locked: false, id: col.id }))
  const [widths, setWidths] = useState(initialWidths)
  const setup = props.field.formatoptions.setup
  let _defaultLabelSize = isDefined(props.field.formatoptions.setup?.layout?.defaultLabelSize) ? props.field.formatoptions.setup?.layout?.defaultLabelSize : 11

  const [alignment, setAlignment] = useState(props.field.formatoptions.setup?.layout?.alignment || 'full')
  const [defaultFontSize, setDefaultFontSize] = useState(props.field.formatoptions.setup?.layout?.defaultFontSize || 11)
  const [defaultLabelSize, setDefaultLabelSize] = useState(_defaultLabelSize)
  const [showRowNumbers, setRowNbOpt] = useState(setup.layout?.showRowNb === false ? false : { showOnPdf: true, showOnPf: true, showOnPn: true })

  const getCSVData = () => {
    let datas = []
    if (props.colonnes.length > 0) {
      let line1 = []
      line1.push(t('export.export-date'))
      line1.push(t('export.export-key'))
      line1.push(t('export.export-number'))
      line1.push(t('export.export-field-number'))
      line1.push(t('export.export-field'))
      props.colonnes.forEach(col => {
        if (isVisibleInCSV(col, "create")) line1.push(getLabelWithSpecialChar(col.label))
      })
      datas.push(line1);
      rows.forEach((row, index) => {
        let data = []
        let now = new Date()
        data.push(now.toLocaleString())
        data.push(props.plugKey)
        data.push(props.plugNb)
        data.push(props.index + 1 + (row.recap === true ? ' - Total' : '.' + (index + 1)))
        data.push(props.title)

        props.colonnes.forEach(col => {
          if (isVisibleInCSV(col, mode)) {
            let someText = typeof row[col.id] === 'string' ? row[col.id].replace(/\r?\n?/g, '').trim() : row[col.id];
            if (someText === undefined) {
              someText = ""
            }
            data.push(someText)
          }
        })
        datas.push(data)
      })
    }
    if (datas.length > 0) {
      setCsvData(datas);
      setDownloadCsv(true)
    }
  }

  useEffect(() => {
    if (downloadCsv) {
      setCsvData([]);
      setDownloadCsv(false);
    }
  }, [downloadCsv])

  const field = {
    options: {
      ...props.field.options,
      lines: props.colonnes,
    },
    title: props.title,
    titleTranslate: props.title,
    value: props.rows,
  }

  const handleChangePreviewMode = (e) => {
    setMode(e.target.value)
  }

  const handleValidateWidths = () => {
    setEdited(false)
    let columns = [...props.colonnes]
    columns.forEach(col => {
      let element = widths.find(entry => entry.id === col.id)
      col.colWidth = element.width
    })
    props.handleUpdateColumns(columns)
  }

  const handleToggleModal = () => {
    setOpenModal(previous => !previous)
    setIsPreview(previous => !previous)
  }

  const handleToggleRowNumbers = () => {
    let model = [...props.model]
    setRowNbOpt(previous => {
      let showRowNb = previous === false
      if (!showRowNb) {
        model[props.index].options.setup.layout = {
          ...model[props.index].options.setup.layout,
          showRowNb: false,
        }
      } else {
        model[props.index].options.setup.layout = {
          ...model[props.index].options.setup.layout,
          showRowNb: {
            showOnPdf: true,
            showOnPf: true,
            showOnPn: true,
          },
        }
      }
      return model[props.index].options.setup.layout.showRowNb
    })
    props.handleUpdateModel(model)
  }

  const handleToggleRowNbPDF = () => {
    let model = [...props.model]
    setRowNbOpt(previous => {
        model[props.index].options.setup.layout.showRowNb = {
          ...model[props.index].options.setup.layout.showRowNb,
          showOnPdf: isDefined(previous.showOnPdf) ? !previous.showOnPdf : false,
        }
      return model[props.index].options.setup.layout.showRowNb
    })
    devLog('updated field', model[props.index].options.setup.layout)
    props.handleUpdateModel(model)
  }

  const handleToggleRowNbPn = () => {
    let model = [...props.model]
    setRowNbOpt(previous => {
        model[props.index].options.setup.layout.showRowNb = {
          ...model[props.index].options.setup.layout.showRowNb,
          showOnPn: isDefined(previous.showOnPn) ? !previous.showOnPn : false,
        }
      return model[props.index].options.setup.layout.showRowNb
    })
    devLog('updated field', model[props.index].options.setup.layout)
    props.handleUpdateModel(model)
  }
  const handleToggleRowNbPf = () => {
    let model = [...props.model]
    setRowNbOpt(previous => {
        model[props.index].options.setup.layout.showRowNb = {
          ...model[props.index].options.setup.layout.showRowNb,
          showOnPf: isDefined(previous.showOnPf) ? !previous.showOnPf : false,
        }
      return model[props.index].options.setup.layout.showRowNb
    })
    devLog('updated field', model[props.index].options.setup.layout)
    props.handleUpdateModel(model)
  }

  const handleEraseFormatting = (colId) => {
    setEdited(true)
    let columns = [...props.colonnes]
    columns.forEach(col => {
      if (col.id === colId) {
        col.headerFontSize = -1
      }
    })
    props.handleUpdateColumns(columns)
  }

  const handleToggleAlignement = (e) => {
    let align = e.target.value;
    if (isDefined(align) && align !== 0) { setAlignment(align); 
    } else {
      align = isDefined(alignment) ? alignment : 'full'
      setAlignment(align)
    }
    let model = [...props.model]
    // devLog('updated model', model)
    if (isDefined(model[props.index].options?.setup?.layout?.alignment)) {
      model[props.index].options.setup.layout.alignment = align;
    } else {
      model[props.index].options = {
        ...model[props.index].options,
        setup: {
          ...model[props.index].options?.setup,
          layout: {
            ...model[props.index].options?.setup?.layout,
            alignment: align,
          }
        }
      }
    }
    props.handleUpdateModel(model)
  }

  const handleChangeLabelAlignment = (e, colId) => {
    setEdited(true)
    let columns = [...props.colonnes]
    columns.forEach(col => {
      if (col.id === colId) {
        col.labelAlignment = e
      }
    })
    props.handleUpdateColumns(columns)
  }

  const handleChangeRowAlignment = (e, colId) => {
    setEdited(true)
    let columns = [...props.colonnes]
    columns.forEach(col => {
      if (col.id === colId) {
        col.rowAlignment = e
      }
    })
    props.handleUpdateColumns(columns)
  }

  const handleChangeHeadersFontSize = (e) => {
    let _defaultSize = Number(e.target.value)
    setDefaultLabelSize(_defaultSize)
    let model = [...props.model]
    if (isDefined(model[props.index].options.setup.layout)) {
      model[props.index].options.setup.layout.defaultLabelSize = _defaultSize;
    } else {
      let layout = {
        defaultLabelSize: _defaultSize
      }
      model[props.index].options.setup = {
        ...model[props.index].options.setup,
        layout: layout,
      }
    }
    props.handleUpdateModel(model)
  }

  const handleDecreaseHeadersFontSize = () => {
    let model = [...props.model]
    let labelSize = model[props.index].options.setup.layout?.defaultLabelSize || 11
    const newSize = labelSize >= 0 ? labelSize - 1 : -1
    setDefaultLabelSize(newSize)
    if (isDefined(model[props.index].options.setup.layout)) {
      model[props.index].options.setup.layout.defaultLabelSize = newSize
    } else {
      let layout = {
        defaultLabelSize: newSize
      }
      model[props.index].options.setup = {
        ...model[props.index].options.setup,
        layout: layout,
      }
    }
    props.handleUpdateModel(model)
  }

  const handleIncreaseHeadersFontSize = () => {
    let model = [...props.model]
    let labelSize = model[props.index].options.setup.layout?.defaultLabelSize || 11
    const newSize = labelSize + 1
    setDefaultLabelSize(newSize)
    if (isDefined(model[props.index].options.setup.layout)) {
      model[props.index].options.setup.layout.defaultLabelSize = newSize
    } else {
      let layout = {
        defaultLabelSize: newSize
      }
      model[props.index].options.setup = {
        ...model[props.index].options.setup,
        layout: layout,
      }
    }
    props.handleUpdateModel(model)
  }

  const handleChangeRowsFontSize = (e) => {
    let _defaultSize = Number(e.target.value)
    setDefaultFontSize(_defaultSize)
    let model = [...props.model]
    if (isDefined(model[props.index].options.setup.layout)) {
      model[props.index].options.setup.layout.defaultFontSize = _defaultSize;
    } else {
      let layout = {
        defaultFontSize: _defaultSize
      }
      model[props.index].options.setup = {
        ...model[props.index].options.setup,
        layout: layout,
      }
    }
    props.handleUpdateModel(model)
  }

  const handleDecreaseRowsFontSize = () => {
    let model = [...props.model]
    let labelSize = model[props.index].options.setup.layout?.defaultFontSize || 11
    const newSize = labelSize >= 0 ? labelSize - 1 : -1
    setDefaultFontSize(newSize)
    if (isDefined(model[props.index].options.setup.layout)) {
      model[props.index].options.setup.layout.defaultFontSize = newSize
    } else {
      let layout = {
        defaultFontSize: newSize,
      }
      model[props.index].options.setup = {
        ...model[props.index].options.setup,
        layout: layout,
      }
    }
    props.handleUpdateModel(model)
  }

  const handleIncreaseRowsFontSize = () => {
    let model = [...props.model]
    let labelSize = model[props.index].options.setup.layout?.defaultFontSize || 11
    const newSize = labelSize + 1
    setDefaultFontSize(newSize)
    if (isDefined(model[props.index].options.setup.layout)) {
      model[props.index].options.setup.layout.defaultFontSize = newSize
    } else {
      let layout = {
        defaultFontSize: newSize
      }
      model[props.index].options.setup = {
        ...model[props.index].options.setup,
        layout: layout,
      }
    }
    props.handleUpdateModel(model)
  }

  const handleChangeLabelFontSize = (value, colId) => {
    // devLog('row label size', value, colId)
    setEdited(true)
    let columns = [...props.colonnes]
    columns.forEach(col => {
      if (col.id === colId) {
        col.headerFontSize = value
      }
    })
    // devLog('edited columns', columns)
    props.handleUpdateColumns(columns)
  }

  const handleIncreaseLabelFontSize = (colId) => {
    setEdited(true)
    let columns = [...props.colonnes]
    columns.forEach(col => {
      if (col.id === colId) {
        const newSize = (isDefined(col.headerFontSize) && !isNaN(col.headerFontSize) && col.headerFontSize > 0) ? col.headerFontSize + 1 : defaultLabelSize + 1
        if (col.headerFontSize <= 0 || isNaN(col.headerFontSize)) { col.headerFontSize = newSize }
        else { col.headerFontSize = newSize }
      }
    })
    props.handleUpdateColumns(columns)
  }

  const handleDecreaseLabelFontSize = (colId) => {
    setEdited(true)
    let columns = [...props.colonnes]
    columns.forEach(col => {
      if (col.id === colId) {
        const newSize = (isDefined(col.headerFontSize) && !isNaN(col.headerFontSize) && col.headerFontSize >= 0) ? col.headerFontSize - 1 : defaultLabelSize - 1
        if (col.headerFontSize <= 0 || isNaN(col.headerFontSize)) { col.headerFontSize = newSize }
        if (col.headerFontSize > 0) {
          col.headerFontSize = newSize
        }
      }
    })
    props.handleUpdateColumns(columns)
  }

  const handleTogglePDFVisibility = (row) => {
    let _row = { ...row }
    if (isDefined(row.showOnPdf)) {
      _row.showOnPdf = !row.showOnPdf
    } else { _row.showOnPdf = false }
    let _rows = [...props.colonnes]
    let rindex = _rows.indexOf(_rows.find(entry => entry.id === row.id))
    _rows[rindex] = _row
    props.setDataRows(_rows)
  }

  const handleTogglePFVisibility = (row) => {
    let _row = { ...row }
    if (isDefined(row.encodable)) {
      _row.encodable = !row.encodable
    } else { _row.encodable = false }
    let _rows = [...props.colonnes]
    let rindex = _rows.indexOf(_rows.find(entry => entry.id === row.id))
    _rows[rindex] = _row
    props.setDataRows(_rows)
  }

  const handleTogglePNVisibility = (row) => {
    let _row = { ...row }
    if (isDefined(row.visible)) {
      _row.visible = !row.visible
    } else { _row.visible = false }
    let _rows = [...props.colonnes]
    let rindex = _rows.indexOf(_rows.find(entry => entry.id === row.id))
    _rows[rindex] = _row
    props.setDataRows(_rows)
  }

  const handleIncreaseRowFontSize = (colId) => {
    setEdited(true)
    let columns = [...props.colonnes]
    columns.forEach(col => {
      if (col.id === colId) {
        const newSize = (col.fontSize <= 0 || isNaN(col.fontSize)) ? defaultFontSize + 1 : col.fontSize + 1
        col.fontSize = newSize
      }
    })
    props.handleUpdateColumns(columns)
  }

  const handleDecreaseRowFontSize = (colId) => {
    setEdited(true)
    let columns = [...props.colonnes]
    columns.forEach(col => {
      if (col.id === colId) {
        const step = (isDefined(col.fontSize) && !isNaN(col.fontSize)) ? Math.ceil(col.fontSize / 10) : 1
        const defaultSize = props.model[props.index].options.setup?.layout?.defaultFontSize || 11
        if (col.fontSize <= 0 || isNaN(col.fontSize)) { col.fontSize = defaultSize - step }
        else { col.fontSize = col.fontSize - step }
      }
    })
    props.handleUpdateColumns(columns)
  }

  const handleChangeRowFontSize = (value, colId) => {
    devLog('row font size', value, colId)
    setEdited(true)
    let columns = [...props.colonnes]
    columns.forEach(col => {
      if (col.id === colId) {
        col.fontSize = value
      }
    })
    devLog('edited columns', columns)
    props.handleUpdateColumns(columns)
  }

  const getWidthsSum = () => {
    let totalWidth = 30
    props.colonnes.forEach(entry => {
      if ((mode === 'plugcode-complete' && entry.encodable === false) || (mode === 'plugcode-consult' && entry.visible === false)) { return null }
      else {
        let colWidth = widths.find(col => col.id === entry.id).width || 60
        totalWidth += colWidth
      }
    })
    if (totalWidth > 540) { return 540 }
    return totalWidth
  }

  const voidAction = () => null

  useEffect(() => {
    // devLog(widths, initialWidths)
    //   if (widths.some((col, index) => col.width !== initialWidths[index].width)) {
    //     // setEditedWidths(true)
    //   devLog('changed widths', true)
    // }
    if (edited) {
      handleValidateWidths()
    }
  }, [widths])

  const pdfStyle = {
    width: getWidthsSum()
  }
  switch (alignment) {
    case 'full':
      pdfStyle.width = 540
      break
    case 'left':
      pdfStyle.marginRight = 'auto'
      break
    case 'right':
      pdfStyle.marginLeft = 'auto'
      break
    case 'center':
    default:
      pdfStyle.marginRight = 'auto'
      pdfStyle.marginLeft = 'auto'
      break
  }

  return (
    <div className={classes.mainCtn} >
      <div className={classes.tabOptionsCtn} >
        <FormControl>
          <FormLabel id="mode-select-group-label">{t('modes.mode-select')}</FormLabel>
          <RadioGroup
            className={classes.radioGroupCtn}
            aria-label="gender" name="gender1"
            title={t('modes.mode-select')}
            onChange={handleChangePreviewMode}
            value={mode}
          >
            <FormControlLabel size='small' value={"edit"}
              control={
                <Radio classes={{ root: classes.radioIcon, checked: classes.radioIconChecked }}
                  size='small'
                />
              }
              label={
                <span className={classes.radioLabel}>
                  <span className="material-icons" style={{ fontSize: 16 }}>
                    edit
                  </span>
                  {t('modes.edit')} & {t('modes.create')}
                </span>
              }
              key="edit-radio"
            />
            <FormControlLabel size='small' value={"plugcode-complete"}
              control={
                <Radio classes={{ root: classes.radioIcon, checked: classes.radioIconChecked }}
                  size='small'
                />
              }
              label={<span className={classes.radioLabel}>{t('modes.plugcode-complete')}</span>}
              key="pf-code-radio"
            />
            <FormControlLabel size='small' value={"plugcode-consult"}
              control={
                <Radio classes={{ root: classes.radioIcon, checked: classes.radioIconChecked }}
                  size='small'
                />
              }
              label={<span className={classes.radioLabel}>{t('modes.plugcode-consult')}</span>}
              key="pn-code-radio"
            />
            <FormControlLabel size='small' value={"pw-code"}
              control={
                <Radio classes={{ root: classes.radioIcon, checked: classes.radioIconChecked }}
                  size='small'
                />
              }
              label={<span className={classes.radioLabel}>{t('modes.pw-code')}</span>}
              key="pw-code-radio"
            />
            <FormControlLabel size='small' value={"pdf"}
              control={
                <Radio classes={{ root: classes.radioIcon, checked: classes.radioIconChecked }}
                  size='small'
                />
              }
              label={<span className={classes.radioLabel}>{t('modes.pdf')}</span>}
              key="pdf-radio"
            />
          </RadioGroup>
        </FormControl>

        <div className="verticalCenter">
          <div
            className={classes.optionTitle}
            onClick={handleToggleRowNumbers}
            style={{ cursor: 'pointer' }}
          >
              <ColorCheckbox checked={showRowNumbers !== false} color={showRowNumbers !== false ? colors.main : colors.labelGrey} size={24} />
            <span>{t('tablemulti.row-numbers')}</span>
          </div>
          <div className="horizontalSpaceBetween" style={{ width: '-webkit-fill-available' }}>
            <span onClick={handleToggleRowNbPDF} className={showRowNumbers === false ? classes.disabled : classes.enabled}>
              <ColorCheckbox
                checked={setup.layout?.showRowNb?.showOnPdf !== false}
                disabled={showRowNumbers === false}
                size={20}
              />
              PDF
            </span>
            <span onClick={handleToggleRowNbPn} className={showRowNumbers === false ? classes.disabled : classes.enabled} >
              <ColorCheckbox
                checked={setup.layout?.showRowNb?.showOnPn !== false}
                disabled={showRowNumbers === false}
                size={20}
              />
              PN-
            </span>
            <span onClick={handleToggleRowNbPf} className={showRowNumbers === false ? classes.disabled : classes.enabled} >
              <ColorCheckbox
                checked={setup.layout?.showRowNb?.showOnPf !== false}
                disabled={showRowNumbers === false}
                size={20}
              />
              PF-
            </span>
          </div>
        </div>

        <FormControl>
          <FormLabel id="select-group-label" style={{ whiteSpace: 'break-spaces' }}>{t('common:print.alignment')}</FormLabel>
          <Select
            name="select-group"
            onChange={handleToggleAlignement}
            value={alignment}
          >
            <MenuItem value="full">{t('common:print.align-full')}</MenuItem>
            <MenuItem value="left">{t('common:print.align-left')}</MenuItem>
            <MenuItem value="center">{t('common:print.align-center')}</MenuItem>
            <MenuItem value="right">{t('common:print.align-right')}</MenuItem>
          </Select>
        </FormControl>

        <div className={classes.normalBtn} onClick={handleToggleModal}>
          {t('tablemulti.modal-view')}
        </div>
      </div>
      <div className={classes.tabOptionsCtn} >
        {/* {
          edited &&
          <div
            className={classes.selectedBtn}
            onClick={handleValidateWidths}
          >{t('global.save-changes')}</div>
        } */}
      </div>
      {
        mode === 'edit' &&
        <div className={classes.tablePreviewCtn} style={{ paddingLeft: 36 }}>
          <RenderEditPerColumnSettingsComponent
            colonnes={props.colonnes}
            defaultFontSize={defaultFontSize}
            defaultLabelSize={defaultLabelSize}
            edited={edited}
            field={field}
            fields={props.fields}
            handleChangeHeadersFontSize={handleChangeHeadersFontSize}
            handleChangeLabelAlignment={handleChangeLabelAlignment}
            handleChangeLabelFontSize={handleChangeLabelFontSize}
            handleChangePreviewMode={handleChangePreviewMode}
            handleChangeRowAlignment={handleChangeRowAlignment}
            handleChangeRowFontSize={handleChangeRowFontSize}
            handleChangeRowsFontSize={handleChangeRowsFontSize}
            handleClickPreview={voidAction}
            handleClose={handleToggleModal}
            handleDecreaseHeadersFontSize={handleDecreaseHeadersFontSize}
            handleDecreaseLabelFontSize={handleDecreaseLabelFontSize}
            handleDecreaseRowsFontSize={handleDecreaseRowsFontSize}
            handleDecreaseRowFontSize={handleDecreaseRowFontSize}
            handleEraseFormatting={handleEraseFormatting}
            handleIncreaseHeadersFontSize={handleIncreaseHeadersFontSize}
            handleIncreaseLabelFontSize={handleIncreaseLabelFontSize}
            handleIncreaseRowFontSize={handleIncreaseRowFontSize}
            handleIncreaseRowsFontSize={handleIncreaseRowsFontSize}
            handleTogglePDFVisibility={handleTogglePDFVisibility}
            handleTogglePFVisibility={handleTogglePFVisibility}
            handleTogglePNVisibility={handleTogglePNVisibility}
            mode={mode}
            model={props.dbModel}
            plugnoteNb={props.plugNb}
            setEdited={setEdited}
            title={props.title}
            widths={widths}
          />
        </div>
      }
      <div className={classes.optionTitleCtn} >
        <AiOutlineColumnWidth color={colors.labelGrey} size={24} />
        <span>{t('tablemulti.resize-columns')}</span>
        <div onClick={() => setPlugnoteView(previous => !previous)} className={classes.clickable} >
          <ColorCheckbox checked={plugnoteView} />
          <span >{t('tablemulti.plugnote-view')}</span>
        </div>
      </div>
      <div className={classes.previewCtn} style={plugnoteView ? { flexDirection: 'column', maxWidth: '40vw' } : undefined}>
        <div className={mode === 'pdf' ? classes.mainPage : undefined} style={plugnoteView ? { alignSelf: 'center', } : undefined}>
          <div className={mode === 'pdf' ? undefined : classes.tablePreviewCtn} style={mode === 'pdf' ? pdfStyle : { paddingTop: mode === 'edit' ? 0 : 20 }}>
            <ModalTablemultiTableViewComponent
              {...props}
              attemptGetDataFromAnotherBaseByClientReset={voidAction}
              criteria=''
              colonnes={props.colonnes}
              datasToDownload={csvData}
              disabled
              editMode={edited}
              editPerColumn={false}
              field={field}
              fields={props.fields}
              handleAddTableMulti={voidAction}
              handleChangeChildrenRowValue={voidAction}
              handleChangeChildrenValue={voidAction}
              handleChangePreviewMode={handleChangePreviewMode}
              handleChangeRowAlignment={handleChangeRowAlignment}
              handleChangeRowFontSize={handleChangeRowFontSize}
              handleClickPreview={voidAction}
              handleClose={handleToggleModal}
              handleDecreaseLabelFontSize={handleDecreaseLabelFontSize}
              handleDecreaseRowFontSize={handleDecreaseRowFontSize}
              handleDeleteRow={voidAction}
              handleEditValue={voidAction}
              handleEraseFormatting={handleEraseFormatting}
              handleExportTableMulti={getCSVData}
              handleIncreaseLabelFontSize={handleIncreaseLabelFontSize}
              handleIncreaseRowFontSize={handleIncreaseRowFontSize}
              handleOpenSearchModel={voidAction}
              handleTogglePDFVisibility={handleTogglePDFVisibility}
              handleTogglePFVisibility={handleTogglePFVisibility}
              handleTogglePNVisibility={handleTogglePNVisibility}
              handleViewTable={voidAction}
              hasDb={false}
              hideSearch
              isModelPreview={!plugnoteView}
              isFromPwCode={false}
              isPreview={isPreview}
              mode={mode}
              model={props.dbModel}
              newRow={null}
              open={openModal}
              openDownload={downloadCsv}
              openRow={null}
              owner=""
              ownerId=""
              plugnoteNb={props.plugNb}
              rows={rows}
              search=""
              setNewRow={voidAction}
              setOpenRow={voidAction}
              setEdited={setEdited}
              setWidths={setWidths}
              title={props.title}
              widths={widths}
            />
          </div>
        </div>
      </div>

    </div>
  )
}

export default TablemultiEditLayoutComponent
