// actions types is object with the
// key: GET_DATA_REQUEST -> value: describes the action
import {toast} from "react-toastify";
import React from "react";
import {Translation} from "react-i18next";

export const Types = {
    RESET_ALL_DATA: 'reset_all_data',

    REDUX_GENERATE_PLUGFORMCODE_RESET: 'redux_generate_plugformcode_reset',

    REDUX_GENERATE_PLUGNOTECODE_RESET : 'redux_generate_plugnotecode_reset',

    POST_GENERATE_PLUGFORMCODE_REQUEST: 'post_generate_plugformcode_request',
    POST_GENERATE_PLUGFORMCODE_SUCCESS: 'post_generate_plugformcode_success',
    POST_GENERATE_PLUGFORMCODE_FAILURE: 'post_generate_plugformcode_failure',
    POST_GENERATE_PLUGFORMCODE_ERROR: 'post_generate_plugformcode_error',

    DELETE_GENERATE_PLUGFORMCODE_REQUEST: 'delete_generate_plugformcode_request',
    DELETE_GENERATE_PLUGFORMCODE_SUCCESS: 'delete_generate_plugformcode_success',
    DELETE_GENERATE_PLUGFORMCODE_FAILURE: 'delete_generate_plugformcode_failure',
    DELETE_GENERATE_PLUGFORMCODE_ERROR: 'delete_generate_plugformcode_error',

    UPDATE_GENERATE_PLUGFORMCODE_REQUEST: 'update_generate_plugformcode_request',
    UPDATE_GENERATE_PLUGFORMCODE_SUCCESS: 'update_generate_plugformcode_success',
    UPDATE_GENERATE_PLUGFORMCODE_FAILURE: 'update_generate_plugformcode_failure',
    UPDATE_GENERATE_PLUGFORMCODE_ERROR: 'update_generate_plugformcode_error',

    GET_PLUGFORMCODE_REQUEST: 'get_plugformcode_request',
    GET_PLUGFORMCODE_SUCCESS: 'get_plugformcode_success',
    GET_PLUGFORMCODE_FAILURE: 'get_plugformcode_failure',
    GET_PLUGFORMCODE_ERROR: 'get_plugformcode_error',

    POST_GENERATE_PLUGNOTECODE_REQUEST: 'post_generate_plugnotecode_request',
    POST_GENERATE_PLUGNOTECODE_SUCCESS: 'post_generate_plugnotecode_success',
    POST_GENERATE_PLUGNOTECODE_FAILURE: 'post_generate_plugnotecode_failure',
    POST_GENERATE_PLUGNOTECODE_ERROR: 'post_generate_plugnotecode_error',

    DELETE_GENERATE_PLUGNOTECODE_REQUEST: 'delete_generate_plugnotecode_request',
    DELETE_GENERATE_PLUGNOTECODE_SUCCESS: 'delete_generate_plugnotecode_success',
    DELETE_GENERATE_PLUGNOTECODE_FAILURE: 'delete_generate_plugnotecode_failure',
    DELETE_GENERATE_PLUGNOTECODE_ERROR: 'delete_generate_plugnotecode_error',

    UPDATE_GENERATE_PLUGNOTECODE_REQUEST: 'update_generate_plugnotecode_request',
    UPDATE_GENERATE_PLUGNOTECODE_SUCCESS: 'update_generate_plugnotecode_success',
    UPDATE_GENERATE_PLUGNOTECODE_FAILURE: 'update_generate_plugnotecode_failure',
    UPDATE_GENERATE_PLUGNOTECODE_ERROR: 'update_generate_plugnotecode_error',

    UPDATE_GENERATE_PS_PLUGNOTECODE_REQUEST: 'update_generate_ps_plugnotecode_request',
    UPDATE_GENERATE_PS_PLUGNOTECODE_SUCCESS: 'update_generate_ps_plugnotecode_success',
    UPDATE_GENERATE_PS_PLUGNOTECODE_FAILURE: 'update_generate_ps_plugnotecode_failure',
    UPDATE_GENERATE_PS_PLUGNOTECODE_ERROR: 'update_generate_ps_plugnotecode_error',

    GET_PLUGNOTE_VIA_CODE_REQUEST : 'get_plugnote_via_code_request',
    GET_PLUGNOTE_VIA_CODE_SUCCESS : 'get_plugnote_via_code_success',
    GET_PLUGNOTE_VIA_CODE_FAILURE : 'get_plugnote_via_code_failure',
    GET_PLUGNOTE_VIA_CODE_ERROR : 'get_plugnote_via_code_error',

    GET_OLD_PLUGNOTE_VIA_CODE_REQUEST : 'get_old_plugnote_via_code_request',
    GET_OLD_PLUGNOTE_VIA_CODE_SUCCESS : 'get_old_plugnote_via_code_success',
    GET_OLD_PLUGNOTE_VIA_CODE_FAILURE : 'get_old_plugnote_via_code_failure',
    GET_OLD_PLUGNOTE_VIA_CODE_ERROR : 'get_old_plugnote_via_code_error',

    GET_PLUGNOTE_VIA_CODE_OFFLINE_RESET : 'get_plugnote_via_code_offline_reset',
    GET_PLUGNOTE_VIA_CODE_OFFLINE_REQUEST : 'get_plugnote_via_code_offline_request',
    GET_PLUGNOTE_VIA_CODE_OFFLINE_SUCCESS : 'get_plugnote_via_code_offline_success',
    GET_PLUGNOTE_VIA_CODE_OFFLINE_FAILURE : 'get_plugnote_via_code_offline_failure',
    GET_PLUGNOTE_VIA_CODE_OFFLINE_ERROR : 'get_plugnote_via_code_offline_error',

    GET_CORPORATE_USERS_GROUPS_REQUEST : 'get_corporate_users_groups_request',
    GET_CORPORATE_USERS_GROUPS_SUCCESS : 'get_corporate_users_groups_success',
    GET_CORPORATE_USERS_GROUPS_FAILURE : 'get_corporate_users_groups_failure',
    GET_CORPORATE_USERS_GROUPS_ERROR : 'get_corporate_users_groups_error',

    GET_PLUGFORM_VIA_CODE_REQUEST : 'get_plugform_via_code_request',
    GET_PLUGFORM_VIA_CODE_SUCCESS : 'get_plugform_via_code_success',
    GET_PLUGFORM_VIA_CODE_FAILURE : 'get_plugform_via_code_failure',
    GET_PLUGFORM_VIA_CODE_ERROR : 'get_plugform_via_code_error',

    GET_OLD_PLUGFORM_VIA_CODE_REQUEST : 'get_old_plugform_via_code_request',
    GET_OLD_PLUGFORM_VIA_CODE_SUCCESS : 'get_old_plugform_via_code_success',
    GET_OLD_PLUGFORM_VIA_CODE_FAILURE : 'get_old_plugform_via_code_failure',
    GET_OLD_PLUGFORM_VIA_CODE_ERROR : 'get_old_plugform_via_code_error',

    GET_PLUGFORM_VIA_CODE_OFFLINE_RESET : 'get_plugform_via_code_offline_reset',
    GET_PLUGFORM_VIA_CODE_OFFLINE_REQUEST : 'get_plugform_via_code_offline_request',
    GET_PLUGFORM_VIA_CODE_OFFLINE_SUCCESS : 'get_plugform_via_code_offline_success',
    GET_PLUGFORM_VIA_CODE_OFFLINE_FAILURE : 'get_plugform_via_code_offline_failure',
    GET_PLUGFORM_VIA_CODE_OFFLINE_ERROR : 'get_plugform_via_code_offline_error',

    POST_CLASSIFY_IN_ITS_PLUGFORM_REQUEST: 'post_classify_in_its_plugform_request',
    POST_CLASSIFY_IN_ITS_PLUGFORM_SUCCESS: 'post_classify_in_its_plugform_success',
    POST_CLASSIFY_IN_ITS_PLUGFORM_FAILURE: 'post_classify_in_its_plugform_failure',
    POST_CLASSIFY_IN_ITS_PLUGFORM_ERROR: 'post_classify_in_its_plugform_error',

    POST_CLASSIFY_IN_ANOTHER_PLUGFORM_REQUEST: 'post_classify_in_another_plugform_request',
    POST_CLASSIFY_IN_ANOTHER_PLUGFORM_SUCCESS: 'post_classify_in_another_plugform_success',
    POST_CLASSIFY_IN_ANOTHER_PLUGFORM_FAILURE: 'post_classify_in_another_plugform_failure',
    POST_CLASSIFY_IN_ANOTHER_PLUGFORM_ERROR: 'post_classify_in_another_plugform_error',

    POST_CLASSIFY_IN_ANOTHER_PLUGFORM_MOVE_REQUEST: 'post_classify_in_another_plugform_move_request',
    POST_CLASSIFY_IN_ANOTHER_PLUGFORM_MOVE_SUCCESS: 'post_classify_in_another_plugform_move_success',
    POST_CLASSIFY_IN_ANOTHER_PLUGFORM_MOVE_FAILURE: 'post_classify_in_another_plugform_move_failure',
    POST_CLASSIFY_IN_ANOTHER_PLUGFORM_MOVE_ERROR: 'post_classify_in_another_plugform_move_error',

    POST_CHECK_EMAIL_REQUEST : 'post_check_email_request',
    POST_CHECK_EMAIL_SUCCESS : 'post_check_email_success',
    POST_CHECK_EMAIL_FAILURE : 'post_check_email_failure',
    POST_CHECK_EMAIL_ERROR : 'post_check_email_error',


    POST_ADD_PLUGCODE_PLUGNOTE_USER_REQUEST: 'post_add_plugcode_plugnote_user_request',
    POST_ADD_PLUGCODE_PLUGNOTE_USER_SUCCESS: 'post_add_plugcode_plugnote_user_success',
    POST_ADD_PLUGCODE_PLUGNOTE_USER_FAILURE: 'post_add_plugcode_plugnote_user_failure',
    POST_ADD_PLUGCODE_PLUGNOTE_USER_ERROR: 'post_add_plugcode_plugnote_user_error',

    DEL_DELETE_PLUGCODE_PLUGNOTE_USER_REQUEST: 'del_delete_plugcode_plugnote_user_request',
    DEL_DELETE_PLUGCODE_PLUGNOTE_USER_SUCCESS: 'del_delete_plugcode_plugnote_user_success',
    DEL_DELETE_PLUGCODE_PLUGNOTE_USER_FAILURE: 'del_delete_plugcode_plugnote_user_failure',
    DEL_DELETE_PLUGCODE_PLUGNOTE_USER_ERROR: 'del_delete_plugcode_plugnote_user_error',

    POST_MODEL_GENERATE_MULTIPLE_PLUGCODE_REQUEST: 'post_model_generate_multiple_plugcode_request',
    POST_MODEL_GENERATE_MULTIPLE_PLUGCODE_SUCCESS: 'post_model_generate_multiple_plugcode_success',
    POST_MODEL_GENERATE_MULTIPLE_PLUGCODE_FAILURE: 'post_model_generate_multiple_plugcode_failure',
    POST_MODEL_GENERATE_MULTIPLE_PLUGCODE_ERROR: 'post_model_generate_multiple_plugcode_error',

};

export const MainToastMessage = ({ title, message }) => (
    <div>
        {title !== '' &&
        <Translation ns="common">
            {(t, { i18n }) => <h5>{t(title)}</h5>}
        </Translation>
        }
        <Translation ns="common">
            {(t, { i18n }) => <p>{t(message)}</p>}
        </Translation>
    </div>
)

/*
Token  payload, userhash, plugnote
 */
// function that returns an object literal
export const postGeneratePlugformcodeRequest = (comment, modalhash, userhash, isPublic, model) => ({
    type: Types.POST_GENERATE_PLUGFORMCODE_REQUEST,
    comment : comment,
    modelhash : modalhash,
    userhash : userhash,
    isPublic : isPublic,
    model : model,
});

// function that returns an object literal
export const postGeneratePlugformcodeSuccess = (data) => ({
    type: Types.POST_GENERATE_PLUGFORMCODE_SUCCESS,
    payload: data
});

// function that returns an object literal
export const postGeneratePlugformcodeFailure = () => ({
    type: Types.POST_GENERATE_PLUGFORMCODE_FAILURE,
});

// function that returns an object literal
export const postGeneratePlugformcodeError = () => ({
    type: Types.POST_GENERATE_PLUGFORMCODE_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const postGeneratePlugnotecodeRequest = (payload,isPublic, userhash, plugnote) => ({
    type: Types.POST_GENERATE_PLUGNOTECODE_REQUEST,
    payload : payload,
    isPublic : isPublic,
    userhash : userhash,
    plugnote : plugnote,
});

/*// function that returns an object literal
export const postGeneratePlugnotecodeSuccess = (data) => ({
    type: Types.POST_GENERATE_PLUGNOTECODE_SUCCESS,
    payload: data
});*/

export function postGeneratePlugnotecodeSuccess(data) {
    return function(dispatch) {
        dispatch({
            type: Types.POST_GENERATE_PLUGNOTECODE_SUCCESS,
            payload: data
        });
        //toast.success("MY SUCCESS");
        toast.success(<MainToastMessage title='global.plugnotes' message='plugcode.delete-plugcode-success' />)
    };
}

// function that returns an object literal
export const postGeneratePlugnotecodeFailure = () => ({
    type: Types.POST_GENERATE_PLUGNOTECODE_FAILURE,
});

// function that returns an object literal
export const postGeneratePlugnotecodeError = () => ({
    type: Types.POST_GENERATE_PLUGNOTECODE_ERROR,
});


//


export const postClassifyInAnotherPlugformRequest = (plugnote, modelhash, annexes, fields, editLabel, userhash) => ({
    type: Types.POST_CLASSIFY_IN_ANOTHER_PLUGFORM_REQUEST,
    plugnote : plugnote,
    modelhash : modelhash,
    annexes : annexes,
    fields : fields,
    editLabel : editLabel,
    userhash : userhash,
});

// function that returns an object literal
export const postClassifyInAnotherPlugformSuccess = (data) => ({
    type: Types.POST_CLASSIFY_IN_ANOTHER_PLUGFORM_SUCCESS,
    payload: data
});

// function that returns an object literal
export const postClassifyInAnotherPlugformFailure = () => ({
    type: Types.POST_CLASSIFY_IN_ANOTHER_PLUGFORM_FAILURE,
});

// function that returns an object literal
export const postClassifyInAnotherPlugformError = () => ({
    type: Types.POST_CLASSIFY_IN_ANOTHER_PLUGFORM_ERROR,
});


export const postClassifyInAnotherPlugformMoveRequest = (plugnote, modelhash, annexes, fields, editLabel, userhash, move) => ({
    type: Types.POST_CLASSIFY_IN_ANOTHER_PLUGFORM_MOVE_REQUEST,
    plugnote : plugnote,
    modelhash : modelhash,
    annexes : annexes,
    fields : fields,
    editLabel : editLabel,
    userhash : userhash,
    move: move,
});

// function that returns an object literal
export const postClassifyInAnotherPlugformMoveSuccess = (data) => ({
    type: Types.POST_CLASSIFY_IN_ANOTHER_PLUGFORM_MOVE_SUCCESS,
    payload: data
});

// function that returns an object literal
export const postClassifyInAnotherPlugformMoveFailure = () => ({
    type: Types.POST_CLASSIFY_IN_ANOTHER_PLUGFORM_MOVE_FAILURE,
});

// function that returns an object literal
export const postClassifyInAnotherPlugformMoveError = () => ({
    type: Types.POST_CLASSIFY_IN_ANOTHER_PLUGFORM_MOVE_ERROR,
});


export const postClassifyInItsPlugformRequest = (plugnote, datahash, userhash) => ({
    type: Types.POST_CLASSIFY_IN_ITS_PLUGFORM_REQUEST,
    plugnote : plugnote,
    datahash : datahash,
    userhash : userhash
});

// function that returns an object literal
export const postClassifyInItsPlugformSuccess = (data) => ({
    type: Types.POST_CLASSIFY_IN_ITS_PLUGFORM_SUCCESS,
    payload: data
});

// function that returns an object literal
export const postClassifyInItsPlugformFailure = () => ({
    type: Types.POST_CLASSIFY_IN_ITS_PLUGFORM_FAILURE,
});

// function that returns an object literal
export const postClassifyInItsPlugformError = () => ({
    type: Types.POST_CLASSIFY_IN_ITS_PLUGFORM_ERROR,
});

/**
 * reset
 */

// function that returns an object literal
export const reduxGeneratePlugformcodeReset = () => ({
    type: Types.REDUX_GENERATE_PLUGFORMCODE_RESET,
});

export const reduxGeneratePlugnotecodeReset = () => ({
    type: Types.REDUX_GENERATE_PLUGNOTECODE_RESET,
});

/**
 * delete
 */

// function that returns an object literal
export const deleteGeneratePlugformcodeRequest = (modelhash, modelpfc, userhash) => ({
    type: Types.DELETE_GENERATE_PLUGFORMCODE_REQUEST,
    modelhash : modelhash,
    modelpfc : modelpfc,
    userhash : userhash,
});

// function that returns an object literal
/*export const deleteGeneratePlugformcodeSuccess = (data) => ({
    type: Types.DELETE_GENERATE_PLUGFORMCODE_SUCCESS,
    payload: data
});*/

export function deleteGeneratePlugformcodeSuccess(data) {
    return function(dispatch) {
        dispatch({
            type: Types.DELETE_GENERATE_PLUGFORMCODE_SUCCESS,
            payload : data
        });
        //toast.success("MY SUCCESS");
        toast.success(<MainToastMessage title='global.plugnotes' message='plugcode.delete-plugcode-success' />)
    };
}

// function that returns an object literal
export const deleteGeneratePlugformcodeFailure = () => ({
    type: Types.DELETE_GENERATE_PLUGFORMCODE_FAILURE,
});

// function that returns an object literal
export const deleteGeneratePlugformcodeError = () => ({
    type: Types.DELETE_GENERATE_PLUGFORMCODE_ERROR,
});

// function that returns an object literal
export const deleteGeneratePlugnotecodeRequest = (datahash, datapnc, userhash) => ({
    type: Types.DELETE_GENERATE_PLUGNOTECODE_REQUEST,
    datahash : datahash,
    datapnc : datapnc,
    userhash : userhash,
});

/*// function that returns an object literal
export const deleteGeneratePlugnotecodeSuccess = (data) => ({
    type: Types.DELETE_GENERATE_PLUGNOTECODE_SUCCESS,
    payload: data
});*/

export function deleteGeneratePlugnotecodeSuccess(data) {
    return function(dispatch) {
        dispatch({
            type: Types.DELETE_GENERATE_PLUGNOTECODE_SUCCESS,
            payload : data
        });
        //toast.success("MY SUCCESS");
        toast.success(<MainToastMessage title='global.plugnotes' message='plugcode.delete-plugcode-success' />)
    };
}

// function that returns an object literal
export const deleteGeneratePlugnotecodeFailure = () => ({
    type: Types.DELETE_GENERATE_PLUGNOTECODE_FAILURE,
});

// function that returns an object literal
export const deleteGeneratePlugnotecodeError = () => ({
    type: Types.DELETE_GENERATE_PLUGNOTECODE_ERROR,
});

/**
 * update
 */

// function that returns an object literal
export const putGeneratePlugformcodeRequest = (payload,accessPayload,modelhash,userhash, model) => ({
    type: Types.UPDATE_GENERATE_PLUGFORMCODE_REQUEST,
    payload : payload,
    accessPayload : accessPayload,
    modelhash : modelhash,
    userhash : userhash,
    model : model,
});

// function that returns an object literal
export const putGeneratePlugformcodeSuccess = (data) => ({
    type: Types.UPDATE_GENERATE_PLUGFORMCODE_SUCCESS,
    payload: data
});

// function that returns an object literal
export const putGeneratePlugformcodeFailure = () => ({
    type: Types.UPDATE_GENERATE_PLUGFORMCODE_FAILURE,
});

// function that returns an object literal
export const putGeneratePlugformcodeError = () => ({
    type: Types.UPDATE_GENERATE_PLUGFORMCODE_ERROR,
});

// function that returns an object literal
export const putGeneratePlugnotecodeRequest = (payload, userhash, plugnote) => ({
    type: Types.UPDATE_GENERATE_PLUGNOTECODE_REQUEST,
    payload : payload,
    userhash : userhash,
    plugnote : plugnote,
});

// function that returns an object literal
export const putGeneratePlugnotecodeSuccess = (data) => ({
    type: Types.UPDATE_GENERATE_PLUGNOTECODE_SUCCESS,
    payload: data
});

// function that returns an object literal
export const putGeneratePlugnotecodeFailure = () => ({
    type: Types.UPDATE_GENERATE_PLUGNOTECODE_FAILURE,
});

// function that returns an object literal
export const putGeneratePlugnotecodeError = () => ({
    type: Types.UPDATE_GENERATE_PLUGNOTECODE_ERROR,
});

export const putGeneratePsPlugnotecodeRequest = (datahash,pnhash,listUsers,userhash,comment,type,plugnote) => ({
    type: Types.UPDATE_GENERATE_PS_PLUGNOTECODE_REQUEST,
    datahash : datahash,
    pnhash : pnhash,
    listUsers : listUsers,
    userhash : userhash,
    comment : comment,
    public : type,
    plugnote : plugnote,
})

// function that returns an object literal
export const putGeneratePsPlugnotecodeSuccess = (data) => ({
    type: Types.UPDATE_GENERATE_PS_PLUGNOTECODE_SUCCESS,
    payload: data
});

// function that returns an object literal
export const putGeneratePsPlugnotecodeFailure = () => ({
    type: Types.UPDATE_GENERATE_PS_PLUGNOTECODE_FAILURE,
});

// function that returns an object literal
export const putGeneratePsPlugnotecodeError = () => ({
    type: Types.UPDATE_GENERATE_PS_PLUGNOTECODE_ERROR,
});


/*
Token
 */
// function that returns an object literal
export const getOldPlugnoteViaCodeRequest = (plugcode,userhash, exist) => ({
    type: Types.GET_OLD_PLUGNOTE_VIA_CODE_REQUEST,
    plugcode: plugcode,
    userhash: userhash,
    exist: exist
});

// function that returns an object literal
export const getOldPlugnoteViaCodeSuccess = (data) => ({
    type: Types.GET_OLD_PLUGNOTE_VIA_CODE_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getOldPlugnoteViaCodeFailure = () => ({
    type: Types.GET_OLD_PLUGNOTE_VIA_CODE_FAILURE,
});

// function that returns an object literal
export const getOldPlugnoteViaCodeError = () => ({
    type: Types.GET_OLD_PLUGNOTE_VIA_CODE_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getPlugnoteViaCodeRequest = (plugcode,userhash, exist) => ({
    type: Types.GET_PLUGNOTE_VIA_CODE_REQUEST,
    plugcode: plugcode,
    userhash: userhash,
    exist: exist
});

// function that returns an object literal
export const getPlugnoteViaCodeSuccess = (data) => ({
    type: Types.GET_PLUGNOTE_VIA_CODE_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getPlugnoteViaCodeFailure = () => ({
    type: Types.GET_PLUGNOTE_VIA_CODE_FAILURE,
});

// function that returns an object literal
export const getPlugnoteViaCodeError = () => ({
    type: Types.GET_PLUGNOTE_VIA_CODE_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getPlugnoteViaCodeOfflineRequest = (plugcode) => ({
    type: Types.GET_PLUGNOTE_VIA_CODE_OFFLINE_REQUEST,
    plugcode: plugcode,
});

// function that returns an object literal
export const getPlugnoteViaCodeOfflineSuccess = (data) => ({
    type: Types.GET_PLUGNOTE_VIA_CODE_OFFLINE_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getPlugnoteViaCodeOfflineFailure = () => ({
    type: Types.GET_PLUGNOTE_VIA_CODE_OFFLINE_FAILURE,
});

// function that returns an object literal
export const getPlugnoteViaCodeOfflineError = () => ({
    type: Types.GET_PLUGNOTE_VIA_CODE_OFFLINE_ERROR,
});

// function that returns an object literal
export const getPlugnoteViaCodeOfflineReset = () => ({
    type: Types.GET_PLUGNOTE_VIA_CODE_OFFLINE_RESET,
});


/*
Token
 */
// function that returns an object literal
export const getCorporateUsersGroupsRequest = (group,userhash) => ({
    type: Types.GET_CORPORATE_USERS_GROUPS_REQUEST,
    group: group,
    userhash: userhash,
});

// function that returns an object literal
export const getCorporateUsersGroupsSuccess = (data, data2, data3,data4 ) => ({
    type: Types.GET_CORPORATE_USERS_GROUPS_SUCCESS,
    payload1: data ,
    payload2: data2,
    payload3: data3,
    payload4: data4,
});

// function that returns an object literal
export const getCorporateUsersGroupsFailure = () => ({
    type: Types.GET_CORPORATE_USERS_GROUPS_FAILURE,
});

// function that returns an object literal
export const getCorporateUsersGroupsError = () => ({
    type: Types.GET_CORPORATE_USERS_GROUPS_ERROR,
});/*

/*
Token
 */
// function that returns an object literal
export const getPlugformViaCodeRequest = (plugcode, userhash, exist) => ({
    type: Types.GET_PLUGFORM_VIA_CODE_REQUEST,
    plugcode : plugcode,
    userhash : userhash,
    exist : exist
});

// function that returns an object literal
export const getPlugformViaCodeSuccess = (data) => ({
    type: Types.GET_PLUGFORM_VIA_CODE_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getPlugformViaCodeFailure = () => ({
    type: Types.GET_PLUGFORM_VIA_CODE_FAILURE,
});

// function that returns an object literal
export const getPlugformViaCodeError = () => ({
    type: Types.GET_PLUGFORM_VIA_CODE_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getOldPlugformViaCodeRequest = (plugcode, userhash, exist) => ({
    type: Types.GET_OLD_PLUGFORM_VIA_CODE_REQUEST,
    plugcode : plugcode,
    userhash : userhash,
    exist : exist
});

// function that returns an object literal
export const getOldPlugformViaCodeSuccess = (data) => ({
    type: Types.GET_OLD_PLUGFORM_VIA_CODE_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getOldPlugformViaCodeFailure = () => ({
    type: Types.GET_OLD_PLUGFORM_VIA_CODE_FAILURE,
});

// function that returns an object literal
export const getOldPlugformViaCodeError = () => ({
    type: Types.GET_OLD_PLUGFORM_VIA_CODE_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getPlugformViaCodeOfflineRequest = (plugcode, folder, fiche,owner) => ({
    type: Types.GET_PLUGFORM_VIA_CODE_OFFLINE_REQUEST,
    plugcode : plugcode,
    folder: folder,
    fiche : fiche,
    owner:owner
});

// function that returns an object literal
export const getPlugformViaCodeOfflineSuccess = (data) => ({
    type: Types.GET_PLUGFORM_VIA_CODE_OFFLINE_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getPlugformViaCodeOfflineFailure = () => ({
    type: Types.GET_PLUGFORM_VIA_CODE_OFFLINE_FAILURE,
});

// function that returns an object literal
export const getPlugformViaCodeOfflineError = () => ({
    type: Types.GET_PLUGFORM_VIA_CODE_OFFLINE_ERROR,
});

// function that returns an object literal
export const getPlugformViaCodeOfflineReset = () => ({
    type: Types.GET_PLUGFORM_VIA_CODE_OFFLINE_RESET,
});

/*
Token  payload, userhash, plugnote
 */
// function that returns an object literal
export const postCheckEmailRequest = (email, userhash) => ({
    type: Types.POST_CHECK_EMAIL_REQUEST,
    email : email,
    userhash : userhash,
});

// function that returns an object literal
export const postCheckEmailSuccess = () => ({
    type: Types.POST_CHECK_EMAIL_SUCCESS,
});

// function that returns an object literal
export const postCheckEmailFailure = () => ({
    type: Types.POST_CHECK_EMAIL_FAILURE,
});

// function that returns an object literal
export const postCheckEmailError = () => ({
    type: Types.POST_CHECK_EMAIL_ERROR,
});

// function that returns an object literal
export const postAddPlugcodePlugnoteUserRequest = (payload, email, userhash) => ({
    type: Types.POST_ADD_PLUGCODE_PLUGNOTE_USER_REQUEST,
    payload : payload,
    userhash : userhash,
    email : email,
});

// function that returns an object literal
export const postAddPlugcodePlugnoteUserSuccess = (data) => ({
    type: Types.POST_ADD_PLUGCODE_PLUGNOTE_USER_SUCCESS,
    payload: data
});

// function that returns an object literal
export const postAddPlugcodePlugnoteUserFailure = () => ({
    type: Types.POST_ADD_PLUGCODE_PLUGNOTE_USER_FAILURE,
});

// function that returns an object literal
export const postAddPlugcodePlugnoteUserError = () => ({
    type: Types.POST_ADD_PLUGCODE_PLUGNOTE_USER_ERROR,
});


// function that returns an object literal
export const delDeletePlugcodePlugnoteUserRequest = (payload, userhash) => ({
    type: Types.DEL_DELETE_PLUGCODE_PLUGNOTE_USER_REQUEST,
    payload : payload,
    userhash : userhash
});

// function that returns an object literal
export const delDeletePlugcodePlugnoteUserSuccess = (data) => ({
    type: Types.DEL_DELETE_PLUGCODE_PLUGNOTE_USER_SUCCESS,
    payload: data
});

// function that returns an object literal
export const delDeletePlugcodePlugnoteUserFailure = () => ({
    type: Types.DEL_DELETE_PLUGCODE_PLUGNOTE_USER_FAILURE,
});

// function that returns an object literal
export const delDeletePlugcodePlugnoteUserError = () => ({
    type: Types.DEL_DELETE_PLUGCODE_PLUGNOTE_USER_ERROR,
});


// function that returns an object literal
export const postModelGenerateMultiplePlugcodeRequest = (modelhash, userhash) => ({
    type: Types.POST_MODEL_GENERATE_MULTIPLE_PLUGCODE_REQUEST,
    modelhash : modelhash,
    userhash : userhash
});

export const postModelGenerateMultiplePlugcodeSuccess = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_MODEL_GENERATE_MULTIPLE_PLUGCODE_SUCCESS,
        });
        toast.success(<MainToastMessage title='global.plugnotes' message='plugcode.generate-multiple-success' />)
    }
};


export const postModelGenerateMultiplePlugcodeFailure = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_MODEL_GENERATE_MULTIPLE_PLUGCODE_FAILURE,
        });
        toast.error(<MainToastMessage title='global.plugnotes' message='plugcode.generate-multiple-error' />)
    }
};


export const postModelGenerateMultiplePlugcodeError = () => {
    return function(dispatch) {
        dispatch({
            type: Types.POST_MODEL_GENERATE_MULTIPLE_PLUGCODE_ERROR,
        });
        toast.error(<MainToastMessage title='global.plugnotes' message='generate-multiple-error' />)
    }
};

/*
Token
 */
// function that returns an object literal
export const getPlugformcodeRequest = (modelhash,userhash,usermainhash) => ({
    type: Types.GET_PLUGFORMCODE_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    usermainhash : usermainhash,
});

// function that returns an object literal
export const getPlugformcodeSuccess = (data) => ({
    type: Types.GET_PLUGFORMCODE_SUCCESS,
    payload: data
});

// function that returns an object literal
export const getPlugformcodeFailure = () => ({
    type: Types.GET_PLUGFORMCODE_FAILURE,
});

// function that returns an object literal
export const getPlugformcodeError = () => ({
    type: Types.GET_PLUGFORMCODE_ERROR,
});


export const resetAllData = () => ({
    type: Types.RESET_ALL_DATA,
});

