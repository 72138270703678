// actions types is object with the
// key: GET_DATA_REQUEST -> value: describes the action
export const Types = {
    RESET_ALL_DATA_LAYOUT: 'reset_all_data_translate',

    GET_ALL_LAYOUTS_REQUEST: 'get_all_layouts_request',
    GET_ALL_LAYOUTS_SUCCESS: 'get_all_layouts_success',
    GET_ALL_LAYOUTS_FAILURE: 'get_all_layouts_failure',
    GET_ALL_LAYOUTS_ERROR: 'get_all_layouts_error',

    GET_ALL_LAYOUTS_BY_MODEL_REQUEST: 'get_all_layouts_by_model_request',
    GET_ALL_LAYOUTS_BY_MODEL_SUCCESS: 'get_all_layouts_by_model_success',
    GET_ALL_LAYOUTS_BY_MODEL_FAILURE: 'get_all_layouts_by_model_failure',
    GET_ALL_LAYOUTS_BY_MODEL_ERROR: 'get_all_layouts_by_model_error',

    GET_ALL_LAYOUTS_BY_MODEL_OFFLINE_REQUEST: 'get_all_layouts_by_model_offline_request',
    GET_ALL_LAYOUTS_BY_MODEL_OFFLINE_SUCCESS: 'get_all_layouts_by_model_offline_success',
    GET_ALL_LAYOUTS_BY_MODEL_OFFLINE_FAILURE: 'get_all_layouts_by_model_offline_failure',
    GET_ALL_LAYOUTS_BY_MODEL_OFFLINE_ERROR: 'get_all_layouts_by_model_offline_error',

    GET_ONE_LAYOUT_BY_MODEL_REQUEST: 'get_one_layout_by_model_request',
    GET_ONE_LAYOUT_BY_MODEL_SUCCESS: 'get_one_layout_by_model_success',
    GET_ONE_LAYOUT_BY_MODEL_FAILURE: 'get_one_layout_by_model_failure',
    GET_ONE_LAYOUT_BY_MODEL_ERROR: 'get_one_layout_by_model_error',

    POST_LAYOUT_BY_MODEL_REQUEST: 'post_layout_by_model_request',
    POST_LAYOUT_BY_MODEL_SUCCESS: 'post_layout_by_model_success',
    POST_LAYOUT_BY_MODEL_FAILURE: 'post_layout_by_model_failure',
    POST_LAYOUT_BY_MODEL_ERROR: 'post_layout_by_model_error',

    DELETE_DELETE_LAYOUT_BY_MODEL_REQUEST: 'delete_delete_layout_by_model_request',
    DELETE_DELETE_LAYOUT_BY_MODEL_SUCCESS: 'delete_delete_layout_by_model_success',
    DELETE_DELETE_LAYOUT_BY_MODEL_FAILURE: 'delete_delete_layout_by_model_failure',
    DELETE_DELETE_LAYOUT_BY_MODEL_ERROR: 'delete_delete_layout_by_model_error',
};


/*
Token
 */
// function that returns an object literal
export const getAllLayoutsRequest = () => ({
    type: Types.GET_ALL_LAYOUTS_REQUEST,
});

// function that returns an object literal
export const getAllLayoutsSuccess = (data) => ({
    type: Types.GET_ALL_LAYOUTS_SUCCESS,
    payload: data
});

// function that returns an object literal
export const getAllLayoutsFailure = () => ({
    type: Types.GET_ALL_LAYOUTS_FAILURE,
});

// function that returns an object literal
export const getAllLayoutsError = () => ({
    type: Types.GET_ALL_LAYOUTS_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getAllLayoutsByModelRequest = (modelhash,userhash) => ({
    type: Types.GET_ALL_LAYOUTS_BY_MODEL_REQUEST,
    modelhash : modelhash,
    userhash : userhash
});

// function that returns an object literal
export const getAllLayoutsByModelSuccess = (data) => ({
    type: Types.GET_ALL_LAYOUTS_BY_MODEL_SUCCESS,
    payload: data,
});

// function that returns an object literal
export const getAllLayoutsByModelFailure = () => ({
    type: Types.GET_ALL_LAYOUTS_BY_MODEL_FAILURE,
});

// function that returns an object literal
export const getAllLayoutsByModelError = () => ({
    type: Types.GET_ALL_LAYOUTS_BY_MODEL_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const getOneLayoutByModelRequest = (modelhash,userhash, translateid) => ({
    type: Types.GET_ONE_LAYOUT_BY_MODEL_REQUEST,
    modelhash : modelhash,
    userhash : userhash,
    translateid : translateid
});

// function that returns an object literal
export const getOneLayoutByModelSuccess = (data) => ({
    type: Types.GET_ONE_LAYOUT_BY_MODEL_SUCCESS,
    payload: { data }
});

// function that returns an object literal
export const getOneLayoutByModelFailure = () => ({
    type: Types.GET_ONE_LAYOUT_BY_MODEL_FAILURE,
});

// function that returns an object literal
export const getOneLayoutByModelError = () => ({
    type: Types.GET_ONE_LAYOUT_BY_MODEL_ERROR,
});


/*
Token
 */
// function that returns an object literal
export const postLayoutByModelRequest = (payload) => ({
    type: Types.POST_LAYOUT_BY_MODEL_REQUEST,
    payload : payload,

});

// function that returns an object literal
export const postLayoutByModelSuccess = () => ({
    type: Types.POST_LAYOUT_BY_MODEL_SUCCESS,
});

// function that returns an object literal
export const postLayoutByModelFailure = () => ({
    type: Types.POST_LAYOUT_BY_MODEL_FAILURE,
});

// function that returns an object literal
export const postLayoutByModelError = () => ({
    type: Types.POST_LAYOUT_BY_MODEL_ERROR,
});

/*
Token
 */
// function that returns an object literal
export const deleteDeleteLayoutByModelRequest = (mid, userhash, id) => ({
    type: Types.DELETE_DELETE_LAYOUT_BY_MODEL_REQUEST,
    mid : mid,
    userhash : userhash,
    id : id,

});

// function that returns an object literal
export const deleteDeleteLayoutByModelSuccess = () => ({
    type: Types.DELETE_DELETE_LAYOUT_BY_MODEL_SUCCESS,
});

// function that returns an object literal
export const deleteDeleteLayoutByModelFailure = () => ({
    type: Types.DELETE_DELETE_LAYOUT_BY_MODEL_FAILURE,
});

// function that returns an object literal
export const deleteDeleteLayoutByModelError = () => ({
    type: Types.DELETE_DELETE_LAYOUT_BY_MODEL_ERROR,
});

export const resetAllDataLayout = () => ({
    type: Types.RESET_ALL_DATA_LAYOUT,
});

