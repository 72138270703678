import {Types} from '../actions/layout';

// create initial state for reducers
const INIT_STATE = {
    list: [],
    fetching: false,
    error: null
};

// reducer function to transform state
export default function layout(state = INIT_STATE, action) {
    switch(action.type) {
        case Types.RESET_ALL_DATA_LAYOUT : return INIT_STATE;
        case Types.GET_ALL_LAYOUTS_BY_MODEL_REQUEST: {
            return {
                ...state,
                list: [],
                fetching : true
            }
        }
        case Types.GET_ALL_LAYOUTS_BY_MODEL_SUCCESS: {
            return {
                list: action.payload,
                fetching : false
            }
        }
        case Types.GET_ALL_LAYOUTS_BY_MODEL_FAILURE:
        case Types.GET_ALL_LAYOUTS_BY_MODEL_ERROR: {
            return {
                list: [],
                fetching : false
            }
        }
        default: return state;
    }
};
