import React, {useContext, useEffect, useMemo, useRef, useState} from "react"
import {makeStyles} from "@material-ui/core/styles";
import {connect} from "react-redux";
import { useTranslation } from "react-i18next";
import colors from "../../assets/themes/Colors";
import { Tab } from "@material-ui/core";
import { PlugTooltip } from "../custom/CustomComponents";
import { ContactMail } from "@material-ui/icons";
import { BackupTable, DocumentConfigIcon, EditValueIcon, SettingsIcon } from "../custom/icons/CustomIcons";
import { ReactComponent as Extdata } from '../../assets/images/format-icon/svg/extdata.svg';
import ModalHelpComponent from "../modals/ModalHelpComponent";
import RenderEditTableComponent from "./tablemulti/RenderEditTableComponent";
import PlugTabs from "./shared/PlugTabs";
import TablemultiEditLayoutComponent from "./tablemulti/TablemultiEditLayoutComponent";
import { devLog, isDefined, returnJSON } from "../../utils/utils";
import RenderEditTableDefaultValuesComponent from "./tablemulti/RenderEditTableDefaultValuesComponent";
import Axios from "axios";
import { getToken } from "../../utils/Common";
import ApplicationConfig from "../../config/ApplicationConfig";
import RenderEditOtherOptionsComponent from "./tablemulti/RenderEditOtherOptionsComponent";
import { fieldsWithColumns } from "../../utils/Fields";
import { DrawerContext } from "../../App";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    table: {
        minWidth: 650,
    },
    tableRoot: {
        marginRight: '20px',
    },
    headers: { '& .MuiTableCell-root': {
        padding: '4px !important',
        textAlign: 'center',
        position: 'sticky',
    }},
    flatHeader: {
        display: 'none',
    },
    appBar: {
        backgroundColor: 'white',
        borderBottom: '3px solid grey',
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        color: 'black',
    },
    title: {
        flexGrow: 1,
    },
    logo: {
        width: '10%',
    },
    field: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    inputField: {
        padding: '0px 0px 7px',
    },
    headerInputCtn: { fontSize: 13, flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center' },
    inputCenter: { textAlign: 'center' },
    mainField: {
        minWidth: '100%',
        display: 'flex',
        flexDirection: 'column',
        padding: '12px 20px',
    },
    selectType: {
        //paddingRight: '24px',
        marginTop: '15px',
        marginLeft: '10px',
    },
    inputType: {
        marginTop: '30px',
        marginLeft: '0px',
        marginRight: '20px',
    },
    inputListType: {
        marginTop: '15px',
        marginLeft: '10px',
    },
    configCell: {
        height: 60,
    },
    nbColumn: {
        width: '30px !important',
        paddingLeft: 5,
        paddingRight: 5
    },
    preselConfig: {
        display: 'flex',
        flexDirection: 'row',
        paddingTop: 5,
        paddingBottom: 5
    },
    switchCtn: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    configRow: {
        // verticalAlign: 'bottom',
        height: '60px !important',
    },
    openClose: {
        flex: '0 0 auto',
        color: 'rgba(0, 0, 0, 0.54)',
        cursor: 'pointer',
        overflow: 'visible',
        fontSize: '1.5rem',
        textAlign: 'center',
        transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: '50%',
        scrollMarginTop: '30px',
    },
    tableRow: {
        borderRadius: 4,
        color: colors.main,
        padding: '0px 5px',
        gap: 5,
        // display: 'flex',
        // alignItems: 'center'
    },
    rowNb: {
        fontSize: 14,
        marginRight: 4,
        border: '1px solid',
        padding: '0px 4px',
        borderRadius: '50%',
        verticalAlign: 'bottom',
    },
    rowContainer: {
        '& .MuiTableCell-root': {
            padding: '0px!important'
        },
    },
    selectedMask: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: "6px",
        backgroundColor: colors.selectedAlpha,
        color: colors.white,
        padding: "3px 8px",
        minHeight: 24,
        gap: 5,
    },
    unselectedMask: {
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 24,
        borderRadius: "6px",
        backgroundColor: colors.unselectedAlpha,
        color: colors.white,
        padding: "3px 8px",
        gap: 5,
    },
    masksContainer: {
        width: 'inherit',
        minHeight: 24,
        cursor: 'pointer',
        flexDirection: 'row',
        display: 'flex',
        flexWrap: 'wrap',
        borderRadius: "6px",
        alignItems: 'flex-start',
        gap: 5,
        paddingLeft: 12,
        paddingTop: 4,
        paddingBottom: 4,
    },
    subOpenClose: {
        display: 'flex',
        flexDirection: 'column',
        '&:hover': {
            borderRadius: 50,
            background: colors.frost
        }
    },
    fieldIcon: {
        // stroke: colors.white,
        fill: colors.white,
        color: colors.white,
        width: 20,
        height: 'auto',
        verticalAlign: 'sub',
        cursor: 'pointer'
    },
    switch: {
        '& .MuiSwitch-root': { height: '43px !important' }, height: '43px !important',
        '& .MuiSwitch-track': { backgroundColor: colors.unselectedAlpha, opacity: 1, },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': { backgroundColor: colors.unselectedAlpha, opacity: 1 }
    },
    switchIcon: { fontSize: 24, color: 'white', backgroundColor: colors.main, borderRadius: 12, padding: 2, },
    clickableText: {
        cursor: 'pointer',
    },
    warnToast: {
        backgroundColor: colors.unselected,
    },
    tab: {
        // paddingTop: -20,
        backgroundColor: colors.palePlugGreen,
        '& .MuiTab-wrapper': {
          flexDirection: 'row',
          fontSize: 15,
          gap: 4,
        },
        '&.MuiTab-labelIcon': {
          minHeight: 'auto !important',
        },
    },
    unselectedTab: {
        backgroundColor: colors.greyLight,
    },
}))

const FormatTableMultipleConstructorComponent = (props) => {
    // devLog('TMulti constructor got props', props)
    const classes = useStyles()
    const { t } = useTranslation(['common', 'models']);
    const [tabNb, setTabNb] = useState(0)
    const { openDrawer, handleToggleDrawer } = useContext(DrawerContext)
    const drawerWasOpen = useMemo(() => openDrawer, [])
    const containerRef = useRef(null)
    const hasExtdata = props.field.format === 'tablemultin' || props.field.format === 'smailvalidmulti'
    const isSmail = props.field.format === 'smailvalidmulti'
    const isDocument = props.field.format === 'document'
    // Tabs
    const tableTab = 0
    const extdataTab = hasExtdata ? 1 : -1
    const layoutTab = hasExtdata ? extdataTab + 1 : tableTab + 1
    const defaultTab = layoutTab + 1
    const emailTab = isSmail ? defaultTab + 1 : -1
    const optionsTab = isSmail ? emailTab + 1 : defaultTab + 1
    const documentTab = isDocument ? optionsTab + 1 : -1

    let valueRows = props.field.formatoptions.length > 0 ? props.field.formatoptions : []
    if ((fieldsWithColumns.includes(props.field.format) && props.field.formatoptions.hasOwnProperty('lines')) || hasExtdata || isDocument) {
        valueRows = props.field.formatoptions.lines.length > 0 ? props.field.formatoptions.lines : []
    }
    // devLog('valueRow', valueRows)
    let valueRows2 = valueRows.map((entry, cindex) => { return { ...entry, dragId: isDefined(entry.dragId) ? entry.dragId : 'col-' + cindex }})

    const [dataRows, setDataRows] = useState(valueRows2)
    // devLog('DataRows', valueRows2)
    const [missingLabel, setMissingLabel] = useState(null)
    const [rowLimit, setRowLimit] = useState(isDefined(props.field.formatoptions.setup?.rowLimit) ? props.field.formatoptions.setup?.rowLimit : 0)
    const [openModalColonneHelp, setOpenModalColonneHelp] = useState(false);
    const [openModalTableHelp, setOpenModalTableHelp] = useState(false)
    const [previewData, setPreviewData] = useState(null)
    const [error, setError] = useState(null)

    const handleModalColonneHelpClose = () => {
        setOpenModalColonneHelp(false);
    }

    const handleOpenModalColonneHelp = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setOpenModalColonneHelp(true);
    }

    const handleModalTableHelpClose = () => {
        setOpenModalTableHelp(false);
    }

    const handleModalTableHelpOpen = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setOpenModalTableHelp(true);
    }

    const handleUpdateModel = (model) => {
        props.setModel(model)
    }

    function createData(label, formatLabel, format, mask, presel, id, regex, configuration, recap, colWidth = 60, fontSize = 11, showOnPdf) {
      return { label, formatLabel, format, mask, presel, id, regex, configuration, recap, colWidth, fontSize, showOnPdf, dragId: 'col-' + dataRows.length }
    }

    const rows = [
        // createData('', '', '', [], [], 0, []),
        createData('Commentaires', 'Texte', 'text', ['10', '40', '256', '512'], null, 5, [], null, null, null, null, true),
        createData('E mail', 'Mail', 'mail', ['@'], null, 7, [], null, null, null, null, true),
        createData('URL', 'Url', 'url', [], null, 13, [], null, null, null, null, true),
        createData('GSM', 'Téléphone', 'tel', ['xxxx/xxxxxxxx', '+xxxxxxxxxxxxxx'], null, 8, ['9999/99999999', '+99999999999999'], null, null, null, null, true),
        createData('Quantité', 'Num', 'numeric', ['XX', 'XX,XXXXXX', 'X.XXX', 'X.XXX,XXXXX'], null, 3, ['99', '99,999999', '9.999', '9.999,99999'], null, false, null, null, true),
        createData('Formula', 'Formula', 'formula', [], null, 10, null, { formula: "" }, false, null, null, true),
        createData('Suivi des dates', 'Date', 'date', ["DD/MM", "DD/MM/YY", "DD/MM/YYYY", "MM/YYYY", 'Free Text'], null, 2, ['99/99', '99/99/99', '99/99/9999', '99/9999', '99'], null, null, null, null, true),
        createData('Debut', 'Heure', 'time', ['HH:MM (24H)', 'HH:MM:SS', 'MM:SS (60min)', 'HH:MM (15min)', 'Free Text', 'HH H MM'], null, 4, ['99:99', '99:99:99', '99:99', '99:99', '99', '99 H 99'], null, null, null, null, true),
        createData(t('constructor.duration'), t('constructor.duration'), 'duration', ["HH:MM", "HH:MM:SS", "MM:SS"], null, 12, ['99:99', '99:99:99', '99:99'], null, null, null, null, true),
        createData('Taches', 'Présélection', 'presel', [{ multiselect: false, editable: false }, { multiselect: false, editable: true }, { multiselect: true, editable: false }, { multiselect: true, editable: true }], [], 1, [], null, null, null, null, true),
        createData('Valider', 'Radio', 'radio', ['Y/N', 'Y/N/NA', 'Checkbox'], null, 6, [], null, null, null, null, true),
        createData('Statut', 'Select', 'select', [], ['1-ouvert', '2-fermé'], 9, [], null, null, null, null, true),
        createData('S01', 'S01', 'extdata', [], null, 11, null, {
            ref: "",
            query: "LIKE",
            columns: "",
            column: "",
            dest: ""
        }, null, null, null, true),
        createData('S03', 'S03', 'intdatamaj', [], null, 15, null, {
            display_search: false,
            plugform: null,
            dest: null,
            dest_infos: null
        }, null, null, null, true),
        createData('I01', 'I01', 'intdata', [], null, 11, null, {
            ref: "",
            query: "LIKE",
            columns: "",
            column: "",
            dest: ""
        }, null, null, null, true),
        createData('S40', 'S40', 'combofillextdata', [], null, 16, null, {
            ref: "",
            query: "LIKE",
            columns: "",
            column: "",
            dest: "",
            filters:[{
                id: 1,
                refColumn : "",
                viewColumn : "",
                multiple : false,
                defaultValue : "",
            }],
        }, null, null, null, true),
        createData('Quantité référencée', 'Num ref', 'numeric-ref', [], null, 14, [], { target: null }, null, null, null, true),
    ]

    if (props.field.format === 'tablemultin') {
        rows.push(createData('S33', 'S33', 's33data', [], null, 14, [], { target: null, targettable: null }, null, null, null, true));
    } else if (props.field.format === 'document') {
        // rows.push(createData('S33', 'S33', 's33data', [], null, 14, [], { target: null, targettable: null }, null, null, null, true))
        rows.push(createData(t('document.docsource'), 'A32 - Source (Mandatory)', 'file-source', "", null, 10000, null, null, true, false))
        rows.push(createData(t('document.docrefid'), 'A32 - Reference (Mandatory)', 'file-refid', "", null, 10011, null, null, true, false))
        rows.push(createData(t('document.docfilename'), 'A32 - Filename', 'file-name-text', ['512'], null, 10022, [], null, null, true))
        rows.push(createData(t('document.doccomment'), 'A32 - Attachment comment', 'file-comment-text', ['512'], null, 10033, [], null, null, true))
        rows.push(createData(t('document.doctype'), 'A32 - Format', 'file-type-text', ['4'], null, 10044, null, null, true, null))
        rows.push(createData(t('document.docsize'), 'A32 - Size', 'file-size-num', ["XX,X"], null, 10055, null, null, true, null))
        rows.push(createData(t('document.docdate'), 'A32 - Create date', 'file-create-date', ["Free Text"], null, 10066, null, true, null, true))
        rows.push(createData(t('document.docstockage'), 'A32 - Access path (Mandatory)', 'file-uri', "", null, 10077, null, null, true, false))
    }

    const handleSetDataRows = (rows) => { // Nécessaire au SMailValidMultiConstructor pour disposer de la liste complète des colonnes
        setDataRows(rows)
        if (isDefined(props.setDataRows)) {
            props.setDataRows(rows)
        }
    }

    const handleAddRow = () => {
        // if (dataRows.some((row, rindex) => {
        //     if (row.label === '') {
        //         setMissingLabel(rindex)
        //         toast.info(t('tablemulti.missing-label'),
        //         {
        //             autoClose: 3000,
        //             hideProgressBar: true,
        //             bodyClassName: classes.warnToast,
        //             className: classes.warnToast,
        //             toastClassName: classes.warnToast,
        //         })
        //         return true
        //     } else { return false }
        // })) {
        //     console.log('', );
        // } else {
            setMissingLabel(null)
            handleAddTableMulti()
        // }
    }

    const handleAddTableMulti = () => {
        let last = {...dataRows[dataRows.length -1]}
        let _rows = [...dataRows]
        _rows.push(
            // createData('', 'Présélection', 'presel', {
            //     multiselect: false,
            //     editable: false
            // }, [], new Date().getTime(), null, null, null, null, null, true)
            {...rows[0], label: '', mask: '512', id: new Date().getTime() }
        )

        // Puts immutable columns at last index
        if (props.field.format === 'document') {
            _rows = _rows.filter(element => element.format !== 'file-uri')
            last.id = new Date().getTime() + 1000
            _rows.push(last)
        } else if (props.field.format === 'smailvalidmulti') {
            _rows = _rows.filter(element => element.id !== 1000)
            if (last?.id === 1000) { _rows.push(last) }
        }
        handleSetDataRows(_rows)
    }

    const handleUpdateLabel = (index, value) => {
        dataRows[index].label = value;
        handleSetDataRows(dataRows);
    }

    const handleUpdateFormat = (index, value) => {
        const newCol = rows.find(entry => entry.format === value)
        dataRows[index].format = newCol.format;
        dataRows[index].formatLabel = newCol.formatLabel;
        dataRows[index].configuration = newCol.configuration;
        dataRows[index].mask = newCol.format === 'presel' ? {multiselect: false, editable: false} : null;
        dataRows[index].presel = newCol.format === 'select' ? ['1-ouvert', '2-fermé'] : [];
        if (newCol.format !== 'formula' && newCol.format !== 'numeric') {
            dataRows[index].recap = null;
        }
        if (newCol.regex?.length > 0) {
            dataRows[index].mask = newCol.mask[0]
            dataRows[index].regex = newCol.regex[0]
        }
        handleSetDataRows([...dataRows]);
    }

    const handleUpdateMask = (index, value, regex) => {
        dataRows[index].mask = value;
        dataRows[index].regex = regex;
        handleSetDataRows(dataRows);
    }


    const handleUpdateType = (index, value) => {
        let multiselect;
        let editable;

        switch (value) {
            case 'T01':
                multiselect = false;
                editable = false;
                break;
            case 'T02':
                multiselect = false;
                editable = true;
                break;
            case 'T03':
                multiselect = true;
                editable = false;
                break;
            case 'T04':
                multiselect = true;
                editable = true;
                break;
            default:
                break;
        }

        dataRows[index].mask = {multiselect: multiselect, editable: editable};
        handleSetDataRows(dataRows);
    }

    const handleUpdatePresel = (index, value) => {
        dataRows[index].presel = value;
        handleSetDataRows([...dataRows])
    }

    const handleUpdatePreselOrder = (index, value) => {
        dataRows[index].sortOrder = value
        handleSetDataRows([...dataRows])
    }

    const handleRemoveSpecificRow = (value) => {
        let items = dataRows.filter((row, index) => index !== value);
        handleSetDataRows(items);
    };

    const handleChangeOrientation = (e) => {
        let model = [...props.model]
        model[props.index].options.setup.tableOrientation = e
        props.setModel(model)
    }

    const handleChangeRowLimit = (e) => {
        let _rowLimit = Number(e.target.value)
        setRowLimit(_rowLimit)
        let model = [...props.model];
        model[props.index].options.setup.rowLimit = _rowLimit;
        props.setModel(model)
    }

    const handleEnableRecapSpecificRow = (index, value) => {
        let _rows = [...dataRows]
        _rows[index].recap = value
        handleSetDataRows(_rows)
    }

    const handleChangeDefaultRows = (rows) => {
        let _model = [...props.model]
        _model[props.index].options.setup = {
            ..._model[props.index].options.setup,
            defaultRows: rows,
        }
        _model[props.index].formatoptions.setup = {
            ..._model[props.index].formatoptions.setup,
            defaultRows: rows,
        }
        handleUpdateModel(_model)
    }

    useEffect(() => {
        let model = [...props.model]
        model[props.index].formatoptions.lines = dataRows
        model[props.index].options.lines = dataRows
        props.setModel(model)
    }, [dataRows])

    useEffect(() => {
      if (isDefined(props.columns)) setDataRows(props.columns)
    }, [props.columns])

    const handleChangeTab = (_, nb) => {
        if (nb === emailTab && !openDrawer) {
            handleToggleDrawer()
        }
        if (nb !== emailTab && !drawerWasOpen && openDrawer) { handleToggleDrawer() }
        if (nb === layoutTab) {
            if (isDefined(previewData)) { setTabNb(nb) } else { handleToggleTablePreview() }
        } else { setTabNb(nb) }
    }

    const getFieldDataByFieldId = (plugNb) => {
        let url = ApplicationConfig.apiv2Url + `Datas/GetField.php?uh=${props.userhash}&field=${props.field.id}&mid=${props.dbModel.id}${isDefined(plugNb) ? 'userindex=' + plugNb : ''}`
        // devLog('fieldNb', props.field.id)
        return Axios.get(
            url,
            {
                crossdomain: true,
                'headers': {
                    'X-AUTH-TOKEN': getToken(),
                }
            })
    }

    const handleToggleTablePreview = () => {
        getFieldDataByFieldId()
            .then(result => {
                // devLog('getFieldDataByFieldId result', result)
                if (result.statusText === 'OK') {
                    let jsonData = returnJSON(result.data['field' + props.field.id])
                    if (!isDefined(jsonData)) { jsonData = [] }
                    setPreviewData(jsonData)
                    setTabNb(layoutTab)
                }
            })
            .catch(e => {
                setPreviewData([])
                setTabNb(layoutTab)
                setError(e)
            })
    }

    return (
        <div className={classes.mainField} ref={containerRef}>
            <PlugTabs
                background={colors.plugnotesLight}
                color={colors.unselected}
                extra={
                    <span style={{ marginLeft: '5px', display: 'flex', gap: 10 }}>
                        <PlugTooltip title={t('common:global.help-manuels')}>
                            <span id="icon-more" className="cursor font_size_15 text-span-round" onClick={(event) => {
                                event.stopPropagation();
                                event.preventDefault();
                                handleModalTableHelpOpen(event)
                            }} style={{ fontWeight: 'bold' }}>
                                ?
                            </span>
                        </PlugTooltip>
                    </span>
                }
                onChange={handleChangeTab}
                value={tabNb}
            >
                <Tab
                    disableRipple
                    icon={<BackupTable color={tabNb === tableTab ? colors.unselected : colors.coal}/>}
                    label={t('tablemulti.tab-columns')} className={`${classes.tab} ${tabNb !== tableTab && classes.unselectedTab}`} />
                {
                    hasExtdata &&
                    <Tab icon={<Extdata fill={tabNb === extdataTab ? colors.unselected : colors.coal} height={24} width={24} />}
                        disableRipple
                        label={t('smailvalidmulti.config-extdata')}
                        className={`${classes.tab} ${tabNb !== extdataTab && classes.unselectedTab}`}
                    />
                }
                <Tab
                    disableRipple
                    icon={
                        <span className={`material-icons `} style={{ color: tabNb === layoutTab ? colors.unselected : colors.coal }}>
                            picture_as_pdf
                        </span>}
                    label={t('tablemulti.tab-layout')} className={`${classes.tab} ${tabNb !== layoutTab && classes.unselectedTab}`} />
                <Tab
                    disableRipple
                    icon={<EditValueIcon color={tabNb === defaultTab ? colors.unselected : colors.coal}/>}
                    className={`${classes.tab} ${tabNb !== defaultTab && classes.unselectedTab}`}
                    label={t('helper.default-values')}
                />
                {
                    isSmail &&
                    <Tab
                        disableRipple
                        icon={<ContactMail fill={tabNb === emailTab ? colors.unselected : colors.coal}/>}
                        label={t('tablemulti.tab-email')} className={`${classes.tab} ${tabNb !== emailTab && classes.unselectedTab}`} />
                }

                <PlugTooltip title={t('tablemulti.other-options')} placement="top">
                    <Tab
                        disableRipple
                        icon={ <SettingsIcon color={tabNb === optionsTab ? colors.unselected : colors.coal} size={30} /> }
                        label={t('helper.options')} className={`${classes.tab} ${tabNb !== optionsTab && classes.unselectedTab}`} />
                </PlugTooltip>
                {
                    isDocument &&
                    <Tab
                        disableRipple
                        icon={<DocumentConfigIcon fill={tabNb === documentTab ? colors.unselected : colors.coal}/>}
                        label={t('tablemulti.sources')} className={`${classes.tab} ${tabNb !== documentTab && classes.unselectedTab}`} />
                }

            </PlugTabs>
            {
                tabNb === documentTab &&
                props.documentTab
            }
            {
                tabNb === extdataTab &&
                props.extdataTab
            }
            {
                tabNb === emailTab &&
                props.emailTab
            }
            {
                tabNb === tableTab &&
                <RenderEditTableComponent
                    dataRows={dataRows}
                    field={props.field}
                    handleAddRow={handleAddRow}
                    handleChangeDefaultRows={handleChangeDefaultRows}
                    handleChangeRowLimit={handleChangeRowLimit}
                    handleEnableRecapSpecificRow={handleEnableRecapSpecificRow}
                    handleOpenModalColonneHelp={handleOpenModalColonneHelp}
                    handleOpenSourceTab={() => setTabNb(documentTab)}
                    handleRemoveSpecificRow={handleRemoveSpecificRow}
                    handleUpdateFormat={handleUpdateFormat}
                    handleUpdateLabel={handleUpdateLabel}
                    handleUpdatePresel={handleUpdatePresel}
                    handleUpdatePreselOrder={handleUpdatePreselOrder}
                    handleUpdateMask={handleUpdateMask}
                    handleUpdateType={handleUpdateType}
                    missingLabel={missingLabel}
                    rowLimit={rowLimit}
                    rows={rows}
                    setDataRows={handleSetDataRows}
                />
            }
            {
                tabNb === defaultTab &&
                <RenderEditTableDefaultValuesComponent
                    index={props.index}
                    model={props.model}
                    setRows={setDataRows}
                    setModel={handleUpdateModel}
                />
            }
            {
                tabNb === optionsTab &&
                <RenderEditOtherOptionsComponent
                    field={props.field}
                    handleChangeOrientation={handleChangeOrientation}
                    handleChangeRowLimit={handleChangeRowLimit}
                    rowLimit={rowLimit}
                />
            }

            {
                openModalColonneHelp &&
                    <ModalHelpComponent open={openModalColonneHelp} close={handleModalColonneHelpClose} from="colonne"/>
            }

            {
                openModalTableHelp  ?
                    <ModalHelpComponent open={openModalTableHelp} close={handleModalTableHelpClose} from="table"/>
                    :
                    null
            }
            {
                tabNb === layoutTab &&
                <TablemultiEditLayoutComponent
                    colonnes={dataRows}
                    containerWidth={containerRef.current.clientWidth}
                    dbModel={props.dbModel}
                    error={error}
                    field={props.field}
                    fields={props.model}
                    handleToggleTablePreview={handleToggleTablePreview}
                    handleUpdateColumns={handleSetDataRows}
                    handleUpdateModel={handleUpdateModel}
                    index={props.index}
                    model={props.model}
                    rows={previewData}
                    setDataRows={handleSetDataRows}
                    title={props.field.label}
                />
            }
        </div>
    )
}

// redux providing state takeover
const mapStateToProps = (state) => {
    // devLog('state', state)
    return {
        dbModel: state.model.model,
        userhash: state.user.user.userhash,
    }
}

const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(FormatTableMultipleConstructorComponent);





