import { Input, InputAdornment, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import colors from "../../assets/themes/Colors";
import { PlugTooltip } from "./CustomComponents";
import { devLog } from "../../utils/utils";


const useStyles = makeStyles((theme) => ({
    input_add_item: {
        flexDirection: 'row',
        width: '100%',
        display: 'flex',
        marginLeft: 5
    },
    margin_left_5: {
        marginLeft : '5px',
    },
    renderSingleSelMainContainer: {
        justifyContent: 'flex-start',
        display: 'flex',
        flexDirection: 'row',
        flexGrow: 1,
    },
    renderSingleSelContainer: {
        display: 'flex',
        justifyContent: 'flex-start',
        background: colors.greenLightest,
        borderRadius: 4,
        cursor: 'pointer',
        marginBottom: 3,
        flexGrow: 1,
        gap: 3,
        flexWrap: 'wrap',
        padding: '2px 3px',
    },
    renderSingleSelContainerColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        // "&: hover": {
            background: colors.greenLightest,
            borderRadius: 4,
            cursor: 'pointer',
        // },
        marginBottom: 3,
        flexGrow: 1
    },
    btnContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    renderSingleSelIcon: {
        fontSize: 28,
        color: colors.greyDark,
    },
    selectItemContainer: {
        display: 'flex',
        gap: 3,
        minHeight: 27,
        width: '100%',
        flexWrap: 'wrap',
        background: colors.greenLightest,
        borderRadius: 4,
        padding: '2px 3px'
    },
    selectItem: {
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer'
    },
    selectedItem: {
        color: colors.white,
        backgroundColor: colors.selectedAlpha,
        padding: '0px 5px',
        margin: '1px 0px',
        minWidth: 30,
        borderRadius: 4,
        cursor: 'pointer',
        fontSize: 13,
        textAlign: 'center'
    },
    unselectedItem: {
        color: colors.white,
        backgroundColor: colors.unselectedAlpha,
        padding: '0px 5px',
        margin: '1px 0px',
        borderRadius: 4,
        cursor: 'pointer',
        fontSize: 13,
    },
    noSelectedItem: {
        color: colors.dark,
        fontSize: 13,
        fontStyle: 'italic',
        marginLeft: 10
    },
}))

export const RenderItemSingleSelectComponent = (props) => {
    const classes = useStyles();
    const { t } = useTranslation(['common'])

    const [selected, setSelected] = useState(props.value || '')
    const [newValue, setNewValue] = useState("")
    const [disabled, setDisabled] = useState(typeof props.disabled === 'undefined' ? false: props.disabled);
    const [open, setOpen] = useState(props.openDefault === true || props.isTablemulti ? !disabled : false)
    const [listMode, setListMode] = useState(false)
    const [setupbis] = props.field?.options?.setupbis || [];
    const preselList = props.presel
    if (!preselList.includes(selected)) {preselList.push(selected)}

    useEffect(() => {
        setDisabled(typeof props.disabled === 'undefined' ? false: props.disabled)
    }, [ props.disabled])

    const handleAddValueInput = (value) => {
        if (!props.disabled) {
            if(value.trim() !== ""){
                if (!props.presel.includes(value)) {
                    props.presel.push(value)
                }
                handleAddValue(value?.trim())
            }
            setNewValue('');
        }
    }

    const handleAddValue = (value) => {
        if (!props.disabled) {
            if(value.trim() !== '') {
                const newValue = selected !== value ? value : ''
                setOpen(false)
                props.handleChangeChildrenValue(newValue, props.col, props.row, props.rindex)
                setSelected(newValue)
        }
            setNewValue('')
        }
    }

    const handleToggleOpen = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (!props.disabled) { setOpen(previous => !previous) }
    }

    let valuesOrdered = preselList
        .filter((value, index) => {
            let visible = true;
            if (typeof setupbis !== "undefined" && (props?.shared && setupbis?.presels[index].visible === false)) {
                visible = false;
            }
            if (visible) {
                if (value !== undefined) return true;
                else return false
            } else return false
        })
        .sort((a, b) => { return a.toString().toLowerCase().localeCompare(b.toString().toLowerCase()) })

    return (
        <div >
            {
                open &&
                <div className={classes.renderSingleSelMainContainer}>
                    <div className={classes.btnContainer}>
                        <span id="icon-delete-playlist" className={`${classes.renderSingleSelIcon} material-icons`} onClick={handleToggleOpen}>
                            arrow_drop_up
                        </span>
                        {/* <PlugTooltip title={t('common:global.view-change')} placement="top">
                            <span id="icon-delete-playlist" className={`${classes.renderSingleSelIcon} material-icons`} onClick={(e) => { e.preventDefault(); e.stopPropagation(); setListMode(!listMode) }}>
                                {!listMode ? 'dashboard' : 'format_list_bulleted'}
                            </span>
                        </PlugTooltip> */}
                    </div>
                    <div className={classes.selectItemContainer} onClick={handleToggleOpen}>
                        <div className={classes.renderSingleSelContainerColumn}>
                            {
                                props.editable && !disabled &&
                                <div className={classes.input_add_item} onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
                                    <Input
                                        disabled={disabled}
                                        autoComplete='off'
                                        id={'new-select-input-' + props.rindex}
                                        label="Label"
                                        style={{ margin: 0, fontSize: 12, marginRight: 8, }}
                                        placeholder={t('common:multiple.add-item')}
                                        fullWidth={true}
                                        margin="none"
                                        // disableUnderline={true}
                                        inputlabelprops={{ shrink: true }}
                                        endAdornment={
                                            !disabled &&
                                            <InputAdornment position="end">
                                                <div onClick={() => handleAddValueInput(newValue, props.field, props.index)}>
                                                    <span className="material-icons" style={{ fontSize: 20 }}>
                                                        add
                                                    </span>
                                                </div>
                                            </InputAdornment>
                                        }
                                        value={newValue}
                                        onKeyPress={(e) => { if (e.key === 'Enter') { handleAddValueInput(newValue, props.field, props.index) } }}
                                        onChange={(event) => setNewValue(event.target.value)}
                                    />
                                </div>}

                            {
                                listMode ?
                                <div >
                                    {valuesOrdered
                                        // .filter(item => {
                                        //     if (!value) return true
                                        //     if (item.toLowerCase().includes(value.toLowerCase())) {
                                        //         return true
                                        //     }
                                        // })
                                        .map((value, indexation) => {
                                            if (value !== "") {
                                                return (
                                                    <div key={indexation} style={{ display: 'flex', alignItems: 'center' }}>
                                                        <input
                                                            disabled={disabled}
                                                            type="checkbox"
                                                            id={value + '-' + indexation + props.rindex}
                                                            checked={selected === value}
                                                            onChange={(event) => {
                                                                handleAddValue(value, props.field, props.index)
                                                            }}
                                                        /><label className={classes.margin_left_5} htmlFor={value + '-' + indexation} style={{ fontSize: 12 }}>{value}</label>
                                                    </div>
                                                )
                                            } return null;
                                        })}
                                </div>
                                :
                                <div className={classes.selectItemContainer}>
                                    {
                                        valuesOrdered
                                        .map((value, indexation) => {
                                            if (value !== "") {
                                                return (
                                                    <span key={indexation}
                                                        id={value + '-' + indexation}
                                                        className={selected === value ? classes.selectedItem : classes.unselectedItem}
                                                        onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleAddValue(value, props.field, props.index) }}>
                                                        {value}
                                                    </span>
                                                )
                                            }
                                            return null;
                                        })}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }

            {
                !open &&
                <div className={classes.renderSingleSelMainContainer}>
                    <span id="icon-delete-playlist" className={`${classes.renderSingleSelIcon} material-icons`} onClick={handleToggleOpen} style={props.disabled ? { visibility: 'hidden' } : undefined}>
                        arrow_drop_down
                    </span>
                    <div className={classes.renderSingleSelContainer} onClick={handleToggleOpen}>
                        {typeof props.presel !== 'undefined' && selected !== '' ?
                        <span className={!listMode? classes.selectedItem : classes.selectedItem}>{selected}</span>
                        :
                        <span className={classes.noSelectedItem}>
                            {/* {t('common:global.no-select')} */}

                        </span>
                        }
                    </div>
                </div>
            }
        </div>
    )
};

export const RenderItemMultipleSelectComponent = (props) => {
    const classes = useStyles();
    const { t } = useTranslation(['common']);

    let tempValue = []
    if (props.value !== "") {
        if (Array.isArray(props.value)) {
            tempValue = props.value;
        } else {
            tempValue = [props.value];
        }
    }

    const preselList = props.presel
    tempValue.forEach(element => {
        if (!preselList.includes(element)) preselList.push(element)
    })
    const disabled = typeof props.disabled === 'undefined' ? false: props.disabled

    const [selected, setSelected] = useState(tempValue)
    const [newSelect, setNewSelect] = useState("")
    const [open, setOpen] = useState(props.openDefault === true || props.isTablemulti ? !disabled : false)
    const [listMode, setListMode] = useState(false)
    const [setupbis,] = props.field?.options?.setupbis || []

    const handleAddValueInput = (value) => {
        if (value.trim() !== "") {
            handleSelectValue(value)
            if (!props.presel.includes(value)) preselList.push(value);
        }
        setNewSelect('')
    }

    const handleSelectValue = (value) => {
        if (value !== "") {
            let newValue = !selected.includes(value) ? [...selected, value] : selected.filter(item => item !== value)
            setSelected(newValue)
            props.handleChangeChildrenValue(newValue, props.col, props.row, props.rindex)
        }
    }

    const handleToggleOpen = () => {
        if (!props.disabled) { setOpen(previous => !previous) }
    }

    let valuesOrdered = preselList
        .filter((value, index) => {
            let visible = true;
            if (typeof setupbis !== "undefined" && (props?.shared && setupbis?.presels[index].visible === false)) {
                visible = false;
            }
            if (visible) {
                if (value !== undefined) return true;
                else return false
            } else return false
        })
        .sort((a, b) => {return a.toLowerCase().localeCompare(b.toLowerCase()); });

    // console.log('presel value', selected, props);

    return (
        <div >
            {open &&
            <div className={classes.renderSingleSelMainContainer}>
                 <div className={classes.btnContainer}>
                    <span id="icon-delete-playlist" className={`${classes.renderSingleSelIcon} material-icons`} onClick={handleToggleOpen}>
                        arrow_drop_up
                    </span>
                  {/*  <PlugTooltip title={t('common:global.view-change')} placement="top">
                        <span id="icon-delete-playlist" className={`${classes.renderSingleSelIcon} material-icons`} onClick={(e) => {e.preventDefault(); e.stopPropagation(); setListMode(!listMode)}}>
                            {!listMode? 'dashboard' : 'format_list_bulleted'}
                        </span>
                    </PlugTooltip>*/}
                </div> 
                <div className={classes.selectItemContainer}>
                    <div className={classes.renderSingleSelContainerColumn} onClick={handleToggleOpen}>
                        {
                            props.editable && !disabled &&
                            <div className={classes.input_add_item} onClick={(e) => {e.preventDefault(); e.stopPropagation()}}>
                                <Input
                                    disabled={disabled}
                                    autoComplete='off'
                                    id={'new-select-input-' + props.field?.id}
                                    style={{ margin: 0, fontSize: 12, padding: '0 5px', marginRight: 8 }}
                                    placeholder={t('common:multiple.add-item')}
                                    fullWidth
                                    margin="none"
                                    inputlabelprops={{
                                        shrink: true,
                                    }}
                                    // disableUnderline={true}
                                    endAdornment={ !disabled &&
                                    <InputAdornment position="end">
                                        <div onClick={handleAddValueInput.bind(this, newSelect, props.field, props.index)}>
                                            <span className="material-icons" style={{fontSize: 20}}>
                                                add
                                            </span>
                                        </div>
                                    </InputAdornment>}
                                    className={classes.fieldRight}
                                    value={newSelect}
                                    onKeyDown={(e) => { if (e.key === 'Enter') { handleAddValueInput(newSelect) } }}
                                    onChange={(event) => setNewSelect(event.target.value)}
                                />
                            </div>
                        }
                        {
                            listMode ?
                            valuesOrdered
                                .map((value, indexation) => {
                                if(value !== "") {
                                    return (
                                    <div key={indexation} className={classes.selectItem}>
                                        <input
                                            disabled={disabled}
                                            type="checkbox"
                                            id={value + '-' + indexation}
                                            checked={selected.includes(value)}
                                            onChange={(event) => {
                                                handleSelectValue(value, props.field, props.index);
                                            }}
                                        />
                                        <label className={classes.margin_left_5} htmlFor={value + '-' + indexation}
                                            style={{fontSize: 12}}
                                            onClick={(e) => {e.preventDefault(); e.stopPropagation(); handleSelectValue(value, props.field, props.index)}}>
                                            {value}
                                        </label>
                                    </div>
                                    )
                                }
                                else return null;})
                            :
                            <div className={classes.selectItemContainer}>
                            {valuesOrdered
                                // .filter(item => {
                                // if (!value) return true
                                // if (item.toLowerCase().includes(value.toLowerCase())) {
                                //     return true
                                // }})
                                .map((value, indexation) => {
                                if(value !== ""){
                                    return (
                                        <span key={indexation}
                                        id={value + '-' + indexation}
                                        className={selected.includes(value) ? classes.selectedItem : classes.unselectedItem}
                                        onClick={(e) => {e.preventDefault(); e.stopPropagation(); handleSelectValue(value, props.field, props.index)}}>
                                        {value}
                                    </span>
                                    )
                                }
                                else return null;})}
                            </div>
                        }
                    </div>
                </div>
            </div>
            }
            {
                !open &&
                <div className={classes.renderSingleSelMainContainer}>
                    <span id="icon-delete-playlist" className={`${classes.renderSingleSelIcon} material-icons`} onClick={handleToggleOpen}>
                        arrow_drop_down
                    </span>
                    <div className={classes.renderSingleSelContainer} onClick={handleToggleOpen}>
                        {typeof props.presel !== 'undefined' && selected.length > 0 ?
                        selected
                            .map((item, index) => {
                            return(
                            <span className={!listMode? classes.selectedItem : classes.selectedItem} key={item + '-' + index}>{item}</span>
                            )
                        })
                        :
                        <span className={classes.noSelectedItem}>
                        </span>
                        }
                    </div>
                </div>
            }
        </div>
    )
}
